import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import QTitle from '_Components/Texts/QTitle/QTitle';
import { spacing } from 'styles/abstracts/variables';
import { purple } from 'styles/abstracts/colors';

const StyledInput = styled.input`
  margin-top: ${spacing.small};
  margin-bottom: ${spacing.xsmall};
  font-weight: bold;
  border: none;
  font-size: 1.25em;
  color: ${purple};
  padding: none;
  width: auto;
  transition: all 0.5s ease-in-out;
`;

const QEditable = ({
  value,
  setValue,
  validation,
  edit,
  setEdit,
  disabled,
  ...props
}) => {
  const [prevValue, setPrevValue] = useState('');
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    setEditing(edit);
  }, [edit]);

  const onInputEnter = () => {
    setPrevValue(value.trim());
  };

  const onInputExit = () => {
    if (!validation) {
      setValue(prevValue.trim());
    } else {
      setValue(value.trim());
    }
    setEditing(false);
    setEdit(false);
  };

  return (
    <section>
      {isEditing && !disabled ? (
        <div
          onBlur={() => onInputExit()}
          onFocus={() => onInputEnter()}
          style={{ borderBottom: `1px solid ${purple}` }}
        >
          <StyledInput
            onChange={e => {
              setValue(e.target.value);
              if (e.target.value.length === 0) {
                setValue(' ');
                setEditing(true);
                setEdit(true);
              }
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                props.keyDown();
                onInputExit();
              }
            }}
            value={value}
            autoFocus
            {...props}
          />
        </div>
      ) : (
        <div
          role="button"
          onClick={() => {
            setEditing(true);
            setEdit(true);
          }}
        >
          <QTitle>{value || 'Enter your value'}</QTitle>
        </div>
      )}
    </section>
  );
};

export default QEditable;
