import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';
import { get } from 'lodash';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import { grayShades, lightBlue, purple } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import { onTheGoPlan } from '../../plans';
import LIST_PLANS_QUERY from '../../graphql/listPlans';

const Plan = styled.div`
  border: 2px solid ${grayShades.g300};
  border-radius: 15px;
  text-align: center;
  width: 17.25em;
  font-weight: bold;
`;

const Title = styled(QTitle)`
  text-align: center;
`;

const Price = styled.p`
  color: ${lightBlue};
  font-size: calc(${fontSizes.large} * 1.2);
`;

const Subtitle = styled.p`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
`;

const SubContainer = styled.div`
  margin-top: 2em;
`;

const Item = styled.p`
  margin-top: 0.25em;
  color: ${purple};
`;

const ItemsContainer = styled.div`
  margin-top: ${({ isCorporate }) => isCorporate && '3.4em'};
  margin-bottom: 1em;
`;
const ChooseButton = styled(QButton)`
  margin-bottom: 1em;
  margin-top: ${({ isMonthlySelected, isCorporate }) =>
    isMonthlySelected && !isCorporate && '2.15em'};
`;

const OnTheGoPanels = ({ setItemPriceId, currentPlan, t }) => {
  const { data } = useQuery(LIST_PLANS_QUERY);
  const {
    id,
    name,
    price,
    pricePerComment,
    dataCollection,
    maxCategories,
    numberUsers,
    numberBenchmarks,
    numberReportings,
    ticketSupportPriority,
  } = onTheGoPlan;

  return (
    <>
      <Plan>
        <Title>{name}</Title>
        <Price>{t('No engagement')}</Price>
        <Subtitle>
          {parseFloat(pricePerComment, 10).toFixed(2)}€ {t('per comment')}
        </Subtitle>
        <ItemsContainer>
          <SubContainer>
            <Subtitle>{t('Projects')}</Subtitle>
            <Item>{t(`${dataCollection} data collection`)}</Item>
            <Item>
              {maxCategories} {t('max categories')}
            </Item>
            <Item>
              <br />
            </Item>
            <Item>
              <br />
            </Item>
          </SubContainer>
          <SubContainer>
            <Subtitle>{t('Features')}</Subtitle>
            <Item>
              {numberUsers} {t(`user${numberUsers > 1 ? 's' : ''}`)}
            </Item>
            <Item>
              {numberBenchmarks}{' '}
              {t(`benchmark${numberBenchmarks > 1 ? 's' : ''}`)}
            </Item>
            <Item>
              {numberReportings}{' '}
              {t(`customizable reporting${numberReportings > 1 ? 's' : ''}`)}
            </Item>
          </SubContainer>
          <SubContainer>
            <Subtitle>{t('Support')}</Subtitle>
            <Item>{t('Online knowledge base')}</Item>
            <Item>
              {t('Ticket support')}: {t(`${ticketSupportPriority} priority`)}
            </Item>
            <Item>
              <br />
            </Item>
          </SubContainer>
        </ItemsContainer>
        {(currentPlan === 'emotion_start' || !currentPlan) && (
          <ChooseButton
            onClick={() =>
              setItemPriceId(
                data?.listPlans?.plans?.find(
                  item => item.name === 'emotion_start',
                ).itemPrice,
              )
            }
            bgColor="lightBlue"
          >
            {currentPlan === 'emotion_start'
              ? t('button:Add more credits')
              : t('button:Choose this plan')}
          </ChooseButton>
        )}
      </Plan>
    </>
  );
};

const mapStateToProps = state => ({
  currentPlan: get(state, ['auth', 'user', 'account', 'planName']),
});

export default connect(mapStateToProps)(OnTheGoPanels);
