import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useMutation, useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { get, groupBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import Legend from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/Legend/Legend';
import { getMinMaxFromUnit } from '_Utils/eindex/eindexUnits';
import Icon from '_Components/Icons/Icon';
import { getGraphToDisplay, getThematicsOverviewByDatesData } from './utils';
import DropdownsContainer from './_Containers/DropdownsContainer/DropdownsContainer';
import GET_THEMATICS_OVERVIEW_BY_DATE_DATA_QUERY from './graphql/getThematicsOverviewByDateData';
import SAVE_THEMATICS_OVERVIEW_SETTINGS from '../graphql/saveThematicsOverviewSettings';

const GraphContainer = styled.div`
  height: 35em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled(Icon)`
  animation: ${spinning} 1s linear infinite;
`;

const LoadingContainer = styled.div`
  font-weight: bold;
  text-align: center;
  width: 100%;
  //margin: auto;
  display: flex;
  justify-content: center;
`;

type CategoryBreakdownCardProps = {
  data: Array<any>;
  rawData: Array<any>;
  verbatimsWithoutThematicCount: number;
  queryFilters: any;
  isEindexUnitFahrenheit: boolean;
  t: any;
  loading: boolean;
  settings: any;
  setSettings: any;
  idpage: string;
};

const CategoryBreakdownCard = ({
  data,
  rawData,
  verbatimsWithoutThematicCount,
  queryFilters,
  isEindexUnitFahrenheit,
  t,
  loading: queryLoading,
  settings,
  setSettings,
  idpage,
}: CategoryBreakdownCardProps) => {
  const [graphToDisplay, setGraphToDisplay] = useState<string>(settings?.categoryBreakdown?.graphToDisplay);
  const [level, setLevel] = useState<string>(settings?.categoryBreakdown?.levelToDisplay);
  const [filterToDisplay, setFilterToDisplay] = useState<string>('mentionCount');
  const [subFilterToDisplay, setSubFilterToDisplay] = useState<string | null>(null);
  const [onlySelectedCategories, setOnlySelectedCategories] = useState<boolean>(
    settings?.categoryBreakdown?.onlySelectedCategories,
  );
  const [riverChartDateType, setRiverChartDateType] = useState<'day' | 'week' | 'month'>('week');
  const [riverChartData, setRiverChartData] = useState<any[]>([]);
  const categoryJoinSpecify = 'and';

  const [saveThematicsOverviewSettings] = useMutation(SAVE_THEMATICS_OVERVIEW_SETTINGS, {
    variables: {
      projectId: queryFilters.projectId,
      settings: JSON.stringify(settings),
    },
    update: () => {},
  });

  useEffect(() => {
    saveThematicsOverviewSettings();
  }, [settings.categoryBreakdown]);

  const { data: thematicsOverviewByDateData, loading } = useQuery(GET_THEMATICS_OVERVIEW_BY_DATE_DATA_QUERY, {
    variables: {
      ...queryFilters,
      dateType: riverChartDateType.toUpperCase(),
    },
  });

  useEffect(() => {
    if (thematicsOverviewByDateData) {
      getThematicsOverviewByDatesData({
        thematicsOverviewByDateData,
        data,
        level,
        t,
        onlySelectedCategories,
        subFilterToDisplay,
        filterToDisplay,
        setRiverChartData,
      });
    }
  }, [
    thematicsOverviewByDateData,
    filterToDisplay,
    subFilterToDisplay,
    riverChartDateType,
    onlySelectedCategories,
    level,
    graphToDisplay === 'river',
  ]);

  return (
    <QcardPanel title={t('Category breakdown')}>
      <DropdownsContainer
        setGraphToDisplay={setGraphToDisplay}
        graphToDisplay={graphToDisplay}
        setLevel={setLevel}
        setFilterToDisplay={setFilterToDisplay}
        setSubFilterToDisplay={setSubFilterToDisplay}
        onlySelectedCategories={onlySelectedCategories}
        setOnlySelectedCategories={setOnlySelectedCategories}
        setSettings={setSettings}
        settings={settings}
      />

      {loading && graphToDisplay === 'river' && (
        <LoadingContainer>
          <Loader icon="INTERFACE_LOADING" /> {t('Loading')}
        </LoadingContainer>
      )}
      <GraphContainer>
        {getGraphToDisplay(
          graphToDisplay,
          onlySelectedCategories,
          rawData,
          data,
          level,
          subFilterToDisplay,
          filterToDisplay,
          verbatimsWithoutThematicCount,
          queryFilters,
          riverChartData,
          riverChartDateType,
          setRiverChartDateType,
          t,
          queryLoading,
          undefined,
          false,
          false,
          undefined,
          undefined,
          undefined,
          categoryJoinSpecify,
        )}
      </GraphContainer>
      <div style={{ position: 'absolute', bottom: '1em' }}>
        {filterToDisplay === 'mentionCount' &&
          graphToDisplay !== 'treemap' &&
          graphToDisplay !== 'sunburst' &&
          graphToDisplay !== 'river' && (
            <Legend
              category="eindex"
              min={`${getMinMaxFromUnit(isEindexUnitFahrenheit).min}°`}
              max={`${getMinMaxFromUnit(isEindexUnitFahrenheit).max}°`}
            />
          )}
      </div>
    </QcardPanel>
  );
};

const mapStateToProps = state => ({
  isEindexUnitFahrenheit: get(state, ['projectConfiguration', 'unitsAndLocalesSettings', 'eindexUnitFahrenheit']),
});

export default withTranslation('card')(connect(mapStateToProps)(CategoryBreakdownCard));
