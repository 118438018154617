import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Col, Progress, Row } from 'reactstrap';
import '_Components/QStepWizard/style.scss';
import { withTranslation } from 'react-i18next';

class QStepWizard extends React.Component {
  static propTypes = {
    onStepSelected: PropTypes.func,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        status: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  };

  static defaultProps = {
    onStepSelected: _ => {},
    steps: [],
  };

  getStatusClass(status) {
    switch (status) {
      case 'complete':
        return 'complete';
      case 'active':
        return 'active';
      default:
        return 'disabled';
    }
  }

  getPercentValue(status) {
    switch (status) {
      case 'complete':
        return 100;
      case 'active':
        return 50;
      case 'disabled':
      default:
        return 0;
    }
  }

  prepareSteps() {
    const { onStepSelected, t } = this.props;
    return this.props.steps.map(step => {
      const { value, status, name } = step;
      return (
        <Col
          className={`bs_wizard_step stepWithNumber ${this.getStatusClass(
            status,
          )}`}
        >
          <Progress
            className="progressbar"
            value={this.getPercentValue(value)}
            color="primary"
          />
          <a
            href="#"
            className="bs_wizard_dot"
            onClick={() => onStepSelected && onStepSelected(name)}
          />
          <div className="bs_wizard_info text-center">{t(name)}</div>
        </Col>
      );
    });
  }

  render() {
    return <Row className="bs_wizard">{this.prepareSteps()}</Row>;
  }
}

QStepWizard.propTypes = {};

export default withTranslation('card')(QStepWizard);
