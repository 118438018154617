import React from 'react';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import Layout from '_Layouts/Layout';
import BenchmarkSubHeader from './_Components/BenchmarkSubHeader/BenchmarkSubHeader';
import BenchmarkV2 from './BenchmarkV2';

const idpage = 'benchmark';
const title = 'Benchmark';
const subHeader = <BenchmarkSubHeader />;
const body = <BenchmarkV2 />;

const action = props => {
  return (
    <QLoginRedirect>
      <Layout
        body={body}
        subHeader={subHeader}
        idpage={idpage}
        title={title}
        {...props}
      />
    </QLoginRedirect>
  );
};

export default action;
