import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get, max } from 'lodash';
import { grayShades, opacity, purple } from 'styles/abstracts/colors';
import { QemotionTheme } from '../../Utils/qemotionTheme';
import grabberSvg from '../../PoleCharts/CBarGraphChart/grabber_graph.svg';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

if (typeof document !== 'undefined') {
  const echarts = require('echarts');
  echarts.registerTheme('QemotionTheme', QemotionTheme);
}

const EmotionsTriggersChart = ({ graphData, t }) => {
  const [data, setData] = useState(graphData);
  useEffect(() => {
    setData(graphData);
  }, [graphData]);

  const getOptions = () => ({
    grid: { bottom: '17%' },
    tooltip: {
      show: true,
      trigger: 'item',
      triggerOn: 'click',
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      feature: {
        dataZoom: {
          title: {
            zoom: t('graph:Zoom'),
            back: t('graph:Zoom reset'),
          },
          // yAxisIndex: 'none',
          iconStyle: {
            borderColor: purple,
          },
        },
        dataView: {
          title: t('graph:View data'),
          lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
          readOnly: false,
          textColor: purple,
          buttonColor: purple,
          iconStyle: {
            borderColor: purple,
          },
        },
        restore: {
          title: t('graph:Reload'),
          iconStyle: {
            borderColor: purple,
          },
        },
        saveAsImage: {
          title: t('graph:Save as image'),
          name: t('graph:Graph Q°Emotion'),
          backgroundColor: '#FFFFFF',
          iconStyle: {
            borderColor: purple,
          },
        },
      },
      emphasis: {
        iconStyle: {
          borderWidth: 2,
          borderColor: purple,
          textBackgroundColor: purple,
          textFill: '#FFFFFF',
          textBorderRadius: [5, 5, 5, 5],
          textPadding: [6, 6, 4, 6],
        },
      },
    },
    xAxis: {
      boundaryGap: true,
      min: 0,
      max: parseInt(max(graphData.map(d => d.value[0])) + max(graphData.map(d => d.value[0])) / 10, 10),
      minInterval: 1,
      axisTick: {
        show: false,
      },
      axisLine: { show: false },
      splitLine: {
        show: false,
      },
      name: t('Number of comments'),
      nameLocation: 'center',
      nameGap: 28,
      nameTextStyle: {
        color: grayShades.g800,
        fontWeight: 'bold',
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      axisLabel: {
        formatter: '{value}%',
      },
      nameGap: 48,
      name: t('% of comments'),
      nameLocation: 'center',
      nameTextStyle: {
        color: grayShades.g800,
        fontWeight: 'bold',
      },
    },
    series: [
      {
        type: 'scatter',
        symbolSize: 15,
        data,
      },
    ],
    dataZoom: [
      {
        show: true,
        start: 0,
        showDataShadow: false,
        showDetail: false,
        labelPrecision: 10,
        backgroundColor: grayShades.g300,
        fillerColor: '#6f3ac7',
        dataBackgroundColor: 'transparent',
        height: 36,
        handleIcon: `image://${grabberSvg}`,
        handleSize: 24,
        handleStyle: {
          shadowColor: opacity.black,
          shadowBlur: 5,
        },
      },
    ],
  });

  const options = graphData && getOptions();

  const onDatazoom = data => {
    options.dataZoom[0].start = data.start;
    options.dataZoom[0].end = data.end;
  };

  const onEvents = () => {
    return {
      datazoom: onDatazoom,
    };
  };
  return (
    <div style={{ height: 'calc(100% - 4em)', width: '100%', margin: 0 }}>
      {ReactEcharts ? (
        <ReactEcharts
          option={options}
          style={{ height: '100%', width: '100%' }}
          className="react_for_echarts"
          theme="QemotionTheme"
          onEvents={onEvents()}
        />
      ) : (
        ' '
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  filterKeys: get(state, ['filters', 'filterKeys']),
});

export default connect(mapStateToProps)(EmotionsTriggersChart);
