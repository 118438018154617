import React from 'react';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import EmotionBreakdownDonutCardContain from './EmotionBreakdownDonutCardContain';

const EmotionBreakdownDonutCard = props => (
  <QcardPanel data={[true]} {...props} hints={[HintsContent.HINT_EMOTION_BREAKDOWN]}>
    <EmotionBreakdownDonutCardContain {...props} globalResultsMode />
  </QcardPanel>
);
export default EmotionBreakdownDonutCard;
