import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { allAxisColors } from '_Resources/Triggers/Services/helpers';
import Icon from '_Components/Icons/Icon';
import { withTranslation } from 'react-i18next';
import { capitalize } from '_Utils/strings/stringsUtils';
import { grayShades } from 'styles/abstracts/colors';
import TriggerItem from './TriggerItem';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 15rem;
`;

const ColumnTitle = styled.div<{ columnColor: string }>`
  display: flex;
  align-items: center;
  color: ${({ columnColor }) => columnColor};
  font-weight: bold;
  font-size: 1.2rem;
  height: 2rem;
  border-bottom: 3px solid ${({ columnColor }) => columnColor};
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
`;

const ItemListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${grayShades.g800};
`;

const TriggersColumn = ({ columnData, t }) => {
  const { color: columnColor, items } = columnData;

  const icon = allAxisColors[columnData.originalName]?.icon || null;

  return (
    <Column>
      <ColumnTitle columnColor={columnColor}>
        {icon && <Icon icon={icon} color={columnColor} size={24} />}
        <Title>{t(capitalize(columnData.name))}</Title>
      </ColumnTitle>
      {items && (
        <ItemListContainer>
          {items.map(item => (
            <TriggerItem item={item} />
          ))}
        </ItemListContainer>
      )}
    </Column>
  );
};

TriggersColumn.propTypes = {
  columnData: PropTypes.shape({
    color: PropTypes.string.isRequired,
    deleted: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        percentage: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    masked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    originalColor: PropTypes.string.isRequired,
    originalName: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

TriggersColumn.defaultProps = {};

export default withTranslation('card', 'bouton')(TriggersColumn);
