import QLoginRedirect from "../../_Components/Auth/QLoginRedirect";
import Layout from "../../_Layouts";
import React from "react";
import ContentMessage from "./ContentMessage";
import { useLocation } from 'react-router';

const titleIcon = 'LINE_CHART';
const action = props => {
    const { state } = useLocation();
    const body = (
      <ContentMessage mdContent={state.mdContent}/>
    );

    return <QLoginRedirect>
      <Layout
        body={body}
        title={'Message'}
        hideSubHeader={true}
        hideMentionsCount={true}
        titleIcon={titleIcon}
        {...props}
      />
    </QLoginRedirect>
  }
;
export default action;
