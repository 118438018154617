import gql from 'graphql-tag';

const getClusters = gql`
  query getClusters(
    $projectId: Int!
    $range: DateRangeInput!
    $verbatimsCountSort: Sort
  ) {
    getClusters(projectId: $projectId, range: $range, verbatimsCountSort: $verbatimsCountSort) {
      enchantements {
        id
        title
        size
        verbatimsCount
        hasParents
      }
      irritants {
        id
        title
        size
        verbatimsCount
        hasParents
      }
    }
  }
`;

export default getClusters;
