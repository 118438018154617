import gql from 'graphql-tag';

const query = gql`
  query getHeaderMentionCountData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $selectedEmotions: [Emotions!]
    $selectedClusters: [Cluster!]
    $keywords: [String!]
    $dataCollectorId: Int
  ) {
    data: getHeaderMentionCountData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      selectedEmotions: $selectedEmotions
      selectedClusters: $selectedClusters
      keywords: $keywords
      dataCollectorId: $dataCollectorId
    ) {
      totalMentionCountWithoutNull
      totalMentionCountWithNull
      currentMentionCountWithoutNull
      totalSentenceCountWithNull
    }
  }
`;

export default query;
