import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import QModal from '_Components/Modals/QModal/QModal';
import QButton from '_Components/Buttons/QButton/QButton';
import { MenuItemLabel } from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import {
  RadioButton,
  CheckboxContainer,
} from '_Charts/LineCharts/CLineChart/CLineChartSettings';
import EXPORT_ALERT_CONFIGURATION_QUERY from '_Cards/AlertsCards/AlertingCard/graphql/exportAlertConfiguration';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: bold;
`;

type ExportAlertModalProps = {
  alertConfigurationId: number;
  isOpen: boolean;
  open: any;
  client: any;
  setMessage: any;
  t: any;
};

const ExportAlertModal = ({
  alertConfigurationId = 0,
  isOpen,
  open,
  client,
  setMessage,
  t,
}: ExportAlertModalProps) => {
  const [chosenFormat, setChosenFormat] = useState<'csv' | 'xlsx'>('csv');

  const footer = (
    <div>
      <QButton
        onClick={async () => {
          const { data } = await client.query({
            query: EXPORT_ALERT_CONFIGURATION_QUERY,
            fetchPolicy: 'no-cache',
            variables: {
              fileExtension: chosenFormat,
              alertConfigurationId,
            },
          });
          setMessage && setMessage(data[Object.keys(data)[0]]);
          open(!isOpen);
        }}
      >
        {t('button:Export data')}
      </QButton>
    </div>
  );

  return (
    <QModal
      isOpen={isOpen}
      title="button:Export data"
      onClose={() => open(!isOpen)}
      size="md"
      footer={footer}
      config={{ mentions: false, date: false }}
    >
      <b>{t('card:Choose your file format')}</b>
      <br />
      <Container>
        <CheckboxContainer onClick={() => setChosenFormat('csv')}>
          <MenuItemLabel checked={chosenFormat === 'csv'} />
          CSV
        </CheckboxContainer>
        <CheckboxContainer onClick={() => setChosenFormat('xlsx')}>
          <MenuItemLabel checked={chosenFormat === 'xlsx'} />
          XLSX
        </CheckboxContainer>
      </Container>
    </QModal>
  );
};

export default withTranslation('card', ['button'])(
  withApollo(ExportAlertModal),
);
