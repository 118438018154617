import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from 'styles/abstracts/variables';

const StyledToggleButton = styled(ToggleButton)`
  min-width: 11em !important;
`;

type VarianceDropdownProps = {
  id: string;
  icon?: string;
  t: any;
  label: string;
  currentSetting?: { label: string; value: number | string } | null;
  itemToIterate: Array<{ label: string; value: number | string }>;
  callback: any;
};

const VarianceDropdown = ({
  id,
  icon = '',
  t,
  label,
  itemToIterate,
  callback,
  currentSetting = null,
}: VarianceDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<{
    label: string;
    value: number | string;
  }>(currentSetting || itemToIterate[0]);

  useEffect(() => {
    callback(currentItem.value);
  }, [currentItem]);

  return (
    <div id={id}>
      <Label>
        {icon && <Icon icon={icon} size={20} />} {t(label)}
      </Label>
      <StyledDropdown
        direction="down"
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
      >
        <StyledDropdownToggle>
          <StyledToggleButton disableHover bgColor="grayShades.g300">
            {currentItem && t(`button:${currentItem.label}`).length > 16
              ? `${t(`button:${currentItem.label}`).substring(0, 16)}(...)`
              : currentItem && t(`button:${currentItem.label}`)}
            <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
          </StyledToggleButton>
        </StyledDropdownToggle>
        <Menu className="dataByFiltersDropdownMenu">
          {itemToIterate.map(item => (
            <Item
              disabled={currentItem && item.value === currentItem.value}
              onClick={() => {
                setCurrentItem(item);
              }}
            >
              {t(`button:${item.label}`)}
            </Item>
          ))}
        </Menu>
      </StyledDropdown>
    </div>
  );
};

export default withTranslation('card', 'button')(VarianceDropdown);
