/* eslint-disable react/sort-comp */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import prepareCenterBasedGridGeneration, {
  AXIS,
} from './_Utils/calculation/grid';
import { VIEW_MODES } from '../../_Utils/modules';
import prepareGetHexagonCoords, {
  convertToCubeCoordinates,
} from './_Utils/calculation/hexagon';
import getPathPointForData, {
  findModuleTilePoint,
} from './_Utils/calculation/paths';
import { prepareZoomHandler } from './_Utils/calculation/zoom';
import { ExecutionScheduler } from '../../../../../../_Utils/ExecutionScheduler';
import { FACTORIES_TYPES, prepareRunFactories } from './_Utils/factories';

const getSize = element => {
  const width = element.width.baseVal.value;
  const height = element.height.baseVal.value;
  return {
    [`${AXIS.X}Length`]: width,
    [`${AXIS.Y}Length`]: height,
  };
};

const PATH_MODES_CONFIG = {
  [VIEW_MODES.MODULE]: 'triangular',
  [VIEW_MODES.ALL]: 'circular',
};
const GRID_WRAPPER_ELEMENT_TYPE = 'grid-wrapper';
const RADIUS = 80;
const pathResolver = prepareGetHexagonCoords(RADIUS);
const prepareCubeCoordinates = size => {
  const generateCenterGridBase = prepareCenterBasedGridGeneration({
    tileRadius: RADIUS * 1.1,
  });
  const axialGridPoints = generateCenterGridBase(size);
  return axialGridPoints.map(convertToCubeCoordinates);
};

const ANIMATION_INTERVAL = 1200;

export default class Grid extends PureComponent {
  static propTypes = {
    scale: PropTypes.number.isRequired,
    period: PropTypes.string.isRequired,
    indicator: PropTypes.string.isRequired,
    insights: PropTypes.arrayOf(PropTypes.object).isRequired,
    viewMode: PropTypes.oneOf(Object.values(VIEW_MODES)).isRequired, // TODO: it may be interpreted as lack of the module
    module: PropTypes.string.isRequired,

    onTileClicked: PropTypes.func.isRequired,

    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  initialized = false;

  zoomHandler = null;
  executionScheduler = new ExecutionScheduler({ interval: ANIMATION_INTERVAL });
  factoriesBaseConfig = null;

  // Points and coordinates
  gridPointsCoordinates = null;
  insightPoints = null;
  modulePoint = null;

  // Grid elements factories
  runFactories = null;

  componentDidMount() {
    setTimeout(() => {
      this.prepareGrid();
      this.calculatePoints();
      this.initialized = true;
      this.forceUpdate();
    }, 200);
  }

  onSvgMounted = ref => {
    this.svg = ref;
  };

  prepareGrid() {
    this.prepareGridWrapper();
    this.prepareBaseConfig();
    this.prepareZoomHandler();
    this.prepareRunFactories();
    this.initialized = true;
  }

  prepareGridWrapper() {
    const d3Svg = select(this.svg);
    d3Svg.append('g').attr('data-type', GRID_WRAPPER_ELEMENT_TYPE);
  }

  prepareBaseConfig() {
    const { onTileClicked, onTileHover, getId } = this.props;
    this.factoriesBaseConfig = {
      container: this.getGridWrapperNode(),
      pathResolver,
      onClick: onTileClicked,
      onMouseover: onTileHover,
      onMouseout: onTileHover,
      id: getId,
    };
  }

  getGridWrapperNode() {
    return select(this.svg)
      .selectAll(`g[data-type="${GRID_WRAPPER_ELEMENT_TYPE}"]`)
      .node();
  }

  prepareZoomHandler() {
    this.zoomHandler = prepareZoomHandler(this.getGridWrapperNode());
  }

  prepareRunFactories() {
    this.runFactories = prepareRunFactories(this.factoriesBaseConfig);
  }

  calculatePoints() {
    this.calculateGridPoints();
    this.calculateModulePoints();
    this.calculateInsightPoints();
  }

  calculateGridPoints() {
    const size = getSize(this.svg);
    this.gridPointsCoordinates = prepareCubeCoordinates(size);
  }

  calculateModulePoints() {
    this.modulePoint = findModuleTilePoint(this.gridPointsCoordinates);
  }

  calculateInsightPoints() {
    const { insights, viewMode } = this.props;
    this.insightPoints = getPathPointForData(
      this.gridPointsCoordinates,
      insights,
      PATH_MODES_CONFIG[viewMode],
    );
  }

  runElementsFactories(
    factories = [
      FACTORIES_TYPES.GRID_TILE,
      FACTORIES_TYPES.INSIGHT_TILE,
      FACTORIES_TYPES.MODULE_TILE,
    ],
  ) {
    const { indicator, period, viewMode, module } = this.props;
    this.runFactories(factories, {
      gridPoints: this.gridPointsCoordinates,
      insightsPoints: this.insightPoints,
      modulePoint: viewMode === VIEW_MODES.MODULE ? this.modulePoint : null,
      viewMode,
      indicator,
      period,
      module,
    });
  }

  adaptScale() {
    const { scale } = this.props;
    this.zoomHandler.zoom(scale);
  }

  render() {
    const { className } = this.props;
    if (this.initialized) {
      this.adaptScale();
      this.executionScheduler.schedule(() => {
        this.calculateInsightPoints();
        this.runElementsFactories();
      });
    }
    return (
      <svg
        id="test-svg"
        ref={this.onSvgMounted}
        className={className}
        style={{
          height: '100vh',
          width: '100%',
          cursor: 'grab',
          overflow: 'hidden',
        }}
      />
    );
  }
}
