import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { flattenDeep } from 'lodash';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import { grayShades, purple } from 'styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from 'styles/abstracts/variables';

const BackItem = styled(Item)`
  justify-content: flex-start !important;
  padding-left: 0.25em !important;
  color: ${grayShades.g800} !important;
`;

const StyledItem = styled(Item)`
  justify-content: flex-start !important;
`;

const SettingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em;
  font-weight: bold;
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 13rem;
`;

type BenchmarkHorizontalGraphOrderByDropdownProps = {
  icon: string;
  id: string;
  label: string;
  t: any;
  itemToIterate: Array<any>;
  setCurrentOrder: Function;
  defaultOrder: { id: string; label: string };
};

const BenchmarkHorizontalGraphOrderByDropdown = ({
  icon,
  id,
  label,
  t,
  itemToIterate,
  setCurrentOrder,
  defaultOrder,
}: BenchmarkHorizontalGraphOrderByDropdownProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const getCurrentItem = () => {
    if (itemToIterate[1].subcategories) {
      let allValues: any[] = [];
      flattenDeep(itemToIterate.map(item => item.subcategories)).map(item => {
        if (item?.subcategories) {
          allValues = [...allValues, ...item.subcategories];
        } else {
          allValues = [...allValues, item];
        }
      });
      return allValues.find(item => {
        return defaultOrder ? item.id === defaultOrder.id : itemToIterate[1].subcategories[1];
      });
    }
    return itemToIterate.find(item => item.id === defaultOrder?.id) || itemToIterate[1];
  };

  const getItem = () => {
    if (defaultOrder) {
      return defaultOrder;
    }
    return getCurrentItem();
  };

  const [currentItem, setCurrentItem] = useState(getItem());

  const [dropdownContent, setDropdownContent] = useState<any[] | null>(null);

  useEffect(() => {
    if ((currentItem && !currentItem.label) || !currentItem) {
      setCurrentItem(itemToIterate[0]);
    }
  }, [currentItem]);

  return (
    <SettingDiv id={id}>
      <Label>
        {icon && <Icon icon={icon} size={20} />} {t(label)}
      </Label>
      <StyledDropdown
        direction="down"
        isOpen={isDropdownOpen}
        toggle={() => {
          setDropdownOpen(!isDropdownOpen);
        }}
      >
        <StyledDropdownToggle>
          <StyledToggleButton disableHover bgColor="grayShades.g300">
            {currentItem && t(currentItem.label).length > 16
              ? `${t(currentItem.label).substring(0, 16)}(...)`
              : currentItem && t(currentItem.label)}
            <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
          </StyledToggleButton>
        </StyledDropdownToggle>
        <Menu className="dataByFiltersDropdownMenu">
          {!dropdownContent ? (
            itemToIterate.map(item => {
              return (
                <Item
                  disabled={currentItem && item.id === currentItem.id && item.label === currentItem.label}
                  toggle={!item.subcategories}
                  onClick={() => {
                    if (!item.subcategories) {
                      setCurrentItem(item);
                      setCurrentOrder(item);
                    } else {
                      setDropdownContent(item.subcategories);
                    }
                  }}
                >
                  {t(item.label)}
                  {item.subcategories && (
                    <Icon
                      icon="INTERFACE_CHEVRON_RIGHT"
                      size={ImageSize.medium}
                      color={
                        currentItem && item.id === currentItem.id && item.label === currentItem.label
                          ? grayShades.g800
                          : purple
                      }
                    />
                  )}
                </Item>
              );
            })
          ) : (
            <>
              <BackItem onClick={() => setDropdownContent(null)} toggle={false}>
                <Icon icon="INTERFACE_CHEVRON_LEFT" size={ImageSize.medium} color={grayShades.g800} />
                {t('Back')}
              </BackItem>
              {dropdownContent.map(item => (
                <StyledItem
                  alignLeft={!item.subcategories}
                  toggle={!item.subcategories}
                  disabled={currentItem && item.id === currentItem.id && currentItem.label === item.label}
                  onClick={() => {
                    if (!item.subcategories) {
                      setCurrentItem(item);
                      setCurrentOrder(item);
                    } else {
                      setDropdownContent(item.subcategories);
                    }
                  }}
                >
                  {item.subcategories ? (
                    <>
                      &nbsp;{t(item.label)}
                      <Icon
                        icon="INTERFACE_CHEVRON_RIGHT"
                        color={
                          (currentItem && item.id === currentItem.id && currentItem.label === item.label) ||
                          item.subcategories
                            ? grayShades.g800
                            : purple
                        }
                        size={ImageSize.medium}
                      />
                    </>
                  ) : (
                    <>
                      <Icon
                        icon={item.id.split('_')[1] === 'asc' ? 'INTERFACE_SORT_ASC' : 'INTERFACE_SORT_DESC'}
                        size={ImageSize.medium}
                        color={item?.id === currentItem?.id ? grayShades.g800 : purple}
                      />
                      &nbsp;{t(item.label)}
                    </>
                  )}
                </StyledItem>
              ))}
            </>
          )}
        </Menu>
      </StyledDropdown>
    </SettingDiv>
  );
};

export default withTranslation('button')(BenchmarkHorizontalGraphOrderByDropdown);
