import produce from 'immer';
import { CONTEXT, TEMPORARY_STORE_TO_WATCH } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';

export const replaceName = (state, { context, name }) => {
  switch (context) {
    case CONTEXT.COLUMN:
    case CONTEXT.CARD: {
      const storeToWatch = TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField;
      return produce(state, newState => {
        newState[storeToWatch].title = name;
      });
    }

    default: {
      return { ...state };
    }
  }
};
