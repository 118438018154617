import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Icon from '_Components/Icons/Icon';
import { grayShades } from 'styles/abstracts/colors';

const ProjectSelect = styled(Select)`
  width: 100%;
`;
class ListProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      options: null,
    };
  }

  getOptions(projectsList) {
    if (!projectsList) return;
    let listProject;
    if (this.props.excludeCurrentProject) {
      listProject = projectsList.filter(
        project => project.id !== this.props.currentProject.id,
      );
    } else {
      listProject = projectsList;
    }
    this.setState({
      options: listProject.map(project => ({
        value: project.id,
        label: project.name,
        state: project.state,
      })),
    });
  }

  componentDidMount() {
    if (this.props.projectsList) {
      this.getOptions(this.props.projectsList);
    }
    if (this.props.currentProject) {
      this.setState({
        selectedOption: {
          label: this.props.currentProject.name,
          value: this.props.currentProject.id,
        },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.projectsList) {
      this.getOptions(nextProps.projectsList);
    }
    if (nextProps.currentProject && !this.props.excludeCurrentProject) {
      this.setState({
        selectedOption: {
          label: nextProps.currentProject.name,
          value: nextProps.currentProject.id,
        },
      });
    }
    if (
      nextProps.futureProjectToSelect &&
      nextProps.futureProjectToSelect !== this.props.futureProjectToSelect
    ) {
      this.handleChange(nextProps.futureProjectToSelect);
    }
  }

  handleChange(project) {
    if (!project) return;
    this.setState({
      selectedOption: project,
    });
    this.props.callbackParent({ id: project.value, name: project.label });
  }

  render() {
    const selectStyles = {
      option: (provided, state) => {
        return {
          ...provided,
          cursor: !state.isDisabled && 'pointer',
          color: state.isDisabled && `${grayShades.g800} !important`,
          fontSize: '1.1em !important',
          margin: '.25em 0',
          '&:hover': {
            backgroundColor: !state.isDisabled && grayShades.g400,
            color: !state.isDisabled && 'black !important',
          },
        };
      },
      singleValue: (provided, state) => {

        return {
          ...provided,
          fontSize: '1.1em !important',
        };
      },
    };
    return (
      <ProjectSelect
        styles={selectStyles}
        name="form-field-name"
        value={this.state.selectedOption}
        className="selectInput"
        searchable
        clearable={false}
        onChange={value => this.handleChange(value)}
        options={this.state.options}
        isOptionDisabled={option => option.state === 'inaccessible'}
        arrowRenderer={() => (
          <Icon icon="INTERFACE_CHEVRON_DOWN" color="secondary" />
        )}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    );
  }
}
function mapStateToProps(state) {
  return {
    currentProject: state.projects.currentProject,
    futureProjectToSelect: state.projects.futureProjectToSelect,
    projectsList: state.projects.projectsList,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProject);
