import gql from 'graphql-tag';

const query = gql`
  query customerPortal {
    customerPortal {
      url
    }
  }
`;

export default query;
