import memoize from 'memoize-one';
import { uniqWith, isEqual, isObject, isArray, mergeWith } from 'lodash';

const mergeDeep = memoize((source, obj) =>
  mergeWith({}, obj, source, (objValue, srcValue) => {
    if (srcValue === null) {
      return objValue;
    }
    // deep merge
    if (isArray(objValue) && isArray(srcValue)) {
      return uniqWith([...srcValue, ...objValue], isEqual);
    } else if (isObject(objValue) && isObject(srcValue)) {
      return mergeDeep(srcValue, objValue);
    }
    return srcValue;
  }),
);

export default mergeDeep;
