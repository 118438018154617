import React from 'react';
import { Row, Col } from 'reactstrap';
import '_Cards/EIndexCards/EindexTemperatureCard/_Components/EindexBenchmark/style.scss';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';

class EindexBenchmark extends React.Component {
  render() {
    return (
      <div className="eIndexBenchmarkItem">
        <Row className="d-flex align-content-center align-items-center">
          <Col xs="auto">
            <EindexRond
              eindex={this.props.eindex}
              type="small"
              customCSS="rondEindex"
            />
          </Col>
          <Col className="eindexBenchmarkItemContainName">
            {this.props.name}
          </Col>
        </Row>
      </div>
    );
  }
}

export default EindexBenchmark;
