import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import CModal from '_Components/CModal/CModal';
import QModalSection from '_Components/Modals/QModalSection/QModalSection';
import QButton from '_Components/Buttons/QButton/QButton';
import { QSideBarLayout } from '_Components/Layouts/QSideBarLayout';
import { get } from 'lodash';
import { connect } from 'react-redux';
import ClustersToMergeSelect from './ClustersToMergeSelect';

const StyledQModalSection = styled(QModalSection)`
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin: 1em;
  }
`;

const StyledCModal = styled(CModal)`
  & .modal-content > *:first-child {
    padding-bottom: 0;
  }
`;

const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  margin: -16px;
`;

const QSideBarLayoutDisplayArea = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const StyledCancelMergeButton = styled(QButton)`
  width: 50%;
`;

const ClustersToMergeSelectModal = ({
  processToNameNewClusterButton,
  onMergeCanceled,
  t,
  isOpen,
  cluster,
}) => {
  const title = 'Merge this cluster';

  const subtitle = 'Are you sure you want to merge this cluster';

  const availableOptions =
    cluster.clickedClusterTone === 'irritant'
      ? cluster.clusters.irritants.filter(
          irritant => irritant.id !== cluster.clickedCluster.id,
        )
      : cluster.clusters.enchantements.filter(
          enchantement => enchantement.id !== cluster.clickedCluster.id,
        );

  const getFooter = () => {
    return (
      <>
        <StyledQModalSection>
          {processToNameNewClusterButton}
          <StyledCancelMergeButton outline onClick={onMergeCanceled}>
            {t('button:Back to cluster details')}
          </StyledCancelMergeButton>
        </StyledQModalSection>
      </>
    );
  };

  return (
    <StyledCModal
      size="xl"
      data={{}}
      title={t(title)}
      displayMentionsSubtitle={false}
      displayDateSubtitle={false}
      subtitleAdditionalContent={t(subtitle)}
      footer={getFooter()}
      modal={isOpen}
    >
      <Body>
        <HeaderArea />
        <QSideBarLayout>
          <QSideBarLayoutDisplayArea>
            <ClustersToMergeSelect options={availableOptions} t={t} />
          </QSideBarLayoutDisplayArea>
        </QSideBarLayout>
      </Body>
    </StyledCModal>
  );
};

const mapStateToProps = state => ({
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
  currentLang: get(state, ['auth', 'user', 'lang']),
  cluster: get(state, ['cluster']),
});

export default withTranslation(
  'card',
  'button',
)(connect(mapStateToProps)(ClustersToMergeSelectModal));
