import React from 'react';
import { Col } from 'reactstrap';
import '_Components/TabSelections/style.scss';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import { connect } from 'react-redux';
import { sendSelectionSelected } from '_Resources/Cards/Actions/sendSelectionSelected';
import { withTranslation } from 'react-i18next';
import { capitalize } from '_Utils/strings/stringsUtils';
import { clone } from '_Utils/other/clone';
import { selections } from '../../constants';

class TabSelections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 'tabSelections',
      filterSelections: selections.map(selection => selection.value),
      typeVertical: false,
    };
  }

  componentDidMount() {
    const { filterSelections, sendSelectionSelected } = this.props;
    this.setState({
      filterSelections: filterSelections || selections.map(selection => selection.value),
    });

    sendSelectionSelected(filterSelections || selections.map(selection => selection.value));
  }

  handleSelectionButtonClick(value) {
    const { sendSelectionSelected } = this.props;
    const { filterSelections } = this.state;

    let filterSelectionsClone = clone(filterSelections);
    if (value === 'all') {
      if (filterSelections.length === selections.length) {
        filterSelectionsClone = [];
      } else {
        filterSelectionsClone = selections.map(selection => selection.value);
      }
    } else if (filterSelections.includes(value)) {
      filterSelectionsClone = filterSelectionsClone.filter(selection => selection !== value);
    } else {
      filterSelectionsClone = filterSelectionsClone.concat(value);
    }
    this.setState({
      filterSelections: filterSelectionsClone,
    });
    sendSelectionSelected(filterSelectionsClone);
  }

  render() {
    const { t, typeVertical } = this.props;
    const { filterSelections, key } = this.state;
    const all = {
      name: 'All selections',
      value: 'all',
      color: '#592ea0',
      icon: 'PICTO_STAR',
    };
    const item = (selection, i, checkOnActive) => (
      <div
        key={key + i}
        className={`buttonSelectionFilter ${
          typeVertical ? 'buttonSelectionFilterVertical' : 'buttonSelectionFilterHorizontal'
        } ${!checkOnActive && 'unchecked'}`}
        onClick={() => this.handleSelectionButtonClick(selection.value)}
      >
        <IconCircle
          size={32}
          icon={selection.icon}
          active={checkOnActive}
          color={selection.color}
          customCSS={!typeVertical && 'iconCenter'}
        />
        <span
          className={`buttonSelectionText ${!typeVertical && 'buttonSelectionTextHorizontal'}`}
          style={!checkOnActive ? { color: '#A7ADC7' } : { color: selection.color }}
        >
          {capitalize(t(`button:${selection.name}`))}
        </span>
      </div>
    );

    return (
      <Col xs="auto" className={`${'border-right px-0'} ${!typeVertical && 'displayHorizontal'}`}>
        {item(all, 'all', filterSelections.length === selections.length)}
        {selections.map((selection, i) => item(selection, i, filterSelections.includes(selection.value)))}
      </Col>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendSelectionSelected: newState => {
      dispatch(sendSelectionSelected(newState));
    },
  };
}
export default connect(null, mapDispatchToProps)(withTranslation('emotion')(TabSelections));
