import { SELECT_SENTENCE } from '../Actions/selectSentenceDetails';
import { SET_SENTENCE_MENU_LEVEL } from '../Actions/setSentenceMenuLevel';
import { SET_SENTENCE_DROPDOWN_MENU_OPEN } from '../Actions/setSentenceDropdownMenuOpen';

const initialState = {
  clickedSentence: {
    id: '',
    sentence: '',
    emotion: '',
    belongingVerbatimId: 0,
  },
  menuLevel: 0,
  showDropdownMenu: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_SENTENCE: {
      return {
        ...state,
        clickedSentence: action.data.clickedSentence,
      };
    }
    case SET_SENTENCE_MENU_LEVEL: {
      return {
        ...state,
        menuLevel: action.data,
      };
    }
    case SET_SENTENCE_DROPDOWN_MENU_OPEN: {
      return {
        ...state,
        showDropdownMenu: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
