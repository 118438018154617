import gql from 'graphql-tag';

const mutation = gql`
  mutation deleteAlertConfiguration($id: Int!) {
    deleteAlertConfiguration(id: $id) {
      id
      errors
    }
  }
`;

export default mutation;
