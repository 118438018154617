function em(size) {
  return `${size}em`;
}
const baseSize = 1;
export const fontSizes = {
  default: em(baseSize),
  bigger: em(baseSize * 1.2)
};

export const radius = {
  default: em(0.5),
  round: em(1)
};
