import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { connect } from 'react-redux';
import { cloneDeep, findIndex } from 'lodash';
import CURRENT_USER_QUERY from '_Resources/Header/Services/api/graphql/currentUser';
import mutationSaveBenchmark from './graphql/saveBenchmark';
import getBenchmarkList from './graphql/getBenchmarkList';

export const updateQueryBenchmarkList = (projectId, settings) => (
  cache,
  { data: { saveSettings } },
) => {
  const benchmarkList = cloneDeep(
    cache.readQuery({
      query: getBenchmarkList,
      variables: { projectId },
    }).getBenchmarkList.projectBenchmarks,
  );
  let dataToReplace;
  const foundModelIndex = findIndex(benchmarkList, {
    id: saveSettings.id,
  });
  if (foundModelIndex === -1) {
    const saveBenchmarkExtended = saveSettings;
    saveBenchmarkExtended.settings = null;
    dataToReplace = benchmarkList.concat(saveBenchmarkExtended);
  } else {
    if (benchmarkList[foundModelIndex].isDefault !== saveSettings.isDefault) {
      const oldDefaultBenchmark = findIndex(benchmarkList, {
        isDefault: true,
      });
      benchmarkList[oldDefaultBenchmark] = {
        ...benchmarkList[oldDefaultBenchmark],
        isDefault: false,
      };
    }
    benchmarkList[foundModelIndex] = {
      ...benchmarkList[foundModelIndex],
      ...saveSettings,
      ...(settings && { settings }),
    };
    dataToReplace = benchmarkList;
  }
  cache.writeQuery({
    query: getBenchmarkList,
    variables: { projectId },
    data: { getBenchmarkList: { projectBenchmarks: dataToReplace } },
  });
  const { currentUser } = cache.readQuery({
    query: CURRENT_USER_QUERY,
  });
  currentUser.account.canCreateBenchmark = saveSettings.canCreateBenchmark;
  cache.writeQuery({
    query: CURRENT_USER_QUERY,
    data: { currentUser },
  });
};

class MutationSaveBenchmark extends React.Component {
  static propTypes = {
    currentProject: PropTypes.objectOf(PropTypes.any).isRequired,
    callBackAction: PropTypes.func,
    children: PropTypes.node.isRequired,
    variables: PropTypes.shape.isRequired,
  };

  static defaultProps = {
    callBackAction: () => null,
  };

  render() {
    const queryForUpdate = updateQueryBenchmarkList(
      this.props.currentProject.id,
      undefined,
    );
    return (
      <Mutation
        mutation={mutationSaveBenchmark}
        update={(cache, saveSettings) => queryForUpdate(cache, saveSettings)}
      >
        {saveSettings =>
          React.Children.map(this.props.children, child => {
            const propsToPass = {
              onClick: () => {
                saveSettings({
                  variables: { ...this.props.variables },
                });
                this.props.callBackAction();
              },
            };
            return React.cloneElement(child, propsToPass);
          })
        }
      </Mutation>
    );
  }
}

const mapStateToProps = state => ({
  currentProject: state.projects.currentProject,
});

export default connect(mapStateToProps)(MutationSaveBenchmark);
