import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useMutation } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { red } from 'styles/abstracts/colors';
import mutation from './graphql/userEmailValidation';

const EmailValidation = ({ location }) => {
  const [userEmailValidation] = useMutation(mutation);
  const [status, setStatus] = useState('');
  const [redirect, setRedirect] = useState(false);

  const params = queryString.parse(location?.search);
  const { token } = params;

  useEffect(() => {
    userEmailValidation({
      variables: { token },
    }).then(({ data }) => {
      if (data.userEmailValidation.errors?.[0] === 'Token expired') {
        setStatus('The validation link is expired. Please ask for a new link and try again.')
        setRedirect(true);
      } else {
        window.localStorage.setItem('openedSettings', 'plan-pricing');
        setStatus('Your email address has been validated.');
        setRedirect(true);
      }
    }).catch(err => {
      setStatus('The validation link is expired. Please ask for a new link and try again.')
      setRedirect(true);
    });
  }, []);

  return (
    redirect && (
      <Redirect
        to={{
          pathname: '/settings',
          state: {
            toastStatus: status,
            toastColor: status.includes('try again') && red,
          },
        }}
      />
    )
  );
};

export default EmailValidation;
