import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { white } from 'styles/abstracts/colors';
import { radius } from 'styles/abstracts/variables';

const Card = styled.div`
  background-color: ${white};
  ${({ hasShadow }) => hasShadow && 'box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)'};
  ${({ round }) => (round ? `border-radius: ${radius.round}` : `border-radius: ${radius.default}`)};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const QCard = ({ className, children, style, displayBackgroundCard, round, id }) => (
  <Card hasShadow={displayBackgroundCard} round={round} className={className} style={style} id={id}>
    {children}
  </Card>
);

QCard.propTypes = {
  displayBackgroundCard: PropTypes.bool,
  round: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.arrayOf(PropTypes.node),
  id: PropTypes.string,
};

QCard.defaultProps = {
  displayBackgroundCard: true,
  round: false,
  className: null,
  style: null,
  children: [],
  id: '',
};

export default QCard;
