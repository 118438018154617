import styled, { css } from 'styled-components';
import QButton from '_Components/Buttons/QButton/QButton';
import { red } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import { spacing } from 'styles/abstracts/variables';

export const FloatingActionButtonPosition = css`
  position: absolute !important;
  bottom: 3rem !important;
  right: 1.5rem !important;
`;
export const StyledPreviewButton = styled(QButton)`
  ${FloatingActionButtonPosition};
  font-size: ${fontSizes.default} !important;
  border: 2px solid ${red} !important;
  border-radius: 40px !important;
  padding: ${spacing.medium} ${spacing.large} !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
`;
export const Text = styled.span`
  padding-left: ${({ paddingSide }) =>
    paddingSide === 'LEFT' ? spacing.mediumLarge : 0};
  padding-right: ${({ paddingSide }) =>
    paddingSide === 'RIGHT' ? spacing.mediumLarge : 0};
`;
