/* eslint-disable import/prefer-default-export */
import { isNaN, orderBy } from 'lodash';
import insightAverageValues from './responseTransformations/insightAverageValues';
import insightDeltaValues from './responseTransformations/insightDeltaValues';
import insightFlatEntries from './responseTransformations/insightFlatEntries';

const transformations = [
  insightFlatEntries,
  insightAverageValues,
  insightDeltaValues,
];

export const processInsightData = (insights = []) =>
  insights.map(insight =>
    transformations.reduce(
      (result, transformation) => transformation(result),
      insight,
    ),
  );

// TODO: think about currying -> insights as the first argument
export const getSortedInsights = (insights, indicator, period) =>
  orderBy(
    insights,
    [
      insight => !!isNaN(insight.entryPoints[period][indicator].value),
      insight => insight.entryPoints[period][indicator].value,
      insight => insight.insightId,
    ],
    ['asc', 'desc', 'desc'],
  );
