import {
  purple,
  surprise,
  calm,
  fear,
  sadness,
  anger,
  disgust,
} from 'styles/abstracts/colors';
import { spacing } from 'styles/abstracts/variables';

export const BENCHMARK_COLS_COLORS = [
  purple,
  sadness,
  surprise,
  anger,
  fear,
  disgust,
  calm,
];

export const COLUMN_WIDTH = 296;
export const FIRST_COLUMN_ADDITIONNAL_WIDTH = spacing.xxlarge;

export const COLUMN_DEFAULT = {
  title: null,
  settings: {
    filters: {
      thematic: null,
      normal: null,
      related: null,
    },
    projectId: null,
    dateRange: {
      startDate: null,
      endDate: null,
    },
  },
  mergedSettings: {
    filters: {
      thematic: null,
      normal: null,
      related: null,
    },
    projectId: null,
    dateRange: {
      startDate: null,
      endDate: null,
    },
  },
};
