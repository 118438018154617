import queryZohoLogin from 'Routes/UtilityRoutes/Login/graphql/ssoZohoLogin';
import mutationSignUpToZoho from 'Routes/UtilityRoutes/Login/graphql/signUpToZoho';

export const connectToZoho = async client => {
  const {
    data: { ssoZohoLogin },
  } = await client.query({
    query: queryZohoLogin,
  });
  if (!ssoZohoLogin.hasSignedUpToZoho) {
    client
      .mutate({
        mutation: mutationSignUpToZoho,
      })
      .then(() => {
        window.location.href = encodeURI(
          `http://help.cxinsights.io/support/RemoteAuth?operation=${ssoZohoLogin.operation}&email=${ssoZohoLogin.email}&loginname=${ssoZohoLogin.loginName}&fullname=${ssoZohoLogin.fullName}&utype=portal&ts=${ssoZohoLogin.ts}&apikey=${ssoZohoLogin.apiKey}&redirect=1`,
        );
      });
  } else {
    window.location.href = encodeURI(
      `http://help.cxinsights.io/support/RemoteAuth?operation=${ssoZohoLogin.operation}&email=${ssoZohoLogin.email}&ts=${ssoZohoLogin.ts}&apikey=${ssoZohoLogin.apiKey}`,
    );
  }
};

const onLoginError = () => {};

export const onLoginCompleted = (
  signIn,
  dispatchStoreUserInfos,
  setToken,
  client,
  redirection,
) => {
  const { user, token, exp } = signIn || {};
  if (!user) {
    onLoginError();
  } else {
    dispatchStoreUserInfos(user, token);
    setToken(token);
    window.localStorage.setItem('LOGGED-IN', 'true');
    window.localStorage.setItem('userName', user.name);
    window.localStorage.setItem('firstName', user.firstName);
    window.localStorage.setItem('lastName', user.lastName);
    window.localStorage.setItem('lang', user.lang);
    window.localStorage.setItem('verbatimsLocale', user.verbatimsLocale);
    window.localStorage.setItem('userID', user.id);
    window.localStorage.setItem('userEmail', user.email);
    window.localStorage.setItem('userAccountId', user.account.id);
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('tokenExpiration', exp * 1000);
    window.localStorage.setItem('expireAt', user.expireAt);
    window.localStorage.setItem(
      'openedSettings',
      window.localStorage.getItem('openedSettings') || 'account-preferences',
    );
  }
  if (
    window.location.search &&
    window.location.search.includes('help.cxinsights.io')
  ) {
    if (user) {
      connectToZoho(client);
    }
  } else {
    window.localStorage.getItem('token') && redirection();
  }
};

export const passwordUpperCaseVerification = password =>
  password.length >= 1 && !password.match(/(?=.*[A-Z])/);

export const passwordDigitVerification = password =>
  password.length >= 1 && !password.match(/(?=.*\d)/);

export const passwordLengthVerification = password =>
  password.length >= 1 && password.length < 8;
