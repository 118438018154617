import React from 'react';
import LandingPageFilterModalFilterSwitcher from './LandingPageFilterModalFilterSwitcher';

const ModalFiltersPage = () => (
  <>
    <LandingPageFilterModalFilterSwitcher
    />
  </>
);

export default ModalFiltersPage;
