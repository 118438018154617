import React from 'react';
import styled from 'styled-components';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import Icon from '_Components/Icons/Icon';
import { grayShades } from 'styles/abstracts/colors';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: bold;
`;

const InfoContainer = styled.div`
  font-weight: bold;
  color: ${grayShades.g800};
  margin-left: 1.3em;
`;

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
`;

const DateSettings = ({ preferDate, setPreferDate, t }) => (
  <>
    <QTitle>{t('Date')}</QTitle>
    <Container>
      <QBooleanSwitch
        onChanged={() => {
          setPreferDate(!preferDate);
        }}
        value={preferDate}
      />
      {t(
        'Prefer the "Date of visit" field if provided (use the "comment date" if not)',
      )}
    </Container>
    <InfoContainer>
      <StyledIcon icon="INTERFACE_INFO" color={grayShades.g800} size={20} />
      {t(
        'The date is useful to display data on a Time dimension (ex evolution, date filtering)',
      )}
    </InfoContainer>
  </>
);

export default DateSettings;
