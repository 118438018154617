import React, { createContext } from 'react';
import { LayoutContext } from './LayoutContext';

export function loadComponent(component, type = 'component') {
  switch (component) {
    case 'EindexEvolutionWithMentionsCard':
      if (type === 'component') {
        return require('../../_Cards/EIndexCards/EindexEvolutionWithMentionsCard/EindexEvolutionWithMentionsCard')
          .default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 8,
            h: 9,
          },
          lg: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 8,
            h: 9,
          },
          md: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 8,
            h: 9,
          },
          sm: {
            minW: 2,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 9,
          },
          xs: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 9,
          },
        };
      }
      break;
    case 'EindexBadgeCard':
      if (type === 'component') {
        return require('../../_Cards/EIndexCards/EindexBadgeCard/EindexBadgeCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 2,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
          lg: {
            minW: 2,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
          md: {
            minW: 2,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
          sm: {
            minW: 2,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 2,
            h: 6,
          },
          xs: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
        };
      }
      break;
    case 'MentionBadgeCard':
      if (type === 'component') {
        return require('../../_Cards/MentionsCards/MentionBadgeCard/MentionBadgeCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 2,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
          lg: {
            minW: 2,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
          md: {
            minW: 2,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
          sm: {
            minW: 2,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 2,
            h: 6,
          },
          xs: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
        };
      }
      break;
    case 'SatisfactionNpsCard':
      if (type === 'component') {
        return require('../../_Cards/SatisfactionCards/SatisfactionNpsCard/SatisfactionNpsCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 2,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
          lg: {
            minW: 2,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
          md: {
            minW: 2,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
          sm: {
            minW: 2,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 2,
            h: 8,
          },
          xs: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
        };
      }
      break;
    case 'EindexTemperatureCard':
      if (type === 'component') {
        return require('../../_Cards/EIndexCards/EindexTemperatureCard/EindexTemperatureCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 3,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 6,
            h: 8,
          },
          lg: {
            minW: 3,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 6,
            h: 8,
          },
          md: {
            minW: 2,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
          sm: {
            minW: 2,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 2,
            h: 8,
          },
          xs: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
        };
      }
      break;
    case 'GaugeCard':
      if (type === 'component') {
        return require('../../_Cards/EmotionalKPIsCards/GaugeCard/GaugeCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 3,
            h: 8,
          },
          lg: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 3,
            h: 8,
          },
          md: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
          sm: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 2,
            h: 8,
          },
          xs: {
            minW: 4,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
        };
      }
      break;
    case 'SpeechEngagementCard':
      if (type === 'component') {
        return require('../../_Cards/EmotionalKPIsCards/SpeechEngagementCard/SpeechEngagementCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 3,
            h: 8,
          },
          lg: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 3,
            h: 8,
          },
          md: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
          sm: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 2,
            h: 8,
          },
          xs: {
            minW: 4,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
        };
      }
      break;
    case 'MentionRepartitionPerSourceCard':
      if (type === 'component') {
        return require('../../_Cards/MentionsCards/MentionRepartitionPerSourceCard/MentionRepartitionPerSourceCard')
          .default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 8,
            h: 9,
          },
          lg: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 8,
            h: 9,
          },
          md: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 8,
            h: 9,
          },
          sm: {
            minW: 2,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 9,
          },
          xs: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 9,
          },
        };
      }
      break;
    case 'EmotionBreakdownDonutCard':
      if (type === 'component') {
        return require('../../_Cards/EmotionsCards/EmotionBreakdownDonutCard/EmotionBreakdownDonutCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 6,
            h: 10,
          },
          lg: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 6,
            h: 10,
          },
          md: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 3,
            h: 10,
          },
          sm: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
          xs: {
            minW: 4,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 4,
            h: 8,
          },
        };
      }
      break;
    case 'EmotionEvolutionLineCard':
      if (type === 'component') {
        return require('_Cards/EmotionsCards/EmotionEvolutionLineCard/EmotionEvolutionLineCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 10,
            h: 10,
          },
          lg: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 10,
            h: 10,
          },
          md: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 5,
            h: 10,
          },
          sm: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 10,
          },
          xs: {
            minW: 4,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 4,
            h: 10,
          },
        };
      }
      break;
    case 'CategoryGlobalBestAndPainPointsCard':
      if (type === 'component') {
        return require('../../_Cards/ThematicsCard/CategoryGlobalBestAndPainPointsCard/CategoryGlobalBestAndPaintPointCardContainer')
          .default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 6,
            minH: 10,
            maxW: null,
            maxH: null,
            w: 8,
            h: 10,
          },
          lg: {
            minW: 6,
            minH: 11,
            maxW: null,
            maxH: null,
            w: 8,
            h: 11,
          },
          md: {
            minW: 6,
            minH: 10,
            maxW: null,
            maxH: null,
            w: 8,
            h: 10,
          },
          sm: {
            minW: 4,
            minH: 13,
            maxW: null,
            maxH: null,
            w: 4,
            h: 13,
          },
          xs: {
            minW: 4,
            minH: 16,
            maxW: null,
            maxH: null,
            w: 4,
            h: 16,
          },
        };
      }
      break;
    case 'VerbatimGlobalBestAndPainPointsCard':
      if (type === 'component') {
        return require('../../_Cards/VerbatimCards/VerbatimGlobalBestAndPainPointsCard/VerbatimGlobalBestAndPaintPointCardContainer')
          .default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 8,
            h: 20,
          },
          lg: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 8,
            h: 21,
          },
          md: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 8,
            h: 20,
          },
          sm: {
            minW: 2,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 4,
            h: 20,
          },
          xs: {
            minW: 4,
            minH: 4,
            maxW: null,
            maxH: null,
            w: 4,
            h: 20,
          },
        };
      }
      break;
    case 'WordCloudCard':
      if (type === 'component') {
        return require('../../_Cards/WordCards/WordCloudCard/WordCloudCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 2,
            minH: 9,
            maxW: null,
            maxH: null,
            w: 8,
            h: 10,
          },
          lg: {
            minW: 2,
            minH: 9,
            maxW: null,
            maxH: null,
            w: 8,
            h: 10,
          },
          md: {
            minW: 2,
            minH: 9,
            maxW: null,
            maxH: null,
            w: 8,
            h: 10,
          },
          sm: {
            minW: 2,
            minH: 9,
            maxW: null,
            maxH: null,
            w: 4,
            h: 10,
          },
          xs: {
            minW: 4,
            minH: 9,
            maxW: null,
            maxH: null,
            w: 4,
            h: 10,
          },
        };
      }
      break;
    case 'SatisfactionCard':
      if (type === 'component') {
        return require('../../_Cards/SatisfactionCards/SatisfactionCard/SatisfactionCard').default;
      }
      if (type === 'breakpoints') {
        return {};
      }
      break;
    case 'RadarAndHorizBarCard':
      if (type === 'component') {
        return require('../_Containers/SyntheticViewGeneric/_Containers/RadarAndHorizBarCard/RadarAndHorizBarCard')
          .default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 16,
            minH: 11,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          lg: {
            minW: 16,
            minH: 11,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          md: {
            minW: 8,
            minH: 11,
            maxW: null,
            maxH: null,
            w: 8,
            h: 21,
          },
          sm: {
            minW: 4,
            minH: 11,
            maxW: null,
            maxH: null,
            w: 4,
            h: 21,
          },
          xs: {
            minW: 4,
            minH: 11,
            maxW: null,
            maxH: null,
            w: 4,
            h: 21,
          },
        };
      }
      break;
    case 'MentionPreviewPerEmotions':
      if (type === 'component') {
        return require('../../_Cards/MentionsCards/MentionPreviewPerEmotions/MentionPreviewPerEmotionsCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          lg: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          md: {
            minW: 6,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 8,
            h: 12,
          },
          sm: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
          xs: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
        };
      }
      break;
    case 'MentionBreakdownCard':
      if (type === 'component') {
        return require('../../_Cards/MentionsCards/MentionBreakdownCard/MentionBreakdownCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 8,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 16,
            h: 6,
          },
          lg: {
            minW: 8,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 8,
            h: 6,
          },
          md: {
            minW: 6,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 8,
            h: 6,
          },
          sm: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
          xs: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 6,
          },
        };
      }
      break;
    case 'WorldMapSidePanelCard':
      if (type === 'component') {
        return require('../../_Cards/SideCards/SidePanelCard/SidePanelCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 8,
            h: 24,
          },
          lg: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 8,
            h: 24,
          },
          md: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 8,
            h: 24,
          },
          sm: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 24,
          },
          xs: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 24,
          },
        };
      }
      break;
    case 'CWorldMapViz':
      if (type === 'component') {
        return require('../Place/WorldMap/_Charts/CWorldMapVizSection').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 5,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 8,
            h: 24,
          },
          lg: {
            minW: 6,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 8,
            h: 24,
          },
          md: {
            minW: 3,
            minH: 8,
            maxW: null,
            maxH: null,
            w: 8,
            h: 16,
          },
          sm: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 16,
          },
          xs: {
            minW: 4,
            minH: 6,
            maxW: null,
            maxH: null,
            w: 4,
            h: 16,
          },
        };
      }
      break;
    case 'NoteWithLeftIconBadgeCard':
      if (type === 'component') {
        return require('../../_Cards/SatisfactionCards/NoteWithLeftIconBadgeCard/NoteWithLeftIconBadgeCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 4,
          },
          lg: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 4,
          },
          md: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 4,
          },
          sm: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 2,
          },
          xs: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 4,
          },
        };
      }
      break;
    case 'ProjectListCard':
      if (type === 'component') {
        return require('../Main/MainPage/_Components/ProjectListCard/ProjectListCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 8.75,
            w: 16,
          },
          lg: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 8.75,
            w: 16,
          },
          md: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 8.75,
            w: 8,
          },
          sm: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 8.75,
            w: 4,
          },
          xs: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 8.75,
            w: 4,
          },
        };
      }
      break;
    case 'TipsAndTricksCard':
      if (type === 'component') {
        return require('../Main/MainPage/_Components/TipsAndTricksCard/TipsAndTricksCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 4.75,
            w: 16,
          },
          lg: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 4.75,
            w: 16,
          },
          md: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 4.75,
            w: 8,
          },
          sm: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 4.75,
            w: 4,
          },
          xs: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 4.75,
            w: 4,
          },
        };
      }
      break;
    case 'EindexBarGraphCard':
      if (type === 'component') {
        return require('../../_Cards/EIndexCards/EindexBarGraphCard/EindexBarGraphCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          lg: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          md: {
            minW: 6,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 8,
            h: 12,
          },
          sm: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
          xs: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
        };
      }
      break;
    case 'AccountAndPreferencesCard':
      if (type === 'component') {
        return require('../../_Cards/SettingsCards/AccountAndPreferencesCard/AccountAndPreferencesCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          lg: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          md: {
            minW: 6,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 8,
            h: 12,
          },
          sm: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
          xs: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
        };
      }
      break;
    case 'ActiveProjectsCard':
      if (type === 'component') {
        return require('_Cards/SettingsCards/ProjectsCard/ProjectsCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          lg: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          md: {
            minW: 6,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 8,
            h: 12,
          },
          sm: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
          xs: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
        };
      }
      break;
    case 'DataByFiltersCard':
      if (type === 'component') {
        return require('../../_Cards/FilterCards/DataByFiltersCard/DataByFiltersCard').default;
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          lg: {
            minW: 8,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 16,
            h: 12,
          },
          md: {
            minW: 6,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 8,
            h: 12,
          },
          sm: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
          xs: {
            minW: 4,
            minH: 12,
            maxW: null,
            maxH: null,
            w: 4,
            h: 12,
          },
        };
      }
      break;
    default:
      if (type === 'component') {
        return require('../../_Components/QcardPanel/QcardPanel');
      }
      if (type === 'breakpoints') {
        return {
          xl: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 16,
          },
          lg: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 16,
          },
          md: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 12,
          },
          sm: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 8,
          },
          xs: {
            minH: null,
            minW: null,
            maxH: null,
            maxW: null,
            h: 6,
            w: 4,
          },
        };
      }
      break;
  }
}

export function getComponentsLayout(layout, propsData, propsGlobal, callback) {
  return layout.map(comp => {
    const Component = loadComponent(comp.component);
    const value = {
      title: comp.data && comp.data.title,
      titleSecondPart: (comp.data && comp.data.titleSecondPart) || propsGlobal.titleSecondPart,
      numberOfMentions: propsGlobal.numberOfMentions,
    };
    callback && callback(comp);
    return (
      <div key={comp.i}>
        <LayoutContext.Provider value={value}>
          <Component key={comp.i} {...comp.data} {...propsGlobal} {...propsData[comp.i]} />
        </LayoutContext.Provider>
      </div>
    );
  });
}
