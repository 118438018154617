import gql from 'graphql-tag';

const exportClusters = gql`
  mutation exportClusters($projectId: Int!, $range: DateRangeInput!) {
    exportClusters(projectId: $projectId, range: $range) {
      errors
      message
    }
  }
`;

export default exportClusters;
