import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import SubHeader from '../../../_Layouts/Header/_Containers/SubHeader/SubHeader';
import QTabs from '../../../_Components/Panels/QTabs/QTabs';
import QTab from '../../../_Components/Panels/QTabs/tab/QTab';
import { white } from '../../../styles/abstracts/colors';
import { setSpecificSettingsTab } from '../../../_Resources/SpecificFiltering/Actions/setSpecificCardThematicTab';

const StyledQTabs = styled(QTabs)`
  padding: 0;
  background-color: ${white};
`;
class SettingsSubHeader extends React.Component {
  static propTypes = {
    updateTabSettings: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      idpage: 'settings',
      selectedTab: window.localStorage.getItem('openedSettings'),
    };
    this.props.updateTabSettings({
      idPage: this.state.idpage,
      tab_id: this.state.selectedTab,
    });
  }

  onTabsSelected = tabId => {
    this.setState({ selectedTab: tabId });
    this.props.getTabId(tabId);
    window.localStorage.setItem('openedSettings', tabId);
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedTab !== prevState.selectedTab) {
      this.props.getTabId(this.state.selectedTab);
      this.props.updateTabSettings({
        idPage: this.state.idpage,
        tab_id: this.state.selectedTab,
      });
    }
    if (
      prevProps.isUserAdmin !== this.props.isUserAdmin &&
      !this.props.isUserAdmin &&
      (this.state.selectedTab === 'users' ||
        this.state.selectedTab === 'plan-pricing' ||
        (this.props.currentPlan === 'emotion_corporate' &&
          this.state.selectedTab === 'plan-pricing'))
    ) {
      this.setState({ selectedTab: 'account-preferences' });
    }
  }

  render() {
    const { t } = this.props;
    const SETTINGS_TABS = [
      { id: 'account-preferences', label: 'Account & preferences' },
      { id: 'projects', label: 'Projects' },
      this.props.isUserAdmin && { id: 'users', label: 'Users & permissions' },
      this.props.isUserAdmin &&
        this.props.currentPlan !== 'emotion_corporate' && {
          id: 'plan-pricing',
          label: 'Plan & pricing',
        },
    ];
    const SHContent = (
      <StyledQTabs
        initialPage={this.props.tabID}
        onTabChanged={this.onTabsSelected}
      >
        {SETTINGS_TABS.filter(tab => tab).map(tab => (
          <QTab id={tab?.id}>{t(tab?.label)}</QTab>
        ))}
      </StyledQTabs>
    );
    return <SubHeader allowCollapse={false} SHContent={SHContent} />;
  }
}
function mapDispatchToProps(dispatch) {
  return {
    updateTabSettings: newState => {
      dispatch(setSpecificSettingsTab(newState));
    },
  };
}

function mapStateToProps(state) {
  return {
    isUserAdmin: get(state, ['auth', 'user', 'isAdmin']),
    currentPlan: get(state, ['auth', 'user', 'account', 'planName']),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('card')(SettingsSubHeader));
