import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import ThematicsHorizontalGraph from '_Charts/PoleCharts/CBarGraphChart/ThematicsHorizontalGraph';
import { INDICATORS_TYPE } from '_Cards/FilterCards/DataByFiltersCard/FiltersSettings';
import DropdownsContainer from './_Containers/DropdownsContainer/DropdownsContainer';

const GraphContainer = styled.div`
  height: calc(100% - 1em);
`;

type ThematicsHorizontalChartCardProps = {
  data: any[];
  horizontalIndicatorsConfig: any;
  settings: any;
  setSettings: any;
  filtersToDisplay: Array<{ id: string; label: string }>;
  defaultSettings: any;
  orderByItems: any[];
  setCardFilters: any;
};

const ThematicsHorizontalChartCard = ({
  data,
  horizontalIndicatorsConfig,
  settings,
  setSettings,
  filtersToDisplay,
  defaultSettings,
  orderByItems,
  setCardFilters,
}: ThematicsHorizontalChartCardProps) => {
  const title = 'Waterfall view by category';

  const [orderedData, setOrderedData] = useState(data);
  const getDirection = (splitOrder = 'asc', dataToOrder) => {
    return splitOrder === 'asc' ? dataToOrder : [...dataToOrder].reverse();
  };
  const sortData = dataToOrder => {
    const settingsToApply = settings?.horizontalDataByThematics || defaultSettings;
    const [splitIndex, splitOrder] =
    settingsToApply && settingsToApply.orderBy && settingsToApply.orderBy.id
        ? settingsToApply.orderBy.id.split('_')
        : orderByItems[1].subcategories[0].id.split('_');
    const filteredData =
      splitIndex === 'hierarchy'
        ? getDirection(splitOrder, dataToOrder)
        : orderBy(
            dataToOrder,
            item => {
              if (item[splitIndex]) {
                const currentItem =
                  (settingsToApply.indicators[INDICATORS_TYPE.BAR].id &&
                    settingsToApply.indicators[INDICATORS_TYPE.BAR].id.split('.')[1]) ||
                  (settingsToApply.indicators[INDICATORS_TYPE.BADGE].id &&
                    settingsToApply.indicators[INDICATORS_TYPE.BADGE].id.split('.')[1]);
                if (item[splitIndex][currentItem]) {
                  return item[splitIndex][currentItem].delta;
                }
                if (item[splitIndex][currentItem] === null) {
                  return '';
                }
                return item[splitIndex] || 0;
              }
              const indicatorId =
                settingsToApply &&
                (settingsToApply.indicators[INDICATORS_TYPE.BAR].selected?.find(item => item?.includes(splitIndex)) ||
                  settingsToApply.indicators[INDICATORS_TYPE.BADGE].selected?.find(item => item?.includes(splitIndex)));
              if (indicatorId) {
                const [id, subId, subSubId] = indicatorId.split('.');
                return item[id]?.[subId]?.[subSubId];
              }
              return item[splitIndex];
            },
            [splitOrder],
          );
    setOrderedData(filteredData);
  };

  const updateData = () => {
    const settingsToApply = settings?.horizontalDataByThematics || defaultSettings;
    const filteredData = settingsToApply ? data.filter(item => item.filter === settingsToApply?.selectedFilter) : data;
    sortData(filteredData);
  };

  useEffect(() => {
    updateData();
  }, [defaultSettings, settings]);

  return (
    <QcardPanel title={title} hints={[]} dataByFiltersCard dropDownOptions={['EXPORT']}>
      <DropdownsContainer
        horizontalIndicatorsConfig={horizontalIndicatorsConfig}
        filtersToDisplay={filtersToDisplay}
        settings={settings}
        setSettings={setSettings}
        defaultSettings={defaultSettings}
        orderByItems={orderByItems}
      />
      <GraphContainer>
        <ThematicsHorizontalGraph
          data={orderedData}
          indicators={defaultSettings?.indicators}
          horizontalIndicatorsConfig={horizontalIndicatorsConfig}
          setCardFilters={setCardFilters}
        />
      </GraphContainer>
    </QcardPanel>
  );
};

export default withTranslation('card')(ThematicsHorizontalChartCard);
