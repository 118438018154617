import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { selectReportingMenuOption } from '_Resources/Reporting/Actions/selectReportingMenuOption';
import TabMenu from '../../../_Components/TabMenu';
import QSwitcher from '../../../../../../_Components/Buttons/QSwitcher/QSwitcher';
import Display from './Display';
import Filters from './Filters';
import DateRange from './DateRange';

class GlobalLayoutSettings extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dispatchSelectReportingMenuOption: PropTypes.func.isRequired,
  };

  content = {
    display: () => <Display />,
    'date-range': () => <DateRange />,
    filters: () => <Filters />,
  };

  state = {
    menuOption: 'display',
  };

  getSwitcherOptions() {
    const { t } = this.props;
    return [
      {
        id: 'display',
        label: t('Display'),
      },
      {
        id: 'date-range',
        label: t('Date range'),
      },
      {
        id: 'filters',
        label: t('Filters'),
      },
    ];
  }

  componentDidMount() {
    const { dispatchSelectReportingMenuOption } = this.props;
    const { menuOption } = this.state;
    dispatchSelectReportingMenuOption(menuOption);
  }

  componentWillUnmount() {
    const { dispatchSelectReportingMenuOption } = this.props;
    dispatchSelectReportingMenuOption(undefined);
  }

  onMenuOptionSelected = menuOption => {
    const { dispatchSelectReportingMenuOption } = this.props;
    this.setState({ menuOption });
    dispatchSelectReportingMenuOption(menuOption);
  };

  render() {
    const { menuOption } = this.state;
    const content = this.content[menuOption];
    return (
      <TabMenu>
        <TabMenu.Item>
          <QSwitcher
            selected={menuOption}
            options={this.getSwitcherOptions()}
            onOptionSelected={this.onMenuOptionSelected}
          />
        </TabMenu.Item>
        <TabMenu.Item>{content && content()}</TabMenu.Item>
      </TabMenu>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSelectReportingMenuOption: menuOption => {
      dispatch(selectReportingMenuOption(menuOption));
    },
  };
}

export default withTranslation('reporting')(
  connect(null, mapDispatchToProps)(GlobalLayoutSettings),
);
