import React from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { findIndex, get } from 'lodash';
import { spacing } from '../../../../../styles/abstracts/variables';
import queryReportingModels from '../graphql/getReportingModel';
import mutationSaveModels from '../graphql/saveReportingModel';
import QButton from '../../../../../_Components/Buttons/QButton/QButton';

const CreateButton = styled(QButton)`
  margin: 0 ${spacing.xsmall} 0 ${spacing.large} !important;
`;

class SaveReportingModel extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Mutation
        mutation={mutationSaveModels}
        update={(cache, { data: { saveReportingModel } }) => {
          const { projectId } = this.props.filterKeys;
          const reportingModelsList = cache.readQuery({
            query: queryReportingModels,
            variables: { projectId },
          }).getReportingModel;
          let dataToReplace;
          const foundModelIndex = findIndex(reportingModelsList, {
            id: saveReportingModel.id,
          });
          if (foundModelIndex === -1) {
            const model = { ...saveReportingModel, settings: null };
            dataToReplace = reportingModelsList.concat(model);
          } else {
            reportingModelsList[foundModelIndex] = {
              ...reportingModelsList[foundModelIndex],
              title: saveReportingModel.title,
            };
            dataToReplace = reportingModelsList;
          }
          cache.writeQuery({
            query: queryReportingModels,
            variables: { projectId },
            data: { getReportingModel: dataToReplace },
          });
        }}
      >
        {saveReportingModel => (
          <CreateButton
            onClick={() => {
              saveReportingModel({
                variables: {
                  title: this.props.templateName,
                  projectId: this.props.filterKeys.projectId,
                  ...(get(this.props.data, 'id') && {
                    reportingModelId: this.props.data.id,
                  }),
                },
              });
              this.props.submitTemplateName();
            }}
          >
            {t(`button:${this.props.button}`)}
          </CreateButton>
        )}
      </Mutation>
    );
  }
}

const mapStateToProps = state => ({
  filterKeys: state.filters.filterKeys,
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('button')(SaveReportingModel));
