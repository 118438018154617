import React from 'react';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import QTitle from '_Components/Texts/QTitle/QTitle';
import HistorySection from './_Components/HistorySection/HistorySection';
import ExtractionSection from './_Components/ExtractionSection/ExtractionSection';
import CommentsSection from './_Components/CommentsSection/CommentsSection';
import DataSettingsSection from './_Components/DataSettingsSection/DataSettingsSection';
import ProjectFiltersSection from './_Components/ProjectFiltersSection/ProjectFiltersSection';
import AdditionalInformationsSection from './_Components/AdditionalInformationsSection/AdditionalInformationsSection';

const GlobalInformations = ({ setActiveTab, data, t }) => (
  <>
    <QTitle>{t('Project information')}</QTitle>
    <HistorySection
      setActiveTab={setActiveTab}
      lastProcessionDate={data?.lastProcessedAt}
      creationDate={data?.createdAt}
      t={t}
    />
    <ExtractionSection period={data?.period} t={t} />
    <CommentsSection commentsData={data?.mentionCountDetails} t={t} />
    {data?.dataSettings && <DataSettingsSection setActiveTab={setActiveTab} />}
    <Row>
      <ProjectFiltersSection
        projectFilters={data?.projectFilters}
        xs={6}
        t={t}
      />
      <AdditionalInformationsSection
        informations={data?.projectInformations}
        xs={6}
        t={t}
      />
    </Row>
  </>
);

export default withTranslation('card', 'button')(GlobalInformations);
