import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { updateExportActive } from '../../../../../_Resources/Reporting/Actions/Export/updateExportActive';
import { exportTaskRunner } from '../tabs/Export/_Providers/transformations/exportTaskRunner';
import QTitle from '../../../../../_Components/Texts/QTitle/QTitle';
import { TEXT_TYPES } from '../../../../../_Components/Texts/textTypes';
import { TEXT_POSITIONS } from '../../../../../_Components/Texts/textPositions';
import LoaderIcon from './LoaderIcon';

const pulsate = keyframes`
  0% {
  -webkit-transform: scale(1);
  transform: scale(1);
}
  50% {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
  100% {
  -webkit-transform: scale(1);
  transform: scale(1);

}`;

const Curtain = styled.div`
  position: absolute;
  display: flex;

  align-items: center;
  justify-content: center;

  z-index: 1;

  width: 100%;
  height: 100%;
  background-color: #f2f4fa;
`;

const Notifier = styled.div`
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  width: 700px;
  height: 700px;
  border-radius: 350px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    top: 0;
    left: 0;
    background-color: white !important;
    animation: ${pulsate} 2s ease-in-out infinite both;
  }

  * {
    z-index: 1;
  }
`;

const StyledLoaderIcon = styled(LoaderIcon)`
  margin-bottom: 2em;
`;

class ReportingLoaderCurtain extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    pagesNumber: PropTypes.number.isRequired,
    exportCounter: PropTypes.number.isRequired,
    dispatchUpdateExportActive: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { dispatchUpdateExportActive } = this.props;
    setTimeout(() => {
      const task = exportTaskRunner.execute();
      if (task) {
        task.then(() => dispatchUpdateExportActive(false));
      }
    }, 3000);
  }

  render() {
    const { t, exportCounter, pagesNumber } = this.props;
    return (
      <Curtain>
        <Notifier>
          <StyledLoaderIcon />
          <QTitle type={TEXT_TYPES.HEADER} position={TEXT_POSITIONS.CENTER}>
            <QTitle.Title>{t('Generating your report...')}</QTitle.Title>
          </QTitle>
          <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.CENTER}>
            <QTitle.Subtitle>
              {t('The download will automatically start after generation...')}
            </QTitle.Subtitle>
            <QTitle.Subtitle>
              <span>
                {t('Page')} {exportCounter + 1} {t('of')} {pagesNumber}
              </span>
            </QTitle.Subtitle>
          </QTitle>
        </Notifier>
      </Curtain>
    );
  }
}

const mapStateToProps = ({
  reporting: { exportCounter, children, iterateByOptions },
}) => ({
  exportCounter,
  pagesNumber:
    children.length *
    (sum(
      iterateByOptions.map(({ option: { values = [] } }) => values.length),
    ) || 1),
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdateExportActive: exportActive =>
    dispatch(updateExportActive(exportActive)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(ReportingLoaderCurtain));
