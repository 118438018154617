import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';

import QSelect from '../../../../../../_Components/Fields/QSelect/QSelect';
import QFieldGroup from '../../../../../../_Components/Panels/QFieldGroup/QFieldGroup';
import { selectOption } from '../../../../../../_Resources/Reporting/Actions/Export/selectOption';
import QTitle from '../../../../../../_Components/Texts/QTitle/QTitle';
import { TEXT_TYPES } from '../../../../../../_Components/Texts/textTypes';
import { TEXT_POSITIONS } from '../../../../../../_Components/Texts/textPositions';
import FilterInput from '../../../_Components/FilterInput';
import FilterByOptionsProvider from './_Providers/FilterByOptionsProvider';
import { addIterateBy } from '../../../../../../_Resources/Reporting/Actions/Export/addIterateBy';
import QButton from '../../../../../../_Components/Buttons/QButton/QButton';
import { setPreviewMode } from '../../../../../../_Resources/Reporting/Actions/setPreviewMode';
import { removeIterateBy } from '../../../../../../_Resources/Reporting/Actions/Export/removeIterateBy';

const Cart = styled.div`
  border-top: 2px solid #edf0f7;
  background: white;
  padding: 1.5em;
`;

const Footer = styled.div`
  text-align: center;
  border-top: 2px solid #edf0f7;
  padding: 1.5em;
`;

class Export extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isFormatSection: PropTypes.bool,

    // dispatch props
    activatePreviewMode: PropTypes.func.isRequired,
    dispatchAddIterateBy: PropTypes.func.isRequired,
    dispatchSelectOption: PropTypes.func.isRequired,
    dispatchRemoveIterateBy: PropTypes.func.isRequired,

    // state props
    iterateByDate: PropTypes.string.isRequired,
    iterateType: PropTypes.string.isRequired,
    filterType: PropTypes.string.isRequired,
    iterateByOptions: PropTypes.any.isRequired,
  };

  static defaultProps = {
    isFormatSection: false,
  };

  optionsIteratedByDate = [
    {
      label: 'For each day',
      value: 'daily',
    },
    {
      label: 'For each month',
      value: 'monthly',
    },
    {
      label: 'For each year',
      value: 'yearly',
    },
  ];

  optionsIterateType = [
    {
      label: 'Combined',
      value: 'combined',
    },
    {
      label: 'Separated',
      value: 'separated',
    },
  ];

  optionsFilterType = [
    {
      label: 'PDF',
      value: 'pdf',
    },
    {
      label: 'CSV',
      value: 'csv',
    },
  ];

  onSelectChanged = (selectedItem, selectName) => {
    const { dispatchSelectOption } = this.props;
    const selectedValue = selectedItem && selectedItem.value;
    dispatchSelectOption({ selectedValue, selectName });
  };

  onIterateRemoved = iterateBy => {
    const { dispatchRemoveIterateBy } = this.props;
    dispatchRemoveIterateBy(iterateBy);
  };

  onIterateSelected = iterateBy => {
    const { dispatchAddIterateBy } = this.props;
    dispatchAddIterateBy(iterateBy);
  };

  render() {
    const {
      activatePreviewMode,
      iterateByDate,
      iterateType,
      iterateByOptions,
      filterType,
      isFormatSection,
      t,
    } = this.props;
    const selectConfig = {
      clearable: false,
    };
    return (
      <Fragment>
        <Cart>
          <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.LEFT}>
            <QTitle.Title>{t('Iterations')}</QTitle.Title>
          </QTitle>

          <QFieldGroup>
            <QFieldGroup.Label>{t('Iterate by filter')}</QFieldGroup.Label>
            <QFieldGroup.Control>
              <FilterByOptionsProvider
                render={options => (
                  <FilterInput
                    initialValues={iterateByOptions}
                    onRemoved={this.onIterateRemoved}
                    onSelected={this.onIterateSelected}
                    options={options}
                  />
                )}
              />
            </QFieldGroup.Control>
          </QFieldGroup>

          {/* <QFieldGroup> */}
          {/* <QFieldGroup.Label>{t('Iterate by date')}</QFieldGroup.Label> */}
          {/* <QFieldGroup.Control> */}
          {/* <QSelect */}
          {/* options={this.optionsIteratedByDate} */}
          {/* onChange={value => this.onSelectChanged(value, 'iterateByDate')} */}
          {/* value={iterateByDate} */}
          {/* config={selectConfig} */}
          {/* /> */}
          {/* </QFieldGroup.Control> */}
          {/* </QFieldGroup> */}

          <QFieldGroup>
            <QFieldGroup.Label>{t('Iterate type')}</QFieldGroup.Label>
            <QFieldGroup.Control>
              <QSelect
                options={this.optionsIterateType}
                onChange={value => this.onSelectChanged(value, 'iterateType')}
                value={iterateType}
                config={selectConfig}
              />
            </QFieldGroup.Control>
          </QFieldGroup>

          {isFormatSection && [
            <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.LEFT}>
              <QTitle.Title>{t('Export format')}</QTitle.Title>
            </QTitle>,
            <QFieldGroup>
              <QFieldGroup.Label>{t('Filter type')}</QFieldGroup.Label>
              <QFieldGroup.Control>
                <QSelect
                  options={this.optionsFilterType}
                  onChange={value => this.onSelectChanged(value, 'filterType')}
                  value={filterType}
                  config={selectConfig}
                />
              </QFieldGroup.Control>
            </QFieldGroup>,
          ]}
        </Cart>
        {/* <Footer> */}
        {/* <QButton color="secondary" onClick={activatePreviewMode}> */}
        {/* {t('Preview & export')} */}
        {/* </QButton> */}
        {/* </Footer> */}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchAddIterateBy: (...args) => dispatch(addIterateBy(...args)),
  dispatchSelectOption: (...args) => dispatch(selectOption(...args)),
  dispatchRemoveIterateBy: (...args) => dispatch(removeIterateBy(...args)),
  activatePreviewMode: () => dispatch(setPreviewMode(true)),
});

const mapStateToProps = ({
  reporting: {
    iterateByOptions,
    export: { iterateByDate, iterateType, filterType },
  },
}) => ({
  iterateByOptions,
  iterateByDate,
  iterateType,
  filterType,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(Export));
