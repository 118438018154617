import React, { useState, useEffect } from 'react';
import { flattenDeep, groupBy } from 'lodash';
import SourceConfigurationPanel from './_Components/SourceConfigurationPanel/SourceConfigurationPanel';

const SourcesConfiguration = ({ selectedSources, setSources, sources, t }) => (
  <SourcesConfigurationData
    selectedSources={selectedSources}
    setSources={setSources}
    sources={sources}
    t={t}
  />
);

const SourcesConfigurationData = ({
  selectedSources,
  setSources,
  sources,
  t,
}) => {
  const [sourcesToSend, setSourcesToSend] = useState(
    flattenDeep(
      Object.values(groupBy(sources, i => i.source)).map(s =>
        s.map((i, ind) => ({
          id: parseInt(`${i.source}${ind}`, 10),
          idSource: i.source,
          profile: i.profile,
          profileName: i.profileName,
          index: ind,
        })),
      ),
    ),
  );

  useEffect(() => {
    setSources(
      sourcesToSend
        .filter(s => (s.id || s.id === 0) && s.profile && s.profileName)
        .map(s => ({
          source: s.idSource,
          profile: s.profile,
          profileName: s.profileName,
        })),
    );
  }, [sourcesToSend]);

  return (
    <div>
      {selectedSources?.map(source => (
        <SourceConfigurationPanel
          source={source}
          sourcesToSend={sourcesToSend}
          setSourcesToSend={setSourcesToSend}
          t={t}
        />
      ))}
    </div>
  );
};

export default SourcesConfiguration;
