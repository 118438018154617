import React from 'react';
import styled from 'styled-components';
import QInput from '_Components/Fields/QInput/QInput';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import { fontSizes } from 'styles/abstracts/typography';

const EmailContainer = styled.div`
  margin-left: 1em;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  font-size: ${fontSizes.smaller};
  margin-top: 0.5em;
`;

const StyledQInput = styled(QInput)`
  margin-left: 1.5em;
  width: 30em;
`;

const ProjectNaming = ({ setName, name, sendEmail, checkSendEmail, t }) => {
  return (
    <div>
      <StyledQInput
        placeholder=""
        value={name}
        onChange={e => {
          setName(e.target.value);
        }}
        maxLength={25}
      />
      <br />
      <EmailContainer>
        <QCheckbox
          selected={sendEmail}
          onClick={() => checkSendEmail(!sendEmail)}
        />
        {t('Send me an email when processing is done!')}
      </EmailContainer>
    </div>
  );
};

export default ProjectNaming;
