import React from 'react';
import CQuery from '_Container/QQuery/CQuery';
import querySchema from '_Cards/EmotionalKPIsCards/SpeechEngagementCard/graphql/getSpeechEngagementCardData';
import CSpeechEngagementChart from '_Charts/KpisCharts/CSpeechEngagment/CSpeechEngagementChart';

const SpeechEngagementSectionView = ({ data }) => (
  <CSpeechEngagementChart value={data && data.speechEngagement.value} />
);

const SpeechEngagementSection = ({
  filters,
  noDataComponent,
  errorComponent,
}) => (
  <CQuery
    query={querySchema}
    filterKeys={filters}
    noDataComponent={noDataComponent}
    errorComponent={errorComponent}
  >
    <SpeechEngagementSectionView />
  </CQuery>
);
export default SpeechEngagementSection;
