import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import '_Charts/KpisCharts/KpiBadges/style.scss';
import { Col } from 'reactstrap';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import CQuery from '_Container/QQuery/CQuery';
import querySchema from 'Routes/_Containers/SyntheticViewGeneric/_Containers/RadarAndHorizBarCard/graphql/getRadarAndHorizBarCardData';
import ContainRowCardRadar from 'Routes/_Containers/SyntheticViewGeneric/_Containers/RadarAndHorizBarCard/ContainRowCardRadar';

const RadarAndHorizBarCard = props => (
  <QcardPanel
    {...props}
    hints={[HintsContent.HINT_SYNTHETIC_RADAR_BAR_THEMATIC]}
  >
    <CQuery query={querySchema} filters={props.filters}>
      <ContainRowCardRadar />
    </CQuery>
  </QcardPanel>
);

function mapStateToProps(state) {
  return {
    eindexUnitFahrenheit:
      state.projectConfiguration.unitsAndLocalesSettings.eindexUnitFahrenheit,
  };
}

export default connect(mapStateToProps)(RadarAndHorizBarCard);
