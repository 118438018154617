import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NestedInputTags } from '@qemotion_team/react-nested-input-tags';
import QTitle from '../../../../../_Components/Texts/QTitle/QTitle';
import { prepareGroupedToOptions } from '../../../../../_Container/QProjectFilters/Filters/QThematicFilter/groupedToOptions';

class ThematicsFilter extends Component {
  static propTypes = {
    values: PropTypes.arrayOf(PropTypes.any).isRequired,
    thematics: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    onRemovedNormalFilter: PropTypes.func.isRequired,
    onSelectedNormalFilter: PropTypes.func.isRequired,
  };

  groupedToOptions = null;

  constructor({ t }) {
    super();
    const groupedToOptions = prepareGroupedToOptions(t);
    this.groupedToOptions = memoizeOne(thematics =>
      groupedToOptions(thematics),
    );
  }

  render() {
    const { t, thematics, onRemoved, onSelected, values } = this.props;
    return (
      <div>
        <QTitle>
          <QTitle.Title>{t('reporting:Thematics')}</QTitle.Title>
        </QTitle>
        <NestedInputTags
          initialValues={values}
          categoryPrefix={t('in')}
          options={this.groupedToOptions(thematics)}
          onSelected={onSelected}
          onRemoved={onRemoved}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ filters }) => ({
  thematics: get(filters, 'projectFilters.thematics'),
});

export default connect(mapStateToProps)(
  withTranslation(['filters', 'reporting'])(ThematicsFilter),
);
