import moment from 'moment';
import { groupBy, identity } from 'lodash';

const convertDate = date => moment(date).format('LL');

export const prepareGetDateRangeSubtitle = (t, lang) => dateRange => {
  const { startDate, endDate } = dateRange;

  let dateString = '';

  dateString += `${t('card:From')} ${convertDate(startDate)} `;

  dateString += `${t('card:to')} ${convertDate(endDate)}`;

  return dateString;
};

const getNormalFiltersString = (filters = []) => {
  const grouped = groupBy(filters, 'filterCategory');
  const filtersStrings = Object.entries(grouped).map(
    ([key, options]) =>
      `${key} > ${options.map(({ option: { label } }) => label).join(' | ')}`,
  );
  return filtersStrings.join(', ');
};

const getThematicFiltersString = (filters = []) =>
  filters
    .map(({ option: { label, excluded }, parents }) => {
      const [, ...valueParents] = parents;
      const parts = excluded ? [...valueParents, `NOT ${label}`] : [...valueParents, label]
      return parts.join(' > ');
    })
    .join(', ')

const getFilters = ({
                      t,
                      header: { isFilters, isThematics },
                      filters: { normal, thematic },
                    }) => {
  const normalFilters =
    isFilters && normal ? getNormalFiltersString(normal) : '';
  const thematicFilters =
    isThematics && thematic ? getThematicFiltersString(thematic) : '';
  return [
    normalFilters && `${t('Filtered on: ')} ${normalFilters}`,
    thematicFilters && `${t('Thematics: ')} ${thematicFilters}`,
  ];
};

export const prepareGenerateSubtitles = (t, language) => {
  const getDateRangeSubtitle = prepareGetDateRangeSubtitle(t, language);
  return ({ dateRange, filters, header = {} }) => {
    if (header.isContextualSubtitle) {
      const dateSubtitle =
        dateRange && header.isDateRange ? getDateRangeSubtitle(dateRange) : '';

      return [
        dateSubtitle,
        ...(filters ? getFilters({ filters, header, t }) : []),
      ].filter(identity);
    }
    return [];
  };
};
