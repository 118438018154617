/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, isNaN } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import QueryGetInsightVerbatim from '_Resources/Insight/Services/api/QueryGetInsightVerbatim';
import CModal from '_Components/CModal/CModal';
import { InsightPropType } from '_Resources/Insight/Services/api/insight.propTypes';
import { QSideBarLayout } from '_Components/Layouts/QSideBarLayout';
import { spacing } from 'styles/abstracts/variables';
import { fontSizes } from 'styles/abstracts/typography';
import { grayShades } from 'styles/abstracts/colors';
import QTabs from '_Components/Panels/QTabs/QTabs';
import QTab from '_Components/Panels/QTabs/tab/QTab';
import { selectInsightDetails } from '_Resources/Insight/Actions/selectInsightDetails';
import DeleteInsightButton from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Containers/InsightDetailsModal/_Containers/DeleteInsightButton';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import Evidences from './_Containers/tabs/Evidences';
import Description from './_Containers/tabs/Description';
import SideBarCategory from './_Components/SideBarCategory';
import Statistics from './_Containers/tabs/Statistics';
import QButton from '_Components/Buttons/QButton/QButton';

const StyledCModal = styled(CModal)`
  // width: 1200px !important;s
  max-width: 1200px !important;
`;

const StyledQTabs = styled(QTabs)`
  margin-top: ${spacing.medium};
  background: #fff !important;
  padding: 0 ${spacing.mediumSmall};
  border-bottom: 1px solid ${grayShades.g300};
`;

const StyledQTab = styled(QTab)`
  padding: ${spacing.medium} ${spacing.small};
  font-size: ${fontSizes.smaller};
`;

const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Chart = styled.div`
  display: inline-block;
  position: relative;
`;

const Body = styled.div`
  margin: -16px;
`;

class InsightDetailModal extends Component {
  static propTypes = {
    dispatchSelectInsight: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    verbatim: PropTypes.arrayOf(PropTypes.object).isRequired,
    insight: InsightPropType.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    range: PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      enDate: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    selectedTab: 'evidences',
    changedDescription: null,
    eindexValue: NaN,
  };

  isStateAffected = false;

  getTitle() {
    const { insightId, insightName } = this.props.insight || {};
    const { isAdmin } = this.props.userInfos;
    if (isAdmin) {
      return `Insight ${insightId} - "${insightName}"`;
    }

    return `Insight - "${insightName}"`;
  }

  tabs = [
    {
      id: 'description',
      label: 'Description',
    },
    {
      id: 'evidences',
      label: 'Evidences',
    },
    // TODO : implement statistics since BE is ready
    // {
    //   id: 'statistics',
    //   label: 'Statistics',
    // },
  ];

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.getEindex(this.props) != prevState.eindexValue){
    //     this.setState({eindexValue : this.getEindex(this.props)})
    // }
    if (
      (!isNaN(InsightDetailModal.getEindex(this.props)) &&
        InsightDetailModal.getEindex(this.props) != prevState.eindexValue) ||
      prevProps.insight.entryPoints.current.mentions !==
        this.props.insight.entryPoints.current.mentions
    ) {
      this.setState({ eindexValue: InsightDetailModal.getEindex(this.props) });
    }
  }

  getDescriptionData() {
    const {
      insight: {
        description,
        editedOn,
        editedBy,
        createdOn,
        createdBy,
        insightName,
      },
    } = this.props;
    const { changedDescription } = this.state;
    return {
      text: changedDescription || description,
      name: insightName,
      lastEditionDate: editedOn,
      editedBy,
      creationDate: createdOn,
      createdBy,
    };
  }

  static getEindex(props) {
    const {
      insight: {
        entryPoints: {
          current: { eindex, mentions },
        },
      },
    } = props;
    return Math.round(eindex / mentions);
  }

  onTabsSelected = tabId => this.setState({ selectedTab: tabId });

  onStatusChange = () =>
    this.setState(({ isActive }) => ({ isActive: !isActive }));

  onModalClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  getFooter() {
    const { t } = this.props;
    return (
      <Fragment>
        <QButton onClick={this.onModalClose}>
          {t('Close')}
        </QButton>
      </Fragment>
    );
  }

  renderTabContent = () => {
    const { selectedTab } = this.state;
    const {
      verbatim,
      insight,
      projectId,
      range,
      totalProjectMentions,
    } = this.props;
    switch (selectedTab) {
      case 'description': {
        return (
          <Description
            descriptionData={this.getDescriptionData()}
            active={this.state.isActive}
            projectId={projectId}
            insightId={insight.insightId}
            range={range}
            dispatchSelectInsight={this.props.dispatchSelectInsight}
          />
        );
      }
      case 'evidences': {
        return verbatim ? (
          <Evidences
            verbatim={verbatim}
            mentionsNumber={totalProjectMentions}
            insightId={insight.insightId}
            insightName={insight.insightName}
          />
        ) : null;
      }
      case 'statistics': {
        return <Statistics />;
      }
      default: {
        return null;
      }
    }
  };

  render() {
    const { isOpen, range, insight, projectId, t } = this.props;
    const { insightId, modules, insightName } = insight;
    const { selectedTab, eindexValue } = this.state;
    const title = this.getTitle();
    // TODO: move to component did mount

    return (
      <>
        <QueryGetInsightVerbatim insightId={insightId} range={range} />
        <StyledCModal
          data={{}}
          title={title}
          // subtitleAdditionalContent="From June 2016  to December 2017"
          displayMentionsSubtitle={false}
          displayDateSubtitle={false}
          modal={isOpen}
          footer={this.getFooter()}
          toggle={this.onModalClose}
        >
          <Body>
            <HeaderArea>
              {!isNaN(eindexValue) && (
                <Chart>
                  <EindexRond eindex={eindexValue} />
                </Chart>
              )}
            </HeaderArea>
            <StyledQTabs
              onTabChanged={this.onTabsSelected}
              initialPage={selectedTab}
            >
              {this.tabs.map(tab => (
                <StyledQTab key={tab.id} id={tab.id}>
                  {t(tab.label)}
                </StyledQTab>
              ))}
            </StyledQTabs>
            <QSideBarLayout>
              <QSideBarLayout.DisplayArea>
                {this.renderTabContent()}
              </QSideBarLayout.DisplayArea>
              <QSideBarLayout.SideBar>
                {/* <SideBarCategory label={t('Module_plural')}> */}
                {/*  <ModuleSelector */}
                {/*    initialValues={modules} */}
                {/*    projectId={projectId} */}
                {/*    insightId={insightId} */}
                {/*    range={range} */}
                {/*    dispatchSelectInsight={this.props.dispatchSelectInsight} */}
                {/*  /> */}
                {/* </SideBarCategory> */}
                <SideBarCategory label={null}>
                  {/* <hr mt="0" /> */}
                  <DeleteInsightButton
                    insightId={insightId}
                    projectId={projectId}
                    range={range}
                    closeModal={this.onModalClose}
                  />
                </SideBarCategory>
              </QSideBarLayout.SideBar>
            </QSideBarLayout>
          </Body>
        </StyledCModal>
      </>
    );
  }
}

const mapStateToProps = ({
  filters,
  insight: { insightDetails, insightDetailsVerbatim },
  mentions: { totalSentenceCountWithNull },
  auth,
}) => ({
  verbatim: insightDetailsVerbatim,
  insight: insightDetails,
  projectId: get(filters, ['filterKeys', 'projectId']),
  range: get(filters, ['filterKeys', 'range']),
  totalProjectMentions: totalSentenceCountWithNull,
  userInfos: auth.user,
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectInsight: (...args) => dispatch(selectInsightDetails(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('button')(InsightDetailModal));
