import gql from 'graphql-tag';

const currentUser = gql`
  query currentUser {
    currentUser {
      id
      hasEmailValidated
      firstName
      lastName
      email
      isEindexUnitFahrenheit
      expireAt
      account {
        id
        companyName
        name
        apiToken
        planName
        insightAvailable
        canAccessBilling
        canCreateBenchmark
        canCreateReporting
        canCreateUser
        remainingCredits
        logo
      }
      isAdmin
      lang
      verbatimsLocale
      phoneNumber
      analysisAxis
    }
  }
`;

export default currentUser;
