import React from 'react';
import FiltersSwitcher from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersSwitcher';
import ModalPageTitle from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/ModalPageTitle';
import PropTypes from 'prop-types';

const ModalFiltersPage = ({
  stepTitle,
  columnCollapseContent,
  context,
  currentSettingsFilters,
}) => (
  <>
    <ModalPageTitle
      stepTitle={stepTitle}
      collapseContent={columnCollapseContent}
    />
    <FiltersSwitcher
      context={context}
      currentSettingsFilters={currentSettingsFilters}
    />
  </>
);

ModalFiltersPage.propTypes = {
  stepTitle: PropTypes.string.isRequired,
  columnCollapseContent: {
    collapseText: PropTypes.node,
    collapseImage: PropTypes.element,
  }.isRequired,
  context: PropTypes.string.isRequired,
  currentSettingsFilters: PropTypes.string.isRequired,
};

export default ModalFiltersPage;
