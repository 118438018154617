import gql from 'graphql-tag';

const query = gql`
  query getEindexPathCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $dataCollectorId: Int
  ) {
    getEindexPathCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dataCollectorId: $dataCollectorId
    ) {
      eindexItems: data {
        id
        label
        eindex
        mentionCount
      }
    }
  }
`;
export default query;
