import React from 'react';
import FiltersSwitcher from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersSwitcher.js';
import ModalPageTitle from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/ModalPageTitle.js';
import PropTypes from 'prop-types';

const ModalFiltersPage = ({ stepTitle, columnCollapseContent, context, currentSettingsFilters, edit }) => (
  <>
    <ModalPageTitle stepTitle={stepTitle} collapseContent={columnCollapseContent} />
    <FiltersSwitcher context={context} currentSettingsFilters={currentSettingsFilters} edit={edit} />
  </>
);

ModalFiltersPage.propTypes = {
  stepTitle: PropTypes.string.isRequired,
  columnCollapseContent: {
    collapseText: PropTypes.node,
    collapseImage: PropTypes.element,
  }.isRequired,
  context: PropTypes.string.isRequired,
  currentSettingsFilters: PropTypes.string.isRequired,
};

export default ModalFiltersPage;
