import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get, remove } from 'lodash';
import { setActiveTriggersTable } from '_Resources/Triggers/Actions/setActiveTriggersTable';
import MutationSaveTriggersTable from '_Resources/Triggers/Services/MutationSaveTriggersTable';
import queryGetEmotionalTriggersTableList from '_Resources/Triggers/Services/graphql/getEmotionalTriggersTableList';
import { spacing } from '../../../../../../../../../../../styles/abstracts/variables';
import { grayShades, lightBlue, white } from '../../../../../../../../../../../styles/abstracts/colors';
import QTitle from '../../../../../../../../../../../_Components/Texts/QTitle/QTitle';
import { fontSizes } from '../../../../../../../../../../../styles/abstracts/typography';
import TriggersTableOptions from './_Components/TriggersTableOptions';

const StyledRow = styled(Row)`
  padding: ${spacing.small} ${spacing.large} !important;
  border-top: 1px solid ${grayShades.g300};
  &:hover {
    background-color: ${grayShades.g200} !important;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledQTitle = styled(QTitle)`
  font-size: ${fontSizes.bigger};
  color: ${props => (props.active ? lightBlue : 'inherit')} !important;
  &:hover {
    cursor: pointer;
    color: ${lightBlue} !important;
  }
`;

const DefaultButton = styled.p`
  font-size: ${fontSizes.extraSmall};
  font-weight: bold;
  padding: ${spacing.xsmall} ${spacing.small};
  border: 1px solid ${props => (props.default ? lightBlue : grayShades.g800)};
  border-radius: 2em;
  background-color: ${props => props.default && lightBlue};
  color: ${props => (props.default ? white : grayShades.g800)};
  cursor: ${props => !props.default && `pointer`};
`;

const Description = styled.p`
  font-size: ${fontSizes.extraSmall};
  text-align: justify;
  color: ${grayShades.g800};
`;

class TriggersRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  handleMouseEnter() {
    this.setState({ isHovered: true });
  }

  handleMouseLeave() {
    this.setState({ isHovered: false });
  }

  setActiveTriggers(triggersTable) {
    const { dispatchSetActiveTriggersTable, toggleModal } = this.props;
    dispatchSetActiveTriggersTable(triggersTable);
    toggleModal();
  }

  render() {
    const { triggersTable, t, activeTriggersTableId, toggleModal, currentProject } = this.props;

    // console.log("triggersTable:", triggersTable);

    const {
      settings: triggersTableSettings,
      id: triggersTableId,
      title,
      description,
      customizations,
      data,
    } = triggersTable;
    const {
      analysisAxis,
      itemsToDisplay,
      calculationCriteria,
      significanceThreshold,
      range,
      thematics,
      criteriaKeys,
    } = triggersTableSettings;
    const { id: projectId } = currentProject;
    const mutationVars = {
      id: triggersTableId,
      projectId,
      title,
      description,
      analysisAxis,
      itemsToDisplay,
      calculationCriteria,
      significanceThreshold,
      range,
      thematics,
      criteriaKeys,
      customizations,
      data,
    };
    const { isHovered } = this.state;
    return (
      <StyledRow onMouseEnter={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()}>
        <Col xs={6}>
          <TitleDiv>
            <StyledQTitle
              active={activeTriggersTableId === triggersTable.id}
              onClick={() => this.setActiveTriggers(triggersTable)}
            >
              {triggersTable.title}
            </StyledQTitle>{' '}
            &nbsp;&nbsp;
            {triggersTable.isDefault ? (
              <DefaultButton default>{t('Default')}</DefaultButton>
            ) : (
              isHovered && (
                <MutationSaveTriggersTable
                  variables={{
                    ...mutationVars,
                    isDefault: true,
                  }}
                  update={(cache, { data: { saveEmotionalTriggersTable } }) => {
                    const triggersTableList = cache.readQuery({
                      query: queryGetEmotionalTriggersTableList,
                      variables: { projectId },
                    }).getEmotionalTriggersTableList.emotionalTriggersTables;
                    const activeTriggersTableToSetAsDefault = triggersTableList.find(
                      triggers => triggers.id === triggersTable.id,
                    );
                    activeTriggersTableToSetAsDefault.isDefault =
                      saveEmotionalTriggersTable.emotionalTriggersTable.isDefault;
                    cache.writeQuery({
                      query: queryGetEmotionalTriggersTableList,
                      data: {
                        getEmotionalTriggersTableList: {
                          emotionalTriggersTables: [
                            ...remove(triggersTableList, triggers => triggers.id !== triggersTable.id),
                            activeTriggersTableToSetAsDefault,
                          ],
                        },
                      },
                    });
                  }}
                >
                  <DefaultButton>{t('Set as default')}</DefaultButton>
                </MutationSaveTriggersTable>
              )
            )}
          </TitleDiv>
          <Description>{triggersTable.description}</Description>
        </Col>
        {isHovered && <TriggersTableOptions triggersTable={triggersTable} toggleModal={toggleModal} />}
      </StyledRow>
    );
  }
}

TriggersRow.propTypes = {
  t: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  activeTriggersTableId: PropTypes.number.isRequired,
  triggersTable: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    isDefault: PropTypes.bool.isRequired,
    createdBy: PropTypes.shape({
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    settings: PropTypes.shape({
      analysisAxis: PropTypes.string.isRequired,
      calculationCriteria: PropTypes.string.isRequired,
      itemsToDisplay: PropTypes.shape({
        name: PropTypes.string.isRequired,
        key: PropTypes.string,
      }).isRequired,
      significanceThreshold: PropTypes.number.isRequired,
      range: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
      }).isRequired,
      thematics: PropTypes.arrayOf(
        PropTypes.shape({
          thematic: PropTypes.string.isRequired,
          subThematic: PropTypes.string,
          subSubThematic: PropTypes.string,
        }),
      ).isRequired,
      criteriaKeys: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          values: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        originalColor: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            originalName: PropTypes.string.isRequired,
            percentage: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ).isRequired,
        value: PropTypes.number.isRequired,
        deleted: PropTypes.bool.isRequired,
        masked: PropTypes.bool.isRequired,
      }),
    ),
    customizations: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        originalColor: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            originalName: PropTypes.string.isRequired,
          }),
        ).isRequired,
        deleted: PropTypes.bool.isRequired,
        masked: PropTypes.bool.isRequired,
      }),
    ),
  }).isRequired,
  currentProject: PropTypes.shape({
    id: PropTypes.number.isRequired,
    demo: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }).isRequired,
  dispatchSetActiveTriggersTable: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    activeTriggersTableId: get(state, ['triggers', 'activeTriggersTable', 'id']),
    currentProject: get(state, ['projects', 'currentProject']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetActiveTriggersTable: newState => {
      dispatch(setActiveTriggersTable(newState));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('button')(TriggersRow));
