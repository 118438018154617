import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import { Label } from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import CategoryBreakdownDropdown from './_Components/CategoryBreakdownDropdown';

const SelectsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 3.5em;
  margin-right: 1em;
  align-items: flex-end;
  flex-wrap: wrap;
  & > div {
    margin-right: 0.5em;
  }

  & #test {
    margin-top: 0.5em;
  }
`;

const GroupedDropdownsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 2em;
  margin-left: 3em;
  & div {
    margin-right: 0.5em;
  }
`;

const Switcher = styled(QBooleanSwitch)`
  margin-top: 0.6em !important;
`;

const graphs = [
  {
    id: 'pie',
    label: 'Pie',
  },
  {
    id: 'donut',
    label: 'Donut',
  },
  {
    id: 'treemap',
    label: 'Treemap',
  },
  {
    id: 'sunburst',
    label: 'Sunburst',
  },
  {
    id: 'river',
    label: 'River',
  },
];

const thematicsToDisplay = [
  {
    id: 'thematics',
    label: 'Categories',
  },
  {
    id: 'subthematics',
    label: 'Subcategories',
  },
  {
    id: 'subsubthematics',
    label: 'Sub sub categories',
  },
];

const filtersToDisplay = [
  {
    id: 'mentionCount',
    label: 'Mentions',
  },
  {
    id: 'emotions',
    label: 'Emotions',
    children: [
      {
        id: 'happiness',
        label: 'Happiness',
      },
      {
        id: 'surprise',
        label: 'Surprise',
      },
      {
        id: 'calm',
        label: 'Calm',
      },
      {
        id: 'sadness',
        label: 'Sadness',
      },
      {
        id: 'fear',
        label: 'Fear',
      },
      {
        id: 'anger',
        label: 'Anger',
      },
      {
        id: 'disgust',
        label: 'Disgust',
      },
    ],
  },
];

type DropdownsContainerProps = {
  setGraphToDisplay: any;
  graphToDisplay: string;
  setLevel: any;
  setFilterToDisplay: any;
  setSubFilterToDisplay: any;
  onlySelectedCategories: boolean;
  setOnlySelectedCategories: any;
  t: any;
  settings: any;
  setSettings: any;
};

const DropdownsContainer = ({
  setGraphToDisplay,
  graphToDisplay,
  setLevel,
  setFilterToDisplay,
  setSubFilterToDisplay,
  onlySelectedCategories,
  setOnlySelectedCategories,
  t,
  settings,
  setSettings,
}: DropdownsContainerProps) => {
  return (
    <div>
      <SelectsContainer>
        <GroupedDropdownsContainer>
          <CategoryBreakdownDropdown
            id="graphToDisplayDropdown"
            label="Graph to display"
            setOptionToDisplay={setGraphToDisplay}
            options={graphs}
            setSubOptionToDisplay={null}
            t={t}
            setSettings={setSettings}
            settings={settings}
            settingsName="graphToDisplay"
          />
          <CategoryBreakdownDropdown
            id="filterToDisplayDropdown"
            label="Filter to display"
            setOptionToDisplay={setFilterToDisplay}
            setSubOptionToDisplay={setSubFilterToDisplay}
            options={filtersToDisplay}
            t={t}
            setSettings={setSettings}
            settings={settings}
            settingsName="filterToDisplay"
          />
        </GroupedDropdownsContainer>
        {graphToDisplay !== 'treemap' && graphToDisplay !== 'sunburst' && (
          <CategoryBreakdownDropdown
            id="orderByDropdownContainer"
            label="Level to display"
            setOptionToDisplay={setLevel}
            options={thematicsToDisplay}
            setSubOptionToDisplay={null}
            t={t}
            setSettings={setSettings}
            settingsName="levelToDisplay"
            settings={settings}
          />
        )}
        <div style={{ marginLeft: '0.5em' }}>
          <Label>{t('Selected categories')}</Label>
          <Switcher
            onChanged={() => {
              setOnlySelectedCategories(!onlySelectedCategories);
              setSettings({
                ...settings,
                categoryBreakdown: {
                  ...settings.categoryBreakdown,
                  onlySelectedCategories: !onlySelectedCategories,
                },
              });
            }}
            value={onlySelectedCategories}
            customMargin="0.6em 0"
          />
        </div>
      </SelectsContainer>
    </div>
  );
};

export default withTranslation('button')(DropdownsContainer);
