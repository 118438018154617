import React, { useEffect, useState, useRef } from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import styled from 'styled-components';
import GET_VERBATIM_LIST_DATA from '_Container/VerbatimList/graphql/getVerbatimListData';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { grayShades, black, white } from 'styles/abstracts/colors';
import moment from 'moment';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import { fontSizes } from 'styles/abstracts/typography';
import Loader from '_Components/Loader/OnLoad';
import VerbatimDetails from '_Components/QVerbatim/_Containers/VerbatimDetails/VerbatimDetails';
import VerbatimFiltersContainer from './VerbatimFiltersContainer';
import QButton from '_Components/Buttons/QButton/QButton';

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const VerbatimListContainer = styled.div<{ headerHeight: number; switchFilterContainerHeight: number }>`
  height: calc(100vh - 14.5em - ${({ headerHeight, switchFilterContainerHeight }) => (headerHeight + switchFilterContainerHeight)}px);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  transition-duration: 0.5s;
`;

const VerbatimContainer = styled.div<{ collapsedInfo: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ${({ collapsedInfo }) => (collapsedInfo ? '1.15' : '0.75')}em 0.5em;

  &:not(:first-child) {
    border-top: 1px solid ${grayShades.g500};
  }
`;

const VerbatimEindexContainer = styled.div`
  width: 7em;
  display: flex;
  padding-top: 1em;
  padding-left: 0.5em;
`;

const VerbatimBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const VerbatimDateContainer = styled.div`
  font-size: 0.7em;
  font-weight: bolder;
  color: ${grayShades.g800};
`;

const VerbatimTextcontainer = styled.p`
  font-size: 1em;
  font-weight: normal;
  color: ${black};
  padding-right: 1em;
  position: relative;
`;

const VerbatimDetailsBtnContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const VerbatimDetailsBtn = styled.span`
  border: 1px solid;
  border-radius: 20px;
  font-size: ${fontSizes.smallest};
  font-weight: bolder;
  padding: 0.5em;
  color: ${grayShades.g800};
  background-color: ${white};
  cursor: pointer;
`;

const LoaderContainer = styled.div`
  height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledCloseModalCrossButton = styled(QButton)`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  right: 1em;
  top: 1em;
  padding: 0 !important;
`;

type VerbatimListCardProps = {
  filters: any;
  emotions: Array<string> | null;
  collapsedInfo: boolean;
  headerHeight: number;
  t: any;
  i18n: any;
  projectId: number;
};

const VerbatimListCard = ({
  filters,
  emotions,
  collapsedInfo,
  headerHeight,
  t,
  i18n,
  projectId,
}: VerbatimListCardProps) => {
  const verbatimFilters = {
    ...filters,
    range: filters?.range,
    projectId: filters?.projectId,
    selectedEmotions: emotions,
  };

  const [getVerbatims, { data, loading }] = useLazyQuery(
    GET_VERBATIM_LIST_DATA,
    {
      variables: {
        ...verbatimFilters,
      },
    },
  );

  const {
    data: getUpdatedVerbatims,
    loading: loadingUpdatedVerbatims,
    refetch,
  } = useQuery(GET_VERBATIM_LIST_DATA, {
    variables: {
      ...verbatimFilters,
      projectId,
      selectedClusters: ['ENCHANTEMENT'],
    },
  });

  const [verbatimList, setVerbatimList] = useState(
    data?.getVerbatimListData?.verbatimList,
  );

  const [seeDetails, toggleSeeDetails] = useState(false);

  const [detailsBtnIndex, setDetailsBtnIndex] = useState(0);

  const [isModalOpen, toggleModal] = useState(false);

  const [verbatimId, setVerbatimId] = useState(0);

  const modalFilters = {
    id: verbatimId,
    type: 'VERBATIM',
  };

  const buttonRight = (
    <StyledCloseModalCrossButton
      bgColor="greyShades.g300"
      type="button"
      onClick={() => toggleModal(false)}
      size="small"
      icon="CROSS"
      iconSize={24}
    />
  );

  useEffect(() => {
    setVerbatimList(data?.getVerbatimListData?.verbatimList);
  }, [data]);

  useEffect(() => {
    refetch({ ...verbatimFilters, projectId });
  }, [projectId]);

  useEffect(() => {
    setVerbatimList(getUpdatedVerbatims?.getVerbatimListData?.verbatimList);
  }, [getUpdatedVerbatims]);


  const [
    switchFilterContainerHeight,
    setSwitchFilterContainerHeight,
  ] = useState(0);

  const switchFilterContainer = useRef(null);

  const handleElementResized = () => {
    if(switchFilterContainer.current.offsetHeight !== switchFilterContainerHeight) {
      setSwitchFilterContainerHeight(switchFilterContainer.current.offsetHeight);
    }
  };

  const resizeObserver = new ResizeObserver(handleElementResized);

  useEffect(() => {
    resizeObserver.observe(switchFilterContainer.current);
    return function cleanup() {
      resizeObserver.disconnect();
    };
  });

  return (
    <>
      <FiltersContainer ref={switchFilterContainer}>
        <VerbatimFiltersContainer
          getVerbatims={getVerbatims}
          verbatimFilters={verbatimFilters}
          selectedSwitchFilters={['ENCHANTEMENT']}
          projectId={projectId}
        />
      </FiltersContainer>
      <VerbatimListContainer
        className="verbatimListContainer"
        headerHeight={headerHeight}
        switchFilterContainerHeight={switchFilterContainerHeight}
      >
        {(loading || loadingUpdatedVerbatims) && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {verbatimList &&
          verbatimList.map((verbatim: any, index: number) => {
            const date = moment(
              verbatim?.informations?.date || '2020-01-01T00:00:00+00:00',
            )
              .locale(i18n.language)
              .format('LL');
            return (
              <VerbatimContainer
                collapsedInfo={collapsedInfo}
                onMouseEnter={() => {
                  toggleSeeDetails(true);
                  setDetailsBtnIndex(index);
                }}
                onMouseLeave={() => toggleSeeDetails(false)}
                key={verbatim.id}
              >
                <VerbatimEindexContainer>
                  <EindexRond
                    eindex={verbatim?.kpis?.eindex?.value || 0}
                    type="small"
                  />
                </VerbatimEindexContainer>
                <VerbatimBodyContainer>
                  <VerbatimDateContainer>{date}</VerbatimDateContainer>
                  <VerbatimTextcontainer>
                    {verbatim.verbatim.verbatimFullText}{' '}
                    {seeDetails && detailsBtnIndex === index && (
                      <VerbatimDetailsBtnContainer>
                        <VerbatimDetailsBtn
                          onClick={() => {
                            toggleModal(!isModalOpen);
                            setVerbatimId(verbatim.id);
                          }}
                        >
                          {t('button:See details')}
                        </VerbatimDetailsBtn>
                      </VerbatimDetailsBtnContainer>
                    )}
                  </VerbatimTextcontainer>
                </VerbatimBodyContainer>
              </VerbatimContainer>
            );
          })}
      </VerbatimListContainer>
      {isModalOpen && (
        <VerbatimDetails
          isOpen={isModalOpen}
          toggle={toggleModal}
          modalFilters={modalFilters}
          buttonRight={buttonRight}
          verbatimEmotionsColors
          hideCollapse
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  collapsedInfo: get(state, ['projectConfiguration', 'collapsedInfo']),
});

export default withTranslation('card')(
  connect(mapStateToProps)(VerbatimListCard),
);
