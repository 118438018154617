import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import QMapPanel from "../components/QMapPanel";
import { radius } from "../styles/commonProperties";

const MIN_ZOOM = 3;
const MAX_ZOOM = 18;

const MapZoomPanel = styled(QMapPanel)`
  padding: 0;
  color: #928fb0;
`;
const secondaryColor = "#eaeef6";
const ZoomButton = styled.button`
  margin: 0;
  background-color: white;
  border: none;
  border-bottom: ${({ upper }) => upper && `solid 1px ${secondaryColor}`};
  border-top-left-radius: ${({ upper }) => upper && radius.default};
  border-top-right-radius: ${({ upper }) => upper && radius.default};
  border-bottom-left-radius: ${({ lower }) => lower && radius.default};
  border-bottom-right-radius: ${({ lower }) => lower && radius.default};
  color: inherit;
  padding: 0.2em 0.5em;
  text-align: center;
  font-size: 1.5em;
  cursor: pointer;

  &:hover {
    background-color: ${secondaryColor};
  }
`;

export default class ZoomControl extends PureComponent {
  // TODO:

  onZoomIn = () => {
    const { onZoomUpdate, zoom, maxZoom = MAX_ZOOM } = this.props;
    if (zoom < maxZoom) {
      onZoomUpdate(zoom + 1);
    }
  };

  onZoomOut = () => {
    const { onZoomUpdate, zoom, minZoom = MIN_ZOOM } = this.props;
    if (zoom > minZoom) {
      onZoomUpdate(zoom - 1);
    }
  };

  render() {
    return (
      <MapZoomPanel className={this.props.className}>
        <ZoomButton upper onClick={this.onZoomIn}>
          <span>+</span>
        </ZoomButton>
        <ZoomButton lower onClick={this.onZoomOut}>
          <span>-</span>
        </ZoomButton>
      </MapZoomPanel>
    );
  }
}

ZoomControl.propTypes = {
  zoom: PropTypes.number.isRequired,
  onZoomUpdate: PropTypes.func.isRequired,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number
};
