import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import refineByPropTypes from './RefineBy.propTypes';
import Icon from '_Components/Icons/Icon';
import { happiness, sadness } from '../../../../styles/abstracts/colors';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const RefineByEindexBody = styled.div`
  display: flex;
  align-items: center;
`;

const IconBox = styled.div`
  margin: 1em;
`;

class RefineByEindex extends Component {
  static propTypes = {
    ...refineByPropTypes,
  };

  marks = {
    '-20': '-20°',
    '-10': '-10°',
    '0': '0°',
    '10': '10°',
    '20': '20°',
    '30': '30°',
    '40': '40°',
  };

  render() {
    const { value, onChange } = this.props;
    return (
      <RefineByEindexBody>
        <IconBox>
          <Icon icon="EMOTION_SADNESS" color={sadness} size={24} />
        </IconBox>
        <Range
          min={-20}
          max={40}
          tipFormatter={value => `${value}%`}
          marks={this.marks}
          trackStyle={[{ backgroundColor: '#592EA0' }]}
          handleStyle={[
            {
              transform: 'translateY(-3px) translateX(-3px)',
              backgroundColor: '#592EA0',
              borderColor: 'white',
              width: '20px',
              height: '20px',
            },
          ]}
          activeDotStyle={{
            backgroundColor: '#592EA0',
            borderColor: '#592EA0',
          }}
          value={value || [-20, 40]}
          onChange={onChange}
        />
        <IconBox>
          <Icon icon="EMOTION_HAPPINESS" color={happiness} size={24} />
        </IconBox>
      </RefineByEindexBody>
    );
  }
}

export default RefineByEindex;
