import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Icon from '_Components/Icons/Icon';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { capitalize } from '_Utils/strings/stringsUtils';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { get } from 'lodash';
import { setCurrentPeriod } from '_Resources/Global/Actions/setCurrentPeriod';
import { grayShades, white, black } from 'styles/abstracts/colors';

const LatestBatchIndicatorContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-weight: bolder;
  color: ${black};
  background-color: ${grayShades.g300};
  height: 2.5em;
  padding: 0.59375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1.25rem;
  margin: auto 0 1em 2em;
`;

const LatestBatchIndicatorSpan = styled.span`
  margin: 0px 0.5em;
`;

const LatestBatchIndicatorCloseBtn = styled.button`
  border: 0;
  margin: 0 -0.5em 0 0.2em;
  cursor: pointer;
  color: ${black};
  font-weight: bolder;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-text: center;
  padding: 0;
  transition: 0.2s;
  background-color: ${grayShades.g300};

  &:focus {
    outline: 0;
    background-color: ${white};
  }

  &:hover {
    background-color: ${white};
  }
`;

type LatestBatchIndicatorProps = {
  dataCollectorId: number;
  filters: any;
  rangeAll: {
    startDate: Date;
    endDate: Date;
  };
  t: any;
};

const handleOnClick = (filters, dispatch, rangeAll) => {
  dispatch(
    setGlobalQueryFilterKeys({
      ...filters,
      range: rangeAll,
      dataCollectorId: null,
    }),
  );
  dispatch(
    setCurrentPeriod({
      startDate: rangeAll.startDate,
      endDate: rangeAll.endDate,
    }),
  );
};

const LatestBatchIndicator = ({
  dataCollectorId,
  filters,
  rangeAll,
  t,
}: LatestBatchIndicatorProps) => {
  const dispatch = useDispatch();
  return (
    <>
      {dataCollectorId && (
        <LatestBatchIndicatorContainer>
          <LatestBatchIndicatorSpan>
            {t(`button:${capitalize('latest batch')}`)}
          </LatestBatchIndicatorSpan>
          <LatestBatchIndicatorCloseBtn onClick={() => handleOnClick(filters, dispatch, rangeAll)}>
            <Icon icon="INTERFACE_CROSS" size={24} color={black} />
          </LatestBatchIndicatorCloseBtn>
        </LatestBatchIndicatorContainer>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  dataCollectorId: get(state, ['filters', 'filterKeys', 'dataCollectorId']),
  filters: get(state, ['filters', 'filterKeys']),
  rangeAll: get(state, ['periods', 'projectPeriods', 'byAll', 'period']),
});

export default withTranslation('button')(
  connect(mapStateToProps)(LatestBatchIndicator),
);
