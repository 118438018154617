import gql from 'graphql-tag';
import FRAGMENTS from '_Resources/Cards/Services/GraphQl/FRAGMENTS';

const query = gql`
  query getEmotionBreakdownDonutCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $dataCollectorId: Int
  ) {
    getEmotionBreakdownDonutCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dataCollectorId: $dataCollectorId
    ) {
      emotions {
        ...EmotionsBreakdownFragment
      }
    }
  }
  ${FRAGMENTS.emotionsBreakdown}
`;
export default query;
