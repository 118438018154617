import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import NoDataMessage from '../../_Components/NoDataMessage/NoDataMessage';
import Loader from '../../_Components/Loader/OnLoad';

class CQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getFilters();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.filters && !isEqual(this.props.filters, prevProps.filters)) ||
      (this.props.filterKeys &&
        !isEqual(this.props.filterKeys, prevProps.filterKeys))
    ) {
      this.getFilters();
    }
  }

  getFilters() {
    const { filters, filterKeys } = this.props;
    let updatedFilterKeys = filterKeys;
    if (filterKeys && filterKeys.texts) {
      filterKeys.texts.map(text => {
        updatedFilterKeys = { ...updatedFilterKeys, [text.name]: text.values };
      });
      delete updatedFilterKeys.texts;
    }
    this.setQueryVariables(filters || updatedFilterKeys);
  }

  setQueryVariables(variablesForQuery) {
    if (!variablesForQuery) {
      return;
    }
    if (variablesForQuery.projectId && variablesForQuery.range) {
      this.setState({ variablesForQuery });
    }
  }

  render() {
    const {
      query,
      loadingComponent,
      errorComponent,
      noDataComponent,
    } = this.props;
    return (
      <Query
        query={`
          ${query}
        `}
        variables={this.state.variablesForQuery}
        {...this.props}
      >
        {({
          loading,
          error,
          data,
          ready,
          refetch,
          networkStatus,
          fetchMore,
        }) => {
          if (loading || networkStatus === 4) {
            return loadingComponent || <Loader />;
          }
          if (error) {
            console.log('error', error);
            console.log('error variables are', this.state.variablesForQuery);
            return (
              errorComponent || (
                <>
                  <NoDataMessage secondText="Error while loading data" />
                </>
              )
            );
          }
          if (
            data === undefined ||
            (data && Object.values(data) && Object.values(data)[0] === null)
          ) {
            return (
              noDataComponent || (
                <>
                  <NoDataMessage secondText="No data available for those filters" />
                </>
              )
            );
          }
          return React.Children.map(this.props.children, (child, index) => {
            const propsToPass = {
              ...this.props,
              data: data && data[Object.keys(data)[index]],
              refetch,
            };
            if (this.props.fetchMore) {
              propsToPass.onLoadMore = () =>
                this.props.fetchMore(fetchMore, data);
            }
            return React.cloneElement(child, propsToPass);
          });
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state, props) => ({
  filterKeys: props.filterKeys || state.filters.filterKeys,
  benchmarkLevelToDisplay: get(state, ['benchmark', 'levelToDisplay']),
  benchmarkOnlySelectedCategories: get(state, [
    'benchmark',
    'onlySelectedCategories',
  ]),
});

export default connect(mapStateToProps)(CQuery);
