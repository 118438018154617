/* eslint-disable import/prefer-default-export */
import { em } from '../../styles/utils';

const inputHeight = 2.5;
const tagAreaRadius = inputHeight / 2;

export const input = {
  padding: `${em(0.5)} ${em(1)}`,
  'background-color': '#edf0f7',
  color: '#280d60',
  radius: em(tagAreaRadius),
  'min-height': em(inputHeight),
};
