import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QTitle from '../../Texts/QTitle/QTitle';
import { TEXT_POSITIONS } from '../../Texts/textPositions';
import { TEXT_TYPES } from '../../Texts/textTypes';
import { spacing } from '../../../styles/abstracts/variables';

const StyledQTitle = styled(QTitle)`
  padding-top: 0;
  margin: ${spacing.mediumLarge};
`;

const StyledQTitleTitle = styled(QTitle.Title)`
  ${({ type }) => (type !== TEXT_TYPES.HEADER ? `padding-bottom: 0;` : null)};
`;

export const QHeader = ({ title, subtitles, type, className }) => (
  <StyledQTitle
    className={className}
    position={TEXT_POSITIONS.CENTER}
    type={type}
  >
    <StyledQTitleTitle>{title}</StyledQTitleTitle>
    {subtitles.map(subtitle => (
      <QTitle.Subtitle>{subtitle}</QTitle.Subtitle>
    ))}
  </StyledQTitle>
);

QHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(Object.values(TEXT_TYPES)),
  className: PropTypes.string,
};

QHeader.defaultProps = {
  type: TEXT_TYPES.PANEL,
  className: '',
};
