import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getBackgroundCSSFormEindex } from '_Utils/eindex/eindexColorFunctions';
import { convertCelsiusToFahrenheit } from '_Utils/eindex/eindexUnits';
import EindexRond from '../KpiBadges/EindexRond';
import '../../style.scss';

const ThermometerInner = styled.div`
  background-color: ${({ eindex, isFahrenheit }) =>
    getBackgroundCSSFormEindex(eindex, isFahrenheit)};
`;

class Thermometer extends React.Component {
  getPercentThermometer(eindex, projectId) {
    let style;
    switch (eindex) {
      case 40:
        style = { height: '0px' };
        break;
      case 39:
        style = {
          height: '7.1px',
          width: '24px',
          left: '3px',
          top: '2px',
        };
        break;
      case 38:
        style = {
          height: '12.2px',
          width: '24px',
          left: '3px',
        };
        break;
      default:
        let h = 156 - (eindex + 20) * (156 / 60);
        h += 'px';
        style = { height: h };
    }
    return style;
  }

  render() {
    const isFahrenheit = get(this.props, ['eindexUnitFahrenheit']);
    const eindex = isFahrenheit
      ? convertCelsiusToFahrenheit(parseInt(this.props.eindex))
      : parseInt(this.props.eindex);
    return (
      <div className="thermometerRoot">
        <div className="thermometer">
          <div className="thermometerBody">
            <div
              className="thermometerEindex"
              style={this.getPercentThermometer(
                this.props.eindex,
                this.props.filterKeys && this.props.filterKeys.projectId,
              )}
            />
            <ThermometerInner
              className="thermometerInner"
              eindex={eindex}
              isFahrenheit={isFahrenheit}
            />
          </div>
          <EindexRond
            eindex={this.props.eindex}
            type="medium"
            customCSS="rondEindex"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
    eindexUnitFahrenheit:
      get(state, [
        'projectConfiguration',
        'unitsAndLocalesSettings',
        'eindexUnitFahrenheit',
      ]) || false,
  };
}

export default connect(mapStateToProps)(Thermometer);
