import { capitalize, sortBy, map } from 'lodash';

const baseCategory = 'thematic';

const prepareAddCategories = translate =>
  function addCategories(option, category) {
    const { subOptions } = option;
    return {
      ...option,
      category: translate(capitalize(category)),
      subOptions:
        subOptions &&
        sortBy(
          subOptions.map(subOption => addCategories(subOption, `sub ${category}`)),
          ['label'],
        ),
    };
  };

export const createCategory = (
  { id: categoryId, label: categoryLabel, values: options },
  type = 'normal',
  optionConfig = {},
) => ({
  ...optionConfig,
  id: categoryId,
  type,
  label: categoryLabel,
  ...(options && {
    subOptions: options.map(subOptions => ({
      ...optionConfig,
      type,
      id: subOptions.id,
      label: subOptions.label,
      ...(subOptions.values && {
        subOptions: subOptions.values.map(subsubOption => createCategory(subsubOption, type, optionConfig)),
      }),
    })),
  }),
});

export const createGroupedOptionsCollection = (normalKeys, type, optionConfig) => {
  const categories = sortBy(normalKeys, ['label']);
  return map(categories, category => createCategory(category, type, optionConfig));
};

export const prepareGroupedToOptions = translate => grouped => {
  // console.log("prepareGroupedToOptions");
  // console.log("translate : ", translate);
  const normalFilters = createGroupedOptionsCollection(grouped, 'thematics', {
    allowAny: true,
  });
  const addCategories = prepareAddCategories(translate);
  return [
    {
      id: 'thematics',
      label: translate('Thematics'),
      categoryPreview: [translate('Thematic'), translate('Sub thematic'), translate('Sub sub thematic')],
      subOptions: normalFilters.map(option => addCategories(option, baseCategory)),
    },
  ];
};
