import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { cloneDeep, findKey, flattenDeep, upperFirst } from 'lodash';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import { grayShades, purple } from 'styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from 'styles/abstracts/variables';
import { INDICATORS_TYPE } from '_Cards/FilterCards/DataByFiltersCard/FiltersSettings';

const BackItem = styled(Item)`
  justify-content: flex-start !important;
  padding-left: 0.25em !important;
  color: ${grayShades.g800} !important;
`;

const StyledItem = styled(Item)`
  justify-content: flex-start !important;
`;

type ThematicsHorizontalChartDropdownProps = {
  icon: string;
  id: string;
  label: string;
  t: any;
  itemToIterate: Array<any>;
  settings: any;
  setSettings: any;
  dropdownType: string;
  defaultSettings: any;
  subCategory: any;
  orderByItems: Array<any>;
};

const ThematicsHorizontalChartDropdown = ({
  icon,
  id,
  label,
  t,
  itemToIterate,
  settings,
  setSettings,
  dropdownType,
  defaultSettings,
  subCategory,
  orderByItems,
}: ThematicsHorizontalChartDropdownProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const getCurrentItem = () => {
    if (itemToIterate[1].subcategories) {
      let allValues: any[] = [];

      flattenDeep(itemToIterate.map(item => item.subcategories)).map(item => {
        if (item?.subcategories) {
          allValues = [...allValues, ...item.subcategories];
        } else {
          allValues = [...allValues, item];
        }
      });
      return allValues.find(item =>
        defaultSettings ? item.id === defaultSettings[dropdownType].id : itemToIterate[1].subcategories[1],
      );
    }
    if (subCategory) {
      return defaultSettings ? defaultSettings[dropdownType][subCategory] : itemToIterate[1];
    }
    return itemToIterate.find(item => item.id === defaultSettings?.[dropdownType]) || itemToIterate[1];
  };

  const getItem = () => {
    if (defaultSettings) {
      if (subCategory) {
        return defaultSettings[dropdownType][subCategory];
      }
      if (typeof defaultSettings[dropdownType] === 'string') {
        return itemToIterate.find(item => item.id === defaultSettings?.[dropdownType]);
      }
      return defaultSettings[dropdownType];
    }
    return getCurrentItem();
  };

  const [currentItem, setCurrentItem] = useState(getItem());

  const [dropdownContent, setDropdownContent] = useState<any[] | null>(null);
  const settingsToChange = findKey(settings, it => JSON.stringify(it) === JSON.stringify(defaultSettings));

  useEffect(() => {
    ((currentItem && !currentItem.label) || !currentItem) && setCurrentItem(getItem());
  }, [currentItem]);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevSettings = usePrevious(defaultSettings);

  useEffect(() => {
    if (
      prevSettings &&
      defaultSettings &&
      JSON.stringify(prevSettings.indicators) !== JSON.stringify(defaultSettings.indicators) &&
      subCategory
    ) {
      if (defaultSettings && defaultSettings.orderBy && subCategory) {
        const [splitIndex, splitOrder] = (defaultSettings.orderBy.id || defaultSettings.orderBy.value).split('_');
        if (
          (defaultSettings.indicators?.[subCategory] &&
            defaultSettings.indicators?.[subCategory]?.id &&
            !defaultSettings.indicators?.[subCategory]?.id.includes(splitIndex)) ||
          (defaultSettings.indicators?.[subCategory] &&
            defaultSettings.indicators?.[subCategory]?.id &&
            !defaultSettings.indicators?.[subCategory]?.id.includes(splitIndex)) ||
          defaultSettings.indicators?.[subCategory]?.id === null
        ) {
          const cloneSettings = cloneDeep(settings);
          cloneSettings[settingsToChange].orderBy = orderByItems
            ?.find(
              item =>
                item.id ===
                (defaultSettings.indicators[
                  subCategory === INDICATORS_TYPE.BAR ? INDICATORS_TYPE.BADGE : INDICATORS_TYPE.BAR
                ].id === null
                  ? defaultSettings.indicators[subCategory].id?.split('.')[0]
                  : defaultSettings.indicators[
                      subCategory === INDICATORS_TYPE.BAR ? INDICATORS_TYPE.BADGE : INDICATORS_TYPE.BAR
                    ].id?.split('.')[0]),
            )
            ?.subcategories.find(sub => sub.id.split('_')[1] === splitOrder);
          setSettings(cloneSettings);
        }
      }
    }
    if (dropdownType === 'orderBy') {
      setCurrentItem(defaultSettings?.orderBy);
    }
  }, []);

  const getContent = item => {
    if (subCategory === 'bar') {
      return (
        <StyledItem
          toggle={false}
          onClick={() => {
            if (!item.subcategories) {
              const cloneSettings = cloneDeep(settings);
              if (subCategory) {
                cloneSettings[settingsToChange][dropdownType][subCategory] =
                  dropdownType === 'selectedFilter' ? item.id : item;
              } else {
                cloneSettings[settingsToChange][dropdownType] = dropdownType === 'selectedFilter' ? item.id : item;
              }
              setCurrentItem(item);
              setSettings(cloneSettings);
            } else {
              setDropdownContent(item.subcategories);
            }
          }}
        >
          {t(item.label)}
        </StyledItem>
      );
    }
    return (
      <StyledItem
        disabled={item.id === currentItem.id || item.label === currentItem.label}
        onClick={() => {
          const cloneSettings = cloneDeep(settings);
          cloneSettings[settingsToChange][dropdownType][subCategory] = item;
          setCurrentItem(item);
          setSettings(cloneSettings);
          setDropdownContent(null);
        }}
      >
        {t(item.label)}
      </StyledItem>
    );
  };

  return (
    <div id={id}>
      <Label>
        {icon && <Icon icon={icon} size={20} />} {t(label)}
      </Label>
      <StyledDropdown
        direction="down"
        isOpen={isDropdownOpen}
        toggle={() => {
          setDropdownOpen(!isDropdownOpen);
        }}
      >
        <StyledDropdownToggle>
          <ToggleButton disableHover bgColor="grayShades.g300">
            {currentItem && t(currentItem.label).length > 16
              ? `${t(currentItem.label).substring(0, 16)}(...)`
              : currentItem && t(currentItem.label)}
            <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
          </ToggleButton>
        </StyledDropdownToggle>
        <Menu className="dataByFiltersDropdownMenu">
          {!dropdownContent ? (
            itemToIterate.map(item => {
              return (
                <Item
                  disabled={
                    (currentItem && item.id === currentItem.id && item.label === currentItem.label) ||
                    (dropdownType === 'orderBy' &&
                      (!defaultSettings?.indicators?.bar.id ||
                        (defaultSettings?.indicators?.bar.id &&
                          defaultSettings?.indicators?.bar.id.split('.')[0] !== item.id)) &&
                      (!defaultSettings?.indicators?.badge.id ||
                        (defaultSettings?.indicators?.badge.id &&
                          defaultSettings?.indicators?.badge.id.split('.')[0] !== item.id)) &&
                      item.id.split('_')[0] !== 'label' &&
                      item.id.split('_')[0] !== 'hierarchy' &&
                      item.id.split('_')[0] !== 'mentions' &&
                      item.id.split('_')[0] !== 'eindex')
                  }
                  toggle={!item.subcategories}
                  onClick={() => {
                    if (!item.subcategories) {
                      const cloneSettings = cloneDeep(settings);
                      if (subCategory) {
                        cloneSettings[settingsToChange][dropdownType][subCategory] =
                          dropdownType === 'selectedFilter' ? item.id : item;
                      } else {
                        cloneSettings[settingsToChange][dropdownType] =
                          dropdownType === 'selectedFilter' ? item.id : item;
                      }
                      setCurrentItem(item);
                      setSettings(cloneSettings);
                    } else {
                      setDropdownContent(item.subcategories);
                    }
                  }}
                >
                  {t(item.label)}
                  {item.subcategories && (
                    <Icon
                      icon="INTERFACE_CHEVRON_RIGHT"
                      size={ImageSize.medium}
                      color={
                        (currentItem && item.id === currentItem.id && item.label === currentItem.label) ||
                        (dropdownType === 'orderBy' &&
                          (!defaultSettings?.indicators?.bar.id ||
                            (defaultSettings?.indicators?.bar.id &&
                              defaultSettings?.indicators?.bar.id.split('.')[0] !== item.id)) &&
                          (!defaultSettings?.indicators?.badge.id ||
                            (defaultSettings?.indicators?.badge.id &&
                              defaultSettings?.indicators?.badge.id.split('.')[0] !== item.id)) &&
                          item.id.split('_')[0] !== 'label' &&
                          item.id.split('_')[0] !== 'hierarchy')
                          ? grayShades.g800
                          : purple
                      }
                    />
                  )}
                </Item>
              );
            })
          ) : (
            <>
              <BackItem onClick={() => setDropdownContent(null)} toggle={false}>
                <Icon icon="INTERFACE_CHEVRON_LEFT" size={ImageSize.medium} color={grayShades.g800} />
                {t('Back')}
              </BackItem>
              {dropdownContent.map(item =>
                subCategory && !item.subcategories ? (
                  getContent(item)
                ) : (
                  <StyledItem
                    alignLeft={!item.subcategories}
                    toggle={(item.subcategories || subCategory) && false}
                    disabled={
                      (currentItem && item.id === currentItem.id && currentItem.label === item.label) ||
                      (dropdownType === 'orderBy' &&
                        item.subcategories &&
                        !defaultSettings?.indicators?.bar.selected.find(sel => sel.split('.')[1] === item.id) &&
                        item.id.split('_')[0] !== 'label')
                    }
                    onClick={() => {
                      if (!item.subcategories) {
                        const cloneSettings = cloneDeep(settings);
                        cloneSettings[settingsToChange][dropdownType] = item;
                        setCurrentItem(item);
                        setSettings(cloneSettings);
                      } else {
                        setDropdownContent(item.subcategories);
                      }
                    }}
                  >
                    {item.subcategories ? (
                      <>
                        &nbsp;{t(item.label)}
                        <Icon
                          icon="INTERFACE_CHEVRON_RIGHT"
                          color={
                            (currentItem && item.id === currentItem.id && currentItem.label === item.label) ||
                            (dropdownType === 'orderBy' &&
                              item.subcategories &&
                              !defaultSettings?.indicators?.bar.selected.find(sel => sel.split('.')[1] === item.id) &&
                              item.id.split('_')[0] !== 'label')
                              ? grayShades.g800
                              : purple
                          }
                          size={ImageSize.medium}
                        />
                      </>
                    ) : (
                      dropdownType === 'orderBy' && (
                        <>
                          <Icon
                            icon={item.id.split('_')[1] === 'asc' ? 'INTERFACE_SORT_ASC' : 'INTERFACE_SORT_DESC'}
                            size={ImageSize.medium}
                            color={item?.id === currentItem?.id ? grayShades.g800 : purple}
                          />
                          &nbsp;{t(item.label)}
                        </>
                      )
                    )}
                  </StyledItem>
                ),
              )}
            </>
          )}
        </Menu>
      </StyledDropdown>
    </div>
  );
};

export default withTranslation('button')(ThematicsHorizontalChartDropdown);
