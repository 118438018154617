import gql from 'graphql-tag';

const query = gql`
  query getBenchmarkList($projectId: Int!) {
    getBenchmarkList(projectId: $projectId) {
      projectBenchmarks {
        id
        title
        description
        isDefault
        settings
      }
    }
  }
`;

export default query;
