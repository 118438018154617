import gql from 'graphql-tag';
import FRAGMENTS from '_Resources/Cards/Services/GraphQl/FRAGMENTS';

const query = gql`
  query getMentionBadgeCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $dataCollectorId: Int
  ) {
    results: getMentionBadgeCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dataCollectorId: $dataCollectorId
    ) {
      mentionCount {
        value
        yearBefore {
          ...BenchmarkPeriodFragment
        }
        periodBefore {
          ...BenchmarkPeriodFragment
        }
      }
    }
  }
  ${FRAGMENTS.benchmarkPeriod}
`;
export default query;
