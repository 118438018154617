import gql from 'graphql-tag';

const VerbatimFragment = gql`
  fragment VerbatimFragment on Verbatim {
    id
    uuid
    kpis {
      eindex {
        unit {
          min
          max
          unit
        }
        value
      }
      satisfaction {
        unit {
          min
          max
          unit
        }
        value
      }
      effort {
        unit {
          min
          max
          unit
        }
        value
      }
      recommendation {
        unit {
          min
          max
          unit
        }
        value
      }
      emotionalIntensity {
        unit {
          min
          max
          unit
        }
        value
      }
      confidenceRate {
        unit {
          min
          max
          unit
        }
        value
      }
    }
    emotions {
      mainEmotion
      detectedEmotions {
        happiness
        surprise
        calm
        fear
        sadness
        anger
        disgust
      }
    }
    thematics {
      thematic
      subThematic
      subSubThematic
    }
    verbatim {
      verbatimFullText
      verbatimFullCurrentText
      verbatimFullEmotionalText
      verbatimFullEmotionalTextLocale
      verbatimFullThematicalText
      verbatimFullThematicalTextLocale
      availableLocales
      verbatimSentences {
        clusters {
          id
          title
          tone
          verbatimsCount
        }
        emotion
        relevantThematic
        emotionalClaim {
          claimId
          claimType
          sentenceId
          suggestedEmotion
          comment
          username
          status
        }
        sentenceId
        sentence
        emotion
        currentSentence
        emotionalText
        emotionalTextLocale
        thematicalText
        thematicalTextLocale
      }
    }
    informations {
      date
      question
      informationsList {
        name
        values {
          name
          value
          featuredInformation
        }
      }
    }
    belongingClusters {
      id
      title
      tone
      verbatimsCount
    }
    __typename
  }
`;

export default VerbatimFragment;
