import { fill, add, subtract, flatten, last, head } from 'lodash';
/**
 * @typedef CenterBasedGridGenerationConfig
 * @type {Object}
 * @property {number} tileRadius - Tile radius
 */

// TODO: change just to X and Y
export const AXIS = {
  X: 'axisX',
  Y: 'axisY',
  Z: 'axisZ',
};

export const MODES = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
};

const cos30 = Math.sqrt(3) / 2;

function findCenter({ [`${AXIS.X}Length`]: X, [`${AXIS.Y}Length`]: Y }) {
  return {
    [AXIS.X]: X / 2,
    [AXIS.Y]: Y / 2,
  };
}

function generateTileSize(radius) {
  // TODO: add property naming to lengthAxisX
  return {
    [AXIS.X]: radius * cos30 * 2,
    [AXIS.Y]: radius * 2,
  };
}

function prepareGenerateVector({ toNextElement }) {
  return function generateVector(start, elementsNumber, mode) {
    const action = mode === MODES.POSITIVE ? add : subtract;
    return fill(Array(elementsNumber), start).map((item, index) =>
      action(item, toNextElement * (index + 1)),
    );
  };
}

export function prepareGenerateLine({ tileSize, center }) {
  return function generateLine(axis) {
    const toNexTile = axis === AXIS.X ? tileSize[axis] : tileSize[axis] * 1.5;
    const fromCenterToEdge = center[axis];
    const fromCenterHexToEdge = fromCenterToEdge - toNexTile / 2;
    const tilesFromCenter = Math.ceil(fromCenterHexToEdge / toNexTile) + 10;
    const generateVector = prepareGenerateVector({ toNextElement: toNexTile });
    const positive = generateVector(
      center[axis],
      tilesFromCenter,
      MODES.POSITIVE,
    );
    const negative = generateVector(
      center[axis],
      tilesFromCenter,
      MODES.NEGATIVE,
    );

    return [...negative, center[axis], ...positive].sort((a, b) => a - b);
  };
}

export function generateMatrixWithAxialCoordinates(vX, vY) {
  const centerXIndex = (vX.length - 1) / 2;
  const centerYIndex = (vY.length - 1) / 2;

  // TODO: more descriptive
  const middleXLine = vX.map((x, index) => ({
    point: { [AXIS.X]: x },
    coordinates: { [AXIS.X]: index - centerXIndex },
  }));

  return vY.map((y, index) => {
    const centerRelativeIndex = index - centerYIndex;
    return middleXLine.map(({ point, coordinates }) => ({
      point: {
        ...point,
        [AXIS.Y]: y,
      },
      coordinates: {
        [AXIS.X]: coordinates[AXIS.X] - centerRelativeIndex,
        [AXIS.Y]: centerRelativeIndex * 2,
      },
    }));
  });
}

export function prepareGenerateLowerXLine({ tileSize }) {
  return function generateLowerXLine(xLine) {
    return xLine.map(({ point, coordinates }) => ({
      point: {
        [AXIS.X]: point[AXIS.X] + tileSize[AXIS.X] * 0.5,
        [AXIS.Y]: point[AXIS.Y] + tileSize[AXIS.Y] * 0.75,
      },
      coordinates: {
        [AXIS.X]: coordinates[AXIS.X],
        [AXIS.Y]: coordinates[AXIS.Y] + 1,
      },
    }));
  };
}

function markRow(row, label) {
  return row.map(el => ({
    ...el,
    edge: label,
  }));
}

function getMarkLabel(index, length) {
  return index === 0 ? 'left' : index === length - 1 ? last : null;
}

function markRowsEndings(rows) {
  return rows.map(row => {
    const len = row.length;
    return row.map((element, index) => {
      const { edge } = element;
      const label = getMarkLabel(index, len);

      return {
        ...element,
        edge: edge && label ? 'corner' : label,
      };
    });
  });
}

function generateMarkedExtendedMatrix(matrix) {
  const firstRow = head(matrix);
  const middleRows = matrix.splice(1, matrix.length - 2);
  const lastRow = last(matrix);

  return [
    markRow(firstRow, 'top'),
    ...markRowsEndings(middleRows),
    markRow(lastRow, 'bottom'),
  ];
}

/**
 * Prepares a function for center-based grid generation
 * @param  {CenterBasedGridGenerationConfig} config
 */
export default function prepareCenterBasedGridGeneration(config) {
  const { tileRadius } = config;
  const tileSize = generateTileSize(tileRadius);
  const generateLowerXLine = prepareGenerateLowerXLine({ tileSize });
  /**
   * Generates matrix of points representing grid tiles centers starting from
   * the middle of the svg container.</br>
   * Consists of two steps: </br>
   * 1) Generating matrix of initial parallel rows - Y distance between parallel row is trice tile radius
   * 2) Generating matrix of shifted rows - Y distance from initial rows is one and the half radius but
   * it's also shifted in x direction by half of tile width
   * @param  {GridSize} size
   * @returns {Array<GridPoint>} - flattened version of grid matrix
   */
  return function centerBasedGridGeneration(size) {
    const center = findCenter(size);
    const generateLine = prepareGenerateLine({
      tileSize,
      center,
    });

    const centralXLine = generateLine(AXIS.X);
    const centralYLine = generateLine(AXIS.Y);
    const matrix = generateMatrixWithAxialCoordinates(
      centralXLine,
      centralYLine,
    );

    const extendedMatrix = matrix.reduce(
      (extended, xLine) => [...extended, xLine, generateLowerXLine(xLine)],
      [],
    );

    const markedExtendedMatrix = generateMarkedExtendedMatrix(extendedMatrix);

    return flatten(markedExtendedMatrix);
  };
}
