import React from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { resetPageSpecificFilters } from '_Resources/SpecificFiltering/Actions/resetPageSpecificFilters';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import { get, find } from 'lodash';
import { getRoute } from '_Utils/routes/routeUtils';
import NoConfigPage from '_Components/NoConfigPage/NoConfigPage';
import imgMoments from '_Components/NoConfigPage/images/moments-path.gif';
import imgJourney from '_Components/NoConfigPage/images/customer-journey-path.gif';
import imgJourneyAnalysis from '_Components/NoConfigPage/images/customer-journey-analysis.gif';
import QResponsiveGridLayout from '../../../_Layouts/QResponsiveGridLayout/QResponsiveGridLayout';
import layoutConfig from './config';
import { getComponentsLayout } from '../../_Utils/routeComponent';

class CustomerJourneyViewGeneric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idpage: this.props.idpage,
      numberOfMentions: '',
      layout: layoutConfig,
      layout_edit: null,
      associatedThematic: null,
      cardNumberOfMentions: null,
      defaultThematicFilter: null,
    };
  }

  componentDidMount() {
    getRoute(
      this.props.updateRoute,
      this.state.idpage,
      this.props.thematic,
      this.props.projectConfiguration,
    );
    if (this.props.filterKeys) {
      this.setFilterKeys(this.props.filterKeys);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.idpage && this.props.idpage !== prevProps.idpage) ||
      this.props.projectConfiguration !== prevProps.projectConfiguration
    ) {
      getRoute(
        prevProps.updateRoute,
        this.props.idpage,
        this.props.thematic,
        this.props.projectConfiguration,
      );
      this.setState({
        idpage: this.props.idpage,
        associatedThematic: this.props.pageConfiguration || null,
      });
      prevProps.resetPageSpecificAllFilters(prevProps.idpage);
    }

    if (
      this.props.pageConfiguration &&
      prevProps.pageConfiguration &&
      this.props.pageConfiguration.defaultThematic !==
        prevProps.pageConfiguration.defaultThematic
    ) {
      if (this.props.pageConfiguration.defaultThematic !== null) {
        this.setState(
          {
            associatedThematic: this.props.pageConfiguration.defaultThematic,
          },
          () => {
            this.props.setGlobalQueryFilterKeys(
              this.setFilterKeys(this.props.filterKeys),
            );
          },
        );
      } else {
        this.setState({ associatedThematic: null });
      }
    } else if (!prevProps.pageConfiguration) {
      this.props.pageConfiguration &&
        this.setState({
          associatedThematic: this.props.pageConfiguration.defaultThematic,
        });
    }

    if (
      this.props.filterKeys !== prevProps.filterKeys ||
      this.props.specificFilters !== prevProps.specificFilters
    ) {
      this.setFilterKeys(this.props.filterKeys, this.props.specificFilters);
      if (this.props.specificFilters) {
        this.setState({
          cardNumberOfMentions: this.props.specificFilters.mentionCount,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetPageSpecificAllFilters(this.props.idpage);
  }

  setFilterKeys(filterKeys, specificFilters) {
    if (!filterKeys) return;
    const keys = JSON.parse(JSON.stringify(filterKeys));
    const defaultThematic = get(this.props, [
      'pageConfiguration',
      'defaultThematic',
    ]);
    if (defaultThematic) {
      keys.thematics = specificFilters ? specificFilters.thematics : [];
      this.setState({
        associatedThematic: defaultThematic,
        defaultThematicFilter: specificFilters
          ? specificFilters.thematics[0]
          : null,
        filterPage: keys,
      });
    } else {
      this.setState({
        associatedThematic: false,
        defaultThematicFilter: null,
      });
    }
    return keys;
  }

  setGraphFilters(filters) {
    const clonedFilters = _.cloneDeep(filters);
    const { specificThematics } = this.props;
    const selectedThematic = find(
      specificThematics && specificThematics.values,
      thematic =>
        clonedFilters &&
        clonedFilters.thematics[0] &&
        clonedFilters.thematics[0].subThematic === thematic.id,
    );
    let arrayFullThematics = [];
    selectedThematic &&
      selectedThematic.values &&
      selectedThematic.values.forEach(subSubThematic => {
        const fullThematic = {
          thematic: clonedFilters && clonedFilters.thematics[0].thematic,
          subThematic: clonedFilters && clonedFilters.thematics[0].subThematic,
          subSubThematic: subSubThematic.id,
        };
        arrayFullThematics = [...arrayFullThematics, fullThematic];
      });
    const filtersForGraph = clonedFilters;
    if (filtersForGraph) {
      filtersForGraph.thematics = arrayFullThematics;
    }
    return filtersForGraph || clonedFilters;
  }

  render() {
    const {
      layout,
      associatedThematic,
      filterPage,
      defaultThematicFilter,
    } = this.state;
    const props = {
      EindexTemperatureCard: {
        filters: filterPage,
      },
      EindexEvolutionWithMentionsCard: {
        filters: filterPage,
      },
      MentionBreakdownCard: {
        filters: filterPage,
      },
      MentionPreviewPerEmotions: {
        filters: filterPage,
        defaultThematicFilter,
        lazyLoad: true,
      },
      EindexBarGraphCard: {
        filters: this.setGraphFilters(filterPage),
      },
    };
    const layoutComponents = getComponentsLayout(layout, props, {
      numberOfMentions: this.state.cardNumberOfMentions,
      // titleSecondPart: this.props.filterTabTitle,
    });
    const noConfigContent = {
      noConfigMainMessage: {
        message: 'DEFAULT_MAIN_MESSAGE',
        image: null,
      },
      noConfigContent: [
        {
          image: this.props.idpage === 'moments' ? imgMoments : imgJourney,
          title: 'CUSTOMER_JOURNEY_01_TITLE',
          description: `CUSTOMER_JOURNEY_01_CONTENT_${this.props.idpage.toUpperCase()}`,
          titleVars: {
            moduleSubject: `CUSTOMER_JOURNEY_MODULE_EXPLANATION_${this.props.idpage.toUpperCase()}`,
          },
        },
        {
          image: imgJourneyAnalysis,
          title: 'CUSTOMER_JOURNEY_02_TITLE',
          description: 'CUSTOMER_JOURNEY_02_CONTENT',
        },
        // {
        //   image: imgGraphs,
        //   title: 'MAP_02_TITLE',
        //   description: 'MAP_02_CONTENT',
        // },
      ],
      noConfigEndMessage: {
        message: 'DEFAULT_LAST_MESSAGE',
      },
    };
    return layout && associatedThematic ? (
      this.props.specificFilters ? (
        <div>
          <Container fluid className="py-3 px-4">
            <QResponsiveGridLayout
              layout={layout}
              isResizable={this.props.editableLayout}
            >
              {layoutComponents}
            </QResponsiveGridLayout>
          </Container>
        </div>
      ) : (
        <div>
          <Container fluid className="py-5 px-">
            <NoDataMessage
              mainTitle="Select a tab !"
              secondText="Please, use the top panel to select a thematic and see corresponding data !"
            />
          </Container>
        </div>
      )
    ) : (
      <div>
        <Container fluid className="py-5 px-4">
          <NoConfigPage config={noConfigContent} />
        </Container>
      </div>
    );
  }
}

CustomerJourneyViewGeneric.defaultProps = {
  layoutDefault: layoutConfig,
  editableLayout: false,
};

function mapStateToProps(state, ownProps) {
  return {
    filterKeys: state.filters.filterKeys,
    editableLayout: state.routes.activeLayoutEdition,
    module: state.routes.route && state.routes.route.name,
    specificFilters: state.specificFilters[ownProps.idpage] || null,
    projectConfiguration: state.projectConfiguration,
    pageConfiguration: get(state, ['routes', 'pageConfiguration']),
    specificThematics:
      state.specificFilters[ownProps.idpage] &&
      find(
        get(state, ['filters', 'projectFilters', 'thematics']),
        thematic =>
          thematic.id ===
          state.specificFilters[ownProps.idpage].thematics[0].thematic,
      ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
    resetPageSpecificAllFilters: newState => {
      dispatch(resetPageSpecificFilters(newState));
    },
    setGlobalQueryFilterKeys: newState => {
      dispatch(setGlobalQueryFilterKeys(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('text')(CustomerJourneyViewGeneric));
