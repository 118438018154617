import {
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  GET_USER_INFOS,
  RENEW_USER_API_TOKEN,
} from '_Resources/Auth/auth';

const initialState = {
  isFetching: false,
  user: {},
  token: null,
  errors: [],
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOGOUT_SUCCESS:
      return initialState;
    case GET_USER_INFOS:
      return {
        ...state,
        user: { ...action.payload.data },
      };
    case RENEW_USER_API_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          account: {
            ...state.user.account,
            apiToken: action.payload.token,
          },
        },
      };
    default:
      return state;
  }
}
