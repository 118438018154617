import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { grayShades, lightBlue } from 'styles/abstracts/colors';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import { useQuery } from 'react-apollo';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import GET_LANDINGPAGE_DECRYPTING_MESSAGES_DATA from '../../_Cards/LandingPageCard/graphql/getLandingPageDecryptingMessagesData';

const AnalysisMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  padding: 1em 0 2em;
`;

const AnalysisMessage = styled.div`
  display: flex;
  flex-direction: row;
  color: ${grayShades.g800};
  width: 100%;
  margin-bottom: 3rem;
  padding: 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

const AnalysisIcon = styled(IconCircle)`
  align-items: top;
`;

const IconNotation = styled(Col)`
  /* display: inline-block; */
  /* margin-right: 1rem; */
  & > * {
    float: right;
  }
`;

const AnalysisCard = ({
  projectId,
  activeTab,
  t,
}: {
  projectId: number;
  activeTab: string;
  t: Function;
}) => {
  const { data, loading } = useQuery(GET_LANDINGPAGE_DECRYPTING_MESSAGES_DATA, {
    variables: {
      projectId,
    },
  });

  const filteredMessages = data?.getLandingPageDecryptingMessagesData?.messages?.filter(
    ({ tab }) => {
      return tab === activeTab.toUpperCase();
    },
  );

  return !(filteredMessages?.length === 0) ? (
    <AnalysisMessages className="analysisMessages">
      {data?.getLandingPageDecryptingMessagesData?.messages
        ?.filter(({ tab }) => {
          return tab === activeTab.toUpperCase();
        })
        .map(message => {
          return (
            <AnalysisMessage className="analysisMessage" key={message.id}>
              <IconNotation xs="3" className="IconNotation">
                <AnalysisIcon
                  icon={`PICTO_${message.icon}`}
                  color={lightBlue}
                  size={24}
                  className="iconCircle"
                />
              </IconNotation>
              <Col>{message.content}</Col>
            </AnalysisMessage>
          );
        })}
    </AnalysisMessages>
  ) : (
    <NoDataMessage secondText={t('text:No analysis available for this project', {activeTab})} />
  );
};

AnalysisCard.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default AnalysisCard;
