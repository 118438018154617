import { Component } from 'react';
import memoizeOne from 'memoize-one';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty, max, last, capitalize } from 'lodash';

function getDepth(thematic, depth = 1) {
  if (isEmpty(thematic.values)) {
    return depth;
  }
  return max(
    thematic.values.map(subThematic => getDepth(subThematic, depth + 1)),
  );
}

const prepareCreateThematicsLabels = t =>
  function createThematicOptions(depth, thematicOptions = []) {
    if (depth > 0) {
      const lastLabel = last(thematicOptions);
      return createThematicOptions(depth - 1, [
        ...thematicOptions,
        lastLabel ? t(`sub ${lastLabel}`) : t('thematic'),
      ]);
    }
    return thematicOptions;
  };

const createThematicsOptions = thematicsLabels =>
  thematicsLabels.map((label, index) => ({
    id: label,
    label,
    depth: index + 1,
    type: 'thematics',
  }));

const createNormalOptions = normalFilters =>
  normalFilters.map(option => ({
    ...option,
    type: 'normal',
  }));

class FilterByOptionsProvider extends Component {
  static propTypes = {
    thematics: PropTypes.array.isRequired,
    normalFilters: PropTypes.array.isRequired,
    render: PropTypes.func.isRequired,
  };

  createThematicsLabels = null;

  constructor({ t }) {
    super();
    this.createThematicsLabels = prepareCreateThematicsLabels(t);
  }

  getThematicsOptions(thematics) {
    const thematicsWrapper = { values: thematics };
    const thematicsDepth = getDepth(thematicsWrapper, 0);
    const thematicsLabels = this.createThematicsLabels(thematicsDepth).map(
      capitalize,
    );
    return createThematicsOptions(thematicsLabels);
  }

  getNormalOptions(normalFilters) {
    return createNormalOptions(normalFilters);
  }

  getOptions = memoizeOne((thematics, normalFilters) => {
    const thematicsOptions = []; // this.getThematicsOptions(thematics);
    const normalOptions = normalFilters
      ? this.getNormalOptions(normalFilters)
      : [];
    return [...thematicsOptions, ...normalOptions];
  });

  render() {
    const { render, thematics, normalFilters } = this.props;
    const options = this.getOptions(thematics, normalFilters);

    return render(options);
  }
}
const mapStateToProps = ({ filters }) => ({
  thematics: get(filters, 'projectFilters.thematics'),
  normalFilters: get(filters, 'projectFilters.criteriaKeys.normal', []),
});

export default connect(mapStateToProps)(
  withTranslation('reporting')(FilterByOptionsProvider),
);
