import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { withTranslation } from 'react-i18next';
import { NestedInputTags } from '@qemotion_team/react-nested-input-tags';
import { prepareGroupedToOptions } from '_Container/QProjectFilters/Filters/QThematicFilter/groupedToOptions';
import QTitle from '../../../../../../../../_Components/Texts/QTitle/QTitle';

class ThematicsFilter extends Component {
  groupedToOptions = null;

  constructor({ t }) {
    super();
    const groupedToOptions = prepareGroupedToOptions(t);

    this.groupedToOptions = memoizeOne(thematics => {
      return groupedToOptions(thematics);
    });
  }

  render() {
    const { t, thematics, onRemoved, onSelected, initialValues } = this.props;

    return (
      <div>
        <QTitle>
          <QTitle.Title>{t('Thematics')}</QTitle.Title>
        </QTitle>
        <NestedInputTags
          initialValues={initialValues}
          categoryPrefix={t('in')}
          options={this.groupedToOptions(thematics)}
          onSelected={onSelected}
          onRemoved={onRemoved}
        />
        {/* <MergeMessages
          context={this.props.context}
          type={FILTER_DIMENSIONS.THEMATIC}
        /> */}
      </div>
    );
  }
}

ThematicsFilter.propTypes = {
  thematics: PropTypes.arrayOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRemoved: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf(
    PropTypes.shape({
      thematic: PropTypes.string.isRequired,
      subThematic: PropTypes.string || null,
      subSubThematic: PropTypes.string || null,
    }),
  ),
};

ThematicsFilter.defaultProps = {
  initialValues: [
    {
      thematic: '',
      subThematic: null,
      subSubThematic: null,
    },
  ],
};

export default withTranslation('reporting')(ThematicsFilter);
