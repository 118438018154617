import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '_Components/Icons/Icon';
import { grayShades } from '../../../../../styles/abstracts/colors';
import { radius } from '../../../../../styles/abstracts/variables';

const grayColor = grayShades.g700;

export const TagFrame = styled.div`
  padding: 0.175em;
  color: ${grayColor};
  border-radius: ${radius.round};
  background-color: ${({ primary }) => (primary ? '#DBD6E9' : grayShades.g300)};
  margin: 0.4em 0.175em;
`;

const RemoveButton = styled.span`
  cursor: pointer;
`;

TagFrame.propTypes = {
  primary: PropTypes.bool,
};

export const TagLabel = styled.span`
  font-weight: bold;
  margin: 0 0.5em;
`;

export const Tag = ({ onRemoved, option }) => {
  const { label } = option;
  return (
    <TagFrame>
      <TagLabel>{label}</TagLabel>
      <RemoveButton onClick={() => onRemoved(option)}>
        <Icon size={24} color={grayColor} icon="CROSS" />
      </RemoveButton>
    </TagFrame>
  );
};

Tag.propTypes = {
  onRemoved: PropTypes.func.isRequired,
  option: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
};
