import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themeColors } from 'styles/abstracts/colors';
import { getFontSizeStyles } from 'styles/abstracts/typography';
import { prepareGetDateRangeSubtitle } from 'Routes/GlobalExperience/Reporting/_Containers/helpers/subtitleHelper';

const SubTitle = styled.p`
  text-align: center;
  font-weight: bold;
  color: ${themeColors.secondary} ${getFontSizeStyles(14)};
`;

const DateText = props => {
  const { t } = props;
  const getDateRangeSubtitle = prepareGetDateRangeSubtitle(
    t,
    props.i18n.language,
  );
  const dateSubtitle = getDateRangeSubtitle(props.filters.range);

  return <SubTitle>{dateSubtitle}</SubTitle>;
};

export default withTranslation('card')(DateText);
