import { ActionTypes } from '../../../constants';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEND_EMOTION_SELECTED:
      const { mentionPreviewPerEmotion } = state;
      return {
        ...state,
        mentionPreviewPerEmotion: {
          ...mentionPreviewPerEmotion,
          selectedEmotion: action.payload.value,
        },
      };
    case ActionTypes.SEND_SENTIMENT_SELECTED: {
      const { mentionPreviewPerEmotion } = state;
      return {
        ...state,
        mentionPreviewPerEmotion: {
          ...mentionPreviewPerEmotion,
          selectedSentiment: action.payload.value,
        },
      };
    }
    case ActionTypes.SEND_SELECTION_SELECTED: {
      const { mentionPreviewPerEmotion } = state;
      return {
        ...state,
        mentionPreviewPerEmotion: {
          ...mentionPreviewPerEmotion,
          selectedSelection: action.payload.value,
        },
      };
    }
    case ActionTypes.SEND_EMOTION_ONHOVER:
      return {
        ...state,
        emotion: action.payload.emotion,
      };
    case ActionTypes.KEYWORDS_UPDATED: {
      const { mentionPreviewPerEmotion } = state;
      return {
        ...state,
        mentionPreviewPerEmotion: {
          ...mentionPreviewPerEmotion,
          keywords: action.data,
        },
      };
    }
    case ActionTypes.SELECT_ANALYSIS_AXIS: {
      const { mentionPreviewPerEmotion } = state;
      return {
        ...state,
        mentionPreviewPerEmotion: {
          ...mentionPreviewPerEmotion,
          analysisAxis: action.data,
        },
      };
    }
    default:
      return state;
  }
};
