/* eslint import/prefer-default-export: 0 */
import { AXIS } from './grid';

const cos30 = Math.sqrt(3) / 2;
const sin30 = 0.5;

export function convertToCubeCoordinates(vectorElement) {
  const { coordinates } = vectorElement;
  const x = coordinates[AXIS.X];
  const z = coordinates[AXIS.Y];
  return {
    ...vectorElement,
    coordinates: {
      [AXIS.X]: x,
      [AXIS.Y]: -x - z,
      [AXIS.Z]: z,
    },
  };
}

export default function prepareGetHexagonCoords(radius) {
  return function getHexagonCoords({ [AXIS.X]: xp, [AXIS.Y]: yp }) {
    return [
      { x: xp, y: yp - radius },
      { x: radius * cos30 + xp, y: yp - radius * sin30 },
      { x: radius * cos30 + xp, y: yp + radius * sin30 },
      { x: xp, y: yp + radius },
      { x: xp - radius * cos30, y: yp + radius * sin30 },
      { x: xp - radius * cos30, y: yp - radius * sin30 },
    ];
  };
}
