import gql from 'graphql-tag';

const query = gql`
  query accountDetails($accountId: Int!) {
    accountDetails(accountId: $accountId) {
      companyName
      name
      address {
        address1
        address2
        address3
        city
        postalCode
        stateProvince
        alpha3Cc
      }
    }
  }
`;

export default query;
