import gql from 'graphql-tag';

const query = gql`
  query getHeaderProjectFiltersData {
    data: getHeaderProjectFiltersData {
      projects {
        name
        id
        slug
        state
        demo
      }
    }
    currentUser {
      id
      email
      isAdmin
      lang
      firstName
      lastName
      expireAt
      account {
        id
        companyName
        name
        planName
        validUntil
        subscriptionCancelAtDate
        subscriptionResetDate
      }
    }
  }
`;

export default query;
