import React, { useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from 'styles/abstracts/variables';
import { grayShades } from 'styles/abstracts/colors';

const BackItem = styled(Item)`
  justify-content: flex-start !important;
  padding-left: 0.25em !important;
  color: ${grayShades.g800} !important;
`;

type VarianceOrderByDropdownProps = {
  id: string;
  t: any;
  label: string;
  currentSetting?: { label: string; value: number | string } | null;
  itemToIterate: Array<{ label: string; value: number | string }>;
  callback: any;
};

const VarianceOrderByDropdown = ({
  id,
  t,
  label,
  itemToIterate,
  callback,
  currentSetting = null,
}: VarianceOrderByDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>(currentSetting || itemToIterate[0].subcategories[0]);
  const [dropdownContent, setDropdownContent] = useState<any>();
  return (
    <div id={id}>
      <Label>{t(label)}</Label>
      <StyledDropdown
        direction="down"
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
      >
        <StyledDropdownToggle>
          <ToggleButton disableHover bgColor="grayShades.g300">
            {t(currentItem.label)}
            <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
          </ToggleButton>
        </StyledDropdownToggle>
        <Menu className="dataByFiltersDropdownMenu">
          {dropdownContent ? (
            <>
              <BackItem
                toggle={false}
                onClick={() => {
                  setDropdownContent(null);
                }}
              >
                <Icon icon="INTERFACE_CHEVRON_LEFT" size={ImageSize.medium} color={grayShades.g800} />
                {t('Back')}
              </BackItem>
              {dropdownContent.map(item => (
                <Item
                  disabled={currentItem.value === item.value}
                  onClick={() => {
                    callback(item.value);
                    setCurrentItem(item);
                  }}
                >
                  {t(item.label)}
                </Item>
              ))}
            </>
          ) : (
            itemToIterate.map(item => (
              <Item toggle={false} onClick={() => setDropdownContent(item.subcategories)}>
                {t(item.label)}
                <Icon icon="INTERFACE_CHEVRON_RIGHT" size={ImageSize.medium} />
              </Item>
            ))
          )}
        </Menu>
      </StyledDropdown>
    </div>
  );
};

export default withTranslation('button')(VarianceOrderByDropdown);
