import gql from 'graphql-tag';

const declareEmotionalExactness = gql`
  mutation declareEmotionalExactness($projectId: Int!, $sentenceId: Int!) {
    declareEmotionalExactness(projectId: $projectId, sentenceId: $sentenceId) {
      claim
      errors
    }
  }
`;

export default declareEmotionalExactness;
