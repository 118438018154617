import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import Icon from '_Components/Icons/Icon';
import { white } from 'styles/abstracts/colors';
import {
  QTooltip,
  QTooltipSubTitle,
  QTooltipTitle,
} from '_Components/Tooltips/QTooltip';
import styled from 'styled-components';

const CommentInfoIcon = styled.span`
  display: inline-block;
  vertical-align: middle;
  top: -2px;
  position: relative;
  margin-left: 0.25rem;
  cursor: pointer;
`;

const StyledQTooltip = styled(QTooltip)`
  & .tooltip-inner {
    text-align: left !important;
    max-width: 600px;
  }
`;

class MentionCountDetailsTootltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    const { t } = this.props;
    const {
      totalMentionCountWithNull,
      totalMentionCountWithoutNull,
    } = this.props;
    return (
      <Fragment>
        <CommentInfoIcon id="TooltipExample">
          <Icon icon="INTERFACE_INFO" size={16} color={white} />
        </CommentInfoIcon>
        <StyledQTooltip
          placement="auto"
          isOpen={this.state.tooltipOpen}
          target="TooltipExample"
          toggle={this.toggle}
          hideArrow
        >
          <Row>
            <Col>
              <QTooltipTitle>{t('About comment count')}</QTooltipTitle>
            </Col>
          </Row>
          <Row className="mt-3 mb-1">
            <Col>
              <QTooltipSubTitle>
                {t('Comment count details')} :
              </QTooltipSubTitle>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>{t('Total with not exploitable comments')} :</Col>
            <Col xs={4} className="font-weight-bold">
              {totalMentionCountWithNull} {t('comments')}
            </Col>
          </Row>
          <Row>
            <Col xs={8}>{t('Number of "not exploitable" comments')} :</Col>
            <Col xs={4} className="font-weight-bold">
              {totalMentionCountWithNull - totalMentionCountWithoutNull}{' '}
              {t('comments')}
            </Col>
          </Row>
          <Row>
            <Col xs={8}>{t('Exploitables comments')} :</Col>
            <Col xs={4} className="font-weight-bold">
              {totalMentionCountWithoutNull} {t('comments')}
            </Col>
          </Row>
          <Row className="mt-3 mb-1">
            <Col>
              <QTooltipSubTitle>
                {t('When do you set a comment as "Not exploitable" ?')} :
              </QTooltipSubTitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                {t(
                  'In some cases we are not able to determine the emotional value of a comment',
                )}{' '}
                :
              </p>
              <ul>
                <li>
                  {t('If a comment is not long enough (less than 3 words)')}
                </li>
                <li>
                  {t(
                    'If two opposite emotions are strong and have too close values',
                  )}
                </li>
              </ul>
            </Col>
          </Row>
        </StyledQTooltip>
      </Fragment>
    );
  }
}

export default withTranslation('header')(MentionCountDetailsTootltip);
