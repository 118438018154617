import React from 'react';

import { hex2rgbaConvert } from '../../../_Utils/styles/colors';

const test =  typeof document !== 'undefined' && require('echarts-liquidfill').default;

const ReactEcharts =
  typeof document !== 'undefined' && require('echarts-for-react').default;

class CSpeechEngagementChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getOption = value => ({
    series: [
      {
        type: 'liquidFill',
        radius: '100%',
        data: [parseInt(value) / 100],
        shape:
          'path://M24.4852989,147.665083 L-3.55271368e-15,151.262854 L12.2350102,130.374546 C5.38484187,117.704244 1.47702397,103.077497 1.47702397,87.5 C1.47702397,39.1750844 39.085105,0 85.477024,0 C131.868943,0 169.477024,39.1750844 169.477024,87.5 C169.477024,135.824916 131.868943,175 85.477024,175 C61.4586886,175 39.7947813,164.499473 24.4852989,147.665083 Z',
        // TODO : changer l'intensite suivant le nombre
        amplitude: 10,
        itemStyle: {
          shadowBlur: 0,
          color: '#592EA0',
        },
        label: {
          fontSize: 28,
          color: '#592EA0',
        },
        backgroundStyle: {
          color: hex2rgbaConvert('#592EA0', 20),
        },
        outline: {
          show: false,
        },
      },
    ],
  });

  render() {
    return (
      <div style={{ height: '100%' }}>
        {ReactEcharts ? (
          <ReactEcharts
            option={this.getOption(this.props.value)}
            style={{ height: '100%', width: '100%' }}
            className="react_for_echarts"
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default CSpeechEngagementChart;
