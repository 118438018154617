import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export function isValidFullDateRange(dateRange, comparedRange) {
  if (!dateRange || !comparedRange) return false;
  const dateRange1 = moment.range(dateRange.startDate, dateRange.endDate);
  const dateRange2 = moment.range(
    comparedRange.startDate,
    comparedRange.endDate,
  );
  const finalDateRange = dateRange1.isSame(dateRange2)
    ? dateRange1
    : dateRange1.intersect(dateRange2);

  if (finalDateRange !== null) {
    return {
      startDate:
        finalDateRange.start.format('YYYY-MM-DD')?.[0] === '-' ||
        finalDateRange.start.format('YYYY-MM-DD').split('-')?.[0].length !== 4
          ? null
          : finalDateRange.start.format('YYYY-MM-DD[T]HH:mm:ss'),
      endDate:
        finalDateRange.end.format('YYYY-MM-DD')?.[0] === '-' ||
        finalDateRange.end.format('YYYY-MM-DD').split('-')?.[0].length !== 4
          ? null
          : finalDateRange.end
              .set({ h: 23, m: 59, s: 59 })
              .format('YYYY-MM-DD[T]HH:mm:ss'),
    };
  }
  return false;
}
