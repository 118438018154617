import { em } from '../../../styles/utils';

export const tagAreaHeight = 2.5;
export const tagHeight = 2;

const tagAreaRadius = tagAreaHeight / 2;
export const tagAreaStyles = {
  'background-color': '#edf0f7',
  radius: em(tagAreaRadius),
  'min-height': em(tagAreaHeight),
};

const tagRadius = tagHeight / 2;
export const tagStyles = {
  border: '2px solid white',
  radius: em(tagRadius),
  margin: em(tagAreaRadius - tagRadius),
  height: em(tagHeight),
  padding: `${em(0.25)} ${em(0.5)}`,
  color: '#a7adc7',
};
