import { SELECT_CLUSTER } from '../Actions/selectClusterDetails';
import { LOAD_CLUSTERS } from '../Actions/loadClusters';
import { LOAD_CLUSTERS_TO_MERGE } from '../Actions/loadClustersToMerge';
import { RENAME_CLUSTER } from '../Actions/renameCluster';

const initialState = {
  clickedCluster: {
    id: '',
    title: '',
    size: 0,
    verbatimsCount: 0,
    hasParents: false,
  },
  clickedClusterTone: '',
  clickedClusterVerbatims: [],
  clusters: {},
  clustersToMerge: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CLUSTER: {
      return {
        ...state,
        clickedCluster: action.data.clickedCluster,
        clickedClusterTone: action.data.clickedClusterTone,
      };
    }
    case LOAD_CLUSTERS: {
      return {
        ...state,
        clusters: action.data,
      };
    }
    case LOAD_CLUSTERS_TO_MERGE: {
      return {
        ...state,
        clustersToMerge: action.data,
      };
    }
    case RENAME_CLUSTER: {
      return {
        ...state,
        clickedCluster: {
          ...state.clickedCluster,
          title: action.data,
        },
      };
    }
    default: {
      return state;
    }
  }
};
