export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const SET_FUTURE_PROJECT_TO_SELECT = 'SET_FUTURE_PROJECT_TO_SELECT';
export const RESET_DATA_COLLECTOR_IN_PROJECT_CONFIGURATION =
  'RESET_DATA_COLLECTOR_IN_PROJECT_CONFIGURATION';

export const setCurrentProject = project => ({
  type: SET_CURRENT_PROJECT,
  data: project,
});

export const setFutureProjectToSelect = futureProjectToSelect => ({
  type: SET_FUTURE_PROJECT_TO_SELECT,
  data: futureProjectToSelect,
});

export const resetDataCollectorInProjectConfiguration = () => ({
  type: RESET_DATA_COLLECTOR_IN_PROJECT_CONFIGURATION,
});
