import React from 'react';
import styled from 'styled-components';
import { grayShades, black, green } from 'styles/abstracts/colors';

const GraphContainer = styled.div<{ scaled: boolean }>`
  display: flex;
  justify-content: center;
  transform: ${({ scaled }) => scaled ? 'scale(0.75)' : ''};
  transition-duration: 0.5s;
`;

const GraphItem = styled.div<{ topMargin: number; smaller: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: ${({ smaller }) => (smaller ? 14.7 : 22.7)}em;
  width: 2.5em;
  margin-top: calc(0em + ${({ topMargin }) => topMargin}em);
  color: ${grayShades.g800};
  transition: all 0.5s;
`;

const GraphBar = styled.div<{
  height: number;
  opacity: number;
  item: string;
  max: number;
  greenLimit: number;
  smaller: boolean;
}>`
  width: 50%;
  height: calc(${({ height }) => height} * ${({ smaller }) =>
  smaller ? 12 : 20}em);
  opacity: ${({ opacity }) => opacity};
  background-color: ${({ item, max, greenLimit }) =>
    parseInt(item) < max - greenLimit ? '#000' : green};
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: all 0.5s;
`;

const GraphItemLabel = styled.span`
  font-size: 0.8em;
  font-weight: 700;
`;

const GraphItemNoteLabel = styled.span<{
  item: string;
  max: number;
  greenLimit: number;
}>`
  color: ${({ item, max, greenLimit }) =>
    parseInt(item) < max - greenLimit ? grayShades.g800 : black};
  font-weight: 700;
`;

const getTopMargin = (
  query: Array<{ kpiValue: string; percentage: number }>,
  smaller: boolean,
) => {
  const tab = [];
  Object.keys(query).map(item => {
    tab.push(query[item]?.percentage / 100);
  });
  return -(1 - Math.max(...tab)) * (smaller ? 12 : 20);
};

const getMaxPercentage = query => {
  const tab = [];
  Object.keys(query).map(item => {
    tab.push(query[item]?.percentage / 100);
  });
  return Math.max(...tab);
};

const KPIGraph = ({ data, min, max, scaled }) => {
  const greenLimit = min === 0 ? 1 : 2;
  const smaller = getMaxPercentage(data?.verbatimsPerKpi) > 0.6;
  return (
    <GraphContainer className="graphContainer" scaled={scaled}>
      {Object.keys(data?.verbatimsPerKpi).map(item => {
        return (
          <GraphItem
            className="graphItem"
            topMargin={getTopMargin(data?.verbatimsPerKpi, smaller)}
            key={item}
            smaller={smaller}
          >
            <GraphItemLabel>{`${Math.round(
              data?.verbatimsPerKpi[item]?.percentage,
            )} %`}</GraphItemLabel>
            <GraphBar
              height={data?.verbatimsPerKpi[item]?.percentage / 100}
              opacity={
                parseInt(item) < max - greenLimit
                  ? (max - parseInt(item)) / max
                  : 1
              }
              item={item}
              max={max}
              greenLimit={greenLimit}
              smaller={smaller}
            />
            <GraphItemNoteLabel
              item={item}
              max={max}
              greenLimit={greenLimit}
            >
              {data?.verbatimsPerKpi[item]?.kpiValue}
            </GraphItemNoteLabel>
          </GraphItem>
        );
      })}
    </GraphContainer>
  );
};

export default KPIGraph;
