import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fontSizes } from 'styles/abstracts/typography';
import { grayShades, purple, white } from 'styles/abstracts/colors';
import { spacing } from 'styles/abstracts/variables';
import { withTranslation } from 'react-i18next';
import MentionCountDetailsTootltip from '_Layouts/Header/_Containers/HeaderTop/_Components/MentionCountDetailsTootltip';

const CommentsContainer = styled.div`
  font-weight: bold;
  margin-left: 2%;
`;

const PillNumber = styled.span`
  font-size: ${fontSizes.smaller};
  position: inherit;
  border-radius: 3rem;
  border: 2px solid ${purple};
  padding: calc(${spacing.xsmall} * 1.5) ${spacing.medium};
  background: ${grayShades.g450};
  color: ${purple};
  z-index: 4;
  font-size: 1em;
`;

const CommentsPill = styled.span`
  font-size: ${fontSizes.smaller};
  padding: ${spacing.xsmall} ${spacing.medium} ${spacing.xsmall}
    calc(2.5 * ${spacing.medium});
  position: inherit;
  border-radius: 2rem;
  border: 2px solid ${purple};
  background: ${grayShades.g900};
  color: ${white};
  margin-left: -2rem;
  height: 2rem;
  line-height: 1.25rem;
`;

const CommentsSelected = styled(CommentsPill)`
  z-index: 3;
`;

const FullProject = styled(CommentsPill)`
  font-size: ${fontSizes.extraSmall};
  z-index: 2;
`;

class NumberOfComments extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    const {
      currentMentionCountWithoutNull,
      totalMentionCountWithoutNull,
    } = this.props.mentions;
    return this.props.mentions ? (
      <CommentsContainer className=" d-flex align-items-center align-content-center">
        <Fragment>
          <PillNumber>{currentMentionCountWithoutNull || '0'}</PillNumber>
          <CommentsSelected>
            {t('comment selected', {
              count: parseInt(currentMentionCountWithoutNull),
            })}
          </CommentsSelected>
          <FullProject>
            {t('on')} {totalMentionCountWithoutNull}{' '}
            {t('exploitable comment', {
              count: parseInt(totalMentionCountWithoutNull),
            })}
            <MentionCountDetailsTootltip {...this.props.mentions} />
          </FullProject>
        </Fragment>
      </CommentsContainer>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    mentions: state.mentions,
    defaultThematic: state.routes.defaultThematic,
  };
}

export default connect(
  mapStateToProps,
  null,
)(withTranslation('header')(NumberOfComments));
