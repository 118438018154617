import { HintsContent } from '../../../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import { DISPLAY_TYPE } from './cardsConfig';

const mock = [
  {
    title: 'E-Index',
    type: 'eindex',
    hint: HintsContent.HINT_EINDEX_BADGE,
    displayType: DISPLAY_TYPE.SPLIT,
    editableLayout: false,
    settings: null,
  },
  {
    title: 'Satisfaction & customer effort',
    type: 'sat_nps',
    hint: HintsContent.HINT_SAT_NPS,
    displayType: DISPLAY_TYPE.SPLIT,
    editableLayout: false,
    settings: null,
  },
  {
    title: 'Emotion breakdown',
    type: 'emotion_breakdown',
    hint: HintsContent.HINT_EMOTION_BREAKDOWN,
    displayType: DISPLAY_TYPE.SPLIT,
    editableLayout: false,
    settings: null,
  },
  {
    title: 'Mention breakdown by category',
    type: 'category_breakdown',
    hint: HintsContent.HINT_MENTION_BREAKDOWN_BY_CATEGORY,
    displayType: DISPLAY_TYPE.MERGED,
    // displayType: DISPLAY_TYPE.SPLIT,
    editableLayout: false,
    settings: null,
  },
  {
    title: 'Waterfall view by category',
    type: 'thematics_horizontal',
    hint: HintsContent.HINT_HORIZONTAL_BENCHMARK_GRAPH,
    displayType: DISPLAY_TYPE.MERGED,
    // displayType: DISPLAY_TYPE.SPLIT,
    editableLayout: false,
    settings: null,
  },
];

export default mock;
