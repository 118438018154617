import { Row, Col } from 'reactstrap';
import QAddButton from '_Components/Buttons/QAddButton/QAddButton';
import {
  COLUMN_DEFAULT,
  COLUMN_WIDTH,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/columnsConfig';
import { lightBlue, themeColors } from 'styles/abstracts/colors';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { createTemporaryItem } from '_Resources/Benchmark/Actions/Items/createTemporaryItem';
import { deleteTemporaryItem } from '_Resources/Benchmark/Actions/Items/deleteTemporaryItem';
import { saveTemporaryItem } from '_Resources/Benchmark/Actions/Items/saveTemporaryItem';
import { connect } from 'react-redux';
import AddModal from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/AddModal';
import { get } from 'lodash';
import ModalFiltersPage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/ModalFiltersPage';
import { cardCollapseContent } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/configs/CollapseContents';
import { CONTEXT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import ModalNamePage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/ModalNamePage';
import PropTypes from 'prop-types';

class BenchmarkAddCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  openCreateCardModal() {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.dispatchCreateCard({
      settings: COLUMN_DEFAULT,
      id: null,
      context: CONTEXT.CARD,
    });
  }

  render() {
    const { isOpen } = this.state;
    const { columns, t } = this.props;
    const cardTitle = get(this.props, ['temporaryCard', 'title']);
    const cardSettings = get(this.props, ['temporaryCard', 'settings']);

    const MODAL_CONTENT_CONFIG = {
      cancelButton: {
        text: t('button:Cancel'),
      },
      validateButton: {
        text: t('button:Create this card'),
        disabled: !cardTitle,
      },
      steps: [
        {
          title: 'Step 0',
          stepBody: (
            <ModalFiltersPage
              stepTitle={t('Choose filters for this column')}
              columnCollapseContent={cardCollapseContent}
              context={CONTEXT.CARD}
              currentSettingsFilters={cardSettings}
            />
          ),
          nextButtonText: t('button:Next step'),
        },
        {
          title: 'Step 1',
          stepBody: (
            <ModalNamePage
              stepTitle={t('Name your card')}
              context={CONTEXT.CARD}
              name={cardTitle}
            />
          ),
          prevButtonText: t('button:Prev. step'),
        },
      ],
    };

    return (
      <>
        <Row>
          <Col>
            {columns.length > 0 && (
              <QAddButton
                onClick={() => this.openCreateCardModal()}
                width={`${columns.length * COLUMN_WIDTH}px`}
                text={t('Add a new E-Index card')}
                color={themeColors.secondary}
                hoverColor={lightBlue}
              />
            )}
          </Col>
        </Row>
        <AddModal
          disableOnClickOutside
          isOpen={isOpen}
          closeModal={() => this.setState({ isOpen: !isOpen })}
          onCancel={this.props.dispatchDeleteTemporaryCard}
          onSave={this.props.dispatchSaveTemporaryCard}
          temporarySettings={this.props.temporaryCard}
          title={t('Add a card')}
          subtitle={t('Easy-peasy... Just follow those X steps !', {
            steps: 2,
          })}
          config={{ mentions: false, date: false }}
          modalConfig={MODAL_CONTENT_CONFIG}
        />
      </>
    );
  }
}

BenchmarkAddCard.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchDeleteTemporaryCard: PropTypes.func.isRequired,
  dispatchSaveTemporaryCard: PropTypes.func.isRequired,
  dispatchCreateCard: PropTypes.func.isRequired,
  temporaryCard: PropTypes.objectOf(PropTypes.node).isRequired,
};

function mapStateToProps(state) {
  return {
    temporaryCard: get(state, ['benchmark', 'temporaryCard']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreateCard: newState => {
      dispatch(createTemporaryItem(newState));
    },
    dispatchDeleteTemporaryCard: () => {
      dispatch(deleteTemporaryItem(CONTEXT.CARD));
    },
    dispatchSaveTemporaryCard: () => {
      dispatch(saveTemporaryItem(CONTEXT.CARD));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark', 'button')(BenchmarkAddCard));
