import { Collapse } from 'reactstrap';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { MessageTable } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/MessageTable';
import { CollapseContent } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/CollapseContent';

const DateRangeMergeMessage = ({ context, isOpen, criteriaKeysResults, t }) => (
  <CollapseContent isOpen={isOpen}>
    <Collapse isOpen={isOpen}>
      {criteriaKeysResults.length > 0 ? (
        <MessageTable>
          <tr>
            <th>{t('Type')}</th>
            <th>{t('Values')}</th>
          </tr>
          {criteriaKeysResults.map(criteriaKey => (
            <tr>
              <td>{criteriaKey.name}</td>
              <td>{criteriaKey.values.join(', ')}</td>
            </tr>
          ))}
        </MessageTable>
      ) : (
        <p className="font-weight-bold">
          {' '}
          {t('No filter set on this cardColumn nor the global context', {
            type: `$t(${context})`,
          })}
        </p>
      )}
    </Collapse>
  </CollapseContent>
);

export default withTranslation('conflicts')(DateRangeMergeMessage);
