import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fontSizes } from 'styles/abstracts/typography';
import { DATE_RANGE_VALUES } from '../../../../Routes/GlobalExperience/Reporting/_Containers/configs/settingOptions';
import { MenuItemLabel } from '../../../../Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import { CheckboxContainer, RadioButton } from '../CLineChartSettings';

const TitleSection = styled.p`
  font-size: ${fontSizes.bigger};
  margin-bottom: 0.5em;
`;

class RangeDisplaySettings extends React.Component {
  rangeCheckboxClicked = period => {
    const { onSettingsChange, settings } = this.props;
    onSettingsChange({ ...settings, range: period });
  };

  render() {
    const { range, t } = this.props;
    return (
      <div>
        <TitleSection>{t('Range display')}</TitleSection>
        {Object.keys(DATE_RANGE_VALUES).map(period => (
          <>
            <CheckboxContainer>
              <MenuItemLabel checked={range === DATE_RANGE_VALUES[period].id}>
                <RadioButton
                  type="radio"
                  checked={range === DATE_RANGE_VALUES[period].id}
                  onChange={() => this.rangeCheckboxClicked(DATE_RANGE_VALUES[period].id)}
                  value={DATE_RANGE_VALUES[period].id}
                />
              </MenuItemLabel>
              {t(DATE_RANGE_VALUES[period].label)}
            </CheckboxContainer>
            <br />
          </>
        ))}
      </div>
    );
  }
}

export default withTranslation('button')(RangeDisplaySettings);
