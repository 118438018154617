import gql from 'graphql-tag';

const BenchmarkPeriodFragment = gql`
  fragment BenchmarkPeriodFragment on BenchmarkPeriod {
    value
    delta
    period {
      startDate
      endDate
    }
    __typename
  }
`;

export default BenchmarkPeriodFragment;
