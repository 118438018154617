import { select } from 'd3-selection';
import { getTransitionDuration, line } from './tile';
import colorInsightTiles from './utils/colors/colors';
import getModuleIconPath from './moduleTileIcons';
import { AXIS } from '../calculation/grid';

function getX(pointData) {
  const { point } = pointData;
  return point[AXIS.X];
}

function getY(pointData) {
  const { point } = pointData;
  return point[AXIS.Y];
}

function printText(selection, module, fill) {
  return selection
    .attr('x', getX)
    .attr('y', getY)
    .attr('dy', '30px')
    .style('text-anchor', 'middle')
    .style('font', 'bold 24px Nunito sans')
    .style('fill', fill)
    .text(() => module);
}

export default function createModuleTileFactory({ container, pathResolver }) {
  return function moduleTileFactory({ point, indicator, module }) {
    const [{ backgroundColor }] = colorInsightTiles(indicator);
    const type = 'moduleTile';
    const data = point ? [point] : [];
    const update = select(container)
      .selectAll(`path.${type}`)
      .data(data);

    update
      .transition(getTransitionDuration())
      .attr('d', ({ point: p }) => {
        const coords = pathResolver(p);
        return line(coords);
      })
      .attr('fill', '#F0F2F9')
      .attr('stroke', backgroundColor)
      .attr('stroke-width', '4')
      .attr('stroke-dasharray', '15,15');

    update
      .enter()
      .append('path')
      .transition(getTransitionDuration())
      .attr('class', type)
      .attr('d', ({ point: p }) => {
        const coords = pathResolver(p);
        return line(coords);
      })
      .attr('fill', '#F0F2F9')
      .attr('stroke', backgroundColor)
      .attr('stroke-width', '4')
      .attr('stroke-dasharray', '15,15');

    update
      .exit()
      .transition(getTransitionDuration())
      .attr('fill', 'white')
      .remove();

    const updateIcon = select(container)
      .selectAll(`path.moduleIcon`)
      .data(data);

    const iconPath = getModuleIconPath(module);

    updateIcon
      .transition(getTransitionDuration())
      .attr('d', iconPath)
      .attr('fill', backgroundColor)
      .attr('transform', ({ point }) => {
        const coords = [point[AXIS.X] - 25, point[AXIS.Y] - 40];
        return `translate(${coords})`;
      });

    updateIcon
      .enter()
      .append('path')
      .attr('class', 'moduleIcon')
      .attr('d', iconPath)
      .attr('fill', backgroundColor)
      .attr('transform', ({ point }) => {
        const coords = [point[AXIS.X] - 25, point[AXIS.Y] - 40];
        return `translate(${coords})`;
      })
      .style('opacity', 0)
      .transition(getTransitionDuration())
      .style('opacity', 1);

    updateIcon
      .exit()
      .transition(getTransitionDuration())
      .attr('fill', 'white')
      .remove();

    const updateLabel = select(container)
      .selectAll(`text.moduleIcon`)
      .data(data);

    printText(updateLabel, module, backgroundColor);
    printText(
      updateLabel
        .enter()
        .append('text')
        .attr('class', 'moduleIcon'),
      module,
      backgroundColor,
    );

    updateLabel
      .exit()
      // .transition(getTransitionDuration())
      // .attr('fill', 'white')
      .remove();

    return { tile: { update } };
  };
}
