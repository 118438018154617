import { SELECTIONS, RECOMMENDATIONS } from 'constants/index.js';
import { grayShades, purple } from 'styles/abstracts/colors';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { PureComponent } from 'react';

const TRIGGERS_EMOTIONS = {
  happiness: {
    color: '#DB2156',
    icon: 'EMOTION_HAPPINESS',
  },
  surprise: {
    color: '#FF8104',
    icon: 'EMOTION_SURPRISE',
  },
  calm: {
    color: '#F5D800',
    icon: 'EMOTION_CALM',
  },
  fear: {
    color: '#8BDB00',
    icon: 'EMOTION_FEAR',
  },
  sadness: {
    color: '#0EC3E3',
    icon: 'EMOTION_SADNESS',
  },
  anger: {
    color: '#007AFF',
    icon: 'EMOTION_ANGER',
  },
  disgust: {
    color: '#222C66',
    icon: 'EMOTION_DISGUST',
  },
};

const TRIGGERS_SENTIMENTS = {
  positive: {
    color: '#8bdb00',
    icon: 'SENTIMENT_POSITIVE',
  },
  neutral: {
    color: '#c3c8d9',
    icon: 'SENTIMENT_NEUTRAL',
  },
  negative: {
    color: '#000000',
    icon: 'SENTIMENT_NEGATIVE',
  },
};

export const allAxisColors = {
  ...TRIGGERS_SENTIMENTS,
  ...TRIGGERS_EMOTIONS,
  ...SELECTIONS,
  ...RECOMMENDATIONS,
};

export const addOriginalColorsInData = data => {
  if (data?.length > 0) {
    const dataWithOriginalColors = data.map(dataItem => {
      if (dataItem?.originalColor === null) {
        if (allAxisColors[dataItem.name]?.color !== undefined) {
          dataItem.originalColor = allAxisColors[dataItem.name].color;
          dataItem.color = allAxisColors[dataItem.name].color;
          return dataItem;
        }
        dataItem.originalColor = purple;
        dataItem.color = purple;
      }
      return dataItem;
    });
    return dataWithOriginalColors;
  }
  return data;
};

export const getAnalysisAxisOptions = t => [
  {
    value: 'emotion',
    label: t('button:Emotion'),
  },
  {
    value: 'sentiment',
    label: t('button:Sentiment'),
  },
  {
    value: 'verbatims_selection',
    label: t('button:Verbatim selection'),
  },
  {
    value: 'satisfaction',
    label: t('button:Satisfaction'),
  },
  {
    value: 'effort',
    label: t('button:Customer Effort Score'),
  },
  {
    value: 'reco',
    label: t('button:Recommendation'),
  },
  {
    value: 'nps',
    label: t('button:Net Promoter Score'),
  },
];

export const getCalculationCriteriaOptions = t => [
  {
    value: 'percentage',
    label: t('button:Percentage'),
  },
  {
    value: 'volume',
    label: t('button:Volume'),
  },
];

export const getItemsToDisplayVariable = (
  itemsToDisplay: {
    id?: String;
    label: String;
    value?: String;
    values?: String[];
  },
  itemsToDisplayOptions: Array<{
    value: String;
    label: String;
    subOptions?: Array<{
      id: String;
      label: String;
      values: String[];
    }>;
  }>,
): { name: String; key?: String } => {
  if (itemsToDisplay && itemsToDisplay.value) {
    return { name: itemsToDisplay.value };
  }
  const optionsWithSubOptions = itemsToDisplayOptions.filter(option => option.subOptions);

  if (optionsWithSubOptions && optionsWithSubOptions?.length > 0) {
    const subOptionParentValue = optionsWithSubOptions.find(option =>
      option.subOptions?.find(
        (subOption: { id: String; label: String; values: String[] }) => subOption?.id === itemsToDisplay?.id,
      ),
    )?.value;

    if (typeof subOptionParentValue === 'string') {
      return { name: subOptionParentValue, key: itemsToDisplay.label };
    }
  }
  return { name: '' };
};

export const getStateItemsToDisplay = (
  itemsToDisplayVariable: {
    name: String;
    key?: String | null;
  },
  itemsToDisplayOptions: Array<{
    value: String;
    label: String;
    subOptions?: Array<{
      id: String;
      label: String;
      values: String[];
    }>;
  }>,
): { id?: String; label: String; value?: String; values?: String[] } => {
  if (itemsToDisplayVariable?.key) {
    return itemsToDisplayOptions
      .find(option => option.value === itemsToDisplayVariable.name)
      ?.subOptions.find(subOption => subOption.label === itemsToDisplayVariable.key);
  }
  return itemsToDisplayOptions.find(option => option.value === itemsToDisplayVariable?.name);
};

export const getItemsToDisplayOptions = (
  criteriaKeysOptions: Array<{
    id: String;
    label: String;
    values: Array<String>;
  }>,
  t: Function,
) => {
  return [
    {
      value: 'thematic',
      label: t('filters:Thematics'),
    },
    {
      value: 'sub_thematic',
      label: t('filters:Sub thematic'),
    },
    {
      value: 'sub_sub_thematic',
      label: t('filters:Sub sub thematic'),
    },
    // {
    //   value: 'clusters',
    //   label: 'Irritants & Enchanting points',
    // },
    {
      value: 'criteria_key',
      label: t('filters:Criteria keys'),
      subOptions: criteriaKeysOptions,
    },
  ];
};

export const emptyTriggerTable = {
  title: '',
  description: '',
  isDefault: false,
  settings: {
    analysisAxis: 'emotion',
    itemsToDisplay: {
      value: 'thematic',
      label: 'Thématiques',
    },
    calculationCriteria: {
      label: 'Pourcentage',
      value: 'percentage',
    },
    significanceThreshold: 1,
    range: {
      startDate: '',
      endDate: '',
    },
    thematics: [],
    criteriaKeys: [],
  },
  data: [],
  customizations: [],
};
