export const UPDATE_REPORTING_MODE = 'REPORTING_UPDATE_REPORTING_MODE';

export const REPORTING_MODES = {
  GLOBAL: 'global',
  PAGE: 'page',
  CARD: 'card',
};

export const SELECTED_TABS = {
  [REPORTING_MODES.GLOBAL]: 'add-pages',
  [REPORTING_MODES.PAGE]: 'change-page',
  [REPORTING_MODES.CARD]: 'change-card',
};

export const updateReportingMode = (mode, pageIndex, cardIndex) => ({
  type: UPDATE_REPORTING_MODE,
  pageIndex,
  cardIndex,
  mode,
  selectedTab: SELECTED_TABS[mode],
});
