import gql from 'graphql-tag';

const mutation = gql`
  mutation renameProject($id: Int!, $name: String!) {
    renameProject(id: $id, name: $name) {
      name
      updatedAt
      errors
    }
  }
`;

export default mutation;
