import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import QButton from '../../Buttons/QButton/QButton';

const RangeSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SliderSection = styled.div`
  flex-grow: 1;
  padding: 1em;
`;

const ButtonsSection = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
`;

const StyledButton = styled(QButton)`
  margin: 0.5em;
`;

const RangeSectionComponent = ({ children, onReset, t }) => (
  <RangeSection>
    <SliderSection>{children}</SliderSection>
    <ButtonsSection>
      <StyledButton outline size="xl" bgColor="red" onClick={onReset}>
        {t('Reset')}
      </StyledButton>
    </ButtonsSection>
  </RangeSection>
);

export default withTranslation('button')(RangeSectionComponent);
