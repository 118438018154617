import React, { Component } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import QTitle from '../../../../../_Components/Texts/QTitle/QTitle';
import FilterInput from '../../_Components/FilterInput';

const addFilterCategory = (category, filters) =>
  filters.map(filter => ({
    ...filter,
    filterCategory: category,
  }));

class NormalFilter extends Component {
  static propTypes = {
    normalFilters: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    onRemoved: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
  };

  createNormalFilters = memoizeOne(normalFilters => {
    const { onRemoved, onSelected, values: initialValues } = this.props;
    return normalFilters.map(({ id, label, values }) => {
      const currentInitialValues = (initialValues || []).filter(
        ({ filterCategory }) => filterCategory === id,
      );
      return (
        <FilterInput
          initialValues={currentInitialValues}
          onRemoved={filters => onRemoved(addFilterCategory(id, filters))}
          onSelected={filters => onSelected(addFilterCategory(id, filters))}
          options={values.map(value => ({ id: value, label: value }))}
          name={label}
        />
      );
    });
  });

  render() {
    const { t, normalFilters } = this.props;
    return (
      <div>
        <QTitle>
          <QTitle.Title>{t('Other filters')}</QTitle.Title>
        </QTitle>
        {this.createNormalFilters(normalFilters)}
      </div>
    );
  }
}

const mapStateToProps = ({ filters }) => ({
  normalFilters: get(filters, 'projectFilters.criteriaKeys.normal', []),
});

export default connect(mapStateToProps)(withTranslation('reporting')(NormalFilter));
