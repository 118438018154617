import React, { Component, Fragment, createContext } from 'react';
import PropTypes from 'prop-types';
import { forEach, type } from 'ramda';
import styled from 'styled-components';
import { preparePropertyComparer } from '../../utils/commonHelpers';
import { GoogleListenerHandler } from '../../utils/GoogleListenersHandler';

export const MAP_DEFAULTS = {
  center: { lat: 48.852, lng: 2.35 },
  zoom: 10,
};

export const MapContext = createContext({
  map: null,
  google: null,
});

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export default class Map extends Component {
  state = { context: { map: null, google: null } };

  mapContainer = null;

  isPropChanged = preparePropertyComparer(this);

  internalListeners = null;

  externalListeners = null;

  setMapRef = mapContainer => (this.mapContainer = mapContainer);

  componentDidMount() {
    this.loadMap();
  }

  componentWillUnmount() {
    this.internalListeners &&
      this.externalListeners &&
      this.clearMapListeners();
  }

  componentDidUpdate(prevProps) {
    if (this.isPropChanged('google', prevProps)) {
      this.loadMap();
    }

    if (this.isPropChanged('options', prevProps)) {
      this.props.options && this.updateOptions();
    }

    if (this.isPropChanged('zoom', prevProps)) {
      this.props.zoom && this.updateZoom();
    }

    if (this.isPropChanged('bounds', prevProps)) {
      this.props.bounds && this.updateBounds();
    }

    if (this.isPropChanged('listeners', prevProps)) {
      this.props.listeners && this.updateListeners();
    }
  }

  loadMap() {
    const { google } = this.props;
    if (!google) {
      return;
    }

    const mapOptions = this.prepareMapOptions(this.props.options || {});
    const mergedOptions = this.mergeOptsWithExposedOptsProps(mapOptions);
    const bounds = this.prepareBounds(this.props.bounds || []);
    const map = this.mapContainer
      ? new google.maps.Map(this.mapContainer, mergedOptions)
      : new google.maps.Map(
          document.getElementsByClassName('map-container')[0],
          mergedOptions,
        );
    this.createMapListeners(map);
    this.resetListeners(this.props.listeners);
    if (!bounds.isEmpty()) {
      map.fitBounds(bounds);
    }
    this.setState({ context: { map, google } });
  }

  createMapListeners(map) {
    this.internalListeners = new GoogleListenerHandler(map);
    this.externalListeners = new GoogleListenerHandler(map);

    this.internalListeners.resetListeners({
      zoom_changed: () => this.notifyZoomChange(map.getZoom()),
    });
  }

  clearMapListeners() {
    this.internalListeners.removeListeners();
    this.externalListeners.removeListeners();
  }

  mergeOptsWithExposedOptsProps(options) {
    const { zoom = options.zoom } = this.props;
    return {
      ...options,
      zoom,
    };
  }

  updateListeners() {
    const { listeners } = this.props;
    if (!this.externalListeners) {
      return;
    }

    this.resetListeners(listeners);
  }

  resetListeners(listeners = {}) {
    this.externalListeners.resetListeners(listeners);
  }

  updateZoom() {
    const { map } = this.state.context;
    const { zoom } = this.props;
    if (!map || !zoom) return;

    map.setOptions({ zoom });
  }

  updateOptions() {
    const { map } = this.state.context;
    const { options } = this.props;
    if (!map || !options) {
      return;
    }
    const mapOptions = this.prepareMapOptions(options);
    map.setOptions(mapOptions);
  }

  updateBounds() {
    const { map } = this.state.context;
    const { bounds } = this.props;
    if (!map || !bounds) {
      return;
    }

    const gBounds = this.prepareBounds(bounds);
    map.fitBounds(gBounds);
  }

  notifyZoomChange(zoom) {
    const { onMapZoomChanged } = this.props;
    if (type(onMapZoomChanged) === 'Function') {
      onMapZoomChanged(zoom);
    }
  }

  prepareBounds(bounds) {
    const gBounds = new this.props.google.maps.LatLngBounds();
    forEach(this.createNewBounds(gBounds), bounds);
    return gBounds;
  }

  createNewBounds(bounds) {
    return function extendBounds(position) {
      bounds.extend(position);
    };
  }

  prepareMapOptions(options) {
    return {
      ...MAP_DEFAULTS,
      ...options,
    };
  }

  render() {
    return (
      <>
        <MapContainer innerRef={this.setMapRef} className="map-container" />
        <MapContext.Provider value={this.state.context}>
          {this.props.children}
        </MapContext.Provider>
      </>
    );
  }
}

Map.propTypes = {
  google: PropTypes.object,
  options: PropTypes.object,
  bounds: PropTypes.arrayOf(
    PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  ),
  zoom: PropTypes.number,
  listeners: PropTypes.objectOf(PropTypes.func),
  onMapZoomChanged: PropTypes.func,
};
