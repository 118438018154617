import React, { useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { selectInsightDetails } from '_Resources/Insight/Actions/selectInsightDetails';
import propTypes from './VerbatimSentences.props';
import VerbatimTextBubble from './VerbatimTextBubble';
import { fontSizes } from '../../../styles/abstracts/typography';
import { grayShades } from '../../../styles/abstracts/colors';
import VerbatimDetails from '../_Containers/VerbatimDetails/VerbatimDetails';

const DetailsButton = styled.span`
  border: 1px solid;
  border-radius: 20px;
  font-size: ${fontSizes.smallest};
  font-weight: bolder;
  padding: 0.5em;
  color: ${grayShades.g800};
  cursor: pointer;
`;

const VerbatimText = styled.p`
  padding-right: 1em;
`;

const prepareSentenceText = (sentences, currentSentence) => {
  const finalSentence = [];
  if (sentences[currentSentence.order - 1]) {
    finalSentence.push(
      <span style={{ color: '#928FB0', margin: '2px' }}>
        {sentences[currentSentence.order - 2] && '(...)'}
        {sentences[currentSentence.order - 1].sentence}
      </span>,
    );
  }
  finalSentence.push(<strong>{currentSentence.sentence}</strong>);
  if (sentences[currentSentence.order + 1]) {
    finalSentence.push(
      <span style={{ color: '#928FB0', margin: '2px' }}>
        {sentences[currentSentence.order + 1].sentence}
        {sentences[currentSentence.order + 2] && '(...)'}
      </span>,
    );
  }
  return finalSentence;
};

const QVerbatimSpreadSentences = ({
  deletable,
  verbatim,
  selectedSentences,
  onSelect,
  onVerbatimRemoved,
  projectId,
  insightId,
  currentRange,
  dispatchSelectInsight,
  prepareIsSentenceSelected,
  t,
}) => {
  const [seeDetails, toggleSeeDetails] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);
  const { order, id, sentenceId, verbatimSentences } = verbatim;

  const selected = prepareIsSentenceSelected(id)(verbatim, selectedSentences);
  const onSentenceSelect = () =>
    onSelect({ id, order, selected: !selected, sentenceId });
  const onSentenceRemoved = () =>
    onVerbatimRemoved({ id, order, selected: !selected, sentenceId });
  const sentenceInfos = {
    sentenceId,
    projectId,
    insightId,
  };
  const modalFilters = {
    id,
    type: 'VERBATIM',
  };
  return (
    <>
      <VerbatimTextBubble
        deletable={deletable}
        onSentenceRemove={onSentenceRemoved}
        selected={selected}
        onSentenceSelect={onSentenceSelect}
        sentenceInfos={sentenceInfos}
        projectId={projectId}
        range={currentRange}
        dispatchSelectInsight={dispatchSelectInsight}
        onMouseEnter={() => toggleSeeDetails(true)}
        onMouseLeave={() => toggleSeeDetails(false)}
        modalFilters={modalFilters}
      >
        <VerbatimText>
          {prepareSentenceText(verbatimSentences, verbatim)}{' '}
          {deletable && seeDetails && (
            <DetailsButton onClick={() => toggleModal(!isModalOpen)}>
              {t('See details')}
            </DetailsButton>
          )}
        </VerbatimText>
      </VerbatimTextBubble>
      {isModalOpen && (
        <VerbatimDetails
          isOpen={isModalOpen}
          toggle={toggleModal}
          modalFilters={modalFilters}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  projects: { currentProject },
  insight: { insightDetails },
  filters: { filterKeys },
}) => ({
  projectId: get(currentProject, ['id']),
  insightId: get(insightDetails, ['insightId']),
  currentRange: get(filterKeys, ['range']),
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectInsight: (...args) => dispatch(selectInsightDetails(...args)),
});

QVerbatimSpreadSentences.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('button')(QVerbatimSpreadSentences));
