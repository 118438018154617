import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import FiltersSection from '../../../_Components/FiltersSection';
import {
  addPageFilters,
  FILTER_TYPES,
} from '../../../../../../_Resources/Reporting/Actions/PageSettings/addPageFilters';
import { removePageFilters } from '../../../../../../_Resources/Reporting/Actions/PageSettings/removePageFilters';
import { pageInitialConfigState } from '../../../../../../_Resources/Reporting/Reducers/reporting';
import { updateDisplaySettings } from '../../../../../../_Resources/Reporting/Actions/PageSettings/updateDisplaySettings';

class Filters extends Component {
  static propTypes = {
    // state props
    filters: PropTypes.any.isRequired,
    currentPageIndex: PropTypes.number.isRequired,

    // dispatch props
    onThematicsSelected: PropTypes.func.isRequired,
    onThematicsRemoved: PropTypes.func.isRequired,
    onOthersSelected: PropTypes.func.isRequired,
    onOthersRemoved: PropTypes.func.isRequired,
    dispatchUpdateDisplaySettings: PropTypes.func.isRequired,
  };

  resetFilters = () => {
    const { dispatchUpdateDisplaySettings, currentPageIndex } = this.props;
    dispatchUpdateDisplaySettings(
      { filters: pageInitialConfigState.filters },
      currentPageIndex,
      null,
    );
  };

  render() {
    const {
      filters,
      onThematicsSelected,
      onThematicsRemoved,
      onOthersSelected,
      onOthersRemoved,
    } = this.props;

    return (
      <FiltersSection
        isModified={!isEqual(filters, pageInitialConfigState.filters)}
        onThematicsSelected={onThematicsSelected}
        onThematicsRemoved={onThematicsRemoved}
        onOthersSelected={onOthersSelected}
        onOthersRemoved={onOthersRemoved}
        onReset={this.resetFilters}
        thematics={(filters || {}).thematic}
        other={(filters || {}).normal}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onThematicsSelected: filterValues =>
    dispatch(addPageFilters(FILTER_TYPES.THEMATIC, filterValues)),
  onThematicsRemoved: (...args) => {
    dispatch(removePageFilters(FILTER_TYPES.THEMATIC, args));
  },
  onOthersSelected: filterValues =>
    dispatch(addPageFilters(FILTER_TYPES.NORMAL, filterValues)),
  onOthersRemoved: filterValues =>
    dispatch(removePageFilters(FILTER_TYPES.NORMAL, [filterValues, ''])),
  dispatchUpdateDisplaySettings: (...args) =>
    dispatch(updateDisplaySettings(...args)),
});

const mapStateToProps = ({ reporting: { currentPageIndex, children } }) => {
  const { filters } = children[currentPageIndex];
  return {
    currentPageIndex,
    filters,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
