import { connect } from 'react-redux';
import TabPanel from '../_Components/TabPanel';
import { selectReportingTab } from '../../../../_Resources/Reporting/Actions/selectReportingTab';

const mapStateToProps = ({ reporting: { mode, selectedTab } }) => ({
  mode,
  selectedTab,
});

const mapDispatchToProps = dispatch => ({
  onTabSelected: tab => dispatch(selectReportingTab(tab)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabPanel);
