import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import FileUploader from '_Components/FileManagement/FileUploader/FileUploader';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QPanelHeader from '_Components/Panels/QPanelHeader/QPanelHeader';
import QButton from '_Components/Buttons/QButton/QButton';

import { grayShades } from 'styles/abstracts/colors';

import CURRENT_USER_QUERY from '_Resources/Header/Services/api/graphql/currentUser';
import UPLOAD_COMPANY_LOGO_MUTATION from './graphql/uploadCompanyLogo';

const StyledQPanel = styled(QPanel)`
  padding: 0;
  margin-top: 2rem;
`;

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 1rem;
  margin: 0 1rem;
  border-bottom: 1px solid ${grayShades.g300};
  &:last-child {
    border-bottom: 0;
  }
`;

type UploadCompanyLogoCardProps = {
  t: any;
  accountLogo: string;
  currentUser: any;
};

type FileStatus = 'no_file' | 'uploaded' | 'error';

const UploadCompanyLogoCard = ({ t, accountLogo, currentUser }: UploadCompanyLogoCardProps) => {
  const containerRef = useRef(null);
  const [fileState, setFileState] = useState<FileStatus>('no_file');
  const [deleteCompanyLogo] = useMutation(UPLOAD_COMPANY_LOGO_MUTATION, {
    variables: { file: null },
    update: (cache, { data: { uploadCompanyLogo } }) => {
      try {
        const { currentUser: user } = cache.readQuery({
          query: CURRENT_USER_QUERY,
        });
        cache.writeQuery({
          query: CURRENT_USER_QUERY,
          data: {
            currentUser: { ...user, account: { ...user.account, logo: null } },
          },
        });
      } catch (e) {
        cache.writeQuery({
          query: CURRENT_USER_QUERY,
          data: {
            currentUser: {
              ...currentUser,
              account: { ...currentUser.account, logo: null },
            },
          },
        });
      }
    },
  });
  const location = useLocation();
  useEffect(() => {
    if (containerRef.current) {
      location?.state?.toLogo && containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <StyledQPanel ref={containerRef}>
      <StyledQPanelHeader>
        <div>{t('card:Company logo')}</div>
        <div>
          <FileUploader
            acceptedFormat={['.jpg', '.png']}
            t={t}
            fileState={fileState}
            fileUploadMutation={UPLOAD_COMPANY_LOGO_MUTATION}
            setFileState={setFileState}
            uploadedText="Everything seems to be ok !"
            defaultIllustration={accountLogo && `${process.env.REACT_APP_BACKEND_URL}${accountLogo}`}
          />
        </div>
        <div>
          <QButton onClick={() => deleteCompanyLogo()}>{t('button:Delete logo')}</QButton>
        </div>
      </StyledQPanelHeader>
    </StyledQPanel>
  );
};

const mapStateToProps = state => ({
  accountLogo: get(state, ['auth', 'user', 'account', 'logo']),
  currentUser: get(state, ['auth', 'user']),
});

export default withTranslation(['card', 'button'])(connect(mapStateToProps)(UploadCompanyLogoCard));
