import React, { useState, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { InMemoryCache } from 'apollo-boost';
import ApolloClient from 'apollo-client';
import { from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ApolloProvider } from 'react-apollo';
import { I18nextProvider } from 'react-i18next';
import apolloLogger from 'apollo-link-logger';
import { createUploadLink } from 'apollo-upload-client';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '_Resources';
import Rollbar from 'rollbar';
import { createGlobalStyle } from 'styled-components';
import { loginFailure, logout } from '_Resources/Auth/Actions/auth';
import 'styles/main.scss';
import fr from 'moment/locale/fr';
import es from 'moment/locale/es';
import de from 'moment/locale/de';
import en from 'moment/locale/en-gb';
import moment from 'moment';
import { get } from 'lodash';
import { purple, white } from 'styles/abstracts/colors';
import AppRouter from 'Routes/AppRouter';
import LogRocket from 'logrocket';
import i18n from './i18n/i18n';

const GlobalStyle = createGlobalStyle`

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

    & .btn:focus,
    .btn-secondary:focus {
      box-shadow: none !important;
    }
  }

  body {
    font-family: "Nunito", serif;
    min-height: 100vh;
    overflow-x: hidden;
    min-height: 100vh;
    & * {
      /* Scrollbar style on Firefox */
      scrollbar-color: ${purple} ${white};
      scrollbar-width: thin;
      /* Scrollbar style on webkit based browsers */
       ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0.45em;
        height: 0.45em;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: ${purple};
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }

  .dropdown-menu:not(#ddcustom):not(.dataByFiltersDropdownMenu):not(.activeProjectRowSettings):not(.qdatepicker):not(.imageProfilDropdown):not(.emotionsTriggersDropdownMenu) {
    margin-top: -2.5em !important;
  }

  .ag-center-cols-container {
    /* width: 100% !important; */
  }

  .pac-container {
    z-index: 10000 !important;
  }
`;

const composeEnhancers =
  process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
    : compose;

const store = createStore(
  rootReducer,
  window.App ? window.App.state : {},
  composeEnhancers(applyMiddleware(thunk, LogRocket.reduxMiddleware())),
);

const getLang = lang => {
  let selectedLang;
  switch (lang) {
    case 'fr':
      selectedLang = fr;
      break;
    case 'es':
      selectedLang = es;
      break;
    case 'de':
      selectedLang = de;
      break;
    case 'en':
    default:
      selectedLang = en;
  }
  return selectedLang;
};

const defineUri = () => process.env.REACT_APP_GRAPHQL_ENDPOINT;

const App = () => {
  const [token, setToken] = useState(window.localStorage.getItem('token'));

  process.env.NODE_ENV === 'production' &&
    LogRocket.init('pxyr3z/cxinsights', {
      shouldCaptureIP: false,
      mergeIframes: true,
      network: {
        requestSanitizer: request => {
          if (
            request.url.toLowerCase().indexOf('graphql') !== -1 &&
            JSON.parse(request.body).operationName === 'signIn'
          ) {
            const newBody = JSON.parse(request.body);
            delete newBody.variables.password;
            request.body = newBody;
          }
          return request;
        },
      },
    });
  const rollbar =
    process.env.NODE_ENV === 'production' &&
    new Rollbar({
      accessToken: 'd6d4f2355cda4841ad94a5d2c5501627',
      captureUncaught: true,
      captureUnhandledRejections: true,
      ignoredMessages: ['Token expired'],
    });

  moment.relativeTimeThreshold('s', 60);
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 24);
  moment.relativeTimeThreshold('d', 30);
  moment.relativeTimeThreshold('M', 12);

  const client = new ApolloClient({
    link: from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(error => {
            console.warn(
              `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}, Status Code: ${error.path}`,
            );
            return error;
          });
          // eslint-disable-next-line no-restricted-syntax
          for (const err of graphQLErrors) {
            const statusCode = get(err, ['extensions', 'status_code']);
            console.error('App l.159 err', err);
            if (
              (statusCode && statusCode === 401) ||
              err?.extensions?.code === 'WRONG_CREDENTIALS' ||
              err?.extensions?.code === 'TOKEN_EXPIRED'
            ) {
              if (err?.extensions?.code === 'WRONG_CREDENTIALS') {
                const error = [{ message: 'Invalid email or password' }];
                store.dispatch(loginFailure(error));
              } else {
                store.dispatch(logout);
              }
            }
          }
        }
        if (networkError) console.warn(`[Network error]: ${networkError}`);
      }),
      ...(process.env.REACT_APP_ENV === 'DEV' ? [apolloLogger] : []),
      createUploadLink({
        uri: defineUri(),
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      }),
    ]),
    cache: new InMemoryCache({
      queryDeduplication: true,
      connectToDevTools: true,
      addTypename: false,
    }),
    __typename: process.env.REACT_APP_ENV !== 'PROD',
    connectToDevTools: false,
  });

  moment.locale(i18n.language, getLang(i18n.language));
  return (
    <div>
      <GlobalStyle />
      <ReduxProvider store={store}>
        <ApolloProvider client={client} addTypename={false}>
          <I18nextProvider i18n={i18n}>
            <AppRouter token={token} setToken={setToken} rollbar={rollbar} />
          </I18nextProvider>
        </ApolloProvider>
      </ReduxProvider>
    </div>
  );
};
export default App;

