import { isNaN } from 'lodash';
import colorInsightTiles from './colors';

// TODO: move transformations out of the tile -> pass coloring function to tiles
export const prepareCategoryColoring = (rangeType, emotion) => {
  const colors = colorInsightTiles(emotion);
  return ({ insightData: insight }) => {
    const { value } = insight.entryPoints.current[emotion];
    if (!isNaN(value)) {
      const resultColor = colors.find(color => {
        const { range } = color;
        const currentRange = range[rangeType];
        return value < currentRange.max && value >= currentRange.min;
      });
      return resultColor && resultColor.backgroundColor;
    }

    return '#DEE3F2';
  };
};
