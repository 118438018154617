export const updateReportingModeHandler = (
  state,
  { pageIndex, cardIndex, selectedTab, mode },
) => {
  const newState = {
    ...state,
    currentPageIndex: pageIndex,
    currentCardIndex: cardIndex,
    selectedTab,
    mode,
    currentCard: null,
  };

  if (mode !== 'card') {
    return newState;
  }

  const pages = state.children;
  const currentPage = pages[pageIndex];
  const currentCard = currentPage.children[cardIndex];

  newState.currentCard = currentCard.content;
  return newState;
};
