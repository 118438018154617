export const getTranslatedSentence = (
  verbatim: any,
  userPreferedLocale: string,
) => {
  switch (userPreferedLocale) {
    case verbatim.thematicalTextLocale:
      return verbatim.thematicalText || verbatim.sentence;
    case verbatim.emotionalTextLocale:
      return verbatim.emotionalText || verbatim.sentence;
    case 'default':
      return verbatim.sentence;
    default:
      return verbatim.sentence;
  }
};

export const getTranslatedFullVerbatim = (
  verbatim: any,
  userPreferedLocale: string,
) => {
  const translatedFullVerbatim = {
    fullVerbatim: '',
    displayedLanguage: '',
  };
  switch (userPreferedLocale) {
    case verbatim.verbatimFullThematicalTextLocale:
      if (verbatim.verbatimFullThematicalText !== null) {
        translatedFullVerbatim.fullVerbatim =
          verbatim.verbatimFullThematicalText;
        translatedFullVerbatim.displayedLanguage =
          verbatim.verbatimFullThematicalTextLocale;
      }
      if (verbatim.verbatimFullThematicalText === null) {
        translatedFullVerbatim.fullVerbatim = verbatim.verbatimFullText;
        translatedFullVerbatim.displayedLanguage = 'default';
      }
      return translatedFullVerbatim;
    case verbatim.verbatimFullEmotionalTextLocale:
      if (verbatim.verbatimFullEmotionalText !== null) {
        translatedFullVerbatim.fullVerbatim =
          verbatim.verbatimFullEmotionalText;
        translatedFullVerbatim.displayedLanguage =
          verbatim.verbatimFullEmotionalTextLocale;
      }
      return translatedFullVerbatim;
    case 'default':
      translatedFullVerbatim.fullVerbatim = verbatim.verbatimFullText;
      translatedFullVerbatim.displayedLanguage = 'default';
      return translatedFullVerbatim;
    default:
      translatedFullVerbatim.fullVerbatim = verbatim.verbatimFullText;
      translatedFullVerbatim.displayedLanguage = 'default';
      return translatedFullVerbatim;
  }
};

export const getAvailableTranslations = (
  verbatim: any,
  userPreferedLocale: string,
) => {
  if (!verbatim.availableLocales.includes('default')) {
    verbatim.availableLocales.push('default');
  }
  const uniqueLanguages = [...new Set(verbatim.availableLocales)];
  if (uniqueLanguages.includes(userPreferedLocale)) {
    return uniqueLanguages.filter(language => language !== userPreferedLocale);
  }
  return ['default'];
};

export const checkIfAvailableTranslation = (
  displayedLanguage: string,
  availableLanguages: Array<string>,
  verbatim: any,
): boolean => {
  if (!availableLanguages[0]) return false;
  if (displayedLanguage === availableLanguages[0]) return false;

  const displayedLanguageFullText = getTranslatedFullVerbatim(
    verbatim,
    displayedLanguage,
  );

  const availableLanguageFullText = getTranslatedFullVerbatim(
    verbatim,
    availableLanguages[0],
  );

  if (
    displayedLanguageFullText.fullVerbatim ===
    availableLanguageFullText.fullVerbatim
  ) {
    return false;
  }

  return true;
};

export const languagesLabels = {
  en: 'english',
  fr: 'french',
  default: 'original',
};
