import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Icon from '_Components/Icons/Icon';
import QTitle from '_Components/Texts/QTitle/QTitle';
import { fontSizes } from 'styles/abstracts/typography';
import { spacing, ImageSize } from 'styles/abstracts/variables';
import { white } from 'styles/abstracts/colors';

const TitleContainer = styled(QTitle)`
  font-size: ${fontSizes.large};
  display: flex;
  align-items: end;
  margin-left: ${spacing.large};
  @media (max-width: 1300px) {
    font-size: ${fontSizes.bigger};
  }
  @media (max-width: 1100px) {
    font-size: ${fontSizes.smaller};
  }
  @media (max-width: 900px) {
    font-size: ${fontSizes.smallest};
  }
`;

const Title = props => (
  <TitleContainer
    className="d-flex align-items-center align-content-center"
    title={props.title}
    icon={props.icon}
  >
    <Icon icon={props.icon} size={ImageSize.medium} color={white} /> &nbsp;
    {props.title}
  </TitleContainer>
);

export default withTranslation('header')(Title);
