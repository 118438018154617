import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { merge, isEqual } from 'lodash';
import memoize from 'memoize-one';

import PresentationDateRange from '../../../_Components/PresentationDateRange';
import { PagePropTypes } from '../../../../../../_Resources/Reporting/Services/types/Page.propTypes';
import { updateDisplaySettings } from '../../../../../../_Resources/Reporting/Actions/PageSettings/updateDisplaySettings';
import { pageInitialConfigState } from '../../../../../../_Resources/Reporting/Reducers/reporting';

class DateRange extends Component {
  static propTypes = {
    dispatchUpdateDisplaySettings: PropTypes.func.isRequired,
    globalSettings: PropTypes.any.isRequired,
    projectPeriods: PropTypes.any,
    currentPageIndex: PropTypes.number.isRequired,
    currentPage: PagePropTypes.isRequired,
  };

  static defaultProps = {
    projectPeriods: null,
  };

  onUpdate = dateRange => {
    const { currentPageIndex, dispatchUpdateDisplaySettings } = this.props;
    dispatchUpdateDisplaySettings({ dateRange }, currentPageIndex);
  };

  onReset = () => this.onUpdate(pageInitialConfigState.dateRange);

  prepareSettings = memoize((currentDateRange, globalDateRange) =>
    merge({}, globalDateRange, currentDateRange),
  );

  render() {
    const { globalSettings, currentPage, projectPeriods } = this.props;
    const settings = this.prepareSettings(
      currentPage.dateRange,
      globalSettings.dateRange,
    );
    return (
      <PresentationDateRange
        settings={settings}
        periods={projectPeriods}
        isModified={
          !isEqual(currentPage.dateRange, pageInitialConfigState.dateRange)
        }
        onUpdate={this.onUpdate}
        onReset={this.onReset}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDisplaySettings: (...args) =>
    dispatch(updateDisplaySettings(...args)),
});

const mapStateToProps = ({
  reporting: { globalSettings, children, currentPageIndex },
  periods: { projectPeriods },
}) => ({
  currentPage: children[currentPageIndex],
  globalSettings,
  projectPeriods,
  currentPageIndex,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(DateRange));
