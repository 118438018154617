import styled from 'styled-components';
import { FIRST_COLUMN_ADDITIONNAL_WIDTH } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/columnsConfig';

export const BenchmarkCol = styled.div`
  width: ${({ columnWidth }) => columnWidth}px !important;
  position: relative;
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding: 0 1.5rem;
  border-bottom: 6px solid ${({ columnColor }) => columnColor};

  &:first-child {
    width: ${({ columnWidth }) =>
      `calc(${columnWidth}px + ${FIRST_COLUMN_ADDITIONNAL_WIDTH} )`} !important;
  }
`;
