import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { getFontSizeStyles } from 'styles/abstracts/typography';

const Title = styled.p`
  text-align: center;
  font-weight: bold;
  ${getFontSizeStyles(20)};
`;

const CardTitle = props => {
  const { t, title, titleSecondPart } = props;
  return <Title id={`${title}-title`}>{t(title) + (titleSecondPart ? ` : ${titleSecondPart}` : '')}</Title>;
};
CardTitle.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  titleSecondPart: PropTypes.string,
};
CardTitle.defaultProps = {
  title: '',
  titleSecondPart: '',
};

export default withTranslation('card')(CardTitle);
