import React from 'react';
import { Mutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { red } from '../../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../../styles/abstracts/typography';
import { ImageSize, spacing } from '../../../../../styles/abstracts/variables';
import Icon from '_Components/Icons/Icon';
import queryReportingModels from '../graphql/getReportingModel';
import archiveReportingModelOrReporting from '../graphql/archiveReportingModelOrReporting';

const DeleteName = styled.span`
  color: ${red} !important;
  font-weight: bold;
  font-size: ${fontSizes.smaller};
`;

const Item = styled(DropdownItem)`
  padding: ${spacing.mediumSmall} ${spacing.medium} !important;
  cursor: pointer;
  line-height: 21px;
`;

class ArchiveReportingModel extends React.Component {
  render() {
    const { t, data } = this.props;
    return data ? (
      <Mutation
        mutation={archiveReportingModelOrReporting}
        update={(cache, { data: { archiveReportingModelOrReporting } }) => {
          const { projectId } = this.props.filterKeys;
          const reportingModelsList = cache.readQuery({
            query: queryReportingModels,
            variables: { projectId },
          });
          cache.writeQuery({
            query: queryReportingModels,
            variables: { projectId },
            data: {
              getReportingModel: reportingModelsList.getReportingModel.filter(
                reportingModel =>
                  reportingModel.id !== archiveReportingModelOrReporting.id,
              ),
            },
          });
        }}
      >
        {archiveReportingModelOrReporting => (
          <Item
            onClick={() =>
              archiveReportingModelOrReporting({
                variables: { id: data && data.id, type: 'MODEL' },
              })
            }
          >
            <Icon icon="TRASH" size={ImageSize.small} color={red} /> &nbsp;
            <DeleteName>{t('Delete')}</DeleteName>
          </Item>
        )}
      </Mutation>
    ) : null;
  }
}

const mapStateToProps = state => ({
  filterKeys: state.filters.filterKeys,
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('button')(ArchiveReportingModel));
