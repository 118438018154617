import React from 'react';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/style.scss';
import Icon from '_Components/Icons/Icon';

class IconWithEindex extends React.Component {
  render() {
    return (
      <div>
        <div>
          <Icon icon={this.props.icon} color={this.props.color} size={96} />
        </div>
        <EindexRond
          eindex={this.props.mentionCount ? this.props.eindex : 'no data'}
          customCSS="eindex_rond"
          type="small"
        />
      </div>
    );
  }
}

export default IconWithEindex;
