import React, { useState } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import CModal from '_Components/CModal/CModal';
import { QSideBarLayout } from '_Components/Layouts/QSideBarLayout';
import QButton from '_Components/Buttons/QButton/QButton';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import { ImageSize } from 'styles/abstracts/variables';
import Icon from '_Components/Icons/Icon';
import { setBenchmarkDonutAnalysisAxisToDisplay } from '_Resources/Benchmark/Actions/setBenchmarkDonutAnalysisAxisToDisplay';

const StyledCModal = styled(CModal)`
  max-width: 500px !important;
`;

const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  margin: -16px;
`;

const QSideBarLayoutDisplayArea = styled.div`
  flex-grow: 1;
  /* overflow: hidden; */
`;

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  right: 1em;
  top: 1em;
  padding: 0 !important;
`;

const SettingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em;
  font-weight: bold;
`;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 13rem;
`;

type BenchmarkDonutGraphSettingsModalProps = {
  onClose: any;
  isOpen: boolean;
  t: any;
  dispatchSetBenchmarkDonutAnalysisAxisToDisplay: any;
  donutAnalysisAxisToDisplay: string;
};

const BenchmarkDonutGraphSettingsModal = ({
  onClose,
  isOpen,
  t,
  dispatchSetBenchmarkDonutAnalysisAxisToDisplay,
  donutAnalysisAxisToDisplay,
}: BenchmarkDonutGraphSettingsModalProps) => {
  const [isDonutAnalysisAxisDropdownOpen, openDonutAnalysisAxisDropdown] = useState<boolean>(false);

  const buttonRight = (
    <StyledQButton
      bgColor="greyShades.g300"
      type="button"
      onClick={() => onClose()}
      size="small"
      icon="CROSS"
      iconSize={24}
    />
  );

  const availableDonutAnalysisAxis = [
    {
      id: 'emotions',
      label: 'Emotions',
    },
    {
      id: 'sentiment',
      label: 'Sentiment',
    },
  ];

  const [currentDonutAnalysisAxis, setCurrentDonutAnalysisAxis] = useState(
    availableDonutAnalysisAxis.find(opt => opt.id === donutAnalysisAxisToDisplay) || availableDonutAnalysisAxis[0],
  );

  const onValidate = () => {
    dispatchSetBenchmarkDonutAnalysisAxisToDisplay(currentDonutAnalysisAxis.id);
  };

  const getFooter = () => {
    return (
      <ModalFooter>
        <QButton onClick={() => onClose()} size="big" bgColor="gray">
          {t('Cancel')}
        </QButton>
        <QButton
          onClick={() => {
            onValidate();
            onClose();
          }}
          size="big"
          bgColor="purple"
        >
          {t('Validate')}
        </QButton>
      </ModalFooter>
    );
  };

  return (
    <>
      <StyledCModal
        data={{}}
        title={t('Settings')}
        displayMentionsSubtitle={false}
        displayCardSubtitle={false}
        displayDateSubtitle={false}
        modal={isOpen}
        footer={getFooter()}
        toggle={onClose}
        buttonRight={buttonRight}
        centered
      >
        <Body>
          <HeaderArea />
          <QSideBarLayout>
            <QSideBarLayoutDisplayArea>
              <SettingDiv>
                <Label>{t('Analysis axis')}</Label>
                <StyledDropdown
                  direction="down"
                  isOpen={isDonutAnalysisAxisDropdownOpen}
                  toggle={() => openDonutAnalysisAxisDropdown(!isDonutAnalysisAxisDropdownOpen)}
                >
                  <StyledDropdownToggle>
                    <StyledToggleButton disableHover bgColor="grayShades.g300">
                      {t(currentDonutAnalysisAxis.label)}
                      <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
                    </StyledToggleButton>
                  </StyledDropdownToggle>
                  <Menu className="dataByFiltersDropdownMenu">
                    {availableDonutAnalysisAxis.map(option => {
                      return (
                        <Item
                          disabled={currentDonutAnalysisAxis.id === option.id}
                          onClick={() => {
                            setCurrentDonutAnalysisAxis(option);
                          }}
                        >
                          <span>{t(option.label)}</span>
                        </Item>
                      );
                    })}
                  </Menu>
                </StyledDropdown>
              </SettingDiv>
            </QSideBarLayoutDisplayArea>
          </QSideBarLayout>
        </Body>
      </StyledCModal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  donutAnalysisAxisToDisplay: get(state, ['benchmark', 'donutAnalysisAxisToDisplay']),
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetBenchmarkDonutAnalysisAxisToDisplay: (newState: any) => {
    dispatch(setBenchmarkDonutAnalysisAxisToDisplay(newState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['button', 'benchmark'])(BenchmarkDonutGraphSettingsModal));
