import stagesResponse from '../__mock__/stagesResponse';

export const LOAD_STAGES_SUCCESS = 'ALERTING_LOAD_STAGES_SUCCESS';
export const LOAD_STAGES_FAILURE = 'ALERTING_LOAD_STAGES_FAILURE';

const loadStagesSuccess = stages => ({
  type: LOAD_STAGES_SUCCESS,
  stages,
});

const loadStagesFailure = () => ({
  type: LOAD_STAGES_FAILURE,
});

// TODO: connect to backend
export const loadStages = () => loadStagesSuccess(stagesResponse);

export const getStagesWithItem = (stages, items) => {};
