/* eslint-disable import/prefer-default-export */
import { isFunction } from 'lodash';

export class ExecutionScheduler {
  interval = null;
  executionQueue = [];
  currentTimer = null;

  constructor({ interval }) {
    this.interval = interval;
  }

  schedule(action) {
    if (!isFunction(action)) {
      throw new Error('action is not a function');
    }

    this.executionQueue = [...this.executionQueue, action];
    this.getNext();
  }

  getNext() {
    const [currentExecution, ...otherExecutions] = this.executionQueue;
    if (!currentExecution || this.currentTimer !== null) return;
    this.executionQueue = otherExecutions;
    currentExecution();

    this.currentTimer = setTimeout(() => {
      this.currentTimer = null;
      this.getNext();
    }, this.interval);
  }
}
