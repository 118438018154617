import {
  SET_SPECIFIC_JOURNEY_FILTERS,
  SET_SPECIFIC_MAP_FILTERS,
  SET_SPECIFIC_SETTINGS_TAB,
} from '_Resources/SpecificFiltering/Actions/setSpecificCardThematicTab';
import { RESET_PAGE_SPECIFIC_FILTERS } from '_Resources/SpecificFiltering/Actions/resetPageSpecificFilters';
import { omit } from 'lodash';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SPECIFIC_JOURNEY_FILTERS: {
      return {
        ...state,
        ...omit(action, ['type']),
      };
    }
    case SET_SPECIFIC_MAP_FILTERS: {
      return {
        ...state,
        ...omit(action, ['type']),
        ...omit(action, ['type']),
      };
    }
    case SET_SPECIFIC_SETTINGS_TAB: {
      return {
        ...state,
        ...omit(action, ['type']),
      };
    }
    case RESET_PAGE_SPECIFIC_FILTERS: {
      return {
        ...omit(state, [action.pageId]),
      };
    }
    default: {
      return state;
    }
  }
};
