import gql from 'graphql-tag';

const query = gql`
  query getAccountUserList($accountId: Int!) {
    getAccountUserList(accountId: $accountId) {
      id
      email
      createdAt
      expiredAt
      firstName
      lastName
      isAdmin
      otpEnabled
      statistics {
        benchmarkCount
        reportingCount
        lastSignIn
        signInCount
      }
    }
  }
`;

export default query;
