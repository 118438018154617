import React, { useState } from 'react';
import styled from 'styled-components';
import QButton from '_Components/Buttons/QButton/QButton';
import { useMutation } from 'react-apollo';
import { get } from 'lodash';
import { connect } from 'react-redux';
import mutationUnmergeCluster from '_Resources/Cluster/Services/api/graphql/unmergeCluster';
import GET_CLUSTERS from '_Resources/Cluster/Services/api/graphql/getClusters';
import Loader from '_Components/Loader/OnLoad';
import { white } from 'styles/abstracts/colors';
import UnmergeClusterConfirmationModal from './UnmergeClusterConfirmationModal';

const StyledQButton = styled(QButton)`
  display: flex;
  margin-right: 1.5em;
  align-items: center;
  & > * {
    margin: 0 0.25em;
  }
`;

type UnmergeClusterButtonProps = {
  t: any;
  projectId: number;
  clusterId: string;
  closeModal: any;
  range: {
    startDate: Date;
    endDate: Date;
  };
  verbatimsCountSort: string;
  cluster: any;
  dispatchLoadClusters: any;
};

const UnmergeClusterButton = ({
  t,
  projectId,
  clusterId,
  closeModal,
  range,
  verbatimsCountSort,
  cluster,
}: UnmergeClusterButtonProps) => {
  const [unmergeClusterConfirmationModalOpen, toggleModal] = useState(false);

  const [
    unmergeCluster,
    { data: unmergeClusterData, loading, error },
  ] = useMutation(mutationUnmergeCluster, {
    refetchQueries: [
      {
        query: GET_CLUSTERS,
        variables: {
          projectId,
          range,
          verbatimsCountSort,
        },
      },
    ],
    onCompleted: data => {
      closeModal(false);
    },
  });

  const unmergeConfirmButton = (
    <StyledQButton
      bgColor="purple"
      onClick={() =>
        unmergeCluster({
          variables: { projectId, mergedClusterId: clusterId },
        })
      }
      icon="UNMERGE_CLUSTERS"
      iconSize={24}
      iconPosition="left"
      disabled={loading}
    >
      <span>
        {t('button:Confirm unmerge cluster')}
        {loading ? <Loader color={white} /> : null}
      </span>
    </StyledQButton>
  );

  return (
    <>
      <StyledQButton
        bgColor="purple"
        onClick={() => toggleModal(true)}
        icon="UNMERGE_CLUSTERS"
        iconPosition="left"
        iconSize={16}
        disabled={!cluster.clickedCluster.hasParents}
      >
        <span>{t('button:Unmerge cluster')}</span>
      </StyledQButton>
      <UnmergeClusterConfirmationModal
        isOpen={unmergeClusterConfirmationModalOpen}
        unmergeConfirmButton={unmergeConfirmButton}
        onUnmergeCanceled={() => toggleModal(false)}
      />
    </>
  );
};

const mapStateToProps = state => ({
  cluster: get(state, ['cluster']),
});

export default connect(mapStateToProps)(UnmergeClusterButton);
