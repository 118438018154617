import React from 'react';
import Layout from '_Layouts/Layout';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import InsightWall from './InsightsWall';

const title = 'Insights Wall';
const body = <InsightWall />;

const action = props => {
  return (
    <QLoginRedirect>
      <Layout body={body} allowCollapse={false} title={title} {...props} />
    </QLoginRedirect>
  );
}

export default action;
