import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormGroup, Input, Label } from 'reactstrap';
import { grayShades, red } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import Icon from '_Components/Icons/Icon';
import VerificationMessages from './VerificationMessages';

const InfoMessage = styled.p`
  font-weight: bold;
  color: ${({ error }) => (error ? red : grayShades.g800)};
  font-size: ${fontSizes.smaller};
  display: flex;
  align-items: center;
  margin-top: 0.5em;
`;

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
  margin-left: 0.5em;
`;

const NewPasswordForm = ({
  password,
  verificationPassword,
  setPassword,
  setVerificationPassword,
}) => {
  return (
    <>
      <FormGroup className="mb-0">
        <Label for="new_password" className="ml-1dot5 text-secondary">
          New password
        </Label>
        <Input
          type="password"
          name="password"
          id="new_password"
          size="lg"
          placeholder=""
          value={password}
          onChange={e => setPassword(e.target.value)}
          minLength={8}
        />
        <VerificationMessages password={password} />
      </FormGroup>
      <br />
      <FormGroup className="mb-0">
        <Label for="verification_password" className="ml-1dot5 text-secondary">
          Verification
        </Label>
        <Input
          type="password"
          name="password"
          id="verification_password"
          size="lg"
          placeholder=""
          value={verificationPassword}
          onChange={e => setVerificationPassword(e.target.value)}
        />
        {verificationPassword.length >= 1 &&
          password.length >= 1 &&
          verificationPassword !== password && (
            <InfoMessage error>
              <StyledIcon icon="WARNING" color={red} size={16} /> The two
              passwords do not match
            </InfoMessage>
          )}
      </FormGroup>
    </>
  );
};

NewPasswordForm.propTypes = {
  password: PropTypes.string.isRequired,
  verificationPassword: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  setVerificationPassword: PropTypes.func.isRequired,
  digitVerification: PropTypes.bool.isRequired,
  uppercaseVerification: PropTypes.bool.isRequired,
  lengthVerification: PropTypes.bool.isRequired,
};

export default NewPasswordForm;
