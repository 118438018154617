import { loadComponent } from '../../_Utils/routeComponent';

export default [
  {
    i: 'DataByFiltersCard',
    component: 'DataByFiltersCard',
    data: {
      title: 'DataByFiltersCard',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('DataByFiltersCard', 'breakpoints').lg.w,
        h: loadComponent('DataByFiltersCard', 'breakpoints').lg.h,
        minW: loadComponent('DataByFiltersCard', 'breakpoints').lg.minW,
        minH: loadComponent('DataByFiltersCard', 'breakpoints').lg.minH,
        x: 0,
        y: 0,
      },
      lg: {
        w: loadComponent('DataByFiltersCard', 'breakpoints').lg.w,
        h: loadComponent('DataByFiltersCard', 'breakpoints').lg.h,
        minW: loadComponent('DataByFiltersCard', 'breakpoints').lg.minW,
        minH: loadComponent('DataByFiltersCard', 'breakpoints').lg.minH,
        x: 0,
        y: 0,
      },
      md: {
        w: loadComponent('DataByFiltersCard', 'breakpoints').md.w,
        h: loadComponent('DataByFiltersCard', 'breakpoints').md.h,
        minW: loadComponent('DataByFiltersCard', 'breakpoints').md.minW,
        minH: loadComponent('DataByFiltersCard', 'breakpoints').md.minH,
        x: 0,
        y: 0,
      },
      sm: {
        w: loadComponent('DataByFiltersCard', 'breakpoints').sm.w,
        h: loadComponent('DataByFiltersCard', 'breakpoints').sm.h,
        minW: loadComponent('DataByFiltersCard', 'breakpoints').sm.minW,
        minH: loadComponent('DataByFiltersCard', 'breakpoints').sm.minH,
        x: 0,
        y: 0,
      },
      xs: {
        w: loadComponent('DataByFiltersCard', 'breakpoints').xs.w,
        h: loadComponent('DataByFiltersCard', 'breakpoints').xs.h,
        minW: loadComponent('DataByFiltersCard', 'breakpoints').xs.minW,
        minH: loadComponent('DataByFiltersCard', 'breakpoints').xs.minH,
        x: 0,
        y: 0,
      },
    },
  },
];
