import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CModal from '../../../../../../../../_Components/CModal/CModal';
import QModalSection from '../../../../../../../../_Components/Modals/QModalSection/QModalSection';
import QButton from '../../../../../../../../_Components/Buttons/QButton/QButton';

const StyledQModalSection = styled(QModalSection)`
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  flex-direction: column;
  & > * {
    margin: 1em;
  }
`;

const StyledCModal = styled(CModal)`
  width: 500px !important;

  & .modal-content > *:first-child {
    padding-bottom: 0;
  }
`;

class DeleteConfirmationModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    deleteConfirmButton: PropTypes.any.isRequired,
    onDeleteCanceled: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  title = 'Delete this insight ?';
  subtitle = 'Are you sure you want to delete this insight ?';

  getFooter() {
    const { deleteConfirmButton, onDeleteCanceled, t } = this.props;
    return (
      <Fragment>
        <StyledQModalSection>
          {deleteConfirmButton}
          <QButton outline onClick={onDeleteCanceled}>
            {t('button:No, go back to the insight details')}
          </QButton>
        </StyledQModalSection>
      </Fragment>
    );
  }

  render() {
    const { isOpen, t } = this.props;
    return (
      <StyledCModal
        size="sm"
        data={{}}
        title={t(this.title)}
        displayMentionsSubtitle={false}
        displayDateSubtitle={false}
        subtitleAdditionalContent={t(this.subtitle)}
        footer={this.getFooter()}
        modal={isOpen}
      />
    );
  }
}

export default withTranslation('card', 'button')(DeleteConfirmationModal);
