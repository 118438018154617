import React from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { setFirstLoadProjects } from '_Resources/Header/Actions/setFirstLoadProjects';
import { last, first } from 'lodash';
import { DispatchDataToStore } from '_Resources/Header/Services/api/DispatchDataToStore';
import querySchema from './graphql/getHeaderProjectFiltersData';

class QueryProjectsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // shouldComponentUpdate() {
  //   return !this.props.projectsList;
  // }
  render() {
    return (
      <>
        <Query
          query={querySchema}
          // fetchPolicy="network-only"
        >
          {({ loading, error, data, networkStatus }) => {
            if (loading || networkStatus === 4) return false;
            if (error) {
              return false;
            }
            return (
              <DispatchDataToStore
                data={data}
                action={() => this.props.setFirstLoadProjects(data)}
              />
            );
          }}
        </Query>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const currentFilters = JSON.parse(window.localStorage.getItem('filters'));
  return {
    setFirstLoadProjects: projects => {
      dispatch(setFirstLoadProjects(projects, (currentFilters && projects.data.projects.find(pro => pro.id === currentFilters.projectId) || first(projects.data.projects.filter(pr => pr.state === 'accessible')))));
    },
  };
}

function mapStateToProps(state) {
  return {
    projectsList: state.projects.projectsList,
    user: state.auth.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QueryProjectsHeader);
