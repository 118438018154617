import React from 'react';
import styled from 'styled-components';
import Items from '_Layouts/_Containers/Items';
import BottomNav from '_Layouts/_Components/BottomNav/BottomNav';
import Icon from '_Components/Icons/Icon';
import { darkPurple, purple } from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import DropdownTools from '_Layouts/_Components/BottomNav/_Components/DropdownBottomNav';

const MenuContainer = styled.div`
  height: calc(100vh - 4rem) !important;
  position: fixed;
  bottom: 0;
  background: #4e288a;
  box-shadow: 2px 85px 14px 0 rgba(0, 0, 0, 0.25);
  width: 5em;
  z-index: 3 !important;
  transform: translateX(0rem);
`;

const ContainerTop = styled.div`
  background: ${purple};
  flex-direction: column;
  top: 0;
  background: ${purple};
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding-top: 0.5rem;
  border-radius: 0 0 2.5rem 2.5rem;
  z-index: 4;
  box-shadow: 0 25px 36px -20px rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  overflow-x: hidden;
`;
const ChevronTop = styled.div`
  min-height: 2em;
  cursor: pointer;
  background: none;
  padding-top: 0.5rem;
  border: none;
  & > svg {
    opacity: 0.5;
  }
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 64,
    };

    this.handleScrollTop = this.handleScrollTop.bind(this);
  }

  handleScrollTop() {
    const el = document.getElementById('menu');
    const scroll = this.state.step;

    el.scrollTop -= scroll;
  }

  render() {
    return (
      <MenuContainer id="menuContainer">
        <ContainerTop>
          <DropdownTools layout="top" key="navTop" />
          <ChevronTop id="chevronTop" onClick={this.handleScrollTop}>
            <Icon
              icon="INTERFACE_CHEVRON_UP"
              color="#fff"
              size={ImageSize.big}
            />
          </ChevronTop>
        </ContainerTop>
        <Items />
        <BottomNav step={this.state.step} user={this.props.user} />
      </MenuContainer>
    );
  }
}

export default Menu;
