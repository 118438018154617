import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Table } from 'reactstrap';
import { orderBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import { grayShades, purple } from 'styles/abstracts/colors';
import ProjectRow from './_Containers/ProjectRow/ProjectRow';

const Container = styled.div`
  overflow-y: auto;
  max-height: ${({ archived }) => (archived ? '15em' : '20em')};
`;

const Item = styled.th`
  padding: 1.5em !important;
  color: ${grayShades.g800};
  border-bottom: 1px solid ${grayShades.g300} !important;
  position: sticky !important;
  top: 0 !important;
  background-color: white !important;
  z-index: 2;
`;

const ProjectsContainer = ({ data, archived, t, setProjectCount, history }) => {
  useEffect(() => {
    setProjectCount(data?.results?.projects?.length);
  }, [data]);
  return (
    <Container archived={archived}>
      <Table borderless>
        <thead>
          <tr>
            <Item>{t('Project name')}</Item>
            <Item>{t('Nb. mentions')}</Item>
            <Item>{t('E-index')}</Item>
            <Item>{t('Period')}</Item>
            <Item>{t('Last update')}</Item>
            <Item />
          </tr>
        </thead>
        <tbody>
          {orderBy(data?.results?.projects, project => project.id).map(
            (project, index) => (
              <ProjectRow
                project={project}
                archived={archived}
                index={index}
                history={history}
              />
            ),
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default withTranslation('card')(ProjectsContainer);
