import { last } from 'lodash';

export const createAdapter = type => {
  return {
    isValid: ({ option }) => {
      return option.label.length > 0;
    },
    transform: ({ parents, option }) => {
      const name = last(parents);
      const value = option.id;
      return { type, name, values: value };
    },
  };
};
