import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Title from '_Layouts/Header/_Containers/HeaderTop/_Components/Title';

class TitleDiv extends React.Component {
  render() {
    const { t, link2, icon, title, titleIcon } = this.props;
    return (
      <div>
        {t(link2) !== 'Welcome !' && (
          <Title
            className="d-flex align-items-center align-content-center"
            title={link2 ? t(link2) : t(title)}
            icon={icon || titleIcon}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    link2: state.routes.route && state.routes.subRoute.name,
    icon: state.routes.route && state.routes.route.icon,
  };
}

export default connect(
  mapStateToProps,
  null,
)(withTranslation('header')(TitleDiv));
