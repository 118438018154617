import React from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { getRoute } from '_Utils/routes/routeUtils';
import Qcard from '../../../_Components/Qcard/Qcard';
import TemplatesList from './_Components/TemplatesList/TemplatesList';
import ReportingCardHeader from './_Components/ReportingCardHeader';
import CQuery from '../../../_Container/QQuery/CQuery';
import query from './apollo/graphql/getReportingModel';

// TODO: Fix filterKeys problem on page reload
class ReportingMainPage extends React.Component {
  idpage = 'reporting-main';

  componentDidMount() {
    getRoute(this.props.dispatchUpdateRoute, this.idpage);
  }

  render() {
    return (
      <Container fluid className="py-3 px-5">
        <Qcard>
          <ReportingCardHeader />
          {this.props.filterKeys && (
            <CQuery query={query} filters={this.props.filterKeys}>
              <TemplatesList />
            </CQuery>
          )}
        </Qcard>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  filterKeys: state.filters.filterKeys,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdateRoute: newState => {
    dispatch(updateRoute(newState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportingMainPage);
