import React from 'react';
import { isEmpty, get } from 'lodash';
import { connect } from 'react-redux';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { radius } from 'styles/abstracts/variables';
import styled from 'styled-components';
import { Row, Col, Alert } from 'reactstrap';
import LogRocket from 'logrocket';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import { black, white, purple, grayShades, lightBlue } from 'styles/abstracts/colors';
import { withTranslation } from 'react-i18next';
import { logout } from '_Resources/Auth/Actions/auth';
import { Beacon, Tooltip } from '_Layouts/GuidedTour';
import 'styles/main.scss';
import Header from '_Layouts/Header';
import AlertStrip from '_Layouts/AlertStrip/AlertStrip';
import BodyLayout from '_Layouts/_Components/BodyLayout';
import Menu from '_Layouts/_Components/Menu';
import GuidedTourQuery from '_Resources/Header/Services/api/GuidedTourQuery';
import ErrorBoundary from '_Layouts/ErrorBoundary';

const ExampleCol = styled(Col)`
  color: ${grayShades.g800};
  font-size: smaller;
`;

const ProcessingAlert = styled(Alert)`
  background: ${lightBlue} !important;
  border: none !important;
  border-radius: calc(${radius.round} * 2) !important;
  color: white !important;
  font-weight: bold !important;
  position: fixed !important;
  z-index: 999 !important;
  right: 2em;
  bottom: 0;
`;

class Layout extends React.Component {
  state = {
    key: this.props.tourID,
    tourIncrement: 0,
    tourID: this.props.tourID,
    replay: false,
    isProcessingToastOpen: false,
  };

  resetTour = (replay = false) => {
    this.setState((prevState, props) => ({
      key: `${props.tourID}${prevState.tourIncrement}`,
      tourIncrement: (prevState.tourIncrement += 1),
    }));
    if (replay) {
      this.setState({ replay: true });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tourSteps !== this.props.tourSteps) {
      this.resetTour();
    }

    if (prevProps.idpage !== this.props.idpage) {
      this.setState({
        tourID: this.props.tourID,
        replay: false,
      });
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      const tokenExp = parseInt(window.localStorage.getItem('tokenExpiration'), 10);
      if (
        ((!tokenExp || tokenExp <= Date.now()) && window.localStorage.getItem('token')) ||
        !window.localStorage.getItem('token')
      ) {
        this.props.logout();
      }
    }
  }

  toggleProcessingToast(value) {
    this.setState({ isProcessingToastOpen: value });
  }

  componentDidMount() {
    const tokenExp = parseInt(window.localStorage.getItem('tokenExpiration'), 10);
    if (
      ((!tokenExp || tokenExp <= Date.now()) && window.localStorage.getItem('token')) ||
      !window.localStorage.getItem('token')
    ) {
      this.props.logout();
    }

    React.createContext({ history: this.props.history });
    document.title = `${this.props.t(`header:${this.props.title}`)} - CXinsights`;
    process.env.NODE_ENV === 'production' &&
      this.props.user &&
      LogRocket.identify(this.props.user.id, {
        ...this.props.user,
        name: `${this.props.user.firstName} ${this.props.user.lastName}`,
      });
    // process.env.NODE_ENV === 'production' &&
    //   this.props.rollbar &&
    //   this.props.rollbar.configure({
    //     transform: obj => {
    //       obj.sessionURL = LogRocket.sessionURL;
    //       return obj;
    //     },
    //     payload: {
    //       person: { ...this.props.user },
    //       page: this.props.idpage,
    //       project: this.props.currentProject,
    //       environment: process.env.REACT_APP_ENV,
    //     },
    //   });

    if (process.env.REACT_APP_ENV === 'PROD') {
      const settingsCode = `window.ZohoHCAsapSettings = { hideLauncherIcon: false, userInfo: {token: "${window.localStorage.getItem(
        'token',
      )}"}};`;
      const widgetCode = `window.ZohoHCAsap=window.ZohoHCAsap||function(a,b){ZohoHCAsap[a]=b;};(function(){var d=document; var s=d.createElement("script");s.type="text/javascript";s.defer=true; s.src="https://desk.zoho.eu/portal/api/web/inapp/29425000000250043?orgId=20066880706"; d.getElementsByTagName("head")[0].appendChild(s); })();`;

      const settingsTag = document.createElement('script');
      const widgetTag = document.createElement('script');

      settingsTag.id = 'asapSettings';
      widgetTag.id = 'asapWidget';

      settingsTag.appendChild(document.createTextNode(settingsCode));
      widgetTag.appendChild(document.createTextNode(widgetCode));

      typeof window !== 'undefined' && document.body.appendChild(settingsTag);
      document.body.appendChild(widgetTag);
    }
  }

  componentWillUnmount() {
    const settingsTag = document.getElementById('asapSettings');
    const widgetTag = document.getElementById('asapWidget');
    const widgetHtml = document.getElementById('zohohc-asap-web-helper-main');

    settingsTag && document.body.removeChild(settingsTag);
    widgetTag && document.body.removeChild(widgetTag);
    widgetHtml && document.body.removeChild(widgetHtml);
  }

  handleJoyrideCallback = data => {
    const { status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      console.log('FINISHED OR SKIPPED !!! ');
    }

    console.groupCollapsed(type);
    console.log(data); // eslint-disable-line no-console
    console.groupEnd();
  };

  getContent(content) {
    const { t } = this.props;
    switch (content.type) {
      case 'image':
        return (
          <Row>
            <Col xs={{ size: content.size || 12, offset: content.offset || 0 }}>
              <img className="img-fluid" src={content.src} alt={content.alt} />
            </Col>
          </Row>
        );
      case 'text':
        return (
          <Row>
            <Col
              dangerouslySetInnerHTML={{
                __html: content.content.includes('<p>You can review this tour by clicking on this question mark.</p>')
                  ? t(`steps:${content.content}`)
                  : content.content,
              }}
            />
          </Row>
        );
      case 'space':
        return <br style={{ fontSize: `${content.size}rem` }} />;
      case 'video':
        return (
          <Player playsInline src={content.src} width={1024} height={768}>
            <BigPlayButton position="center" />
            <ControlBar className="my-class" />
          </Player>
        );
      case 'separator':
        return <hr style={{ margin: `${content.size}rem 0` }} />;
      case 'example':
        return (
          <Row>
            <ExampleCol
              dangerouslySetInnerHTML={{
                __html: t(`steps:${content.content}`),
              }}
            />
          </Row>
        );
      default:
        return null;
    }
  }

  createSteps(steps) {
    if (!steps) return [];
    return steps.map(step => {
      const generatedContent = step.contentBis.map(content => this.getContent(content));
      return { ...step, content: generatedContent };
    });
    // return steps;
  }

  setReplay = replay => {
    this.setState({ replay });
  };

  render() {
    const {
      t,
      tourSteps,
      pageConfiguration,
      location,
      title,
      titleIcon,
      tourID,
      idpage,
      hideSubHeader,
      additionalSubHeader,
      subHeader,
      allowCollapse,
      history,
      hideMentionsCount,
      additionalButtons,
      user,
      body,
    } = this.props;
    const { replay, key, isProcessingToastOpen } = this.state;
    const steps = this.createSteps(Object.values(tourSteps));
    return (
      <ErrorBoundary>
        <div>
          <GuidedTourQuery replay={replay} tourID={tourID} idpage={idpage} pageConfiguration={pageConfiguration} />
          {typeof window !== 'undefined' && (
            <Joyride
              key={key}
              steps={steps}
              continuous
              debug
              run
              callback={this.handleJoyrideCallback}
              beaconComponent={Beacon}
              tooltipComponent={infos => Tooltip(t, infos, replay, this.setReplay, tourID, tourSteps)}
              showProgress
              showSkipButton
              locale={{
                back: t('button:Back'),
                close: t('button:Close'),
                last: t('button:Last'),
                next: t('button:Next'),
                skip: t('button:Skip'),
              }}
              styles={{
                options: {
                  arrowColor: white,
                  backgroundColor: white,
                  primaryColor: purple,
                  textColor: black,
                  zIndex: 1000,
                },
              }}
            />
          )}
          <div>
            <Header
              hideSubHeader={hideSubHeader}
              additionalSubHeader={additionalSubHeader}
              subHeader={subHeader}
              allowCollapse={allowCollapse}
              resetTour={tourID && this.resetTour}
              replay={replay}
              setReplay={() => this.setReplay}
              action={history.action}
              fromLogin={
                location.state &&
                (location.state.fromLogin || location.state.toMainPage || location.state.fromReporting)
              }
              location={location}
              hideMentionsCount={hideMentionsCount}
              title={title}
              titleIcon={titleIcon}
              additionalButtons={additionalButtons}
            />
            <Menu user={user} />
            <AlertStrip user={user} />
            <BodyLayout
              toggleProcessingToast={() => this.toggleProcessingToast(!isProcessingToastOpen)}
              isProcessingToastOpen={isProcessingToastOpen}
              body={body}
              id="bodyLayout"
            />
          </div>
        </div>
        <ProcessingAlert
          isOpen={isProcessingToastOpen}
          toggle={() => this.toggleProcessingToast(!isProcessingToastOpen)}
        >
          Scanning pages
        </ProcessingAlert>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  tourSteps: get(state, ['tourSteps']),
  idpage: get(state, ['routes', 'subRoute', 'id']),
  pageConfiguration: get(state, ['routes', 'pageConfiguration']),
  user: get(state, ['auth', 'user']),
  currentProject: get(state, ['projects', 'currentProject']),
});

const mapDispatchToProps = dispatch => ({
  logout: newState => {
    dispatch(logout(newState));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['button', 'steps', 'header'])(Layout));
