import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { setSpecificJourneyFilters } from '_Resources/SpecificFiltering/Actions/setSpecificCardThematicTab';
import { grayShades } from 'styles/abstracts/colors';
import IconWithEindex from 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/IconWithEindex';
import 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/style.scss';
import {
  getBackgroundCSSFormEindex,
  getColorHexaFromEindex,
} from '_Utils/eindex/eindexColorFunctions';
import { convertCelsiusToFahrenheit } from '_Utils/eindex/eindexUnits';

class EindexItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.label,
      active: this.props.active,
    };
    this.btnOnClick = this.btnOnClick.bind(this);
  }

  componentWillReceiveProps() {
    const self = this;
    setTimeout(() => {
      self.setState({
        active: self.props.active,
      });
    }, 100);
  }

  btnOnClick() {
    this.props.setSpecificFilters({
      thematics: [
        {
          thematic: this.props.associatedThematic,
          subThematic: this.props.id,
          subSubThematic: null,
        },
      ],
      idPage: this.props.idPage,
      mentionCount: this.props.mentionCount,
    });
  }

  render() {
    const projectId = this.props.filterKeys && this.props.filterKeys.projectId;
    const isFahrenheit = get(this.props, ['eindexUnitFahrenheit']);
    const eindex =
      this.props.eindex !== 'no data'
        ? isFahrenheit
          ? convertCelsiusToFahrenheit(parseInt(this.props.eindex))
          : parseInt(this.props.eindex)
        : this.props.eindex;
    const color = this.props.mentionCount
      ? this.state.active
        ? getColorHexaFromEindex(eindex, isFahrenheit)
        : grayShades.g800
      : grayShades.g400;
    const background_color =
      this.state.active && getBackgroundCSSFormEindex(eindex, isFahrenheit);
    return (
      <div
        className="item"
        style={this.props.style}
        onClick={this.props.mentionCount ? () => this.btnOnClick() : null}
      >
        <div className="title" style={{ color }}>
          {this.props.label}
        </div>
        <IconWithEindex
          icon={
            this.props.icon
              ? this.props.icon
              : `PICTO_NUMBER_${this.props.i + 1}`
          }
          eindex={this.props.eindex}
          mentionCount={this.props.mentionCount}
          color={
            this.props.mentionCount
              ? this.props.active
                ? getColorHexaFromEindex(eindex, isFahrenheit)
                : '#928FB0'
              : grayShades.g300
          }
        />
        <div
          className="border_bottom"
          style={{ backgroundColor: background_color }}
        />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setSpecificFilters: newState => {
      dispatch(setSpecificJourneyFilters(newState));
    },
  };
}

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
    idPage: state.routes.subRoute.id,
    eindexUnitFahrenheit:
      state.projectConfiguration.unitsAndLocalesSettings.eindexUnitFahrenheit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EindexItem);
