import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import GET_HEADER_MENTION_COUNT_DATA from '_Resources/Header/Services/api/graphql/getHeaderMentionsCountData.js';
import Icon from '_Components/Icons/Icon';
import { grayShades } from '../../styles/abstracts/colors';

const MentionNumberContainer = styled.div`
  position: absolute;
  left: 0;
  font-weight: bold;
  color: ${grayShades.g800};
`;

const MentionNumber = props => {
  const { t, filters } = props;

  const { data } = useQuery(GET_HEADER_MENTION_COUNT_DATA, {
    variables: {
      ...filters,
    },
    fetchPolicy: 'network-only',
  });
  const nbMentions = data && data.data.currentMentionCountWithoutNull;
  const notFiltered = nbMentions && nbMentions === data.data.totalMentionCountWithoutNull;
  const filtered = nbMentions && nbMentions !== data.data.totalMentionCountWithoutNull;

  return (
    <MentionNumberContainer>
      {nbMentions} {t(nbMentions > 1 ? 'card:mentions' : 'card:mention')}{' '}
      {notFiltered && (
        <span title={t('card:not_filtered')}>
          <Icon icon="FILTER" color={grayShades.g800} />
        </span>
      )}{' '}
      {filtered && (
        <span title={t('card:filtered')}>
          <Icon icon="FILTER_FULL" color={grayShades.g800} />
        </span>
      )}
    </MentionNumberContainer>
  );
};

export default MentionNumber;
