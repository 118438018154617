import React from 'react';
import CQuery from '../../../_Container/QQuery/CQuery';
import querySchema from './graphql/getEindexTemperatureCardData';
import ChartThermometer from '../../../_Charts/KpisCharts/ChartThermometer/ChartThermometer';

const EindexTemperatureSection = ({ filters, noDataComponent, errorComponent, ...props }) => (
  <CQuery query={querySchema} filterKeys={filters} noDataComponent={noDataComponent} errorComponent={errorComponent}>
    <EindexTemperatureSectionView {...props} />
  </CQuery>
);

const EindexTemperatureSectionView = ({ className, children, ...props }) => (
  <ChartThermometer {...props.data} reportingAnalysisAxis={props.reportingAnalysisAxis} />
);

export default EindexTemperatureSection;
