import gql from 'graphql-tag';
import FRAGMENTS from '_Resources/Cards/Services/GraphQl/FRAGMENTS';

const query = gql`
  query getVerbatimGlobalBestAndPainPointsCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
  ) {
    getVerbatimGlobalBestAndPainPointsCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
    ) {
      bestVerbatim {
        ...VerbatimFragment
      }
      worstVerbatim {
        ...VerbatimFragment
      }
    }
  }
  ${FRAGMENTS.verbatim}
`;

export default query;
