import gql from 'graphql-tag';

const query = gql`
  query getUseCases {
    getUseCases {
      id
      name
      description
      logoLink
    }
  }
`;

export default query;
