import gql from 'graphql-tag';

const SentimentsBreakdownFragment = gql`
  fragment SentimentBreakdownFragment on SentimentsBreakdown {
    positive
    negative
    neutral
    __typename
  }
`;

export default SentimentsBreakdownFragment;
