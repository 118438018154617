import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { isEqual } from 'lodash';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { grayShades } from 'styles/abstracts/colors';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import CWordCloudGraph from '../../../_Charts/WordCharts/CWordCloudChart/CWordCloudGraph';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import CQuery from '../../../_Container/QQuery/CQuery';
import querySchema from './graphql/getWordCloudCardData';

const StyledInfo = styled.p`
  text-align: center;
  color: ${grayShades.g800};
  font-size: 0.875rem;
`;

const WordCloudCard = props => (
  <QcardPanel {...props} hints={[HintsContent.HINT_WORD_FREQUENCY]}>
    <div>
      <Row>
        <Col>
          <StyledInfo>
            {props.t(
              'Click on a word to know the number of occcurences and to have an overview of the context',
            )}
          </StyledInfo>
          <WordCloudSection {...props} />
        </Col>
      </Row>
    </div>
  </QcardPanel>
);

export class WordCloudSection extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    return <WordCloudContainer {...this.props} />;
  }
}

export const WordCloudContainer = ({ filters, height }) => (
  <CQuery filterKeys={filters} query={querySchema}>
    <CWordCloudGraph height={height} />
  </CQuery>
);

export default withTranslation('card')(WordCloudCard);
