import { select } from 'd3-selection';
import { get } from 'lodash';
import { getTransitionDuration, line, transitionDurationTime } from './tile';
import createTileLabelFactory from './tileLabel';
import { prepareOrderColoring } from './utils/colors/orderColoring';
import { prepareCategoryColoring } from './utils/colors/categoryColoring';

// NOTE: we should provide color by context instead of global coloring function
let getColor;

function onMouseOver() {
  select(this).attr('fill', '#4E2995');
}

function onMouseOut(data, index) {
  select(this).attr('fill', getColor(data, index));
}

const coloringPerIndicators = {
  eindex: (isFahrenheit, indicator) =>
    prepareCategoryColoring(isFahrenheit ? 'fahrenheit' : 'celsius', indicator),
  sat: (_, indicator) => prepareCategoryColoring('default', indicator),
  reco: (_, indicator) => prepareCategoryColoring('default', indicator),
};

export default function createInsightTileFactory({
  container,
  pathResolver,
  isFahrenheit,
  onClick,
  onMouseover,
  onMouseout,
  id,
}) {
  const labelFactory = createTileLabelFactory({
    container,
    onClick,
    onMouseover,
    id,
  });

  return function insightTileFactory({ points, type, indicator }) {
    const prepColor = coloringPerIndicators[indicator];
    getColor = prepColor
      ? prepColor(isFahrenheit, indicator)
      : prepareOrderColoring(points, indicator);

    const update = select(container)
      .selectAll('path.insightTile')
      .data(points, point => get(point, 'insightData.insightId'));

    update
      .transition(getTransitionDuration())
      .delay(transitionDurationTime)
      .attr('d', ({ point }) => {
        const coords = pathResolver(point);
        return line(coords);
      })
      .attr('fill', getColor);

    update
      .enter()
      .append('path')
      .attr('id', id)
      .on('mouseover', onMouseover)
      .on('mouseout', onMouseout)
      .on('click', onClick)
      .transition(getTransitionDuration())
      .delay(transitionDurationTime * 2)
      .attr('class', type)
      .attr('d', ({ point }) => {
        const coords = pathResolver(point);
        return line(coords);
      })
      .attr('fill', getColor)
      .attr('cursor', 'pointer');

    update
      .exit()
      .transition(getTransitionDuration())
      .attr('fill', 'white')
      .remove();

    const label = labelFactory({ points });
    return { tile: { update }, label };
  };
}
