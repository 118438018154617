import React from 'react';
import QModal from '_Components/Modals/QModal/QModal';
import VerbatimBubble from '_Components/QVerbatimBubble/VerbatimBubble';

const VerbatimDetailsModal = props => {
  const { isOpen, toggle, data, buttonRight, verbatimEmotionsColors, hideCollapse } = props;
  return (
    <QModal
      onClose={() => toggle(!isOpen)}
      isOpen={isOpen}
      title={`Verbatim n°${data.id}`}
      buttonRight={buttonRight}
    >
      <VerbatimBubble {...data} hideCollapse={hideCollapse} verbatimEmotionsColors={verbatimEmotionsColors} />
    </QModal>
  );
};

export default VerbatimDetailsModal;
