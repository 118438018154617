import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QInput from '_Components/Fields/QInput/QInput';
import mutationUpdateInsight from '_Resources/Insight/Services/api/graphql/updateInsight';
import getInsights from '_Resources/Insight/Services/api/graphql/getInsights';
import { Mutation } from 'react-apollo';
import DescriptionDetails from '../../_Components/DescriptionDetails';
import QButton from '_Components/Buttons/QButton/QButton';

const TextArea = styled.textarea`
  border: none;

  padding: 20px;
  border-radius: 20px;
  color: #280d60;
  background-color: #edf0f7;
  resize: none;
`;

const InputArea = styled.div`
  margin: 1rem;
`;

const DetailsPanel = styled.div`
  border-bottom: 1px solid #edf0f7;
`;

const TextAreaPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;

const StyledTextArea = styled(TextArea)`
  flex-grow: 1;
`;
const ButtonArea = styled.div`
  margin: 1em;
  display: flex;
  justify-content: flex-end;
`;

class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: this.props.descriptionData.text,
      name: this.props.descriptionData.name,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isDataModified = this.isDataModified.bind(this);
  }
  static propTypes = {
    descriptionData: PropTypes.shape({
      text: PropTypes.string,
      lastEditionDate: PropTypes.string,
      editedBy: PropTypes.string,
      creationDate: PropTypes.string,
    }).isRequired,
    t: PropTypes.func.isRequired,
    dispatchSelectInsight: PropTypes.func.isRequired,
  };

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  isDataModified() {
    const { name, description } = this.state;
    const {
      descriptionData: { name: originalName, text: originalDescription },
    } = this.props;
    return name !== originalName || description !== originalDescription;
  }

  render() {
    const {
      descriptionData: { lastEditionDate, editedBy, creationDate, createdBy },
      t,
      projectId,
      insightId,
      range,
      dispatchSelectInsight,
    } = this.props;

    const { description, name } = this.state;

    return (
      <Fragment>
        <DetailsPanel>
          <DescriptionDetails
            creationDate={creationDate}
            editedBy={editedBy}
            lastEditionDate={lastEditionDate}
            createdBy={createdBy}
            lang={this.props.i18n.language}
          />
        </DetailsPanel>
        <InputArea>
          <QTitle>
            <QTitle.Subtitle>{t('Name')}</QTitle.Subtitle>
          </QTitle>
          <QInput
            type="text"
            value={name}
            name="name"
            onInput={this.handleInputChange}
          />
        </InputArea>
        <TextAreaPanel>
          <QTitle>
            <QTitle.Subtitle>{t('Description')}</QTitle.Subtitle>
          </QTitle>
          <StyledTextArea
            rows={10}
            value={description}
            name="description"
            onInput={this.handleInputChange}
          />
        </TextAreaPanel>
        <ButtonArea>
          <Mutation
            mutation={mutationUpdateInsight}
            onCompleted={() => {
              dispatchSelectInsight(insightId, range);
            }}
            update={(cache, { data: { updateInsight } }) => {
              const insightList = cache.readQuery({
                query: getInsights,
                variables: { projectId },
              }).insights;
              const indexToReplace = findIndex(insightList, [
                'insightId',
                insightId,
              ]);
              const newInsightData = {
                ...insightList[indexToReplace],
                insightName: updateInsight.insight.insightName,
                description: updateInsight.insight.description,
                editedOn: updateInsight.insight.editedOn,
                editedBy: updateInsight.insight.editedBy,
              };

              const newList = [...insightList];
              newList[indexToReplace] = newInsightData;

              cache.writeQuery({
                query: getInsights,
                variables: { projectId },
                data: {
                  insights: newList,
                },
              });
            }}
          >
            {updateInsight => (
              <QButton
                disabled={!this.isDataModified()}
                onClick={() => {
                  updateInsight({
                    variables: {
                      projectId,
                      id: insightId,
                      name,
                      description,
                    },
                  });
                }}
              >
                {t('button:Save')}
              </QButton>
            )}
          </Mutation>
        </ButtonArea>
      </Fragment>
    );
  }
}

export default withTranslation('card', 'button')(Description);
