import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Item } from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import { purple, grayShades } from 'styles/abstracts/colors';
import { loadClustersToMerge } from '_Resources/Cluster/Actions/loadClustersToMerge';

const StyledClustersToMergeContainer = styled.div`
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;

const StyledQCheckbox = styled(QCheckbox)`
  margin: 0 1em 0 0 !important;
`;

const StyledItem = styled(Item)`
  justify-content: flex-start !important;
  width: auto !important;
`;

const StyledUl = styled.ul`
  margin-top: 1.5rem;
  list-style: none;
`;

const Label = styled.span`
  font-weight: bold;
  color: ${purple};
`;

const StyledIdSpan = styled.span`
  font-weight: bold;
  color: ${grayShades.g800};
  font-size: 14px;
  font-size: 0.9rem;
`;

const ClustersToMergeSelect = ({
  options,
  t,
  cluster,
  loadClustersToMerge,
}) => {
  const label = `Select clusters to merge with ${cluster.clickedClusterTone}`;

  const [selectedClusters, setSelectedClusters] = useState([
    cluster.clickedCluster.id,
  ]);

  const handleClick = clickedOption => {
    let newSelection = [];
    if (selectedClusters.includes(clickedOption.id)) {
      newSelection = selectedClusters.filter(
        clusterId => clusterId !== clickedOption.id,
      );
    } else {
      newSelection = [...selectedClusters, clickedOption.id];
    }
    setSelectedClusters(newSelection);
    loadClustersToMerge(newSelection);
  };

  return (
    <StyledClustersToMergeContainer>
      <Label>{t(label)} "{cluster.clickedCluster.title}"</Label>
      <StyledIdSpan>{` (${cluster.clickedCluster.id})`}</StyledIdSpan>
      <StyledUl className="ClustersToMergeSelectMenu">
        {options.map(option => {
          return (
            <li>
              <StyledItem>
                <StyledQCheckbox
                  selected={selectedClusters.includes(option.id)}
                  onClick={() => handleClick(option)}
                />
                <p><span>{option.verbatimsCount} {t('verbatim')} </span> : {option.title.replace(/:$/, '')}</p>
              </StyledItem>
            </li>
          );
        })}
      </StyledUl>
    </StyledClustersToMergeContainer>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  loadClustersToMerge: (newState: any) => {
    dispatch(loadClustersToMerge(newState));
  },
});

const mapStateToProps = state => ({
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
  currentLang: get(state, ['auth', 'user', 'lang']),
  cluster: get(state, ['cluster']),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClustersToMergeSelect);
