import gql from 'graphql-tag';

const mutation = gql`
  mutation updateSaasUploadConfig($uuid: String!, $file: Upload!, $projectName: String, $sendEmailAfterProcessing: Boolean, $lang: String) {
    updateSaasUploadConfig(uuid: $uuid, file: $file, projectName: $projectName, sendEmailAfterProcessing: $sendEmailAfterProcessing, lang: $lang) {
      status
      errors
    }
  }
`;

export default mutation;
