export class DataHandler {
  processData(data) {
    return data.map(dataSingle => {
      return adapt(dataSingle);
    });
  }
}

function adapt(location) {
  const {
    id,
    name,
    coordinates,
    results: { eindex, sat, reco, ces },
  } = location;
  return {
    id,
    name,
    position: { lat: Number(coordinates.lat), lng: Number(coordinates.long) },
    indicators: [
      {
        name: 'E-index',
        type: 'eindex',
        value: eindex,
      },
      {
        name: 'Satisfaction',
        type: 'sat',
        value: sat,
      },
      {
        name: 'Recommendations',
        type: 'reco',
        value: reco,
      },
      {
        name: 'Effort',
        type: 'effort',
        value: ces,
      },
    ],
  };
}
