import gql from 'graphql-tag';

const query = gql`
  query accountDetails($accountId: Int!) {
    accountDetails(accountId: $accountId) {
      companyName
      address {
        address1
        alpha3Cc
      }
      planName
      remainingCredits
      canAccessBilling
      subscriptionCancelAtDate
      subscriptionResetDate
      totalCredits
      typeCredits
      validUntil
    }
  }
`;

export default query;
