import React, { useState } from 'react';
import styled from 'styled-components';
import '_Charts/style.scss';
import { cloneDeep } from 'lodash';
import { grayShades } from 'styles/abstracts/colors';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import { useQuery } from 'react-apollo';
import GET_MENTION_BADGE_CARD_DATA from '_Cards/MentionsCards/MentionBadgeCard/graphql/getMentionBadgeCardData';
import EindexRond from '../../../_Charts/KpisCharts/KpiBadges/EindexRond';
import CQuery from '../../../_Container/QQuery/CQuery';
import querySchema from '../EindexTemperatureCard/graphql/getEindexTemperatureCardData';
import VerbatimListModal from '../../../_Components/Modals/VerbatimListModal/VerbatimListModal';
import BadgeBenchmarkTooltip from '../../../_Components/Tooltips/BadgeBenchmarkTooltip/BadgeBenchmarkTooltip';

const Container = styled.div`
  cursor: ${props => props.showVerbatimDetails !== false && 'pointer'};
`;

const EIndexIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IndicatorTitle = styled.p`
  font-size: 0.875em;
  font-weight: 700;
  color: ${grayShades.g800};
  text-align: center;
  line-height: 21px;
  margin-bottom: 0.4em;
  margin-top: 3.5em;
`;

const BadgeBenchmarkTooltipContainer = styled.div`
  margin-top: 1em;
`;

const EindexBadgeSection = ({
  filters,
  noDataComponent,
  errorComponent,
  size,
  showVerbatimDetails,
  title,
  indicatorTitle,
  props,
  index,
  id,
  settings,
  badgeShow,
}) => {
  const [modal, setModal] = useState(false);
  const { data } = useQuery(GET_MENTION_BADGE_CARD_DATA, {
    fetchPolicy: 'cache-and-network',
    variables: filters,
  });
  const mentionCount = (data && data.results?.mentionCount?.value) || 0;
  const propsModal = {
    title,
    titleSecondPart: 'Eindex',
    filters,
    modal,
    toggle: () => setModal(!modal),
    classBody: 'containVerbatimbubble',
    mentionCount,
  };
  return (
    <Container showVerbatimDetails={showVerbatimDetails} onClick={() => setModal(!modal)}>
      <IndicatorTitle>{indicatorTitle}</IndicatorTitle>
      <CQuery
        query={querySchema}
        filterKeys={filters}
        noDataComponent={noDataComponent}
        errorComponent={errorComponent}
      >
        <EindexTransformData size={size} {...props} title={title} index={id} badgeShow={badgeShow} />
      </CQuery>
      {showVerbatimDetails && <VerbatimListModal {...propsModal} />}
    </Container>
  );
};

export const EindexTransformData = props => {
  const eindexValues = props.data && cloneDeep(props.data.eindex);
  const { badgeShow, index, id } = props;
  return eindexValues ? (
    <EIndexIndicatorContainer className="EIndexIndicatorContainer">
      <EindexRond eindex={props.data && props.data.eindex.value} type={props.size || 'medium'} />
      <BadgeBenchmarkTooltipContainer>
        <BadgeBenchmarkTooltip
          title={props.title}
          idTooltip="EindexDeltaTooltip"
          data={eindexValues && eindexValues}
          eindexUnitFahrenheit={props.eindexUnitFahrenheit}
          index={index || id}
          badgeShow={badgeShow}
        />
      </BadgeBenchmarkTooltipContainer>
    </EIndexIndicatorContainer>
  ) : (
    <NoDataMessage secondText="There isn't any KPI provided in the data" />
  );
};

EindexBadgeSection.defaultProps = {
  showVerbatimDetails: true,
};

export default EindexBadgeSection;
