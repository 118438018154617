import { last } from 'lodash';

export const createAdapter = type => ({
  isValid: ({ option: { type: optionType } }) => optionType === type,
  transform: ({ parents, option }) => {
    const name = last(parents);
    const value = option.id;
    return { type, name, values: value };
  },
});
