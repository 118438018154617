import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import Layout from '_Layouts';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import ThematicsOverview from 'Routes/GlobalExperience/ThematicsOverview/ThematicsOverview';
import SubHeader from 'Routes/GlobalExperience/ThematicsOverview/_Containers/SubHeader';

const action = props => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(
    location?.state?.exportLink || location?.state?.toTab === 2 ? 'category-ranking' : 'category-hierarchy',
  );
  const [isDetailsToggled, toggleDetails] = useState(false);
  const [message, setMessage] = useState(null);
  const [currentGraphView, setCurrentGraphView] = useState('category-breakdown');

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  }, [message]);

  useEffect(() => {
    toggleDetails(isDetailsToggled);
  }, [currentGraphView, currentTab]);

  const title = 'Thematics overview';
  const body = (
    <ThematicsOverview
      idpage="thematics-overview"
      currentTab={currentTab}
      isDetailsToggled={isDetailsToggled}
      message={message}
      currentGraphView={currentGraphView}
      exportLink={location?.state?.exportLink}
      toTab={location?.state?.toTab}
    />
  );
  const additionalSubHeader = (
    <SubHeader
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      isDetailsToggled={isDetailsToggled}
      toggleDetails={toggleDetails}
      setMessage={setMessage}
      setCurrentGraphView={setCurrentGraphView}
      currentGraphView={currentGraphView}
    />
  );

  return (
    <QLoginRedirect>
      <Layout body={body} title={title} {...props} additionalSubHeader={additionalSubHeader} />
    </QLoginRedirect>
  );
};

export default action;
