import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual, get } from 'lodash';
import {
  CONTEXT,
  FILTER_DIMENSIONS,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import QDatepicker from '_Components/Fields/QDatepicker/QDatepicker';
import { addConflict } from '_Resources/Benchmark/Actions/Items/addConflict';
import { removeConflict } from '_Resources/Benchmark/Actions/Items/removeConflict';
import { isValidFullDateRange } from '../../../../BenchmarkGroupCard/_Utils/checkDateRange';
import { checkConflicts } from '../FiltersSwitcher/FiltersUtils/ConflictsUtils';

class DatePickerBenchmark extends React.Component {
  static propTypes = {
    projectPeriods: PropTypes.shape.isRequired,
    dispatcher: PropTypes.func.isRequired,
    context: PropTypes.string.isRequired,
    dateRange: PropTypes.objectOf(PropTypes.string).isRequired,
    temporaryColumn: PropTypes.objectOf(PropTypes.any),
    temporaryCard: PropTypes.objectOf(PropTypes.any),
    globalBenchmarkSettings: PropTypes.objectOf(PropTypes.any).isRequired,
    isInSubheader: PropTypes.bool.isRequired,
    benchmark: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatchAddConflict: PropTypes.func.isRequired,
    dispatchRemoveConflict: PropTypes.func.isRequired,
    settings: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  state = {
    hasBeenModified: false,
  };

  componentDidUpdate(prevProps) {
    const args = [
      this.props.benchmark,
      this.props.dispatchAddConflict,
      this.props.dispatchRemoveConflict,
    ];
    if (
      this.props.temporaryColumn !== null &&
      prevProps.temporaryColumn !== null &&
      prevProps.temporaryColumn.settings.dateRange !==
        this.props.temporaryColumn.settings.dateRange &&
      !this.props.isInSubheader
    ) {
      checkConflicts(
        CONTEXT.COLUMN,
        FILTER_DIMENSIONS.DATE_RANGE,
        isValidFullDateRange,
        ...args,
      );
    }
    if (
      this.props.temporaryCard !== null &&
      prevProps.temporaryCard !== null &&
      prevProps.temporaryCard.settings.dateRange !==
        this.props.temporaryCard.settings.dateRange &&
      !this.props.isInSubheader
    ) {
      checkConflicts(
        CONTEXT.CARD,
        FILTER_DIMENSIONS.DATE_RANGE,
        isValidFullDateRange,
        ...args,
      );
    }
    if (
      prevProps.globalBenchmarkSettings.dateRange !==
      this.props.globalBenchmarkSettings.dateRange
    ) {
      checkConflicts(
        CONTEXT.GLOBAL,
        FILTER_DIMENSIONS.DATE_RANGE,
        isValidFullDateRange,
        ...args,
      );
    }
  }

  onPeriodUpdate = ({ startDate, endDate }) => {
    if (!isEqual({ startDate, endDate }, this.props.dateRange)) {
      this.props.dispatcher(this.props.context, {
        startDate,
        endDate,
      });
      this.setState({ hadDateBeenModified: true });
    }
  };

  render() {
    const { projectPeriods, dateRange } = this.props;

    return dateRange ? (
      <QDatepicker
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        periods={projectPeriods}
        onPeriodUpdate={this.onPeriodUpdate}
      />
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    benchmark: get(state, ['benchmark']),
    globalBenchmarkSettings: get(state, [
      'benchmark',
      'activeBenchmark',
      'settings',
      'globalSettings',
    ]),
    settings: get(state, ['benchmark', 'activeBenchmark', 'settings']),
    temporaryColumn: get(state, ['benchmark', 'temporaryColumn']),
    temporaryCard: get(state, ['benchmark', 'temporaryCard']),
    globalPeriod: get(state, ['periods', 'projectPeriods', 'byAll', 'periods']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchAddConflict: (firstContext, secondContext, typeConflict) => {
      dispatch(addConflict(firstContext, secondContext, typeConflict));
    },
    dispatchRemoveConflict: (firstContext, secondContext, typeConflict) => {
      dispatch(removeConflict(firstContext, secondContext, typeConflict));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DatePickerBenchmark);
