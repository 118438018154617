import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QDatepicker from '_Components/Fields/QDatepicker/QDatepicker';
import { Label } from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import { grayShades } from 'styles/abstracts/colors';
import {
  MAIN_INDICATOR_CONFIG,
  EXPLAIN_INDICATOR_CONFIG,
  ORDER_BY_CONFIG,
} from './utils';
import VarianceDropdown from './_Containers/VarianceDropdown';
import VarianceOrderByDropdown from './_Containers/VarianceOrderByDropdown';

const SelectsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 3.5em;
  margin-right: 1em;
  flex-wrap: wrap;
`;

const IndicatorsDropdownsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 2em;
  margin-left: 3em;
  & div {
    margin-right: 0.5em;
  }
`;

type DropdownsContainerProps = {
  setExplainIndicator: any;
  setMainIndicator: any;
  setReferencePeriod: any;
  mainIndicator: string;
  explainIndicator: number;
  referencePeriod: { startDate: string; endDate: string };
  projectPeriods: any[];
  details: boolean;
  setDetails: any;
  setOrderBy: any;
  orderBy: string;
  t: any;
};

const DropdownsContainer = ({
  setExplainIndicator,
  setMainIndicator,
  explainIndicator,
  mainIndicator,
  setReferencePeriod,
  referencePeriod,
  projectPeriods,
  details,
  setDetails,
  setOrderBy,
  orderBy,
  t,
}: DropdownsContainerProps) => {
  return (
    <div>
      <SelectsContainer>
        <div>
          <Label>{t('Reference period')}</Label>
          <QDatepicker
            startDate={referencePeriod?.startDate}
            endDate={referencePeriod?.endDate}
            periods={projectPeriods}
            onPeriodUpdate={setReferencePeriod}
          />
        </div>
        <IndicatorsDropdownsContainer>
          <VarianceDropdown
            callback={setMainIndicator}
            currentSetting={MAIN_INDICATOR_CONFIG.find(
              i => i.value === mainIndicator,
            )}
            itemToIterate={MAIN_INDICATOR_CONFIG}
            id="mainIndicatorVarianceDropdown"
            label="Main indicator"
          />
          <VarianceDropdown
            callback={setExplainIndicator}
            currentSetting={EXPLAIN_INDICATOR_CONFIG.find(
              i => i.value === explainIndicator,
            )}
            itemToIterate={EXPLAIN_INDICATOR_CONFIG}
            id="explainIndicatorVarianceDropdown"
            label="Explain indicator"
          />
          <VarianceOrderByDropdown
            label="Sort by"
            id="orderByVarianceDropdown"
            itemToIterate={ORDER_BY_CONFIG}
            callback={setOrderBy}
            currentSetting={ORDER_BY_CONFIG.find(
              i => i.value === orderBy.split('_')[0],
            ).subcategories.find(i => i.value === orderBy)}
          />
        </IndicatorsDropdownsContainer>
        <div id="detailsVarianceDropdown">
          <Label>
            {t('Details')} ({t('VolumeTonalityMixEffectsLogos')})
          </Label>
          <QBooleanSwitch
            value={details}
            onChanged={() => setDetails(!details)}
            labelColor={grayShades.g800}
          />
        </div>
      </SelectsContainer>
    </div>
  );
};

const mapStateToProps = ({ periods: { projectPeriods } }) => ({
  projectPeriods,
});

export default withTranslation('card')(
  connect(mapStateToProps)(DropdownsContainer),
);
