/* eslint-disable react/sort-comp */
/* eslint-disable react/forbid-prop-types */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import QButton from '_Components/Buttons/QButton/QButton';
import QTitle from '../../../../../../../../../_Components/Texts/QTitle/QTitle';
import QFilteredVerbatimList from '../../../../../../../../../_Container/QFilteredVerbatimList/QFilteredVerbatimList';
import { SENTENCE_BUBBLE_MODE } from '../../../../../../../../../_Components/QVerbatim/QVerbatim';
import { REFINE_BY_CATEGORIES } from '../../../../../../../../../_Components/QRefineBy/QRefineBy';
import AddInsightModal from '../../../AddingInsightModal/AddInsightModal';
import { ADDING_INSIGHT_PROGRESS_STEPS } from '../../../AddingInsightModal/_Containers/AddInsightProgress';
import { prepareExtractSelectedSentences } from './utils/verbatim';

const {
  EINDEX,
  EMOTIONS,
  RECOMMENDATION,
  SATISFACTION,
  SENSITIVITY,
} = REFINE_BY_CATEGORIES;

const EMPTY_FILTERS = {
  [EINDEX]: null,
  [EMOTIONS]: null,
  [RECOMMENDATION]: null,
  [SATISFACTION]: null,
  [SENSITIVITY]: null,
};

const isSentenceEqual = s1 => s2 => s1.id === s2.id && s1.order === s2.order;

const VerbatimHeaderPanel = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
`;

const StyledQTitle = styled(QTitle)`
  color: #2e096b;
`;

const QSubtitle = styled.div`
  color: #928fb0;
  font-size: 0.9em;
  margin: 1em;
  font-weight: bold;
`;

const VerbatimModePanel = styled.div`
  flex: 1;
  align-content: flex-end;
  flex-direction: row-reverse;
  display: flex;
  & > {
    align-self: flex-end;
  }
`;

const StyledButton = styled(QButton)`
  align-self: flex-end;
`;

const StyledFeedButton = styled(QButton)`
  align-self: flex-end;
`;

const StyledCancelButton = styled(QButton)`
  align-self: flex-end;
  margin-right: 1rem;
`;

const { SEARCH, FILTERS } = ADDING_INSIGHT_PROGRESS_STEPS;

const FEEDING_STEPS = {
  SELECTION: 'selection',
  FINISHED: 'finished',
  ADDING: 'adding',
};

const FEEDING_STATES = {
  [FEEDING_STEPS.SELECTION]: {
    isFeedInsightModalOpen: false,
    bubbleMode: SENTENCE_BUBBLE_MODE.SELECT,
  },
  [FEEDING_STEPS.ADDING]: {
    isFeedInsightModalOpen: true,
    bubbleMode: SENTENCE_BUBBLE_MODE.SELECT,
  },
  [FEEDING_STEPS.FINISHED]: {
    isFeedInsightModalOpen: false,
    bubbleMode: SENTENCE_BUBBLE_MODE.DELETE,
    selectedSentences: [],
  },
};

class Evidences extends Component {
  static propTypes = {
    verbatim: PropTypes.any.isRequired,
    mentionsNumber: PropTypes.number.isRequired,
  };

  state = {
    selectedSentences: [],
    filters: cloneDeep(EMPTY_FILTERS),
    filteredVerbatim: [],
    bubbleMode: SENTENCE_BUBBLE_MODE.DELETE,
    isFeedInsightModalOpen: false,
    allSelectedVerbatim: false,
    verbatimList: [],
  };

  steps = [SEARCH, FILTERS];

  feedActions = {
    label: 'Feed this insight',
    id: FEEDING_STEPS.ADDING,
  };

  cancelAction = {
    label: 'Cancel',
    id: FEEDING_STEPS.FINISHED,
  };

  feedButton = {
    label: 'Feed this insight with similar evidences',
    id: FEEDING_STEPS.SELECTION,
  };

  constructor(props) {
    super(props);
    this.initializeVerbatim(props);
  }

  initializeVerbatim({ verbatim }) {
    this.extractSentences = prepareExtractSelectedSentences(verbatim);
    this.state.filteredVerbatim = verbatim;
  }

  componentDidUpdate({ verbatim: prevVerbatim }) {
    const { verbatim } = this.props;
    if (prevVerbatim !== verbatim) {
      this.extractSentences = prepareExtractSelectedSentences(verbatim);
    }
  }

  componentWillUnmount() {
    this.state.filteredVerbatim = null;
  }

  getFeedingInitStepsData() {
    const { selectedSentences } = this.state;
    const extractedSentences = this.extractSentences(selectedSentences);
    return {
      search: {
        searchEngine: 'AI',
        answerCount: 100,
        tags: extractedSentences.map(({ sentence }) => sentence),
      },
    };
  }

  getVerbatimNumber() {
    const { verbatim } = this.props;
    let relevantSentencesCount = 0;
    verbatim.forEach(verbatimDetail =>
      verbatimDetail.sentences.forEach(sentence => {
        if (sentence.relevant) {
          relevantSentencesCount += 1;
        }
      }),
    );
    return relevantSentencesCount;
  }

  onVerbatimChange = changed => {
    const isEqualToChanged = isSentenceEqual(changed);
    this.setState(({ selectedSentences }) => {
      const newSentences = selectedSentences.find(isEqualToChanged)
        ? selectedSentences.filter(sentence => !isEqualToChanged(sentence))
        : [...selectedSentences, changed];
      if (
        selectedSentences.length === this.state.verbatimList.length ||
        (this.state.prevSelectedSentences &&
          selectedSentences.length === this.state.verbatimList.length - 1 &&
          this.state.prevSelectedSentences.length !==
            this.state.verbatimList.length)
      ) {
        this.toggleAllSelectedVerbatim();
      }
      return { selectedSentences: newSentences };
    });
  };

  toggleAllSelectedVerbatim = () => {
    this.setState({ allSelectedVerbatim: !this.state.allSelectedVerbatim });
  };

  updateVerbatimList = verbatimList => {
    this.setState({ verbatimList });
  };

  onFilterChanged = (filters, filteredVerbatim) => {
    // TODO: consider changing to submitting whole verbatim instead of removing
    this.setState({ filters, filteredVerbatim });
  };

  onFeedSwitcherClicked = value => {
    this.setState({
      ...FEEDING_STATES[value],
    });
  };

  onFeedInsightModalClose = () => {
    this.setState({
      ...FEEDING_STATES[FEEDING_STEPS.FINISHED],
    });
  };

  render() {
    const { mentionsNumber, verbatim, t, insightId, insightName } = this.props;

    const {
      selectedSentences,
      filters,
      filteredVerbatim,
      bubbleMode,
      isFeedInsightModalOpen,
    } = this.state;

    const verbatimNum = this.getVerbatimNumber();
    const verbatimPercentages = ((verbatimNum / mentionsNumber) * 100).toFixed(
      2,
    );
    const isBubbleDeleteMode = bubbleMode === SENTENCE_BUBBLE_MODE.DELETE;

    const feedInsightTitle = t('Feed the XXX insight', { insightName });

    return (
      <>
        <VerbatimHeaderPanel>
          <StyledQTitle>
            <QTitle.Title>
              {t('EvidencesCount', { count: verbatimNum })}
            </QTitle.Title>
          </StyledQTitle>
          <QSubtitle>
            • {verbatimPercentages}% {t('sentences')}
          </QSubtitle>
          <VerbatimModePanel>
            {isBubbleDeleteMode ? (
              <StyledButton
                onClick={() => this.onFeedSwitcherClicked(this.feedButton.id)}
                hasOpacity
                className={this.props.className}
              >
                {t(`button:${this.feedButton.label}`)}
              </StyledButton>
            ) : (
              <>
                <StyledFeedButton
                  onClick={() =>
                    this.onFeedSwitcherClicked(this.feedActions.id)
                  }
                >
                  {t(`button:${this.feedActions.label}`)}
                </StyledFeedButton>
                <StyledCancelButton
                  onClick={() =>
                    this.onFeedSwitcherClicked(this.cancelAction.id)
                  }
                  bgColor="red"
                  outline
                >
                  {t(`button:${this.cancelAction.label}`)}
                </StyledCancelButton>
              </>
            )}
          </VerbatimModePanel>
        </VerbatimHeaderPanel>
        {/* TODO: think about wrapping it with container */}
        <QFilteredVerbatimList
          key={verbatim.length}
          verbatim={verbatim}
          filteredVerbatim={filteredVerbatim}
          filters={filters}
          selectedSentences={selectedSentences}
          sentenceBubbleMode={bubbleMode}
          onFilterChanged={this.onFilterChanged}
          onVerbatimChanged={this.onVerbatimChange}
          toggleAllSelectedVerbatim={this.toggleAllSelectedVerbatim}
          allSelectedVerbatim={this.state.allSelectedVerbatim}
          updateVerbatimList={this.updateVerbatimList}
          verbatimList={this.state.verbatimList}
        />
        <AddInsightModal
          title={feedInsightTitle}
          key={isFeedInsightModalOpen}
          steps={this.steps}
          initStepsData={this.getFeedingInitStepsData()}
          isOpen={isFeedInsightModalOpen}
          onClose={this.onFeedInsightModalClose}
          insightId={insightId}
        />
      </>
    );
  }
}

export default withTranslation('card', ['button'])(Evidences);
