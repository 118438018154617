import React from 'react';
import styled, { keyframes } from 'styled-components';
import Icon from '_Components/Icons/Icon';

const rotation = keyframes`
  from {transform: rotate(0deg);}
  to   {transform: rotate(359deg);}
`;

const OnLoad = styled.div`
  animation: ${rotation} 2s infinite linear;
  margin: auto;
`;

const Loader = props => (
  <div
    style={{
      textAlign: 'center',
      width: props.small ? 'fit-content' : '100%',
      display: props.small ? 'inline-block' : 'initial',
      marginLeft: props.small ? '1em' : 'auto',
    }}
  >
    <OnLoad>
      <Icon icon="INTERFACE_LOADING" {...props} />
    </OnLoad>
  </div>
);

export default Loader;
