import React from 'react';
import menuItems from '_Layouts/Header/_Containers/HeaderTop/menuItems';
import MenuItem from '_Layouts/_Components/MenuItem';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';

const MenuItems = styled.div`
  min-height: 4em;
  justify-content: center;
`;
class DropdownBottomNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState({ isOpen: true });
  }

  handleMouseLeave() {
    this.setState({ isOpen: false });
  }

  getDisabledStatus(item) {
    return (
      (this.props.user &&
        this.props.user.account &&
        ((item.name === 'insights' &&
          !this.props.user.account.insightAvailable) ||
          ((item.name === 'benchmark' || item.name === 'reporting') &&
            !this.props.user.account.planName))) ||
      this.props.projectList.length === 0
    );
  }

  render() {
    const bottomNav = _.filter(menuItems, { layout: 'bottom' });
    const topNav = _.filter(menuItems, { layout: 'top' });
    return (
      <>
        {this.props.layout === 'bottom'
          ? bottomNav.map((item, index) => (
              <MenuItems className="d-flex align-items-center align-content-center">
                <MenuItem
                  key={`BottomNav${index}`}
                  tools
                  {...item}
                  active={item.name === this.props.name}
                  disabled={this.getDisabledStatus(item)}
                  isProjectListEmpty={this.props.projectList.length === 0}
                />
              </MenuItems>
            ))
          : topNav.map((item, index) => (
              <MenuItems className="d-flex align-items-center align-content-center">
                <MenuItem
                  key={`TopNav${index}`}
                  tools
                  active={item.name === this.props.name}
                  {...item}
                  disabled={this.getDisabledStatus(item)}
                  isProjectListEmpty={this.props.projectList.length === 0}
                />
              </MenuItems>
            ))}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    name: state.routes.route && state.routes.route.name,
    projectList: state.projects.projectsList,
  };
}

export default connect(mapStateToProps)(DropdownBottomNav);
