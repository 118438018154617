import React, { useState } from 'react';
import { upperFirst } from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { ImageSize } from 'styles/abstracts/variables';
import Icon from '_Components/Icons/Icon';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import { StyledDropdown, StyledDropdownToggle, Item, ToggleButton, Menu, Label } from './utils/DropdownStyles';
import { THEMATICS_TYPE } from '../../FiltersSettings';
import { grayShades } from '../../../../../styles/abstracts/colors';
import MutationSaveSettings from './MutationSaveSettings';

const BackItem = styled(Item)`
  justify-content: flex-start !important;
  padding-left: 0.25em !important;
  color: ${grayShades.g800} !important;
`;

const StyledItem = styled(Item)`
  justify-content: flex-start !important;
`;

const StyledQCheckbox = styled(QCheckbox)`
  margin: 0 1em 0 0 !important;
`;

const DataByFiltersDropdown = props => {
  const [dropdown, setDropdown] = useState(false);
  const [dropdownContent, setDropdownContent] = useState([]);
  const {
    dropdownType,
    subCategory,
    settingsVariables,
    icon,
    label,
    labelToShow,
    itemToIterate,
    updateValue,
    t,
    id,
    size,
  } = props;
  const settings = JSON.parse(settingsVariables.settings);

  const sendSettings = (item, selection) => {
    let selectedValues = settings.indicators.bar.selected;
    if (selection) {
      if (selection === 'all') {
        selectedValues =
          selectedValues && selectedValues.length !== dropdownContent.length
            ? dropdownContent && dropdownContent.map(content => content.value)
            : [];
      } else {
        selectedValues =
          selectedValues && selectedValues.includes(item.value)
            ? selectedValues.filter(emotion => emotion !== item.value)
            : selectedValues
            ? [...selectedValues, item.value]
            : [item.value];
        selectedValues.length > dropdownContent && selectedValues.pop();
      }
    } else {
      selectedValues = [];
    }
    const currentIterationItem = itemToIterate.find(iteration => iteration.subcategories === dropdownContent);
    const updatedValue =
      item !== null && !dropdownContent.includes(item)
        ? item.id || item
        : {
            label:
              selectedValues.length === dropdownContent.length
                ? 'All emotions'
                : selectedValues.length > 1
                ? `${selectedValues.length} ${currentIterationItem.value}`
                : selectedValues.length === 0
                ? 'None'
                : upperFirst(selectedValues[0]),
            value: selectedValues.length === 0 ? null : currentIterationItem.value,
            selected: selectedValues,
          };
    if (subCategory) {
      settings[dropdownType][subCategory] = updatedValue;
      updateValue(subCategory, updatedValue);
    } else {
      settings[dropdownType] = item.id || item;
      updateValue(item.id || item);
    }
    settingsVariables.settings = JSON.stringify(settings);
  };

  const getMaximumLabelLength = () => {
    switch (size) {
      case 'xl':
        return 45;
      default:
        return 15;
    }
  };

  const getResizedLabelToShow = () => {
    if (labelToShow.length >= getMaximumLabelLength()) {
      return `${t(labelToShow).substring(0, getMaximumLabelLength())}(...)`;
    }
    return t(labelToShow);
  };

  const getNoDropdownTypeSelectedLabel = () => {
    let noDropdownTypeSelectedLabel = '';
    switch (subCategory) {
      case 'subThematic':
        noDropdownTypeSelectedLabel = 'No sub thematic selected';
        break;
      case 'subSubThematic':
        noDropdownTypeSelectedLabel = 'No sub sub thematic selected';
        break;
      default:
        noDropdownTypeSelectedLabel = `No ${subCategory || dropdownType} selected`;
        break;
    }
    if (noDropdownTypeSelectedLabel.length >= getMaximumLabelLength()) {
      return `${noDropdownTypeSelectedLabel.substring(0, getMaximumLabelLength())}(...)`;
    }
    return noDropdownTypeSelectedLabel;
  };

  return (
    <div id={id}>
      <Label>
        <>
          {icon && <Icon icon={icon} size={20} />} {t(label)}
        </>
      </Label>
      <StyledDropdown
        direction="down"
        isOpen={dropdown}
        toggle={() => {
          setDropdown(!dropdown);
          dropdownContent && setDropdownContent([]);
        }}
      >
        <StyledDropdownToggle>
          <ToggleButton disableHover bgColor="grayShades.g300" size={size}>
            {labelToShow && labelToShow !== null
              ? getResizedLabelToShow()
              : `${t(`${getNoDropdownTypeSelectedLabel()}`)}`}
            <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
          </ToggleButton>
        </StyledDropdownToggle>

        <Menu className="dataByFiltersDropdownMenu">
          {dropdownType === 'thematic' && (
            <MutationSaveSettings
              callback={() => {
                if (labelToShow !== null) {
                  settings[dropdownType][subCategory] = null;
                  if (subCategory === THEMATICS_TYPE.SUBTHEMATIC) {
                    settings[dropdownType][THEMATICS_TYPE.SUBSUBTHEMATIC] = null;
                  }
                  updateValue(subCategory, null);
                  settingsVariables.settings = JSON.stringify(settings);
                }
              }}
              variables={settingsVariables}
              projectConfiguration={props.projectConfiguration}
              defaultThematic={props.defaultThematic}
            >
              <Item disabled={labelToShow === null}>{t('None')}</Item>
            </MutationSaveSettings>
          )}
          {((dropdownContent && dropdownContent.length === 0) || !dropdownContent) && itemToIterate
            ? itemToIterate.map(item =>
                !item.subcategories ? (
                  <MutationSaveSettings
                    callback={() => sendSettings(item)}
                    variables={settingsVariables}
                    projectConfiguration={props.projectConfiguration}
                    defaultThematic={props.defaultThematic}
                  >
                    <Item disabled={labelToShow === item.id || labelToShow === item.label || labelToShow === item}>
                      {t(item.id || item.label || item)}
                    </Item>
                  </MutationSaveSettings>
                ) : (
                  <Item toggle={false} onClick={() => setDropdownContent(item.subcategories)}>
                    {t(item.id || item.label || item)}
                    <Icon icon="INTERFACE_CHEVRON_RIGHT" size={ImageSize.medium} />
                  </Item>
                ),
              )
            : dropdownContent.length >= 1 && (
                <>
                  <BackItem toggle={false} onClick={() => setDropdownContent([])}>
                    <Icon icon="INTERFACE_CHEVRON_LEFT" size={ImageSize.medium} color={grayShades.g800} />
                    {t('Back')}
                  </BackItem>
                  <MutationSaveSettings
                    callback={() => sendSettings(null, 'all')}
                    variables={settingsVariables}
                    projectConfiguration={props.projectConfiguration}
                    defaultThematic={props.defaultThematic}
                  >
                    <StyledItem toggle={false}>
                      <StyledQCheckbox
                        selected={
                          settings.indicators.bar.selected &&
                          settings.indicators.bar.selected.length === dropdownContent.length
                        }
                      />
                      {t('All emotions')}
                    </StyledItem>
                  </MutationSaveSettings>
                  {dropdownContent.map(item => (
                    <MutationSaveSettings
                      callback={() => sendSettings(item, true)}
                      variables={settingsVariables}
                      projectConfiguration={props.projectConfiguration}
                      defaultThematic={props.defaultThematic}
                    >
                      <StyledItem toggle={false}>
                        <StyledQCheckbox
                          selected={
                            settings.indicators.bar.selected && settings.indicators.bar.selected.includes(item.value)
                          }
                        />
                        {t(item.id || item.label || item)}
                      </StyledItem>
                    </MutationSaveSettings>
                  ))}
                </>
              )}
        </Menu>
      </StyledDropdown>
    </div>
  );
};

export default withTranslation('button')(DataByFiltersDropdown);
