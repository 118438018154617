import produce from 'immer';
import { filter, cloneDeep, flattenDeep, find, remove } from 'lodash';
import {
  STORE_TO_WATCH,
  TEMPORARY_STORE_TO_WATCH,
  CONTEXT,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import { insertItem, removeItem } from './utils';
import { constructConflict } from '../../../../Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/ConflictsUtils';

export const createTemporaryItem = (state, { settings, id, context }) => {
  const storeToWatch = TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField;
  const idToWatch = TEMPORARY_STORE_TO_WATCH[context].temporaryIDField;
  return produce(state, newState => {
    newState[storeToWatch] = settings;
    newState[idToWatch] = Number.isInteger(id) ? id : -1;
  });
};

export const deleteTemporaryItem = (state, { context }) => {
  const storeToWatch = TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField;
  const idToWatch = TEMPORARY_STORE_TO_WATCH[context].temporaryIDField;
  return produce(state, newState => {
    newState[storeToWatch] = null;
    newState[idToWatch] = null;
  });
};

export const deleteItem = (state, { id, context }) => {
  const storeToWatch = STORE_TO_WATCH[context];
  const conflictsStore = STORE_TO_WATCH[CONTEXT.CONFLICTS];
  return produce(state, newState => {
    newState.activeBenchmark.settings[storeToWatch] = removeItem(
      state.activeBenchmark.settings[storeToWatch],
      {
        index: id,
      },
    );
    newState.activeBenchmark.settings[conflictsStore] = filter(
      state.activeBenchmark.settings[conflictsStore],
      conflict => conflict.dimensions[context] !== id,
    );
  });
};

export const duplicateItem = (state, { id, context }) => {
  const storeToWatch = STORE_TO_WATCH[context];
  const conflictsStore = STORE_TO_WATCH[CONTEXT.CONFLICTS];
  const items = state.activeBenchmark.settings[storeToWatch];
  const itemToDuplicate = {
    ...items[id],
    title: `${items[id].title} - copy`,
  };
  const duplicatedConflicts = filter(
    state.activeBenchmark.settings[conflictsStore],
    conflict => conflict.dimensions[context] === id,
  );
  const conflictsToAdd = duplicatedConflicts.map(conflict => ({
    ...conflict,
    dimensions: {
      ...conflict.dimensions,
      [context]: items.length,
    },
  }));
  return produce(state, newState => {
    newState.activeBenchmark.settings[storeToWatch] = insertItem(
      state.activeBenchmark.settings[storeToWatch],
      {
        index: items.length,
        item: itemToDuplicate,
      },
    );
    newState.activeBenchmark.settings[conflictsStore] = [
      ...state.activeBenchmark.settings[conflictsStore],
      ...conflictsToAdd,
    ];
  });
};

export const moveItem = (state, { context, itemId, direction }) => {
  const currentBenchmark = cloneDeep(state.activeBenchmark);
  const newSettings = cloneDeep(currentBenchmark.settings);
  const newItems = cloneDeep(newSettings[context]);
  const temporaryItem = cloneDeep(newItems[itemId]);
  switch (direction) {
    case 'left':
    case 'up':
      newItems[itemId] = newItems[itemId - 1];
      newItems[itemId - 1] = temporaryItem;
      break;
    case 'right':
    case 'down':
    default:
      newItems[itemId] = newItems[itemId + 1];
      newItems[itemId + 1] = temporaryItem;
      break;
  }
  newSettings[context] = newItems;

  currentBenchmark.settings = newSettings;
  return produce(state, newState => {
    newState.activeBenchmark = currentBenchmark;
  });
};

export const saveTemporaryItem = (state, { context }) => {
  const storeToWatch = STORE_TO_WATCH[context];
  const temporaryStoreToWatch =
    TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField;
  const idToWatch = TEMPORARY_STORE_TO_WATCH[context].temporaryIDField;
  const items = state.activeBenchmark.settings[storeToWatch];
  const currentIndex = state[idToWatch];

  const temporaryConflicts =
    TEMPORARY_STORE_TO_WATCH[CONTEXT.CONFLICTS].temporarySettingsField;
  const conflictsStore = cloneDeep(
    state.activeBenchmark.settings[STORE_TO_WATCH[CONTEXT.CONFLICTS]],
  );
  const idConflicts =
    TEMPORARY_STORE_TO_WATCH[CONTEXT.CONFLICTS].temporaryIDField;
  const specificConflicts = filter(
    conflictsStore,
    conflict => conflict.dimensions[context] === state[idToWatch],
  );

  if (Number.isInteger(currentIndex)) {
    if (currentIndex === -1) {
      return produce(state, newState => {
        newState.activeBenchmark.settings[STORE_TO_WATCH[CONTEXT.CONFLICTS]] =
          state[temporaryConflicts] !== null
            ? flattenDeep([...conflictsStore, state[temporaryConflicts]])
            : conflictsStore;

        newState.activeBenchmark.settings[storeToWatch] = insertItem(
          state.activeBenchmark.settings[storeToWatch],
          {
            index: items.length,
            item: state[temporaryStoreToWatch],
          },
        );
        newState[temporaryStoreToWatch] = null;
        newState[idToWatch] = null;
        newState[temporaryConflicts] = null;
        newState[idConflicts] = null;
      });
    }

    return produce(state, newState => {
      let conflictsToAdd = [];
      let conflictsToRemove = [];
      state[temporaryConflicts].forEach(temporaryConflict => {
        const newTemporaryConflicts = filter(
          state[temporaryConflicts],
          conflictToAdd => conflictToAdd === temporaryConflict,
        );
        if (!find(conflictsStore, temporaryConflict)) {
          conflictsToAdd = [...conflictsToAdd, ...newTemporaryConflicts];
          newState.activeBenchmark.settings[STORE_TO_WATCH[CONTEXT.CONFLICTS]] =
            state[temporaryConflicts] !== null
              ? [...conflictsStore, ...conflictsToAdd]
              : conflictsStore;
        }
      });
      specificConflicts.forEach(conflict => {
        if (!find(state[temporaryConflicts], conflict)) {
          const specificConflictToRemove = filter(
            conflictsStore,
            conflictToRemove => conflictToRemove === conflict,
          );
          conflictsToRemove = [
            ...conflictsToRemove,
            ...specificConflictToRemove,
          ];
          conflictsToRemove.forEach(conflictToRemove => {
            remove(
              conflictsStore,
              conflictToDelete => conflictToDelete === conflictToRemove,
            );
          });
          newState.activeBenchmark.settings[
            STORE_TO_WATCH[CONTEXT.CONFLICTS]
          ] = conflictsStore;
        }
      });

      newState.activeBenchmark.settings[storeToWatch][currentIndex] =
        state[temporaryStoreToWatch];
      newState[temporaryStoreToWatch] = null;
      newState[idToWatch] = null;
      newState[temporaryConflicts] = null;
      newState[idConflicts] = null;
    });
  }
  return { ...state };
};

export const addConflict = (
  state,
  { firstContext, secondContext, typeConflict },
) => {
  const isTemporaryID =
    Number.isInteger(firstContext.temporaryID) ||
    Number.isInteger(secondContext.temporaryID);
  const storeToWatch = isTemporaryID
    ? TEMPORARY_STORE_TO_WATCH[CONTEXT.CONFLICTS].temporarySettingsField
    : STORE_TO_WATCH[CONTEXT.CONFLICTS];
  const settingToAdd = constructConflict(
    firstContext,
    secondContext,
    typeConflict,
  );

  let newSettings;
  if (!isTemporaryID) {
    newSettings =
      state.activeBenchmark.settings[CONTEXT.CONFLICTS] !== null
        ? [...state.activeBenchmark.settings[CONTEXT.CONFLICTS], settingToAdd]
        : [settingToAdd];
  } else {
    newSettings =
      state.temporaryConflicts !== null
        ? [...state.temporaryConflicts, settingToAdd]
        : [settingToAdd];
  }

  return produce(state, newState => {
    if (isTemporaryID) {
      newState[storeToWatch] = newSettings;
    } else {
      newState.activeBenchmark.settings[CONTEXT.CONFLICTS] = newSettings;
    }
  });
};

export const removeConflict = (
  state,
  { firstContext, secondContext, typeConflict },
) => {
  const isTemporaryID =
    Number.isInteger(firstContext.temporaryID) ||
    Number.isInteger(secondContext.temporaryID);
  const storeToWatch = isTemporaryID
    ? TEMPORARY_STORE_TO_WATCH[CONTEXT.CONFLICTS].temporarySettingsField
    : STORE_TO_WATCH[CONTEXT.CONFLICTS];
  const conflictToRemove = constructConflict(
    firstContext,
    secondContext,
    typeConflict,
  );
  let newConflicts;
  let existingConflict;
  if (isTemporaryID) {
    existingConflict = find(state.temporaryConflicts, conflictToRemove);
    newConflicts = filter(
      state.temporaryConflicts,
      conflict => conflict !== existingConflict,
    );
  } else {
    existingConflict = find(
      state.activeBenchmark.settings[CONTEXT.CONFLICTS],
      conflictToRemove,
    );
    newConflicts = filter(
      state.activeBenchmark.settings[CONTEXT.CONFLICTS],
      conflict => conflict !== existingConflict,
    );
  }

  return produce(state, newState => {
    if (isTemporaryID) {
      newState[storeToWatch] = newConflicts;
    } else {
      newState.activeBenchmark.settings[CONTEXT.CONFLICTS] = newConflicts;
    }
  });
};
