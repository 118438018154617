import React, { useState, useEffect } from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import styled from 'styled-components';
import {
  white,
  opacity,
  colors,
  grayShades,
  lightenOrDarkenColor,
  changeOpacity,
} from 'styles/abstracts/colors';
import PropTypes from 'prop-types';
import Icon from '_Components/Icons/Icon';

function getButtonClassName({ size }) {
  return size === 'large' ? 'btn-lg' : size === 'small' && 'btn-sm';
}

const getSolidColors = (bgColor, disabled, iconColor, secondaryColor) => {
  let backgroundColor;
  let fontColor;
  let hoverColor;
  switch (true) {
    case Object.keys(colors).includes(bgColor):
      backgroundColor = colors[bgColor];
      fontColor = Object.keys(colors).includes(secondaryColor)
        ? colors[secondaryColor]
        : secondaryColor || white;
      hoverColor = secondaryColor
        ? colors[secondaryColor] || secondaryColor
        : lightenOrDarkenColor(colors[bgColor], -0.25);
      break;
    case Object.keys(grayShades).includes(bgColor.split('.')[1]):
      backgroundColor = grayShades[bgColor.split('.')[1]];
      fontColor = bgColor.split('.')[1] === 'g300' ? grayShades.g800 : white;
      hoverColor = lightenOrDarkenColor(
        grayShades[bgColor.split('.')[1]],
        -0.25,
      );
      break;
    case disabled:
      backgroundColor = grayShades.g300;
      fontColor = grayShades.g800;
      break;
    default:
      backgroundColor = bgColor;
      fontColor = white;
      hoverColor = lightenOrDarkenColor(bgColor, -0.25);
      break;
  }
  return {
    backgroundColor,
    color: fontColor,
    hover: hoverColor,
  };
};

const getOpacityColors = bgColor => {
  let backgroundColor;
  let fontColor;
  let hoverColor;
  switch (true) {
    case Object.keys(opacity).includes(bgColor) &&
      Object.keys(colors).includes(bgColor):
      backgroundColor = opacity[bgColor];
      fontColor = colors[bgColor];
      hoverColor = colors[bgColor];
      break;
    case Object.keys(colors).includes(bgColor):
      backgroundColor = changeOpacity(colors[bgColor], 0.2);
      fontColor = colors[bgColor];
      hoverColor = colors[bgColor];
      break;
    default:
      backgroundColor = changeOpacity(bgColor, 0.2);
      fontColor = bgColor;
      hoverColor = bgColor;
  }
  return {
    backgroundColor,
    color: fontColor,
    hover: hoverColor,
  };
};

const getOutlineColors = bgColor => {
  let fontColor;
  let hoverColor;
  switch (true) {
    case Object.keys(colors).includes(bgColor) &&
      Object.keys(opacity).includes(bgColor):
      fontColor = colors[bgColor];
      hoverColor = opacity[bgColor];
      break;
    case Object.keys(colors).includes(bgColor):
      fontColor = colors[bgColor];
      hoverColor = changeOpacity(colors[bgColor], 0.2);
      break;
    case bgColor.split('.')[1] === 'g800':
      fontColor = grayShades.g800;
      hoverColor = grayShades.g300;
      break;
    case Object.keys(grayShades).includes(bgColor.split('.')[1]):
      fontColor = grayShades[bgColor.split('.')[1]];
      hoverColor = changeOpacity(grayShades[bgColor.split('.')[1]], 0.2);
      break;
    default:
      fontColor = bgColor;
      hoverColor = changeOpacity(bgColor, 0.2);
  }
  return {
    backgroundColor: null,
    color: fontColor,
    hover: hoverColor,
  };
};

const getHoverFontColor = (
  bgColor,
  hasOpacity,
  outline,
  disabled,
  iconColor,
  disableHover,
) => {
  let fontColor;
  let hoverColor;
  if (hasOpacity) {
    fontColor = getOpacityColors(bgColor).color;
    hoverColor = getOpacityColors(bgColor).hover;
  } else if (outline) {
    fontColor = getOutlineColors(bgColor).color;
    hoverColor = getOutlineColors(bgColor).hover;
  } else {
    fontColor = getSolidColors(bgColor, disabled, iconColor).color;
    hoverColor = getSolidColors(bgColor, disabled, iconColor).hover;
  }
  if (!disableHover && !disabled) {
    if (hoverColor === fontColor || bgColor.split('.')[1] === 'g300') {
      return white;
    }
    return fontColor;
  }
  return null;
};

const StyledQButton = styled(Button)`
  border-radius: 10rem;
  cursor: pointer;
  background-color: ${({
    bgColor,
    outline,
    disabled,
    hasOpacity,
    iconColor,
    secondaryColor,
  }) =>
    !outline &&
    (hasOpacity
      ? getOpacityColors(bgColor).backgroundColor
      : getSolidColors(bgColor, disabled, iconColor, secondaryColor)
          .backgroundColor)} !important;
  border: 0;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'unset')} !important;
  color: ${({
    bgColor,
    outline,
    disabled,
    hasOpacity,
    iconColor,
    secondaryColor,
  }) => {
    if (hasOpacity) {
      return getOpacityColors(bgColor).color;
    }
    if (outline) {
      return getOutlineColors(bgColor).color;
    }
    return getSolidColors(bgColor, disabled, iconColor, secondaryColor).color;
  }} !important;
  transition: ${({ transition }) => `${transition} !important`};

  &.disabled {
    pointer-events: all !important;
  }

  &:hover,
  &:active {
    background-color: ${({
      bgColor,
      outline,
      hasOpacity,
      disableHover,
      iconColor,
      disabled,
      secondaryColor,
    }) => {
      if (!disableHover && !disabled) {
        if (hasOpacity) {
          return getOpacityColors(bgColor).hover;
        }
        if (outline) {
          return getOutlineColors(bgColor).hover;
        }
        return getSolidColors(bgColor, disabled, iconColor, secondaryColor)
          .hover;
      }
      return null;
    }} !important;
    color: ${({
      bgColor,
      outline,
      hasOpacity,
      disableHover,
      iconColor,
      disabled,
    }) =>
      getHoverFontColor(
        bgColor,
        hasOpacity,
        outline,
        disabled,
        iconColor,
        disableHover,
      )} !important;
    transition: all 0.2 ease-in-out;

    & > svg > path {
      fill: ${({
        bgColor,
        outline,
        hasOpacity,
        disableHover,
        disabled,
        iconColor,
      }) =>
        getHoverFontColor(
          bgColor,
          hasOpacity,
          outline,
          disabled,
          iconColor,
          disableHover,
        )} !important;
      transition: all 0.2s ease-in-out !important;
    }
    transition: all 0.2s ease-in-out !important;
  }
`;

const ButtonIcon = ({ icon, iconSize, iconColor, ...props }) => {
  let color;
  if (props.hasOpacity) {
    color = getOpacityColors(props.bgColor).color;
  } else if (props.outline) {
    color = getOutlineColors(props.bgColor).color;
  } else {
    color = getSolidColors(
      props.bgColor,
      props.disabled,
      props.iconColor,
      props.secondaryColor,
    ).color;
  }
  return (
    <Icon
      icon={icon}
      size={iconSize}
      color={
        (iconColor !== 'white' && (colors[iconColor] || iconColor)) || color
      }
    />
  );
};

const CustomPopover = styled(Popover)`
  background-color: black !important;
  & .arrow:after {
    border-bottom-color: black !important;
  }
`;

const CustomPopoverBody = styled(PopoverBody)`
  color: ${white} !important;
`;

const QButton = ({ size, bgColor, onClick, ...props }) => {
  const [isPopoverOpen, openPopover] = useState(false);
  const buttonProps = {
    className: `${getButtonClassName({ size })} ${props.className}`,
    bgColor,
    disabled: props.disabled,
    outline: props.outline,
    hasOpacity: props.hasOpacity,
    disableHover: props.disableHover,
    iconColor: props.iconColor,
    secondaryColor: props.secondaryColor,
    id: props.id,
    loading: props.loading,
    transition: props.transition,
  };
  const iconProps = {
    icon: props.icon,
    iconSize: props.iconSize,
    ...buttonProps,
  };
  if (onClick) {
    buttonProps.onClick = param => onClick(param);
  }
  if (props.onMouseEnter) {
    buttonProps.onMouseEnter = () => {
      openPopover(true);
      props.onMouseEnter();
    };
  } else {
    buttonProps.onMouseEnter = () => openPopover(true);
  }
  if (props.onMouseLeave) {
    buttonProps.onMouseLeave = () => {
      openPopover(false);
      props.onMouseLeave();
    };
  } else {
    buttonProps.onMouseLeave = () => openPopover(false);
  }

  return (
    <>
      <StyledQButton {...buttonProps}>
        {props.icon && props.iconPosition === 'left' && (
          <ButtonIcon {...iconProps} />
        )}
        {props.loading ? (
          <ButtonIcon {...iconProps} icon="INTERFACE_LOADING" />
        ) : (
          props.children
        )}
        {props.icon && props.iconPosition === 'right' && (
          <ButtonIcon {...iconProps} />
        )}
      </StyledQButton>
      {!!props.popoverText && document.getElementById(props.id) && props.disabled &&  (
        <CustomPopover
          placement="bottom"
          isOpen={true}
          toggle={openPopover}
          target={props.id}
        >
          <CustomPopoverBody>{props.popoverText}</CustomPopoverBody>
        </CustomPopover>
      )}
    </>
  );
};

ButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  iconSize: PropTypes.number.isRequired,
  hasOpacity: PropTypes.bool.isRequired,
  bgColor: PropTypes.string.isRequired,
  outline: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  secondaryColor: PropTypes.bool.isRequired,
};

QButton.propTypes = {
  /** Background color of the button, please refer to the Color guide */
  bgColor: PropTypes.string,
  /** Size of the button */
  size: PropTypes.string,
  /** Any element displayed inside of "QButton" element is considered as a children */
  // eslint-disable-next-line
  children: PropTypes.any,
  /** Callback function triggered while clicking the button */
  onClick: PropTypes.func,
  /** The icon you want to pass to the button */
  icon: PropTypes.string,
  /** The position of the icon in the button */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /** The color of the icon */
  iconColor: PropTypes.string,
  /** The size of the icon (in pixels) */
  iconSize: PropTypes.number,
  /** Stops the button from working */
  disabled: PropTypes.bool,
  /** Removes the background of the button */
  outline: PropTypes.bool,
  /** Callback function triggered when entering the button */
  onMouseEnter: PropTypes.func,
  /** Callback function triggered when leaving the button */
  onMouseLeave: PropTypes.func,
  /** Determines if the background has opacity */
  hasOpacity: PropTypes.bool,
  /** The classname of the button (for custom styling) */
  className: PropTypes.string,
  /** Disables hover's color changes */
  disableHover: PropTypes.bool,
  /** Secondary color of the button */
  secondaryColor: PropTypes.string,
  /** Show loading icon  */
  loading: PropTypes.bool,
  /** Custom transition on the button  */
  transition: PropTypes.string,
};

QButton.defaultProps = {
  onClick: null,
  bgColor: 'purple',
  size: null,
  icon: null,
  iconPosition: 'right',
  iconColor: 'white',
  disabled: false,
  outline: false,
  iconSize: 24,
  onMouseEnter: null,
  onMouseLeave: null,
  hasOpacity: false,
  className: null,
  disableHover: false,
  secondaryColor: null,
  loading: false,
  transition: 'all 0.2s ease-in-out',
};

export default QButton;
