/* eslint-disable import/prefer-default-export */
import colorInsightTiles from './colors';

export const prepareOrderColoring = (insights, emotion) => {
  const [firstColor, ...colors] = colorInsightTiles(emotion).map(
    ({ backgroundColor }) => backgroundColor,
  );
  const insightsPerColor = colors.length / insights.length;
  return (insight, index) => {
    const colorIndex = Math.floor(index * insightsPerColor);
    return index === 0 ? firstColor : colors[colorIndex];
  };
};
