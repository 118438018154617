import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Layout from '_Layouts/Layout';
import { connect } from 'react-redux';
import { get, map } from 'lodash';
import { useLocation } from 'react-router';
import { withTranslation } from 'react-i18next';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import { lightBlue } from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import ExportDataModal from '_Cards/SettingsCards/ProjectDetailsCard/_Components/ProcessingHistory/_Components/ProcessingHistoryHeader/_Components/ExportDataModal/ExportDataModal';
import EXPORT_PROJECT_DATA_QUERY from '_Cards/SettingsCards/ProjectDetailsCard/graphql/exportProjectData';
import QButton from '_Components/Buttons/QButton/QButton';
import QToast from '_Components/QToast/QToast';
import VerbatimListBody from './VerbatimListBody';

const title = 'Verbatim list';
const body = <VerbatimListBody idpage="verbatim-list" />;

const ExportDataContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${lightBlue};
  font-weight: bold;
`;

const VerbatimList = ({
  t,
  projectId,
  projectName,
  filters,
  keywords,
  selectedAnalysisAxis,
  selectedEmotions,
  selectedSelections,
  selectedSentiments,
  ...props
}) => {
  const location = useLocation();
  const [isModalOpen, openModal] = useState(location?.state?.exportLink);
  const [toastMessage, setToastMessage] = useState({});
  const [filtersToDisplay, setFiltersToDisplay] = useState({
    selectedEmotions: map(selectedEmotions, emotion => emotion.toUpperCase()),
    selectedClusters: map(selectedSelections, selection => selection.toUpperCase()),
    selectedSentiments: map(selectedSentiments, sentiment => sentiment.toUpperCase()),
  });

  useEffect(() => {
    setFiltersToDisplay({
      selectedEmotions: map(selectedEmotions, emotion => emotion.toUpperCase()),
      selectedClusters: map(selectedSelections, selection => selection.toUpperCase()),
      selectedSentiments: map(selectedSentiments, sentiment => sentiment.toUpperCase()),
    });
  }, [selectedAnalysisAxis, selectedEmotions, selectedSelections, selectedSentiments]);

  return (
    <QLoginRedirect>
      <Layout
        body={body}
        title={title}
        {...props}
        additionalButtons={
          <ExportDataContainer>
            <QButton
              onClick={() => openModal(!isModalOpen)}
              outline
              icon="DOWNLOAD"
              iconPosition="left"
              iconSize={ImageSize.small}
              bgColor={lightBlue}
            >
              &nbsp; {t('Export data')}
            </QButton>
          </ExportDataContainer>
        }
      />
      {ReactDOM.createPortal(
        <ExportDataModal
          t={t}
          setMessage={setToastMessage}
          isOpen={isModalOpen}
          open={openModal}
          projectId={projectId}
          projectName={projectName}
          query={EXPORT_PROJECT_DATA_QUERY}
          additionalVariables={{
            ...filters,
            keywords,
            selectedEmotions: filtersToDisplay.selectedEmotions,
            selectedClusters: filtersToDisplay.selectedClusters,
            selectedSentiments: filtersToDisplay.selectedSentiments,
          }}
          analysisAxis={selectedAnalysisAxis}
        />,
        document.getElementById('root'),
      )}
      {toastMessage.message && <QToast color={lightBlue}>{toastMessage.message}</QToast>}
    </QLoginRedirect>
  );
};

const mapStateToProps = state => ({
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
  filters: get(state, ['filters', 'filterKeys']),
  selectedAnalysisAxis: get(state, ['cards', 'mentionPreviewPerEmotion', 'analysisAxis']),
  selectedEmotions: get(state, ['cards', 'mentionPreviewPerEmotion', 'selectedEmotion']),
  selectedSelections: get(state, ['cards', 'mentionPreviewPerEmotion', 'selectedSelection']),
  selectedSentiments: get(state, ['cards', 'mentionPreviewPerEmotion', 'selectedSentiment']),
  keywords: get(state, ['cards', 'mentionPreviewPerEmotion', 'keywords']),
  projectName: get(state, 'projects.currentProject.name'),
});

export default connect(mapStateToProps)(withTranslation('button', ['card'])(VerbatimList));
