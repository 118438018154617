import styled from 'styled-components';
import QPanel from '../../../../../../../_Components/Panels/QPanel/QPanel';

export default styled(QPanel)`
  box-sizing: border-box;
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75em;
`;
