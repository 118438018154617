import React from 'react';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import BadgeBenchmarkTooltip from '_Components/Tooltips/BadgeBenchmarkTooltip/BadgeBenchmarkTooltip';
import { emotionColors, sentimentColors } from 'styles/abstracts/colors';

const EmotionValue = styled.span`
  &::before {
    content: '• ';
    color: ${({ emotion }) => emotionColors[emotion]};
  }
`;

const SentimentValue = styled.span`
  &::before {
    content: '• ';
    color: ${({ sentiment }) => sentimentColors[sentiment]};
  }
`;

export const getAvailableColumns = (filteredData, t) => [
  {
    id: 'mentionCount',
    label: t('Mentions'),
    cellType: 'number',
    getFormattedData: data => data,
  },
  {
    id: 'eindex',
    label: t('E-index'),
    cellType: 'eindex',
    getFormattedData: data => data !== null && <EindexRond type="xs" eindex={data} />,
  },
  {
    id: 'emotionalIntensity',
    label: t('Emotional intensity'),
    cellType: 'number',
    getFormattedData: data => data && `${data} %`,
  },
  {
    id: 'speechEngagement',
    label: t('Speech engagement'),
    cellType: 'number',
    getFormattedData: data => data && `${data} %`,
  },
  {
    id: 'eindexEvolution',
    label: t('E-index evolution'),
    children:
      filteredData &&
      filteredData[0]?.thematicsOverviewDetails.eindexEvolution &&
      Object.keys(filteredData[0]?.thematicsOverviewDetails.eindexEvolution)?.map(key => ({
        id: `eindexEvolution.${key}`,
        label: `${t('E-index evolution')} (${t(
          key
            .split(/(?=[A-Z])/)
            .join(' ')
            .toLowerCase(),
        )})`,
        cellType: 'evolution',
        getFormattedData: (data, id) =>
          data && data.delta !== null && id ? (
            <BadgeBenchmarkTooltip
              idTooltip={`eindexEvolutionBadge${key}${id
                .replace(/\W+/g, ' ')
                .split(/ |\B(?=[A-Z])/)
                .map(word => word.toLowerCase())
                .join('_')}`}
              data={{
                periodBefore: key === 'periodBefore' && data,
                yearBefore: key === 'yearBefore' && data,
              }}
            />
          ) : (
            'N/A'
          ),
      })),
  },
  {
    id: 'mentionEvolution',
    label: t('Mention evolution'),
    children:
      filteredData &&
      filteredData[0]?.thematicsOverviewDetails.mentionEvolution &&
      Object.keys(filteredData[0]?.thematicsOverviewDetails.mentionEvolution)?.map(key => ({
        id: `mentionEvolution.${key}`,
        label: `${t('Mention evolution')} (% ${t(
          key
            .split(/(?=[A-Z])/)
            .join(' ')
            .toLowerCase(),
        )})`,
        cellType: 'evolution',
        getFormattedData: (data, id) =>
          data && data.delta !== null && id ? (
            <BadgeBenchmarkTooltip
              idTooltip={`mentionEvolutionBadge${key}${id
                .replace(/\W+/g, ' ')
                .split(/ |\B(?=[A-Z])/)
                .map(word => word.toLowerCase())
                .join('_')}`}
              data={{
                periodBefore: key === 'periodBefore' && data,
                yearBefore: key === 'yearBefore' && data,
              }}
            />
          ) : (
            'N/A'
          ),
      })),
  },
  {
    id: 'emotions',
    label: t('Emotions'),
    children:
      filteredData &&
      filteredData[0]?.thematicsOverviewDetails.emotions &&
      orderBy(
        Object.keys(filteredData[0]?.thematicsOverviewDetails.emotions)
          ?.map(emotion => ({
            id: `emotions.${emotion}`,
            label: t(emotion.charAt(0).toUpperCase() + emotion.slice(1)),
            children:
              filteredData &&
              filteredData[0]?.thematicsOverviewDetails.emotions[emotion] &&
              Object.keys(filteredData[0]?.thematicsOverviewDetails.emotions[emotion])?.map(byUnit => ({
                id: `emotions.${emotion}.${byUnit}`,
                label: `${t(emotion.charAt(0).toUpperCase() + emotion.slice(1))} (${t(
                  byUnit
                    .split(/(?=[A-Z])/)
                    .join(' ')
                    .toLowerCase(),
                )})`,
                cellType: 'number',
                getFormattedData: data =>
                  data > -1 && !isNaN(data) ? (
                    <EmotionValue emotion={emotion}>
                      {data}
                      {byUnit === 'byPercentage' && ' %'}
                    </EmotionValue>
                  ) : (
                    'N/A'
                  ),
              })),
          }))
          .map(emotion => {
            let index;
            switch (emotion.id) {
              case 'emotions.happiness':
                index = 1;
                break;
              case 'emotions.surprise':
                index = 2;
                break;
              case 'emotions.calm':
                index = 3;
                break;
              case 'emotions.fear':
                index = 4;
                break;
              case 'emotions.sadness':
                index = 5;
                break;
              case 'emotions.anger':
                index = 6;
                break;
              case 'emotions.disgust':
                index = 7;
                break;
              default:
                index = 3;
            }
            return { ...emotion, index };
          }),
        item => item.index,
        'asc',
      ),
  },
  {
    id: 'emotionsEvolution',
    label: t('Emotions evolution'),
    children:
      filteredData &&
      filteredData[0]?.thematicsOverviewDetails.emotions &&
      orderBy(
        Object.keys(filteredData[0]?.thematicsOverviewDetails.emotions)
          ?.map(emotion => ({
            id: `emotionsEvolution.${emotion}`,
            label: t(emotion.charAt(0).toUpperCase() + emotion.slice(1)),
            children:
              filteredData &&
              filteredData[0]?.thematicsOverviewDetails.emotionsEvolution &&
              Object.keys(filteredData[0]?.thematicsOverviewDetails.emotionsEvolution)?.map(evolution => ({
                id: `emotionsEvolution.${emotion}.${evolution}`,
                label: `${t(`emotion:${emotion} evolution`)} (% ${t(
                  evolution
                    .split(/(?=[A-Z])/)
                    .join(' ')
                    .toLowerCase(),
                )})`,
                cellType: 'evolution',
                getFormattedData: (data, id) => {
                  return data && data.delta !== null && id ? (
                    <BadgeBenchmarkTooltip
                      idTooltip={`emotionsEvolutionBadge${emotion}${evolution}${id
                        .replace(/\W+/g, ' ')
                        .split(/ |\B(?=[A-Z])/)
                        .map(word => word.toLowerCase())
                        .join('_')}`}
                      data={{
                        periodBefore: evolution === 'periodBefore' && data,
                        yearBefore: evolution === 'yearBefore' && data,
                      }}
                    />
                  ) : (
                    'N/A'
                  );
                },
              })),
          }))
          .map(emotion => {
            let index;
            switch (emotion.id) {
              case 'emotionsEvolution.happiness':
                index = 1;
                break;
              case 'emotionsEvolution.surprise':
                index = 2;
                break;
              case 'emotionsEvolution.calm':
                index = 3;
                break;
              case 'emotionsEvolution.fear':
                index = 4;
                break;
              case 'emotionsEvolution.sadness':
                index = 5;
                break;
              case 'emotionsEvolution.anger':
                index = 6;
                break;
              case 'emotionsEvolution.disgust':
                index = 7;
                break;
              default:
                index = 3;
            }
            return { ...emotion, index };
          }),
        item => item.index,
        'asc',
      ),
  },
  {
    id: 'sentiments',
    label: t('Sentiment'),
    children:
      filteredData &&
      filteredData[0]?.thematicsOverviewDetails.sentiments &&
      orderBy(
        Object.keys(filteredData[0]?.thematicsOverviewDetails.sentiments)
          ?.map(key => ({
            id: `sentiments.${key}`,
            label: t(key.charAt(0).toUpperCase() + key.slice(1)),
            children:
              filteredData &&
              filteredData[0]?.thematicsOverviewDetails.sentiments[key] &&
              Object.keys(filteredData[0]?.thematicsOverviewDetails.sentiments[key])?.map(subKey => ({
                id: `sentiments.${key}.${subKey}`,
                label: `${t(key.charAt(0).toUpperCase() + key.slice(1))} (${t(
                  subKey
                    .split(/(?=[A-Z])/)
                    .join(' ')
                    .toLowerCase(),
                )})`,
                cellType: 'number',
                getFormattedData: data =>
                  data > -1 && !isNaN(data) ? (
                    <SentimentValue sentiment={key}>
                      {data}
                      {subKey === 'byPercentage' && ' %'}
                    </SentimentValue>
                  ) : (
                    'N/A'
                  ),
              })),
          }))
          .map(key => {
            let index;
            switch (key.id) {
              case 'sentiments.positive':
                index = 1;
                break;
              case 'sentiments.neutral':
                index = 2;
                break;
              case 'sentiments.negative':
                index = 3;
                break;
              default:
                index = 2;
            }
            return { ...key, index };
          }),
        item => item.index,
        'asc',
      ),
  },
  {
    id: 'sentimentsEvolution',
    label: t('Sentiment evolution'),
    children:
      filteredData &&
      filteredData[0]?.thematicsOverviewDetails.sentiments &&
      orderBy(
        Object.keys(filteredData[0]?.thematicsOverviewDetails.sentiments)
          ?.map(sentiment => ({
            id: `sentimentsEvolution.${sentiment}`,
            label: t(sentiment.charAt(0).toUpperCase() + sentiment.slice(1)),
            children:
              filteredData &&
              filteredData[0]?.thematicsOverviewDetails.sentimentsEvolution &&
              Object.keys(filteredData[0]?.thematicsOverviewDetails.sentimentsEvolution)?.map(evolution => ({
                id: `sentimentsEvolution.${sentiment}.${evolution}`,
                label: `${t(`emotion:${sentiment} evolution`)} (% ${t(
                  evolution
                    .split(/(?=[A-Z])/)
                    .join(' ')
                    .toLowerCase(),
                )})`,
                cellType: 'evolution',
                getFormattedData: (data, id) => {
                  return data && data.delta !== null && id ? (
                    <BadgeBenchmarkTooltip
                      idTooltip={`sentimentsEvolutionBadge${sentiment}${evolution}${id
                        .replace(/\W+/g, ' ')
                        .split(/ |\B(?=[A-Z])/)
                        .map(word => word.toLowerCase())
                        .join('_')}`}
                      data={{
                        periodBefore: evolution === 'periodBefore' && data,
                        yearBefore: evolution === 'yearBefore' && data,
                      }}
                    />
                  ) : (
                    'N/A'
                  );
                },
              })),
          }))
          .map(sentiment => {
            let index;
            switch (sentiment.id) {
              case 'sentimentsEvolution.positive':
                index = 1;
                break;
              case 'sentimentsEvolution.neutral':
                index = 2;
                break;
              case 'sentimentsEvolution.negative':
                index = 3;
                break;
              default:
                index = 2;
            }
            return { ...sentiment, index };
          }),
        item => item.index,
        'asc',
      ),
  },
];

export const getSelectedColumns = (settings, availableColumns) => {
  let newSelectedColumns = [];
  settings?.categoryRanking?.selectedColumns.map(colId => {
    const [id, subId, subSubId] = colId.split('.');
    if (subSubId) {
      const grandParent = availableColumns.find(item => item.id === id)?.children;
      const parent = grandParent?.find(item => item.id === `${id}.${subId}`)?.children;
      if (grandParent && parent) {
        newSelectedColumns = [...newSelectedColumns, parent.find(item => item.id === colId)];
      }
    } else if (subId) {
      const parent = availableColumns.find(item => item.id === id)?.children;
      if (parent) {
        newSelectedColumns = [...newSelectedColumns, parent.find(item => item.id === colId)];
      }
    } else {
      newSelectedColumns = [...newSelectedColumns, availableColumns.find(item => item.id === id)];
    }
  });
  return newSelectedColumns;
};
