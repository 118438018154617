import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo'
import { withRouter } from 'react-router-dom';
import QModal from '_Components/Modals/QModal/QModal';
import QButton from '_Components/Buttons/QButton/QButton';
import ListAvailableSources from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ListAvailableSources/ListAvailableSources';
import CREATE_SAAS_UPLOAD_CONFIG_MUTATION from '_Cards/SettingsCards/ProjectsConfigurationCard/graphql/createSaasUploadConfig';

const FooterContainer = styled.div`
  & button:last-child {
    margin-left: 1em;
  }
`;

const Modal = styled(QModal)`
  min-width: 55vw !important;
`;

const NewSourcesModal = ({
  isModalOpen,
  openModal,
  setSources,
  sources,
  t,
  selectSources,
  useCaseId,
  industryId,
  history
}) => {
  const [selectedSources, setSelectedSources] = useState(selectSources);
  const [createSaasUploadConfig] = useMutation(CREATE_SAAS_UPLOAD_CONFIG_MUTATION);

  useEffect(() => {
    setSelectedSources(selectSources);
  }, [selectSources]);

  const onNewSources = () => {
    const convertSourceName = selectedSource => {
      const splitName = selectedSource.name.split(' ');
      const lowercaseName = splitName.map((name, ind) =>
        ind > 0 ? name.toLowerCase() : name,
      );
      const joinedName = lowercaseName.join('');
      return `${joinedName}Page`;
    };

    let sourcesToSend = [];

    selectedSources.forEach(selectedSource => {
      if (
        selectSources.find(
          selectsrc => selectsrc.name !== selectedSource.name,
        ) || selectSources.length === 0 || selectSources.length === 1
      ) {
        if (selectedSource.name !== t('projectConfiguration:File upload')) {
          sourcesToSend = [...sourcesToSend, {
            profile: '',
            profileName: '',
            source: convertSourceName(selectedSource),
            projectSourceItem: selectedSource,
          }];
          setSources([
            ...sourcesToSend,
          ]);
        } else {
          createSaasUploadConfig({
            variables: { useCaseId, industryId },
          }).then(({ data }) => {
            const {
              createSaasUploadConfig: { uuid },
            } = data;
            history.push({ pathname: `/upload-data/${uuid}`, state: { fromDetails: true } });
          });
        }
      }
    });
  };

  const modalFooter = (
    <FooterContainer>
      <QButton
        outline
        bgColor="grayShades.g800"
        onClick={() => openModal(!isModalOpen)}
      >
        {t('button:Cancel')}
      </QButton>
      <QButton
        onClick={() => {
          onNewSources();
          openModal(!isModalOpen);
        }}
      >
        {t('button:Add sources')}
      </QButton>
    </FooterContainer>
  );

  return (
    <Modal
      title={t('Choose your sources')}
      isOpen={isModalOpen}
      onClose={() => openModal(!isModalOpen)}
      footer={modalFooter}
      size="xl"
      config={{mentions: false, date: false}}
    >
      <ListAvailableSources
        setSelectedSources={setSelectedSources}
        selectedSources={selectedSources}
      />
    </Modal>
  );
};

export default withRouter(NewSourcesModal);
