import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PagesShortcuts from '../../../_Components/PagesShortcuts';
import cardsLayouts from '../../layouts/cardsLayouts';
import {
  PAGES_SHORTCUT_MODES,
  PAGES_SHORTCUT_TYPES,
} from '../../../_Components/Shortcut';
import { updatePageCard } from '../../../../../../_Resources/Reporting/Actions/updatePageCard';

class AddCards extends Component {
  static propTypes = {
    onPageCard: PropTypes.func.isRequired,
  };

  render() {
    const { onPageCard } = this.props;
    return (
      <PagesShortcuts
        mode={PAGES_SHORTCUT_MODES.PREDEFINED_LAYOUTS}
        droppable={false}
        shortcuts={cardsLayouts}
        onShortcutClicked={onPageCard}
        type={PAGES_SHORTCUT_TYPES.CARD}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onPageCard: page => {
    const {
      children: [shortcut],
    } = page;
    dispatch(updatePageCard(shortcut.content));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(AddCards);
