import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QTitle from '_Components/Texts/QTitle/QTitle';
import { grayShades } from 'styles/abstracts/colors';
import QButton from '_Components/Buttons/QButton/QButton';
import { fontSizes } from 'styles/abstracts/typography';
import Icon from '_Components/Icons/Icon';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.5em 1em;
  border-right: 1px solid ${grayShades.g400};
`;

const Separator = styled.hr`
  width: 80%;
  border-color: ${grayShades.g300};
  margin: 2em auto;
`;
const UseCaseIndustryContainer = styled.div`
  margin-top: 1.5em;
`;

const UseCaseIndustryItem = styled.p`
  margin-bottom: 0.25em;
`;

const ButtonsContainer = styled.div`
  display: flex;

  & a:not(:first-child) {
    margin-left: 0.5em;
  }
`;

const InstructionsList = styled.div`
  margin: 2em 0;

  & li {
    margin: 1em 0;
  }
`;

const Link = styled.a`
  font-size: ${fontSizes.smaller};
  font-weight: bold;
`;

const StyledParagraph = styled.p`
  margin-bottom: 0.5em;
`;

const BackContainer = styled.div`
  margin-top: 4.5em;
`;

const InstructionsSection = ({
  history,
  useCase,
  industry,
  location,
  t,
  lang,
}) => {
  const userLang = window.localStorage.getItem('lang');
  return (
    <Container>
      <QTitle>{t('Download the Excel Template file')}</QTitle>
      <UseCaseIndustryContainer>
        <UseCaseIndustryItem>
          <b>{t('Chosen industry')} </b> : {industry}
        </UseCaseIndustryItem>
        <UseCaseIndustryItem>
          <b>{t('Chosen use case')} </b> : {useCase}
        </UseCaseIndustryItem>
      </UseCaseIndustryContainer>
      <Separator />
      <InstructionsList>
        {t('Upload your file with a simple drag and drop on the right panel.')}
      </InstructionsList>
      <StyledParagraph
        dangerouslySetInnerHTML={{
          __html: t(
            'Don’t want to do it now ? Please note that you can access this page anytime at',
            {
              link: `<a style="font-size: ${fontSizes.smaller}; font-weight: bold;">${globalThis.location.href}</a>`,
            },
          ),
        }}
      />

      <StyledParagraph>
        {t(
          'Please note that an email with the instructions has been sent to your email address.',
        )}
      </StyledParagraph>
      <br />
      <StyledParagraph>
        {t('You are lost?')}{' '}
        <span
          dangerouslySetInnerHTML={{
            __html: t('Send us a message at', {
              link: `<a target="_blank" href="mailto:support-team@qemotion.com">support-team@qemotion.com</a>`,
            }),
          }}
        />
      </StyledParagraph>
      <BackContainer>
        <QButton
          hasOpacity
          onClick={() =>
            location?.state?.fromDetails
              ? history.goBack()
              : history.push({
                  pathname: '/project-configuration',
                  state: { step: 'sources-list' },
                })
          }
        >
          {t('button:Back')}
        </QButton>
      </BackContainer>
    </Container>
  );
};

export default withTranslation('card', 'button')(InstructionsSection);
