function findParentOption(options, parents) {
  const [currentParent, ...restParents] = parents;

  if (!currentParent) {
    return null;
  }

  const parentOption = options.find(option => option.id === currentParent);

  if (!parentOption) {
    return null;
  }

  return restParents.length
    ? findParentOption(parentOption.subOptions, restParents)
    : parentOption;
}

export const containsOption = options => optionWithParents => {
  const { option, parents } = optionWithParents;
  const parent = findParentOption(options, parents);
  if (parent && parent.type !== 'text') {
    return (
      parent.subOptions && parent.subOptions.find(({ id }) => id === option.id)
    );
  }
  return parent && parent.subOptions && parent.subOptions.find(subOption => subOption.option.id === option.id);
};

export const getNaiveKeyImplementation = optionWithParents => {
  optionWithParents[optionWithParents.length - 1] === undefined &&
    optionWithParents.pop();
  return optionWithParents
    .map(item => item && [...item.parents, item.option.id].join('-'))
    .join('|');
};
