import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QFilterSearchKeyword from '_Container/QProjectFilters/Fields/QFilterSearchKeyword';
import { createAdapter } from './adapter';

export class TextFilter extends PureComponent {
  static propTypes = {
    filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    type: PropTypes.string,

    // NOTE: properties passed by QProjectFilters
    onFilterOptionsCreated: PropTypes.func,
    onFilterRemoved: PropTypes.func,
    registerAdapter: PropTypes.func,
  };

  static defaultProps = {
    type: 'text',
    onFilterOptionsCreated: undefined,
    onFilterRemoved: undefined,
    registerAdapter: undefined,
  };

  componentDidMount() {
    const { registerAdapter, type } = this.props;
    registerAdapter(createAdapter(type));
  }

  componentWillUnmount() {
    const { onFilterRemoved } = this.props;
    onFilterRemoved();
  }

  render() {
    const { onFilterOptionsCreated, type, t } = this.props;
    const options = {
      id: 'textSearch',
      label: t('Text search'),
      subOptions: [
        {
          id: 'keywords',
          label: t('Keywords'),
          type,
          renderChildren: onSelect => (
            <QFilterSearchKeyword onSelect={onSelect} />
          ),
        },
      ],
    };
    onFilterOptionsCreated(options);
    return null;
  }
}

export const QTextFilter = withTranslation('filters')(TextFilter);
