import React from 'react';
import { get, find, defaultTo, lowerCase } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './style.scss';
import { selectAnalysisAxis } from '_Resources/Cards/Actions/selectAnalysisAxis';
import { sendEmotionSelected } from '_Resources/Cards/Actions/sendEmotionSelected';
import { sendSelectionSelected } from '_Resources/Cards/Actions/sendSelectionSelected';
import { sendSentimentSelected } from '_Resources/Cards/Actions/sendSentimentSelected';
import TabSentiments from '_Components/TabSentiments/TabSentiments';
import TabSelections from '_Components/TabSelections/TabSelections';
import { sentimentsList, emotions } from 'constants/index';
import TabEmotions from '../../../_Components/TabEmotions/TabEmotions';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import Header from './_Components/Header';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import QueryVerbatimList from '../../../_Components/Modals/VerbatimListModal/_Components/QueryVerbatimList';
import { keywordsUpdated } from '../../../_Resources/Cards/Actions/sendKeywordsUpdated';

class MentionPreviewPerEmotionsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categorySelectOption: {
        value: 'all',
        label: this.props.t('All categories'),
      },
      subCategorySelectOption: {
        value: 'all',
        label: this.props.t('All categories'),
      },
      thematics: this.props.defaultThematicFilter,
      selectedSortByOption: {
        value: 'DESC',
        label: this.props.t('button:Eindex - Descending'),
      },
      keywords: [],
      selectedAnalysisAxisOption: this.props.analysisAxis,
      selectedEmotions: this.props.emotionSelected,
      selectedSentiments: this.props.sentimentSelected,
      selectedSelections: this.props.selectionSelected,
    };
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onSortByChange = this.onSortByChange.bind(this);
    this.onSubCategoryChange = this.onSubCategoryChange.bind(this);
    this.onAnalysisAxisChange = this.onAnalysisAxisChange.bind(this);
  }

  componentDidMount() {
    const { dispatchSelectAnalysisAxis, dispatchSendSentimentSelected, dispatchSendSelectionSelected } = this.props;
    this.props.defaultThematicFilter &&
      this.updateCategoriesList(this.props.projectThematicsList, this.props.defaultThematicFilter);
    dispatchSelectAnalysisAxis('emotions');
    this.setState({ selectedAnalysisAxisOption: 'emotions' });
    dispatchSendSentimentSelected([]);
    this.setState({ selectedSentiments: [] });
    dispatchSendSelectionSelected([]);
    this.setState({ selectedSelections: [] });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters,
      projectThematicsList,
      defaultThematicFilter,
      emotionSelected,
      sentimentSelected,
      selectionSelected,
    } = this.props;
    const { thematics } = this.state;

    if (prevProps.filters && filters) {
      if (
        projectThematicsList !== prevProps.projectThematicsList ||
        defaultThematicFilter !== prevProps.defaultThematicFilter
      ) {
        prevProps.filters && this.updateCategoriesList(projectThematicsList, defaultThematicFilter);
      }
    }
    if (thematics && prevState.thematics && thematics.subThematic !== prevState.thematics.subThematic) {
      this.updateCategoriesList(projectThematicsList, thematics);
    }
    if (emotionSelected !== prevProps.emotionSelected) {
      this.setState({ selectedEmotions: emotionSelected });
    }
    if (sentimentSelected !== prevProps.sentimentSelected) {
      this.setState({ selectedSentiments: sentimentSelected });
    }
    if (selectionSelected !== prevProps.selectionSelected) {
      this.setState({ selectedSelections: selectionSelected });
    }
  }

  onSubCategoryChange(value) {
    const defaultThematicFilters = this.props.defaultThematicFilter;
    const thematic = { ...this.state.thematics };
    if (value.value !== 'all') {
      thematic.subSubThematic = value.value;
      this.setState({ subCategorySelectOption: value, thematics: thematic });
    } else {
      this.setState({
        subCategorySelectOption: {
          value: 'all',
          label: this.props.t('All categories'),
        },
        thematics: defaultThematicFilters,
      });
    }
  }

  onCategoryChange(value) {
    const thematic = { ...this.state.thematics };
    const defaultThematicFilters = this.props.defaultThematicFilter;
    const levelbis = defaultThematicFilters.subThematic ? 'subThematic' : 'thematic';

    if (value.value !== 'all') {
      if (levelbis === 'subThematic') {
        thematic.subSubThematic = value.value;
      } else if (levelbis === 'thematic') {
        thematic.subThematic = value.value;
        thematic.subSubThematic = '';
      }
      this.setState({
        categorySelectOption: value,
        subCategorySelectOption: '',
        thematics: thematic,
      });
    } else {
      this.setState({
        categorySelectOption: {
          value: 'all',
          label: this.props.t('All categories'),
        },
        thematics: defaultThematicFilters,
      });
    }
  }

  onSortByChange(selectedSortByOption) {
    this.setState({ selectedSortByOption });
  }

  onAnalysisAxisChange(selectedAnalysisAxisOption) {
    const {
      dispatchSelectAnalysisAxis,
      dispatchSendEmotionSelected,
      dispatchSendSentimentSelected,
      dispatchSendSelectionSelected,
    } = this.props;
    const previouslySelectedAnalysisAxisOption = this.state.selectedAnalysisAxisOption;
    switch (previouslySelectedAnalysisAxisOption) {
      case 'emotions':
        if (selectedAnalysisAxisOption.value !== previouslySelectedAnalysisAxisOption) {
          dispatchSendEmotionSelected([]);
          this.setState({ selectedEmotions: [] });
        }
        break;
      case 'sentiments':
        if (selectedAnalysisAxisOption.value !== previouslySelectedAnalysisAxisOption) {
          dispatchSendSentimentSelected([]);
          this.setState({ selectedSentiments: [] });
        }
        break;
      case 'selections':
        if (selectedAnalysisAxisOption.value !== previouslySelectedAnalysisAxisOption) {
          dispatchSendSelectionSelected([]);
          this.setState({ selectedSelections: [] });
        }
        break;
      default:
        break;
    }
    this.setState({ selectedAnalysisAxisOption: selectedAnalysisAxisOption.value });
    dispatchSelectAnalysisAxis(selectedAnalysisAxisOption.value);
  }

  findChildrenCategories(items, id) {
    if (!items) return;
    for (const item of items) {
      if (item.id === id) return item;
      const child = this.findChildrenCategories(item.values, id);
      if (child) return child;
    }
  }

  updateCategoriesList(projectThematicsList, pageThematics = {}) {
    const pageThematicToFindInList =
      pageThematics.subSubThematic || pageThematics.subThematic || pageThematics.thematic;
    const thematicsOptions = this.findChildrenCategories(projectThematicsList, pageThematicToFindInList);
    const categories =
      thematicsOptions &&
      thematicsOptions.values &&
      thematicsOptions.values.map(category => ({
        label: category.label,
        value: category.id,
      }));
    if (this.props.defaultThematicFilter.subThematic || (pageThematics.thematic && !pageThematics.subThematic)) {
      this.setState({
        categories,
        thematics: pageThematics,
      });
    } else if (pageThematics?.subThematic && !pageThematics?.subSubThematic) {
      this.setState({ subCategories: categories, thematics: pageThematics });
    }
  }

  onTagsChanged = tags => {
    const currentTags = defaultTo(tags, []);
    this.setState({ keywords: currentTags });
    this.props.keywordsUpdated(currentTags);
  };

  getSideBar = () => {
    const { sideBar, analysisAxis } = this.props;
    if (sideBar) {
      switch (analysisAxis) {
        case 'emotions':
          return <TabEmotions typeVertical />;
        case 'sentiments':
          return <TabSentiments typeVertical />;
        case 'selections':
          return <TabSelections typeVertical />;
        default:
          return <TabEmotions typeVertical />;
      }
    }
    return sideBar;
  };

  getEmotionsVariableArray = () => {
    const { analysisAxis } = this.props;
    const { selectedEmotions, selectedSentiments } = this.state;

    switch (analysisAxis) {
      case 'emotions':
        if (selectedEmotions && selectedEmotions.length > 0) {
          return String.prototype.toUpperCase.apply(selectedEmotions).split(',');
        }
        return [];
      case 'sentiments':
        if (selectedSentiments && selectedSentiments.length > 0) {
          let selectedEmotions = [];
          selectedSentiments.forEach(sentiment => {
            selectedEmotions = selectedEmotions.concat(sentimentsList[lowerCase(sentiment)]);
          });
          return selectedEmotions;
        }
        return [];
      default:
        return [];
    }
  };

  getSelectionsVariableArray = () => {
    const { analysisAxis } = this.props;
    const { selectedSelections } = this.state;
    switch (analysisAxis) {
      case 'selections':
        if (selectedSelections && selectedSelections.length > 0) {
          return selectedSelections;
        }
        return [];
      default:
        return [];
    }
  };

  setVariables = () => {
    const { filters, filterKeys, defaultThematicFilter, dataCollectorId } = this.props;
    const { thematics, selectedSortByOption } = this.state;
    const filtersToUse = filters || filterKeys;
    if (filtersToUse && filtersToUse.texts) {
      filtersToUse.keywords = get(
        find(filtersToUse.texts, item => item.name === 'keywords'),
        'values',
      );
      delete filtersToUse.texts;
    }

    let keywords;
    if (filtersToUse.keywords) {
      keywords = [...this.state.keywords, ...filtersToUse.keywords];
    } else if (this.state.keywords.length !== 0) {
      keywords = this.state.keywords;
    } else {
      keywords = filtersToUse.keywords || this.props.keywords;
    }


    const variablesToReturn = {
      projectId: filtersToUse && filtersToUse.projectId,
      range: {
        startDate: get(filtersToUse, 'range.startDate'),
        endDate: get(filtersToUse, 'range.endDate'),
      },
      criteriaKeys: filtersToUse && filtersToUse.criteriaKeys,
      thematics: defaultThematicFilter ? (this.state ? thematics : []) : filtersToUse ? filtersToUse.thematics : [],
      sort: selectedSortByOption.value,
      lastVerbatimId: null,
      keywords,
      dataCollectorId,
    };

    const selectedEmotions = this.getEmotionsVariableArray();
    const selectedClusters = this.getSelectionsVariableArray();
    if (selectedEmotions.length < 1 && selectedClusters.length < 1) {
      return {
        ...variablesToReturn,
        selectedEmotions: emotions.map(emotion => emotion.name.toUpperCase()),
        selectedClusters,
      };
    }
    return {
      ...variablesToReturn,
      selectedEmotions: this.getEmotionsVariableArray(),
      selectedClusters: this.getSelectionsVariableArray(),
    };
  };

  render() {
    const sideBar = this.getSideBar();
    const {
      filters,
      filterKeys,
      projectId,
      headerRef,
      headerWidth,
      setHeaderWidth,
      disableKeywordsSearch,
      keywords: propsKeywords,
    } = this.props;
    const {
      keywords,
      selectedSortByOption,
      categorySelectOption,
      subCategorySelectOption,
      categories,
      subCategories,
      selectedAnalysisAxisOption,
    } = this.state;
    const filtersToUse = filters || filterKeys;
    const subHeader = (
      <>
        <Header
          filters={filtersToUse}
          onCategoryChange={this.onCategoryChange}
          onSubCategoryChange={this.onSubCategoryChange}
          onSortByChange={this.onSortByChange}
          categorySelectOption={categorySelectOption}
          subCategorySelectOption={subCategorySelectOption}
          selectedSortByOption={selectedSortByOption}
          categories={categories}
          subCategories={subCategories}
          onTagsChanged={this.onTagsChanged}
          initialTags={propsKeywords}
          disableKeywordsSearch={disableKeywordsSearch}
          selectedAnalysisAxisOption={selectedAnalysisAxisOption}
          onAnalysisAxisChange={this.onAnalysisAxisChange}
        />
      </>
    );
    return (
      <QcardPanel
        {...this.props}
        hints={[HintsContent.HINT_VERBATIM_PREVIEW, HintsContent.HINT_DETAILED_VERBATIM_BUBBLE]}
        sideBar={sideBar}
        subHeader={subHeader}
        CustomCSSBodyCol="bodyGrey"
        scroll
      >
        {filtersToUse && filtersToUse.projectId && (
          <QueryVerbatimList
            projectId={projectId}
            selectedSortByOption={selectedSortByOption}
            filters={this.setVariables()}
            keywords={keywords}
            headerRef={headerRef}
            headerWidth={headerWidth}
            setHeaderWidth={setHeaderWidth}
          />
        )}
      </QcardPanel>
    );
  }
}

MentionPreviewPerEmotionsCard.defaultProps = {
  emotionSelected: [],
  sentimentSelected: [],
  selectionSelected: [],
  sideBar: true,
  lazyLoad: false,
};

function mapStateToProps(state) {
  return {
    emotionSelected: get(state.cards, ['mentionPreviewPerEmotion', 'selectedEmotion']),
    sentimentSelected: get(state.cards, ['mentionPreviewPerEmotion', 'selectedSentiment']),
    selectionSelected: get(state.cards, ['mentionPreviewPerEmotion', 'selectedSelection']),
    keywords: get(state.cards, ['mentionPreviewPerEmotion', 'keywords']),
    filterKeys: state.filters.filterKeys,
    projectThematicsList: state.filters.projectFilters && state.filters.projectFilters.thematics,
    dataCollectorId: get(state, ['filters', 'filterKeys', 'dataCollectorId']),
    analysisAxis: get(state.cards, ['mentionPreviewPerEmotion', 'analysisAxis']),
  };
}

const mapDispatchToProps = dispatch => {
  return {
    keywordsUpdated: keywords => dispatch(keywordsUpdated(keywords)),
    dispatchSelectAnalysisAxis: analysisAxis => dispatch(selectAnalysisAxis(analysisAxis)),
    dispatchSendEmotionSelected: emotion => dispatch(sendEmotionSelected(emotion)),
    dispatchSendSentimentSelected: sentiment => dispatch(sendSentimentSelected(sentiment)),
    dispatchSendSelectionSelected: selection => dispatch(sendSelectionSelected(selection)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('button')(MentionPreviewPerEmotionsCard));
