import { Collapse } from 'reactstrap';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { MessageTable } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/MessageTable';
import { CollapseContent } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/CollapseContent';

const ThematicMergeMessage = ({ context, isOpen, thematicsResults, t }) => (
  <CollapseContent isOpen={isOpen}>
    <Collapse isOpen={isOpen}>
      {thematicsResults.length > 0 ? (
        <MessageTable>
          <tr>
            <th>{t('Thematic')}</th>
            <th>{t('Sub thematic')}</th>
            <th>{t('Sub sub thematic')}</th>
            <th>{t('Excluded')}</th>
          </tr>
          {thematicsResults.map(thematic => (
            <tr>
              <td>{thematic.thematic}</td>
              <td>{thematic.subThematic}</td>
              <td>{thematic.subSubThematic}</td>
              <td>{thematic.excluded ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </MessageTable>
      ) : (
        <p className="font-weight-bold">
          {' '}
          {t('No thematic set on this cardColumn nor the global context', {
            type: `$t(${context})`,
          })}
        </p>
      )}
    </Collapse>
  </CollapseContent>
);

export default withTranslation('conflicts')(ThematicMergeMessage);
