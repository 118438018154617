import React from 'react';
import { Col, Row } from 'reactstrap';
import SignUpForm from './_Components/SignUpForm/SignUpForm';
import logoText2x from '../Login/img/qemotion_login-logo@2x.png';
import logoText from '../Login/img/qemotion_login-logo.png';

const SignUp = ({ history, setToken, t, windowDimensions }) => {
  return (
    <Row className="justify-content-center flex-column">
      <Col className="mb-lg-2 mt-lg-2 mx-auto">
        <img
          src={logoText}
          srcSet={`${logoText} 1x, ${logoText2x} 2x`}
          alt="Q°emotion"
          className="m-auto img-fluid"
        />
      </Col>
      <Col className="mb-lg-2 mt-1 mx-auto">
        <h3>{t('Create your account')}</h3>
      </Col>
      <Col className="mx-auto">
        <SignUpForm history={history} setToken={setToken} t={t} windowDimensions={windowDimensions} />
      </Col>
    </Row>
  );
};

export default SignUp;
