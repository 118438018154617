import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { merge, isEqual } from 'lodash';
import memoize from 'memoize-one';

import { updateDisplaySettings } from '../../../../../../_Resources/Reporting/Actions/GlobalLayoutSettings/updateDisplaySettings';
import PresentationDateRange from '../../../_Components/PresentationDateRange';
import { dateRangeInitialState } from '../../../../../../_Resources/Reporting/Reducers/reporting';

class DateRange extends Component {
  static propTypes = {
    dispatchUpdateDisplaySettings: PropTypes.func.isRequired,
    globalSettings: PropTypes.any.isRequired,
    projectPeriods: PropTypes.any,
    currentPeriod: PropTypes.any,
  };

  static defaultProps = {
    projectPeriods: null,
    currentPeriod: null,
  };

  onUpdate = dateRange => {
    const { dispatchUpdateDisplaySettings } = this.props;
    dispatchUpdateDisplaySettings({
      dateRange,
    });
  };

  onReset = () => {
    const { dispatchUpdateDisplaySettings, currentPeriod } = this.props;
    dispatchUpdateDisplaySettings({
      dateRange: merge({}, dateRangeInitialState, currentPeriod),
    });
  };

  prepareSettings = memoize((globalDateRange, currentProjectPeriod) =>
    merge({}, currentProjectPeriod, globalDateRange),
  );

  render() {
    const {
      globalSettings: { dateRange },
      currentPeriod,
      projectPeriods,
    } = this.props;
    const settings = projectPeriods
      ? this.prepareSettings(dateRange, currentPeriod)
      : dateRange;
    const globalSettingsPeriod = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };

    return (
      <PresentationDateRange
        settings={settings}
        periods={projectPeriods}
        isModified={!isEqual(globalSettingsPeriod, currentPeriod)}
        onUpdate={this.onUpdate}
        onReset={this.onReset}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDisplaySettings: (...args) =>
    dispatch(updateDisplaySettings(...args)),
});

const mapStateToProps = ({
  reporting: { globalSettings },
  periods: { projectPeriods, currentPeriod },
}) => ({
  globalSettings,
  projectPeriods,
  currentPeriod,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(DateRange));
