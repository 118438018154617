import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import menuItem from '_Layouts/Header/_Containers/HeaderTop/menuItems';
import MenuItem from '_Layouts/_Components/MenuItem';

const Container = styled.div`
  height: calc(100% - 22.5em);
  padding: 1em 0;
  margin-top: 5.5em !important;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transform: translate3d(0, 0, 0);
  overflow-y: auto;
  overflow-x: hidden;
  position: static;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    overflow: -moz-scrollbars-none;
  }
`;

const MenuItems = styled.div`
  min-height: 4em;
  justify-content: center;
`;

class Items extends React.Component {

  getDisabledStatus(item) {
    return this.props.projectList.length === 0;
  }

  render() {
    return (
      <Container id="menu">
        {menuItem.slice(0, menuItem.length - 1).map((item, i) =>
          !item.layout
            ? (!item.hide || item.hide === false) && (
                <MenuItems className="d-flex align-items-center align-content-center">
                  <MenuItem
                    key={`menuItem${item.name}${i}`}
                    {...item}
                    active={item.name === this.props.name}
                    disabled={this.getDisabledStatus(item)}
                    isProjectListEmpty={this.props.projectList.length === 0}
                  />
                </MenuItems>
              )
            : null,
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    name: state.routes.route && state.routes.route.name,
    currentProject: state.projects.currentProject,
    projectList: state.projects.projectsList,
    currentPlan: state.auth.user && state.auth.user.account && state.auth.user.account.planName,
  };
}

export default connect(mapStateToProps)(Items);
