import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { getRoute } from '_Utils/routes/routeUtils';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import AlertingCard from '_Cards/AlertsCards/AlertingCard/AlertingCard';

type AlertingProps = {
  idpage: string;
  dispatchUpdateRoute: any;
};

const Alerting = ({ idpage, dispatchUpdateRoute }: AlertingProps) => {
  useEffect(() => {
    getRoute(dispatchUpdateRoute, idpage);
  }, []);

  return (
    <Container fluid className="py-3 px-4">
      <AlertingCard />
    </Container>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatchUpdateRoute: (newState: any) => {
    dispatch(updateRoute(newState));
  },
});

export default connect(null, mapDispatchToProps)(Alerting);
