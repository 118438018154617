import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { fontSizes } from '../../../../../../../../styles/abstracts/typography';
import { spacing } from '../../../../../../../../styles/abstracts/variables';
import { grayShades } from '../../../../../../../../styles/abstracts/colors';
import Link from '_Components/Link';
import { resetReportingSettings } from '../../../../../../../../_Resources/Reporting/Actions/resetReportingSettings';

const Container = styled.td`
  width: 50%;
  border: none !important;
`;

const ProjectName = styled(Link)`
  display: inline;
  font-size: ${fontSizes.bigger};
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  margin: 0 ${spacing.small};
  :hover {
    text-decoration: underline !important;
  }
`;

const LastEdit = styled.p`
  font-size: ${fontSizes.extraSmall};
  margin: ${spacing.small};
  color: ${grayShades.g800};
`;

const TemplateInfos = props => {
  const { data, t, dispatchResetReportingSettings } = props;
  const { id, title, settings } = data;
  let report;
  try {
    report = JSON.parse(settings);
  } catch (e) {
    report = {};
  }
  return (
    <Container>
      <span>
        <ProjectName
          to="reporting"
          onClick={() => dispatchResetReportingSettings(id, title, report)}
        >
          {data.title}
        </ProjectName>
      </span>
      <LastEdit>
        {t('Last edition on')} :{' '}
        <b>
          {moment(data.updatedAt)
            .locale(props.i18n.language)
            .format('LL')}
        </b>
        &nbsp; &nbsp; {t('Last edition by')} : <b>{data.updatedBy}</b>
      </LastEdit>
    </Container>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchResetReportingSettings: (id, title, report) => {
    dispatch(resetReportingSettings(id, title, report));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation('card')(TemplateInfos));
