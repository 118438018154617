import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { withTranslation } from 'react-i18next';
import CModal from '_Components/CModal/CModal';
import ModaleExportBody from '_Components/QcardPanel/ExportCard/_Components/ModaleExportBody';
import ModaleExportFooter from '_Components/QcardPanel/ExportCard/_Components/ModaleExportFooter';

const MenuExport = styled.div`
  position: relative;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  justify-content: flex-end;
  padding: 0 1rem;
  top: 1rem;
`;
class ExportCardModale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.isOpen,
      hoverCard: false,
    };
    this.getSettingsExport = this.getSettingsExport.bind(this);
  }

  getSettingsExport(settings) {
    this.setState({
      optionSettings: settings,
    });
  }

  createExportContentStructure() {
    return (
      <ModaleExportBody
        cardID={this.props.title}
        getSettingsExport={this.getSettingsExport}
        dataByFiltersCard={this.props.dataByFiltersCard}
      />
    );
  }

  footer() {
    return (
      <ModaleExportFooter
        toggleModal={this.props.toggleModal}
        exportCard={this.exportCard}
        optionSettings={this.state.optionSettings}
      />
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen !== this.state.modal) {
      this.setState({
        modal: this.props.isOpen,
      });
    }
  }

  exportCard(optionSettings) {
    const element = document.getElementById(optionSettings.cardID);
    const titleCard = document.getElementById(`${optionSettings.cardID}-title`);
    const subtitleCard = document.getElementById(
      `${optionSettings.cardID}-subtitle`,
    );
    const iconHeaderCard = document.getElementById(
      `${optionSettings.cardID}-iconHeaderCard`,
    );
    const dataByFiltersDropdownsContainer = document.getElementById(
      'dataByFilterDropdownsContainer',
    );
    const colTitleCard = document.querySelectorAll('p[class^="cards__Title"]');

    element.style.fontFamily = 'Nunito, sans-serif';
    colTitleCard.forEach(title => {
      title.style.height = '3rem';
    });
    document.body.style.zoom = 1.0;
    function resetCard() {
      iconHeaderCard.style.display = 'block';
      if (element !== null) {
        element.style.background = '#FFF';
      }
      if (titleCard !== null) {
        titleCard.style.display = 'block';
      }
      if (subtitleCard !== null) {
        subtitleCard.style.display = 'block';
      }
      if (dataByFiltersDropdownsContainer !== null) {
        dataByFiltersDropdownsContainer.style.display = 'block';
      }
      colTitleCard.forEach(title => (title.style.height = 'auto'));
    }
    iconHeaderCard.style.display = 'none';
    if (!optionSettings.backgroundCard && element !== null) {
      element.style.background = 'transparent';
    }
    if (!optionSettings.titleDisplay && titleCard !== null) {
      titleCard.style.display = 'none';
    }
    if (!optionSettings.subtitleDisplay && subtitleCard !== null) {
      subtitleCard.style.display = 'none';
    }
    if (
      !optionSettings.showDataByFiltersDropdowns &&
      dataByFiltersDropdownsContainer !== null
    ) {
      dataByFiltersDropdownsContainer.style.display = 'none';
    }

    return html2canvas(element, {
      backgroundColor: 'transparent',
      width: element.offsetWidth,
      height: element.offsetHeight,
      windowWidth: element.offsetWidth,
      windowHeight: element.offsetHeight,
      scrollY: element.scrollY,
      scrollX: element.scrollX,
      x: 0,
      y: 0,
      foreignObjectRendering: true,
      useCORS: true,
      allowTaint: true,
    }).then(canvas => {
      const img = canvas.toDataURL(`image/${optionSettings.format}`, 1.0);
      const link = document.createElement('a');
      link.download = `${optionSettings.cardID}-image.${optionSettings.format}`;
      link.href = img;
      link.click();
      resetCard();
      this.toggleModal();
    });
  }

  render() {
    const self = this;
    const { t } = this.props;
    const propsModal = {
      numberOfMentions: '',
      displayMentionsSubtitle: false,
      displayDateSubtitle: false,
      subtitleAdditionalContent:
        'Choose the format and change the display of the card for export',
      titleSecondPart: '',
      title: 'button:Export card',
      toggle: self.toggleModal,
      data: {},
      modal: self.state.modal,
      footer: this.footer(),
    };
    const modal = (
      <CModal {...propsModal}>{this.createExportContentStructure()}</CModal>
    );
    return <MenuExport isOpen={this.props.isOpen}>{modal}</MenuExport>;
  }
}

ExportCardModale.propTypes = {
  displayCardTitle: PropTypes.bool,
  displayCardSubTitle: PropTypes.bool,
  title: PropTypes.string,
  titleSecondPart: PropTypes.string,
  numberOfMentions: PropTypes.number,
  hints: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  ),
};

ExportCardModale.defaultProps = {
  displayCardTitle: true,
  displayCardSubTitle: true,
  titleSecondPart: null,
  title: null,
  numberOfMentions: null,
  hints: null,
};

export default withTranslation('card')(ExportCardModale);
