import React, { useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { grayShades, purple } from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import Icon from '_Components/Icons/Icon';
import MutationSaveSettings from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/MutationSaveSettings';
import { Menu, StyledDropdownToggle, Label, Item, ToggleButton } from './utils/DropdownStyles';
import { ORDER_BY_ITEMS } from '../../FiltersSettings';

const BackItem = styled(Item)`
  justify-content: flex-start !important;
  padding-left: 0.25em !important;
  color: ${grayShades.g800} !important;
`;

const StyledItem = styled(Item)`
  justify-content: ${({ alignleft }) => (alignleft ? 'flex-start' : 'space-between')} !important;
`;

const OrderByDropdown = props => {
  const [dropdown, setDropdown] = useState(false);
  const [dropdownContent, setDropdownContent] = useState(null);
  const { orderBy, setOrderBy, settingsVariables, t, id } = props;
  const settings = JSON.parse(settingsVariables.settings);
  return (
    <div id={id}>
      <Label>{t('Sort by')}</Label>
      <Dropdown
        direction="down"
        isOpen={dropdown}
        toggle={() => {
          setDropdown(!dropdown);
          dropdownContent && setDropdownContent(null);
        }}
      >
        <StyledDropdownToggle>
          <ToggleButton bgColor="grayShades.g300" icon="INTERFACE_CHEVRON_DOWN" iconColor="purple" disableHover>
            <Icon
              icon={orderBy.value.split('_')[1] === 'asc' ? 'INTERFACE_SORT_ASC' : 'INTERFACE_SORT_DESC'}
              size={ImageSize.medium}
            />
            &nbsp;
            {`${t(orderBy.label).substring(0, 10)}(...)` || 'Aucun filtre'}
          </ToggleButton>
        </StyledDropdownToggle>
        <Menu>
          {!dropdownContent ? (
            ORDER_BY_ITEMS.map(item => (
              <Item
                toggle={item.subcategories && false}
                onClick={() => (item.subcategories ? setDropdownContent(item.subcategories) : setOrderBy(item))}
                disabled={
                  props.currentIndicators.badge.value !== item.value &&
                  props.currentIndicators.bar.value !== item.value &&
                  item.value.split('_')[0] !== 'label'
                }
              >
                {t(item.label)}{' '}
                {item.subcategories && (
                  <Icon
                    icon="INTERFACE_CHEVRON_RIGHT"
                    size={ImageSize.medium}
                    color={
                      props.currentIndicators.badge.value !== item.value &&
                      props.currentIndicators.bar.value !== item.value &&
                      item.value.split('_')[0] !== 'label'
                        ? grayShades.g800
                        : purple
                    }
                  />
                )}
              </Item>
            ))
          ) : (
            <>
              <BackItem onClick={() => setDropdownContent(null)} toggle={false}>
                <Icon icon="INTERFACE_CHEVRON_LEFT" size={ImageSize.medium} color={grayShades.g800} />
                {t('Back')}
              </BackItem>
              {dropdownContent &&
                dropdownContent.map(content => (
                  <MutationSaveSettings
                    callback={() => {
                      !content.subcategories && setOrderBy(content);
                      content.subcategories && setDropdownContent(content.subcategories);
                      settings.orderBy = content;
                      settingsVariables.settings = JSON.stringify(settings);
                    }}
                    variables={settingsVariables}
                    projectConfiguration={props.projectConfiguration}
                    defaultThematic={props.defaultThematic}
                  >
                    <StyledItem
                      alignleft={!content.subcategories}
                      toggle={content.subcategories && false}
                      disabled={
                        orderBy.label === content.label ||
                        (content.subcategories && !settings.indicators.bar.selected.includes(content.value))
                      }
                    >
                      {!content.subcategories && (
                        <Icon
                          icon={content.value.split('_')[1] === 'asc' ? 'INTERFACE_SORT_ASC' : 'INTERFACE_SORT_DESC'}
                          size={ImageSize.medium}
                          color={orderBy.label === content.label ? grayShades.g800 : purple}
                        />
                      )}
                      &nbsp;{t(content.label)}
                      {content.subcategories && (
                        <Icon
                          icon="INTERFACE_CHEVRON_RIGHT"
                          color={
                            orderBy.label === content.label ||
                            (content.subcategories && !settings.indicators.bar.selected.includes(content.value))
                              ? grayShades.g800
                              : purple
                          }
                          size={ImageSize.medium}
                        />
                      )}
                    </StyledItem>
                  </MutationSaveSettings>
                ))}
            </>
          )}
        </Menu>
      </Dropdown>
    </div>
  );
};

export default withTranslation('button')(OrderByDropdown);
