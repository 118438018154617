import { merge } from 'lodash';
import { FILTERS_REMOVE_STRATEGIES } from '../../../../_Utils/filters/filterUtils';
import { rawFilterValues } from '../../../../_Utils/filters/rawFilterValues';

export const addReportFilter = (state, { filterType, filterValues }) => {
  const { filters } = state.globalSettings;
  const existingFilters = filters[filterType] || [];
  return {
    ...state,
    globalSettings: merge({}, state.globalSettings, {
      filters: {
        [filterType]: [...existingFilters, ...rawFilterValues(filterValues)],
      },
    }),
  };
};

export const removeReportFilter = (
  state,
  { filterType, filterValues: [[filterValue], parentKey] },
) => {
  const { globalSettings } = state;
  const filters = globalSettings.filters || {};
  const existingFilters = filters[filterType] || [];

  const removingStrategy =
    FILTERS_REMOVE_STRATEGIES[filterType] || FILTERS_REMOVE_STRATEGIES.default;

  return {
    ...state,
    globalSettings: {
      ...globalSettings,
      filters: {
        ...filters,
        [filterType]: removingStrategy(existingFilters, filterValue, parentKey),
      },
    },
  };
};
