import React from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { getRoute } from '_Utils/routes/routeUtils';
import QResponsiveGridLayout from '_Layouts/QResponsiveGridLayout/QResponsiveGridLayout';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { get } from 'lodash';
import NoConfigPage from '_Components/NoConfigPage/NoConfigPage';
import imgGraphsDataByFilter from '_Components/NoConfigPage/images/data-by-filters-graph.gif';
import imgDataDetails from '_Components/NoConfigPage/images/filter-details-tabs.gif';
import layoutConfig from './config';
import { getComponentsLayout } from '../../_Utils/routeComponent';
import SideCardContainer from './_Containers/SideCardContainer';

class DataByFiltersGeneric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idpage: this.props.idpage,
      layout: layoutConfig,
      numberOfMentions: null,
      associatedThematic: null,
      displaySideCard: false,
      cardFilters: {},
      unmountSideCard: true,
    };
    this.toggleDisplaySideCard = this.toggleDisplaySideCard.bind(this);
    this.setCardTitle = this.setCardTitle.bind(this);
    this.setCardFilters = this.setCardFilters.bind(this);
  }

  componentDidMount() {
    getRoute(this.props.updateRoute, this.state.idpage, this.props.thematic, this.props.projectConfiguration);
    if (this.props.filterKeys && this.props.pageConfiguration) {
      this.setFilterKeys(this.props.filterKeys);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.idpage && this.props.idpage !== prevProps.idpage) ||
      prevProps.projectConfiguration !== this.props.projectConfiguration
    ) {
      getRoute(prevProps.updateRoute, this.props.idpage, this.props.thematic, this.props.projectConfiguration);
      this.setState({
        idpage: this.props.idpage,
        associatedThematic: null,
      });
    }

    if (
      this.props.pageConfiguration &&
      prevProps.pageConfiguration &&
      this.props.pageConfiguration.defaultThematic !== prevProps.pageConfiguration.defaultThematic
    ) {
      if (this.props.pageConfiguration.defaultThematic !== null) {
        this.setState(
          {
            associatedThematic: this.props.pageConfiguration.defaultThematic,
          },
          () => {
            this.props.setGlobalQueryFilterKeys(this.setFilterKeys(this.props.filterKeys));
          },
        );
      } else {
        this.setState({ associatedThematic: null });
      }
    }

    if (
      (this.props.filterKeys && this.props.filterKeys !== prevProps.filterKeys) ||
      prevProps.projectConfiguration !== this.props.projectConfiguration ||
      prevProps.pageConfiguration !== this.props.pageConfiguration
    ) {
      this.setFilterKeys(this.props.filterKeys);
    }
  }

  toggleDisplaySideCard() {
    this.setState({ displaySideCard: !this.state.displaySideCard });
    setTimeout(
      () => this.setState({ unmountSideCard: !this.state.unmountSideCard }),
      this.state.unmountSideCard ? 0 : 500,
    );
  }

  setCardTitle(title) {
    this.setState({ title });
  }

  setCardFilters(cardFilters) {
    this.setState({ cardFilters });
  }

  setFilterKeys(filterKeys) {
    if (!filterKeys) return;
    const keys = JSON.parse(JSON.stringify(filterKeys));
    const defaultThematic = get(this.props, ['pageConfiguration', 'defaultThematic']);
    if (defaultThematic) {
      const defaultThematicFilter = {
        thematic: defaultThematic,
        subThematic: '',
        subSubThematic: '',
      };
      keys.thematics = [];
      keys && keys.thematics && keys.thematics.push(defaultThematicFilter);
      this.setState({
        associatedThematic: defaultThematic,
        filterPage: keys,
        defaultThematicFilter,
      });
    } else {
      this.setState({
        associatedThematic: null,
        defaultThematicFilter: null,
      });
    }
    return keys;
  }

  render() {
    const { layout, associatedThematic } = this.state;
    const layoutComponents = getComponentsLayout(
      layout,
      {},
      {
        idpage: this.state.idpage,
        defaultThematic: get(this.props, ['pageConfiguration', 'defaultThematic']),
        thematic: this.props.thematic,
        toggleDisplaySideCard: this.toggleDisplaySideCard,
        setCardTitle: this.setCardTitle,
        setCardFilters: this.setCardFilters,
        projectConfiguration: this.props.projectConfiguration,
      },
    );
    const noConfigContent = {
      noConfigMainMessage: {
        message: 'DEFAULT_MAIN_MESSAGE',
        image: null,
      },
      noConfigContent: [
        {
          image: imgGraphsDataByFilter,
          title: 'DATA_BY_FILTERS_01_TITLE',
          description: 'SYNTHETIC_VIEW_01_CONTENT',
          descriptionVars: { moduleSubject: 'Votre personnel' },
          titleVars: {
            moduleSubject: `MODULE_EXPLANATION_${this.props.thematic.toUpperCase()}`,
          },
        },
        {
          image: imgDataDetails,
          title: 'DATA_BY_FILTERS_02_TITLE',
          description: 'DATA_BY_FILTERS_02_CONTENT',
        },
        // {
        //   image: imgGraphs,
        //   title: 'MAP_02_TITLE',
        //   description: 'MAP_02_CONTENT',
        // },
      ],
      noConfigEndMessage: {
        message: 'DEFAULT_LAST_MESSAGE',
      },
    };
    return (layout && associatedThematic !== null) || this.props.thematic === 'global' ? (
      <div>
        <Container fluid className="py-3 px-4">
          <QResponsiveGridLayout layout={layout} isResizable={this.props.editableLayout}>
            {layoutComponents}
          </QResponsiveGridLayout>
          <SideCardContainer
            toggleDisplaySideCard={this.toggleDisplaySideCard}
            displaySideCard={this.state.displaySideCard}
            unmountSideCard={this.state.unmountSideCard}
            filters={this.state.cardFilters}
            cardTitle={this.state.title}
            closeCard={() => this.toggleDisplaySideCard()}
            isCardOpened={this.state.displaySideCard}
          />
        </Container>
      </div>
    ) : (
      <div>
        <Container fluid className="py-5 px-4">
          <NoConfigPage config={noConfigContent} />
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    module: state.routes.route && state.routes.route.name,
    projectConfiguration: state.projectConfiguration,
    filterKeys: state.filters.filterKeys,
    pageConfiguration: state.routes.pageConfiguration,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
    setGlobalQueryFilterKeys: newState => {
      dispatch(setGlobalQueryFilterKeys(newState));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataByFiltersGeneric);
