import React, { useState } from 'react';
import styled from 'styled-components';
import { Col } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import QPanel from '_Components/Panels/QPanel/QPanel';
import { grayShades, purple } from 'styles/abstracts/colors';
import QPanelHeader from '_Components/Panels/QPanelHeader/QPanelHeader';
import CQuery from '_Container/QQuery/CQuery';
import getProjectListCardData from 'Routes/Main/MainPage/_Components/ProjectListCard/getProjectListCardData';
import QButton from '_Components/Buttons/QButton/QButton';
import UpdateAllProjectsModal from '_Cards/SettingsCards/ProjectsCard/_Containers/ProjectsContainer/_Containers/ProjectRow/_Components/Modals/UpdateAllProjectsModal';
import ProjectsContainer from './_Containers/ProjectsContainer/ProjectsContainer';

const StyledQPanel = styled(QPanel)`
  margin: 1em 0;
  padding: 12px 0;
`;

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 1rem;
  border-bottom: 1px solid ${grayShades.g300};
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;

const TitleCol = styled(Col)`
  line-height: 3rem;
  padding-left: 0 !important;
  padding-bottom: 1rem;
`;

const ButtonsDiv = styled.div`
  line-height: 3rem;
`;
const OutlinedButton = styled(QButton)`
  border: 2px solid ${purple} !important;
  margin-right: 1em;
`;

const ProjectsCard = ({ t, archived, history, currentUser }) => {
  const [isUpdateAllModalOpen, openUpdateAllModal] = useState(false);
  const { data } = useQuery(getProjectListCardData, {
    variables: { archived },
  });
  const [projectCount, setProjectCount] = useState(0);
  return (
    <StyledQPanel>
      <StyledQPanelHeader type="menu">
        <TitleCol>
          {t(archived ? 'card:Archived projects' : 'card:Active projects')} (
          {projectCount})
        </TitleCol>
        {!archived && currentUser.account?.planName !== 'emotion_corporate' && (
          <ButtonsDiv>
            {data?.projects?.length > 0 && (
              <>
                <OutlinedButton
                  outline
                  iconPosition="left"
                  icon="INTERFACE_REFRESH"
                  onClick={() => openUpdateAllModal(!isUpdateAllModalOpen)}
                >
                  &nbsp; {t('Update all projects')}
                </OutlinedButton>
                <UpdateAllProjectsModal
                  isOpen={isUpdateAllModalOpen}
                  open={openUpdateAllModal}
                />
              </>
            )}
            <span id="createProjectButton">
              <QButton
                id="settingsCreateProjectButton"
                bgColor="lightBlue"
                iconPosition="left"
                icon="INTERFACE_PLUS_CIRCLE"
                onClick={() => history.push('/project-configuration')}
                disabled={
                  !currentUser.hasEmailValidated ||
                  !currentUser.account?.planName ||
                  !currentUser.account?.remainingCredits
                }
                popoverText={
                  !currentUser.hasEmailValidated
                    ? t(
                        'You must validate your email address in order to create a new project.',
                      )
                    : !currentUser.account?.planName &&
                      t('You must have a plan in order to create a new project.')
                }
              >
                &nbsp; {t('Create a new project')}
              </QButton>
            </span>
          </ButtonsDiv>
        )}
      </StyledQPanelHeader>
      <ProjectsContainer
        archived={archived}
        projectCount={projectCount}
        setProjectCount={setProjectCount}
        history={history}
        data={data}
      />
    </StyledQPanel>
  );
};

export default withRouter(withTranslation('button', 'card')(ProjectsCard));
