/* eslint-disable react/sort-comp */
import React, { PureComponent, Fragment } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { InsightPropType } from '_Resources/Insight/Services/api/insight.propTypes';
import QueryGetInsights from '_Resources/Insight/Services/api/QueryGetInsights';
import { loadInsightVerbatimSuccess } from '_Resources/Insight/Actions/loadInsightDetailsVerbatim';
import { connect } from 'react-redux';
import Icon from '_Components/Icons/Icon';
import { QTooltip, QTooltipTitle } from '_Components/Tooltips/QTooltip';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import Rond from '_Charts/KpisCharts/KpiBadges/Rond';
import InsightModeMenu from './_Components/InsightModeMenu/InsightModeMenu';
import InsightWallLegend from './_Components/Legend/Legend';
import InsightWallMode from './_Components/ModeTypeSwitcher/ModeTypeSwitcher';
import { getDisplayByConfig, getPeriodConfig } from './_Utils/menus';
import { getSortedInsights, processInsightData } from './_Utils/insights';
import {
  extractModulesFromInsights,
  VIEW_MODES_FILTERS,
} from './_Utils/modules';
import { getEindexMode, RANGES } from './_Utils/ranges';
import ModuleIndicator from './_Components/ModuleIndicator/ModuleIndicator';
import AddInsightModal from './_Containers/AddingInsightModal/AddInsightModal';
import InsightDetailModal from './_Containers/InsightDetailsModal/InsightDetailModal';
import Grid from './_Containers/Grid/Grid';
import ScaledZoomControler from './_Components/ScaledZoomController/ScaledZoomControler';
import { selectInsightDetails } from '../../../../../_Resources/Insight/Actions/selectInsightDetails';
import {
  getHeaderTopHeight,
  getMainFiltersContainerHeight,
} from '../../../../../_Layouts/Header/_Utils/HeaderUtils';
import {
  purple,
  lightBlue,
  white,
} from '../../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../../styles/abstracts/typography';

const Wall = styled.div`
  position: relative;
  width: 100%;
  height: ${props =>
    !props.isAddingInsightModalOpen && !props.isDetailsInsightModalOpen
      ? `calc(
    100vh - ${props.headerTopHeight + props.mainFiltersContainer}px
  )`
      : '100vh'};
  overflow: hidden;
`;

const Menu = styled.div`
  width: 300px;
  position: absolute;
  bottom: 0;
  margin-bottom: ${props =>
    !props.isAddingInsightModalOpen && !props.isDetailsInsightModalOpen
      ? 0
      : `${props.headerTopHeight + props.mainFiltersContainer}px`};
`;

const StyledInsightModeMenu = styled(InsightModeMenu)`
  margin: 1em;
  padding: 0.5em;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
`;

const StyledInsightZoomControl = styled(ScaledZoomControler)`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
`;

const StyledModuleIndicator = styled(ModuleIndicator)`
  position: absolute;
  min-width: 270px;
  top: 0;
  transform: translateX(-60%);
  margin-top: 1em;
  margin-left: 50%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

const AddInsight = styled.div`
  cursor: pointer;
  border-radius: 25px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  margin: 1em;
  bottom: ${props =>
    !props.isAddingInsightModalOpen && !props.isDetailsInsightModalOpen
      ? 0
      : `${props.headerTopHeight + props.mainFiltersContainer}px`};
  right: calc(50% - 50px - 1em);
  background-color: ${purple};
  color: ${white};
  font-weight: bold;
  padding-right: 1em;
`;

const InsightsInfos = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Tooltip = styled(QTooltip)`
  & div {
    padding: 0.5em !important:
  }
`;

const TooltipTitle = styled(QTooltipTitle)`
  margin-left: 2em;
  font-size: ${fontSizes.default} !important;
`;

const SatAndRecoLabel = styled.span`
  font-weight: bold;
  color: ${lightBlue};
  font-size: ${fontSizes.bigger};
`;

class InsightWallGrid extends PureComponent {
  static propTypes = {
    /* eslint-disable react/forbid-prop-types */
    insights: PropTypes.array.isRequired,
    insightDetails: InsightPropType.isRequired,
    className: PropTypes.string,
    projectId: PropTypes.number.isRequired,
    // dispatchLoadInsights: PropTypes.func.isRequired,
    dispatchSelectInsight: PropTypes.func.isRequired,
    dispatchLoadInsightverbatim: PropTypes.func.isRequired,
    onInsightCreated: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  state = {
    gridScale: 1,
    gridDisplayMode: 'circular',
    viewMode: 'all',
    emotion: 'eindex',
    coloring: 'eindex',
    range: { min: null, max: null },
    insights: [], // TODO: I don't need to put this into the state
    modules: [],
    selectedInsight: null,
    currentInsights: null,
    currentModule: null,
    period: 'current',
    isAddingInsightModalOpen: false,
    isDetailsInsightModalOpen: false,
    isTooltipOpened: false,
    currentInsight: null,
    hoveredInsight: null,
  };

  displayByMenuConfig = null;

  periodMenuConfig = null;

  svg = null;

  constructor(props, ...args) {
    super(props, ...args);
    this.initializeConfigs();
    this.initializeState(props);
  }

  componentDidMount() {
    this.setState({
      headerTopHeight: getHeaderTopHeight(),
      mainFiltersContainer: getMainFiltersContainerHeight(),
    });
    if (this.props.location?.state?.fromLandingPage) {
      this.setState({ isAddingInsightModalOpen: true, hoveredInsight: null });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { insights } = this.props;
    if (prevProps.insights !== insights) {
      // TODO: think of using memoize to solve this problem
      this.onInsightUpdate();
    }
    if (this.state.headerTopHeight !== getHeaderTopHeight()) {
      this.setState({
        headerTopHeight: getHeaderTopHeight(),
      });
    }
    if (!this.state.mainFiltersContainer !== getMainFiltersContainerHeight()) {
      this.setState({
        mainFiltersContainer: getMainFiltersContainerHeight(),
      });
    }
  }

  onInsightUpdate() {
    this.setState(this.prepareNewInsightState(this.props));
  }

  initializeState(props) {
    // TODO: check if I can move prepareNewInsightState to service
    const newInsightState = this.prepareNewInsightState(props);
    Object.assign(this.state, newInsightState);
  }

  prepareNewInsightState(props) {
    const { emotion } = this.state;
    const { insights: rawInsights } = props;
    const insights = processInsightData(rawInsights || []);
    const currentInsights = getSortedInsights(
      insights,
      emotion,
      this.state.period,
    );
    const range = this.getRange(emotion, currentInsights);
    const modules = extractModulesFromInsights(insights);
    return { currentInsights, range, insights, modules };
  }

  initializeConfigs() {
    this.displayByMenuConfig = getDisplayByConfig({
      onSelect: this.onIndicatorSelect,
    });
    this.state.periodMenuConfig = getPeriodConfig({
      onSelect: this.onPeriodSelect,
    });
  }

  filterInsights(...filterData) {
    const { viewMode, insights } = this.state;
    const insightFilter = VIEW_MODES_FILTERS[viewMode];
    return getSortedInsights(
      insightFilter(insights, ...filterData),
      this.state.emotion,
      this.state.period,
    );
  }

  getRange(indicator, insights, period = this.state.period) {
    const EINDEX_MODE = getEindexMode(this.props.eindexUnitFahrenheit);
    const getRange =
      RANGES[EINDEX_MODE][indicator] || RANGES[EINDEX_MODE].default;
    return getRange(insights, period);
  }

  onModuleSelected = value => {
    // TODO: change name for mode
    const gridDisplayMode = value === 'module' ? 'triangle' : 'circular';
    this.setState(({ modules, insights }) => {
      const [mostPopularModule] = modules;
      const insightFilter = VIEW_MODES_FILTERS[value];
      const currentInsights = getSortedInsights(
        insightFilter(insights, mostPopularModule),
        this.state.emotion,
        this.state.period,
      );
      return {
        gridDisplayMode,
        viewMode: value,
        currentModule: mostPopularModule,
        currentInsights,
        range: this.getRange(this.state.emotion, currentInsights),
      };
    });
  };

  onIndicatorSelect = value => {
    this.setState(({ currentInsights }) => {
      const newInsights = getSortedInsights(
        currentInsights,
        value,
        this.state.period,
      );
      const range = this.getRange(value, newInsights);
      return {
        emotion: value,
        coloring: this.state.period !== 'current' ? 'delta' : value,
        currentInsights: getSortedInsights(
          currentInsights,
          value,
          this.state.period,
        ),
        range,
      };
    });
  };

  onPeriodSelect = value => {
    const sortedInsights = getSortedInsights(
      this.state.currentInsights,
      this.state.emotion,
      value,
    );
    const range = this.getRange(this.state.emotion, sortedInsights, value);
    this.setState({
      period: value,
      currentInsights: sortedInsights,
      range,
      coloring: value !== 'current' ? 'delta' : this.state.emotion,
    });
  };

  onModuleSwitched = module => {
    const currentInsights = this.filterInsights(module);
    this.setState({
      currentInsights,
      currentModule: module,
      range: this.getRange(this.state.emotion, currentInsights),
    });
  };

  onScaleChange = scale => {
    this.setState(() => ({
      gridScale: scale,
    }));
  };

  onAddingInsightModalClose = () => {
    this.setState({ isAddingInsightModalOpen: false });
  };

  onAddingInsightModalOpen = () => {
    this.setState({ isAddingInsightModalOpen: true, hoveredInsight: null });
  };

  onDetailsInsightModalClose = () => {
    const { dispatchSelectInsight, dispatchLoadInsightVerbatim } = this.props;
    dispatchSelectInsight(null);
    dispatchLoadInsightVerbatim(null);
    this.setState({
      isDetailsInsightModalOpen: false,
      hoveredInsight: null,
      isTooltipOpened: false,
    });
  };

  onInsightTileClicked = ({ insightData: { insightId } }) => {
    // TODO: think about using existing data
    const { dispatchSelectInsight, range } = this.props;
    dispatchSelectInsight(insightId, range);
    this.setState({ isDetailsInsightModalOpen: true });
  };

  onInsightTileHover = ({ insightData }) => {
    this.setState({
      insightTileId: insightData ? `insightTile${insightData.insightId}` : null,
    });
    this.setState({
      hoveredInsight: !this.state.isTooltipOpened ? insightData : null,
    });
    this.setState({
      isTooltipOpened: !!(!this.state.isTooltipOpened && insightData),
    });
  };

  getId = ({ insightData }) => {
    const id = `insightTile${insightData.insightId}`;
    this.setState({ insightTileId: id });
    return id;
  };

  getCurrentValue = insightData => {
    const { period, emotion } = this.state;
    let dataToShow;
    switch (emotion) {
      case 'eindex':
        dataToShow = (
          <EindexRond
            type="small"
            eindex={
              insightData && insightData.entryPoints[period][emotion].value
            }
          />
        );
        break;
      case 'mentions':
        dataToShow = (
          <Rond
            type="small"
            mentions={
              insightData && insightData.entryPoints[period][emotion].value
            }
          />
        );
        break;
      case 'sat':
      case 'reco':
        dataToShow = (
          <SatAndRecoLabel>
            {insightData && insightData.entryPoints[period][emotion].value}{' '}
            {(this.props.sat || this.props.reco || this.props.reco) && (
              <span>/</span>
            )}{' '}
            {emotion === 'sat'
              ? this.props.sat && this.props.sat.max
              : emotion === 'reco'
              ? this.props.reco && this.props.reco.max
              : this.props.cess && this.props.ces.max}
          </SatAndRecoLabel>
        );
        break;
      default:
        dataToShow = null;
        break;
    }
    return dataToShow;
  };

  render() {
    const {
      viewMode,
      emotion,
      period,
      isAddingInsightModalOpen,
      isDetailsInsightModalOpen,
      gridScale,
      currentInsights,
      currentModule,
      headerTopHeight,
      mainFiltersContainer,
      hoveredInsight,
    } = this.state;
    const { projectId, className, insightDetails } = this.props;
    const tooltipInsightInfos = this.getCurrentValue(hoveredInsight);
    return (
      <>
        <QueryGetInsights />
        <>
          <Wall
            className={className}
            headerTopHeight={headerTopHeight}
            mainFiltersContainer={mainFiltersContainer}
            isAddingInsightModalOpen={isAddingInsightModalOpen}
            isDetailsInsightModalOpen={isDetailsInsightModalOpen}
          >
            <Grid
              onTileClicked={this.onInsightTileClicked}
              onTileHover={this.onInsightTileHover}
              scale={gridScale}
              // insights={apiResponse.new}
              insights={currentInsights}
              viewMode={viewMode}
              module={currentModule}
              indicator={emotion}
              period={period}
              getId={this.getId}
            />
            {![
              'disgust',
              'happiness',
              'fear',
              'calm',
              'anger',
              'sadness',
              'surprise',
            ].includes(emotion) &&
              hoveredInsight &&
              !isAddingInsightModalOpen &&
              !isDetailsInsightModalOpen && (
                <Tooltip
                  hideArrow
                  placement="bottom"
                  isOpen={this.state.isTooltipOpened}
                  toggle={this.onInsightTileHover}
                  target={
                    hoveredInsight
                      ? `insightTile${hoveredInsight.insightId}`
                      : 'TooltipExample'
                  }
                >
                  {hoveredInsight && (
                    <InsightsInfos>
                      {tooltipInsightInfos}
                      <TooltipTitle>{hoveredInsight.insightName}</TooltipTitle>
                    </InsightsInfos>
                  )}
                </Tooltip>
              )}
            <Menu
              headerTopHeight={headerTopHeight}
              mainFiltersContainer={mainFiltersContainer}
              isAddingInsightModalOpen={isAddingInsightModalOpen}
              isDetailsInsightModalOpen={isDetailsInsightModalOpen}
            >
              {/* <InsightWallMode */}
              {/*  onValueSelected={this.onModuleSelected} */}
              {/*  selected={this.state.viewMode} */}
              {/* /> */}
              {/* <StyledInsightModeMenu */}
              {/*  config={this.state.periodMenuConfig} */}
              {/*  selected={this.state.period} */}
              {/* /> */}
              <StyledInsightModeMenu
                config={this.displayByMenuConfig}
                selected={this.state.emotion}
                sat={this.props.sat}
                reco={this.props.reco}
                ces={this.props.ces}
              />
              <InsightWallLegend
                category={this.state.coloring}
                min={this.state.range.min}
                max={this.state.range.max}
              />
            </Menu>
            <StyledInsightZoomControl onScaleChange={this.onScaleChange} />
            {/* TODO: make it more stateful
            it takes too many properties and the logic is too complicated */}
            <AddInsight
              onClick={this.onAddingInsightModalOpen}
              headerTopHeight={headerTopHeight}
              mainFiltersContainer={mainFiltersContainer}
              isAddingInsightModalOpen={isAddingInsightModalOpen}
              isDetailsInsightModalOpen={isDetailsInsightModalOpen}
            >
              <Icon icon="PLUS" color="white" size={50} />
              {this.props.t('Add an insight')}
            </AddInsight>
            <StyledModuleIndicator
              modules={this.state.modules}
              currentModule={currentModule}
              onModuleSwitched={this.onModuleSwitched}
              viewMode={viewMode}
              indicator={emotion}
              period={period}
            />
          </Wall>
          {isAddingInsightModalOpen && (
            <AddInsightModal
              projectId={projectId}
              onClose={this.onAddingInsightModalClose}
              onComplete={this.onAddingInsightComplete}
              isOpen={isAddingInsightModalOpen}
            />
          )}
          {insightDetails && (
            <InsightDetailModal
              onClose={this.onDetailsInsightModalClose}
              isOpen={!!insightDetails}
            />
          )}
        </>
      </>
    );
  }
}

const mapStateToProps = ({
  insight: { insights, insightDetails },
  filter,
  projectConfiguration,
}) => ({
  insights,
  insightDetails,
  projectId: get(filter, 'filter_keys.project_id'),
  range: get(filter, 'filter_keys.range'),
  sat: get(projectConfiguration, ['unitsAndLocalesSettings', 'sat']),
  reco: get(projectConfiguration, ['unitsAndLocalesSettings', 'reco']),
  ces: get(projectConfiguration, ['unitsAndLocalesSettings', 'ces']),
  eindexUnitFahrenheit: get(projectConfiguration, [
    'unitsAndLocalesSettings',
    'eindexUnitFahrenheit',
  ]),
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectInsight: (...args) => dispatch(selectInsightDetails(...args)),
  dispatchLoadInsightVerbatim: (...args) =>
    dispatch(loadInsightVerbatimSuccess(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('card')(withRouter(InsightWallGrid)));
