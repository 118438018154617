import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const MenuItemTab = styled.div`
  padding-left: 1em;
`;
export const MenuItemLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-color: ${({ checked }) => (checked ? '#592ea0' : '#edf0f7')};
  border-radius: 10px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: ${({ checked }) => (checked ? '#fff' : 'transparent')};
    top: 5px;
    left: 5px;
  }
`;

const MenuItemInput = styled.input`
  visibility: hidden;
  width: 0;
  margin: 0;
`;
const MenuItemTitle = styled.span`
  cursor: pointer;
`;

class InsightModeMenuItem extends Component {
  onMenuItemInputClicked = () => {
    const { option, onClick } = this.props;
    onClick(option.id, option);
  };

  render() {
    const { option, selected, t } = this.props;
    const checked = selected === option.id;
    return (
      <MenuItemTab>
        <MenuItemLabel checked={checked}>
          <MenuItemInput
            type="radio"
            checked={checked}
            onClick={this.onMenuItemInputClicked}
          />
        </MenuItemLabel>
        <MenuItemTitle onClick={this.onMenuItemInputClicked}>
          {' '}
          {t(option.title)}
        </MenuItemTitle>
      </MenuItemTab>
    );
  }
}
export default withTranslation('card')(InsightModeMenuItem);
// TODO: move prop-types out of the file
InsightModeMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  option: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
