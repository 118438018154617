/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { cloneDeep, get, indexOf, findIndex } from 'lodash';
import { Row } from 'reactstrap';
import querySchema from '_Resources/Insight/Services/api/graphql/searchInsights';
import CQuery from '_Container/QQuery/CQuery';
import { Mutation } from 'react-apollo';
import { selectInsightDetails } from '_Resources/Insight/Actions/selectInsightDetails';
import mutationCreateInsight from '_Resources/Insight/Services/api/graphql/createInsight';
import mutationAddSentencesInsight from '_Resources/Insight/Services/api/graphql/addSentencesInsight';
import queryGetInsights from '_Resources/Insight/Services/api/graphql/getInsights';
import getInsightVerbatim from '_Resources/Insight/Services/api/graphql/getInsight';
import CModal from '../../../../../../../_Components/CModal/CModal';
import Search from './_Containers/steps/Search';
import Filters from './_Containers/steps/Filters';
import AddInsightProgress, {
  ADDING_INSIGHT_PROGRESS_STEPS,
} from './_Containers/AddInsightProgress';
import Results from './_Containers/steps/Results';
import AbortModal from './_Components/AbortModal';
import FiltersWarningModal from './_Components/FiltersWarningModal';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import QButton from '_Components/Buttons/QButton/QButton';

const ProgressWrapper = styled.div`
  width: 60%;
  margin: auto;
  padding: 1em 0;
`;

const PrevButtonPanel = styled.div`
  flex-grow: 1;
`;

const ButtonsSection = styled.div`
  & > * {
    margin-left: 1em;
  }
`;

const { SEARCH, FILTERS, RESULTS } = ADDING_INSIGHT_PROGRESS_STEPS;
const DEFAULT_STEPS_DATA = {
  [SEARCH]: {},
  [FILTERS]: {},
};

const IDLE_STATE = {
  nextAvailable: false,
  abortModalOpen: false,
  filtersWarningModalOpen: false,
  currentStep: SEARCH,
  tags: [],
  filters: null,
  stepsData: null,
};

const getNextStepState = nextStep =>
  nextStep === SEARCH
    ? { filtersWarningModalOpen: true }
    : { currentStep: nextStep, nextAvailable: false };

class AddInsightModal extends Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
    insightId: PropTypes.number,
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    dispatchSelectInsight: PropTypes.func.isRequired,
    currentRange: PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    }).isRequired,

    title: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(ADDING_INSIGHT_PROGRESS_STEPS)),
    ),
  };

  static defaultProps = {
    title: null,
    insightId: null,
    steps: [SEARCH, FILTERS, RESULTS],
  };

  state = {
    ...cloneDeep(IDLE_STATE),
  };

  title = 'Add an insight';
  subtitle = 'Easy-peasy... Just follow those 3 steps !';
  stepViews = {};

  cachedInsights = null;
  cachedResultsDesc = {};

  nextButtonLabels = {
    [SEARCH]: 'Add verbatim',
    [FILTERS]: 'Validate selected verbatim',
    [RESULTS]: 'Create insight',
  };

  constructor(props) {
    super(props);
    this.initializeStepViews();
    this.initializeStepData(props);
  }

  getStepData(step) {
    return get(this.state, `stepsData.${step}`);
  }

  initializeStepViews() {
    this.stepViews = {
      // [SEARCH]: () => (
      //   <Results
      //     onDescriptionChanged={this.onDescriptionChanged}
      //     onNameChanged={this.onNameChanged}
      //   />
      // ),
      [SEARCH]: () => {
        const data = this.getStepData(SEARCH);
        return (
          <Search
            onNextStepAvailabilityChanged={this.onNextStepAvailabilityChanged}
            data={data}
            onDataChange={this.onSearchDataChanged}
          />
        );
      },
      [FILTERS]: () => {
        const { projectId, insightId } = this.props;
        const prevStateData = this.getStepData(SEARCH);
        const dataBis = this.getStepData(FILTERS);

        return (
          // TODO: to have each step in same generic form is not useful
          <>
            <CQuery
              query={querySchema}
              filters={{
                terms: prevStateData.tags,
                projectId,
                engine: prevStateData.searchEngine,
                range: this.props.currentRange,
                limit: prevStateData.answerCount,
                ...(insightId && { insightId }),
              }}
              noDataComponent={
                <Fragment>
                  <NoDataMessage
                    mainTitle="An error has occurred"
                    secondText="Please, go back and lauch your query again"
                  />
                </Fragment>
              }
              fetchPolicy="network-only"
            >
              <TransformData
                dataBis={dataBis}
                onFiltersDataChanged={this.onFiltersDataChanged}
                onNextStepAvailabilityChanged={
                  this.onNextStepAvailabilityChanged
                }
                prevStateData={prevStateData}
              />
            </CQuery>
          </>
        );
      },
      [RESULTS]: () => (
        <Results
          onDescriptionChanged={this.onDescriptionChanged}
          onNameChanged={this.onNameChanged}
        />
      ),
    };
  }

  initializeStepData(props) {
    this.state.stepsData = {
      ...cloneDeep(DEFAULT_STEPS_DATA),
      ...this.getStepDataIdleState(props),
    };
  }

  getStepDataIdleState(props = this.props) {
    const { initStepsData } = props;
    return initStepsData;
  }

  prepareOnDataChanges = (step, data) => ({ stepsData }) => ({
    stepsData: { ...stepsData, [step]: data },
  });

  onSearchDataChanged = data =>
    this.setState(this.prepareOnDataChanges(SEARCH, data));

  onFiltersDataChanged = data => {
    this.setState(this.prepareOnDataChanges(FILTERS, data));
  };

  resetState(callback) {
    this.setState(
      { ...cloneDeep(IDLE_STATE), stepsData: this.getStepDataIdleState() },
      callback,
    );
  }

  getStepView() {
    return this.stepViews[this.state.currentStep]();
  }

  getFooter() {
    const { nextAvailable, currentStep } = this.state;
    const {
      steps,
      insightId,
      onClose,
      t,
      projectId,
      currentRange,
      dispatchSelectInsight,
    } = this.props;
    return (
      <Fragment>
        {indexOf(steps, currentStep) !== 0 && (
          <PrevButtonPanel>
            <QButton
              bgColor="grayShades.g800"
              onClick={this.onModalPrevClicked}
              disabled={this.isPrevButtonDisable()}
            >
              {t('Prev. step')}
            </QButton>
          </PrevButtonPanel>
        )}

        <ButtonsSection>
          <QButton outline bgColor="grayShades.g800" onClick={this.onCancelClicked}>
            {t('Cancel')}
          </QButton>
          {indexOf(steps, currentStep) !== steps.length - 1 ? (
            <QButton
              onClick={this.onModalNextClicked}
              disabled={!nextAvailable}
            >
              {t(this.nextButtonLabels[currentStep])}
            </QButton>
          ) : insightId ? (
            <Mutation
              mutation={mutationAddSentencesInsight}
              onCompleted={() => {
                dispatchSelectInsight(insightId, currentRange);
              }}
              update={(cache, { data: { addSentencesInsight } }) => {
                // Updating insights verbatim list
                const insightVerbatimList = cache.readQuery({
                  query: getInsightVerbatim,
                  variables: { insightId },
                }).insight;
                cache.writeQuery({
                  query: getInsightVerbatim,
                  variables: { insightId },
                  data: {
                    insight: {
                      insightId: insightVerbatimList.insightId,
                      selectedVerbatim:
                        addSentencesInsight.insight.selectedVerbatim,
                    },
                  },
                });

                // Updating insights list
                const insightsList = cache.readQuery({
                  query: queryGetInsights,
                  variables: { projectId },
                }).insights;
                const insightIndexToReplace = findIndex(
                  insightsList,
                  insight => insight.insightId === insightId,
                );
                const currentInsight = insightsList[insightIndexToReplace];
                const newInsightData = {
                  ...currentInsight,
                  entryPoints: addSentencesInsight.insight.entryPoints,
                  editedOn: addSentencesInsight.insight.editedOn,
                  editedBy: addSentencesInsight.insight.editedBy,
                };
                const newInsightsListData = [...insightsList];
                newInsightsListData[insightIndexToReplace] = newInsightData;
                cache.writeQuery({
                  query: queryGetInsights,
                  variables: { projectId },
                  data: {
                    insights: newInsightsListData,
                  },
                });
                this.resetState(() => onClose());
              }}
            >
              {addSentencesInsight => (
                <QButton
                  onClick={() =>
                    addSentencesInsight({
                      variables: this.getFeedInsightVariables(),
                    })
                  }
                  disabled={!nextAvailable}
                >
                  {t(this.nextButtonLabels[currentStep])}
                </QButton>
              )}
            </Mutation>
          ) : (
            <Mutation
              mutation={mutationCreateInsight}
              update={(cache, { data: { createInsight } }) => {
                const insightsList = cache.readQuery({
                  query: queryGetInsights,
                  variables: { projectId },
                });
                const arrayToConcatenate =
                  insightsList.insights === null ? [] : insightsList.insights;
                cache.writeQuery({
                  query: queryGetInsights,
                  variables: { projectId },
                  data: {
                    insights: arrayToConcatenate.concat(createInsight.insight),
                  },
                });
                this.onAbortConfirmed();
              }}
            >
              {createInsight => (
                <QButton
                  onClick={() =>
                    createInsight({ variables: this.getNewInsightVariables() })
                  }
                  disabled={!nextAvailable}
                >
                  {t(this.nextButtonLabels[currentStep])}
                </QButton>
              )}
            </Mutation>
          )}
        </ButtonsSection>
      </Fragment>
    );
  }

  getNewInsightVariables() {
    const { projectId } = this.props;
    const {
      stepsData: {
        filters: { selectedSentences },
      },
    } = this.state;
    const { name, description } = this.cachedResultsDesc;
    // if (!name && steps.length === 3) return;

    const sentenceIds = selectedSentences.map(({ sentenceId }) => sentenceId);
    return {
      name,
      description,
      sentenceIds,
      projectId,
    };
  }

  getFeedInsightVariables() {
    const { projectId, insightId } = this.props;
    const {
      stepsData: {
        filters: { selectedSentences },
      },
    } = this.state;

    const sentenceIds = selectedSentences.map(({ sentenceId }) => sentenceId);
    return {
      insightId,
      sentenceIds,
      projectId,
    };
  }

  isPrevButtonDisable = () => {
    const { steps } = this.props;
    const [firstStep] = steps;
    return firstStep === this.state.currentStep;
  };

  onCancelClicked = () => this.setState({ abortModalOpen: true });

  onNextStepAvailabilityChanged = availability => {
    this.setState({ nextAvailable: availability });
  };

  onModalNextClicked = () => {
    const { steps } = this.props;
    const { currentStep } = this.state;
    const index = steps.findIndex(step => step === currentStep);
    const isLast = index === steps.length - 1;
    if (!isLast) {
      this.setState({
        currentStep: steps[index + 1],
        nextAvailable: index === 1 && steps.length === 3, // TODO: remove this condition when endpoint for saving details is ready
      });
    }
  };

  onModalPrevClicked = () => {
    const { steps } = this.props;
    const { currentStep } = this.state;
    const index = steps.findIndex(step => step === currentStep);
    this.onStepSelected(steps[index - 1]);
  };

  onStepSelected = nextStep => this.setState(() => getNextStepState(nextStep));

  onAbortConfirmed = () => {
    const { onClose } = this.props;
    this.resetState(() => onClose());
  };

  onAbortCanceled = () => {
    this.setState({ abortModalOpen: false });
  };

  onFilterModalContinue = () =>
    this.setState(({ stepsData }) => ({
      currentStep: SEARCH,
      stepsData: { ...stepsData, [FILTERS]: {} },
      filtersWarningModalOpen: false,
    }));

  onFilterModalStay = () => this.setState({ filtersWarningModalOpen: false });

  onDescriptionChanged = value => {
    this.cachedResultsDesc.description = value;
  };

  onNameChanged = value => {
    this.cachedResultsDesc.name = value;
  };

  render() {
    const { steps, isOpen, onClose, title } = this.props;
    const { currentStep, abortModalOpen, filtersWarningModalOpen } = this.state;
    return (
      <CModal
        data={{}}
        title={title || this.title}
        displayMentionsSubtitle={false}
        displayDateSubtitle={false}
        subtitleAdditionalContent={this.subtitle}
        footer={this.getFooter()}
        modal={isOpen}
        scrollable={false}
        toggle={onClose}
      >
        <ProgressWrapper>
          <AddInsightProgress
            currentStep={currentStep}
            steps={steps}
            onStepSelected={this.onStepSelected}
          />
        </ProgressWrapper>
        <Row className="border-top">{this.getStepView()}</Row>
        <AbortModal
          isOpen={abortModalOpen}
          onAbortConfirmed={this.onAbortConfirmed}
          onAbortCanceled={this.onAbortCanceled}
        />
        <FiltersWarningModal
          isOpen={filtersWarningModalOpen}
          onContinue={this.onFilterModalContinue}
          onStay={this.onFilterModalStay}
        />
      </CModal>
    );
  }
}
const mapStateToProps = state => ({
  projectId: get(state, 'filters.filterKeys.projectId'),
  currentRange: get(state, 'filters.filterKeys.range'),
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectInsight: (...args) => dispatch(selectInsightDetails(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('button')(AddInsightModal));

const TransformData = ({
  data,
  onNextStepAvailabilityChanged,
  prevStateData,
  dataBis,
  onFiltersDataChanged,
}) => (
  <Filters
    insights={data}
    onNextStepAvailabilityChanged={onNextStepAvailabilityChanged}
    prevStepData={prevStateData}
    data={dataBis}
    onDataChange={onFiltersDataChanged}
  />
);
