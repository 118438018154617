import React from 'react';
import moment from 'moment';
import {
  Section,
  SectionItem,
  SectionLink,
  SectionTitle,
  SectionTitleContainer,
} from '_Cards/SettingsCards/ProjectDetailsCard/_Components/GlobalInformations/styles';

const HistorySection = ({ setActiveTab, lastProcessedAt, creationDate, t }) => (
  <Section>
    <SectionTitleContainer>
      <SectionTitle>{t('History')}</SectionTitle>
      <SectionLink onClick={() => setActiveTab('processing-history')}>
        {t('button:See processing history')}
      </SectionLink>
    </SectionTitleContainer>
    <SectionItem>
      <p>{t('Creation date')} :</p> <span>{moment(creationDate).format('L')}</span>
    </SectionItem>
    <SectionItem>
      <p>{t('Last processing date')} :</p>{' '}
      <span>{moment(lastProcessedAt).format('L')}</span>
    </SectionItem>
  </Section>
);

export default HistorySection;
