import gql from 'graphql-tag';

const query = gql`
  query getTour($id: Int!, $replay: Boolean) {
    results: getTour(id: $id, replay: $replay) {
      settings
    }
  }
`;

export default query;
