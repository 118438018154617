import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import CLinesChart from '_Charts/LineCharts/CLinesChart/CLinesChart';
import { EMOTIONS, SENTIMENTS } from 'constants/index';
import { getKeywords } from '_Cards/EIndexCards/EindexEvolutionWithMentionsCard/EindexEvolutionWithMentionsSection';
import GET_EMOTION_EVOLUTION_LINE_CARD_DATA_QUERY from './graphql/getEmotionEvolutionLineCardData';

type EmotionEvolutionLineSectionProps = {
  mixBarLine?: boolean;
  settings: {
    dataDisplay: 'percentage' | 'absoluteValues';
    range: 'day' | 'week' | 'month';
    analysisAxis: 'sentiment' | 'emotion';
    reportingAnalysisAxis: 'sentiment' | 'emotion';
  };
  range: 'day' | 'week' | 'month';
  filters: any;
  filterKeys: any;
  valueAbsolute: 'percentage' | 'absolute';
  analysisAxis: 'sentiment' | 'emotion';
  reportingAnalysisAxis: 'sentiment' | 'emotion';
  mockData: any;
};

const EmotionEvolutionLineSection = ({
  mixBarLine,
  settings,
  range = 'day',
  filters,
  filterKeys,
  valueAbsolute,
  analysisAxis,
  reportingAnalysisAxis,
  mockData,
}: EmotionEvolutionLineSectionProps) => {
  const [chartData, setChartData] = useState<any[] | null>(null);
  const [constantToPass, setConstantToPass] = useState<any>(EMOTIONS);
  const [valueAbsoluteToPass, setValueAbsoluteToPass] = useState('percentage');
  const { data } = useQuery(GET_EMOTION_EVOLUTION_LINE_CARD_DATA_QUERY, {
    variables: {
      ...(filters || filterKeys),
      dateType: (settings?.range || range).toUpperCase(),
      keywords: getKeywords(filters || filterKeys),
    },
  });

  useEffect(() => {
    if (data) {
      setChartData(data?.getEmotionEvolutionLineCardData?.emotionEvolution);
    }
  }, [data]);

  useEffect(() => {
    if (analysisAxis === 'emotion' || reportingAnalysisAxis === 'emotion') {
      setConstantToPass(EMOTIONS);
    }
    if (analysisAxis === 'sentiment' || reportingAnalysisAxis === 'sentiment') {
      setConstantToPass(SENTIMENTS);
    }
    if (settings?.dataDisplay?.includes('absolute')) {
      setValueAbsoluteToPass('absolute');
    }
    if (!settings?.dataDisplay?.includes('absolute')) {
      setValueAbsoluteToPass(settings?.dataDisplay);
    }
    if (settings?.dataDisplay === undefined) {
      setValueAbsoluteToPass(valueAbsolute);
    }
  }, [valueAbsolute, analysisAxis, reportingAnalysisAxis]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {(mockData || chartData) && (
        <CLinesChart
          mixBarLine={mixBarLine}
          currentPeriod={settings && settings.range}
          data={mockData?.emotionEvolution ?? chartData}
          constant={constantToPass}
          valueAbsolute={valueAbsoluteToPass}
          analysisAxis={analysisAxis || reportingAnalysisAxis}
        />
      )}
    </div>
  );
};

EmotionEvolutionLineSection.defaultProps = {
  mixBarLine: null,
};

export default EmotionEvolutionLineSection;
