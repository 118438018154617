import { merge } from 'lodash';

const round = num => Math.round(num * 100) / 100;

const getAverageValues = (indicators, mentions) =>
  Object.keys(indicators).reduce(
    (averageValues, indicatorName) => ({
      ...averageValues,
      [indicatorName]: {
        ...indicators[indicatorName],
        value: round(indicators[indicatorName].value / mentions.value),
      },
    }),
    {},
  );

const getEntryPointAverageValues = entryPoint => {
  const { mentions, ...otherIndicators } = entryPoint;

  return {
    mentions,
    ...getAverageValues(otherIndicators, mentions),
  };
};

export default insight => {
  const {
    entryPoints: { current, previousPeriod, previousYear },
  } = insight;

  return merge(insight, {
    entryPoints: {
      current: getEntryPointAverageValues(current),
      previousPeriod: getEntryPointAverageValues(previousPeriod),
      previousYear: getEntryPointAverageValues(previousYear),
    },
  });
};
