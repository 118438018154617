import React from 'react';
import styled from 'styled-components';
import '_Charts/KpisCharts/KpiBadges/style.scss';
import styleVars from 'styles/abstracts/_variables.module.scss';
import Icon from '_Components/Icons/Icon';
import { getClassCssRondType } from '_Charts/KpisCharts/KpiBadges/utils';
import { getKValue } from '_Utils/mentions/getKValue';

const MentionText = styled.div`
  font-size: ${props =>
    props.type === 'small'
      ? '1rem !important'
      : props.type === 'medium'
      ? '1.25rem !important'
      : '2.5rem'};
  line-height: 1;
  text-align: center;
  color: white;
  font-weight: bold;
`;
const MentionSubText = styled.div`
  font-size: ${props =>
    props.type === 'medium' ? '.75rem !important' : '1rem'};
`;

const Container = styled.div`
  ${({ type }) => getClassCssRondType(type)}
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Rond = props => {
  return (
    <Container
      className="eindexRond mentionCount"
      style={props.customCSS || { backgroundColor: styleVars.primary }}
      type={props.type}
    >
      {props.mentions ||
      props.data.mentionCount.value ||
      props.data.mentionCount.value === 0 ? (
        <MentionText type={props.type}>
          {`${getKValue(props.mentions || props.data.mentionCount.value)}`}
          {!props.mentions && (
            <>
              <br />
              <MentionSubText type={props.type}>mentions</MentionSubText>
            </>
          )}
        </MentionText>
      ) : (
        <div className="rotate">
          <Icon icon="INTERFACE_LOADING" color={styleVars.secondary} />
        </div>
      )}
    </Container>
  );
};

export default Rond;
