import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NestedInputTags } from '@qemotion_team/react-nested-input-tags';
import { connect } from 'react-redux';
import { setGraphThematics } from '_Resources/Benchmark/Actions/setGraphThematics';
import { uniqWith, isEqual } from 'lodash';
import { removeOptions } from '../helpers/remove';

export const OptionsProps = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  tagLabel: PropTypes.string,
  disabled: PropTypes.bool,
  allowAny: PropTypes.bool,
  render: PropTypes.func,
};

OptionsProps.subOptions = PropTypes.shape(OptionsProps);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

class QNestedInputTag extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape(OptionsProps)).isRequired,
    onSearch: PropTypes.func.isRequired,
    className: PropTypes.string,
    inputTagFactory: PropTypes.func,
    initialValues: PropTypes.arrayOf(
      PropTypes.shape({
        option: PropTypes.any,
        parents: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
    dispatchSetGraphThematics: PropTypes.func.isRequired,
    dispatchSetSelectedFilters: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    inputTagFactory: null,
    initialValues: null,
    dispatchSetSelectedFilters: () => {},
  };

  state = {
    selected: [],
  };

  constructor(props) {
    super(props);
    this.state.selected = this.props.initialValues;
    if (this.props.initialValues) {
      setTimeout(() => {
        this.onSearchButtonClicked();
      }, 100);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.idpage !== this.props.idpage) {
      this.setState({ selected: this.props.initialValues || [] });
      this.onSearchButtonClicked();
    }
  }

  onSelected = items => {
    const newSelection = [...this.state.selected, ...items];
    const uniquesSelection = uniqWith(newSelection, (a, b) => isEqual(a, b));
    this.setState({ selected: uniquesSelection });
    setTimeout(() => this.onSearchButtonClicked(), 100);
  };

  // TODO: update NIT
  onRemoved = (items, parentKey) => {
    this.setState(({ selected }) => ({
      selected: removeOptions(items, selected, parentKey),
    }));
    setTimeout(() => this.onSearchButtonClicked(), 100);
  };

  onSearchButtonClicked = () => {
    const { onSearch, dispatchSetGraphThematics, dispatchSetSelectedFilters, initialValues } = this.props;
    const { selected } = this.state;
    if (selected !== initialValues) {
      dispatchSetSelectedFilters(selected);
    }
    dispatchSetGraphThematics([]);
    onSearch(selected);
  };

  render() {
    const { options, className, inputTagFactory, t, initialValues } = this.props;

    return (
      <Container className={className}>
        <NestedInputTags
          categoryPrefix={t('in')}
          options={options}
          onSelected={this.onSelected}
          onRemoved={this.onRemoved}
          inputTagFactory={inputTagFactory}
          initialValues={initialValues}
        />
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetGraphThematics: newState => {
      dispatch(setGraphThematics(newState));
    },
  };
}

export default connect(null, mapDispatchToProps)(withTranslation(['filters', 'button'])(QNestedInputTag));
