import React from 'react';
import '_Cards/EIndexCards/EindexTemperatureCard/_Components/EindexBenchmark/style.scss';
import EindexBenchmarkItem from '_Cards/EIndexCards/EindexTemperatureCard/_Components/EindexBenchmark/EindexBenchmarkItem';
import { isEmpty } from 'lodash';

class EindexBenchmark extends React.Component {
  render() {
    return (
      // TODO : quickifix benchmarkLine && () car undefined mais trouver la cause
      <div className="eIndexBenchmarkBis">
        {this.props.data &&
          !isEmpty(this.props.data) &&
          this.props.data.map(
            (benchmarkLine, index) =>
              benchmarkLine && (
                <EindexBenchmarkItem
                  key={`${index * 2}-benchmark-line`}
                  eindex={benchmarkLine.value || null}
                  name={benchmarkLine.name}
                />
              ),
          )}
      </div>
    );
  }
}

export default EindexBenchmark;
