import React, { useState, useEffect } from 'react';
import NewDataContainer from './_Components/NewDataContainer';
import DataUploadedContainer from './_Components/DataUploadedContainer';
import ErrorUploadContainer from './_Components/ErrorUploadContainer';

const FileUploader = ({
  setFileState,
  t,
  acceptedFormat,
  fileState,
  uploadedText,
  uploadedSubText,
  fileUploadMutation,
  uuid,
  setFileToUpload,
  defaultIllustration = null,
}) => {
  const onDrop = (acceptedFile, rejectedFile) => {
    if (acceptedFile.length > 0) {
      setFileState('uploaded');
    } else {
      setFileState('error');
    }
  };

  const getContent = () => {
    let content;
    if (fileState === 'no_file') {
      content = (
        <NewDataContainer
          t={t}
          onDrop={onDrop}
          setFileState={setFileState}
          acceptedFormat={acceptedFormat}
          fileUploadMutation={fileUploadMutation}
          uuid={uuid}
          setFileToUpload={setFileToUpload}
          defaultIllustration={defaultIllustration}
        />
      );
    } else if (fileState === 'uploaded') {
      content = (
        <DataUploadedContainer
          t={t}
          uploadedText={uploadedText}
          uploadedSubText={uploadedSubText}
        />
      );
    } else {
      content = <ErrorUploadContainer t={t} />;
    }
    return content;
  };

  return getContent();
};

export default FileUploader;
