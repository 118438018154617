import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BasePropTypes } from '../../../../_Utils/common.propTypes';
import { grayShades } from '../../../../styles/abstracts/colors';

const Menu = styled.div`
  background-color: white;
`;

const Item = styled.div`
  padding: 1.5em;
  &:not(:first-child) {
    border-top: 2px solid ${grayShades.g300};
  }
`;

const TabMenu = ({ children, className }) => (
  <Menu className={className}>{children}</Menu>
);

TabMenu.propTypes = {
  ...BasePropTypes,
};

TabMenu.Item = ({ children, className }) => (
  <Item className={className}>{children}</Item>
);

TabMenu.Item.propTypes = {
  ...BasePropTypes,
};

export default TabMenu;
