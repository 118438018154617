/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { isEmpty, flatten } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from 'isomorphic-style-loader/withStyles';
// import s from '../../components/templates/Sidebar/style.scss';
import {
  getOptionsForNormalFilters,
  getOptionsFromRelatedFilters,
} from './utils/filters';
import QFunnelSelect from '../Fields/QFunnelSelect/QFunnelSelect';
import FiltersSection from './_Components/FiltersSection';
import QSelect from '../Fields/QSelect/QSelect';
import { QFiltersValue } from './QFilters.propTypes';

const categoryProp = PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string));

const addNewFilterValue = (dict, filter) => {
  const { categoryName } = filter;
  const categoryFilters = dict[categoryName] || [];

  return { ...dict, [categoryName]: [...categoryFilters, filter] };
};

export const FILTER_TYPES = {
  RELATED: 'related',
  NORMAL: 'normal',
};

class QFilters extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    relatedFilters: categoryProp,
    normalFilters: categoryProp,
    initialFilterValues: QFiltersValue,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    relatedFilters: {},
    initialFilterValues: {},
    normalFilters: {},
  };

  state = {
    normalFiltersValues: {},
    relatedFilterValue: [],
  };

  constructor(props) {
    super(props);
    const { initialFilterValues } = props;
    this.initializeFiltersValues(initialFilterValues);
  }

  initializeFiltersValues(initialFilterValues) {
    if (!initialFilterValues) return;
    const { normal, related } = initialFilterValues;
    this.initializeNormalFilters(normal);
    this.initializeRelatedFilters(related);
  }

  initializeNormalFilters(normalFilterValues = []) {
    const filterValuesByCategory = normalFilterValues.reduce(
      addNewFilterValue,
      {},
    );
    this.state.normalFiltersValues = filterValuesByCategory;
  }

  initializeRelatedFilters(relatedFilterValue = []) {
    this.state.relatedFilterValue = relatedFilterValue;
  }

  prepareRelatedFilters() {
    const { relatedFilters } = this.props;
    return getOptionsFromRelatedFilters(relatedFilters);
  }

  prepareNormalFilters() {
    const { normalFilters } = this.props;
    return getOptionsForNormalFilters(normalFilters);
  }

  onRelatedFilterValueChanged = value => {
    const { onChange } = this.props;
    this.setState({ relatedFilterValue: value }, () => {
      const { relatedFilterValue } = this.state;
      onChange({ type: FILTER_TYPES.RELATED, value: relatedFilterValue });
    });
  };

  onNormalFilterValueChanged = (filterName, value) => {
    const { onChange } = this.props;
    this.setState(
      ({ normalFiltersValues }) => ({
        normalFiltersValues: {
          ...normalFiltersValues,
          [filterName]: value,
        },
      }),
      () => {
        const { normalFiltersValues } = this.state;
        onChange({
          type: FILTER_TYPES.NORMAL,
          value: flatten(Object.values(normalFiltersValues)),
        });
      },
    );
  };

  prepareNormalFiltersSelects() {
    const filters = this.prepareNormalFilters();
    return filters.map(({ items, name, label }) => {
      const { normalFiltersValues } = this.state;
      const config = { placeholder: label, multi: true };
      return (
        <QSelect
          options={items}
          onChange={value => this.onNormalFilterValueChanged(name, value)}
          value={normalFiltersValues[name]}
          config={config}
        />
      );
    });
  }

  render() {
    const { t, relatedFilters, normalFilters, className } = this.props;
    const { relatedFilterValue } = this.state;

    return (
      <div className={className}>
        {!isEmpty(relatedFilters) && (
          <FiltersSection label={t('Geography')}>
            <QFunnelSelect
              initialValue={relatedFilterValue}
              categories={this.prepareRelatedFilters()}
              onChange={this.onRelatedFilterValueChanged}
            />
          </FiltersSection>
        )}
        {!isEmpty(normalFilters) && (
          <FiltersSection label={t('Geography')}>
            {this.prepareNormalFiltersSelects()}
          </FiltersSection>
        )}
      </div>
    );
  }
}

export default withTranslation('header')(QFilters);
