import styled from 'styled-components';
import PropTypes from 'prop-types';
import { purple } from '../../../styles/abstracts/colors';

export const QCHEVRON_ORIENTATION = {
  TOP: 'top',
  BOTTOM: 'bottom',
  RIGHT: 'right',
  LEFT: 'left',
};

const QCHEVRON_ORIENTATION_DATA = {
  [QCHEVRON_ORIENTATION.LEFT]: {
    rotation: '-135deg',
    translateY: '5px',
  },
  [QCHEVRON_ORIENTATION.RIGHT]: {
    rotation: '45deg',
    translateY: '5px',
  },
  [QCHEVRON_ORIENTATION.TOP]: {
    rotation: '-45deg',
    translateY: '5px',
  },
  [QCHEVRON_ORIENTATION.BOTTOM]: {
    rotation: '135deg',
    translateY: '5px',
  },
};

const QCHEVRON_HEIGHT = '1em';

const QChevron = styled.div`
  &::before {
    content: '';
    cursor: pointer;
    display: inline-block;
    width: ${() => QCHEVRON_HEIGHT};
    height: ${() => QCHEVRON_HEIGHT};
    border-right: ${() => `3px solid ${purple};`}
    border-top: ${() => `3px solid ${purple};`}
    transform: ${({ orientation }) => {
      const { rotation, translateY } = QCHEVRON_ORIENTATION_DATA[orientation];
      return `translateY(${translateY}) rotate(${rotation});`;
    }};
  }
`;

QChevron.propTypes = {
  orientation: PropTypes.oneOf(Object.values(QCHEVRON_ORIENTATION)),
};

QChevron.defaultProps = {
  orientation: QCHEVRON_ORIENTATION.RIGHT,
};

export default QChevron;
