import React, { Children, cloneElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BasePropTypes } from '../../../_Utils/common.propTypes';
import { redBlueShades } from '../../../styles/abstracts/colors';

const Tabs = styled.div`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'horizontal' ? 'row' : 'column'};
  align-items: center;
  padding: 0 2rem;
  background-color: ${redBlueShades.rbs7};
`;

class QTabs extends PureComponent {
  static propTypes = {
    tabDirection: PropTypes.string,
    initialPage: PropTypes.string,
    onTabChanged: PropTypes.func.isRequired,

    ...BasePropTypes,
  };

  static defaultProps = {
    tabDirection: 'horizontal',
    initialPage: null,
  };

  constructor(props, ...args) {
    super(props, ...args);
    this.initializeState(props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialPage !== this.props.initialPage) {
      this.prepareNewSelectedTabState();
    }
  }

  prepareNewSelectedTabState() {
    this.setState({ selectedTab: this.props.initialPage });
  }

  onTabSelectionChange = id => {
    const { onTabChanged } = this.props;
    this.setState({ selectedTab: id }, () => onTabChanged(id));
  };

  initializeState({ initialPage }) {
    this.state = {
      selectedTab: initialPage,
    };
  }

  renderChildren() {
    const { selectedTab } = this.state;
    const { children } = this.props;

    return Children.map(children, child =>
      cloneElement(child, {
        id: child.props && child.props.id,
        onSelect: this.onTabSelectionChange,
        isSelected: child.props && child.props.id === selectedTab,
      }),
    );
  }

  render() {
    const { tabDirection, className } = this.props;
    return (
      <div>
        <Tabs role="tablist" direction={tabDirection} className={className}>
          {this.renderChildren()}
        </Tabs>
      </div>
    );
  }
}

export default QTabs;
