import React from 'react';
import styled from 'styled-components';
import { grayShades, purple, white } from '../../styles/abstracts/colors';
import Icon from '../../_Components/Icons/Icon';
import { radius } from '../../styles/abstracts/variables';
import { TABS } from './utils/tabs';

const Tab = styled.div<{ isActive: string }>`
  background-color: ${({ isActive }) => (isActive ? white : grayShades.g200)};
  font-weight: bold;
  color: ${({ isActive }) => (isActive ? purple : grayShades.g800)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  & svg {
    margin-bottom: 0.5rem;
  }

  &:not(:first-child) {
    border-top: 1px solid ${grayShades.g400};
  }
  &:first-child {
    border-top-left-radius: ${radius.default};
  }
  &:last-child {
    border-bottom-left-radius: ${radius.default};
  }
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: calc(100% / 10) !important;
  max-width: 7em;
  border-right: 1px solid ${grayShades.g400};
`;

const Sidebar = ({ activeTab, setActiveTab, t }) => {
  return (
    <TabsContainer className="sidebar tabsContainer">
      {TABS.filter(tab => !tab.disabled).map((tab, ind) => (
        <Tab
          isActive={tab.id === activeTab}
          onClick={() => setActiveTab(tab.id)}
        >
          <Icon
            icon={tab.icon}
            color={tab.id === activeTab ? purple : grayShades.g800}
            size={32}
          />
          {ind + 1}. {t(tab.name)}
        </Tab>
      ))}
    </TabsContainer>
  );
};

export default Sidebar;
