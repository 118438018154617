import React from 'react';
import CDonutChart from '../../../_Charts/KpisCharts/CDonutChart/CDonutChart';
import querySchema from './graphql/getEmotionBreakdownDonutCardData';
import CQuery from '../../../_Container/QQuery/CQuery';

const EmotionBreakdownDonutCardContain = ({ filters, noDataComponent, errorComponent, ...props }) => (
  <CQuery query={querySchema} filterKeys={filters} noDataComponent={noDataComponent} errorComponent={errorComponent}>
    <CDonutChart {...props} filters={filters} />
  </CQuery>
);

export default EmotionBreakdownDonutCardContain;
