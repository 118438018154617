import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { BasePropTypes } from '../../../_Utils/common.propTypes';
import { lightPurple, themeColors } from '../../../styles/abstracts/colors';
import { radius } from '../../../styles/abstracts/variables';

const Body = styled.div`
  display: ${({ display }) => display || 'flex'};

  width: ${({ width }) => width || '100%'};
  white-space: nowrap;
  cursor: pointer;
  & > *:first-child {
    border-radius: ${({ borderRadius }) =>
      borderRadius
        ? `${borderRadius} 0 0 ${borderRadius}`
        : `${radius.round} 0 0 ${radius.round}`};
  }

  & > *:last-child {
    border-radius: ${({ borderRadius }) =>
      borderRadius
        ? `0 ${borderRadius} ${borderRadius} 0`
        : `0 ${radius.round} ${radius.round} 0`};
  }
  ${({ display }) => display && `align-items: center`}
`;

const Option = styled.div`
  width: 100%;

  padding: ${({ padding }) => padding || '0.25em'};
  text-align: center;
  font-weight: bold;

  background-color: ${({ selected }) =>
    selected ? themeColors.primary : lightPurple};
  color: ${({ selected }) => (selected ? 'white' : themeColors.primary)};

  transition: background-color 0.5s, color 0.5s;
`;

const QSwitcher = ({
  options,
  selected,
  onOptionSelected,
  padding,
  borderRadius,
  display,
  width,
  t,
}) => (
  <Body borderRadius={borderRadius} display={display} width={width}>
    {options.map(({ id, label }) => (
      <Option
        selected={id === selected || id === null}
        onClick={() => onOptionSelected(id)}
        padding={padding}
      >
        <span>{t(label)}</span>
      </Option>
    ))}
  </Body>
);

export const QSwitchOptionPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

QSwitcher.propTypes = {
  ...BasePropTypes,
  selected: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(QSwitchOptionPropType).isRequired,
  onOptionSelected: PropTypes.func.isRequired,
};

export default withTranslation('button')(QSwitcher);
