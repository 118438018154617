import React from 'react';
import moment from 'moment';
import {
  Section,
  SectionItem,
  SectionLink,
  SectionTitle,
  SectionTitleContainer,
} from '_Cards/SettingsCards/ProjectDetailsCard/_Components/GlobalInformations/styles';

const ExtractionSection = ({ period, t }) => (
  <Section>
    <SectionTitleContainer>
      <SectionTitle>{t('Mention date range')}</SectionTitle>
    </SectionTitleContainer>
    <SectionItem>
      <span>
        {t('From')} {moment(period?.startDate).format('L')} {t('to')}{' '}
        {moment(period?.endDate).format('L')}
      </span>
    </SectionItem>
  </Section>
);

export default ExtractionSection;
