import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { purple, emotionColors, grayShades } from 'styles/abstracts/colors';
import { flat } from '_Cards/ThematicsCard/ThematicsOverviewCards/CategoryBreakdownCard/utils';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

type DataProps = {
  children: Array<DataProps | any>;
  value: number;
  name: string;
};

type TreemapChartProps = {
  data: Array<DataProps> | undefined;
  subFilterToDisplay: string | null;
  filterToDisplay: string;
  sunburstMode: boolean;
  t: any;
};

const TreemapChart = ({ data, subFilterToDisplay, filterToDisplay, sunburstMode, t }: TreemapChartProps) => {
  const [graphData, setGraphData] = useState(data);
  useEffect(() => {
    const dataBis = cloneDeep(data);
    setGraphData(
      dataBis.map(d => {
        d.children = [];
        return d;
      }),
    );
  }, []);

  const [chosenThematic, setChosenThematic] = useState<string>('');

  const getTreemapLevelOption = () => {
    return [
      {
        itemStyle: {
          backgroundColor: 'red',
          borderColor: '#777',
          borderWidth: 1,
          gapWidth: 1,
        },
        label: {
          color: '#fff',
          borderColor: 'transparent',
          borderWidth: 0,
        },
        upperLabel: {
          show: false,
        },
      },
      {
        colorMappingBy: 'value',
        label: {
          color: '#fff',
          borderColor: 'transparent',
          borderWidth: 0,
        },
        itemStyle: {
          borderColor:
            subFilterToDisplay && filterToDisplay === 'emotions'
              ? // @ts-ignore
                emotionColors[subFilterToDisplay]
              : purple,
          borderWidth: 5,
          gapWidth: 1,
          backgroundColor: 'red',
        },
        emphasis: {
          itemStyle: {
            borderColor: '#ddd',
          },
        },
      },
      {
        colorMappingBy: 'value',
        label: {
          color: '#fff',
          borderColor: 'transparent',
        },
        colorSaturation: [0.35, 0.5],
        color: [
          subFilterToDisplay && filterToDisplay === 'emotions'
            ? // @ts-ignore
              emotionColors[subFilterToDisplay]
            : purple,
        ],
        itemStyle: {
          borderWidth: 5,
          gapWidth: 1,
          borderColorSaturation: 0.6,
        },
      },
    ];
  };

  const getSunburstLevelOption = () => {
    return [
      {},
      {
        r0: '10%',
        r: '45%',
        label: {
          rotate: 'radial',
        },
      },
      {
        r0: '45%',
        r: '90%',
        label: {
          rotate: 'radial',
        },
      },
    ];
  };

  const getOption = () => {
    return {
      tooltip: {
        trigger: 'item',
        formatter: a => {
          return `<div><p><b>${a.name}</b></p><p>${t('Mention count')}: ${a.value}</p><p>${t('buttotn:Percentage')}: ${
            a.data.percentage
          }%</p></div>`;
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        iconStyle: {
          borderWidth: 2,
        },
        feature: {
          dataView: {
            title: t('graph:View data'),
            lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
            readOnly: false,
            textColor: purple,
            buttonColor: purple,
            iconStyle: {
              borderColor: grayShades.g800,
            },
          },
          restore: {
            title: t('graph:Reload'),
            iconStyle: {
              borderColor: grayShades.g800,
            },
          },
          saveAsImage: {
            title: t('graph:Save as image'),
            name: t('graph:Graph Q°Emotion'),
            backgroundColor: '#FFFFFF',
            iconStyle: {
              borderColor: grayShades.g800,
            },
          },
        },
        emphasis: {
          iconStyle: {
            borderWidth: 2,
            borderColor: grayShades.g900,
            textBackgroundColor: '#FFFFFF',
            textFill: purple,
            textBorderRadius: [5, 5, 5, 5],
            textPadding: [6, 6, 4, 6],
          },
        },
      },
      series: [
        {
          name: '',
          type: sunburstMode ? 'sunburst' : 'treemap',
          highlightPolicy: 'ancestor',
          avoidLabelOverlap: true,
          renderLabelForZeroData: false,
          radius: [0, '95%'],
          levels: sunburstMode ? getSunburstLevelOption() : getTreemapLevelOption(),
          data: sunburstMode ? graphData : data,
          upperLabel: {
            show: true,
            // height: 30,
            // borderWidth: 1,
            color: '#ffffff',
            borderColor: 'transparent',
            // borderWidth: 0,
          },
          label: {
            color: '#fff',
            borderColor: 'transparent',
            borderWidth: 0,
          },
          breadcrumb: {
            show: false,
          },
          itemStyle: {
            borderWidth: 1,
            borderColor: grayShades.g300,
            color:
              subFilterToDisplay && filterToDisplay === 'emotions'
                ? // @ts-ignore
                  emotionColors[subFilterToDisplay]
                : purple,
            minWidth: '10em',
          },
        },
      ],
    };
  };

  const onChartClick = (d: any) => {
    const numberOfChildren = flat(cloneDeep(data))?.find(da => d?.name === da?.name)?.children?.length;

    if (sunburstMode && numberOfChildren) {
      if (d.name !== chosenThematic) {
        const dataToDisplay = [flat(cloneDeep(data))?.find(da => d.name === da.name)];
        dataToDisplay[0].children?.map(child => {
          child.children = [];
        });
        delete dataToDisplay[0].value;
        setGraphData(dataToDisplay);
        setChosenThematic(d.name);
      } else if (chosenThematic && d.name === chosenThematic) {
        const dataBis = cloneDeep(data);
        setGraphData(
          dataBis.map(d => {
            d.children = [];
            return d;
          }),
        );
        setChosenThematic('');
      }
    }
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {ReactEcharts ? (
        <ReactEcharts
          option={getOption()}
          style={{
            height: '100%',
            width: '100%',
          }}
          className="react_for_echarts"
          onEvents={onEvents}
          theme="QemotionTheme"
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default withTranslation('card', ['button', 'graph'])(TreemapChart);
