import React, { useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { cloneDeep, groupBy } from 'lodash';
import { useMutation } from 'react-apollo';
import { QDropdownMenuItem } from '_Components/Menus/QDropdownMenu/QDropdownMenu';
import Icon from '_Components/Icons/Icon';
import { purple } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import SAVE_THEMATICS_OVERVIEW_SETTINGS_MUTATION from '../../graphql/saveThematicsOverviewSettings';
import GET_THEMATICS_OVERVIEW_DATA_QUERY from '../../graphql/getThematicsOverviewData';
import { Badge } from '../CategoryRankingCard';

const StyledDropdownMenu = styled(DropdownMenu)`
  background: white;
  margin-right: 1em !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  top: inherit;
`;

const Item = styled(QDropdownMenuItem)`
  padding: ${({ isParent }) => (isParent ? '0.5em 0 0.5em 2.375em' : '0.25em 0')} !important;
  min-width: 20em;
  color: ${purple};
  font-weight: bold;
  font-size: ${fontSizes.smaller};
`;

const Checkbox = styled(QCheckbox)`
  margin: 0.5em;
`;

const CategoryDropdownItem = ({ col, selectedColumns, setSelectedColumns, t, projectId, settings, filterKeys }) => {
  const [isChildrenDropdownOpen, toggleChildrenDropdownOpen] = useState(false);
  const [saveThematicsOverviewSettings] = useMutation(SAVE_THEMATICS_OVERVIEW_SETTINGS_MUTATION);
  const isColumnSelected = selectedColumns.find(column => column.id === col.id);
  const selectedChildrenLength =
    col.children &&
    selectedColumns.filter(
      column =>
        column.id
          .split('.')
          .slice(0, -1)
          .join('.') === col.id ||
        column.id
          .split('.')
          .slice(0, -2)
          .join('.') === col.id,
    ).length;

  return !col.children ? (
    <Item
      toggle={false}
      onClick={() => {
        const cloneSettings = cloneDeep(settings);
        const newColumns = selectedColumns.find(column => col.id === column.id)
          ? selectedColumns.filter(column => column.id !== col.id)
          : [...selectedColumns, col];
        setSelectedColumns(newColumns);
        cloneSettings.categoryRanking.selectedColumns = newColumns.map(c => c.id);
        saveThematicsOverviewSettings({
          variables: { projectId, settings: JSON.stringify(cloneSettings) },
          refetchQueries: [
            {
              query: GET_THEMATICS_OVERVIEW_DATA_QUERY,
              variables: filterKeys,
            },
          ],
        });
      }}
    >
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Checkbox selected={isColumnSelected} />
        {col.label}
      </div>
    </Item>
  ) : (
    <Item toggle={false} isParent={!!col.children}>
      <Dropdown
        isOpen={isChildrenDropdownOpen}
        toggle={() => toggleChildrenDropdownOpen(!isChildrenDropdownOpen)}
        direction="left"
      >
        <DropdownToggle
          onClick={() => toggleChildrenDropdownOpen(!isChildrenDropdownOpen)}
          data-toggle="dropdown"
          aria-expanded={isChildrenDropdownOpen}
          tag="span"
        >
          <span
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '1em',
              height: '100%',
            }}
          >
            <span>{col.label}</span>
            <span>
              {selectedChildrenLength > 0 && <Badge>{selectedChildrenLength}</Badge>}
              <Icon icon="INTERFACE_CHEVRON_RIGHT" color={purple} />
            </span>
          </span>
        </DropdownToggle>
        <StyledDropdownMenu>
          {col.children.map(child => (
            <CategoryDropdownItem
              col={child}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              projectId={projectId}
              t={t}
              settings={settings}
              filterKeys={filterKeys}
            />
          ))}
        </StyledDropdownMenu>
      </Dropdown>
    </Item>
  );
};

export default CategoryDropdownItem;
