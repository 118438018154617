import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import BadgeBenchmarkTooltip from '_Components/Tooltips/BadgeBenchmarkTooltip/BadgeBenchmarkTooltip';
import { withTranslation } from 'react-i18next';
import { grayShades, black } from 'styles/abstracts/colors';
import { getFontSizeStyles } from 'styles/abstracts/typography';

const IndicatorName = styled.p<{ mt: boolean }>`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${grayShades.g800};
  text-align: left;
  line-height: 21px;
  margin-bottom: -0.1em;
  // margin-top: ${mt => (mt ? '0.4em' : '0')};
  margin-top: 0;
`;

const NotationText = styled.span`
  font-size: 3em;
  color: ${black};
  text-align: left;
  margin-bottom: 0;
  line-height: 1;
`;

const NotationScale = styled.span`
  font-size: 0.8rem;
  color: ${grayShades.g800};
  align-self: flex-end;
`;

const KPITitle = styled.p<{ smaller: boolean }>`
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.25rem;
  ${({ smaller }) => getFontSizeStyles(smaller ? 17 : 20)};
`;

const KPICol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const KPINote = props => {
  const {
    t,
    title,
    name,
    indicator,
    data,
    idTooltip,
    smaller,
    inlineTooltips,
  } = props;
  return (
    <Row className="font-weight-bold">
      <KPICol className="textnotation">
        {!inlineTooltips && (
          <>
            {title && <KPITitle smaller={smaller}>{t(title)}</KPITitle>}
            <IndicatorName className="IndicatorName" mt={!!title}>
              {t(name)}
            </IndicatorName>
            <Row className="mb-2">
              <NotationText className="NotationText">{`${data?.value}`}</NotationText>
              <NotationScale>{`${
                data?.unit?.max ? `/ ${data?.unit?.max}` : ''
              }`}</NotationScale>
            </Row>
            {idTooltip && (
              <BadgeBenchmarkTooltip
                title={t(indicator)}
                data={data}
                idTooltip={idTooltip}
              />
            )}
          </>
        )}

        {inlineTooltips && (
          <>
            {title && <KPITitle smaller={smaller}>{t(title)}</KPITitle>}
            <IndicatorName className="IndicatorName" mt={!!title}>
              {t(name)}
            </IndicatorName>
            <Row className="mb-2 align-items-center">
              <div className="mr-1">
                <NotationText className="NotationText">{`${data?.value}`}</NotationText>
                <NotationScale>{`${
                  data?.unit?.max ? `/ ${data?.unit?.max}` : ''
                }`}</NotationScale>
              </div>
              {idTooltip && (
                <div className="ml-1">
                  <BadgeBenchmarkTooltip
                    title={t(indicator)}
                    data={data}
                    idTooltip={idTooltip}
                  />
                </div>
              )}
            </Row>
          </>
        )}
      </KPICol>
    </Row>
  );
};

export default withTranslation('card')(KPINote);
