import gql from 'graphql-tag';

const declareEMotionalError = gql`
  mutation declareEmotionalError(
    $projectId: Int!
    $sentenceId: Int!
    $suggestedEmotion: Emotions!
    $comment: String
  ) {
    declareEmotionalError(
      projectId: $projectId
      sentenceId: $sentenceId
      suggestedEmotion: $suggestedEmotion
      comment: $comment
    ) {
      errors
      claim
    }
  }
`;

export default declareEMotionalError;
