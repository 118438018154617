import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { prepareGroupedToOptions } from './groupedToOptions';
import { createAdapter } from './adapter';

export class ThematicsFilter extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    type: PropTypes.string,

    // NOTE: properties passed by QProjectFilters
    onFilterOptionsCreated: PropTypes.func,
    onFilterRemoved: PropTypes.func,
    registerAdapter: PropTypes.func,
  };

  static defaultProps = {
    type: 'thematics',
    onFilterOptionsCreated: undefined,
    onFilterRemoved: undefined,
    registerAdapter: undefined,
  };

  groupedToOptions = null;

  constructor(props) {
    super(props);
    this.initialize();
  }

  initialize() {
    const { t } = this.props;
    this.groupedToOptions = prepareGroupedToOptions(t);
  }

  componentDidMount() {
    const { registerAdapter, type } = this.props;
    registerAdapter(createAdapter(type));
  }

  componentWillUnmount() {
    const { onFilterRemoved } = this.props;
    onFilterRemoved();
  }

  render() {
    const { filters, onFilterOptionsCreated, type } = this.props;
    const options = this.groupedToOptions(filters, type);
    onFilterOptionsCreated(options);
    return null;
  }
}

export const QThematicsFilter = withTranslation('filters')(ThematicsFilter);

