import styled from "styled-components";
import { fontSizes } from "../styles/commonProperties";

export default styled.div`
  text-align: center;
  color: #592ea0;
  font-weight: bold;
  font-size: ${fontSizes.bigger};
  padding: 0 2em;
  margin-bottom: ${({ contentHeader }) => contentHeader && "0.5em"};
`;
