import React from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import GlobalFiltersValueProvider from '_Layouts/Header/_Containers/SubHeader/_Containers/HeaderProjectFilters/GlobalFiltersValueProvider';
import HeaderProjectFilters from '_Layouts/Header/_Containers/SubHeader/_Containers/HeaderProjectFilters/HeaderProjectFilters';

const CustomCol = styled(Col)`
  margin: 1em;
`;

const StyledHeaderProjectFilters = styled.div`
  flex-grow: 1;
`;


const LandingPageFilterModalFilterSwitcher = () =>
  {

    return (
      <>
        <Row>
          <CustomCol>
            <StyledHeaderProjectFilters>
              <GlobalFiltersValueProvider>
                <HeaderProjectFilters />
              </GlobalFiltersValueProvider>
            </StyledHeaderProjectFilters>
          </CustomCol>
        </Row>
      </>
    );
  };
export default connect()(withTranslation('filters')(LandingPageFilterModalFilterSwitcher));
