import React from 'react';
import styled from 'styled-components';
import { grayShades, red } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import Icon from '_Components/Icons/Icon';
import { passwordLengthVerification, passwordDigitVerification, passwordUpperCaseVerification } from 'Routes/UtilityRoutes/PasswordReset/utils';

const InfoMessage = styled.p`
  font-weight: bold;
  color: ${({ error }) => (error ? red : grayShades.g800)};
  font-size: ${fontSizes.smaller};
  display: flex;
  align-items: center;
  margin-top: 0.5em;
`;

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
  margin-left: 0.5em;
`;

const VerificationMessages = ({password}) => (
  <>
    <InfoMessage>
      <StyledIcon icon="INTERFACE_INFO" color={grayShades.g800} size={16} />{' '}
      Minimum 8 characters, 1 number, 1 uppercase{' '}
    </InfoMessage>
    {passwordLengthVerification(password) && (
      <InfoMessage error>
        Your password must have 8 characters or more.
      </InfoMessage>
    )}
    {passwordDigitVerification(password) && (
      <InfoMessage error>Your password must have a number.</InfoMessage>
    )}
    {passwordUpperCaseVerification(password) && (
      <InfoMessage error>
        Your password must have an uppercase letter.
      </InfoMessage>
    )}
  </>
);

export default VerificationMessages;
