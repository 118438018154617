import React from 'react';
import CQuery from '../../../_Container/QQuery/CQuery';
import querySchema from './graphql/getMentionBadgeCardData';
import Rond from '../../../_Charts/KpisCharts/KpiBadges/Rond';

const MentionBadgeSection = ({
  filters,
  data,
  color,
  type,
  noDataComponent,
  errorComponent,
}) => (
  <CQuery
    query={querySchema}
    filterKeys={filters}
    noDataComponent={noDataComponent}
    errorComponent={errorComponent}
  >
    <Rond
      {...data}
      type={type || 'large'}
      customCSS={color && { backgroundColor: color }}
    />
  </CQuery>
);

export default MentionBadgeSection;
