import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import Icon from '_Components/Icons/Icon';
import { REPORTING_MODES } from '../../../../../../_Resources/Reporting/Actions/updateReportingMode';
import {
  grayShades,
  themeColors,
} from '../../../../../../styles/abstracts/colors';

const BREAD_CRUMBS = [
  {
    name: 'Document',
    mode: REPORTING_MODES.GLOBAL,
    payload: {},
  },
  {
    name: 'Page',
    mode: REPORTING_MODES.PAGE,
    payload: { pageIndex: 0 },
  },
  {
    name: 'Card',
    mode: REPORTING_MODES.CARD,
    payload: { pageIndex: 0, cardIndex: 0 },
  },
];

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconHolder = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${grayShades.g300};
  border-radius: 16px;
  margin: 0 1rem;
  ${({ index }) => index === 0 && `display: none;`}
  ${({ clickable }) => clickable && `cursor: pointer;`}
`;

const BreadCrumb = styled.span`
  font-size: 1.2em;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ContextLabel = styled.span`
  font-size: 0.8em;
  color: ${themeColors.secondary};
  font-weight: bold;
  margin-left: 0.5em;
`;

const selectBreadCrumbs = mode => {
  const breadCrumbs = [];

  BREAD_CRUMBS.some(crumb => {
    breadCrumbs.push(crumb);
    return crumb.mode === mode;
  }, []);

  return breadCrumbs;
};

const selectPrevMode = (currentMode, pageIndex, onModeSelected) => {
  if (REPORTING_MODES.CARD === currentMode) {
    onModeSelected(REPORTING_MODES.PAGE, pageIndex);
  } else {
    onModeSelected(REPORTING_MODES.GLOBAL);
  }
};

const goToPrevMode = ({ pageIndex }, mode, onModeSelected, history) =>
  REPORTING_MODES.GLOBAL !== mode
    ? selectPrevMode(mode, pageIndex, onModeSelected)
    : history.push('reporting-main');

const BreadCrumbs = ({ t, mode, onModeSelected, payloads, history }) => {
  const breadCrumbs = selectBreadCrumbs(mode);
  return (
    <Wrapper>
      <IconHolder
        onClick={() =>
          goToPrevMode(payloads[mode], mode, onModeSelected, history)
        }
        clickable
      >
        <Icon
          icon="INTERFACE_CHEVRON_LEFT"
          size={32}
          color={themeColors.secondary}
        />
      </IconHolder>
      {breadCrumbs.map(({ name, mode: breadCrumbMode }, index) => {
        const { pageIndex, cardIndex, contextLabel } = payloads[breadCrumbMode];
        return (
          <>
            <IconHolder index={index}>
              <Icon
                icon="INTERFACE_CHEVRON_RIGHT"
                size={16}
                color={themeColors.secondary}
              />
            </IconHolder>
            <BreadCrumb
              onClick={() =>
                onModeSelected(breadCrumbMode, pageIndex, cardIndex)
              }
            >
              {t(name)}
              <ContextLabel>
                {contextLabel ? `(${contextLabel})` : ''}
              </ContextLabel>
            </BreadCrumb>
          </>
        );
      })}
    </Wrapper>
  );
};

BreadCrumbs.propTypes = {
  t: PropTypes.func.isRequired,
  onModeSelected: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(Object.values(REPORTING_MODES)).isRequired,
  payloads: PropTypes.shape({
    [REPORTING_MODES.GLOBAL]: PropTypes.shape({}),

    [REPORTING_MODES.PAGE]: PropTypes.shape({
      contextLabel: PropTypes.string.isRequired,
      pageIndex: PropTypes.number.isRequired,
    }),

    [REPORTING_MODES.CARD]: PropTypes.shape({
      contextLabel: PropTypes.string.isRequired,
      pageIndex: PropTypes.number.isRequired,
      cardIndex: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default withRouter(withTranslation('reporting')(BreadCrumbs));
