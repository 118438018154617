import benchmarkColConfigImage from './benchmark_col_config.png';
import benchmarkCardConfigImage from './benchmark_card_config.png';

export const columnCollapseContent = {
  collapseText: 'TUTO_COLUMN_COLLAPSE_CONTENT',
  collapseImage: benchmarkColConfigImage,
};

export const cardCollapseContent = {
  collapseText: 'TUTO_CARD_COLLAPSE_CONTENT',
  collapseImage: benchmarkCardConfigImage,
};
