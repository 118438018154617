import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { grayShades } from 'styles/abstracts/colors';
import { HighlightedKeyword } from '_Components/QVerbatimBubble/VerbatimBubble';
import { getColorHexaByEmotion } from '_Utils/emotions/emotionsColorFunctions';
import { capitalize } from '_Utils/strings/stringsUtils';
import { sendVerbatimEmotionsOnHover } from '_Resources/Cards/Actions/sendVerbatimEmotionsOnHover';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import VerbatimSentenceOptions from '_Components/QVerbatimBubble/VerbatimSentenceOptions';
import { getTranslatedSentence } from './utils';

const Area = styled.span``;

const AreaChat = styled.span`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AreaChatClient = styled.p`
  background-color: ${({ emotionColor }) => emotionColor};
  color: white;
  font-weight: bold;
  padding: 0.25em 1em;
  margin-bottom: 0.5em;
  border-radius: 24px;
  max-width: 75%;
  expandline-height: 2em;
  margin-right: 1em;
`;

const AreaChatAgent = styled(Area)`
  background-color: ${grayShades.g300};
  padding: 0.25em 1em;
  border-radius: 24px;
  line-height: 2em;
  margin-left: 1em;
  font-weight: bold;
  color: ${grayShades.g800};
`;

const Tag = styled.div`
  position: absolute;
  right: 0;
  margin-top: ${({ isChat }) => (isChat ? '0px' : '-12px')};
  display: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  font-size: 12px;
  color: #ffffff;
  text-align: left;
  line-height: 18px;
  padding: 2px 4px 2px 12px;
  font-weight: bold;
  text-transform: capitalize;
  z-index: 5;
  ${({ isActive }) => isActive && `display: flex !important`};

  &:hover {
    display: flex !important;
  }

  ${Area}:hover {
    display: flex !important;
  }

  & label {
    text-transform: none;
  }
`;

const AgentChatContainer = styled.div`
  margin: 0.5em 0;
`;

const ChatTag = styled(Tag)`
  margin-top: ${({ height }) => `calc(${height}px + 1em)`};
  margin-right: calc(6.5em + 5px);
  right: inherit;
`;

const Expand = styled.span`
  position: relative;
  width: ${({ isChat }) => isChat && '75%'};
  border-bottom-color: ${({ isChat, color }) => !isChat && `${color}`};
  border-bottom-style: ${({ isChat, underline }) => !isChat && `${underline}`};
  border-bottom-width: ${({ isChat, underline }) => {
    if (!isChat) {
      switch (underline) {
        case 'double':
          return 'thick';
        case 'dashed':
          return '2px';
      }
    }
    return '1px';
  }};
  cursor: pointer;
  ${Area}:hover {
    border-bottom: ${({ isChat }) => !isChat && '3px solid'};
  }
`;

const isTagActive = (
  emotionSelect,
  emotion,
  showDropdownMenu,
  sentenceIndex,
  index,
  isChat,
  collapsed,
) => {
  if (!isChat && !collapsed) {
    if (sentenceIndex !== undefined) {
      if (showDropdownMenu) {
        return showDropdownMenu && sentenceIndex === index;
      }
      return emotionSelect === emotion && sentenceIndex === index;
    }
    return emotionSelect === emotion;
  }
  return false;
};

const VerbatimColor = props => {
  const {
    verbatims,
    emotionSelect,
    t,
    keywords,
    sendEmotionOnHover,
    belongingClusters,
    belongingVerbatim,
    storedSentence,
    collapsed,
    informations,
    userPreferedLocale,
  } = props;
  const newLineArray = verbatims.map(v => v.sentence.includes('|*|'));
  const isChat = newLineArray.includes(true);
  const [sentenceIndex, setSentenceIndex] = useState(undefined);

  const getUnderline = emotionalClaim => {
    if (emotionalClaim !== null) {
      const { claimType, status } = emotionalClaim;
      if (claimType && status) {
        return claimType === 'error' && status === 'PENDING'
          ? 'dashed'
          : claimType === 'exactness' && status === 'COMPLETED'
          ? 'double'
          : 'solid';
      }
    }
    return 'solid';
  };

  const isAgentSpeech = () => {
    const criteriaKeys = informations?.informationsList?.find(
      info => info.name === 'CriteriaKey',
    )?.values;
    return criteriaKeys.some(
      key => key.name === 'Author' && key.value === 'Agent',
    );
  };

  return (
    <span>
      {verbatims &&
        verbatims.map((verbatim, index) => {
          const sentenceRefact = capitalize(
            getTranslatedSentence(verbatim, userPreferedLocale),
          );
          const isEmotion = !!verbatim.emotion || false;
          const emotion = isEmotion ? verbatim.emotion.toLowerCase() : false;
          let spanInserted = sentenceRefact
            .split(new RegExp('([?:!.\\s])', 'gi'))
            .filter(word => word !== ' ');
          const sentence = spanInserted.map((word, j) => {
            spanInserted[j] =
              keywords &&
              keywords.some(
                keyword =>
                  keyword.toLowerCase() === word.toString().toLowerCase(),
              ) ? (
                <HighlightedKeyword keyword={word} />
              ) : (
                word
              );
            keywords &&
              keywords.map(keyword => {
                const keywordArray = keyword.split(' ');
                if (keywordArray.length > 1) {
                  if (
                    /^[[]-_.\\s]/.test &&
                    keywordArray[0].toLowerCase() ===
                      word.toString().toLowerCase()
                  ) {
                    const groupWords = [];
                    const groupKeywords = [];
                    for (let l = 1; l < keywordArray.length; l++) {
                      groupKeywords.push(keywordArray[l]);
                      groupWords.push(spanInserted[j + l]);
                      if (groupKeywords.join(' ') === groupWords.join(' ')) {
                        spanInserted[j] = (
                          <HighlightedKeyword keyword={spanInserted[j]} />
                        );
                        spanInserted[j + l] = (
                          <HighlightedKeyword keyword={spanInserted[j + l]} />
                        );
                      }
                    }
                  }
                }
              });
            return spanInserted[j];
          });
          spanInserted = sentence.map((word, k, { length }) => {
            if (isChat && word === '|*|') {
              return (
                <Expand key={`expandID${k * 2}`} isChat={isChat}>
                  <div className="d-flex align-items-center">
                    <IconCircle
                      color={grayShades.g800}
                      icon="MODULES_PEOPLE"
                      size={48}
                    />
                    <AreaChatAgent>
                      {isAgentSpeech()
                        ? '[Interaction Client]'
                        : '[Interaction Agent]'}
                    </AreaChatAgent>
                  </div>
                </Expand>
              );
            }
            if (k >= 0 && sentence?.[k - 1] === '|*|') {
              return null;
            }
            if (isChat && word !== '|*|') {
              return (
                <Expand
                  color={getColorHexaByEmotion(emotion)}
                  key={`expandID${k * 2}`}
                  isChat={isChat}
                >
                  {word}{' '}
                </Expand>
              );
            }

            return (
              <Expand
                color={getColorHexaByEmotion(emotion)}
                key={`expandID${k * 2}`}
                isChat={isChat}
                onMouseEnter={() => {
                  if (!storedSentence.showDropdownMenu) {
                    !isChat && sendEmotionOnHover(emotion);
                    setSentenceIndex(index);
                  }
                }}
                onMouseLeave={() => {
                  if (!storedSentence.showDropdownMenu) {
                    !isChat && sendEmotionOnHover(null);
                    setSentenceIndex(undefined);
                  }
                }}
                underline={getUnderline(verbatim.emotionalClaim)}
              >
                {word}{' '}
                {k === length - 1 && !isChat && (
                  <Tag
                    isActive={isTagActive(
                      emotionSelect,
                      emotion,
                      storedSentence.showDropdownMenu,
                      sentenceIndex,
                      index,
                      isChat,
                      collapsed,
                    )}
                    style={{
                      backgroundColor: getColorHexaByEmotion(emotion),
                    }}
                    isChat={isChat}
                  >
                    {emotion && t(emotion)}
                    <VerbatimSentenceOptions
                      index={index}
                      sentenceIndex={sentenceIndex}
                      emotion={emotion}
                      sentenceId={verbatim.sentenceId}
                      sentenceText={verbatim.sentence}
                      belongingClusters={belongingClusters}
                      belongingVerbatim={belongingVerbatim}
                      verbatim={verbatim}
                    />
                  </Tag>
                )}
              </Expand>
            );
          });
          if (isChat) {
            if (!sentence.includes('|*|')) {
              return (
                <AreaChat>
                  <AreaChatClient
                    key={`verbatimAreaKey${index * 2}`}
                    emotionColor={getColorHexaByEmotion(emotion)}
                    id={`verbatimAreaKey${index * 2}`}
                  >
                    {spanInserted}
                  </AreaChatClient>
                  <IconCircle
                    size={48}
                    color={getColorHexaByEmotion(emotion)}
                    icon={`EMOTION_${emotion.toUpperCase()}`}
                  />
                  <ChatTag
                    isActive={emotionSelect === emotion}
                    style={{
                      backgroundColor: getColorHexaByEmotion(emotion),
                    }}
                    height={
                      typeof document !== 'undefined' &&
                      document.getElementById(`verbatimAreaKey${index * 2}`)
                        ?.offsetHeight
                    }
                  >
                    {emotion && t(emotion)}
                  </ChatTag>
                </AreaChat>
              );
            }
            return (
              <AgentChatContainer>
                <Area key={`verbatimAreaKey${index * 2}`}>{spanInserted}</Area>
              </AgentChatContainer>
            );
          }
          return (
            <Area
              key={`verbatimAreaKey${index * 2}`}
              onMouseEnter={() => !isChat && sendEmotionOnHover(emotion)}
              onMouseLeave={() => !isChat && sendEmotionOnHover(null)}
            >
              {spanInserted}
            </Area>
          );
        })}
    </span>
  );
};

function mapStateToProps(state) {
  return {
    emotionSelect: state.cards.emotion,
    storedSentence: state.sentence,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendEmotionOnHover: newState => {
      dispatch(sendVerbatimEmotionsOnHover(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('emotion')(VerbatimColor));
