import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import TabMenu from '../../../Routes/GlobalExperience/Reporting/_Components/TabMenu';
import QSwitcher from '../../Buttons/QSwitcher/QSwitcher';
import { BasePropTypes } from '../../../_Utils/common.propTypes';

class QContentSwitcher extends Component {
  static propTypes = {
    ...BasePropTypes,
    config: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        id: PropTypes.string,
        content: PropTypes.func,
      }),
    ).isRequired,
    initialSelected: PropTypes.string,
  };

  static defaultProps = {
    initialSelected: null,
  };

  state = {
    selected: null,
  };

  constructor(props) {
    super(props);
    this.initialize();
  }

  initialize() {
    const {
      initialSelected,
      config: [firstConfigOption],
    } = this.props;
    this.state.selected = initialSelected || firstConfigOption.id;
  }

  onMenuOptionSelected = selected => this.setState({ selected });

  render() {
    const { config, children } = this.props;
    const { selected } = this.state;
    const { content } = config.find(({ id }) => id === selected) || {};
    return (
      <Fragment>
        <TabMenu>
          <TabMenu.Item>
            <QSwitcher
              selected={selected}
              options={config}
              onOptionSelected={this.onMenuOptionSelected}
            />
          </TabMenu.Item>
          {children}
        </TabMenu>
        {content && content()}
      </Fragment>
    );
  }
}

export default QContentSwitcher;
