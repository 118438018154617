import gql from 'graphql-tag';

const cancelEmotionalExactness = gql`
  mutation cancelEmotionalExactness($projectId: Int!, $claimId: String!) {
    cancelEmotionalExactness(projectId: $projectId, claimId: $claimId) {
      errors
      claim
    }
  }
`;

export default cancelEmotionalExactness;
