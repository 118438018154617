import gql from 'graphql-tag';

const query = gql`
  query getHeaderCriteriasAndThematicsFiltersData($projectId: Int!) {
    results: getHeaderCriteriasAndThematicsFiltersData(projectId: $projectId) {
      criteriaKeys {
        normal {
          id
          label
          values
        }
        related {
          name
          rootLevel
          levels {
            id
            label
            childrenCriteriaKey
            values
          }
        }
      }
      thematics {
        id
        label
        values {
          id
          label
          values {
            id
            label
            values {
              id
              label
            }
          }
        }
      }
    }
  }
`;

export default query;
