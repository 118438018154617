import React, { useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import { grayShades } from 'styles/abstracts/colors';

export const StepTitle = styled(Row)`
  border-bottom: 1px solid ${grayShades.g400};
  border-top: 1px solid ${grayShades.g400};
  padding: 1rem;
  font-weight: bold;
`;

const CollapseToggler = styled(StepTitle)`
  cursor: pointer;
`;

const ExplanationsToggler = styled(Col)`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${grayShades.g800};
  > p {
    margin-right: 0.5rem;
  }
`;

const StyledCollapse = styled(Collapse)`
  background-color: ${grayShades.g200};
  padding: 2rem;
  color: ${grayShades.g800};
  font-weight: bold;
  font-size: 0.875rem;
  width: 100%;
  border-bottom: 1px solid ${grayShades.g400};
`;

const CollapseText = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CollapsibleStepTitle = ({
  stepTitle,
  collapseText,
  collapseImage,
  t,
}) => {
  const [collapse, toggle] = useState(false);
  return (
    <>
      <CollapseToggler onClick={() => toggle(!collapse)}>
        <Col>
          <p>{stepTitle}</p>
        </Col>
        <ExplanationsToggler>
          {collapse ? (
            <>
              <p>{t('Hide explanations')}</p>
              <Icon
                icon="INTERFACE_CHEVRON_UP"
                color={grayShades.g800}
                size={24}
              />
            </>
          ) : (
            <>
              <p>{t('Show explanations')}</p>
              <Icon
                icon="INTERFACE_CHEVRON_DOWN"
                color={grayShades.g800}
                size={24}
              />
            </>
          )}
        </ExplanationsToggler>
      </CollapseToggler>
      <Row>
        <StyledCollapse isOpen={collapse}>
          <Row>
            {collapseImage && (
              <Col xs={3}>
                <img src={collapseImage} alt="Context" />
              </Col>
            )}
            <CollapseText
              xs="auto"
              dangerouslySetInnerHTML={{
                __html: collapseText,
              }}
            />
          </Row>
        </StyledCollapse>
      </Row>
    </>
  );
};
