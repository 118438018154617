import styled from 'styled-components';
import PropTypes from 'prop-types';
import { createStyle } from '../../../styles/utils';
import { fontSizes } from '../../../styles/abstracts/typography';
import { gray } from '../../../styles/abstracts/colors';
import { TEXT_POSITIONS } from '../textPositions';
import { TEXT_TYPES } from '../textTypes';

export const QTEXT_MODES = {
  PRIMARY: 'primary',
  STANDARD: 'standard',
};

const modeOptions = {
  [QTEXT_MODES.PRIMARY]: {
    'font-weight': 'bold',
  },
  [QTEXT_MODES.STANDARD]: {},
};

const typeOptions = {
  [TEXT_TYPES.HEADER]: {
    'font-size': fontSizes.bigger,
  },
  [TEXT_TYPES.PANEL]: {
    'font-size': fontSizes.default,
  },
  [TEXT_TYPES.SECTION]: {
    'font-size': fontSizes.smaller,
  },
};

const QText = styled.div`
  color: ${gray};
  text-align: ${({ position }) => position};
  ${({ type }) => createStyle(typeOptions[type])};
  ${({ mode }) => createStyle(modeOptions[mode])};
`;

QText.propTypes = {
  mode: PropTypes.oneOf(Object.values(QTEXT_MODES)),
  position: PropTypes.oneOf(Object.values(TEXT_POSITIONS)),
  type: PropTypes.oneOf(Object.values(TEXT_TYPES)),
};

QText.defaultProps = {
  mode: QTEXT_MODES.STANDARD,
  position: TEXT_POSITIONS.LEFT,
  type: TEXT_TYPES.PANEL,
};
export default QText;
