import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import CDatepicker from '_Layouts/Header/_Containers/SubHeader/CDatepicker';

import { spacing } from 'styles/abstracts/variables';
import { grayShades } from 'styles/abstracts/colors';

const Container = styled.div`
  color: ${grayShades.g800};
  font-weight: bold;
  margin: 0.75em 0 0.75em ${props => props.marginLeft || '2em'};
  // width: 100%;

  & > div {
    // width: 15em;
  }
`;

class Period extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Container
        className=""
        marginLeft={this.props.marginLeft}
        id="periodDatepicker"
      >
        <span style={{ marginRight: '5%' }}>{t('Period')}</span>
        <CDatepicker />
      </Container>
    );
  }
}

export default withTranslation('header')(Period);
