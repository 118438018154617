export const FIELD_TYPE = {
  COPY: 'copy',
  HEADER: 'header',
  FOOTER: 'footer',
};

export const FIELD_MODE = {
  TEXT_INPUT: 'text_input',
  CHECKBOX: 'checkbox',
};

export const COPY_FIELD_IDS = {
  TITLE: 'title',
  SUBTITLE: 'subtitle',
};

export const HEADER_FIELD_IDS = {
  DATE_RANGE: 'isDateRange',
  FILTERS: 'isFilters',
  THEMATICS: 'isThematics',
};

export const FOOTER_FIELD_IDS = {
  PAGINATION: 'isPagination',
  REPORT_TITLE: 'isReportTitle',
  EDITION_DATE: 'isEditionDate',
  IS_CUSTOMIZED_TEXT: 'isCustomizedText',
  CUSTOMIZED_TEXT: 'customizedText',
};

export const SETTING_FIELD_IDS = {
  [FIELD_TYPE.COPY]: COPY_FIELD_IDS,
  [FIELD_TYPE.HEADER]: HEADER_FIELD_IDS,
  [FIELD_TYPE.FOOTER]: FOOTER_FIELD_IDS,
};
