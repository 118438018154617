import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import ThematicsFilter from '../_Containers/filters/ThematicsFilter';
import NormalFilter from '../_Containers/filters/NormalFilter';
import TabMenu from './TabMenu';
import QButton from '../../../../_Components/Buttons/QButton/QButton';

const Footer = styled.div`
  text-align: center;
  margin: 1.5em;
`;

const FiltersArea = styled.div`
  padding: 0.5em;
  font-size: 0.9em;
`;

const StyledTabMenu = styled(TabMenu)`
  padding: 0;
`;
const StyledTabMenuItem = styled(TabMenu.Item)`
  padding: 0 0 0.5em 0;
`;

class FiltersSection extends Component {
  static propTypes = {
    isModified: PropTypes.bool.isRequired,
    onThematicsSelected: PropTypes.func.isRequired,
    onThematicsRemoved: PropTypes.func.isRequired,
    onOthersSelected: PropTypes.func.isRequired,
    onOthersRemoved: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    thematics: PropTypes.arrayOf(PropTypes.any),
    other: PropTypes.arrayOf(PropTypes.any),
  };

  static defaultProps = {
    thematics: null,
    normal: null,
  };

  render() {
    const {
      isModified,
      onThematicsSelected,
      onThematicsRemoved,
      thematics,
      onOthersSelected,
      onOthersRemoved,
      other,
      onReset,
      t,
    } = this.props;
    return (
      <Fragment>
        <FiltersArea>
          <StyledTabMenu>
            <StyledTabMenuItem>
              <ThematicsFilter
                values={thematics}
                onSelected={onThematicsSelected}
                onRemoved={onThematicsRemoved}
              />
            </StyledTabMenuItem>
            <StyledTabMenuItem>
              <NormalFilter
                values={other}
                onSelected={onOthersSelected}
                onRemoved={onOthersRemoved}
              />
            </StyledTabMenuItem>
          </StyledTabMenu>
        </FiltersArea>

        <Footer>
          <QButton
            bgColor="red"
            disabled={isModified}
            onClick={isModified && onReset}
          >
            {t('Reset "filters" to default report settings')}
          </QButton>
        </Footer>
      </Fragment>
    );
  }
}

export default withTranslation('reporting')(FiltersSection);
