import React from 'react';
import { Col } from 'reactstrap';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import CQuery from '../../../_Container/QQuery/CQuery';
import querySchema from './graphql/getSatisfactionNpsCardData';
import NoteWithLeftIcon from '../../../_Charts/KpisCharts/NoteWithLeftIcon/NoteWithLeftIcon';

export const SatisfactionNpsCardView = ({
  classNameCol,
  classNameCssTop,
  data: { satisfaction, netPromoterScore, effort },
  id,
}) => {
  const color = '#592EA0';
  const size = 48;
  return satisfaction || netPromoterScore || effort ? (
    <>
      {satisfaction && (
        <Col
          xs={classNameCol}
          className={classNameCssTop}
          style={{ margin: 0 }}
        >
          <NoteWithLeftIcon
            icon="PICTO_STAR"
            color={color}
            size={size}
            backgroundColor={color}
            indicator="Satisfaction"
            idTooltip={`SATDeltaTooltip${id}` || ''}
            data={satisfaction}
          />
        </Col>
      )}
      {netPromoterScore && (
        <Col
          xs={classNameCol}
          className={classNameCssTop}
          style={{ margin: 0 }}
        >
          <NoteWithLeftIcon
            icon="PICTO_HEART"
            color={color}
            size={size}
            backgroundColor={color}
            indicator="Net promoter score"
            idTooltip={`NPSDeltaTooltip${id}` || ''}
            data={netPromoterScore}
          />
        </Col>
      )}
      {effort && (
        <Col
          xs={classNameCol}
          className={classNameCssTop}
          style={{ margin: 0 }}
        >
          <NoteWithLeftIcon
            icon="CHECK_ALL"
            color={color}
            size={size}
            backgroundColor={color}
            indicator="Effort score"
            idTooltip={`CESDeltaTooltip${id}` || ''}
            data={effort}
          />
        </Col>
      )}
    </>
  ) : (
    <NoDataMessage secondText="There isn't any KPI provided in the data" />
  );
};

const SatisfactionNpsCard = props => (
  <QcardPanel {...props} hints={[HintsContent.HINT_SAT_NPS]}>
    <CQuery query={querySchema}>
      <SatisfactionNpsCardView
        {...props}
        classNameCssTop="mt-3"
        classNameCol={12}
      />
    </CQuery>
  </QcardPanel>
);

export default SatisfactionNpsCard;
