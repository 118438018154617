import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QWarningModal from '../../../../../../../../_Components/Modals/QWarningModal/QWarningModal';

const message = {
  title: 'Aborting verbatim selection',
  text:
    'Are you sure you want to go back to Search step? It will cancel you current selection...',
};

const warning = {
  text: 'Yes, go back to Search step',
};
const cancelText = 'No, I keep my current selection';

const FiltersWarningModal = ({ isOpen, onContinue, onStay, t }) => {
  const translatedMessages = {
    title: t(message.title),
    text: t(message.text),
  };
  const translatedWarning = {
    text: t(`button:${warning.text}`),
  };
  return (
    <QWarningModal
      message={translatedMessages}
      warning={translatedWarning}
      cancelText={t(`button:${cancelText}`)}
      isOpen={isOpen}
      onAbortConfirmed={onContinue}
      onAbortCanceled={onStay}
    />
  );
};

FiltersWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  onStay: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('card', 'button')(FiltersWarningModal);
