import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { grayShades, green } from 'styles/abstracts/colors';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import getIndustriesQuery from '_Cards/SettingsCards/ProjectsConfigurationCard/graphql/getIndustries';
import getUseCasesQuery from '_Cards/SettingsCards/ProjectsConfigurationCard/graphql/getUseCases';

const StyledCol = styled(Col)`
  margin-bottom: 1em;

  &:first-child,
  &:nth-child(2) {
    margin: 1em 0;
  }
`;

const StyledQPanel = styled(QPanel)`
  color: ${({ isActive }) => !isActive && grayShades.g800} !important;
  transition: all 0.1s ease-in-out;
  display: flex;

  & span {
    margin-left: 1em;
  }
`;

const ActiveIcon = styled.div`
  position: absolute !important;
  top: 0;
  transform: translateY(-25%) translateX(-25%);
  right: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: all 0.1s ease-in-out;
`;

const ItemLogo = styled.img`
  height: 5em;
  width: 5em;
`;

const ListUseCasesIndustries = ({
  isUseCase,
  setIndustry,
  setUseCase,
  industry,
  useCase,
}) => {
  const { ...industriesQuery } = useQuery(getIndustriesQuery, { fetchPolicy: 'no-cache' });
  const { ...useCasesQuery } = useQuery(getUseCasesQuery, { fetchPolicy: 'no-cache' });
  const industriesData =
    industriesQuery &&
    industriesQuery.data &&
    industriesQuery.data.getIndustries;
  const useCasesData =
    useCasesQuery && useCasesQuery.data && useCasesQuery.data.getUseCases;
  return (
    <ListUseCasesIndustriesData
      data={isUseCase ? useCasesData : industriesData}
      setIndustry={setIndustry}
      setUseCase={setUseCase}
      isUseCase={isUseCase}
      industry={industry}
      useCase={useCase}
    />
  );
};

const ListUseCasesIndustriesData = ({
  data,
  setIndustry,
  isUseCase,
  setUseCase,
  industry,
  useCase,
}) => {
  const [selectedItem, setSelectedItem] = useState(
    isUseCase ? useCase : industry,
  );

  useEffect(() => {
    setSelectedItem(isUseCase ? useCase : industry);
  }, [isUseCase]);
  return (
    <Row>
      {data &&
        data.map(item => (
          <StyledCol xs={6}>
            <StyledQPanel
              onClick={() => {
                selectedItem !== item.id
                  ? setSelectedItem(item.id)
                  : setSelectedItem(null);
                !isUseCase &&
                  (selectedItem !== item.id
                    ? setIndustry(item.id)
                    : setIndustry(undefined));
                isUseCase &&
                  (selectedItem !== item.id
                    ? setUseCase(item.id)
                    : setUseCase(undefined));
              }}
              isActive={item.id === selectedItem}
            >
              <ItemLogo
                src={`https://${item.logoLink}`}
                alt={item.description}
              />
              <span>
                <QTitle>{item.name}</QTitle>
                <p>{item.description}</p>
              </span>
            </StyledQPanel>
            <ActiveIcon isActive={item.id === selectedItem}>
              <IconCircle color={green} icon="CHECK_ALL" size={16} />
            </ActiveIcon>
          </StyledCol>
        ))}
    </Row>
  );
};

export default ListUseCasesIndustries;
