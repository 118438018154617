import { Component } from 'react';
import PropTypes from 'prop-types';
import { preparePropertyComparer } from '../../utils/commonHelpers';
import MapItem from './MapItem';
import { GoogleListenerHandler } from '../../utils/GoogleListenersHandler';

export class Marker extends Component {
  state = { marker: null };

  isPropChanged = preparePropertyComparer(this);

  listenersHandler = null;

  componentDidMount() {
    this.createNewMarker();
  }

  componentWillUnmount() {
    this.state.marker && this.removeMarkerFromTheMap();
    this.listenersHandler && this.clearListenerHandler();
  }

  componentDidUpdate(prevProps) {
    if (this.isPropChanged('google', prevProps)) {
      this.createNewMarker();
    }

    if (this.isPropChanged('options', prevProps)) {
      this.updateOptions();
    }

    if (this.isPropChanged('listeners', prevProps)) {
      this.updateListeners();
    }
  }

  createNewMarker() {
    const { google, options, listeners } = this.props;
    if (!google) return;

    const marker = new google.maps.Marker(options);
    this.createListenerHandler(marker);
    this.resetListeners(listeners);
    this.setState({
      marker,
    });
  }

  createListenerHandler(marker) {
    if (this.listenersHandler) {
      this.clearListenerHandler();
    }

    this.listenersHandler = new GoogleListenerHandler(marker);
  }

  clearListenerHandler() {
    this.listenersHandler.removeListeners();
  }

  updateListeners() {
    const { listeners } = this.props;
    if (!this.listenersHandler) {
      return;
    }

    this.resetListeners(listeners);
  }

  resetListeners(listeners = {}) {
    this.listenersHandler.resetListeners(listeners);
  }

  updateOptions() {
    const { marker } = this.state;
    if (!marker) {
      return;
    }

    marker.setOptions(this.props.options);
  }

  removeMarkerFromTheMap() {
    this.state.marker.setOptions({ map: null });
  }

  render() {
    return null;
  }
}

Marker.propTypes = {
  google: PropTypes.object,
  options: PropTypes.object,
  listeners: PropTypes.objectOf(PropTypes.func),
};

export default MapItem(Marker);
