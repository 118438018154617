/* eslint-disable import/prefer-default-export */
import { em, rem } from '../utils';

export const radius = {
  default: em(0.5),
  round: em(1),
};

export const radiusRoot = {
  default: rem(0.5),
  round: rem(1),
};

export const spacing = {
  xsmall: rem(0.25),
  small: rem(0.5),
  mediumSmall: rem(0.75),
  medium: rem(1),
  mediumLarge: rem(1.25),
  large: rem(1.5),
  xlarge: rem(1.75),
  xxlarge: rem(2),
};

export const ImageSize = {
  small: 16,
  medium: 24,
  big: 32,
};
