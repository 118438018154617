import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { get, find, orderBy } from 'lodash';
import {
  ORDER_BY_ITEMS,
  FILTERS_DIMENSIONS,
  INDICATORS_TYPE,
  INDICATORS_CONFIG,
  THEMATICS_TYPE,
} from './FiltersSettings';
import DropdownsContainer from './_Containers/DropdownsContainer';
import GraphContainer from './_Containers/GraphContainer';

class DataByFiltersCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      criteriaKeys: this.props.criteriaKeys,
      orderBy: this.props.dropdownSettings
        ? this.props.dropdownSettings.orderBy
        : ORDER_BY_ITEMS[0] && ORDER_BY_ITEMS[0].subcategories && ORDER_BY_ITEMS[0].subcategories[0],
      selectedCriteriaKey: this.props.dropdownSettings
        ? this.props.dropdownSettings.selectedCriteriaKey
        : this.props.criteriaKeys && orderBy(this.props.criteriaKeys, ['label'], ['asc'])[0].id,
      currentIndicators: this.props.dropdownSettings
        ? this.props.dropdownSettings.indicators
        : {
            [INDICATORS_TYPE.BADGE]: find(
              INDICATORS_CONFIG[INDICATORS_TYPE.BADGE],
              item => item.value === FILTERS_DIMENSIONS.EINDEX,
            ),
            [INDICATORS_TYPE.BAR]: find(
              INDICATORS_CONFIG[INDICATORS_TYPE.BAR],
              item => item.value === FILTERS_DIMENSIONS.MENTIONS,
            ),
          },
      thematic:
        this.props.thematic !== 'global'
          ? (this.props.dropdownSettings && this.props.dropdownSettings.thematic) || {
              thematic: this.props.defaultThematic,
              subThematic: null,
              subSubThematic: null,
            }
          : null,
    };
    this.updateSelectedCriteriaKey = this.updateSelectedCriteriaKey.bind(this);
    this.updateOrderBy = this.updateOrderBy.bind(this);
    this.updateCurrentIndicators = this.updateCurrentIndicators.bind(this);
    this.updateSubThematic = this.updateSubThematic.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { dropdownSettings } = this.props;
    if (
      this.props.thematic === 'global' &&
      this.props.dropdownSettings !== prevProps.dropdownSettings &&
      this.props.dropdownSettings
    ) {
      this.setState({
        orderBy: dropdownSettings.orderBy,
        selectedCriteriaKey: dropdownSettings.selectedCriteriaKey,
        currentIndicators: dropdownSettings.indicators,
        thematic: dropdownSettings ? dropdownSettings.thematic : null,
      });
    } else if (
      this.props.thematic === 'global' &&
      this.dropdownSettings !== prevProps.settings &&
      this.props.criteriaKeys &&
      !this.state.selectedCriteriaKey &&
      this.props.dropdownSettings === null
    ) {
      this.setState({
        selectedCriteriaKey: this.props.criteriaKeys && orderBy(this.props.criteriaKeys, ['label'], ['asc'])[0].id,
      });
    }
    if (
      (prevProps.criteriaKeys && this.props.criteriaKeys !== prevProps.criteriaKeys) ||
      this.props.idpage !== prevProps.idpage ||
      this.props.filterKeys.projectId !== prevProps.filterKeys?.projectId
    ) {
      this.setState({ criteriaKeys: this.props.criteriaKeys });
      this.setState({
        selectedCriteriaKey: this.props.dropdownSettings
          ? this.props.dropdownSettings.selectedCriteriaKey
          : orderBy(this.props.criteriaKeys, ['label'], ['asc'])?.[0]?.id,
      });
    }
    if (this.props.defaultThematic !== prevProps.defaultThematic) {
      const thematic = {
        thematic: this.props.defaultThematic,
        subThematic: null,
        subSubThematic: null,
      };
      this.setState({ thematic });
    }
  }

  updateOrderBy(orderBy) {
    this.setState({ orderBy });
  }

  updateSelectedCriteriaKey(selectedCriteriaKey) {
    this.setState({ selectedCriteriaKey });
  }

  updateCurrentIndicators(indicatorType, selectedIndicator) {
    const { currentIndicators } = this.state;
    currentIndicators[indicatorType] = selectedIndicator;
    if (indicatorType === INDICATORS_TYPE.BAR && selectedIndicator.value !== FILTERS_DIMENSIONS.EMOTIONS) {
      currentIndicators[indicatorType].selected = [];
    }
    this.setState({ currentIndicators });
  }

  updateSubThematic(thematicType, subThematic) {
    const { thematic } = this.state;
    thematic[thematicType] = subThematic;
    thematic.subSubThematic = thematicType === THEMATICS_TYPE.SUBTHEMATIC ? null : thematic.subSubThematic;
    this.setState({ thematic });
  }

  render() {
    return (
      <QcardPanel
        dataByFiltersCard
        hints={[HintsContent.HINT_DATA_BY_FILTERS]}
        dropDownOptions={this.props.dropDownOptions}
        title={`${this.props.t('Comparison')} ${this.props.t(
          this.state.currentIndicators?.[INDICATORS_TYPE.BADGE].label,
        )} ${
          this.state.thematic
            ? `(${
                this.state.thematic.subSubThematic !== null
                  ? `${this.state.thematic.thematic} > ${this.state.thematic.subThematic} > ${this.state.thematic.subSubThematic}`
                  : this.state.thematic.subThematic
                  ? `${this.state.thematic.thematic} > ${this.state.thematic.subThematic}`
                  : this.state.thematic.thematic
              })`
            : ''
        }`}
      >
        <DropdownsContainer
          filterKeys={this.props.filterKeys}
          idpage={this.props.idpage}
          criteriaKeys={orderBy(this.props.criteriaKeys, ['label'], ['asc'])}
          selectedCriteriaKey={this.state.selectedCriteriaKey}
          setCurrentCriteriaKey={this.updateSelectedCriteriaKey}
          currentIndicators={this.state.currentIndicators}
          updateCurrentIndicators={this.updateCurrentIndicators}
          thematic={this.state.thematic}
          updateSubThematic={this.updateSubThematic}
          orderBy={this.state.orderBy}
          setOrderBy={this.updateOrderBy}
          projectThematics={this.props.projectThematics}
          defaultThematic={this.props.thematic}
          projectConfiguration={this.props.projectConfiguration}
        />
        <GraphContainer
          id="graphContainer"
          filterKeys={this.props.filterKeys}
          thematic={this.state.thematic}
          selectedCriteriaKey={this.state.selectedCriteriaKey}
          badgeType={this.state.currentIndicators?.[INDICATORS_TYPE.BADGE].value}
          barType={this.state.currentIndicators?.[INDICATORS_TYPE.BAR].value}
          barIndicator={this.state.currentIndicators?.[INDICATORS_TYPE.BAR]}
          orderBy={this.state.orderBy}
          toggleDisplaySideCard={this.props.toggleDisplaySideCard}
          setCardTitle={this.props.setCardTitle}
          setCardFilters={this.props.setCardFilters}
          dropdownSettings={this.props.dropdownSettings}
        />
      </QcardPanel>
    );
  }
}

const mapStateToProps = state => ({
  criteriaKeys: get(state, ['filters', 'projectFilters', 'criteriaKeys', 'normal']),
  projectThematics: get(state, ['filters', 'projectFilters', 'thematics']),
  filterKeys: get(state, ['filters', 'filterKeys']),
  dropdownSettings:
    get(state, ['routes', 'pageConfiguration', 'settings']) &&
    JSON.parse(get(state, ['routes', 'pageConfiguration', 'settings'])),
});

export default connect(mapStateToProps)(withTranslation('card')(DataByFiltersCard));
