import gql from 'graphql-tag';
import FRAGMENTS from '../../../_Resources/Cards/Services/GraphQl/FRAGMENTS';

const query = gql`
  query getVerbatimListData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $dataCollectorId: Int
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $sort: Sort
    $selectedEmotions: [Emotions!]
    $selectedClusters: [Cluster!]
    $noThematic: Boolean
    $lastVerbatimId: Int
    $limit: Int
  ) {
    getVerbatimListData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      dataCollectorId: $dataCollectorId
      thematics: $thematics
      keywords: $keywords
      sort: $sort
      selectedEmotions: $selectedEmotions
      noThematic: $noThematic
      lastVerbatimId: $lastVerbatimId
      limit: $limit
      selectedClusters: $selectedClusters
    ) {
      verbatimList {
        ...VerbatimFragment
      }
      lastVerbatimId
      remainingVerbatim
    }
  }
  ${FRAGMENTS.verbatim}
`;

export default query;
