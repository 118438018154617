export const ADD_SECTION_FILTERS = 'REPORTING_ADD_SECTION_FILTERS';

export const FILTER_TYPES = {
  THEMATIC: 'thematic',
  NORMAL: 'normal',
};

export const addSectionFilters = (filterType, filterValues) => ({
  type: ADD_SECTION_FILTERS,
  filterType,
  filterValues,
});
