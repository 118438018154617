import gql from 'graphql-tag';

const mutation = gql`
  mutation createAlertConfiguration($projectId: Int!, $frequency: String!, $conditions: Json, $emails: [String!]!, $alertConfigurationName: String!) {
    createAlertConfiguration(projectId: $projectId, conditions: $conditions, emails: $emails, alertConfigurationName: $alertConfigurationName, frequency: $frequency) {
      active
      alertConfigurationId
      alertConfigurationName
      conditions
      emails
      errors
      projectId
      frequency
    }
  }
`;

export default mutation;
