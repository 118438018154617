import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import { get } from 'lodash';
import MutationSaveBenchmark from '_Resources/Benchmark/Services/MutationSaveBenchmark';
import { spacing } from 'styles/abstracts/variables';
import { grayShades, white } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QInput from '_Components/Fields/QInput/QInput';
import QModal from '_Components/Modals/QModal/QModal';
import QModalBody from '_Components/Modals/QModalBody/QModalBody';
import Icon from '_Components/Icons/Icon';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';

const StyledQModalBody = styled(QModalBody)`
  padding: 0 ${spacing.medium};
`;

const StyledQInput = styled(QInput)`
  width: 100%;
`;

const StyledTextArea = styled(QInput)`
  width: 100%;
  resize: none;
`;

const StyledLabel = styled(Label)`
  color: ${grayShades.g800};
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const Cancel = styled.p`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  cursor: pointer;
  font-weight: bold;
`;

const CreateButton = styled(QButton)`
  margin: 0 ${spacing.xsmall} 0 ${spacing.large} !important;
`;

const Description = styled(QTitle)`
  font-size: 1em;
  padding: 0;
  margin: 0;
`;

class InputNameModal extends React.Component {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    benchmark: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
        settings: PropTypes.array,
      }),
    ),
    footer: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    currentProject: PropTypes.shape.isRequired,
  };
  static defaultProps = {
    benchmark: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      benchmarkName: get(this.props.benchmark, 'title') || '',
      benchmarkDescription: get(this.props.benchmark, 'description') || '',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
  }
  onSubmit() {
    if (this.state.benchmarkName.length > 0) {
      this.props.toggle();
      this.setState({ benchmarkName: '' });
      this.setState({ benchmarkDescription: '' });
    }
  }

  onNameChange(e) {
    this.setState({ benchmarkName: e.target.value });
  }

  onDescriptionChange(e) {
    this.setState({ benchmarkDescription: e.target.value });
  }

  getAddFooter() {
    const { toggle, currentProject, t } = this.props;
    return (
      <Footer>
        <Cancel onClick={toggle}>{t('button:Cancel')}</Cancel>
        <MutationSaveBenchmark
          variables={{
            title: this.state.benchmarkName,
            description: this.state.benchmarkDescription,
            projectType: 'SINGLE_PROJECT',
            projectIds: currentProject && currentProject.id,
          }}
          callBackAction={this.onSubmit}
        >
          <CreateButton
            icon="CHECK_ALL"
            iconSize={20}
            iconPosition="left"
            disabled={!this.state.benchmarkName.length > 0}
          >
            &nbsp; {t('button:Create this benchmark')}
          </CreateButton>
        </MutationSaveBenchmark>
      </Footer>
    );
  }

  getRenameModalFooter() {
    const { t } = this.props;
    return (
      <Footer>
        <Cancel onClick={() => this.props.toggle()}>Cancel</Cancel>
        <MutationSaveBenchmark
          variables={{
            id: this.props.benchmark.id,
            title: this.state.benchmarkName,
            description: this.state.benchmarkDescription,
          }}
          callBackAction={this.onSubmit}
        >
          <CreateButton>{t('button:Edit this benchmark')}</CreateButton>
        </MutationSaveBenchmark>
      </Footer>
    );
  }

  initModalValues = {
    config: {
      date: false,
      mentions: false,
    },
  };

  render() {
    const propsModal = this.initModalValues;
    const { t } = this.props;
    return (
      <QModal
        footer={
          this.props.benchmark
            ? this.getRenameModalFooter()
            : this.getAddFooter()
        }
        isOpen={this.props.isOpen}
        title={t(this.props.title)}
        onClose={this.props.toggle}
        subtitle={t(this.props.subtitle)}
        {...propsModal}
      >
        <StyledQModalBody>
          {!this.props.benchmark && (
            <>
              <Description>
                {t('Name and describe your new benchmark')}
              </Description>
              <hr />
            </>
          )}
          <FormGroup>
            <StyledLabel>{t('Name')}</StyledLabel> <br />
            <StyledQInput
              primary
              maxLength={50}
              value={this.state.benchmarkName}
              onChange={this.onNameChange}
            />
          </FormGroup>
          <FormGroup>
            <StyledLabel>{t('Description (optional)')}</StyledLabel> <br />
            <StyledTextArea
              as="textarea"
              primary
              rows={2}
              value={this.state.benchmarkDescription}
              onChange={this.onDescriptionChange}
              maxLength={175}
            />
          </FormGroup>
        </StyledQModalBody>
      </QModal>
    );
  }
}

const mapStateToProps = state => ({
  benchmarkList: state.benchmark.projectBenchmarks,
  currentProject: state.projects.currentProject,
});

export default connect(mapStateToProps)(
  withTranslation('benchmark', 'button')(InputNameModal),
);
