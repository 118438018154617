import React from 'react';
import { Col } from 'reactstrap';
import {
  Section,
  SectionItem,
  SectionTitle,
  SectionTitleContainer,
} from '_Cards/SettingsCards/ProjectDetailsCard/_Components/GlobalInformations/styles';

const ProjectFiltersSection = ({ projectFilters, xs, t }) => (
  <Col xs={xs}>
    <Section>
      <SectionTitleContainer>
        <SectionTitle>{t('Project filters')}</SectionTitle>
      </SectionTitleContainer>
      {projectFilters?.map(filter => (
        <SectionItem key={filter}>
          <span>• {filter}</span>
        </SectionItem>
      ))}
    </Section>
  </Col>
);

export default ProjectFiltersSection;
