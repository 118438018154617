import gql from 'graphql-tag';

const resendOTP = gql`
  mutation resendOTP(
    $email: String!
    $password: String!
    $resendOtp: Boolean!
  ) {
    signIn(email: $email, password: $password, resendOtp: $resendOtp) {
      token
      exp
      user {
        otpEnabled
      }
    }
  }
`;

export default resendOTP;
