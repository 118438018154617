import React from 'react';
import img from '_Components/NoConfigPage/images/img-no-config@1x.png';
import img2x from '_Components/NoConfigPage/images/img-no-config@2x.png';
import ContactButtonPlaceholder from '_Components/NoConfigPage/_Components/ContactButtonPlaceholder';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const NoConfigMessageCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoConfigText = styled.h6`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
`;

const ImgNoConfig = styled.img`
  margin: 2rem;
`;

const NoConfigCTAMessage = ({ config, displayImage, t }) => (
  <Row>
    <NoConfigMessageCol>
      {displayImage && (
        <ImgNoConfig src={img} srcSet={`${img} 1x,${img2x} 2x`} />
      )}
      <NoConfigText
        dangerouslySetInnerHTML={{
          __html: t(config.message || 'DEFAULT_MAIN_MESSAGE'),
        }}
      />
      <ContactButtonPlaceholder />
    </NoConfigMessageCol>
  </Row>
);

NoConfigCTAMessage.propTypes = {
  t: PropTypes.func.isRequired,
  displayImage: PropTypes.node,
};

NoConfigCTAMessage.defaultProps = {
  displayImage: false,
};

export default withTranslation('placeholderPages')(NoConfigCTAMessage);
