export const CHANGE_PAGE_BY_SHORTCUT = 'REPORTING_CHANGE_PAGE_BY_SHORTCUT';

export const changePageByShortcut = ({
  size,
  pageIndex,
  children,
  cssGridLayout,
}) => ({
  type: CHANGE_PAGE_BY_SHORTCUT,
  size,
  children,
  cssGridLayout,
  pageIndex,
});
