import React from 'react';
import CMixBarGraphChart from '../../../_Charts/MixedCharts/CMixBarGraphChart/CMixBarGraphChart';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import CQuery from '../../../_Container/QQuery/CQuery';
import querySchema from './graphql/getMentionRepartitionPerSourceCardData';

const MentionRepartitionInTimeScopeCard = props => (
  <QcardPanel
    {...props}
    hints={[HintsContent.HINT_MENTION_REPARTITION_PER_SOURCE]}
  >
    <CQuery query={querySchema}>
      <CMixBarGraphChart />
    </CQuery>
  </QcardPanel>
);

export default MentionRepartitionInTimeScopeCard;
