import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { black, opacity, purple, green } from 'styles/abstracts/colors';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import QButton from '_Components/Buttons/QButton/QButton';
import { ImageSize } from 'styles/abstracts/variables';
import { setCurrentProject } from '_Resources/Header/Actions/setProject';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { setCurrentPeriod } from '_Resources/Global/Actions/setCurrentPeriod';
import ActiveProjectRowSettings from './_Components/ActiveProjectRowSettings';
import DeleteProjectModal from './_Components/Modals/DeleteProjectModal';
import UpdateAllProjectsModal from './_Components/Modals/UpdateAllProjectsModal';
import UnarchiveProjectButton from './_Components/UnarchiveProjectButton';
import AlreadyProcessedModal from './_Components/Modals/AlreadyProcessedModal';

const StyledCell = styled.td`
  padding: ${({ eindex, settings }) =>
    eindex ? '1em' : settings ? '0.75em' : '1.5em'} !important;
  padding-left: ${({ eindex }) => eindex && '2em'} !important;
  color: ${black};
  min-width: ${({ settings, archived }) =>
    settings && (archived ? '20em' : '9.5em')};
  display: ${({ settings }) => settings && 'inline-flex'} !important;
  justify-content: ${({ settings }) => settings && 'center'} !important;
  & span {
    display: ${({ settings }) => settings && 'inline-flex'} !important;
    justify-content: ${({ settings }) =>
      settings && 'space-between'} !important;
  }
`;

const SettingsContainer = styled.span`
  & button:last-child {
    margin-left: 1em;
  }
`;

const ProjectName = styled.b`
  cursor: ${({ accessible }) => accessible && 'pointer'};
`;

const ButtonsContainer = styled.span`
  display: inline-flex;
  align-items: center;
`;

const StyledButton = styled(QButton)`
  margin-right: ${({ first }) => first && '0.5em'};
  visibility: ${({ isRowHovered, isProjectDemo }) =>
    (!isRowHovered || isProjectDemo) && 'hidden'};
`;

const ProjectRow = ({
  project,
  archived,
  t,
  index,
  history,
  setCurrentProject,
  setGlobalQueryFilterKeys,
  setCurrentPeriod,
  filterKeys,
  currentPlan,
}) => {
  const [isRowHovered, hover] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [isUpdateModalOpen, openUpdateModal] = useState(false);
  const [isAlreadyProcessedModalOpen, openAlreadyProcessedModal] = useState(
    false,
  );
  const [isTooltipOpen, openTooltip] = useState(false);

  const goToAddData = () => {
    if (currentPlan === 'emotion_corporate') {
      setCurrentProject({
        id: project.id,
        name: project.name,
      });
      setGlobalQueryFilterKeys({
        ...filterKeys,
        projectId: project.id,
        range: project.period,
      });
      setCurrentPeriod(project.period);
      history.push(`/add-data`);
    } else {
      history.push(`/project-details/${project.slug}`, {
        id: project.id,
      });
      window.localStorage.setItem(
        'projectDetailsTab',
        JSON.stringify({ slug: project.slug, tabId: 'sources' }),
      );
    }
  };

  const activeProjectSettings = (
    <ButtonsContainer>
      {
        <StyledButton
          hasOpacity
          bgColor="purple"
          icon="INTERFACE_PLUS_CIRCLE"
          iconSize={ImageSize.medium}
          onClick={() => goToAddData()}
          isRowHovered={isRowHovered}
          isProjectDemo={project.demo}
          first
        />
      }
      {currentPlan !== 'emotion_corporate' && (
        <StyledButton
          isRowHovered={isRowHovered}
          isProjectDemo={project.demo}
          iconSize={ImageSize.medium}
          icon="INTERFACE_REFRESH"
          bgColor="purple"
          hasOpacity
          onClick={() =>
            moment().diff(moment(project.updatedAt), 'days', true) > 1
              ? openUpdateModal(!isUpdateModalOpen)
              : openAlreadyProcessedModal(!isAlreadyProcessedModalOpen)
          }
        />
      )}
      <UpdateAllProjectsModal
        isOpen={isUpdateModalOpen}
        open={() => openUpdateModal(!isUpdateModalOpen)}
        project={project}
      />
      <AlreadyProcessedModal
        isOpen={isAlreadyProcessedModalOpen}
        open={() => openAlreadyProcessedModal(!isAlreadyProcessedModalOpen)}
      />
      <ActiveProjectRowSettings
        project={project}
        history={history}
        goToAddData={goToAddData}
        t={t}
        index={index}
        currentPlan={currentPlan}
      />
    </ButtonsContainer>
  );
  const archivedProjectSettings = (
    <SettingsContainer>
      <UnarchiveProjectButton projectId={project.id} t={t} />
      <QButton
        bgColor="red"
        hasOpacity
        icon="TRASH"
        iconPosition="left"
        onClick={() => openDeleteModal(!isDeleteModalOpen)}
      >
        {' '}
        &nbsp;{t('Delete')}
      </QButton>
      <DeleteProjectModal
        isOpen={isDeleteModalOpen}
        open={openDeleteModal}
        project={project}
      />
    </SettingsContainer>
  );

  return (
    <>
      <tr onMouseEnter={() => hover(true)} onMouseLeave={() => hover(false)}>
        <StyledCell>
          <ProjectName
            accessible={project.state === 'accessible'}
            onClick={() => {
              project.state === 'accessible' &&
                setCurrentProject({
                  id: project.id,
                  name: project.name,
                });
              project.state === 'accessible' &&
                setGlobalQueryFilterKeys({
                  ...filterKeys,
                  projectId: project.id,
                  range: project.period,
                });
              project.state === 'accessible' &&
                setCurrentPeriod(project.period);
              project.state === 'accessible' && history.push(`/global-results`);
            }}
          >
            {project.name}
          </ProjectName>
        </StyledCell>
        {project.state === 'accessible' ? (
          project.eindex.value || project.eindex.value === 0 ? (
            <>
              <StyledCell>{project.mentionCount}</StyledCell>
              <StyledCell eindex>
                <EindexRond eindex={project.eindex.value} type="small" />
              </StyledCell>
              <StyledCell>
                {t('card:From')} {moment(project.period.startDate).format('L')}{' '}
                {t('card:to')} {moment(project.period.endDate).format('L')}
              </StyledCell>
              <StyledCell>
                <span id={`projectUpdatedAt-${index}`}>
                  {moment(project.updatedAt).fromNow()}
                </span>
              </StyledCell>
              <Tooltip
                isOpen={isTooltipOpen}
                toggle={() => openTooltip(!isTooltipOpen)}
                target={`projectUpdatedAt-${index}`}
              >
                {moment(project.updatedAt).format('lll')}
              </Tooltip>
              <StyledCell settings archived={archived}>
                {archived ? archivedProjectSettings : activeProjectSettings}
              </StyledCell>
            </>
          ) : (
            <>
              <StyledCell
                colSpan={4}
                style={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                <p>
                  {t(
                    'card:There is no data on this project. Please check its configuration.',
                  )}
                </p>
              </StyledCell>
              <StyledCell settings archived={archived}>
                {archived ? archivedProjectSettings : activeProjectSettings}
              </StyledCell>
            </>
          )
        ) : (
          <StyledCell
            colSpan={5}
            style={{ textAlign: 'center', fontWeight: 'bold' }}
          >
            <p>
              {t(
                'card:This project is currently processing. Please come back later.',
              )}
            </p>
          </StyledCell>
        )}
      </tr>
    </>
  );
};

function mapStateToProps(state) {
  return {
    filterKeys: get(state, ['filters', 'filterKeys']),
    currentPlan: get(state, ['auth', 'user', 'account', 'planName']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentProject: newState => {
      dispatch(setCurrentProject(newState));
    },
    setCurrentPeriod: newState => {
      dispatch(setCurrentPeriod(newState));
    },
    setGlobalQueryFilterKeys: newState => {
      dispatch(setGlobalQueryFilterKeys(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('button', 'card')(ProjectRow));
