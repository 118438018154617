import React from 'react';
import ReportingVerbatimList from '_Cards/VerbatimCards/ReportingBestWorstVerbatimCard/_Components/ReportingVerbatimList';
import { SatisfactionNpsSectionView } from '_Cards/SatisfactionCards/SatisfactionNpsCard/SatisfactionNpsSection';
import EindexBadgeSection, { EindexTransformData } from '_Cards/EIndexCards/EindexBadgeCard/EindexBadgeSection';

import EmotionEvolutionLineSection from '_Cards/EmotionsCards/EmotionEvolutionLineCard/EmotionEvolutionLineSection';
import EindexEvolutionWithMentionsSection from '_Cards/EIndexCards/EindexEvolutionWithMentionsCard/EindexEvolutionWithMentionsSection';
import CardSection from '../../../../Routes/GlobalExperience/Reporting/_Components/CardSection';
import Rond from '../../../../_Charts/KpisCharts/KpiBadges/Rond';
import data, {
  wordCloud,
  eindexEvolution,
  emotionBrakeDownEvolution,
  verbatimBestWorstAndRandom,
  categoriesBestAndPainPoints,
  eindexBarGraph,
} from './demoData';
import CDonutChart from '../../../../_Charts/KpisCharts/CDonutChart/CDonutChart';
import CGaugeChart from '../../../../_Charts/KpisCharts/CGaugeChart/CGaugeChart';
import CSpeechEngagementChart from '../../../../_Charts/KpisCharts/CSpeechEngagment/CSpeechEngagementChart';
import LayoutSection from '../../../../Routes/GlobalExperience/Reporting/_Components/LayoutSection';
import ChartThermometer from '../../../../_Charts/KpisCharts/ChartThermometer/ChartThermometer';
import CategoryGlobalBestAndPaintPointCard from '../../../../_Cards/ThematicsCard/CategoryGlobalBestAndPainPointsCard/CategoryGlobalBestAndPainPointsCard';
import CWordCloudGraph from '../../../../_Charts/WordCharts/CWordCloudChart/CWordCloudGraph';
import { EindexBarGraphContainer } from '../../../../_Cards/EIndexCards/EindexBarGraphCard/EindexBarGraphSection';
import ReportingCLineSettings from './_Containers/ReportingCLineSettings';

const eIndexData = {
  unit: {
    min: -20,
    max: 40,
    unit: '°',
  },
  value: 30,
  verbatimsPerKpi: null,
  yearBefore: {
    value: 25,
    delta: 5,
    period: {
      startDate: '2023-04-01T00:00:00+00:00',
      endDate: '2023-04-30T23:59:59+00:00',
    },
  },
  periodBefore: {
    value: 32,
    delta: -2,
    period: {
      startDate: '2024-03-01T00:00:00+00:00',
      endDate: '2024-03-31T23:59:59+00:00',
    },
  },
  __typename: 'KpiResults',
};

export default {
  eindex: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ width: '60%' }}>
            <ChartThermometer
              eindex={30}
              reportingAnalysisAxis={section.settings?.analysisAxis}
              emotions={{
                happiness: 170,
                surprise: 20,
                calm: 50,
                fear: 5,
                sadness: 90,
                anger: 50,
                disgust: 40,
              }}
            />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => <ReportingCLineSettings type="eindex" />,
  },
  eindexBadge: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <EindexTransformData
            data={{ eindex: eIndexData }}
            badgeShow={section.settings?.badgeShow}
            section={section}
            id={id}
          />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => <ReportingCLineSettings type="eindexBadge" />,
  },
  mention: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <Rond data={{ mentionCount: { value: data.nb_mentions } }} type="medium" />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => null,
  },
  emotionsBreakdown: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ width: '100%', height: '100%' }}>
            <CDonutChart
              data={data}
              numberOfMentions={data.nb_mentions}
              reportingMode
              settings={section?.settings}
              reportingAnalysisAxis={section.settings?.analysisAxis}
            />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => <ReportingCLineSettings type="emotionsBreakdown" />,
  },
  emotionalIntensity: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ width: '60%', height: '60%' }}>
            <CGaugeChart value={74} />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => null,
  },
  speechEngagement: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ width: '60%', height: '60%' }}>
            <CSpeechEngagementChart value={69} />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => null,
  },
  eindexEvolution: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <EindexEvolutionWithMentionsSection settings={section.settings} hideRangeButtons mockData={eindexEvolution} />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => <ReportingCLineSettings type="eindexEvolution" />,
  },
  emotionsEvolution: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ width: '100%', height: '100%' }}>
            <EmotionEvolutionLineSection
              settings={section.settings}
              range={section.settings?.range}
              mockData={emotionBrakeDownEvolution}
              reportingAnalysisAxis={section.settings?.analysisAxis}
            />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => <ReportingCLineSettings type="emotionsEvolution" />,
  },
  bestAndWorstCategories: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <CategoryGlobalBestAndPaintPointCard
            data={categoriesBestAndPainPoints}
            filterKeys={{ projectId: 1 }}
            reportingMode
          />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => <ReportingCLineSettings type="bestAndWorstCategories" />,
  },
  satisfaction: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <SatisfactionNpsSectionView
            data={{
              satisfaction: data.satisfaction,
              netPromoterScore: data.netPromoterScore,
            }}
            classNameCssBottom="mt-2"
            classNameCol={12}
          />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => null,
  },
  bestVerbatim: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ height: '100%' }} className="reportingVerbatimContainer">
            <ReportingVerbatimList data={{ bestVerbatim: verbatimBestWorstAndRandom.bestVerbatim }} />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => null,
  },
  worstVerbatim: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ height: '100%' }} className="reportingVerbatimContainer">
            <ReportingVerbatimList data={{ worstVerbatim: verbatimBestWorstAndRandom.worstVerbatim }} />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => null,
  },
  wordCloud: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <CWordCloudGraph data={wordCloud} height="100%" />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => null,
  },
  eindexBarGraph: {
    factory: (id, section) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <EindexBarGraphContainer data={eindexBarGraph} height="100%" />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
    settingsFactory: () => null,
  },
};
