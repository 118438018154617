import React, { useState, useEffect } from 'react';
import { clone } from 'lodash';
import styled from 'styled-components';
import QTitle from '_Components/Texts/QTitle/QTitle';
import DateSettings from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/DataConfiguration/_Components/DateSettings';
import { grayShades } from 'styles/abstracts/colors';

const Container = styled.div`
  border-top: 1px solid ${grayShades.g300};
  margin: 0 -1em;
  padding: 1em;
`;

const DataContentSettings = ({ settings, setSettings, t }) => {
  const [preferDate, setPreferDate] = useState(
    settings.dataConfiguration.preferDate,
  );

  useEffect(() => {
    const settingsToSend = {
      test: preferDate,
    };
    const newSettings = clone(settings);

    settings.dataConfiguration = settingsToSend;

    setSettings(newSettings);
  }, [preferDate]);
  return (
    <Container>
      <QTitle>{t('Data content settings')}</QTitle>
      <DateSettings preferDate={preferDate} setPreferDate={setPreferDate} />
    </Container>
  );
};
export default DataContentSettings;
