import gql from 'graphql-tag';
import FRAGMENTS from '_Resources/Cards/Services/GraphQl/FRAGMENTS';

const query = gql`
  query getEmotionEvolutionLineCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $dateType: DateInterval!
  ) {
    getEmotionEvolutionLineCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dateType: $dateType
    ) {
      emotionEvolution {
        date
        emotions {
          ...EmotionsBreakdownFragment
        }
        sentiments {
          ...SentimentBreakdownFragment
        }
      }
    }
  }
  ${FRAGMENTS.emotionsBreakdown}
  ${FRAGMENTS.sentimentsBreakdown}
`;
export default query;
