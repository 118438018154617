import React from 'react';
import styled from 'styled-components';
import { BasePropTypes } from '../../../../_Utils/common.propTypes';

export const Section = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

// TODO: feature - align-items: safe center;
export const ChartBox = styled.div`
  display: flex;

  align-items: start;
  justify-content: center;
  //height: 90%;
  height: 100%;
  width: 100%;

  & > * {
    margin: auto 0;
  }
`;

const CardSection = ({ children, id }) => (
  <Section id={id}>
    <ChartBox>{children}</ChartBox>
  </Section>
);

CardSection.propTypes = {
  ...BasePropTypes,
};

export default CardSection;
