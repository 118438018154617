import React, { useState } from 'react';
import styled from 'styled-components';
import { red, white, purple } from 'styles/abstracts/colors';
import VideoModal from './VideoModal/VideoModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1.5em;
  margin-left: 1.5em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
`;

const VideoBlockContainer = styled.div`
  height: 7em;
  width: 12em;
  border-radius: 25px;
  background-color: ${({ thumbnail }) => !thumbnail && red};
  color: ${white};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-image: ${({ thumbnail }) => thumbnail && `url(${thumbnail})`};
  background-size: cover;
  background-position: center;

  &:before {
    transition: background 0.3s;
    content: '';
  }

  &:hover {

    &:before {
      content: '${({ title }) => title}';
      background-color: rgba(255, 255, 255, 0.95);
      color: ${purple};
      height: 100%;
      width: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
`;

const VideoBlockContent = styled.div``;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 50px solid ${white};
  border-bottom: 25px solid transparent;
  margin: auto;
`;

const VideoName = styled.p`
  width: 12em;
  margin-top: 0.5em;
  text-align: center;
  font-weight: bold;
`;

const VideoBlock = ({ data }) => {
  const [isModalOpen, toggleModal] = useState(false);

  return (
    <Container>
      <VideoBlockContainer
        onClick={() => toggleModal(true)}
        thumbnail={data.thumbnail}
        title={data.name}
      >
        {!data.thumbnail && (
          <VideoBlockContent>
            <Triangle />
          </VideoBlockContent>
        )}
      </VideoBlockContainer>
      <VideoModal data={data} isOpen={isModalOpen} toggle={toggleModal} />
    </Container>
  );
};

export default VideoBlock;
