export const getOptionPath = ({ parents, option }) =>
  [...parents, option.id].join('-');

export const removeOptions = (optionsToRemove, selected, parentKey) => {
  const removedPaths = optionsToRemove.map(getOptionPath);
  const result = selected.filter(optionWithParents => {
    const path = getOptionPath(optionWithParents);
    return !removedPaths.includes(path);
  });

  if (result.length !== selected.length) {
    return result;
  }

  return selected.filter(({ parents, option: { id } }) => {
    const path = [...parents, id].join('.');
    return parentKey !== path;
  });
};
