import React from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import QButton from '_Components/Buttons/QButton/QButton';
import { grayShades } from 'styles/abstracts/colors';
import queryProjectDetails from 'Routes/Settings/ProjectDetails/graphql/getProjectDetails';
import mutation from '_Cards/SettingsCards/ProjectDetailsCard/graphql/updateProjectConfigurationSettings';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-radius: 1px solid ${grayShades.g300};
`;

const AutomationFooter = ({ settings, projectId, slug, t, setError }) => {
  const [updateProjectConfigurationSettings] = useMutation(mutation, {
    update: (cache, { data: { updateProjectConfigurationSettings } }) => {
      const projectDetailsData = cache.readQuery({
        query: queryProjectDetails,
        variables: {
          id: projectId,
          slug,
        },
      }).getProjectDetails;
      const { automation } = updateProjectConfigurationSettings;
      projectDetailsData.automation = automation;
      cache.writeQuery({
        query: queryProjectDetails,
        variables: {
          id: projectId,
          slug,
        },
        data: {
          data: projectDetailsData,
        },
      });
    },
  });

  return (
    <Container>
      <QButton
        bgColor="green"
        hasOpacity
        onClick={() =>
          updateProjectConfigurationSettings({
            variables: {
              projectId,
              settings: JSON.stringify(settings),
            },
          }).catch(err => {
            setError(true);
          })
        }
      >
        {t('button:Save')}
      </QButton>
    </Container>
  );
};

export default AutomationFooter;
