import React from 'react';
import { Col, Row } from 'reactstrap';
import '_Cards/ThematicsCard/CategoryGlobalBestAndPainPointsCard/CategoryWithLeftEindex/style.scss';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import { isEmpty } from 'lodash';

class CategoryWithLeftEindex extends React.Component {
  render() {
    const offset = this.props.index % 2 === 0 ? 0 : 1;
    const { reportingMode } = this.props;
    const smallFirst = reportingMode ? { size: 3, offset } : { size: 10, offset: 1 };
    const midLargeFirst = reportingMode ? { size: 3, offset } : { size: 4, offset: 1 };
    const smallSecond = reportingMode ? { size: 8, offset: 0 } : { size: 9, offset: 2 };
    const midLargeSecond = reportingMode ? { size: 8, offset: 0 } : { size: 6, offset: 0 };
    return !isEmpty(this.props) ? (
      <Row>
        <Col
          xs={smallFirst}
          sm={smallFirst}
          md={midLargeFirst}
          lg={midLargeFirst}
          xl={midLargeFirst}
          className="d-flex justify-content-center align-self-center"
        >
          <EindexRond
            eindex={this.props.value}
            type="medium"
          />
        </Col>
        <Col
          xs={smallSecond}
          sm={smallSecond}
          md={midLargeSecond}
          lg={midLargeSecond}
          xl={midLargeSecond}
          className="containCategoryDetails p-1 d-flex align-content-center justify-content-center flex-wrap"
        >
          <p className="categoryHierarchy">{this.props.categoryHierarchy}</p>
          <p className="categoryName font-weight-bold">
            {this.props.categoryName}
          </p>

          <p className="categoryNumberOfMentions font-weight-bold">
            {`${this.props.mentionCount} ${
              this.props.mentionCount > 1 ? 'mentions' : 'mention'
            }`}
          </p>
        </Col>
      </Row>
    ) : null;
  }
}

export default CategoryWithLeftEindex;
