import {
  CONTEXT,
  STORE_TO_WATCH,
  TEMPORARY_STORE_TO_WATCH,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import produce from 'immer';

const changeRange = (currentSettings, rangeValues, type) =>
  produce(currentSettings, newState => {
    newState[type].dateRange = rangeValues;
  });

export const replaceRange = (
  state,
  { context, rangeValues, mergedValues, itemIndex },
) => {
  switch (context) {
    case CONTEXT.GLOBAL: {
      return produce(state, newState => {
        newState.activeBenchmark.settings.globalSettings.dateRange = {
          ...rangeValues,
        };
      });
    }
    case CONTEXT.COLUMN:
    case CONTEXT.CARD: {
      const isDefinedIndex = Number.isInteger(itemIndex);
      const storeToWatch = isDefinedIndex
        ? [STORE_TO_WATCH[context]]
        : TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField;
      const currentSettings = isDefinedIndex
        ? state.activeBenchmark.settings[storeToWatch][itemIndex]
        : state[storeToWatch];
      if (!currentSettings) return { ...state };
      const newSettings = changeRange(
        changeRange(currentSettings, rangeValues, 'settings'),
        mergedValues,
        'mergedSettings',
      );
      if (isDefinedIndex) {
        return produce(state, newState => {
          newState.activeBenchmark.settings[storeToWatch][
            itemIndex
          ] = newSettings;
        });
      }
      return produce(state, newState => {
        newState[storeToWatch] = newSettings;
      });
    }
    default: {
      return { ...state };
    }
  }
};
