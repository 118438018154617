import { type as rType } from "ramda";
import { transform as emotionTransform } from "./transformations/emotionTransformation";
import { transform as satisfactionTransform } from "./transformations/satisfactionTransformation";

export const TRANSFORMATION_IS_NOT_A_FUNCTION =
  "transformations is not a function";

export class TransformationsRegister {
  transformations = new Map();

  getTransformations(type) {
    return this.transformations.get(type);
  }

  register(type, transformation) {
    if (rType(transformation) !== "Function") {
      throw new Error(TRANSFORMATION_IS_NOT_A_FUNCTION);
    }

    this._addTransformation(type, transformation);
  }

  unregister(type) {
    this.transformations.delete(type);
  }

  _addTransformation(type, transformation) {
    const transformations = this.transformations.get(type) || [];
    const newTransformations = [...transformations, transformation];
    this.transformations.set(type, newTransformations);
  }
}

export const register = new TransformationsRegister();

register.register("eindex", emotionTransform);
register.register("sat", satisfactionTransform);
register.register("reco", satisfactionTransform);
register.register("effort", satisfactionTransform);
