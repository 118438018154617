import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getBenchmarkList } from '../../../_Resources/Benchmark/Actions/getBenchmarkList';
import querySchema from './graphql/getBenchmarkList';
import { DispatchDataToStore } from '../../Header/Services/api/DispatchDataToStore';
import _ from 'lodash';
import MOCK_FILTERKEYS from './MOCK_FILTERKEYS.json';
import { transformItemFiltersToSettings } from '_Providers/QFilterOptionsProvider/optionProviderFunctions';

export const SETTINGS_TYPES = {
  CARDS: 'cards',
  COLUMNS: 'columns',
};

class QueryBenchmarkList extends React.Component {
  static propTypes = {
    currentProjectId: PropTypes.number.isRequired,
    dispatchGetBenchmarkList: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.projectThematics !== this.props.projectThematics) {
      return true;
    }

    return false;
  }

  prepareBenchmarks(benchmarks) {
    const { projectThematics, t } = this.props;
    if (!projectThematics) return;

    const result = _.cloneDeep(benchmarks).map(benchmark => {
      const settings = typeof benchmark.settings === 'string' ? JSON.parse(benchmark.settings) : _.cloneDeep(benchmark.settings);

      const isNewSchema =
        !_.isEmpty(benchmark.settings) &&
        JSON.stringify(benchmark.settings).search('criteriaKeys') > -1;

      const settingsTypes = Object.keys(SETTINGS_TYPES);
      const benchmarkSettings = { ...benchmark, settings };
      if (isNewSchema && !_.isEmpty(settings)) {
        settingsTypes.forEach(settingsType => {
          const CONFIG = SETTINGS_TYPES[settingsType];
          const arrayToTransform = settings[CONFIG];
          const resultArray = arrayToTransform?.map(setting => ({
            title: setting.title,
            settings: transformItemFiltersToSettings(
              setting.settings,
              projectThematics,
            ),
            mergedSettings: transformItemFiltersToSettings(
              setting.mergedSettings,
              projectThematics,
            ),
          }));
          benchmarkSettings.settings[CONFIG] = resultArray;
        });
        benchmarkSettings.settings.globalSettings = transformItemFiltersToSettings(
          settings.globalSettings,
          projectThematics,
          true,
          t,
        );
      }
      benchmarkSettings.settings = benchmarkSettings.settings
        ? JSON.stringify(benchmarkSettings.settings)
        : null;
      return benchmarkSettings;
    });
    return result;
  }

  render() {
    return (this.props.projectThematics && this.props.currentProjectId) ? (
      <Query
        query={querySchema}
        variables={{
          projectId: this.props.currentProjectId,
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch, networkStatus }) => {
          if (loading || networkStatus === 4) return false;
          if (error) {
            return false;
          }
          const cloneData = data;
          const benchmarks = cloneData.getBenchmarkList.projectBenchmarks;
          const finalShape = {
            getBenchmarkList: {
              projectBenchmarks: this.prepareBenchmarks(benchmarks),
            },
          };
          return (
            <DispatchDataToStore
              data={data}
              action={() => this.props.dispatchGetBenchmarkList(finalShape)}
            />
          );
        }}
      </Query>
    ) : null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetBenchmarkList: data => {
      dispatch(getBenchmarkList(data));
    },
  };
}

function mapStateToProps(state) {
  return {
    currentProjectId: _.get(state, ['projects', 'currentProject', 'id']),
    projectThematics: _.get(state, ['filters', 'projectFilters', 'thematics']),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('filters')(QueryBenchmarkList));
