import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import SectionHeader from './SectionHeader';
import { prepareGenerateSubtitles } from '../_Containers/helpers/subtitleHelper';
import { connect } from 'react-redux';
import _ from 'lodash';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LayoutSection = ({ section, children, t, lang }) => {
  const { title } = section;
  const subtitles = prepareGenerateSubtitles(t, lang)({
    ...section,
  });
  return (
    <Body>
      <SectionHeader title={title} subtitles={subtitles} />
      {children}
    </Body>
  );
};

LayoutSection.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  lang: _.get(state, ['auth', 'user', 'lang']),
});

export default connect(mapStateToProps)(withTranslation('reporting')(LayoutSection));
