import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { orange, white, green } from 'styles/abstracts/colors';
import query from '../graphql/emailValidationSendEmail';

const Container = styled.div`
  background-color: ${orange};
  color: ${white};
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 0.5em;
`;

const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const ToastContainer = styled.div`
  border-radius: 25em;
  background-color: ${green};
  color: ${white};
  font-weight: bold;
  padding: 1em;
`;

const Toast = ({ children }) => {
  return ReactDOM.createPortal(
    <ToastContainer>{children}</ToastContainer>,
    document.getElementById('toasts'),
  );
};

const ConfirmEmailStrip = ({ client, t }) => {
  const [message, setMessage] = useState('');
  return (
    <Container>
      {t(
        'Please confirm your email address to have full access to the platform.',
      )}{' '}
      <Link
        onClick={async () => {
          const {
            data: { emailValidationSendEmail },
          } = await client.query({ query });
          if (emailValidationSendEmail) {
            setMessage(emailValidationSendEmail.message);
            setTimeout(() => {
              setMessage('');
            }, 5000);
          }
        }}
      >
        {t('Send again')}
        {message && <Toast>{message}</Toast>}
      </Link>
    </Container>
  );
};

export default withApollo(withTranslation('card')(ConfirmEmailStrip));
