import { cloneDeep, isEmpty } from 'lodash';
import WorldMapViz from '_Components/WorldMap/containers/WorldMapViz';
import React from 'react';
import { convertCelsiusToFahrenheit } from '_Utils/eindex/eindexUnits';

class CWorldMapVizTransformData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataToShow: [],
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.updateMarkers();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.updateMarkers();
    }
  }

  updateMarkers = () => {
    const { data, activeMarker, setKeys } = this.props;
    const dataMarkers = cloneDeep(data.markers);
    this.setState({
      dataToShow: this.convertTemperatureData(dataMarkers),
    });
    if (activeMarker) {
      const newActiveMarkerData = data.markers.find(
        ({ id: currentId }) => currentId === activeMarker.id,
      );
      if (newActiveMarkerData) {
        this.onMarkerSelected(activeMarker.id);
      } else {
        setKeys(null);
      }
    }
  };

  getAvailableModes = indicators => {
    const hiddenModes = {};
    Object.keys(indicators).forEach(mode => {
      if (!indicators[mode]) {
        hiddenModes[mode] = null;
      }
    });
    return hiddenModes;
  };

  onMarkerSelected = id => {
    const responseClone = cloneDeep(this.props.data.markers);
    const location = responseClone.find(
      ({ id: currentId }) => currentId === id,
    );
    if (!isEmpty(location.filters.normal)) {
      location.filters.normal[0].name = location.filters.normal[0].label;
      delete location.filters.normal[0].label;
      this.props.setKeys(location);
    } else {
      this.props.setKeys(location);
    }
  };

  convertTemperatureData = data => {
    if (this.props.fahrenheit) {
      return data.map(dataToConvert => {
        dataToConvert.results.eindex = convertCelsiusToFahrenheit(
          parseInt(dataToConvert.results.eindex),
        );
        return dataToConvert;
      });
    }
    return data;
  };

  render() {
    const { config, mapOptions, fahrenheit } = this.props;

    return (
      <WorldMapViz
        config={config}
        locations={this.state.dataToShow}
        mapOptions={mapOptions}
        onMarkerSelected={this.onMarkerSelected}
        modes={this.getAvailableModes(this.props.data.indicators)}
        fahrenheit={fahrenheit}
      />
    );
  }
}

export default CWorldMapVizTransformData;
