import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { withTranslation } from 'react-i18next';
import { NestedInputTags } from '@qemotion_team/react-nested-input-tags';
import { prepareGroupedToOptions } from '_Container/QProjectFilters/Filters/QThematicFilter/groupedToOptions';
import QTitle from '../../../../../../../../_Components/Texts/QTitle/QTitle';
import MergeMessages from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/MergeMessages';
import { FILTER_DIMENSIONS } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';

class ThematicsFilter extends Component {
  static propTypes = {
    values: PropTypes.arrayOf(PropTypes.any).isRequired,
    thematics: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    onRemoved: PropTypes.func.isRequired,
  };

  groupedToOptions = null;

  constructor({ t }) {
    super();
    const groupedToOptions = prepareGroupedToOptions(t);
    this.groupedToOptions = memoizeOne(thematics =>
      groupedToOptions(thematics),
    );
  }

  render() {
    const { t, thematics, onRemoved, onSelected, initialValues } = this.props;
    return (
      <div>
        <QTitle>
          <QTitle.Title>{t('Thematics')}</QTitle.Title>
        </QTitle>
        <NestedInputTags
          initialValues={initialValues}
          categoryPrefix={t('in')}
          options={this.groupedToOptions(thematics)}
          onSelected={onSelected}
          onRemoved={onRemoved}
        />
        <MergeMessages
          context={this.props.context}
          type={FILTER_DIMENSIONS.THEMATIC}
        />
      </div>
    );
  }
}

export default withTranslation('reporting')(ThematicsFilter);
