import React, { Component } from 'react';
import { flatten } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QPanel from '../../../../../../../_Components/Panels/QPanel/QPanel';
import InsightModeMenuBody from './_Components/InsightModeMenuBody';
import QAccordionPanel from '../../../../../../../_Components/Panels/QAccordion/QAccordionPanel';
import QPanelHeader from '../../../../../../../_Components/Panels/QPanelHeader/QPanelHeader';

function getOptionsFromItems(items) {
  const options = items.map(({ options: opts }) => opts);
  return flatten(options);
}

function findSelectedOption(option, options) {
  return options.find(({ id }) => id === option);
}

class InsightModeMenu extends Component {
  state = { selectedOption: null, open: false };

  componentDidMount() {
    this.initializeSelectedOption();
  }

  onClick = (id, option) => {
    const {
      config: { onSelect },
    } = this.props;

    this.setState({ selectedOption: option });
    onSelect(id);
  };

  onToggle = ({ open }) => {
    this.setState({
      open,
    });
  };

  initializeSelectedOption() {
    const {
      config: { items },
      selected,
    } = this.props;

    const options = getOptionsFromItems(items);
    this.setState({ selectedOption: findSelectedOption(selected, options) });
  }

  render() {
    const {
      config: { items, title },
      className,
      selected,
      t,
    } = this.props;

    return (
      <QPanel className={className}>
        <QAccordionPanel title={title} onToggle={this.onToggle}>
          <InsightModeMenuBody
            items={items}
            onClick={this.onClick}
            selected={selected}
            sat={this.props.sat}
            reco={this.props.reco}
            ces={this.props.ces}
          />
        </QAccordionPanel>

        {!this.state.open && (
          <QPanelHeader type="menu" secondary>
            {this.state.selectedOption && t(this.state.selectedOption.title)}
          </QPanelHeader>
        )}
      </QPanel>
    );
  }
}
export default withTranslation('card')(InsightModeMenu);

InsightModeMenu.propTypes = {
  className: PropTypes.string,
};

InsightModeMenu.defaultProps = {
  className: '',
};
