import PropTypes from 'prop-types';
import React from 'react';
import CModal from '../../CModal/CModal';
import {
  BasePropTypes,
  TemplatePropTypes,
} from '../../../_Utils/common.propTypes';

const QModal = ({
  children,
  className,
  title,
  isOpen,
  onClose,
  subtitle,
  footer,
  config,
  hideFooter,
  data,
  size,
  buttonRight,
}) => {
  const { mentions, date } = config;
  return (
    <CModal
      className={className}
      data={data}
      title={title}
      displayMentionsSubtitle={mentions}
      displayDateSubtitle={date}
      subtitleAdditionalContent={subtitle}
      footer={footer}
      modal={isOpen}
      toggle={onClose}
      hideFooter={hideFooter}
      size={size}
      buttonRight={buttonRight}
    >
      {children}
    </CModal>
  );
};

QModal.propTypes = {
  ...BasePropTypes,

  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,

  subtitle: PropTypes.string,
  footer: TemplatePropTypes,
  config: PropTypes.shape({
    mentions: PropTypes.bool,
    date: PropTypes.bool,
  }),

  data: PropTypes.objectOf(PropTypes.any),
};

QModal.defaultProps = {
  subtitle: null,
  footer: null,
  config: {},
  data: {},
};

export default QModal;
