import React from 'react';
import { flatten, last } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tag } from './Tag';
import TagInput from './TagInput';

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const extractAllOptions = selectedOptions =>
  flatten(selectedOptions.map(({ optionsDetails }) => optionsDetails));

const SingleTagInput = ({
  selectedOptions,
  inputValue,
  onRemoved,
  onChange,
}) => {
  const options = extractAllOptions(selectedOptions);
  return (
    <TagsContainer>
      {options.map(details => {
        const { option, parents } = details;
        const parent = last(parents);
        return (
          <Tag option={option} onRemoved={() => onRemoved(parent, details)} />
        );
      })}
      <TagInput value={inputValue} onChange={onChange} />
    </TagsContainer>
  );
};

SingleTagInput.propTypes = {
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      optionsDetails: PropTypes.shape({
        option: PropTypes.shape({ label: PropTypes.string }),
      }),
    }),
  ).isRequired,
  inputValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoved: PropTypes.func.isRequired,
};

export default SingleTagInput;
