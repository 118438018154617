import gql from 'graphql-tag';

const sendOTP = gql`
  mutation sendOTP($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      exp
      user {
        id
        firstName
        lastName
        email
        isAdmin
        expireAt
        account {
          id
          companyName
          name
          apiToken
        }
        lang
        verbatimsLocale
        otpEnabled
      }
    }
  }
`;

export default sendOTP;
