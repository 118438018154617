import gql from 'graphql-tag';

const mutation = gql`
  mutation archiveInsight($projectId: Int!, $id: Int!) {
    archiveInsight(projectId: $projectId, id: $id) {
      insight {
        insightId
      }
    }
  }
`;

export default mutation;
