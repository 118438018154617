import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QTitle from '../../../../_Components/Texts/QTitle/QTitle';
import { TEXT_TYPES } from '../../../../_Components/Texts/textTypes';
import { TEXT_POSITIONS } from '../../../../_Components/Texts/textPositions';

const StyledQTitle = styled(QTitle)`
  padding-bottom: 0;
  max-height: 110px;
  overflow: hidden;
  flex-shrink: 0;
`;

const SectionHeader = ({ title, subtitles }) => (
  <StyledQTitle type={TEXT_TYPES.SECTION} position={TEXT_POSITIONS.CENTER}>
    <QTitle.Title>{title}</QTitle.Title>
    {subtitles.map(subtitle => (
      <QTitle.Subtitle>{subtitle}</QTitle.Subtitle>
    ))}
  </StyledQTitle>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.string),
};

SectionHeader.defaultProps = {
  subtitles: [],
};

export default SectionHeader;
