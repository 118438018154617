import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import '../../../node_modules/react-resizable/css/styles.css';
import '../../../node_modules/react-grid-layout/css/styles.css';
import { getRoute } from '_Utils/routes/routeUtils';
import ProjectsCard from '_Cards/SettingsCards/ProjectsCard/ProjectsCard';
import QToast from '_Components/QToast/QToast';
import QueryCurrentUser from '_Resources/Header/Services/api/QueryCurrentUser';
import UploadCompanyLogoCard from '_Cards/SettingsCards/UploadCompanyLogoCard/UploadCompanyLogoCard';
import layoutConfig from './config';
import AccountAndPreferencesCard from '../../_Cards/SettingsCards/AccountAndPreferencesCard/AccountAndPreferencesCard';
import PasswordCard from '../../_Cards/SettingsCards/AccountAndPreferencesCard/PasswordCard';
import APIIntegrationCard from '../../_Cards/SettingsCards/AccountAndPreferencesCard/APIIntegrationCard';
import UserAndPermissionsCard from '../../_Cards/SettingsCards/UserAndPermissionsCard/UserAndPermissionsCard';
import PlanPricingCard from '../../_Cards/SettingsCards/PlanPricingCard/PlanPricingCard';
import AccountConsumptionCard from '../../_Cards/SettingsCards/AccountConsumptionCard/AccountConsumptionCard';
import AccountDetailsCard from '../../_Cards/SettingsCards/AccountDetailsCard/AccountDetailsCard';

const UserPermissionsContainer = styled.div`
  margin-top: 5em;
`;

const PlanPricingContainer = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
`;

class Settings extends React.Component {
  static propTypes = {
    idTab: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      idpage: 'settings',
      layout: layoutConfig,
      toastStatus: this.props.location.state?.toastStatus,
      tabID: this.props.tabID,
    };
  }

  componentDidMount() {
    const { dispatchUpdateRoute } = this.props;
    const { toastStatus, idpage } = this.state;
    getRoute(dispatchUpdateRoute, idpage);
    if (toastStatus?.length > 0) {
      setTimeout(() => {
        this.setState({ toastStatus: null });
      }, 5000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { tabID } = this.props;

    if (tabID !== prevProps.tabID) {
      this.setState({ tabID });
    }
  }

  getBody() {
    let bodyContent;
    const { currentUser, usersTabId } = this.props;
    const { tabID } = this.state;
    switch (tabID) {
      case 'account-preferences':
        bodyContent = (
          <div>
            <AccountAndPreferencesCard />
            <PasswordCard />
            {currentUser?.isAdmin && <UploadCompanyLogoCard />}
            <APIIntegrationCard />
            {currentUser?.account?.planName !== 'emotion_corporate' && (
              <AccountDetailsCard accountId={currentUser?.account?.id} isUserAdmin={currentUser?.isAdmin} />
            )}
          </div>
        );
        break;
      case 'projects':
        bodyContent = (
          <div>
            <ProjectsCard currentUser={currentUser} />
            <ProjectsCard archived />
          </div>
        );
        break;
      case 'users':
        bodyContent = (
          <UserPermissionsContainer>
            <UserAndPermissionsCard usersTabId={usersTabId} />
          </UserPermissionsContainer>
        );
        break;
      case 'plan-pricing':
        bodyContent = (
          <PlanPricingContainer>
            <PlanPricingCard />
            {currentUser && currentUser.account && currentUser.account.planName && <AccountConsumptionCard />}
          </PlanPricingContainer>
        );
        break;
      default:
        bodyContent = null;
        break;
    }
    return bodyContent;
  }

  render() {
    const { layout, toastStatus } = this.state;
    const { currentUser, location } = this.props;
    if (!currentUser) {
      return null;
    }
    return (
      layout && (
        <div>
          {currentUser && <QueryCurrentUser />}
          <Container fluid className="py-3 px-4">
            {this.getBody()}
            {toastStatus && <QToast color={location.state.toastColor}>{toastStatus}</QToast>}
          </Container>
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    module: state.routes.route && state.routes.route.name,
    idTab: state.specificFilters.settings && state.specificFilters.settings.tab_id,
    currentUser: state.auth.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateRoute: newState => {
      dispatch(updateRoute(newState));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
