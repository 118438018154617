import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fontSizes } from 'styles/abstracts/typography';
import { ANALYSIS_AXIS } from '../../../../Routes/GlobalExperience/Reporting/_Containers/configs/settingOptions';
import { MenuItemLabel } from '../../../../Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import { CheckboxContainer, RadioButton } from '../CLineChartSettings';

const TitleSection = styled.p`
  font-size: ${fontSizes.bigger};
  margin-bottom: 0.5em;
`;

class AnalysisAxisSettings extends React.Component {
  axisCheckboxClicked = analysisAxis => {
    const { onSettingsChange, settings } = this.props;
    onSettingsChange({ ...settings, analysisAxis });
  };

  render() {
    const { analysisAxis, t } = this.props;
    return (
      <div>
        <br />
        <TitleSection>{t('Analysis axis')}</TitleSection>
        {Object.keys(ANALYSIS_AXIS).map(axis => (
          <>
            <CheckboxContainer>
              <MenuItemLabel checked={analysisAxis === ANALYSIS_AXIS[axis].id}>
                <RadioButton
                  type="radio"
                  checked={analysisAxis === ANALYSIS_AXIS[axis].id}
                  onChange={() => this.axisCheckboxClicked(ANALYSIS_AXIS[axis].id)}
                  value={ANALYSIS_AXIS[axis].id}
                />
              </MenuItemLabel>
              {t(ANALYSIS_AXIS[axis].label)}
            </CheckboxContainer>
            <br />
          </>
        ))}
      </div>
    );
  }
}

export default withTranslation('button')(AnalysisAxisSettings);
