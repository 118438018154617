import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import Icon from '_Components/Icons/Icon';
import VarianceAnalysisChart from '_Charts/AnalysesCharts/VarianceAnalysisChart/VarianceAnalysisChart';
import DropdownsContainer from './_Containers/DropdownsContainer/DropdownsContainer';
import GET_DATA_ANALYSIS_QUERY from './graphql/getVarianceAnalysis';
import {
  MAIN_INDICATOR_CONFIG,
  sortData,
} from './_Containers/DropdownsContainer/utils';

const GraphContainer = styled.div`
  height: 35em;
`;
const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled(Icon)`
  animation: ${spinning} 1s linear infinite;
`;

type VarianceAnalysisCardProps = {
  filterKeys: any;
  isEindexUnitFahrenheit: boolean;
  projectMonths: any[];
  t: any;
};

const VarianceAnalysisCard = ({
  filterKeys,
  isEindexUnitFahrenheit,
  projectMonths,
  t,
}: VarianceAnalysisCardProps) => {
  const [mainIndicator, setMainIndicator] = useState<string>('eindex');
  const [explainIndicator, setExplainIndicator] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string>('impactWeight_asc');
  const [details, setDetails] = useState<boolean>(false);
  const [analysis, setAnalysis] = useState<any>();
  const [thematicsToIterate, setThematicsToIterate] = useState<Array<any>>([]);
  const [thematicsTotalEffectValues, setThematicsTotalEffectValues] = useState<
    any
  >(
    thematicsToIterate &&
      thematicsToIterate.length > 0 &&
      sortData(
        orderBy,
        details,
        thematicsToIterate,
        mainIndicator,
        isEindexUnitFahrenheit,
        t,
      ) &&
      sortData(
        orderBy,
        details,
        thematicsToIterate,
        mainIndicator,
        isEindexUnitFahrenheit,
        t,
      ).totalEffect,
  );
  const [labels, setLabels] = useState<Array<string> | any[]>(
    thematicsToIterate &&
      thematicsToIterate.length > 0 &&
      sortData(
        orderBy,
        details,
        thematicsToIterate,
        mainIndicator,
        isEindexUnitFahrenheit,
        t,
      ).labels,
  );

  const [referencePeriod, setReferencePeriod] = useState<{
    startDate: string;
    endDate: string;
  } | null>(projectMonths?.[projectMonths?.length - 1]?.period || null);
  useEffect(() => {
    setReferencePeriod(projectMonths?.[projectMonths?.length - 1]?.period);
  }, [projectMonths]);

  const getThematicsToIterate = () => {
    switch (explainIndicator) {
      case 1:
      default:
        setThematicsToIterate(analysis?.thematics);
        break;
      case 2:
        let subThematics: Array<any> = [];
        analysis?.thematics?.map(thematic => {
          if (thematic.subThematics && thematic.subThematics.length > 0) {
            subThematics = [...subThematics, ...thematic.subThematics];
          } else {
            subThematics = [...subThematics, thematic];
          }
        });
        setThematicsToIterate(subThematics);
        break;
      case 3:
        let subThematicsArray: Array<any> = [];
        let subSubThematics: Array<any> = [];
        analysis?.thematics?.map(thematic => {
          subThematicsArray = [...subThematicsArray, ...thematic.subThematics];
        });
        subThematicsArray?.map(subThematic => {
          if (
            subThematic.subSubThematics &&
            subThematic.subSubThematics.length > 0
          ) {
            subSubThematics = [
              ...subSubThematics,
              ...subThematic.subSubThematics,
            ];
          } else {
            subSubThematics = [...subSubThematics, subThematic];
          }
        });
        setThematicsToIterate(subSubThematics);
        break;
    }
  };

  useEffect(() => {
    getThematicsToIterate();
  }, [analysis, explainIndicator]);

  const { data, loading } = useQuery(GET_DATA_ANALYSIS_QUERY, {
    variables: {
      projectId: filterKeys?.projectId,
      currentPeriod: filterKeys?.range,
      referencePeriod,
      indicator: mainIndicator,
      level: explainIndicator,
      criteriaKeys: filterKeys?.criteriaKeys,
      thematics: filterKeys?.thematics,
      keywords: filterKeys?.keywords,
    },
  });

  useEffect(() => {
    setAnalysis(data?.getVarianceAnalysis);
  }, [data]);

  const referenceCommentsWithThematics =
    analysis?.globalReferenceVarianceAnalysis?.indicatorTotal /
    analysis?.globalReferenceVarianceAnalysis?.verbatimWithThematicCount;
  const globalReference = [
    analysis?.globalReferenceVarianceAnalysis?.totalEffect,
    referenceCommentsWithThematics -
      analysis?.globalReferenceVarianceAnalysis?.totalEffect,
    referenceCommentsWithThematics,
  ];

  const currentCommentsWithThematics =
    analysis?.globalCurrentVarianceAnalysis?.indicatorTotal /
    analysis?.globalCurrentVarianceAnalysis?.verbatimWithThematicCount;
  const globalCurrent = [
    currentCommentsWithThematics,
    analysis?.globalCurrentVarianceAnalysis?.totalEffect -
      currentCommentsWithThematics,
    analysis?.globalCurrentVarianceAnalysis?.totalEffect,
  ];
  useEffect(() => {
    setThematicsTotalEffectValues(
      sortData(
        orderBy,
        details,
        thematicsToIterate,
        mainIndicator,
        isEindexUnitFahrenheit,
        t,
      ) &&
        sortData(
          orderBy,
          details,
          thematicsToIterate,
          mainIndicator,
          isEindexUnitFahrenheit,
          t,
        ).totalEffect,
    );
    setLabels(
      sortData(
        orderBy,
        details,
        thematicsToIterate,
        mainIndicator,
        isEindexUnitFahrenheit,
        t,
      ).labels,
    );
  }, [orderBy, details, thematicsToIterate]);

  const effectData = globalReference &&
    thematicsTotalEffectValues &&
    globalCurrent && [
      ...globalReference,
      ...thematicsTotalEffectValues,
      ...globalCurrent,
    ];

  const negativeTotalEffect =
    effectData &&
    effectData?.map((d, ind) =>
      d > 0 ||
      ind === 0 ||
      ind === 2 ||
      ind === effectData.length - 1 ||
      ind === effectData.length - 3
        ? '-'
        : d,
    );
  const positiveTotalEffect =
    effectData &&
    effectData?.map((d, ind) =>
      d < 0 ||
      ind === 0 ||
      ind === 2 ||
      ind === effectData.length - 1 ||
      ind === effectData.length - 3
        ? '-'
        : d,
    );

  const totalEffect =
    effectData &&
    effectData?.map((d, ind) =>
      ind === 0 ||
      ind === 2 ||
      ind === effectData.length - 1 ||
      ind === effectData.length - 3
        ? d
        : '-',
    );

  let thematicsGraphData = [globalReference[globalReference.length - 1]];
  thematicsTotalEffectValues &&
    thematicsTotalEffectValues?.map((d, ind) => {
      thematicsGraphData = [
        ...thematicsGraphData,
        thematicsGraphData[ind]
          ? d > 0
            ? thematicsTotalEffectValues[ind - 1] > 0
              ? thematicsGraphData[ind] + thematicsTotalEffectValues[ind - 1]
              : thematicsGraphData[ind]
            : !thematicsTotalEffectValues[ind - 1] ||
              thematicsTotalEffectValues[ind - 1] < 0
            ? thematicsGraphData[ind] + d
            : thematicsGraphData[ind] + d + thematicsTotalEffectValues[ind - 1]
          : d,
      ];
    });
  thematicsGraphData.shift();

  const graphData = [
    ...globalReference.map((d, ind) => {
      return totalEffect && totalEffect?.includes(d)
        ? 0
        : d < 0
        ? globalReference[globalReference.length - 1]
        : globalReference[globalReference.length - 1] - d;
    }),
    ...thematicsGraphData,
    ...globalCurrent.map((d, ind) => {
      return totalEffect && totalEffect?.includes(d)
        ? 0
        : d < 0
        ? globalCurrent[globalCurrent.length - 1]
        : globalCurrent[globalCurrent.length - 1] - d;
    }),
  ];
  return (
    <QcardPanel
      title={`
      ${t(`Variance analysis`, {
        indicator: MAIN_INDICATOR_CONFIG.find(d => d.value === mainIndicator)
          .label,
      })}
      (${details ? t('VolumeTonalityMixEffects') : t('Global')})`}
      referencePeriod={referencePeriod}
      referenceMentions={
        analysis?.globalReferenceVarianceAnalysis?.verbatimAmount
      }
      hints={[
        HintsContent.HINT_VARIANCE_ANALYSIS_INTRODUCTION,
        HintsContent.HINT_VARIANCE_ANALYSIS_TONALITY_VOLUME_EFFECTS,
        HintsContent.HINT_VARIANCE_ANALYSIS_MIX_EFFECT,
      ]}
    >
      <DropdownsContainer
        setExplainIndicator={setExplainIndicator}
        explainIndicator={explainIndicator}
        setMainIndicator={setMainIndicator}
        mainIndicator={mainIndicator}
        setReferencePeriod={setReferencePeriod}
        referencePeriod={referencePeriod}
        details={details}
        setDetails={setDetails}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <div
        style={{
          width: '100%',
          display: 'inline-flex',
          justifyContent: 'center',
        }}
      >
        {loading && (
          <b>
            <Loader icon="INTERFACE_LOADING" /> {t('Loading')}
          </b>
        )}
      </div>
      <GraphContainer>
        <VarianceAnalysisChart
          data={{
            labels: thematicsToIterate && [
              // labels.length > 0 && // labels &&
              t('All comments (Reference period)'),
              t('Comments without categories'),
              t('Comments with categories (Reference period)'),
              ...(labels || [' ']),
              t('Comments with categories (Current period)'),
              t('Comments without categories'),
              t('All comments (Current period)'),
            ],
            values: {
              negativeTotalEffect:
                negativeTotalEffect &&
                negativeTotalEffect?.map(d => Math.abs(d)),
              positiveTotalEffect:
                positiveTotalEffect && positiveTotalEffect?.map(d => d),
              totalEffect:
                totalEffect &&
                totalEffect?.map(d => Math.round(d * 1000) / 1000),
              graphData,
            },
          }}
          details={details}
          mainIndicator={mainIndicator}
          isEindexUnitFahrenheit={isEindexUnitFahrenheit}
        />
      </GraphContainer>
    </QcardPanel>
  );
};

const mapStateToProps = state => ({
  filterKeys: get(state, ['filters', 'filterKeys']),
  isEindexUnitFahrenheit: false,
  projectMonths: get(state, ['periods', 'projectPeriods', 'byMonth']),
});

export default withTranslation('card')(
  connect(mapStateToProps)(VarianceAnalysisCard),
);
