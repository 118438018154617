export const SET_RANGE_FILTER = 'SET_RANGE_FILTER';

export const setRangeFilter = (
  context,
  rangeValues,
  mergedValues,
  itemIndex,
) => ({
  type: SET_RANGE_FILTER,
  context,
  rangeValues,
  mergedValues,
  itemIndex,
});
