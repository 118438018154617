import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import QTabs from '_Components/Panels/QTabs/QTabs';
import { spacing } from 'styles/abstracts/variables';
import { grayShades } from 'styles/abstracts/colors';
import QTab from '_Components/Panels/QTabs/tab/QTab';
import { StyledQModal, Navigation, StyledQButton } from './_Components/index';

const StyledQTabs = styled(QTabs)`
  background: #fff !important;
  padding: 0 ${spacing.mediumSmall};
  border-bottom: 1px solid ${grayShades.g300};
`;

const StyledQTab = styled(QTab)`
  padding: 0 ${spacing.large} ${spacing.medium};
`;

class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'name',
    };
  }

  onTabsSelected = tabId => this.setState({ selectedTab: tabId });

  resetModale = () => {
    this.props.closeModal(!this.props.isOpen);
    this.setState({
      selectedTab: 'name',
    });
  };

  cancelCreateModale = () => {
    this.resetModale();
    this.props.onCancel();
  };

  validateColumnCreation = () => {
    this.resetModale();
    this.props.onSave();
  };

  getFooter = config => {
    const { cancelButton, validateButton } = config;
    return (
      <Navigation>
        <StyledQButton onClick={this.cancelCreateModale}>
          {cancelButton.text}
        </StyledQButton>
        <StyledQButton
          onClick={this.validateColumnCreation}
          disabled={validateButton.disabled}
        >
          {validateButton.text}
        </StyledQButton>
      </Navigation>
    );
  };

  getTabs = tabs =>
    Object.keys(tabs).map(tab => (
      <StyledQTab key={tab} id={tab}>
        {tabs[tab].title}
      </StyledQTab>
    ));

  render() {
    const { selectedTab } = this.state;
    const { modalConfig } = this.props;

    return (
      <StyledQModal
        footer={this.getFooter(modalConfig, selectedTab)}
        isOpen={this.props.isOpen}
        onClose={this.closeModal}
        title={this.props.title}
        subtitle={this.props.subtitle}
        config={this.props.config}
      >
        <StyledQTabs
          onTabChanged={this.onTabsSelected}
          initialPage={selectedTab}
        >
          {this.getTabs(modalConfig.steps)}
        </StyledQTabs>
        {modalConfig.steps[selectedTab].stepBody}
      </StyledQModal>
    );
  }
}

EditModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.bool),
  modalConfig: PropTypes.objectOf(
    PropTypes.oneOfType(
      PropTypes.objectOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)),
    ),
  ).isRequired,
};

EditModal.defaultProps = {
  isOpen: false,
  title: null,
  subtitle: null,
  config: null,
};

export default EditModal;
