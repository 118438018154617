import React from 'react';
import { Row, Col } from 'reactstrap';
import NameInputBenchmark from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/Inputs/NameInputBenchmark';
import { connect } from 'react-redux';
import { setName } from '_Resources/Benchmark/Actions/Filters/setName';
import PropTypes from 'prop-types';
import ModalPageTitle from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/ModalPageTitle';

const ModalNamePage = ({ dispatchSetName, name, stepTitle, context }) => (
  <>
    <ModalPageTitle stepTitle={stepTitle} />
    <Row>
      <Col>
        <NameInputBenchmark
          name={name}
          context={context}
          dispatcher={dispatchSetName}
        />
      </Col>
    </Row>
  </>
);

const mapDispatchToProps = (dispatch, { context }) => ({
  dispatchSetName: name => {
    dispatch(setName({ name, context }));
  },
});

ModalNamePage.propTypes = {
  dispatchSetName: PropTypes.func.isRequired,
  name: PropTypes.string,
  stepTitle: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
};

ModalNamePage.defaultProps = {
  name: null,
};

export default connect(
  null,
  mapDispatchToProps,
)(ModalNamePage);
