import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fontSizes } from 'styles/abstracts/typography';
import { EMOTIONS_EVOLUTION_DATA_DISPLAY } from '../../../../Routes/GlobalExperience/Reporting/_Containers/configs/settingOptions';
import { MenuItemLabel } from '../../../../Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import { CheckboxContainer, RadioButton } from '../CLineChartSettings';

const TitleSection = styled.p`
  font-size: ${fontSizes.bigger};
  margin-bottom: 0.5em;
`;

class DataDisplaySettings extends React.Component {
  dataDisplayCheckboxClicked = option => {
    const { onSettingsChange, settings } = this.props;
    onSettingsChange({ ...settings, dataDisplay: option });
  };

  render() {
    const { dataDisplay, t } = this.props;
    return (
      <div>
        <br />
        <TitleSection>{t('Values')}</TitleSection>
        {Object.keys(EMOTIONS_EVOLUTION_DATA_DISPLAY).map(option => (
          <>
            <CheckboxContainer>
              <MenuItemLabel checked={dataDisplay === EMOTIONS_EVOLUTION_DATA_DISPLAY[option].value}>
                <RadioButton
                  type="radio"
                  checked={dataDisplay === EMOTIONS_EVOLUTION_DATA_DISPLAY[option].value}
                  onChange={() => this.dataDisplayCheckboxClicked(EMOTIONS_EVOLUTION_DATA_DISPLAY[option].value)}
                  value={EMOTIONS_EVOLUTION_DATA_DISPLAY[option].value}
                />
              </MenuItemLabel>
              {t(EMOTIONS_EVOLUTION_DATA_DISPLAY[option].label)}
            </CheckboxContainer>
            <br />
          </>
        ))}
      </div>
    );
  }
}

export default withTranslation('button')(DataDisplaySettings);
