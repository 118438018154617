import React from 'react';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import EindexBarGraphSection from './EindexBarGraphSection';

const Container = styled(Row)`
  & > div {
    width: 100%;
  }
`;

const EindexBarGraphCard = props => {
  const { filters } = props;
  return (
    <QcardPanel {...props}>
      <Container className="containContent">
        <EindexBarGraphSection filters={filters} />
      </Container>
    </QcardPanel>
  );
};

export default EindexBarGraphCard;
