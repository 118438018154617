import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { prepareNormalToOptions } from './normalToOptions';
import { createAdapter } from './adapter';

export class NormalFilter extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    type: PropTypes.string,

    // NOTE: properties passed by QProjectFilters
    onFilterOptionsCreated: PropTypes.func,
    onFilterRemoved: PropTypes.func,
    registerAdapter: PropTypes.func,
  };

  normalToOptions = null;

  static defaultProps = {
    type: 'normal',
    onFilterOptionsCreated: undefined,
    onFilterRemoved: undefined,
    registerAdapter: undefined,
  };

  constructor(props) {
    super(props);
    this.initialize();
  }

  initialize() {
    const { t } = this.props;
    this.normalToOptions = prepareNormalToOptions(t);
  }

  componentDidMount() {
    const { registerAdapter, type } = this.props;
    registerAdapter(createAdapter(type));
  }

  componentWillUnmount() {
    const { onFilterRemoved } = this.props;
    onFilterRemoved();
  }

  render() {
    const { filters, onFilterOptionsCreated, type } = this.props;
    const options = this.normalToOptions(filters, type);
    onFilterOptionsCreated(options);
    return null;
  }
}

export const QNormalFilter = withTranslation('filters')(NormalFilter);
