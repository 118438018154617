import React from 'react';
import { Query } from 'react-apollo';
import query from '../../graphql/getVerbatim';
import VerbatimDetailsModal from './VerbatimDetailsModal';

const VerbatimDetails = props => {
  const { modalFilters } = props;
  return (
    <Query query={query} variables={modalFilters} fetchPolicy="network-only">
      {({ loading, error, data, refetch, networkStatus }) => {
        if (loading || networkStatus === 4) return false;
        if (error) {
          return false;
        }
        return <VerbatimDetailsModal {...props} data={data.getVerbatim} />;
      }}
    </Query>
  );
};

export default VerbatimDetails;
