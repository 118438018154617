import { removeNormalIterationFilter } from '../../../../_Utils/filters/filterUtils';

export const addIterateBy = (state, { iterateByValues }) => {
  const { iterateByOptions } = state;
  return {
    ...state,
    iterateByOptions: [...iterateByOptions, ...iterateByValues],
  };
};

export const removeIterateBy = (state, { iterateByValues: [filterValue] }) => {
  const { iterateByOptions } = state;
  return {
    ...state,
    iterateByOptions: removeNormalIterationFilter(iterateByOptions, filterValue),
  };
};
