import { Collapse } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import DateRangeMergeSubtitle from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/DateRangeMergeSubtitle';
import { MessageTable } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/MessageTable';
import { CollapseContent } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/CollapseContent';
import { lightBlue } from 'styles/abstracts/colors';
import { capitalize } from '_Utils/strings/stringsUtils';

const StyledTr = styled.tr`
  color: ${lightBlue};
  font-size: initial;
`;

const DateRangeMergeMessage = ({
  context,
  isOpen,
  itemMergedPeriod,
  itemOriginalPeriod,
  globalContextPeriod,
  t,
  i18n,
}) => {
  function formatDate(date) {
    if (!date) return t('Invalid date');
    return moment(date)
      .locale(i18n.language)
      .format('LL');
  }
  return (
    <CollapseContent isOpen={isOpen}>
      <DateRangeMergeSubtitle
        context={context}
        mergedDateRange={itemMergedPeriod}
      />
      <Collapse isOpen={isOpen}>
        <MessageTable>
          <tr>
            <th />
            <th>{t('From')}</th>
            <th>{t('To')}</th>
          </tr>
          <tr>
            <td>
              {capitalize(
                t('cardColumn date range', { type: `$t(${context})` }),
              )}
            </td>
            <td>{formatDate(itemOriginalPeriod.startDate)}</td>
            <td>{formatDate(itemOriginalPeriod.endDate)}</td>
          </tr>
          <tr>
            <td>{capitalize(t('global context date range'))}</td>
            <td>{formatDate(globalContextPeriod.startDate)}</td>
            <td>{formatDate(globalContextPeriod.endDate)}</td>
          </tr>
          <StyledTr>
            <td>{capitalize(t('Result'))}</td>
            <td>{formatDate(itemMergedPeriod.startDate)}</td>
            <td>{formatDate(itemMergedPeriod.endDate)}</td>
          </StyledTr>
        </MessageTable>
      </Collapse>
    </CollapseContent>
  );
};

export default withTranslation('conflicts')(DateRangeMergeMessage);
