import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { useMutation } from 'react-apollo';
import QButton from '_Components/Buttons/QButton/QButton';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import ProjectNaming from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ProjectNaming/ProjectNaming';
import ProjectLanguage from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/DataConfiguration/_Components/LanguageSettings';
import ProcessingStep from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ProcessingStep/ProcessingStep';
import InstructionsSection from './_Components/InstructionsSection';
import FileUploadSection from './_Components/FileUploadSection';
import UPDATE_SAAS_UPLOAD_CONFIG_MUTATION from './graphql/updateSaasUploadConfig';

const Container = styled(QPanel)`
  padding: ${({ hasFile }) => (hasFile ? '1em' : 0)} !important;
  display: ${({ hasFile }) => !hasFile && 'flex'};
  font-size: 16px;
`;

const StyledCol = styled(Col)`
  min-height: 100%;
`;

const StyledRow = styled(Row)`
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
`;

const Footer = styled.div`
  margin-top: 1em;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const UploadDataCard = ({
  history,
  t,
  saasUploadConfig,
  uuid,
  setActiveStep,
  location,
}) => {
  const [addProjectFile] = useMutation(UPDATE_SAAS_UPLOAD_CONFIG_MUTATION);

  const [file, setFile] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [sendEmail, checkSendEmail] = useState(false);
  const [isNameValidated, setNameValidation] = useState(false);
  const [projectLang, setProjectLang] = useState('en');
  let content;

  if (isNameValidated || saasUploadConfig?.status === 'UPLOADED') {
    setActiveStep(5);
    content = (
      <Container>
        <ProcessingStep t={t} />
      </Container>
    );
  } else if (file && !location?.state?.fromDetails) {
    const onSend = () => {
      setNameValidation(true);
      addProjectFile({
        variables: {
          file,
          uuid,
          projectName,
          lang: projectLang,
          sendEmailAfterProcessing: sendEmail,
        },
      })
        .then(({ data }) => {
          setNameValidation(true);
        })
        .catch(err => {
          console.error('UploadDataCard l.58 err', err);
        });
    };

    content = (
      <Container hasFile={!!file}>
        <QTitle>{t('Final step !')}</QTitle>
        <StyledRow>
          <Col xs={12}>
            {t(
              'Just name your project and let us know if you want an email when data is processed !',
            )}
          </Col>
        </StyledRow>
        <ProjectNaming
          t={t}
          setName={setProjectName}
          name={projectName}
          sendEmail={sendEmail}
          checkSendEmail={checkSendEmail}
        />
        <ProjectLanguage t={t} setLang={setProjectLang} lang={projectLang} />
        <Footer>
          <QButton onClick={() => setFile(null)}>{t('button:Return')}</QButton>
          <QButton
            onClick={() => onSend()}
            disabled={!projectName?.trim() || !projectLang}
          >
            {t('button:Confirm')}
          </QButton>
        </Footer>
      </Container>
    );
  } else {
    content = (
      <Container>
        <StyledCol xs={6} className="p-0">
          <InstructionsSection
            history={history}
            t={t}
            useCase={saasUploadConfig?.useCase}
            industry={saasUploadConfig?.industry}
            location={location}
          />
        </StyledCol>
        <StyledCol xs={6} className="p-0">
          <FileUploadSection
            t={t}
            status={saasUploadConfig?.status}
            uuid={uuid}
            setFileToUpload={setFile}
            fromDetails={location?.state?.fromDetails}
          />
        </StyledCol>
      </Container>
    );
  }
  return content;
};

export default UploadDataCard;
