import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fontSizes } from 'styles/abstracts/typography';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85%;
  font-size: ${fontSizes.bigger};
  margin-bottom: 0.5em;
`;

class EvolutionBadgeSettings extends React.Component {
  badgeShowToggled = () => {
    const { onSettingsChange, settings } = this.props;
    onSettingsChange({ ...settings, badgeShow: !settings.badgeShow });
  };

  render() {
    const { t, settings } = this.props;
    const { badgeShow } = settings;

    // console.log("badgeShow:", badgeShow);


    return (
      <div>
        <br />
        <TitleSection>
          {t('Evolution badge')}
          <QBooleanSwitch id="badge-switcher" onChanged={() => this.badgeShowToggled()} value={badgeShow} />
        </TitleSection>
      </div>
    );
  }
}

export default withTranslation('card')(EvolutionBadgeSettings);
