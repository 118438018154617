export default [
  {
    size: { columns: 1, rows: 1 },
    children: [{ name: 'section1', pdfStartingPoint: { x: 0, y: 0 } }],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1" 
        `,
  },
  {
    size: { columns: 2, rows: 1 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr;
          grid-template-areas: "section1 section2" 
        `,
  },
  {
    size: { columns: 3, rows: 1 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0, w: 2 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 2fr;
          grid-template-areas: "section1 section2" 
        `,
  },
  {
    size: { columns: 3, rows: 1 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0, w: 2 } },
      { name: 'section2', pdfStartingPoint: { x: 2, y: 0 } },
    ],
    cssGridLayout: `
          grid-template-columns: 2fr 1fr;
          grid-template-areas: "section1 section2" 
        `,
  },
  {
    size: { columns: 1, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 0, y: 1 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: "section1" "section2" 
        `,
  },
  {
    size: { columns: 3, rows: 1 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0 } },
      { name: 'section3', pdfStartingPoint: { x: 2, y: 0 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas: "section1 section2 section3" 
        `,
  },
  {
    size: { columns: 3, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0, h: 2 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0, w: 2 } },
      { name: 'section3', pdfStartingPoint: { x: 1, y: 1, w: 2 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 2fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: "section1 section2" "section1 section3" 
        `,
  },
  {
    size: { columns: 3, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0, w: 2 } },
      { name: 'section2', pdfStartingPoint: { x: 0, y: 1, w: 2 } },
      { name: 'section3', pdfStartingPoint: { x: 2, y: 0, h: 2 } },
    ],
    cssGridLayout: `
          grid-template-columns: 2fr 1fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: "section1 section3" "section2 section3" 
        `,
  },
  {
    size: { columns: 3, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section3', pdfStartingPoint: { x: 0, y: 1 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0, h: 2, w: 2 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 2fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: "section1 section2" "section3 section2" 
        `,
  },
  {
    size: { columns: 3, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0, h: 2, w: 2 } },
      { name: 'section2', pdfStartingPoint: { x: 2, y: 0 } },
      { name: 'section3', pdfStartingPoint: { x: 2, y: 1 } },
    ],
    cssGridLayout: `
          grid-template-columns: 2fr 1fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: "section1 section2" "section1 section3" 
        `,
  },
  {
    size: { columns: 1, rows: 3 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 0, y: 1 } },
      { name: 'section3', pdfStartingPoint: { x: 0, y: 2 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          grid-template-areas: "section1" "section2" "section3" 
        `,
  },
  {
    size: { columns: 2, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0 } },
      { name: 'section3', pdfStartingPoint: { x: 0, y: 1 } },
      { name: 'section4', pdfStartingPoint: { x: 1, y: 1 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr ;
          grid-template-areas: "section1 section2" "section3 section4" 
        `,
  },
  {
    size: { columns: 3, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0, w: 3 } },
      { name: 'section2', pdfStartingPoint: { x: 0, y: 1 } },
      { name: 'section3', pdfStartingPoint: { x: 1, y: 1 } },
      { name: 'section4', pdfStartingPoint: { x: 2, y: 1 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr ;
          grid-template-areas: "section1 section1 section1" "section2 section3 section4" 
        `,
  },
  {
    size: { columns: 3, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0 } },
      { name: 'section3', pdfStartingPoint: { x: 2, y: 0 } },
      { name: 'section4', pdfStartingPoint: { x: 0, y: 1, w: 3 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr ;
          grid-template-areas: "section1 section2 section3" "section4 section4 section4"
        `,
  },
  {
    size: { columns: 3, rows: 3 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0, h: 3 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0, w: 2 } },
      { name: 'section3', pdfStartingPoint: { x: 1, y: 1, w: 2 } },
      { name: 'section4', pdfStartingPoint: { x: 1, y: 2, w: 2 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 2fr;
          grid-template-rows: 1fr 1fr 1fr;
          grid-template-areas: "section1 section2 " "section1 section3" "section1 section4"
        `,
  },
  {
    size: { columns: 3, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0 } },
      { name: 'section3', pdfStartingPoint: { x: 2, y: 0 } },
      { name: 'section4', pdfStartingPoint: { x: 0, y: 1 } },
      { name: 'section5', pdfStartingPoint: { x: 1, y: 1 } },
      { name: 'section6', pdfStartingPoint: { x: 2, y: 1 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: "section1 section2 section3" "section4 section5 section6"
        `,
  },
  {
    size: { columns: 3, rows: 3 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0 } },
      { name: 'section3', pdfStartingPoint: { x: 2, y: 0 } },
      { name: 'section4', pdfStartingPoint: { x: 0, y: 1 } },
      { name: 'section5', pdfStartingPoint: { x: 1, y: 1 } },
      { name: 'section6', pdfStartingPoint: { x: 2, y: 1 } },
      { name: 'section7', pdfStartingPoint: { x: 0, y: 2 } },
      { name: 'section8', pdfStartingPoint: { x: 1, y: 2 } },
      { name: 'section9', pdfStartingPoint: { x: 2, y: 2 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          grid-template-areas: "section1 section2 section3" "section4 section5 section6" "section7 section8 section9"
        `,
  },
  {
    size: { columns: 4, rows: 2 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0 } },
      { name: 'section3', pdfStartingPoint: { x: 2, y: 0 } },
      { name: 'section4', pdfStartingPoint: { x: 3, y: 0 } },
      { name: 'section5', pdfStartingPoint: { x: 0, y: 1 } },
      { name: 'section6', pdfStartingPoint: { x: 1, y: 1 } },
      { name: 'section7', pdfStartingPoint: { x: 2, y: 1 } },
      { name: 'section8', pdfStartingPoint: { x: 3, y: 1 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: "section1 section2 section3 section4" "section5 section6 section7 section8"
        `,
  },
  {
    size: { columns: 4, rows: 3 },
    children: [
      { name: 'section1', pdfStartingPoint: { x: 0, y: 0 } },
      { name: 'section2', pdfStartingPoint: { x: 1, y: 0 } },
      { name: 'section3', pdfStartingPoint: { x: 2, y: 0 } },
      { name: 'section4', pdfStartingPoint: { x: 3, y: 0 } },
      { name: 'section5', pdfStartingPoint: { x: 0, y: 1 } },
      { name: 'section6', pdfStartingPoint: { x: 1, y: 1 } },
      { name: 'section7', pdfStartingPoint: { x: 2, y: 1 } },
      { name: 'section8', pdfStartingPoint: { x: 3, y: 1 } },
      { name: 'section9', pdfStartingPoint: { x: 0, y: 2 } },
      { name: 'section10', pdfStartingPoint: { x: 1, y: 2 } },
      { name: 'section11', pdfStartingPoint: { x: 2, y: 2 } },
      { name: 'section12', pdfStartingPoint: { x: 3, y: 2 } },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          grid-template-areas: "section1 section2 section3 section4" "section5 section6 section7 section8" "section9 section10 section11 section12"
        `,
  },
];
