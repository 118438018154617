/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import 'rc-slider/assets/index.css';
import Icon from '_Components/Icons/Icon';
import { hex2rgbaConvert } from '../../../../_Utils/styles/colors';
import styleVars from 'styles/abstracts/_variables.module.scss';
import refineByPropTypes from './RefineBy.propTypes';

const EmotionTag = styled.div`
  cursor: pointer;
  margin: 5px;
  padding: 0.25em 0.5em;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  background-color: ${({ active, color }) =>
    active ? hex2rgbaConvert(color, 25) : '#F5F5F5'};
  color: ${({ active, color }) =>
    active ? hex2rgbaConvert(color, 100) : '#A7ADC7'};
  & > span {
    margin-left: 5px;
  }
`;

class RefineByEmotions extends Component {
  static propTypes = {
    ...refineByPropTypes,
  };
  state = {
    emotions: null,
  };

  positiveEmotions = [
    { name: 'Happiness', color: styleVars.red },
    { name: 'Surprise', color: styleVars.orange },
    { name: 'Calm', color: styleVars.yellow },
  ];

  negativeEmotions = [
    { name: 'Fear', color: styleVars.green },
    { name: 'Sadness', color: styleVars.light_blue },
    { name: 'Anger', color: styleVars.blue },
    { name: 'Disgust', color: styleVars.dark_blue },
  ];

  constructor(props) {
    super(props);
    this.state.emotions = this.props.value || [];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ emotions: this.props.value || [] });
    }
  }

  onEmotionTagClicked = newEmotion => {
    this.setState(
      ({ emotions }) => {
        const newEmotions = emotions.includes(newEmotion)
          ? emotions.filter(emotion => newEmotion !== emotion)
          : [...emotions, newEmotion];

        return { emotions: newEmotions };
      },
      () => this.props.onChange(this.state.emotions),
    );
  };

  generateEmotionTags(emotions) {
    return emotions.map(({ name, color }) => {
      const isActive = this.state.emotions.includes(name);
      const { t } = this.props;
      return (
        <EmotionTag
          onClick={() => this.onEmotionTagClicked(name)}
          color={color}
          active={isActive}
        >
          <Icon
            icon={`EMOTION_${name.toUpperCase()}`}
            size={20}
            color={isActive ? color : '#A7ADC7'}
          />
          <span>{t(name)}</span>
        </EmotionTag>
      );
    });
  }

  render() {
    return (
      <div>
        <div>{this.generateEmotionTags(this.positiveEmotions)}</div>
        <div>{this.generateEmotionTags(this.negativeEmotions)}</div>
      </div>
    );
  }
}

export default withTranslation('card')(RefineByEmotions);
