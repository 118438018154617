import React, { useState } from 'react';
import styled from 'styled-components';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from 'styles/abstracts/variables';
import { grayShades } from 'styles/abstracts/colors';

export const BackItem = styled(Item)`
  justify-content: flex-start !important;
  padding-left: 0.25em !important;
  color: ${grayShades.g800} !important;
`;

type OptionType = {
  id: string;
  label: string;
};

type CategoryBreakdownDropdownProps = {
  id: string;
  label: string;
  setOptionToDisplay: any;
  setSubOptionToDisplay: any;
  options: Array<OptionType>;
  t: any;
  settings: any;
  setSettings: any;
  settingsName: string;
};

const CategoryBreakdownDropdown = ({
  label,
  id,
  setOptionToDisplay,
  setSubOptionToDisplay,
  options,
  t,
  setSettings,
  settings,
  settingsName,
}: CategoryBreakdownDropdownProps) => {
  const [isDropdownOpen, openDropdown] = useState<boolean>(false);
  const [childrenContent, setChildrenContent] = useState<Array<
    OptionType
  > | null>(null);
  const [currentItem, setCurrentItem] = useState<OptionType>(
    options.find(
      opt => opt.id === settings?.categoryBreakdown?.[settingsName],
    ) || options[0],
  );
  return (
    <div id={id}>
      <Label>{t(label)}</Label>
      <StyledDropdown
        direction="down"
        isOpen={isDropdownOpen}
        toggle={() => openDropdown(!isDropdownOpen)}
      >
        <StyledDropdownToggle>
          <ToggleButton disableHover bgColor="grayShades.g300">
            {t(currentItem.label)}
            <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
          </ToggleButton>
        </StyledDropdownToggle>
        <Menu className="dataByFiltersDropdownMenu">
          {childrenContent ? (
            <>
              <BackItem onClick={() => setChildrenContent(null)} toggle={false}>
                <Icon
                  icon="INTERFACE_CHEVRON_LEFT"
                  size={ImageSize.medium}
                  color={grayShades.g800}
                />
                {t('Back')}
              </BackItem>
              {childrenContent.map(child => (
                <Item
                  disabled={currentItem.id === child.id}
                  onClick={() => {
                    setOptionToDisplay(currentItem.id);
                    setCurrentItem(child);
                    setSubOptionToDisplay?.(child.id);
                    setChildrenContent(null);
                  }}
                >
                  {t(child.label)}
                </Item>
              ))}
            </>
          ) : (
            options.map(option => {
              return (
                <Item
                  disabled={currentItem.id === option.id && !option.children}
                  toggle={!option.children}
                  onClick={() => {
                    if (option.children) {
                      setCurrentItem(option);
                      setChildrenContent(option.children);
                    } else {
                      !option.children && setCurrentItem(option);
                      setOptionToDisplay(option.id);
                      setSubOptionToDisplay?.(null);
                      setSettings({
                        ...settings,
                        categoryBreakdown: {
                          ...settings?.categoryBreakdown,
                          [settingsName]: option.id,
                        },
                      });
                    }
                  }}
                >
                  <span>{t(option.label)}</span>
                  {option.children && (
                    <Icon
                      icon="INTERFACE_CHEVRON_RIGHT"
                      size={ImageSize.medium}
                    />
                  )}
                </Item>
              );
            })
          )}
        </Menu>
      </StyledDropdown>
    </div>
  );
};

export default CategoryBreakdownDropdown;
