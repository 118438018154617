import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QFieldGroup from '../../Panels/QFieldGroup/QFieldGroup';
import { spacing } from '../../../styles/abstracts/variables';
import QSelect from './QSelect';
import { QSelectProps } from './QSelect.propTypes';

const StyledQFieldGroup = styled(QFieldGroup)`
  min-width: 350px;
  display: flex;
  padding: 0 ${spacing.large};
  margin: -${spacing.xsmall} 0;
  align-items: center;
`;

const StyledQFieldGroupControl = styled(QFieldGroup.Control)`
  min-width: 200px;
  padding-left: ${spacing.small};
  padding-top: 16px;
`;

const QSelectBox = ({ label, ...selectProps }) => (
  <StyledQFieldGroup>
    <QFieldGroup.Label>{label}</QFieldGroup.Label>
    <StyledQFieldGroupControl>
      <QSelect {...selectProps} />
    </StyledQFieldGroupControl>
  </StyledQFieldGroup>
);

QSelectBox.propTypes = {
  ...QSelectProps,
  label: PropTypes.string.isRequired,
};

export default QSelectBox;
