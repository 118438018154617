import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { getHorizontalBenchmarkGraphOption } from './utils';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

type ThematicsHorizontalBenchmarkGraphProps = {
  index: number;
  data: any[];
  indicators: any;
  horizontalIndicatorsConfig: any;
  eindexUnitFahrenheit: boolean;
  t: any;
  benchmarkHorizontalGraphLegend: { [index: string]: string };
};

const ThematicsHorizontalBenchmarkGraph = ({
  index,
  data,
  indicators,
  t,
  benchmarkHorizontalGraphLegend,
}: ThematicsHorizontalBenchmarkGraphProps) => {
  const chartRef = useRef(null);

  const handleDataZoom = () => {
    const chartInstance = chartRef?.current?.getEchartsInstance();

    if (chartInstance) {
      const yAxisData = chartInstance.getOption().yAxis[0].data || chartInstance.getOption().dataset[0].source || [];

      const zoomedInCategories = yAxisData.slice(
        Math.floor((yAxisData.length * chartInstance.getOption().dataZoom[1].start) / 100),
        Math.ceil((yAxisData.length * chartInstance.getOption().dataZoom[1].end) / 100),
      );

      const showLabels = zoomedInCategories.length <= 17;

      chartInstance.setOption({
        series: chartInstance.getOption().series.map(series => ({
          ...series,
          label: {
            ...series.label,
            show: showLabels,
          },
        })),
      });
    }
  };

  const onEvents = () => {
    return {
      datazoom: handleDataZoom,
    };
  };

  const options = getHorizontalBenchmarkGraphOption(data, indicators, t, index, benchmarkHorizontalGraphLegend);

  return (
    <div style={{ height: '100%', width: '100%', margin: 0 }}>
      {ReactEcharts ? (
        <ReactEcharts
          ref={chartRef}
          option={options}
          style={{ width: '100%', height: '100%' }}
          className="react_for_echarts"
          theme="QemotionTheme"
          onEvents={onEvents()}
        />
      ) : (
        ' '
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  eindexUnitFahrenheit: get(state, ['projectConfiguration', 'unitsAndLocalesSettings', 'eindexUnitFahrenheit']),
  filterKeys: get(state, ['filters', 'filterKeys']),
  benchmarkHorizontalGraphLegend: get(state, ['benchmark', 'horizontalGraphLegend']),
});

export default connect(mapStateToProps)(withTranslation('card', ['button'])(ThematicsHorizontalBenchmarkGraph));
