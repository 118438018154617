import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { useMutation, withApollo } from 'react-apollo';
import { cloneDeep, uniqBy } from 'lodash';
import QInput from '_Components/Fields/QInput/QInput';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import { green, purple, grayShades, red } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import updateMutation from '_Cards/SettingsCards/ProjectDetailsCard/graphql/updateReviewshakeSources';
import queryProjectDetails from 'Routes/Settings/ProjectDetails/graphql/getProjectDetails';
import DeleteSourceModal from '../DeleteSourceModal/DeleteSourceModal';
import GoogleModal from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/SourcesConfiguration/_Components/SourceConfigurationPanel/_Components/GoogleModal/GoogleModal';

const StyledInput = styled(QInput)`
  width: 100% !important;
  border: ${({ validUrl, isLink }) =>
    isLink && !validUrl && `2px solid ${red}`};
`;

const StyledCol = styled(Col)`
  display: inline-flex;
`;

const SaveButton = styled.span`
  margin-left: 1em !important;
`;
const Container = styled(Row)`
  margin-top: 1em;
`;

const ErrorMessage = styled.p`
  color: ${red};
  font-weight: bold;
  font-size: ${fontSizes.smaller};
`;

const SourceRow = ({
  data,
  source,
  sources,
  projectId,
  slug,
  projectSourceItem,
  setSources,
  t,
  client,
  setSourceAdded,
  setError,
  error,
  projectDetails
}) => {
  const [profile, setProfile] = useState(data.profile || '');
  const [profileName, setProfileName] = useState(data.profileName || '');
  const [profileValidity, setProfileValidity] = useState(
    data.profileState || 'valid_url',
  );
  const [isModalOpen, openModal] = useState(false);
  const [searchTermLength, setSearchTermLength] = useState(0);
  const [placeIDLength, setPlaceIDLength] = useState(0);
  const [isGoogleModalOpen, openGoogleModal] = useState(false);

  const index = sources.findIndex(src => data.profileName === src.profileName);

  useEffect(() => {
    setProfileName(data.profileName);
    setProfile(data.profile);
  }, [data]);

  const [updateReviewshakeProjectSources] = useMutation(updateMutation, {
    refetchQueries: [
      { query: queryProjectDetails, variables: { id: projectId, slug } },
    ],
    update: (cache, { data: { updateReviewshakeSources } }) => {
      try {
        const projectDetailsConfig = cache.readQuery({
          query: queryProjectDetails,
          variables: {
            id: projectId,
            slug,
          },
        }).getProjectDetails;
        let newSources = projectDetailsConfig.sources;
        if (data.id) {
          const sourceToReplace = newSources[index];
          sourceToReplace.profile = updateReviewshakeSources.profile;
          sourceToReplace.profileName = updateReviewshakeSources.profileName;
          newSources[index] = sourceToReplace;
        } else {
          newSources = [
            ...newSources,
            {
              id: updateReviewshakeSources.id,
              profile: updateReviewshakeSources.profile,
              profileName: updateReviewshakeSources.profileName,
              projectSourceItem,
              source,
              profileState: 'valid_url',
            },
          ];
        }
        setSources(uniqBy(newSources, s => s.id));
        cache.writeQuery({
          query: queryProjectDetails,
          variables: { id: projectId, slug },
          data: {
            getProjectDetails: {
              sources: uniqBy(newSources, s => s.id),
              ...projectDetailsConfig,
            },
          },
        });
      } catch (e) {
        const projectDetailsConfig = projectDetails;
        let newSources = projectDetailsConfig.sources;
        if (data.id) {
          const sourceToReplace = newSources[index];
          sourceToReplace.profile = updateReviewshakeSources.profile;
          sourceToReplace.profileName = updateReviewshakeSources.profileName;
          newSources[index] = sourceToReplace;
        } else {
          newSources = !updateReviewshakeSources.errors
            ? [
                ...newSources,
                {
                  id: updateReviewshakeSources.id,
                  profile: updateReviewshakeSources.profile,
                  profileName: updateReviewshakeSources.profileName,
                  projectSourceItem,
                  source,
                  profileState: 'valid_url',
                },
              ]
            : newSources;
        }
        setSources(uniqBy(newSources, s => s.id));
        cache.writeQuery({
          query: queryProjectDetails,
          variables: { id: projectId, slug },
          data: {
            getProjectDetails: {
              sources: uniqBy(newSources, s => s.id),
              ...projectDetailsConfig,
            },
          },
        });
      }
    },
  });

  const isValidPlaceID = placeID =>
    placeID?.length >= 27 && !/\s/g.test(placeID);

  const isValidUrl = url => {
    if (String(url) !== url) return false;
    const regex = /(https?:\/\/)?(www\.)?\w{2,}(\.\w{2,}){1,}/g;
    const didMatch = url.match(regex);
    return Array.isArray(didMatch);
  };

  return (
    <Container>
      <Col xs={3}>
        <StyledInput
          value={profileName}
          onChange={e => setProfileName(e.target.value)}
          // disabled={profileName?.length > 0 && profileName === data.profileName}
          onBlur={() => setProfileName(profileName?.trim())}
        />
      </Col>
      {data.source !== 'GooglereviewsPage' ? (
        <Col xs={8}>
          <StyledInput
            value={profile}
            onChange={e => setProfile(e.target.value)}
            onBlur={() => setProfile(profile?.trim())}
            isLink
            validUrl={profileValidity === 'valid_url'}
          />
          {profileValidity === 'invalid_url' && (
            <ErrorMessage>This link is not valid.</ErrorMessage>
          )}
        </Col>
      ) : (
        <>
          <Col xs={8}>
            <StyledInput
              value={searchTermLength === 0 ? profile : ''}
              onClick={() => openGoogleModal(true)}
              onBlur={() => setProfile(profile?.trim())}
              disabled={searchTermLength > 0}
              isLink
              validUrl={profileValidity === 'valid_url'}
            />
            <GoogleModal
              open={openGoogleModal}
              isOpen={isGoogleModalOpen}
              setGooglePlaceIDValue={setProfile}
            />
            {profileValidity === 'invalid_url' && (
              <ErrorMessage>This place ID is invalid.</ErrorMessage>
            )}
          </Col>
        </>
      )}
      <StyledCol xs={1}>
        <IconCircle
          icon="CROSS"
          color={purple}
          size={20}
          pointer
          onClick={() => {
            if (data.id) {
              openModal(!isModalOpen);
            } else {
              const cloneSources = cloneDeep(sources);
              const cloneSrc = cloneSources.filter(
                src => src.id,
              );
              setSources(cloneSrc);
            }
          }}
        />
        <DeleteSourceModal
          projectId={projectId}
          ids={[data.id]}
          isModalOpen={isModalOpen}
          openModal={openModal}
          slug={slug}
          source={data}
          setSources={setSources}
          t={t}
        />
        <SaveButton>
          <IconCircle
            icon="CHECK_ALL"
            color={
              profile?.length > 0 &&
              profileName?.length > 0 &&
              ((searchTermLength === 0 && placeIDLength === 0) ||
                (searchTermLength > 0 && !isValidPlaceID(profile)) ||
                (searchTermLength === 0 &&
                  isValidPlaceID(profile) &&
                  placeIDLength >= 25)) &&
              (data.profile !== profile || data.profileName !== profileName)
                ? green
                : grayShades.g800
            }
            size={20}
            pointer={
              profile?.length > 0 &&
              profileName?.length > 0 &&
              ((searchTermLength === 0 && placeIDLength === 0) ||
                (searchTermLength > 0 && !isValidPlaceID(profile)) ||
                (searchTermLength === 0 &&
                  isValidPlaceID(profile) &&
                  placeIDLength >= 25)) &&
              (data.profile !== profile || data.profileName !== profileName)
            }
            onClick={() => {
              profile?.length > 0 &&
                profileName?.length > 0 &&
                ((searchTermLength === 0 && placeIDLength === 0) ||
                  (searchTermLength > 0 && !isValidPlaceID(profile)) ||
                  (searchTermLength === 0 &&
                    isValidPlaceID(profile) &&
                    placeIDLength >= 25)) &&
                (data.profile !== profile ||
                  data.profileName !== profileName) &&
                updateReviewshakeProjectSources({
                  variables: {
                    id: data.id,
                    projectId,
                    profile,
                    profileName,
                    profileTypeId: data?.projectSourceItem?.id,
                  },
                }).then(({ data }) => {
                  if (data?.updateReviewshakeSources?.errors) {
                    setError(data?.updateReviewshakeSources?.errors[0]);
                    setTimeout(() => setError(null), 3000);
                  } else {
                    setSourceAdded(true);
                    setProfileValidity('valid_url');
                    setTimeout(() => setSourceAdded(false), 3000);
                  }
                });
            }}
          />
        </SaveButton>
      </StyledCol>
    </Container>
  );
};

export default withApollo(SourceRow);
