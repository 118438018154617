import styled from 'styled-components';
import { input } from '../styles';

const { color, radius, 'background-color': bgColor, padding } = input;

const QInput = styled.input`
  border: none;
  border-radius: ${radius};

  outline: none;

  color: ${color};
  background-color: ${bgColor};

  padding: ${padding};

  font-weight: ${({ primary }) => (primary ? 'bold' : 'normal')};
`;

export default QInput;
