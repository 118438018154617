import React, { createRef, useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { green, themeColors, white } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QSelect from '_Components/Fields/QSelect/QSelect';
import { withTranslation } from 'react-i18next';
import Loader from '_Components/Loader/OnLoad';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { selectClusterDetails } from '_Resources/Cluster/Actions/selectClusterDetails';
import GET_CLUSTERS from '_Resources/Cluster/Services/api/graphql/getClusters';
import { loadClusters } from '_Resources/Cluster/Actions/loadClusters';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import { TooltipText } from '../utils/TooltipText';
import ClusterDetailModal from './ClusterDetailModal';

const IrritantsAndEnchantingPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const OrderByCol = styled(Col)`
  margin-left: auto;
  margin-right: auto;
  flex-wrap: nowrap;
`;

const SelectLabel = styled.span`
  font-size: ${fontSizes.smaller};
  font-weight: bold;
  float: ${props => props.position};
  margin-right: ${props => (props.position === 'right' ? '1em' : '0')};
`;

const OrderBySelect = styled(QSelect)`
  width: 100% !important;
`;

const IrritantsAndEnchantingPointsHeader = styled.div`
  height: 3em;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const IrritantsAndEnchantingPoints = styled.div<{ loading: boolean; noData: boolean}>`
  height: ${(noData) => noData ? '75%' : '100%'};
  display: flex;
  flex-direction: row;
  flex-wrap: ${loading => (loading ? 'wrap' : 'no-wrap')};
`;

const IrritantsContainer = styled.div<{ loading: boolean }>`
  height: ${loading => (loading ? 'auto' : '100%')};
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0.5em;
`;

const EnchantingPointsContainer = styled.div<{ loading: boolean }>`
  height: ${loading => (loading ? 'auto' : '100%')};
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
`;

const GraphItem = styled.div`
  display: flex;
  font-weight: bolder;
  margin-bottom: 1em;
  width: 100%;
  cursor: pointer;
`;

const IrritantTitleContainer = styled.div`
  position: relative;
  width: calc(50% - 3em);
`;

const IrritantTitleText = styled.div`
  line-height: 1;
  height: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
`;

const EnchantmentTitleContainer = styled.div`
  position: relative;
  width: calc(50% - 3em);
`;

const EnchantmentTitleText = styled.div`
  position: relative;
  height: 1em;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const GraphBarContainer = styled.div`
  display: flex;
  min-width: 50%;
  margin-right: 0.8em;
  margin-left: 0.8em;
  margin-top: 0.2em;
`;

const IrritantGraphBar = styled.div<{ width: number }>`
  background-color: black;
  height: 1em;
  border-radius: 2em;
  min-width: ${({ width }) => width}%;
`;

const EnchantingGraphBar = styled.div<{ width: number }>`
  background-color: ${green};
  height: 1em;
  border-radius: 2em;
  min-width: ${({ width }) => width}%;
`;

const IrritantsSectionTitle = styled.div`
  color: black;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 1em;
  text-align: center;
  width: 100%;
`;

const EnchantingSectionTitle = styled.div`
  color: ${green};
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 1em;
  text-align: center;
  width: 100%;
`;

const LoaderContainer = styled.div`
  height: 87%;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoDataContainer = styled.div`
  height: 87%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IrritantsAndEnchantingPointsCard = ({
  filters,
  t,
  selectClusterDetails,
  loadClusters,
  cluster,
}) => {
  const [selectedSortByOption, setSelectedSortByOption] = useState({
    value: 'DESC',
    label: t('button:Verbatims - Descending'),
  });
  const [irritantTooltipShow, setIrritantTooltipShow] = useState(false);
  const [enchantmentTooltipShow, setEnchantmentTooltipShow] = useState(false);
  const [tooltipIndex, setTooltipIndex] = useState(0);
  const [coord, setCoord] = useState({ x: 0, y: 0 });
  const [isDetailsClusterModalOpen, setIsDetailsClusterModalOpen] = useState(
    false,
  );
  const onMouseMove = (e: any) => {
    setCoord({
      x: e.clientX - 50,
      y: e.clientY - 30,
    });
  };
  const onSortByChange = (sortByOption: { value: string; label: string }) => {
    setSelectedSortByOption(sortByOption);
  };
  const { projectId, range } = filters || {};
  const sortByOptions = [
    {
      value: 'ASC',
      label: t('button:Verbatims - Ascending'),
    },
    {
      value: 'DESC',
      label: t('button:Verbatims - Descending'),
    },
  ];
  const { data, loading, refetch } = useQuery(GET_CLUSTERS, {
    variables: {
      projectId,
      range,
      verbatimsCountSort: selectedSortByOption.value,
    },
    fetchPolicy: 'network-only',
  });
  const { clusters } = cluster;
  const [irritantsRefs, setIrritantsRefs] = useState([]);
  const [enchantmentsRefs, setEnchantmentsRefs] = useState([]);

  useEffect(() => {
    refetch({
      projectId,
      range,
      verbatimsCountSort: selectedSortByOption.value,
    }).then(updatedClustersData => {
      loadClusters(updatedClustersData?.data?.getClusters);
    });
  }, [selectedSortByOption.value, data]);

  useEffect(() => {
    setIrritantsRefs(irrRefs =>
      Array(data?.getClusters?.irritants.length)
        .fill()
        .map((irr, i) => irrRefs[i] || createRef()),
    );
    setEnchantmentsRefs(encRefs =>
      Array(data?.getClusters?.enchantements.length)
        .fill()
        .map((enc, i) => encRefs[i] || createRef()),
    );
  }, [
    data?.getClusters?.irritants.length,
    data?.getClusters?.enchantements.length,
  ]);

  const irritantMaxVerbatimsCount = data?.getClusters?.irritants.reduce(
    (acc, irritant) =>
      (acc = acc > irritant.verbatimsCount ? acc : irritant.verbatimsCount),
    0,
  );
  const enchantmentMaxVerbatimsCount = data?.getClusters?.enchantements.reduce(
    (acc, enchantment) =>
      (acc =
        acc > enchantment.verbatimsCount ? acc : enchantment.verbatimsCount),
    0,
  );
  const maxVerbatimsCount =
    irritantMaxVerbatimsCount > enchantmentMaxVerbatimsCount
      ? irritantMaxVerbatimsCount
      : enchantmentMaxVerbatimsCount;

  const onClusterModalClose = (refetchDataOnClose: boolean = false) => {
    setIsDetailsClusterModalOpen(false);
    selectClusterDetails({
      clickedCluster: {
        id: '',
        title: '',
        size: 0,
        verbatimsCount: 0,
        hasParents: false,
      },
      clickedClusterTone: '',
    });
    if (refetchDataOnClose) {
      refetch({
        projectId,
        range,
        verbatimsCountSort: selectedSortByOption.value,
      }).then(result => {
        loadClusters(result?.data?.getClusters);
      });
    }
  };

  const onClusterClicked = (
    clickedCluster: {
      id: string;
      title: string;
      size: number;
      verbatimsCount: number;
      hasParents: boolean;
    },
    clickedClusterTone: string = 'irritant',
  ) => {
    selectClusterDetails({ clickedCluster, clickedClusterTone });
    setIsDetailsClusterModalOpen(true);
  };

  return (
    <>
      <IrritantsAndEnchantingPointsContainer>
        <IrritantsAndEnchantingPointsHeader>
          <OrderByCol xs={3} className="selectMinWidth">
            <SelectLabel position="left">{t('button:Sort by')}</SelectLabel>
            <br />
            <OrderBySelect
              name="orderBySelect"
              value={selectedSortByOption}
              searchable={false}
              clearable={false}
              placeholder={t('button:Sort by')}
              onChange={onSortByChange}
              options={sortByOptions}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </OrderByCol>
        </IrritantsAndEnchantingPointsHeader>
        <IrritantsAndEnchantingPoints
          onMouseMove={onMouseMove}
          loading={loading}
          noData={!clusters}
        >
          <IrritantsContainer loading={loading}>
            <IrritantsSectionTitle>
              {t('Irritant points')} : {clusters?.irritants?.length}
            </IrritantsSectionTitle>
            {clusters?.irritants &&
              clusters?.irritants.map((irritant, index) => {
                return (
                  <GraphItem
                    key={irritant.id}
                    style={{
                      justifyContent: 'end',
                      textAlign: 'end',
                    }}
                    onClick={() => onClusterClicked(irritant, 'irritant')}
                  >
                    <IrritantTitleContainer>
                      <IrritantTitleText
                        ref={irritantsRefs[index]}
                        onMouseEnter={() => {
                          setIrritantTooltipShow(true);
                          setTooltipIndex(index);
                        }}
                        onMouseOut={() => {
                          setIrritantTooltipShow(false);
                        }}
                      >
                        {irritant.title}
                      </IrritantTitleText>
                    </IrritantTitleContainer>
                    {irritantsRefs[index]?.current?.clientWidth <
                      irritantsRefs[index]?.current?.scrollWidth - 3 && (
                      <TooltipText
                        className="tooltipText"
                        show={irritantTooltipShow && index === tooltipIndex}
                        style={{
                          position: 'fixed',
                          top: 0,
                          left: coord.x,
                          maxWidth: '20em',
                          overflow: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          height: `${coord.y}px`,
                          backgroundColor: 'transparent',
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            bottom: 0,
                            backgroundColor: themeColors.primary,
                            color: white,
                            borderRadius: '6px',
                            padding: '5px 12px',
                          }}
                        >
                          {irritant.title}
                        </div>
                      </TooltipText>
                    )}
                    <GraphBarContainer
                      style={{
                        justifyContent: 'end',
                      }}
                    >
                      <IrritantGraphBar
                        width={
                          (irritant.verbatimsCount / maxVerbatimsCount) * 100
                        }
                      />
                    </GraphBarContainer>
                    <span>{irritant.verbatimsCount}</span>
                  </GraphItem>
                );
              })}
          </IrritantsContainer>
          <EnchantingPointsContainer loading={loading}>
            <EnchantingSectionTitle>
            {t('Enchantment points')} : {clusters?.enchantements?.length}
            </EnchantingSectionTitle>
            {clusters?.enchantements &&
              clusters?.enchantements.map((enchantment, index) => {
                return (
                  <GraphItem
                    key={enchantment.id}
                    irritant={false}
                    onClick={() =>
                      onClusterClicked(enchantment, 'enchantment')
                    }
                  >
                    <span>{enchantment.verbatimsCount}</span>
                    <GraphBarContainer
                      style={{
                        justifyContent: 'start',
                      }}
                    >
                      <EnchantingGraphBar
                        width={
                          (enchantment.verbatimsCount / maxVerbatimsCount) *
                          100
                        }
                      />
                    </GraphBarContainer>
                    <EnchantmentTitleContainer>
                      <EnchantmentTitleText
                        ref={enchantmentsRefs[index]}
                        onMouseEnter={() => {
                          setEnchantmentTooltipShow(true);
                          setTooltipIndex(index);
                        }}
                        onMouseOut={() => {
                          setEnchantmentTooltipShow(false);
                        }}
                      >
                        {enchantment.title}
                      </EnchantmentTitleText>
                    </EnchantmentTitleContainer>
                    {enchantmentsRefs[index]?.current?.clientWidth <
                      enchantmentsRefs[index]?.current?.scrollWidth - 3 && (
                      <TooltipText
                        className="tooltipText"
                        show={enchantmentTooltipShow && index === tooltipIndex}
                        style={{
                          position: 'fixed',
                          top: 0,
                          left: coord.x,
                          maxWidth: '20em',
                          overflow: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          height: `${coord.y}px`,
                          backgroundColor: 'transparent',
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            bottom: 0,
                            backgroundColor: themeColors.primary,
                            color: white,
                            borderRadius: '6px',
                            padding: '5px 12px',
                          }}
                        >
                          {enchantment.title}
                        </div>
                      </TooltipText>
                    )}
                  </GraphItem>
                );
              })}
          </EnchantingPointsContainer>
          {loading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          {!clusters && (
            <NoDataContainer>
              <NoDataMessage secondText="Not enough data for irritants or enchanting points" />
            </NoDataContainer>
          )}
        </IrritantsAndEnchantingPoints>
      </IrritantsAndEnchantingPointsContainer>
      {isDetailsClusterModalOpen && (
        <ClusterDetailModal
          onClose={onClusterModalClose}
          isOpen={!!isDetailsClusterModalOpen}
          verbatimsCountSort={selectedSortByOption.value}
          refetch={refetch}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  insight: { insights, insightDetails },
  filter,
  projectConfiguration,
  cluster,
}) => ({
  insights,
  insightDetails,
  projectId: get(filter, 'filter_keys.project_id'),
  cluster,
  sat: get(projectConfiguration, ['unitsAndLocalesSettings', 'sat']),
  reco: get(projectConfiguration, ['unitsAndLocalesSettings', 'reco']),
  ces: get(projectConfiguration, ['unitsAndLocalesSettings', 'ces']),
  eindexUnitFahrenheit: get(projectConfiguration, [
    'unitsAndLocalesSettings',
    'eindexUnitFahrenheit',
  ]),
});

const mapDispatchToProps = (dispatch: any) => ({
  selectClusterDetails: (newState: any) => {
    dispatch(selectClusterDetails(newState));
  },
  loadClusters: (newState: any) => {
    dispatch(loadClusters(newState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('card')(IrritantsAndEnchantingPointsCard));
