import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QLegend from '_Components/QLegend/QLegend';
import styled from 'styled-components';
import QScale from '_Components/QScale/QScale';
import InsightPanel from '../../../../../Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightPanel/InsightPanel';
import colorInsightTiles from '../../../../../Insights/InsightsWall/_Containers/InsightWallGrid/_Containers/Grid/_Utils/presentation/utils/colors/colors';

const StyledInsightPanel = styled(InsightPanel)<{ nbOfColumns: number }>`
  width: fit-content;
  margin: ${({ nbOfColumns }) =>
    nbOfColumns > 1 ? '1em 1em 0 1em' : '0 0.5em'};
  padding: ${({ nbOfColumns }) => (nbOfColumns > 1 ? '0.75em' : '0.75em 0')};
`;

type BenchmarkGroupCardEindexLegendProps = {
  category: string;
  max: string;
  min: string;
  nbOfColumns: number;
  t: any;
  className: string | '';
};

const BenchmarkGroupCardEindexLegend = ({
  category,
  max,
  min,
  nbOfColumns,
  t,
  className = '',
}: BenchmarkGroupCardEindexLegendProps) => {
  const prepareColors = () => {
    const colorsConfigs = colorInsightTiles(category);
    return colorsConfigs
      .map(({ backgroundColor }) => backgroundColor)
      .reverse();
  };

  const colors = prepareColors();
  const range = { min: min || 'min', max: max || 'max' };
  return (
    <>
      {nbOfColumns < 2 && (
        <StyledInsightPanel className={className} nbOfColumns={nbOfColumns}>
          <QLegend colors={colors} range={range} title={t('E-index')} />
        </StyledInsightPanel>
      )}
      {nbOfColumns > 1 && <QScale colors={colors} range={range} />}
    </>
  );
};

BenchmarkGroupCardEindexLegend.propTypes = {
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  nbOfColumns: PropTypes.number.isRequired,
};

BenchmarkGroupCardEindexLegend.defaultProps = {
  className: '',
};

export default withTranslation('card')(BenchmarkGroupCardEindexLegend);
