import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fontSizes } from 'styles/abstracts/typography';
import { CheckboxContainer, RadioButton } from '../CLineChartSettings';
import { LABEL } from '../../../../Routes/GlobalExperience/Reporting/_Containers/configs/settingOptions';
import { MenuItemLabel } from '../../../../Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85%;
  font-size: ${fontSizes.bigger};
  margin-bottom: 0.5em;
`;

class LabelSettings extends React.Component {
  labelOptionCheckboxClicked = label => {
    const { onSettingsChange, settings } = this.props;
    onSettingsChange({ ...settings, label });
  };

  render() {
    const { t, settings } = this.props;
    const { label } = settings;
    return (
      <div>
        <br />
        <TitleSection>{t('Labels')}</TitleSection>
        {Object.keys(LABEL).map(labelOption => (
          <>
            <CheckboxContainer>
              <MenuItemLabel checked={label === LABEL[labelOption].id}>
                <RadioButton
                  type="radio"
                  checked={label === LABEL[labelOption].id}
                  onChange={() => this.labelOptionCheckboxClicked(LABEL[labelOption].id)}
                  value={LABEL[labelOption].id}
                />
              </MenuItemLabel>
              {t(LABEL[labelOption].label)}
            </CheckboxContainer>
            <br />
          </>
        ))}
      </div>
    );
  }
}

export default withTranslation('card')(LabelSettings);
