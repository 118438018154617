import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { grayShades } from 'styles/abstracts/colors';
import QTabs from '../../../_Components/Panels/QTabs/QTabs';
import QTab from '../../../_Components/Panels/QTabs/tab/QTab';

const Container = styled.div`
  padding-left: 5em;
  border-bottom: 1px solid ${grayShades.g500};
`;

const USERS_TABS = [
  { id: 'groups', label: 'Groups' },
  { id: 'users', label: 'Users' },
];

const UsersSubHeader = ({ usersTab, setUsersTab, t }) => {
  return (
    <Container>
      <QTabs onTabChanged={tabId => setUsersTab(tabId)} initialPage={usersTab}>
        {USERS_TABS.map(tab => (
          <QTab id={tab.id}>{t(tab.label)}</QTab>
        ))}
      </QTabs>
    </Container>
  );
};

export default withTranslation('card')(UsersSubHeader);
