import { ActionTypes } from '../../../constants';

// SHOULD BE DELETED OR MOVED AFTER EMOTIONS TAB REFACTOR IN THEMATICS BY EMOTION
export function sendEmotionSelected(value) {
  return {
    type: ActionTypes.SEND_EMOTION_SELECTED,
    payload: {
      value,
    },
  };
}
