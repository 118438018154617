import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { getRoute } from '_Utils/routes/routeUtils';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import ProjectConfigurationCard from '_Cards/SettingsCards/ProjectsConfigurationCard/ProjectConfigurationCard';

const ProjectConfiguration = ({
  idpage,
  updateRoute,
  activeStep,
  setActiveStep,
  steps,
  isProcessingToastOpen,
  toggleProcessingToast,
  history,
  location,
}) => {
  useEffect(() => {
    getRoute(updateRoute, idpage);
  }, []);
  return (
    <div>
      <Container fluid className="py-3 px-4">
        <ProjectConfigurationCard
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          steps={steps}
          isProcessingToastOpen={isProcessingToastOpen}
          toggleProcessingToast={toggleProcessingToast}
          history={history}
          location={location}
        />
      </Container>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateRoute: newState => {
    dispatch(updateRoute(newState));
  },
});

export default connect(null, mapDispatchToProps)(ProjectConfiguration);
