import { getMarkerColorFactory } from "./utils";
import { modes } from "../../config/modesConfig";
import { findMode } from "../../utils/modesHelpers";

const prepareGetMarkerColor = isFahrenheit => {
  const { celsius, fahrenheit } = modes;
  const { ranges } = findMode("eindex", isFahrenheit ? fahrenheit : celsius);
  return getMarkerColorFactory(ranges);
};

function prepareMarkerColor(color) {
  return {
    fillColor: color
  };
}

function prepareMarkerLabel(temperature) {
  return {
    label: {
      color: "#FFFFFF",
      fontWeight: "900",
      text: `${temperature}°`
    }
  };
}

export function transform(
  indicatorOptions,
  markerOptions,
  { isFahrenheit } = {}
) {
  const { value: temperature } = indicatorOptions;
  const getColors = prepareGetMarkerColor(isFahrenheit);
  const { color } = getColors(temperature);

  return {
    ...markerOptions,
    icon: {
      ...markerOptions.icon,
      ...prepareMarkerColor(color)
    },
    ...prepareMarkerLabel(temperature)
  };
}
