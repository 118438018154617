/* eslint-disable react/sort-comp */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QTitle from '_Components/Texts/QTitle/QTitle';
import {
  grayShades,
  black,
  white,
  danger,
  opacity,
} from 'styles/abstracts/colors';
import { capitalize } from '_Utils/strings/stringsUtils';
import VerbatimDetails from '_Components/QVerbatim/_Containers/VerbatimDetails/VerbatimDetails';
import { fontSizes } from 'styles/abstracts/typography';
import QButton from '_Components/Buttons/QButton/QButton';
import Icon from '_Components/Icons/Icon';
import removeVerbatimFromCluster from '_Resources/Cluster/Services/api/graphql/removeVerbatimFromCluster';
import { useMutation } from 'react-apollo';
import getClusterVerbatims from '_Resources/Cluster/Services/api/graphql/getClusterVerbatims';
import { uniq } from 'lodash';
import { selectClusterDetails } from '_Resources/Cluster/Actions/selectClusterDetails';
import { connect } from 'react-redux';
import Loader from '_Components/Loader/OnLoad';
import DeleteClusterButton from './DeleteClusterButton';
import MergeClusterButton from './MergeClusterButton';
import UnmergeClusterButton from './UnmergeClusterButton';

const VerbatimHeaderPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 1em 0.2em 1em;
  justify-content: flex-start;
`;

const VerbatimHeaderInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 1em 0.2em 1em;
  justify-content: start;
  width: 100%;
  font-weight: bold;
  color: ${grayShades.g800};
  font-size: 14px;
  font-size: 0.9rem;
`;

const VerbatimHeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 1em 0.2em 1em;
  width: 100%;
`;

const StyledQTitle = styled(QTitle)`
  color: ${black};
`;

const VerbatimModePanel = styled.div`
  flex: 1;
  align-content: flex-end;
  flex-direction: row-reverse;
  display: flex;
  & > {
    align-self: flex-end;
  }
`;

const VerbatimSection = styled.div`
  background: #f7f9ff;
  max-height: calc(100vh - 630px);
  min-height: 200px;
  width: 100%;
  overflow: scroll;
`;

const VerbatimBody = styled.div`
  cursor: 'pointer';
  position: relative;
  width: 100%;
  display: flex;
  padding: 0.5em;
  align-items: center;
`;

const VerbatimText = styled.div`
  background-color: white;
  flex-grow: 1;
  width: 70%;
  margin: 0.5em;
  margin-bottom: 0;
  padding: 1em;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: #e1e4ed;
`;

const VerbatimDetailsBtnContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
  position: absolute;
  bottom: 1em;
  right: 0.5em;
`;

const VerbatimDetailsBtn = styled.span`
  border: 1px solid;
  border-radius: 20px;
  font-size: ${fontSizes.smallest};
  font-weight: bolder;
  padding: 0.5em;
  color: ${grayShades.g800};
  background-color: ${white};
  cursor: pointer;
`;

const StyledCloseModalCrossButton = styled(QButton)`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  right: 1em;
  top: 1em;
  padding: 0 !important;
`;

const VerbatimDeleteIcon = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;

  cursor: pointer;
  position: absolute;
  transform: translate(-25%, 25%);
  top: 0;
  right: 0;
  padding: 0.25em;
  border-radius: 20px;
  background-color: #8784a7;

  &:hover {
    background-color: ${danger};
    color: white;
  }

  ${VerbatimBody}:hover & {
    visibility: visible;
  }

  & > svg {
    transform: translateY(-1px);
    flex-grow: 1;
  }
`;

const ClusterVerbatims = ({
  clusterVerbatims,
  clusterId,
  t,
  projectId,
  closeModal,
  range,
  verbatimsCountSort,
  refetch,
  cluster,
  dispatchSelectClusterDetails,
}) => {
  const HTMLSymbolsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
  };

  const HTMLSymbolsRegEx = new RegExp(
    Object.keys(HTMLSymbolsToReplace).join('|'),
    'gi',
  );

  const escapeHtml = (string: string) => {
    return string.replace(HTMLSymbolsRegEx, matched => {
      return HTMLSymbolsToReplace[matched.toLowerCase()];
    });
  };

  const removeTrailingDotsAndSpaces = (sentence: string) => {
    let newSentence = sentence;
    if (newSentence[newSentence.length - 1] === '.') {
      newSentence = newSentence.slice(0, -1);
      removeTrailingDotsAndSpaces(newSentence);
    }
    if (newSentence[newSentence.length - 1] === ' ') {
      newSentence = newSentence.slice(0, -1);
      removeTrailingDotsAndSpaces(newSentence);
    }
    return newSentence;
  };

  const getVerbatimText = (text: string, mainSentences: Array<string>) => {
    let newText = escapeHtml(text).trim().split('  ').join(' ');
    const uniqueMainSentences = uniq(mainSentences);

    uniqueMainSentences.map(sentence => {
      newText = newText.replaceAll(
        removeTrailingDotsAndSpaces(escapeHtml(sentence).trim()),
        `<b>${escapeHtml(sentence).trim()}</b>`,
      );
      return newText;
    });
    return newText;
  };

  const [seeDetails, toggleSeeDetails] = useState(false);

  const [detailsBtnIndex, setDetailsBtnIndex] = useState(0);

  const [isModalOpen, toggleModal] = useState(false);

  const [verbatimId, setVerbatimId] = useState(0);

  const modalFilters = {
    id: verbatimId,
    type: 'VERBATIM',
  };

  const { id, size, title, hasParents } = cluster?.clickedCluster || {};

  const [removeVerbatim, { data, loading, error }] = useMutation(
    removeVerbatimFromCluster,
    {
      refetchQueries: [
        {
          query: getClusterVerbatims,
          variables: {
            projectId,
            clusterId,
            range,
          },
        },
      ],
      update: (cache, { data: { removeVerbatimFromCluster } }) => {
        const cachedData = cache.readQuery({
          query: getClusterVerbatims,
          variables: { projectId, clusterId, range },
        });
        const updatedVerbatimList = cachedData.getClusterVerbatims.verbatimList.filter(
          verbatim => verbatim.id !== verbatimId,
        );
        const updatedVerbatimsCount =
          cachedData.getClusterVerbatims.verbatimsCount - 1;
        const updatedData = {
          verbatimList: updatedVerbatimList,
          verbatimsCount: updatedVerbatimsCount,
        };

        cache.writeQuery({
          query: getClusterVerbatims,
          variables: { projectId, clusterId, range },
          data: updatedData,
        });
        dispatchSelectClusterDetails({
          clickedCluster: {
            id,
            size,
            title,
            hasParents,
            verbatimsCount: updatedVerbatimsCount,
          },
          clickedClusterTone: cluster.clickedClusterTone,
        });
      },
    },
  );

  const buttonRight = (
    <StyledCloseModalCrossButton
      bgColor="greyShades.g300"
      type="button"
      onClick={() => toggleModal(false)}
      size="small"
      icon="CROSS"
      iconSize={24}
    />
  );

  return (
    <>
      <div>
        <VerbatimHeaderPanel>
          <VerbatimHeaderInfosContainer>
            <p>ID : {clusterId}</p>
            <p>
              {t('Tone')} : {(t(capitalize(cluster.clickedClusterTone)))}
            </p>
            <p>
              {t('Status')} :{' '}
              {t(
                capitalize(
                  cluster.clickedCluster.hasParents ? 'Merged' : 'Unmerged',
                ),
              )}
            </p>
          </VerbatimHeaderInfosContainer>
          <VerbatimHeaderActionsContainer>
            <StyledQTitle>
              <QTitle.Title>
                {cluster.clickedCluster.verbatimsCount || 0} {t('verbatims')}{' '}
              </QTitle.Title>
            </StyledQTitle>
            <VerbatimModePanel />
            <MergeClusterButton
              clusterId={clusterId}
              projectId={projectId}
              range={range}
              closeModal={closeModal}
              t={t}
              verbatimsCountSort={verbatimsCountSort}
              refetch={refetch}
            />
            <UnmergeClusterButton
              t={t}
              projectId={projectId}
              clusterId={clusterId}
              closeModal={closeModal}
              range={range}
              verbatimsCountSort={verbatimsCountSort}
            >
              <span>{t('Unmerge cluster')}</span>
            </UnmergeClusterButton>
            <DeleteClusterButton
              clusterId={clusterId}
              projectId={projectId}
              range={range}
              closeModal={closeModal}
              t={t}
            />
          </VerbatimHeaderActionsContainer>
        </VerbatimHeaderPanel>

        {clusterVerbatims && (
          <>
            <VerbatimSection>
              {clusterVerbatims &&
                clusterVerbatims.map((verbatim: any, index: number) => {
                  return (
                    <VerbatimBody
                      key={verbatim.id}
                      onMouseEnter={() => {
                        toggleSeeDetails(true);
                        setDetailsBtnIndex(index);
                      }}
                      onMouseLeave={() => toggleSeeDetails(false)}
                    >
                      <VerbatimText>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: getVerbatimText(
                              verbatim.text,
                              verbatim.mainSentences,
                            ),
                          }}
                        />
                      </VerbatimText>
                      {seeDetails && detailsBtnIndex === index && (
                        <VerbatimDetailsBtnContainer>
                          <VerbatimDetailsBtn
                            onClick={() => {
                              toggleModal(!isModalOpen);
                              setVerbatimId(verbatim.id);
                            }}
                          >
                            {t('button:See details')}
                          </VerbatimDetailsBtn>
                        </VerbatimDetailsBtnContainer>
                      )}
                      <VerbatimDeleteIcon
                        onClick={() => {
                          removeVerbatim({
                            variables: {
                              projectId,
                              clusterId,
                              verbatimId: verbatim.id,
                            },
                          });
                        }}
                      >
                        {!loading && (
                          <Icon icon="TRASH" size={15} color="white" />
                        )}
                        {loading && <Loader color={white} />}
                      </VerbatimDeleteIcon>
                    </VerbatimBody>
                  );
                })}
            </VerbatimSection>
            {isModalOpen && (
              <VerbatimDetails
                isOpen={isModalOpen}
                toggle={toggleModal}
                modalFilters={modalFilters}
                buttonRight={buttonRight}
                verbatimEmotionsColors
                hideCollapse
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSelectClusterDetails: (newState: any) => {
    dispatch(selectClusterDetails(newState));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation('card', ['button'])(ClusterVerbatims));
