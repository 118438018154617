import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import Icon from '_Components/Icons/Icon';
import QModal from '../../../../../../../../../_Components/Modals/QModal/QModal';
import QModalBody from '../../../../../../../../../_Components/Modals/QModalBody/QModalBody';
import QModalSection from '../../../../../../../../../_Components/Modals/QModalSection/QModalSection';
import {
  purple,
  opacity,
} from '../../../../../../../../../styles/abstracts/colors';
import { ImageSize } from '../../../../../../../../../styles/abstracts/variables';
import BenchmarkRow from './_Components/BenchmarkRow/BenchmarkRow';
import QButton from '../../../../../../../../../_Components/Buttons/QButton/QButton';

const ModalBody = styled(QModalBody)`
  padding: 0 !important;
  width: 100%;
`;

class ManageBenchmarksModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    benchmarkList: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string,
          isDefault: PropTypes.bool.isRequired,
          settings: PropTypes.array,
        }),
      ).isRequired,
    ).isRequired,
    toggleAddBenchmarkModal: PropTypes.func.isRequired,
  };

  initModalValues = {
    config: {
      date: false,
      mentions: false,
    },
  };

  handleOnClick() {
    this.props.toggle();
    this.props.toggleAddBenchmarkModal();
  }

  render() {
    const propsModal = this.initModalValues;
    const { benchmarkList, t } = this.props;
    return (
      <QModal
        isOpen={this.props.isOpen}
        title={t('Benchmark list')}
        subtitle={t(
          'Here you can create, rename, duplicate and load your benchmark screens !',
        )}
        onClose={this.props.toggle}
        {...propsModal}
      >
        <ModalBody>
          <QModalSection>
            <QButton
              id="manageBenchmarkModalButton"
              opacity
              size="large"
              onClick={() => this.handleOnClick()}
              icon="PLUS"
              iconPosition="left"
              disabled={!this.props.canCreateBenchmark}
              popoverText={
                !this.props.canCreateBenchmark &&
                t("header:You can't create more benchmarks. Please upgrade your plan in order to create more benchmarks.")
              }
            >
              &nbsp; {t('Create a new benchmark')}
            </QButton>
          </QModalSection>
          <br />
          {!isEmpty(benchmarkList) &&
            benchmarkList.map(benchmark => (
              <BenchmarkRow
                benchmark={benchmark}
                toggleModal={this.props.toggle}
              />
            ))}
        </ModalBody>
      </QModal>
    );
  }
}

export default withTranslation('benchmark', 'header')(ManageBenchmarksModal);
