import { ActionTypes } from '../../../constants';

export function sendSelectionSelected(value) {
  return {
    type: ActionTypes.SEND_SELECTION_SELECTED,
    payload: {
      value,
    },
  };
}
