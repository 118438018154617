import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import { uniqBy, sortBy, cloneDeep, reverse } from 'lodash';
import QInput from '_Components/Fields/QInput/QInput';
import styled from 'styled-components';
import { grayShades, purple, red } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import GoogleModal from '../GoogleModal/GoogleModal';

const Input = styled(QInput)`
  width: 100% !important;
`;

const Container = styled(Row)`
  margin-bottom: 1em !important;
`;

const StyledCol = styled(Col)`
  display: inline-flex;
  align-items: center;
  padding: 0 !important;
`;

const SeparatorInput = styled.span`
  margin-left: 1em;
  color: ${grayShades.g800};
  font-weight: bolder;
`;

const InvalidUrlMessage = styled.p`
  color: ${red};
  font-weight: bolder;
  font-size: ${fontSizes.smaller};
`;

const SourceConfigurationRow = ({
  setConfiguredSources,
  configuredSources,
  source,
  index,
  configuredSource,
  t,
}) => {
  const [profileName, setProfileName] = useState(
    configuredSource?.profileName || '',
  );
  const [profile, setProfile] = useState(configuredSource?.profile || '');
  const [isGoogleModalOpen, openGoogleModal] = useState(false);
  const [_, forceUpdate] = useState(0);

  const isValidUrl = url => {
    if (String(url) !== url) return false;
    const regex = /(https?:\/\/)?(www\.)?\w{2,}(\.\w{2,}){1,}/g;
    const didMatch = url.match(regex);
    return Array.isArray(didMatch);
  };

  const prevConfiguredSources = useRef();

  useEffect(() => {
    if (
      configuredSource.profile &&
      prevConfiguredSources?.current &&
      configuredSources.length < prevConfiguredSources?.current?.length
    ) {
      setProfileName(configuredSource.profileName);
      setProfile(configuredSource.profile);
    } else if (
      configuredSources.length < prevConfiguredSources?.current?.length
    ) {
    }
  }, [configuredSources]);

  useEffect(() => {
    if (source.name === 'Google Reviews') {
      profile &&
        profileName &&
        setConfiguredSources(
          uniqBy(
            reverse([
              ...configuredSources.filter(i => i.id === 0 || i.id),
              {
                profileName,
                profile,
                idSource: source.id,
                id: parseInt(`${source.id}${index}`, 10),
                index,
              },
            ]),
            i => i.id || i.id === 0,
          ),
        );
    } else {
      profile &&
        profileName &&
        isValidUrl(profile) &&
        setConfiguredSources(
          uniqBy(
            reverse([
              ...configuredSources.filter(i => i.id | (i.id === 0)),
              {
                profileName,
                profile,
                idSource: source.id,
                id: parseInt(`${source.id}${index}`, 10),
                index,
              },
            ]),
            i => i.id,
          ),
        );
    }
  }, [profile, profileName]);

  const getErrorMessage = () => {
    switch (source.name) {
      case 'Tripadvisor':
        return (
          profile.length > 0 &&
          (!isValidUrl(profile) ||
            (isValidUrl(profile) && !profile.includes('tripadvisor.com'))) && (
            <InvalidUrlMessage>
              {t('This is not a valid XXX format.', { format: 'URL' })}
            </InvalidUrlMessage>
          )
        );
    }
  };

  return (
    <Container>
      <Col xs={3}>
        <Input
          value={profileName}
          onChange={e => setProfileName(e.target.value)}
        />
      </Col>
      <Col xs={8}>
        <Input
          value={profile}
          onChange={e =>
            source.name !== 'Google Reviews' && setProfile(e.target.value)
          }
          onClick={() =>
            source.name === 'Google Reviews' && openGoogleModal(true)
          }
        />
        <GoogleModal
          open={openGoogleModal}
          isOpen={isGoogleModalOpen}
          setGooglePlaceIDValue={setProfile}
        />
        {getErrorMessage()}
      </Col>
      <Col xs={1}>
        {configuredSources.length > 1 && (
          <IconCircle
            icon="CROSS"
            color={purple}
            size={24}
            pointer
            onClick={() => {
              prevConfiguredSources.current = configuredSources;
              setConfiguredSources(
                configuredSources.filter(
                  i => i.id !== parseInt(`${source.id}${index}`, 10),
                ),
              );
            }}
          />
        )}
      </Col>
    </Container>
  );
};

export default withRouter(SourceConfigurationRow);
