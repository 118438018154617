import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import PresentationDateRange from '../../../_Components/PresentationDateRange';
import { updateDisplaySettings } from '../../../../../../_Resources/Reporting/Actions/PageSettings/updateDisplaySettings';
import { sectionInitialConfigState } from '../../../../../../_Resources/Reporting/Reducers/reporting';
import mergeDeep from '_Utils/other/mergeDeep';

class DateRange extends Component {
  static propTypes = {
    // state props
    cardDateRange: PropTypes.any.isRequired,
    pageDateRange: PropTypes.any.isRequired,
    projectPeriods: PropTypes.any,
    currentPageIndex: PropTypes.number.isRequired,
    currentCardIndex: PropTypes.number.isRequired,

    // dispatch props
    dispatchUpdateDisplaySettings: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectPeriods: null,
  };

  onUpdate = dateRange => {
    const {
      currentPageIndex,
      currentCardIndex,
      dispatchUpdateDisplaySettings,
    } = this.props;
    dispatchUpdateDisplaySettings(
      { dateRange },
      currentPageIndex,
      currentCardIndex,
    );
  };

  onReset = () => this.onUpdate(sectionInitialConfigState.dateRange);

  render() {
    const { cardDateRange, pageDateRange, projectPeriods } = this.props;
    const dateRangeConfig = mergeDeep(cardDateRange, pageDateRange);
    return (
      <PresentationDateRange
        settings={dateRangeConfig}
        periods={projectPeriods}
        onUpdate={this.onUpdate}
        onReset={this.onReset}
        isModified={
          !isEqual(cardDateRange, sectionInitialConfigState.dateRange)
        }
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDisplaySettings: (...args) =>
    dispatch(updateDisplaySettings(...args)),
});

const mapStateToProps = ({
  reporting: { globalSettings, children, currentPageIndex, currentCardIndex },
  periods: { projectPeriods },
}) => {
  const currentPage = children[currentPageIndex];
  const currentCard = currentPage.children[currentCardIndex];
  const pageDateRange = mergeDeep(
    currentPage.dateRange,
    globalSettings.dateRange,
  );
  return {
    pageDateRange,
    cardDateRange: currentCard.dateRange,
    projectPeriods,
    currentPageIndex,
    currentCardIndex,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(DateRange));
