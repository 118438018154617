import { GET_PROJECT_CONFIGURATION } from '_Resources/Header/Actions/getProjectConfiguration';
import { UPDATE_COLLAPSED_INFO } from '../Actions/updateCollapsedInfo';
import {
  RESET_DATA_COLLECTOR_IN_PROJECT_CONFIGURATION,
  SET_CURRENT_PROJECT,
} from '../Actions/setProject';
import { UPDATE_SECTION_WIDTH } from '../Actions/updateSectionWidth.ts';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_CONFIGURATION: {
      return {
        ...state,
        ...action.data.results,
      };
    }
    case UPDATE_COLLAPSED_INFO: {
      return {
        ...state,
        collapsedInfo: action.data,
      };
    }
    case RESET_DATA_COLLECTOR_IN_PROJECT_CONFIGURATION: {
      return {
        ...state,
        lastDataCollectorId: null,
      };
    }
    case SET_CURRENT_PROJECT: {
      return {
        ...state,
        lastDataCollectorId: null,
      };
    }
    case UPDATE_SECTION_WIDTH: {
      return {
        ...state,
        width: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
