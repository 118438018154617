import styled from 'styled-components';
import {
  BENCHMARK_COLS_COLORS,
  COLUMN_WIDTH,
  FIRST_COLUMN_ADDITIONNAL_WIDTH,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/columnsConfig';
import HeaderColumn from 'Routes/Benchmark/BenchmarkBoard/_Components/AdditionalHeader/_Components/HeaderColumn';
import React from 'react';
import HeaderAddColumn from 'Routes/Benchmark/BenchmarkBoard/_Components/AdditionalHeader/_Components/HeaderAddColumn';
import PropTypes from 'prop-types';

export const AdditionalHeaderContainer = styled.div`
  background-color: white;
  position: relative;
  top: -1rem;
  left: 0;
  min-width: 100% !important;
  width: ${({ colsCount }) =>
    `calc(${(colsCount + 1) *
      COLUMN_WIDTH}px + ${FIRST_COLUMN_ADDITIONNAL_WIDTH})`} !important;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.14);
  display: flex;
`;

const AdditionalHeader = ({ colsToShow }) => {
  return (
    <AdditionalHeaderContainer colsCount={colsToShow && colsToShow.length}>
      {colsToShow &&
        colsToShow.map((colToShow, index) => (
          <HeaderColumn
            columnWidth={COLUMN_WIDTH}
            columnColor={
              BENCHMARK_COLS_COLORS[index % BENCHMARK_COLS_COLORS.length]
            }
            {...colToShow}
            columnId={index}
          />
        ))}
      <HeaderAddColumn columnWidth={COLUMN_WIDTH} />
    </AdditionalHeaderContainer>
  );
};

const colToShow = {
  title: PropTypes.string,
  mentionCount: PropTypes.number,
  id: PropTypes.number,
};

AdditionalHeader.propTypes = {
  colsToShow: PropTypes.arrayOf(PropTypes.shape(colToShow)).isRequired,
};

export default AdditionalHeader;
