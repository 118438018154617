import React, { useState, useEffect } from 'react';
import { groupBy, uniq } from 'lodash';
import { withTranslation } from 'react-i18next';
import SourcesContainer from '_Cards/SettingsCards/ProjectDetailsCard/_Components/Sources/_Containers/SourcesContainer/SourcesContainer';
import SourcesHeader from './_Containers/SourcesHeader/SourcesHeader';

// eslint-disable-next-line react/prop-types
const Sources = ({ data, projectId, slug, t, useCaseId, industryId, projectDetails }) => {
  const [sources, setSources] = useState(data);
  useEffect(() => {
    setSources(data);
  }, [data]);

  const dataBySources = groupBy(sources, d => d.source);
  const sourcesCount = Object.keys(dataBySources).length;
  const linksCount = sources?.length;

  const selectedSources = uniq(sources?.map(src => src.projectSourceItem));

  return (
    <>
      <SourcesHeader
        sourcesCount={sourcesCount}
        linksCount={linksCount}
        setSources={setSources}
        selectedSources={selectedSources}
        sources={sources}
        t={t}
        useCaseId={useCaseId}
        industryId={industryId}
      />
      <SourcesContainer
        sources={sources}
        setSources={setSources}
        dataBySources={dataBySources}
        projectId={projectId}
        slug={slug}
        t={t}
        projectDetails={projectDetails}
      />
    </>
  );
};

export default withTranslation('card', ['button', 'projectConfiguration'])(Sources);
