import { connect } from 'react-redux';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { get } from 'lodash';
import { setSelectedFilters } from '_Resources/Header/Actions/setSelectedFilters';
import {
  StyledQModal,
  Navigation,
  StyledQButton,
} from '../../../../../../Routes/Benchmark/BenchmarkBoard/_Components/Modals/_Components';
import ModalFiltersPage from './ModalFiltersPage';


type FilterModalProps = {
  isOpen: boolean;
  title: string;
  subtitle: string;
  config: any;
  closeModal: Function;
  onCancel: Function;
  onSave: Function;
  modalConfig: any;
  dispatchSetGlobalQueryFilterKeys: any;
  filterKeys: any;
  thematics: any;
  criteriaKeys: any;
  keywords: any;
  dispatchSetSelectedFilters: any;
};

const FilterModal = ({
  isOpen,
  title,
  subtitle,
  config,
  closeModal,
  onCancel,
  onSave,
  modalConfig,
  dispatchSetGlobalQueryFilterKeys,
  filterKeys,
  thematics,
  criteriaKeys,
  keywords,
  dispatchSetSelectedFilters,
}: FilterModalProps) => {

  const closeFilterModal = () => {
    closeModal(!isOpen);
  };


  const getFooter = (configuration: any) => {
    const { closeButton, deleteAllButton } = configuration;
    return (
      <Navigation>
        <StyledQButton onClick={closeFilterModal}>
          {closeButton.text}
        </StyledQButton>
      </Navigation>
    );
  };

  return (
    <StyledQModal
      footer={getFooter(modalConfig)}
      isOpen={isOpen}
      onClose={closeModal}
      title={title}
      subtitle={subtitle}
      config={config}>
      <ModalFiltersPage />
    </StyledQModal>
  );
};

const mapStateToProps = ({ filters: {filterKeys} }) => ({
  filterKeys,
  thematics: get(filterKeys, ['thematics']),
  criteriaKeys: get(filterKeys, ['criteriaKeys']),
  keywords: get(filterKeys, ['texts']),
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetGlobalQueryFilterKeys: (newState: any) => {
    dispatch(setGlobalQueryFilterKeys(newState));
  },
  dispatchSetSelectedFilters: (selected: any) => {
    dispatch(setSelectedFilters(selected));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
