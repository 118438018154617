import {
  StyledPreviewButton,
  Text,
} from 'Routes/GlobalExperience/Reporting/_Containers/ReportingFloatingActionButton/_Components/StyledPreviewButton';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const PreviewButton = ({ callbackPreviewMode, t }) => (
  <StyledPreviewButton
    icon="INTERFACE_SHOW"
    iconPosition="left"
    bgColor="red"
    onClick={() => callbackPreviewMode(true)}
  >
    <Text paddingSide="LEFT">{t('Preview with project data')}</Text>
  </StyledPreviewButton>
);

PreviewButton.propTypes = {
  callbackPreviewMode: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('reporting')(PreviewButton);
