import React from 'react';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import { purple } from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import DropdownTools from '_Layouts/_Components/BottomNav/_Components/DropdownBottomNav';

const Container = styled.div`
  background: ${purple};
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  box-shadow: 0 -15px 36px -20px rgba(0, 0, 0, 0.75);
`;

const ChevronDown = styled.div`
  min-height: 2em;
  cursor: pointer;
  background: none;
  border: none;

  & > svg {
    opacity: 0.5;
  }
`;

const BottomNav = props => {
  const handleScrollDown = () => {
    const el = document.getElementById('menu');
    const scroll = props.step;

    el.scrollTop += el.scrollTop + scroll;
    el.style.transition = 'all 1s';
  };

  return (
    <Container>
      <ChevronDown id="chevronDown" onClick={handleScrollDown}>
        <Icon icon="INTERFACE_CHEVRON_DOWN" color="#fff" size={ImageSize.big} />
      </ChevronDown>
      <DropdownTools layout="bottom" key="navBottom" user={props.user} />
    </Container>
  );
};

export default BottomNav;
