import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useQuery } from 'react-apollo';
import { grayShades, lightBlue } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QButton from '_Components/Buttons/QButton/QButton';
import query from '../../../../graphql/getCreditEstimation';

const Container = styled.div`
  border-radius: 25px;
  font-weight: bold;
  padding: 1em;
  border: 2px solid ${grayShades.g300};
  width: 30%;
  font-size: ${fontSizes.bigger};
  text-align: center;
`;

const EstimationNumber = styled.div`
  & span {
    color: ${lightBlue};
  }
`;

const ProjectEstimation = styled.div`
  display: inline-flex;
  justify-content: space-between;
  color: ${grayShades.g800};
  font-size: ${fontSizes.extraSmall};
  width: 85%;
`;

const CreditEstimation = ({ startDate, range, sources, t, hasFreeData }) => {
  const sourcesName = sources.map(source => source.name);
  const { data } = useQuery(query, {
    variables: { range, sources: sourcesName },
  });

  const globalEstimation = data?.getCreditEstimation?.find(
    estimation => estimation.name === 'Global',
  ).creditEstimation;

  const sourcesEstimation = data?.getCreditEstimation?.filter(
    estimation => estimation.name !== 'Global',
  );

  const creditsUserMock = 5000;

  return (
    <>
      {/* <b>{t('Estimated amount of credits consumption')}</b> */}
      <b>{t('Historic data')}</b>
      {/* <Container>
        <p>{creditsUserMockcreditsUserMock} remaining verbatim credits</p>
        <p>
          {t('XXX remaining verbatim credits', { credits: creditsUserMock })}
        </p>
        <br />
        <EstimationNumber
          dangerouslySetInnerHTML={{
            __html: t('Around XXX to XXX credits will be consumed', {
              minCredits: globalEstimation?.min,
              maxCredits: globalEstimation?.max,
            }),
          }}
        />
        {sourcesEstimation?.map(source => (
          <ProjectEstimation>
            <span>{source.name}</span>
            <span>
              {source.creditEstimation.min} - {source.creditEstimation.max}
            </span>
          </ProjectEstimation>
        ))}
      </Container> */}
      <br />
      <br />
      <p>
       {t('You want historic data ? You can ask us an estimation by sending us an email via the button below.')}{' '}
      </p>
      <br />
      <QButton
        bgColor="lightBlue"
        onClick={() => window.open('mailto:support-team@qemotion.com')}
      >
        {t('button:Ask an estimation')}
      </QButton>
    </>
  );
};

const mapStateToProps = state => ({
  hasFreeData: get(state, ['auth', 'user', 'account', 'freeDataAvailable']),
})

export default connect(mapStateToProps)(CreditEstimation);
