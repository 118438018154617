import { Col, Row } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { grayShades } from '../../../../styles/abstracts/colors';
import QPanelHeader from '../../../../_Components/Panels/QPanelHeader/QPanelHeader';
import QInput from '../../../../_Components/Fields/QInput/QInput';
import { LabelSettings, FormGroupSettings } from './SectionCard';

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 1rem;
  margin: 0 1rem;
  border-bottom: ${({ displayBorderBottom }) =>
    displayBorderBottom && `1px solid ${grayShades.g300}`};
  &:last-child {
    border-bottom: 0;
  }
`;
const TitleCol = styled(Col)`
  line-height: 3rem;
  padding-left: 0 !important;
  padding-bottom: 1rem;
`;
const StyledQInput = styled(QInput)`
  font-weight: bold;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
class PersonalInfoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      phoneNumber: this.props.phoneNumber,
      expireAt: this.props.expireAt,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.callback({
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      expireAt: this.state.expireAt,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.firstName !== prevProps.firstName ||
      this.props.lastName !== prevProps.lastName ||
      this.props.phoneNumber !== prevProps.phoneNumber ||
      this.props.expireAt !== prevProps.expireAt
    ) {
      this.setState({
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        phoneNumber: this.props.phoneNumber,
        expireAt: this.props.expireAt,
    });
    }
    if (
      this.state.firstName !== prevState.firstName ||
      this.state.lastName !== prevState.lastName ||
      this.state.phoneNumber !== prevState.phoneNumber ||
      this.state.expireAt !== prevState.expireAt
    ) {
      this.props.callback({
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        expireAt: this.state.expireAt,
      });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <StyledQPanelHeader
        type="menu"
        displayBorderBottom={this.props.displayBorderBottom}
      >
        <Row>
          <TitleCol>{this.props.t('Personal Information')}</TitleCol>
        </Row>
        <Row>
          <FormGroupSettings>
            <LabelSettings>{this.props.t('First name')}</LabelSettings>
            <StyledQInput
              type="text"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleChange}
            />
          </FormGroupSettings>
          <FormGroupSettings>
            <LabelSettings>{this.props.t('Last name')}</LabelSettings>
            <StyledQInput
              type="text"
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleChange}
            />
          </FormGroupSettings>
        </Row>
        <Row>
          <FormGroupSettings>
            <LabelSettings>{this.props.t('Phone number')}</LabelSettings>
            <StyledQInput
              type="number"
              name="phoneNumber"
              value={this.state.phoneNumber}
              onChange={this.handleChange}
            />
          </FormGroupSettings>
        </Row>
      </StyledQPanelHeader>
    );
  }
}

PersonalInfoSection.propTypes = {
  t: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  expireAt: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  displayBorderBottom: PropTypes.bool,
};

PersonalInfoSection.defaultProps = {
  displayBorderBottom: true,
};

export default withTranslation('card')(PersonalInfoSection);
