import gql from 'graphql-tag';

const query = gql`
  query getRecaptchaValidation($token: String!) {
    getRecaptchaValidation(token: $token) {
      success
      errors
    }
  }
`;

export default query;
