import React from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import QModal from '_Components/Modals/QModal/QModal';
import QModalBody from '_Components/Modals/QModalBody/QModalBody';
import QButton from '_Components/Buttons/QButton/QButton';
import archiveProjectMutation from '_Cards/SettingsCards/ProjectsCard/graphql/archiveOrUnarchiveProject';
import getProjectListCardData from 'Routes/Main/MainPage/_Components/ProjectListCard/getProjectListCardData';
import queryProjectHeader from '_Resources/Header/Services/api/graphql/getHeaderProjectFiltersData';

const StyledBody = styled(QModalBody)`
  text-align: center;
`;

const StyledButton = styled(QButton)`
  margin-bottom: 1em;
`;

const ArchiveProjectModal = ({
  isOpen,
  open,
  projectId,
  t,
  redirect,
  history,
}) => (
  <QModal
    isOpen={isOpen}
    onClose={() => open(!isOpen)}
    title="Archive this project?"
    subtitle="The project will be unavailable for all the users"
    hideFooter
    size="md"
    config={{mentions: false, date: false}}
  >
    <StyledBody>
      <Mutation
        mutation={archiveProjectMutation}
        update={(cache, { data: { archiveOrUnarchiveProject } }) => {
          const activeProjectsList = cache.readQuery({
            query: getProjectListCardData,
          }).results.projects;
          const archivedProjectList = cache.readQuery({
            query: getProjectListCardData,
            variables: { archived: true },
          }).results.projects;
          const headerProjectsList = cache.readQuery({
            query: queryProjectHeader,
          }).data.projects;
          const projectToArchive = activeProjectsList.find(
            project => project.id === archiveOrUnarchiveProject.id,
          );
          projectToArchive.updatedAt = archiveOrUnarchiveProject.updatedAt;
          cache.writeQuery({
            query: getProjectListCardData,
            variables: { archived: true },
            data: {
              results: {
                projects: [projectToArchive, ...archivedProjectList],
              },
            },
          });
          cache.writeQuery({
            query: queryProjectHeader,
            data: {
              data: {
                projects: headerProjectsList.filter(
                  project => archiveOrUnarchiveProject.id !== project.id,
                ),
              },
            },
          });
          cache.writeQuery({
            query: getProjectListCardData,
            data: {
              results: {
                projects: activeProjectsList.filter(
                  project => archiveOrUnarchiveProject.id !== project.id,
                ),
              },
            },
          });
        }}
      >
        {archiveProject => (
          <StyledButton
            bgColor="red"
            icon="TRASH"
            iconPosition="left"
            onClick={() => {
              archiveProject({
                variables: {
                  id: projectId,
                  archive: true,
                },
              });
              redirect && history.push('/settings');
              open(!isOpen);
            }}
          >
            &nbsp; {t('Yes, archive')}
          </StyledButton>
        )}
      </Mutation>
      <br />
      <QButton bgColor="grayShades.g800" outline onClick={() => open(!isOpen)}>
        &nbsp; {t('No, it is a mistake')}
      </QButton>
    </StyledBody>
  </QModal>
);

export default withTranslation('button')(ArchiveProjectModal);
