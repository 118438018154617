import React from 'react';
import { max } from 'lodash';
import { connect } from 'react-redux';
import { getColorHexaFromEindex } from '_Utils/eindex/eindexColorFunctions';
import { getMinMaxFromUnit } from '_Utils/eindex/eindexUnits';
import { QemotionTheme } from '../../Utils/qemotionTheme';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

class CMixBarGraphChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (typeof document !== 'undefined') {
      const echarts = require('echarts');
      echarts.registerTheme('QemotionTheme', QemotionTheme);
    }
  }

  // TODO find another method for fill area chart
  getOption = data => ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: 'transparent',
        },
      },
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        interval: 0,
      },
      data: data && data.map(item => item.name),
    },
    yAxis: [
      {
        type: 'value',
        min: getMinMaxFromUnit(this.props.eindexUnitFahrenheit).min,
        max: getMinMaxFromUnit(this.props.eindexUnitFahrenheit).max,
        axisLabel: {
          formatter(value) {
            return `${value}°`;
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      {
        type: 'value',
        min: 0,
        max: data && max(data.map(item => item.mentionCount)) * 4,
        axisLabel: {
          formatter: '{value}',
        },
        splitLine: {
          show: false,
        },
      },
    ],
    visualMap: {
      type: 'piecewise',
      seriesIndex: 0,
      min: getMinMaxFromUnit(this.props.eindexUnitFahrenheit).min,
      max: getMinMaxFromUnit(this.props.eindexUnitFahrenheit).max,
      showLabel: false,
      show: false,
      splitNumber: 6,
      color: [
        getColorHexaFromEindex(40),
        getColorHexaFromEindex(30),
        getColorHexaFromEindex(20),
        getColorHexaFromEindex(10),
        getColorHexaFromEindex(0),
        getColorHexaFromEindex(-10),
      ],
    },
    series: [
      {
        type: 'graph',
        name: 'E-index',
        layout: 'none',
        coordinateSystem: 'cartesian2d',
        symbolSize: 64,
        itemStyle: {
          borderWidth: 4,
          borderColor: '#fff',
        },
        label: {
          normal: {
            show: true,
            formatter: '{c}°',
            fontSize: 24,
          },
        },
        data: data && data.map(item => item.value),
        z: 2,
      },
      {
        name: 'Mentions',
        type: 'bar',
        data: data && data.map(item => item.mentionCount),
        z: 1,
        yAxisIndex: 1,
        xAxisIndex: 0,
        barMaxWidth: 24,
        barMinHeight: 12,
        itemStyle: {
          color: '#592EA0',
          barBorderRadius: [12, 12, 0, 0],
        },
      },
    ],
  });

  render() {
    return (
      <div style={{ height: '100%' }}>
        {ReactEcharts && (
          <ReactEcharts
            option={this.getOption(this.props.data.mentionRepartitionPerSource)}
            style={{ height: '100%', width: '100%' }}
            className="react_for_echarts"
            theme="QemotionTheme"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
    eindexUnitFahrenheit: state.projectConfiguration.unitsAndLocalesSettings.eindexUnitFahrenheit,
  };
}

export default connect(mapStateToProps)(CMixBarGraphChart);
