import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { hex2rgbaConvert } from '../../../../_Utils/styles/colors';
import { lightBlue } from '../../../../styles/abstracts/colors';

export const PAGE_SLOT_SIZE = {
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
};

const SLOT_SIZES = {
  [PAGE_SLOT_SIZE.SMALL]: '4em',
  [PAGE_SLOT_SIZE.MEDIUM]: '8em',
};

const Slot = styled.div`
  width: 900px;
  height: ${({ size }) => SLOT_SIZES[size]};
  padding: 1em 0;
`;

const Highlight = styled.div`
  height: 100%;
  ${Slot}:hover & {
    background-color: ${hex2rgbaConvert(lightBlue, 10)};
  }
`;

class PageSlot extends Component {
  static propTypes = {
    size: PropTypes.oneOf(Object.values(PAGE_SLOT_SIZE)),
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
  };

  static defaultProps = {
    onMouseEnter: null,
    onMouseLeave: null,
    size: PAGE_SLOT_SIZE.SMALL,
  };

  render() {
    const { size, onMouseEnter, onMouseLeave } = this.props;
    return (
      <Slot size={size} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Highlight />
      </Slot>
    );
  }
}

export default PageSlot;
