import gql from 'graphql-tag'

const mutation = gql`
  mutation saveThematicsOverviewSettings($projectId: Int!, $settings: Json!) {
    saveThematicsOverviewSettings(projectId: $projectId, settings: $settings) {
      # errors
      settings
    }
  }
`;

export default mutation;
