import { GET_FILTERS_NUMBER_OF_MENTIONS } from '_Resources/Header/Actions/getFiltersNumberOfMentions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FILTERS_NUMBER_OF_MENTIONS: {
      return {
        ...state,
        ...action.data.data,
      };
    }
    default: {
      return state;
    }
  }
};
