import React, { useState } from 'react';
import styled from 'styled-components';
import QButton from '_Components/Buttons/QButton/QButton';
import { Mutation } from 'react-apollo';
import { filter, get } from 'lodash';
import { connect } from 'react-redux';
import { loadClusters } from '_Resources/Cluster/Actions/loadClusters';
import mutationDeleteCluster from '_Resources/Cluster/Services/api/graphql/deleteCluster';
import Loader from '_Components/Loader/OnLoad';
import { white } from 'styles/abstracts/colors';
import DeleteClusterConfirmationModal from './DeleteClusterConfirmationModal';

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  & > * {
    margin: 0 0.25em;
  }
`;

type DeleteClusterButtonProps = {
  t: any;
  clusterId: string;
  projectId: number;
  range: {
    startDate: Date;
    endDate: Date;
  };
  closeModal: any;
  dispatchLoadClusters: any;
  cluster: any;
};

const DeleteClusterButton = ({
  t,
  clusterId,
  projectId,
  closeModal,
  dispatchLoadClusters,
  cluster,
}: DeleteClusterButtonProps) => {
  const [deleteClusterConfirmationModalOpen, toggleModal] = useState(false);

  const deleteConfirmButton = (
    <Mutation
      mutation={mutationDeleteCluster}
      update={(cache, { data: { deleteCluster } }) => {
        const clustersList = cluster.clusters;
        const updatedEnchantementClustersList = filter(
          clustersList.enchantements,
          cluster => cluster.id !== clusterId,
        );
        const updatedIrritantClustersList = filter(
          clustersList.irritants,
          cluster => cluster.id !== clusterId,
        );
        const newList = {
          enchantements: updatedEnchantementClustersList,
          irritants: updatedIrritantClustersList,
        };
        dispatchLoadClusters(newList);
      }}
      onCompleted={() => {
        closeModal(false);
      }}
    >
      {(deleteCluster, { data, loading, error }) => (
        <StyledQButton
          bgColor="red"
          onClick={() => deleteCluster({ variables: { projectId, clusterId } })}
          icon="TRASH"
          iconSize={24}
          iconPosition="left"
          disabled={loading}
        >
          <span>{t('button:Confirm delete cluster')}</span>
          {loading && <Loader color={white} />}
        </StyledQButton>
      )}
    </Mutation>
  );

  return (
    <>
      <StyledQButton
        bgColor="red"
        onClick={() => toggleModal(true)}
        icon="TRASH"
        iconPosition="left"
        iconSize={16}
      >
        <span>{t('button:Delete cluster')}</span>
      </StyledQButton>
      <DeleteClusterConfirmationModal
        isOpen={deleteClusterConfirmationModalOpen}
        deleteConfirmButton={deleteConfirmButton}
        onDeleteCanceled={() => toggleModal(false)}
      />
    </>
  );
};

const mapStateToProps = state => ({
  cluster: get(state, ['cluster']),
});

const mapDispatchToProps = dispatch => ({
  dispatchLoadClusters: newState => {
    dispatch(loadClusters(newState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteClusterButton);
