import { convertFahrenheitToCelsius } from '_Utils/eindex/eindexUnits';

export function getMainEmotionFromEindex(eindex, config) {
  const arr = [
    'happiness',
    'surprise',
    'calm',
    'fear',
    'sadness',
    'anger',
    'disgust',
  ];
  const isFahrenheit = config.unitsAndLocalesSettings.eindexUnitFahrenheit;
  eindex = parseInt(eindex);

  if (isFahrenheit) {
    eindex = convertFahrenheitToCelsius(eindex);
  }

  let emotion = '';
  switch (true) {
    case eindex >= 31 && eindex <= 40:
      emotion = arr[0];
      break;
    case eindex >= 21 && eindex <= 30:
      emotion = arr[1];
      break;
    case eindex >= 11 && eindex <= 20:
      emotion = arr[2];
      break;
    case eindex >= 6 && eindex <= 10:
      emotion = arr[3];
      break;
    case eindex >= -5 && eindex <= 5:
      emotion = arr[4];
      break;
    case eindex >= -14 && eindex <= -4:
      emotion = arr[5];
      break;
    case eindex >= -20 && eindex <= -15:
      emotion = arr[6];
      break;
  }
  return emotion;
}

export function getColorHexaByEmotion(value) {
  const arr = [
    '#DB2156',
    '#FF8104',
    '#F5D800',
    '#8BDB00',
    '#0EC3E3',
    '#007AFF',
    '#222C66',
  ];
  return filterByEmotion(value, arr);
}

export function filterByEmotion(value, arr) {
  let emotion = '';
  switch (value) {
    case 'happiness':
      emotion = arr[0];
      break;
    case 'surprise':
      emotion = arr[1];
      break;
    case 'calm':
      emotion = arr[2];
      break;
    case 'fear':
      emotion = arr[3];
      break;
    case 'sadness':
      emotion = arr[4];
      break;
    case 'anger':
      emotion = arr[5];
      break;
    case 'disgust':
      emotion = arr[6];
      break;
    default:
      emotion = 'transparent';
      break;
  }
  return emotion;
}
