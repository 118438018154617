import { connect } from 'react-redux';
import { get } from 'lodash';
import BreadCrumbs from '../_Components/BreadCrumbs';
import {
  REPORTING_MODES,
  updateReportingMode,
} from '../../../../../../_Resources/Reporting/Actions/updateReportingMode';

const mapStateToProps = ({
  reporting: { mode, children, currentPageIndex, currentCardIndex },
}) => {
  const pageContextLabel = get(children, `[${currentPageIndex}].title`);
  const cardContextLabel = get(
    children,
    `[${currentPageIndex}].children[${currentCardIndex}].title`,
  );
  return {
    mode,
    payloads: {
      [REPORTING_MODES.GLOBAL]: {},

      [REPORTING_MODES.PAGE]: {
        contextLabel:
          pageContextLabel === 'Page title' ? undefined : pageContextLabel,
        pageIndex: currentPageIndex,
      },

      [REPORTING_MODES.CARD]: {
        contextLabel:
          cardContextLabel === 'Section title' ? undefined : cardContextLabel,
        pageIndex: currentPageIndex,
        cardIndex: currentCardIndex,
      },
    },
  };
};

const mapDispatchToProps = dispatch => ({
  onModeSelected: (mode, pageIndex, cardIndex) =>
    dispatch(updateReportingMode(mode, pageIndex, cardIndex)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BreadCrumbs);
