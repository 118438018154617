import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { withTranslation } from 'react-i18next';
import { capitalize } from '_Utils/strings/stringsUtils';
import { useQuery, useMutation } from 'react-apollo';
import querySchema from '_Cards/SatisfactionCards/SatisfactionNpsCard/graphql/getSatisfactionNpsCardData';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { grayShades, purple, lightBlue } from '../../styles/abstracts/colors';
import getTabComponents from './utils/getTabComponents';
import Sidebar from './Sidebar';
import { TABS } from './utils/tabs';
import { getIrritantsContainerWidth } from './utils/getLandingPageSizes';
import EXPORT_CLUSTERS from '_Resources/Cluster/Services/api/graphql/exportClusters';
import QToast from '_Components/QToast/QToast';
import { switchFilterSelected } from "../../_Resources/Header/Actions/setSelectedSwitchFilter";

const Container = styled(QcardPanel)<{ headerHeight: number }>`
  display: flex;
  height: calc(100vh - 7em - ${({headerHeight}) => headerHeight}px);

  & .col > div {
    padding: 0 !important;
  }
`;

const StyledContainer = styled(Container)<{
  headerHeight: number;
  componentsLength: number;
  transparentBorder: boolean
}>`
  height: calc(100vh - 9em - ${({headerHeight}) => headerHeight}px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: calc(100% / ${({componentsLength}) => componentsLength});
  flex-grow: 1;
  align-items: center;

  & .col > div {
    padding: inherit;
  }

  border-radius: 0;

  &:not(:last-child) {
    border-right: 5px dotted ${({transparentBorder}) => transparentBorder ? 'transparent' : grayShades.g500};
    margin-right: 0.2em;
    padding-right: 0.5em;
  }

  &:not(:first-child) {
    padding-left: 0.5em;
    margin-left: 0.2em;
    border-left: 5px dotted ${({transparentBorder}) => transparentBorder ? 'transparent' : grayShades.g500};
  }
`;

const ComponentsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Component = styled.div<{ componentsLength: number }>`
  flex-grow: 1;
  align-items: center;
  height: 100%;

  &:not(:last-child) {
    border-right: 5px dotted ${grayShades.g500};
    margin-right: 0.2em;
    padding-right: 0.5em;
  }

  &:not(:first-child) {
    padding-left: 0.5em;
    margin-left: 0.2em;
    border-left: 5px dotted ${grayShades.g500};
  }
`;

const AdjustableSeparator = styled.div<{ width: number }>`
  position: absolute;
  left: ${({width}) => width}px;
  width: 2px;
  min-width: 2px;
  height: 95%;
  background-color: ${grayShades.g500};
  cursor: col-resize;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "|";
    background-color: ${grayShades.g500};
    color: ${grayShades.g300};
    height: 200px;
    min-width: 12px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 2em;
  }

  &:hover {
    background-color: ${purple};

    &:before {
      background-color: ${purple};
    }
  }
`;

const LandingPageCard = ({
                           t,
                           headerHeight,
                           history,
                           i18n,
                         }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TABS[0].id);
  const filters = useSelector(state => get(state, ['filters', 'filterKeys']));
  const projectId = useSelector(state => get(state, ['filters', 'filterKeys', 'projectId']));
  const selectedThematics = useSelector(state => get(state, ['filters', 'filterKeys', 'thematics']));
  const range = useSelector(state => get(state, ['filters', 'filterKeys', 'range']));
  const customerJourneyThematic = useSelector(state =>
    get(state, ['projectConfiguration', 'moduleSettings']),
  )
    ?.find(mod => mod.moduleId === 'moments')
    ?.pageSettings.find(page => page.pageId === 'customer-journey');
  const {data} = useQuery(querySchema, {
    variables: filters,
  });

  const [width, setWidth] = useState(430);

  const irritantsContainerWidth = getIrritantsContainerWidth();

  const onMouseDown = (mouseDownEvent: any, irritantsContainerWidth: number) => {
    const startWidth = width;
    const startPosition = mouseDownEvent.pageX;
    const maxWidth = irritantsContainerWidth;

    const onMouseMove = (mouseMoveEvent: any) => {
      const newWidth = startWidth + (mouseMoveEvent.pageX - startPosition);
      if (newWidth > maxWidth - 10) {
        setWidth(maxWidth - 10);
      } else if (newWidth < 10) {
        setWidth(10);
      } else {
        setWidth(newWidth);
      }
    }
    const onMouseUp = (mouseUpEvent: any) => {
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, {once: true});
  };

  useEffect(() => {
    const thematics = activeTab === 'customer_journey'
      ? [{
        thematic: customerJourneyThematic?.defaultThematic,
        subThematic: null,
        subSubThematic: null,
      }]
      : selectedThematics

    dispatch(setGlobalQueryFilterKeys({
      ...filters,
      thematics: thematics
    }));

    dispatch(switchFilterSelected(['ENCHANTEMENT']))
  }, [dispatch, activeTab]);

  const {
    leftComponent,
    midComponent,
    rightComponent,
    leftTitle,
    midTitle,
    rightTitle,
    leftHref,
    leftHrefToTab,
    leftHrefProps,
    midHref,
    leftAdditionalIcon,
    midAdditionalIcon,
    midAdditionalAction,
    leftHints,
    midHints,
    leftWidth,
    rightWidth,
    componentsLength,
  } = getTabComponents({
    activeTab,
    filters,
    t,
    history,
    data,
    headerHeight,
    i18n,
    width,
  });

  const [toastMessage, setToastMessage] = useState('');

  const [exportClustersData] = useMutation(EXPORT_CLUSTERS, {
    variables: {projectId, range},
    onCompleted: (data) => {
      setToastMessage(data.exportClusters.message);
      setTimeout(() => {
        setToastMessage('');
      }, 3000);
    },
  });

  return (
    <Container
      className="container--landing--page"
      displayCardSubTitle={false}
      sideBar={
        <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} t={t}/>
      }
      style={{marginBottom: '2em'}}
      headerHeight={headerHeight}
    >
      <ComponentsContainer
        className="componentsContainer"
        style={{height: '100%', position: 'relative',}}
        id={activeTab}
      >
        {leftComponent && (
          <StyledContainer
            title={leftTitle}
            componentsLength={componentsLength}
            displayCardHeader
            displayBackgroundCard={false}
            displayDateSubtitle={false}
            dropDownOptions={['EMOTION_CLUSTERS_DATA']}
            hints={leftHints}
            landingSection
            additionalIcon={leftAdditionalIcon}
            additionalAction={() => history.push(leftHref)}
            style={{
              width: activeTab === 'irritants' ? `${((typeof leftWidth) === "number") ? leftWidth + 'px' : leftWidth}` : (`${leftWidth}` ||
                `calc(100% / ${({componentsLength}) => componentsLength};`),
              flexGrow: `${leftWidth ? '0' : '1'}`,
              minWidth: activeTab === 'irritants' ? `${((typeof leftWidth) === "number") ? leftWidth + 'px' : leftWidth}` : (`${leftWidth}` ||
                `calc(100% / ${({componentsLength}) => componentsLength};`),
            }}
            headerHeight={headerHeight}
            transparentBorder={activeTab === 'irritants'}
          >
            <Component
              className="component"
              componentsLength={componentsLength}
            >
              {leftComponent}
              {leftHref && !leftHrefToTab && (
                <div className="w-100 text-right">
                  <a href={`${leftHref}`} className="text-right">
                    {`${capitalize(t('see more'))}`} ...
                  </a>
                </div>
              )}
              {leftHref && leftHrefToTab && (
                <div className="w-100 text-right">
                  <Link
                    to={{
                      pathname: leftHref,
                      search: '',
                      state: {
                        toTab: leftHrefToTab,
                        props: leftHrefProps,
                      },
                    }}
                  >
                    {`${capitalize(t('see more'))}`} ...
                  </Link>
                </div>
              )}
            </Component>
          </StyledContainer>
        )}
        {midComponent && (
          <StyledContainer
            title={midTitle}
            componentsLength={componentsLength}
            displayBackgroundCard={false}
            displayDateSubtitle={false}
            dropDownOptions={['EXPORT']}
            hints={midHints}
            landingSection
            additionalIcon={midAdditionalIcon}
            additionalAction={midAdditionalAction}
            headerHeight={headerHeight}
          >
            <Component
              className="component"
              componentsLength={componentsLength}
            >
              {midComponent}
              {midHref && (
                <div className="w-100 text-right">
                  <a href={`${midHref}`} className="text-right">
                    {`${capitalize(t('see more'))}`} ...
                  </a>
                </div>
              )}
            </Component>
          </StyledContainer>
        )}
        {activeTab === 'irritants' && (
          <AdjustableSeparator
            onMouseDown={(onMouseDownEvent) => onMouseDown(onMouseDownEvent, irritantsContainerWidth)}
            width={width}
          />
        )}
        {rightComponent && rightTitle !== 'Irritant and enchanting points' && (
          <StyledContainer
            title={rightTitle}
            componentsLength={componentsLength}
            displayBackgroundCard={false}
            displayDateSubtitle={false}
            dropDownOptions={['EXPORT']}
            landingSection
            style={{
              width: activeTab === 'irritants' ? 'auto' : `${rightWidth ||
              'calc(100% / ${({ componentsLength }) => componentsLength});'}`,
              flexGrow: `${rightWidth ? '0' : '1'}`,
            }}
            headerHeight={headerHeight}
            transparentBorder={activeTab === 'irritants'}
          >
            <Component
              className="component"
              componentsLength={componentsLength}
              style={{
                overflow:
                  rightComponent.type.name === 'AnalysisCard'
                    ? 'auto'
                    : 'inherit',
              }}
            >
              {rightComponent}
            </Component>
          </StyledContainer>
        )}
        {rightComponent && rightTitle === 'Irritant and enchanting points' && (
          <StyledContainer
            title={rightTitle}
            componentsLength={componentsLength}
            displayBackgroundCard={false}
            displayDateSubtitle={false}
            dropDownOptions={['EXPORT', 'CLUSTERS_DATA']}
            landingSection
            style={{
              width: `${rightWidth ||
              'calc(100% / ${({ componentsLength }) => componentsLength});'}`,
              flexGrow: `${rightWidth ? '0' : '1'}`,
            }}
            headerHeight={headerHeight}
            exportClustersData={exportClustersData}
            transparentBorder
          >
            <Component
              className="component"
              componentsLength={componentsLength}
              style={{
                overflow:
                  rightComponent.type.name === 'AnalysisCard'
                    ? 'auto'
                    : 'inherit',
              }}
            >
              {rightComponent}
            </Component>
          </StyledContainer>
        )}
      </ComponentsContainer>
      {toastMessage.length && (
        <QToast color={lightBlue}>{toastMessage}</QToast>
      )}
    </Container>
  );
};

export default withRouter(withTranslation('card', ['text'])(LandingPageCard));
