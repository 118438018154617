import React from 'react';
import { Row, Col } from 'reactstrap';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import NoteWithLeftIcon from '_Charts/KpisCharts/NoteWithLeftIcon/NoteWithLeftIcon';

const NoteWithLeftIconBadgeCard = props => (
  <QcardPanel {...props}>
    <Row className="containContent">
      <Col xs="12">
        <NoteWithLeftIcon
          icon={props.icon || 'PICTO_STAR'}
          color="#592EA0"
          size={48}
          backgroundColor="#592EA0"
          indicator={props.indicator}
          note={props.data}
        />
      </Col>
    </Row>
  </QcardPanel>
);

export default NoteWithLeftIconBadgeCard;
