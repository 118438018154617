import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import CModal from '_Components/CModal/CModal';
import QModalSection from '_Components/Modals/QModalSection/QModalSection';
import QButton from '_Components/Buttons/QButton/QButton';
import { QSideBarLayout } from '_Components/Layouts/QSideBarLayout';
import { connect } from 'react-redux';
import mutationMergeCluster from '_Resources/Cluster/Services/api/graphql/mergeClusters';
import { Mutation } from 'react-apollo';
import { get } from 'lodash';
import QInput from '_Components/Fields/QInput/QInput';
import { getFontSizeStyles } from 'styles/abstracts/typography';
import { spacing } from 'styles/abstracts/variables';
import { grayShades, purple, white } from 'styles/abstracts/colors';
import { loadClusters } from '_Resources/Cluster/Actions/loadClusters';
import { loadClustersToMerge } from '_Resources/Cluster/Actions/loadClustersToMerge';
import Loader from '_Components/Loader/OnLoad';

const StyledQModalSection = styled(QModalSection)`
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  flex-direction: column;
  & > * {
    margin: 1em;
  }
`;

const StyledCModal = styled(CModal)`
  & .modal-content > *:first-child {
    padding-bottom: 0;
  }
`;

const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  margin: -16px;
`;

const QSideBarLayoutDisplayArea = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const ConfirmMergeButton = styled(QButton)`
  display: flex;
  align-items: center;
  & > * {
    margin: 0 0.25em;
  }
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  ${getFontSizeStyles(20)};
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 0.5em 1em;
  font-size: 1.25em;
  cursor: pointer;
`;

const StyledQInput = styled(QInput)`
  margin-top: ${spacing.small};
  margin-bottom: ${spacing.xsmall};
  font-weight: bold;
  border: none;
  font-size: 1.25em;
  color: ${purple};
  padding: none;
  width: auto;
  transition: all 0.5s ease-in-out;
`;

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  & > * {
    margin: 0 0.25em;
  }
  margin-left: 2em;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0 !important;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const NameNewClusterModal = ({
  projectId,
  onNameNewClusterModalCanceled,
  t,
  isOpen,
  cluster,
  dispatchLoadClustersToMerge,
  closeModal,
}) => {
  const title = 'Name new cluster';

  const subtitle = 'Name it to be sure';

  const [newName, setNewName] = useState(cluster.clickedCluster.title);
  const { clustersToMerge } = cluster;
  const inputEl = useRef(null);
  const [editingNewClusterName, setEditingNewClusterName] = useState(true);

  useEffect(() => {
    if (editingNewClusterName) {
      if (inputEl.current !== null) {
        inputEl.current.select();
      }
    }
  }, []);

  const handleEnterKey = e => {
    if (e.keyCode === 13) {
      setEditingNewClusterName(false);
    }
  };

  const mergeConfirmButton = (
    <Mutation
      mutation={mutationMergeCluster}
      onCompleted={() => {
        closeModal(true);
        dispatchLoadClustersToMerge([]);
      }}
    >
      {(mergeClusters, { data, loading, error }) => (
        <ConfirmMergeButton
          bgColor="purple"
          onClick={() => {
            mergeClusters({
              variables: {
                projectId,
                clusterIds: clustersToMerge,
                newClusterName: newName,
              },
            });
          }}
          icon="DUPLICATE"
          iconSize={24}
          iconPosition="left"
          disabled={clustersToMerge.length < 2 || loading}
        >
          <span>{t('button:Confirm merge cluster')}</span>
          {loading && <Loader color={white} />}
        </ConfirmMergeButton>
      )}
    </Mutation>
  );

  const getFooter = () => {
    return (
      <>
        <StyledQModalSection>
          {mergeConfirmButton}
          <QButton
            outline
            onClick={() => {
              setEditingNewClusterName(false);
              onNameNewClusterModalCanceled();
            }}
          >
            {t('button:Back to clusters selection')}
          </QButton>
        </StyledQModalSection>
      </>
    );
  };

  return (
    <StyledCModal
      size="small"
      data={{}}
      title={t(title)}
      displayMentionsSubtitle={false}
      displayDateSubtitle={false}
      subtitleAdditionalContent={t(subtitle)}
      footer={getFooter()}
      modal={isOpen}
    >
      <Body>
        <HeaderArea />
        <QSideBarLayout>
          <QSideBarLayoutDisplayArea>
            {editingNewClusterName && (
              <TitleContainer>
                <StyledQInput
                  // name="clusterName"
                  type="text"
                  value={newName}
                  onChange={e => {
                    setNewName(e.target.value);
                  }}
                  ref={inputEl}
                  size={title.length}
                  onFocus={e => e.target.select()}
                  onKeyDown={e => handleEnterKey(e)}
                />
                <StyledQButton
                  type="button"
                  onClick={() => setEditingNewClusterName(false)}
                  size="small"
                  hasOpacity
                  bgColor={grayShades.g800}
                >
                  OK
                </StyledQButton>
              </TitleContainer>
            )}
            {!editingNewClusterName && (
              <TitleContainer>
                <Title
                  id={`${newName}-newName`}
                  onClick={() =>
                    setEditingNewClusterName(!editingNewClusterName)
                  }
                >
                  {newName}
                </Title>
                <StyledQButton
                  type="button"
                  size="small"
                  onClick={() =>
                    setEditingNewClusterName(!editingNewClusterName)
                  }
                  icon="INTERFACE_EDIT"
                  iconSize={20}
                  hasOpacity
                  bgColor={grayShades.g800}
                />
              </TitleContainer>
            )}
          </QSideBarLayoutDisplayArea>
        </QSideBarLayout>
      </Body>
    </StyledCModal>
  );
};

const mapStateToProps = state => ({
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
  currentLang: get(state, ['auth', 'user', 'lang']),
  cluster: get(state, ['cluster']),
});

const mapDispatchToProps = dispatch => ({
  dispatchLoadClusters: newState => {
    dispatch(loadClusters(newState));
  },
  dispatchLoadClustersToMerge: newState => {
    dispatch(loadClustersToMerge(newState));
  },
});

export default withTranslation(
  'card',
  'button',
)(connect(mapStateToProps, mapDispatchToProps)(NameNewClusterModal));
