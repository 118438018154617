import gql from 'graphql-tag';

const query = gql`
    query($projectId: Int!, $fileExtension: String!, $range: DateRangeInput!, $thematics: [ThematicInput!], $criteriaKeys: [CriteriaKey!], $keywords: [String!]) {
        exportCategoryRankingData(projectId: $projectId, fileExtension: $fileExtension, range: $range, thematics: $thematics, criteriaKeys: $criteriaKeys, keywords: $keywords) {
            code
            message
        }
    }
`

export default query;
