import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '_Components/Icons/Icon';
import mutationDeleteSentencesInsight from '_Resources/Insight/Services/api/graphql/deleteSentencesInsight';
import { Mutation } from 'react-apollo';
import { findIndex } from 'lodash';
import getInsightVerbatim from '_Resources/Insight/Services/api/graphql/getInsight';
import getInsightsList from '_Resources/Insight/Services/api/graphql/getInsights';
import { danger } from '../../../styles/abstracts/colors';

const VerbatimBody = styled.div`
  cursor: ${({ deletable }) => (deletable ? 'auto' : 'pointer')};
  position: relative;
  width: 100%;
  display: flex;
  padding: 0.5em;
  align-items: center;
`;

const VerbatimInput = styled.div`
  cursor: pointer;
  flex-grow: 0;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: ${({ selected }) => (selected ? '#592EA0' : '#E1E4ED')};
  margin: 1em;
  &::before {
    margin: 50%;
    transform: translate(-50%, -72%) rotate(135deg);
    content: ${({ selected }) => (selected ? "''" : 'none')};
    display: block;
    width: 14px;
    height: 6px;
    border-top: 3px solid white;
    border-right: 3px solid white;
    border-top-right-radius: 3px;
  }
`;
const VerbatimText = styled.div`
  background-color: white;
  flex-grow: 1;
  width: 70%;
  margin: 0.5em;
  margin-bottom: 0;
  padding: 1em;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ selected }) => (selected ? '#592EA0' : '#E1E4ED')};
`;

const VerbatimDeleteIcon = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;

  cursor: pointer;
  position: absolute;
  transform: translate(-25%, 25%);
  top: 0;
  right: 0;
  padding: 0.25em;
  border-radius: 20px;
  background-color: #8784a7;

  &:hover {
    background-color: ${danger};
    color: white;
  }

  ${VerbatimBody}:hover & {
    visibility: visible;
  }

  & > svg {
    transform: translateY(-1px);
    flex-grow: 1;
  }
`;

const VerbatimTextBubble = ({
  selected,
  onSentenceSelect,
  onSentenceRemove,
  children,
  deletable,
  sentenceInfos,
  projectId,
  range,
  dispatchSelectInsight,
  onMouseEnter,
  onMouseLeave,
}) => {
  const isSelected = !deletable && selected;

  const onBubbleClick = event => !deletable && onSentenceSelect(event);

  return (
    <VerbatimBody
      deletable={deletable}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!deletable && (
        <VerbatimInput selected={isSelected} onClick={onBubbleClick} />
      )}
      <VerbatimText selected={isSelected} onClick={onBubbleClick}>
        <p>{children}</p>
      </VerbatimText>
      {deletable && (
        <Mutation
          mutation={mutationDeleteSentencesInsight}
          onCompleted={() => {
            dispatchSelectInsight(sentenceInfos.insightId, range);
          }}
          update={(cache, { data: { deleteSentencesInsight } }) => {
            // const { projectId } = this.props;
            cache.writeQuery({
              query: getInsightVerbatim,
              variables: { insightId: sentenceInfos.insightId },
              data: {
                insight: {
                  insightId: deleteSentencesInsight.insight.insightId,
                  selectedVerbatim:
                    deleteSentencesInsight.insight.selectedVerbatim,
                },
              },
            });

            const insightsList = cache.readQuery({
              query: getInsightsList,
              variables: { projectId },
            }).insights;
            const insightIndexToReplace = findIndex(
              insightsList,
              insight => insight.insightId === sentenceInfos.insightId,
            );
            const currentInsight = insightsList[insightIndexToReplace];
            const newInsightData = {
              ...currentInsight,
              entryPoints: deleteSentencesInsight.insight.entryPoints,
              editedOn: deleteSentencesInsight.insight.editedOn,
              editedBy: deleteSentencesInsight.insight.editedBy,
            };
            const newInsightsListData = [...insightsList];
            newInsightsListData[insightIndexToReplace] = newInsightData;
            cache.writeQuery({
              query: getInsightsList,
              variables: { projectId },
              data: {
                insights: newInsightsListData,
              },
            });

            onSentenceRemove();
          }}
        >
          {deleteSentencesInsight => (
            <VerbatimDeleteIcon
              onClick={() => {
                deleteSentencesInsight({
                  variables: {
                    projectId: sentenceInfos.projectId,
                    insightId: sentenceInfos.insightId,
                    sentenceIds: [sentenceInfos.sentenceId],
                  },
                });
              }}
            >
              <Icon icon="TRASH" size={15} color="white" />
            </VerbatimDeleteIcon>
          )}
        </Mutation>
      )}
    </VerbatimBody>
  );
};

VerbatimTextBubble.propTypes = {
  deletable: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onSentenceRemove: PropTypes.func.isRequired,
  onSentenceSelect: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default VerbatimTextBubble;
