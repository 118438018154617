import { map } from 'ramda';

const satisfactionRanges = [
  { max: 1, min: 0, color: '#DED6EC' },
  { max: 2, min: 1, color: '#BDACD9' },
  { max: 3, min: 2, color: '#9C82C6' },
  { max: 4, min: 3, color: '#7B58B3' },
  { max: Infinity, min: 4, color: '#592EA0' },
];

const satisfactionScaleRange = { min: 0, max: 5 };

const effortRanges = [
  { max: 1, min: 0, color: '#DED6EC' },
  { max: 2, min: 1, color: '#BDACD9' },
  { max: 3, min: 2, color: '#9C82C6' },
  { max: 4, min: 3, color: '#7B58B3' },
  { max: Infinity, min: 4, color: '#592EA0' },
];

const effortScaleRange = { min: 0, max: 5 };

const celsius = {
  eindexRanges: [
    { max: -10, min: -Infinity, color: '#222C66' },
    { max: 0, min: -10, color: '#492796' },
    { max: 10, min: 0, color: '#6E208C' },
    { max: 20, min: 10, color: '#992085' },
    { max: 30, min: 20, color: '#BD1370' },
    { max: Infinity, min: 30, color: '#DB2156' },
  ],
  eindexScaleRange: { min: -20, max: 40 },
  satisfactionRanges,
  satisfactionScaleRange,
};

const fahrenheit = {
  eindexRanges: [
    { max: 17, min: -Infinity, color: '#222C66' },
    { max: 33, min: 17, color: '#492796' },
    { max: 50, min: 33, color: '#6E208C' },
    { max: 66, min: 50, color: '#992085' },
    { max: 83, min: 66, color: '#BD1370' },
    { max: Infinity, min: 83, color: '#DB2156' },
  ],
  eindexScaleRange: { min: 0, max: 100 },
  satisfactionRanges,
  satisfactionScaleRange,
};

const getColor = ({ color }) => color;
const extractColors = map(getColor);

const prepareModes = category => {
  const {
    eindexRanges,
    eindexScaleRange,
    satisfactionRanges,
    satisfactionScaleRange,
  } = category;
  const eindexColors = extractColors(eindexRanges);
  const satisfactionColors = extractColors(satisfactionRanges);
  const effortColors = extractColors(effortRanges);

  return {
    eindex: {
      label: 'E-Index',
      mode: 'eindex',
      colors: eindexColors,
      range: eindexScaleRange,
      ranges: eindexRanges,
    },
    sat: {
      label: 'Satisfaction',
      mode: 'sat',
      colors: satisfactionColors,
      range: satisfactionScaleRange,
      ranges: satisfactionRanges,
    },
    reco: {
      label: 'Recommendation',
      mode: 'reco',
      colors: satisfactionColors,
      range: satisfactionScaleRange,
      ranges: satisfactionRanges,
    },
    ces: {
      label: 'Effort',
      mode: 'effort',
      colors: effortColors,
      range: effortScaleRange,
      ranges: effortRanges,
    },
  };
};

export const modes = {
  celsius: prepareModes(celsius),
  fahrenheit: prepareModes(fahrenheit),
};
