import gql from 'graphql-tag';

const query = gql`
  query getRadarAndHorizBarCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]!
    $keywords: [String!]
    $dataCollectorId: Int
  ) {
    getRadarAndHorizBarCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dataCollectorId: $dataCollectorId
    ) {
      data {
        id
        label
        eindex
        mentionCount
        values {
          id
          label
          eindex
          mentionCount
        }
      }
    }
  }
`;
export default query;
