/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import { omit } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RefineByEindex from './_Components/Filters/RefineByEindex';
import RefineByEmotions from './_Components/Filters/RefineByEmotions';
import {
  RefineByRecommendation,
  RefineBySatisfaction,
} from './_Components/Filters/RefineByKpi';
import RefineBySensitivity from './_Components/Filters/RefineBySensitivity';
import FiltersHeaders from './_Components/FiltersHeaders';
import RangeSection from './_Components/RangeSection';

const RefineByFilters = styled.div`
  padding: 0.5em 1em;
  border-bottom: 1px solid #e1e4ed;
  width: 100%;
`;

export const REFINE_BY_CATEGORIES = {
  EINDEX: 'eindex',
  SATISFACTION: 'sat',
  RECOMMENDATION: 'reco',
  EMOTIONS: 'emotions',
  SENSITIVITY: 'sensitivity',
};

const {
  EINDEX,
  EMOTIONS,
  RECOMMENDATION,
  SATISFACTION,
  SENSITIVITY,
} = REFINE_BY_CATEGORIES;

// NOTE: this is configuration for Filters,
// if you want to add new filter just it here, you don't have to change QRefineBy component
export const CONFIG = {
  [EINDEX]: { el: RefineByEindex, label: 'E-index' },
  [EMOTIONS]: { el: RefineByEmotions, label: 'Emotions' },
  [RECOMMENDATION]: { el: RefineByRecommendation, label: 'Recommendation' },
  [SATISFACTION]: { el: RefineBySatisfaction, label: 'Satisfaction' },
  [SENSITIVITY]: { el: RefineBySensitivity, label: 'Similarity' },
};

export default class QRefineBy extends Component {
  static propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({}).isRequired,
  };

  state = { currentCategory: null };

  prepareHeadersCategories() {
    const { filters, hiddenFilters } = this.props;

    const configToImplement = omit(CONFIG, hiddenFilters);
    return Object.keys(configToImplement).reduce((categories, id) => {
      const { label } = configToImplement[id];
      return [...categories, { label, id, active: !!filters[id] }];
    }, []);
  }

  prepareRefineByFilter() {
    const { filters } = this.props;
    const { currentCategory } = this.state;
    const { el: Filter } = CONFIG[currentCategory];
    return (
      <Filter
        type={currentCategory}
        onChange={value => this.onFilterChange(currentCategory, value)}
        value={filters[currentCategory]}
      />
    );
  }

  onFilterChange(category, value) {
    const { onFilterChange } = this.props;
    onFilterChange(category, value);
  }

  onCategorySelected = id => {
    this.setState(({ currentCategory }) => ({
      currentCategory: currentCategory === id ? null : id,
    }));
  };

  onFilterReset = () => this.onFilterChange(this.state.currentCategory, null);

  render() {
    const { currentCategory } = this.state;
    return (
      <Fragment>
        <FiltersHeaders
          categories={this.prepareHeadersCategories()}
          current={currentCategory}
          onCategorySelected={this.onCategorySelected}
        />
        {currentCategory && (
          <RefineByFilters>
            <RangeSection onReset={this.onFilterReset}>
              {this.prepareRefineByFilter()}
            </RangeSection>
          </RefineByFilters>
        )}
      </Fragment>
    );
  }
}
