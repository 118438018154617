import gql from 'graphql-tag';

const query = gql`
  query getCreditEstimation($range: DateRangeInput!, $sources: [String!]!) {
    getCreditEstimation(range: $range, sources: $sources) {
      name
      creditEstimation {
        min
        max
      }
    }
  }
`;

export default query;
