export const groupPermissions = {
  users: [
    {
      name: 'Account and billing',
      globalPermissions: {
        View: 'all',
        Edit: 'indeterminate',
        Delete: null,
      },
      detailedPermissions: [],
    },
    {
      name: 'User management',
      globalPermissions: {
        Add: null,
        Edit: 'indeterminate',
        Delete: null,
      },
      detailedPermissions: [],
    },
    {
      name: 'Projects',
      globalPermissions: {
        View: 'all',
        Add: 'all',
        Edit: 'all',
        Delete: 'all',
      },
      detailedPermissions: [],
    },
    {
      name: 'Modules and pages',
      globalPermissions: {
        View: 'all',
        Add: 'all',
        Edit: 'all',
        Delete: 'all',
      },
      detailedPermissions: [],
    },
  ],
  administrators: [
    {
      name: 'Account and billing',
      globalPermissions: {
        View: 'all',
        Edit: 'all',
        Delete: 'all',
      },
      detailedPermissions: [],
    },
    {
      name: 'User management',
      globalPermissions: {
        Add: 'all',
        Edit: 'all',
        Delete: 'all',
      },
      detailedPermissions: [],
    },
    {
      name: 'Projects',
      globalPermissions: {
        View: 'all',
        Add: 'all',
        Edit: 'all',
        Delete: 'all',
      },
      detailedPermissions: [],
    },
    {
      name: 'Modules and pages',
      globalPermissions: {
        View: 'all',
        Add: 'all',
        Edit: 'all',
        Delete: 'all',
      },
      detailedPermissions: [],
    },
  ],
};
