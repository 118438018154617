import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QPanelHeader from '_Components/Panels/QPanelHeader/QPanelHeader';
import QButton from '_Components/Buttons/QButton/QButton';
import {
  MessageValidation,
  StyledValidationIcon,
} from '_Cards/SettingsCards/AccountAndPreferencesCard/AccountAndPreferencesCard';
import { black, purple, grayShades, red, fear } from 'styles/abstracts/colors';
import AccountSection from './_Components/AccountSection';
import AddressInfosSection from './_Components/AddressInfosSection';
import ACCOUNT_DETAILS_QUERY from './graphql/accountDetails';
import SAVE_ACCOUNT_SETTINGS_MUTATION from './graphql/saveAccountSettings';

const StyledQPanel = styled(QPanel)`
  padding: 0;
  margin-top: 2rem;
`;

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 0;
  border-bottom: 1px solid ${grayShades.g300};
`;

const ButtonSave = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  & button {
    background-color: ${purple};
    &:hover {
      background-color: ${black};
    }
  }
`;

const ErrorMessage = styled.p`
  font-weight: bold;
  color: ${red};
`;

const AccountDetailsCard = ({ accountId, t, isUserAdmin }) => {
  const { data } = useQuery(ACCOUNT_DETAILS_QUERY, {
    variables: { accountId },
  });

  const [isValidated, setIsValidated] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [alpha3Cc, setAlpha3Cc] = useState(null);
  const [city, setCity] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [stateProvince, setStateProvince] = useState(null);

  const [saveAccountSettings] = useMutation(SAVE_ACCOUNT_SETTINGS_MUTATION, {
    variables: {
      name,
      companyName,
      address1,
      address2,
      address3,
      alpha3Cc,
      city,
      postalCode,
      stateProvince,
    },
    update: (cache, { data: { saveAccountSettings: saveSettings } }) => {
      const address = {
        address1,
        address2,
        address3,
        alpha3Cc,
        city,
        postalCode,
        stateProvince,
      };
      try {
        const { accountDetails } = cache.readQuery({
          query: ACCOUNT_DETAILS_QUERY,
          variables: { accountId },
        });
        accountDetails.address = address;
        accountDetails.companyName = companyName;
        accountDetails.name = name;
        cache.writeQuery({
          query: ACCOUNT_DETAILS_QUERY,
          variables: { accountId },
          data: { accountDetails: { ...accountDetails } },
        });
      } catch (e) {
        cache.writeQuery({
          query: ACCOUNT_DETAILS_QUERY,
          variables: { accountId },
          data: { accountDetails: { address, companyName, name } },
        });
      }
    },
  });

  useEffect(() => {
    setName(data?.accountDetails?.name);
    setCompanyName(data?.accountDetails?.companyName);
    setAddress1(data?.accountDetails?.address?.address1);
    setAddress2(data?.accountDetails?.address?.address2);
    setAddress3(data?.accountDetails?.address?.address3);
    setAlpha3Cc(data?.accountDetails?.address?.alpha3Cc);
    setCity(data?.accountDetails?.address?.city);
    setPostalCode(data?.accountDetails?.address?.postalCode);
    setStateProvince(data?.accountDetails?.address?.stateProvince);
  }, [data]);

  const getButtonDisabled = () => {
    return (
      (name?.length < 1 || name === data?.accountDetails?.name) &&
      (address1?.length < 1 ||
        address1 === data?.accountDetails?.address.address1) &&
      address2 === data?.accountDetails?.address.address2 &&
      address3 === data?.accountDetails?.address.address3 &&
      (city?.length < 1 || city === data?.accountDetails?.address.city) &&
      (postalCode?.length < 1 ||
        postalCode === data?.accountDetails?.address.postalCode) &&
      (stateProvince?.length < 1 ||
        stateProvince === data?.accountDetails?.address.stateProvince) &&
      (alpha3Cc?.length < 1 ||
        alpha3Cc === data?.accountDetails?.address.alpha3Cc)
    );
  };

  return (
    <StyledQPanel>
      <StyledQPanelHeader>
        <AccountSection
          displayBorderBottom
          name={name}
          setName={setName}
          isUserAdmin={isUserAdmin}
          setError={setError}
          error={error}
        />
        <AddressInfosSection
          companyName={companyName}
          setCompanyName={setCompanyName}
          address1={address1}
          address2={address2}
          address3={address3}
          alpha3Cc={alpha3Cc}
          city={city}
          postalCode={postalCode}
          stateProvince={stateProvince}
          setAddress1={setAddress1}
          setAddress2={setAddress2}
          setAddress3={setAddress3}
          setAlpha3Cc={setAlpha3Cc}
          setCity={setCity}
          setPostalCode={setPostalCode}
          setStateProvince={setStateProvince}
          isUserAdmin={isUserAdmin}
        />
        {isValidated && (
          <MessageValidation>
            <StyledValidationIcon icon="CHECK_ALL" color={fear} size={24} />{' '}
            {t('card:Settings updated')}
          </MessageValidation>
        )}
        {error && (
          <ErrorMessage>
            {t('card:One or more fields have not been filled in.')}
          </ErrorMessage>
        )}
      </StyledQPanelHeader>
      <ButtonSave>
        <QButton
          disabled={getButtonDisabled()}
          onClick={() => {
            saveAccountSettings()
              .then(() => {
                setIsValidated(true);
              })
              .catch(err => setError(true));
          }}
        >
          {t('Save settings')}
        </QButton>
      </ButtonSave>
    </StyledQPanel>
  );
};

export default withTranslation('button', 'card')(AccountDetailsCard);
