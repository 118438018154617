import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import QMapPanel from '../InsightPanel/InsightPanel';
import { purple } from '../../../../../../../styles/abstracts/colors';

const MapZoomPanel = styled(QMapPanel)`
  padding: 0;
  color: ${purple};
  line-height: 1.2em;
  display: flex;
  align-items: center;
`;
const secondaryColor = '#eaeef6';
const radius = '8px';
const ZoomButton = styled.button`
  outline: 0;
  background-color: white;
  border: none;
  border-right: ${({ left }) => left && `solid 1px ${secondaryColor}`};
  border-left: ${({ right }) => right && `solid 1px ${secondaryColor}`};
  border-top-left-radius: ${({ left }) => left && radius};
  border-bottom-left-radius: ${({ left }) => left && radius};
  border-top-right-radius: ${({ right }) => right && radius};
  border-bottom-right-radius: ${({ right }) => right && radius};
  color: inherit;
  padding: 0.2em 0.4em;
  text-align: center;
  font-size: 1.5em;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: ${secondaryColor};
  }
`;

const ZoomLabel = styled.span`
  margin: 0 0.5em;
`;

export default class ZoomControl extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onZoomIn: PropTypes.func.isRequired,
    onZoomOut: PropTypes.func.isRequired,
  };

  onZoomIn = () => {
    this.props.onZoomIn();
  };

  onZoomOut = () => {
    this.props.onZoomOut();
  };

  render() {
    const { className, value } = this.props;

    return (
      <MapZoomPanel className={className}>
        <ZoomButton left onClick={this.onZoomOut}>
          <span>-</span>
        </ZoomButton>
        <ZoomLabel>{value}</ZoomLabel>
        <ZoomButton right onClick={this.onZoomIn}>
          <span>+</span>
        </ZoomButton>
      </MapZoomPanel>
    );
  }
}
