export default {
  init: {
    size: { columns: 2, rows: 1 },
    children: [
      {
        name: 'section1',
        pdfStartingPoint: { x: 0, y: 0 },
      },
      {
        name: 'section2',
        pdfStartingPoint: { x: 1, y: 0 },
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr 1fr;
          grid-template-areas: "section1 section2" 
        `,
  },
  addPage: {
    size: { columns: 1, rows: 1 },
    children: [{ name: 'section1', pdfStartingPoint: { x: 0, y: 0 } }],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1" 
        `,
  },
};
