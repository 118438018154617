import { loadComponent } from '../../_Utils/routeComponent';

export default [
  {
    i: 'EindexTemperatureCard',
    component: 'EindexTemperatureCard',
    data: {
      title: 'Average E-index',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').xl.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').xl.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').xl.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').xl.minH,
        x: 0,
        y: 0,
      },
      lg: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').lg.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').lg.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').lg.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').lg.minH,
        x: 0,
        y: 0,
      },
      md: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').md.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').md.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').md.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').md.minH,
        x: 0,
        y: 0,
      },
      sm: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').sm.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').sm.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').sm.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').sm.minH,
        x: 0,
        y: 0,
      },
      xs: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').xs.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').xs.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').xs.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').xs.minH,
        x: 0,
        y: 0,
      },
    },
  },
  {
    i: 'SatisfactionNpsCard',
    component: 'SatisfactionNpsCard',
    data: {
      title: 'Satisfaction',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('SatisfactionNpsCard', 'breakpoints').xl.w,
        h: loadComponent('SatisfactionNpsCard', 'breakpoints').xl.h,
        minW: loadComponent('SatisfactionNpsCard', 'breakpoints').xl.minW,
        minH: loadComponent('SatisfactionNpsCard', 'breakpoints').xl.minH,
        x: 6,
        y: 0,
      },
      lg: {
        w: loadComponent('SatisfactionNpsCard', 'breakpoints').lg.w,
        h: loadComponent('SatisfactionNpsCard', 'breakpoints').lg.h,
        minW: loadComponent('SatisfactionNpsCard', 'breakpoints').lg.minW,
        minH: loadComponent('SatisfactionNpsCard', 'breakpoints').lg.minH,
        x: 6,
        y: 0,
      },
      md: {
        w: loadComponent('SatisfactionNpsCard', 'breakpoints').md.w,
        h: loadComponent('SatisfactionNpsCard', 'breakpoints').md.h,
        minW: loadComponent('SatisfactionNpsCard', 'breakpoints').md.minW,
        minH: loadComponent('SatisfactionNpsCard', 'breakpoints').md.minH,
        x: 4,
        y: 0,
      },
      sm: {
        w: loadComponent('SatisfactionNpsCard', 'breakpoints').sm.w,
        h: loadComponent('SatisfactionNpsCard', 'breakpoints').sm.h,
        minW: loadComponent('SatisfactionNpsCard', 'breakpoints').sm.minW,
        minH: loadComponent('SatisfactionNpsCard', 'breakpoints').sm.minH,
        x: 2,
        y: 0,
      },
      xs: {
        w: loadComponent('SatisfactionNpsCard', 'breakpoints').xs.w,
        h: loadComponent('SatisfactionNpsCard', 'breakpoints').xs.h,
        minW: loadComponent('SatisfactionNpsCard', 'breakpoints').xs.minW,
        minH: loadComponent('SatisfactionNpsCard', 'breakpoints').xs.minH,
        x: 0,
        y: 8,
      },
    },
  },
  {
    i: 'GaugeCard',
    component: 'GaugeCard',
    data: {
      title: 'Emotional intensity',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('GaugeCard', 'breakpoints').xl.w,
        h: loadComponent('GaugeCard', 'breakpoints').xl.h,
        minW: loadComponent('GaugeCard', 'breakpoints').xl.minW,
        minH: loadComponent('GaugeCard', 'breakpoints').xl.minH,
        x: 10,
        y: 0,
      },
      lg: {
        w: loadComponent('GaugeCard', 'breakpoints').lg.w,
        h: loadComponent('GaugeCard', 'breakpoints').lg.h,
        minW: loadComponent('GaugeCard', 'breakpoints').lg.minW,
        minH: loadComponent('GaugeCard', 'breakpoints').lg.minH,
        x: 10,
        y: 0,
      },
      md: {
        w: loadComponent('GaugeCard', 'breakpoints').md.w,
        h: loadComponent('GaugeCard', 'breakpoints').md.h,
        minW: loadComponent('GaugeCard', 'breakpoints').md.minW,
        minH: loadComponent('GaugeCard', 'breakpoints').md.minH,
        x: 0,
        y: 8,
      },
      sm: {
        w: loadComponent('GaugeCard', 'breakpoints').sm.w,
        h: loadComponent('GaugeCard', 'breakpoints').sm.h,
        minW: loadComponent('GaugeCard', 'breakpoints').sm.minW,
        minH: loadComponent('GaugeCard', 'breakpoints').sm.minH,
        x: 0,
        y: 8,
      },
      xs: {
        w: loadComponent('GaugeCard', 'breakpoints').xs.w,
        h: loadComponent('GaugeCard', 'breakpoints').xs.h,
        minW: loadComponent('GaugeCard', 'breakpoints').xs.minW,
        minH: loadComponent('GaugeCard', 'breakpoints').xs.minH,
        x: 0,
        y: 16,
      },
    },
  },
  {
    i: 'SpeechEngagementCard',
    component: 'SpeechEngagementCard',
    data: {
      title: 'Speech engagement',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('SpeechEngagementCard', 'breakpoints').xl.w,
        h: loadComponent('SpeechEngagementCard', 'breakpoints').xl.h,
        minW: loadComponent('SpeechEngagementCard', 'breakpoints').xl.minW,
        minH: loadComponent('SpeechEngagementCard', 'breakpoints').xl.minH,
        x: 13,
        y: 0,
      },
      lg: {
        w: loadComponent('SpeechEngagementCard', 'breakpoints').lg.w,
        h: loadComponent('SpeechEngagementCard', 'breakpoints').lg.h,
        minW: loadComponent('SpeechEngagementCard', 'breakpoints').lg.minW,
        minH: loadComponent('SpeechEngagementCard', 'breakpoints').lg.minH,
        x: 13,
        y: 0,
      },
      md: {
        w: loadComponent('SpeechEngagementCard', 'breakpoints').md.w,
        h: loadComponent('SpeechEngagementCard', 'breakpoints').md.h,
        minW: loadComponent('SpeechEngagementCard', 'breakpoints').md.minW,
        minH: loadComponent('SpeechEngagementCard', 'breakpoints').md.minH,
        x: 4,
        y: 8,
      },
      sm: {
        w: loadComponent('SpeechEngagementCard', 'breakpoints').sm.w,
        h: loadComponent('SpeechEngagementCard', 'breakpoints').sm.h,
        minW: loadComponent('SpeechEngagementCard', 'breakpoints').sm.minW,
        minH: loadComponent('SpeechEngagementCard', 'breakpoints').sm.minH,
        x: 8,
        y: 2,
      },
      xs: {
        w: loadComponent('SpeechEngagementCard', 'breakpoints').xs.w,
        h: loadComponent('SpeechEngagementCard', 'breakpoints').xs.h,
        minW: loadComponent('SpeechEngagementCard', 'breakpoints').xs.minW,
        minH: loadComponent('SpeechEngagementCard', 'breakpoints').xs.minH,
        x: 8,
        y: 24,
      },
    },
  },
  {
    i: 'EindexEvolutionWithMentionsCard',
    component: 'EindexEvolutionWithMentionsCard',
    data: {
      title: 'E-Index evolution in time scope',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: 16,
        h: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xl.h,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xl.minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xl.minH,
        x: 0,
        y: 8,
      },
      lg: {
        w: 16,
        h: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').lg.h,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').lg.minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').lg.minH,
        x: 0,
        y: 8,
      },
      md: {
        w: 16,
        h: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').md.h,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').md.minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').md.minH,
        x: 0,
        y: 16,
      },
      sm: {
        w: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').sm.w,
        h: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').sm.h,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').sm.minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').sm.minH,
        x: 0,
        y: 16,
      },
      xs: {
        w: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xs.w,
        h: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xs.h,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xs.minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xs.minH,
        x: 0,
        y: 32,
      },
    },
  },
  // {
  //   i: 'MentionRepartitionPerSourceCard',
  //   component: 'MentionRepartitionPerSourceCard',
  //   data: {
  //     title: 'Mention repartition per verbatim source',
  //     titleSecondPart: '',
  //   },
  //   breakpoints: {
  //     xl: {
  //       w: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').xl.w,
  //       h: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').xl.h,
  //       minW: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').xl
  //         .minW,
  //       minH: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').xl
  //         .minH,
  //       x: 8,
  //       y: 8,
  //     },
  //     lg: {
  //       w: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').lg.w,
  //       h: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').lg.h,
  //       minW: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').lg
  //         .minW,
  //       minH: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').lg
  //         .minH,
  //       x: 8,
  //       y: 8,
  //     },
  //     md: {
  //       w: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').md.w,
  //       h: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').md.h,
  //       minW: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').md
  //         .minW,
  //       minH: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').md
  //         .minH,
  //       x: 8,
  //       y: 25,
  //     },
  //     sm: {
  //       w: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').sm.w,
  //       h: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').sm.h,
  //       minW: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').sm
  //         .minW,
  //       minH: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').sm
  //         .minH,
  //       x: 0,
  //       y: 25,
  //     },
  //     xs: {
  //       w: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').xs.w,
  //       h: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').xs.h,
  //       minW: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').xs
  //         .minW,
  //       minH: loadComponent('MentionRepartitionPerSourceCard', 'breakpoints').xs
  //         .minH,
  //       x: 0,
  //       y: 41,
  //     },
  //   },
  // },
  {
    i: 'EmotionBreakdownDonutCard',
    component: 'EmotionBreakdownDonutCard',
    data: {
      title: 'Emotion breakdown',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').xl.w,
        h: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').xl.h,
        minW: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').xl.minW,
        minH: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').xl.minH,
        x: 0,
        y: 16,
      },
      lg: {
        w: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').lg.w,
        h: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').lg.h,
        minW: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').lg.minW,
        minH: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').lg.minH,
        x: 0,
        y: 16,
      },
      md: {
        w: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').md.w,
        h: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').md.h,
        minW: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').md.minW,
        minH: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').md.minH,
        x: 0,
        y: 34,
      },
      sm: {
        w: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').sm.w,
        h: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').sm.h,
        minW: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').sm.minW,
        minH: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').sm.minH,
        x: 0,
        y: 32,
      },
      xs: {
        w: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').xs.w,
        h: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').xs.h,
        minW: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').xs.minW,
        minH: loadComponent('EmotionBreakdownDonutCard', 'breakpoints').xs.minH,
        x: 0,
        y: 48,
      },
    },
  },
  {
    i: 'EmotionEvolutionLineCard',
    component: 'EmotionEvolutionLineCard',
    data: {
      title: 'Emotion breakdown evolution',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('EmotionEvolutionLineCard', 'breakpoints').xl.w,
        h: loadComponent('EmotionEvolutionLineCard', 'breakpoints').xl.h,
        minW: loadComponent('EmotionEvolutionLineCard', 'breakpoints').xl.minW,
        minH: loadComponent('EmotionEvolutionLineCard', 'breakpoints').xl.minH,
        x: 6,
        y: 16,
      },
      lg: {
        w: loadComponent('EmotionEvolutionLineCard', 'breakpoints').lg.w,
        h: loadComponent('EmotionEvolutionLineCard', 'breakpoints').lg.h,
        minW: loadComponent('EmotionEvolutionLineCard', 'breakpoints').lg.minW,
        minH: loadComponent('EmotionEvolutionLineCard', 'breakpoints').lg.minH,
        x: 6,
        y: 16,
      },
      md: {
        w: loadComponent('EmotionEvolutionLineCard', 'breakpoints').md.w,
        h: loadComponent('EmotionEvolutionLineCard', 'breakpoints').md.h,
        minW: loadComponent('EmotionEvolutionLineCard', 'breakpoints').md.minW,
        minH: loadComponent('EmotionEvolutionLineCard', 'breakpoints').md.minH,
        x: 3,
        y: 34,
      },
      sm: {
        w: loadComponent('EmotionEvolutionLineCard', 'breakpoints').sm.w,
        h: loadComponent('EmotionEvolutionLineCard', 'breakpoints').sm.h,
        minW: loadComponent('EmotionEvolutionLineCard', 'breakpoints').sm.minW,
        minH: loadComponent('EmotionEvolutionLineCard', 'breakpoints').sm.minH,
        x: 0,
        y: 40,
      },
      xs: {
        w: loadComponent('EmotionEvolutionLineCard', 'breakpoints').xs.w,
        h: loadComponent('EmotionEvolutionLineCard', 'breakpoints').xs.h,
        minW: loadComponent('EmotionEvolutionLineCard', 'breakpoints').xs.minW,
        minH: loadComponent('EmotionEvolutionLineCard', 'breakpoints').xs.minH,
        x: 0,
        y: 56,
      },
    },
  },
  {
    i: 'CategoryGlobalBestAndPainPointsCard',
    component: 'CategoryGlobalBestAndPainPointsCard',
    data: {
      title: 'Global best & pain points',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').xl.w,
        h: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').xl.h,
        minW: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').xl.minW,
        minH: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').xl.minH,
        x: 0,
        y: 26,
      },
      lg: {
        w: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').lg.w,
        h: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').lg.h,
        minW: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').lg.minW,
        minH: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').lg.minH,
        x: 0,
        y: 26,
      },
      md: {
        w: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').md.w,
        h: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').md.h,
        minW: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').md.minW,
        minH: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').md.minH,
        x: 0,
        y: 44,
      },
      sm: {
        w: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').sm.w,
        h: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').sm.h,
        minW: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').sm.minW,
        minH: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').sm.minH,
        x: 0,
        y: 50,
      },
      xs: {
        w: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').xs.w,
        h: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').xs.h,
        minW: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').xs.minW,
        minH: loadComponent('CategoryGlobalBestAndPainPointsCard', 'breakpoints').xs.minH,
        x: 0,
        y: 66,
      },
    },
  },
  {
    i: 'VerbatimGlobalBestAndPainPointsCard',
    component: 'VerbatimGlobalBestAndPainPointsCard',
    data: {
      title: 'Tops & Flops',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').xl.w,
        h: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').xl.h,
        minW: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').xl.minW,
        minH: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').xl.minH,
        x: 8,
        y: 26,
      },
      lg: {
        w: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').lg.w,
        h: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').lg.h,
        minW: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').lg.minW,
        minH: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').lg.minH,
        x: 8,
        y: 26,
      },
      md: {
        w: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').md.w,
        h: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').md.h,
        minW: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').md.minW,
        minH: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').md.minH,
        x: 0,
        y: 64,
      },
      sm: {
        w: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').sm.w,
        h: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').sm.h,
        minW: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').sm.minW,
        minH: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').sm.minH,
        x: 0,
        y: 70,
      },
      xs: {
        w: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').xs.w,
        h: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').xs.h,
        minW: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').xs.minW,
        minH: loadComponent('VerbatimGlobalBestAndPainPointsCard', 'breakpoints').xs.minH,
        x: 0,
        y: 86,
      },
    },
  },
  {
    i: 'WordCloudCard',
    component: 'WordCloudCard',
    data: {
      title: 'Word frequency',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('WordCloudCard', 'breakpoints').xl.w,
        h: loadComponent('WordCloudCard', 'breakpoints').xl.h,
        minW: loadComponent('WordCloudCard', 'breakpoints').xl.minW,
        minH: loadComponent('WordCloudCard', 'breakpoints').xl.minH,
        x: 0,
        y: 35,
      },
      lg: {
        w: loadComponent('WordCloudCard', 'breakpoints').lg.w,
        h: loadComponent('WordCloudCard', 'breakpoints').lg.h,
        minW: loadComponent('WordCloudCard', 'breakpoints').lg.minW,
        minH: loadComponent('WordCloudCard', 'breakpoints').lg.minH,
        x: 0,
        y: 35,
      },
      md: {
        w: loadComponent('WordCloudCard', 'breakpoints').md.w,
        h: loadComponent('WordCloudCard', 'breakpoints').md.h,
        minW: loadComponent('WordCloudCard', 'breakpoints').md.minW,
        minH: loadComponent('WordCloudCard', 'breakpoints').md.minH,
        x: 0,
        y: 54,
      },
      sm: {
        w: loadComponent('WordCloudCard', 'breakpoints').sm.w,
        h: loadComponent('WordCloudCard', 'breakpoints').sm.h,
        minW: loadComponent('WordCloudCard', 'breakpoints').sm.minW,
        minH: loadComponent('WordCloudCard', 'breakpoints').sm.minH,
        x: 0,
        y: 60,
      },
      xs: {
        w: loadComponent('WordCloudCard', 'breakpoints').xs.w,
        h: loadComponent('WordCloudCard', 'breakpoints').xs.h,
        minW: loadComponent('WordCloudCard', 'breakpoints').xs.minW,
        minH: loadComponent('WordCloudCard', 'breakpoints').xs.minH,
        x: 0,
        y: 76,
      },
    },
  },
];
