
import React from 'react';
import Layout from '_Layouts/Layout';
import InsertData from './InsertData';

const title = 'POC Data Grid';
const body = <InsertData />;

const action = props => <Layout title={title} body={body} {...props} />;

export default action;
