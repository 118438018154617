import styled from 'styled-components';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { grayShades, purple } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QButton from '../../../../../../_Components/Buttons/QButton/QButton';

export const StyledDropdown = styled(Dropdown)``;

export const ToggleButton = styled(QButton)`
  color: ${purple} !important;
  min-width: ${({ size }) => {
    switch (size) {
      case 'xl':
        return '26em !important;';
      default:
        return '13em !important';
    }
  }};
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  & *:focus {
    outline: none !important;
  }
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  background-color: transparent !important;
  padding: 0 !important;
  & *:focus {
    outline: none !important;
  }
`;

export const Menu = styled(DropdownMenu)`
  margin-top: 0.5em !important;
  background-color: ${grayShades.g300} !important;
  border: none !important;
  border-radius: 1em !important;
  max-height: 15em;
  overflow-y: scroll;
  & *:focus {
    outline: none !important;
  }
`;

export const Item = styled(DropdownItem)`
  font-weight: bold !important;
  font-size: ${fontSizes.smaller} !important;
  color: ${({ disabled }) => (disabled ? grayShades.g800 : purple)} !important;
  padding: 0.25em 1em !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  &:hover {
    background-color: transparent !important;
  }
  &:not(:first-child) {
    margin-top: 0.75em !important;
  }

  & *:focus {
    outline: none !important;
  }
`;

export const Label = styled.p`
  font-weight: bold;
  color: ${purple};
`;
