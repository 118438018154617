/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import { isNil } from 'lodash';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FILTER_TYPES } from '../../../../../../../../../_Components/QFilters/QFilters';
import QFilteredVerbatimList, {
  getEmptyFilters,
} from '../../../../../../../../../_Container/QFilteredVerbatimList/QFilteredVerbatimList';

const ModalSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const CounterNumbers = styled.span`
  color: ${({ primary }) => (primary ? '#0ec3e3' : '#a7adc7')};
  font-size: ${({ primary }) => (primary ? '1em' : '0.75em')};
  font-weight: bold;
`;
const CurrentNumber = styled.span`
  font-size: ${({ primary }) => (primary ? '2em' : '1em')};
`;

const CounterBody = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  color: #a7adc7;
`;

const AllNumber = styled.span``;

function getVerbatimCounterNumber(verbatim) {
  return verbatim
    ? verbatim.reduce(
        (counter, { sentences }) =>
          counter + sentences.filter(({ relevant: r }) => r).length,
        0,
      )
    : 0;
}

const VerbatimCounter = ({ all, current, primary, children }) => (
  <CounterBody>
    <CounterNumbers primary={primary}>
      <CurrentNumber primary={primary}>
        {current >= all ? all : current}
      </CurrentNumber>{' '}
      / <AllNumber>{all}</AllNumber>
    </CounterNumbers>
    {children}
  </CounterBody>
);

const isSentenceEqual = s1 => s2 => s1.id === s2.id && s1.order === s2.order;

class Results extends Component {
  static propTypes = {
    onNextStepAvailabilityChanged: PropTypes.func.isRequired,
    onDataChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    insights: PropTypes.any.isRequired,
  };

  state = {
    selectedSentences: [],
    filtersOpen: false,
    filters: null,
    filteredVerbatim: null,
    projectFilters: {
      [FILTER_TYPES.RELATED]: [],
      [FILTER_TYPES.NORMAL]: [],
    },
  };

  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      filteredVerbatim: props.insights,
      allSelectedVerbatim: false,
      verbatimList: [],
    };
    this.initializeFilters(data);
    this.initializeSelectedSentences(data);
  }

  componentDidMount() {
    const { onNextStepAvailabilityChanged } = this.props;
    onNextStepAvailabilityChanged(!!this.state.selectedSentences.length);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedSentences !== prevState.selectedSentences) {
      this.setState({ prevSelectedSentences: prevState.selectedSentences });
    }
  }

  notifyDataChange() {
    const { onDataChange } = this.props;
    const { filters, selectedSentences } = this.state;
    onDataChange({ filters, selectedSentences });
  }

  initializeFilters({ filters }) {
    this.state.filters = filters || getEmptyFilters();
  }

  initializeSelectedSentences({ selectedSentences }) {
    this.state.selectedSentences = selectedSentences || [];
  }

  updateVerbatimList = verbatimList => {
    this.setState({ verbatimList });
  };

  onVerbatimChange = changed => {
    const { onNextStepAvailabilityChanged } = this.props;
    const isEqualToChanged = isSentenceEqual(changed);
    this.setState(
      ({ selectedSentences }) => {
        const newSentences = selectedSentences.find(isEqualToChanged)
          ? selectedSentences.filter(sentence => !isEqualToChanged(sentence))
          : [...selectedSentences, changed];
        if (
          selectedSentences.length === this.state.verbatimList.length ||
          (this.state.prevSelectedSentences &&
            selectedSentences.length === this.state.verbatimList.length - 1 &&
            this.state.prevSelectedSentences.length !==
              this.state.verbatimList.length)
        ) {
          this.toggleAllSelectedVerbatim();
        }
        return { selectedSentences: newSentences };
      },
      () => {
        onNextStepAvailabilityChanged(!!this.state.selectedSentences.length);
        this.notifyDataChange();
      },
    );
  };

  toggleAllSelectedVerbatim = () => {
    this.setState({ allSelectedVerbatim: !this.state.allSelectedVerbatim });
  };

  onProjectFiltersClose = () => this.setState({ filtersOpen: false });
  onProjectFiltersOpen = () => this.setState({ filtersOpen: true });
  onProjectFilterChange = projectFilters => this.setState({ projectFilters });
  onProjectFilterClear = () => this.setState({ projectFilters: {} });

  getFilteredSentencesNumber(verbatim) {
    const { selectedSentences } = this.state;

    return selectedSentences.reduce((counter, { id, order }) => {
      const verbatimText = verbatim.find(
        ({ id: verbatimId }) => verbatimId === id,
      );

      if (!verbatimText) {
        return counter;
      } else if (isNil(order)) {
        const hasRelevant = verbatimText.sentences.find(
          ({ relevant }) => relevant,
        );
        return hasRelevant ? counter + 1 : counter;
      }
      const sentence = verbatimText.sentences.find(
        ({ order: sentenceOrder }) => sentenceOrder === order,
      );
      return sentence && sentence.relevant ? counter + 1 : counter;
    }, 0);
  }

  onFilterChanged = (filters, filteredVerbatim) =>
    this.setState({ filters, filteredVerbatim }, () => this.notifyDataChange());

  render() {
    const { insights, t } = this.props;
    const {
      filters,
      filteredVerbatim,
      selectedSentences,
      filtersOpen,
      projectFilters,
    } = this.state;
    return (
      <Fragment>
        <ModalSection>
          <VerbatimCounter
            primary
            all={getVerbatimCounterNumber(filteredVerbatim)}
            current={this.getFilteredSentencesNumber(filteredVerbatim)}
          />
        </ModalSection>
        <ModalSection>
          <VerbatimCounter
            all={getVerbatimCounterNumber(insights)}
            current={selectedSentences.length}
          >
            <span>
              {' '}
              {t('verbatim selected in total', {
                count: selectedSentences.length,
              })}
            </span>
          </VerbatimCounter>
        </ModalSection>
        {/* <ModalSection>
          <ButtonWrapper>
            <FilterButton
              color="primary"
              onClick={this.onProjectFiltersOpen}
              onClear={this.onProjectFilterClear}
              filters={projectFilters}
              label="Add some project filters"
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <StyledButton color="primary">Add a thematic filter</StyledButton>
          </ButtonWrapper>
        </ModalSection> */}
        <QFilteredVerbatimList
          verbatim={insights}
          filteredVerbatim={filteredVerbatim}
          filters={filters}
          selectedSentences={selectedSentences}
          onFilterChanged={this.onFilterChanged}
          onVerbatimChanged={this.onVerbatimChange}
          toggleAllSelectedVerbatim={this.toggleAllSelectedVerbatim}
          allSelectedVerbatim={this.state.allSelectedVerbatim}
          updateVerbatimList={this.updateVerbatimList}
          verbatimList={this.state.verbatimList}
        />
        {/* <ProjectFiltersModal */}
        {/* onFilterChanged={this.onProjectFilterChange} */}
        {/* value={projectFilters} */}
        {/* isOpen={filtersOpen} */}
        {/* onClose={this.onProjectFiltersClose} */}
        {/* /> */}
      </Fragment>
    );
  }
}

export default withTranslation('insights')(Results);
