import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { textColor } from '../../../../../../../../styles/abstracts/colors';
import QTitle from '../../../../../../../../_Components/Texts/QTitle/QTitle';

const SideBarCategoryArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

const StyledQTitle = styled(QTitle)`
  color: ${textColor};
`;

const SideBarCategory = ({ label, children }) => (
  <SideBarCategoryArea>
    {label && (
      <StyledQTitle>
        <QTitle.Title>{label}</QTitle.Title>
      </StyledQTitle>
    )}
    <div>{children}</div>
  </SideBarCategoryArea>
);

SideBarCategory.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SideBarCategory;
