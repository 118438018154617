import React from 'react';
import { withTranslation } from 'react-i18next';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import CQuery from '../../../_Container/QQuery/CQuery';
import query from './graphql/geReportingEindexBarGraphChartCardData';
import CBarGraphChart from '../../../_Charts/PoleCharts/CBarGraphChart/CBarGraphChart';

const EindexBarGraphSection = ({ filters, height, t }) => {
  const newShapeofFilters = {
    ...filters,
    thematicsToShow: filters && filters.thematics,
    thematics: [],
  };
  return (
    <CQuery query={query} filterKeys={newShapeofFilters}>
      <EindexBarGraphContainer height={height} t={t} />
    </CQuery>
  );
};

export const EindexBarGraphContainer = props =>
  props.data.data.length > 0 ? (
    <CBarGraphChart orientation="vertical" rotateLabel data={props.data} height={props.height} />
  ) : (
    <NoDataMessage
      mainTitle={props.t('This category does not contain any subcategory!')}
      secondText={props.t('Contact the technical team for further information.')}
    />
  );
export default withTranslation('card')(EindexBarGraphSection);
