import { getColorHexaFromEindex } from '_Utils/eindex/eindexColorFunctions';
import { upperFirst, max, min } from 'lodash';
import { convertCelsiusToFahrenheit, getMinMaxFromUnit } from '_Utils/eindex/eindexUnits';
import { purple, green, red } from 'styles/abstracts/colors';
import { INDICATORS_TYPE } from '_Cards/FilterCards/DataByFiltersCard/FiltersSettings';

export const FILTERS_DIMENSIONS = {
  EINDEX: 'eindex',
  MENTIONS: 'mentions',
  EMOTIONS: 'emotions',
  BYMENTIONCOUNT: 'byMentionCount',
  BYPERCENTAGE: 'byPercentage',
  HAPPINESS: 'happiness',
  SURPRISE: 'surprise',
  CALM: 'calm',
  FEAR: 'fear',
  SADNESS: 'sadness',
  ANGER: 'anger',
  DISGUST: 'disgust',
  HAPPINESS_BYMENTIONCOUNT: 'emotions.happiness.byMentionCount',
  HAPPINESS_BYPERCENTAGE: 'emotions.happiness.byPercentage',
  SURPRISE_BYMENTIONCOUNT: 'emotions.surprise.byMentionCount',
  SURPRISE_BYPERCENTAGE: 'emotions.surprise.byPercentage',
  CALM_BYMENTIONCOUNT: 'emotions.calm.byMentionCount',
  CALM_BYPERCENTAGE: 'emotions.calm.byPercentage',
  FEAR_BYMENTIONCOUNT: 'emotions.fear.byMentionCount',
  FEAR_BYPERCENTAGE: 'emotions.fear.byPercentage',
  SADNESS_BYMENTIONCOUNT: 'emotions.sadness.byMentionCount',
  SADNESS_BYPERCENTAGE: 'emotions.sadness.byPercentage',
  ANGER_BYMENTIONCOUNT: 'emotions.anger.byMentionCount',
  ANGER_BYPERCENTAGE: 'emotions.anger.byPercentage',
  DISGUST_BYMENTIONCOUNT: 'emotions.disgust.byMentionCount',
  DISGUST_BYPERCENTAGE: 'emotions.disgust.byPercentage',
  EMOTIONSVOLUME: 'emotions.volume',
  EMOTIONSPERCENTAGE: 'emotions.percentage',
  SENTIMENTVOLUME: 'sentiment.volume',
  SENTIMENTPERCENTAGE: 'sentiment.percentage',
  MENTIONEVOLUTION: 'mentionEvolution',
  MENTIONEVOLUTION_PERIODEBEFORE: 'mentionEvolution.periodBefore',
  MENTIONEVOLUTION_YEARBEFORE: 'mentionEvolution.yearBefore',
  EINDEXEVOLUTION: 'eindexEvolution',
  EINDEXEVOLUTION_PERIODEBEFORE: 'eindexEvolution.periodBefore',
  EINDEXEVOLUTION_YEARBEFORE: 'eindexEvolution.yearBefore',
  SATISFACTION: 'satisfaction',
  RECOMMENDATION: 'recommendation',
  EFFORT: 'effort',
  SENTIMENT: 'sentiment',
};

export const INDICATORS_CONFIG = {
  [INDICATORS_TYPE.BADGE]: [
    {
      id: null,
      label: 'None',
    },
    {
      id: FILTERS_DIMENSIONS.EINDEX,
      label: 'E-index',
      color: (eindex, isFahrenheit) => getColorHexaFromEindex(eindex, isFahrenheit),
      min: (data, eindexUnitFahrenheit) =>
        data && getMinMaxFromUnit(eindexUnitFahrenheit).min - (eindexUnitFahrenheit ? 40 : 20),
      max: (data, eindexUnitFahrenheit) => data && getMinMaxFromUnit(eindexUnitFahrenheit).max,
      data: (item, isFahrenheit) => {
        return isFahrenheit ? convertCelsiusToFahrenheit(item.eindex) : item.eindex;
      },
    },
    {
      id: FILTERS_DIMENSIONS.MENTIONS,
      label: upperFirst(FILTERS_DIMENSIONS.MENTIONS),
      color: data => purple,
      min: data => data && 0 - max(data.map(item => item.mentionCount)) * 4,
      max: data => {
        return data && max(data.map(item => item.mentionCount));
      },
      data: item => item.mentionCount,
    },
    {
      id: FILTERS_DIMENSIONS.MENTIONEVOLUTION,
      label: 'Mention evolution',
      subcategories: [
        {
          id: FILTERS_DIMENSIONS.MENTIONEVOLUTION_YEARBEFORE,
          label: 'Mention evolution - Year before',
          color: data => (data.value < 0 ? red : green),
          min: (data, isFahrenheit, indicator) => {
            const [id, subId] = indicator.split('.');
            return min(data.map(d => d[id] && d[id][subId] && d[id][subId].delta)) * 6;
          },
          max: (data, isFahrenheit, indicatorType, indicator) => {
            const [id, subId] = indicator.split('.');
            return max(data.map(d => d[id] && d[id][subId] && d[id][subId].delta)) * 2;
          },
          data: (item, isFahrenheit, indicator) => {
            const [id, subId] = indicator.id.split('.');
            return item[id][subId]?.delta > 0 ? `+${item[id][subId]?.delta}` : item[id][subId]?.delta;
          },
          fontColor: (item, indicator) => {
            const [id, subId] = indicator?.split('.');
            if (item && item[id] && item[id][subId]) {
              return `${item[id][subId].delta}`[0] === '-' ? red : green;
            }
          },
        },
        {
          id: FILTERS_DIMENSIONS.MENTIONEVOLUTION_PERIODEBEFORE,
          label: 'Mention evolution - Period before',
          color: data => (data.value < 0 ? red : green),
          min: (data, isFahrenheit, indicator) => {
            const [id, subId] = indicator.split('.');
            return min(data.map(d => d[id] && d[id][subId] && d[id][subId].delta)) * 6;
          },
          max: (data, isFahrenheit, indicatorType, indicator) => {
            const [id, subId] = indicator.split('.');
            return max(data.map(d => d[id] && d[id][subId] && d[id][subId].delta)) * 2;
          },
          data: (item, isFahrenheit, indicator) => {
            const [id, subId] = indicator.id.split('.');
            if (item[id]) {
              return item[id][subId]?.delta > 0 ? `+${item[id][subId]?.delta}` : item[id][subId]?.delta;
            }
          },
          fontColor: (item, indicator) => {
            const [id, subId] = indicator?.split('.');
            if (item && item[id] && item[id][subId]) {
              return `${item[id][subId].delta}`[0] === '-' ? red : green;
            }
          },
        },
      ],
    },
    {
      id: FILTERS_DIMENSIONS.EINDEXEVOLUTION,
      label: 'E-index evolution',
      subcategories: [
        {
          id: FILTERS_DIMENSIONS.EINDEXEVOLUTION_YEARBEFORE,
          label: 'E-index evolution - Year before',
          color: data => (data.value < 0 ? red : green),
          min: (data, isFahrenheit, indicator) => {
            const [id, subId] = indicator.split('.');
            return min(data.map(d => d.id && d[id][subId] && d[id][subId].delta)) * 6;
          },
          max: (data, isFahrenheit, indicatorType, indicator) => {
            const [id, subId] = indicator.split('.');
            return max(data.map(d => d[id] && d[id][subId] && d[id][subId].delta)) * 2;
          },
          data: (item, isFahrenheit, indicator) => {
            const [id, subId] = indicator.id.split('.');
            if (item[id]) {
              return item[id][subId]?.delta > 0 ? `+${item[id][subId]?.delta}` : item[id][subId]?.delta;
            }
          },
          fontColor: (item, indicator) => {
            const [id, subId] = indicator?.split('.');
            if (item && item[id] && item[id][subId]) {
              return `${item[id][subId].delta}`[0] === '-' ? red : green;
            }
          },
        },
        {
          id: FILTERS_DIMENSIONS.EINDEXEVOLUTION_PERIODEBEFORE,
          label: 'E-index evolution - Period before',
          color: data => (data.value < 0 ? red : green),
          min: (data, isFahrenheit, indicator) => {
            const [id, subId] = indicator.split('.');
            return min(data.map(d => d[id] && d[id][subId] && d[id][subId].delta)) * 6;
          },
          max: (data, isFahrenheit, indicatorType, indicator) => {
            const [id, subId] = indicator.split('.');
            return max(data.map(d => d[id] && d[id][subId] && d[id][subId].delta)) * 2;
          },
          data: (item, isFahrenheit, indicator) => {
            const [id, subId] = indicator.id.split('.');
            if (item[id]) {
              return item[id][subId]?.delta > 0 ? `+${item[id][subId]?.delta}` : item[id][subId]?.delta;
            }
          },
          fontColor: (item, indicator) => {
            const [id, subId] = indicator?.split('.');
            if (item && item[id] && item[id][subId]) {
              return `${item[id][subId].delta}`[0] === '-' ? red : green;
            }
          },
        },
      ],
    },
  ],
  [INDICATORS_TYPE.BAR]: [
    {
      id: null,
      label: 'None',
    },
    {
      id: FILTERS_DIMENSIONS.EINDEX,
      label: 'E-index',
      color: (eindex, isFahrenheit) => getColorHexaFromEindex(eindex, isFahrenheit),
      min: (data, isFahrenheit) =>
        data && min(data.map(item => !isFahrenheit && item.eindex)) < 0 ? min(data.map(item => item.eindex)) : 0,
      max: (filterKeys, eindexUnitFahrenheit) => filterKeys && getMinMaxFromUnit(eindexUnitFahrenheit).max,
      data: (item, isFahrenheit) => (isFahrenheit ? convertCelsiusToFahrenheit(item.eindex) : item.eindex),
    },
    {
      id: FILTERS_DIMENSIONS.MENTIONS,
      label: upperFirst(FILTERS_DIMENSIONS.MENTIONS),
      color: data => purple,
      min: () => 0,
      max: data => data && max(data.map(item => item.mentionCount)) * 2,
      data: item => item.mentionCount,
    },
    {
      id: FILTERS_DIMENSIONS.EMOTIONS,
      label: upperFirst(FILTERS_DIMENSIONS.EMOTIONS),
      min: () => 0,
      max: (data, isFahrenheit, indicator) => {
        if (indicator.selected && indicator.selected[0]) {
          const emotionsValues = data.map(d => {
            const [id, subId, subSubId] =
              indicator.selected && indicator.selected[0] && indicator.selected[0].split('.');
            return indicator.selected && indicator.selected[0] && d && d[id] ? Math.round(d[id][subId][subSubId]) : 0;
          });
          return max(emotionsValues) * 2;
        }
      },
      subcategories: [
        {
          id: FILTERS_DIMENSIONS.BYMENTIONCOUNT,
          label: 'By mention count',
          subcategories: [
            {
              id: FILTERS_DIMENSIONS.HAPPINESS_BYMENTIONCOUNT,
              label: 'Happiness',
            },
            {
              id: FILTERS_DIMENSIONS.SURPRISE_BYMENTIONCOUNT,
              label: 'Surprise',
            },
            {
              id: FILTERS_DIMENSIONS.CALM_BYMENTIONCOUNT,
              label: 'Calm',
            },
            {
              id: FILTERS_DIMENSIONS.FEAR_BYMENTIONCOUNT,
              label: 'Fear',
            },
            {
              id: FILTERS_DIMENSIONS.SADNESS_BYMENTIONCOUNT,
              label: 'Sadness',
            },
            {
              id: FILTERS_DIMENSIONS.ANGER_BYMENTIONCOUNT,
              label: 'Anger',
            },
            {
              id: FILTERS_DIMENSIONS.DISGUST_BYMENTIONCOUNT,
              label: 'Disgust',
            },
          ],
        },
        {
          id: FILTERS_DIMENSIONS.BYPERCENTAGE,
          label: 'By percentage',
          subcategories: [
            {
              id: FILTERS_DIMENSIONS.HAPPINESS_BYPERCENTAGE,
              label: 'Happiness',
            },
            {
              id: FILTERS_DIMENSIONS.SURPRISE_BYPERCENTAGE,
              label: 'Surprise',
            },
            {
              id: FILTERS_DIMENSIONS.CALM_BYPERCENTAGE,
              label: 'Calm',
            },
            {
              id: FILTERS_DIMENSIONS.FEAR_BYPERCENTAGE,
              label: 'Fear',
            },
            {
              id: FILTERS_DIMENSIONS.SADNESS_BYPERCENTAGE,
              label: 'Sadness',
            },
            {
              id: FILTERS_DIMENSIONS.ANGER_BYPERCENTAGE,
              label: 'Anger',
            },
            {
              id: FILTERS_DIMENSIONS.DISGUST_BYPERCENTAGE,
              label: 'Disgust',
            },
          ],
        },
      ],
    },
  ],
};

export const ORDER_BY_ITEMS = [
  {
    id: 'label',
    label: 'Alphabetical',
    subcategories: [
      {
        id: 'name_asc',
        label: 'Alphabetical - Ascending',
      },
      {
        id: 'name_desc',
        label: 'Alphabetical - Descending',
      },
    ],
  },
  {
    id: 'hierarchy',
    label: 'Hierarchical',
    subcategories: [
      {
        id: 'hierarchy_asc',
        label: 'Hierarchical - Ascending',
      },
      {
        id: 'hierarchy_desc',
        label: 'Hierarchical - Descending',
      },
    ],
  },
  {
    id: FILTERS_DIMENSIONS.EINDEX,
    label: 'E-Index',
    subcategories: [
      {
        id: 'eindex_asc',
        label: 'Eindex - Ascending',
      },
      {
        id: 'eindex_desc',
        label: 'Eindex - Descending',
      },
    ],
  },
  {
    id: FILTERS_DIMENSIONS.MENTIONS,
    label: 'Mentions',
    subcategories: [
      {
        id: 'mentionCount_asc',
        label: 'Mentions - Ascending',
      },
      {
        id: 'mentionCount_desc',
        label: 'Mentions - Descending',
      },
    ],
  },
  {
    id: FILTERS_DIMENSIONS.EMOTIONS,
    label: 'Emotions',
    subcategories: [
      {
        id: FILTERS_DIMENSIONS.HAPPINESS,
        label: 'Happiness',
        subcategories: [
          {
            id: 'happiness_asc',
            label: 'Happiness - Ascending',
          },
          {
            id: 'happiness_desc',
            label: 'Happiness - Descending',
          },
        ],
      },
      {
        id: FILTERS_DIMENSIONS.SURPRISE,
        label: 'Surprise',
        subcategories: [
          {
            id: 'surprise_asc',
            label: 'Surprise - Ascending',
          },
          {
            id: 'surprise_desc',
            label: 'Surprise - Descending',
          },
        ],
      },
      {
        id: FILTERS_DIMENSIONS.CALM,
        label: 'Calm',
        subcategories: [
          {
            id: 'calm_asc',
            label: 'Calm - Ascending',
          },
          {
            id: 'calm_desc',
            label: 'Calm - Descending',
          },
        ],
      },
      {
        id: FILTERS_DIMENSIONS.FEAR,
        label: 'Fear',
        subcategories: [
          {
            id: 'fear_asc',
            label: 'Fear - Ascending',
          },
          {
            id: 'fear_desc',
            label: 'Fear - Descending',
          },
        ],
      },
      {
        id: FILTERS_DIMENSIONS.SADNESS,
        label: 'Sadness',
        subcategories: [
          {
            id: 'sadness_asc',
            label: 'Sadness - Ascending',
          },
          {
            id: 'sadness_desc',
            label: 'Sadness - Descending',
          },
        ],
      },
      {
        id: FILTERS_DIMENSIONS.ANGER,
        label: 'Anger',
        subcategories: [
          {
            id: 'anger_asc',
            label: 'Anger - Ascending',
          },
          {
            id: 'anger_desc',
            label: 'Anger - Descending',
          },
        ],
      },
      {
        id: FILTERS_DIMENSIONS.DISGUST,
        label: 'Disgust',
        subcategories: [
          {
            id: 'disgust_asc',
            label: 'Disgust - Ascending',
          },
          {
            id: 'disgust_desc',
            label: 'Disgust - Descending',
          },
        ],
      },
    ],
  },
  {
    id: FILTERS_DIMENSIONS.MENTIONEVOLUTION,
    label: 'Mention evolution',
    subcategories: [
      {
        id: 'mentionEvolution_asc',
        label: 'Mention evolution - Ascending',
      },
      {
        id: 'mentionEvolution_desc',
        label: 'Mention evolution - Descending',
      },
    ],
  },
  {
    id: FILTERS_DIMENSIONS.EINDEXEVOLUTION,
    label: 'E-index evolution',
    subcategories: [
      {
        id: 'eindexEvolution_asc',
        label: 'Eindex evolution - Ascending',
      },
      {
        id: 'eindexEvolution_desc',
        label: 'Eindex evolution - Descending',
      },
    ],
  },
];
