import React from 'react';
import Layout from '_Layouts/Layout';
import DataByFiltersGeneric from '../../_Containers/DataByFiltersGeneric/DataByFiltersGeneric';
import QLoginRedirect from '../../../_Components/Auth/QLoginRedirect';

const title = 'Data by filters - Comm';
const idpage = 'comm-data-by-filters';
const body = <DataByFiltersGeneric thematic="comm" idpage={idpage} />;

const tourID = 1;

const action = props => {
  return (
    <QLoginRedirect>
      <Layout body={body} tourID={tourID} title={title} {...props} />
    </QLoginRedirect>
  );
}

export default action;
