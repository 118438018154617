import React from 'react';
import styled from 'styled-components';
import { lightBlue } from 'styles/abstracts/colors';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const DateRangeConflictSubtitle = styled.p`
  color: ${lightBlue};
  font-weight: bold;
`;

const MergeSubtitle = ({ context, t, i18n, mergedDateRange }) => {
  function formatDate(date) {
    if (!date) return t('Invalid date');
    return moment(date)
      .locale(i18n.language)
      .format('LL');
  }
  return (
    <DateRangeConflictSubtitle>
      {t('From')} {formatDate(mergedDateRange.startDate)} {t('To')}{' '}
      {formatDate(mergedDateRange.endDate)}
    </DateRangeConflictSubtitle>
  );
};

export default withTranslation('conflicts')(MergeSubtitle);
