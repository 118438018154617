import React from 'react';
import { setPreviewMode } from '_Resources/Reporting/Actions/setPreviewMode';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ExportButton from 'Routes/GlobalExperience/Reporting/_Containers/ReportingFloatingActionButton/_Components/ExportButtons';
import PreviewButton from 'Routes/GlobalExperience/Reporting/_Containers/ReportingFloatingActionButton/_Components/PreviewButton';
import ReportExportProvider, {
  REPORT_TYPES,
} from 'Routes/GlobalExperience/Reporting/_Containers/tabs/Export/_Providers/ReportExportProvider';
import { updateExportActive } from '_Resources/Reporting/Actions/Export/updateExportActive';
import { PagePropTypes } from '_Resources/Reporting/Services/types/Page.propTypes';

const ReportingFloatingActionButton = ({
  changePreviewMode,
  isPreviewMode,
  dispatchUpdateExportActive,
  pages,
  reportSettings,
  globalSettings,
  exportActive,
}) =>
  isPreviewMode && !exportActive ? (
    <ReportExportProvider
      render={exportReport => {
        const onExport = () => {
          dispatchUpdateExportActive(true);
          setTimeout(() => exportReport(), 50);
        };

        return <ExportButton callbackPreviewMode={bool => changePreviewMode(bool)} onExport={onExport} />;
      }}
      pages={pages}
      reportSettings={reportSettings}
      globalSettings={globalSettings}
      type={REPORT_TYPES.PDF}
    />
  ) : (
    <PreviewButton callbackPreviewMode={bool => changePreviewMode(bool)} />
  );

const mapStateToProps = ({
  reporting: {
    isPreviewMode,
    exportActive,
    children,
    globalSettings,
    header,
    footer,
    logo,
    dateRange,
    filters,
    title,
    subtitle,
    iterateByOptions,
  },
}) => ({
  isPreviewMode,
  exportActive,
  pages: children,
  globalSettings,
  reportSettings: {
    iterateByOptions,
    title,
    subtitle,
    logo,
    filters,
    dateRange,
    header,
    footer,
  },
});

const mapDispatchToProps = dispatch => ({
  changePreviewMode: bool => dispatch(setPreviewMode(bool)),
  dispatchUpdateExportActive: exportActive => dispatch(updateExportActive(exportActive)),
});

ReportingFloatingActionButton.propTypes = {
  changePreviewMode: PropTypes.func.isRequired,
  dispatchUpdateExportActive: PropTypes.func.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(PropTypes.PropTypes).isRequired,
  globalSettings: PagePropTypes.isRequired,
  reportSettings: PropTypes.any.isRequired,
  exportActive: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(ReportingFloatingActionButton));
