import { isNil } from 'lodash';
import {
  LOAD_STATUSES_FAILURE,
  LOAD_STATUSES_SUCCESS,
} from '../Actions/loadStatuses';
import {
  LOAD_STAGES_FAILURE,
  LOAD_STAGES_SUCCESS,
} from '../Actions/loadStages';
import { SELECT_ITEM } from '../Actions/selectItem';
import { ADD_STAGE_SUCCESS } from '../Actions/addStage';
import { RENAME_STAGE_SUCCESS } from '../Actions/renameStage';
import { REMOVE_STAGE_SUCCESS } from '../Actions/removeStage';
import { MOVE_ITEM_SUCCESS } from '../Actions/moveItem';

const alertingStagesInitialState = {
  stages: [],
  stagesLoadError: null,
};
const alertingStatusesInitialState = {
  statuses: [],
  statusesLoadError: null,
};

const initialState = {
  ...alertingStagesInitialState,
  ...alertingStatusesInitialState,
};

const moveToUnstaged = (stages, sourceIndex, targetIndex) => {
  const newStages = stages.slice();
  newStages[targetIndex] = {
    ...stages[targetIndex],
    items: [...stages[targetIndex].items, ...stages[sourceIndex].items],
  };
  return newStages;
};

const removeFromArray = (items, index) => [
  ...items.slice(0, index),
  ...items.slice(index + 1),
];

const addToArray = (items, item, index) => {
  if (index == null) {
    return [...items, item];
  }
  return [...items.slice(0, index), item, ...items.slice(index)];
};

// const removeStageIdFromStatuses = (statuses, statusId, stageId) => {
//   const statusIndex = statuses.findIndex(status => status.id === statusId);
//   return [
//     ...statuses.slice(0, statusIndex),
//     {
//       ...statuses[statusIndex],
//       stageIds: removeFromArray(
//         statuses[statusIndex].stageIds,
//         statuses[statusIndex].stageIds.indexOf(stageId),
//       ),
//     },
//     ...statuses.slice(statusIndex + 1),
//   ];
// };
//
// const removeStageIdsFromStatuses = (statuses, statusId, stageId) => {
//   switch (statusId) {
//     case 'all': {
//       return statuses.map(
//         status =>
//           status.id !== 'all' && status.stageIds.indexOf(status.id) > -1
//             ? removeStageIdFromStatuses(statuses, status.id, stageId)
//             : status,
//       );
//     }
//     default: {
//       return removeStageIdFromStatuses(statuses, statusId, stageId);
//     }
//   }
// };

export default (state = initialState, action) => {
  switch (action.type) {
    case MOVE_ITEM_SUCCESS: {
      const sourceStage = state.stages.find(
        stage => stage.id === action.sourceId,
      );
      if (!sourceStage) {
        throw new Error('Source stage not exists');
      }

      const sourceIndex = sourceStage.items.findIndex(
        item => item.id === action.itemId,
      );
      const item = sourceStage.items[sourceIndex];
      if (!item) {
        throw new Error('Item not exists');
      }

      return {
        ...state,
        stages: state.stages.map(stage => {
          if (stage.id === action.sourceId) {
            if (action.sourceId === action.destinationId) {
              // Reorder items
              const newStageItems = removeFromArray(stage.items, sourceIndex);
              return {
                ...stage,
                items: addToArray(newStageItems, item, action.index),
              };
            }
            return {
              ...stage,
              items: removeFromArray(stage.items, sourceIndex),
            };
          } else if (stage.id === action.destinationId) {
            return {
              ...stage,
              items: addToArray(stage.items, item, action.index),
            };
          }
          return stage;
        }),
      };
    }
    case RENAME_STAGE_SUCCESS: {
      const index = state.stages.findIndex(stage => stage.id === action.id);
      return {
        ...state,
        stages: [
          ...state.stages.slice(0, index),
          {
            ...state.stages[index],
            name: action.name,
          },
          ...state.stages.slice(index + 1),
        ],
      };
    }
    case REMOVE_STAGE_SUCCESS: {
      const unstagedIndex = state.stages.findIndex(stage => stage.id === -1);
      const sourceIndex = state.stages.findIndex(
        stage => stage.id === action.id,
      );

      const newStages = moveToUnstaged(
        state.stages,
        sourceIndex,
        unstagedIndex,
      );
      return {
        ...state,
        stages: removeFromArray(newStages, sourceIndex),
      };
    }
    case ADD_STAGE_SUCCESS: {
      const id = Math.max(...state.stages.map(stage => stage.id)) + 1; // TODO: set to response id - waits for backend

      return {
        ...state,
        stages: [
          ...state.stages,
          {
            id,
            name: action.name,
            items: [],
          },
        ],
        // statuses: [
        //   ...state.statuses.slice(0, statusIndex),
        //   {
        //     ...state.statuses[statusIndex],
        //     stageIds: [...state.statuses[statusIndex].stageIds, id],
        //   },
        //   ...state.statuses.slice(statusIndex + 1),
        // ],
      };
    }
    case LOAD_STAGES_SUCCESS: {
      return {
        ...state,
        stages: action.stages,
        stagesLoadError: null,
      };
    }
    case LOAD_STAGES_FAILURE: {
      return {
        ...state,
        stages: [],
        stagesLoadError: action.error,
      };
    }
    case LOAD_STATUSES_SUCCESS: {
      return {
        ...state,
        statuses: [
          ...action.statuses,
          {
            id: 'all',
            label: 'All',
            // stageIds: null,
            items: null,
          },
        ],
        statusesLoadError: null,
      };
    }
    case LOAD_STATUSES_FAILURE: {
      return {
        ...state,
        statuses: [],
        statusesLoadError: action.error,
      };
    }
    case SELECT_ITEM: {
      const { id } = action;
      return {
        ...state,
        itemDetails: isNil(id)
          ? null
          : state.items.find(({ itemId }) => itemId === id),
      };
    }
    default: {
      return state;
    }
  }
};
