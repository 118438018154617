import bestAndWorstCategories from './layouts/bestAndWorstCategories.png';
import eindexAndEmotions from './layouts/eindexAndEmotions.png';
import eindexEvolution from './layouts/eindexEvolution.png';
import bestVerbatim from './layouts/bestVerbatim.png';
import worstVerbatim from './layouts/worstVerbatim.png';
import emotionalIntensity from './layouts/emotionalIntensity.png';
import emotionsEvolution from './layouts/emotionsEvolution.png';
import mentionAndEindex from './layouts/mentionAndEindex.png';
import satisfaction from './layouts/satisfaction.png';
import wordCloud from './layouts/wordCloud.png';

export default {
  bestAndWorstCategories,
  bestVerbatim,
  worstVerbatim,
  eindexAndEmotions,
  eindexEvolution,
  emotionalIntensity,
  emotionsEvolution,
  mentionAndEindex,
  satisfaction,
  wordCloud,
};
