import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { getProjectCriteriaKeysAndThematics } from '_Resources/Header/Actions/getProjectCriteriaKeysAndThematics';
import { DispatchDataToStore } from '_Resources/Header/Services/api/DispatchDataToStore';
import querySchema from './graphql/getHeaderCriteriasAndThematicsFiltersData';

class QueryFiltersHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Query
          query={querySchema}
          variables={{
            projectId:
              (this.props.currentProject && this.props.currentProject.id) ||
              this.props.projectId ||
              0,
          }}
          // fetchPolicy="network-only"
        >
          {({ loading, error, data, networkStatus }) => {
            if (loading || networkStatus === 4) return false;
            if (error) {
              return false;
            }
            return (
              <DispatchDataToStore
                data={data}
                action={() =>
                  this.props.getProjectCriteriaKeysAndThematics(data)
                }
              />
            );
          }}
        </Query>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectCriteriaKeysAndThematics: filters => {
      dispatch(getProjectCriteriaKeysAndThematics(filters));
    },
  };
}

function mapStateToProps(state) {
  return {
    currentProject: state.projects.currentProject,
    projectId: get(state, ['filterKeys', 'projectId']),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QueryFiltersHeader);
