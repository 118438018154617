import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import SubHeader from '_Layouts/Header/_Containers/SubHeader/SubHeader';
import { grayShades, purple, white } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import Icon from '_Components/Icons/Icon';
import QButton from '_Components/Buttons/QButton/QButton';
import QEditable from '_Components/Fields/QEditable/QEditable';
import { ImageSize } from 'styles/abstracts/variables';
import mutation from '_Cards/SettingsCards/ProjectsCard/graphql/renameProject';
import getProjectListData from 'Routes/Main/MainPage/_Components/ProjectListCard/getProjectListCardData';
import query from '_Resources/Header/Services/api/graphql/getHeaderProjectFiltersData';
import ArchiveProjectModal from '_Cards/SettingsCards/ProjectsCard/_Containers/ProjectsContainer/_Containers/ProjectRow/_Components/Modals/ArchiveProjectModal';

const Container = styled.div`
  width: 100%;
  background: ${white};
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CancelButton = styled.div`
  color: ${grayShades.g800};
  cursor: pointer;
  left: 1em;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
`;

const TitleContainer = styled.div`
  color: ${purple};
  display: flex;
  align-items: center;
`;

const RenameContainer = styled.div`
  font-weight: bold;
  color: ${grayShades.g800};
  margin-left: 0.5em;
  margin-top: 0.5em;
  font-size: ${fontSizes.smaller};
  cursor: pointer;
`;

const ProjectDetailsSubHeader = ({
  history,
  projectName,
  projectId,
  t,
  isProjectDemo,
}) => {
  const [title, setTitle] = useState('');
  const [renameProject] = useMutation(mutation, {
    update: (cache, { data: renameProject }) => {
      const projectListCardData = cache.readQuery({
        query: getProjectListData,
      }).results.projects;
      const headerProjectFiltersData = cache.readQuery({
        query,
      }).data.projects;
      const projectToReplace = projectListCardData.find(
        project => project.name === projectName && project.id === projectId,
      );
      const projectToReplaceIndex = projectListCardData.findIndex(
        project => project.name === projectName && project.id === projectId,
      );
      const headerProjectToReplace = headerProjectFiltersData.find(
        project => project.name === projectName && project.id === projectId,
      );
      const headerProjectToReplaceIndex = headerProjectFiltersData.findIndex(
        project => project.name === projectName && project.id === projectId,
      );

      projectToReplace.name = renameProject.renameProject.name;
      headerProjectToReplace.name = renameProject.renameProject.name;

      projectListCardData[projectToReplaceIndex] = projectToReplace;
      headerProjectFiltersData[
        headerProjectToReplaceIndex
      ] = headerProjectToReplace;

      cache.writeQuery({
        query: getProjectListData,
        data: {
          results: {
            projects: projectListCardData,
          },
        },
      });

      cache.writeQuery({
        query,
        data: {
          data: {
            projects: headerProjectFiltersData,
          },
        },
      });
    },
  });
  useEffect(() => {
    setTitle(projectName);
  }, [projectName]);
  const [editInput, setEditInput] = useState(false);
  const [isModalOpen, openModal] = useState(false);
  const SHContent = (
    <Container>
      <TitleContainer>
        <CancelButton onClick={() => history.push('/settings')}>
          <IconCircle
            bgColor={grayShades.g300}
            color={purple}
            icon="INTERFACE_CHEVRON_LEFT"
            size={16}
          />
        </CancelButton>
        {title && (
          <QEditable
            value={title}
            setValue={setTitle}
            validation={title.trim().length > 1}
            edit={editInput}
            setEdit={setEditInput}
            maxLength={25}
            disabled={isProjectDemo}
            onBlur={() =>
              title !== projectName &&
              renameProject({ variables: { id: projectId, name: title } })
            }
            keyDown={() =>
              renameProject({ variables: { id: projectId, name: title } })
            }
            autocomplete={false}
          />
        )}
        {!editInput && !isProjectDemo && (
          <RenameContainer onClick={() => setEditInput(true)}>
            <Icon
              icon="INTERFACE_EDIT"
              color={grayShades.g800}
              size={ImageSize.small}
            />{' '}
            {t('Rename')}
          </RenameContainer>
        )}
      </TitleContainer>
      {!isProjectDemo && (
        <QButton hasOpacity bgColor="red" onClick={() => openModal(true)}>
          {t('Archive this project')}
        </QButton>
      )}
      <ArchiveProjectModal
        isOpen={isModalOpen}
        open={openModal}
        redirect
        history={history}
        projectId={projectId}
      />
    </Container>
  );

  return <SubHeader SHContent={SHContent} allowCollapse={false} />;
};

export default withTranslation('button')(ProjectDetailsSubHeader);
