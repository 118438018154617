import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Collapse, Row, Col, Tooltip } from 'reactstrap';
import { uniqBy, flatten, reverse } from 'lodash';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import { grayShades, lightBlue, opacity } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import Icon from '_Components/Icons/Icon';
import SourceConfigurationRow from './_Components/SourceConfigurationRow/SourceConfigurationRow';

const Container = styled(QPanel)`
  padding: 0 !important;
  margin-bottom: 1em;
`;

const TitleContainer = styled(QTitle)`
  padding: 1em;
  display: inline-flex;
  align-items: center;
`;

const HeaderContainer = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  padding: 1em;
  border-top: 1px solid ${grayShades.g300};
`;

const DescriptionRow = styled(Row)`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  font-weight: bolder;
  margin-bottom: 1em;
`;

const LogoContainer = styled.div`
  width: 1.5em;
  height: 1.5em;
  margin-right: 1em;
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const CollapseChevron = styled(Icon)`
  margin-right: 2em;
  transform: ${({ isCollapsed }) => !isCollapsed && `rotate(-90deg)`};
  transition: all 0.5s ease-in-out;
`;

const TooltipContainer = styled.span`
  color: ${lightBlue};
  font-size: ${fontSizes.smaller};
  font-weight: normal;
`;

const StyledTooltip = styled(Tooltip)`
  & > .tooltip-inner {
    max-width: ${({ target }) =>
      target === 'tripadvisor-link-examples' ? '70em' : '30em'} !important;
    background-color: ${opacity.lightBlue} !important;
    color: ${lightBlue} !important;
    font-weight: bolder;
    font-size: ${fontSizes.smaller};
  }

  & a {
    color: ${lightBlue};
    text-decoration: underline;
  }
`;

const SourceConfigurationPanel = ({
  source,
  sourcesToSend,
  setSourcesToSend,
  t,
}) => {
  const [isCollapsed, toggleCollapse] = useState(true);
  const [configuredSources, setConfiguredSources] = useState(
    sourcesToSend?.length === 0 ? [{}] : sourcesToSend,
  );
  const [isTooltipOpen, openTooltip] = useState(false);

  useEffect(() => {
    setSourcesToSend(
      uniqBy(
        reverse([...sourcesToSend.filter(i => i.idSource !== source.id), ...configuredSources]),
        i => (i.id || i.id === 0) && i.profileName && i.profile,
      ),
    );
  }, [configuredSources]);

  const getPanelContent = () => {
    let tooltipId;
    let inputLabel;
    let tooltipLabel;
    let tooltipContent;
    let tooltipContentFirst;
    let tooltipContentSecond;
    switch (source.name) {
      case 'Google Reviews':
        tooltipId = 'find-place-id';
        inputLabel = 'Google Place ID';
        tooltipLabel = 'How to find it?';
        tooltipContent =
          'Just click on the input below, then search your business on the map. Once you\'re done, click "Confirm".';
        break;
      case 'Tripadvisor':
        tooltipId = 'tripadvisor-link-examples';
        inputLabel = 'Link to the pages to scan';
        tooltipLabel = 'Help';
        tooltipContentFirst = 'Warning';
        tooltipContentSecond =
          'the TripAdvisor links containing "ShowTopic", "Tourism" or "Profile" are not accepted.';
        break;
    }

    return {
      tooltipId,
      inputLabel,
      tooltipLabel,
      tooltipContent,
      tooltipContentFirst,
      tooltipContentSecond,
    };
  };

  const {
    tooltipId,
    inputLabel,
    tooltipLabel,
    tooltipContent,
    tooltipContentFirst,
    tooltipContentSecond,
  } = getPanelContent();

  return (
    <Container>
      <HeaderContainer onClick={() => toggleCollapse(!isCollapsed)}>
        <TitleContainer>
          <LogoContainer>
            <Logo src={source.imageLink} alt={`Logo ${source.name}`} />
          </LogoContainer>
          <QTitle type="panel">{source.name}</QTitle>
        </TitleContainer>
        <CollapseChevron
          icon="INTERFACE_CHEVRON_DOWN"
          size={32}
          isCollapsed={isCollapsed}
        />
      </HeaderContainer>
      <Collapse isOpen={isCollapsed}>
        <Content>
          <DescriptionRow>
            <Col xs={3}>{t('Name of the source')}</Col>
            <Col xs={9}>
              {t(inputLabel)}{' '}
              <TooltipContainer id={tooltipId}>
                {t(tooltipLabel)}
                <StyledTooltip
                  isOpen={isTooltipOpen}
                  toggle={à => openTooltip(!isTooltipOpen)}
                  target={tooltipId}
                  placement="top"
                  autohide={false}
                >
                  {tooltipContentFirst ? (
                    <>
                      {t(tooltipContentFirst)} : {t(tooltipContentSecond)}
                    </>
                  ) : (
                    t(tooltipContent)
                  )}
                </StyledTooltip>
              </TooltipContainer>
            </Col>
          </DescriptionRow>
          {(Object.keys(configuredSources[0]).length > 0
            ? uniqBy(
                configuredSources.filter(s => s.idSource === source.id),
                i => (i.id || i.id === 0) && i.profile && i.profileName,
              )
            : configuredSources
          )?.map((confSrc, index) => (
            <SourceConfigurationRow
              configuredSources={configuredSources}
              setConfiguredSources={setConfiguredSources}
              source={source}
              index={index}
              setSourcesToSend={setSourcesToSend}
              sourcesToSend={sourcesToSend}
              t={t}
              configuredSource={confSrc}
            />
          ))}
          <QButton
            size="small"
            iconPosition="left"
            icon="INTERFACE_PLUS_CIRCLE"
            hasOpacity
            onClick={() =>
              setConfiguredSources([
                ...configuredSources,
                {
                  id: parseInt(`${source.id}${configuredSources.length}`, 10),
                  idSource: source.id,
                },
              ])
            }
          >
            &nbsp; {t('Add a source')}
          </QButton>
        </Content>
      </Collapse>
    </Container>
  );
};

export default SourceConfigurationPanel;
