import gql from 'graphql-tag';

const mutation = gql`
  mutation userEmailValidation($token: String!) {
    userEmailValidation(token: $token) {
      status
      errors
    }
  }
`;

export default mutation;
