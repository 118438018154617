import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { get, cloneDeep, isEmpty } from 'lodash';
import { convertCelsiusToFahrenheit } from '_Utils/eindex/eindexUnits';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import querySchema from './graphql/getEindexTemperatureCardData';
import CQuery from '../../../_Container/QQuery/CQuery';
import BadgeBenchmarkTooltip from '../../../_Components/Tooltips/BadgeBenchmarkTooltip/BadgeBenchmarkTooltip';
import EindexBenchmark from './_Components/EindexBenchmark/EindexBenchmark';
import Loader from '../../../_Components/Loader/OnLoad';
import ChartThermometer from '../../../_Charts/KpisCharts/ChartThermometer/ChartThermometer';

const EindexTemperatureCard = props => {
  return (
    <QcardPanel className={props.className} {...props} hints={[HintsContent.HINT_EINDEX_TEMPERATURE]}>
      <EindexTemperaturecardSection filters={props.filters} {...props} />
    </QcardPanel>
  );
};

export const EindexTemperaturecardSection = props => {
  return (
    <CQuery query={querySchema} filters={props.filters || null}>
      <EindexTemperatureCardView {...props} />
    </CQuery>
  );
};

const StyledCol = styled(Col)`
  min-width: 100px;
`;

const EindexTemperatureCardView = ({ className, children, ...props }) => {
  const isFahrenheit = get(props, ['eindexUnitFahrenheit']);
  const { data, title } = props;
  const eindexValues = data && cloneDeep(data.eindex);
  if (isFahrenheit) {
    if (eindexValues.periodBefore) {
      eindexValues.periodBefore.value = convertCelsiusToFahrenheit(eindexValues.periodBefore.value);
      eindexValues.periodBefore.delta =
        convertCelsiusToFahrenheit(eindexValues.value) - eindexValues.periodBefore.value;
    }
    if (eindexValues.yearBefore) {
      eindexValues.yearBefore.value = convertCelsiusToFahrenheit(eindexValues.yearBefore.value);
      eindexValues.yearBefore.delta = convertCelsiusToFahrenheit(eindexValues.value) - eindexValues.yearBefore.value;
    }
  }
  return (
    <>
      <Row style={{ minHeight: '200px', height: 'calc(100% - 64px)' }}>
        <EindexTemperatureCardContain {...props} />
      </Row>
      <Row>
        <Col className="text-center mb-3">
          <BadgeBenchmarkTooltip
            title={title}
            idTooltip="EindexDeltaTooltip"
            data={eindexValues && eindexValues}
            eindexUnitFahrenheit={isFahrenheit}
          />
        </Col>
      </Row>
    </>
  );
};

export const EindexTemperatureCardContain = props => {
  const { analysisAxis, data } = props;
  return (
    <>
      <StyledCol className="text-center">
        <ChartThermometer {...data} analysisAxis={analysisAxis} />
      </StyledCol>
      {data ? (
        !isEmpty(data.benchmarkParent) && (
          <Col className="text-center d-flex align-items-center">
            <EindexBenchmark
              displayBackgroundCard={false}
              displayCardTitle={false}
              displayCardSubTitle={false}
              data={data.benchmarkParent}
            />
          </Col>
        )
      ) : (
        <Col className="text-center d-flex align-items-center">
          <Loader />
        </Col>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    eindexUnitFahrenheit: get(state, ['projectConfiguration', 'unitsAndLocalesSettings', 'eindexUnitFahrenheit']),
    analysisAxis: get(state, ['auth', 'user', 'analysisAxis']),
  };
}

export default connect(mapStateToProps)(EindexTemperatureCard);
