import React from 'react';
import PermissionsRow from '../PermissionsRow/PermissionsRow';

const DetailedPermission = ({ permissions }) => {
  return (
    <>
      {permissions.map(permission => (
        <PermissionsRow permissions={permission} />
      ))}
    </>
  );
};

export default DetailedPermission;
