import React, { Fragment } from 'react';
import styled from 'styled-components';
import QTitle from '../../../../../../../../../../../../_Components/Texts/QTitle/QTitle';
import { fontSizes } from '../../../../../../../../../../../../styles/abstracts/typography';
import { grayShades } from '../../../../../../../../../../../../styles/abstracts/colors';
import { spacing } from '../../../../../../../../../../../../styles/abstracts/variables';

const ReportTitle = styled(QTitle)`
  font-size: ${fontSizes.default};
`;

const ReportLink = styled.a`
  font-weight: bold;
  font-size: 0.7rem;
  color: ${grayShades.g800};

  &::selection {
    background: ${props => (props.isLinkCopied ? 'transparent' : '')};
  }
`;

const ReportInfo = styled.p`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  font-weight: bold;
`;

const Cell = styled.td`
  vertical-align: middle !important;
  padding: ${spacing.mediumSmall} ${spacing.mediumLarge} !important;
`;

const ReportInfos = props => {
  const { data } = props;
  return (
    <Fragment>
      <Cell>
        <ReportTitle>{data.name}</ReportTitle>
        <ReportLink
          href={data.link}
          id={`link${data.id}`}
          isLinkCopied={props.isLinkCopied}
        >
          {data.link}
        </ReportLink>
      </Cell>
      <Cell>
        <ReportInfo>{data.generationDate}</ReportInfo>
      </Cell>
      <Cell>
        <ReportInfo>{data.numberPages}</ReportInfo>
      </Cell>
    </Fragment>
  );
};

export default ReportInfos;
