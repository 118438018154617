import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { getHorizontalGraphOption } from './utils';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

type ThematicsHorizontalGraphProps = {
  data: any[];
  indicators: any;
  horizontalIndicatorsConfig: any;
  eindexUnitFahrenheit: boolean;
  filterKeys: any;
  setCardFilters: any;
  t: any;
};

const ThematicsHorizontalGraph = ({
  data,
  indicators,
  filterKeys,
  setCardFilters,
  t,
}: ThematicsHorizontalGraphProps) => {
  const chartRef = useRef(null);
  const onDataclick = graphData => {
    const cardFilters = {
      ...filterKeys,
      thematics: [...filterKeys.thematics, graphData.data.thematic],
    };
    setCardFilters(cardFilters);
  };

  const handleDataZoom = () => {
    const chartInstance = chartRef?.current?.getEchartsInstance();

    if (chartInstance) {
      const yAxisData = chartInstance.getOption().yAxis[0].data || chartInstance.getOption().dataset[0].source || [];

      const zoomedInCategories = yAxisData.slice(
        Math.floor((yAxisData.length * chartInstance.getOption().dataZoom[1].start) / 100),
        Math.ceil((yAxisData.length * chartInstance.getOption().dataZoom[1].end) / 100),
      );

      const showLabels = zoomedInCategories.length <= 17;

      chartInstance.setOption({
        series: chartInstance.getOption().series.map(series => ({
          ...series,
          label: {
            ...series.label,
            show: showLabels,
          },
        })),
      });
    }
  };

  const onEvents = () => {
    return {
      click: onDataclick,
      datazoom: handleDataZoom,
    };
  };

  const options = getHorizontalGraphOption(data, indicators, t);

  return (
    <div style={{ height: '100%', width: '100%', margin: 0 }}>
      {ReactEcharts ? (
        <ReactEcharts
          ref={chartRef}
          option={options}
          style={{ width: '100%', height: ' calc(100% - 4em)' }}
          className="react_for_echarts"
          theme="QemotionTheme"
          onEvents={onEvents()}
        />
      ) : (
        ' '
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  eindexUnitFahrenheit: get(state, ['projectConfiguration', 'unitsAndLocalesSettings', 'eindexUnitFahrenheit']),
  filterKeys: get(state, ['filters', 'filterKeys']),
});

export default connect(mapStateToProps)(withTranslation('card', ['button'])(ThematicsHorizontalGraph));
