import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { getRoute } from '_Utils/routes/routeUtils';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import VarianceAnalysisCard from '_Cards/AnalysisCards/VarianceAnalysisCard/VarianceAnalysisCard';

type VarianceAnalysisProps = {
  idpage: string;
  dispatchUpdateRoute: any;
};

const VarianceAnalysis = ({ idpage, dispatchUpdateRoute }: VarianceAnalysisProps) => {
  useEffect(() => {
    getRoute(dispatchUpdateRoute, idpage);
  }, []);
  return (
    <Container fluid className="py-3 px-4">
      <VarianceAnalysisCard />
    </Container>
  );
};

function mapDispatchToProps(dispatch: any) {
  return {
    dispatchUpdateRoute: (newState: any) => {
      dispatch(updateRoute(newState));
    },
  };
}

export default connect(null, mapDispatchToProps)(VarianceAnalysis);
