import styled from 'styled-components';
import { BasePropTypes } from '../../../_Utils/common.propTypes';

const QPanel = styled.div`
  background-color: white;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 12px;
`;

QPanel.propTypes = {
  ...BasePropTypes,
};

export default QPanel;
