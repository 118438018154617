import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import {
  getHeaderTopHeight,
  getMainFiltersContainerHeight,
  getCollapsibleFiltersContainerHeight,
  getOptionalHeaderHeight,
  getFreeTrialStripHeight,
} from '_Layouts/Header/_Utils/HeaderUtils';
import {
  grayShades,
  white,
  green,
  orange,
  red,
  black,
} from '../../styles/abstracts/colors';

const AlertStripContainer = styled.div`
  background-color: ${red};
  color: ${white};
  width: calc(100% - 5em);
  text-align: center;
  font-weight: bold;
  padding: 1.5em;
  padding-top: 2em;
  margin-left: auto;
`;

const setAlertStripPosition = () => {
  if (document.getElementById('alertStripContainer') !== null) {
    document.getElementById(
      'alertStripContainer',
    ).style.top = `${getHeaderTopHeight() +
      getMainFiltersContainerHeight() +
      getCollapsibleFiltersContainerHeight() +
      getOptionalHeaderHeight() +
      getFreeTrialStripHeight()}px`;
    document.getElementById('alertStripContainer').style.position = 'relative';
  }
};

export const getExpireDateString = (language, expireAt) => {
  let expireAtString;
  switch (language) {
    case 'en':
      expireAtString = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(expireAt);
      break;
    case 'de':
      expireAtString = new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(expireAt);
      break;
    case 'es':
      expireAtString = new Intl.DateTimeFormat('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(expireAt);
      break;
    default:
      expireAtString = new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(expireAt);
      break;
  }
  return expireAtString;
};

const AlertStrip = (props: any) => {
  const [now, setNow] = useState(new Date());
  const [expired, setExpired] = useState(false);
  const expirationDate = new Date(props?.user?.expireAt || new Date());
  const { t } = props;
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setNow(new Date());
      setExpired(now > expirationDate);
      setAlertStripPosition();
    }, 1000);

    return () => clearTimeout(timer);
  }, [now, props]);

  return (
    <>
      {expired && location.pathname === '/main' && (
        <AlertStripContainer id="alertStripContainer">
          <div
            dangerouslySetInnerHTML={{
              __html: t('license expired', {
                expireAt: `${getExpireDateString(
                  props.i18n.language,
                  expirationDate,
                )}`,
                sales: `<a href="mailto:sales@qemotion.com"><span style="text-decoration: underline; color: ${white};">sales@qemotion.com</span></a>`,
              }),
            }}
          />
        </AlertStripContainer>
      )}
    </>
  );
};

AlertStrip.propTypes = {
  now: PropTypes.instanceOf(Date),
  expirationDate: PropTypes.instanceOf(Date).isRequired,
  t: PropTypes.func.isRequired,
};

AlertStrip.defaultProps = {
  now: new Date(),
};

const mapStateToProps = state => ({
  currentUser: get(state, ['auth', 'user']),
});

export default connect(mapStateToProps)(withTranslation('text')(AlertStrip));
