import gql from 'graphql-tag';

const query = gql`
  query emailValidationSendEmail {
    emailValidationSendEmail {
      code
      message
    }
  }
`;

export default query;
