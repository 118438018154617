import React from 'react';
import { get, maxBy } from 'lodash';
import CRadarChart from '_Charts/RadarCharts/CRadarChart/CRadarChart';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import CBarGraphChart from '_Charts/PoleCharts/CBarGraphChart/CBarGraphChart';
import styled from 'styled-components';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import '_Charts/KpisCharts/KpiBadges/style.scss';
import { convertCelsiusToFahrenheit } from '_Utils/eindex/eindexUnits';

const ColRadarChart = styled(Col)`
  height: 600px;
  width: 50%;
  @media (max-width: 1282px) {
    width: 100%;
  }
`;

const RowCardRadar = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1282px) {
    flex-direction: column;
  }
`;

class ContainRowCardRadar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedThematic: null,
    };
  }

  handleLabelClick(value) {
    this.setState({ selectedThematic: value });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setDataShape(this.props.data.data);
    }
  }

  componentWillMount() {
    if (this.props.data) this.setDataShape(this.props.data.data);
  }

  setDataShape(data) {
    this.handleLabelClick(null);
    const eindexValues = { thematicName: [], thematicValue: [] };
    const mentionValues = { thematicName: [], thematicValue: [] };
    data.forEach(thematic => {
      eindexValues.thematicName.push({
        name: thematic.label,
        min: get(this.props, ['eindexUnitFahrenheit']) ? 0 : -20,
        max: get(this.props, ['eindexUnitFahrenheit']) ? 100 : 40,
      });
      mentionValues.thematicName.push({
        name: thematic.label,
        min: 0,
        max: maxBy(data, o => o.mentionCount).mentionCount,
      });
      eindexValues.thematicValue.push(
        get(this.props, ['eindexUnitFahrenheit'])
          ? convertCelsiusToFahrenheit(thematic.eindex)
          : thematic.eindex,
      );
      mentionValues.thematicValue.push(thematic.mentionCount);
    });
    const dataRadar = { byEIndex: eindexValues, byMentionCount: mentionValues };
    let dataBars = null;
    if (get(this.props, ['eindexUnitFahrenheit'])) {
      data.map(parentThematic => {
        parentThematic.eindex = convertCelsiusToFahrenheit(
          parentThematic.eindex,
        );
        if (parentThematic.values) {
          parentThematic.values.map(
            childrenThematic =>
              (childrenThematic.eindex = convertCelsiusToFahrenheit(
                childrenThematic.eindex,
              )),
          );
        }
        // parentThematic.values
        return parentThematic;
      });
      dataBars = data;
    } else {
      dataBars = data;
    }
    this.setState({ dataRadar, dataBars, selectedThematic: null });
  }

  render() {
    return (
      <RowCardRadar className="containContent">
        <ColRadarChart>
          <CRadarChart
            data={this.state.dataRadar}
            callbackParent={newState => this.handleLabelClick(newState)}
          />
        </ColRadarChart>
        <ColRadarChart>
          {this.state.selectedThematic ? (
            <h6 className="text-center font-weight-bold">
              {this.state.selectedThematic}
            </h6>
          ) : (
            <NoDataMessage secondText="Please, select a thematic in the graph to see corresponding statistics !" />
          )}
          <CBarGraphChart
            data={this.state.dataBars}
            datatoShow={this.state.selectedThematic}
            orientation="horizontal"
          />
        </ColRadarChart>
      </RowCardRadar>
    );
  }
}

function mapStateToProps(state) {
  return {
    eindexUnitFahrenheit:
      state.projectConfiguration.unitsAndLocalesSettings.eindexUnitFahrenheit,
  };
}

export default connect(mapStateToProps)(ContainRowCardRadar);
