import gql from 'graphql-tag';

const removeVerbatimFromCluster = gql`
  mutation removeVerbatim(
    $projectId: Int!
    $clusterId: String!
    $verbatimId: Int!
  ) {
    removeVerbatim(
      projectId: $projectId
      clusterId: $clusterId
      verbatimId: $verbatimId
    ) {
      errors
    }
  }
`;

export default removeVerbatimFromCluster;
