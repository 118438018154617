import React from 'react';
import styled from 'styled-components';
import { grayShades } from 'styles/abstracts/colors';
import SourceCard from './_Containers/SourceCard/SourceCard';

const Body = styled.div`
  padding: 1em;
  background-color: ${grayShades.g100}
  margin-left: -1em;
  margin-right: -1em;
  border: 1px solid ${grayShades.g300};
 `;

const SourcesContainer = ({
  sources,
  setSources,
  dataBySources,
  projectId,
  slug,
  t,
  projectDetails,
}) => (
  <Body>
    {Object.keys(dataBySources).map(source => {
      return (
        <SourceCard
          data={dataBySources[source]}
          sources={sources}
          setSources={setSources}
          projectId={projectId}
          slug={slug}
          t={t}
          projectDetails={projectDetails}
        />
      );
    })}
  </Body>
);

export default SourcesContainer;
