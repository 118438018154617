import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QPanel from '_Components/Panels/QPanel/QPanel';
import { grayShades } from 'styles/abstracts/colors';
import PermissionsRow from './_Containers/PermissionsRow/PermissionsRow';
import DetailedPermission from './_Containers/DetailedPermissions/DetailedPermissions';

const LabelContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${grayShades.g800};
  font-weight: bold;
`;

const PermissionsCard = ({ permissions, t }) => {
  const [showDetails, toggleShowDetails] = useState(false);
  return (
    <QPanel>
      <Row>
        <Col xs={3}>
          <QTitle type="panel">{t(permissions.name)}</QTitle>
        </Col>
        <LabelContainer xs={9}>
          {Object.keys(permissions.globalPermissions).length === 3 && (
            <Col xs={2} />
          )}
          {Object.keys(permissions.globalPermissions).length > 0 &&
            Object.keys(permissions.globalPermissions).map(permission => (
              <Col xs={2} className="d-flex justify-content-center">
                {t(permission)}
              </Col>
            ))}
        </LabelContainer>
      </Row>
      <PermissionsRow
        permissions={permissions.globalPermissions}
        detailedPermissions={permissions.detailedPermissions}
        toggleShowDetails={toggleShowDetails}
        showDetails={showDetails}
      />
      {showDetails && permissions.detailedPermissions && (
        <DetailedPermission permissions={permissions.detailedPermissions} />
      )}
    </QPanel>
  );
};

export default PermissionsCard;
