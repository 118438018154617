import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import '_Components/QVerbatimBubble/style.scss';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import Icon from '_Components/Icons/Icon';
import VerbatimColor from '_Components/QVerbatimBubble/VerbatimColor';
import { calm, white, black, grayShades, green, lightenOrDarkenColor, purple } from 'styles/abstracts/colors';
import { spacing } from 'styles/abstracts/variables';
import { fontSizes } from 'styles/abstracts/typography';
import PropTypes from 'prop-types';
import { sendVerbatimEmotionsOnHover } from '_Resources/Cards/Actions/sendVerbatimEmotionsOnHover';
import { getColorHexaByEmotion } from '_Utils/emotions/emotionsColorFunctions';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import styleVars from 'styles/abstracts/_variables.module.scss';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { forEach, filter, pickBy, isEmpty, startCase, capitalize as cap } from 'lodash';
import { capitalize } from '_Utils/strings/stringsUtils';
import { Mutation } from 'react-apollo';
import removeVerbatimFromCluster from '_Resources/Cluster/Services/api/graphql/removeVerbatimFromCluster';
import getVerbatim from '_Components/QVerbatim/graphql/getVerbatim';
import getClusterVerbatims from '_Resources/Cluster/Services/api/graphql/getClusterVerbatims';
import { selectClusterDetails } from '_Resources/Cluster/Actions/selectClusterDetails';
import { selectVerbatim } from '_Resources/Verbatim/Actions/selectVerbatim';
import { withRouter } from 'react-router-dom';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import { emotions } from '../../constants';
import {
  getTranslatedFullVerbatim,
  getAvailableTranslations,
  checkIfAvailableTranslation,
  languagesLabels,
} from './utils';

export const CustomMark = styled.mark`
  padding: 0.25rem;
  background-color: ${calm};
  font-weight: bold;
  border-radius: 0.25rem;
`;

const TranslationOptions = styled.p`
  font-size: 12px;
  color: ${grayShades.g800};
  text-align: left;
  line-height: 12px;
  margin-bottom: 0;
  font-weight: bold;
  margin-top: 0.5rem;
`;

const TranslationSpan = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${grayShades.g800};
  line-height: 12px;
  cursor: pointer;
`;

const TranslationRow = styled(Row)`
  justify-content: right;
`;

const Bubble = styled(Col)`
  background-color: ${white};
  overflow: visible;
  padding: ${spacing.large};
  border-radius: 2rem;
  font-size: ${fontSizes.default};
  color: ${black};
  text-align: left;
  line-height: 46px;
  margin: 0 0 ${spacing.medium};
  margin-left: auto;
  margin-right: auto;
`;

export const HighlightedKeyword = ({ keyword }) => <CustomMark>{keyword}</CustomMark>;

const ClusterTag = styled.div`
  display: flex;
  background-color: ${grayShades.g300};
  border-radius: 12px;
  font-size: ${fontSizes.extraSmall};
  color: ${white};
  text-align: left;
  line-height: 18px;
  font-weight: bold;
  padding: 3px 4px 3px 4px;
  margin-right: 4px;
  margin-top: 8px;
  position: relative;
  max-width: calc(50% - 4px);
  height: 1.5rem;
  max-height: 1.5rem;
  transition: height 0.3s ease-out, max-height 0.3s ease-out;

  &:hover {
    height: auto;
    max-height: none;
  }
`;

const ClusterTagTitle = styled.span`
  max-height: 1rem;
  display: inline;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${grayShades.g800};
  ${ClusterTag}:hover & {
    max-height: none;
  }
`;

const ClusterTagTone = styled.span`
  margin-right: 8px;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.tone === 'pos' ? green : '#fff')};
`;

const ClusterTagSize = styled.span`
  background-color: ${white};
  margin-left: 8px;
  color: ${grayShades.g800};
  border-radius: 1.5em;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClusterTagTooltip = styled.div`
  position: absolute;
  z-index: 30;
  background-color: #000;
  visibility: hidden;
  opacity: 0;
  text-align: left;
  padding: 2px 4px;
  border-radius: 8px;
  color: #fff;
  width: max-content;
  max-width: 100%;
  font-size: 10px;
  left: 30%;
  bottom: 28px;
  box-shadow: 2px 2px 10px #000;
  transition: visibility 0.3s ease-out, opacity 0.3s ease-out;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 12px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  ${ClusterTag}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const ClusterTagRemoveButton = styled.button`
  border: 0;
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
  color: ${props => (props.tone === 'pos' ? '#000' : white)};
  font-weight: bolder;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-text: center;
  padding: 0;
  transition: 0.2s;
  background-color: ${grayShades.g300};

  &:focus {
    outline: 0;
  }

  & > svg > path {
    transition: 0.2s;
  }

  &:hover {
    background-color: ${lightenOrDarkenColor(grayShades.g300, -0.25)};

    & > svg > path {
      fill: ${grayShades.g300} !important;
    }
  }
`;

const SwitchContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
`;

class VerbatimBubble extends React.Component {
  constructor(props) {
    super(props);
    const { id, uuid, isOpen, informations, userInfos, verbatim } = this.props;
    const userPreferedLocale = getTranslatedFullVerbatim(verbatim, userInfos.verbatimsLocale).displayedLanguage;
    const collapsed = this.getCollapsed();
    const fullVerbatim =
      (this.props.keywords &&
        verbatim &&
        this.highlightKeywords(getTranslatedFullVerbatim(verbatim, userPreferedLocale).fullVerbatim)
          .verbatimFullText) ||
      getTranslatedFullVerbatim(verbatim, userPreferedLocale).fullVerbatim;
    this.state = {
      collapsed,
      bubbleClass: collapsed === false ? 'bubbleExpanded' : 'bubbleCollapsed',
      buttonCollapseShowing: 'buttonShow',
      verbatimToShow: !collapsed ? (
        <VerbatimColor
          verbatims={this.props.verbatim.verbatimSentences}
          emotions={emotions}
          keywords={this.props.keywords}
          belongingClusters={this.props.belongingClusters}
          belongingVerbatim={{ id, uuid }}
          collapsed={collapsed}
          informations={informations || null}
          userPreferedLocale={userPreferedLocale}
        />
      ) : (
        this.splitVerbatim(fullVerbatim)
      ),
      collapseIcon: collapsed === false ? 'INTERFACE_CHEVRON_UP' : 'INTERFACE_CHEVRON_DOWN',
      displayedLanguage: userPreferedLocale,
      showEmptyCriteria: false,
    };
    this.toggleVerbatim = this.toggleVerbatim.bind(this);
    this.clusterTagRefs = [];
    this.setVerbatimInStore = this.setVerbatimInStore.bind(this);
    this.getCollapsed = this.getCollapsed.bind(this);
  }

  getCollapsed = () => {
    const { id, isOpen, verbatimEmotionsColors, hideCollapse, selectedVerbatim } = this.props;
    if (isOpen !== undefined) {
      return !isOpen;
    }
    if (verbatimEmotionsColors) {
      return false;
    }
    if (hideCollapse === true) {
      return false;
    }
    return false;
  };

  toggleVerbatim = collapsed => {
    const { id, uuid, selectedVerbatim, hideCollapse, informations, userInfos, verbatim } = this.props;
    // const { verbatimsLocale: userPreferedLocale } = userInfos;
    const userPreferedLocale = getTranslatedFullVerbatim(verbatim, userInfos.verbatimsLocale).displayedLanguage;
    const newCollapsed = !this.getCollapsed();
    const fullVerbatim =
      (this.props.keywords &&
        verbatim &&
        this.highlightKeywords(getTranslatedFullVerbatim(verbatim, userPreferedLocale).fullVerbatim)
          .verbatimFullText) ||
      getTranslatedFullVerbatim(verbatim, userPreferedLocale).fullVerbatim;
    this.setState({
      bubbleClass: newCollapsed === false || hideCollapse === true ? 'bubbleExpanded' : 'bubbleCollapsed',
      verbatimToShow: newCollapsed ? (
        this.splitVerbatim(fullVerbatim)
      ) : (
        <VerbatimColor
          verbatims={this.props.verbatim.verbatimSentences}
          emotions={emotions}
          keywords={this.props.keywords}
          belongingClusters={this.props.belongingClusters}
          belongingVerbatim={{ id, uuid }}
          collapsed={newCollapsed}
          informations={informations || null}
          userPreferedLocale={userPreferedLocale}
        />
      ),
      collapseIcon: newCollapsed ? 'INTERFACE_CHEVRON_DOWN' : 'INTERFACE_CHEVRON_UP',
      collapsed: !this.state.collapsed,
      displayedLanguage: userPreferedLocale,
    });
  };

  toggleShowEmptyCriteria = () => {
    this.setState({
      showEmptyCriteria: !this.state.showEmptyCriteria,
    });
  };

  setVerbatimInStore = () => {
    const { id, uuid, selectedVerbatim } = this.props;
    if (id !== selectedVerbatim.id) {
      this.props.dispatchSelectVerbatim({ id, uuid });
    } else {
      this.props.dispatchSelectVerbatim({ id: 0, uuid: '' });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.checkVerbatimLength();
    }
  }

  componentDidMount() {
    const { id, uuid, isOpen, informations, userInfos, verbatim } = this.props;
    // const { verbatimsLocale: userPreferedLocale } = userInfos;
    const userPreferedLocale = getTranslatedFullVerbatim(verbatim, userInfos.verbatimsLocale).displayedLanguage;
    this.checkVerbatimLength();
    const fullVerbatim =
      (this.props.keywords &&
        verbatim &&
        this.highlightKeywords(getTranslatedFullVerbatim(verbatim, userPreferedLocale).fullVerbatim)
          .verbatimFullText) ||
      getTranslatedFullVerbatim(verbatim, userPreferedLocale).fullVerbatim;
    if (this.props.hideCollapse) {
      this.toggleVerbatim(false);
    }
    const collapsed = this.getCollapsed();
    this.setState({
      collapsed,
      verbatimToShow: collapsed ? (
        this.splitVerbatim(fullVerbatim)
      ) : (
        <VerbatimColor
          verbatims={this.props.verbatim.verbatimSentences}
          emotions={emotions}
          keywords={this.props.keywords}
          belongingClusters={this.props.belongingClusters}
          belongingVerbatim={{ id, uuid }}
          collapsed={collapsed}
          informations={informations || null}
          userPreferedLocale={userPreferedLocale}
        />
      ),
      displayedLanguage: userPreferedLocale,
    });
  }

  splitVerbatim(verbatimFull) {
    return verbatimFull.length > 200 ? (
      <span>
        {`${verbatimFull.substring(0, 199)}(...) `}
        <a href="#" onClick={this.onSplitVerbatimLinkClicked}>
          {this.props.t('see more')}
        </a>
      </span>
    ) : (
      <span>{verbatimFull}</span>
    );
  }

  onSplitVerbatimLinkClicked = e => {
    e.preventDefault();
    this.toggleVerbatim(!this.state.collapsed);
    this.setVerbatimInStore();
  };

  createCriteriaData = (key, name, value, colSize = 6) => {
    const { showEmptyCriteria } = this.state;
    if (value !== '' || showEmptyCriteria === true) {
      return (
        <Col key={key} xs={colSize} className="bubbleAdditionnalInfos">
          <span className="addInfoLabel">{capitalize(name)} : </span>
          <span className="addInfoValue">
            {value.includes('https://') || value.includes('http://') ? (
              <a
                href={value}
                style={{ color: 'inherit', textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value}
              </a>
            ) : (
              value
            )}
          </span>
        </Col>
      );
    }
  };

  checkVerbatimLength() {
    const { id, uuid, informations, userInfos, verbatim } = this.props;
    // const { verbatimsLocale: userPreferedLocale } = userInfos;
    const userPreferedLocale = getTranslatedFullVerbatim(verbatim, userInfos.verbatimsLocale).displayedLanguage;
    const collapsed = this.getCollapsed();
    if (!this.props.verbatim) return;
    const fullVerbatim =
      (this.props.keywords &&
        verbatim &&
        this.highlightKeywords(getTranslatedFullVerbatim(verbatim, userPreferedLocale).fullVerbatim)
          .verbatimFullText) ||
      getTranslatedFullVerbatim(verbatim, userPreferedLocale).fullVerbatim;
    if (fullVerbatim.length > 200 && !this.props.hideCollapse && !this.props.verbatimEmotionsColors && collapsed) {
      this.setState({
        verbatimToShow: this.splitVerbatim(fullVerbatim),
        displayedLanguage: userPreferedLocale,
      });
    } else {
      this.setState({
        collapsed,
        verbatimToShow:
          this.props.verbatimEmotionsColors || !collapsed ? (
            <VerbatimColor
              verbatims={this.props.verbatim.verbatimSentences}
              emotions={emotions}
              keywords={this.props.keywords}
              belongingClusters={this.props.belongingClusters}
              belongingVerbatim={{ id, uuid }}
              collapsed={collapsed}
              informations={informations || null}
              userPreferedLocale={userPreferedLocale}
            />
          ) : (
            fullVerbatim
          ),
        displayedLanguage: getTranslatedFullVerbatim(verbatim, userPreferedLocale).displayedLanguage,
      });
    }
  }

  addAdditionnalInfo = () => {
    const additionnalInfos = this.props.informations.informationsList;
    const questionInformation = this.props.informations.question;
    let allFeaturedInformations = [];
    forEach(additionnalInfos, (criteriaGroupValue, criteriaGroupKey) => {
      const featuredInformation = filter(criteriaGroupValue.values, o => o.featuredInformation);
      allFeaturedInformations = allFeaturedInformations.concat(featuredInformation);
    });
    return (
      <>
        <Row className="mt-3">
          {allFeaturedInformations.map((info, i) =>
            this.createCriteriaData(`verbatimAddtional${i}`, info.name, info.value),
          )}
        </Row>
        <Row>
          {questionInformation &&
            this.createCriteriaData(`verbatimAddtionalQuestion`, this.props.t('Question'), questionInformation, 12)}
        </Row>
        <Row>{this.createCriteriaData(`verbatimAddtionalQuestion`, 'Verbatim ID', this.props.uuid, 12)}</Row>
        {this.props.belongingClusters && this.props?.belongingClusters?.length > 0 && (
          <Row>
            <div className="col-12">
              {this.props.belongingClusters.map((belongingCluster, index) => {
                return (
                  <>
                    <ClusterTag key={index} style={{ maxWidth: '50%', width: 'fit-content' }}>
                      <ClusterTagTone tone={belongingCluster.tone}>
                        <Icon
                          icon={belongingCluster.tone === 'pos' ? 'SUN' : 'INTERFACE_IRRITANTS'}
                          size={16}
                          color={belongingCluster.tone === 'pos' ? '#fff' : '#000'}
                        />
                      </ClusterTagTone>
                      <ClusterTagTitle ref={clusterTagRef => (this.clusterTagRefs[index] = clusterTagRef)}>
                        {belongingCluster.title}
                      </ClusterTagTitle>
                      {belongingCluster.verbatimsCount && (
                        <ClusterTagSize>{belongingCluster.verbatimsCount}</ClusterTagSize>
                      )}
                      {/* {this.removeVerbatimFromClusterButton(
                          belongingCluster.id,
                          belongingCluster.tone,
                        )} */}
                      {this.clusterTagRefs[index]?.clientWidth < this.clusterTagRefs[index]?.scrollWidth - 3 && (
                        <ClusterTagTooltip>
                          <p>
                            {this.props.t('Cluster name')} : {belongingCluster.title}
                          </p>
                        </ClusterTagTooltip>
                      )}
                    </ClusterTag>
                  </>
                );
              })}
            </div>
          </Row>
        )}
      </>
    );
  };

  removeVerbatimFromClusterButton = (clusterId, clusterTone) => {
    const {
      id: verbatimId,
      projectId,
      range,
      clickedCluster,
      clickedClusterTone,
      dispatchSelectClusterDetails,
    } = this.props;
    return (
      <Mutation
        mutation={removeVerbatimFromCluster}
        variables={{
          projectId,
          clusterId,
          verbatimId,
        }}
        update={(cache, { data: { removeVerbatimFromClusterData } }) => {
          const cachedData = cache.readQuery({
            query: getVerbatim,
            variables: { id: verbatimId, type: 'VERBATIM' },
          });
          const updatedBelongingClustersList = cachedData.getVerbatim.belongingClusters.filter(
            cluster => cluster.id !== clusterId,
          );
          const updatedData = cachedData;
          updatedData.getVerbatim.belongingClusters = updatedBelongingClustersList;
          cache.writeQuery({
            query: getVerbatim,
            variables: { id: verbatimId, type: 'VERBATIM' },
            data: updatedData,
          });
          const updatedVerbatimsCount =
            clickedCluster.id === clusterId ? clickedCluster.verbatimsCount - 1 : clickedCluster.verbatimsCount;
          dispatchSelectClusterDetails({
            clickedCluster: {
              id: clickedCluster.id,
              size: clickedCluster.size,
              title: clickedCluster.title,
              hasParents: clickedCluster.hasParents,
              verbatimsCount: updatedVerbatimsCount,
            },
            clickedClusterTone,
          });
        }}
        refetchQueries={[
          {
            query: getClusterVerbatims,
            variables: { projectId, clusterId, range },
          },
          {
            query: getClusterVerbatims,
            variables: { projectId, clusterId: clickedCluster.id, range },
          },
        ]}
      >
        {removeVerbatimFromClusterData => (
          <ClusterTagRemoveButton
            onClick={() => {
              removeVerbatimFromClusterData();
            }}
          >
            <Icon icon="INTERFACE_CROSS" size={24} color={grayShades.g800} />
          </ClusterTagRemoveButton>
        )}
      </Mutation>
    );
  };

  addAdditionnalInfoExpand = () => {
    const additionnalInfos = this.props.informations.informationsList;
    const questionInformation = this.props.informations.question;
    const emotionsData = this.props.emotions;
    const detectedEmotions = Object.keys(pickBy(emotionsData.detectedEmotions, (value, key) => value));
    const arrayOfEmotions = emotions.filter(i => detectedEmotions.includes(i.value));
    const isChat = this.props.verbatim.verbatimFullText.includes('|*|') && !this.state.collapsed;
    const { t } = this.props;
    const { showEmptyCriteria } = this.state;
    return (
      <Row className="mt-3">
        <Col className="mx-3 mb-2" style={{ borderBottom: 'solid 1px #E1E4ED' }}>
          <Row className="addInfoType">{this.props.t('Detected emotions')}</Row>
          <Row className="rowAdditionalInfo">
            {arrayOfEmotions.map((emotion, index) => (
              <span
                style={{ cursor: !isChat && 'pointer' }}
                onMouseEnter={() => {
                  !isChat && this.props.sendEmotionOnHover(emotion.value);
                }}
                onMouseLeave={() => !isChat && this.props.sendEmotionOnHover(null)}
                key={`emotionIcon${index * 2}`}
              >
                <IconCircle size={30} icon={emotion.icon} color={emotion.color} customCSS="iconCSS" />
              </span>
            ))}
          </Row>
        </Col>
        {additionnalInfos.map((type, i) => {
          const maxLength = additionnalInfos.length;
          return (
            <Col className={this.props.hideCollapse ? 'ml-3 pr-4' : 'mx-3'} xs={12} key={`verbatimAddtionalExpand${i}`}>
              <Row className="addInfoType">
                {this.props.t(cap(startCase(type.name)))}
                {type.name === 'CriteriaKey' && (
                  <SwitchContainer>
                    <QBooleanSwitch
                      label={t('button:Show all')}
                      value={showEmptyCriteria}
                      onChanged={this.toggleShowEmptyCriteria}
                      labelColor={purple}
                      customMargin="0"
                    />
                  </SwitchContainer>
                )}
              </Row>
              <Row className="rowAdditionalInfo">
                {type.values.map((info, index) =>
                  this.createCriteriaData(`bubbleAdditionalInfoKey${index * 2}`, info.name, info.value),
                )}
                {maxLength === i + 1 &&
                  questionInformation &&
                  this.createCriteriaData(`verbatimAddtionalQuestion`, 'Question', questionInformation, 12)}
              </Row>
            </Col>
          );
        })}
        <Col className="mx-3 mb-2" xs={12}>
          <Row className="addInfoType">{this.props.t('Thematics')}</Row>
          <Row>{this.getThematics(this.props.thematics)}</Row>
        </Col>
        <Col className="mx-3 mb-2" xs={12}>
          <Row className="addInfoType">{this.props.t('Technical information')}</Row>
          <Row>{this.createCriteriaData(`bubbleTechnicalID`, 'Verbatim ID', this.props.uuid, 12)}</Row>
        </Col>
        <Col className="mx-3 mb-2" xs={12}>
          <Row className="addInfoType">{this.props.t('Cluster')}</Row>
          {this.props.belongingClusters && this.props?.belongingClusters?.length > 0 && (
            <Row style={{ width: '75%' }}>
              {this.props.belongingClusters.map((belongingCluster, index) => {
                return (
                  <>
                    <ClusterTag key={index}>
                      <ClusterTagTone tone={belongingCluster.tone}>
                        <Icon
                          icon={belongingCluster.tone === 'pos' ? 'SUN' : 'INTERFACE_IRRITANTS'}
                          size={16}
                          color={belongingCluster.tone === 'pos' ? '#fff' : '#000'}
                        />
                      </ClusterTagTone>
                      <ClusterTagTitle ref={clusterTagRef => (this.clusterTagRefs[index] = clusterTagRef)}>
                        {belongingCluster.title}
                      </ClusterTagTitle>
                      {belongingCluster.verbatimsCount && (
                        <ClusterTagSize>{belongingCluster.verbatimsCount}</ClusterTagSize>
                      )}
                      {/* {this.removeVerbatimFromClusterButton(
                          belongingCluster.id,
                          belongingCluster.tone,
                        )} */}
                      {this.clusterTagRefs[index]?.clientWidth < this.clusterTagRefs[index]?.scrollWidth - 3 && (
                        <ClusterTagTooltip>
                          <p>
                            {this.props.t('Cluster name')} : {belongingCluster.title}
                          </p>
                        </ClusterTagTooltip>
                      )}
                    </ClusterTag>
                  </>
                );
              })}
            </Row>
          )}
          {(!this.props.belongingClusters || this.props?.belongingClusters?.length < 1) && (
            <span className="text-secondary font-weight-bold">
              {this.props.t('No cluster associated to this verbatim')}
            </span>
          )}
        </Col>
      </Row>
    );
  };

  highlightKeywords(text) {
    const { keywords, verbatim } = this.props;
    const highlightedVerbatim = {};
    let verbatimText = text;

    keywords &&
      keywords.forEach(keyword => {
        if (typeof verbatimText === 'string') {
          if (verbatimText.toLowerCase().includes(keyword.toLowerCase())) {
            verbatimText = verbatimText.split(new RegExp(`${keyword}`, 'gi'));
            let i = verbatimText.length - 1;
            do {
              verbatimText.splice(i, 0, <HighlightedKeyword keyword={keyword} />);
            } while (i--);
            verbatimText.splice(0, 1);
          }
        } else if (Array.isArray(verbatimText)) {
          verbatimText = verbatimText.map(child => {
            if (typeof child === 'string') {
              return this.highlightKeywords(child).verbatimFullText;
            }
            return child;
          });
        }
        highlightedVerbatim.verbatimFullText = verbatimText;
      });
    return highlightedVerbatim;
  }

  getThematics(thematics) {
    if (!isEmpty(this.props.thematics)) {
      return thematics.map((thematic, index) => {
        const thematicTag = (indexThematictag, tagContent, tooltipContent) => (
          <span className="tagThematic" key={`bubbleThematicTag${indexThematictag * 2}`}>
            {tagContent}
            {tooltipContent && <div className="tooltipThematic">{tooltipContent}</div>}
          </span>
        );
        if (thematic.subSubThematic) {
          return thematicTag(index, thematic.subSubThematic, `${thematic.thematic} > ${thematic.subThematic}`);
        }
        if (thematic.subThematic) {
          return thematicTag(index, thematic.subThematic, thematic.thematic);
        }
        return thematicTag(index, thematic.thematic);
      });
    }
    return (
      <span className="text-secondary font-weight-bold">{this.props.t('No thematic associated to this verbatim')}</span>
    );
  }

  render() {
    const footExpand =
      !this.getCollapsed() || this.props.hideCollapse ? this.addAdditionnalInfoExpand() : this.addAdditionnalInfo();
    const getRateDisplay = kpiType =>
      typeof this.props.kpis[kpiType].value === 'number'
        ? `${this.props.kpis[kpiType].value} ${this.props.kpis[kpiType].unit.unit ||
            ` / ${this.props.kpis[kpiType].unit.max}`}`
        : 'NA';
    const kpis = [
      {
        label: 'Sat.',
        value: getRateDisplay('satisfaction'),
      },
      {
        label: 'Reco.',
        value: getRateDisplay('recommendation'),
      },
      {
        label: 'CES',
        value: getRateDisplay('effort'),
      },
      {
        label: 'Emot. intens.',
        value: getRateDisplay('emotionalIntensity'),
      },
      {
        label: 'Confid. rate',
        value: getRateDisplay('confidenceRate'),
      },
    ];
    const styleMainEmotion = {
      backgroundColor: getColorHexaByEmotion(this.props.emotions.mainEmotion.toLowerCase()),
    };
    const date = moment(this.props.informations.date)
      .locale(this.props.i18n.language)
      .format('LL');
    const isChat = this.props.verbatim.verbatimFullText.includes('|*|') && !this.state.collapsed;
    const { verbatimSentences } = this.props.verbatim;

    const checkIfErrorPending = () => {
      return verbatimSentences.some(sentence => {
        if (sentence.emotionalClaim) {
          return sentence.emotionalClaim.status === 'PENDING';
        }
      });
    };

    const checkIfVerified = () => {
      if (!checkIfErrorPending()) {
        return verbatimSentences.some(sentence => {
          if (sentence.emotionalClaim) {
            return sentence.emotionalClaim.claimType === 'exactness' && sentence.emotionalClaim.status === 'COMPLETED';
          }
        });
      }
      return false;
    };

    const getTitle = () => {
      if (checkIfErrorPending()) {
        return 'There are errors declared on this verbatim';
      }
      if (checkIfVerified()) {
        return 'At least one emotion confirmed';
      }
      return '';
    };

    const getTranslations = () => {
      const availableLanguage = getAvailableTranslations(this.props.verbatim, this.state.displayedLanguage);

      const availableTranslation = checkIfAvailableTranslation(
        this.state.displayedLanguage,
        availableLanguage,
        this.props.verbatim,
      );

      return (
        <>
          <TranslationRow>
            <TranslationOptions>
              {availableTranslation && (
                <>
                  <TranslationSpan
                    onClick={() => {
                      const { id, uuid, isOpen, informations, userInfos, verbatim } = this.props;
                      const collapsed = this.getCollapsed();
                      const fullVerbatim =
                        (this.props.keywords &&
                          verbatim &&
                          this.highlightKeywords(getTranslatedFullVerbatim(verbatim, availableLanguage[0]).fullVerbatim)
                            .verbatimFullText) ||
                        getTranslatedFullVerbatim(verbatim, availableLanguage[0]).fullVerbatim;
                      this.setState({
                        verbatimToShow: !collapsed ? (
                          <VerbatimColor
                            verbatims={verbatim.verbatimSentences}
                            emotions={emotions}
                            keywords={this.props.keywords}
                            belongingClusters={this.props.belongingClusters}
                            belongingVerbatim={{ id, uuid }}
                            collapsed={collapsed}
                            informations={informations || null}
                            userPreferedLocale={availableLanguage[0]}
                          />
                        ) : (
                          this.splitVerbatim(fullVerbatim)
                        ),
                        displayedLanguage: availableLanguage[0],
                      });
                    }}
                  >
                    <Icon icon="LANGUAGE_SWITCH" color={grayShades.g800} size={36} />
                    {this.props.t(`${languagesLabels[this.state.displayedLanguage]} version`)}
                    {' - '}
                    {this.props.t(`switch to ${languagesLabels[availableLanguage[0]]}`)}
                  </TranslationSpan>
                </>
              )}
            </TranslationOptions>
          </TranslationRow>
        </>
      );
    };

    return (
      <Row className={`${!this.props.hideCollapse && 'px-3'} d-block`}>
        <Bubble xs={12}>
          <Row className="mb-2 pb-1" style={{ borderBottom: 'solid 1px #E1E4ED' }}>
            <Col xs="auto">
              <EindexRond
                eindex={this.props.kpis.eindex.value === null ? 'no data' : this.props.kpis.eindex.value}
                type="small"
              />
            </Col>
            <Col className=" align-items-center text-center pl-2">
              <div className="scoreLabel">{this.props.t('Main emotion')}</div>
              <div>
                <div className="divMainEmotion" style={styleMainEmotion}>
                  {this.props.t(`emotion:${capitalize(this.props.emotions.mainEmotion.toLowerCase())}`)}
                </div>
              </div>
            </Col>
            <Col xs={1} title={this.props.t(getTitle())}>
              {(checkIfErrorPending() || checkIfVerified()) && (
                <Icon
                  icon={checkIfErrorPending() ? 'WARNING' : checkIfVerified() ? 'CHECKMARK' : null}
                  color={styleVars.gray_500}
                  size={32}
                />
              )}
            </Col>
            {kpis.map((i, index) => (
              <Col key={`${index}kpismap`} className=" align-items-center text-center pl-2">
                <div className="scoreLabel">{this.props.t(i.label)}</div>
                <div className="score">{i.value}</div>
              </Col>
            ))}
            <Col xs={1} />
            <Col xs={1}>
              {this.props.userInfos &&
                this.props.userInfos.account.id.toString() === process.env.REACT_APP_QEMOTION_ACCOUNT_ID.toString() &&
                this.props.userInfos.account.name === process.env.REACT_APP_QEMOTION_ACCOUNT_NAME && (
                  <span
                    onClick={() => window.open(`${process.env.REACT_APP_BACK_OFFICE_URL}/verbatims/${this.props.id}`)}
                  >
                    <Icon
                      icon="PICTO_DISABLE"
                      color={styleVars.gray_400}
                      size={24}
                      backgroundColor={styleVars.gray_400}
                    />
                  </span>
                )}
            </Col>
          </Row>
          <Row style={{ borderBottom: 'solid 1px #E1E4ED' }}>
            <Col className="mx-3 pb-2">
              <Row>
                <p className="bubbledate">{date}</p>
              </Row>
              <Row
                className={`${this.state.bubbleClass}`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <span style={{ width: isChat ? '70%' : '100%' }}>{this.state.verbatimToShow}</span>
              </Row>
              {getTranslations()}
            </Col>
          </Row>
          {footExpand}
          {this.props.hideCollapse !== true && (
            <span
              onClick={() => {
                this.toggleVerbatim(!this.state.collapsed);
                this.setVerbatimInStore();
              }}
              className={`buttonExpand ${this.state.buttonCollapseShowing}`}
            >
              <Icon icon={this.state.collapseIcon} color={grayShades.g800} />
            </span>
          )}
        </Bubble>
      </Row>
    );
  }
}

VerbatimBubble.propTypes = {
  date: PropTypes.string,
};
VerbatimBubble.defaultProps = {
  date: '',
};

function mapStateToProps(state) {
  return {
    userInfos: state.auth.user,
    projectId: state.projects.currentProject.id,
    clusterId: state.cluster.clickedCluster.id,
    range: state.periods.currentPeriod,
    clickedCluster: state.cluster.clickedCluster,
    clickedClusterTone: state.cluster.clickedClusterTone,
    clusters: state.cluster.clusters,
    selectedVerbatim: state.verbatim.selectedVerbatim,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendEmotionOnHover: newState => {
      dispatch(sendVerbatimEmotionsOnHover(newState));
    },
    dispatchSelectClusterDetails: (newState: any) => {
      dispatch(selectClusterDetails(newState));
    },
    dispatchSelectVerbatim: newState => {
      dispatch(selectVerbatim(newState));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation('card', ['emotion', 'button'])(VerbatimBubble)));
