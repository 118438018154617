import { FormGroup, Label } from 'reactstrap';
import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QInput from '_Components/Fields/QInput/QInput';
import { grayShades } from 'styles/abstracts/colors';

const StyledQInput = styled(QInput)`
  width: 100%;
`;

const StyledLabel = styled(Label)`
  color: ${grayShades.g800};
`;

class NameInputBenchmark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
    };
    this.onNameChange = this.onNameChange.bind(this);
  }

  onNameChange(e) {
    this.setState({ name: e.target.value });
    this.props.dispatcher(e.target.value);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <FormGroup>
          <StyledLabel>{t('Name')}</StyledLabel> <br />
          <StyledQInput
            primary
            maxLength={50}
            value={this.state.name}
            onChange={this.onNameChange}
          />
        </FormGroup>
      </>
    );
  }
}
export default withTranslation('card')(NameInputBenchmark);
