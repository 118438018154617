import React from 'react';
import styled from 'styled-components';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import { lightBlue, red } from 'styles/abstracts/colors';

const Error = styled.p`
  font-weight: bold;
`;

const ContactTeam = styled.a`
  font-weight: bold;
  font-size: 0.875rem;
  color: ${lightBlue};
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: ${lightBlue};
  }
`;

const ErrorUploadContainer = ({ t }) => (
  <span>
    <IconCircle size={64} icon="WARNING" color={red} />
    <br />
    <Error>{t('An error occurred')}</Error> <br />
    <p>{t('We encountered a problem while uploading your file...')}</p>
    <p>
      {t(
        'Verify that the file format is correct (for instance, .CSV or .docx formats are not allowed).',
      )}
    </p>
    <p>{t('If your problem persists, contact the technical team by email.')}</p>
    <br />
    <ContactTeam href="mailto:support-team@qemotion.com">
      &nbsp; {t('Contact the technical team')}
    </ContactTeam>
  </span>
);

export default ErrorUploadContainer;
