import gql from 'graphql-tag';
import FRAGMENTS from '../../../_Resources/Cards/Services/GraphQl/FRAGMENTS';

const query = gql`
  query getMergedVerbatimListData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $mergedThematics: [ThematicInput!]
    $keywords: [String!]
    $sort: Sort
    $selectedEmotions: [Emotions!]
    $lastVerbatimId: Int
    $limit: Int
    $dataCollectorId: Int
  ) {
    getMergedVerbatimListData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      mergedThematics: $mergedThematics
      keywords: $keywords
      sort: $sort
      selectedEmotions: $selectedEmotions
      lastVerbatimId: $lastVerbatimId
      limit: $limit
      dataCollectorId: $dataCollectorId
    ) {
      verbatimList {
        ...VerbatimFragment
      }
      lastVerbatimId
      remainingVerbatim
    }
  }
  ${FRAGMENTS.verbatim}
`;

export default query;
