export default [
  {
    label: 'E-index ©',
    children: [
      {
        name: 'section1',
        content: 'eindex',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'E-index badge',
    children: [
      {
        name: 'section1',
        content: 'eindexBadge',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'Mentions',
    children: [
      {
        name: 'section1',
        content: 'mention',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },

  {
    label: 'Emotional intensity',
    children: [
      {
        name: 'section1',
        content: 'emotionalIntensity',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'Speech engagement',
    children: [
      {
        name: 'section1',
        content: 'speechEngagement',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'Satisfaction',
    children: [
      {
        name: 'section1',
        content: 'satisfaction',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'Emotional breakdown',
    children: [
      {
        name: 'section1',
        content: 'emotionsBreakdown',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'E-index evolution',
    children: [
      {
        name: 'section1',
        content: 'eindexEvolution',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'Emotions evolution',
    children: [
      {
        name: 'section1',
        content: 'emotionsEvolution',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'Best & Worst categories',
    children: [
      {
        name: 'section1',
        content: 'bestAndWorstCategories',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'Best verbatim',
    children: [
      {
        name: 'section1',
        content: 'bestVerbatim',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'Worst verbatim',
    children: [
      {
        name: 'section1',
        content: 'worstVerbatim',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'Word cloud',
    children: [
      {
        name: 'section1',
        content: 'wordCloud',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    label: 'E-index bar graph',
    children: [
      {
        name: 'section1',
        content: 'eindexBarGraph',
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
];
