import React, { Component } from 'react';
import styled from 'styled-components';
import { pipe, mergeDeepRight, clone, path, map } from 'ramda';
import { connect } from 'react-redux';
import GoogleApi from '../core/containers/GoogleApi/GoogleApi';
import Map from '../core/containers/Map/Map';
import { DataHandler } from '../core/utils/DataHandler';
import { preparePropertyComparer } from '../core/utils/commonHelpers';
import { mapOptions as defaultMapOptions } from './config/mapOptions';
import { modes as defaultModes } from './config/modesConfig';

import EmotionMarker from './EmotionMarker/EmotionMarker';
import MonitorBy from './MonitorBy';
import CurrentPlace from './CurrentPlace';
import ZoomControl from './ZoomControl';
import WorldMapLegend from './WorldMapLegend';

const DESELECT_TIME_OFFSET = 250;
const INCREASE = 1;
const DECREASE = -1;
const safeMerge = pipe(clone, mergeDeepRight);

const mapPanelHeight = '6em';

const StyledWorldMapLegend = styled(WorldMapLegend)`
  bottom: 0;
  right: 0;
  height: ${mapPanelHeight};
`;

const StyledMonitorBy = styled(MonitorBy)`
  bottom: 0;
  height: ${mapPanelHeight};
`;

const StyledZoomControl = styled(ZoomControl)`
  top: 0;
`;

const StyledCurrentPlace = styled(CurrentPlace)`
  top: 0;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const WorldMapVizContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

class WorldMapViz extends Component {
  isPropChanged = preparePropertyComparer(this);

  state = {
    zoom: 13,
    hoverIndicator: 0,
    currentLocationName: null,
    markers: [],
    bounds: [],
    currentMode: 'eindex',
    modes: defaultModes.celsius,
    mapOptions: defaultMapOptions,
    listeners: {
      click: () => this.setState({ hoverIndicator: 0 }),
    },
  };

  dataHandler = new DataHandler();

  componentDidMount() {
    this.updateMapOptions();
    this.updateModes();
  }

  componentDidUpdate(prevProps, prevState) {
    const areLocationsChanged = this.isPropChanged('locations', prevProps);
    if (
      areLocationsChanged ||
      this.state.currentMode !== prevState.currentMode
    ) {
      const locations = this.prepareLocations();

      this.updateMarkers(locations);
      if (areLocationsChanged && path(['length'], locations)) {
        this.updateBounds(locations);
      }
    }

    if (this.isPropChanged('mapOptions', prevProps)) {
      this.updateMapOptions();
    }

    if (this.isPropChanged('modes', prevProps)) {
      this.updateModes();
    }

    if (this.isPropChanged('fahrenheit', prevProps)) {
      this.updateModes();
    }
  }

  prepareLocations() {
    return this.dataHandler.processData(this.props.locations);
  }

  updateMapOptions() {
    const { mapOptions = {} } = this.props;
    const newMapOptions = safeMerge(defaultMapOptions)(mapOptions);

    this.setState(prevState => ({
      zoom: mapOptions.zoom || prevState.zoom,
      mapOptions: newMapOptions,
    }));
  }

  updateModes() {
    const { modes = {}, fahrenheit: fahrenheitEnabled } = this.props;
    const { ranges } = defaultModes.celsius.sat;
    if (
      this.props.sat &&
      (this.props.sat.max !== defaultModes.celsius.sat.range.max ||
        this.props.sat.max !== defaultModes.celsius.reco.range.max)
    ) {
      const range = {
        min: this.props.sat.min,
        max: this.props.sat.max,
      };
      const newDefaultModesSatRange = mergeDeepRight(
        defaultModes.celsius.sat.range,
        range,
      );
      defaultModes.celsius.sat.range = newDefaultModesSatRange;
      defaultModes.fahrenheit.sat.range = newDefaultModesSatRange;
      defaultModes.celsius.reco.range = newDefaultModesSatRange;
      defaultModes.fahrenheit.reco.range = newDefaultModesSatRange;
      const scale = x =>
        (x * this.props.sat.max) / defaultModes.celsius.sat.ranges.length;
      const newSatRanges = [];
      ranges.map((range) => {
        const minMaxRange = {
          min: range.min,
          max: range.max,
        };
        const newMinMaxRange = map(scale, minMaxRange);
        const newRangeWithColor = mergeDeepRight(range, newMinMaxRange);
        newSatRanges.push(newRangeWithColor);
      });
      defaultModes.celsius.sat.ranges = newSatRanges;
      defaultModes.fahrenheit.sat.range = newSatRanges;
      defaultModes.celsius.reco.ranges = newSatRanges;
      defaultModes.fahrenheit.reco.range = newSatRanges;
    }
    const { celsius, fahrenheit } = defaultModes;
    const currentModes = fahrenheitEnabled ? fahrenheit : celsius;
    this.setState({
      modes: safeMerge(currentModes)(modes),
    });
  }

  updateMarkers(locations) {
    this.setState({
      markers: locations.map(options => {
        return (
          <EmotionMarker
            key={options.id}
            options={options}
            type={this.state.currentMode}
            onSelected={this.onMarkerSelected}
            onMouseOver={this.onMarkerEntered}
            onMouseOut={this.onMarkerLeft}
            fahrenheit={this.props.fahrenheit}
          />
        );
      }),
    });
  }

  updateBounds(locations) {
    const bounds = locations.map(({ position }) => position);
    this.setState({ bounds });
  }

  updateHoverIndicator(amount) {
    this.setState(prevState => {
      return {
        hoverIndicator: prevState.hoverIndicator + amount,
      };
    });
  }

  delayedHoverIndicatorUpdate(amount) {
    setTimeout(this.updateHoverIndicator(amount), DESELECT_TIME_OFFSET);
  }

  updateCurrentLocationName(name) {
    this.setState({
      currentLocationName: name,
    });
  }

  onModeChange = mode => this.setState({ currentMode: mode });

  onMarkerSelected = (id, name) => {
    const { onMarkerSelected } = this.props;
    onMarkerSelected && onMarkerSelected(id, name);
  };

  onMarkerEntered = (id, name) => {
    this.updateHoverIndicator(INCREASE);
    this.updateCurrentLocationName(name);
  };

  onMarkerLeft = () => this.delayedHoverIndicatorUpdate(DECREASE);

  onCurrentPlacePanelEntered = () => this.updateHoverIndicator(INCREASE);

  onCurrentPlacePanelLeft = () => this.delayedHoverIndicatorUpdate(DECREASE);

  onZoomUpdate = zoom => this.setState({ zoom });

  render() {
    return (
      <WorldMapVizContainer className={this.props.className}>
        <GoogleApi
          config={this.props.config}
          render={google => (
            <Map
              google={google}
              options={this.state.mapOptions}
              bounds={this.state.bounds}
              zoom={this.state.zoom}
              onMapZoomChanged={this.onZoomUpdate}
              listeners={this.state.listeners}
            >
              {this.state.markers}
            </Map>
          )}
        />
        <StyledZoomControl
          zoom={this.state.zoom}
          onZoomUpdate={this.onZoomUpdate}
        />
        <StyledMonitorBy
          modes={this.state.modes}
          onChange={this.onModeChange}
        />
        <StyledWorldMapLegend
          modes={this.state.modes}
          currentMode={this.state.currentMode}
        />
        {this.state.hoverIndicator !== 0 && (
          <StyledCurrentPlace
            name={this.state.currentLocationName}
            onMouseLeave={this.onCurrentPlacePanelLeft}
            onMouseEnter={this.onCurrentPlacePanelEntered}
          />
        )}
      </WorldMapVizContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    sat: state.projectConfiguration.unitsAndLocalesSettings.sat,
  };
}

export default connect(mapStateToProps)(WorldMapViz);
