import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CModal from '../../../../../../../../_Components/CModal/CModal';
import QModalSection from '../../../../../../../../_Components/Modals/QModalSection/QModalSection';
import QButton from '../../../../../../../../_Components/Buttons/QButton/QButton';
import Icon from '_Components/Icons/Icon';

const StyledQModalSection = styled(QModalSection)`
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  flex-direction: column;
  & > * {
    margin: 1em;
  }
`;

const StyledCModal = styled(CModal)`
  width: 500px !important;

  & .modal-content > *:first-child {
    padding-bottom: 0;
  }
`;

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  & > * {
    margin: 0 0.5em;
  }
`;

class AbortModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onAbortConfirmed: PropTypes.func.isRequired,
    onAbortCanceled: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  title = 'Aborting insight creation';
  subtitle = 'Are you sure you want to abort this insight creation ?';

  getFooter() {
    const { onAbortConfirmed, onAbortCanceled, t } = this.props;
    return (
      <Fragment>
        <StyledQModalSection>
          <StyledQButton bgColor="red" icon="TRASH" iconSize={24} iconPosition="left" onClick={onAbortConfirmed}>
            <span>{t('button:Yes, abort this insight creation')}</span>
          </StyledQButton>
          <QButton outline onClick={onAbortCanceled}>
            {t('button:No, go back to insight creation')}
          </QButton>
        </StyledQModalSection>
      </Fragment>
    );
  }

  render() {
    const { isOpen, t } = this.props;
    return (
      <StyledCModal
        size="sm"
        data={{}}
        title={t(this.title)}
        displayMentionsSubtitle={false}
        displayDateSubtitle={false}
        subtitleAdditionalContent={t(this.subtitle)}
        footer={this.getFooter()}
        modal={isOpen}
      />
    );
  }
}

export default withTranslation('card', 'button')(AbortModal);
