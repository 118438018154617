import gql from 'graphql-tag';

const mutation = gql`
  mutation saveAccountSettings($companyName: String!, $name: String!, $address1: String!, $address2: String, $address3: String, $alpha3Cc: String!, $city: String!, $postalCode: String!, $stateProvince: String) {
    saveAccountSettings(companyName: $companyName, name: $name, address1: $address1, address2: $address2, address3: $address3, alpha3Cc: $alpha3Cc, city: $city, postalCode: $postalCode, stateProvince: $stateProvince) {
      errors
    }
  }
`
export default mutation;
