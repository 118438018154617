/* eslint-disable import/prefer-default-export */

const normalizeCategories = categories =>
  categories.map(category => ({
    ...category,
    items: category.items.map(item => ({
      ...item,
      parent: item.parent || null,
    })),
  }));

const sortCategories = (normalizedCategories, parent = null) => {
  const category = normalizedCategories.find(({ items }) =>
    items.find(({ parent: p }) => p === parent),
  );

  if (!category) return [];

  const [{ value: nextParent }] = category.items;
  return [category, ...sortCategories(normalizedCategories, nextParent)];
};

export const getCategoriesHierarchy = categories => {
  const normalizedCategories = normalizeCategories(categories);
  return sortCategories(normalizedCategories);
};
