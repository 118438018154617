import gql from 'graphql-tag';

const EmotionsBreakdownFragment = gql`
  fragment EmotionsBreakdownFragment on EmotionsBreakdown {
    happiness
    surprise
    calm
    fear
    sadness
    anger
    disgust
    __typename
  }
`;

export default EmotionsBreakdownFragment;
