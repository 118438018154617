import React from 'react';
import { connect } from 'react-redux';
import querySchema from './graphql/getCategoryGlobalBestAndPainPointsCardData';
import CQuery from '../../../_Container/QQuery/CQuery';
import CategoryGlobalBestAndPainPointsCard from './CategoryGlobalBestAndPainPointsCard';

const CategoryGlobalBestAndPaintPointCardSection = ({
  filters,
  noDataComponent,
  errorComponent,
  filterKeys,
  level,
  ...props
}) => {
  const filterKeysWithNbThematicsPerCategory = {
    ...filterKeys,
    nbThematicsPerCategory: props.nbThematicsPerCategory,
    level,
  };

  return (
    <CQuery
      query={querySchema}
      filterKeys={filterKeysWithNbThematicsPerCategory}
      noDataComponent={noDataComponent}
      errorComponent={errorComponent}
    >
      <CategoryGlobalBestAndPainPointsCard {...props} />
    </CQuery>
  );
};

const mapStateToProps = (state, props) => ({
  filterKeys: props.filters || state.filters.filterKeys,
});

export default connect(mapStateToProps)(CategoryGlobalBestAndPaintPointCardSection);
