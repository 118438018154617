import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  activeTextColor,
  borderColor,
  purple,
  textColor,
  touchedTextColor,
} from '../../../styles/abstracts/colors';

const Body = styled.div`
  border-top: 1px solid #e1e4ed;
  display: flex;
  width: 100%;
  background: #f7f9ff;
`;

const sectionBorder = color => `1px solid ${color}`;
const Section = styled.div`
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  padding: 0.5em 1em;
  font-weight: bold;
  color: ${({ selected, active }) => {
    if (selected) {
      return touchedTextColor;
    } else if (active) {
      return activeTextColor;
    }
    return textColor;
  }};
  background-color: ${({ selected }) => selected && 'white'};
  border: ${({ selected }) => selected && sectionBorder(borderColor)};
  border-top: ${({ selected }) => selected && sectionBorder('white')};
  border-bottom: ${({ selected }) =>
    selected ? sectionBorder('white') : sectionBorder(borderColor)};
`;

const SectionTitle = styled.span`
  font-weight: bold;
  color: ${purple};
  cursor: auto;
`;

const FiltersHeaders = ({
  categories = [],
  current,
  onCategorySelected,
  t,
}) => (
  <Body>
    <Section>
      <SectionTitle>{t('button:Refine by')}...</SectionTitle>
    </Section>
    {categories.map(({ id, label, active }) => (
      <Section
        selected={id === current}
        active={active}
        onClick={() => onCategorySelected(id)}
      >
        {t(label)}
      </Section>
    ))}
  </Body>
);

FiltersHeaders.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      active: PropTypes.bool,
    }),
  ).isRequired,
  current: PropTypes.string.isRequired,
  onCategorySelected: PropTypes.func.isRequired,
};

export default withTranslation('card', 'button')(FiltersHeaders);
