import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { white, grayShades } from 'styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import CModal from '_Components/CModal/CModal';
import { QSideBarLayout } from '_Components/Layouts/QSideBarLayout';
import QModalSection from '_Components/Modals/QModalSection/QModalSection';
import QButton from '_Components/Buttons/QButton/QButton';
import { selectSentenceDetails } from '_Resources/Sentence/Actions/selectSentenceDetails';
import { setSentenceMenuLevel } from '_Resources/Sentence/Actions/setSentenceMenuLevel';
import { OptionType, isOptionVisible } from '_Utils/components/isOptionVisible';
import { ImageSize } from 'styles/abstracts/variables';
import declareEmotionalError from '_Resources/Sentence/Services/api/graphql/declareEmotionalError';
import cancelEmotionalError from '_Resources/Sentence/Services/api/graphql/cancelEmotionalError';
import declareEmotionalExactness from '_Resources/Sentence/Services/api/graphql/declareEmotionalExactness';
import cancelEmotionalExactness from '_Resources/Sentence/Services/api/graphql/cancelEmotionalExactness';
import { useMutation } from 'react-apollo';
import GET_VERBATIM_QUERY from '_Components/QVerbatim/graphql/getVerbatim';
import { hex2rgbaConvert } from '_Utils/styles/colors';
import VerbatimSentenceCommentBox from './VerbatimSentenceCommentBox';
import { setSentenceDropdownMenuOpen } from '_Resources/Sentence/Actions/setSentenceDropdownMenuOpen';
import { capitalize } from 'lodash';
import { sendVerbatimEmotionsOnHover } from '_Resources/Cards/Actions/sendVerbatimEmotionsOnHover';

const VerbatimSentenceOptionsToggle = styled(DropdownToggle) <{
  isActive: boolean;
}>`
  background: none !important;
  &:focus {
    box-shadow: none !important;
  }
  ${({ isActive }) =>
    isActive &&
    `
    & > svg > path {
      fill: ${grayShades.g900} !important;
    }
    `}

  &:hover > svg > path {
    fill: ${grayShades.g900} !important;
  }
`;

const VerbatimSentenceDropdownOption = styled(DropdownItem)<{color: string; isSelected: boolean}>`
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  padding: 0.2rem 0.5rem !important;
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  ${({isSelected}) => isSelected ? `background-color: ${grayShades.g300};` : ``};
    & > * {
      transition: 0.2s ease;
    };

  ${({isSelected, color}) => isSelected ? `
    color: ${color};

    & > svg {
      transform: rotate(90deg);
    };

    & > svg > path {
      fill: ${hex2rgbaConvert(color, 100)} !important;
    };

    & > div > div {
      background-color: ${hex2rgbaConvert(color, 20)};

      & > svg > path {
        fill: ${hex2rgbaConvert(color, 100)} !important;
      };
    };

    ` : ``};

  &:hover, &:focus, &:focus-visible {
    outline: none;
    background-color: ${grayShades.g300};
    color: ${color => color !== '' ? color : 'initial'};
  }
`;

const VerbatimSentenceOptionsButton = styled(ButtonDropdown)`
  border: 0;
  margin-left: 0.5em;
  cursor: pointer;
  font-weight: bolder;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-text: center;
  padding: 0;
  transition: 0.2s;
  background-color: transparent;

  &:focus {
    outline: 0;
  }

  & > svg > path {
    transition: 0.2s;
  }

  &:hover > svg > path {
    fill: ${grayShades.g900} !important;
  }
`;

const VerbatimSentenceOptionsDropdownMenu = styled(DropdownMenu)<{level: string}>`
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px !important;
  padding: 12px 0 !important;
  border: none !important;
  min-width: 12rem !important;
  top: 31px !important;
  transition: all 0.2s ease;
`;

const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  margin: -16px;
`;

const QSideBarLayoutDisplayArea = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const StyledCModal = styled(CModal)`
  & .modal-content > *:first-child {
    padding-bottom: 0;
  }
`;

const StyledQModalSection = styled(QModalSection)`
  padding-left: 3em;
  padding-right: 3em;
  align-items: center;
  & > * {
    margin: 1em;
  }
`;

const StyledCancelButton = styled(QButton)`
  width: 50%;
`;

const StyledCloseModalCrossButton = styled(QButton)`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  right: 1em;
  top: 1em;
  padding: 0 !important;
`;

const StyledButton = styled(QButton)`
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0 0.25em;
  }
`;

const AssignClustersButton = styled(QButton)`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0 0.25em;
  }
`;

const OptionTitle = styled.div`
  margin-right: 3rem;
  display: flex;
  align-items: center;
  span {
    margin-left: 1rem;
  }
`;

const StyledTextarea = styled.textarea`
  border-radius: 10px;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  padding: 0.5rem;
`;

const StyledCircle = styled.div<{size: number; bgColor: string; pointer: string; color: string}>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${grayShades.g400};
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 200ms ease;

  & > svg > path {
    fill: ${grayShades.g600} !important;
  }

  ${VerbatimSentenceDropdownOption}:hover &, ${VerbatimSentenceDropdownOption}:focus & {
    background-color: ${ ({bgColor})  => bgColor};

    & > svg > path {
      fill: ${ ({color})  => color} !important;
    }
  }
`;

const StyledIcon = styled(Icon)<{hoverColor: string}>`
  ${VerbatimSentenceDropdownOption}:hover &, ${VerbatimSentenceDropdownOption}:focus & {
    & > path {
      fill: ${ ({hoverColor})  => hoverColor} !important;
    }
  }
`;

const ClaimInformationsContainer = styled.div`
  padding-left: calc(2rem + 24px);
  color: ${grayShades.g800};
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  text-transform: initial;
`;

const ClaimInformationValue = styled.span`
  font-weight: bold;
`;

type BelongingVerbatimType = {
  id: number;
  uuid: string;
};

type EmotionalClaimType = {
  claimId: string;
  claimType: string;
  sentenceId: number;
  suggestedEmotion: string;
  comment: string | null;
  username: string;
  status: string;
};

type VerbatimType = {
  clusters: Array<string> | [];
  emotion: string;
  relevantThematic: boolean;
  emotionalClaim: EmotionalClaimType | null,
  sentenceId: number;
  sentence: string;
};

type BelongingClustersArrayType = {
  id: string;
  title: string;
  tone: string;
  verbatimsCount: number;
};

type VerbatimSentenceOptionsProps = {
  t: any;
  index: number;
  sentenceIndex: number | undefined;
  emotion: string;
  sentenceId: number | undefined;
  sentenceText: string;
  belongingClusters: Array<BelongingClustersArrayType>;
  belongingVerbatim: BelongingVerbatimType;
  verbatim: VerbatimType;
  cluster: any;
  sentence: string;
  project: any;
  dispatchSelectSentenceDetails: Function;
  dispatchSetSentenceMenuLevel: Function;
  dispatchSetSentenceDropdownMenuOpen: Function;
  sendEmotionOnHover: Function;
};

const VerbatimSentenceOptions = ({
  t,
  index,
  sentenceIndex,
  sentenceText,
  emotion,
  sentenceId,
  belongingClusters,
  belongingVerbatim,
  verbatim,
  cluster,
  sentence,
  project,
  dispatchSelectSentenceDetails,
  dispatchSetSentenceMenuLevel,
  dispatchSetSentenceDropdownMenuOpen,
  sendEmotionOnHover,
  }: VerbatimSentenceOptionsProps) => {
  const [clusterModal, setClusterModal] = useState({ openModal: false, clusterTone: undefined, modal: undefined, modalToSwitchTo: undefined, validateButton: undefined });

  const [commentBox, toggleCommentBox] = useState(false);

  const [comment, setComment] = useState('');

  const [showDropdownMenu, toggleShowDropdownMenu] = useState(sentence.showDropdownMenu);

  const handleComment = (event) => {
    setComment(event.target.value);
  };

  const [emotionToSuggest, setEmotionToSuggest] = useState('');

  const getClustersToAssignOptions = (clusterTone: string) => {
    if (clusterTone !== undefined) {
      return clusterTone === 'neg'
        ? cluster?.clusters?.irritants
        : cluster?.clusters?.enchantements;
    }
    return [];
  };

  const getClaimInformations = (verbatim) => {
    const { emotionalClaim, emotion } = verbatim;
    const { sentenceId, claimType, suggestedEmotion, comment, username } = emotionalClaim;
    return (
      <ClaimInformationsContainer>
        <p>{t('Sentence ID')} : <ClaimInformationValue>{sentenceId}</ClaimInformationValue></p>
        <p>{t('Claim type')} : <ClaimInformationValue>{t(capitalize(claimType))}</ClaimInformationValue></p>
        {suggestedEmotion &&
          <p>{t('Suggested emotion')} : <ClaimInformationValue>{t(capitalize(suggestedEmotion))}</ClaimInformationValue></p>
        }
        {!suggestedEmotion &&
          <p>{t('Emotion')} : <ClaimInformationValue>{t(capitalize(emotion))}</ClaimInformationValue></p>
        }
        {suggestedEmotion &&
          <p>{t('Comment')} : <ClaimInformationValue>{comment}</ClaimInformationValue></p>
        }
        <p>{t('Corrector')} : <ClaimInformationValue>{username}</ClaimInformationValue></p>
      </ClaimInformationsContainer>
      );
  }

  const [ declareError, { data, loading, error}] = useMutation(declareEmotionalError, {
    refetchQueries: [
      {
        query: GET_VERBATIM_QUERY,
        variables: {
          id: sentence.clickedSentence.belongingVerbatimId,
          type: 'VERBATIM',
        },
      },
    ],
  });

  const [ cancelError, { data: cancelErrorData, loading: cancelErrorLoading, error: cancelErrorError}] = useMutation(cancelEmotionalError, {
    refetchQueries: [
      {
        query: GET_VERBATIM_QUERY,
        variables: {
          id: sentence.clickedSentence.belongingVerbatimId,
          type: 'VERBATIM',
        },
      },
    ],
  });

  const [ confirmAnalysis, { data: confirmAnalysisData, loading: confirmAnalysisLoading, error: confirmAnalysisError}] = useMutation(declareEmotionalExactness, {
    refetchQueries: [
      {
        query: GET_VERBATIM_QUERY,
        variables: {
          id: sentence.clickedSentence.belongingVerbatimId,
          type: 'VERBATIM',
        },
      },
    ],
  });

  const [ cancelConfirmation, { data: cancelConfirmationData, loading: cancelConfirmationLoading, error: cancelConfirmationError}] = useMutation(cancelEmotionalExactness, {
    refetchQueries: [
      {
        query: GET_VERBATIM_QUERY,
        variables: {
          id: sentence.clickedSentence.belongingVerbatimId,
          type: 'VERBATIM',
        },
      },
    ],
  });

  const assignClustersButton = (disabled: boolean) => (
    <AssignClustersButton
      bgColor="purple"
      onClick={() => {
        // TODO: requete assign cluster
        // setNameNewClusterModalOpen(true);
      }}
      icon="DUPLICATE"
      iconSize={24}
      iconPosition="left"
      disabled={disabled}
    >
      <span>{t(`button:Assign cluster`)}</span>
    </AssignClustersButton>
  );

  const getFooter = () => {
    return (
      <>
        <StyledQModalSection>
          <StyledCancelButton
            outline
            onClick={() => {
              setClusterModal({
                openModal: false,
                clusterTone: undefined,
                modal: undefined,
                modalToSwitchTo: undefined,
                validateButton: undefined,
              })
            }}
          >
            {t('button:Back to verbatim details')}
          </StyledCancelButton>
          {switchButton}
          {validateButton}
        </StyledQModalSection>
      </>
    );
  };

  const buttonRight = (
    <StyledCloseModalCrossButton
      bgColor="greyShades.g300"
      type="button"
      onClick={() => {
        setClusterModal({
          openModal: false,
          clusterTone: undefined,
          modal: undefined,
          modalToSwitchTo: undefined,
          validateButton: undefined,
        })
      }}
      size="small"
      icon="CROSS"
      iconSize={24}
    />
  );

  const ErrorDeclarationOptions: OptionType[] = [
    {
      text: 'Back',
      icon: {
        iconType: 'INTERFACE_CHEVRON_LEFT',
        iconColor: '',
      },
      topSeparator: '',
      onClick: () => { setDropdownOptions(VerbatimSentenceDropdownOptions); toggleCommentBox(false)},
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      menuLevel: 2,
    },
    {
      text: 'Happiness',
      icon: {
        iconType: 'EMOTION_HAPPINESS',
        iconColor: '#DB2156',
      },
      topSeparator: '',
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      hasFollowing: true,
      emotionToSuggest: 'Happiness',
      menuLevel: 2,
    },
    {
      text: 'Surprise',
      icon: {
        iconType: 'EMOTION_SURPRISE',
        iconColor: '#FF8104',
      },
      topSeparator: '',
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      hasFollowing: true,
      emotionToSuggest: 'Surprise',
      menuLevel: 2,
    },
    {
      text: 'Calm',
      icon: {
        iconType: 'EMOTION_CALM',
        iconColor: '#F5D800',
      },
      topSeparator: '',
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      hasFollowing: true,
      emotionToSuggest: 'Calm',
      menuLevel: 2,
    },
    {
      text: 'Fear',
      icon: {
        iconType: 'EMOTION_FEAR',
        iconColor: '#8BDB00',
      },
      topSeparator: '',
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      hasFollowing: true,
      emotionToSuggest: 'Fear',
      menuLevel: 2,
    },
    {
      text: 'Sadness',
      icon: {
        iconType: 'EMOTION_SADNESS',
        iconColor: '#0EC3E3',
      },
      topSeparator: '',
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      hasFollowing: true,
      emotionToSuggest: 'Sadness',
      menuLevel: 2,
    },
    {
      text: 'Anger',
      icon: {
        iconType: 'EMOTION_ANGER',
        iconColor: '#007AFF',
      },
      topSeparator: '',
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      hasFollowing: true,
      emotionToSuggest: 'Anger',
      menuLevel: 2,
    },
    {
      text: 'Disgust',
      icon: {
        iconType: 'EMOTION_DISGUST',
        iconColor: '#222C66',
      },
      topSeparator: '',
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      hasFollowing: true,
      emotionToSuggest: 'Disgust',
      menuLevel: 2,
    },
  ]

  const VerbatimSentenceDropdownOptions: OptionType[] = [
    {
      text: 'This sentence emotion was declared incorrect',
      icon: {
        iconType: 'WARNING',
        iconColor: '',
      },
      topSeparator: '',
      onClick: () => {
        setDropdownOptions(CancelErrorDeclarationOptions);
        dispatchSetSentenceMenuLevel(2);
      },
      emotionalClaimConditions: [{ claimType: 'ERROR', status: 'PENDING' }],
      hasFollowing: true,
      menuLevel: 1,
    },
    {
      text: 'Emotional analysis on this sentence has been confirmed',
      icon: {
        iconType: 'CHECKMARK',
        iconColor: '',
      },
      topSeparator: '',
      onClick: () => {
        setDropdownOptions(CancelExactnessConfirmationOptions);

       },
      emotionalClaimConditions: [{ claimType: 'EXACTNESS', status: 'COMPLETED' }],
      hasFollowing: true,
      menuLevel: 1,
    },
    {
      text: 'Confirm emotional analysis',
      icon: {
        iconType: 'CHECK_ALL',
        iconColor: '',
      },
      topSeparator: '',
      onClick: () => {
        confirmAnalysis({
          variables: {
            projectId: project.id,
            sentenceId,
          },
        });

        setDropdownOptions(VerbatimSentenceDropdownOptions);
        dispatchSetSentenceDropdownMenuOpen(false);
        toggleShowDropdownMenu(false);
        dispatchSetSentenceMenuLevel(0);
        dispatchSelectSentenceDetails({
          clickedSentence: {
            id: "",
            sentence: "",
            emotion: "",
            belongingVerbatimId: 0,
          }
        });

        // toggleDropdown();

        sendEmotionOnHover(null);

      },
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      menuLevel: 1,
    },
    {
      text: 'Declare an emotional error',
      icon: {
        iconType: 'CROSS',
        iconColor: '',
      },
      topSeparator: '',
      onClick: () => {
        setDropdownOptions(ErrorDeclarationOptions);
        dispatchSetSentenceMenuLevel(2);
      },
      emotionalClaimConditions: [null, { claimType: 'ERROR', status: 'COMPLETED' }, { claimType: 'ERROR', status: 'CANCELED' }, { claimType: 'EXACTNESS', status: 'CANCELED' }],
      hasFollowing: true,
      menuLevel: 1,
    },
    // {
    //   text: 'Associate to an existing irritant point',
    //   tone: 'neg',
    //   icon: {
    //     iconType: 'LINK',
    //     iconColor: '',
    //   },
    //   topSeparator: '',
    //   associatedEmotions: ['fear', 'sadness', 'anger', 'disgust'],
    //   modal: (<div>Soon</div>),
    //   action: 'associate',
    //   modalToSwitchTo: (<CreateNewCluster tone='neg' />),
    //   validateButton: { assignClustersButton },
    //   emotionalClaimConditions: [],
    //   menuLevel: 1,
    // },
    // {
    //   text: 'Associate to an existing enchantment point',
    //   tone: 'pos',
    //   icon: {
    //     iconType: 'LINK',
    //     iconColor: '',
    //   },
    //   topSeparator: '',
    //   associatedEmotions: ['happiness', 'surprise'],
    //   modal: (<ClustersToAssignSelect options={getClustersToAssignOptions('pos')} t={t} belongingClusters={belongingClusters} tone='pos' />),
    //   action: 'associate',
    //   modalToSwitchTo: (<CreateNewCluster tone='pos' />),
    //   validateButton: { assignClustersButton },
    //   emotionalClaimConditions: [],
    //   menuLevel: 1,
    // },
    // {
    //   text: 'Create a new irritant point',
    //   tone: 'neg',
    //   icon: {
    //     iconType: 'PLUS',
    //     iconColor: '',
    //   },
    //   topSeparator: '',
    //   associatedEmotions: ['fear', 'sadness', 'anger', 'disgust'],
    //   modal: (<CreateNewCluster tone='neg' />),
    //   action: 'create',
    //   modalToSwitchTo: (<ClustersToAssignSelect options={getClustersToAssignOptions('neg')} t={t} belongingClusters={belongingClusters} tone='neg' />),
    //   validateButton: { assignClustersButton },
    //   emotionalClaimConditions: [],
    //   menuLevel: 1,
    // },
    // {
    //   text: 'Create a new enchantment point',
    //   tone: 'pos',
    //   icon: {
    //     iconType: 'PLUS',
    //     iconColor: '',
    //   },
    //   topSeparator: '',
    //   associatedEmotions: ['happiness', 'surprise'],
    //   modal: (<CreateNewCluster tone='pos' />),
    //   action: 'create',
    //   modalToSwitchTo: (<ClustersToAssignSelect options={getClustersToAssignOptions('pos')} t={t} belongingClusters={belongingClusters} tone='pos' />),
    //   validateButton: { assignClustersButton },
    //   emotionalClaimConditions: [],
    //   menuLevel: 1,
    // },
  ];

  const CancelErrorDeclarationOptions: OptionType[] = [
    {
      text: 'Back',
      icon: {
        iconType: 'INTERFACE_CHEVRON_LEFT',
        iconColor: '',
      },
      topSeparator: '',
      onClick: () => { setDropdownOptions(VerbatimSentenceDropdownOptions); toggleCommentBox(false)},
      emotionalClaimConditions: [{ claimType: 'ERROR', status: 'PENDING' }],
      menuLevel: 2,
    },
    {
      text: 'Information',
      icon: {
        iconType: 'WARNING',
        iconColor: '',
      },
      topSeparator: '',
      emotionalClaimConditions: [{ claimType: 'ERROR', status: 'PENDING' }],
      menuLevel: 2,
    },
    {
      text: 'Cancel previously declared error',
      icon: {
        iconType: 'TRASH',
        iconColor: '',
      },
      topSeparator: '',
      onClick: () => {
        const { claimId } = verbatim.emotionalClaim;
        cancelError({
          variables: {
            projectId: project.id,
            claimId,
          },
        });
        toggleDropdown();
        sendEmotionOnHover(null);
      },
      emotionalClaimConditions: [{ claimType: 'ERROR', status: 'PENDING' }],
      menuLevel: 2,
    },
  ];

  const CancelExactnessConfirmationOptions: OptionType[] = [
    {
      text: 'Back',
      icon: {
        iconType: 'INTERFACE_CHEVRON_LEFT',
        iconColor: '',
      },
      topSeparator: '',
      onClick: () => { setDropdownOptions(VerbatimSentenceDropdownOptions)},
      emotionalClaimConditions: [{ claimType: 'EXACTNESS', status: 'COMPLETED' }],
      menuLevel: 2,
    },
    {
      text: 'Information',
      icon: {
        iconType: 'CHECKMARK',
        iconColor: '',
      },
      topSeparator: '',
      emotionalClaimConditions: [{ claimType: 'EXACTNESS', status: 'COMPLETED' }],
      menuLevel: 2,
    },
    {
      text: `Cancel previously confirmed emotion`,
      icon: {
        iconType: 'TRASH',
        iconColor: '',
      },
      topSeparator: '',
      onClick: () => {
        const { claimId } = verbatim?.emotionalClaim;
        cancelConfirmation({
          variables: {
            projectId: project.id,
            claimId,
          },
        });
        toggleDropdown();
        sendEmotionOnHover(null);
      },
      emotionalClaimConditions: [{ claimType: 'EXACTNESS', status: 'COMPLETED' }],
      menuLevel: 2,
    },
  ]

  const [dropdownOptions, setDropdownOptions] = useState(VerbatimSentenceDropdownOptions);

  const toggleDropdown = () => {
    setDropdownOptions(VerbatimSentenceDropdownOptions);
    dispatchSetSentenceDropdownMenuOpen(!sentence.showDropdownMenu);
    toggleShowDropdownMenu(!sentence.showDropdownMenu);
    dispatchSetSentenceMenuLevel(0);
    if (sentence.showDropdownMenu === true) {
      dispatchSelectSentenceDetails({
        clickedSentence: {
          id: "",
          sentence: "",
          emotion: "",
          belongingVerbatimId: 0,
        }
      });
    }
    if (sentence.showDropdownMenu === false) {
      dispatchSelectSentenceDetails({
        clickedSentence: {
          id: sentenceId,
          sentence: sentenceText,
          emotion,
          belongingVerbatimId: belongingVerbatim.id,
        }
      });
    }
  }

  const handleSend = (option) => {
    toggleCommentBox(!commentBox);
    toggleShowDropdownMenu(false);
    sendEmotionOnHover(null);
    setDropdownOptions(VerbatimSentenceDropdownOptions);
    declareError({
      variables: {
        projectId: project.id,
        sentenceId: sentence.clickedSentence.id,
        suggestedEmotion: option?.emotionToSuggest?.toUpperCase(),
        comment,
      },
    });
    dispatchSelectSentenceDetails({
      clickedSentence: {
        id: sentenceId,
        sentence: sentenceText,
        emotion,
        belongingVerbatimId: belongingVerbatim.id,
      }
    });
  };

  const onKeyDown = (e: any, option: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSend(option);
    }
  };

  return (
    <>
      <VerbatimSentenceOptionsButton
        isOpen={index === sentenceIndex && sentence.showDropdownMenu}
        toggle={toggleDropdown}
        direction="down"
      >
        <VerbatimSentenceOptionsToggle
        >
          <Icon
            icon="INTERFACE_DOTS_VERTICAL"
            size={20}
            color={white}
          />
        </VerbatimSentenceOptionsToggle>
        <VerbatimSentenceOptionsDropdownMenu className="verbatimSentenceOptionsDropdownMenu" level={dropdownOptions[0].text === 'Back' ? 'secondary' : 'primary'}>
          {dropdownOptions.filter(option => isOptionVisible(option, verbatim, emotion)).filter(option => option.text.toLowerCase() !== emotion).map(option =>
          (
            <>
              {option.topSeparator && <hr />}
              <VerbatimSentenceDropdownOption
                onClick={() => {
                  setClusterModal({ openModal: true, clusterTone: option.tone, modal: option.modal, modalToSwitchTo: option.modalToSwitchTo, validateButton: option.validateButton });
                  if (option.onClick) {
                    option.onClick();
                  }
                  if (dropdownOptions[0].text === 'Back') {
                    if (option.text === emotionToSuggest && commentBox) {
                      toggleCommentBox(false);

                    } else {
                      toggleCommentBox(true);
                    }
                    setComment('');
                    setEmotionToSuggest(option.text);
                  }
                }}
                color={option.icon.iconColor}
                isSelected={commentBox && option.emotionToSuggest === emotionToSuggest}
                toggle={false}
                onKeyDown={e => {
                  if (dropdownOptions[0].text === 'Back') {
                    if (option.text === emotionToSuggest && commentBox) {
                      onKeyDown(e, option);
                    }
                  }
                }}
              >
                <OptionTitle>
                  <>
                {option.icon.iconType && option.emotionToSuggest === undefined && (
                  <Icon
                    icon={option.icon.iconType}
                    size={24}
                    color={option.icon.iconColor || grayShades.g800}
                  />
                )}
                {option.icon.iconType && option.emotionToSuggest && (
                  <StyledCircle
                    color={hex2rgbaConvert(option.icon.iconColor, 100)}
                    bgColor={hex2rgbaConvert(option.icon.iconColor, 20)}
                    pointer
                  >
                    <Icon icon={option.icon.iconType} color={option.icon.iconColor} size={24} />
                  </StyledCircle>
                )}
                </>
                <span>{t('button:'+option.text, {emotion: t(capitalize(verbatim.emotion))})}</span>
                </OptionTitle>
                {option.hasFollowing && (
                    <StyledIcon
                      icon="INTERFACE_CHEVRON_RIGHT"
                      size={ImageSize.medium}
                      color={grayShades.g800}
                      hoverColor={option.icon.iconColor}
                    />
                  )}
              </VerbatimSentenceDropdownOption>
              {option.text === 'Information' && getClaimInformations(verbatim)}
              {commentBox && option.emotionToSuggest === emotionToSuggest &&
                  <VerbatimSentenceCommentBox
                      commentBox={commentBox}
                      comment={comment}
                      handleComment={handleComment}
                      toggleCommentBox={toggleCommentBox}
                      loading={loading}
                      toggleShowDropdownMenu={() => toggleShowDropdownMenu(!showDropdownMenu)}
                      setDropdownOptions={setDropdownOptions}
                      declareError={declareError}
                      project={project}
                      sentence={sentence}
                      option={option}
                      verbatimSentenceDropdownOptions={VerbatimSentenceDropdownOptions}
                      toggleDropdown={() => toggleDropdown()}
                  />

              }
            </>
          )
          )}
        </VerbatimSentenceOptionsDropdownMenu>
      </VerbatimSentenceOptionsButton>
      {clusterModal.openModal && (
        <StyledCModal
          size="xl"
          data={{}}
          title={t('You selected "' + sentenceText + '"')}
          displayMentionsSubtitle={false}
          displayDateSubtitle={false}
          footer={() => getFooter()}
          modal={clusterModal.modal}
          buttonRight={buttonRight}
        >
          <Body>
            <HeaderArea />
            <QSideBarLayout>
              <QSideBarLayoutDisplayArea>
                {clusterModal.modal}
              </QSideBarLayoutDisplayArea>
            </QSideBarLayout>
          </Body>
        </StyledCModal>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSelectSentenceDetails: (newState: any) => {
    dispatch(selectSentenceDetails(newState));
  },
  dispatchSetSentenceMenuLevel: (level: number) => {
    dispatch(setSentenceMenuLevel(level));
  },
  dispatchSetSentenceDropdownMenuOpen: showDropdownMenu => {
    dispatch(setSentenceDropdownMenuOpen(showDropdownMenu));
  },
  sendEmotionOnHover: (newState: any) => {
    dispatch(sendVerbatimEmotionsOnHover(newState));
  },
});

const mapStateToProps = (state: any) => ({
  cluster: state.cluster,
  sentence: state.sentence,
  project: state.projects.currentProject,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('card', ['button'])
  (VerbatimSentenceOptions));
