import gql from 'graphql-tag';
import { cloneDeep, findIndex } from 'lodash';
import getReportingModel from 'Routes/Tools/ReportingMainPage/apollo/graphql/getReportingModel';
import CURRENT_USER_QUERY from '_Resources/Header/Services/api/graphql/currentUser';

export const SAVE_REPORT = gql`
  mutation SaveReportingModel($projectId: Int!, $title: String!, $settings: Json, $reportingModelId: Int) {
    saveReportingModel(projectId: $projectId, title: $title, settings: $settings, reportingModelId: $reportingModelId) {
      id
    }
  }
`;

export const updateQueryReporting = (projectId, title, settings) => (cache, { data: { saveReportingModel } }) => {
  const reportingList = cloneDeep(
    cache.readQuery({ query: getReportingModel, variables: { projectId } }).getReportingModel,
  );
  let dataToReplace;
  const foundModelIndex = findIndex(reportingList, {
    id: saveReportingModel.id,
  });
  if (foundModelIndex === -1) {
    const saveReportingExtended = saveReportingModel;
    saveReportingExtended.settings = null;
    dataToReplace = reportingList.concat(saveReportingExtended);
  } else {
    reportingList[foundModelIndex] = {
      ...reportingList[foundModelIndex],
      ...saveReportingModel,
      ...(settings && { settings }),
    };
    dataToReplace = reportingList;
    cache.writeQuery({
      query: getReportingModel,
      variables: { projectId },
      data: { getReportingModel: dataToReplace },
    });
  }
  const { currentUser } = cache.readQuery({
    query: CURRENT_USER_QUERY,
  });
  currentUser.account.canCreateReporting = saveReportingModel.canCreateReporting;
  cache.writeQuery({
    query: CURRENT_USER_QUERY,
    data: { currentUser },
  });
};
