import React from 'react';
import { connect } from 'react-redux';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import CategoryGlobalBestAndPaintPointCardSection from './CategoryGlobalBestAndPaintPointCardSection';

const CategoryGlobalBestAndPaintPointCardContainer = props => (
  <QcardPanel
    data={[true]}
    {...props}
    hints={[HintsContent.HINT_BEST_PAIN_POINT]}
  >
    <CategoryGlobalBestAndPaintPointCardSection {...props} />
  </QcardPanel>
);

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
  };
}

export default connect(mapStateToProps)(
  CategoryGlobalBestAndPaintPointCardContainer,
);
