import React from 'react';
import styled from 'styled-components';
import {
  getHeaderTopHeight,
  getMainFiltersContainerHeight,
  getMenuContainerWidth,
} from '_Layouts/Header/_Utils/HeaderUtils';
import SidePanelCard from '_Cards/SideCards/SidePanelCard/SidePanelCard';
import { opacity } from '../../../../styles/abstracts/colors';

const Container = styled.div`
  height: calc(100% + 2em);
  width: ${({ menuWidth }) => `calc(100vw - ${menuWidth}px)`};
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ displaySideCard }) =>
    displaySideCard ? opacity.black : 'transparent'};
  transition: all 0.5s ease-in-out;
  z-index: ${({ displaySideCard }) => (displaySideCard ? 2 : '-1')};
  overflow-y: hidden;
`;

const CardContainer = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  right: ${({ displaySideCard }) => (displaySideCard ? 0 : '-50%')};
  transition: all 0.5s ease-in-out;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const SideCardContainer = props => {
  let filters = { ...props.filters };
  if (props.mentions) filters = { ...filters, mentions: props.mentions };

  return (
    <Container
      onClick={props.toggleDisplaySideCard}
      menuWidth={getMenuContainerWidth()}
      headerHeight={getMainFiltersContainerHeight() + getHeaderTopHeight()}
      displaySideCard={props.displaySideCard}
    >
      <CardContainer
        onClick={event => event.stopPropagation()}
        displaySideCard={props.displaySideCard}
      >
        {!props.unmountSideCard && (
          <SidePanelCard
            dropDownOptions={['EXPORT']}
            filters={filters}
            cardTitle={props.cardTitle}
            noBorderRadius
            closeCard={() => props.toggleDisplaySideCard()}
            isCardOpened={props.isCardOpened}
            disableBPPTab={props.disableBPPTab}
          />
        )}
      </CardContainer>
    </Container>
  );
};

export default SideCardContainer;
