import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QButton from '../../../Buttons/QButton/QButton';

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
const Cancel = styled(QButton)`
  margin-right: 2rem;
  cursor: pointer;
  font-weight: 800;
`;
const StyledButton = styled(QButton)`
  display: flex !important;
`;
const ButtonText = styled.div`
  padding-right: 1rem;
`;
class ModaleExportFooter extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Footer>
        <Cancel
          bgColor="grayShades.g800"
          outline
          onClick={() => this.props.toggleModal()}
        >
          {t('Cancel')}
        </Cancel>
        <StyledButton
          color="primary"
          onClick={() => this.props.exportCard(this.props.optionSettings)}
          icon="DOWNLOAD"
        >
          <ButtonText>{t('Export card')}</ButtonText>
        </StyledButton>
      </Footer>
    );
  }
}

export default withTranslation('button')(ModaleExportFooter);
