import gql from 'graphql-tag';

const mutation = gql`
  mutation deleteProject($id: Int!) {
    deleteProject(id: $id) {
      id
      errors
    }
  }
`;

export default mutation;
