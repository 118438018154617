import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next'
import QButton from '_Components/Buttons/QButton/QButton';
import { black } from 'styles/abstracts/colors';
import SubHeader from '_Layouts/Header/_Containers/SubHeader/SubHeader';
import Project from '_Layouts/Header/_Containers/SubHeader/_Containers/ListProjectContainer';

const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${black};
`;


type SubheaderProps = {
  isCreateAlertModalOpen: boolean;
  openCreateAlertModal: any;
  t: any;
};

const Subheader = ({
  isCreateAlertModalOpen,
  openCreateAlertModal,
  t
}: SubheaderProps) => {
  const SHContent = (
    <Container>
      <Project id="alerting-project-subheader" />
      <div>
        <QButton
          bgColor="lightBlue"
          size="lg"
          icon="PLUS"
          iconPosition="left"
          onClick={() => {
            openCreateAlertModal(!isCreateAlertModalOpen);
          }}
          id="alerting-create-alert-button"
        >
          {t('Create an alert')}
        </QButton>
      </div>
    </Container>
  );
  return <SubHeader SHContent={SHContent} allowCollapse={false} />;
};

export default withTranslation('button')(Subheader);
