import React, { Component } from 'react';
import styled from 'styled-components';
import QDropdown from '_Components/Menus/QDropdownMenu/QDropdown';
import QDropdownMenu, {
  QDropdownMenuItem,
} from '_Components/Menus/QDropdownMenu/QDropdownMenu';
import QDropdownMenuToggle from '_Components/Menus/QDropdownMenu/QDropdownMenuToggle';
import Icon from '_Components/Icons/Icon';
import { red, grayShades } from 'styles/abstracts/colors';
import onClickOutside from 'react-onclickoutside';
import { deleteItem } from '_Resources/Benchmark/Actions/Items/deleteItem';
import { duplicateItem } from '_Resources/Benchmark/Actions/Items/duplicateItem';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CONTEXT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';

const StyledQDropdownMenu = styled(QDropdownMenu)`
  z-index: 9999;
  min-width: 12rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
`;

const StyledQDropdownMenuItem = styled(QDropdownMenuItem)`
  display: flex;
  padding: 1rem;
  svg {
    margin-right: 1rem;
  }
`;

const RedMenuItem = styled(StyledQDropdownMenuItem)`
  color: ${red};
`;

class BenchmarkColumnDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleEdit() {
    this.setState({ isOpen: false });
    this.props.onClickEditAction();
  }

  handleDuplicate() {
    this.setState({ isOpen: false });
    this.props.dispatchDuplicateColumn({
      id: this.props.columnId,
      context: CONTEXT.COLUMN,
    });
  }

  handleDelete() {
    this.setState({ isOpen: false });
    this.props.dispatchDeleteColumn({
      id: this.props.columnId,
      context: CONTEXT.COLUMN,
    });
  }

  myClickOutsideHandler = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <QDropdown>
          <QDropdownMenuToggle
            toggle={() => this.setState({ isOpen: !this.state.isOpen })}
            isOpen={this.state.isOpen}
            index={this.props.columnId}
          >
            <Icon
              icon="INTERFACE_DOTS_VERTICAL"
              size={24}
              color={grayShades.g600}
            />
          </QDropdownMenuToggle>
          <StyledQDropdownMenu
            isOpen={this.state.isOpen}
            index={this.props.columnId}
            placement="right"
          >
            <StyledQDropdownMenuItem onClick={() => this.handleEdit()}>
              <Icon icon="INTERFACE_EDIT" size={24} color={grayShades.g800} />{' '}
              {t('Edit')}
            </StyledQDropdownMenuItem>
            <StyledQDropdownMenuItem onClick={() => this.handleDuplicate()}>
              <Icon icon="DUPLICATE" size={24} color={grayShades.g800} />{' '}
              {t('Duplicate')}
            </StyledQDropdownMenuItem>
            <hr />
            <RedMenuItem onClick={() => this.handleDelete()}>
              <Icon icon="TRASH" size={24} color={red} /> {t('Delete')}
            </RedMenuItem>
          </StyledQDropdownMenu>
        </QDropdown>
      </div>
    );
  }
}

BenchmarkColumnDropdown.propTypes = {
  onClickEditAction: PropTypes.func.isRequired,
  dispatchDuplicateColumn: PropTypes.func.isRequired,
  dispatchDeleteColumn: PropTypes.func.isRequired,
  columnId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

const clickOutsideConfig = {
  handleClickOutside(instance) {
    return instance.myClickOutsideHandler;
  },
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchDeleteColumn: newState => {
      dispatch(deleteItem(newState));
    },
    dispatchDuplicateColumn: newState => {
      dispatch(duplicateItem(newState));
    },
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(
  withTranslation('benchmark')(
    onClickOutside(BenchmarkColumnDropdown, clickOutsideConfig),
  ),
);
