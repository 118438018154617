import React from 'react';
import { connect } from 'react-redux';
import { upperFirst, get, find, orderBy, pick } from 'lodash';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { QemotionTheme } from '../../Utils/qemotionTheme';
import grabberGraph from './grabber_graph.svg';
import { white, grayShades, opacity, emotionColors, purple } from '../../../styles/abstracts/colors';
import {
  FILTERS_DIMENSIONS,
  INDICATORS_CONFIG,
  INDICATORS_TYPE,
} from '../../../_Cards/FilterCards/DataByFiltersCard/FiltersSettings';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

class DataByFiltersGraph extends React.Component {
  static propTypes = {
    height: PropTypes.string,
    barType: PropTypes.string,
    badgeType: PropTypes.string,
  };

  static defaultProps = {
    height: 'calc(100% - 4em)',
    barType: FILTERS_DIMENSIONS.MENTIONS,
    badgeType: FILTERS_DIMENSIONS.EINDEX,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      badgeType: this.props.badgeType,
      barType: this.props.barType,
      barIndicator: this.props.barIndicator,
      orderBy: this.props.orderBy,
    };
    this.updateStateGraph = this.updateStateGraph.bind(this);
  }

  componentDidMount() {
    this.props.orderBy && this.props.data && this.sortData();
    this.props.updateData(this.state.data);
    this.props.updateDatazoom({
      start: this.props.datazoom.start,
      end: this.props.datazoom.end,
    });
    this.updateStateGraph();
  }

  componentWillMount() {
    this.props.orderBy && this.props.data && this.sortData();
    if (typeof document !== 'undefined') {
      const echarts = require('echarts');
      echarts.registerTheme('QemotionTheme', QemotionTheme);
    }
    this.updateStateGraph();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.orderBy.value !== prevProps.orderBy.value ||
      (this.props.badgeType !== prevProps.badgeType && this.props.badgeType !== null) ||
      (this.props.barType !== prevProps.barType && this.props.barType !== null)
    ) {
      this.sortData();
    }
    if (this.props.data !== prevProps.data) {
      this.props.refetch();
    }
    if (this.props.barIndicator.selected !== prevProps.barIndicator.selected) {
      const options = this.getOption(this.state.data);
      this.echartsInstance.getEchartsInstance().setOption({ ...options }, true);
    }
  }

  updateStateGraph() {
    this.setState({
      badgeType: this.props.badgeType,
      barType: this.props.barType,
      barIndicator: this.props.barIndicator,
      orderBy: this.props.orderBy,
    });
  }

  sortData() {
    const splitOrder = this.props.orderBy.value.split('_');
    const emotionsList = Object.keys(this.state.data[0].emotions);
    if (
      splitOrder[0] !== this.props.badgeType &&
      splitOrder[0] !== this.props.barType &&
      splitOrder[0] !== 'criteriaKeyLabel' &&
      !emotionsList.includes(splitOrder[0])
    ) {
      splitOrder[0] = this.props.badgeType || this.props.barType || 'criteriaKeyLabel';
    }
    const secondSplit =
      splitOrder[0] === this.props.badgeType
        ? this.props.barType && this.props.barType.split('_')[0]
        : this.props.badgeType && this.props.badgeType.split('_')[0];
    let orderedData;
    if (!emotionsList.includes(splitOrder[0])) {
      orderedData =
        (this.props.data && Number.isInteger(this.props.data[0][splitOrder[0]])) ||
        (this.props.data &&
          this.props.data[0][splitOrder[0]].value !== null &&
          this.props.data[0][splitOrder[0]].value !== undefined)
          ? this.props.data.sort(
              (a, b) =>
                a[splitOrder[0]] - b[splitOrder[0]] ||
                (Number.isInteger(a[splitOrder[0]].value) ? a[splitOrder[0]].value : a[splitOrder[0]]) -
                  (Number.isInteger(b[splitOrder[0]].value) ? b[splitOrder[0]].value : b[splitOrder[0]]) ||
                (secondSplit &&
                  (Number.isInteger(a[secondSplit].value) ? a[secondSplit].value : a[secondSplit]) -
                    (Number.isInteger(b[secondSplit].value) ? b[secondSplit].value : b[secondSplit])),
            )
          : orderBy(this.props.data, item => item[splitOrder[0]] && item[splitOrder[0]].toLowerCase(), 'asc');
    } else if (this.props.barType === null) {
      orderedData = this.props.data.sort(
        (a, b) =>
          secondSplit &&
          (Number.isInteger(a[secondSplit].value) ? a[secondSplit].value : a[secondSplit]) -
            (Number.isInteger(b[secondSplit].value) ? b[secondSplit].value : b[secondSplit]),
      );
    } else {
      orderedData = this.props.data.sort(
        (a, b) =>
          a[this.props.barType][splitOrder[0]] - b[this.props.barType][splitOrder[0]] ||
          (secondSplit &&
            (Number.isInteger(a[secondSplit].value) ? a[secondSplit].value : a[secondSplit]) -
              (Number.isInteger(b[secondSplit].value) ? b[secondSplit].value : b[secondSplit])),
      );
    }
    orderedData = splitOrder[1] === 'desc' ? orderedData.reverse() : orderedData;
    this.setState({
      data: orderedData,
    });
  }

  getIndicatorColor(indicator, indicatorType, options) {
    const { data } = options;
    switch (indicatorType) {
      case FILTERS_DIMENSIONS.EINDEX:
        data &&
          data.forEach((item, index) => {
            data[index].itemStyle.color = find(
              INDICATORS_CONFIG[indicator],
              i => i.value === FILTERS_DIMENSIONS.EINDEX,
            ).color(item.value, this.props.eindexUnitFahrenheit);
          });
        break;
      case FILTERS_DIMENSIONS.MENTIONS:
        data &&
          data.forEach((item, index) => {
            data[index].itemStyle.color = find(
              INDICATORS_CONFIG[indicator],
              i => i.value === FILTERS_DIMENSIONS.MENTIONS,
            ).color;
          });
        break;
      case FILTERS_DIMENSIONS.RECOMMENDATION:
      case FILTERS_DIMENSIONS.SATISFACTION:
      case FILTERS_DIMENSIONS.EFFORT:
        data &&
          data.forEach((item, index) => {
            data[index].itemStyle.color =
              indicator === INDICATORS_TYPE.BADGE && !data[index].label.normal.show
                ? 'transparent'
                : find(INDICATORS_CONFIG[indicator], i => i.value === FILTERS_DIMENSIONS[indicatorType.toUpperCase()])
                    .color;
            data[index].itemStyle.borderColor =
              indicator === INDICATORS_TYPE.BADGE && !data[index].label.normal.show ? 'transparent' : 'white';
          });
        break;
      default:
        if (data && data.length > 0) {
          data.forEach((item, index) => {
            data[index].itemStyle.color = null;
          });
        }
        break;
    }
  }

  getIndicatorsOptions(data, indicatorType) {
    const { barType, badgeType, barIndicator } = this.props;

    const currentIndicator = indicatorType === INDICATORS_TYPE.BADGE ? badgeType : barType;
    const indicatorsOptions = currentIndicator !== null && {
      min:
        currentIndicator === INDICATORS_TYPE.BADGE && currentIndicator === FILTERS_DIMENSIONS.EINDEX
          ? find(INDICATORS_CONFIG[indicatorType], i => i.value === currentIndicator).min(
              this.props.filters,
              this.props.eindexUnitFahrenheit,
            )
          : find(INDICATORS_CONFIG[indicatorType], i => i.value === currentIndicator).min(
              data,
              this.props.eindexUnitFahrenheit,
            ),
      max:
        currentIndicator === badgeType && currentIndicator === FILTERS_DIMENSIONS.EINDEX
          ? find(INDICATORS_CONFIG[indicatorType], i => i.value === currentIndicator).max(
              this.props.filters,
              this.props.eindexUnitFahrenheit,
            )
          : find(INDICATORS_CONFIG[indicatorType], i => i.value === currentIndicator).max(data),
      dataset: currentIndicator === FILTERS_DIMENSIONS.EMOTIONS && {},
      data:
        data &&
        currentIndicator !== FILTERS_DIMENSIONS.EMOTIONS &&
        data.map(item => ({
          value:
            find(INDICATORS_CONFIG[indicatorType], i => i.value === currentIndicator).data(
              item,
              this.props.eindexUnitFahrenheit,
            ) ?? 0,
          itemStyle: {
            barBorderRadius:
              item[currentIndicator].value > 0 ||
              item[currentIndicator] > 0 ||
              currentIndicator === FILTERS_DIMENSIONS.EMOTIONS ||
              this.props.eindexUnitFahrenheit
                ? [12, 12, 0, 0]
                : [0, 0, 12, 12],
          },
          label: {
            normal: {
              show:
                indicatorType === INDICATORS_TYPE.BADGE &&
                item[currentIndicator].value !== null &&
                item[currentIndicator] !== null,
              formatter: currentIndicator === FILTERS_DIMENSIONS.EINDEX ? '{c}°' : '{c}',
              fontSize: 12,
            },
          },
        })),
    };
    if (indicatorType === INDICATORS_TYPE.BAR && currentIndicator === FILTERS_DIMENSIONS.EMOTIONS) {
      const arrayEmotions = INDICATORS_CONFIG[indicatorType]
        .find(config => config.value === barType)
        .subcategories.map(subcat => subcat.value);
      const emotionsLabels = data && Object.keys(data[0].emotions);
      emotionsLabels && emotionsLabels.pop();
      emotionsLabels && emotionsLabels.unshift('labels');
      const emotionsValues =
        data &&
        data
          .map(item => {
            const emotionsToOrder = pick(item.emotions, barIndicator.selected);
            const orderedEmotions = Object.keys(emotionsToOrder).sort(
              (a, b) => arrayEmotions.indexOf(a) - arrayEmotions.indexOf(b),
            );
            let emotionsToMap = {};
            emotionsToMap = orderedEmotions
              .map(emotion => ({
                ...emotionsToMap,
                [emotion]: emotionsToOrder[emotion],
              }))
              .reduce((r, c) => Object.assign(r, c), {});
            return Object.values(emotionsToMap);
          })
          .map((emotion, index) => {
            emotion.unshift(data[index].criteriaKeyLabel);
            return emotion;
          });
      emotionsLabels && emotionsValues.unshift(emotionsLabels);
      indicatorsOptions.dataset.source = emotionsValues;
    }
    this.getIndicatorColor(indicatorType, currentIndicator, indicatorsOptions);
    return indicatorsOptions;
  }

  getOption = data => {
    const badgeConfig = this.getIndicatorsOptions(data, INDICATORS_TYPE.BADGE);
    const barConfig = this.getIndicatorsOptions(data, INDICATORS_TYPE.BAR);
    const { barType, badgeType, barIndicator, t } = this.props;
    const selectedEmotions =
      barIndicator.selected &&
      barIndicator.selected.sort(
        (a, b) => Object.keys(data[0].emotions).indexOf(a) - Object.keys(data[0].emotions).indexOf(b),
      );
    const emotionsBars =
      barType === FILTERS_DIMENSIONS.EMOTIONS
        ? selectedEmotions &&
          selectedEmotions.map(emotion => ({
            name: this.props.t(upperFirst(emotion)),
            type: 'bar',
            itemStyle: {
              barBorderRadius: [0, 0, 0, 0],
              color: emotionColors[emotion],
            },
            z: 1,
            yAxisIndex: 1,
            xAxisIndex: 0,
            barMaxWidth: 12,
          }))
        : null;
    const options = {
      grid: {
        bottom: this.props.data && this.props.data.length > 10 ? '35%' : '15%',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          lineStyle: {
            color: 'transparent',
          },
        },
        formatter: data => {
          return `${data
            .filter(d => d.color !== 'transparent')
            ?.map(d =>
              d.seriesType === 'graph' || (d.seriesType === 'bar' && barType !== FILTERS_DIMENSIONS.EMOTIONS)
                ? `<div>${d.seriesName} : ${d.value}</div>`
                : `<div>${d.seriesName} : ${d.value[d.seriesIndex]}</div>`,
            )
            .join('')}`;
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        feature: {
          dataZoom: {
            title: {
              zoom: t('graph:Zoom'),
              back: t('graph:Zoom reset'),
            },
            // yAxisIndex: 'none',
            iconStyle: {
              borderColor: purple,
            },
          },
          dataView: {
            title: t('graph:View data'),
            lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
            readOnly: false,
            textColor: purple,
            buttonColor: purple,
            iconStyle: {
              borderColor: purple,
            },
          },
          magicType: {
            title: {
              line: t('graph:Switch to line'),
              bar: t('graph:Switch to bar'),
              stack: t('graph:Stack'),
              tiled: t('graph:Tile'),
            },
            type: barType === FILTERS_DIMENSIONS.EMOTIONS ? ['line', 'bar', 'stack'] : ['line', 'bar'],
            iconStyle: {
              borderColor: purple,
            },
          },
          restore: {
            title: t('graph:Reload'),
            iconStyle: {
              borderColor: purple,
            },
          },
          saveAsImage: {
            title: t('graph:Save as image'),
            name: t('graph:Graph Q°Emotion'),
            backgroundColor: '#FFFFFF',
            iconStyle: {
              borderColor: purple,
            },
          },
        },
        emphasis: {
          iconStyle: {
            borderWidth: 2,
            borderColor: purple,
            textBackgroundColor: purple,
            textFill: '#FFFFFF',
            textBorderRadius: [5, 5, 5, 5],
            textPadding: [6, 6, 4, 6],
          },
        },
      },
      dataset: (barConfig && barConfig.dataset) || null,
      xAxis: {
        type: 'category',
        axisLabel: {
          interval: 0,
          fontWeight: 'bolder',
          color: grayShades.g800,
          rotate: this.props.data && this.props.data.length > 10 && 35,
        },
        triggerEvent: true,
        axisLine: {
          show: false,
        },
        data: data && data.map(item => (item.criteriaKeyLabel.length > 0 ? item.criteriaKeyLabel : t('Unknown'))),
      },
      yAxis: [
        {
          type: 'value',
          min: data && badgeConfig && badgeConfig.min,
          max: data && badgeConfig && badgeConfig.max,
          axisLabel: {
            show: !barConfig,
          },
          axisTick: {
            show: false,
          },
          axisLine: { show: !barConfig },
          splitLine: {
            show: !barConfig,
          },
        },
        barConfig
          ? {
              type: 'value',
              min: data && barConfig && barConfig.min,
              position: 'left',
              max: data && barConfig && barConfig.max,
              axisLabel: {
                formatter: '{value}',
              },
              axisTick: { show: false },
              axisLine: { show: false },
            }
          : {},
      ],
      dataZoom: [
        {
          show: true,
          start: this.props.datazoom && this.props.datazoom.start,
          end: this.props.datazoom && this.props.datazoom.end,
          showDataShadow: false,
          showDetail: false,
          backgroundColor: grayShades.g300,
          fillerColor: '#6f3ac7',
          dataBackgroundColor: 'transparent',
          height: 36,
          handleIcon: `image://${grabberGraph}`,
          handleSize: 24,
          handleStyle: {
            shadowColor: opacity.black,
            shadowBlur: 5,
          },
        },
      ],
      series: [
        {
          type: 'graph',
          name: badgeType === FILTERS_DIMENSIONS.EINDEX ? 'E-index' : this.props.t(upperFirst(badgeType)),
          layout: 'none',
          coordinateSystem: 'cartesian2d',
          symbolSize: 32,
          itemStyle: {
            borderWidth: 4,
            borderColor: white,
          },
          label: data && badgeConfig && badgeConfig.label,
          data: data && badgeConfig && badgeConfig.data,
          z: 2,
        },
        {
          name: this.props.t(upperFirst(barType)),
          type: 'bar',
          data: barConfig && barConfig.data,
          z: 1,
          yAxisIndex: 1,
          xAxisIndex: 0,
          barMaxWidth: 24,
          itemStyle: barConfig && barConfig.itemStyle,
        },
        ...(emotionsBars || []),
      ],
    };
    options.series =
      barType === FILTERS_DIMENSIONS.EMOTIONS
        ? options.series.filter(option => option.name !== this.props.t('Emotions'))
        : options.series;
    return options;
  };

  onDataclick = data => {
    const cardFilters = {
      projectId: this.props.filters.projectId,
      range: this.props.filters.range,
      thematics: this.props.filters.thematics,
      criteriaKeys: [
        ...this.props.filters.criteriaKeys,
        {
          name: this.props.filters.selectedCriteriaKey,
          values: [data.name || data.value],
        },
      ],
      keywords: this.props.filters.keywords,
      mentions: this.state.data[data.dataIndex].mentions,
    };
    this.props.setCardFilters(cardFilters);
    this.props.setCardTitle(data.name || data.value);
    this.props.toggleDisplaySideCard();
  };

  onEvents() {
    return {
      click: this.onDataclick,
    };
  }

  render() {
    const { data } = this.state;
    const options = this.getOption(data);
    return (
      <div style={{ height: '100%', width: '100%', margin: 0 }}>
        {ReactEcharts ? (
          <ReactEcharts
            option={options}
            style={{ height: this.props.height, width: '100%' }}
            className="react_for_echarts"
            theme="QemotionTheme"
            onEvents={this.onEvents()}
            ref={e => {
              this.echartsInstance = e;
            }}
          />
        ) : (
          ' '
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    eindexUnitFahrenheit: get(state, ['projectConfiguration', 'unitsAndLocalesSettings', 'eindexUnitFahrenheit']),
  };
}

export default connect(mapStateToProps)(withTranslation('card', ['graph'])(DataByFiltersGraph));
