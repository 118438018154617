import gql from 'graphql-tag';

const query = gql`
  query getAlertConfigurationList($projectId: Int!) {
    getAlertConfigurationList(projectId: $projectId) {
      active
      alertConfigurationId
      alertConfigurationName
      conditions
      emails
      projectId
      frequency
    }
  }
`;

export default query;
