import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themeColors } from 'styles/abstracts/colors';
import { getFontSizeStyles } from 'styles/abstracts/typography';

const SubTitle = styled.p`
  text-align: center;
  font-weight: bold;
  color: ${themeColors.secondary} ${getFontSizeStyles(14)};
`;

const MentionText = props => {
  const { t } = props;
  return (
    <SubTitle>
      {`${props.data.mentionCount.value}
    ${t('mention', {
      count: parseInt(props.data.mentionCount.value),
    })}`}
    </SubTitle>
  );
};

export default withTranslation('card')(MentionText);
