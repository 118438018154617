import React, { Fragment } from 'react';
import TemplateInfos from './_Components/TemplateInfos';
import TemplateOptions from './_Components/TemplateOptions';
import ReportsList from './_Components/ReportsList/ReportsList';

class TemplatesRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      showReports: false,
    };

    this.toggleShowReports = this.toggleShowReports.bind(this);
  }

  handleMouseEnter() {
    this.setState({
      isHovered: true,
    });
  }

  handleMouseLeave() {
    this.setState({
      isHovered: false,
    });
  }

  toggleShowReports() {
    this.setState({
      showReports: !this.state.showReports,
    });
  }

  render() {
    const { data } = this.props;
    return (
      <Fragment>
        <tr
          onMouseEnter={() => this.handleMouseEnter()}
          onMouseLeave={() => this.handleMouseLeave()}
        >
          <TemplateInfos data={data} />
          <TemplateOptions
            showReports={this.state.showReports}
            isHovered={this.state.isHovered}
            toggleShowReports={this.toggleShowReports}
            data={data}
          />
        </tr>
        <tr>
          <ReportsList data={data} showReports={this.state.showReports} />
        </tr>
      </Fragment>
    );
  }
}

export default TemplatesRow;
