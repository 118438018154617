import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import QueryFiltersHeader from '_Resources/Header/Services/api/QueryFiltersHeader';
import QueryConfigurationHeader from '_Resources/Header/Services/api/QueryConfigHeader';
import QueryProjectsHeader from '_Resources/Header/Services/api/QueryProjectsHeader';
import QueryDatesHeader from '_Resources/Header/Services/api/QueryDatesHeader';
import QueryMentionsHeader from '_Resources/Header/Services/api/QueryMentionsHeader';
import HeaderTop from '_Layouts/Header/_Containers/HeaderTop/HeaderTop';
import { white, orange, green } from 'styles/abstracts/colors';
import HeaderCenter from '_Layouts/Header/_Containers/SubHeader/SubHeader';
import {
  getHeaderTopHeight,
  getMainFiltersContainerHeight,
  getCollapsibleFiltersContainerHeight,
  getOptionalHeaderHeight,
  getFreeTrialStripHeight,
  getAlertStripHeight,
} from '_Layouts/Header/_Utils/HeaderUtils';
import { fontSizes } from 'styles/abstracts/typography';
import ConfirmEmailStrip from './_Components/ConfirmEmailStrip';

const HeaderContainer = styled.div`
  color: ${white};
  position: fixed;
  z-index: 2;
  width: 100%;
  height: ${({ isSmall }) => (isSmall ? '64px' : '136px')};
  ${({ idpage }) =>
    idpage === 'thematics-by-emotions' &&
    `
    height: 224px
  `};
`;

const StyledHeaderCenter = styled(HeaderCenter)`
  ${({ hidden }) => hidden && `display: none;`}
`;

const FreeTrialStrip = styled.div`
  background-color: ${green};
  color: ${white};
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 0.5em;
`;

const StyledLink = styled(Link)`
  color: ${white};
  text-decoration: underline;

  &:hover {
    color: ${white};
  }
`;

class Header extends React.Component {
  static propTypes = {
    hideSubHeader: PropTypes.bool,
  };

  static defaultProps = {
    hideSubHeader: false,
    subHeader: <StyledHeaderCenter />,
  };

  constructor(props) {
    super(props);

    this.state = {
      collapsedFilters: true,
      collapsedInfo: false,
      firstLoad: true,
    };

    this.toggleFilters = this.toggleFilters.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentDidMount() {
    this.setState({
      collapsedFilters: true,
      collapsedInfo: false,
    });
    this.setHeaderHeight(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.idpage !== prevProps.idpage) {
      this.setState({
        collapsedFilters: true,
        ...this.state,
      });
      this.setHeaderHeight(false);
      this.setState({ firstLoad: prevProps.idpage === undefined });
    } else {
      this.setHeaderHeight(!this.state.collapsedFilters);
    }
  }

  toggleFilters() {
    this.setState({
      collapsedFilters: !this.state.collapsedFilters,
    });
    this.setHeaderHeight(this.state.collapsedFilters);
  }

  toggleInfo() {
    this.setState({
      collapsedInfo: !this.state.collapsedInfo,
    });
    this.setHeaderHeight(this.state.collapsedInfo);
  }

  setHeaderHeight(isOpened) {
    const isOpenedHeight =
      getHeaderTopHeight() +
      getMainFiltersContainerHeight() +
      getCollapsibleFiltersContainerHeight() +
      getOptionalHeaderHeight();

    const isClosedHeight =
      getHeaderTopHeight() +
      getMainFiltersContainerHeight() +
      getOptionalHeaderHeight();

    if (isOpened) {
      document.getElementById('bodyLayout').style.top = `${isOpenedHeight}px`;
    } else {
      document.getElementById('bodyLayout').style.top = `${isClosedHeight}px`;
    }
    document.getElementById('bodyLayout').style.transition =
      'all .25s ease-in-out';
  }

  render() {
    const {
      hideSubHeader,
      currentUser,
      idpage,
      currentProject,
      filterKeys,
      additionalSubHeader,
      allowCollapse,
      subHeader,
      resetTour,
      action,
      fromLogin,
      hideMentionsCount,
      client,
      projectList,
      additionalButtons,
    } = this.props;
    return (
      <HeaderContainer
        isSmall={hideSubHeader}
        id="headerContainer"
        idpage={idpage}
        onLoad={() =>
          this.setHeaderHeight(!this.state.collapsedFilters)
        }
      >
        {(action !== 'PUSH' || fromLogin === true) &&
          currentUser &&
          currentUser.id && (
            <QueryProjectsHeader firstLoad={this.state.firstLoad} />
          )}
        {currentProject && currentProject.id && (
          <>
            <QueryDatesHeader />
            <QueryFiltersHeader />
            <QueryConfigurationHeader />
          </>
        )}
        {filterKeys && filterKeys.projectId && <QueryMentionsHeader />}
        <HeaderTop
          resetTour={resetTour}
          replay={this.props.replay}
          setReplay={this.props.setReplay}
          location={this.props.location}
          hideMentionsCount={hideMentionsCount}
          title={this.props.title}
          titleIcon={this.props.titleIcon}
        />
        {subHeader !== null &&
          React.cloneElement(subHeader, {
            hidden: hideSubHeader,
            additionalSubHeader,
            allowCollapse,
            collapsedFilters: this.state.collapsedFilters,
            collapsedInfo: this.state.collapsedInfo,
            toggleFilters: this.toggleFilters,
            toggleInfo: this.toggleInfo,
            setCollapseHeight: status => this.setHeaderHeight(status),
            idpage: this.props.idpage,
            additionalButtons,
          })}
        {currentUser.hasEmailValidated === false && <ConfirmEmailStrip />}
        {this.props.location.pathname === '/main' &&
          currentUser.hasEmailValidated &&
          currentUser.account &&
          currentUser.account.planName &&
          currentUser.account.planName !== 'emotion_corporate' &&
          projectList.filter(pro => !pro.demo).length === 0 && (
            <FreeTrialStrip id="freeTrialStrip">
              {this.props.t('Free trial')}:{' '}
              {this.props.t(
                'Start a free project with 200 free comments or reviews to test the solution',
              )}
              .{' '}
              <StyledLink to="/project-configuration">
                {this.props.t('button:Create a new project')}
              </StyledLink>
            </FreeTrialStrip>
          )}
      </HeaderContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    idpage: state.routes.subRoute && state.routes.subRoute.id,
    currentUser: state.auth.user,
    currentProject: state.projects.currentProject,
    filterKeys: state.filters.filterKeys,
    projectList: state.projects.projectsList,
  };
}

export default connect(mapStateToProps)(
  withTranslation('header', 'button')(Header),
);
