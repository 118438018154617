import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import QPanel from '_Components/Panels/QPanel/QPanel';
import DateSettings from './_Components/DateSettings';
import SatAndRecoMarkSettings from './_Components/SatAndRecoMarkSettings';
import LanguageSettings from './_Components/LanguageSettings';

const StyledRow = styled(Row)`
  margin-bottom: 1em !important;
`;

const DataConfiguration = ({
  settings,
  setSettings,
  selectedSources,
  lang,
  setLang,
  t,
}) => {
  const [preferDate, setPreferDate] = useState(settings?.preferDate || false);
  const [recoMark, setRecoMark] = useState(settings?.recoMark || null);
  const [satMark, setSatMark] = useState(settings?.satMark || 5);
  const [language, setLanguage] = useState(lang || 'en');


  useEffect(() => {
    const settingsToSend = {
      satDenominator: satMark,
    };
    setSettings(settingsToSend);
    setLang(language);
  }, [preferDate, recoMark, satMark, language]);
  return (
    <>
      <StyledRow>
        <Col xs={12}>
          <QPanel>
            <LanguageSettings lang={language} setLang={setLanguage} t={t} />
          </QPanel>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={12}>
          <QPanel>
            <DateSettings
              preferDate={preferDate}
              setPreferDate={setPreferDate}
              t={t}
            />
          </QPanel>
        </Col>
      </StyledRow>
      <Row>
        <Col xs={6}>
          <SatAndRecoMarkSettings
            setSatMark={setSatMark}
            satMark={satMark}
            selectedSources={selectedSources}
            t={t}
          />
        </Col>
        {/* <Col xs={6}> */}
        {/*  <SatAndRecoMarkSettings */}
        {/*    setRecoMark={setRecoMark} */}
        {/*    recoMark={recoMark} */}
        {/*    selectedSources={selectedSources} */}
        {/*    isRecoCard */}
        {/*  /> */}
        {/* </Col> */}
      </Row>
    </>
  );
};

export default DataConfiguration;
