import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import QDropdownMenu, { QDropdownMenuItem } from '_Components/Menus/QDropdownMenu/QDropdownMenu';
import QDropdownMenuToggle from '_Components/Menus/QDropdownMenu/QDropdownMenuToggle';
import QDropdown from '_Components/Menus/QDropdownMenu/QDropdown';
import Icon from '_Components/Icons/Icon';
import { fontSizes } from 'styles/abstracts/typography';
import { grayShades } from 'styles/abstracts/colors';

const Menu = styled(QDropdownMenu)`
  z-index: 1 !important;
  min-width: 8.5em;
  margin-left: -1rem !important;
`;

const Item = styled(QDropdownMenuItem)`
  font-size: ${fontSizes.extraSmall};
  font-weight: bold;
  padding: 1em;
`;

const DropdownSettings = ({ history, projectId, slug, t, isModalOpen, openModal }) => {
  const [isOpen, toggleOpen] = useState(false);
  return (
    <QDropdown>
      <QDropdownMenuToggle toggle={() => toggleOpen(!isOpen)} isOpen={isOpen} index={projectId}>
        <Icon icon="INTERFACE_DOTS_VERTICAL" color={grayShades.g800} size={24} />
      </QDropdownMenuToggle>
      <Menu isOpen={isOpen} placement="right" index={projectId}>
        <Item onClick={() => history.push(`/project-details/${slug}`, { id: projectId })}>
          <Icon icon="INTERFACE_GEAR" /> {t('Project configuration')}
        </Item>
        <Item onClick={() => openModal(!isModalOpen)}>
          <Icon icon="DOWNLOAD" /> {t('button:Export data')}
        </Item>
      </Menu>
    </QDropdown>
  );
};

export default withRouter(DropdownSettings);
