import React, { useState, useEffect } from 'react';
import CQuery from '_Container/QQuery/CQuery';
import {
  flat,
  getGraphToDisplay,
  getPieChartData,
} from '_Cards/ThematicsCard/ThematicsOverviewCards/CategoryBreakdownCard/utils';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import _, { get } from 'lodash';
import { connect } from 'react-redux';
import querySchema from '../../graphql/getThematicsOverviewData';
import { setActiveBenchmarkLegend } from '_Resources/Benchmark/Actions/setActiveBenchmarkLegend';
import { setOtherCategories } from '_Resources/Benchmark/Actions/setOtherCategories';
import { setGraphThematics } from '_Resources/Benchmark/Actions/setGraphThematics';

const GraphContainer = styled.div`
  height: 35em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type LegendIndexPropsType = {
  legend: {
    [key: number]: string;
  };
  name: string;
};

export const CategoryBreakdownSectionView = ({
    data: getThematicsOverviewData,
    filterKeys,
    t,
    thematicsUnifiedLegend,
    dispatchSetActiveBenchmarkLegend,
    dispatchSetOtherCategories,
    graphThematics,
    dispatchSetGraphThematics,
    columns,
    column,
    benchmarkLevelToDisplay,
    benchmarkOnlySelectedCategories,
  }) => {

    const [riverChartDateType, setRiverChartDateType] = useState<
      'day' | 'week' | 'month'
    >('week');

    const [pieChartGraph, setPieChartGraph] = useState();

    const {
      thematics,
      verbatimsWithoutThematicCount,
    } = getThematicsOverviewData;

    const flattenData = (thematics: any) => {
      return flat(thematics)
        .map((them: any) => {
          if (them.subThematics) {
            return them;
          }
          if (typeof them.subSubThematics !== 'undefined') {
            return {
              ...them,
              thematic: thematics.find((them: any) =>
                them.subThematics.find(
                  (sub: any) =>
                    sub?.thematicsOverviewDetails ===
                    them?.thematicsOverviewDetails,
                ),
              )?.thematicsOverviewDetails?.name,
            };
          }
          if (!them.subThematics && !them.subSubThematics) {
            const thematic = thematics.find((them: any) =>
              them.subThematics?.find((sub: any) =>
                sub.subSubThematics?.find(
                  (subsub: any) =>
                    subsub?.thematicsOverviewDetails ===
                    them?.thematicsOverviewDetails,
                ),
              ),
            );
            return {
              ...them,
              thematic: thematic?.thematicsOverviewDetails?.name,
              subThematic: thematic?.subThematics?.find((sub: any) =>
                sub?.subSubThematics?.find(
                  (subsub: any) =>
                    subsub?.thematicsOverviewDetails ===
                    them?.thematicsOverviewDetails,
                ),
              )?.thematicsOverviewDetails?.name,
            };
          }
        })
        .filter(
          them =>
            them.thematicsOverviewDetails &&
            them.thematicsOverviewDetails.isSelected,
        );
    };

    const getLegendIndex = ({ legend, name }: LegendIndexPropsType): string => {
      function isLegend(legend: LegendIndexPropsType["legend"]): legend is LegendIndexPropsType["legend"] {
        let keysOk: boolean;
        let valuesOk: boolean;
        if (typeof legend === "object") {
          keysOk = Object.keys(legend).every(key => (!isNaN(key) && typeof parseInt(key) === "number"));
          valuesOk = Object.values(legend).every(value => typeof value === "string");
          return keysOk && valuesOk;
        };
        return false;
      }
      if (isLegend(legend)) {
        const legendIndex = Object.keys(legend).find(key => legend[key] === name);
        if (legendIndex) return legendIndex;
        return t(name);
      }
      return "legend is not valid";
    };

    const captionThematics = (thematics: Array<Object>): Array<Object> => {
      let captionedThematics: any[] = [];
      thematics?.forEach((thematic) => {
        let captionedThematicsOverviewDetails = { ...thematic.thematicsOverviewDetails, index: getLegendIndex({legend: thematicsUnifiedLegend, name: t(thematic.thematicsOverviewDetails.name)})};
        let captionedThematic = { ...thematic, thematicsOverviewDetails: captionedThematicsOverviewDetails };
        captionedThematics.push(captionedThematic);
      });
      return captionedThematics;
    };

    const flattenedData = flattenData(thematics);

    useEffect(() => {
      const graphCategories = getPieChartData(
        benchmarkOnlySelectedCategories, // onlySelectedCategories,
        thematics, // rawData: any,
        flattenedData, // data: any,
        benchmarkLevelToDisplay, // level,
        null, // subFilterToDisplay: string | null,
        'mentionCount', // filterToDisplay: string,
        verbatimsWithoutThematicCount, // verbatimsWithoutThematicCount: number,
        t, // t: (arg0: string) => any,
        undefined, // commentsWithoutCategoriesLegendIndex: string | undefined,
        true, // isCaptionedData: boolean,
        )

      const graphConcatThematics = graphCategories.map((graphCategory: any) => {
        const { name, value } = graphCategory;
        return { name, value };
      });

      dispatchSetGraphThematics(graphConcatThematics);
      dispatchSetActiveBenchmarkLegend({});

    }, [thematics, benchmarkOnlySelectedCategories, benchmarkLevelToDisplay]);

    useEffect(() => {
      const isOtherCategory = (value: number) => value < 0.5;
      const otherCategories = graphThematics.filter((thematic: any) => thematic.values.every(isOtherCategory));

      dispatchSetOtherCategories(otherCategories);

    }, [graphThematics])

    useEffect(() => {
      const updatedFlattenedData = flattenData(thematics);

      const captionedFlattenedData = captionThematics(updatedFlattenedData);

      let commentsWithoutCategoriesLegendIndex = '';

      if (thematicsUnifiedLegend && typeof thematicsUnifiedLegend === 'object') {
        commentsWithoutCategoriesLegendIndex = (Object.keys(thematicsUnifiedLegend).findIndex((key) => (thematicsUnifiedLegend[key] === t('Comments without categories')))+1).toString();
      }

      const pieChartGraphToUpdate = getGraphToDisplay(
        'pie',
        benchmarkOnlySelectedCategories, // onlySelectedCategories,
        thematics, // rawData,
        captionedFlattenedData, // data,
        benchmarkLevelToDisplay, // level,
        null, // subFilterToDisplay
        'mentionCount', // filterToDisplay,
        verbatimsWithoutThematicCount,
        filterKeys,
        [], // riverChartData
        'week', // riverChartDateType
        setRiverChartDateType, // function to set riverChartDateType parameter
        t,
        false, // queryLoading,
        commentsWithoutCategoriesLegendIndex, // commentsWithoutCategoriesLegendIndex = legend index of comments without categories,
        true, // isBenchmark,
        true, // isCaptionedData
        thematicsUnifiedLegend,
        columns,
        column,
      );
      setPieChartGraph(pieChartGraphToUpdate);

    }, [thematicsUnifiedLegend, thematics, benchmarkLevelToDisplay, benchmarkOnlySelectedCategories]);

    return <GraphContainer>{pieChartGraph}</GraphContainer>;
  };

const CategoryBreakdownSection = ({
    filters,
    noDataComponent,
    errorComponent,
    t,
    thematicsUnifiedLegend,
    dispatchSetActiveBenchmarkLegend,
    dispatchSetOtherCategories,
    graphThematics,
    dispatchSetGraphThematics,
    columns,
    column,
    benchmarkLevelToDisplay,
    benchmarkOnlySelectedCategories,
    ...props
  }) => (
    <CQuery
      query={querySchema}
      filterKeys={filters}
      noDataComponent={noDataComponent}
      errorComponent={errorComponent}
    >
      <CategoryBreakdownSectionView
        filterKeys={filters}
        t={t}
        thematicsUnifiedLegend = {thematicsUnifiedLegend}
        dispatchSetActiveBenchmarkLegend = {dispatchSetActiveBenchmarkLegend}
        dispatchSetOtherCategories = {dispatchSetOtherCategories}
        graphThematics={graphThematics}
        dispatchSetGraphThematics={dispatchSetGraphThematics}
        columns={columns}
        column={column}
        benchmarkLevelToDisplay={benchmarkLevelToDisplay}
        benchmarkOnlySelectedCategories={benchmarkOnlySelectedCategories}
        {...props}
        />
    </CQuery>
  );
const mapStateToProps = (state: any) => ({
  thematicsUnifiedLegend: get(state, [
    'benchmark',
    'thematicsUnifiedLegend',
  ]),
  graphThematics: get(state, [
    'benchmark',
    'graphThematics',
  ]),
  benchmarkLevelToDisplay: get(state, ['benchmark', 'levelToDisplay']),
  benchmarkOnlySelectedCategories: get(state, [
    'benchmark',
    'onlySelectedCategories',
  ]),
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetActiveBenchmarkLegend: (newState: any) => {
    dispatch(setActiveBenchmarkLegend(newState));
  },
  dispatchSetOtherCategories: (newState: any) => {
    dispatch(setOtherCategories(newState));
  },
  dispatchSetGraphThematics: (newState: any) => {
    dispatch(setGraphThematics(newState));
  },
});

export default withTranslation('card')(
  connect(mapStateToProps, mapDispatchToProps)(CategoryBreakdownSection),
);
