import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get, groupBy, flatten } from 'lodash';
import is from 'is_js';
import QModal from '_Components/Modals/QModal/QModal';
import QModalBody from '_Components/Modals/QModalBody/QModalBody';
import QButton from '_Components/Buttons/QButton/QButton';
import { AlertType } from '_Cards/AlertsCards/AlertingCard/_Components/AlertingRow/AlertingRow';
import QInput from '_Components/Fields/QInput/QInput';
import QTags from '_Components/Fields/QTags/QTags';
import QSelect from '_Components/Fields/QSelect/QSelect';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import CREATE_ALERT_CONFIGURATION_MUTATION from '_Cards/AlertsCards/AlertingCard/graphql/createAlertConfiguration';
import UPDATE_ALERT_CONFIGURATION_MUTATION from '_Cards/AlertsCards/AlertingCard/graphql/updateAlertConfiguration';
import GET_ALERT_CONFIGURATION_LIST_QUERY from '_Cards/AlertsCards/AlertingCard/graphql/getAlertConfigurationList';
import GET_ACCOUNT_USER_LIST_QUERY from '_Cards/SettingsCards/UserAndPermissionsCard/graphql/getAccountUserList';
import Condition from './_Components/Condition/Condition';
import SelectorDropdown from './_Components/SelectorDropdown/SelectorDropdown';
import { red } from '../../../../../styles/abstracts/colors';

const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 1.325em;
  margin-bottom: 0.5em;
`;

const ConditionPhrase = styled.p`
  font-weight: bold;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckboxGroup = styled.div`
  display: flex;
  place-content: center flex-start;
  align-items: center;
  padding-bottom: 0.5em;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: ${red};
  font-weight: bold;
`;

const FREQUENCY_OPTIONS = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

type CreateAlertModalProps = {
  isOpen: boolean;
  open: any;
  projectId: number;
  alert: AlertType | null;
  criteriaKeys: any;
  accountId: number;
  t: any;
  thematics: any;
  userEmail: string;
};

const CreateAlertModal = ({
  isOpen,
  open,
  projectId,
  criteriaKeys,
  alert = null,
  accountId,
  t,
  thematics,
  userEmail,
}: CreateAlertModalProps) => {
  const [name, setName] = useState<string>(alert?.alertConfigurationName || '');
  const [mainSelector, setMainSelector] = useState<'and' | 'or'>('or');
  const [secondarySelector, setSecondarySelector] = useState<'and' | 'or'>(
    'and',
  );
  const [conditions, setConditions] = useState<any>(
    (alert && JSON.parse(alert?.conditions)) || [null],
  );
  const [mainConditions, setMainConditions] = useState<any>(
    (alert &&
      JSON.parse(alert?.conditions)?.filter(
        cond => cond?.selector === mainSelector,
      )) || [null],
  );
  const [secondaryConditions, setSecondaryConditions] = useState<any>(
    (alert &&
      JSON.parse(alert?.conditions)?.filter(
        cond => cond?.selector === secondarySelector,
      )) || [null],
  );
  const [emails, setEmails] = useState<Array<string>>(
    alert?.emails || [userEmail],
  );
  const [frequency, setFrequency] = useState<string>(alert?.frequency || '');
  const [includeAllMails, setIncludeAllMails] = useState<boolean>(false);
  useEffect(() => {
    if (
      usersListData?.getAccountUserList
        ?.map(a => a.email)
        .filter(email => !emails.includes(email)).length > 0
    ) {
      setIncludeAllMails(false);
    }
  }, [emails]);

  useEffect(() => {
    if (alert) {
      setConditions(alert && JSON.parse(alert.conditions));
      setMainConditions(
        alert &&
          JSON.parse(alert.conditions)?.find(c => c.selector === mainSelector)
            ?.conditions,
      );
      setSecondaryConditions(
        alert &&
          JSON.parse(alert.conditions)?.find(
            c => c.selector === secondarySelector,
          )?.conditions,
      );
    }
  }, [alert]);

  useEffect(() => {
    if (conditions.filter(c => c).length > 0) {
      setMainConditions(conditions.filter(c => c?.selector === mainSelector));
      setSecondaryConditions(
        conditions.filter(c => c?.selector === secondarySelector),
      );
    }
  }, [conditions]);

  useEffect(() => {
    if (!mainConditions.length && secondaryConditions?.length) {
      setMainConditions(secondaryConditions);
      setSecondaryConditions([]);
      setMainSelector(secondarySelector);
      setSecondarySelector(mainSelector);
    }
  }, [mainConditions]);

  useEffect(() => {
    if (includeAllMails) {
      setEmails([
        ...emails,
        ...usersListData?.getAccountUserList?.map(a => a.email),
      ]);
    }
  }, [includeAllMails]);

  const { data: usersListData } = useQuery(GET_ACCOUNT_USER_LIST_QUERY, {
    variables: { accountId },
  });

  const [createAlertConfiguration] = useMutation(
    CREATE_ALERT_CONFIGURATION_MUTATION,
    {
      update: (cache, { data: { createAlertConfiguration: createAlert } }) => {
        let alertConfigurationList;
        try {
          alertConfigurationList = cache.readQuery({
            query: GET_ALERT_CONFIGURATION_LIST_QUERY,
            variables: { projectId },
          }).getAlertConfigurationList;
        } catch (e) {
          alertConfigurationList = [];
        }
        const newAlertConfigurationList = [
          ...alertConfigurationList,
          createAlert,
        ];
        cache.writeQuery({
          query: GET_ALERT_CONFIGURATION_LIST_QUERY,
          variables: { projectId },
          data: { getAlertConfigurationList: newAlertConfigurationList },
        });
      },
    },
  );

  const [updateAlertConfiguration] = useMutation(
    UPDATE_ALERT_CONFIGURATION_MUTATION,
    {
      update: (cache, { data: { updateAlertConfiguration: updateAlert } }) => {
        let alertConfigurationList;
        try {
          alertConfigurationList = cache.readQuery({
            query: GET_ALERT_CONFIGURATION_LIST_QUERY,
            variables: { projectId },
          }).getAlertConfigurationList;
        } catch (e) {
          alertConfigurationList = [];
        }
        const newAlertConfigurationList = alertConfigurationList?.map(config =>
          config.alertConfigurationId === updateAlert.alertConfigurationId
            ? updateAlert
            : config,
        );
        cache.writeQuery({
          query: GET_ALERT_CONFIGURATION_LIST_QUERY,
          variables: { projectId },
          data: { getAlertConfigurationList: newAlertConfigurationList },
        });
      },
    },
  );
  const footer = (
    <FooterContainer>
      <QButton onClick={() => open(!isOpen)}>{t('button:Return')}</QButton>
      <QButton
        disabled={
          !name ||
          !mainSelector ||
          !frequency ||
          mainConditions.filter(c => c).length === 0 ||
          emails.length === 0 ||
          !is.all.email(emails)
        }
        onClick={() => {
          if (!alert) {
            const conditionsToSend = Object.keys(
              groupBy(
                [
                  ...mainConditions.filter(c => c),
                  ...secondaryConditions.filter(c => c),
                ],
                c => c.selector,
              ),
            ).map(sel => ({
              selector: sel,
              conditions: groupBy(
                [
                  ...mainConditions.filter(c => c),
                  ...secondaryConditions.filter(c => c),
                ],
                c => c.selector,
              )[sel],
            }));
            createAlertConfiguration({
              variables: {
                projectId,
                frequency,
                conditions: JSON.stringify(
                  conditionsToSend
                    .filter(c => c)
                    .map(cond => ({
                      ...cond,
                      conditions: cond.conditions.map(c => ({
                        ...c,
                        name: c?.name?.label,
                      })),
                    })),
                ),
                emails,
                alertConfigurationName: name,
              },
            }).then(() => {
              setName('');
              setMainConditions([null]);
              setSecondarySelector('and');
              setMainSelector('or');
              setConditions([null]);
              setEmails([]);
              setFrequency('');
              setIncludeAllMails(false);
              open(!isOpen);
            });
          } else {
            updateAlertConfiguration({
              variables: {
                alertConfigurationId: alert?.alertConfigurationId,
                conditions: JSON.stringify(conditions.filter(cond => cond)),
                emails,
                frequency,
                alertConfigurationName: name,
                active: alert?.active,
              },
            }).then(() => {
              setName('');
              setMainConditions([null]);
              setSecondarySelector('and');
              setMainSelector('or');
              setConditions([null]);
              setEmails([]);
              setFrequency('');
              setIncludeAllMails(false);
              open(!isOpen);
            });
          }
        }}
      >
        {t('button:Confirm')}
      </QButton>
    </FooterContainer>
  );

  return (
    <QModal
      title={`${alert ? 'Edit' : 'Create an'} alert`}
      isOpen={isOpen}
      onClose={() => {
        setName('');
        setMainConditions([null]);
        setSecondarySelector('and');
        setMainSelector('or');
        setConditions([null]);
        setEmails([]);
        setFrequency('');
        setIncludeAllMails(false);
        open(!isOpen);
      }}
      size="xl"
      config={{ mentions: false, date: false }}
      hideFooter={false}
      footer={footer}
    >
      <QModalBody>
        <div>
          <SectionTitle>{t('Name of the alert')}</SectionTitle>
          {/* <br /> */}
          <QInput
            style={{ width: '80%' }}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <br />
        <div>
          <SectionTitle>{t('Conditions')}</SectionTitle>
          {/* <br /> */}
          <ConditionPhrase>
            {t('When')}{' '}
            <SelectorDropdown
              selector={mainSelector}
              setSelector={setMainSelector}
              alert={alert}
              setSecondarySelector={setSecondarySelector}
              t={t}
            />{' '}
            {t(`is fulfilled${mainSelector === 'and' ? '_plural' : ''}`)}:
          </ConditionPhrase>
          <br />
          {flatten(mainConditions?.map(cond => cond?.conditions))?.map(
            (cond, index) => {
              return (
                <Condition
                  criteriaKeys={criteriaKeys}
                  condition={cond}
                  setConditions={setMainConditions}
                  conditions={mainConditions}
                  index={index}
                  isAlert={!!alert}
                  selector={mainSelector}
                  t={t}
                  thematics={thematics}
                />
              );
            },
          )}
          {!alert && (
            <>
              {/* <br /> */}
              <div>
                <QButton
                  onClick={() => {
                    setMainConditions([...mainConditions, null]);
                  }}
                  disabled={mainConditions.filter(cond => cond === null).length}
                >
                  {t('Add')}
                </QButton>
              </div>
            </>
          )}
          {mainConditions.filter(c => c).length > 0 && (
            <>
              <br />
              <ConditionPhrase>
                {t('When')}{' '}
                <SelectorDropdown
                  selector={secondarySelector}
                  setSelector={setSecondarySelector}
                  alert={alert}
                  secondary
                  t={t}
                />{' '}
                {t(
                  `is fulfilled${secondarySelector === 'and' ? '_plural' : ''}`,
                )}
                :
              </ConditionPhrase>
              <br />
              {flatten(secondaryConditions?.map(cond => cond?.conditions))?.map(
                (cond, index) => {
                  return (
                    <Condition
                      criteriaKeys={criteriaKeys}
                      condition={cond}
                      setConditions={setSecondaryConditions}
                      conditions={secondaryConditions}
                      index={index}
                      isAlert={!!alert}
                      selector={secondarySelector}
                      t={t}
                      thematics={thematics}
                    />
                  );
                },
              )}
              {!alert && (
                <>
                  <QButton
                    onClick={() => {
                      setSecondaryConditions([...secondaryConditions, null]);
                    }}
                    disabled={
                      secondaryConditions.filter(cond => cond === null).length
                    }
                  >
                    {t('Add')}
                  </QButton>
                  <br />
                </>
              )}
            </>
          )}
          <br />
          <div>
            <SectionTitle>{t('Frequency')}</SectionTitle>
            <div style={{ width: '50%' }}>
              <QSelect
                onChange={option => setFrequency(option.value)}
                value={FREQUENCY_OPTIONS.map(fr => ({
                  ...fr,
                  label: t(`button:${fr.label}`),
                })).find(f => f.value === frequency)}
                options={FREQUENCY_OPTIONS.map(fr => ({
                  ...fr,
                  label: t(`button:${fr.label}`),
                }))}
                config={{ placeholder: t('Select') }}
              />
            </div>
          </div>
          <br />
          <div>
            <SectionTitle>{t('Email addresses')}</SectionTitle>
            <QTags
              initialTags={emails}
              onTagsChanged={newTags => setEmails(newTags)}
              key={emails.length.toString()}
            />
            {!is.all.email(emails) && (
              <ErrorMessage>
                {t('One of the given email addresses is not correct')}
              </ErrorMessage>
            )}
            <CheckboxGroup onClick={() => setIncludeAllMails(!includeAllMails)}>
              <QCheckbox selected={includeAllMails} />
              <QCheckbox.Label>
                {t("Include all account's email addresses")}
              </QCheckbox.Label>
            </CheckboxGroup>
          </div>
        </div>
      </QModalBody>
    </QModal>
  );
};

const mapStateToProps = (state: any) => ({
  criteriaKeys: get(state, [
    'filters',
    'projectFilters',
    'criteriaKeys',
    'normal',
  ]),
  thematics: get(state, ['filters', 'projectFilters', 'thematics']),
  accountId: get(state, ['auth', 'user', 'account', 'id']),
  userEmail: get(state, ['auth', 'user', 'email']),
});

export default withTranslation('card', ['button'])(
  connect(mapStateToProps)(CreateAlertModal),
);
