export const REMOVE_STAGE_SUCCESS = 'REMOVE_ALERTING_STAGE_SUCCESS';
export const REMOVE_STAGE_FAILURE = 'REMOVE_ALERTING_STAGE_FAILURE';

export const REMOVE_STAGE = 'REMOVE_ALERTING_STAGE';

const removeStageSuccess = ({ stageId, statusId }) => ({
  type: REMOVE_STAGE_SUCCESS,
  id: stageId,
  statusId,
});

const removeStageFailure = error => ({
  type: REMOVE_STAGE_FAILURE,
  error,
});

export const removeStage = removeCriteria => removeStageSuccess(removeCriteria);
