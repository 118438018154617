import gql from 'graphql-tag';

const checkOTP = gql`
  mutation checkOTP($email: String!, $otp: String!) {
    otp(email: $email, otp: $otp) {
      token
      exp
      user {
        id
        firstName
        lastName
        email
        isAdmin
        expireAt
        account {
          id
          companyName
          name
          apiToken
        }
        lang
        verbatimsLocale
        otpEnabled
      }
    }
  }
`;

export default checkOTP;
