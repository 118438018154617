import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep, filter, get } from 'lodash';

class GlobalFiltersValueProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thematicsInputTags: null,
      criteriaKeysInputTags: null,
    };
  }

  componentDidMount() {
    if (this.props.projectFilters) {
      this.getFilterListFromProjectID(this.props.projectFilters);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectFilters !== prevProps.projectFilters) {
      this.getFilterListFromProjectID(this.props.projectFilters);
    }
    if (this.props.projectFilters && prevProps.idPage !== this.props.idPage) {
      this.getFilterListFromProjectID(this.props.projectFilters);
    }
  }

  getFilterListFromProjectID(projectFilters) {
    this.setState({
      thematicsInputTags: null,
      criteriaKeysInputTags: null,
    });
    this.setCriteriaKeysInputTags(projectFilters.criteriaKeys);
    this.setThematicsInputTags(projectFilters.thematics);
  }

  setThematicsInputTags(data) {
    if (!data) return;

    // TODO replace with real configuration since BE is ready
    const hiddenFilters = get(this.props, [
      'pageConfiguration',
      'hiddenFilters',
      'thematic',
    ]);
    let dataToReturn = cloneDeep(data);

    if (hiddenFilters) {
      dataToReturn = filter(
        dataToReturn,
        thematic => !hiddenFilters.includes(thematic.id),
      );
    }
    const thematicsInputTags = Object.keys(dataToReturn).reduce(
      (obj, key) => {
        return { ...obj, [key]: dataToReturn[key] }
      },
      {},
    );

    this.setState({
      thematicsInputTags,
    });
  }

  setCriteriaKeysInputTags(data) {
    if (!data) return;

    const dataToReturn = cloneDeep(data);
    // TODO replace with real configuration since BE is ready
    const { hiddenFilters } = this.props.pageConfiguration;
    if (hiddenFilters && hiddenFilters.normal) {
      const normalData = data.normal;
      dataToReturn.normal = filter(
        normalData,
        criteria => !hiddenFilters.normal.includes(criteria.id),
      );
    }

    this.setState({
      criteriaKeysInputTags: dataToReturn,
    });
  }

  render() {
    return React.Children.map(this.props.children, (child, index) => {
      const propsToPass = {
        thematicsInputTags: this.state.thematicsInputTags,
        criteriaKeysInputTags: this.state.criteriaKeysInputTags,
      };
      return React.cloneElement(child, propsToPass);
    });
  }
}

function mapStateToProps(state) {
  return {
    idPage: state.routes.subRoute && state.routes.subRoute.id,
    defaultThematic: get(state, [
      'routes',
      'pageConfiguration',
      'defaultThematic',
    ]),
    pageConfiguration: get(state, ['routes', 'pageConfiguration']) || {},
    projectFilters: state.filters.projectFilters,
  };
}

export default connect(
  mapStateToProps,
  null,
)(GlobalFiltersValueProvider);
