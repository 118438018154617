import gql from 'graphql-tag';

const query = gql`
  query getThematicsOverviewByDateData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $dateType: DateInterval!
  ) {
    getThematicsOverviewByDateData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dateType: $dateType
    ) {
      settings
      thematics {
        date
        verbatimsWithoutThematicCount
        subThematics {
          subSubThematics {
            thematicsOverviewDetails {
              name
              eindex
              mentionCount
              emotions {
                happiness {
                  byMentionCount
                }
                surprise {
                  byMentionCount
                }
                calm {
                  byMentionCount
                }
                fear {
                  byMentionCount
                }
                sadness {
                  byMentionCount
                }
                anger {
                  byMentionCount
                }
                disgust {
                  byMentionCount
                }
              }
            }
          }
          thematicsOverviewDetails {
            name
            eindex
            mentionCount
            emotions {
              happiness {
                byMentionCount
              }
              surprise {
                byMentionCount
              }
              calm {
                byMentionCount
              }
              fear {
                byMentionCount
              }
              sadness {
                byMentionCount
              }
              anger {
                byMentionCount
              }
              disgust {
                byMentionCount
              }
            }
          }
        }
        thematicsOverviewDetails {
          name
          eindex
          mentionCount
          emotions {
            happiness {
              byMentionCount
            }
            surprise {
              byMentionCount
            }
            calm {
              byMentionCount
            }
            fear {
              byMentionCount
            }
            sadness {
              byMentionCount
            }
            anger {
              byMentionCount
            }
            disgust {
              byMentionCount
            }
          }
        }
      }
    }
  }
`;

export default query;
