import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import '../../../../node_modules/react-resizable/css/styles.css';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import { get } from 'lodash';
import { getRoute } from '_Utils/routes/routeUtils';
import NoConfigPage from '_Components/NoConfigPage/NoConfigPage';
import imgGraphsPlace from '_Components/NoConfigPage/images/synthetic-place.gif';
import imgVerbatim from '_Components/NoConfigPage/images/synthetic-verbatim.gif';
import { getComponentsLayout } from '../../_Utils/routeComponent';
import QResponsiveGridLayout from '../../../_Layouts/QResponsiveGridLayout/QResponsiveGridLayout';
import layoutConfig from './config';

const StyledContainer = styled(Container)`
  overflow-x: hidden;
`;

class SyntheticViewGeneric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idpage: this.props.idpage,
      layout: layoutConfig,
      layout_edit: null,
      associatedThematic: null,
      defaultThematicFilter: null,
    };
  }

  componentDidMount() {
    getRoute(
      this.props.updateRoute,
      this.state.idpage,
      this.props.thematic,
      this.props.projectConfiguration,
    );
    if (this.props.filterKeys) {
      this.setFilterKeys(this.props.filterKeys);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.idpage && this.props.idpage !== prevProps.idpage) ||
      prevProps.projectConfiguration !== this.props.projectConfiguration
    ) {
      getRoute(
        prevProps.updateRoute,
        this.props.idpage,
        this.props.thematic,
        this.props.projectConfiguration,
      );
      this.setState({
        idpage: this.props.idpage,
        associatedThematic: null,
      });
    }

    if (
      this.props.pageConfiguration &&
      prevProps.pageConfiguration &&
      this.props.pageConfiguration.defaultThematic !==
        prevProps.pageConfiguration.defaultThematic
    ) {
      if (this.props.pageConfiguration.defaultThematic !== null) {
        this.setState(
          {
            associatedThematic: this.props.pageConfiguration.defaultThematic,
          },
          () => {
            this.props.setGlobalQueryFilterKeys(
              this.setFilterKeys(this.props.filterKeys),
            );
          },
        );
      } else {
        this.setState({ associatedThematic: null });
      }
    }

    if (
      (this.props.filterKeys &&
        this.props.filterKeys !== prevProps.filterKeys) ||
      prevProps.projectConfiguration !== this.props.projectConfiguration
    ) {
      this.setFilterKeys(this.props.filterKeys);
    }
  }

  setFilterKeys(filterKeys) {
    if (!filterKeys) return;
    const keys = JSON.parse(JSON.stringify(filterKeys));
    const defaultThematic = get(this.props, [
      'pageConfiguration',
      'defaultThematic',
    ]);
    if (defaultThematic) {
      const defaultThematicFilter = {
        thematic: defaultThematic,
        subThematic: '',
        subSubThematic: '',
      };
      keys.thematics = [];
      keys && keys.thematics && keys.thematics.push(defaultThematicFilter);
      this.setState({
        associatedThematic: defaultThematic,
        filterPage: keys,
        defaultThematicFilter,
      });
    } else {
      this.setState({
        associatedThematic: null,
        defaultThematicFilter: null,
      });
    }
    return keys;
  }

  getTitleRadarCard(comp) {
    if (this.props.thematic && comp.i === 'RadarAndHorizBarCard') {
      switch (this.props.thematic) {
        case 'people':
          comp.data.title = 'Staff skills assessment';
          break;
        case 'place':
          comp.data.title = 'Place key topics';
          break;
        case 'product':
          comp.data.title = 'Product theme';
          break;
        default:
          comp.data.title = 'Thematics e-index overview';
          break;
      }
    }
  }

  render() {
    const {
      layout,
      associatedThematic,
      filterPage,
      defaultThematicFilter,
    } = this.state;
    const props = {
      MentionPreviewPerEmotions: {
        filters: filterPage,
        defaultThematicFilter,
        lazyLoad: true,
      },
    };

    const layoutComponents = getComponentsLayout(
      layout,
      props,
      { filters: filterPage },
      args => this.getTitleRadarCard(args),
    );
    const noConfigContent = {
      noConfigMainMessage: {
        message: 'DEFAULT_MAIN_MESSAGE',
        image: null,
      },
      noConfigContent: [
        {
          image: imgGraphsPlace,
          title: 'SYNTHETIC_VIEW_01_TITLE',
          description: 'SYNTHETIC_VIEW_01_CONTENT',
          descriptionVars: { moduleSubject: 'Votre personnel' },
          titleVars: {
            moduleSubject: `MODULE_EXPLANATION_${this.props.thematic.toUpperCase()}`,
          },
        },
        {
          image: imgVerbatim,
          title: 'SYNTHETIC_VIEW_02_TITLE',
          description: 'SYNTHETIC_VIEW_02_CONTENT',
          descriptionVars: { moduleSubject: 'Votre personnel' },
          titleVars: {
            moduleSubject: `MODULE_EXPLANATION_${this.props.thematic.toUpperCase()}`,
          },
        },
        // {
        //   image: imgGraphs,
        //   title: 'MAP_02_TITLE',
        //   description: 'MAP_02_CONTENT',
        // },
      ],
      noConfigEndMessage: {
        message: 'DEFAULT_LAST_MESSAGE',
      },
    };
    return layout && associatedThematic !== null ? (
      <div>
        <StyledContainer fluid className="py-3 px-4">
          <QResponsiveGridLayout
            layout={layout}
            isResizable={this.props.editableLayout}
          >
            {layoutComponents}
          </QResponsiveGridLayout>
        </StyledContainer>
      </div>
    ) : (
      <div>
        <Container fluid className="py-5 px-4">
          <NoConfigPage config={noConfigContent} />
        </Container>
      </div>
    );
  }
}

SyntheticViewGeneric.defaultProps = {
  layoutDefault: layoutConfig,
  editableLayout: false,
};

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
    editableLayout: state.routes.activeLayoutEdition,
    module: state.routes.route && state.routes.route.name,
    projectConfiguration: state.projectConfiguration,
    pageConfiguration: state.routes.pageConfiguration,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
    setGlobalQueryFilterKeys: newState => {
      dispatch(setGlobalQueryFilterKeys(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('text')(SyntheticViewGeneric));
