import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';
import { get, flatten, cloneDeep } from 'lodash';
import GET_THEMATICS_OVERVIEW_DATA from '_Cards/ThematicsCard/ThematicsOverviewCards/graphql/getThematicsOverviewData';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { getRoute } from '_Utils/routes/routeUtils';
import CategoryHierarchyCard from '_Cards/ThematicsCard/ThematicsOverviewCards/CategoryHierarchyCard/CategoryHierarchyCard';
import CategoryRankingCard from '_Cards/ThematicsCard/ThematicsOverviewCards/CategoryRankingCard/CategoryRankingCard';
import EmotionsTriggersCard from '_Cards/ThematicsCard/ThematicsOverviewCards/EmotionsTriggersCard/EmotionsTriggersCard';
import DataByThematics from '_Cards/ThematicsCard/ThematicsOverviewCards/DataByThematics/DataByThematics';
import CategoryBreakdownCard from '_Cards/ThematicsCard/ThematicsOverviewCards/CategoryBreakdownCard/CategoryBreakdownCard';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import styled from 'styled-components';
import { flat } from '_Cards/ThematicsCard/ThematicsOverviewCards/CategoryBreakdownCard/utils';
import ThematicsHorizontalChart from '_Cards/ThematicsCard/ThematicsOverviewCards/ThematicsHorizontalChartCard/ThematicsHorizontalChart';

const StyledContainer = styled(Container)`
  height: 52rem;
`;

const ThematicsOverview = ({
  idpage,
  dispatchUpdateRoute,
  currentTab,
  filterKeys,
  isDetailsToggled,
  message,
  currentGraphView,
  exportLink,
  toTab,
}) => {
  const [data, setData] = useState([]);
  const [settings, setSettings] = useState({});
  const [allCategoriesHierarchy, setAllCategoriesHierarchy] = useState([]);
  const [queryFilters, setQueryFilters] = useState({});

  const { data: thematicsOverviewData, refetch, loading } = useQuery(GET_THEMATICS_OVERVIEW_DATA, {
    variables: queryFilters,
  });

  useEffect(() => {
    getRoute(dispatchUpdateRoute, idpage);
  }, []);

  useEffect(() => {
    const filters = cloneDeep(filterKeys);
    filters.keywords = flatten(filterKeys.texts?.filter(t => t.name === 'keywords').map(t => t.values)) || [];
    delete filters.texts;
    setQueryFilters(filters);
  }, [filterKeys]);

  let formattedData = [];

  const formatDeep = (arrayToIterate, level, objToFill) => {
    let newObjToFill = {};
    arrayToIterate?.map(item => {
      const childrenKey = Object.keys(item).find(i => Array.isArray(item[i]));
      newObjToFill = {
        ...objToFill,
        [level]: item.thematicsOverviewDetails.name,
      };
      formattedData = [...formattedData, newObjToFill];
      if (Array.isArray(item[childrenKey])) {
        formatDeep(
          item[childrenKey],
          childrenKey?.toLowerCase()?.substring(0, childrenKey.length - 1),
          newObjToFill,
          formattedData,
        );
      }
    });
    return formattedData;
  };

  const categoriesHierarchies = formatDeep(
    thematicsOverviewData?.getThematicsOverviewData?.thematics,
    'thematic',
    {},
  ).map(item => {
    if (!item.subthematic) {
      return { ...item, subthematic: null, subsubthematic: null };
    }
    if (!item.subsubthematic) {
      return { ...item, subsubthematic: null };
    }
    return item;
  });
  const defaultSettings = {
    selectedThematics: categoriesHierarchies,
    categoryRanking: {
      selectedColumns: [
        'mentionCount',
        'eindex',
        'emotionalIntensity',
        'speechEngagement',

        'mentionEvolution.yearBefore',
        'mentionEvolution.periodBefore',

        'eindexEvolution.yearBefore',
        'eindexEvolution.periodBefore',

        'sentiments.positive.byPercentage',
        'sentiments.positive.byMentionCount',
        'sentiments.neutral.byPercentage',
        'sentiments.neutral.byMentionCount',
        'sentiments.negative.byPercentage',
        'sentiments.negative.byMentionCount',

        'sentimentsEvolution.positive.yearBefore',
        'sentimentsEvolution.positive.periodBefore',
        'sentimentsEvolution.neutral.yearBefore',
        'sentimentsEvolution.neutral.periodBefore',
        'sentimentsEvolution.negative.yearBefore',
        'sentimentsEvolution.negative.periodBefore',

        'emotions.happiness.byPercentage',
        'emotions.happiness.byMentionCount',

        'emotions.surprise.byPercentage',
        'emotions.surprise.byMentionCount',

        'emotions.calm.byPercentage',
        'emotions.calm.byMentionCount',

        'emotions.fear.byPercentage',
        'emotions.fear.byMentionCount',

        'emotions.sadness.byPercentage',
        'emotions.sadness.byMentionCount',

        'emotions.anger.byPercentage',
        'emotions.anger.byMentionCount',

        'emotions.disgust.byPercentage',
        'emotions.disgust.byMentionCount',

        'emotionsEvolution.happiness.yearBefore',
        'emotionsEvolution.happiness.periodBefore',

        'emotionsEvolution.surprise.yearBefore',
        'emotionsEvolution.surprise.periodBefore',

        'emotionsEvolution.calm.yearBefore',
        'emotionsEvolution.calm.periodBefore',

        'emotionsEvolution.sadness.yearBefore',
        'emotionsEvolution.sadness.periodBefore',

        'emotionsEvolution.fear.yearBefore',
        'emotionsEvolution.fear.periodBefore',

        'emotionsEvolution.anger.yearBefore',
        'emotionsEvolution.anger.periodBefore',

        'emotionsEvolution.disgust.yearBefore',
        'emotionsEvolution.disgust.periodBefore',
      ],
      sortedBy: null,
    },
    emotionsTriggers: {
      selectedIndicator: ['happiness'],
      datazoom: {
        start: 0,
        end: 100,
      },
      display: ['thematics', 'subThematics', 'subSubThematics'],
    },
    dataByThematics: {
      selectedFilter: 'thematics',
      indicators: {
        badge: {
          label: 'E-index',
          id: 'eindex',
        },
        bar: {
          label: 'Mentions',
          id: 'mentions',
          color: '#592ea0',
          selected: [],
        },
      },
      orderBy: {
        label: 'Hierarchical - Ascending',
        id: 'hierarchy_asc',
      },
    },
    horizontalDataByThematics: {
      selectedFilter: 'thematics',
      indicators: {
        badge: {
          label: 'E-index',
          id: 'eindex',
        },
        bar: {
          label: 'Emotions volume',
          id: 'emotions.volume',
        },
      },
      orderBy: {
        label: 'Mentions - Ascending',
        id: 'mentionCount_asc',
      },
    },
    categoryBreakdown: {
      graphToDisplay: 'pie',
      levelToDisplay: 'thematics',
      onlySelectedCategories: true,
      riverChartDateType: 'week',
    },
  };

  useEffect(() => {
    if (thematicsOverviewData) {
      setData(thematicsOverviewData.getThematicsOverviewData.thematics);
      setAllCategoriesHierarchy(categoriesHierarchies);

      setSettings(
        JSON.parse(thematicsOverviewData.getThematicsOverviewData.settings)
          ? {
              ...defaultSettings,
              ...JSON.parse(thematicsOverviewData.getThematicsOverviewData.settings),
              selectedThematics:
                JSON.parse(thematicsOverviewData.getThematicsOverviewData.settings).selectedThematics || formattedData,
            }
          : defaultSettings,
      );
    }
  }, [thematicsOverviewData]);

  const flattenedData = flat(data)
    .map(d => {
      if (d.subThematics) {
        return d;
      }
      if (typeof d.subSubThematics !== 'undefined') {
        return {
          ...d,
          thematic: data.find(them =>
            them.subThematics.find(sub => sub?.thematicsOverviewDetails === d?.thematicsOverviewDetails),
          )?.thematicsOverviewDetails?.name,
        };
      }
      if (!d.subThematics && !d.subSubThematics) {
        const thematic = data.find(them =>
          them.subThematics?.find(sub =>
            sub.subSubThematics?.find(subsub => subsub?.thematicsOverviewDetails === d?.thematicsOverviewDetails),
          ),
        );
        return {
          ...d,
          thematic: thematic?.thematicsOverviewDetails?.name,
          subThematic: thematic?.subThematics?.find(sub =>
            sub?.subSubThematics?.find(subsub => subsub?.thematicsOverviewDetails === d?.thematicsOverviewDetails),
          )?.thematicsOverviewDetails?.name,
        };
      }
    })
    .filter(d => d.thematicsOverviewDetails && d.thematicsOverviewDetails.isSelected);

  const getGraphView = () => {
    let graphView;
    switch (currentGraphView) {
      case 'data-by-thematics':
        graphView = (
          <DataByThematics data={flattenedData} settings={settings} setSettings={setSettings} refetch={refetch} />
        );
        break;
      case 'emotions-trigger':
        graphView = (
          <EmotionsTriggersCard
            data={flattenedData}
            settings={settings}
            setSettings={setSettings}
            isDetailsToggled={isDetailsToggled}
            message={message}
          />
        );
        break;
      case 'category-breakdown':
        graphView = (
          <CategoryBreakdownCard
            idpage={idpage}
            data={flattenedData}
            settings={settings}
            setSettings={setSettings}
            rawData={data}
            verbatimsWithoutThematicCount={
              thematicsOverviewData?.getThematicsOverviewData?.verbatimsWithoutThematicCount
            }
            queryFilters={queryFilters}
            loading={loading}
          />
        );
        break;
      case 'horizontal-thematics':
        graphView = (
          <>
            <ThematicsHorizontalChart
              data={flattenedData}
              settings={settings}
              setSettings={setSettings}
              refetch={refetch}
            />
          </>
        );
        break;
      default:
        graphView = (
          <DataByThematics data={flattenedData} settings={settings} setSettings={setSettings} refetch={refetch} />
        );
    }
    return graphView;
  };

  const getBody = () => {
    let body;
    switch (currentTab) {
      case 'category-hierarchy':
        body = (
          <CategoryHierarchyCard
            thematics={data}
            settings={settings}
            projectId={filterKeys?.projectId}
            filterKeys={queryFilters}
            allSelectedCategories={allCategoriesHierarchy}
            hints={[
              HintsContent.HINT_EINDEX_BADGE,
              HintsContent.HINT_SPEECH_ENGAGEMENT,
              HintsContent.HINT_EMOTIONAL_INTENSITY,
            ]}
            message={message}
          />
        );
        break;
      case 'category-ranking':
        body = (
          <CategoryRankingCard
            filteredData={flattenedData}
            rawData={data}
            isDetailsToggled={isDetailsToggled}
            settings={settings}
            projectId={filterKeys?.projectId}
            filterKeys={queryFilters}
            setSettings={setSettings}
            hints={[
              HintsContent.HINT_EINDEX_BADGE,
              HintsContent.HINT_SPEECH_ENGAGEMENT,
              HintsContent.HINT_EMOTIONAL_INTENSITY,
            ]}
            message={message}
            exportLink={exportLink}
            toTab={toTab}
          />
        );
        break;
      case 'graph-view':
        body = getGraphView();
        break;
      default:
        body = (
          <CategoryHierarchyCard
            thematics={data}
            settings={settings}
            projectId={filterKeys?.projectId}
            filterKeys={queryFilters}
            allSelectedCategories={allCategoriesHierarchy}
            hints={[
              HintsContent.HINT_EINDEX_BADGE,
              HintsContent.HINT_SPEECH_ENGAGEMENT,
              HintsContent.HINT_EMOTIONAL_INTENSITY,
            ]}
            message={message}
          />
        );
    }
    return body;
  };

  return (
    <StyledContainer fluid className="py-3 px-4">
      {getBody()}
    </StyledContainer>
  );
};

ThematicsOverview.propTypes = {
  idpage: PropTypes.string,
  dispatchUpdateRoute: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  exportLink: PropTypes.bool,
  toTab: PropTypes.number,
  filterKeys: PropTypes.shape({
    criteriaKeys: PropTypes.arrayOf(PropTypes.string),
    dataCollectorId: PropTypes.number,
    projectId: PropTypes.number.isRequired,
    range: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }).isRequired,
    texts: PropTypes.string,
    thematics: PropTypes.arrayOf(PropTypes.string),
  }),
  isDetailsToggled: PropTypes.bool,
  message: PropTypes.string,
  currentGraphView: PropTypes.string,
};

ThematicsOverview.defaultProps = {
  idpage: 'thematics-overview',
  exportLink: false,
  toTab: 1,
  filterKeys: {},
  isDetailsToggled: false,
  message: '',
  currentGraphView: 'category-breakdown',
};

function mapStateToProps(state) {
  return {
    filterKeys: get(state, ['filters', 'filterKeys']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateRoute: newState => {
      dispatch(updateRoute(newState));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThematicsOverview);
