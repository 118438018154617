import React, { createRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withApollo } from 'react-apollo';
import '../../_Cards/MentionsCards/MentionPreviewPerEmotions/style.scss';
import QInput from '_Components/Fields/QInput/QInput';
import GET_VERBATIM_QUERY from '_Components/QVerbatim/graphql/getVerbatim';
import VerbatimBubble from '../../_Components/QVerbatimBubble/VerbatimBubble';
import NoDataMessage from '../../_Components/NoDataMessage/NoDataMessage';
import { gray, grayShades } from '../../styles/abstracts/colors';
import QButton from '../../_Components/Buttons/QButton/QButton';
import MentionNumber from './MentionNumber';

const VerbatimCounter = styled.p`
  text-align: center;
  font-weight: bold;
  color: ${grayShades.g800};
  margin-bottom: 0.5em;
`;

const SearchInputContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  font-size: 0.75em;
  margin-top: 1em;
  font-weight: bold;
  margin-bottom: 1em;
  ${({ headerWidth }) => headerWidth < 800 && 'justify-content: end;'}
`;

const SearchInput = styled(QInput)`
  font-weight: bold;
  min-width: 25em;
  max-width: 100%;
  border: 1px solid ${grayShades.g600};
  ${({ headerWidth }) => headerWidth < 800 && 'min-width: 23em; margin-bottom: 5px;'}
`;
const SearchInputContainer = styled.div`
  ${({ headerWidth }) =>
    headerWidth < 800 && 'display: flex; flex-direction: column; justify-content: center; align-items: end;'}
`;

const SearchInputButton = styled(QButton)`
  ${({ headerWidth }) => headerWidth < 800 && 'width: fit-content;'}
`;

const VerbatimListContainer = styled.span``;

const VerbatimListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  position: relative;
  ${({ headerWidth }) => headerWidth < 800 && 'justify-content: end;'}
`;

class VerbatimList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      verbatimByUuid: null,
    };
  }

  componentDidMount() {
    const { data, filters, headerRef, setHeaderWidth } = this.props;

    data &&
      this.setState({
        numberOfMentions: data.verbatimList && data.verbatimList.length,
      });
    if (
      data &&
      (!filters.keywords || (filters.keywords && filters.keywords.length === 0)) &&
      ((filters.thematics && filters.thematics.length === 0) || (filters.thematics && !filters.thematics.subThematic))
    ) {
      this.setState({ numberOfMentions: null });
    }
    setHeaderWidth && setHeaderWidth(headerRef.current.offsetWidth);
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, keywords, filters, refetch, headerRef, headerWidth, setHeaderWidth } = this.props;

    if (prevProps.keywords !== keywords) {
      refetch(filters);
    }

    if (prevProps.data && data && prevProps.data.verbatimList !== data.verbatimList) {
      this.setState({
        queryResults: data.verbatimList,
      });
    }
    if (prevProps.headerWidth !== headerWidth) {
      setHeaderWidth(headerRef.current.offsetWidth);
    }
  }

  async searchByUuid() {
    const { client } = this.props;
    const { uuid } = this.state;
    client
      .query({
        query: GET_VERBATIM_QUERY,
        variables: {
          type: 'VERBATIM',
          uuid,
        },
      })
      .then(({ data: { getVerbatim } }) => this.setState({ verbatimByUuid: getVerbatim }))
      .catch(err => {
        this.setState({ verbatimByUuid: 'error' });
      });
  }

  resetSearchByUuid() {
    this.setState({ uuid: '', verbatimByUuid: null });
  }

  getVerbatimListContent = () => {
    const { t, keywords, verbatimList, data, onLoadMore, selectedVerbatim, lazyLoad } = this.props;
    const { queryResults, numberOfMentions, verbatimByUuid, key } = this.state;
    const verbatimCount = (queryResults && queryResults.length) || numberOfMentions;

    if (verbatimByUuid && verbatimByUuid !== 'error') {
      return (
        <div className="containverbatimBubbles">
          <VerbatimBubble {...verbatimByUuid} key={`${key}verbatim1`} keywords={keywords} verbatimList={verbatimList} />
        </div>
      );
    }

    if (data && data.verbatimList && data.verbatimList.length > 0) {
      return (
        <div className="containverbatimBubbles">
          {numberOfMentions !== null && (
            <VerbatimCounter>
              {numberOfMentions >= 100
                ? t('More than XXX verbatim found', {
                    verbatimCount,
                  })
                : t('XXX verbatim found', { verbatimCount })}
            </VerbatimCounter>
          )}
          {verbatimByUuid === 'error' && (
            <VerbatimCounter>{t('card:The verbatim you are looking for does not exist.')}</VerbatimCounter>
          )}
          {data.verbatimList.map((verbatim, i) => {
            return (
              <VerbatimBubble
                {...verbatim}
                key={`${key}verbatim${i}`}
                keywords={keywords}
                verbatimList={verbatimList}
                isOpen={selectedVerbatim.id === verbatim.id}
              />
            );
          })}
          {lazyLoad && (
            <div className="text-center my-4">
              <QButton
                bgColor="grayShades.g800"
                id="callNewVerbatim"
                onClick={() => onLoadMore()}
                disabled={!data.remainingVerbatim}
              >
                Load more
              </QButton>
            </div>
          )}
        </div>
      );
    }

    return (
      <NoDataMessage
        mainTitle="Select an emotion !"
        secondText="Please, use the left panel to select an emotion and see corresponding verbatim !"
      />
    );
  };

  render() {
    const { t, filters, headerRef, headerWidth } = this.props;
    const { uuid, verbatimByUuid } = this.state;

    return (
      <VerbatimListContainer>
        <VerbatimListHeader ref={headerRef} headerWidth={headerWidth}>
          <MentionNumber t={t} filters={filters} />
          <SearchInputContainerWrapper headerWidth={headerWidth}>
            <div>
              <div>{t('card:Search a verbatim by ID')}</div>
              <SearchInputContainer headerWidth={headerWidth}>
                <SearchInput
                  value={uuid}
                  onChange={e => this.setState({ uuid: e.target.value })}
                  headerWidth={headerWidth}
                />{' '}
                <SearchInputButton
                  onClick={() => (uuid ? this.searchByUuid() : this.resetSearchByUuid())}
                  headerWidth={headerWidth}
                >
                  {t('card:Search')}
                </SearchInputButton>
                {verbatimByUuid && uuid && (
                  <QButton bgColor="red" outline onClick={() => this.resetSearchByUuid()}>
                    {t('Reset')}
                  </QButton>
                )}
              </SearchInputContainer>
            </div>
          </SearchInputContainerWrapper>
        </VerbatimListHeader>
        {this.getVerbatimListContent()}
      </VerbatimListContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterEmotions: state.cards.emotion_selected,
    selectedVerbatim: state.verbatim.selectedVerbatim,
  };
}

VerbatimList.defaultProps = {
  lazyLoad: false,
};

export default connect(mapStateToProps)(withApollo(withTranslation('button', 'card')(VerbatimList)));
