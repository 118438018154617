import statusesResponse from '../__mock__/statusesResponse';

export const LOAD_STATUSES_SUCCESS = 'ALERTING_LOAD_STATUSES_SUCCESS';
export const LOAD_STATUSES_FAILURE = 'ALERTING_LOAD_STATUSES_FAILURE';

const loadStatusesSuccess = statuses => ({
  type: LOAD_STATUSES_SUCCESS,
  statuses,
});

const loadStatusesFailure = () => ({
  type: LOAD_STATUSES_FAILURE,
});

// TODO: connect to backend
export const loadStatuses = () => loadStatusesSuccess(statusesResponse);
