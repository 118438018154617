import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from '_Components/Icons/Icon';
import {
  grayShades,
  lightBlue,
  themeColors,
} from '../../../styles/abstracts/colors';
import { ImageSize } from '../../../styles/abstracts/variables';
import QButton from '../QButton/QButton';

const StyledIcon = styled(Icon)``;

const AddButton = styled(QButton)`
  &.btn {
    cursor: pointer;
    border: ${grayShades.g800} dashed 2px;
    color: ${themeColors.secondary} !important;
    background: transparent;
    width: ${({ width }) => width};
    border-radius: 1rem;
  }
  ${({ hoverColor }) =>
    !hoverColor
      ? null
      : `
      &.btn:hover {
        border: ${hoverColor} dashed 2px;
        color: ${hoverColor} !important;
        background: transparent !important;
    
        ${StyledIcon} path {
          fill: ${hoverColor} !important;
        }
      }
  `};
`;

const QAddButton = ({
  text,
  color,
  hoverColor,
  icon,
  onClick,
  className,
  width,
}) => (
  <AddButton
    bgColor="transparent"
    onClick={onClick}
    className={className}
    hoverColor={hoverColor}
    width={width}
    icon={icon}
    iconColor={color}
    iconSize={ImageSize.big}
    iconPosition="left"
  >
    {text}
  </AddButton>
);

QAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
};

QAddButton.defaultProps = {
  color: themeColors.secondary,
  hoverColor: lightBlue,
  icon: 'PLUS',
  text: '',
  className: '',
  width: '900px',
};

export default QAddButton;
