import React from 'react';
import { Mutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import Icon from '_Components/Icons/Icon';
import CURRENT_USER_QUERY from '_Resources/Header/Services/api/graphql/currentUser';
import {
  grayShades,
  purple,
  opacity,
} from '../../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../../styles/abstracts/typography';
import { ImageSize, spacing } from '../../../../../styles/abstracts/variables';
import queryReportingModels from '../graphql/getReportingModel';
import mutationDuplicateModel from '../graphql/duplicateReportingModel';

const Item = styled(DropdownItem)`
  padding: ${spacing.mediumSmall} ${spacing.medium} !important;
  cursor: ${props => !props.disabled && 'pointer'};
  line-height: 21px;
`;

const Duplicate = styled.span`
  font-weight: bold;
  color: ${props => (props.disabled ? opacity.purple : purple)} !important;
  font-size: ${fontSizes.smaller};
`;

class DuplicateReportingModel extends React.Component {
  render() {
    const { t, data } = this.props;
    return (
      <Mutation
        mutation={mutationDuplicateModel}
        update={(cache, { data: { duplicateReportingModel } }) => {
          const { projectId } = this.props.filterKeys;
          const reportingModelsList = cache.readQuery({
            query: queryReportingModels,
            variables: { projectId },
          });
          const duplicateModel = {
            ...duplicateReportingModel,
            settings: data.settings,
          };
          cache.writeQuery({
            query: queryReportingModels,
            variables: { projectId },
            data: {
              getReportingModel: reportingModelsList.getReportingModel.concat(
                duplicateModel,
              ),
            },
          });
          const { currentUser } = cache.readQuery({
            query: CURRENT_USER_QUERY,
          });
          currentUser.account.canCreateReporting =
            duplicateReportingModel.canCreateReporting;
          cache.writeQuery({
            query: CURRENT_USER_QUERY,
            data: { currentUser },
          });
        }}
      >
        {duplicateReportingModel => (
          <Item
            id="duplicateReportingItem"
            disabled={!this.props.canCreateReporting}
            onClick={() =>
              duplicateReportingModel({
                variables: { reportingModelId: data.id },
              })
            }
          >
            <Icon
              icon="DUPLICATE"
              size={ImageSize.medium}
              color={
                !this.props.canCreateReporting
                  ? grayShades.g300
                  : grayShades.g800
              }
            />
            &nbsp;
            <Duplicate disabled={!this.props.canCreateReporting}>
              {t('Duplicate')}
            </Duplicate>
          </Item>
        )}
      </Mutation>
    );
  }
}

const mapStateToProps = state => ({
  filterKeys: state.filters.filterKeys,
  canCreateReporting: state.auth.user.account.canCreateReporting,
});

export default connect(mapStateToProps)(
  withTranslation('button')(DuplicateReportingModel),
);
