import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { radius } from 'styles/abstracts/variables';
import { grayShades, lightBlue, orange } from 'styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';
import ProcessingHistoryHeader, {
  ExportData,
} from './_Components/ProcessingHistoryHeader/ProcessingHistoryHeader';
import ProcessingHistoryRow from './_Components/ProcessingHistoryRow/ProcessingHistoryRow';

const Container = styled.div`
  border-radius: ${radius.default};
  border: 3px solid ${grayShades.g300};
`;

const Message = styled.p`
  width: 100%;
  text-align: center;
  padding: 1em;
  color: ${lightBlue};
  font-weight: bold;
`;

const InformationsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Informations = styled.span`
  width: 70%;
  display: inline-block;
  font-weight: bolder;
  text-align: justify;
`;

const Caution = styled(Informations)`
  color: ${orange};
`;

const StyledIcon = styled(Icon)`
  margin-top: 0.25em;
  margin-right: 1em;
`;

const ProcessingHistory = ({ data, t, projectId, projectName }) => {
  const [message, setMessage] = useState({});
  const [history, setHistory] = useState([]);

  useEffect(() => {
    data && data.length > 0 && setHistory(data);
  }, [data]);

  return data?.length > 0 ? (
    <Container>
      {message?.message && <Message>{message.message}</Message>}
      <ProcessingHistoryHeader
        t={t}
        projectId={projectId}
        setMessage={setMessage}
        projectName={projectName}
      />
      {history?.map(d => (
        <ProcessingHistoryRow data={d} t={t} />
      ))}
    </Container>
  ) : (
    <>
      <Message>
        {t(
          'The processing history is available starting from xx-xx-xxxx. Any processing launched before this date will not appear here.',
          { date: moment('2/07/2020').format('L') },
        )}
      </Message>
      <ExportData
        projectId={projectId}
        setMessage={setMessage}
        projectName={projectName}
        t={t}
        noHistory
      />
      <br />
      <InformationsContainer>
        <StyledIcon icon="INTERFACE_INFO" size={16} />
        <Informations>{t('PROCESSING_HISTORY_INFORMATION')}</Informations>
      </InformationsContainer>
      <br />
      <InformationsContainer>
        <StyledIcon icon="WARNING" size={16} color={orange} />
        <Caution>{t('PROCESSING_HISTORY_CAUTION')}</Caution>
      </InformationsContainer>

      {message.message && <Message>{message.message}</Message>}
    </>
  );
};

export default withTranslation('card', 'button')(ProcessingHistory);
