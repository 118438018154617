import gql from 'graphql-tag';

const query = gql`
  query getProjectConfiguration($projectId: Int!) {
    results: getProjectConfiguration(projectId: $projectId) {
      unitsAndLocalesSettings {
        eindexUnitFahrenheit
        analysisAxis
        sat {
          min
          max
          unit
        }
        reco {
          min
          max
          unit
        }
        ces {
          min
          max
          unit
        }
      }
      lastDataCollectorId
      moduleSettings {
        moduleId
        pageSettings {
          defaultThematic
          pageId
          tilesArray
          settings
          hiddenFilters {
            normal
            related
            thematic
          }
        }
      }
    }
  }
`;

export default query;
