import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import { get } from 'lodash';
import SideCardContainer from 'Routes/_Containers/DataByFiltersGeneric/_Containers/SideCardContainer';
import ThematicsHorizontalChartCard from './ThematicsHorizontalChartCard';
import SAVE_THEMATICS_OVERVIEW_SETTINGS from '../graphql/saveThematicsOverviewSettings';
import { HORIZONTAL_INDICATORS_CONFIG, ORDER_BY_ITEMS } from './utils';

type ThematicsHorizontalChartProps = {
  data: any[];
  projectId: number;
  refetch: any;
  settings: any;
  setSettings: any;
};

const ThematicsHorizontalChart = ({
  data,
  projectId,
  refetch,
  settings,
  setSettings,
}: ThematicsHorizontalChartProps) => {
  const [saveThematicsOverviewSettings] = useMutation(SAVE_THEMATICS_OVERVIEW_SETTINGS, {
    variables: { projectId, settings: JSON.stringify(settings) },
    update: () => {},
  });
  const [cardFilters, setCardFilters] = useState({});
  useEffect(() => {
    saveThematicsOverviewSettings();
  }, [settings]);

  const propsToPass = {
    data: data
      .map(item => ({
        ...item.thematicsOverviewDetails,
        hierarchy: {
          thematic: item.thematic || item.thematicsOverviewDetails.name,
          subThematic: item.subThematic || (item.subSubThematics ? item.thematicsOverviewDetails.name : null),
          subSubThematic: item.thematic && item.subThematic ? item.thematicsOverviewDetails.name : null,
        },
        filter: item.subThematics ? 'thematics' : item.subSubThematics ? 'subThematics' : 'subSubThematics',
      }))
      .filter(item => item.mentionCount > 0 && item.eindex !== null),
    settings,
    refetch,
    setSettings,
    mutation: saveThematicsOverviewSettings,
    horizontalIndicatorsConfig: HORIZONTAL_INDICATORS_CONFIG,
    defaultSettings: settings.horizontalDataByThematics,
    filtersToDisplay: [
      {
        id: 'thematics',
        label: 'Categories',
      },
      {
        id: 'subThematics',
        label: 'Subcategories',
      },
      {
        id: 'subSubThematics',
        label: 'Sub sub categories',
      },
    ],
    orderByItems: ORDER_BY_ITEMS,
    setCardFilters,
  };
  return (
    <>
      <ThematicsHorizontalChartCard {...propsToPass} />
      {/* <SideCardContainer
        toggleDisplaySideCard={toggleDisplaySideCard}
        displaySideCard={displaySideCard}
        unmountSideCard={unmountSideCard}
        filters={cardFilters}
        cardTitle={sideCardTitle}
        closeCard={() => this.toggleDisplaySideCard()}
        isCardOpened={displaySideCard}
        disableBPPTab
      /> */}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
});

export default connect(mapStateToProps)(ThematicsHorizontalChart);
