import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QTitle from '_Components/Texts/QTitle/QTitle';
import DatePickerBenchmark from './DatePickerBenchmark';
import ConflictMessages from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/ConflictMessages';
import { FILTER_DIMENSIONS } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import MergeMessages from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/MergeMessages';

const Container = styled.div`
  margin-left: ${props => props.marginLeft || '2em'};
  margin-right: ${props => props.marginRight || '0'};
  color: #928fb0;
  font-weight: bold;

  & > div {
    width: 100%;
  }
`;

class DatePickerContainer extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
  };

  static defaultProps = {
    title: null,
    marginLeft: '2em',
    marginRight: '0',
  };

  render() {
    const { t, title } = this.props;
    return (
      <>
        {!title ? (
          <Container
            className="d-flex align-items-center align-content-center datePickerContainer"
            marginLeft={this.props.marginLeft}
            marginRight={this.props.marginRight}
          >
            <p style={{ marginRight: '5%' }}>{t('Period')}</p>
            <DatePickerBenchmark {...this.props} />
          </Container>
        ) : (
          <>
            {!this.props.isInSubheader && (
              <>
                <ConflictMessages
                  context={this.props.context}
                  type={FILTER_DIMENSIONS.DATE_RANGE}
                />
              </>
            )}
            <QTitle>
              <QTitle className="Title">{t(title)}</QTitle>
            </QTitle>
            <DatePickerBenchmark {...this.props} />
            {!this.props.isInSubheader && (
              <>
                <MergeMessages
                  context={this.props.context}
                  type={FILTER_DIMENSIONS.DATE_RANGE}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default withTranslation('header')(DatePickerContainer);
