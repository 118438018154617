import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getRoute } from '_Utils/routes/routeUtils';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import UploadDataCard from '_Cards/SettingsCards/UploadDataCard/UploadDataCard';
import getSaasUploadConfigQuery from '_Cards/SettingsCards/UploadDataCard/graphql/getFileUploadConfig';

const UploadDataSaas = ({ idpage, updateRoute, history, t, uuid, setActiveStep, location }) => {
  useEffect(() => {
    getRoute(updateRoute, idpage);
  }, []);

  const query = useQuery(getSaasUploadConfigQuery, {
    variables: {
      uuid,
    },
  });

  const { data, error: queryError } = query;

  const saasUploadConfig = data?.getSaasUploadConfig;

  if (saasUploadConfig === null || queryError) {
    history.push('/settings');
  }

  return (
    <Container fluid className="py-3 px-4">
      <UploadDataCard
        history={history}
        t={t}
        uuid={uuid}
        saasUploadConfig={saasUploadConfig}
        setActiveStep={setActiveStep}
        location={location}
      />
    </Container>
  );
};

const mapDispatchToProps = dispatch => ({
  updateRoute: newState => {
    dispatch(updateRoute(newState));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation('card', 'button')(UploadDataSaas));
