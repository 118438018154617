import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { clone } from 'lodash';
import Icon from '_Components/Icons/Icon';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import { grayShades, black } from 'styles/abstracts/colors';
import VerbatimListModal from '_Components/Modals/VerbatimListModal/VerbatimListModal';
import { sortByName } from '../../CategoryHierarchyCard';
import '../../../style.scss';

const SpanChevron = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  display: inline-block;
`;

const ChevronButton = styled(SpanChevron)`
  border-radius: 0.75rem;
  background-color: ${grayShades.g300};
  text-align: center;
  cursor: pointer;
`;

const StyledCell = styled.td`
  font-weight: bold;
  color: ${black};
  padding: 1.25em 0.75em 0.75em !important;
  padding-left: ${({ level }) => (level === 'subThematic' ? '2em' : level === 'subSubThematic' && '5em')} !important;
`;

const EindexCell = styled(StyledCell)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-weight: bold;
`;

const NumbersCell = styled(StyledCell)`
  color: ${grayShades.g800};
  text-align: center;
`;

const CheckboxCell = styled(StyledCell)`
  margin: auto !important;
  text-align: center;
  width: 2.5rem;
`;

const Checkbox = styled(QCheckbox)`
  margin: 0;
`;

const FlexCell = styled(StyledCell)`
  display: flex;
  color: ${({ isSelected }) => !isSelected && grayShades.g600};
  height: 3.5em !important;
`;

const ModalLink = styled.span`
  cursor: ${({ isCategorySelected }) => isCategorySelected && 'pointer'};
`;

const TableRow = ({
  data,
  level,
  showSelection,
  isGlobalSelected,
  setGlobalSelected,
  categories,
  setCategories,
  thematicIndex,
  settings,
  index,
  parentObject,
  selectedThematics,
  setSelectedThematics,
  filterKeys,
  displayAll,
  setDisplayAll,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [category, setCategory] = useState(data);
  const [isCategorySelected, setIsCategorySelected] = useState(data.thematicsOverviewDetails?.isSelected);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setCategory(data);
  }, [data, isGlobalSelected]);

  useEffect(() => {
    setIsCategorySelected(category.thematicsOverviewDetails?.isSelected);
  }, [category, isGlobalSelected]);

  useEffect(() => {
    if (!showSelection) {
      setCategory(data);
      setIsCategorySelected(data?.thematicsOverviewDetails?.isSelected);
    }
  }, [showSelection]);

  useEffect(() => {
    setExpanded(displayAll);
  }, [displayAll]);

  const getCurrentItem = () => {
    let item = {};
    switch (level) {
      case 'thematic':
        item = categories[thematicIndex];
        break;
      case 'subThematic':
        item = categories[thematicIndex].subThematics[index];
        break;
      case 'subSubThematic':
        item = parentObject.subSubThematics?.[index];
    }
    return item;
  };

  const getHierarchy = () => {
    let item = {};
    switch (level) {
      case 'thematic':
        item = {
          thematic: categories[thematicIndex]?.thematicsOverviewDetails?.name,
          subthematic: null,
          subsubthematic: null,
        };
        break;
      case 'subThematic':
        item = {
          thematic: categories[thematicIndex]?.thematicsOverviewDetails?.name,
          subthematic: data?.thematicsOverviewDetails?.name,
          subsubthematic: null,
        };
        break;
      case 'subSubThematic':
        item = {
          thematic: categories[thematicIndex]?.thematicsOverviewDetails?.name,
          subthematic: parentObject?.thematicsOverviewDetails?.name,
          subsubthematic: data?.thematicsOverviewDetails?.name,
        };
    }
    return item;
  };

  useEffect(() => {
    const currentItem = getCurrentItem();
    const modifiedCategories = clone(categories);
    if (level === 'thematic') {
      modifiedCategories[thematicIndex] = currentItem;
    } else if (level === 'subThematic') {
      modifiedCategories[thematicIndex].subThematics[index] = currentItem;
    }
    setCategories(modifiedCategories);
  }, [isCategorySelected]);

  useEffect(() => {
    const lookupValue = JSON.stringify(getHierarchy());
    const isCategorySelected = selectedThematics.some(thematic => JSON.stringify(thematic) === lookupValue);
    setIsCategorySelected(isCategorySelected);
  }, [isGlobalSelected]);

  const { thematicsOverviewDetails, subThematics, subSubThematics } = category;

  const { thematic, subthematic, subsubthematic } = getHierarchy();

  const categoryHierarchy = {
    thematic,
    subThematic: subthematic,
    subSubThematic: subsubthematic,
    joinSpecify: 'and',
  };

  const propsModal = {
    numberOfMentions: thematicsOverviewDetails.mentionCount,
    title: `${categoryHierarchy.thematic} ${
      categoryHierarchy.subThematic ? `> ${categoryHierarchy.subThematic}` : ''
    } ${categoryHierarchy.subSubThematic ? `> ${categoryHierarchy.subSubThematic}` : ''}`,
    titleSecondPart: '',
    toggle: () => setModal(!modal),
    className: '',
    modal,
  };

  return (
    thematicsOverviewDetails && (
      <>
        <tr>
          {showSelection && (
            <CheckboxCell>
              <div
                style={{
                  height: '3em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Checkbox
                  selected={isCategorySelected}
                  onClick={() => {
                    setIsCategorySelected(!isCategorySelected);
                    if (isCategorySelected) {
                      setSelectedThematics(
                        selectedThematics.filter(cat => JSON.stringify(cat) !== JSON.stringify(getHierarchy())),
                      );
                    } else {
                      setSelectedThematics([...selectedThematics, getHierarchy()]);
                    }
                  }}
                />
              </div>
            </CheckboxCell>
          )}
          <FlexCell level={level} isSelected={isCategorySelected}>
            <div
              style={{
                height: '3em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {(level === 'subThematic' && subSubThematics && subSubThematics[0]) ||
              (level === 'thematic' && subThematics && subThematics[0]) ? (
                <ChevronButton onClick={() => setExpanded(!expanded)}>
                  <Icon
                    icon={expanded ? 'INTERFACE_CHEVRON_DOWN' : 'INTERFACE_CHEVRON_RIGHT'}
                    color={grayShades.g800}
                  />
                </ChevronButton>
              ) : (
                <div style={{ width: '2.5em', height: '1.5em' }} />
              )}
              <ModalLink isCategorySelected={isCategorySelected} onClick={() => isCategorySelected && setModal(!modal)}>
                {thematicsOverviewDetails.name}
              </ModalLink>
              <VerbatimListModal
                {...propsModal}
                filters={{
                  ...filterKeys,
                  thematics: [...filterKeys.thematics, categoryHierarchy],
                  sort: 'DESC',
                  selectedEmotions: null,
                }}
                mentionCount={thematicsOverviewDetails?.mentionCount}
                classBody="containBubble"
              />
            </div>
          </FlexCell>
          {isCategorySelected && (
            <>
              <NumbersCell>{thematicsOverviewDetails.mentionCount}</NumbersCell>
              {thematicsOverviewDetails.eindex !== null ? (
                <EindexCell>
                  <EindexRond type="xs" eindex={thematicsOverviewDetails.eindex} />
                </EindexCell>
              ) : (
                <NumbersCell>N/A</NumbersCell>
              )}
            </>
          )}
        </tr>
        {(subThematics || subSubThematics) &&
          sortByName(subThematics || subSubThematics).map(
            (child, index) =>
              expanded && (
                <TableRow
                  data={child}
                  showSelection={showSelection}
                  level={child.subSubThematics !== undefined ? 'subThematic' : 'subSubThematic'}
                  isGlobalSelected={isGlobalSelected}
                  setGlobalSelected={setGlobalSelected}
                  categories={categories}
                  setCategories={setCategories}
                  thematicIndex={thematicIndex}
                  index={index}
                  parentObject={
                    child.subSubThematics === undefined &&
                    categories[thematicIndex].subThematics.find(item => item.subSubThematics === subSubThematics)
                  }
                  selectedThematics={selectedThematics}
                  setSelectedThematics={setSelectedThematics}
                  settings={settings}
                  filterKeys={filterKeys}
                  displayAll={displayAll}
                  setDisplayAll={setDisplayAll}
                />
              ),
          )}
      </>
    )
  );
};

export default TableRow;
