import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TagFrame } from './Tag';
import {
  grayShades,
  themeColors,
} from '../../../../../styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';

const Text = styled.input`
  margin: 0.5em;
  border: none;
  color: ${grayShades.g700};
  font-weight: bold;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  line-height: 1em;
  font-size: 1em;
  outline: none;
`;

const PlusIcon = styled.span`
  cursor: pointer;
`;

const BASE_INPUT_WIDTH = 8;

class TagInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  state = {
    isInputFocused: false,
  };

  textRef = null;

  getDynamicStyle() {
    const { value = '' } = this.props;
    const newMaxWidth = (value.length + 1) * BASE_INPUT_WIDTH;
    return {
      width: `${newMaxWidth < 24 ? 24 : newMaxWidth}px`,
    };
  }

  onTextChange = ({ target: { value } }) => {
    const { onChange } = this.props;
    onChange(value);
  };

  onPlusClicked = () => {
    this.setState({ isInputFocused: true });
  };

  onTextRef = ref => {
    this.textRef = ref;
    if (this.textRef) {
      this.textRef.focus();
    }
  };

  onBlur = () => this.setState({ isInputFocused: false });

  render() {
    const { value } = this.props;
    const { isInputFocused } = this.state;
    return (
      <TagFrame primary={!isInputFocused}>
        {isInputFocused ? (
          <Text
            innerRef={this.onTextRef}
            style={this.getDynamicStyle()}
            onChange={this.onTextChange}
            onBlur={this.onBlur}
            value={value}
          />
        ) : (
          <PlusIcon onClick={this.onPlusClicked}>
            <Icon color={themeColors.primary} size={24} icon="PLUS" />
          </PlusIcon>
        )}
      </TagFrame>
    );
  }
}

export default TagInput;
