import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from '../../../../../../../../styles/abstracts/variables';
import { fontSizes } from '../../../../../../../../styles/abstracts/typography';
import { purple } from '../../../../../../../../styles/abstracts/colors';
import DropdownOptions from '../../../../DropdownOptions';

const Container = styled.td`
  width: 50%;
  vertical-align: middle !important;
  text-align: right;
  border: none !important;
`;

const ShowReports = styled.span`
  color: ${purple};
  font-weight: bold;
  font-size: ${fontSizes.smaller};
  cursor: pointer;
`;

const TemplateOptions = props => {
  const { t } = props;
  return (
    <Container>
      {(props.isHovered || props.showReports) && (
        <>
          {/* <ShowReports onClick={props.toggleShowReports}> */}
          {/* {props.showReports ? ( */}
          {/* <span> */}
          {/* {t('Hide all generated reports')} &nbsp; */}
          {/* <Icon icon="INTERFACE_CHEVRON_UP" size={ImageSize.medium} /> */}
          {/* </span> */}
          {/* ) : ( */}
          {/* <span> */}
          {/* {t('Show all generated reports')} &nbsp; */}
          {/* <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} /> */}
          {/* </span> */}
          {/* )} */}
          {/* </ShowReports> */}
          <DropdownOptions data={props.data} template />
        </>
      )}
    </Container>
  );
};

export default withTranslation('card')(TemplateOptions);
