export const SET_SPECIFIC_JOURNEY_FILTERS = 'SET_SPECIFIC_JOURNEY_FILTERS';
export const SET_SPECIFIC_MAP_FILTERS = 'SET_SPECIFIC_MAP_FILTERS';
export const SET_SPECIFIC_SETTINGS_TAB = 'SET_SPECIFIC_SETTINGS_TAB';

export function setSpecificJourneyFilters(data) {
  const { thematics, mentionCount, idPage } = data;
  return {
    type: SET_SPECIFIC_JOURNEY_FILTERS,
    [idPage]: {
      thematics,
      mentionCount,
    },
  };
}

export function setSpecificMapFilters(data) {
  const { idPage, ...props } = data;
  return {
    type: SET_SPECIFIC_MAP_FILTERS,
    [idPage]: props,
  };
}
export function setSpecificSettingsTab(data) {
  const { idPage, ...props } = data;
  return {
    type: SET_SPECIFIC_SETTINGS_TAB,
    [idPage]: props,
  };
}
