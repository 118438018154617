/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { remove, isEmpty } from 'lodash';
import styled from 'styled-components';
import { tagAreaStyles } from './styles';
import Tag from './QTag';
import TagInput from './QTagInput';

const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: ${tagAreaStyles['min-height']};
  background-color: ${tagAreaStyles['background-color']};
  border-radius: ${tagAreaStyles.radius};
`;

const StyledTagInput = styled(TagInput)`
  //flex-grow: 1;
  width: 7rem;
`;

const StyledTag = styled(Tag)`
  flex-shrink: 0;
`;

export default class Tags extends Component {
  static propTypes = {
    initialTags: PropTypes.arrayOf(PropTypes.string),
    onTagsChanged: PropTypes.func,
  };

  static defaultProps = {
    initialTags: [],
    onTagsChanged: _ => {},
  };

  state = { tags: [], currentTag: '' };

  constructor(props) {
    super(props);
    this.initializeTags();
  }

  initializeTags() {
    this.state.tags = this.props.initialTags;
  }

  addTag() {
    const { onTagsChanged } = this.props;
    const { currentTag } = this.state;

    if (this.isTagAlreadyAdded(currentTag) || this.isTagEmpty(currentTag))
      return;

    this.setState(
      ({ tags }) => ({
        tags: [...tags, currentTag],
        currentTag: '',
      }),
      () => onTagsChanged(this.state.tags),
    );
  }

  isTagAlreadyAdded(value) {
    const { tags } = this.state;
    return tags.includes(value);
  }

  isTagEmpty(value) {
    return isEmpty(value);
  }

  prepareTags() {
    const { tags } = this.state;
    return tags.map(tag => (
      <StyledTag onRemove={this.onTagRemoved} name={tag} />
    ));
  }

  onTagRemoved = tag => {
    const { onTagsChanged } = this.props;
    this.setState(
      ({ tags }) => {
        remove(tags, currentTag => currentTag === tag);
        return { tags: [...tags] };
      },
      () => onTagsChanged(this.state.tags),
    );
  };

  onTagAdded = () => this.addTag();
  onTagInput = value =>
    this.setState({ currentTag: value, inputWidth: value.length });

  render() {
    const { currentTag, inputWidth } = this.state;
    const tags = this.prepareTags();

    return (
      <Body>
        {tags}
        <StyledTagInput
          onTagAdd={this.onTagAdded}
          value={currentTag}
          onTagInput={this.onTagInput}
          inputWidth={inputWidth}
        />
      </Body>
    );
  }
}
