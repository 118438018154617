import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { get } from 'lodash';
import styled from 'styled-components';

import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import EindexEvolutionWithMentionsSection from './EmotionEvolutionLineSection';

type EmotionEvolutionLineCardProps = {
  title: string;
  titleSecondPart: string;
  dropDownOptions: Array<string>;
  mixBarLine?: boolean;
  multipleEindex?: boolean;
  hideRangeButtons: boolean;
  filterKeys: any;
  filters: any;
  hideDataDisplayButtons: boolean;
};

const SwitcherContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const RANGE_BUTTONS: Array<any> = [
  {
    label: 'Day',
    id: 'day',
  },
  {
    label: 'Week',
    id: 'week',
  },
  {
    label: 'Month',
    id: 'month',
  },
];

const btnsDisplayType = [
  {
    label: 'Percentage',
    id: 'percentage',
  },
  {
    label: 'Absolute values',
    id: 'absolute',
  },
];

const analysisAxisSwitcherOptions = [
  {
    id: 'sentiment',
    label: 'Sentiment',
  },
  {
    id: 'emotion',
    label: 'Emotion',
  },
];

const EmotionEvolutionLineCard = (props: EmotionEvolutionLineCardProps) => {
  const [range, setRange] = useState<'day' | 'week' | 'month'>('day');
  const [valueAbsolute, setValueAbsolute] = useState<'percentage' | 'absolute'>('percentage');
  const [analysisAxis, setAnalysisAxis] = useState<'sentiment' | 'emotion'>('sentiment');

  return (
    <QcardPanel {...props} hints={[HintsContent.HINT_EINDEX_EVOLUTION]}>
      <SwitcherContainer>
        {!props.hideRangeButtons && (
          <span className="mx-2">
            <QSwitcher
              selected={range}
              options={RANGE_BUTTONS}
              onOptionSelected={setRange}
              padding="0.25rem 1rem"
              display="inline-flex"
              width="auto"
            />
          </span>
        )}
        {!props.hideDataDisplayButtons && (
          <span className="mx-2">
            <QSwitcher
              selected={valueAbsolute}
              options={btnsDisplayType}
              onOptionSelected={setValueAbsolute}
              padding="0.25rem 1rem"
              display="inline-flex"
              width="auto"
            />
          </span>
        )}
        <span className="mx-2">
          <QSwitcher
            selected={analysisAxis}
            options={analysisAxisSwitcherOptions}
            onOptionSelected={setAnalysisAxis}
            borderradius="2em"
            padding="0.25rem 1rem"
            display="inline-flex"
            width="auto"
          />
        </span>
      </SwitcherContainer>
      <Row className="" style={{ height: '100%' }}>
        <EindexEvolutionWithMentionsSection
          mixBarLine={props.mixBarLine}
          filters={props.filterKeys}
          range={range}
          valueAbsolute={valueAbsolute}
          analysisAxis={analysisAxis}
        />
      </Row>
    </QcardPanel>
  );
};

EmotionEvolutionLineCard.defaultProps = {
  mixBarLine: false,
  multipleEindex: false,
};

const mapStateToProps = (state: any) => ({
  filterKeys: get(state, ['filters', 'filterKeys']),
});

export default connect(mapStateToProps)(EmotionEvolutionLineCard);
