import React from 'react';
import { LayoutContext } from 'Routes/_Utils/LayoutContext';

export default Component =>
  class QcardHeaderContext extends React.Component {
    render() {
      return (
        <LayoutContext.Consumer>
          {value => <Component {...value} {...this.props} />}
        </LayoutContext.Consumer>
      );
    }
  };
