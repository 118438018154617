import gql from 'graphql-tag';

const ThematicsOverviewFragment = gql`
  fragment ThematicsOverviewFragment on ThematicsOverviewItem {
    eindex
    eindexEvolution {
      yearBefore {
        value
        delta
        period {
          startDate
          endDate
        }
      }
      periodBefore {
        value
        delta
        period {
          startDate
          endDate
        }
      }
    }
    mentionEvolution {
      yearBefore {
        value
        delta
        period {
          startDate
          endDate
        }
      }
      periodBefore {
        value
        delta
        period {
          startDate
          endDate
        }
      }
    }
    emotionalIntensity
    emotions {
      anger {
        byMentionCount
        byPercentage
      }
      calm {
        byMentionCount
        byPercentage
      }
      disgust {
        byMentionCount
        byPercentage
      }
      fear {
        byMentionCount
        byPercentage
      }
      happiness {
        byMentionCount
        byPercentage
      }
      sadness {
        byMentionCount
        byPercentage
      }
      surprise {
        byMentionCount
        byPercentage
      }
    }
    emotionsEvolution {
      periodBefore {
        happiness {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        surprise {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        calm {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        fear {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        anger {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        disgust {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        sadness {
          delta
          value
          period {
            startDate
            endDate
          }
        }
      }
      yearBefore {
        happiness {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        surprise {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        calm {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        fear {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        anger {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        disgust {
          delta
          value
          period {
            startDate
            endDate
          }
        }
        sadness {
          delta
          value
          period {
            startDate
            endDate
          }
        }
      }
    }
    sentimentsEvolution {
      periodBefore {
        negative {
          delta
          period {
            startDate
            endDate
          }
          value
        }
        positive {
          delta
          period {
            startDate
            endDate
          }
          value
        }
        neutral {
          delta
          period {
            startDate
            endDate
          }
          value
        }
      }
      yearBefore {
        negative {
          delta
          period {
            startDate
            endDate
          }
          value
        }
        positive {
          delta
          period {
            startDate
            endDate
          }
          value
        }
        neutral {
          delta
          period {
            startDate
            endDate
          }
          value
        }
      }
    }
    sentiments {
      positive {
        byPercentage
        byMentionCount
      }
      negative {
        byPercentage
        byMentionCount
      }
      neutral {
        byPercentage
        byMentionCount
      }
    }
    isSelected
    mentionCount
    name
    speechEngagement
  }
`;

export default ThematicsOverviewFragment;
