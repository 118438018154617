/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { QSelectOption } from '../QSelect/QSelect.propTypes';

export const QFunnelOptions = PropTypes.shape({
  ...QSelectOption,
  parent: PropTypes.string,
});

export const QFunnelCategories = PropTypes.shape({
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(QFunnelOptions),
});
