import React from 'react';
import { connect } from 'react-redux';
import { get, isEqual, uniqWith } from 'lodash';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { resetDataCollectorInProjectConfiguration } from '_Resources/Header/Actions/setProject';
import ProjectFilters from '_Layouts/Header/_Containers/SubHeader/_Containers/HeaderProjectFilters/ProjectFilters';
import { setSelectedFilters } from '_Resources/Header/Actions/setSelectedFilters';

class HeaderProjectFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCriterias: [],
      currentThematics: [],
      currentTexts: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !isEqual(nextProps.criteriaKeysInputTags, this.props.criteriaKeysInputTags) ||
      !isEqual(nextProps.thematicsInputTags, this.props.thematicsInputTags) ||
      !isEqual(nextProps.projectId, this.props.projectId)
    ) {
      this.setState({
        currentCriterias: [],
        currentThematics: [],
        currentTexts: [],
      });
      this.sendNewFiltersToGlobalState(nextProps, nextState, nextProps.projectId !== this.props.projectId);
      return true;
    }
    if (
      !isEqual(nextState.currentCriterias, this.state.currentCriterias) ||
      !isEqual(nextState.currentThematics, this.state.currentThematics) ||
      !isEqual(nextState.currentTexts, this.state.currentTexts)
    ) {
      this.sendNewFiltersToGlobalState(nextProps, nextState, nextProps.projectId !== this.props.projectId);
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.state.currentCriterias, prevState.currentCriterias) ||
      !isEqual(this.state.currentThematics, prevState.currentThematics) ||
      !isEqual(prevProps.idPage, this.props.idPage)
    ) {
      this.sendNewFiltersToGlobalState(this.props, this.state, prevProps.projectId !== this.props.projectId);
    }
  }

  mergeIdenticalFilters(filters) {
    const groups = {};
    for (let i = 0; i < filters.length; i++) {
      const groupName = filters[i].name;
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(filters[i].values instanceof Array ? filters[i].values[0] : filters[i].values);
    }
    const filtersListArray = [];
    for (const groupName in groups) {
      filtersListArray.push({ name: groupName, values: groups[groupName] });
    }
    return filtersListArray;
  }

  setCurrentInputTags(filters) {
    const uniquesThematics = uniqWith(filters.thematics, (a, b) => isEqual(a, b));

    const requestCriteriaNormal =
      typeof filters.normal !== 'undefined' ? this.mergeIdenticalFilters(filters.normal) : [];
    const requestCriteriaRelated =
      typeof filters.related !== 'undefined' ? this.mergeIdenticalFilters(filters.related) : [];
    const requestCriteriaText = typeof filters.text !== 'undefined' ? this.mergeIdenticalFilters(filters.text) : [];
    const currentCriterias = requestCriteriaNormal.concat(requestCriteriaRelated);
    this.setState({
      currentCriterias,
      currentThematics: uniquesThematics || [],
      currentTexts: requestCriteriaText || [],
    });
  }

  sendNewFiltersToGlobalState(nextProps, nextState, resetDataCollectorId = false) {
    const { range, projectId } = nextProps;
    const { currentCriterias: criteriaKeys, currentThematics: thematics, currentTexts: texts } = nextState;
    let thematicSpecificPage = null;
    if (nextProps.defaultThematic) {
      const correspondingThematic = get(nextProps, ['pageConfiguration', 'defaultThematic']);
      if (correspondingThematic) {
        thematicSpecificPage = [
          {
            thematic: correspondingThematic,
            subThematic: '',
            subSubThematic: '',
          },
          ...thematics,
        ];
      }
    }

    const currentFiltersFromLocalStorage = JSON.parse(window.localStorage.getItem('filters'));

    const currentDataCollectorIdFromLocalStorage =
      (currentFiltersFromLocalStorage && currentFiltersFromLocalStorage.dataCollectorId) || null;

    const arr = {
      range,
      projectId,
      criteriaKeys,
      thematics: thematicSpecificPage || thematics,
      texts,
      dataCollectorId: resetDataCollectorId ? null : currentDataCollectorIdFromLocalStorage,
    };
    this.props.setGlobalQueryFilterKeys(arr);
  }

  getInitialValues = () => {
    // if keywords search in NIT, then app breaks (blank screen).
    // No solution found but react-nested-input-tags seems to be the problem according to the browser errors,
    // so filtering keywords search in initialValues
    // TODO: find a proper solution to keep keywords search when changing modules
    const filteredInitialValues = this.props.selectedFilters?.filter(
      value => !value.parents.includes('textSearch') && !value.parents.includes('keywords'),
    );

    return filteredInitialValues;
  };

  render() {
    const { dispatchSetSelectedFilters } = this.props;
    return (
      <ProjectFilters
        projectId={this.props.projectId}
        thematicsInputTags={this.props.thematicsInputTags}
        normalCriteriaKeys={get(this.props, ['criteriaKeysInputTags', 'normal'])}
        relatedCriteriaKeys={get(this.props, ['criteriaKeysInputTags', 'related'])}
        keywordSearch={this.props.idPage !== 'verbatim-list'}
        onSearch={filters => this.setCurrentInputTags(filters)}
        dispatchSetSelectedFilters={dispatchSetSelectedFilters}
        initialValues={this.getInitialValues()}
      />
    );
  }
}
function mapStateToProps(state) {
  return {
    projectId: state.projects.currentProject && state.projects.currentProject.id,
    idPage: state.routes.subRoute && state.routes.subRoute.id,
    defaultThematic: state.routes.defaultThematic,
    range: state.periods.currentPeriod,
    pageConfiguration: state.routes.pageConfiguration,
    dataCollectorId: get(state, ['filters', 'filterKeys', 'dataCollectorId']),
    filters: get(state, ['filters', 'filterKeys']),
    lastDataCollectorId: get(state, ['projectConfiguration', 'lastDataCollectorId']),
    selectedFilters: get(state, ['filters', 'selectedFilters']),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setGlobalQueryFilterKeys: newState => {
      dispatch(setGlobalQueryFilterKeys(newState));
    },
    resetDataCollectorInProjectConfiguration: () => {
      dispatch(resetDataCollectorInProjectConfiguration());
    },
    dispatchSetSelectedFilters: selected => {
      dispatch(setSelectedFilters(selected));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderProjectFilters);
