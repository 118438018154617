import PropTypes from 'prop-types';

export default {
  selectedSentences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      order: PropTypes.number,
      sentence: PropTypes.string,
    }),
  ).isRequired,
  verbatim: PropTypes.shape({
    sentences: PropTypes.arrayOf({
      sentence: PropTypes.string,
      order: PropTypes.number,
    }),
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};
