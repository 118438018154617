import gql from 'graphql-tag';

const query = gql`
  query getMap(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $dataCollectorId: Int
  ) {
    results: getMap(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dataCollectorId: $dataCollectorId
    ) {
      markers {
        id
        name
        results {
          eindex
          mentionCount
          reco
          sat
          ces
        }
        coordinates {
          lat
          long
        }
        filters {
          normal {
            label
            values
          }
          thematic {
            thematic
            subThematic
            subSubThematic
          }
        }
      }
      indicators {
        sat
        reco
        ces
      }
    }
  }
`;
export default query;
