import { isFunction } from 'lodash';

export const exportTaskRunner = {
  task: null,

  scheduleExportTask(task) {
    this.task = task;
  },

  execute() {
    if (this.task && isFunction(this.task)) {
      const { task } = this;
      this.task = null;
      return task();
    }

    return undefined;
  },
};
