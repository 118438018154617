import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { reverse } from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const MenuDate = styled.div`
  overflow: auto;
  max-height: 12rem;
  padding: 0;
`;

const MenuDateItem = styled.div`
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 1.25rem;
  cursor: pointer;
  &:hover {
    background-color: $gray-200;
  }
`;

const PERIODS = {
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
};

const getDatesByRange = periods => {
  const { byYear, byQuarter, byMonth } = periods;
  return {
    [PERIODS.MONTH]: byMonth,
    [PERIODS.QUARTER]: byQuarter,
    [PERIODS.YEAR]: byYear,
  };
};

const PeriodRangeHeader = ({
  chooseLabel,
  dateRangeChosen,
  periods,
  onMenuItemClick,
  t,
}) => {
  const datesByRange = getDatesByRange(periods);
  const currentPeriod = datesByRange[dateRangeChosen];
  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col className="p-0dot5">
            <h6 className="font-weight-bold">
              {chooseLabel} {t(`a ${dateRangeChosen}`)}
            </h6>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <MenuDate>
          {reverse(
            currentPeriod.map(period => (
              <MenuDateItem onClick={() => onMenuItemClick(period)}>
                {period.name}
              </MenuDateItem>
            )),
          )}
        </MenuDate>
      </Col>
    </Row>
  );
};

PeriodRangeHeader.propTypes = {
  chooseLabel: PropTypes.string.isRequired,
  dateRangeChosen: PropTypes.string.isRequired,
  periods: PropTypes.any.isRequired,
};

export default withTranslation('text')(PeriodRangeHeader);
