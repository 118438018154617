import menuItem from '_Layouts/Header/_Containers/HeaderTop/menuItems';
import { find, isEmpty } from 'lodash';

export function getRoute(callback, idpage, thematic = null, projectConfig = null) {
  const path = {};
  menuItem.some(parent => {
    if (parent.pages) {
      parent.pages.some(page => {
        if (page.id === idpage) {
          path.route = {
            name: parent.name,
            title: parent.title,
            icon: parent.icon,
          };
          path.subRoute = page;
          path.defaultThematic = thematic;
          if (!isEmpty(projectConfig)) {
            path.pageConfiguration = find(find(projectConfig.moduleSettings, { moduleId: thematic })?.pageSettings, {
              pageId: idpage,
            });
          }
          return true;
        }
      });
    }
  });
  callback(path);
}
