import React from 'react';
import styled from 'styled-components';
import { BasePropTypes } from '../../../_Utils/common.propTypes';
import { grayShades } from '../../../styles/abstracts/colors';

const Area = styled.div`
  padding-bottom: 1em;
`;

const Label = styled.div`
  padding: 0.5em 0 0.2em 0 0;
  color: ${grayShades.g600};
  font-weight: 700;
`;

const Control = styled.div``;

const CheckboxControl = styled.div`
  display: flex;
  place-content: center flex-start;
  align-items: center;
  padding-bottom: 0.5em;
`;

const QFieldGroup = ({ children, className }) => (
  <Area className={className}>{children}</Area>
);

QFieldGroup.propTypes = {
  ...BasePropTypes,
};

QFieldGroup.Label = ({ children, className }) => (
  <Label className={className}>{children}</Label>
);

QFieldGroup.Label.propTypes = {
  ...BasePropTypes,
};

QFieldGroup.Control = ({ children, className }) => (
  <Control className={className}>{children}</Control>
);

QFieldGroup.Control.propTypes = {
  ...BasePropTypes,
};

QFieldGroup.CheckboxControl = ({ children, className }) => (
  <CheckboxControl className={className}>{children}</CheckboxControl>
);

QFieldGroup.CheckboxControl.propTypes = {
  ...BasePropTypes,
};

export default QFieldGroup;
