import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import { get } from 'lodash';
import SideCardContainer from 'Routes/_Containers/DataByFiltersGeneric/_Containers/SideCardContainer';
import DataByThematicsCard from './DataByThematicsCard';
import SAVE_THEMATICS_OVERVIEW_SETTINGS from '../graphql/saveThematicsOverviewSettings';
import { INDICATORS_CONFIG, ORDER_BY_ITEMS } from './utils';

type DataByThematicsProps = {
  data: any[];
  projectId: number;
  refetch: any;
  settings: any;
  setSettings: any;
};

const DataByThematics = ({ data, projectId, refetch, settings, setSettings }: DataByThematicsProps) => {
  const [saveThematicsOverviewSettings] = useMutation(SAVE_THEMATICS_OVERVIEW_SETTINGS, {
    variables: { projectId, settings: JSON.stringify(settings) },
    update: () => {},
  });
  const [displaySideCard, setDisplaySideCard] = useState(false);
  const [unmountSideCard, setUnmountSideCard] = useState(true);
  const [cardFilters, setCardFilters] = useState({});
  const [sideCardTitle, setSideCardTitle] = useState('');
  const [mentions, setMentions] = useState(0);
  useEffect(() => {
    saveThematicsOverviewSettings();
  }, [settings]);

  const toggleDisplaySideCard = () => {
    setDisplaySideCard(!displaySideCard);
    setTimeout(() => setUnmountSideCard(!unmountSideCard), unmountSideCard ? 0 : 500);
  };

  const propsToPass = {
    data: data
      .map(item => ({
        ...item.thematicsOverviewDetails,
        hierarchy: {
          thematic: item.thematic || item.thematicsOverviewDetails.name,
          subThematic: item.subThematic || (item.subSubThematics ? item.thematicsOverviewDetails.name : null),
          subSubThematic: item.thematic && item.subThematic ? item.thematicsOverviewDetails.name : null,
          joinSpecify: 'and',
        },
        filter: item.subThematics ? 'thematics' : item.subSubThematics ? 'subThematics' : 'subSubThematics',
      }))
      .filter(item => item.mentionCount > 0 && item.eindex !== null),
    settings,
    refetch,
    setSettings,
    mutation: saveThematicsOverviewSettings,
    indicatorsConfig: INDICATORS_CONFIG,
    defaultSettings: settings.dataByThematics,
    filtersToDisplay: [
      {
        id: 'thematics',
        label: 'Categories',
      },
      {
        id: 'subThematics',
        label: 'Subcategories',
      },
      {
        id: 'subSubThematics',
        label: 'Sub sub categories',
      },
    ],
    orderByItems: ORDER_BY_ITEMS,
    setSideCardTitle,
    setCardFilters,
    setMentions,
    toggleDisplaySideCard,
  };

  return (
    <>
      <DataByThematicsCard {...propsToPass} />
      <SideCardContainer
        toggleDisplaySideCard={toggleDisplaySideCard}
        displaySideCard={displaySideCard}
        unmountSideCard={unmountSideCard}
        filters={cardFilters}
        cardTitle={sideCardTitle}
        mentions={mentions}
        closeCard={() => this.toggleDisplaySideCard()}
        isCardOpened={displaySideCard}
        disableBPPTab
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
});

export default connect(mapStateToProps)(DataByThematics);
