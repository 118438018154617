import React, { PureComponent } from 'react';
import styled from 'styled-components';
import QPanelHeader from '../../../../../../../_Components/Panels/QPanelHeader/QPanelHeader';
import InsightPanel from '../InsightPanel/InsightPanel';

import Icon from '_Components/Icons/Icon';
import { withTranslation } from 'react-i18next';
import { radius } from '../../../../../../../styles/abstracts/variables';

const InsightWallModeSwitch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const InsightWallModeSwitchItem = styled.div`
  margin: 0;
  background-color: ${({ selected }) => (selected ? '#2e096b' : 'white')};
  cursor: pointer;
  border: none;
  border-top-left-radius: ${({ left }) => left && radius.default};
  border-bottom-left-radius: ${({ left }) => left && radius.default};
  border-top-right-radius: ${({ right }) => right && radius.default};
  border-bottom-right-radius: ${({ right }) => right && radius.default};
  color: ${({ selected }) => (selected ? 'white' : 'inherit')};
  padding: 0.2em 0.5em;
  text-align: center;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #592ea0;
  }
`;

const ModeLabel = styled.span`
  margin: 0 0.5em;
`;

class ModeTypeSwitcher extends PureComponent {
  onAllClick = () => {
    this.props.onValueSelected('all');
  };

  onModeClick = () => {
    this.props.onValueSelected('module');
  };

  render() {
    const { className, t } = this.props;
    return (
      <InsightPanel className={className}>
        <QPanelHeader contentHeader>{t('View mode')}</QPanelHeader>
        <InsightWallModeSwitch>
          <InsightWallModeSwitchItem
            selected={this.props.selected === 'all'}
            left
            onClick={this.onAllClick}
          >
            <Icon
              icon="INSIGHTWALL_ALL"
              size={18}
              color={this.props.selected === 'module' ? '#2e096b' : 'white'}
            />
            <ModeLabel>{t('All')}</ModeLabel>
          </InsightWallModeSwitchItem>
          <InsightWallModeSwitchItem
            selected={this.props.selected === 'module'}
            right
            onClick={this.onModeClick}
          >
            <ModeLabel>{t('Module')}</ModeLabel>
            <Icon
              icon="INSIGHTWALL_MODULE"
              size={18}
              color={this.props.selected === 'all' ? '#2e096b' : 'white'}
            />
          </InsightWallModeSwitchItem>
        </InsightWallModeSwitch>
      </InsightPanel>
    );
  }
}
export default withTranslation('button')(ModeTypeSwitcher);
