import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { lightBlue, white } from '../../../../styles/abstracts/colors';
import { ImageSize, spacing } from '../../../../styles/abstracts/variables';
import IconCircle from '../../../../_Components/Icons/IconCircle/IconCircle';

const ActionList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  top: ${spacing.large};
  right: -${ImageSize.small}px;
`;

const Action = styled.div`
  margin: ${spacing.xsmall} 0;
  cursor: pointer;
`;

const PageActions = ({ onDuplicateClick, onRemoveClick }) => (
  <ActionList>
    <Action onClick={onDuplicateClick}>
      <IconCircle
        icon="DUPLICATE"
        bgColor={lightBlue}
        color={white}
        size={ImageSize.small}
      />
    </Action>
    <Action onClick={onRemoveClick}>
      <IconCircle
        icon="TRASH"
        bgColor={lightBlue}
        color={white}
        size={ImageSize.small}
      />
    </Action>
  </ActionList>
);

PageActions.propTypes = {
  onDuplicateClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export default PageActions;
