import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { storeUserInfos } from '_Resources/Auth/Actions/auth';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { hex2rgbaConvert } from '_Utils/styles/colors';
import { withTranslation } from 'react-i18next';
import Icon from '_Components/Icons/Icon';
import QPanel from '../../../_Components/Panels/QPanel/QPanel';
import { grayShades, black, purple, fear } from '../../../styles/abstracts/colors';
import QPanelHeader from '../../../_Components/Panels/QPanelHeader/QPanelHeader';
import QButton from '../../../_Components/Buttons/QButton/QButton';
import PersonalInfoSection from './_Component/PersonalInfoSection';
import PreferencesSection from './_Component/PreferencesSection';
import SAVE_SETTINGS from './graphql/saveSettings';
import currentUser from '../../../_Resources/Header/Services/api/graphql/currentUser';
import queryEIndex from '../../../_Resources/Header/Services/api/graphql/getProjectConfigurationData';

import { getProjectConfiguration } from '../../../_Resources/Header/Actions/getProjectConfiguration';

const StyledQPanel = styled(QPanel)`
  padding: 0;
`;
const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 0;
  border-bottom: 1px solid ${grayShades.g300};
`;
export const MessageValidation = styled.span`
  display: inline-block !important;
  margin: 0 1rem 1rem;
  padding: 1rem;
  border-radius: 4rem;
  color: ${fear};
  background-color: ${hex2rgbaConvert(fear, 20)};
  font-weight: bold;
  font-size: 0.75rem;
`;
const ButtonSave = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  & button {
    background-color: ${purple};
    &:hover {
      background-color: ${black};
    }
  }
`;

export const StyledValidationIcon = styled(Icon)`
  margin-right: 1rem;
`;
class AccountAndPreferencesCard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    storeUserInfos: PropTypes.func.isRequired,
    getProjectConfiguration: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      changeLanguage: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      personal: '',
      preferences: '',
      validated: false,
    };
  }

  getStatePersonal(props) {
    this.setState({
      personal: props,
    });
  }

  setStatePreferences(props) {
    this.setState({
      preferences: props,
    });
  }

  updateDataSettings(data) {
    const { id, firstName, lastName, lang, isEindexUnitFahrenheit, expireAt, verbatimsLocale, analysisAxis } = data;
    const {
      isAdmin,
      account,
      hasEmailValidated,
      phoneNumber,
      storeUserInfos,
      getProjectConfiguration,
      i18n,
    } = this.props;
    storeUserInfos({
      id,
      firstName,
      lastName,
      lang: lang || window.localStorage.getItem('lang'),
      verbatimsLocale: verbatimsLocale || window.localStorage.getItem('verbatimsLocale'),
      isAdmin,
      account,
      hasEmailValidated,
      isEindexUnitFahrenheit,
      analysisAxis,
      phoneNumber,
      expireAt,
    });
    getProjectConfiguration({
      unitsAndLocalesSettings: { eindexUnitFahrenheit: isEindexUnitFahrenheit },
      analysisAxis,
    });
    lang && i18n.changeLanguage(lang);
    lang && window.localStorage.setItem('lang', lang);
    verbatimsLocale && window.localStorage.setItem('verbatimsLocale', verbatimsLocale);
    analysisAxis && window.localStorage.setItem('analysisAxis', analysisAxis);
    this.setState({ validated: true });
    setTimeout(() => {
      this.setState({ validated: false });
    }, 5000);
  }

  render() {
    const { t, id } = this.props;
    const { personal, preferences, validated } = this.state;
    const variablesSettings = {
      id,
      firstName: personal.firstname,
      lastName: personal.lastname,
      lang: preferences.language,
      verbatimsLocale: preferences.verbatimsLocale,
      isEindexUnitFahrenheit: preferences.menuOption,
      analysisAxis: preferences.analysisAxis,
      phoneNumber: personal.phoneNumber,
      expireAt: personal.expireAt,
    };
    return (
      <StyledQPanel>
        <StyledQPanelHeader>
          <PersonalInfoSection {...this.props} callback={state => this.getStatePersonal(state)} />
          <PreferencesSection {...this.props} callback={state => this.setStatePreferences(state)} />
          {validated && (
            <MessageValidation>
              <StyledValidationIcon icon="CHECK_ALL" color={fear} size={24} /> {t('card:Settings updated')}
            </MessageValidation>
          )}
        </StyledQPanelHeader>
        <ButtonSave>
          <Mutation
            mutation={SAVE_SETTINGS}
            update={(cache, { data: { saveUserSettings } }) => {
              const {
                firstName,
                lastName,
                lang,
                verbatimsLocale,
                isEindexUnitFahrenheit,
                analysisAxis,
                phoneNumber,
                expireAt,
              } = variablesSettings;
              globalThis.localStorage.setItem('firstName', saveUserSettings.currentUser.firstName);
              globalThis.localStorage.setItem('lastName', saveUserSettings.currentUser.lastName);
              globalThis.localStorage.setItem('lang', saveUserSettings.currentUser.lang);
              globalThis.localStorage.setItem('verbatimsLocale', saveUserSettings.currentUser.verbatimsLocale);
              globalThis.localStorage.setItem('analysisAxis', saveUserSettings.currentUser.analysisAxis);
              globalThis.localStorage.setItem('expireAt', saveUserSettings.currentUser.expireAt);
              cache.writeQuery({
                query: currentUser,
                variables: {
                  firstName,
                  lastName,
                  lang: lang.value,
                  verbatimsLocale: verbatimsLocale.value,
                  isEindexUnitFahrenheit,
                  analysisAxis,
                  phoneNumber,
                  expireAt,
                },
                data: {
                  currentUser: saveUserSettings.currentUser,
                },
              });
              cache.writeQuery({
                query: queryEIndex,
                variables: {
                  isEindexUnitFahrenheit,
                },
                data: {
                  results: {
                    unitsAndLocalesSettings: saveUserSettings.unitsAndLocalesSettings,
                  },
                },
              });
            }}
            onCompleted={() => this.updateDataSettings(variablesSettings)}
          >
            {(saveUserSettings, { data }) => (
              <QButton
                onClick={() => {
                  saveUserSettings({
                    variables: {
                      firstName: personal.firstname,
                      lastName: personal.lastname,
                      lang: preferences.language && preferences.language.value,
                      verbatimsLocale: preferences.verbatimsLocale && preferences.verbatimsLocale.value,
                      isEindexUnitFahrenheit: preferences.menuOption,
                      analysisAxis: preferences.analysisAxis,
                      phoneNumber: personal.phoneNumber,
                      expireAt: personal.expireAt,
                    },
                  });
                }}
              >
                {t('button:Save Settings')}
              </QButton>
            )}
          </Mutation>
        </ButtonSave>
      </StyledQPanel>
    );
  }
}
function mapStateToProps(state) {
  return {
    id: _.get(state, ['auth', 'user', 'id']),
    lastName: _.get(state, ['auth', 'user', 'lastName']),
    firstName: _.get(state, ['auth', 'user', 'firstName']),
    lang: _.get(state, ['auth', 'user', 'lang']),
    eindexUnit: _.get(state, ['auth', 'user', 'isEindexUnitFahrenheit']),
    isAdmin: _.get(state, ['auth', 'user', 'isAdmin']),
    account: _.get(state, ['auth', 'user', 'account']),
    phoneNumber: _.get(state, ['auth', 'user', 'phoneNumber']),
    hasEmailValidated: _.get(state, ['auth', 'user', 'hasEmailValidated']),
    expireAt: _.get(state, ['auth', 'user', 'expireAt']),
    verbatimsLocale: _.get(state, ['auth', 'user', 'verbatimsLocale']),
    analysisAxis: _.get(state, ['auth', 'user', 'analysisAxis']),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    storeUserInfos: newState => {
      dispatch(storeUserInfos(newState));
    },
    getProjectConfiguration: newState => {
      dispatch(getProjectConfiguration(newState));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('button', 'card')(AccountAndPreferencesCard));
