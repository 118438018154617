import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QButton from '../../../_Components/Buttons/QButton/QButton';
import QInput from '../../../_Components/Fields/QInput/QInput';

const AddButton = styled(QButton)`
  max-width: 120px;
  &::before {
    content: '+';
    margin-right: 1rem;
  }
`;

const StyledInput = styled(QInput)`
  width: 100%;
  margin-bottom: 1rem;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  padding: 1rem;
`;

class QFilterSearchKeyword extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  state = {
    inputValue: '',
  };

  onInputChange = ({ target: { value } }) => {
    this.setState({ inputValue: value });
  };

  onButtonClicked = () => {
    const { onSelect } = this.props;
    const { inputValue } = this.state;
    onSelect(inputValue, inputValue);
  };

  render() {
    const { t } = this.props;
    const { inputValue } = this.state;

    return (
      <Body>
        <StyledInput
          placeholder={t('Search keywords')}
          value={inputValue}
          onChange={this.onInputChange}
        />
        <AddButton disabled={!inputValue} onClick={this.onButtonClicked}>
          <span>{t('Add')}</span>
        </AddButton>
      </Body>
    );
  }
}

export default withTranslation(['text', 'button'])(QFilterSearchKeyword);
