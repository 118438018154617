import gql from 'graphql-tag';

const query = gql`
  query insight($insightId: Int!) {
    insight(insightId: $insightId) {
      insightId
      selectedVerbatim {
        id
        createdOn
        fullText
        sentences {
          sentence
          order
          id
          relevant
          results {
            mentions
            eindex
            sat
            reco
            sensitivity
            strongestEmotion
            emotions {
              happiness
              surprise
              calm
              fear
              sadness
              anger
              disgust
            }
          }
        }
      }
    }
  }
`;

export default query;
