import React, { useState } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import CModal from '_Components/CModal/CModal';
import { QSideBarLayout } from '_Components/Layouts/QSideBarLayout';
import QButton from '_Components/Buttons/QButton/QButton';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import { ImageSize } from 'styles/abstracts/variables';
import Icon from '_Components/Icons/Icon';
import { setHorizontalLevelToDisplay } from '_Resources/Benchmark/Actions/setHorizontalLevelToDisplay';
import { setAspectToDisplay } from '_Resources/Benchmark/Actions/setAspectToDisplay';
import { setUnitToDisplay } from '_Resources/Benchmark/Actions/setUnitToDisplay';
import { setOnlySelectedCategories } from '_Resources/Benchmark/Actions/setOnlySelectedCategories';
import { ORDER_BY_ITEMS } from '_Cards/ThematicsCard/ThematicsOverviewCards/ThematicsHorizontalChartCard/utils';
import { setHorizontalCategoriesOrder } from '_Resources/Benchmark/Actions/setHorizontalCategoriesOrder';
import BenchmarkHorizontalGraphOrderByDropdown from './BenchmarkHorizontalGraphOrderByDropdown';
import { grayShades } from '../../../../../styles/abstracts/colors';

const StyledCModal = styled(CModal)`
  max-width: 500px !important;
`;

const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  margin: -16px;
`;

const QSideBarLayoutDisplayArea = styled.div`
  flex-grow: 1;
  /* overflow: hidden; */
`;

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  right: 1em;
  top: 1em;
  padding: 0 !important;
`;

const SettingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em;
  font-weight: bold;
`;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 13rem;
`;

const LabelContainer = styled.div`
  display: flex;
`;

const StyledSpan = styled.span`
  margin-left: 1em;

  &:hover > div {
  visibility: visible;
`;
const TooltipThematic = styled.div`
  visibility: hidden;
  max-width: 20em;
  position: absolute;
  top: 0px;
  z-index: 30;
  background-color: ${grayShades.g200};
  text-align: center;
  padding: 5px 4px;
  border-radius: 8px;
  color: ${grayShades.g800};
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
  width: max-content;
  font-size: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 12px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${grayShades.g200} transparent transparent transparent;
  }
`;

type BenchmarkHorizontalGraphSettingsModalProps = {
  onClose: any;
  isOpen: boolean;
  t: any;
  dispatchSetHorizontalLevelToDisplay: any;
  dispatchSetAspectToDisplay: any;
  dispatchSetUnitToDisplay: any;
  dispatchSetOnlySelectedCategories: any;
  benchmarkHorizontalLevelToDisplay: string;
  benchmarkAspectToDisplay: string;
  benchmarkUnitToDisplay: string;
  benchmarkOnlySelectedCategories: boolean;
  benchmarkHorizontalCategoriesOrder: { id: string; label: string };
  dispatchSetHorizontalCategoriesOrder: Function;
};

const BenchmarkHorizontalGraphSettingsModal = ({
  onClose,
  isOpen,
  t,
  dispatchSetHorizontalLevelToDisplay,
  dispatchSetAspectToDisplay,
  dispatchSetUnitToDisplay,
  dispatchSetOnlySelectedCategories,
  benchmarkHorizontalLevelToDisplay,
  benchmarkAspectToDisplay,
  benchmarkUnitToDisplay,
  benchmarkOnlySelectedCategories,
  benchmarkHorizontalCategoriesOrder,
  dispatchSetHorizontalCategoriesOrder,
}: BenchmarkHorizontalGraphSettingsModalProps) => {
  const [onlySelectedCategories, setLocalOnlySelectedCategories] = useState(benchmarkOnlySelectedCategories || false);

  const [isLevelDropdownOpen, openLevelDropdown] = useState<boolean>(false);
  const [isAspectDropdownOpen, openAspectDropdown] = useState<boolean>(false);
  const [isUnitDropdownOpen, openUnitDropdown] = useState<boolean>(false);

  const buttonRight = (
    <StyledQButton
      bgColor="greyShades.g300"
      type="button"
      onClick={() => onClose()}
      size="small"
      icon="CROSS"
      iconSize={24}
    />
  );

  const levelsToDisplay = [
    {
      id: 'thematics',
      label: 'Categories',
    },
    {
      id: 'subThematics',
      label: 'Subcategories',
    },
    {
      id: 'subSubThematics',
      label: 'Sub sub categories',
    },
  ];

  const availableAspects = [
    {
      id: 'emotions',
      label: 'Emotions',
    },
    {
      id: 'sentiment',
      label: 'Sentiment',
    },
  ];

  const availableUnits = [
    {
      id: 'volume',
      label: 'Volume',
    },
    {
      id: 'percentage',
      label: 'Percentage',
    },
  ];

  const [currentLevel, setCurrentLevel] = useState(
    levelsToDisplay.find(opt => opt.id === benchmarkHorizontalLevelToDisplay) || levelsToDisplay[1],
  );

  const [currentAnalysisAxis, setCurrentAnalysisAxis] = useState(
    availableAspects.find(opt => opt.id === benchmarkAspectToDisplay) || availableAspects[0],
  );

  const [currentUnit, setCurrentUnit] = useState(
    availableUnits.find(opt => opt.id === benchmarkUnitToDisplay) || availableUnits[0],
  );

  const [currentOrder, setCurrentOrder] = useState(
    benchmarkHorizontalCategoriesOrder || ORDER_BY_ITEMS[0].subcategories[0],
  );

  const onValidate = () => {
    dispatchSetHorizontalLevelToDisplay(currentLevel.id);
    dispatchSetAspectToDisplay(currentAnalysisAxis.id);
    dispatchSetUnitToDisplay(currentUnit.id);
    dispatchSetOnlySelectedCategories(onlySelectedCategories);
    dispatchSetHorizontalCategoriesOrder(currentOrder);
  };

  const getFooter = () => {
    return (
      <ModalFooter>
        <QButton onClick={() => onClose()} size="big" bgColor="gray">
          {t('Cancel')}
        </QButton>
        <QButton
          onClick={() => {
            onValidate();
            onClose();
          }}
          size="big"
          bgColor="purple"
        >
          {t('Validate')}
        </QButton>
      </ModalFooter>
    );
  };

  return (
    <>
      <StyledCModal
        data={{}}
        title={t('Settings')}
        displayMentionsSubtitle={false}
        displayCardSubtitle={false}
        displayDateSubtitle={false}
        modal={isOpen}
        footer={getFooter()}
        toggle={onClose}
        buttonRight={buttonRight}
        centered
      >
        <Body>
          <HeaderArea />
          <QSideBarLayout>
            <QSideBarLayoutDisplayArea>
              <SettingDiv>
                <Label>{t('Level to display')}</Label>
                <StyledDropdown
                  direction="down"
                  isOpen={isLevelDropdownOpen}
                  toggle={() => openLevelDropdown(!isLevelDropdownOpen)}
                >
                  <StyledDropdownToggle>
                    <StyledToggleButton disableHover bgColor="grayShades.g300">
                      {t(currentLevel.label)}
                      <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
                    </StyledToggleButton>
                  </StyledDropdownToggle>
                  <Menu className="dataByFiltersDropdownMenu">
                    {levelsToDisplay.map(option => {
                      return (
                        <Item
                          disabled={currentLevel.id === option.id}
                          onClick={() => {
                            setCurrentLevel(option);
                          }}
                        >
                          <span>{t(option.label)}</span>
                        </Item>
                      );
                    })}
                  </Menu>
                </StyledDropdown>
              </SettingDiv>
              <SettingDiv>
                <Label>{t('Analysis axis')}</Label>
                <StyledDropdown
                  direction="down"
                  isOpen={isAspectDropdownOpen}
                  toggle={() => openAspectDropdown(!isAspectDropdownOpen)}
                >
                  <StyledDropdownToggle>
                    <StyledToggleButton disableHover bgColor="grayShades.g300">
                      {t(currentAnalysisAxis.label)}
                      <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
                    </StyledToggleButton>
                  </StyledDropdownToggle>
                  <Menu className="dataByFiltersDropdownMenu">
                    {availableAspects.map(option => {
                      return (
                        <Item
                          disabled={currentAnalysisAxis.id === option.id}
                          onClick={() => {
                            setCurrentAnalysisAxis(option);
                          }}
                        >
                          <span>{t(option.label)}</span>
                        </Item>
                      );
                    })}
                  </Menu>
                </StyledDropdown>
              </SettingDiv>
              <SettingDiv>
                <Label>{t('Unit to display')}</Label>
                <StyledDropdown
                  direction="down"
                  isOpen={isUnitDropdownOpen}
                  toggle={() => openUnitDropdown(!isUnitDropdownOpen)}
                >
                  <StyledDropdownToggle>
                    <StyledToggleButton disableHover bgColor="grayShades.g300">
                      {t(currentUnit.label)}
                      <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
                    </StyledToggleButton>
                  </StyledDropdownToggle>
                  <Menu className="dataByFiltersDropdownMenu">
                    {availableUnits.map(option => {
                      return (
                        <Item
                          disabled={currentUnit.id === option.id}
                          onClick={() => {
                            setCurrentUnit(option);
                          }}
                        >
                          <span>{t(option.label)}</span>
                        </Item>
                      );
                    })}
                  </Menu>
                </StyledDropdown>
              </SettingDiv>
              <BenchmarkHorizontalGraphOrderByDropdown
                id="orderByDropdownContainer"
                label="Sort by"
                itemToIterate={ORDER_BY_ITEMS}
                setCurrentOrder={setCurrentOrder}
                defaultOrder={currentOrder}
              />
              <SettingDiv>
                <LabelContainer>
                  <Label>{t('Selected categories')}</Label>
                  <StyledSpan role="presentation">
                    <Icon icon="INTERFACE_INFO" size={20} color={grayShades.g400} />
                    <TooltipThematic
                      dangerouslySetInnerHTML={{
                        __html: t('benchmark:Only selected categories switch hint'),
                      }}
                    />
                  </StyledSpan>
                </LabelContainer>
                <QBooleanSwitch
                  onChanged={() => {
                    setLocalOnlySelectedCategories(!onlySelectedCategories);
                  }}
                  value={onlySelectedCategories}
                />
              </SettingDiv>
            </QSideBarLayoutDisplayArea>
          </QSideBarLayout>
        </Body>
      </StyledCModal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  benchmarkHorizontalLevelToDisplay: get(state, ['benchmark', 'horizontalLevelToDisplay']),
  benchmarkAspectToDisplay: get(state, ['benchmark', 'aspectToDisplay']),
  benchmarkUnitToDisplay: get(state, ['benchmark', 'unitToDisplay']),
  benchmarkOnlySelectedCategories: get(state, ['benchmark', 'onlySelectedCategories']),
  benchmarkHorizontalCategoriesOrder: get(state, ['benchmark', 'horizontalCategoriesOrder']),
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetHorizontalLevelToDisplay: (newState: any) => {
    dispatch(setHorizontalLevelToDisplay(newState));
  },
  dispatchSetAspectToDisplay: (newState: any) => {
    dispatch(setAspectToDisplay(newState));
  },
  dispatchSetUnitToDisplay: (newState: any) => {
    dispatch(setUnitToDisplay(newState));
  },
  dispatchSetOnlySelectedCategories: (newState: any) => {
    dispatch(setOnlySelectedCategories(newState));
  },
  dispatchSetHorizontalCategoriesOrder: (newState: any) => {
    dispatch(setHorizontalCategoriesOrder(newState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['button', 'benchmark'])(BenchmarkHorizontalGraphSettingsModal));
