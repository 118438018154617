import React from 'react';
import { get, find } from 'lodash';
import CQuery from '_Container/QQuery/CQuery';
import styled from 'styled-components';
import DataByFiltersGraph from '_Charts/PoleCharts/CBarGraphChart/DataByFiltersGraph';
import { connect } from 'react-redux';
import query from '../graphql/getDataByFiltersCardData';

const Container = styled.div`
  height: 35em;
`;

class GraphContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datazoom: {
        start: 0,
        end: null,
      },
      data: null,
    };
    this.updateData = this.updateData.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.data !== prevState.data &&
      prevProps.thematic &&
      this.props.thematic &&
      Object.values(prevProps.thematic) !== Object.values(this.props.thematic)
    ) {
      const datazoomWidth =
        typeof window !== 'undefined' &&
        this.state.data &&
        this.state.datazoom.start + document.documentElement.offsetWidth / this.state.data.length;
      const { datazoom } = this.state;
      datazoom.end = datazoom.end !== null ? prevState.datazoom.end : datazoomWidth;
      this.setState({ datazoom });
    }
  }

  updateData(data) {
    this.setState({ data });
  }

  render() {
    const { filterKeys } = this.props;
    const graphFilters = {
      projectId: filterKeys && filterKeys.projectId,
      range: filterKeys && filterKeys.range,
      thematics: this.props.thematic?.thematic ? [this.props.thematic] : filterKeys && filterKeys.thematics,
      criteriaKeys: this.props.filterKeys && this.props.filterKeys.criteriaKeys,
      selectedCriteriaKey: this.props.selectedCriteriaKey,
      keywords:
        filterKeys &&
        get(
          find(filterKeys.texts, item => item.name === 'keywords'),
          'values',
        ),
      dataCollectorId: this.props.dataCollectorId,
    };
    return (
      <Container>
        <CQuery filters={graphFilters} query={query} fetchPolicy="cache-and-network">
          <DataByFiltersGraph
            badgeType={this.props.badgeType}
            barType={this.props.barType}
            barIndicator={this.props.barIndicator}
            orderBy={this.props.orderBy}
            toggleDisplaySideCard={this.props.toggleDisplaySideCard}
            setCardTitle={this.props.setCardTitle}
            datazoom={this.state.datazoom}
            updateDatazoom={datazoom => this.setState({ datazoom })}
            setCardFilters={this.props.setCardFilters}
            updateData={this.updateData}
            dropdownSettings={this.props.dropdownSettings}
          />
        </CQuery>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    dataCollectorId: get(state, ['filters', 'filterKeys', 'dataCollectorId']),
  };
}

export default connect(mapStateToProps)(GraphContainer);
