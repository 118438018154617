import s from '_Charts/KpisCharts/KpiBadges/style.scss';

export function getClassCssRondType(type) {
  let typeRond;
  switch (type) {
    case 'x-small':
    case 'xs':
      typeRond = {
        height: '28px',
        width: '28px',
        'border-radius': '28px',
      };
      break;
    case 'small':
    case 'sm':
      typeRond = {
        height: '40px',
        width: '40px',
        'border-radius': '40px',
      };
      break;
    case 'large':
    case 'lg':
      typeRond = {
        height: '128px',
        width: '128px',
        'border-radius': '128px',
      };
      break;
    case 'medium':
    case 'md':
    default:
      typeRond = {
        height: '70px',
        width: '70px',
        'border-radius': '70px',
        'min-height': '70px',
      };
      break;
  }
  return typeRond;
}

export function getCssRondText(type) {
  let typeRond;
  switch (type) {
    case 'x-small':
    case 'xs':
      typeRond = {
        'font-size': '12px',
        'letter-spacing': 0,
      };
      break;
    case 'small':
    case 'sm':
      typeRond = {
        'font-size': '16px',
        'letter-spacing': 0,
      };
      break;
    case 'large':
    case 'lg':
      typeRond = {
        'font-size': '48px',
      };
      break;
    case 'medium':
    case 'md':
    default:
      typeRond = {
        'font-size': '24px',
      };
      break;
  }
  return typeRond;
}
