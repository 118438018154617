import React, { useState } from 'react';
import styled from 'styled-components';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import NewSourcesModal from './_Components/NewSourcesModal/NewSourcesModal';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`;

const SourcesHeader = ({ linksCount, sourcesCount, setSources, selectedSources, sources, t, useCaseId, industryId }) => {
  const [isModalOpen, openModal] = useState(false);
  return (
    <Container>
      <QTitle>
        {sourcesCount} {t('sources')} ({linksCount} {t('links')})
      </QTitle>
      <QButton hasOpacity onClick={() => openModal(true)}>
        {t('button:Add a new source')}
      </QButton>
      <NewSourcesModal
        isModalOpen={isModalOpen}
        openModal={openModal}
        setSources={setSources}
        selectSources={selectedSources}
        sources={sources}
        t={t}
        useCaseId={useCaseId}
        industryId={industryId}
      />
    </Container>
  );
};

export default SourcesHeader;
