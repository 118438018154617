import { pipe, reduce, join } from "ramda";

const SCRIPT_PATH = "https://maps.googleapis.com/maps/api/js";
const MAP_PARAMETERS = ["key", "libraries", "v", "language"];

export function prepareGoogleApiUrl(config) {
  const generateQuery = prepareQueryGenerator(config);
  const generateQueryString = pipe(reduce(generateQuery, []), join("&"));

  return `${SCRIPT_PATH}?${generateQueryString(MAP_PARAMETERS)}`;
}

function prepareQueryGenerator(config) {
  return (queries, parameter) => {
    const value = config[parameter];
    return value ? [...queries, `${parameter}=${value}`] : queries;
  };
}
