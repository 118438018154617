import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { orderBy, isEqual } from 'lodash';
import { useQuery } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import { grayShades, green } from 'styles/abstracts/colors';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import Icon from '_Components/Icons/Icon';
import query from '_Cards/SettingsCards/ProjectsConfigurationCard/graphql/getProjectSources';

const StyledCol = styled(Col)`
  margin-bottom: 1em;
`;

const StyledQPanel = styled(QPanel)`
  color: ${({ isActive }) => !isActive && grayShades.g800} !important;
  display: flex;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 4em;
  height: 4em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SourceImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ActiveIcon = styled.div`
  position: absolute !important;
  top: 0;
  right: 0;
  transform: translateY(-25%) translateX(-25%);
  opacity: ${({ isActive }) => (isActive ? 1 : 0)}
  transition: all 0.1s ease-in-out;
`;

const ListAvailableSources = ({ setSelectedSources, selectedSources, t }) => {
  const { data } = useQuery(query);
  const [selectedItems, setSelectedItems] = useState(selectedSources);

  const availableSources = data && [
    ...data?.getProjectSources,
    {
      id: data?.getProjectSources?.length,
      name: t('File upload'),
      link: t('Send us your data'),
      imageLink: '',
    },
  ];

  useEffect(() => {
    setSelectedItems(selectedSources);
  }, []);

  return (
    <Row>
      {availableSources?.map(availableSource => (
        <StyledCol xs={4}>
          <StyledQPanel
            onClick={() => {
              if (selectedItems?.find(item => item.id === availableSource.id)) {
                const newSelectedItems = selectedItems.filter(
                  item => item.id !== availableSource.id,
                );
                setSelectedItems(newSelectedItems);
                setSelectedSources(newSelectedItems);
              } else {
                let newSelectedItems;
                if (availableSource.id !== availableSources.length - 1) {
                  newSelectedItems = [...selectedItems, availableSource].filter(
                    item => item.id !== availableSources.length - 1,
                  );
                } else {
                  newSelectedItems = [availableSource];
                }
                setSelectedItems(newSelectedItems);
                setSelectedSources(orderBy(newSelectedItems, item => item.id));
              }
              //   if
              //   console.debug(
              //     'ListAvailableSources l.72 selectedItem',
              //     selectedItems,
              //   );
              //   if (selectedItems) {
              //     if (selectedItems.includes(availableSource)) {
              //       setSelectedItems(
              //         selectedItems.filter(
              //           item => item.id !== availableSource.id,
              //         ),
              //       );
              //       setSelectedSources(
              //         orderBy(
              //           selectedItems.filter(
              //             item => item.id !== availableSource.id,
              //           ),
              //           item => item.id,
              //         ),
              //       );
              //     } else {
              //       setSelectedItems([...selectedItems, availableSource]);
              //       setSelectedSources(
              //         orderBy(
              //           [...selectedItems, availableSource],
              //           item => item.id,
              //         ),
              //       );
              //     }
              //   }
            }}
            isActive={
              selectedItems &&
              selectedItems.some(item => isEqual(item, availableSource))
            }
          >
            <ImageContainer>
              {availableSource.id !== availableSources.length - 1 ? (
                <SourceImage
                  src={availableSource.imageLink}
                  alt={`Logo ${availableSource.name}`}
                />
              ) : (
                <Icon icon="DOWNLOAD" size={48} />
              )}
            </ImageContainer>
            <div>
              <QTitle>{availableSource.name}</QTitle>
              <p>{availableSource.link}</p>
            </div>
          </StyledQPanel>
          <ActiveIcon
            isActive={
              selectedItems &&
              selectedItems.some(item => isEqual(item, availableSource))
            }
          >
            <IconCircle color={green} icon="CHECK_ALL" size={16} />
          </ActiveIcon>
        </StyledCol>
      ))}
    </Row>
  );
};

export default withTranslation('projectConfiguration')(ListAvailableSources);
