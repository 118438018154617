export const REMOVE_PAGE_FILTERS = 'REPORTING_REMOVE_PAGE_FILTERS';

export const FILTER_TYPES = {
  THEMATIC: 'thematic',
  NORMAL: 'normal',
};

export const removePageFilters = (filterType, filterValues) => ({
  type: REMOVE_PAGE_FILTERS,
  filterType,
  filterValues,
});
