import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { find, get, includes } from 'lodash';
import { connect } from 'react-redux';
import { MenuItemLabel } from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import {
  RadioButton,
  CheckboxContainer,
} from '_Charts/LineCharts/CLineChart/CLineChartSettings';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import {
  weekDays,
  monthDays,
  automationEveryChoices,
  automationAtChoices,
} from 'Routes/Settings/ProjectConfiguration/utils';
import { lightBlue } from 'styles/abstracts/colors';
import AutomationSettings from './_Components/AutomationSettings';

const Container = styled.div`
  font-weight: bold;
  margin-left: 1em;
  & > div {
    margin-bottom: 1em;
  }
`;

const CronConfiguration = styled.div`
  margin-left: 2.5em;
`;

const AutomaticMessage = styled.div`
  color: ${lightBlue};
  margin-left: 2.5em;
`;

const EmailContainer = styled.div`
  margin-top: -1em;
  margin-left: 1.5em;
  display: inline-flex;
  align-items: center;
`;

const ProcessingAutomation = ({
  automation,
  setAutomation,
  automationFrequency,
  setAutomationFrequency,
  t,
  currentPlan,
}) => {
  const [isManual, selectManual] = useState(
    automation?.crontask === null ||
      !automation?.crontask ||
      automation?.crontask === '' ||
      !automationFrequency,
  );
  const [automationSettings, setAutomationSettings] = useState(
    automationFrequency,
  );
  const [sendEmail, checkSendEmail] = useState(automation?.email ?? false);

  const getCronTask = () => {
    if (!isManual && (currentPlan === 'emotion_start' || includes(currentPlan, 'emotion_plus'))) {
      return '* * * * *';
    } else {
      return automationSettings && !isManual ? convertToCronString() : null;
    }
  }

  useEffect(() => {
    setAutomationFrequency(automationSettings);
    const settingsToSend = {
      crontask: getCronTask(),
      email: sendEmail,
    };
    setAutomation(settingsToSend);
  }, [isManual, sendEmail, automationSettings]);

  let onData;
  switch (automationSettings?.every) {
    case 'week':
      onData = weekDays;
      break;
    case 'month':
      onData = monthDays;
  }

  const convertToCronString = () => {
    const { every, on, at } = automationSettings;
    let cronString = '* * * * *';

    if (every === 'month') {
      cronString = `0 ${at} ${on} * *`;
    } else if (every === 'week') {
      const dayNumber = onData.indexOf(find(onData, item => item.id === on));
      cronString = `0 ${at} * * ${dayNumber}`;
    }

    return cronString;
  };


  return (
    <Container>
      <CheckboxContainer>
        <MenuItemLabel checked={isManual}>
          <RadioButton
            type="radio"
            checked={isManual}
            value
            onChange={() => selectManual(true)}
          />
        </MenuItemLabel>
        {t('I want to keep it manual')}
      </CheckboxContainer>
      <br />
      <CheckboxContainer>
        <MenuItemLabel checked={isManual === false}>
          <RadioButton
            type="radio"
            checked={isManual === false}
            value={false}
            onChange={() => selectManual(false)}
          />
        </MenuItemLabel>
        {t('Setup an automatic search')}
      </CheckboxContainer>
      {!isManual && (
        <>
          {!includes(currentPlan, 'emotion_plus') &&
          currentPlan !== 'emotion_start' ? (
            <CronConfiguration>
              {t('Every')}{' '}
              <AutomationSettings
                options={automationEveryChoices(includes(currentPlan, 'emotion_pro'))}
                level="every"
                automationSettings={automationSettings}
                setAutomationSettings={setAutomationSettings}
                setAutomationFrequency={setAutomationFrequency}
                automationFrequency={automationFrequency}
                t={t}
              />{' '}
              {t('on')}{' '}
              <AutomationSettings
                options={onData}
                level="on"
                automationSettings={automationSettings}
                setAutomationSettings={setAutomationSettings}
                setAutomationFrequency={setAutomationFrequency}
                automationFrequency={automationFrequency}
                t={t}
              />{' '}
              {t('at')}{' '}
              <AutomationSettings
                options={automationAtChoices}
                level="at"
                automationSettings={automationSettings}
                setAutomationSettings={setAutomationSettings}
                setAutomationFrequency={setAutomationFrequency}
                automationFrequency={automationFrequency}
                t={t}
              />{' '}
            </CronConfiguration>
          ) : (
            <AutomaticMessage>
              {t('The search is done at the beginning of every month.')}
            </AutomaticMessage>
          )}
          <EmailContainer style={{ width: '100%' }}>
            <QCheckbox
              selected={sendEmail}
              onClick={() => checkSendEmail(!sendEmail)}
            />
            {t('Send an email when new data is available')}
          </EmailContainer>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  currentPlan: get(state, ['auth', 'user', 'account', 'planName']),
});

export default connect(mapStateToProps)(ProcessingAutomation);
