import React from 'react';
import { useQuery } from 'react-apollo';
import { getSubscriptions } from '../../plans';
import Plan from '../Plan/Plan';
import LIST_PLANS_QUERY from '../../graphql/listPlans';

const SubscriptionsPanels = ({ isMonthlySelected, setItemPriceId, t, setEnterAddress, hasAddress, setNameId }) => {
  const subscriptions = getSubscriptions(isMonthlySelected);
  const { data } = useQuery(LIST_PLANS_QUERY);
  return (
    <>
      {subscriptions.map(sub => {
        return (
          <Plan
            sub={sub}
            isMonthlySelected={isMonthlySelected}
            setItemPriceId={setItemPriceId}
            t={t}
            itemPrice={data?.listPlans?.plans?.find(item => item.name === sub.nameId).itemPrice}
            setEnterAddress={setEnterAddress}
            hasAddress={hasAddress}
            setNameId={setNameId}
          />
        );
      })}
    </>
  );
};

export default SubscriptionsPanels;
