import React from 'react';
import { DropdownMenu } from 'reactstrap';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import DDPortalContent from '_Layouts/_Components/DDPortalContent';
import { white } from 'styles/abstracts/colors';

const Container = styled(DropdownMenu)`
  // position: absolute !important;
  background: ${white};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 0;
  margin: 0;
  left: 4.7em !important;
  right: 0;
  top: -3rem;
`;

class DropdownPortal extends React.Component {
  getContent() {
    return (
      <Container id="ddcustom">
        <DDPortalContent pages={this.props.pages} />
      </Container>
    );
  }

  render() {
    return (
      typeof document !== 'undefined' &&
      document.getElementById('menuContainer') &&
      ReactDOM.createPortal(
        this.getContent(),
        document.getElementById('menuContainer'),
      )
    );
  }
}

export default DropdownPortal;
