import gql from 'graphql-tag';

const query = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      user {
        id
        firstName
        lastName
        email
        isAdmin
        expireAt
        account {
          id
          companyName
          name
          apiToken
        }
        lang
        verbatimsLocale
        otpEnabled
      }
      token
      exp
    }
  }
`;

export default query;
