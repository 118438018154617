import gql from 'graphql-tag';

const query = gql`
  query listPlans {
    listPlans {
      plans {
        name
        itemPrice
      }
    }
  }
`;

export default query;
