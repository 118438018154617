import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setActiveBenchmark } from '_Resources/Benchmark/Actions/setActiveBenchmark';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverBody,
} from 'reactstrap';
import Icon from '_Components/Icons/Icon';
import {
  ImageSize,
  spacing,
} from '../../../../../../../../../../../../styles/abstracts/variables';
import {
  grayShades,
  red,
  white,
} from '../../../../../../../../../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../../../../../../../../../styles/abstracts/typography';
import InputNameModal from '../../../../InputNameModal/InputNameModal';
import MutationArchiveBenchmark from '../../../../../../../../../../../../_Resources/Benchmark/Services/MutationArchiveBenchmark';
import MutationDuplicateBenchmark from '../../../../../../../../../../../../_Resources/Benchmark/Services/MutationDuplicateBenchmark';

const Options = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  cursor: pointer;
`;

const OptionsItem = styled(DropdownItem)`
  font-weight: bold !important;
  color: ${props => (props.delete ? red : `#2e096b`)} !important;
  padding: ${spacing.medium} !important;
  font-size: ${fontSizes.smaller};
  cursor: pointer;
`;

const Menu = styled(DropdownMenu)`
  margin-top: 5em !important;
`;

const CustomPopover = styled(Popover)`
  background-color: black !important;
  top: calc(${props => props.menuDimensions?.top}px - 1em) !important;
  left: calc(${props => props.menuDimensions?.right}px + 5em) !important;

  & .arrow:after {
    border-right-color: black !important;
  }
`;

const CustomPopoverBody = styled(PopoverBody)`
  color: ${white} !important;
`;

class BenchmarkOptions extends React.Component {
  static propTypes = {
    benchmark: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
        settings: PropTypes.array,
      }),
    ).isRequired,
    toggleModal: PropTypes.func.isRequired,
    dispatchSetActiveBenchmark: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isRenameModalOpen: false,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleRenameModal = this.toggleRenameModal.bind(this);
  }

  toggleDropdown() {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  }

  setActiveBenchmark() {
    this.props.dispatchSetActiveBenchmark(this.props.benchmark);
    this.props.toggleModal();
  }

  toggleRenameModal() {
    this.setState({ isRenameModalOpen: !this.state.isRenameModalOpen });
  }

  getDropdownMenuDimensions() {
    const menu = document.getElementById('benchmarkOptionsDropdown');

    return {
      top: menu.offsetTop + menu.offsetHeight * 4,
      right: menu.getBoundingClientRect().width * 5,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isDropdownOpen !== prevState.isDropdownOpen) {
      this.setState({ menuDimensions: this.getDropdownMenuDimensions() });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Options>
          <Dropdown
            isOpen={this.state.isDropdownOpen}
            toggle={this.toggleDropdown}
            direction="right"
            id="benchmarkOptionsDropdown"
          >
            <StyledDropdownToggle tag="span">
              <Icon
                icon="INTERFACE_DOTS_VERTICAL"
                size={ImageSize.medium}
                color={grayShades.g800}
              />
            </StyledDropdownToggle>
            <Menu>
              <OptionsItem onClick={() => this.setActiveBenchmark()}>
                <Icon
                  icon="CHECK_ALL"
                  color={grayShades.g800}
                  size={ImageSize.medium}
                />
                &nbsp; {t('See this benchmark')}
              </OptionsItem>
              <OptionsItem onClick={this.toggleRenameModal}>
                <Icon
                  icon="INTERFACE_EDIT"
                  color={grayShades.g800}
                  size={ImageSize.medium}
                />
                &nbsp; {t('Rename')}
              </OptionsItem>
              <MutationDuplicateBenchmark
                benchmarkId={this.props.benchmark.id}
              />
              <DropdownItem divider />
              <MutationArchiveBenchmark benchmarkId={this.props.benchmark.id} />
              {!this.props.canCreateBenchmark &&
                document.getElementById('duplicateBenchmarkItem') &&
                this.state.isDropdownOpen && (
                  <CustomPopover
                    isOpen
                    target="benchmarkOptionsDropdown"
                    menuDimensions={this.state.menuDimensions}
                  >
                    <CustomPopoverBody>
                      {t('header:You can\'t create more benchmarks. Please upgrade your plan in order to create more benchmarks.')}
                    </CustomPopoverBody>
                  </CustomPopover>
                )}
            </Menu>
          </Dropdown>
        </Options>
        <InputNameModal
          benchmark={this.props.benchmark}
          title={t('Edit your benchmark')}
          subtitle={t('You can edit your benchmark here')}
          isOpen={this.state.isRenameModalOpen}
          toggle={this.toggleRenameModal}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  canCreateBenchmark: state.auth.user.account.canCreateBenchmark,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetActiveBenchmark: newState => {
      dispatch(setActiveBenchmark(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark', 'header')(BenchmarkOptions));
