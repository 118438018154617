import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QModal from '_Components/Modals/QModal/QModal';
import QButton from '_Components/Buttons/QButton/QButton';
import QModalBody from '_Components/Modals/QModalBody/QModalBody';

const StyledBody = styled(QModalBody)`
  text-align: center;
`;

const AlreadyProcessedModal = ({ isOpen, open, t }) => (
  <QModal
    isOpen={isOpen}
    onClose={() => open(!isOpen)}
    title="Daily processing already performed"
    subtitle="You cannot update your project data more than once a day. Please start again tomorrow"
    hideFooter
    size="md"
    config={{mentions: false, date: false}}
  >
    <StyledBody>
      <QButton onClick={() => open(!isOpen)}>{t('Close')}</QButton>
    </StyledBody>
  </QModal>
);

export default withTranslation('button')(AlreadyProcessedModal);
