import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import { grayShades } from 'styles/abstracts/colors';

const DatesStats = styled.div`
  border-top: 1px solid ${grayShades.g300};
  padding: 1em;
`;

const StyledCol = styled(Col)`
  margin-top: 2em;
`;

const UserStatistics = ({ selectedUser, t }) => {
  moment.locale(window.localStorage.getItem('lang'));
  return (
    <QPanel>
      <QTitle>{t('Statistics')}</QTitle>
      <DatesStats>
        <QTitle>{t('Login stats')}</QTitle>
        <Row>
          <StyledCol xs={4}>
            {t('Created on')} : {moment(selectedUser?.createdAt).format('LL')}
          </StyledCol>
          <StyledCol xs={4}>
            {t('Sign in count')} : {selectedUser?.statistics?.signInCount}
          </StyledCol>
          <StyledCol xs={4}>
            {t('Last sign in')} :{' '}
            {selectedUser?.statistics?.lastSignIn
              ? moment(selectedUser?.statistics?.lastSignIn).format('LL')
              : t("Hasn't signed in yet")}
          </StyledCol>
          {selectedUser?.expiredAt && (
            <StyledCol xs={4}>
              Expiration date : {moment(selectedUser?.expiredAt).format('LL')}
            </StyledCol>
          )}
        </Row>
      </DatesStats>
      <DatesStats>
        <QTitle>{t('Modules stats')}</QTitle>
        <Row className="mt-2">
          <StyledCol xs={4}>
            {t('Created benchmarks')} :{' '}
            {selectedUser?.statistics?.benchmarkCount}
          </StyledCol>
          <StyledCol xs={4}>
            {t('Created reportings')} :{' '}
            {selectedUser?.statistics?.reportingCount}
          </StyledCol>
        </Row>
      </DatesStats>
    </QPanel>
  );
};

export default UserStatistics;
