import React from 'react';
import {
  Section,
  SectionItem,
  SectionTitle,
  SectionTitleContainer,
} from '_Cards/SettingsCards/ProjectDetailsCard/_Components/GlobalInformations/styles';

const CommentsSection = ({ commentsData, t }) => (
  <Section>
    <SectionTitleContainer>
      <SectionTitle>{t('Comments count')}</SectionTitle>
    </SectionTitleContainer>
    <SectionItem>
      <p>{t('header:Total with not exploitable comments')}:</p>
      <span>
        {commentsData?.totalMentionCountWithNull} {t('reviews')}
      </span>
    </SectionItem>
    <SectionItem>
      <p>{t('header:Number of "not exploitable" comments')}:</p>{' '}
      <span>
        {commentsData?.totalMentionCountWithNull -
          commentsData?.totalMentionCountWithoutNull}{' '}
        {t('reviews')}
      </span>
    </SectionItem>
    <SectionItem>
      <p>{t('header:Exploitables comments')}:</p>{' '}
      <span>
        {commentsData?.currentMentionCountWithoutNull} {t('reviews')}
      </span>
    </SectionItem>
  </Section>
);

export default CommentsSection;
