import { type } from "ramda";

export class DependencyLoader {
  static NOT_A_STRING_INPUT = "Input is not a string";
  constructor(global) {
    this.global = global;
    this._initialize();
  }

  load(src) {
    if (type(src) !== "String") {
      throw DependencyLoader.NOT_A_STRING_INPUT;
    }

    if (this._checkIfScriptAlreadyAdded(src)) {
      return Promise.resolve();
    }

    return new Promise((res, rej) => {
      this._addScriptTag({
        src,
        onerror: (...args) => rej(...args),
        onload: () => res()
      });
    });
  }

  _initialize() {
    this._initializeRegister();
  }

  _initializeRegister() {
    this.scriptRegister = new Set();
  }

  _addScriptTag({ src, onerror, onload }) {
    const global = this.global || document;
    const { body } = global;
    const script = global.createElement("script");

    Object.assign(script, {
      src,
      onerror,
      onload,
      type: "text/javascript",
      async: true
    });

    body.appendChild(script);
    this.scriptRegister.add(src);
  }

  _checkIfScriptAlreadyAdded(src) {
    return this.scriptRegister.has(src);
  }
}

export const loader = new DependencyLoader();
