import React, { useState } from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { filter, remove } from 'lodash';
import { withTranslation } from 'react-i18next';
import QModal from '_Components/Modals/QModal/QModal';
import { grayShades } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QButton from '_Components/Buttons/QButton/QButton';
import QInput from '_Components/Fields/QInput/QInput';
import getProjectListCardData from 'Routes/Main/MainPage/_Components/ProjectListCard/getProjectListCardData';
import queryProjectHeader from '_Resources/Header/Services/api/graphql/getHeaderProjectFiltersData';
import renameProjectMutation from '_Cards/SettingsCards/ProjectsCard/graphql/renameProject';

const StyledInput = styled(QInput)`
  width: 100% !important;
`;

const InputSubTitle = styled.span`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  font-weight: bolder;
`;

const FooterContainer = styled.div`
  & button:last-child {
    margin-left: 1em;
  }
`;

const RenameProjectModal = ({ project, isOpen, open, t }) => {
  const [newName, setNewName] = useState(project.name);

  const modalFooter = (
    <FooterContainer>
      <QButton outline bgColor="grayShades.g800" onClick={() => open(!isOpen)}>
        {t('Cancel')}
      </QButton>
      <Mutation
        mutation={renameProjectMutation}
        variables={{ id: project.id, name: newName }}
        update={(cache, { data: { renameProject } }) => {
          const activeProjectList = cache.readQuery({
            query: getProjectListCardData,
          }).results.projects;
          const headerProjectList = cache.readQuery({
            query: queryProjectHeader,
          }).data.projects;
          const activeProjectToRename = activeProjectList.find(
            activeProject => activeProject.id === project.id,
          );
          const headerProjectToRename = headerProjectList.find(
            headerProject => headerProject.id === project.id,
          );
          activeProjectToRename.name = renameProject.name;
          headerProjectToRename.name = renameProject.name;
          cache.writeQuery({
            query: getProjectListCardData,
            data: {
              results: {
                projects: [
                  ...remove(
                    activeProjectList,
                    activeProject => activeProject.id !== project.id,
                  ),
                  activeProjectToRename,
                ],
              },
            },
          });
          cache.writeQuery({
            query: queryProjectHeader,
            data: {
              data: {
                projects: [
                  ...headerProjectList.filter(p => p.id !== project.id),
                  headerProjectToRename,
                ],
              },
            },
          });
        }}
      >
        {renameProject => (
          <QButton
            disabled={project.name === newName}
            onClick={() => {
              renameProject({ variables: { id: project.id, name: newName } });
              open(!isOpen);
            }}
          >
            {t('Save new name')}
          </QButton>
        )}
      </Mutation>
    </FooterContainer>
  );

  return (
    <QModal
      isOpen={isOpen}
      onClose={() => open(!isOpen)}
      title="Rename this project"
      subtitle="It will rename the project for all the users"
      footer={modalFooter}
      size="md"
      config={{mentions: false, date: false}}
    >
      <StyledInput
        value={newName}
        onChange={e => setNewName(e.target.value)}
        maxLength={25}
      />
      &nbsp;<InputSubTitle>{t('card:25 characters max.')}</InputSubTitle>
    </QModal>
  );
};

export default withTranslation('button', 'card')(RenameProjectModal);
