import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import KPINote from '_Charts/KpisCharts/KPINote/KPINote';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import styled from 'styled-components';
import KPIGraph from '_Charts/KpisCharts/KPINote/KPIGraph';
import KPINPSGraph from '_Charts/KpisCharts/KPINote/KPINPSGraph';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';
import Icon from '_Components/Icons/Icon';
import { white, lightPurple, purple } from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import { capitalize } from '_Utils/strings/stringsUtils';

const SatisfactionContainer = styled.div<{ collapsedInfo: boolean }>`
  font-size: 0.75em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: ${({ collapsedInfo }) => (collapsedInfo ? 3.5 : 0.5)}em;
  margin-top: 1.5em;
  transition-duration: 0.2s;
`;

const SatisfactionNpsSectionContainer = styled.div`
  height: calc(100% - 2em);
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

const NPSCESSectionContainer = styled.div<{ collapsedInfo: boolean; mt: number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: ${({ mt }) => mt}rem;
  margin-bottom: ${({ collapsedInfo }) => (collapsedInfo ? 3 : 0)}rem;
  text-align: center;
  font-size: 0.75em;
  transition-duration: 0.2s;
`;

const SliderArrow = styled.button`
  height: 27px !important;
  width: 27px !important;
  &:focus svg {
    border: 1px solid ${purple};
  }
`;

const SliderArrowSVG = styled(Icon)`
  display: block !important;
  color: ${white} !important;
  background-color: ${lightPurple} !important;
  border-radius: 50% !important;
  height: 25px !important;
  width: 25px !important;
  padding: 3px !important;
  transition-duration: 0.5s !important;
  transition-timing-function: ease-in-out !important;
  position: relative;
  top: -22px;
  &:hover {
    background-color: ${purple} !important;
  }
`;

const Arrow = props => {
  const { className, onClick, left, right } = props;
  return (
    <>
      {left && (
        <SliderArrow
          className={className}
          style={{
            left: '0',
            zIndex: 10,
          }}
          onClick={onClick}
          type="button"
        >
          <SliderArrowSVG
            class={className}
            style={{
              left: '0',
              zIndex: '10',
            }}
            icon="INTERFACE_CHEVRON_LEFT"
            color={white}
            onClick={onClick}
            size={ImageSize.medium}
          />
        </SliderArrow>
      )}
      {right && (
        <SliderArrow
          className={className}
          style={{
            right: '0',
          }}
          onClick={onClick}
          type="button"
        >
          <SliderArrowSVG
            class={className}
            style={{
              right: '0',
            }}
            icon="INTERFACE_CHEVRON_RIGHT"
            color={white}
            onClick={onClick}
            size={ImageSize.medium}
          />
        </SliderArrow>
      )}
    </>
  );
};

const kpiNames = {
  satisfaction: 'satisfaction',
  netPromoterScore: 'Net promoter score',
  effort: 'effort',
  recommendation: 'recommendation',
};

const indicatorNames = {
  satisfaction: 'CSAT',
  'Net promoter score': 'NPS',
  effort: 'CES',
  recommendation: 'RECO',
};

const indicatorTitles = {
  satisfaction: 'Satisfaction',
  'Net promoter score': 'Net promoter score',
  effort: 'Effort',
  recommendation: 'Recommendation',
};

export const getSectionLayout = data => {
  const { satisfaction, netPromoterScore, effort, recommendation } = data || {};

  satisfaction ? (satisfaction.kpiName = kpiNames.satisfaction) : null;
  netPromoterScore ? (netPromoterScore.kpiName = kpiNames.netPromoterScore) : null;
  effort ? (effort.kpiName = kpiNames.effort) : null;
  recommendation ? (recommendation.kpiName = kpiNames.recommendation) : null;

  const initialSectionLayout = [];
  initialSectionLayout.push(satisfaction, netPromoterScore, effort, recommendation);
  const filteredInitialSectionLayout = initialSectionLayout.filter(item => {
    return item != null;
  });
  filteredInitialSectionLayout.map((kpi, index) => {
    kpi.layoutOrder = index + 1;
    kpi.name = indicatorNames[kpi.kpiName];
    kpi.title = indicatorTitles[kpi.kpiName];
    return kpi;
  });
  return filteredInitialSectionLayout;
};

export const SatisfactionNpsLandingPageView = ({ className, data, collapsedInfo, filters }) => {
  const { satisfaction, netPromoterScore, effort, recommendation } = data?.results || {};
  const [sectionLayout, setSectionLayout] = useState(getSectionLayout(data?.results));
  const [badges, setBadges] = useState(!!satisfaction?.yearBefore || !!satisfaction?.periodBefore);
  const [windowHeight, setWindowHeight] = useState(500);

  useEffect(() => {
    setSectionLayout(getSectionLayout(data?.results));
    setBadges(!!satisfaction?.yearBefore || !!satisfaction?.periodBefore);
    setWindowHeight(window.innerHeight);
  }, [data, collapsedInfo, filters]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    dotsClass: 'slider-dots',
    appendDots: dots => (
      <div
        style={{
          bottom:
            collapsedInfo && windowHeight > 1000 ? '-35px' : '-25px',
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    prevArrow: <Arrow left />,
    nextArrow: <Arrow right />,
  };

  return sectionLayout.length > 0 ? (
    <SatisfactionNpsSectionContainer className={`SatisfactionNpsSectionContainer ${className}`}>
      <Slider {...settings}>
        {sectionLayout.map((indicator, index) => {
          return (
            <div>
              <SatisfactionContainer collapsedInfo={collapsedInfo}>
                <KPINote
                  key={indicator.name}
                  title={indicator.title}
                  name={indicator.name}
                  indicator={capitalize(indicator.kpiName)}
                  data={indicator}
                  idTooltip={`${indicator.name}DeltaTooltip${index}` || ''}
                  inlineTooltips={windowHeight < 1000 && !collapsedInfo}
                  smaller
                />
                {indicator.name !== 'NPS' && (
                  <KPIGraph
                    data={indicator}
                    min={indicator.unit?.min || 0}
                    max={indicator.unit?.max || 10}
                    scaled={!collapsedInfo}
                  />
                )}
                {indicator.name === 'NPS' && <KPINPSGraph data={indicator} />}
              </SatisfactionContainer>
              {(sectionLayout[index + 1] || sectionLayout[index - 1]) && (
                <NPSCESSectionContainer
                  collapsedInfo={collapsedInfo || indicator.name === 'NPS' || (windowHeight > 1000 && !badges)}
                  className="NPSCESSectionContainer"
                  mt={
                    windowHeight > 1000 && !badges && collapsedInfo
                      ? 6
                      : windowHeight > 1000 && !badges
                      ? 4
                      : collapsedInfo || indicator.name === 'NPS'
                      ? 3
                      : 1
                  }
                >
                  {sectionLayout[index - 1] && sectionLayout.length > 2 && (
                    <KPINote
                      title={sectionLayout[index - 1].title}
                      name={sectionLayout[index - 1].name}
                      indicator={capitalize(sectionLayout[index - 1].kpiName)}
                      data={sectionLayout[index - 1]}
                      idTooltip={`${sectionLayout[index - 1].name}DeltaTooltip${index}` || ''}
                      smaller
                      inlineTooltips={windowHeight < 1000 && !collapsedInfo}
                    />
                  )}
                  {!sectionLayout[index - 1] && (
                    <KPINote
                      title={sectionLayout[sectionLayout.length - 1].title}
                      name={sectionLayout[sectionLayout.length - 1].name}
                      indicator={capitalize(sectionLayout[sectionLayout.length - 1].kpiName)}
                      data={sectionLayout[sectionLayout.length - 1]}
                      idTooltip={`${sectionLayout[sectionLayout.length - 1].name}DeltaTooltip${index}` || ''}
                      smaller
                      inlineTooltips={windowHeight < 1000 && !collapsedInfo}
                    />
                  )}
                  {sectionLayout[index + 1] && sectionLayout.length > 2 && (
                    <KPINote
                      title={sectionLayout[index + 1].title}
                      name={sectionLayout[index + 1].name}
                      indicator={capitalize(sectionLayout[index + 1].kpiName)}
                      data={sectionLayout[index + 1]}
                      idTooltip={`${sectionLayout[index + 1].name}DeltaTooltip${index}` || ''}
                      smaller
                      inlineTooltips={windowHeight < 1000 && !collapsedInfo}
                    />
                  )}
                  {!sectionLayout[index + 1] && (
                    <KPINote
                      title={sectionLayout[0].title}
                      name={sectionLayout[0].name}
                      indicator={capitalize(sectionLayout[0].kpiName)}
                      data={sectionLayout[0]}
                      idTooltip={`${sectionLayout[0].name}DeltaTooltip${index}` || ''}
                      smaller
                      inlineTooltips={windowHeight < 1000 && !collapsedInfo}
                    />
                  )}
                </NPSCESSectionContainer>
              )}
            </div>
          );
        })}
      </Slider>
    </SatisfactionNpsSectionContainer>
  ) : (
    <NoDataMessage secondText="There isn't any KPI provided in the data" />
  );
};

const SatisfactionNpsLandingPage = ({
  filters,
  collapsedInfo,
  noDataComponent,
  errorComponent,
  classNameCol = 12,
  data,
  ...props
}) => (
  <SatisfactionNpsLandingPageView
    data={data}
    {...props}
    classNameCssBottom="mt-2"
    classNameCol={classNameCol}
    collapsedInfo={collapsedInfo}
    filters={filters}
  />
);

function mapStateToProps(state) {
  return {
    collapsedInfo: state.projectConfiguration.collapsedInfo,
  };
}

export default connect(mapStateToProps)(SatisfactionNpsLandingPage);
