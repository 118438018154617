import gql from 'graphql-tag';

const mutation = gql`
  mutation saveDataByFiltersSettings(
    $projectId: Int!
    $pageId: String!
    $settings: Json!
  ) {
    saveDataByFiltersSettings(
      projectId: $projectId
      pageId: $pageId
      settings: $settings
    ) {
      errors
      settings
    }
  }
`;

export default mutation;
