import { ActionTypes } from '../../../constants';

export function sendVerbatimEmotionsOnHover(emotion) {
  return {
    type: ActionTypes.SEND_EMOTION_ONHOVER,
    payload: {
      emotion,
    },
  };
}
