import React from 'react';
import styled from 'styled-components';
import { Table } from 'reactstrap';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import { fontSizes } from 'styles/abstracts/typography';
import { grayShades, purple, red } from 'styles/abstracts/colors';
import ACCOUNT_CONSUMPTION_HISTORY_QUERY from './graphql/accountConsumptionHistory';

const Container = styled(QPanel)`
  text-align: center;
  font-weight: bold;
  width: 49%;
`;

const StyledTable = styled(Table)`
  margin-top: 2em;
  color: ${purple} !important;
  & thead,
  & #dateCol {
    font-size: ${fontSizes.smaller};
    color: ${grayShades.g800};
  }
`;

const CustomTd = styled.td`
  background-color: ${grayShades.g300};
  color: ${({creditsExpired}) => creditsExpired ? red : purple};
  border-radius: 15px;
  padding: 0.75em !important;
  font-size: ${fontSizes.smaller};
`;

const EmptyTd = styled.td`
  padding: 0.15em !important;
`;

const AccountConsumptionCard = ({ accountId, t }) => {
  const { data } = useQuery(ACCOUNT_CONSUMPTION_HISTORY_QUERY, {
    variables: { accountId },
  });

  return (
    <Container>
      <QTitle position="center">{t('Credit consumption history')}</QTitle>
      {data?.accountConsumptionHistory && (
        <StyledTable borderless>
          <thead>
            <tr>
              <th>{t('Date')}</th>
              <th>{t('Nb. credits')}</th>
              <th>{t('Project')}</th>
              <th>{t('Source')}</th>
            </tr>
          </thead>
          <tbody>
            {data?.accountConsumptionHistory.map(row =>
              row.type === 'debit' ? (
                <>
                  <tr>
                    <td id="dateCol">{moment(row.date).format('L LT')}</td>
                    <td>
                      {row.creditsUsed && '-'}
                      {row.creditsUsed}
                    </td>
                    <td>{row.projectName}</td>
                    <td>
                      {row.sources &&
                        (row.sources.length > 1
                          ? `${row.sources[0]}, +${row.sources.length - 1}`
                          : row.sources[0])}
                    </td>
                  </tr>
                  <EmptyTd />
                </>
              ) : (
                <>
                  <tr>
                    <td id="dateCol">{moment(row.date).format('L LT')}</td>
                    <CustomTd colSpan="3" creditsExpired={row.type === 'credit_expiration'}>
                      {row.type === 'credit_extra_free'
                        ? t('Discover offer (free credits)', { credits: row.total })
                        : row.type === 'credit_extra'
                        ? `${t('Credits refill')} (${row.total})`
                        : row.type === 'credit_expiration'
                        ? `${t('Credits expiration')} (${row.total})`
                        : `${t('Periodic refill')} (${row.total})`}
                    </CustomTd>
                  </tr>
                  <EmptyTd />
                </>
              ),
            )}
          </tbody>
        </StyledTable>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  accountId: get(state, ['auth', 'user', 'account', 'id']),
});

export default connect(mapStateToProps)(
  withTranslation('card')(AccountConsumptionCard),
);
