import gql from 'graphql-tag';

const renameCluster = gql`
  mutation renameCluster(
    $projectId: Int!
    $clusterId: String!
    $newTitle: String!
  ) {
    renameCluster(
      projectId: $projectId
      clusterId: $clusterId
      newTitle: $newTitle
    ) {
      errors
    }
  }
`;

export default renameCluster;
