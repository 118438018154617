import { redBlueShades } from 'styles/abstracts/colors';

export function filterByTemperature(eindex, arr, isFahrenheit) {
  eindex = parseInt(eindex);
  let emotion = '';
  switch (true) {
    case !isFahrenheit && eindex >= 31 && eindex <= 40:
    case isFahrenheit && eindex >= 83 && eindex <= 100:
      emotion = arr[0];
      break;
    case !isFahrenheit && eindex >= 21 && eindex <= 30:
    case isFahrenheit && eindex >= 66 && eindex <= 82:
      emotion = arr[1];
      break;
    case !isFahrenheit && eindex >= 11 && eindex <= 20:
    case isFahrenheit && eindex >= 50 && eindex <= 65:
      emotion = arr[2];
      break;
    case !isFahrenheit && eindex >= 1 && eindex <= 10:
    case isFahrenheit && eindex >= 33 && eindex <= 49:
      emotion = arr[3];
      break;
    case !isFahrenheit && eindex >= -9 && eindex <= 0:
    case isFahrenheit && eindex >= 17 && eindex <= 32:
      emotion = arr[4];
      break;
    case !isFahrenheit && eindex >= -20 && eindex <= -10:
    case isFahrenheit && eindex >= 0 && eindex <= 16:
      emotion = arr[5];
      break;
  }
  return emotion;
}

export function getColorHexaFromEindex(eindex, isFahrenheit = false) {
  const arr = [
    redBlueShades.rbs1,
    redBlueShades.rbs2,
    redBlueShades.rbs3,
    redBlueShades.rbs4,
    redBlueShades.rbs5,
    redBlueShades.rbs6,
  ];
  return filterByTemperature(eindex, arr, isFahrenheit);
}

export function getBackgroundCSSFormEindex(eindex, isFahrenheit) {
  const arr = [
    '#db2156',
    '#bd1370',
    '#992085',
    '#6e208c',
    '#492796',
    '#222c66',
  ];
  return filterByTemperature(eindex, arr, isFahrenheit);
}

export function getColorCSSFormEindex(eindex, isFahrenheit) {
  const arr = [
    '#db2156',
    '#bd1370',
    '#992085',
    '#6e208c',
    '#492796',
    '#222c66',
  ];
  return filterByTemperature(eindex, arr, isFahrenheit);
}
