import React from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import '../../../../node_modules/react-resizable/css/styles.css';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import layoutConfig from './config';
import { getComponentsLayout } from '../../_Utils/routeComponent';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import QResponsiveGridLayout from '../../../_Layouts/QResponsiveGridLayout/QResponsiveGridLayout';
import getEmotionalIntensityCardData from '../../../_Charts/KpisCharts/CGaugeChart/graphql/getEmotionalIntensityCardData';
import { getRoute } from '_Utils/routes/routeUtils';

class GlobalResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idpage: 'global-results',
      layout: layoutConfig,
      layout_edit: null,
    };
  }

  componentDidMount() {
    getRoute(this.props.updateRoute, this.state.idpage);
  }

  render() {
    const props = {
      GaugeCard: {
        hints: [
          HintsContent.HINT_EMOTIONAL_INTENSITY,
          HintsContent.HINT_EMOTIONAL_INTENSITY_AND_EMOTIONAL_NORMS,
        ],
        query: getEmotionalIntensityCardData,
      },
      EindexEvolutionWithMentionsCard: {
        mixBarLine: true,
      },
    };
    const { layout } = this.state;
    const layoutComponents = getComponentsLayout(layout, props, {});
    return (
      layout && (
        <div>
          <Container fluid className="py-3 px-4">
            <QResponsiveGridLayout
              layout={layout}
              isResizable={this.props.editableLayout}
            >
              {layoutComponents}
            </QResponsiveGridLayout>
          </Container>
        </div>
      )
    );
  }
}

GlobalResults.defaultProps = {
  layoutDefault: layoutConfig,
  editableLayout: false,
};

function mapStateToProps(state) {
  return {
    editableLayout: state.routes.activeLayoutEdition,
    module: state.routes.route && state.routes.route.name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalResults);
