import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { last } from 'lodash';

import PageList from '../_Components/PageList';
import ReportPage from './ReportPage';
import {
  REPORTING_MODES,
  updateReportingMode,
} from '../../../../_Resources/Reporting/Actions/updateReportingMode';
import { selectSlotOver } from '../../../../_Resources/Reporting/Actions/selectSlotOver';
import PageSlot from '../_Components/PageSlot';
import { addPageByShortcut } from '../../../../_Resources/Reporting/Actions/addPageByShortcut';
import defaultLayouts from './layouts/emptyLayouts';
import QAddButton from '../../../../_Components/Buttons/QAddButton/QAddButton';
import { lightBlue, themeColors } from '../../../../styles/abstracts/colors';
import ReportingCover from './ReportingCover';
import { multiplyReport } from './helpers/iterateReport';

const PageWrapper = styled.div`
  margin: 0 auto;
  ${({ active }) =>
    !active &&
    `
      visibility: hidden;
      position: absolute;
    `}
`;

const StyledPageList = styled(PageList)`
  ${({ isPreviewMode }) =>
    !isPreviewMode &&
    css`
      @media screen and (max-width: 1500px) {
        zoom: 0.85;
        @media screen and (max-width: 1375px) {
          zoom: 0.75;
          @media screen and (max-width: 1275px) {
            zoom: 0.65;
            @media screen and (max-width: 1175px) {
              zoom: 0.55;
            }
          }
        }
      }
    `};
`;

class ReportPageList extends Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    isPreviewMode: PropTypes.bool.isRequired,
    previewFor: PropTypes.string.isRequired,
    iterateBy: PropTypes.string.isRequired,

    // dispatch props
    onSlotOver: PropTypes.func.isRequired,
    onPageAdd: PropTypes.func.isRequired,
    onListClicked: PropTypes.func.isRequired,

    // state props
    pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    className: '',
  };

  onSlotMouseEnter = index => {
    const { isPreviewMode, onSlotOver } = this.props;
    if (!isPreviewMode) {
      onSlotOver(index);
    }
  };

  onSlotMouseLeave = () => {
    const { isPreviewMode, onSlotOver } = this.props;
    if (!isPreviewMode) {
      onSlotOver(null);
    }
  };

  onPageAddClicked = () => this.props.onPageAdd(defaultLayouts[0]);

  getPages() {
    const { iterateByOptions, isPreviewMode, pages } = this.props;

    return isPreviewMode ? multiplyReport(iterateByOptions, pages) : pages;
  }

  isPageIterated = filters => {
    const { iterateBy, previewFor } = this.props;

    const iterateFilter = last(filters); // multiplyReport put filter to last el of array
    const isIterateByEqual = iterateFilter.filterCategory === iterateBy;
    const isPreviewed =
      !previewFor ||
      previewFor === 'all' ||
      iterateFilter.option.id === previewFor;
    return isIterateByEqual && isPreviewed;
  };

  isPageActive = filters => {
    const { iterateBy, isPreviewMode } = this.props;
    return !isPreviewMode || !iterateBy || this.isPageIterated(filters);
  };

  onListClick = () => !this.props.isPreviewMode && this.props.onListClicked();

  render() {
    const { t, className, isPreviewMode } = this.props;
    const pages = this.getPages();
    return (
      <StyledPageList
        onClick={this.onListClick}
        className={className}
        isPreviewMode={isPreviewMode}
      >
        <ReportingCover />
        <PageSlot
          onMouseEnter={() => this.onSlotMouseEnter(0)}
          onMouseLeave={this.onSlotMouseLeave}
        />
        {pages.map((page, index) => [
          <PageWrapper
            active={this.isPageActive(page.filters && page.filters.normal)}
          >
            <ReportPage pagesLength={pages.length} page={page} index={index} />
            <PageSlot
              onMouseEnter={() => this.onSlotMouseEnter(index + 1)}
              onMouseLeave={this.onSlotMouseLeave}
            />
          </PageWrapper>,
        ])}
        {!isPreviewMode && (
          <QAddButton
            onClick={this.onPageAddClicked}
            text={t('Add a new page')}
            color={themeColors.secondary}
            hoverColor={lightBlue}
          />
        )}
      </StyledPageList>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onListClicked: () => dispatch(updateReportingMode(REPORTING_MODES.GLOBAL)),
  onSlotOver: (...args) => dispatch(selectSlotOver(...args)),
  onPageAdd: (...args) => dispatch(addPageByShortcut(...args)),
});

const mapStateToProps = ({
  reporting: {
    children,
    isPreviewMode,
    iterateBy,
    iterateByOptions,
    previewFor,
  },
}) => ({
  iterateBy,
  iterateByOptions,
  previewFor,
  pages: children,
  isPreviewMode,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(ReportPageList));
