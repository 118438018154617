import React, { useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QPanel from '_Components/Panels/QPanel/QPanel';
import ProjectDetailsCardHeader from './_Components/ProjectDetailsCardHeader/ProjectDetailsCardHeader';
import Automation from './_Components/Automation/Automation';
import GlobalInformations from './_Components/GlobalInformations/GlobalInformations';
import ProcessingHistory from './_Components/ProcessingHistory/ProcessingHistory';
import Sources from './_Components/Sources/Sources';

const Container = styled(QPanel)`
  padding: 0;
`;

const Body = styled.div`
  padding: 1em;
`;

const getBodyContent = (activeTab, setActiveTab, data, slug, t) => {
  let bodyContent;
  switch (activeTab) {
    case 'global-information':
      bodyContent = (
        <GlobalInformations setActiveTab={setActiveTab} data={data} t={t} />
      );
      break;
    case 'sources':
      bodyContent = (
        <Sources data={data?.sources} projectId={data?.id} slug={slug} t={t} useCaseId={data?.useCaseId} industryId={data?.industryId} projectDetails={data} />
      );
      break;
    case 'automation':
      bodyContent = (
        <Automation
          automation={data?.automation}
          settings={data?.dataSettings}
          projectId={data?.id}
          slug={slug}
          t={t}
        />
      );
      break;
    case 'processing-history':
      bodyContent = (
        <ProcessingHistory
          data={data?.processingHistory}
          projectId={data?.id}
          projectName={data?.name}
          t={t}
        />
      );
      break;
    default:
      bodyContent = null;
  }

  return bodyContent;
};

const ProjectDetailsCard = ({ data, slug, t }) => {
  const savedTab = JSON.parse(window.localStorage.getItem('projectDetailsTab'));
  const [activeTab, setActiveTab] = useState(savedTab?.slug === slug ? savedTab.tabId : 'global-information');
  return (
    <Container>
      <ProjectDetailsCardHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        t={t}
        data={{...data, slug}}
      />
      <Body>{getBodyContent(activeTab, setActiveTab, data, slug, t)}</Body>
    </Container>
  );
};

export default withTranslation(['card', 'button', 'monthDays', 'header'])(
  ProjectDetailsCard,
);
