import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QPanel from '../../../../_Components/Panels/QPanel/QPanel';
import { grayShades, themeColors } from '../../../../styles/abstracts/colors';
import cards from '../../../../_Components/Layouts/QLayout/sections/img/cards';
import layouts from '../../../../_Components/Layouts/QLayout/sections/img/layouts';

export const PAGES_SHORTCUT_TYPES = {
  CARD: 'card',
  PAGE: 'page',
};

export const PAGES_SHORTCUT_MODES = {
  EMPTY_LAYOUTS: 'empty-layouts',
  PREDEFINED_LAYOUTS: 'predefined-layouts',
};

const ShortcutBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
`;

const PANEL_SIZE = {
  WIDTH: () => 128,
  HEIGHT: type => (type === PAGES_SHORTCUT_TYPES.CARD ? 160 : 85),
};

const Panel = styled(QPanel)`
  width: ${({ type }) => PANEL_SIZE.WIDTH(type)}px;
  height: ${({ type }) => PANEL_SIZE.HEIGHT(type)}px;
  display: flex;
  align-items: center;
  ${({ mode }) =>
    mode === PAGES_SHORTCUT_MODES.PREDEFINED_LAYOUTS && `padding: 0.25rem;`};
`;

const Label = styled.span`
  font-weight: bold;
  color: ${grayShades.g600};
  text-align: center;
  font-size: 0.875rem;
  margin-top: 0.5rem;

  ${ShortcutBody}:hover & {
    color: ${themeColors.primary};
    transition: color 0.5s;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
`;

const predefinedLayout = {
  [PAGES_SHORTCUT_TYPES.PAGE]: content => (
    <Image src={layouts[content]} alt="page layout" />
  ),
  [PAGES_SHORTCUT_TYPES.CARD]: (content, children) => (
    <Image src={cards[children[0].content]} alt="card layout" />
  ),
};

const Shortcut = ({ shortcut, onClicked, type, mode, t }) => {
  const { label, content, children } = shortcut;
  return (
    <ShortcutBody onClick={onClicked}>
      <Panel type={type} mode={mode}>
        {mode === PAGES_SHORTCUT_MODES.EMPTY_LAYOUTS
          ? content && content()
          : predefinedLayout[type](content, children)}
      </Panel>
      <Label>{t(label)}</Label>
    </ShortcutBody>
  );
};

export const PageShortCutPropType = PropTypes.shape({
  label: PropTypes.string,
  content: PropTypes.func,
});

Shortcut.propTypes = {
  shortcut: PageShortCutPropType.isRequired,
  type: PropTypes.oneOf(PropTypes.arrayOf(Object.values(PAGES_SHORTCUT_TYPES)))
    .isRequired,
  mode: PropTypes.oneOf(PropTypes.arrayOf(Object.values(PAGES_SHORTCUT_MODES)))
    .isRequired,
  onClicked: PropTypes.func,
};

Shortcut.defaultProps = {
  onClicked: null,
};

export default withTranslation('card')(Shortcut);
