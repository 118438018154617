import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QCheckbox from '../../../../_Components/Fields/QCheckbox/QCheckbox';
import QFieldGroup from '../../../../_Components/Panels/QFieldGroup/QFieldGroup';
import { BasePropTypes } from '../../../../_Utils/common.propTypes';

const StyledCheckboxControl = styled(QFieldGroup.CheckboxControl)`
  ${({ indentation }) => (indentation ? `margin-left: 50px` : null)};
  ${({ disabled }) => (disabled ? `opacity: 0.5` : null)};
`;

const StyledQCheckbox = styled(QCheckbox)`
  margin-right: 0.5em;
  margin-top: 0;
  margin-bottom: 0;
`;

const CheckboxGroup = styled.div`
  display: flex;
  place-content: center flex-start;
  align-items: center;
  padding-bottom: 0.5em;
  cursor: pointer;
`;

const FieldCheckbox = ({
  selected,
  toggle,
  id,
  label,
  children,
  className,
  indentation,
  disabled,
}) => (
  <StyledCheckboxControl
    indentation={indentation}
    disabled={disabled}
    className={className}
  >
    <CheckboxGroup onClick={() => !disabled && toggle(id)}>
      <StyledQCheckbox id={id} selected={selected} />
      <QCheckbox.Label forId={id}>{label}</QCheckbox.Label>
    </CheckboxGroup>
    {children}
  </StyledCheckboxControl>
);

FieldCheckbox.propTypes = {
  ...BasePropTypes,

  selected: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FieldCheckbox;
