import gql from 'graphql-tag';

const mutation = gql`
  mutation deleteUser($id: Int!, $transferModules: Boolean!) {
    deleteUser(id: $id, transferModules: $transferModules) {
      id
      errors
    }
  }
`;

export default mutation;
