import { uniq } from 'lodash';

export const mergeThematics = (thematics, thematicsToMerge) => {
  if (!thematics && !thematicsToMerge) {
    return null;
  } else if (!thematics) {
    return thematicsToMerge;
  } else if (!thematicsToMerge) {
    return thematics;
  }
  const finalFullThematics = [...thematics, ...thematicsToMerge];
  return finalFullThematics ? uniq(finalFullThematics) : null;
};
