import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';

import Icon from '_Components/Icons/Icon';
import CModal from '_Components/CModal/CModal';
import Hint from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import { grayShades, themeColors } from 'styles/abstracts/colors';

// TODO [QTABS]
const Tab = styled(NavLink)`
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  padding-top: 0;
  padding-bottom: 6px;
  font-weight: bold;
  color: ${({ active }) =>
    active ? themeColors.primary : '#928FB0 '}!important;
  border-bottom: ${({ active }) =>
    active && `4px solid ${themeColors.primary} !important`};

  &:hover {
    border: 0 0 4px 0 solid inherit !important;
  }
`;

class QCardHints extends React.Component {
  constructor(props) {
    super(props);
    const { hints } = this.props;
    this.state = {
      modal: false,
      activeTab: hints[0],
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hints[0] !== this.props.hints[0]) {
      this.setState({ ...this.state, activeTab: this.props.hints[0] });
    }
  }

  createHintsContentStructure(hints) {
    return (
      <div>
        <div>
          {hints.length > 1 && (
            <Nav className="border-bottom">
              {hints.map(hint => (
                <NavItem key={`${hint.title}key`}>
                  <Tab
                    active={this.state.activeTab === hint}
                    onClick={() => {
                      this.toggleTab(hint);
                    }}
                  >
                    {this.props.t(hint.title)}
                  </Tab>
                </NavItem>
              ))}
            </Nav>
          )}
        </div>

        <div>
          {hints.length > 1 ? (
            <TabContent activeTab={this.state.activeTab}>
              {hints.map(hint => (
                <TabPane tabId={hint}>
                  <Row>
                    <Col sm="12">
                      <Hint hint={hint} />
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </TabContent>
          ) : (
            <Hint hint={hints[0]} />
          )}
        </div>
      </div>
    );
  }

  render() {
    const self = this;
    const propsModal = {
      numberOfMentions: 'Hints & explanations',
      displayMentionsSubtitle: false,
      displayDateSubtitle: false,
      subtitleAdditionalContent: 'Find all the answers you need !',
      titleSecondPart: '',
      title: 'Hints & explanations',
      toggle: self.toggleModal,
      data: {},
      modal: self.state.modal,
    };
    const modal = (
      <CModal {...propsModal}>
        {this.createHintsContentStructure(this.props.hints)}
      </CModal>
    );
    return (
      <span
        className={`${this.props.landingSection ? 'mr-1' : 'mr-2'}`}
        onClick={() => this.toggleModal('test')}
        role="presentation"
      >
        <Icon icon="INTERFACE_INFO" size={20} color={grayShades.g400} />
        {modal}
      </span>
    );
  }
}

QCardHints.propTypes = {
  displayCardTitle: PropTypes.bool,
  displayCardSubTitle: PropTypes.bool,
  title: PropTypes.string,
  titleSecondPart: PropTypes.string,
  numberOfMentions: PropTypes.number,
  hints: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  ),
};

QCardHints.defaultProps = {
  displayCardTitle: true,
  displayCardSubTitle: true,
  titleSecondPart: null,
  title: null,
  numberOfMentions: null,
  hints: null,
};

export default withTranslation('card')(QCardHints);
