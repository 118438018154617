import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { get, includes } from 'lodash';
import { connect } from 'react-redux';
import GA from '_Utils/other/googleAnalytics';
import routes from 'Routes';
import NotFound from 'Routes/UtilityRoutes/NotFound';

const AppRouter = ({ currentPlan, token, setToken, projectList }) => (
  <Router>
    {GA.init() && <GA.RouteTracker />}
    <Switch>
      {routes.map(route => {
        if (
          route.allowPlans &&
          (!currentPlan ||
            !route.allowPlans.includes(currentPlan) ||
            ((route.path !== '/project-configuration' &&
              !route.path.includes('/upload-data/')) &&
              projectList.length === 0))
        ) {
          return (
            <Route
              path={route.path}
              exact={route.exact}
              render={props =>
                React.cloneElement(<NotFound />, {
                  ...props,
                  token,
                  setToken,
                  accessDenied: true,
                })
              }
            />
          );
        } else if (
          !route.allowPlans ||
          includes(route.allowPlans, currentPlan)
        ) {
          return (
            <Route
              path={route.path}
              exact={route.exact}
              render={props =>
                React.cloneElement(route.component, {
                  ...props,
                  token,
                  setToken,
                })
              }
            />
          );
        }
      })}
      <Route
        render={props =>
          React.cloneElement(<NotFound />, {
            ...props,
            token,
            setToken,
          })
        }
        status={404}
      />
    </Switch>
  </Router>
);

const mapStateToProps = state => ({
  currentPlan: get(state, ['auth', 'user', 'account', 'planName']),
  projectList: get(state, ['projects', 'projectsList']),
});

export default connect(mapStateToProps)(AppRouter);
