import gql from 'graphql-tag';

const query = gql`
  query getReportingEindexBarGraphChartCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput]!
    $thematicsToShow: [ThematicInput!]!
    $keywords: [String!]
    $dataCollectorId: Int
  ) {
    getReportingEindexBarGraphChartCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      thematicsToShow: $thematicsToShow
      keywords: $keywords
      dataCollectorId: $dataCollectorId
    ) {
      data {
        id
        label
        eindex
        mentionCount
      }
    }
  }
`;

export default query;
