import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { purple, gray } from '../../../../styles/abstracts/colors';
import { BasePropTypes } from '../../../../_Utils/common.propTypes';

const Tab = styled.div`
  position: relative;
  color: ${({ active }) => (active ? purple : gray)};
  padding: 24px 15px 23px;
  font-weight: bold;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        border-bottom: 4px solid ${purple};
      }
    `};
`;

export default class QTab extends Component {
  static defaultProps = {
    id: null,
    isSelected: false,
    onSelect: () => {},
  };

  static propTypes = {
    id: PropTypes.string,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,

    ...BasePropTypes,
  };

  handleClick = () => {
    const { id, onSelect } = this.props;
    onSelect(id);
  };

  render() {
    const { id, children, isSelected, className } = this.props;

    return (
      <div role="tab" id={id}>
        <Tab
          active={isSelected}
          onClick={this.handleClick}
          className={className}
        >
          {children}
        </Tab>
      </div>
    );
  }
}
