import React from 'react';
import { connect } from 'react-redux';
import { groupBy, clone, isArray, get, isEqual } from 'lodash';
import { updateFilters } from '_Resources/Benchmark/Actions/Filters/updateFilters';
import ProjectFilters from '_Layouts/Header/_Containers/SubHeader/_Containers/HeaderProjectFilters/ProjectFilters';
import { addConflict } from '_Resources/Benchmark/Actions/Items/addConflict';
import { removeConflict } from '_Resources/Benchmark/Actions/Items/removeConflict';
import { checkConflicts } from '../FiltersSwitcher/FiltersUtils/ConflictsUtils';
import { isValidCriteriaKeys } from '../FiltersSwitcher/FiltersUtils/CriteriaKeysUtils';
import { CONTEXT } from '../../../../configs/globalConfig';

class BenchmarkHeaderProjectFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !isEqual(
        nextProps.criteriaKeysInputTags,
        this.props.criteriaKeysInputTags,
      ) ||
      !isEqual(nextProps.thematicsInputTags, this.props.thematicsInputTags) ||
      !isEqual(nextProps.projectId, this.props.projectId) ||
      !isEqual(nextProps.idPage, this.props.idPage) ||
      !isEqual(nextProps.initialValues, this.props.initialValues)
    ) {
      this.setState({ currentCriterias: [], currentThematics: [] });
      return true;
    }
    if (!isEqual(nextState.filters, this.state.filters)) {
      this.sendNewFiltersToGlobalState(nextProps, nextState);
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevProps.initialValues.normal, this.props.initialValues.normal)
    ) {
      checkConflicts(
        CONTEXT.GLOBAL,
        'normal',
        isValidCriteriaKeys,
        this.props.benchmark,
        this.props.dispatchAddConflict,
        this.props.dispatchRemoveConflict,
      );
    }
    if (
      !isEqual(this.state.filters, prevState.filters) ||
      !isEqual(this.state.currentCriterias, prevState.currentCriterias) ||
      !isEqual(this.state.currentThematics, prevState.currentThematics) ||
      !isEqual(prevProps.idPage, this.props.idPage)
    ) {
      this.sendNewFiltersToGlobalState(this.props, this.state);
    }
  }

  setCurrentInputTags(filters) {
    let initialObject = {
      normal: null,
      thematic: null,
      related: null,
      keywords: [],
    };
    const testObject = groupBy(filters, b => b.option.type);
    testObject.thematic = clone(testObject.thematics, true);
    delete testObject.thematics;
    if (testObject[undefined]) {
      testObject.keywords = testObject[undefined];
    }
    initialObject = { ...initialObject, ...testObject };
    this.setState({ filters: initialObject });
  }

  sendNewFiltersToGlobalState(nextProps, nextState) {
    this.props.dispatchUpdateFilters(nextState.filters);
  }

  render() {
    const { initialValues } = this.props;
    const mergedInitialValues = [];
    if (initialValues) {
      Object.keys(initialValues).forEach(option => {
        if (isArray(initialValues[option])) {
          mergedInitialValues.push(...initialValues[option]);
        }
      });
    }
    return (
      <ProjectFilters
        projectId={this.props.projectId}
        thematicsInputTags={this.props.thematicsInputTags}
        normalCriteriaKeys={get(this.props, [
          'criteriaKeysInputTags',
          'normal',
        ])}
        relatedCriteriaKeys={get(this.props, [
          'criteriaKeysInputTags',
          'related',
        ])}
        onSearch={filters => this.setCurrentInputTags(filters)}
        adaptOptions={false}
        initialValues={mergedInitialValues}
      />
    );
  }
}
function mapStateToProps(state) {
  return {
    projectId:
      state.projects.currentProject && state.projects.currentProject.id,
    idPage: state.routes.subRoute && state.routes.subRoute.id,
    defaultThematic: state.routes.defaultThematic,
    range: state.periods.currentPeriod,
    pageConfiguration: state.routes.pageConfiguration,
    initialValues:
      get(state, [
        'benchmark',
        'activeBenchmark',
        'settings',
        'globalSettings',
        'filters',
      ]) || [],
    benchmark: get(state, ['benchmark']),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateFilters: newState => {
      dispatch(updateFilters(newState));
    },
    dispatchAddConflict: (firstContext, secondContext, typeConflict) => {
      dispatch(addConflict(firstContext, secondContext, typeConflict));
    },
    dispatchRemoveConflict: (firstContext, secondContext, typeConflict) => {
      dispatch(removeConflict(firstContext, secondContext, typeConflict));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BenchmarkHeaderProjectFilters);
