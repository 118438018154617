import gql from 'graphql-tag';

const query = gql`
  query getMentionRepartitionPerSourceCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
  ) {
    results: getMentionRepartitionPerSourceCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
    ) {
      mentionRepartitionPerSource {
        name
        value
        source
        mentionCount
      }
    }
  }
`;
export default query;
