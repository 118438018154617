import { inRange, isEmpty, isNil, sortBy } from 'lodash';

export const prepareIsSentenceInRanges = ranges => ({
  results: indicatorsValues,
}) => {
  const rangesResults = ranges.map(({ id, range: [start, end] }) =>
    inRange(indicatorsValues[id], start, end + 1),
  );
  return rangesResults.every(partialResult => partialResult);
};

export const prepareHasSentenceMainEmotion = emotions => ({
  results: { emotions: sentenceEmotions },
}) => {
  if (isEmpty(emotions)) return true;

  const [[mostMeaningFullEmotion]] = sortBy(
    Object.entries(sentenceEmotions),
    ([, value]) => value,
  ).reverse();
  return emotions
    .map(emotion => emotion.toLowerCase())
    .includes(mostMeaningFullEmotion.toLowerCase());
};

export const prepareIsNotRemoved = removed => ({ order, id }) => {
  const normalizedRemoved = removed || [];
  return !normalizedRemoved.find(({ id: removedId, order: removedOrder }) => {
    const normalizedRemovedOrder = isNil(removedOrder) ? order : removedOrder;
    return id === removedId && order === normalizedRemovedOrder;
  });
};
