import React, { Fragment } from 'react';
import styled from 'styled-components';
import ReportingVerbatimBubble from './ReportingVerbatimBubble';

const Container = styled.div`
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
`;

class ReportingVerbatimList extends React.Component {
  componentDidMount() {
    const card = document.querySelector('.reportingVerbatimContainer');
    const bubbles = [...document.getElementsByClassName('bubble')];
    bubbles.map(bubble => {
      if (
        bubble.offsetTop + bubble.offsetHeight >
        card.offsetTop + card.offsetHeight
      ) {
        bubble.style.display = 'none';
      }
    });
  }

  render() {
    return (
      <Container className="reportingVerbatimList">
        {this.props.data.bestVerbatim
          ? this.props.data.bestVerbatim.map(data => (
              <Fragment>
                <ReportingVerbatimBubble data={data} />
                <br />
              </Fragment>
            ))
          : this.props.data.worstVerbatim &&
            this.props.data.worstVerbatim.map(data => (
              <ReportingVerbatimBubble data={data} />
            ))}
      </Container>
    );
  }
}

export default ReportingVerbatimList;
