import React from 'react';
import styled from 'styled-components';
import { red } from 'styles/abstracts/colors';
import { withTranslation } from 'react-i18next';
import { CONTEXT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';

const Subtitle = styled.p`
  color: ${red};
  font-weight: bold;
`;

const ConflictSubtitle = ({
  context,
  t,
  globalConflict,
  itemTitle,
  criteriaKeyTitle,
}) => (
  <Subtitle>
    {globalConflict
      ? t('Conflict on filter between this cardColumn and the global context', {
          criteriaKeyTitle,
          type: `$t(${context})`,
        })
      : t('Conflict on filter between this cardColumn and the cardColumn', {
          criteriaKeyTitle,
          type: `$t(${context})`,
          otherType: `$t(${
            context === CONTEXT.CARD ? t(CONTEXT.COLUMN) : t(CONTEXT.CARD)
          })`,
          title: itemTitle,
        })}
  </Subtitle>
);

export default withTranslation('conflicts')(ConflictSubtitle);
