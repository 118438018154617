export default {
  nb_mentions: 1567,
  eindex: 8497,
  arousal: 2132.900000000002,
  valence: 3054.2800000000025,
  dominance: 3016.539999999998,
  sat: 1044,
  reco: 0,
  nps_data: {
    nb_detractors: 0,
    nb_passives: 0,
    nb_promoters: 0,
    nb_total: 0,
  },
  sources: [],
  emotions: {
    happiness: 7749,
    surprise: 2380,
    calm: 3775,
    fear: 3636,
    sadness: 7185,
    anger: 1699,
    disgust: 1504,
  },
  performance: 50389,
  confidence_rate: 51105,
  emotional_intensity: 18369,
  commitment: {
    speech_size: 82.14999999999986,
    personal_involvement: 68.55999999999992,
    global_engagement: 210.60999999999953,
  },
  satisfaction: {
    kpiName: 'satisfaction',
    layoutOrder: 1,
    unit: { min: 0, max: 10, unit: null },
    value: 4.1,
    verbatimsPerKpi: [
      { kpiValue: '0', percentage: 25 },
      { kpiValue: '1', percentage: 10 },
      { kpiValue: '2', percentage: 2 },
      { kpiValue: '3', percentage: 3 },
      { kpiValue: '4', percentage: 1 },
      { kpiValue: '5', percentage: 0 },
      { kpiValue: '6', percentage: 8 },
      { kpiValue: '7', percentage: 7 },
      { kpiValue: '8', percentage: 8 },
      { kpiValue: '9', percentage: 21 },
      { kpiValue: '10', percentage: 15 },
    ],
    yearBefore: null,
    periodeBefore: {
      value: 5,
      delta: -0.9,
      period: {
        startDate: '2013-04-15T00:00:00+00:00',
        endDate: '2016-01-31T00:00:00+00:00',
      },
    },
    __typename: 'KpiResults',
  },
  netPromoterScore: {
    kpiName: 'netPromoterScore',
    layoutOrder: 2,
    unit: { min: -100, max: 100, unit: null },
    value: 21,
    verbatimsPerKpi: [
      { kpiValue: 'promoters', percentage: 45.8 },
      { kpiValue: 'dectractors', percentage: 29.2 },
      { kpiValue: 'neutrals', percentage: 24.9 },
    ],
    yearBefore: null,
    periodeBefore: {
      value: 13,
      delta: 8,
      period: {
        startDate: '2013-04-15T00:00:00+00:00',
        endDate: '2016-01-31T00:00:00+00:00',
      },
    },
    __typename: 'KpiResults',
  },
  effort: {
    unit: {
      min: 1,
      max: 5,
      unit: null,
    },
    value: 2.8,
    verbatimsPerKpi: [
      { kpiValue: 1, percentage: 33.5 },
      { kpiValue: 2, percentage: 14.2 },
      { kpiValue: 3, percentage: 14.9 },
      { kpiValue: 4, percentage: 14.3 },
      { kpiValue: 5, percentage: 23.2 },
    ],
    yearBefore: null,
    periodBefore: null,
    __typename: 'KpiResults',
  },
};

export const eindexEvolution = {
  eindexEvolutionWithMention: [
    { date: '2016-01-15T00:00:00+00:00', value: 33, mentionCount: 1 },
    { date: '2016-01-16T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-17T00:00:00+00:00', value: -20, mentionCount: 1 },
    { date: '2016-01-18T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-19T00:00:00+00:00', value: 34, mentionCount: 1 },
    { date: '2016-01-20T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-21T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-22T00:00:00+00:00', value: 32, mentionCount: 2 },
    { date: '2016-01-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-24T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-25T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-26T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-27T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-28T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-29T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-01-30T00:00:00+00:00', value: -17, mentionCount: 1 },
    { date: '2016-01-31T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-01T00:00:00+00:00', value: -12, mentionCount: 1 },
    { date: '2016-02-02T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-03T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-04T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-05T00:00:00+00:00', value: 1, mentionCount: 1 },
    { date: '2016-02-06T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-07T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-08T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-09T00:00:00+00:00', value: 4, mentionCount: 1 },
    { date: '2016-02-10T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-11T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-12T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-13T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-14T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-15T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-16T00:00:00+00:00', value: 31, mentionCount: 1 },
    { date: '2016-02-17T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-18T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-19T00:00:00+00:00', value: 31, mentionCount: 1 },
    { date: '2016-02-20T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-21T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-22T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-24T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-25T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-26T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-02-27T00:00:00+00:00', value: 13, mentionCount: 6 },
    { date: '2016-02-28T00:00:00+00:00', value: 22, mentionCount: 4 },
    { date: '2016-02-29T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-03-01T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-03-02T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-03-03T00:00:00+00:00', value: 12, mentionCount: 1 },
    { date: '2016-03-04T00:00:00+00:00', value: 33, mentionCount: 1 },
    { date: '2016-03-05T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-03-06T00:00:00+00:00', value: 17, mentionCount: 4 },
    { date: '2016-03-07T00:00:00+00:00', value: 34, mentionCount: 1 },
    { date: '2016-03-08T00:00:00+00:00', value: -1, mentionCount: 1 },
    { date: '2016-03-09T00:00:00+00:00', value: 7, mentionCount: 1 },
    { date: '2016-03-10T00:00:00+00:00', value: -19, mentionCount: 1 },
    { date: '2016-03-11T00:00:00+00:00', value: 32, mentionCount: 1 },
    { date: '2016-03-12T00:00:00+00:00', value: 12, mentionCount: 2 },
    { date: '2016-03-13T00:00:00+00:00', value: 17, mentionCount: 1 },
    { date: '2016-03-14T00:00:00+00:00', value: 23, mentionCount: 4 },
    { date: '2016-03-15T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-03-16T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-03-17T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-03-18T00:00:00+00:00', value: 6, mentionCount: 2 },
    { date: '2016-03-19T00:00:00+00:00', value: 28, mentionCount: 5 },
    { date: '2016-03-20T00:00:00+00:00', value: 15, mentionCount: 1 },
    { date: '2016-03-21T00:00:00+00:00', value: -3, mentionCount: 2 },
    { date: '2016-03-22T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-03-23T00:00:00+00:00', value: 19, mentionCount: 2 },
    { date: '2016-03-24T00:00:00+00:00', value: 0, mentionCount: 2 },
    { date: '2016-03-25T00:00:00+00:00', value: -6, mentionCount: 2 },
    { date: '2016-03-26T00:00:00+00:00', value: 4, mentionCount: 2 },
    { date: '2016-03-27T00:00:00+00:00', value: 18, mentionCount: 2 },
    { date: '2016-03-28T00:00:00+00:00', value: -20, mentionCount: 1 },
    { date: '2016-03-29T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-03-30T00:00:00+00:00', value: -20, mentionCount: 1 },
    { date: '2016-03-31T00:00:00+00:00', value: 2, mentionCount: 1 },
    { date: '2016-04-01T00:00:00+00:00', value: 18, mentionCount: 2 },
    { date: '2016-04-02T00:00:00+00:00', value: 32, mentionCount: 3 },
    { date: '2016-04-03T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-04-04T00:00:00+00:00', value: 13, mentionCount: 1 },
    { date: '2016-04-05T00:00:00+00:00', value: 31, mentionCount: 1 },
    { date: '2016-04-06T00:00:00+00:00', value: -20, mentionCount: 1 },
    { date: '2016-04-07T00:00:00+00:00', value: 4, mentionCount: 3 },
    { date: '2016-04-08T00:00:00+00:00', value: 9, mentionCount: 5 },
    { date: '2016-04-09T00:00:00+00:00', value: 7, mentionCount: 2 },
    { date: '2016-04-10T00:00:00+00:00', value: 40, mentionCount: 1 },
    { date: '2016-04-11T00:00:00+00:00', value: 12, mentionCount: 1 },
    { date: '2016-04-12T00:00:00+00:00', value: -20, mentionCount: 1 },
    { date: '2016-04-13T00:00:00+00:00', value: 11, mentionCount: 3 },
    { date: '2016-04-14T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-04-15T00:00:00+00:00', value: 26, mentionCount: 3 },
    { date: '2016-04-16T00:00:00+00:00', value: 33, mentionCount: 3 },
    { date: '2016-04-17T00:00:00+00:00', value: 32, mentionCount: 1 },
    { date: '2016-04-18T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-04-19T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-04-20T00:00:00+00:00', value: 13, mentionCount: 2 },
    { date: '2016-04-21T00:00:00+00:00', value: -20, mentionCount: 2 },
    { date: '2016-04-22T00:00:00+00:00', value: 10, mentionCount: 3 },
    { date: '2016-04-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-04-24T00:00:00+00:00', value: 12, mentionCount: 2 },
    { date: '2016-04-25T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-04-26T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-04-27T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-04-28T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-04-29T00:00:00+00:00', value: 32, mentionCount: 1 },
    { date: '2016-04-30T00:00:00+00:00', value: 4, mentionCount: 3 },
    { date: '2016-05-01T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-02T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-03T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-04T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-05T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-06T00:00:00+00:00', value: 21, mentionCount: 4 },
    { date: '2016-05-07T00:00:00+00:00', value: 17, mentionCount: 4 },
    { date: '2016-05-08T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-09T00:00:00+00:00', value: 23, mentionCount: 1 },
    { date: '2016-05-10T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-11T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-12T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-13T00:00:00+00:00', value: 6, mentionCount: 3 },
    { date: '2016-05-14T00:00:00+00:00', value: 4, mentionCount: 1 },
    { date: '2016-05-15T00:00:00+00:00', value: 23, mentionCount: 1 },
    { date: '2016-05-16T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-17T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-18T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-19T00:00:00+00:00', value: -4, mentionCount: 3 },
    { date: '2016-05-20T00:00:00+00:00', value: 33, mentionCount: 1 },
    { date: '2016-05-21T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-22T00:00:00+00:00', value: 3, mentionCount: 1 },
    { date: '2016-05-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-24T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-25T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-26T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-27T00:00:00+00:00', value: 20, mentionCount: 2 },
    { date: '2016-05-28T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-29T00:00:00+00:00', value: 4, mentionCount: 1 },
    { date: '2016-05-30T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-05-31T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-01T00:00:00+00:00', value: 31, mentionCount: 1 },
    { date: '2016-06-02T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-03T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-04T00:00:00+00:00', value: 20, mentionCount: 2 },
    { date: '2016-06-05T00:00:00+00:00', value: 34, mentionCount: 2 },
    { date: '2016-06-06T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-07T00:00:00+00:00', value: 30, mentionCount: 2 },
    { date: '2016-06-08T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-09T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-10T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-11T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-12T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-13T00:00:00+00:00', value: 22, mentionCount: 4 },
    { date: '2016-06-14T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-15T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-16T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-17T00:00:00+00:00', value: 38, mentionCount: 1 },
    { date: '2016-06-18T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-19T00:00:00+00:00', value: 23, mentionCount: 1 },
    { date: '2016-06-20T00:00:00+00:00', value: 33, mentionCount: 2 },
    { date: '2016-06-21T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-22T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-24T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-25T00:00:00+00:00', value: 33, mentionCount: 1 },
    { date: '2016-06-26T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-27T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-28T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-29T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-06-30T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-01T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-02T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-03T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-04T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-05T00:00:00+00:00', value: 12, mentionCount: 1 },
    { date: '2016-07-06T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-07T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-08T00:00:00+00:00', value: 37, mentionCount: 1 },
    { date: '2016-07-09T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-10T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-11T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-12T00:00:00+00:00', value: 34, mentionCount: 1 },
    { date: '2016-07-13T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-14T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-15T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-16T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-17T00:00:00+00:00', value: 32, mentionCount: 1 },
    { date: '2016-07-18T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-19T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-20T00:00:00+00:00', value: 36, mentionCount: 1 },
    { date: '2016-07-21T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-22T00:00:00+00:00', value: 32, mentionCount: 1 },
    { date: '2016-07-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-24T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-25T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-26T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-27T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-28T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-29T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-07-30T00:00:00+00:00', value: 12, mentionCount: 1 },
    { date: '2016-07-31T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-01T00:00:00+00:00', value: 4, mentionCount: 1 },
    { date: '2016-08-02T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-03T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-04T00:00:00+00:00', value: 31, mentionCount: 1 },
    { date: '2016-08-05T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-06T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-07T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-08T00:00:00+00:00', value: 14, mentionCount: 2 },
    { date: '2016-08-09T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-10T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-11T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-12T00:00:00+00:00', value: 38, mentionCount: 1 },
    { date: '2016-08-13T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-14T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-15T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-16T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-17T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-18T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-19T00:00:00+00:00', value: 1, mentionCount: 3 },
    { date: '2016-08-20T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-21T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-22T00:00:00+00:00', value: -5, mentionCount: 1 },
    { date: '2016-08-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-24T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-25T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-26T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-27T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-28T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-29T00:00:00+00:00', value: 32, mentionCount: 1 },
    { date: '2016-08-30T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-08-31T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-01T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-02T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-03T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-04T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-05T00:00:00+00:00', value: -8, mentionCount: 2 },
    { date: '2016-09-06T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-07T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-08T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-09T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-10T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-11T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-12T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-13T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-14T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-15T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-16T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-17T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-18T00:00:00+00:00', value: 32, mentionCount: 1 },
    { date: '2016-09-19T00:00:00+00:00', value: 3, mentionCount: 2 },
    { date: '2016-09-20T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-21T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-22T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-24T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-25T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-26T00:00:00+00:00', value: 3, mentionCount: 1 },
    { date: '2016-09-27T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-28T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-29T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-09-30T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-01T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-02T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-03T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-04T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-05T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-06T00:00:00+00:00', value: 31, mentionCount: 1 },
    { date: '2016-10-07T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-08T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-09T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-10T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-11T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-12T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-13T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-14T00:00:00+00:00', value: 18, mentionCount: 2 },
    { date: '2016-10-15T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-16T00:00:00+00:00', value: 16, mentionCount: 3 },
    { date: '2016-10-17T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-18T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-19T00:00:00+00:00', value: 39, mentionCount: 1 },
    { date: '2016-10-20T00:00:00+00:00', value: 34, mentionCount: 1 },
    { date: '2016-10-21T00:00:00+00:00', value: 15, mentionCount: 3 },
    { date: '2016-10-22T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-24T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-25T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-26T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-27T00:00:00+00:00', value: 23, mentionCount: 1 },
    { date: '2016-10-28T00:00:00+00:00', value: 26, mentionCount: 3 },
    { date: '2016-10-29T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-30T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-10-31T00:00:00+00:00', value: 11, mentionCount: 1 },
    { date: '2016-11-01T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-02T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-03T00:00:00+00:00', value: -8, mentionCount: 2 },
    { date: '2016-11-04T00:00:00+00:00', value: 12, mentionCount: 1 },
    { date: '2016-11-05T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-06T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-07T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-08T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-09T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-10T00:00:00+00:00', value: 4, mentionCount: 1 },
    { date: '2016-11-11T00:00:00+00:00', value: 36, mentionCount: 1 },
    { date: '2016-11-12T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-13T00:00:00+00:00', value: 32, mentionCount: 1 },
    { date: '2016-11-14T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-15T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-16T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-17T00:00:00+00:00', value: 33, mentionCount: 1 },
    { date: '2016-11-18T00:00:00+00:00', value: 6, mentionCount: 2 },
    { date: '2016-11-19T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-20T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-21T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-22T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-23T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-24T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-25T00:00:00+00:00', value: 34, mentionCount: 2 },
    { date: '2016-11-26T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-27T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-28T00:00:00+00:00', value: 34, mentionCount: 1 },
    { date: '2016-11-29T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-11-30T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-01T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-02T00:00:00+00:00', value: 8, mentionCount: 2 },
    { date: '2016-12-03T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-04T00:00:00+00:00', value: -9, mentionCount: 1 },
    { date: '2016-12-05T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-06T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-07T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-08T00:00:00+00:00', value: 15, mentionCount: 3 },
    { date: '2016-12-09T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-10T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-11T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-12T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-13T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-14T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-15T00:00:00+00:00', value: null, mentionCount: 0 },
    { date: '2016-12-16T00:00:00+00:00', value: 23, mentionCount: 10 },
    { date: '2016-12-17T00:00:00+00:00', value: 13, mentionCount: 6 },
    { date: '2016-12-18T00:00:00+00:00', value: 22, mentionCount: 4 },
    { date: '2016-12-19T00:00:00+00:00', value: 30, mentionCount: 5 },
    { date: '2016-12-20T00:00:00+00:00', value: 24, mentionCount: 6 },
    { date: '2016-12-21T00:00:00+00:00', value: 21, mentionCount: 8 },
    { date: '2016-12-22T00:00:00+00:00', value: 32, mentionCount: 2 },
    { date: '2016-12-23T00:00:00+00:00', value: 8, mentionCount: 9 },
    { date: '2016-12-24T00:00:00+00:00', value: -12, mentionCount: 2 },
    { date: '2016-12-25T00:00:00+00:00', value: 4, mentionCount: 3 },
    { date: '2016-12-26T00:00:00+00:00', value: 14, mentionCount: 12 },
    { date: '2016-12-27T00:00:00+00:00', value: 9, mentionCount: 5 },
  ],
};

export const emotionBrakeDownEvolution = {
  emotionEvolution: [
    {
        "date": "2020-08-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-08-31T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-09-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-10-31T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-11-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2020-12-31T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-01-31T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-02-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-03-31T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-04-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-05-31T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-06-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-07-31T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-30T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-08-31T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-01T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-02T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-03T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-04T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-05T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-06T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-07T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-08T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-09T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-10T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-11T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-12T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-13T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-14T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-15T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-16T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-17T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-18T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-19T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-20T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-21T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-22T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-23T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-24T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 3,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 3,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-25T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-26T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 1,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 1,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-27T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-28T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-29T00:00:00+00:00",
        "emotions": {
            "happiness": 0,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 0,
            "negative": 0,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-09-30T00:00:00+00:00",
        "emotions": {
            "happiness": 1,
            "surprise": 1,
            "calm": 0,
            "fear": 0,
            "sadness": 2,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 2,
            "negative": 2,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-01T00:00:00+00:00",
        "emotions": {
            "happiness": 135,
            "surprise": 9,
            "calm": 21,
            "fear": 7,
            "sadness": 64,
            "anger": 8,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 144,
            "negative": 81,
            "neutral": 21,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-02T00:00:00+00:00",
        "emotions": {
            "happiness": 33,
            "surprise": 0,
            "calm": 9,
            "fear": 1,
            "sadness": 16,
            "anger": 2,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 33,
            "negative": 21,
            "neutral": 9,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-03T00:00:00+00:00",
        "emotions": {
            "happiness": 18,
            "surprise": 1,
            "calm": 2,
            "fear": 0,
            "sadness": 12,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 19,
            "negative": 13,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-04T00:00:00+00:00",
        "emotions": {
            "happiness": 12,
            "surprise": 3,
            "calm": 2,
            "fear": 0,
            "sadness": 10,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 15,
            "negative": 12,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-05T00:00:00+00:00",
        "emotions": {
            "happiness": 8,
            "surprise": 0,
            "calm": 4,
            "fear": 2,
            "sadness": 5,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 8,
            "negative": 8,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-06T00:00:00+00:00",
        "emotions": {
            "happiness": 3,
            "surprise": 2,
            "calm": 1,
            "fear": 0,
            "sadness": 3,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 5,
            "negative": 5,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-07T00:00:00+00:00",
        "emotions": {
            "happiness": 291,
            "surprise": 20,
            "calm": 34,
            "fear": 8,
            "sadness": 103,
            "anger": 16,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 311,
            "negative": 128,
            "neutral": 34,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-08T00:00:00+00:00",
        "emotions": {
            "happiness": 174,
            "surprise": 6,
            "calm": 27,
            "fear": 7,
            "sadness": 73,
            "anger": 11,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 180,
            "negative": 91,
            "neutral": 27,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-09T00:00:00+00:00",
        "emotions": {
            "happiness": 41,
            "surprise": 3,
            "calm": 7,
            "fear": 2,
            "sadness": 17,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 44,
            "negative": 19,
            "neutral": 7,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-10T00:00:00+00:00",
        "emotions": {
            "happiness": 18,
            "surprise": 0,
            "calm": 0,
            "fear": 1,
            "sadness": 7,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 18,
            "negative": 8,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-11T00:00:00+00:00",
        "emotions": {
            "happiness": 19,
            "surprise": 1,
            "calm": 8,
            "fear": 1,
            "sadness": 8,
            "anger": 0,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 20,
            "negative": 10,
            "neutral": 8,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-12T00:00:00+00:00",
        "emotions": {
            "happiness": 12,
            "surprise": 1,
            "calm": 1,
            "fear": 1,
            "sadness": 4,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 13,
            "negative": 5,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-13T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 3,
            "anger": 0,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 4,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-14T00:00:00+00:00",
        "emotions": {
            "happiness": 237,
            "surprise": 16,
            "calm": 44,
            "fear": 11,
            "sadness": 105,
            "anger": 11,
            "disgust": 4,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 253,
            "negative": 131,
            "neutral": 44,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-15T00:00:00+00:00",
        "emotions": {
            "happiness": 161,
            "surprise": 12,
            "calm": 22,
            "fear": 13,
            "sadness": 86,
            "anger": 11,
            "disgust": 4,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 173,
            "negative": 114,
            "neutral": 22,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-16T00:00:00+00:00",
        "emotions": {
            "happiness": 34,
            "surprise": 2,
            "calm": 8,
            "fear": 0,
            "sadness": 10,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 36,
            "negative": 11,
            "neutral": 8,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-17T00:00:00+00:00",
        "emotions": {
            "happiness": 15,
            "surprise": 1,
            "calm": 3,
            "fear": 2,
            "sadness": 8,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 16,
            "negative": 10,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-18T00:00:00+00:00",
        "emotions": {
            "happiness": 24,
            "surprise": 0,
            "calm": 5,
            "fear": 0,
            "sadness": 13,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 24,
            "negative": 13,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-19T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 0,
            "calm": 2,
            "fear": 2,
            "sadness": 3,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 7,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-20T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 4,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 5,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-21T00:00:00+00:00",
        "emotions": {
            "happiness": 325,
            "surprise": 20,
            "calm": 56,
            "fear": 26,
            "sadness": 164,
            "anger": 17,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 345,
            "negative": 208,
            "neutral": 56,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-22T00:00:00+00:00",
        "emotions": {
            "happiness": 178,
            "surprise": 9,
            "calm": 22,
            "fear": 11,
            "sadness": 88,
            "anger": 16,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 187,
            "negative": 117,
            "neutral": 22,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-23T00:00:00+00:00",
        "emotions": {
            "happiness": 42,
            "surprise": 2,
            "calm": 8,
            "fear": 4,
            "sadness": 28,
            "anger": 5,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 44,
            "negative": 37,
            "neutral": 8,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-24T00:00:00+00:00",
        "emotions": {
            "happiness": 15,
            "surprise": 4,
            "calm": 2,
            "fear": 1,
            "sadness": 9,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 19,
            "negative": 12,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-25T00:00:00+00:00",
        "emotions": {
            "happiness": 17,
            "surprise": 0,
            "calm": 3,
            "fear": 0,
            "sadness": 8,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 17,
            "negative": 8,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-26T00:00:00+00:00",
        "emotions": {
            "happiness": 17,
            "surprise": 1,
            "calm": 4,
            "fear": 0,
            "sadness": 6,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 18,
            "negative": 7,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-27T00:00:00+00:00",
        "emotions": {
            "happiness": 7,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 2,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 7,
            "negative": 2,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-28T00:00:00+00:00",
        "emotions": {
            "happiness": 265,
            "surprise": 12,
            "calm": 34,
            "fear": 14,
            "sadness": 114,
            "anger": 19,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 277,
            "negative": 148,
            "neutral": 34,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-29T00:00:00+00:00",
        "emotions": {
            "happiness": 171,
            "surprise": 8,
            "calm": 32,
            "fear": 8,
            "sadness": 72,
            "anger": 8,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 179,
            "negative": 90,
            "neutral": 32,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-30T00:00:00+00:00",
        "emotions": {
            "happiness": 43,
            "surprise": 3,
            "calm": 6,
            "fear": 5,
            "sadness": 25,
            "anger": 4,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 46,
            "negative": 35,
            "neutral": 6,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-10-31T00:00:00+00:00",
        "emotions": {
            "happiness": 21,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 9,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 21,
            "negative": 9,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-01T00:00:00+00:00",
        "emotions": {
            "happiness": 7,
            "surprise": 0,
            "calm": 3,
            "fear": 0,
            "sadness": 6,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 7,
            "negative": 6,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-02T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 0,
            "calm": 3,
            "fear": 0,
            "sadness": 2,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 3,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-03T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 0,
            "calm": 0,
            "fear": 1,
            "sadness": 2,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 3,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-04T00:00:00+00:00",
        "emotions": {
            "happiness": 285,
            "surprise": 18,
            "calm": 47,
            "fear": 12,
            "sadness": 133,
            "anger": 21,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 303,
            "negative": 169,
            "neutral": 47,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-05T00:00:00+00:00",
        "emotions": {
            "happiness": 180,
            "surprise": 9,
            "calm": 25,
            "fear": 6,
            "sadness": 81,
            "anger": 8,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 189,
            "negative": 98,
            "neutral": 25,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-06T00:00:00+00:00",
        "emotions": {
            "happiness": 42,
            "surprise": 3,
            "calm": 4,
            "fear": 1,
            "sadness": 19,
            "anger": 5,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 45,
            "negative": 25,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-07T00:00:00+00:00",
        "emotions": {
            "happiness": 19,
            "surprise": 1,
            "calm": 4,
            "fear": 1,
            "sadness": 10,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 20,
            "negative": 12,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-08T00:00:00+00:00",
        "emotions": {
            "happiness": 21,
            "surprise": 2,
            "calm": 2,
            "fear": 1,
            "sadness": 6,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 23,
            "negative": 8,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-09T00:00:00+00:00",
        "emotions": {
            "happiness": 16,
            "surprise": 0,
            "calm": 3,
            "fear": 1,
            "sadness": 7,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 16,
            "negative": 10,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-10T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 2,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 2,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-11T00:00:00+00:00",
        "emotions": {
            "happiness": 172,
            "surprise": 11,
            "calm": 27,
            "fear": 7,
            "sadness": 104,
            "anger": 12,
            "disgust": 4,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 183,
            "negative": 127,
            "neutral": 27,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-12T00:00:00+00:00",
        "emotions": {
            "happiness": 129,
            "surprise": 8,
            "calm": 25,
            "fear": 5,
            "sadness": 82,
            "anger": 6,
            "disgust": 4,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 137,
            "negative": 97,
            "neutral": 25,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-13T00:00:00+00:00",
        "emotions": {
            "happiness": 39,
            "surprise": 2,
            "calm": 5,
            "fear": 3,
            "sadness": 17,
            "anger": 3,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 41,
            "negative": 23,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-14T00:00:00+00:00",
        "emotions": {
            "happiness": 8,
            "surprise": 1,
            "calm": 2,
            "fear": 0,
            "sadness": 8,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 9,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-15T00:00:00+00:00",
        "emotions": {
            "happiness": 24,
            "surprise": 1,
            "calm": 5,
            "fear": 1,
            "sadness": 4,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 25,
            "negative": 6,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-16T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 2,
            "calm": 1,
            "fear": 0,
            "sadness": 2,
            "anger": 0,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 11,
            "negative": 3,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-17T00:00:00+00:00",
        "emotions": {
            "happiness": 3,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 2,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 3,
            "negative": 2,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-18T00:00:00+00:00",
        "emotions": {
            "happiness": 277,
            "surprise": 13,
            "calm": 46,
            "fear": 10,
            "sadness": 129,
            "anger": 13,
            "disgust": 5,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 290,
            "negative": 157,
            "neutral": 46,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-19T00:00:00+00:00",
        "emotions": {
            "happiness": 122,
            "surprise": 10,
            "calm": 18,
            "fear": 5,
            "sadness": 73,
            "anger": 6,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 132,
            "negative": 86,
            "neutral": 18,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-20T00:00:00+00:00",
        "emotions": {
            "happiness": 32,
            "surprise": 1,
            "calm": 9,
            "fear": 0,
            "sadness": 19,
            "anger": 0,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 33,
            "negative": 21,
            "neutral": 9,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-21T00:00:00+00:00",
        "emotions": {
            "happiness": 23,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 8,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 23,
            "negative": 8,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-22T00:00:00+00:00",
        "emotions": {
            "happiness": 18,
            "surprise": 2,
            "calm": 4,
            "fear": 2,
            "sadness": 7,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 20,
            "negative": 11,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-23T00:00:00+00:00",
        "emotions": {
            "happiness": 12,
            "surprise": 1,
            "calm": 0,
            "fear": 0,
            "sadness": 4,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 13,
            "negative": 4,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-24T00:00:00+00:00",
        "emotions": {
            "happiness": 4,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 1,
            "anger": 2,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 4,
            "negative": 4,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-25T00:00:00+00:00",
        "emotions": {
            "happiness": 212,
            "surprise": 8,
            "calm": 22,
            "fear": 10,
            "sadness": 98,
            "anger": 17,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 220,
            "negative": 126,
            "neutral": 22,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-26T00:00:00+00:00",
        "emotions": {
            "happiness": 147,
            "surprise": 8,
            "calm": 24,
            "fear": 9,
            "sadness": 77,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 155,
            "negative": 88,
            "neutral": 24,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-27T00:00:00+00:00",
        "emotions": {
            "happiness": 32,
            "surprise": 4,
            "calm": 10,
            "fear": 1,
            "sadness": 17,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 36,
            "negative": 19,
            "neutral": 10,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-28T00:00:00+00:00",
        "emotions": {
            "happiness": 10,
            "surprise": 1,
            "calm": 3,
            "fear": 1,
            "sadness": 6,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 11,
            "negative": 7,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-29T00:00:00+00:00",
        "emotions": {
            "happiness": 5,
            "surprise": 1,
            "calm": 3,
            "fear": 1,
            "sadness": 5,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 6,
            "negative": 6,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-11-30T00:00:00+00:00",
        "emotions": {
            "happiness": 6,
            "surprise": 1,
            "calm": 2,
            "fear": 0,
            "sadness": 5,
            "anger": 0,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 7,
            "negative": 6,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-01T00:00:00+00:00",
        "emotions": {
            "happiness": 3,
            "surprise": 0,
            "calm": 0,
            "fear": 1,
            "sadness": 0,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 3,
            "negative": 1,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-02T00:00:00+00:00",
        "emotions": {
            "happiness": 242,
            "surprise": 12,
            "calm": 38,
            "fear": 10,
            "sadness": 127,
            "anger": 14,
            "disgust": 4,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 254,
            "negative": 155,
            "neutral": 38,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-03T00:00:00+00:00",
        "emotions": {
            "happiness": 130,
            "surprise": 11,
            "calm": 21,
            "fear": 4,
            "sadness": 66,
            "anger": 7,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 141,
            "negative": 77,
            "neutral": 21,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-04T00:00:00+00:00",
        "emotions": {
            "happiness": 27,
            "surprise": 1,
            "calm": 6,
            "fear": 1,
            "sadness": 15,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 28,
            "negative": 18,
            "neutral": 6,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-05T00:00:00+00:00",
        "emotions": {
            "happiness": 11,
            "surprise": 0,
            "calm": 2,
            "fear": 0,
            "sadness": 7,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 11,
            "negative": 8,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-06T00:00:00+00:00",
        "emotions": {
            "happiness": 12,
            "surprise": 1,
            "calm": 0,
            "fear": 0,
            "sadness": 8,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 13,
            "negative": 10,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-07T00:00:00+00:00",
        "emotions": {
            "happiness": 7,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 5,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 7,
            "negative": 5,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-08T00:00:00+00:00",
        "emotions": {
            "happiness": 2,
            "surprise": 0,
            "calm": 2,
            "fear": 0,
            "sadness": 3,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 2,
            "negative": 3,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-09T00:00:00+00:00",
        "emotions": {
            "happiness": 303,
            "surprise": 18,
            "calm": 29,
            "fear": 11,
            "sadness": 136,
            "anger": 15,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 321,
            "negative": 165,
            "neutral": 29,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-10T00:00:00+00:00",
        "emotions": {
            "happiness": 138,
            "surprise": 10,
            "calm": 27,
            "fear": 6,
            "sadness": 68,
            "anger": 9,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 148,
            "negative": 84,
            "neutral": 27,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-11T00:00:00+00:00",
        "emotions": {
            "happiness": 28,
            "surprise": 3,
            "calm": 8,
            "fear": 0,
            "sadness": 16,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 31,
            "negative": 18,
            "neutral": 8,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-12T00:00:00+00:00",
        "emotions": {
            "happiness": 16,
            "surprise": 1,
            "calm": 4,
            "fear": 1,
            "sadness": 7,
            "anger": 1,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 17,
            "negative": 10,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-13T00:00:00+00:00",
        "emotions": {
            "happiness": 18,
            "surprise": 1,
            "calm": 2,
            "fear": 1,
            "sadness": 4,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 19,
            "negative": 6,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-14T00:00:00+00:00",
        "emotions": {
            "happiness": 13,
            "surprise": 1,
            "calm": 1,
            "fear": 0,
            "sadness": 5,
            "anger": 0,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 14,
            "negative": 6,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-15T00:00:00+00:00",
        "emotions": {
            "happiness": 1,
            "surprise": 1,
            "calm": 1,
            "fear": 1,
            "sadness": 3,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 2,
            "negative": 4,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-16T00:00:00+00:00",
        "emotions": {
            "happiness": 266,
            "surprise": 14,
            "calm": 46,
            "fear": 11,
            "sadness": 127,
            "anger": 17,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 280,
            "negative": 156,
            "neutral": 46,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-17T00:00:00+00:00",
        "emotions": {
            "happiness": 145,
            "surprise": 7,
            "calm": 29,
            "fear": 7,
            "sadness": 87,
            "anger": 9,
            "disgust": 5,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 152,
            "negative": 108,
            "neutral": 29,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-18T00:00:00+00:00",
        "emotions": {
            "happiness": 35,
            "surprise": 1,
            "calm": 4,
            "fear": 3,
            "sadness": 23,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 36,
            "negative": 27,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-19T00:00:00+00:00",
        "emotions": {
            "happiness": 17,
            "surprise": 1,
            "calm": 7,
            "fear": 2,
            "sadness": 7,
            "anger": 1,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 18,
            "negative": 11,
            "neutral": 7,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-20T00:00:00+00:00",
        "emotions": {
            "happiness": 22,
            "surprise": 0,
            "calm": 0,
            "fear": 2,
            "sadness": 15,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 22,
            "negative": 19,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-21T00:00:00+00:00",
        "emotions": {
            "happiness": 8,
            "surprise": 1,
            "calm": 0,
            "fear": 0,
            "sadness": 5,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 5,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-22T00:00:00+00:00",
        "emotions": {
            "happiness": 4,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 4,
            "anger": 0,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 4,
            "negative": 5,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-23T00:00:00+00:00",
        "emotions": {
            "happiness": 312,
            "surprise": 18,
            "calm": 42,
            "fear": 12,
            "sadness": 135,
            "anger": 13,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 330,
            "negative": 161,
            "neutral": 42,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-24T00:00:00+00:00",
        "emotions": {
            "happiness": 130,
            "surprise": 8,
            "calm": 25,
            "fear": 7,
            "sadness": 61,
            "anger": 9,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 138,
            "negative": 78,
            "neutral": 25,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-25T00:00:00+00:00",
        "emotions": {
            "happiness": 25,
            "surprise": 3,
            "calm": 4,
            "fear": 1,
            "sadness": 10,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 28,
            "negative": 13,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-26T00:00:00+00:00",
        "emotions": {
            "happiness": 38,
            "surprise": 2,
            "calm": 5,
            "fear": 0,
            "sadness": 17,
            "anger": 3,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 40,
            "negative": 21,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-27T00:00:00+00:00",
        "emotions": {
            "happiness": 24,
            "surprise": 2,
            "calm": 5,
            "fear": 0,
            "sadness": 10,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 26,
            "negative": 10,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-28T00:00:00+00:00",
        "emotions": {
            "happiness": 23,
            "surprise": 1,
            "calm": 2,
            "fear": 1,
            "sadness": 7,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 24,
            "negative": 9,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-29T00:00:00+00:00",
        "emotions": {
            "happiness": 10,
            "surprise": 0,
            "calm": 1,
            "fear": 1,
            "sadness": 1,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 10,
            "negative": 2,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-30T00:00:00+00:00",
        "emotions": {
            "happiness": 286,
            "surprise": 13,
            "calm": 30,
            "fear": 24,
            "sadness": 127,
            "anger": 20,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 299,
            "negative": 174,
            "neutral": 30,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2021-12-31T00:00:00+00:00",
        "emotions": {
            "happiness": 168,
            "surprise": 9,
            "calm": 24,
            "fear": 7,
            "sadness": 79,
            "anger": 8,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 177,
            "negative": 95,
            "neutral": 24,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-01T00:00:00+00:00",
        "emotions": {
            "happiness": 38,
            "surprise": 2,
            "calm": 6,
            "fear": 4,
            "sadness": 16,
            "anger": 3,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 40,
            "negative": 23,
            "neutral": 6,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-02T00:00:00+00:00",
        "emotions": {
            "happiness": 31,
            "surprise": 1,
            "calm": 7,
            "fear": 2,
            "sadness": 13,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 32,
            "negative": 17,
            "neutral": 7,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-03T00:00:00+00:00",
        "emotions": {
            "happiness": 17,
            "surprise": 3,
            "calm": 7,
            "fear": 3,
            "sadness": 15,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 20,
            "negative": 20,
            "neutral": 7,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-04T00:00:00+00:00",
        "emotions": {
            "happiness": 16,
            "surprise": 0,
            "calm": 2,
            "fear": 1,
            "sadness": 9,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 16,
            "negative": 11,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-05T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 0,
            "calm": 2,
            "fear": 1,
            "sadness": 2,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 4,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-06T00:00:00+00:00",
        "emotions": {
            "happiness": 268,
            "surprise": 22,
            "calm": 33,
            "fear": 13,
            "sadness": 98,
            "anger": 11,
            "disgust": 4,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 290,
            "negative": 126,
            "neutral": 33,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-07T00:00:00+00:00",
        "emotions": {
            "happiness": 149,
            "surprise": 9,
            "calm": 25,
            "fear": 6,
            "sadness": 74,
            "anger": 9,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 158,
            "negative": 89,
            "neutral": 25,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-08T00:00:00+00:00",
        "emotions": {
            "happiness": 43,
            "surprise": 3,
            "calm": 6,
            "fear": 1,
            "sadness": 18,
            "anger": 3,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 46,
            "negative": 22,
            "neutral": 6,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-09T00:00:00+00:00",
        "emotions": {
            "happiness": 18,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 6,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 18,
            "negative": 8,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-10T00:00:00+00:00",
        "emotions": {
            "happiness": 14,
            "surprise": 2,
            "calm": 3,
            "fear": 1,
            "sadness": 4,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 16,
            "negative": 6,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-11T00:00:00+00:00",
        "emotions": {
            "happiness": 6,
            "surprise": 0,
            "calm": 2,
            "fear": 0,
            "sadness": 7,
            "anger": 0,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 6,
            "negative": 8,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-12T00:00:00+00:00",
        "emotions": {
            "happiness": 7,
            "surprise": 2,
            "calm": 1,
            "fear": 0,
            "sadness": 4,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 4,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-13T00:00:00+00:00",
        "emotions": {
            "happiness": 73,
            "surprise": 3,
            "calm": 30,
            "fear": 5,
            "sadness": 60,
            "anger": 11,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 76,
            "negative": 77,
            "neutral": 30,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-14T00:00:00+00:00",
        "emotions": {
            "happiness": 80,
            "surprise": 5,
            "calm": 31,
            "fear": 12,
            "sadness": 83,
            "anger": 15,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 85,
            "negative": 112,
            "neutral": 31,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-15T00:00:00+00:00",
        "emotions": {
            "happiness": 27,
            "surprise": 1,
            "calm": 21,
            "fear": 4,
            "sadness": 20,
            "anger": 2,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 28,
            "negative": 27,
            "neutral": 21,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-16T00:00:00+00:00",
        "emotions": {
            "happiness": 5,
            "surprise": 1,
            "calm": 6,
            "fear": 1,
            "sadness": 2,
            "anger": 2,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 6,
            "negative": 6,
            "neutral": 6,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-17T00:00:00+00:00",
        "emotions": {
            "happiness": 10,
            "surprise": 2,
            "calm": 5,
            "fear": 3,
            "sadness": 8,
            "anger": 3,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 12,
            "negative": 14,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-18T00:00:00+00:00",
        "emotions": {
            "happiness": 3,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 4,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 3,
            "negative": 4,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-19T00:00:00+00:00",
        "emotions": {
            "happiness": 3,
            "surprise": 0,
            "calm": 3,
            "fear": 0,
            "sadness": 3,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 3,
            "negative": 3,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-20T00:00:00+00:00",
        "emotions": {
            "happiness": 192,
            "surprise": 8,
            "calm": 133,
            "fear": 28,
            "sadness": 173,
            "anger": 23,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 200,
            "negative": 227,
            "neutral": 133,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-21T00:00:00+00:00",
        "emotions": {
            "happiness": 77,
            "surprise": 6,
            "calm": 52,
            "fear": 8,
            "sadness": 56,
            "anger": 9,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 83,
            "negative": 75,
            "neutral": 52,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-22T00:00:00+00:00",
        "emotions": {
            "happiness": 25,
            "surprise": 1,
            "calm": 19,
            "fear": 2,
            "sadness": 19,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 26,
            "negative": 23,
            "neutral": 19,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-23T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 1,
            "calm": 4,
            "fear": 1,
            "sadness": 7,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 10,
            "negative": 9,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-24T00:00:00+00:00",
        "emotions": {
            "happiness": 9,
            "surprise": 1,
            "calm": 5,
            "fear": 2,
            "sadness": 7,
            "anger": 1,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 10,
            "negative": 11,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-25T00:00:00+00:00",
        "emotions": {
            "happiness": 7,
            "surprise": 0,
            "calm": 4,
            "fear": 2,
            "sadness": 4,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 7,
            "negative": 7,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-26T00:00:00+00:00",
        "emotions": {
            "happiness": 3,
            "surprise": 0,
            "calm": 2,
            "fear": 2,
            "sadness": 2,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 3,
            "negative": 6,
            "neutral": 2,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-27T00:00:00+00:00",
        "emotions": {
            "happiness": 97,
            "surprise": 3,
            "calm": 44,
            "fear": 10,
            "sadness": 97,
            "anger": 16,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 100,
            "negative": 126,
            "neutral": 44,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-28T00:00:00+00:00",
        "emotions": {
            "happiness": 81,
            "surprise": 5,
            "calm": 57,
            "fear": 5,
            "sadness": 82,
            "anger": 16,
            "disgust": 4,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 86,
            "negative": 107,
            "neutral": 57,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-29T00:00:00+00:00",
        "emotions": {
            "happiness": 21,
            "surprise": 1,
            "calm": 9,
            "fear": 3,
            "sadness": 18,
            "anger": 3,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 22,
            "negative": 25,
            "neutral": 9,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-30T00:00:00+00:00",
        "emotions": {
            "happiness": 10,
            "surprise": 0,
            "calm": 5,
            "fear": 0,
            "sadness": 7,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 10,
            "negative": 7,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-01-31T00:00:00+00:00",
        "emotions": {
            "happiness": 10,
            "surprise": 1,
            "calm": 5,
            "fear": 2,
            "sadness": 2,
            "anger": 2,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 11,
            "negative": 7,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-01T00:00:00+00:00",
        "emotions": {
            "happiness": 6,
            "surprise": 2,
            "calm": 5,
            "fear": 1,
            "sadness": 8,
            "anger": 0,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 8,
            "negative": 11,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-02T00:00:00+00:00",
        "emotions": {
            "happiness": 6,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 3,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 6,
            "negative": 3,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-03T00:00:00+00:00",
        "emotions": {
            "happiness": 89,
            "surprise": 9,
            "calm": 68,
            "fear": 9,
            "sadness": 68,
            "anger": 10,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 98,
            "negative": 89,
            "neutral": 68,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-04T00:00:00+00:00",
        "emotions": {
            "happiness": 48,
            "surprise": 1,
            "calm": 33,
            "fear": 2,
            "sadness": 50,
            "anger": 8,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 49,
            "negative": 62,
            "neutral": 33,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-05T00:00:00+00:00",
        "emotions": {
            "happiness": 11,
            "surprise": 0,
            "calm": 3,
            "fear": 1,
            "sadness": 8,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 11,
            "negative": 10,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-06T00:00:00+00:00",
        "emotions": {
            "happiness": 3,
            "surprise": 0,
            "calm": 3,
            "fear": 1,
            "sadness": 8,
            "anger": 2,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 3,
            "negative": 12,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-07T00:00:00+00:00",
        "emotions": {
            "happiness": 5,
            "surprise": 0,
            "calm": 3,
            "fear": 1,
            "sadness": 7,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 5,
            "negative": 9,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-08T00:00:00+00:00",
        "emotions": {
            "happiness": 1,
            "surprise": 0,
            "calm": 0,
            "fear": 0,
            "sadness": 2,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 1,
            "negative": 2,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-09T00:00:00+00:00",
        "emotions": {
            "happiness": 4,
            "surprise": 0,
            "calm": 1,
            "fear": 0,
            "sadness": 3,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 4,
            "negative": 3,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-10T00:00:00+00:00",
        "emotions": {
            "happiness": 122,
            "surprise": 1,
            "calm": 64,
            "fear": 19,
            "sadness": 86,
            "anger": 21,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 123,
            "negative": 129,
            "neutral": 64,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-11T00:00:00+00:00",
        "emotions": {
            "happiness": 69,
            "surprise": 3,
            "calm": 36,
            "fear": 4,
            "sadness": 53,
            "anger": 4,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 72,
            "negative": 64,
            "neutral": 36,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-12T00:00:00+00:00",
        "emotions": {
            "happiness": 11,
            "surprise": 1,
            "calm": 11,
            "fear": 2,
            "sadness": 10,
            "anger": 4,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 12,
            "negative": 16,
            "neutral": 11,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-13T00:00:00+00:00",
        "emotions": {
            "happiness": 4,
            "surprise": 0,
            "calm": 4,
            "fear": 0,
            "sadness": 6,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 4,
            "negative": 7,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-14T00:00:00+00:00",
        "emotions": {
            "happiness": 4,
            "surprise": 0,
            "calm": 4,
            "fear": 0,
            "sadness": 9,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 4,
            "negative": 9,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-15T00:00:00+00:00",
        "emotions": {
            "happiness": 4,
            "surprise": 2,
            "calm": 1,
            "fear": 1,
            "sadness": 1,
            "anger": 1,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 6,
            "negative": 3,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-16T00:00:00+00:00",
        "emotions": {
            "happiness": 2,
            "surprise": 0,
            "calm": 4,
            "fear": 0,
            "sadness": 3,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 2,
            "negative": 3,
            "neutral": 4,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-17T00:00:00+00:00",
        "emotions": {
            "happiness": 175,
            "surprise": 6,
            "calm": 71,
            "fear": 12,
            "sadness": 113,
            "anger": 28,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 181,
            "negative": 156,
            "neutral": 71,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-18T00:00:00+00:00",
        "emotions": {
            "happiness": 73,
            "surprise": 2,
            "calm": 46,
            "fear": 9,
            "sadness": 58,
            "anger": 15,
            "disgust": 2,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 75,
            "negative": 84,
            "neutral": 46,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-19T00:00:00+00:00",
        "emotions": {
            "happiness": 18,
            "surprise": 0,
            "calm": 8,
            "fear": 4,
            "sadness": 17,
            "anger": 3,
            "disgust": 1,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 18,
            "negative": 25,
            "neutral": 8,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-20T00:00:00+00:00",
        "emotions": {
            "happiness": 8,
            "surprise": 0,
            "calm": 8,
            "fear": 0,
            "sadness": 3,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 8,
            "negative": 5,
            "neutral": 8,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-21T00:00:00+00:00",
        "emotions": {
            "happiness": 6,
            "surprise": 3,
            "calm": 1,
            "fear": 1,
            "sadness": 6,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 9,
            "negative": 7,
            "neutral": 1,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-22T00:00:00+00:00",
        "emotions": {
            "happiness": 5,
            "surprise": 0,
            "calm": 0,
            "fear": 2,
            "sadness": 6,
            "anger": 3,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 5,
            "negative": 11,
            "neutral": 0,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-23T00:00:00+00:00",
        "emotions": {
            "happiness": 5,
            "surprise": 0,
            "calm": 3,
            "fear": 1,
            "sadness": 2,
            "anger": 0,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 5,
            "negative": 3,
            "neutral": 3,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-24T00:00:00+00:00",
        "emotions": {
            "happiness": 126,
            "surprise": 8,
            "calm": 63,
            "fear": 12,
            "sadness": 112,
            "anger": 30,
            "disgust": 4,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 134,
            "negative": 158,
            "neutral": 63,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-25T00:00:00+00:00",
        "emotions": {
            "happiness": 79,
            "surprise": 4,
            "calm": 46,
            "fear": 11,
            "sadness": 75,
            "anger": 12,
            "disgust": 3,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 83,
            "negative": 101,
            "neutral": 46,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-26T00:00:00+00:00",
        "emotions": {
            "happiness": 22,
            "surprise": 0,
            "calm": 12,
            "fear": 4,
            "sadness": 17,
            "anger": 3,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 22,
            "negative": 24,
            "neutral": 12,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-27T00:00:00+00:00",
        "emotions": {
            "happiness": 14,
            "surprise": 0,
            "calm": 7,
            "fear": 0,
            "sadness": 5,
            "anger": 3,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 14,
            "negative": 8,
            "neutral": 7,
            "__typename": "SentimentsBreakdown"
        }
    },
    {
        "date": "2022-02-28T00:00:00+00:00",
        "emotions": {
            "happiness": 6,
            "surprise": 0,
            "calm": 5,
            "fear": 2,
            "sadness": 10,
            "anger": 2,
            "disgust": 0,
            "__typename": "EmotionsBreakdown"
        },
        "sentiments": {
            "positive": 6,
            "negative": 14,
            "neutral": 5,
            "__typename": "SentimentsBreakdown"
        }
    },
  ]
};

export const verbatimBestWorstAndRandom = {
  worstVerbatim: [
    {
      id: 243497,
      kpis: { eindex: { value: -20 } },
      verbatim: {
        verbatimFullText:
          'Vestibulum id ligula porta felis euismod semper. Donec sed odio dui.',
        verbatimSentences: [
          {
            sentence: 'Vestibulum id ligula porta felis euismod semper.',
            relevantThematic: true,
          },
          {
            sentence: 'Donec sed odio dui.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 252219,
      kpis: { eindex: { value: -20 } },
      verbatim: {
        verbatimFullText:
          'Maecenas sed diam eget risus varius blandit sit amet non magna. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nulla vitae elit libero, a pharetra augue.',
        verbatimSentences: [
          {
            sentence:
              'Maecenas sed diam eget risus varius blandit sit amet non magna.',
            relevantThematic: false,
          },
          {
            sentence:
              'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
            relevantThematic: true,
          },
          {
            sentence: 'Nulla vitae elit libero, a pharetra augue.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 243641,
      kpis: { eindex: { value: -20 } },
      verbatim: {
        verbatimFullText:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam id dolor id nibh ultricies vehicula ut id elit. Sed posuere consectetur est at lobortis.',
        verbatimSentences: [
          {
            sentence:
              'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
            relevantThematic: true,
          },
          {
            sentence: 'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            relevantThematic: false,
          },
          {
            sentence: 'Sed posuere consectetur est at lobortis.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 259123,
      kpis: { eindex: { value: -20 } },
      verbatim: {
        verbatimFullText:
          'Nullam id dolor id nibh ultricies vehicula ut id elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
        verbatimSentences: [
          {
            sentence: 'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            relevantThematic: true,
          },
          {
            sentence:
              'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
            relevantThematic: false,
          },
        ],
      },
    },

    {
      id: 259123,
      kpis: { eindex: { value: -20 } },
      verbatim: {
        verbatimFullText:
          'Nullam id dolor id nibh ultricies vehicula ut id elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
        verbatimSentences: [
          {
            sentence: 'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            relevantThematic: false,
          },
          {
            sentence:
              'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 243641,
      kpis: { eindex: { value: -20 } },
      verbatim: {
        verbatimFullText:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam id dolor id nibh ultricies vehicula ut id elit. Sed posuere consectetur est at lobortis.',
        verbatimSentences: [
          {
            sentence:
              'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
            relevantThematic: false,
          },
          {
            sentence: 'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            relevantThematic: true,
          },
          {
            sentence: 'Sed posuere consectetur est at lobortis.',
            relevantThematic: false,
          },
        ],
      },
    },

    {
      id: 252219,
      kpis: { eindex: { value: -20 } },
      verbatim: {
        verbatimFullText:
          'Maecenas sed diam eget risus varius blandit sit amet non magna. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nulla vitae elit libero, a pharetra augue.',
        verbatimSentences: [
          {
            sentence:
              'Maecenas sed diam eget risus varius blandit sit amet non magna.',
            relevantThematic: true,
          },
          {
            sentence:
              'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
            relevantThematic: false,
          },
          {
            sentence: 'Nulla vitae elit libero, a pharetra augue.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 243497,
      kpis: { eindex: { value: -20 } },
      verbatim: {
        verbatimFullText:
          'Vestibulum id ligula porta felis euismod semper. Donec sed odio dui.',
        verbatimSentences: [
          {
            sentence: 'Vestibulum id ligula porta felis euismod semper.',
            relevantThematic: false,
          },
          {
            sentence: 'Donec sed odio dui.',
            relevantThematic: true,
          },
        ],
      },
    },
  ],
  bestVerbatim: [
    {
      id: 243497,
      kpis: { eindex: { value: 40 } },
      verbatim: {
        verbatimFullText:
          'Vestibulum id ligula porta felis euismod semper. Donec sed odio dui.',
        verbatimSentences: [
          {
            sentence: 'Vestibulum id ligula porta felis euismod semper.',
            relevantThematic: true,
          },
          {
            sentence: 'Donec sed odio dui.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 252219,
      kpis: { eindex: { value: 40 } },
      verbatim: {
        verbatimFullText:
          'Maecenas sed diam eget risus varius blandit sit amet non magna. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nulla vitae elit libero, a pharetra augue.',
        verbatimSentences: [
          {
            sentence:
              'Maecenas sed diam eget risus varius blandit sit amet non magna.',
            relevantThematic: false,
          },
          {
            sentence:
              'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
            relevantThematic: true,
          },
          {
            sentence: 'Nulla vitae elit libero, a pharetra augue.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 243641,
      kpis: { eindex: { value: 40 } },
      verbatim: {
        verbatimFullText:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam id dolor id nibh ultricies vehicula ut id elit. Sed posuere consectetur est at lobortis.',
        verbatimSentences: [
          {
            sentence:
              'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
            relevantThematic: true,
          },
          {
            sentence: 'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            relevantThematic: false,
          },
          {
            sentence: 'Sed posuere consectetur est at lobortis.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 259123,
      kpis: { eindex: { value: 40 } },
      verbatim: {
        verbatimFullText:
          'Nullam id dolor id nibh ultricies vehicula ut id elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
        verbatimSentences: [
          {
            sentence: 'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            relevantThematic: true,
          },
          {
            sentence:
              'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
            relevantThematic: false,
          },
        ],
      },
    },

    {
      id: 259123,
      kpis: { eindex: { value: 40 } },
      verbatim: {
        verbatimFullText:
          'Nullam id dolor id nibh ultricies vehicula ut id elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
        verbatimSentences: [
          {
            sentence: 'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            relevantThematic: false,
          },
          {
            sentence:
              'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 243641,
      kpis: { eindex: { value: 40 } },
      verbatim: {
        verbatimFullText:
          'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam id dolor id nibh ultricies vehicula ut id elit. Sed posuere consectetur est at lobortis.',
        verbatimSentences: [
          {
            sentence:
              'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
            relevantThematic: false,
          },
          {
            sentence: 'Nullam id dolor id nibh ultricies vehicula ut id elit.',
            relevantThematic: true,
          },
          {
            sentence: 'Sed posuere consectetur est at lobortis.',
            relevantThematic: false,
          },
        ],
      },
    },

    {
      id: 252219,
      kpis: { eindex: { value: 40 } },
      verbatim: {
        verbatimFullText:
          'Maecenas sed diam eget risus varius blandit sit amet non magna. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nulla vitae elit libero, a pharetra augue.',
        verbatimSentences: [
          {
            sentence:
              'Maecenas sed diam eget risus varius blandit sit amet non magna.',
            relevantThematic: true,
          },
          {
            sentence:
              'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
            relevantThematic: false,
          },
          {
            sentence: 'Nulla vitae elit libero, a pharetra augue.',
            relevantThematic: true,
          },
        ],
      },
    },
    {
      id: 243497,
      kpis: { eindex: { value: 40 } },
      verbatim: {
        verbatimFullText:
          'Vestibulum id ligula porta felis euismod semper. Donec sed odio dui.',
        verbatimSentences: [
          {
            sentence: 'Vestibulum id ligula porta felis euismod semper.',
            relevantThematic: false,
          },
          {
            sentence: 'Donec sed odio dui.',
            relevantThematic: true,
          },
        ],
      },
    },
  ],
};

export const categoriesBestAndPainPoints = {
  byEindex: {
    bestThematics: [
      {
        value: 40,
        thematic: {
          thematic: 'Thematic',
          subThematic: 'Sub thematic',
          subSubThematic: 'Cras Lorem Inceptos',
        },
        mentionCount: 567,
      },
      {
        value: 33,
        thematic: {
          thematic: 'Thematic',
          subThematic: 'Sub thematic',
          subSubThematic: 'Porta Lorem',
        },
        mentionCount: 202,
      },
      {
        value: 29,
        thematic: {
          thematic: 'Thematic',
          subThematic: 'Sub thematic',
          subSubThematic: 'Sollicitudin Fringilla',
        },
        mentionCount: 263,
      },
    ],
    worstThematics: [
      {
        value: -19,
        thematic: {
          thematic: 'Thematic',
          subThematic: 'Sub thematic',
          subSubThematic: 'Mollis Dolor',
        },
        mentionCount: 106,
      },
      {
        value: -14,
        thematic: {
          thematic: 'Thematic',
          subThematic: 'Sub thematic',
          subSubThematic: 'Egestas Condimentum',
        },
        mentionCount: 403,
      },
      {
        value: -8,
        thematic: {
          thematic: 'Thematic',
          subThematic: 'Sub thematic',
          subSubThematic: 'Pharetra',
        },
        mentionCount: 1216,
      },
    ],
    __typename: 'ThematicValuesList',
  },
  byProjectBenchmark: null,
};

export const wordCloud = {
  wordcloud: [
    {
      name: 'Maecenas',
      mentionCount: 7995,
      value: 0,
      contextSentences: ['Maecenas faucibus mollis interdum.'],
    },
    {
      name: 'ornare',
      mentionCount: 6411,
      value: 0,
      contextSentences: [
        'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
      ],
    },
    {
      name: 'lacinia',
      mentionCount: 6126,
      value: 0,
      contextSentences: [
        'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
      ],
    },
    {
      name: 'laoreet',
      mentionCount: 5423,
      value: 0,
      contextSentences: [
        'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
      ],
    },
    {
      name: 'porta',
      mentionCount: 4832,
      value: 0,
      contextSentences: ['Vestibulum id ligula porta felis euismod semper.'],
    },
    {
      name: 'bibendum',
      mentionCount: 3588,
      value: 0,
      contextSentences: ['Aenean lacinia bibendum nulla sed consectetur.'],
    },
    {
      name: 'augue',
      mentionCount: 3400,
      value: 0,
      contextSentences: [
        'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.',
      ],
    },
    {
      name: 'malesuada',
      mentionCount: 3324,
      value: 0,
      contextSentences: ['Etiam porta sem malesuada magna mollis euismod.'],
    },
    {
      name: 'blandit',
      mentionCount: 3298,
      value: 0,
      contextSentences: ['Curabitur blandit tempus porttitor.'],
    },
    {
      name: 'odio',
      mentionCount: 3284,
      value: 0,
      contextSentences: ['Donec sed odio dui.'],
    },
    {
      name: 'gravida',
      mentionCount: 3175,
      value: 0,
      contextSentences: ['Donec id elit non mi porta gravida at eget metus.'],
    },
    {
      name: 'commodo',
      mentionCount: 3122,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'cursus',
      mentionCount: 3083,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'tortor',
      mentionCount: 2818,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'condimentum',
      mentionCount: 2756,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'justo',
      mentionCount: 2444,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'Pellentesque',
      mentionCount: 2313,
      value: 0,
      contextSentences: [
        'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
      ],
    },
    {
      name: 'venenatis',
      mentionCount: 2128,
      value: 0,
      contextSentences: [
        'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
      ],
    },
    {
      name: 'Aenean',
      mentionCount: 2049,
      value: 0,
      contextSentences: [
        'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.',
      ],
    },
    {
      name: 'porttitor',
      mentionCount: 1981,
      value: 0,
      contextSentences: ['Curabitur blandit tempus porttitor.'],
    },
    {
      name: 'risus',
      mentionCount: 1944,
      value: 0,
      contextSentences: [
        'Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
      ],
    },
    {
      name: 'consectetur',
      mentionCount: 1942,
      value: 0,
      contextSentences: [
        'Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
      ],
    },
    {
      name: 'vestibulum',
      mentionCount: 1670,
      value: 0,
      contextSentences: [
        'Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
      ],
    },
    {
      name: 'felis',
      mentionCount: 1606,
      value: 0,
      contextSentences: ['Vestibulum id ligula porta felis euismod semper.'],
    },
    {
      name: 'ligula',
      mentionCount: 1562,
      value: 0,
      contextSentences: ['Vestibulum id ligula porta felis euismod semper.'],
    },
    {
      name: 'euismod',
      mentionCount: 1555,
      value: 0,
      contextSentences: ['Vestibulum id ligula porta felis euismod semper.'],
    },
    {
      name: 'magna',
      mentionCount: 1473,
      value: 0,
      contextSentences: ['Etiam porta sem malesuada magna mollis euismod.'],
    },
    {
      name: 'mollis',
      mentionCount: 1438,
      value: 0,
      contextSentences: ['Etiam porta sem malesuada magna mollis euismod.'],
    },
    {
      name: 'ullamcorper',
      mentionCount: 1438,
      value: 0,
      contextSentences: ['Donec ullamcorper nulla non metus auctor fringilla.'],
    },
    {
      name: 'Donec',
      mentionCount: 1432,
      value: 0,
      contextSentences: ['Donec ullamcorper nulla non metus auctor fringilla.'],
    },
    {
      name: 'metus',
      mentionCount: 1403,
      value: 0,
      contextSentences: ['Donec ullamcorper nulla non metus auctor fringilla.'],
    },
    {
      name: 'auctor',
      mentionCount: 1392,
      value: 0,
      contextSentences: ['Donec ullamcorper nulla non metus auctor fringilla.'],
    },
    {
      name: 'fringilla',
      mentionCount: 1351,
      value: 0,
      contextSentences: ['Donec ullamcorper nulla non metus auctor fringilla.'],
    },
    {
      name: 'eros',
      mentionCount: 1265,
      value: 0,
      contextSentences: [
        'Morbi leo risus, porta ac consectetur ac, vestibulum at eros.',
      ],
    },
    {
      name: 'interdum',
      mentionCount: 1227,
      value: 0,
      contextSentences: ['Maecenas faucibus mollis interdum.'],
    },
    {
      name: 'elit',
      mentionCount: 1209,
      value: 0,
      contextSentences: [
        'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
      ],
    },
    {
      name: 'libero',
      mentionCount: 1198,
      value: 0,
      contextSentences: ['Nulla vitae elit libero, a pharetra augue.'],
    },
    {
      name: 'Nulla',
      mentionCount: 1189,
      value: 0,
      contextSentences: ['Nulla vitae elit libero, a pharetra augue.'],
    },
    {
      name: 'pharetra',
      mentionCount: 1181,
      value: 0,
      contextSentences: ['Nulla vitae elit libero, a pharetra augue.'],
    },
    {
      name: 'augue',
      mentionCount: 1177,
      value: 0,
      contextSentences: ['Nulla vitae elit libero, a pharetra augue.'],
    },
    {
      name: 'dapibus',
      mentionCount: 1156,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'Fusce',
      mentionCount: 1151,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'tellus',
      mentionCount: 1150,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'tortor',
      mentionCount: 1086,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'nibh',
      mentionCount: 1067,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'massa',
      mentionCount: 1066,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'justo',
      mentionCount: 1063,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'amet',
      mentionCount: 1046,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'risus',
      mentionCount: 1044,
      value: 0,
      contextSentences: [
        'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
      ],
    },
    {
      name: 'parturient',
      mentionCount: 1032,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'montes',
      mentionCount: 1009,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'nascetur',
      mentionCount: 1008,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'ridiculus',
      mentionCount: 1001,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'mus',
      mentionCount: 996,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'magna',
      mentionCount: 958,
      value: 0,
      contextSentences: [
        'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
      ],
    },
    {
      name: 'scelerisque',
      mentionCount: 954,
      value: 0,
      contextSentences: [
        'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
      ],
    },
    {
      name: 'consectetur',
      mentionCount: 943,
      value: 0,
      contextSentences: [
        'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
      ],
    },
    {
      name: 'eget',
      mentionCount: 926,
      value: 0,
      contextSentences: [
        'Nullam quis risus eget urna mollis ornare vel eu leo.',
      ],
    },
    {
      name: 'mollis',
      mentionCount: 904,
      value: 0,
      contextSentences: [
        'Nullam quis risus eget urna mollis ornare vel eu leo.',
      ],
    },
    {
      name: 'ornare',
      mentionCount: 897,
      value: 0,
      contextSentences: [
        'Nullam quis risus eget urna mollis ornare vel eu leo.',
      ],
    },
    {
      name: 'leo',
      mentionCount: 891,
      value: 0,
      contextSentences: [
        'Nullam quis risus eget urna mollis ornare vel eu leo.',
      ],
    },
    {
      name: 'buy',
      mentionCount: 884,
      value: 0,
      contextSentences: [
        'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
      ],
    },
    {
      name: 'nisi',
      mentionCount: 880,
      value: 0,
      contextSentences: [
        'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
      ],
    },
    {
      name: 'erat',
      mentionCount: 871,
      value: 0,
      contextSentences: [
        'Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
      ],
    },
    {
      name: 'sociis',
      mentionCount: 867,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'dis',
      mentionCount: 794,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'nascetur',
      mentionCount: 777,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'mus',
      mentionCount: 769,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'et',
      mentionCount: 764,
      value: 0,
      contextSentences: [
        'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      ],
    },
    {
      name: 'Cras',
      mentionCount: 759,
      value: 0,
      contextSentences: [
        'Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
      ],
    },
    {
      name: 'quam',
      mentionCount: 759,
      value: 0,
      contextSentences: [
        'Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
      ],
    },
    {
      name: 'Etiam',
      mentionCount: 759,
      value: 0,
      contextSentences: ['Etiam porta sem malesuada magna mollis euismod.'],
    },
    {
      name: 'porta',
      mentionCount: 752,
      value: 0,
      contextSentences: ['Etiam porta sem malesuada magna mollis euismod.'],
    },
    {
      name: 'malesuada',
      mentionCount: 738,
      value: 0,
      contextSentences: ['Etiam porta sem malesuada magna mollis euismod.'],
    },
    {
      name: 'Suspendisse',
      mentionCount: 737,
      value: 0,
      contextSentences: [
        'Suspendisse tortor ipsum, sagittis non eros congue, sagittis vestibulum ex.',
      ],
    },
    {
      name: 'sagittis',
      mentionCount: 737,
      value: 0,
      contextSentences: [
        'Suspendisse tortor ipsum, sagittis non eros congue, sagittis vestibulum ex.',
      ],
    },
    {
      name: 'non',
      mentionCount: 729,
      value: 0,
      contextSentences: [
        'Suspendisse tortor ipsum, sagittis non eros congue, sagittis vestibulum ex.',
      ],
    },
    {
      name: 'congue',
      mentionCount: 721,
      value: 0,
      contextSentences: [
        'Suspendisse tortor ipsum, sagittis non eros congue, sagittis vestibulum ex.',
      ],
    },
    {
      name: 'ex',
      mentionCount: 713,
      value: 0,
      contextSentences: [
        'Suspendisse tortor ipsum, sagittis non eros congue, sagittis vestibulum ex.',
      ],
    },
    {
      name: 'pulvinar',
      mentionCount: 682,
      value: 0,
      contextSentences: ['Nunc tincidunt urna in pulvinar interdum.'],
    },
    {
      name: 'interdum',
      mentionCount: 679,
      value: 0,
      contextSentences: ['Nunc tincidunt urna in pulvinar interdum.'],
    },
    {
      name: 'Praesent',
      mentionCount: 675,
      value: 0,
      contextSentences: [
        'Praesent ut ante aliquet, placerat magna eu, rhoncus neque.',
      ],
    },
    {
      name: 'placerat',
      mentionCount: 664,
      value: 0,
      contextSentences: [
        'Praesent ut ante aliquet, placerat magna eu, rhoncus neque.',
      ],
    },
    {
      name: 'rhoncus',
      mentionCount: 657,
      value: 0,
      contextSentences: [
        'Praesent ut ante aliquet, placerat magna eu, rhoncus neque.',
      ],
    },
    {
      name: 'neque',
      mentionCount: 652,
      value: 0,
      contextSentences: [
        'Praesent ut ante aliquet, placerat magna eu, rhoncus neque.',
      ],
    },
    {
      name: 'enim',
      mentionCount: 650,
      value: 0,
      contextSentences: ['Phasellus eget enim quis quam semper tincidunt.'],
    },
    {
      name: 'semper',
      mentionCount: 641,
      value: 0,
      contextSentences: ['Phasellus eget enim quis quam semper tincidunt.'],
    },
    {
      name: 'orci',
      mentionCount: 637,
      value: 0,
      contextSentences: ['Aliquam pellentesque dignissim orci'],
    },
    {
      name: 'dignissim',
      mentionCount: 636,
      value: 0,
      contextSentences: ['Aliquam pellentesque dignissim orci'],
    },
    {
      name: 'Proin',
      mentionCount: 636,
      value: 0,
      contextSentences: [
        'Proin dictum sollicitudin augue, eget varius tortor finibus nec.',
      ],
    },
    {
      name: 'dictum',
      mentionCount: 628,
      value: 0,
      contextSentences: [
        'Proin dictum sollicitudin augue, eget varius tortor finibus nec.',
      ],
    },
    {
      name: 'sollicitudin',
      mentionCount: 625,
      value: 0,
      contextSentences: [
        'Proin dictum sollicitudin augue, eget varius tortor finibus nec.',
      ],
    },
    {
      name: 'nec',
      mentionCount: 624,
      value: 0,
      contextSentences: [
        'Proin dictum sollicitudin augue, eget varius tortor finibus nec.',
      ],
    },
    {
      name: 'Maecenas',
      mentionCount: 616,
      value: 0,
      contextSentences: [
        'Maecenas facilisis imperdiet odio, id rhoncus nunc sagittis ac.',
      ],
    },
    {
      name: 'imperdiet',
      mentionCount: 615,
      value: 0,
      contextSentences: [
        'Maecenas facilisis imperdiet odio, id rhoncus nunc sagittis ac.',
      ],
    },
    {
      name: 'libero',
      mentionCount: 610,
      value: 0,
      contextSentences: [
        'Duis eu diam varius, tristique nulla eget, volutpat libero.',
      ],
    },
    {
      name: 'volutpat',
      mentionCount: 605,
      value: 0,
      contextSentences: [
        'Duis eu diam varius, tristique nulla eget, volutpat libero.',
      ],
    },
    {
      name: 'tristique',
      mentionCount: 603,
      value: 0,
      contextSentences: [
        'Duis eu diam varius, tristique nulla eget, volutpat libero.',
      ],
    },
    {
      name: 'diam',
      mentionCount: 589,
      value: 0,
      contextSentences: [
        'Duis eu diam varius, tristique nulla eget, volutpat libero.',
      ],
    },
    {
      name: 'eu',
      mentionCount: 586,
      value: 0,
      contextSentences: [
        'Duis eu diam varius, tristique nulla eget, volutpat libero.',
      ],
    },
  ],
};

export const eindexBarGraph = {
  data: [
    {
      id: 'Thematic 1',
      label: 'Thematic 1',
      eindex: 35,
      mentionCount: 800,
    },
    {
      id: 'Thematic 2',
      label: 'Thematic 2',
      eindex: 15,
      mentionCount: 1500,
    },
    {
      id: 'Thematic 3',
      label: 'Thematic 3',
      eindex: 25,
      mentionCount: 1200,
    },
  ],
};
