import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { setTemporaryTriggersTable } from '_Resources/Triggers/Actions/setTemporaryTriggersTable';
import { emptyTriggerTable } from '_Resources/Triggers/Services/helpers';
import { connect } from 'react-redux';
import QModal from '../../../../../../../../../_Components/Modals/QModal/QModal';
import QModalBody from '../../../../../../../../../_Components/Modals/QModalBody/QModalBody';
import QModalSection from '../../../../../../../../../_Components/Modals/QModalSection/QModalSection';
import TriggersRow from './_Components/TriggersRow/TriggersRow';
import QButton from '../../../../../../../../../_Components/Buttons/QButton/QButton';

const ModalBody = styled(QModalBody)`
  padding: 0 !important;
  width: 100%;
`;

class ManageTriggersModal extends React.Component {
  initModalValues = {
    config: {
      date: false,
      mentions: false,
    },
  };

  handleOnClick() {
    const { toggle, toggleAddTriggersTableModal, dispatchSetTemporaryTriggersTable } = this.props;
    dispatchSetTemporaryTriggersTable(emptyTriggerTable);
    toggle();
    toggleAddTriggersTableModal();
  }

  render() {
    const propsModal = this.initModalValues;
    const { triggersTableList, t, canCreateTriggersTable, toggle, isOpen } = this.props;

    return (
      <QModal
        isOpen={isOpen}
        title={t('Triggers list')}
        subtitle={t('Here you can create')}
        onClose={toggle}
        {...propsModal}
      >
        <ModalBody>
          <QModalSection>
            <QButton
              id="manageTriggersModalButton"
              opacity
              size="large"
              onClick={() => this.handleOnClick()}
              icon="PLUS"
              iconPosition="left"
              disabled={!canCreateTriggersTable}
              popoverText={
                !canCreateTriggersTable &&
                t(
                  "header:You can't create more triggers tables. Please upgrade your plan in order to create more triggers tables.",
                )
              }
            >
              &nbsp; {t('Create a new triggers table')}
            </QButton>
          </QModalSection>
          <br />
          {!isEmpty(triggersTableList) &&
            triggersTableList.map(triggersTable => <TriggersRow triggersTable={triggersTable} toggleModal={toggle} />)}
        </ModalBody>
      </QModal>
    );
  }
}

ManageTriggersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  triggersTableList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      isDefault: PropTypes.bool.isRequired,
      createdBy: PropTypes.shape({
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
      settings: PropTypes.shape({
        analysisAxis: PropTypes.string.isRequired,
        calculationCriteria: PropTypes.string.isRequired,
        itemsToDisplay: PropTypes.shape({
          name: PropTypes.string.isRequired,
          key: PropTypes.string,
        }).isRequired,
        significanceThreshold: PropTypes.number.isRequired,
        range: PropTypes.shape({
          startDate: PropTypes.string.isRequired,
          endDate: PropTypes.string.isRequired,
        }).isRequired,
        thematics: PropTypes.arrayOf(
          PropTypes.shape({
            thematic: PropTypes.string.isRequired,
            subThematic: PropTypes.string,
            subSubThematic: PropTypes.string,
          }),
        ).isRequired,
        criteriaKeys: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(PropTypes.string).isRequired,
          }),
        ).isRequired,
      }).isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string.isRequired,
          originalColor: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          originalName: PropTypes.string.isRequired,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              originalName: PropTypes.string.isRequired,
              percentage: PropTypes.number.isRequired,
              value: PropTypes.number.isRequired,
            }),
          ).isRequired,
          value: PropTypes.number.isRequired,
          deleted: PropTypes.bool.isRequired,
          masked: PropTypes.bool.isRequired,
        }),
      ),
      customizations: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string.isRequired,
          originalColor: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          originalName: PropTypes.string.isRequired,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              originalName: PropTypes.string.isRequired,
            }),
          ).isRequired,
          deleted: PropTypes.bool.isRequired,
          masked: PropTypes.bool.isRequired,
        }),
      ),
    }).isRequired,
  ).isRequired,
  toggleAddTriggersTableModal: PropTypes.func.isRequired,
  canCreateTriggersTable: PropTypes.bool,
  dispatchSetTemporaryTriggersTable: PropTypes.func.isRequired,
};

ManageTriggersModal.defaultProps = {
  canCreateTriggersTable: true,
};

function mapDispatchToProps(dispatch: (arg0: { type: string, data?: any, activeTriggersTable?: any }) => void) {
  return {
    dispatchSetTemporaryTriggersTable: newState => {
      dispatch(setTemporaryTriggersTable(newState));
    },
  };
}

export default connect(null, mapDispatchToProps)(withTranslation('triggers', 'header')(ManageTriggersModal));
