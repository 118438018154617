import { ActionTypes } from '../../../constants';

export function sendSentimentSelected(value) {
  return {
    type: ActionTypes.SEND_SENTIMENT_SELECTED,
    payload: {
      value,
    },
  };
}
