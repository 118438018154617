import React from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import QPanelHeader from '_Components/Panels/QPanelHeader/QPanelHeader';
import QInput from '_Components/Fields/QInput/QInput';
import QButton from '_Components/Buttons/QButton/QButton';
import { grayShades, purple, black } from 'styles/abstracts/colors';
import { LabelSettings, FormGroupSettings } from '../../AccountAndPreferencesCard/_Component/SectionCard';

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 1rem;
  margin: 0 1rem;
  border-bottom: ${({ displayBorderBottom }) =>
    displayBorderBottom && `1px solid ${grayShades.g300}`};
  &:last-child {
    border-bottom: 0;
  }
`;

const TitleCol = styled(Col)`
  line-height: 3rem;
  padding-left: 0 !important;
  padding-bottom: 1rem;
`;
const StyledQInput = styled(QInput)`
  font-weight: bold;
`;

const ButtonSave = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  & button {
    background-color: ${purple};
    &:hover {
      background-color: ${black};
    }
  }
`;

const AccountSection = ({ displayBorderBottom, t, name, setName, isUserAdmin }) => {
  return (
    <StyledQPanelHeader type="menu" displayBorderBottom={displayBorderBottom}>
      <Row>
        <TitleCol>{t('Account information')}</TitleCol>
      </Row>
      <Row>
        <FormGroupSettings>
          <LabelSettings>{t('Name')}</LabelSettings>
          <StyledQInput disabled={!isUserAdmin} type="text" name="name" value={name} onChange={e => setName(e.target.value)}/>
        </FormGroupSettings>
      </Row>
      <ButtonSave>

      </ButtonSave>
    </StyledQPanelHeader>
  )
};



export default withTranslation('card')(AccountSection);
