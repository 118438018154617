import gql from 'graphql-tag';

const mutation = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $lang: String!
    $isFahrenheit: Boolean!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      lang: $lang
      isFahrenheit: $isFahrenheit
    ) {
      errors
      accountUserItem {
        id
        email
        firstName
        lastName
        createdAt
        expiredAt
        isAdmin
        statistics {
          benchmarkCount
          reportingCount
          lastSignIn
          signInCount
        }
      }
    }
  }
`;

export default mutation;
