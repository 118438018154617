import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import React from 'react';
import { withTranslation } from 'react-i18next';

const StyledWarningHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  p {
    flex-grow: 1;
    font-weight: bold;
    color: ${({ color }) => color};
    margin-left: 1rem;
  }
`;

const IconChevron = styled(Icon)`
  align-self: flex-end;
`;

const MessagesHeader = ({ onClick, collapse, t, title, color }) => (
  <StyledWarningHeader onClick={onClick} color={color}>
    <Icon icon="WARNING" size={40} color={color} />
    <p>{t(title)}</p>
    <IconChevron
      icon={collapse ? 'INTERFACE_CHEVRON_UP' : 'INTERFACE_CHEVRON_DOWN'}
      size={40}
      color={color}
    />
  </StyledWarningHeader>
);

export default withTranslation('conflicts')(MessagesHeader);
