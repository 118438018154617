import React, { cloneElement } from 'react';
import styled from 'styled-components';
import EindexBadgeSection from '_Cards/EIndexCards/EindexBadgeCard/EindexBadgeSection';
import LandingPageDonutCardSlider from '_Cards/EmotionsCards/EmotionBreakdownDonutCard/LandingPageDonutCardSlider';
import SatisfactionNpsLandingPage from '_Cards/SatisfactionCards/SatisfactionNpsCard/SatisfactionNpsLandingPage';
import AnalysisCard from '../AnalysisCard';
import CustomerJourney from '../CustomerJourney';
import VerbatimListCard from '../IrritantsTab/VerbatimListCard';
import IrritantsAndEnchantingPointsCard from '../IrritantsTab/IrritantsAndEnchantingPointsCard';
import { grayShades } from '../../../styles/abstracts/colors';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import CategoryGlobalBestAndPaintPointCardSection from '../../ThematicsCard/CategoryGlobalBestAndPainPointsCard/CategoryGlobalBestAndPaintPointCardSection';

const ComponentContent = styled.div<{ padding: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 3em);
`;

const GifContainer = styled.div<{ href: string }>`
  margin-top: 4em;
  width: 100%;
  height: calc(100% - 18em);
  background-image: url(${({ href }) => href});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const getTabComponents = ({ activeTab, filters, t, history, data, headerHeight, i18n, width }) => {
  const TAB_CONTENTS = [
    {
      id: 'key_indicators',
      leftTitle: 'Emotions',
      leftComponent: (
        <ComponentContent className="componentContent">
          <EindexBadgeSection title="Average E-index" showVerbatimDetails={false} indicatorTitle="E-INDEX" />
          <LandingPageDonutCardSlider landingPageMode title="Emotion breakdown" labelColor={grayShades.g800} />
        </ComponentContent>
      ),
      leftHref: '/global-results',
      leftAdditionalIcon: 'INTERFACE_OUTSIDE_LINK',
      leftAdditionalAction: () => history.push('/global-results'),
      leftHints: [HintsContent.HINT_EINDEX_TEMPERATURE],
      leftWidth: '37%',
      midTitle: 'Scores',
      midComponent: (
        <ComponentContent className="componentContent">
          <SatisfactionNpsLandingPage filters={filters} data={data} />
        </ComponentContent>
      ),
      midHints: [HintsContent.HINT_SAT_NPS],
      rightTitle: 'Analysis',
      rightComponent: <AnalysisCard projectId={filters?.projectId} activeTab={activeTab} t={t} />,
      rightHref: '/global-results',
      rightWidth: '25%',
    },
    {
      id: 'customer_journey',
      leftTitle: 'Customer journey',
      leftComponent: (
        <ComponentContent className="componentContent" padding={false} style={{ flexDirection: 'row' }}>
          <CustomerJourney filters={filters} t={t} />
        </ComponentContent>
      ),
      leftHref: '/customer-journey',
      leftAdditionalIcon: 'INTERFACE_OUTSIDE_LINK',
      leftWidth: '75%',
      rightTitle: 'Analysis',
      rightComponent: <AnalysisCard projectId={filters?.projectId} activeTab={activeTab} t={t} />,
      rightHref: '/customer-journey',
      rightWidth: '25%',
    },
    {
      id: 'insights',
      leftTitle: 'Key insights',
      leftComponent: (
        <ComponentContent className="componentContent">
          <CategoryGlobalBestAndPaintPointCardSection nbThematicsPerCategory={5} />
        </ComponentContent>
      ),
      leftHref: '/thematics-overview',
      leftHrefToTab: 2,
      leftWidth: '75%',
      leftHrefProps: {},
      rightTitle: 'Did you know',
      rightComponent: <AnalysisCard projectId={filters?.projectId} activeTab={activeTab} t={t} />,
      rightWidth: '25%',
    },
    {
      id: 'irritants',
      leftTitle: 'Verbatim selection',
      leftComponent: (
        <VerbatimListCard
          filters={filters}
          headerHeight={headerHeight}
          emotions={null}
          projectId={filters?.projectId}
        />
      ),
      leftHints: [
        HintsContent.HINT_ENCHANTING_WOW,
        HintsContent.HINT_STORIES,
        HintsContent.HINT_IRRITANTS,
        HintsContent.HINT_CHURN,
      ],
      leftWidth: width,
      rightTitle: 'Irritant and enchanting points',
      rightComponent: <IrritantsAndEnchantingPointsCard filters={filters} />,
    },
    {
      id: 'actions',
      leftTitle: 'Actions (coming soon)',
      leftComponent: (
        <GifContainer
          className="d-flex justify-content-center align-items-center"
          href="https://qemotion-dashboard-ui.s3.eu-west-1.amazonaws.com/mockup_5bis.gif"
        />
      ),
    },
  ];
  const activeTabComponents = TAB_CONTENTS.find(tab => activeTab === tab.id);
  const leftComponent =
    activeTabComponents?.leftComponent &&
    cloneElement(activeTabComponents?.leftComponent, {
      filters,
    });
  const midComponent =
    activeTabComponents?.midComponent &&
    cloneElement(activeTabComponents?.midComponent, {
      filters,
    });
  const rightComponent =
    activeTabComponents?.rightComponent &&
    cloneElement(activeTabComponents?.rightComponent, {
      filters,
    });
  return {
    leftComponent,
    midComponent,
    rightComponent,
    ...activeTabComponents,
    componentsLength: Object.keys(activeTabComponents).filter(c => c.includes('Component')).length,
  };
};

export default getTabComponents;
