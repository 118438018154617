import gql from 'graphql-tag';

const query = gql`
  query ssoZohoLogin {
    ssoZohoLogin {
      email
      ts
      operation
      apiKey
      loginName
      fullName
      hasSignedUpToZoho
    }
  }
`;

export default query;
