/* eslint-disable import/prefer-default-export */
import { merge } from 'lodash';
import { select } from 'd3-selection';
import { drag } from 'd3-drag';

const DEFAULT_SCALE = 1;

const extractString = (lookbehind, lookahead) => str => {
  const ahead = str.replace(lookbehind, '');
  return ahead.replace(lookahead, '');
};

const extractTransformData = transform => {
  const matchTranslate = extractString(/.*translate\(/, /\).*/);
  const matchScale = extractString(/.*scale\(/, /\).*/);

  const translate = matchTranslate(transform);
  const scale = matchScale(transform);
  const [x, y] = translate.split(',').map(i => Number(i));
  return {
    translate: { x, y },
    scale: Number(scale) || DEFAULT_SCALE,
  };
};

const mergeTransformData = (transformData, elementTransformString) => {
  if (elementTransformString) {
    const { translate, scale } = extractTransformData(elementTransformString);
    merge(transformData, { translate, scale });
  }
};

const getD3EventValue = () => require('d3-selection').event;

const prepareTransformString = ({ translate: { x, y }, scale }) =>
  `translate(${[x, y]}) scale(${scale})`;

export const prepareZoomHandler = element => {
  const transformData = { translate: { x: 0, y: 0 }, scale: 1 };

  function onDrag() {
    const elementTransformData = this.getAttribute('transform');
    mergeTransformData(transformData, elementTransformData);
    // TODO: check if I need this
    const { dx, dy } = getD3EventValue() || { dx: 0, dy: 0 };
    transformData.translate.x += dx;
    transformData.translate.y += dy;
    select(this).attr('transform', prepareTransformString(transformData));
  }

  function zoom(scale) {
    merge(transformData, { scale });

    select(element).attr('transform', prepareTransformString(transformData));
  }

  select(element).call(drag().on('drag', onDrag));

  return {
    zoom,
  };
};
