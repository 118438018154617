import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { grayShades, green } from 'styles/abstracts/colors';
import QButton from '_Components/Buttons/QButton/QButton';
import { hex2rgbaConvert } from '_Utils/styles/colors';
import { fontSizes } from 'styles/abstracts/typography';
import { spacing, ImageSize } from 'styles/abstracts/variables';
import Icon from '_Components/Icons/Icon';
import { SAVE_BTN_MODES } from './SavePageButton';

const SaveButton = styled(QButton)`
  width: 315px;
  transition: max-width 0.3s ease-out;
  background: ${({ afterSave }) =>
    hex2rgbaConvert(afterSave ? grayShades.g800 : green, 30)} !important;
  color: ${({ afterSave }) => (afterSave ? grayShades.g800 : green)} !important;
  font-size: ${fontSizes.default} !important;
  margin: ${({ idpage }) =>
    idpage === 'benchmark'
      ? `${spacing.xsmall} 0 ${spacing.xsmall} ${spacing.large}`
      : `${spacing.xsmall} ${spacing.large}`};
  border-radius: 40px !important;
  padding: ${spacing.small} ${spacing.medium} !important;
  white-space: nowrap;
`;

const LastUpdateLabel = styled.span`
  font-size: ${fontSizes.extraSmall};
`;

const IconHolder = styled.div`
  height: 24px;
  display: inline;
  position: relative;
  & > * {
    margin: 0 0.25rem;
  }
  & > *:nth-child(2) {
    position: relative;
    top: 1px;
    margin: 0 0.25rem;
  }
`;

const IdleButtonContent = ({ label, color, lastUpdate, t, idpage }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
    }}
  >
    <Icon icon="CHECK_ALL" color={color} size={ImageSize.medium} /> &nbsp;&nbsp;
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ fontSize: fontSizes.smaller }}>{label}</span>
      {idpage === 'benchmark' && lastUpdate && (
        <LastUpdateLabel>
          {t('Last update')} : {lastUpdate}
        </LastUpdateLabel>
      )}
    </div>
  </div>
);
const ActiveButtonContent = ({ label }) => (
  <>
    <span style={{ fontSize: '0.8em' }}>••• </span>
    <span>{label}</span>
  </>
);

class Button extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    // mode: PropTypes.oneOf(SAVE_BTN_MODES).isRequired,
  };

  options = {
    [SAVE_BTN_MODES.WAITING]: t => (
      <ActiveButtonContent label={t('Saving changes')} color={green} />
    ),
    [SAVE_BTN_MODES.AFTER_SAVE]: t => (
      <IdleButtonContent
        label={t('All changes saved')}
        color={grayShades.g800}
        lastUpdate={this.props.lastUpdate}
        {...this.props}
      />
    ),
    [SAVE_BTN_MODES.DEFAULT]: t => (
      <IdleButtonContent
        label={t('Save changes')}
        color={green}
        lastUpdate={this.props.lastUpdate}
        {...this.props}
      />
    ),
  };

  componentDidMount() {
    window.onbeforeunload = () => {
      this.props.onUnmount();
    };
  }

  render() {
    const { onSave, mode, t, idpage } = this.props;
    const isAfterSave = mode === SAVE_BTN_MODES.AFTER_SAVE;
    return (
      <SaveButton disableHover bgColor={isAfterSave ? 'grayShades.g300' : 'green'} hasOpacity={!isAfterSave} idpage={idpage} onClick={onSave} afterSave={isAfterSave}>
        <IconHolder>{this.options[mode](t)}</IconHolder>
      </SaveButton>
    );
  }
}

export default withTranslation('reporting')(Button);
