import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import QTitle from '../../../../../../../../../_Components/Texts/QTitle/QTitle';
import QTags from '../../../../../../../../../_Components/Fields/QTags/QTags';
import QInput from '../../../../../../../../../_Components/Fields/QInput/QInput';

const ModalSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ModalBody = styled.div`
  width: 100%;
  padding: 0 5em;
`;

const StyledQSwitcher = styled(QSwitcher)`
  font-size: 0.5rem !important;
`;

const TagHint = styled.span`
  display: inline-block;
  font-size: 0.9em;
  padding: 0.5em 0;
  color: #928fb0;
`;

const NumberInput = styled(QInput)`
  font-weight: bold;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

class Search extends Component {
  static propTypes = {
    onNextStepAvailabilityChanged: PropTypes.func.isRequired,
    onDataChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    data: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string),
      searchEngine: PropTypes.string,
    }).isRequired,
  };

  state = {
    searchEngine: 'AI',
    answerCount: 100,
    tags: [],
    availability: false,
  };

  constructor(props) {
    super(props);
    const { data } = props;
    this.initializeTags(data);
    this.initializeSearchEngine(data);
    this.initializeAnswerCount(data);
  }

  componentDidMount() {
    if (this.state.tags.length) {
      this.state.availability = true;
      this.props.onNextStepAvailabilityChanged(this.state.availability);
    }
  }

  initializeTags({ tags = [] } = { tags: [] }) {
    this.state.tags = tags;
  }

  initializeSearchEngine({ searchEngine = 'AI' } = { searchEngine: 'AI' }) {
    this.state.searchEngine = searchEngine;
  }

  initializeAnswerCount({ answerCount = 100 } = { answerCount: 100 }) {
    this.state.answerCount = answerCount;
  }

  notifyDataChange() {
    const { onDataChange } = this.props;
    const { searchEngine, tags, answerCount } = this.state;
    onDataChange({ tags, searchEngine, answerCount });
  }

  onTagsChanged = tags => {
    const { onNextStepAvailabilityChanged } = this.props;
    this.setState({ tags, availability: !!tags.length }, () => {
      const { availability } = this.state;
      onNextStepAvailabilityChanged(availability);
      this.notifyDataChange();
    });
  };

  onSearchEngineCategoryChanged = searchEngine =>
    this.setState({ searchEngine }, () => this.notifyDataChange());

  onMaxAnswerInputChanged = ({ currentTarget: { value } }) => {
    const answerCount = !value && value !== 0 ? 100 : Number(value);
    this.setState({ answerCount }, () => this.notifyDataChange());
  };

  checkLimits = ({ currentTarget: { value } }) => {
    const LIMITS = {
      min: 1,
      max: 100,
    };
    let answerCount = Number(value);
    if (answerCount < LIMITS.min) {
      answerCount = LIMITS.min;
    } else if (answerCount > LIMITS.max) {
      answerCount = LIMITS.max;
    }
    this.setState({ answerCount }, () => this.notifyDataChange());
  };

  searchEngineButtons() {
    const { t } = this.props;
    return [
      {
        label: t('button:Artificial intelligence (recommended)'),
        id: 'AI',
        hint: t('Example AI'),
      },
      {
        label: t('button:Keyword only'),
        id: 'KEYWORD',
        hint: t('Example Keyword'),
      },
    ];
  }

  getHint() {
    const { searchEngine } = this.state;
    const { hint } = this.searchEngineButtons().find(
      ({ id }) => id === searchEngine,
    );
    return hint;
  }

  render() {
    const { tags, searchEngine, answerCount } = this.state;
    const { t } = this.props;
    return (
      <>
        <ModalSection>
          <QTitle>
            <QTitle.Title>
              {t('Search for similar terms in verbatim')}
            </QTitle.Title>
          </QTitle>
        </ModalSection>
        <ModalSection>
          <ModalBody>
            <QTitle>
              <QTitle.Title>{t('Search engine')}</QTitle.Title>
            </QTitle>
            <StyledQSwitcher
              selected={searchEngine}
              options={this.searchEngineButtons()}
              onOptionSelected={this.onSearchEngineCategoryChanged}
              padding="0.25rem 1rem"
              display="inline-flex"
              width="auto"
            />
            <QTitle>
              <QTitle.Title>{t('Define your insights')}</QTitle.Title>
            </QTitle>
            <QTags onTagsChanged={this.onTagsChanged} initialTags={tags} />
            <TagHint>{this.getHint()}</TagHint>
            <QTitle>
              <QTitle.Title>
                {t('Define maximum number of verbatim results')}
              </QTitle.Title>
            </QTitle>
            <NumberInput
              type="number"
              value={answerCount}
              onChange={this.onMaxAnswerInputChanged}
              onBlur={this.checkLimits}
            />
          </ModalBody>
        </ModalSection>
      </>
    );
  }
}

export default withTranslation(['insights', 'button'])(Search);
