import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import QTitle from '../../../../_Components/Texts/QTitle/QTitle';
import QSingleNestedInputTags from '../../../../_Components/Fields/NestedInputs/QSingleNestedInputTags/QSingleNestedInputTags';

const StyledSNIT = styled(QSingleNestedInputTags)`
  padding: 0;
`;

const FilterInput = ({
  name,
  options,
  onSelected,
  onRemoved,
  initialValues,
}) => (
  <div>
    <QTitle>
      <QTitle.Subtitle>{name}</QTitle.Subtitle>
    </QTitle>
    <StyledSNIT
      options={options}
      onSelected={onSelected}
      onRemoved={onRemoved}
      initialValues={initialValues}
    />
  </div>
);

FilterInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.object),
  onSelected: PropTypes.func.isRequired,
  onRemoved: PropTypes.func.isRequired,
  name: PropTypes.string,
};

FilterInput.defaultProps = {
  name: null,
  initialValues: null,
};

export default FilterInput;
