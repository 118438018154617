import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import QPanel from '_Components/Panels/QPanel/QPanel';
import SideMenu from './_Containers/SideMenu/SideMenu';
import UsersAndPermissionsBody from './_Containers/UsersAndPermissionsBody/UsersAndPermissionsBody';
import query from './graphql/getAccountUserList';

const AVAILABLE_GROUPS = [
  { id: 'all', name: 'All groups' },
  { id: 'administrators', name: 'Administrators' },
  { id: 'users', name: 'Regular users' },
];

const Container = styled(QPanel)`
  padding: 0;
  display: flex;
  overflow-y: hidden;
  max-height: 45em;
`;

const UserAndPermissionsCard = ({ usersTabId }) => {
  const accountId = window.localStorage.getItem('userAccountId');
  const { data } = useQuery(query, {
    variables: { accountId: parseInt(accountId, 10) },
  });
  const mockToIterate =
    usersTabId === 'groups'
      ? AVAILABLE_GROUPS.filter(g => g.id !== 'all').map(g => ({
          value: g.id,
          label: g.name,
        }))
      : data?.getAccountUserList;
  const [selectedItem, selectItem] = useState(mockToIterate?.[0]);

  useEffect(() => {
    selectItem(mockToIterate?.[0]);
  }, [usersTabId, data]);
  return (
    <Container>
      <SideMenu
        usersTabId={usersTabId}
        selectedItem={selectedItem}
        selectItem={selectItem}
        availableGroups={AVAILABLE_GROUPS}
        users={data?.getAccountUserList}
        data={mockToIterate}
      />
      <UsersAndPermissionsBody
        usersTabId={usersTabId}
        selectedItem={selectedItem}
        users={data?.getAccountUserList}
      />
    </Container>
  );
};

export default UserAndPermissionsCard;
