import React, { useEffect } from 'react';
import Layout from '_Layouts/Layout';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { get } from 'lodash';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import ProjectDetails from 'Routes/Settings/ProjectDetails/ProjectDetails';
import query from './graphql/getProjectDetails';
import ProjectDetailsSubHeader from './_Containers/ProjectDetailsSubHeader';

const title = 'Project details';

const action = props => {
  const { history } = props;
  const projectId = props.location?.state?.id;
  const pathname = props.location?.pathname;
  const slug = pathname.replace('/project-details/', '');

  useEffect(() => {
    if (!projectId && !slug) {
      history.push('/settings');
    }
  }, []);

  const projectName = props.projectsList?.find(
    project => project.id === projectId,
  )?.name;

  const isProjectDemo = props.projectsList?.find(
    project => project.id === projectId,
  )?.demo;

  const { data } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: projectId || null,
      slug,
    },
  });

  const projectDetailsData = data?.getProjectDetails;
  const body = <ProjectDetails data={data && projectDetailsData} slug={slug} />;
  const subHeader = (
    <ProjectDetailsSubHeader
      history={history}
      projectName={projectDetailsData?.name || projectName}
      projectId={projectDetailsData?.id || projectId}
      slug={slug}
      isProjectDemo={projectDetailsData?.demo || isProjectDemo}
    />
  );
  console.log(props.location)
  return (
    <QLoginRedirect>
      <Layout
        body={body}
        subHeader={subHeader}
        title={title}
        hideMentionsCount
        {...props}
      />
    </QLoginRedirect>
  );
};

const mapStateToProps = state => ({
  projectsList: get(state, ['projects', 'projectsList']),
});

export default connect(mapStateToProps)(action);
