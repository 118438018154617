import React from 'react';

const LoaderIcon = ({ className }) => (
  <svg className={className} width="188" height="189" viewBox="0 0 188 189">
    <defs>
      <polygon
        id="report_generation_illustration-a"
        points="0 .189 187.769 .189 187.769 188.956 0 188.956"
      />
      <polygon
        id="report_generation_illustration-c"
        points="2 187 182 187 182 7 2 7"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="report_generation_illustration-b" fill="#fff">
        <use href="#report_generation_illustration-a" />
      </mask>
      <path
        fill="#CAEEF5"
        d="M3.70868525,83.117857 C-12.9277738,125.032166 31.4494721,160.129646 37.3052098,172.836475 C43.1609475,185.544849 98.3281607,196.50306 136.100751,182.208263 C173.873341,167.915011 196.860193,95.5976131 184.330456,63.6220033 C171.800718,31.6448488 165.550489,-10.3111674 127.884226,2.57948298 C90.2179639,15.4701334 20.3451443,41.2020033 3.70868525,83.117857"
        mask="url(#report_generation_illustration-b)"
      />
      <polygon
        fill="#F7F9FF"
        points="74.51 18 168 18 168 149.18 121.255 149.18 121.255 171 27.36 171 27.36 90.255 17 80.095 17 49.451 27.36 49.451 27.36 38.993 74.51 38.993"
      />
      <polygon fill="#FBEF9B" points="96 135 107 135 107 121 96 121" />
      <mask id="report_generation_illustration-d" fill="#fff">
        <use href="#report_generation_illustration-c" />
      </mask>
      <polygon
        fill="#B0E692"
        points="116 135 128 135 128 116 116 116"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#D09BB5"
        points="137 135 149 135 149 110 137 110"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#CAEEF5"
        points="48 118 74 118 74 92 48 92"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#D09BB5"
        points="122 77 133.752 97 140 87.001 140 79.487"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#B0E692"
        points="122 55 122.032 76.436 140.17 79 143 70.29 133.373 60.334"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#FBEF9B"
        points="121.736 55 121.768 76.326 133 95.073 123.21 98 108.861 93.903 101.898 84.182 101 73.572 105.273 61.986 116.059 55"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#BDABD9"
        points="17 50 75 50 75 82.107 27.45 82.107 27.45 91 17.123 80.67"
        mask="url(#report_generation_illustration-d)"
      />
      <path
        fill="#2E096B"
        d="M77.1994081,147.668145 L166.735352,147.668145 L166.735352,21.2675537 L77.1994081,21.2675537 L77.1994081,147.668145 Z M50.867307,116.067258 L71.9332835,116.067258 L71.9332835,95.0014784 L50.867307,95.0014784 L50.867307,116.067258 Z M119.334318,168.733925 L29.799852,168.733925 L29.799852,84.4678494 L71.9332835,84.4678494 L71.9332835,89.7339247 L45.599704,89.7339247 L45.599704,121.333333 L71.9332835,121.333333 L71.9332835,126.600887 L45.599704,126.600887 L45.599704,131.866962 L71.9332835,131.866962 L71.9332835,137.134516 L45.599704,137.134516 L45.599704,142.400591 L71.9332835,142.400591 L71.9332835,147.668145 L45.599704,147.668145 L45.599704,152.93422 L119.334318,152.93422 L119.334318,168.733925 Z M22.9902592,84.4678494 L24.5322491,84.4678494 L24.5322491,86.0113032 L22.9902592,84.4678494 Z M19.267603,79.2002957 L71.9332835,79.2002957 L71.9332835,52.8669623 L19.267603,52.8669623 L19.267603,79.2002957 Z M29.799852,47.600887 L71.9332835,47.600887 L71.9332835,42.3333333 L29.799852,42.3333333 L29.799852,47.600887 Z M169.368415,16 L74.5663458,16 C73.1145394,16 71.9332835,17.178288 71.9332835,18.6330377 L71.9332835,37.0657796 L27.1653113,37.0657796 C25.7120265,37.0657796 24.5322491,38.2470245 24.5322491,39.6988173 L24.5322491,47.5994086 L16.6330623,47.5994086 C15.1782991,47.5994086 14,48.7806535 14,50.2324463 L14,81.8333333 C14.0118273,82.3936485 14.2069785,82.9362228 14.5558851,83.372352 C14.594324,83.4980163 14.6682449,83.6088966 14.7702557,83.6916873 L24.5322491,93.4565461 L24.5322491,171.366962 C24.5322491,172.821712 25.7120265,174 27.1653113,174 L121.96738,174 C123.423622,174 124.601921,172.821712 124.601921,171.366962 L124.601921,152.93422 L169.368415,152.93422 C170.8217,152.93422 171.999999,151.754454 171.999999,150.301183 L171.999999,18.6330377 C172.001477,17.178288 170.8217,16 169.368415,16 Z"
        mask="url(#report_generation_illustration-d)"
      />
      <path
        fill="#2E096B"
        d="M32.6661677 61.5627763C32.6661677 62.2878799 32.0688623 62.8759211 31.3338323 62.8759211L27.3323353 62.8759211 27.3323353 60.2496316 31.3338323 60.2496316C32.0688623 60.2496316 32.6661677 60.8376728 32.6661677 61.5627763zM27.3323353 68.1270265L31.3338323 68.1270265C35.0149701 68.1270265 38 65.1868201 38 61.5627763 38 57.9372588 35.0149701 55 31.3338323 55L22 55 22 76 27.3323353 76 27.3323353 68.1270265zM43.3338323 60.2507368L46 60.2507368C47.4730539 60.2507368 48.6676647 61.4267368 48.6676647 62.8753684L48.6676647 68.1246316C48.6676647 69.5747368 47.4730539 70.7492632 46 70.7492632L43.3338323 70.7492632 43.3338323 60.2507368zM46 76C50.4191617 76 54 72.4749474 54 68.1275789L54 62.8753684C54 58.5265263 50.4161677 55 46 55L38 55 38 76 46 76z"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#2E096B"
        points="61.201 68.127 66.4 68.127 66.4 62.876 61.201 62.876 61.201 60.25 69 60.25 69 55 56 55 56 76 61.201 76"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#2E096B"
        points="46 163 101 163 101 158 46 158"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#2E096B"
        points="88 34 93 34 93 29 88 29"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#2E096B"
        points="98 34 122 34 122 29 98 29"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#2E096B"
        points="127 34 156 34 156 29 127 29"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#2E096B"
        points="88 45 93 45 93 40 88 40"
        mask="url(#report_generation_illustration-d)"
      />
      <polygon
        fill="#2E096B"
        points="98 45 156 45 156 40 98 40"
        mask="url(#report_generation_illustration-d)"
      />
      <path
        fill="#2E096B"
        d="M118.917167 58.3558783L118.917167 76.4559446C118.917167 76.5117501 118.946536 76.5602127 118.948005 76.6116125 118.965626 76.7893089 118.999401 76.9640681 119.049329 77.1344217 119.088977 77.3033067 119.144779 77.4692546 119.216733 77.6278596 119.240229 77.6748537 119.240229 77.7277221 119.266661 77.7776533L128.332942 93.4457756C118.953879 97.2067711 108.304596 92.6527504 104.543867 83.2744929 100.781668 73.8962354 105.335367 63.2462004 114.714431 59.4852049 116.063947 58.9418358 117.475138 58.5644145 118.917167 58.3558783M139.631284 73.8433671L124.149295 73.8433671 124.149295 58.3573468C132.167095 59.521919 138.466794 65.8220638 139.631284 73.8433671M132.852866 90.8214495L126.064169 79.0743964 139.631284 79.0743964C138.961666 83.7135939 136.534299 87.9180961 132.852866 90.8214495M121.532497 99.9999822C125.644189 100.001451 129.680989 98.9191182 133.239071 96.8616583 133.261098 96.8616583 133.284594 96.8455041 133.302215 96.8337556 133.321305 96.8234756 133.330116 96.8043842 133.349206 96.7926357 144.555035 90.2487091 148.333387 75.8582385 141.789922 64.6516174 135.24499 53.4449962 120.855536 49.6663779 109.649707 56.2103045 98.4468148 62.7542311 94.6655266 77.1461702 101.210459 88.3513228 105.42788 95.5766637 113.169609 100.010262 121.532497 99.9999822M98.4643621 131.727811L103.692872 131.727811 103.692872 123.818047 98.4643621 123.818047 98.4643621 131.727811zM119.385745 131.726331L124.617192 131.726331 124.617192 118.544378 119.385745 118.544378 119.385745 131.726331zM140.308596 131.726331L145.538574 131.726331 145.538574 113.272189 140.308596 113.272189 140.308596 131.726331zM156 137L156 131.726331 150.768553 131.726331 150.768553 108 135.077149 108 135.077149 131.726331 129.845702 131.726331 129.845702 113.272189 114.155766 113.272189 114.155766 131.726331 108.924319 131.726331 108.924319 118.544378 93.2314469 118.544378 93.2314469 131.726331 88 131.726331 88 137 156 137z"
        mask="url(#report_generation_illustration-d)"
      />
    </g>
  </svg>
);

export default LoaderIcon;
