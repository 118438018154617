export const RENAME_STAGE_SUCCESS = 'RENAME_ALERTING_STAGE_SUCCESS';
export const RENAME_STAGE_FAILURE = 'RENAME_ALERTING_STAGE_FAILURE';

export const RENAME_STAGE = 'RENAME_ALERTING_STAGE';

const renameStageSuccess = ({ id, name }) => ({
  type: RENAME_STAGE_SUCCESS,
  id,
  name,
});

const renameStageFailure = error => ({
  type: RENAME_STAGE_FAILURE,
  error,
});

export const renameStage = ({ id, name }) => renameStageSuccess({ id, name });
