export default [
  {
    title: 'Mentions & E-index',
    subtitle: [
      'From January 1st To December 31th 2019',
      'Filtered on: Region > Europe',
      'Thematics: People > Attitude',
    ],
    size: { columns: 2, rows: 1 },
    label: 'Mentions + E-index',
    content: 'mentionAndEindex',
    children: [
      {
        name: 'section1',
        content: 'mention',
        pdfStartingPoint: { x: 0, y: 0 },
      },
      {
        name: 'section2',
        content: 'eindex',
        pdfStartingPoint: { x: 1, y: 0 },
      },
    ],
  },
  {
    title: 'E-index & emotions',
    size: { columns: 2, rows: 1 },
    label: 'E-index + emotions',
    content: 'eindexAndEmotions',
    children: [
      {
        name: 'section1',
        content: 'eindex',
        pdfStartingPoint: { x: 0, y: 0 },
      },
      {
        name: 'section2',
        content: 'emotionsBreakdown',
        pdfStartingPoint: { x: 1, y: 0 },
      },
    ],
  },
  {
    title: 'Emotional intensity',
    size: { columns: 2, rows: 1 },
    subtitle: [
      'From January 1st To December 31th 2019',
      'Filtered on: Region > Europe',
      'Thematics: People > Attitude',
    ],
    label: 'Emotional intensity',
    content: 'emotionalIntensity',
    children: [
      {
        name: 'section1',
        content: 'emotionalIntensity',
        pdfStartingPoint: { x: 0, y: 0 },
      },
      {
        name: 'section2',
        content: 'speechEngagement',
        pdfStartingPoint: { x: 1, y: 0 },
      },
    ],
  },
  {
    title: 'E-index evolution',
    subtitle: [
      'From January 1st To December 31th 2019',
      'Filtered on: Region > Europe',
      'Thematics: People > Attitude',
    ],
    label: 'E-index evolution',
    content: 'eindexEvolution',
    size: { columns: 1, rows: 1 },
    children: [
      {
        name: 'section1',
        content: 'eindexEvolution',
        pdfStartingPoint: { x: 0, y: 0 },
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    title: 'Emotions evolution',
    subtitle: [
      'From January 1st To December 31th 2019',
      'Filtered on: Region > Europe',
      'Thematics: People > Attitude',
    ],
    label: 'Emotions evolution',
    content: 'emotionsEvolution',
    size: { columns: 1, rows: 1 },
    children: [
      {
        name: 'section1',
        content: 'emotionsEvolution',
        pdfStartingPoint: { x: 0, y: 0 },
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    size: { columns: 1, rows: 1 },
    label: 'Best & Worst categories',
    content: 'bestAndWorstCategories',
    children: [
      {
        name: 'section1',
        content: 'bestAndWorstCategories',
        pdfStartingPoint: { x: 0, y: 0 },
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    size: { columns: 1, rows: 1 },
    label: 'Best verbatim',
    content: 'bestVerbatim',
    children: [
      {
        name: 'section1',
        content: 'bestVerbatim',
        pdfStartingPoint: { x: 0, y: 0 },
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    size: { columns: 1, rows: 1 },
    label: 'Worst verbatim',
    content: 'worstVerbatim',
    children: [
      {
        name: 'section1',
        content: 'worstVerbatim',
        pdfStartingPoint: { x: 0, y: 0 },
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
  {
    size: { columns: 1, rows: 1 },
    label: 'Word cloud',
    content: 'wordCloud',
    children: [
      {
        name: 'section1',
        content: 'wordCloud',
        pdfStartingPoint: { x: 0, y: 0 },
      },
    ],
    cssGridLayout: `
          grid-template-columns: 1fr;
          grid-template-areas: "section1"
        `,
  },
];
