import { em } from '../utils';

const baseSize = 1;
export const fontSizes = {
  default: em(baseSize),
  large: em(baseSize * 1.5),
  bigger: em(baseSize * 1.2),
  smaller: em(baseSize * 0.875),
  extraSmall: em(baseSize * 0.75),
  smallest: em(baseSize * 0.6),
};

const rootFontValue = 16;

const calculateRem = size => Math.round((size / rootFontValue) * 10) / 10;

export const getFontSizeStyles = size => `
    font-size: ${size}px; //Fallback in px
    font-size: ${calculateRem(size)}rem;
  `;
