import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useLocation } from 'react-router';
import Layout from '_Layouts/Layout';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import Alerting from './Alerting';
import CreateAlertModal from './_Components/CreateAlertModal/CreateAlertModal';
import Subheader from './_Components/Subheader/Subheader';

const action = (props: any) => {
  const { state } = useLocation();
  const [isCreateAlertModalOpen, openCreateAlertModal] = useState<boolean>(
    state?.fromLandingPage,
  );

  const title = 'Alerting';
  const idpage = 'alerting';
  const body = <Alerting idpage={idpage} />;
  const tourID = 5;
  const additionalSubheader = (
    <Subheader
      isCreateAlertModalOpen={isCreateAlertModalOpen}
      openCreateAlertModal={openCreateAlertModal}
    />
  );
  return (
    <QLoginRedirect>
      <Layout
        body={body}
        title={title}
        tourID={tourID}
        {...props}
        subHeader={additionalSubheader}
        allowCollapse={false}
      />
      <CreateAlertModal
        isOpen={isCreateAlertModalOpen}
        open={openCreateAlertModal}
        projectId={props.projectId}
      />
    </QLoginRedirect>
  );
};

const mapStateToProps = (state: any) => ({
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
});

export default connect(mapStateToProps)(action);
