import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CLineChartSettings from '../../../../../_Charts/LineCharts/CLineChart/CLineChartSettings';
import { updateSectionSettings } from '../../../../../_Resources/Reporting/Actions/Section/updateSectionSettings';

class ReportingCLineSettings extends Component {
  static propTypes = {
    settings: PropTypes.any.isRequired,
    updateSettings: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  };

  render() {
    const { settings, updateSettings, type } = this.props;
    return <CLineChartSettings settings={settings} onSettingsChange={updateSettings} type={type} />;
  }
}

const currentSettingsSelector = ({ reporting: { currentPageIndex, currentCardIndex, children } }) => {
  const currentPage = children[currentPageIndex];
  const currentCard = currentPage.children[currentCardIndex];

  return currentCard.settings;
};

const mapStateToProps = state => ({
  settings: currentSettingsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  updateSettings: settings => dispatch(updateSectionSettings(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportingCLineSettings);
