import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import QButton from '_Components/Buttons/QButton/QButton';
import { withTranslation } from 'react-i18next';
import { capitalize } from '_Utils/strings/stringsUtils';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { get } from 'lodash';
import { setCurrentPeriod } from '_Resources/Global/Actions/setCurrentPeriod';

const handleOnClick = (
  lastDataCollectorId: number,
  filters: any,
  dispatch: any,
  range: { startDate: DateConstructor; endDate: DateConstructor },
) => {
  dispatch(
    setCurrentPeriod({
      startDate: range.startDate,
      endDate: range.endDate,
    }),
  );
  dispatch(
    setGlobalQueryFilterKeys({
      ...filters,
      range: {
        startDate: range.startDate,
        endDate: range.endDate,
      },
      dataCollectorId: lastDataCollectorId || null,
    }),
  );
};

type LatestBatchProps = {
  dataCollectorId: number;
  t: any;
};

const LatestBatch = ({ dataCollectorId, t }: LatestBatchProps) => {
  const dispatch = useDispatch();
  const lastDataCollectorId = useSelector((state: any) =>
    get(state, ['projectConfiguration', 'lastDataCollectorId']),
  );

  const filters = useSelector((state: any) =>
    get(state, ['filters', 'filterKeys']),
  );

  const rangeLastBatch = useSelector((state: any) =>
    get(state, ['periods', 'projectPeriods', 'byLastBatch', 'period']),
  );
  const rangeAll = useSelector((state: any) =>
    get(state, ['periods', 'projectPeriods', 'byAll', 'period']),
  );

  let range = { startDate: Date, endDate: Date };

  if (rangeLastBatch) {
    range = Object.values(rangeLastBatch).some(date => !!date)
      ? rangeLastBatch
      : rangeAll;
  }

  return (
    <QButton
      bgColor="grayShades.g800"
      size="small"
      block
      onClick={() =>
        handleOnClick(lastDataCollectorId, filters, dispatch, range)
      }
      disabled={!lastDataCollectorId}
    >
      {t(`button:${capitalize('latest batch')}`)}
    </QButton>
  );
};

const mapStateToProps = (state: any) => ({
  dataCollectorId: get(state, ['filters', 'filterKeys', 'dataCollectorId']),
});

export default withTranslation('button')(connect(mapStateToProps)(LatestBatch));
