import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Collapse } from 'reactstrap';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import HeaderProjectFilters from '_Layouts/Header/_Containers/SubHeader/_Containers/HeaderProjectFilters/HeaderProjectFilters';
import cornerRounded from '_Layouts/Header/_Containers/SubHeader/corner_rounded.svg';
import Project from '_Layouts/Header/_Containers/SubHeader/_Containers/ListProjectContainer';
import Period from '_Layouts/Header/_Containers/SubHeader/_Components/Period';
import LatestBatchIndicator from '_Layouts/Header/_Containers/SubHeader/_Components/LatestBatchIndicator';
import OptionsButtons from '_Layouts/Header/_Containers/SubHeader/_Containers/OptionsButtons';
import FilterButton from '_Layouts/Header/_Containers/SubHeader/_Components/FilterButton';
import GlobalFiltersValueProvider from '_Layouts/Header/_Containers/SubHeader/_Containers/HeaderProjectFilters/GlobalFiltersValueProvider';
import { grayShades, white } from 'styles/abstracts/colors';
import { spacing } from 'styles/abstracts/variables';
import { EnhancerOptions as undefined } from 'redux-devtools-extension';

const SHContainer = styled.div`
  background: ${white};
  height: auto;
  // min-height: 75px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.14);
  margin-left: calc(5 * ${spacing.medium});
  position: relative;
  z-index: 2;
`;

const Contain = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  min-height: 72px;
  padding: 0 calc(${spacing.medium} * 2);
  border-radius: 4rem;
  border-bottom: ${props => props.isSubHeader && `1px solid ${grayShades.g300}`};
`;

const FilterCollapseContainer = styled.div`
  background-color: ${white};
  padding: ${spacing.medium};
  border-bottom: ${props => props.isSubHeader && `1px solid ${grayShades.g300}`};
  border-top: ${`1px solid ${grayShades.g300}`};
  box-shadow: 0px -9px 1px -1px ${white}, 0 2px 14px 0 rgba(0, 0, 0, 0.14);
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledHeaderProjectFilters = styled.div`
  flex-grow: 1;
`;
const ContainInput = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  p {
    width: 5rem;
    color: ${grayShades.g800};
    font-weight: bold;
  }
`;

const OptionalHeader = styled.div`
  background: ${white};
  top: 100%;
`;

const FiltersButton = styled(Col)`
  position: absolute !important;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
`;

const OptionsDiv = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: flex-start;
`;

class SubHeader extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    additionalSubHeader: PropTypes.element,
    SHContent: PropTypes.element,
    setCollapseHeight: PropTypes.func.isRequired,
    collapsedFilters: PropTypes.bool.isRequired,
    collapsedInfo: PropTypes.bool.isRequired,
    collapsed: PropTypes.bool,
    collapseContent: PropTypes.element,
    allowCollapse: PropTypes.bool,
    toggleFilters: PropTypes.func.isRequired,
    toggleInfo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    collapseContent: undefined,
    allowCollapse: true,
    SHContent: undefined,
    additionalSubHeader: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, className, allowCollapse, collapsedFilters, toggleFilters, subHeaderRef, idpage } = this.props;

    return (
      <SHContainer id="subHeader" className={className} ref={subHeaderRef}>
        <img src={cornerRounded} style={{ position: 'absolute' }} alt="" />
        <Contain
          id="mainFiltersContainer"
          isSubHeader={!!this.props.additionalSubHeader}
          className="align-items-stretch align-content-stretch"
        >
          <OptionsDiv>
            {this.props.SHContent === null
              ? null
              : this.props.SHContent || (
                  <>
                    <Project />
                    <Period />
                    <LatestBatchIndicator />
                  </>
                )}
          </OptionsDiv>
          <OptionsButtons />
          {this.props.additionalButtons && <div>{this.props.additionalButtons}</div>}
        </Contain>
        {allowCollapse && (
          <Collapse
            isOpen={!collapsedFilters}
            id="collapsible"
            onEntering={() => this.props.setCollapseHeight(true)}
            onExiting={() => this.props.setCollapseHeight(false)}
          >
            <FilterCollapseContainer
              id="collapsibleFiltersContainer"
              isSubHeader={!!this.props.additionalSubHeader}
              isOpen={!collapsedFilters}
            >
              {this.props.collapseContent || (
                <ContainInput>
                  <p>{t('Filters')}</p>
                  <StyledHeaderProjectFilters>
                    <GlobalFiltersValueProvider>
                      <HeaderProjectFilters />
                    </GlobalFiltersValueProvider>
                  </StyledHeaderProjectFilters>
                </ContainInput>
              )}
            </FilterCollapseContainer>
          </Collapse>
        )}
        <OptionalHeader id="optionalHeader">{this.props.additionalSubHeader}</OptionalHeader>
        {allowCollapse && (
          <FiltersButton
            xs="auto"
            className="d-flex align-items-center align-content-center"
            style={{ bottom: '-1rem' }}
          >
            <FilterButton
              toggle={toggleFilters}
              collapsed={collapsedFilters}
              labelCollapsed="More filters"
              labelExpanded="Less filters"
              filtersAppliedLabelCollapsed="filters applied collapsed"
              filtersAppliedLabelExpanded="filters applied expanded"
              displayAppliedFilters
              idPage={idpage}
            />
          </FiltersButton>
        )}
      </SHContainer>
    );
  }
}

export default withTranslation('header')(SubHeader);
