import gql from 'graphql-tag';

const mutation = gql`
  mutation updateInsight(
    $projectId: Int!
    $id: Int!
    $name: String
    $description: String
    $modules: [String!]
  ) {
    updateInsight(
      projectId: $projectId
      id: $id
      name: $name
      description: $description
      modules: $modules
    ) {
      insight {
        insightId
        insightName
        description
        modules
        editedOn
        editedBy
      }
    }
  }
`;

export default mutation;
