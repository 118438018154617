import gql from 'graphql-tag';

const KpiResultsFragment = gql`
  fragment KpiResultsFragment on KpiResults {
    unit {
      min
      max
      unit
    }
    value
    verbatimsPerKpi {
      kpiValue
      percentage
    }
    yearBefore {
      #TODO ...BenchmarkPeriodFragment
      value
      delta
      period {
        startDate
        endDate
      }
    }
    periodBefore {
      #TODO ...BenchmarkPeriodFragment
      value
      delta
      period {
        startDate
        endDate
      }
    }
    __typename
  }
`;

export default KpiResultsFragment;
