import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  inputColor,
  purple,
  lightBlue,
} from '../../../styles/abstracts/colors';
import { em } from '../../../styles/utils';
import { BasePropTypes } from '../../../_Utils/common.propTypes';
import { fontSizes } from '../../../styles/abstracts/typography';

const InitSize = 1.25;
const size = em(InitSize);
const radius = em(InitSize / 6);
const borderWidth = '3px';

const StyledQCheckbox = styled.div`
  cursor: pointer;
  width: ${size};
  height: ${size};
  border-radius: ${radius};
  background-color: ${({ selected, indeterminate }) =>
    selected ? purple : indeterminate ? lightBlue : inputColor};

  margin: 1em;
  &::before {
    display: inline-block;
    margin: 50%;
    transform: translate(-50%, -72%) rotate(135deg);
    content: ${({ selected }) => (selected ? "''" : 'none')};
    width: 14px;
    height: 6px;
    border-top: ${borderWidth} solid white;
    border-right: ${borderWidth} solid white;
    border-top-right-radius: ${borderWidth};
  }

  ${({ indeterminate, selected }) =>
    indeterminate &&
    !selected &&
    `
    display: flex !important;
    justify-content: center;
    align-items: center;
  `}
`;

const Label = styled.div`
  font-weight: 600;
  font-size: ${fontSizes.smaller};
`;

const CheckboxTick = styled.span`
  background-color: white;
  font-weight: bold;
  width: 0.8em;
  height: 0.1em;
  border-radius: ${radius};
`;

const QCheckbox = props =>
  props.indeterminate && !props.selected ? (
    <StyledQCheckbox {...props} as="span">
      <CheckboxTick>
        <div />
      </CheckboxTick>
    </StyledQCheckbox>
  ) : (
    <StyledQCheckbox {...props} />
  );

QCheckbox.propTypes = {
  selected: PropTypes.bool,
};

QCheckbox.defaultProps = {
  selected: false,
};

QCheckbox.Label = ({ children, className }) => (
  <Label className={className}>{children}</Label>
);

QCheckbox.Label.propTypes = {
  ...BasePropTypes,
};

export default QCheckbox;
