import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import QButton from '_Components/Buttons/QButton/QButton';
import { withApollo } from 'react-apollo';
import { red, lightBlue, grayShades } from 'styles/abstracts/colors';
import query from '../../graphql/resetPasswordSendEmail';
import logo2x from '../../img/qemotion_login-logo@2x.png';
import logo from '../../img/qemotion_login-logo.png';

const EmailVerificationMessage = styled.p`
  font-weight: bold;
  color: ${({ code }) => (code === 'MAIL_ERROR_SENDING' ? red : lightBlue)};
`;

const StyledCol = styled(Col)`
  flex: 0;
`;

const ForgotPassword = ({ client, t }) => {
  const [email, setEmail] = useState('');
  const [queryMessage, setQueryMessage] = useState({});
  const { code, message } = queryMessage;
  return (
    <Row className="flex-column">
      <Col className="mb-lg-5 mt-lg-5 mx-auto d-flex justify-content-center">
        <img
          src={logo}
          srcSet={`${logo} 1x, ${logo2x} 2x`}
          alt="Q°emotion"
          className="m-auto img-fluid"
        />
      </Col>
      <Col className="mb-lg-5 mx-auto">
        <h1 className="text-center text-md-left">{t('button:Forgot password?')}</h1>
        <p className="text-center text-md-left">
          {t(`Enter your email below, and we'll send you an email with a link that will allow you to reset your password.`)}
        </p>
      </Col>
      <Col className="mx-auto">
        <FormGroup>
          <Label for="login_email" className="ml-1dot5 text-secondary">
            {t('Company email address')}
          </Label>
          <Input
            type="email"
            name="usernameOrEmail"
            id="usernameOrEmail"
            size="lg"
            placeholder=""
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus
          />
        </FormGroup>
      </Col>
      <StyledCol xl={6} lg={8} md={10} sm={12} className="text-center mx-auto">
        <EmailVerificationMessage code={code}>
          {message}
        </EmailVerificationMessage>
        <QButton
          bgColor="red"
          size="large"
          className="px-5 mb-3 mt-4"
          type="submit"
          onClick={async () => {
            const {
              data: { resetPasswordSendEmail },
            } = await client.query({ query, variables: { email } });
            setQueryMessage(resetPasswordSendEmail);
          }}
          disabled={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
        >
          {t('button:Send email')}
        </QButton>
      </StyledCol>
    </Row>
  );
};

export default withApollo(ForgotPassword);
