import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import { get, uniq } from 'lodash';
import MutationSaveTriggersTable from '_Resources/Triggers/Services/MutationSaveTriggersTable';
import { spacing } from 'styles/abstracts/variables';
import { grayShades } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QInput from '_Components/Fields/QInput/QInput';
import QModalBody from '_Components/Modals/QModalBody/QModalBody';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import QModal from '_Components/Modals/QModal/QModal';
import QSelect from '_Components/Fields/QSelect/QSelect';
import Icon from '_Components/Icons/Icon';
import ModalFiltersPage from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/ModalFiltersPage';
import { columnCollapseContent } from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/configs/CollapseContents';
import { CONTEXT } from 'Routes/Insights/Triggers/_Components/configs/globalConfig';
import { StyledQButton } from 'Routes/Insights/Triggers/_Components/Modals/_Components/index';
import { setTriggersTableName } from '_Resources/Triggers/Actions/Filters/setTriggersTableName';
import { setTriggersTableDescription } from '_Resources/Triggers/Actions/Filters/setTriggersTableDescription';
import { setTriggersTableAnalysisAxis } from '_Resources/Triggers/Actions/Filters/setTriggersTableAnalysisAxis';
import { setTriggersTableItems } from '_Resources/Triggers/Actions/Filters/setTriggersTableItems';
import { setTriggersTableCalculationCriteria } from '_Resources/Triggers/Actions/Filters/setTriggersTableCalculationCriteria';
import { setTriggersTableThreshold } from '_Resources/Triggers/Actions/Filters/setTriggersTableThreshold';
import {
  getAnalysisAxisOptions,
  // analysisAxisOptions,
  // calculationCriteriaOptions,
  getCalculationCriteriaOptions,
  // itemsToDisplayOptions,
  getItemsToDisplayOptions,
  getItemsToDisplayVariable,
  getStateItemsToDisplay,
} from '_Resources/Triggers/Services/helpers';
import ItemsToDisplaySelect from './ItemsToDisplaySelect';

const StyledQModalBody = styled(QModalBody)`
  padding: 0 ${spacing.medium};
`;

const StyledQInput = styled(QInput)`
  width: 100%;
`;

const VolumeInput = styled(QInput)`
  width: 100%;
  padding: 0.5em 2em 0.5em 1em;
  font-size: 13px;
  height: 38px;
  border-radius: 20px;
`;

const StyledTextArea = styled(QInput)`
  width: 100%;
  resize: none;
`;

const StyledLabel = styled(Label)`
  color: ${grayShades.g800};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Cancel = styled.p`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  cursor: pointer;
  font-weight: bold;
`;

const CreateButton = styled(QButton)`
  margin: 0 ${spacing.xsmall} 0 ${spacing.large} !important;
`;

const Description = styled(QTitle)`
  font-size: 1em;
  padding: 0;
  margin: 0;
`;

const SelectContainer = styled.div`
  width: calc(50% - 0.5rem);
  font-weight: bold;
`;

const StyledSelect = styled(QSelect)``;

const SelectFormGroup = styled(FormGroup)`
  display: flex;
  justify-content: space-between;
`;

const PercentageInputContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PercentageSign = styled.span`
  position: absolute;
  right: 2.5rem;
`;

const StyledSpan = styled.span`
  margin-left: 1em;
  position: relative;
  &:hover > div {
  visibility: visible;
`;

const TooltipThematic = styled.div`
  visibility: hidden;
  max-width: 32em;
  position: absolute;
  top: -50px;
  left: 26px;
  z-index: 30;
  background-color: ${grayShades.g200};
  text-align: left;
  padding: 7px 10px;
  border-radius: 8px;
  color: ${grayShades.g800};
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
  width: max-content;
  font-size: 12px;

  &::after {
    content: '';
    position: absolute;
    right: 100%;
    top: 57px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent ${grayShades.g200} transparent transparent;
  }
`;

class TriggersSettingsModal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    triggersTable: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        analysisAxis: PropTypes.string,
        period: PropTypes.shape({
          startDate: PropTypes.string,
          endDate: PropTypes.string,
        }),
        isDefault: PropTypes.bool.isRequired,
        settings: PropTypes.array,
      }),
    ),
    footer: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    currentProject: PropTypes.shape.isRequired,
  };

  static defaultProps = {
    triggersTable: null,
  };

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      triggersTableName: get(this.props.triggersTable, 'title') || '',
      triggersTableDescription: get(this.props.triggersTable, 'description') || '',
      triggersTableAnalysisAxis: get(this.props.triggersTable, ['settings', 'analysisAxis']) || '',
      triggersTableItemsToDisplay: get(this.props.triggersTable, ['settings', 'itemsToDisplay']) || '',
      triggersTableCalculationCriteria: get(this.props.triggersTable, ['settings', 'calculationCriteria']) || '',
      triggersTableSignificanceThreshold: get(this.props.triggersTable, ['settings', 'significanceThreshold']) || '',
      triggersTablePeriod: get(this.props.triggersTable, 'period') || { startDate: '', endDate: '' },
      step: 0,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onAnalysisAxisChange = this.onAnalysisAxisChange.bind(this);
    this.onItemsToDisplayChange = this.onItemsToDisplayChange.bind(this);
    this.onCalculationCriteriaChange = this.onCalculationCriteriaChange.bind(this);
    this.onSignificanceThresholdChange = this.onSignificanceThresholdChange.bind(this);
    this.onPeriodStartChange = this.onPeriodStartChange.bind(this);
    this.onPeriodEndChange = this.onPeriodEndChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    // this.getStateItemsToDisplay = this.getStateItemsToDisplay.bind(this);
  }

  onSubmit() {
    const { toggle } = this.props;
    toggle();
    this.setState({ step: 0 });
  }

  // itemsToDisplayOptions() {
  //   const { t, criteriaKeysOptions } = this.props;
  //   return [
  //     {
  //       value: 'thematic',
  //       label: t('Thematics'),
  //     },
  //     {
  //       value: 'sub_thematic',
  //       label: t('Sub thematics'),
  //     },
  //     {
  //       value: 'sub_sub_thematic',
  //       label: t('Sub sub thematics'),
  //     },
  //     // {
  //     //   value: 'clusters',
  //     //   label: t('Irritants & Enchanting points'),
  //     // },
  //     {
  //       value: 'criteria_key',
  //       label: t('Criteria keys values'),
  //       subOptions: criteriaKeysOptions,
  //     },
  //   ];
  // }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
    const {
      t,
      dispatchSetTriggersTableName,
      dispatchSetTriggersTableDescription,
      dispatchSetTriggersTableAnalysisAxis,
      dispatchSetTriggersTableItems,
      dispatchSetTriggersTableCalculationCriteria,
      dispatchSetTriggersTableThreshold,
      criteriaKeysOptions,
    } = this.props;
    if ((prevProps?.triggersTable?.title !== this.props?.triggersTable?.title) && (this.props?.triggersTable?.title !== undefined) ) {
      this.setState({
        triggersTableName: this.props.triggersTable.title,
      });
      dispatchSetTriggersTableName(this.props.triggersTable.title);
    }
    if ((prevProps?.triggersTable?.description !== this.props?.triggersTable?.description) && (this.props?.triggersTable?.description !== undefined)) {
      this.setState({
        triggersTableDescription: this.props.triggersTable.description,
      });
      dispatchSetTriggersTableDescription(this.props.triggersTable.description);
    }

    if (
      typeof this.state.triggersTableAnalysisAxis === 'string' ||
      prevProps?.triggersTable?.id !== this.props?.triggersTable?.id
    ) {
      const stateAnalysisAxis = getAnalysisAxisOptions(t).find(
        option => option.value === this.props.triggersTable?.settings?.analysisAxis,
      );
      this.setState({
        triggersTableAnalysisAxis: stateAnalysisAxis,
      });
      dispatchSetTriggersTableAnalysisAxis(stateAnalysisAxis);
    }
    if ((
      this.state.triggersTableItemsToDisplay === undefined ||
      prevProps?.triggersTable?.id !== this.props?.triggersTable?.id
    ) && (prevProps?.triggersTable?.settings?.itemsToDisplay !== this.props?.triggersTable?.settings?.itemsToDisplay)){
      const variableItemsToDisplay = this.props?.triggersTable?.settings?.itemsToDisplay;
      const itemsOptions = getItemsToDisplayOptions(criteriaKeysOptions, t);

      const stateItemsToDisplay = getStateItemsToDisplay(variableItemsToDisplay, itemsOptions);

      this.setState({
        triggersTableItemsToDisplay: stateItemsToDisplay,
      });
      dispatchSetTriggersTableItems(stateItemsToDisplay);
    }
    if (
      typeof this.state.triggersTableCalculationCriteria === 'string' || // either there is a problem in the local state data
      prevProps?.triggersTable?.id !== this.props?.triggersTable?.id // or the user just switched triggers tables
    ) {
      const stateCalculationCriteria = getCalculationCriteriaOptions(t).find(
        option => option.value === this.props?.triggersTable?.settings?.calculationCriteria,
      );
      this.setState({
        triggersTableCalculationCriteria: stateCalculationCriteria,
      });
      dispatchSetTriggersTableCalculationCriteria(stateCalculationCriteria);
    }
    if (
      (typeof parseFloat(this.state.triggersTableSignificanceThreshold) !== 'number' ||
        prevProps?.triggersTable?.id !== this.props?.triggersTable?.id) &&
      !isNaN(this.props?.triggersTable?.settings?.significanceThreshold)
    ) {
      const stateSignificanceThreshold = parseFloat(this.props?.triggersTable?.settings?.significanceThreshold);
      this.setState({
        triggersTableSignificanceThreshold: stateSignificanceThreshold,
      });
      dispatchSetTriggersTableThreshold(stateSignificanceThreshold);
    }
  }

  onNameChange(e: { target: { value: any } }) {
    const { dispatchSetTriggersTableName } = this.props;
    this.setState({ triggersTableName: e.target.value });
    dispatchSetTriggersTableName(e.target.value);
  }

  onDescriptionChange(e: { target: { value: any } }) {
    const { dispatchSetTriggersTableDescription } = this.props;
    this.setState({ triggersTableDescription: e.target.value });
    dispatchSetTriggersTableDescription(e.target.value);
  }

  onAnalysisAxisChange(e: any) {
    const { dispatchSetTriggersTableAnalysisAxis } = this.props;
    this.setState({ triggersTableAnalysisAxis: e });
    dispatchSetTriggersTableAnalysisAxis(e.value);
  }

  onItemsToDisplayChange(e: any) {
    const { dispatchSetTriggersTableItems } = this.props;
    this.setState({ triggersTableItemsToDisplay: e });
    dispatchSetTriggersTableItems(e);
  }

  onCalculationCriteriaChange(e: any) {
    const { dispatchSetTriggersTableCalculationCriteria } = this.props;
    this.setState({ triggersTableCalculationCriteria: e });
    dispatchSetTriggersTableCalculationCriteria(e);
  }

  onSignificanceThresholdChange(e: { target: { value: string } }) {
    const { dispatchSetTriggersTableThreshold } = this.props;
    if (!isNaN(e.target.value)) {
      this.setState({ triggersTableSignificanceThreshold: e.target.value });
      dispatchSetTriggersTableThreshold(parseFloat(e.target.value));
    }
  }

  onPeriodStartChange(e: { target: { value: any } }) {
    this.setState({ triggersTablePeriod: { startDate: e.target.value } });
  }

  onPeriodEndChange(e: { target: { value: any } }) {
    this.setState({ triggersTablePeriod: { endDate: e.target.value } });
  }

  onCancel() {
    const { toggle } = this.props;
    toggle();
    this.setState({ step: 0 });
  }

  getThematicsVariable() {
    const { edit } = this.props;
    const { settings: temporaryTriggersSettings } = this.props.temporaryTriggers;
    const { settings: activeTriggersSettings } = this.props.triggersTable || {};
    const { thematics } = temporaryTriggersSettings;

    let thematicsVariable = [];

    if (thematics && thematics.length > 0) {
      thematicsVariable = thematics.map((thematic: { option: { category: any; label: any }; parents: any[] }) => {
        switch (thematic?.option?.category) {
          case 'Thematic':
            return { thematic: thematic.option.label };
          case 'Sub thematic':
            return { thematic: thematic.parents[1], subThematic: thematic.option.label };
          case 'Sub sub thematic':
            return {
              thematic: thematic.parents[1],
              subThematic: thematic.parents[2],
              subSubThematic: thematic.option.label,
            };
          default:
            return { thematic: thematic?.option?.label };
        }
      });
    }

    return thematicsVariable;
  }

  getCriteriaKeysVariable() {
    const { temporaryTriggers, edit } = this.props;

    const { settings: triggersSettings } = temporaryTriggers;
    const { criteriaKeys } = triggersSettings;
    const criteriaKeysCategories = uniq(criteriaKeys.map((key: { filterCategory: string }) => key.filterCategory));
    const criteriaKeysArray: any[] = [];
    if (edit) return criteriaKeys;
    criteriaKeysCategories.forEach((category: string) => {
      criteriaKeys.map((key: { filterCategory: string; option: { label: any } }) => {
        if (key.filterCategory === category) {
          const criteriaKeysFoundCategory = criteriaKeysArray.find(criteriaKey => criteriaKey.name === category);
          if (!criteriaKeysFoundCategory) {
            criteriaKeysArray.push({ name: key.filterCategory, values: [] });
          }
          criteriaKeysArray.find(criteriaKey => criteriaKey.name === category).values.push(key?.option?.label);
        }
        return criteriaKeysArray;
      });
    });

    return criteriaKeysArray;
  }

  getAddFooter(modalConfig: { cancelButton: any; validateButton: any; steps: any }, currentStep: number) {
    const { currentProject, t, temporaryTriggers, edit, triggersTable, criteriaKeysOptions } = this.props;

    const {
      triggersTableName,
      triggersTableDescription,
      triggersTableAnalysisAxis,
      triggersTableCalculationCriteria,
      triggersTableSignificanceThreshold: stateTriggersTableSignificanceThreshold,
    } = this.state;
    const { steps } = modalConfig;
    const { settings: temporaryTriggersSettings } = temporaryTriggers;
    const { itemsToDisplay, significanceThreshold: triggersTableSignificanceThreshold, range } = temporaryTriggersSettings;
    const itemsOptions = getItemsToDisplayOptions(criteriaKeysOptions, t);

    return (
      <Footer>
        <StyledQButton hide={!steps[currentStep - 1]} onClick={this.prevStep}>
          {steps[currentStep].prevButtonText || t('Prev. step')}
        </StyledQButton>
        <StyledQButton onClick={() => this.onCancel()}>{t('button:Cancel')}</StyledQButton>
        {steps[currentStep + 1] && (
          <StyledQButton hide={!steps[currentStep + 1]} onClick={this.nextStep}>
            {steps[currentStep].nextButtonText || t('Next step')}
          </StyledQButton>
        )}
        {!steps[currentStep + 1] && (
          <MutationSaveTriggersTable
            variables={{
              id: edit && triggersTable.id,
              computeTable: edit,
              projectId: currentProject && currentProject.id,
              title: triggersTableName,
              description: triggersTableDescription,
              analysisAxis: triggersTableAnalysisAxis?.value,
              itemsToDisplay: getItemsToDisplayVariable(itemsToDisplay, itemsOptions),
              calculationCriteria: triggersTableCalculationCriteria?.value,
              significanceThreshold: parseFloat(stateTriggersTableSignificanceThreshold),
              range,
              thematics: this.getThematicsVariable(),
              criteriaKeys: this.getCriteriaKeysVariable(),
            }}
            callBackAction={this.onSubmit}
          >
            <CreateButton
              icon="CHECK_ALL"
              iconSize={20}
              iconPosition="left"
              disabled={!(triggersTableName.length > 0) || typeof parseFloat(triggersTableSignificanceThreshold) !== 'number'}
            >
              &nbsp; {!edit && t('triggers:Create triggers table')} {edit && t('triggers:Recompute triggers table')}
            </CreateButton>
          </MutationSaveTriggersTable>
        )}
      </Footer>
    );
  }

  getRenameModalFooter() {
    const { t, triggersTable, toggle } = this.props;
    const { triggersTableName, triggersTableDescription } = this.state;
    return (
      <Footer>
        <Cancel
          onClick={() => {
            toggle();
            this.setState({ step: 0 });
          }}
        >
          {t('button:Cancel')}
        </Cancel>
        <MutationSaveTriggersTable
          variables={{
            id: triggersTable.id,
            title: triggersTableName,
            description: triggersTableDescription,
          }}
          callBackAction={this.onSubmit}
        >
          <CreateButton>{t('triggers:Edit this triggers table')}</CreateButton>
        </MutationSaveTriggersTable>
      </Footer>
    );
  }

  nextStep() {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  }

  prevStep() {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  }

  initModalValues = {
    config: {
      date: false,
      mentions: false,
    },
  };

  render() {
    const propsModal = this.initModalValues;
    const {
      t,
      criteriaKeysOptions,
      temporaryTriggers,
      triggersTable,
      isOpen,
      title,
      toggle,
      subtitle,
      edit,
    } = this.props;
    const {
      triggersTableName,
      step,
      triggersTableAnalysisAxis,
      triggersTableCalculationCriteria,
      triggersTableSignificanceThreshold,
      triggersTableDescription,
      triggersTableItemsToDisplay,
    } = this.state;

    const { settings: temporaryTriggersSettings } = temporaryTriggers;

    const { settings: activeTriggersSettings } = triggersTable || {};

    const currentSettings = edit ? activeTriggersSettings : temporaryTriggersSettings;

    const MODAL_CONTENT_CONFIG = {
      cancelButton: {
        text: t('button:Cancel'),
      },
      validateButton: {
        text: t('button:Create this triggers table'),
        disabled: !triggersTableName,
      },
      steps: [
        {
          title: 'Step 0',
          stepBody: (
            <>
              {!triggersTable && (
                <>
                  <Description>{t('Name and describe your new triggers table')}</Description>
                  <hr />
                </>
              )}
              <FormGroup>
                <StyledLabel>{t('Name of triggers table')}</StyledLabel> <br />
                <StyledQInput primary maxLength={50} value={triggersTableName} onChange={this.onNameChange} />
              </FormGroup>
              <SelectFormGroup>
                <SelectContainer>
                  <StyledLabel>{t('Analysis axis')}</StyledLabel>
                  <StyledSpan role="presentation">
                    <Icon
                      icon="INTERFACE_INFO"
                      size={20}
                      color={grayShades.g400}
                    />
                    <TooltipThematic
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'triggers:Analysis axis select definition',
                        ),
                      }}
                    />
                  </StyledSpan>
                  <br />
                  <StyledSelect
                    name="analysisAxisSelect"
                    value={triggersTableAnalysisAxis}
                    className="selectInput"
                    searchable={false}
                    clearable={false}
                    placeholder={t('button:Analysis axis')}
                    onChange={this.onAnalysisAxisChange}
                    options={getAnalysisAxisOptions(t)}
                    // options={analysisAxisOptions}
                  />
                </SelectContainer>
                <SelectContainer>
                  <StyledLabel>{t('Items to display')}</StyledLabel>
                  <StyledSpan role="presentation">
                    <Icon
                      icon="INTERFACE_INFO"
                      size={20}
                      color={grayShades.g400}
                    />
                    <TooltipThematic
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'triggers:Items to display select definition',
                        ),
                      }}
                    />
                  </StyledSpan>
                  <br />
                  <ItemsToDisplaySelect
                    value={triggersTableItemsToDisplay}
                    onChange={this.onItemsToDisplayChange}
                    // options={itemsToDisplayOptions(criteriaKeysOptions)}
                    options={getItemsToDisplayOptions(criteriaKeysOptions, t)}
                  />
                </SelectContainer>
              </SelectFormGroup>
              <SelectFormGroup>
                <SelectContainer>
                  <StyledLabel>{t('Calculation criteria')}</StyledLabel> <br />
                  <StyledSelect
                    name="calculationCriteriaSelect"
                    value={triggersTableCalculationCriteria}
                    className="selectInput"
                    searchable={false}
                    clearable={false}
                    placeholder={t('button:Calculation criteria')}
                    onChange={this.onCalculationCriteriaChange}
                    // options={calculationCriteriaOptions}
                    options={getCalculationCriteriaOptions(t)}
                  />
                </SelectContainer>
                <SelectContainer>
                  <StyledLabel>{t('Volume threshold')}</StyledLabel> <br />
                  <PercentageInputContainer>
                    <VolumeInput
                      primary
                      maxLength={4}
                      value={triggersTableSignificanceThreshold}
                      onChange={this.onSignificanceThresholdChange}
                    />
                    <PercentageSign> %</PercentageSign>
                  </PercentageInputContainer>
                </SelectContainer>
              </SelectFormGroup>
              <FormGroup>
                <StyledLabel>{t('Description')}</StyledLabel> <br />
                <StyledTextArea
                  as="textarea"
                  primary
                  rows={2}
                  value={triggersTableDescription}
                  onChange={this.onDescriptionChange}
                  maxLength={175}
                />
              </FormGroup>
            </>
          ),
          nextButtonText: t('button:Next step'),
        },
        {
          title: 'Step 1',
          stepBody: (
            <ModalFiltersPage
              stepTitle={t('Choose your filters')}
              columnCollapseContent={columnCollapseContent}
              context={CONTEXT.TRIGGERS}
              currentSettingsFilters={currentSettings}
              edit={edit}
            />
          ),
          prevButtonText: t('button:Prev. step'),
        },
      ],
    };

    return (
      <QModal
        footer={this.getAddFooter(MODAL_CONTENT_CONFIG, step)}
        isOpen={isOpen}
        title={t(title)}
        onClose={toggle}
        subtitle={t(subtitle)}
        {...propsModal}
      >
        <StyledQModalBody>{MODAL_CONTENT_CONFIG.steps[step].stepBody}</StyledQModalBody>
      </QModal>
    );
  }
}

const mapStateToProps = (state: { triggers: { projectTriggersTables: any }; projects: { currentProject: any } }) => ({
  triggersTablesList: state.triggers?.projectTriggersTables,
  currentProject: state.projects.currentProject,
  temporaryTriggers: state.triggers?.temporaryTriggers,
  criteriaKeysOptions: state.filters?.projectFilters?.criteriaKeys?.normal,
});

const mapDispatchToProps = (dispatch: (arg0: { type: string; data: any }) => void) => ({
  dispatchSetTriggersTableName: (newState: any) => {
    dispatch(setTriggersTableName(newState));
  },
  dispatchSetTriggersTableDescription: (newState: any) => {
    dispatch(setTriggersTableDescription(newState));
  },
  dispatchSetTriggersTableAnalysisAxis: (newState: any) => {
    dispatch(setTriggersTableAnalysisAxis(newState));
  },
  dispatchSetTriggersTableItems: (newState: any) => {
    dispatch(setTriggersTableItems(newState));
  },
  dispatchSetTriggersTableCalculationCriteria: (newState: any) => {
    dispatch(setTriggersTableCalculationCriteria(newState));
  },
  dispatchSetTriggersTableThreshold: (newState: any) => {
    dispatch(setTriggersTableThreshold(newState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('triggers', 'button')(TriggersSettingsModal));
