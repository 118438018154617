import { loadComponent } from '../../_Utils/routeComponent';

export default [
  {
    i: 'EindexTemperatureCard',
    component: 'EindexTemperatureCard',
    data: {
      title: 'Average E-index',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: 8,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').xl.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').xl.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').xl.minH,
        x: 0,
        y: 0,
      },
      lg: {
        w: 8,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').lg.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').lg.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').lg.minH,
        x: 0,
        y: 0,
      },
      md: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').md.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').md.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').md.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').md.minH,
        x: 0,
        y: 0,
      },
      sm: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').sm.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').sm.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').sm.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').sm.minH,
        x: 0,
        y: 0,
      },
      xs: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').xs.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').xs.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').xs.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').xs.minH,
        x: 0,
        y: 0,
      },
    },
  },
  {
    i: 'EindexEvolutionWithMentionsCard',
    component: 'EindexEvolutionWithMentionsCard',
    data: {
      title: 'E-Index evolution',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xl.w,
        h: 8,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xl
          .minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xl
          .minH,
        x: 8,
        y: 0,
      },
      lg: {
        w: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').lg.w,
        h: 8,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').lg
          .minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').lg
          .minH,
        x: 8,
        y: 0,
      },
      md: {
        w: 4,
        h: 8,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').md
          .minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').md
          .minH,
        x: 4,
        y: 0,
      },
      sm: {
        w: 2,
        h: 8,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').sm
          .minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').sm
          .minH,
        x: 2,
        y: 0,
      },
      xs: {
        w: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xs.w,
        h: 8,
        minW: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xs
          .minW,
        minH: loadComponent('EindexEvolutionWithMentionsCard', 'breakpoints').xs
          .minH,
        x: 0,
        y: 8,
      },
    },
  },
  {
    i: 'EindexBarGraphCard',
    component: 'EindexBarGraphCard',
    data: {
      title: 'Average E-Index',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.minH,
        x: 0,
        y: 8,
      },
      lg: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.minH,
        x: 0,
        y: 8,
      },
      md: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.minH,
        x: 0,
        y: 8,
      },
      sm: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.minH,
        x: 0,
        y: 8,
      },
      xs: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.minH,
        x: 0,
        y: 8,
      },
    },
  },
  // {
  //   i: 'MentionBreakdownCard',
  //   component: 'MentionBreakdownCard',
  //   data: {
  //     title: 'Mention breakdown',
  //     titleSecondPart: '',
  //   },
  //   breakpoints: {
  //     xl: {
  //       w: loadComponent('MentionBreakdownCard', 'breakpoints').xl.w,
  //       h: loadComponent('MentionBreakdownCard', 'breakpoints').xl.h,
  //       minW: loadComponent('MentionBreakdownCard', 'breakpoints').xl.minW,
  //       minH: loadComponent('MentionBreakdownCard', 'breakpoints').xl.minH,
  //       x: 0,
  //       y: 8,
  //     },
  //     lg: {
  //       w: loadComponent('MentionBreakdownCard', 'breakpoints').lg.w,
  //       h: loadComponent('MentionBreakdownCard', 'breakpoints').lg.h,
  //       minW: loadComponent('MentionBreakdownCard', 'breakpoints').lg.minW,
  //       minH: loadComponent('MentionBreakdownCard', 'breakpoints').lg.minH,
  //       x: 0,
  //       y: 8,
  //     },
  //     md: {
  //       w: loadComponent('MentionBreakdownCard', 'breakpoints').md.w,
  //       h: loadComponent('MentionBreakdownCard', 'breakpoints').md.h,
  //       minW: loadComponent('MentionBreakdownCard', 'breakpoints').md.minW,
  //       minH: loadComponent('MentionBreakdownCard', 'breakpoints').md.minH,
  //       x: 0,
  //       y: 8,
  //     },
  //     sm: {
  //       w: loadComponent('MentionBreakdownCard', 'breakpoints').sm.w,
  //       h: loadComponent('MentionBreakdownCard', 'breakpoints').sm.h,
  //       minW: loadComponent('MentionBreakdownCard', 'breakpoints').sm.minW,
  //       minH: loadComponent('MentionBreakdownCard', 'breakpoints').sm.minH,
  //       x: 0,
  //       y: 8,
  //     },
  //     xs: {
  //       w: loadComponent('MentionBreakdownCard', 'breakpoints').xs.w,
  //       h: loadComponent('MentionBreakdownCard', 'breakpoints').xs.h,
  //       minW: loadComponent('MentionBreakdownCard', 'breakpoints').xs.minW,
  //       minH: loadComponent('MentionBreakdownCard', 'breakpoints').xs.minH,
  //       x: 0,
  //       y: 16,
  //     },
  //   },
  // },
  {
    i: 'MentionPreviewPerEmotions',
    component: 'MentionPreviewPerEmotions',
    data: {
      title: 'Mention preview per emotions',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.minH,
        x: 0,
        y: 14,
      },
      lg: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.minH,
        x: 0,
        y: 14,
      },
      md: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.minH,
        x: 0,
        y: 14,
      },
      sm: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.minH,
        x: 0,
        y: 14,
      },
      xs: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.minH,
        x: 0,
        y: 24,
      },
    },
  },
];
