import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import QTabs from '_Components/Panels/QTabs/QTabs';
import QTab from '_Components/Panels/QTabs/tab/QTab';
import { grayShades, white } from 'styles/abstracts/colors';

const StyledQTabs = styled(QTabs)`
  border-bottom: 2px solid ${grayShades.g300};
  padding: 0;
  background-color: ${white};
`;

const StyledTab = styled(QTab)`
  padding: 1em 2em;
`;

const ProjectDetailsCardHeader = ({ activeTab, setActiveTab, t, currentPlan, data }) => {
  const PROJECT_DETAILS_TABS = [
    { id: 'global-information', label: 'Global information' },
    currentPlan !== 'emotion_corporate' && !data?.demo && { id: 'sources', label: 'Sources' },
    currentPlan !== 'emotion_corporate' && data?.automation !== null && !data?.demo && { id: 'automation', label: 'Automation' },
    { id: 'processing-history', label: 'Processing history' },
  ];
  return (
    <div>
      <StyledQTabs
        initialPage={activeTab}
        onTabChanged={tabId => {window.localStorage.setItem('projectDetailsTab', JSON.stringify({slug: data?.slug, tabId})); setActiveTab(tabId)}}
        tabDirection="horizontal"
      >
        {PROJECT_DETAILS_TABS.filter(tab => tab.id).map(tab => (
          <StyledTab id={tab.id}>{t(tab.label)}</StyledTab>
        ))}
      </StyledQTabs>
    </div>
  );
};

const mapStateToProps = state => ({
  currentPlan: get(state, ['auth', 'user', 'account', 'planName']),
})

export default connect(mapStateToProps)(ProjectDetailsCardHeader);
