import gql from 'graphql-tag';

const mutation = gql`
  mutation deleteReviewshakeSources($id: [Int!]!, $projectId: Int!) {
    deleteReviewshakeSources(id: $id, projectId: $projectId) {
      errors
      id
      projectId
    }
  }
`;

export default mutation;
