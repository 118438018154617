import React from 'react';
import { withTranslation } from 'react-i18next';
import { EMAILS } from 'constants/index';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import QButton from '../../Buttons/QButton/QButton';

const ContactButton = styled(QButton)`
  margin: 1rem;
`;

const ContactButtonText = styled.span`
  margin-left: 0.5rem;
`;

const ContactButtonPlaceholder = ({ t }) => (
  <ContactButton
    icon="INTERFACE_EMAIL"
    iconPosition="left"
    bgColor="lightBlue"
    href={`mailto:${EMAILS.SUPPORT}`}
  >
    <ContactButtonText>{t('CONTACT_TECHNICAL_TEAM')}</ContactButtonText>
  </ContactButton>
);

ContactButtonPlaceholder.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('placeholderPages')(ContactButtonPlaceholder);
