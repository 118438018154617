/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QInput from '../../../../../../../../../_Components/Fields/QInput/QInput';
import QModalBody from '../../../../../../../../../_Components/Modals/QModalBody/QModalBody';
import QModalSection from '../../../../../../../../../_Components/Modals/QModalSection/QModalSection';
import QTitle from '../../../../../../../../../_Components/Texts/QTitle/QTitle';

const StyledQInput = styled(QInput)`
  width: 100%;
`;

const Hint = styled.span`
  display: inline-block;
  font-size: 0.9em;
  padding: 0.5em 0;
  color: #928fb0;
`;

const TextArea = styled.textarea`
  border: none;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  color: #280d60;
  background-color: #edf0f7;
  resize: none;
`;

class Results extends Component {
  static propTypes = {
    onDescriptionChanged: PropTypes.func.isRequired,
    onNameChanged: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  nameMaxLength = 50;

  onNameChange = ({ target: { value } }) => this.props.onNameChanged(value);

  onDescriptionChange = ({ target: { value } }) =>
    this.props.onDescriptionChanged(value);

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <QModalSection>
          <QTitle>
            <QTitle.Title>{t('Name your insight')}</QTitle.Title>
          </QTitle>
        </QModalSection>
        <QModalSection>
          <QModalBody>
            <StyledQInput
              primary
              maxLength={this.nameMaxLength}
              onChange={this.onNameChange}
            />
            <Hint>
              {this.nameMaxLength} {t('characters max.')}
            </Hint>
          </QModalBody>
        </QModalSection>
        <QModalSection>
          <QTitle>
            <QTitle.Title>{t('Describe your insight')}</QTitle.Title>
          </QTitle>
        </QModalSection>
        <QModalSection>
          <QModalBody>
            <TextArea rows={5} onChange={this.onDescriptionChange} />
          </QModalBody>
        </QModalSection>
      </Fragment>
    );
  }
}

export default withTranslation('insights')(Results);
