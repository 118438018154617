import gql from 'graphql-tag';

const query = gql`
  query resetPasswordTokenValidation($token: String!) {
    resetPasswordTokenValidation(token: $token) {
      code
      message
    }
  }
`;

export default query;
