export const GLOBAL_STEPS = [
  { id: 'pre-setup', label: 'Pre-setup' },
  { id: 'source-setup', label: 'Source setup' },
  { id: 'project-setup', label: 'Project setup' },
  { id: 'automation', label: 'Automation' },
  { id: 'processing', label: 'Processing' },
];

export const CARD_STEPS = {
  INDUSTRIES: 'industries',
  USE_CASES: 'use-cases',
  SOURCES_LIST: 'sources-list',
  SOURCES_CONFIGURATION: 'sources-configuration',
  DATE_CONFIGURATION: 'date-configuration',
  DATA_CONFIGURATION: 'data-configuration',
  AUTOMATION: 'automation',
  VALIDATION: 'validation',
  PROJECT_NAMING: 'project-naming',
  PROCESSING: 'processing',
};

export const availableLanguages = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'Français' },
];

export const weekDays = [
  { id: 'sunday', label: 'Sunday' },
  { id: 'monday', label: 'Monday' },
  { id: 'tuesday', label: 'Tuesday' },
  { id: 'wednesday', label: 'Wednesday' },
  { id: 'thursday', label: 'Thursday' },
  { id: 'friday', label: 'Friday' },
  { id: 'saturday', label: 'Saturday' },
];

export const monthDays = [
  { id: 1, label: 'the 1st' },
  { id: 2, label: 'the 2nd' },
  { id: 3, label: 'the 3rd' },
  { id: 4, label: 'the 4th' },
  { id: 5, label: 'the 5th' },
  { id: 6, label: 'the 6th' },
  { id: 7, label: 'the 7th' },
  { id: 8, label: 'the 8th' },
  { id: 9, label: 'the 9th' },
  { id: 10, label: 'the 10th' },
  { id: 11, label: 'the 11th' },
  { id: 12, label: 'the 12th' },
  { id: 13, label: 'the 13th' },
  { id: 14, label: 'the 14th' },
  { id: 15, label: 'the 15th' },
  { id: 16, label: 'the 16th' },
  { id: 17, label: 'the 17th' },
  { id: 18, label: 'the 18th' },
  { id: 19, label: 'the 19th' },
  { id: 20, label: 'the 20th' },
  { id: 21, label: 'the 21st' },
  { id: 22, label: 'the 22nd' },
  { id: 23, label: 'the 23rd' },
  { id: 24, label: 'the 24th' },
  { id: 25, label: 'the 25th' },
  { id: 26, label: 'the 26th' },
  { id: 27, label: 'the 27th' },
  { id: 28, label: 'the 28th' },
  { id: 29, label: 'the 29th' },
  { id: 30, label: 'the 30th' },
  { id: 31, label: 'the 31st' },
];

export const automationEveryChoices = isEmotionPro => {
  return [
    { id: 'week', label: 'Week' },
    !isEmotionPro && { id: 'month', label: 'Month' },
  ];
};

export const automationAtChoices = [...Array(24).keys()].map(item => ({
  id: item,
  label: `${item === 0 ? '12' : item > 12 ? item - 12 : item} ${
    item < 12 ? 'AM' : 'PM'
  }`,
}));
