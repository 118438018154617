import gql from 'graphql-tag';

const mutation = gql`
  mutation closeUserTour($id: Int!) {
    closeUserTour(id: $id) {
      archived
      errors
      id
      name
    }
  }
`;

export default mutation;
