import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { getGraphOption } from './utils';

const ReactEcharts =
  typeof document !== 'undefined' && require('echarts-for-react').default;

type DataByThematicsGraphProps = {
  data: any[];
  indicators: any;
  indicatorsConfig: any;
  eindexUnitFahrenheit: boolean;
  filterKeys: any;
  setSideCardTitle: any;
  setMentions: any;
  setCardFilters: any;
  toggleDisplaySideCard: any;
  t: any;
};

const DataByThematicsGraph = ({
  data,
  indicators,
  indicatorsConfig,
  eindexUnitFahrenheit,
  filterKeys,
  setSideCardTitle,
  setMentions,
  setCardFilters,
  toggleDisplaySideCard,
  t,
}: DataByThematicsGraphProps) => {
  const onDataclick = graphData => {
    const cardFilters = {
      ...filterKeys,
      thematics: [...filterKeys.thematics, graphData.data.thematic],
    };
    setCardFilters(cardFilters);
    setSideCardTitle(graphData.name || graphData.value);
    setMentions(graphData.data.mentionCount);
    toggleDisplaySideCard();
  };

  const onEvents = () => {
    return {
      click: onDataclick,
    };
  };

  const options = getGraphOption(
    data,
    indicators,
    indicatorsConfig,
    eindexUnitFahrenheit,
    t,
  );
  return (
    <div style={{ height: '100%', width: '100%', margin: 0 }}>
      {ReactEcharts ? (
        <ReactEcharts
          option={options}
          style={{ width: '100%', height: ' calc(100% - 4em)' }}
          className="react_for_echarts"
          theme="QemotionTheme"
          onEvents={onEvents()}
        />
      ) : (
        ' '
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  eindexUnitFahrenheit: get(state, [
    'projectConfiguration',
    'unitsAndLocalesSettings',
    'eindexUnitFahrenheit',
  ]),
  filterKeys: get(state, ['filters', 'filterKeys']),
});

export default connect(mapStateToProps)(
  withTranslation('card', ['button'])(DataByThematicsGraph),
);
