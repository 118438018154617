import React from 'react';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import SyntheticViewGeneric from 'Routes/_Containers/SyntheticViewGeneric/SyntheticViewGeneric';
import Layout from '_Layouts/Layout';

const title = 'Synthetic view - Altrers';
const body = (
  <SyntheticViewGeneric idpage="alerts-synthetic-view" thematic="alerts" />
);

const action = props => (
  <QLoginRedirect>
    <Layout body={body} title={title} {...props} />
  </QLoginRedirect>
);

export default action;
