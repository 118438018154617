import gql from 'graphql-tag';

const mutation = gql`
  mutation saveUserSettings(
    $firstName: String
    $lastName: String
    $lang: String
    $verbatimsLocale: VerbatimsLocale
    $isEindexUnitFahrenheit: Boolean
    $phoneNumber: String
    $analysisAxis: AnalysisAxis
  ) {
    saveUserSettings(
      firstName: $firstName
      lastName: $lastName
      lang: $lang
      verbatimsLocale: $verbatimsLocale
      isEindexUnitFahrenheit: $isEindexUnitFahrenheit
      phoneNumber: $phoneNumber
      analysisAxis: $analysisAxis
    ) {
      currentUser {
        firstName
        lastName
        lang
        verbatimsLocale
        analysisAxis
        isEindexUnitFahrenheit
        phoneNumber
        expireAt
      }
    }
  }
`;

export default mutation;
