import React from 'react';
import { cloneDeep } from 'lodash';
import styled, { keyframes } from 'styled-components';
import { grayShades, orange, purple, white } from 'styles/abstracts/colors';
import QCard from '_Components/Qcard/Qcard';
import QButton from '_Components/Buttons/QButton/QButton';
import Icon from '_Components/Icons/Icon';
import { Mutation } from 'react-apollo';
import mutationCloseUserTour from '_Resources/Header/Services/api/graphql/closeUserTour';
import getTour from '_Resources/Header/Services/api/graphql/getTour';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: rgba(255, 100, 100, 0.9);
    transform: scale(1.6);
  }
`;

export const Beacon = styled.span`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: ${orange};
  border-radius: 50%;
  display: inline-block;
  height: 1rem;
  width: 1rem;
`;

const StyledQCard = styled(QCard)`
  width: 500px;
`;

const CloseButton = styled(QButton)`
  display: inline-flex;
  align-self: flex-start;
  padding: 0 !important;
`;

const Title = styled.h6`
  font-weight: bold;
  padding: 0.25rem 1rem 0 0.5rem;
  flex-grow: 1;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-wrap: nowrap;
`;

const Footer = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
`;

const RightButtons = styled.div`
  > * {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const Body = styled.div`
  padding: 1rem 1.5rem;
`;

export const Tooltip = (t, infos, replay, setReplay, tourID, tourSteps) => {
  const {
    continuous,
    index,
    step,
    backProps,
    closeProps,
    skipProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    size,
  } = infos;
  return (
    <div {...tooltipProps}>
      <StyledQCard>
        <Mutation
          mutation={mutationCloseUserTour}
          update={(cache, { data: { closeUserTour } }) => {
            let results = [];
            try {
              results = cloneDeep(
                cache.readQuery({
                  query: getTour,
                  variables: { id: tourID },
                }).results,
              );
            } catch (e) {
              Object.values(tourSteps).forEach(
                step =>
                  (results = [...results, { settings: JSON.stringify(step) }]),
              );
              results.pop();
            }
            // TODO : Passer variables dynamiques quand il sera possible d'enregistrer les steps vues
            cache.writeQuery({
              query: getTour,
              variables: { id: tourID },
              data: {
                results: null,
              },
            });
          }}
        >
          {closeUserTour => (
            <>
              <StyledHeader>
                <Title>{step.title === 'Review the tour' ? t(`steps:${step.title}`) : step.title}</Title>
                <QButton
                  {...skipProps}
                  icon="INTERFACE_CROSS"
                  iconSize={32}
                  bgColor="grayShades.g800"
                  outline
                  onClick={event => {
                    skipProps.onClick(event);
                    closeUserTour({
                      variables: { id: tourID },
                    });
                    setReplay(false);
                  }}
                />
              </StyledHeader>

              <Body>{step.content}</Body>
              <Footer>
                <div>
                  <QButton
                    {...skipProps}
                    outline
                    bgColor="red"
                    onClick={event => {
                      skipProps.onClick(event);
                      closeUserTour({
                        variables: { id: tourID },
                      });
                      setReplay(false);
                    }}
                  >
                    {t('button:Skip')}
                  </QButton>
                </div>
                <RightButtons>
                  {index > 0 && (
                    <QButton {...backProps} outline bgColor="grayShades.g800">
                      {t('button:Back')}
                    </QButton>
                  )}
                  {continuous && isLastStep ? (
                    <QButton
                      {...primaryProps}
                      bgColor="lightBlue"
                      onClick={event => {
                        skipProps.onClick(event);
                        closeUserTour({
                          variables: { id: tourID },
                        });
                        setReplay(false);
                      }}
                    >
                      {t('button:Last')} ({index + 1}/
                      {Object.values(tourSteps).length})
                    </QButton>
                  ) : (
                    <QButton {...primaryProps} bgColor="lightBlue">
                      {t('button:Next')} ({index + 1}/
                      {Object.values(tourSteps).length})
                    </QButton>
                  )}
                  {!continuous && (
                    <QButton {...closeProps} bgColor="lightBlue">
                      {t('button:Close')}
                    </QButton>
                  )}
                </RightButtons>
              </Footer>
            </>
          )}
        </Mutation>
      </StyledQCard>
    </div>
  );
};
