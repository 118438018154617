import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import { grayShades, opacity, lightenOrDarkenColor } from 'styles/abstracts/colors';

const ItemListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${grayShades.g800};
  border: 1px solid transparent;
  border-radius: 12px;
  transition: background-color 0.2s, border 0.2s;
  &:hover {
    border: 1px solid ${grayShades.g800};
    border-radius: 12px;
    background-color: ${lightenOrDarkenColor('#0EC3E3', 16)};
  }
  &:first-child {
    margin-top: 0.5rem;
  }

  &:last-child {
    margin-bottom: 1.5rem;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const ItemName = styled.div`
  align-text: start;
  font-weight: bold;
`;

const ItemValue = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 2.2rem;
  font-size: 0.8rem;
  font-weight: normal;
  align-items: center;
`;

type TriggerItemType = {
  item: {
    name: string;
    originalName: string;
    percentage: number;
    value: number;
  };
};

const TriggerItem = ({ item }: TriggerItemType) => {
  const { name, percentage, value } = item;

  const icon = 'TRIANGLE';

  return (
    <ItemListContainer>
      <ItemContainer>
        <ItemName>{name}</ItemName>
        <ItemValue>
          <Icon icon={icon} color={grayShades.g800} size={24} />
          {percentage}
          {' %   '}
          {value}
          {' mentions'}
        </ItemValue>
      </ItemContainer>
    </ItemListContainer>
  );
};

TriggerItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
};

TriggerItem.defaultProps = {};

export default TriggerItem;
