import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QButton from '_Components/Buttons/QButton/QButton';
import { ImageSize } from 'styles/abstracts/variables';

const StyledButton = styled(QButton)`
  border: 2px solid ${({ borderColor }) => borderColor} !important;
  color: ${({ textColor }) => textColor} !important;
  transition: background-color 0.25s !important;
  & > span {
    margin-left: 0.5rem;
  }
`;

const EditButton = ({ primaryColor, text, size, className, onClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <StyledButton
      bgColor={hover ? primaryColor : '#FFFFFF'}
      textColor={hover ? '#FFFFFF' : primaryColor}
      borderColor={primaryColor}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={className}
      size={size || 'md'}
      onClick={onClick}
      icon="INTERFACE_EDIT"
      iconPosition="left"
      iconColor={hover ? '#FFFFFF' : primaryColor}
      iconSize={ImageSize.small}
    >
      <span>{text}</span>
    </StyledButton>
  );
};

EditButton.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EditButton;
