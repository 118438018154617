import gql from 'graphql-tag';

const query = gql`
  query getProjectDetails($id: Int, $slug: String) {
    getProjectDetails(id: $id, slug: $slug) {
      automation {
        crontask
        email
      }
      eindex {
        value
      }
      eindexUnitFahrenheit
      id
      lastProcessedAt
      mentionCount
      mentionCountDetails {
        totalMentionCountWithNull
        totalSentenceCountWithNull
        totalMentionCountWithoutNull
        currentMentionCountWithoutNull
      }
      name
      period {
        startDate
        endDate
      }
      processingHistory {
        mentionCount
        mode
        processingDate
        usedCredits
      }
      projectFilters
      projectInformations
      sources {
        id
        profile
        profileName
        profileState
        source
        projectSourceItem {
          id
          imageLink
          link
          name
        }
      }
      updatedAt
      createdAt
      industryId
      useCaseId
      demo
    }
  }
`;

export default query;
