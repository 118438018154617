import gql from 'graphql-tag';

const query = gql`
  query insights($projectId: Int!) {
    insights(projectId: $projectId) {
      insightId
      insightName
      insightStatus
      description
      entryPoints {
        current {
          mentions
          eindex
          sat
          reco
          ces
          emotions {
            happiness
            surprise
            calm
            fear
            sadness
            anger
            disgust
          }
        }
        previousPeriod {
          mentions
          eindex
          sat
          reco
          emotions {
            happiness
            surprise
            calm
            fear
            sadness
            anger
            disgust
          }
        }
        previousYear {
          mentions
          eindex
          sat
          reco
          emotions {
            happiness
            surprise
            calm
            fear
            sadness
            anger
            disgust
          }
        }
      }
      modules
      editedOn
      editedBy
      createdOn
      createdBy
    }
  }
`;

export default query;
