import { map, forEach } from 'ramda';

export class GoogleListenerHandler {
  googleListeners = [];

  constructor(mvcObject) {
    this.mvcObject = mvcObject;
  }

  resetListeners(listeners) {
    this._clearListeners();
    this._addListeners(listeners);
  }

  removeListeners() {
    this._clearListeners();
    this._flushClearingFunctions();
  }

  _addListeners(listeners) {
    const addListener = prepareAddListener(listeners, this.mvcObject);
    const eventNames = Object.keys(listeners);
    this.googleListeners = map(addListener, eventNames);
  }

  _clearListeners() {
    forEach(clearGoogleListener, this.googleListeners);
  }

  _flushClearingFunctions() {
    this.googleListeners = [];
  }
}

function clearGoogleListener(googleListener) {
  googleListener.remove();
}

function prepareAddListener(listeners, mvcObject) {
  return function addListener(eventName) {
    const eventListener = listeners[eventName];
    return mvcObject && mvcObject.addListener(eventName, eventListener);
  };
}
