import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { loadStripe } from '@stripe/stripe-js';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QInput from '_Components/Fields/QInput/QInput';
import QButton from '_Components/Buttons/QButton/QButton';
import { onTheGoPlan } from '../../plans';
import CREATE_CHECKOUT_MUTATION from '../../graphql/stripeCreateCheckoutSession';

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const AddOrRemoveButton = styled(QButton)`
  padding: 0.375rem 0.75rem !important;
  margin: 0 0.5em;
`;

const InputContainer = styled.div`
  margin: 1em 0;
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Checkout = ({
  setModalFooter,
  setItemPriceId,
  itemPriceId,
  addMoreCredits,
  toggleAddMoreCredits,
  t,
  setEnterAddress,
  setNameId,
  hasAddress,
  nbPack,
  setNbPack,
}) => {
  const [stripeCreateCheckoutSession] = useMutation(CREATE_CHECKOUT_MUTATION);

  const handleClick = async () => {
    const stripe = await stripePromise;
    if (hasAddress) {
      stripeCreateCheckoutSession({
        variables: {
          itemQuantity: nbPack,
          itemPrice: itemPriceId,
          paymentType: 'PAYMENT',
        },
      }).then(({ data }) => {
        return stripe
          .redirectToCheckout({
            sessionId: data?.stripeCreateCheckoutSession?.sessionId,
          })
          .then(result => {
            if (result.error) {
              console.error(
                'Plan l.93 result.error.message',
                result.error.message,
              );
            }
          });
      });
    } else {
      setEnterAddress(true);
      setNameId(onTheGoPlan.nameId);
    }
  };

  const Footer = ({ setItemPriceId }) => (
    <FooterContainer>
      <QButton
        onClick={() => {
          setItemPriceId(null);
          toggleAddMoreCredits && toggleAddMoreCredits(false);
        }}
      >
        {t('button:Return')}
      </QButton>
      <QButton
        onClick={() => handleClick()}
        disabled={nbPack < 1 || Number.isNaN(nbPack)}
      >
        {hasAddress ? t('button:Pay') : t('button:Next')}
      </QButton>
    </FooterContainer>
  );

  useEffect(() => {
    if (nbPack < 1) {
      setNbPack(1);
    }
    setModalFooter(<Footer setItemPriceId={setItemPriceId} />);
  }, [nbPack]);

  return (
    <div>
      {!addMoreCredits && (
        <QTitle style={{ textAlign: 'center' }}>
          {t('You have chosen')} {onTheGoPlan.name}
        </QTitle>
      )}
      <br />
      <b>{t('Please select number of packs')}</b>
      <InputContainer>
        <AddOrRemoveButton
          onClick={() => nbPack > 1 && setNbPack(nbPack - 1)}
          disabled={nbPack <= 1 || Number.isNaN(nbPack)}
        >
          -
        </AddOrRemoveButton>
        <QInput
          value={parseInt(nbPack, 10)}
          type="number"
          onChange={e => setNbPack(parseInt(e.target.value, 10))}
        />
        <AddOrRemoveButton onClick={() => setNbPack((nbPack || 0) + 1)}>
          +
        </AddOrRemoveButton>
      </InputContainer>
      <p>1 pack = 100 verbatim = 90€</p>
    </div>
  );
};

export default Checkout;
