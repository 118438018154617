/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const QSelectOption = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const QSelectOptions = PropTypes.arrayOf(QSelectOption);

export const QSelectValue = PropTypes.oneOf([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);

export const QSelectConfig = PropTypes.shape({
  name: PropTypes.string,
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  rtl: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  removeSelected: PropTypes.bool,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  simpleValue: PropTypes.bool,
});

export const QSelectProps = {
  onChange: PropTypes.func.isRequired,
  options: QSelectOptions.isRequired,
  value: QSelectValue.isRequired,
  disabled: PropTypes.bool,
  config: QSelectConfig,
};
