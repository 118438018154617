import gql from 'graphql-tag';

const query = gql`
  query getWordCloudCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $dataCollectorId: Int
  ) {
    getWordCloudCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dataCollectorId: $dataCollectorId
    ) {
      wordcloud {
        name
        mentionCount
        value
        contextSentences
      }
    }
  }
`;

export default query;
