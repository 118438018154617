import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { omit } from 'lodash';
import { radius } from '../../../styles/abstracts/variables';
import { grayShades, lightBlue } from '../../../styles/abstracts/colors';
import { hex2rgbaConvert } from '../../../_Utils/styles/colors';
import { getSectionId } from './sectionId';
import editSections from './sections/edit';
import previewSections from './sections/preview';
import mergeDeep from '_Utils/other/mergeDeep';
import QFilterKeysProvider from '../../../_Providers/QFilterKeysProvider/QFilterKeysProvider';

const Area = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${({ active }) => (active ? '20px' : '0')};
`;

const Sections = styled.div`
  display: grid;
  grid-gap: 0.5em;
  ${({ cssGridLayout }) => cssGridLayout};
  width: 100%;
  height: 100%;
`;

const getBorderColor = (isEmpty, selected) =>
  `2px ${isEmpty ? 'dashed' : 'solid'} ${
    selected ? lightBlue : grayShades.g400
  }`;

const Section = styled.div`
  background-color: ${({ active }) => (!active ? grayShades.g400 : 'none')};
  grid-area: ${({ section }) => section};
  width: 100%;
  ${({ preview }) => !preview && `cursor: pointer;`};
  overflow: hidden;
  border-radius: ${({ active }) => active && radius.default};
  background-color: none;
  border: ${({ isEmpty, selected }) =>
    !isEmpty && !selected
      ? `2px solid transparent`
      : getBorderColor(isEmpty, selected)};

  ${({ isEmpty, selected, active, preview }) =>
    !selected &&
    !preview &&
    active &&
    `
    &:hover {
      background-color: ${hex2rgbaConvert(lightBlue, 10)};
      border: ${getBorderColor(isEmpty, true)};
      transition: all 1s;
    }
  `}

  ${({ selected }) => selected && `background-color: white;`};
`;

const handleSectionOver = (onOver, index) => onOver && (() => onOver(index));

export const LayoutPropType = PropTypes.arrayOf(
  PropTypes.shape({ name: PropTypes.string }),
);

class QLayout extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    projectId: PropTypes.number,
    preview: PropTypes.bool,
    active: PropTypes.bool,
    selectedIndex: PropTypes.number,
    cssGridLayout: PropTypes.string,
    layout: LayoutPropType,
    onSectionOver: PropTypes.func,
    className: PropTypes.string,
    config: PropTypes.any,
  };

  static defaultProps = {
    id: null,
    projectId: null,
    active: true,
    preview: false,
    selectedIndex: null,
    config: null,
    cssGridLayout: `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "section1 section2"
  `,
    layout: [[{ name: 'section1' }, { name: 'section2' }]],
    onSectionOver: null,
    className: '',
  };

  getSectionComponent(section, filterKeys) {
    if (filterKeys) {
      filterKeys.thematics = filterKeys.thematics.map(thematic => {
        if (thematic.excluded) thematic.joinSpecify = 'not';
        return omit(thematic, 'excluded');
      })
    }

    const { id, preview } = this.props;
    const { name, content } = section;
    const sectionsCollection = preview ? previewSections : editSections;
    return content
      ? sectionsCollection[content].factory(
        getSectionId(id, name),
        section,
        filterKeys,
      )
      : null;
  }

  renderActiveSection = section => {
    const {
      config: { pageConfig, projectId },
    } = this.props;
    const sectionConfig = mergeDeep(section, {
      dateRange: pageConfig.dateRange,
      filters: pageConfig.filters,
    });
    return (
      <QFilterKeysProvider
        filters={sectionConfig.filters}
        dateRange={sectionConfig.dateRange}
        projectId={projectId}
        render={filterKeys =>
          this.getSectionComponent(sectionConfig, filterKeys)
        }
      />
    );
  };

  render() {
    const {
      onSectionOver,
      layout,
      cssGridLayout,
      active,
      selectedIndex,
      className,
      preview,
    } = this.props;
    return (
      <Area active={active} className={className}>
        <Sections cssGridLayout={cssGridLayout}>
          {layout.map((section, index) => {
            const { name, content } = section;
            return (
              <Section
                active={active}
                selected={index === selectedIndex}
                section={name}
                isEmpty={!content}
                preview={preview}
                onMouseEnter={handleSectionOver(onSectionOver, index)}
                onMouseLeave={handleSectionOver(onSectionOver, null)}
              >
                {active && content
                  ? this.renderActiveSection(section)
                  : this.getSectionComponent(section)}
              </Section>
            );
          })}
        </Sections>
      </Area>
    );
  }
}

export default QLayout;
