export const MOVE_ITEM_SUCCESS = 'MOVE_ALERTING_ITEM_SUCCESS';
export const MOVE_ITEM_FAILURE = 'MOVE_ALERTING_ITEM_FAILURE';

export const MOVE_ITEM = 'MOVE_ALERTING_ITEM';

const moveItemSuccess = ({ sourceId, destinationId, itemId, index }) => ({
  type: MOVE_ITEM_SUCCESS,
  sourceId,
  destinationId,
  itemId,
  index,
});

const moveItemFailure = error => ({
  type: MOVE_ITEM_FAILURE,
  error,
});

export const moveItem = moveCriteria => moveItemSuccess(moveCriteria);
