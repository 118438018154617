import gql from 'graphql-tag';

const mutation = gql`
  mutation deleteSentencesInsight(
    $projectId: Int!
    $insightId: Int!
    $sentenceIds: [Int!]!
  ) {
    deleteSentencesInsight(
      projectId: $projectId
      insightId: $insightId
      sentenceIds: $sentenceIds
    ) {
      insight {
        insightId
        insightName
        insightStatus
        description
        entryPoints {
          current {
            mentions
            eindex
            sat
            reco
            ces
            emotions {
              happiness
              surprise
              calm
              fear
              sadness
              anger
              disgust
            }
          }
          previousPeriod {
            mentions
            eindex
            sat
            reco
            emotions {
              happiness
              surprise
              calm
              fear
              sadness
              anger
              disgust
            }
          }
          previousYear {
            mentions
            eindex
            sat
            reco
            emotions {
              happiness
              surprise
              calm
              fear
              sadness
              anger
              disgust
            }
          }
        }
        modules
        editedOn
        editedBy
        createdOn
        createdBy
        selectedVerbatim {
          id
          createdOn
          fullText
          sentences {
            sentence
            order
            id
            relevant
            results {
              mentions
              eindex
              sat
              reco
              sensitivity
              strongestEmotion
              emotions {
                happiness
                surprise
                calm
                fear
                sadness
                anger
                disgust
              }
            }
          }
        }
      }
    }
  }
`;

export default mutation;
