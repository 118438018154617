import React from 'react';
import PropTypes from 'prop-types';
import QDroppable from './QDroppable';

const QDroppableBox = ({ id, render, dropDisabled, className }) => (
  <QDroppable
    id={id}
    dropDisabled={dropDisabled}
    render={(provided, snapshot) => (
      <div
        className={className}
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {render(provided, snapshot)}
      </div>
    )}
  />
);

QDroppableBox.propTypes = {
  id: PropTypes.string.isRequired,
  dropDisabled: PropTypes.bool,
  className: PropTypes.string,

  render: PropTypes.func.isRequired,
};

QDroppableBox.defaultProps = {
  dropDisabled: false,
  className: null,
};

export default QDroppableBox;
