import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ThematicsHorizontalBenchmarkGraph from '_Charts/PoleCharts/CBarGraphChart/ThematicsHorizontalBenchmarkGraph';
import {
  HORIZONTAL_INDICATORS_CONFIG,
  ORDER_BY_ITEMS,
} from '_Cards/ThematicsCard/ThematicsOverviewCards/ThematicsHorizontalChartCard/utils';
import CQuery from '_Container/QQuery/CQuery';
import GET_THEMATICS_OVERVIEW_DATA from '_Cards/ThematicsCard/ThematicsOverviewCards/graphql/getThematicsOverviewData';
import styled from 'styled-components';
import { INDICATORS_TYPE } from '_Cards/FilterCards/DataByFiltersCard/FiltersSettings';
import { orderBy, get } from 'lodash';
import { connect } from 'react-redux';
import { setHorizontalCategories } from '_Resources/Benchmark/Actions/setHorizontalCategories';
import { setHorizontalGraphLegend } from '_Resources/Benchmark/Actions/setHorizontalGraphLegend';

const GraphContainer = styled.div`
  height: 35em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface Evolution {
  yearBefore: null;
  periodBefore: null;
}

export interface Metrics {
  byMentionCount: number;
  byPercentage: number;
}
export interface Emotions {
  anger: Metrics;
  calm: Metrics;
  disgust: Metrics;
  fear: Metrics;
  happiness: Metrics;
  sadness: Metrics;
  surprise: Metrics;
}

export interface ThematicsOverviewDetails {
  eindex: number;
  eindexEvolution: Evolution;
  mentionEvolution: Evolution;
  emotionalIntensity: number;
  emotions: Emotions;
  isSelected: boolean;
  mentionCount: number;
  name: string;
  speechEngagement: number;
}

export interface SubSubThematic {
  thematicsOverviewDetails: ThematicsOverviewDetails;
}
export interface SubThematic {
  subSubThematics: SubSubThematic[];
  thematicsOverviewDetails: ThematicsOverviewDetails;
}
export interface Thematic {
  subThematics: SubThematic[];
  subSubThematics: SubSubThematic[];
  thematicsOverviewDetails: ThematicsOverviewDetails;
  thematic: string;
  subThematic: string;
}
export interface GetThematicsOverviewData {
  verbatimsWithoutThematicCount: number;
  thematics: Thematic[];
}

const arrangeData = (
  thematicsOverviewData: GetThematicsOverviewData,
  setOrderedData: {
    (value: React.SetStateAction<never[]>): void;
    (
      arg0: {
        hierarchy: {
          thematic: string;
          subThematic: string | null;
          subSubThematic: string | null;
        };
        level: string;
        eindex: number;
        eindexEvolution: Evolution;
        mentionEvolution: Evolution;
        emotionalIntensity: number;
        emotions: Emotions;
        isSelected: boolean;
        mentionCount: number;
        name: string;
        speechEngagement: number;
      }[],
    ): void;
  },
  defaultSettings: { selectedLevel: string; orderBy: any; indicators: any },
  orderByItems:
    | {
        id: string;
        label: string;
        subcategories: { label: string; id: string }[];
      }[]
    | { subcategories: { id: { split: (arg0: string) => [any, any] } }[] }[],
) => {
  if (!thematicsOverviewData.thematics) return null;

  const flatData = thematicsOverviewData.thematics
    .map((thematic: Thematic) => thematic)
    .concat(
      thematicsOverviewData.thematics.reduce((acc: any, thematic: Thematic) => {
        return acc.concat(
          thematic.subThematics.map((subThematic: SubThematic) => ({
            ...subThematic,
            thematic: thematic.thematicsOverviewDetails.name,
          })),
        );
      }, []),
    )
    .concat(
      thematicsOverviewData.thematics.reduce((acc: any, thematic: Thematic) => {
        thematic.subThematics.forEach((subThematic: SubThematic) =>
          subThematic.subSubThematics.forEach((subSubThematic: any) => {
            acc = acc.concat({
              ...subSubThematic,
              thematic: thematic.thematicsOverviewDetails.name,
              subThematic: subThematic.thematicsOverviewDetails.name,
            });
          }),
        );
        return acc;
      }, []),
    )
    .filter(
      (thematic: { thematicsOverviewDetails: { isSelected: any } }) =>
        thematic.thematicsOverviewDetails && thematic.thematicsOverviewDetails.isSelected,
    );

  const richData = flatData
    .map(item => ({
      ...item.thematicsOverviewDetails,
      hierarchy: {
        thematic: item.thematic || item.thematicsOverviewDetails.name,
        subThematic: item.subThematic || (item.subSubThematics ? item.thematicsOverviewDetails.name : null),
        subSubThematic: item.thematic && item.subThematic ? item.thematicsOverviewDetails.name : null,
      },
      level: item.subThematics ? 'thematics' : item.subSubThematics ? 'subThematics' : 'subSubThematics',
    }))
    .filter(item => item.mentionCount > 0 && item.eindex !== null);

  const richFilteredData = defaultSettings
    ? richData.filter(item => item.level === defaultSettings.selectedLevel)
    : richData;

  const [splitIndex, splitOrder] =
    defaultSettings && defaultSettings.orderBy && defaultSettings.orderBy.id
      ? defaultSettings.orderBy.id.split('_')
      : orderByItems[1].subcategories[0].id.split('_');

  const getOrderedDataNotNested = () => {
    if (splitIndex === 'hierarchy') {
      if (splitOrder === 'asc') {
        return richFilteredData;
      }
      return [...richFilteredData].reverse();
    }
    return orderBy(
      richFilteredData,
      item => {
        if (item[splitIndex]) {
          const currentItem =
            (defaultSettings.indicators[INDICATORS_TYPE.BAR].id &&
              defaultSettings.indicators[INDICATORS_TYPE.BAR].id.split('.')[1]) ||
            (defaultSettings.indicators[INDICATORS_TYPE.BADGE].id &&
              defaultSettings.indicators[INDICATORS_TYPE.BADGE].id.split('.')[1]);
          if (item[splitIndex][currentItem]) {
            return item[splitIndex][currentItem].delta;
          }
          if (item[splitIndex][currentItem] === null) {
            return '';
          }
          return item[splitIndex] || 0;
        }
        const indicatorId =
          defaultSettings &&
          (defaultSettings.indicators[INDICATORS_TYPE.BAR].selected?.find((item: string | any[]) =>
            item?.includes(splitIndex),
          ) ||
            defaultSettings.indicators[INDICATORS_TYPE.BADGE].selected?.find((item: string | any[]) =>
              item?.includes(splitIndex),
            ));
        if (indicatorId) {
          const [id, subId, subSubId] = indicatorId.split('.');
          return item[id]?.[subId]?.[subSubId];
        }
        return item[splitIndex];
      },
      [splitOrder],
    );
  };

  const orderedDataNotNested = getOrderedDataNotNested();
  setOrderedData(orderedDataNotNested);
};

const placeholderCategory = {
  eindex: null,
  eindexEvolution: {
    yearBefore: null,
    periodBefore: null,
  },
  mentionEvolution: {
    yearBefore: null,
    periodBefore: null,
  },
  emotionalIntensity: null,
  emotions: {
    anger: {
      byMentionCount: 0,
      byPercentage: 0,
    },
    calm: {
      byMentionCount: 0,
      byPercentage: 0,
    },
    disgust: {
      byMentionCount: 0,
      byPercentage: 0,
    },
    fear: {
      byMentionCount: 0,
      byPercentage: 0,
    },
    happiness: {
      byMentionCount: 0,
      byPercentage: 0,
    },
    sadness: {
      byMentionCount: 0,
      byPercentage: 0,
    },
    surprise: {
      byMentionCount: 0,
      byPercentage: 0,
    },
  },
  mentionCount: 0,
  speechEngagement: 0,
};

const ThematicHorizontalSectionView = ({
  data: thematicsOverviewData,
  benchmarkHorizontalLevelToDisplay,
  benchmarkAspectToDisplay,
  benchmarkUnitToDisplay,
  column,
  columns,
  index,
  dispatchSetHorizontalCategories,
  dispatchSetHorizontalGraphLegend,
  benchmarkHorizontalCategories,
  benchmarkHorizontalCategoriesOrder,
}) => {
  const [orderedData, setOrderedData] = useState([]);
  const [horizontalGraph, setHorizontalGraph] = useState();

  const orderByItems = ORDER_BY_ITEMS;

  const indicators = {
    badge: {
      label: 'E-index',
      id: 'eindex',
    },
    bar: {
      label: 'Mentions',
      id: `${benchmarkAspectToDisplay}.${benchmarkUnitToDisplay}`,
      color: '#592ea0',
      selected: [],
    },
  };

  const defaultSettings = {
    selectedLevel: `${benchmarkHorizontalLevelToDisplay}`,
    orderBy: benchmarkHorizontalCategoriesOrder,
    indicators,
  };

  useEffect(() => {
    arrangeData(thematicsOverviewData, setOrderedData, defaultSettings, orderByItems);
  }, [thematicsOverviewData.thematics, benchmarkHorizontalLevelToDisplay, benchmarkHorizontalCategoriesOrder]);

  useEffect(() => {
    const graphToDisplay = (
      <ThematicsHorizontalBenchmarkGraph
        index={index}
        data={orderedData}
        indicators={indicators}
        horizontalIndicatorsConfig={HORIZONTAL_INDICATORS_CONFIG}
      />
    );
    setHorizontalGraph(graphToDisplay);
  }, [orderedData, benchmarkAspectToDisplay, benchmarkUnitToDisplay, benchmarkHorizontalLevelToDisplay]);

  useEffect(() => {
    if (index === 0 && orderedData.length > 0) {
      const horizontalCategories = orderedData.map(data => data);

      dispatchSetHorizontalCategories(horizontalCategories);
    }
  }, [orderedData]);

  useEffect(() => {
    let horizontalLegend = {};
    const captionedData: any[] = [];
    if (index === 0 && orderedData.length > 0) {
      orderedData.map((graphData, index) => {
        horizontalLegend = { ...horizontalLegend, [index + 1]: graphData.name };
      });
      dispatchSetHorizontalGraphLegend(horizontalLegend);
      orderedData.map((graphData, index) => {
        captionedData.push({ ...graphData, name: index + 1 });
      });
    }

    const graphToDisplay = (
      <ThematicsHorizontalBenchmarkGraph
        index={index}
        data={captionedData}
        indicators={indicators}
        horizontalIndicatorsConfig={HORIZONTAL_INDICATORS_CONFIG}
      />
    );
    setHorizontalGraph(graphToDisplay);
  }, [
    orderedData,
    benchmarkHorizontalCategories,
    benchmarkAspectToDisplay,
    benchmarkUnitToDisplay,
    benchmarkHorizontalLevelToDisplay,
  ]);

  useEffect(() => {
    if (
      index !== 0 &&
      orderedData.length > 0 &&
      benchmarkHorizontalCategories &&
      benchmarkHorizontalCategories.length > 0
    ) {
      const newOrderedData = orderedData.filter(graphData =>
        benchmarkHorizontalCategories.some(
          (storeData: { hierarchy: { thematic: any; subThematic: any; subSubThematic: any } }) => {
            return (
              storeData.hierarchy.thematic === graphData.hierarchy.thematic &&
              storeData.hierarchy.subThematic === graphData.hierarchy.subThematic &&
              storeData.hierarchy.subSubThematic === graphData.hierarchy.subSubThematic
            );
          },
        ),
      );

      const orderedSortedData: any[] = [];

      benchmarkHorizontalCategories.forEach(
        (
          storedData: {
            hierarchy: { thematic: any; subThematic: any; subSubThematic: any };
            isSelected: any;
            level: any;
            name: any;
          },
          index: string | number,
        ) => {
          const foundCategory = newOrderedData.find(
            graphDataItem =>
              graphDataItem.hierarchy.thematic === storedData.hierarchy.thematic &&
              graphDataItem.hierarchy.subThematic === storedData.hierarchy.subThematic &&
              graphDataItem.hierarchy.subSubThematic === storedData.hierarchy.subSubThematic,
          );
          if (foundCategory) {
            orderedSortedData[index] = foundCategory;
          } else {
            const emptyThematic = {
              ...placeholderCategory,
              hierarchy: {
                thematic: storedData.hierarchy.thematic,
                subThematic: storedData.hierarchy.subThematic,
                subSubThematic: storedData.hierarchy.subSubThematic,
              },
              isSelected: storedData.isSelected,
              level: storedData.level,
              name: storedData.name,
            };
            orderedSortedData[index] = emptyThematic;
          }
        },
      );

      const graphToDisplay = (
        <ThematicsHorizontalBenchmarkGraph
          index={index}
          data={orderedSortedData}
          indicators={indicators}
          horizontalIndicatorsConfig={HORIZONTAL_INDICATORS_CONFIG}
        />
      );
      setHorizontalGraph(graphToDisplay);
    }
  }, [
    orderedData,
    benchmarkHorizontalCategories,
    benchmarkAspectToDisplay,
    benchmarkUnitToDisplay,
    benchmarkHorizontalLevelToDisplay,
  ]);

  return <GraphContainer>{horizontalGraph}</GraphContainer>;
};

const ThematicsHorizontalSection = ({
  column,
  columns,
  index,
  filters,
  noDataComponent,
  errorComponent,
  benchmarkHorizontalLevelToDisplay,
  benchmarkAspectToDisplay,
  benchmarkUnitToDisplay,
  dispatchSetHorizontalCategories,
  dispatchSetHorizontalGraphLegend,
  benchmarkHorizontalCategories,
  benchmarkHorizontalCategoriesOrder,
}) => {
  return (
    <CQuery
      query={GET_THEMATICS_OVERVIEW_DATA}
      filterKeys={filters}
      noDataComponent={noDataComponent}
      errorComponent={errorComponent}
    >
      <ThematicHorizontalSectionView
        benchmarkHorizontalLevelToDisplay={benchmarkHorizontalLevelToDisplay}
        benchmarkAspectToDisplay={benchmarkAspectToDisplay}
        benchmarkUnitToDisplay={benchmarkUnitToDisplay}
        column={column}
        columns={columns}
        index={index}
        dispatchSetHorizontalCategories={dispatchSetHorizontalCategories}
        dispatchSetHorizontalGraphLegend={dispatchSetHorizontalGraphLegend}
        benchmarkHorizontalCategories={benchmarkHorizontalCategories}
        benchmarkHorizontalCategoriesOrder={benchmarkHorizontalCategoriesOrder}
      />
    </CQuery>
  );
};

const mapStateToProps = (state: any) => ({
  benchmarkHorizontalLevelToDisplay: get(state, ['benchmark', 'horizontalLevelToDisplay']),
  benchmarkAspectToDisplay: get(state, ['benchmark', 'aspectToDisplay']),
  benchmarkUnitToDisplay: get(state, ['benchmark', 'unitToDisplay']),
  benchmarkHorizontalCategories: get(state, ['benchmark', 'horizontalCategories']),
  benchmarkHorizontalCategoriesOrder: get(state, ['benchmark', 'horizontalCategoriesOrder']),
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetHorizontalCategories: (newState: any) => {
    dispatch(setHorizontalCategories(newState));
  },
  dispatchSetHorizontalGraphLegend: (newState: any) => {
    dispatch(setHorizontalGraphLegend(newState));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('card')(ThematicsHorizontalSection));
