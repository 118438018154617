import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QButton from '_Components/Buttons/QButton/QButton';
import { red, green, grayShades, purple } from 'styles/abstracts/colors';
import CHECK_OTP from '../../graphql/checkOTP.ts';
import { onLoginCompleted } from '../../utils.js';
import { useMutation } from 'react-apollo';
import Loader from '_Components/Loader/OnLoad';

const StyledInputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const StyledDigitInput = styled.input<{ isCodeValid: boolean; isSubmitted: boolean; loading: boolean; windowDimensions: any}>`
  width: 50px;
  height: 60px;
  padding: 0.5rem;
  display: inline-flex;
  text-align: center;
  align-items: center;
  margin: ${({ windowDimensions: { width } }) => {
    if (width < 576) {
      return '1px';
    }
    if (width < 768) {
      return '3px';
    }
    if (width < 992) {
      return '0.2rem';
    }
    return '0.5rem';
  }};
  border-radius: 5px;
  border: 2px ${({ loading }) => loading ? 'dashed' : 'solid' } ${({ isCodeValid, isSubmitted, loading }) => !isSubmitted || loading ? '#dbdbdb' : isCodeValid ? green : red};
  font-size: 28px;

  &:focus-visible {
    outline: none;
    border: 2px solid rgba(89, 46, 160, 0.44);
  }
`;

const Styled2FAFormTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;

const Styled2FAFormSubTitle = styled.p`
  margin-top: 0.7rem;
  margin-bottom: 1.5rem;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;

const Styled2FAFormLoadingSubTitleContainer = styled.div`
  display: flex;
  justify-content: center;

  & div {
    display: flex !important;
    width: auto !important;
    margin-left: initial !important;

    & div {
      margin-top: 0.7rem;
      margin-bottom: 1.5rem
    }
  }
`;

const Styled2FAFormLoadingSubTitle = styled(Styled2FAFormSubTitle)`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

const Styled2FAFormInvalidCode = styled(Styled2FAFormSubTitle)`
  color: ${red};

  & span {
    font-weight: bold;
  }
`;

const Styled2FAFormCodeOK = styled(Styled2FAFormInvalidCode)`
  color: ${green};
`;

const SubmitBtnContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SubmitBtn = styled(QButton)`
  font-size: 20px;
  padding: 0.5rem 1rem 0.4rem;
`;

const StyledBackLink = styled.button`
  border: none;
  background-color: transparent;
  color: ${grayShades.g800};
  font-weight: bold;
  font-size: 14px;

  &:focus {
    outline: none;
  }
`;

const StyledResendLink = styled(StyledBackLink)`
  font-weight: normal;
  font-size: 12px;
`;

const StyledInfoLink = styled.a`
  font-size: 11px;
  text-decoration: none;
  font-weight: normal;
  color: ${grayShades.g800};

  &:hover {
    text-decoration: none;
    color: ${grayShades.g800};
  }
`;

const TwoFactorAuthenticationContainer = styled.div<{ twoFactorAuthentication : boolean; twoFAHeight : number }>`
  position: ${({twoFactorAuthentication}) => twoFactorAuthentication ? 'absolute' : 'fixed' };
  z-index: 10;
  right: 'unset';
  left: -100%;
  opacity: ${({twoFactorAuthentication}) => twoFactorAuthentication ? '1' : '0' };
  top: ${({twoFAHeight}) => twoFAHeight+'px'};
  background-color: #fff;
  width: ${({twoFactorAuthentication}) => twoFactorAuthentication ? '100%' : '0px' };
  height: ${({twoFactorAuthentication, twoFAHeight}) => twoFactorAuthentication ? 'calc(100% - ' + twoFAHeight + 'px)' : '0px' };
  border-radius: 12px;
  transition: all 0.5s ease-in-out;
  padding: ${({twoFactorAuthentication}) => twoFactorAuthentication ? '1rem' : '0' };
  // display: ${({twoFactorAuthentication}) => twoFactorAuthentication ? 'block' : 'none' };
`;

const TwoFactorAuthenticationForm = ({ t, setTwoFactorAuthentication, twoFactorAuthentication, resendOtp, email, password, dispatchStoreUserInfos, setToken, client, history, rollbar, twoFAHeight, windowDimensions }) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const [checkOTP, { data, loading, error }] = useMutation(CHECK_OTP, {
    variables: { email, otp: code},
    onError: (error) => {
      if(error?.graphQLErrors?.[0]?.message === "Wrong code") {
        setIsCodeValid(false);
        return
      } else if (error?.networkError) {
        setNetworkError(error?.networkError);
      };
      process.env.NODE_ENV === 'production' &&
      rollbar.info(error, {
        email,
      })
    },
    onCompleted: ({otp, error}) => {
      !error && setIsCodeValid(true);
      onLoginCompleted(otp, dispatchStoreUserInfos, setToken, client, () =>
          history.push('/main', { fromLogin: true }),
        )
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const stringOTP = code.join('');
    checkOTP({variables: { email, otp: stringOTP },
    })
    setIsSubmitted(true);
    resetForm();
  };

  const handleKeyDown = (event, index) => {
    const newCode = [...code];
    if (event.key === 'Backspace') {
      event.target.value = '';
      newCode[index] = event.target.value;
      setCode(newCode);
      if (event.target.value === '' && index > 0) {
        document.getElementById(`digit-input-${index - 1}`).focus();
        document.getElementById(`digit-input-${index - 1}`).select();
      }

    } else if (event.key === 'ArrowLeft' && (index > 0)) {
      document.getElementById(`digit-input-${index - 1}`).focus();
      document.getElementById(`digit-input-${index - 1}`).select();
    } else if (event.key === 'ArrowRight' && (index < 5)) {
      document.getElementById(`digit-input-${index + 1}`).focus();
      document.getElementById(`digit-input-${index + 1}`).select();
    } else if (event.key === 'Enter' && (code.join('').length === 6)) {
      handleSubmit(event);
    } else if (event.key.match(/^[a-z0-9]$/i)) {
      newCode[index] = event.key;
      setCode(newCode);
      if (index < 5) {
        document.getElementById(`digit-input-${index + 1}`).select();
        document.getElementById(`digit-input-${index + 1}`).focus();
      }
    }
  };

  const handleOnClick = (event :any) => {
    event.target.select();
  };

  const handlePaste = (e :any) => {
    if (e.target.localName !== 'input') return;
    e.preventDefault();
    const clipboard = (e.clipboardData || window.Clipboard).getData('text').toUpperCase();
    const inputs = document.querySelectorAll('[name=input]');
    if (clipboard.length !== inputs.length) return;
    const newCode = [...code];
    inputs.forEach((input, index) => {
      input.focus();
      input.value = clipboard[index];
      newCode[index] = clipboard[index];
      setCode(newCode);
    });

  };

  const lang = window.localStorage.getItem('i18nextLng') || navigator.language || "en";

  const getInfoLink = (lang: string) => {
    if (lang === 'fr') {
      return 'https://help.cxinsights.io/portal/fr/kb/articles/authentification-double-facteur-2fa-et-code-à-usage-unique-otp';
    }
    return 'https://help.cxinsights.io/portal/fr/kb/articles/login-double-factor-authentification-and-one-time-password';
  };

  const resetForm = () => {
    const resetTimeout = setTimeout(() => {
      setIsSubmitted(false);
      setIsCodeValid(false);
      setNetworkError(false);
      clearTimeout(resetTimeout);
    }, 4000);
  };

  useEffect(() => {
    document.getElementById(`digit-input-0`).focus();
    document.getElementById(`digit-input-0`).select();
  }, [twoFactorAuthentication]);

  return (
    <TwoFactorAuthenticationContainer twoFactorAuthentication={twoFactorAuthentication} twoFAHeight={twoFAHeight} style={{ left: twoFactorAuthentication ? 'unset' : '-100%', right: twoFactorAuthentication ? '0' : 'unset' }}>
      <Styled2FAFormTitle>{t('Authentication second step')}</Styled2FAFormTitle>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="form-group">
        {!isSubmitted && (
          <Styled2FAFormSubTitle>
            {t('Enter 2FA code')}
          </Styled2FAFormSubTitle>
        )}
        {loading && (
          <Styled2FAFormLoadingSubTitleContainer>
            <Loader color={purple} />
            <Styled2FAFormLoadingSubTitle>
              {t('2FA code verification')}
            </Styled2FAFormLoadingSubTitle>
          </Styled2FAFormLoadingSubTitleContainer>
        )}
        {!isCodeValid && !networkError && isSubmitted && !loading && (
          <Styled2FAFormInvalidCode
            dangerouslySetInnerHTML={{
              __html: t('Error 2FA code'),
            }}
          />
        )}
        {networkError && isSubmitted && (
          <Styled2FAFormInvalidCode
            dangerouslySetInnerHTML={{
              __html: t('Network error', {message : networkError.message}),
            }}
          />
        )}
        {isCodeValid && !networkError && isSubmitted && (
          <Styled2FAFormCodeOK>
            {t('2FA code OK')}
          </Styled2FAFormCodeOK>
        )}
          <StyledInputContainer className="code-input">
            {code.map((value, index) => {
              return (
              <StyledDigitInput
                type="text"
                id={`digit-input-${index}`}
                name='input'
                key={index}
                autoFocus={index===0 && twoFactorAuthentication}
                value={value}
                maxLength={1}
                onKeyDown={(e: any) => handleKeyDown(e, index)}
                onClick={(e: any) => handleOnClick(e)}
                onPaste={(e: any) => handlePaste(e)}
                isCodeValid={isCodeValid && !networkError && !loading}
                isSubmitted={isSubmitted}
                loading={loading}
                windowDimensions={windowDimensions}
              />
              )
            })}
          </StyledInputContainer>
        </div>
        <SubmitBtnContainer>
          <SubmitBtn
            type="button"
            onClick={e => {
              handleSubmit(e);
            }}
            size="large"
            bgColor="purple"
            disabled={loading || code.join('').length < 6}
          >
            {t('Verify')}
          </SubmitBtn>
        </SubmitBtnContainer>
      </form>
      <div className='text-center mt-3'>
        <StyledBackLink
          onClick={() => setTwoFactorAuthentication(false)}
        >
          {t('Back to login page')}
        </StyledBackLink>
      </div>
      <div className='text-center mt-2'>
        <StyledResendLink onClick={() => resendOtp({
                variables: {
                  email,
                  password,
                },
              })}>{t('Resend code')}</StyledResendLink>
      </div>
      <div className='text-center mt-2'>
        <StyledInfoLink href={getInfoLink(lang)} target='_blank'>{t('More info about 2FA')}</StyledInfoLink>
      </div>
    </TwoFactorAuthenticationContainer>
  );
};

export default withTranslation('card')(TwoFactorAuthenticationForm);
