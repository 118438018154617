import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { sortBy, reverse } from 'lodash';
import QVerbatimSpreadSentences from '_Components/QVerbatim/_Components/VerbatimSpreadSentences';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import { QVerbatimSelectedOptions } from './QVerbatim.propTypes';

const SelectAllContainer = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0.5em;
`;

const SelectAllLabel = styled.span`
  cursor: pointer;
`;

export const SENTENCE_BUBBLE_MODE = {
  SELECT: 'select',
  DELETE: 'delete',
};

const QVerbatim = ({
  selectedSentences,
  verbatim,
  onVerbatimChange,
  onVerbatimRemoved,
  bubbleMode,
  toggleAllSelectedVerbatim,
  allSelectedVerbatim,
  updateVerbatimList,
  t,
}) => {
  function verbatimListReworked() {
    return verbatim.reduce((finalList, singleVerbatim) => {
      const relevantSentences = singleVerbatim.sentences.reduce(
        (arraySentences, sentence) => {
          if (sentence.relevant) {
            arraySentences.push({
              ...sentence,
              sentenceId: sentence.sentenceId,
              id: singleVerbatim.id,
              verbatimSentences: singleVerbatim.sentences,
            });
          }
          return arraySentences;
        },
        [],
      );
      if (relevantSentences) {
        // eslint-disable-next-line no-param-reassign
        finalList = finalList.concat(relevantSentences);
      }
      return finalList;
    }, []);
  }

  const relevantVerbatimList = reverse(
    sortBy(verbatimListReworked(), sentence =>
      sentence.results.sensitivity > 0
        ? sentence.results.sensitivity
        : sentence.results.eindex,
    ),
  );

  useEffect(() => {
    updateVerbatimList && updateVerbatimList(relevantVerbatimList);
  }, []);

  const prepareIsSentenceSelected = id => ({ order }, selectedSentences) =>
    selectedSentences.find(
      ({ id: selectedId, order: selectedOrder }) =>
        id === selectedId && order === selectedOrder,
    );

  function onSelectAllVerbatim() {
    toggleAllSelectedVerbatim();
    const arrayOfSentences = relevantVerbatimList.filter(relevantVerbatim =>
      selectedSentences.length < relevantVerbatimList.length
        ? !selectedSentences.some(
            sentence =>
              sentence.id === relevantVerbatim.id &&
              relevantVerbatim.order === sentence.order,
          )
        : selectedSentences.some(
            sentence =>
              sentence.id !== relevantVerbatim.id &&
              relevantVerbatim.order !== sentence.order,
          ),
    );
    arrayOfSentences.forEach(verbatimToSelect => {
      const { id, order, sentenceId } = verbatimToSelect;
      onVerbatimChange({
        id,
        order,
        sentenceId,
        selected: !prepareIsSentenceSelected(id)(
          verbatimToSelect,
          selectedSentences,
        ),
      });
    });
  }

  return (
    <div>
      {bubbleMode !== SENTENCE_BUBBLE_MODE.DELETE && (
        <SelectAllContainer>
          <QCheckbox
            selected={allSelectedVerbatim}
            onClick={() => onSelectAllVerbatim()}
          />
          <SelectAllLabel onClick={() => onSelectAllVerbatim()}>
            <QCheckbox.Label>
              {t('Select / Deselect all sentences')}
            </QCheckbox.Label>
          </SelectAllLabel>
        </SelectAllContainer>
      )}
      {relevantVerbatimList.map(verbatimData => (
        <>
          <QVerbatimSpreadSentences
            deletable={bubbleMode === SENTENCE_BUBBLE_MODE.DELETE}
            verbatim={verbatimData}
            selectedSentences={selectedSentences}
            onSelect={onVerbatimChange}
            onVerbatimRemoved={onVerbatimRemoved}
            allSelectedVerbatim={allSelectedVerbatim}
            prepareIsSentenceSelected={prepareIsSentenceSelected}
          />
        </>
      ))}
    </div>
  );
};

QVerbatim.propTypes = {
  onVerbatimChange: PropTypes.func.isRequired,
  onVerbatimRemoved: PropTypes.func.isRequired,
  verbatim: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSentences: QVerbatimSelectedOptions.isRequired,
  bubbleMode: PropTypes.oneOf(Object.values(SENTENCE_BUBBLE_MODE)).isRequired,
};

QVerbatim.defaultProps = {};

export default withTranslation('insights')(QVerbatim);
