import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QModal from '_Components/Modals/QModal/QModal';
import QButton from '_Components/Buttons/QButton/QButton';
import QInput from '_Components/Fields/QInput/QInput';
import { grayShades } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import deleteProjectMutation from '_Cards/SettingsCards/ProjectsCard/graphql/deleteProject';
import getProjectListCardData from 'Routes/Main/MainPage/_Components/ProjectListCard/getProjectListCardData';

const FooterContainer = styled.div`
  & button:last-child {
    margin-left: 1em;
  }
`;

const InputSubTitle = styled.span`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  font-weight: bolder;
`;

const StyledInput = styled(QInput)`
  width: 100% !important;
`;

const DeleteProjectModal = ({ project, isOpen, open, t }) => {
  const [verificationName, setVerificationName] = useState('');

  const modalFooter = (
    <FooterContainer>
      <QButton outline bgColor="grayShades.g800" onClick={() => open(!isOpen)}>
        {t('button:No, it is a mistake')}
      </QButton>
      <Mutation
        mutation={deleteProjectMutation}
        variables={{ id: project.id }}
        update={(cache, { data: { deleteProject } }) => {
          const projectList = cache.readQuery({
            query: getProjectListCardData,
            variables: { archived: true },
          }).results.projects;
          cache.writeQuery({
            query: getProjectListCardData,
            variables: { archived: true },
            data: {
              results: {
                projects: projectList.filter(
                  archivedProject => archivedProject.id !== deleteProject.id,
                ),
              },
            },
          });
        }}
      >
        {deleteProject => (
          <QButton
            bgColor="red"
            iconPosition="left"
            icon="TRASH"
            onClick={() => {
              deleteProject({
                variables: project.id,
              });
              open(!isOpen);
            }}
            disabled={verificationName !== project.name}
          >
            &nbsp;{t('button:Delete')}
          </QButton>
        )}
      </Mutation>
    </FooterContainer>
  );

  return (
    <QModal
      title={t(`Definitely delete XXX ?`, { projectName: project.name })}
      subtitle="All data will be deleted from our database"
      isOpen={isOpen}
      onClose={() => open(!isOpen)}
      footer={modalFooter}
      size="md"
      config={{mentions: false, date: false}}
    >
      <InputSubTitle>
        {t('To confirm, please enter the name of the project')}
      </InputSubTitle>
      <StyledInput
        onChange={e => setVerificationName(e.target.value)}
        onPaste={e => e.preventDefault()}
      />
    </QModal>
  );
};

export default withTranslation('card', 'button')(DeleteProjectModal);
