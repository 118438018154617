import React from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import '../../../../node_modules/react-resizable/css/styles.css';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import styled from 'styled-components';
import { getRoute } from '_Utils/routes/routeUtils';
import layoutConfig from './config';
import QResponsiveGridLayout from '../../../_Layouts/QResponsiveGridLayout/QResponsiveGridLayout';
import { getComponentsLayout } from '../../_Utils/routeComponent';

const StyledContainer = styled(Container)`
  overflow-x: hidden;
`;

class VerbatimListBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idpage: this.props.idpage,
      layout: layoutConfig,
    };
  }

  componentDidMount() {
    getRoute(this.props.updateRoute, this.state.idpage);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.idpage && nextProps.idpage !== this.props.idpage) {
      getRoute(this.props.updateRoute, nextProps.idpage);
      this.setState({
        idpage: nextProps.idpage,
      });
    }
  }

  render() {
    const props = {
      MentionPreviewPerEmotions: {
        data: {},
        lazyLoad: true,
        limitVerbatim: 100,
        offsetVerbatim: 0,
      },
    };
    const { layout } = this.state;

    const layoutComponents = getComponentsLayout(layout, props, {});
    return (
      layout && (
        <div>
          <StyledContainer fluid className="py-3 px-4">
            <QResponsiveGridLayout layout={layout} isResizable={this.props.editableLayout}>
              {layoutComponents}
            </QResponsiveGridLayout>
          </StyledContainer>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      )
    );
  }
}

VerbatimListBody.defaultProps = {
  layoutDefault: layoutConfig,
};

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
    editableLayout: state.routes.activeLayoutEdition,
    module: state.routes.route && state.routes.route.name,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerbatimListBody);
