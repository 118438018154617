const getEmptySectionIndex = sections => {
  const sectionIndex = sections.findIndex(page => !page.content);
  return sectionIndex !== -1 ? sectionIndex : 0;
};

export const updatePageCardHelper = (state, { content }) => {
  const { currentCardIndex, currentPageIndex } = state;
  const pages = state.children;
  const prevPageState = pages[currentPageIndex];
  const cardIndex =
    currentCardIndex !== null
      ? currentCardIndex
      : getEmptySectionIndex(prevPageState.children);
  const prevSection = prevPageState.children[cardIndex];
  const newSection = { ...prevSection, content };
  const newChildren = [...prevPageState.children];
  newChildren[cardIndex] = newSection;

  const newPage = {
    ...prevPageState,
    children: newChildren,
  };

  const newPages = [...pages];
  newPages[currentPageIndex] = newPage;
  return {
    ...state,
    children: newPages,
    currentCard: newChildren[cardIndex].content,
  };
};
