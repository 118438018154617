import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

const QDropzone = ({ acceptTypes, render, onDrop }) => (
  <Dropzone accept={acceptTypes} onDrop={onDrop}>
    {args => render(args)}
  </Dropzone>
);

QDropzone.propTypes = {
  acceptTypes: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
};

export default QDropzone;
