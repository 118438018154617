import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Row, Col } from 'reactstrap';
import countryList from 'react-select-country-list';
import getCountryISO3 from 'country-iso-2-to-3';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import signInMutation from 'Routes/UtilityRoutes/Login/graphql/signIn';
import QInput from '_Components/Fields/QInput/QInput';
import { grayShades, purple, white, red } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import Icon from '_Components/Icons/Icon';
import QButton from '_Components/Buttons/QButton/QButton';
import QSelect from '_Components/Fields/QSelect/QSelect';
import i18n from 'i18n/i18n';
import {
  onLoginCompleted,
  passwordDigitVerification,
  passwordLengthVerification,
  passwordUpperCaseVerification,
} from 'Routes/UtilityRoutes/Login/utils';
import VerificationMessages from 'Routes/UtilityRoutes/PasswordReset/_Components/VerificationMessages';
import { storeUserInfos } from '_Resources/Auth/Actions/auth';
import signUpMutation from 'Routes/UtilityRoutes/SignUp/graphql/signUp';
import GET_RECAPTCHA_VALIDATION_QUERY from '../../../Login/graphql/getRecaptchaValidation';

const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  /* min-width: 40em; */
  /* margin-left: -3em; */
`;

const Input = styled(QInput)`
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Label = styled.div`
  font-weight: bold;
  font-size: ${({ windowDimensions: { width } }) =>
    width > 520 ? fontSizes.smaller : fontSizes.extraSmall};
  color: ${grayShades.g800};
`;

const SubLabel = styled.div`
  font-weight: bold;
  font-size: 0.85em;
  color: ${purple};
  margin-top: 0.5em;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${grayShades.g300};
`;
const StyledRow = styled(Row)`
  margin-bottom: 1.25em;
`;

const CheckboxLabel = styled(QCheckbox.Label)`
  font-weight: normal !important;
  width: 85%;
  margin-left: ${({ windowDimensions: { width } }) => width < 521 && '3em'};
`;

const SubText = styled.div`
  font-size: ${({ small }) => small && fontSizes.smaller};
  margin-top: ${({ small }) => (small ? '-0.5em' : '0.25em')};
  margin-left: 0.5em;
  &,
  & span,
  & a,
  & a:hover {
    color: ${grayShades.g800};
    font-weight: bold;
  }

  & span,
  & a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Loader = styled(Icon)`
  animation: ${spinning} 1s linear infinite;
`;

const SpacedRow = styled(StyledRow)`
  /* padding-top: 0em !important; */
  margin-bottom: 0;
`;

const ErrorLabel = styled.span`
  color: ${red};
  font-weight: bold;
  margin-top: 1em;
  width: 100%;
  text-align: center;
`;

const CaptchaContainer = styled.div`
  margin-top: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SignUpForm = ({
  history,
  dispatchStoreUserInfos,
  setToken,
  client,
  t,
  windowDimensions,
}) => {
  const browserLanguage = window.navigator.language.substring(0, 2);
  const defaultLanguage = () => {
    const availablesLanguages = Object.keys(i18n.options.resources);
    if (availablesLanguages.includes(browserLanguage)) {
      return browserLanguage;
    }
    return 'en';
  };

  const [lang] = useState(defaultLanguage());

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isChecked, checkBox] = useState(false);
  const [errors, setErrors] = useState([]);
  const [country, setCountry] = useState(null);
  const [success, setSuccess] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [signIn, signInOptions] = useMutation(signInMutation, {
    onCompleted: ({ signIn }) =>
      onLoginCompleted(signIn, dispatchStoreUserInfos, setToken, client, () =>
        history.push('/settings', { fromLogin: true }),
      ),
  });
  const [signUp, signUpOptions] = useMutation(signUpMutation);

  const loading = signInOptions?.loading || signUpOptions?.loading;

  const signUpToPlatform = () => {
    const inputValues = {
      email,
      password,
      firstName,
      lastName,
      lang,
      companyName,
      phoneNumber,
      acceptNotifications: isChecked,
      alpha3Cc: getCountryISO3(country.value),
    };
    signUp({ variables: inputValues })
      .then(({ data: { signUp } }) => {
        if (!signUp.errors || signUp.errors.length === 0) {
          window.localStorage.setItem('openedSettings', 'projects');

          signIn({ variables: { email, password } }).catch(err =>
            console.error(err),
          );
        } else {
          setErrors(signUp.errors);
        }
      })
      .catch(err => console.error(err));
  };

  const passwordVerification =
    password.length <= 1 ||
    passwordUpperCaseVerification(password) ||
    passwordLengthVerification(password) ||
    passwordDigitVerification(password);

  const getVerifications = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    const emailVerification =
      email.length > 1 &&
      emailRegex.test(email) &&
      !email.split('@')[0].includes('+');
    const firstNameVerification = firstName.length > 1;
    const lastNameVerification = lastName.length > 1;
    const companyNameVerification = companyName.length > 1;
    const countryVerification = country !== null;
    return (
      passwordVerification ||
      !emailVerification ||
      !lastNameVerification ||
      !firstNameVerification ||
      !companyNameVerification ||
      !countryVerification ||
      !success
    );
  };

  return (
    <Container>
      <StyledRow>
        <Col xs={12} md={6}>
          <Label windowDimensions={windowDimensions} className="text-truncate">
            {t('Company email address')}
          </Label>
          <Input
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            size="lg"
          />
        </Col>
        <Col>
          <Label windowDimensions={windowDimensions}>{t('Password')}</Label>
          <Input
            type="password"
            onChange={event => setPassword(event.target.value)}
            value={password}
          />
          <SubLabel>
            {t('Minimum 8 characters, 1 uppercase, 1 number')}
          </SubLabel>
          {passwordVerification && password.length > 0 && (
            <ErrorLabel>{t('Your password is not valid.')}</ErrorLabel>
          )}
        </Col>
      </StyledRow>
      <StyledRow className="h-25 justify-content-center flex-column align-items-center">
        <Col xs={8}>
          <Separator />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={6} md={6}>
          <Label windowDimensions={windowDimensions}>{t('First name')}</Label>
          <Input
            onChange={event => setFirstName(event.target.value)}
            value={firstName}
          />
        </Col>
        <Col xs={6} md={6}>
          <Label windowDimensions={windowDimensions}>{t('Last name')}</Label>
          <Input
            onChange={event => setLastName(event.target.value)}
            value={lastName}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={6} md={6}>
          <Label windowDimensions={windowDimensions}>{t('Company name')}</Label>
          <Input
            onChange={event => setCompanyName(event.target.value)}
            value={companyName}
          />
        </Col>
        <Col xs={6} md={6}>
          <Label windowDimensions={windowDimensions}>{t('Country')}</Label>
          <QSelect
            options={countryList().getData()}
            value={country}
            onChange={value => setCountry(value)}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col xs={6} md={6}>
          <Label windowDimensions={windowDimensions}>{t('Phone number (optional)')}</Label>
          <Input
            onChange={event => setPhoneNumber(event.target.value)}
            value={phoneNumber}
            type="number"
            min="0"
            max="9999999999999"
          />
        </Col>
      </StyledRow>
      <SpacedRow className="d-flex align-items-center">
        <QCheckbox
          selected={isChecked}
          onClick={() => checkBox(!isChecked)}
          style={windowDimensions.width < 521 ? { position: 'absolute' } : {}}
        />
        <CheckboxLabel windowDimensions={windowDimensions}>
          {t(
            `I accept to be notified about any updates on my data, security notifications and newsletters by Q°emotion`,
          )}
        </CheckboxLabel>
      </SpacedRow>
      <StyledRow>
        {errors.length > 0 &&
          errors.map(error => (
            <Col xs={12} className="d-flex justify-content-center">
              <ErrorLabel>{error}</ErrorLabel>
            </Col>
          ))}
        <CaptchaContainer>
          <ReCAPTCHA
            sitekey="6Lf7qtsZAAAAAF1sO_Zdj3DJYYfhptRFL5WAWPH9"
            onChange={async value => {
              await client
                .query({
                  query: GET_RECAPTCHA_VALIDATION_QUERY,
                  variables: { token: value },
                })
                .then(res =>
                  setSuccess(res.data.getRecaptchaValidation.success),
                );
            }}
          />
        </CaptchaContainer>
        <Col
          xs={12}
          className="d-flex justify-content-center text-align-center"
        >
          <QButton
            bgColor="red"
            onClick={() => signUpToPlatform()}
            disabled={getVerifications()}
            size="large"
            className="px-5 mb-1 mt-2"
          >
            {loading ? (
              <Loader icon="INTERFACE_LOADING" color={white} />
            ) : (
              t('button:Create your account')
            )}
          </QButton>
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          <SubText>
            {t('Already have an account?')}{' '}
            <span onClick={() => history.push('/login')}>
              {t('button:Sign in')}
            </span>
            !
          </SubText>
        </Col>
      </StyledRow>
      <SpacedRow>
        <SubText small>
          {t('By clicking on "Create your account", you agree to our')}{' '}
          <span>
            <a
              href={`https://qemotion.com/${
                browserLanguage === 'fr' ? browserLanguage : 'en'
              }/legalMention/termsConditions`}
            >
              {t('Terms')}
            </a>
          </span>{' '}
          {t('and you acknowledge having read our')}{' '}
          <span>
            <a
              href={`https://qemotion.com/${
                browserLanguage === 'fr' ? browserLanguage : 'en'
              }/legalMention/privacyPolicy`}
            >
              {t('Privacy Policy')}
            </a>
          </span>
        </SubText>
      </SpacedRow>
    </Container>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchStoreUserInfos: (user, token) => {
      dispatch(storeUserInfos(user, token));
    },
  };
}

export default connect(null, mapDispatchToProps)(withApollo(SignUpForm));
