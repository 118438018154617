import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { setPreviewMode } from '../../../../../../_Resources/Reporting/Actions/setPreviewMode';
import Filters from '../_Components/Filters';
import { setIterateBy } from '../../../../../../_Resources/Reporting/Actions/setIterateBy';
import { setPreviewFor } from '../../../../../../_Resources/Reporting/Actions/setPreviewFor';
import PreviousButton from '../_Components/PreviousButton';

class PreviewHeader extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,

    // state props
    iterateByOptions: PropTypes.any,
    iterateBy: PropTypes.string,
    previewFor: PropTypes.string,

    // dispatch props
    dispatchSetIterateBy: PropTypes.func.isRequired,
    dispatchSetPreviewFor: PropTypes.func.isRequired,
    dispatchDeactivatePreview: PropTypes.func.isRequired,
  };

  static defaultProps = {
    iterateBy: null,
    previewFor: null,
    iterateByOptions: [],
  };

  getSelectedIterateByOption = memoize((iterateBy, iterateByOptions) => {
    const selected = iterateByOptions.find(
      ({ option: { id } }) => id === iterateBy,
    );
    return selected && selected.option;
  });

  onSelectIterateBy = option =>
    this.props.dispatchSetIterateBy((option && option.value) || null);

  onSelectPreviewFor = option =>
    this.props.dispatchSetPreviewFor((option && option.value) || null);

  render() {
    const {
      t,
      dispatchDeactivatePreview,
      previewFor,
      iterateByOptions,
      iterateBy,
    } = this.props;
    return (
      <Fragment>
        <PreviousButton
          label={t('Preview')}
          onDeactivatePreview={dispatchDeactivatePreview}
        />
        {!isEmpty(iterateByOptions) && (
          <Filters
            iterateByOptions={iterateByOptions}
            selectedIterateByOption={this.getSelectedIterateByOption(
              iterateBy,
              iterateByOptions,
            )}
            selectedPreviewFor={previewFor}
            onSelectIterateBy={this.onSelectIterateBy}
            onSelectPreviewFor={this.onSelectPreviewFor}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchSetIterateBy: iterateBy => dispatch(setIterateBy(iterateBy)),
  dispatchSetPreviewFor: previewFor => dispatch(setPreviewFor(previewFor)),
  dispatchDeactivatePreview: () => dispatch(setPreviewMode(false)),
});

const mapStateToProps = ({
  reporting: { iterateByOptions, iterateBy, previewFor },
}) => ({
  iterateBy,
  previewFor,
  iterateByOptions,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(PreviewHeader));
