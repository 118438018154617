import { flatten, cloneDeep } from 'lodash';
import { AXIS } from './grid';

function getVector(start, end) {
  const vector = Array(end + 1 - start);
  vector.fill(start);
  return vector.map((element, index) => element + index);
}

function getPoints({ xs, ys, zs }) {
  return xs.reduce((points, currentX, index) => {
    const point = {
      x: currentX,
      y: ys[index],
      z: zs[index],
    };
    points.push(point);
    return points;
  }, []);
}

function prepareCircularGetNext(radius) {
  const hexEdges =
    radius === 0
      ? [{ x: 0, y: 0, z: 0 }]
      : flatten([
          getPoints({
            xs: Array(radius).fill(radius),
            ys: getVector(-radius, -1).reverse(),
            zs: getVector(-radius + 1, 0),
          }),
          getPoints({
            xs: getVector(0, radius - 1).reverse(),
            ys: Array(radius).fill(radius * -1),
            zs: getVector(1, radius),
          }),
          getPoints({
            xs: getVector(-radius, -1).reverse(),
            ys: getVector(-radius + 1, 0),
            zs: Array(radius).fill(radius),
          }),
          getPoints({
            xs: Array(radius).fill(radius * -1),
            ys: getVector(1, radius),
            zs: getVector(0, radius - 1).reverse(),
          }),
          getPoints({
            xs: getVector(-radius + 1, 0),
            ys: Array(radius).fill(radius),
            zs: getVector(-radius, -1).reverse(),
          }),
          getPoints({
            xs: getVector(1, radius),
            ys: getVector(0, radius - 1).reverse(),
            zs: Array(radius).fill(radius * -1),
          }),
        ]);
  let lastIndex = 0;
  return function getNext() {
    const result = hexEdges[lastIndex];
    lastIndex += 1;
    return result;
  };
}

function prepareTriangleGetNext(height) {
  const center = { x: 1, y: 1, z: -2 };
  const triangleLevel =
    height === 0
      ? [{ ...center }]
      : getPoints({
          xs: getVector(center.x - height, center.x),
          ys: getVector(center.y - height, center.y).reverse(),
          zs: Array(height + 1).fill(center.z + height),
        });
  let lastIndex = 0;
  return function getNext() {
    const result = triangleLevel[lastIndex];
    lastIndex += 1;
    return result;
  };
}

export function findModuleTilePoint(points) {
  const center = { x: 1, y: 1, z: -2 };
  return points.find(
    ({ coordinates: { [AXIS.X]: X, [AXIS.Y]: Y, [AXIS.Z]: Z } }) => {
      const { x, y, z } = center;
      return X === x && Y === y && Z === z;
    },
  );
}

export default function getPathPointForData(points, data, mode) {
  let currentRadius = mode === 'circular' ? 0 : 1;
  const prepareGetNext =
    mode === 'circular' ? prepareCircularGetNext : prepareTriangleGetNext;
  let getNextPoint = prepareGetNext(currentRadius);

  return data.map(d => {
    let nextPoint = getNextPoint(currentRadius);
    if (nextPoint === undefined) {
      currentRadius += 1;
      getNextPoint = prepareGetNext(currentRadius);
      nextPoint = getNextPoint(currentRadius);
    }
    const point = points.find(
      ({ coordinates: { [AXIS.X]: X, [AXIS.Y]: Y, [AXIS.Z]: Z } }) => {
        const { x, y, z } = nextPoint;
        return X === x && Y === y && Z === z;
      },
    );
    const resultPoint = cloneDeep(point);
    resultPoint.insightData = d;
    return resultPoint;
  });
}
