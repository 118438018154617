import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import { grayShades, lightBlue, purple } from 'styles/abstracts/colors';
import QTitle from '_Components/Texts/QTitle/QTitle';
import Icon from '_Components/Icons/Icon';

const Container = styled(Row)`
  border-top: ${({ isDetails }) => isDetails && `1px solid ${grayShades.g300}`};
  padding: ${({ isDetails }) => isDetails && '1em 0;'};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const OptionsLabel = styled.span`
  color: ${lightBlue};
  cursor: pointer;
`;

const PermissionsRow = ({
  permissions,
  detailedPermissions,
  toggleShowDetails,
  showDetails,
}) => {
  const permissionsToIterate = permissions.permissions || permissions;
  return (
    <Container isDetails={!!permissions.permissions}>
      <Col xs={3} className="d-flex align-items-center">
        {detailedPermissions && detailedPermissions.length > 0 && (
          <OptionsLabel onClick={() => toggleShowDetails(!showDetails)}>
            {showDetails ? 'Hide' : 'Show'} details
          </OptionsLabel>
        )}
        {permissions.name && <QTitle>{permissions.name}</QTitle>}
      </Col>
      <StyledCol xs={9}>
        {permissionsToIterate &&
          Object.keys(permissionsToIterate).length === 3 && <Col xs={2} />}
        {permissionsToIterate &&
          Object.keys(permissionsToIterate).length > 0 &&
          Object.keys(permissionsToIterate).map(permission => (
            <Col xs={2} className="d-flex justify-content-center">
              {permissionsToIterate[permission] && (
                <Icon
                  icon="CHECK_ALL"
                  color={
                    permissionsToIterate[permission] === 'all'
                      ? purple
                      : lightBlue
                  }
                  size={24}
                />
              )}
            </Col>
          ))}
      </StyledCol>
    </Container>
  );
};

export default PermissionsRow;
