import PropTypes from 'prop-types';

export const QVerbatimSelectedOption = PropTypes.shape({
  id: PropTypes.number,
  order: PropTypes.number,
});

export const QVerbatimSelectedOptions = PropTypes.arrayOf(
  QVerbatimSelectedOption,
);
