import React, { Component } from "react";
import { MapContext } from "./Map";

export default Item =>
  class MapItem extends Component {
    render() {
      return (
        <MapContext.Consumer>
          {({ google, map }) => {
            const options = { ...this.props.options, map };
            return <Item {...this.props} google={google} options={options} />;
          }}
        </MapContext.Consumer>
      );
    }
  };
