const ELLIPSIS = '...';
const JOINER = ' ';

export default function prepareWrapText(config) {
  const { lineLength, linesNumber } = config;
  // TODO: remove after using Typescript
  if (!lineLength) throw new Error('config.lineLength property missing');
  if (!linesNumber) throw new Error('config.linesNumber property missing');

  const prepareTextLine = (text, ptlConfig = {}) => {
    const { ellipsis } = ptlConfig;

    return text.length > lineLength || ellipsis
      ? text.substring(0, lineLength - ELLIPSIS.length) + ELLIPSIS
      : text;
  };

  const prepareStoreTextLine = lines => text =>
    lines.push(prepareTextLine(text));

  const adjustLinesNumber = lines => {
    const newLines = lines.slice(0, linesNumber);
    const newLast = prepareTextLine(newLines.pop(), { ellipsis: true });
    return [...newLines, newLast];
  };

  return function wrapText(text) {
    const words = text.split(' ');
    const lines = [];
    const storeLineText = prepareStoreTextLine(lines);

    const lastText = words.reduce((lineText, word) => {
      const newLength = lineText.length + word.length + JOINER.length;
      if (newLength < lineLength) {
        return [lineText, word].join(JOINER);
      } else if (lineText.length === 0) {
        storeLineText(word);
        return '';
      }

      storeLineText(lineText);
      if (word.length > lineText.length) {
        storeLineText(word);
        return '';
      }
      return word;
    }, '');

    if (lastText !== '') {
      storeLineText(lastText);
    }

    return (lines.length > linesNumber ? adjustLinesNumber(lines) : lines).map(
      t => t.trim(),
    );
  };
}
