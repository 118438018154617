import React, { Fragment } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import QCard from '_Components/Qcard/Qcard';
import CQuery from '_Container/QQuery/CQuery';
import 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/style.scss';
import querySchema from 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/graphql/getEindexPathCardData';
import EindexPath from 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/EindexPath';
import { resetPageSpecificFilters } from '_Resources/SpecificFiltering/Actions/resetPageSpecificFilters';

class EindexPathContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      associatedThematic: null,
      activeTabId: null,
    };
  }

  componentDidMount() {
    this.setFilterKeys(this.props.filterKeys);
    this.findAssociatedThematic(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.idpage !== prevProps.idpage ||
      this.props.filterKeys !== prevProps.filterKeys
    ) {
      this.props.resetPageSpecificAllFilters(this.props.idpage);
      this.setFilterKeys(this.props.filterKeys);
    }
    if (
      this.props.idpage !== prevProps.idpage ||
      get(this.props, ['filterKeys', 'projectId']) !==
        get(prevProps, ['filterKeys', 'projectId'])
    ) {
      this.findAssociatedThematic(this.props);
    }
  }

  findAssociatedThematic(props) {
    const defaultThematic = get(this.props, [
      'pageConfiguration',
      'defaultThematic',
    ]);
    this.setState({
      associatedThematic: defaultThematic,
    });
  }

  setFilterKeys(filterKeys) {
    if (!filterKeys) return;
    let keys = JSON.parse(JSON.stringify(filterKeys));
    const defaultThematic =
      this.props.defaultThematic ||
      get(this.props, ['pageConfiguration', 'defaultThematic']);
    if (defaultThematic) {
      keys.thematics = {
        thematic: defaultThematic,
        subThematic: '',
        subSubThematic: '',
      };
      if (keys.texts) {
        keys.texts.map(text => {
          keys = { ...keys, [text.name]: text.values };
        });
        delete keys.texts;
      }
      this.setState({
        associatedThematic: defaultThematic,
        filterPage: keys,
      });
    } else {
      this.setState({
        associatedThematic: null,
      });
    }
  }

  render() {
    return !!this.state.associatedThematic || !!this.props.defaultThematic ? (
      <QCard id="optionalHeaderBis" style={{ height: '154px' }}>
        <>
          <CQuery
            query={querySchema}
            filters={this.state.filterPage || null}
            errorComponent={
              <NoDataMessage
                secondText="Error while loading data"
                imagePlaceholder={false}
              />
            }
            noDataComponent={
              <NoDataMessage
                secondText="No data available for those filters"
                imagePlaceholder={false}
              />
            }
          >
            <EindexPath {...this.props} />
          </CQuery>
        </>
      </QCard>
    ) : null;
  }
}
function mapStateToProps(state, ownProps) {
  return {
    filterKeys: state.filters.filterKeys,
    specificFilters: state.specificFilters[ownProps.idpage] || null,
    currentProject: state.projects.currentProject,
    pageConfiguration: state.routes.pageConfiguration,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    resetPageSpecificAllFilters: newState => {
      dispatch(resetPageSpecificFilters(newState));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('text')(EindexPathContainer));
