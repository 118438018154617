import gql from 'graphql-tag';

const query = gql`
  mutation renewApiToken {
    renewApiToken {
      account {
        apiToken
      }
    }
  }
`;

export default query;
