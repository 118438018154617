import React, { useRef } from 'react';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import Layout from '_Layouts/Layout';
import LandingPage from './MainPage';
import SubHeader from './_Components/SubHeader/SubHeader';

const action = props => {
  const subHeaderRef = useRef(null);

  const title = 'Main page';
  const body = <LandingPage subHeaderRef={subHeaderRef} />;
  const subHeader = <SubHeader subHeaderRef={subHeaderRef} />;
  const tourID = 3;
  return (
    <QLoginRedirect history={props.history}>
      <Layout
        body={body}
        title={title}
        tourID={tourID}
        {...props}
        hideMentionsCount
        subHeader={subHeader}
      />
    </QLoginRedirect>
  );
};

export default action;
