import { purple } from 'styles/abstracts/colors';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter, get } from 'lodash';
import ModalNamePage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/ModalNamePage';
import { CONTEXT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import ModalFiltersPage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/ModalFiltersPage';
import { cardCollapseContent } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/configs/CollapseContents';
import EditModal from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/EditModal';
import { createTemporaryItem } from '_Resources/Benchmark/Actions/Items/createTemporaryItem';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import EditButton from 'Routes/Benchmark/BenchmarkBoard/_Components/EditButton';
import ConflictButton from 'Routes/Benchmark/BenchmarkBoard/_Components/ConflictButton';
import { deleteTemporaryItem } from '_Resources/Benchmark/Actions/Items/deleteTemporaryItem';
import { saveTemporaryItem } from '_Resources/Benchmark/Actions/Items/saveTemporaryItem';

const Buttons = css`
  position: relative;
  left: 50%;
  transform: translate(-50%, 50%);
  margin: 0;
`;

const StyledEditButton = styled(EditButton)`
  opacity: ${({ isHovered }) => (isHovered ? 1 : 0)};
  position: relative;
  left: 50%;
  transform: translate(-50%, -30%);
  margin: 0;
  margin-top: 2rem;
`;
const StyledConflictButton = styled(ConflictButton)`
  ${Buttons}
`;

const BenchmarkEditCard = ({
  cardId,
  dispatchCreateCard,
  dispatchDeleteTemporaryItems,
  dispatchSaveTemporaryCard,
  cards,
  temporaryCard,
  conflicts,
  dispatchCreateConflicts,
  isHovered,
  conflict,
  t,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openEditColumnModal = () => {
    setIsOpen(!isOpen);
    dispatchCreateCard({
      settings: cards[cardId],
      id: cardId,
      context: CONTEXT.CARD,
    });
    dispatchCreateConflicts({
      settings: filter(
        conflicts,
        conflict => conflict.dimensions.card === cardId,
      ),
      id: conflicts.length,
      context: CONTEXT.CONFLICTS,
    });
  };

  const closeEditColumnModal = () => {
    setIsOpen(!isOpen);
  };

  const cardTitle = get(temporaryCard, ['title']);
  const cardSettings = get(temporaryCard, ['settings']);

  const MODAL_CONTENT_CONFIG = {
    cancelButton: {
      text: t('button:Cancel'),
    },
    validateButton: {
      text: t('button:Save'),
    },
    steps: {
      name: {
        title: t('Name'),
        stepBody: (
          <ModalNamePage
            stepTitle={t('Name your card')}
            context={CONTEXT.CARD}
            name={cardTitle}
          />
        ),
      },
      filters: {
        title: t('Filters'),
        stepBody: (
          <ModalFiltersPage
            stepTitle={t('Choose filters for this card')}
            columnCollapseContent={cardCollapseContent}
            context={CONTEXT.CARD}
            currentSettingsFilters={cardSettings}
          />
        ),
      },
    },
  };

  return (
    <>
      {conflict ? (
        <StyledConflictButton
          text={t('conflicts:Conflict detected')}
          onClick={() => openEditColumnModal()}
          size="sm"
          css={Buttons}
        />
      ) : (
        <StyledEditButton
          primaryColor={purple}
          text={t('Edit this E-Index card')}
          size="sm"
          onClick={() => openEditColumnModal()}
          isHovered={isHovered}
        />
      )}

      <EditModal
        disableOnClickOutside
        isOpen={isOpen}
        closeModal={() => closeEditColumnModal()}
        onCancel={dispatchDeleteTemporaryItems}
        onSave={dispatchSaveTemporaryCard}
        title={t('Edit your card')}
        config={{ mentions: false, date: false }}
        modalConfig={MODAL_CONTENT_CONFIG}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreateCard: newState => {
      dispatch(createTemporaryItem(newState));
    },
    dispatchDeleteTemporaryItems: () => {
      dispatch(deleteTemporaryItem(CONTEXT.CARD));
      dispatch(deleteTemporaryItem(CONTEXT.CONFLICTS));
    },
    dispatchSaveTemporaryCard: () => {
      dispatch(saveTemporaryItem(CONTEXT.CARD));
    },
    dispatchCreateConflicts: newState => {
      dispatch(createTemporaryItem(newState));
    },
  };
}

BenchmarkEditCard.propTypes = {
  cardId: PropTypes.number.isRequired,
  dispatchCreateCard: PropTypes.func.isRequired,
  dispatchDeleteTemporaryItems: PropTypes.func.isRequired,
  dispatchSaveTemporaryCard: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(PropTypes.any).isRequired,
  conflict: PropTypes.arrayOf(PropTypes.any).isRequired,
  temporaryCard: PropTypes.objectOf(PropTypes.any).isRequired,
  conflicts: PropTypes.arrayOf(PropTypes.any).isRequired,
  dispatchCreateConflicts: PropTypes.func.isRequired,
  isHovered: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    cards: state.benchmark.activeBenchmark.settings.cards,
    temporaryCard: get(state, ['benchmark', 'temporaryCard']),
    conflicts: state.benchmark.activeBenchmark.settings.conflicts,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark', 'conflicts', 'button')(BenchmarkEditCard));
