import gql from 'graphql-tag';

const mutation = gql`
  mutation updateUserPassword(
    $currentPassword: String!
    $newPassword: String!
  ) {
    updateUserPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      status
    }
  }
`;
export default mutation;
