import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import moment from 'moment/moment';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Icon from '_Components/Icons/Icon';
import EXPORT_PROJECT_DATA_QUERY from '_Cards/SettingsCards/ProjectDetailsCard/graphql/exportProjectData';
import ExportDataModal from '_Cards/SettingsCards/ProjectDetailsCard/_Components/ProcessingHistory/_Components/ProcessingHistoryHeader/_Components/ExportDataModal/ExportDataModal';
import DropdownSettings from './_Components/DropdownSettings/DropdownSettings';
import QTitle from '../../../../../../../_Components/Texts/QTitle/QTitle';
import {
  white,
  grayShades,
  themeColors,
  lightBlue,
} from '../../../../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../../../../styles/abstracts/typography';
import { hex2rgbaConvert } from '../../../../../../../_Utils/styles/colors';
import {
  spacing,
  radiusRoot,
} from '../../../../../../../styles/abstracts/variables';
import EindexRond from '../../../../../../../_Charts/KpisCharts/KpiBadges/EindexRond';
import { getKValue } from '../../../../../../../_Utils/mentions/getKValue';
import { TEXT_TYPES } from '../../../../../../../_Components/Texts/textTypes';
import QToast from '_Components/QToast/QToast';

const Card = styled.div`
  padding: 1rem;
  background-color: ${({ projectState }) =>
    projectState === 'inaccessible' ? grayShades.g300 : white};
  border-radius: ${radiusRoot.round};
  transition: 0.5s;
  border: 2px solid
    ${({ projectState }) =>
      projectState === 'inaccessible' ? grayShades.g400 : grayShades.g300};
  text-align: center;
  height: 13rem;
  min-width: 10rem;
  flex-basis: 10rem;
  flex-shrink: 1;

  &:hover {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
    border-color: ${({ projectState }) =>
      projectState === 'inaccessible' ? grayShades.g400 : white};
  }
`;

const LinkButton = styled.div`
  background-color: ${hex2rgbaConvert(themeColors.primary, 20)} !important;
  color: ${themeColors.primary} !important;

  &:hover {
    background-color: ${themeColors.primary} !important;
    color: ${white} !important;
  }
`;

const StyledQTitleSubtitle = styled(QTitle.Subtitle)`
  color: ${grayShades.g800};
  font-size: ${fontSizes.extraSmall};
`;

const Infos = styled.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  height: calc(4 * ${spacing.medium});
  width: 100%;
`;

const Eindex = styled(EindexRond)`
  height: inherit !important;
`;

const Separator = styled.div`
  width: 1px;
  background-color: ${grayShades.g300};
  height: 100%;
`;

const MentionCount = styled.div`
  font-weight: bold;
`;

const MentionNumber = styled.span`
  font-size: 2.25rem;
`;

const StyledTitle = styled.div`
  display: flex !important;
  width: 100% !important;
  justify-content: space-between !important;
`;

const ProcessingMessage = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin: 0.5em 0;
  color: ${grayShades.g800};
`;

const spinning = keyframes`
  from {
    transform: rotate(0deg);
  };
  to {
    transform: rotate(360deg);
  };
`;

const ProcessingIcon = styled(Icon)`
  animation: ${spinning} 2s linear infinite;
`;

class ProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.data,
      isHovering: false,
      isModalOpen: false,
      toastMessage: {},
    };
  }

  openModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  handleMouseEnter() {
    this.setState({ isHovering: true });
  }

  handleMouseLeave() {
    this.setState({ isHovering: false });
  }

  setToastMessage(toastMessage) {
    this.setState({ toastMessage })
  }

  render() {
    const { project } = this.state;
    const { startDate } = project.period;
    const { endDate } = project.period;
    const { t } = this.props;
    return (
      <Card
        onMouseEnter={() => this.handleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
        projectState={this.state.project.state}
      >
        <QTitle type={TEXT_TYPES.PANEL}>
          <StyledTitle>
            <QTitle.Title>{this.props.data.name}</QTitle.Title>
            {project.state === 'accessible' && (
              <>
                <DropdownSettings
                  projectId={project.id}
                  slug={project.slug}
                  t={t}
                  isModalOpen={this.state.isModalOpen}
                  openModal={() => this.openModal()}
                />
                {ReactDOM.createPortal(
                  <ExportDataModal
                    t={t}
                    setMessage={this.setToastMessage.bind(this)}
                    isOpen={this.state.isModalOpen}
                    open={() => this.openModal()}
                    projectId={project.id}
                    projectName={project.name}
                    query={EXPORT_PROJECT_DATA_QUERY}
                    additionalVariables={{ range: project.period }}
                  />,
                  document.getElementById('root'),
                )}
                {this.state.toastMessage.message && <QToast color={lightBlue}>{this.state.toastMessage.message}</QToast>}
              </>
            )}
          </StyledTitle>
          {project.state === 'accessible' && project.period.startDate && (
            <StyledQTitleSubtitle>
              {moment(startDate)
                .locale(this.props.i18n.language)
                .format('LL')}{' '}
              •{' '}
              {moment(endDate)
                .locale(this.props.i18n.language)
                .format('LL')}
            </StyledQTitleSubtitle>
          )}
        </QTitle>
        <br />
        {project.state === 'accessible' ? (
          this.state.isHovering ? (
            project.eindex.value || project.eindex.value === 0 ? (
              <LinkButton
                className="btn btn-lg mx-auto mt-3"
                onClick={() =>
                  this.props.callbackParent({
                    value: this.props.data.id,
                    label: this.props.data.name,
                  })
                }
              >
                <span>{t("button:Let's discover emotions !")}</span>
              </LinkButton>
            ) : (
              <LinkButton
                className="btn btn-lg mx-auto mt-3"
                onClick={() =>
                  this.props.history.push(`/project-details/${project.slug}`)
                }
              >
                <span>{t('button:Project configuration')}</span>
              </LinkButton>
            )
          ) : (
            <Infos>
              {project.eindex.value || project.eindex.value === 0 ? (
                <>
                  <Eindex
                    eindex={project.eindex.value}
                    projectId={project.id}
                    fahrenheitOverride={this.props.eindexUnitFahrenheit}
                  />
                  <Separator />
                  <MentionCount>
                    <MentionNumber>
                      {getKValue(project.mentionCount)}
                    </MentionNumber>
                    <br />
                    <span>{t('mentions')}</span>
                  </MentionCount>
                </>
              ) : (
                <ProcessingMessage>
                  {t(
                    'There is no data on this project. Please check its configuration.',
                  )}
                </ProcessingMessage>
              )}
            </Infos>
          )
        ) : (
          <ProcessingMessage>
            <ProcessingIcon icon="INTERFACE_GEAR" size={40} />
            <p>
              {t(
                'This project is currently processing. Please come back later.',
              )}
            </p>
          </ProcessingMessage>
        )}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  eindexUnitFahrenheit: get(state, [
    'projectConfiguration',
    'unitsAndLocalesSettings',
    'eindexUnitFahrenheit',
  ]),
});

ProjectCard.propTypes = {
  callbackParent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(
  withRouter(
    withTranslation('card', ['button', 'header', 'conflicts'])(ProjectCard),
  ),
);
