import React from 'react';
import { Row } from 'reactstrap';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import MentionBadgeSection from './MentionBadgeSection';

const MentionBadgeCard = props => {
  const { color, data, filters } = props;
  return (
    <QcardPanel {...props} hints={[HintsContent.HINT_MENTION_COUNT]}>
      <Row className="containContent">
        <MentionBadgeSection color={color} data={data} filters={filters} />
      </Row>
    </QcardPanel>
  );
};

export default MentionBadgeCard;
