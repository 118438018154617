import React from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Popover,
  PopoverBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import { get } from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Icon from '_Components/Icons/Icon';
import classnames from 'classnames';
import { spacing, ImageSize } from '../../../../styles/abstracts/variables';
import { grayShades, purple, white } from '../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../styles/abstracts/typography';
import InputNameModal from './InputNameModal';
import ArchiveReportingModel from '../apollo/Mutations/ArchiveReportingModel';
import DuplicateReportingModel from '../apollo/Mutations/DuplicateReportingModel';

const DDOptions = styled(Dropdown)`
  display: inline-block !important;
  margin: 0 ${spacing.mediumLarge};
`;

const Item = styled(DropdownItem)`
  padding: ${spacing.mediumSmall} ${spacing.medium} !important;
  cursor: pointer;
  line-height: 21px;
`;

const ActionName = styled.span`
  font-weight: bold;
  color: ${purple};
  font-size: ${fontSizes.smaller};
`;

const Toggle = styled(DropdownToggle)`
  background-color: transparent !important;
`;

const Menu = styled(DropdownMenu)`
  margin-top: 5em !important!;
`;

const CustomPopover = styled(Popover)`
  background-color: black !important;
  top: 1em !important;
  left: calc(-${props => props.menuDimensions?.right}px) !important;

  & .arrow:after {
    border-left-color: black !important;
  }
`;

const CustomPopoverBody = styled(PopoverBody)`
  color: ${white} !important;
`;

class DropdownOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      modal: false,
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleDropdown() {
    this.setState({ dropdown: !this.state.dropdown });
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  getMenuDimensions() {
    const menu = document.getElementById('duplicateReportingItem');
    return {
      right: menu.offsetWidth,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dropdown !== this.state.dropdown) {
      this.setState({ menuDimensions: this.getMenuDimensions() });
    }
  }

  render() {
    const type = this.props.template ? 'template' : 'report';
    const { t } = this.props;
    const propsModal = {
      toggle: this.toggleModal,
      isOpen: this.state.modal,
      title: `Rename a ${type}`,
      subtitle: `Please rename your report${
        this.props.template ? ' template' : ''
      }`,
      button: `Rename my report${this.props.template ? ' template' : ''}`,
    };
    return (
      <DDOptions
        isOpen={this.state.dropdown}
        toggle={this.toggleDropdown}
        direction="right"
        className={classnames}
        id="reportingOptionsDropdown"
      >
        <Toggle>
          <Icon
            icon="INTERFACE_DOTS_VERTICAL"
            size={ImageSize.medium}
            color={grayShades.g800}
          />
        </Toggle>
        <DropdownMenu className={classnames}>
          <Item onClick={() => this.toggleModal()}>
            <Icon
              icon="INTERFACE_EDIT"
              size={ImageSize.medium}
              color={grayShades.g800}
            />
            <InputNameModal
              data={this.props.data}
              template={this.props.template}
              {...propsModal}
            />
            &nbsp;
            <ActionName>{t('Rename')}</ActionName>
          </Item>
          {this.props.template && (
            <>
              <DuplicateReportingModel data={this.props.data} />
              {!this.props.canCreateReporting &&
                document.getElementById('duplicateReportingItem') &&
                this.state.dropdown && (
                  <CustomPopover
                    isOpen
                    target="reportingOptionsDropdown"
                    menuDimensions={this.state.menuDimensions}
                    placement="left"
                  >
                    <CustomPopoverBody>
                      {t("header:You can't create more reportings. Please upgrade your plan in order to create more reportings.")}
                    </CustomPopoverBody>
                  </CustomPopover>
                )}
            </>
          )}
          <DropdownItem divider />
          <ArchiveReportingModel data={this.props.data} />
        </DropdownMenu>
      </DDOptions>
    );
  }
}

const mapStateToProps = state => ({
  canCreateReporting: get(state, [
    'auth',
    'user',
    'account',
    'canCreateReporting',
  ]),
});

export default connect(mapStateToProps)(
  withTranslation('button', 'header')(DropdownOptions),
);
