import { mergeDeepRight } from "ramda";
import { getMarkerColorFactory } from "./utils";
import { modes } from "../../config/modesConfig";
import { findMode } from "../../utils/modesHelpers";

const prepareGetMarkerColor = isFahrenheit => {
  const { celsius, fahrenheit } = modes;
  const { ranges } = findMode("sat", isFahrenheit ? fahrenheit : celsius);
  return getMarkerColorFactory(ranges);
};

export function transform(
  indicatorOptions,
  markerOptions,
  { isFahrenheit } = {}
) {
  const { value } = indicatorOptions;
  const getColor = prepareGetMarkerColor(isFahrenheit);
  const { color: fillColor } = getColor(value);

  return mergeDeepRight(markerOptions, {
    icon: { fillColor },
    ...prepareMarkerLabel(value)
  });
}

function prepareMarkerLabel(value) {
  return {
    label: {
      color: "#FFFFFF",
      fontWeight: "900",
      text: `${value}`
    }
  };
}
