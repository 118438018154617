import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import refineByPropTypes from './RefineBy.propTypes';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const RefineByKpiBody = styled.div``;

class RefineByKpi extends Component {
  static propTypes = {
    ...refineByPropTypes,
  };

  marks = {
    '0': { label: '0', style: { color: '#592ea0' } },
    '1': { label: '1', style: { color: '#592ea0' } },
    '2': { label: '2', style: { color: '#592ea0' } },
    '3': { label: '3', style: { color: '#592ea0' } },
    '4': { label: '4', style: { color: '#592ea0' } },
    '5': { label: '5', style: { color: '#592ea0' } },
    '6': { label: '6', style: { color: '#592ea0' } },
    '7': { label: '7', style: { color: '#592ea0' } },
    '8': { label: '8', style: { color: '#592ea0' } },
    '9': { label: '9', style: { color: '#592ea0' } },
    '10': { label: '10', style: { color: '#592ea0' } },
  };
  render() {
    const { value, onChange } = this.props;
    return (
      <RefineByKpiBody>
        <Range
          min={0}
          max={10}
          value={value || [0, 10]}
          tipFormatter={v => `${v}%`}
          marks={this.marks}
          trackStyle={[
            { backgroundColor: '#592EA0' },
            { backgroundColor: 'yellow' },
          ]}
          handleStyle={[
            {
              transform: 'translateY(-3px) translateX(-3px)',
              backgroundColor: '#592EA0',
              borderColor: 'white',
              width: '20px',
              height: '20px',
            },
          ]}
          activeDotStyle={{
            backgroundColor: '#592EA0',
            borderColor: '#592EA0',
          }}
          onChange={onChange}
        />
      </RefineByKpiBody>
    );
  }
}

const StyledRefineBy = RefineByKpi;
export default StyledRefineBy;
export const RefineBySatisfaction = props => <StyledRefineBy {...props} />;
export const RefineByRecommendation = props => <StyledRefineBy {...props} />;
