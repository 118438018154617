import React from 'react';
import { cloneDeep, find, get } from 'lodash';
import { connect } from 'react-redux';
import { Mutation, withApollo } from 'react-apollo';
import getProjectConfigurationData from '_Resources/Header/Services/api/graphql/getProjectConfigurationData';
import mutationSaveDataByFiltersSettings from '../../graphql/saveDataByFiltersSettings';
import getDataByFiltersCardData from '../../graphql/getDataByFiltersCardData';

class MutationSaveSettings extends React.Component {
  render() {
    return (
      <Mutation
        mutation={mutationSaveDataByFiltersSettings}
        update={(cache, { data: { saveDataByFiltersSettings } }) => {
          const { thematic, selectedCriteriaKey, orderBy } = JSON.parse(saveDataByFiltersSettings.settings);
          const { variables, dateRange, criteriaKeys, client } = this.props;
          const { projectId, pageId } = variables;
          const dataByFiltersVariables = {
            projectId,
            range: dateRange,
            thematics: [thematic],
            criteriaKeys,
            selectedCriteriaKey,
          };
          try {
            const data = cache.readQuery({
              query: getDataByFiltersCardData,
              variables: dataByFiltersVariables,
            });
            cache.writeQuery({
              query: getDataByFiltersCardData,
              variables: dataByFiltersVariables,
              data,
            });
          } catch (e) {
            client
              .query({
                query: getDataByFiltersCardData,
                variables: dataByFiltersVariables,
              })
              .then(data =>
                cache.writeQuery({
                  query: getDataByFiltersCardData,
                  variables: dataByFiltersVariables,
                  data,
                }),
              );
          }
          let results;
          try {
            results = cloneDeep(
              cache.readQuery({
                query: getProjectConfigurationData,
                variables: { projectId },
              }).results,
            );
          } catch (e) {
            results = this.props.projectConfiguration;
          }
          const moduleList = results.moduleSettings;
          const moduleToEdit = find(moduleList, module => module.moduleId === this.props.defaultThematic);
          const indexOfModule = moduleList.indexOf(moduleToEdit);
          const pageSettingsToEdit = find(moduleToEdit.pageSettings, page => page.pageId === pageId);
          const indexOfPageSettings = moduleToEdit.pageSettings.indexOf(pageSettingsToEdit);
          pageSettingsToEdit.settings = saveDataByFiltersSettings.settings;
          moduleToEdit.pageSettings[indexOfPageSettings] = pageSettingsToEdit;
          moduleList[indexOfModule] = moduleToEdit;
          cache.writeQuery({
            query: getProjectConfigurationData,
            variables: { projectId },
            data: {
              results: {
                unitsAndLocalesSettings: results.unitsAndLocalesSettings,
                moduleSettings: moduleList,
              },
            },
          });
        }}
      >
        {saveDataByFiltersSettings =>
          React.Children.map(this.props.children, child => {
            const { variables } = this.props;
            const { settings } = variables;
            const newSettings = JSON.parse(settings);
            const { thematic } = newSettings;
            newSettings.thematic = thematic?.thematic !== null ? thematic : null;
            variables.settings = JSON.stringify(newSettings);
            const propsToPass = {
              onClick: () => {
                this.props.callback();
                saveDataByFiltersSettings({
                  variables: { ...variables },
                });
              },
            };
            return React.cloneElement(child, propsToPass);
          })
        }
      </Mutation>
    );
  }
}

const mapStateToProps = state => ({
  dateRange: get(state, ['filters', 'filterKeys', 'range']),
  criteriaKeys: get(state, ['filters', 'filterKeys', 'criteriaKeys']),
});

export default connect(mapStateToProps)(withApollo(MutationSaveSettings));
