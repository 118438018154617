import { upperFirst, find, pick } from 'lodash';
import { INDICATORS_TYPE } from '_Cards/FilterCards/DataByFiltersCard/FiltersSettings';
import { emotionColors, sentimentColors, grayShades, opacity, white, purple } from 'styles/abstracts/colors';
import { positiveEmotions, negativeEmotions, neutralEmotions } from 'constants/index';
import grabberGraph from './grabber_graph.svg';

const getValue = (indicator, indicatorType, indicatorsConfig) => {
  const item = find(indicatorsConfig[indicator], i => {
    if (i.subcategories) {
      return i.subcategories.find(sub => sub.id === indicatorType);
    }
    return i.id === indicatorType;
  });
  if (item && item.subcategories) {
    return find(item.subcategories, i => i.id === indicatorType);
  }
  return item;
};

const getIndicatorColor = (indicator, indicatorType, options, indicatorsConfig, eindexUnitFahrenheit) => {
  const { data } = options || [];
  if (indicatorType === 'eindex') {
    data?.forEach((item, ind) => {
      data[ind].itemStyle.color = find(indicatorsConfig[indicator], i => (i.id || i.value) === indicatorType)?.color(
        item.value,
        eindexUnitFahrenheit,
      );
    });
  } else if (indicatorType !== 'emotions') {
    data?.forEach((item, ind) => {
      if (data && data[ind] && data[ind].itemStyle) {
        data[ind].itemStyle.color = getValue(indicator, indicatorType, indicatorsConfig)?.color(data[ind]);
        data[ind].itemStyle.borderColor = !data[ind]?.label?.normal?.show ? 'transparent' : 'white';
      }
    });
  }
};

const getIndicatorsOptions = (graphData, indicatorType, indicators, indicatorsConfig, eindexUnitFahrenheit) => {
  const currentIndicator = indicators[indicatorType].id || indicators[indicatorType].value;

  const indicatorOptions = currentIndicator && {
    min: getValue(indicatorType, currentIndicator, indicatorsConfig)?.min(
      graphData,
      eindexUnitFahrenheit,
      currentIndicator,
    ),
    max: getValue(indicatorType, currentIndicator, indicatorsConfig)?.max(
      graphData,
      eindexUnitFahrenheit,
      indicators[indicatorType],
      currentIndicator,
    ),
    dataset: currentIndicator === 'emotions' && {},
    data:
      graphData &&
      currentIndicator !== 'emotions' &&
      graphData.map(item => {
        return {
          mentionCount: item.mentionCount,
          value:
            getValue(indicatorType, currentIndicator, indicatorsConfig)?.data(
              item,
              eindexUnitFahrenheit,
              getValue(indicatorType, currentIndicator, indicatorsConfig),
            ) ?? 0,
          thematic: item.hierarchy,
          itemStyle: {
            barBorderRadius:
              item[currentIndicator === 'mentions' ? 'mentionCount' : currentIndicator] > 0 ||
              currentIndicator === 'emotions' ||
              eindexUnitFahrenheit
                ? [12, 12, 0, 0]
                : [0, 0, 12, 12],
          },
          label: indicatorType === 'badge' && {
            normal: {
              show:
                (!Number.isNaN(
                  parseInt(item[currentIndicator === 'mentions' ? 'mentionCount' : currentIndicator], 10),
                ) &&
                  item[currentIndicator]?.value !== null &&
                  item[currentIndicator] !== null) ||
                (item[currentIndicator] !== null && item[currentIndicator]?.delta !== null),
              formatter:
                currentIndicator === 'eindex'
                  ? '{c}°'
                  : indicators[INDICATORS_TYPE.BAR].selected &&
                    indicators[INDICATORS_TYPE.BAR].selected[0] &&
                    indicators[INDICATORS_TYPE.BAR].selected[0].split('.')[2] === 'byPercentage'
                  ? '{c}%'
                  : '{c}',
              fontSize: 12,
            },
          },
        };
      }),
  };
  if (currentIndicator === 'emotions') {
    const arrayEmotions = indicatorsConfig[indicatorType]
      .find(config => (config.id || config.value) === indicators?.[INDICATORS_TYPE.BAR].id)
      ?.subcategories?.map(sub =>
        sub?.subcategories?.map(subsub => subsub?.id?.split('.')[1] || subsub?.value?.split('.')[1]),
      )[0];
    const emotionsLabels = graphData && Object.keys(graphData[0]?.emotions);
    emotionsLabels?.unshift('labels');
    const emotionsValues =
      graphData &&
      graphData
        .map(item => {
          let emotionsObj = {};
          indicators[indicatorType].selected[0] && item.emotions
            ? Object.keys(item.emotions).map(em => {
                emotionsObj = {
                  ...emotionsObj,
                  [em]: item.emotions[em][indicators[indicatorType].selected[0].split('.')[2]],
                };
              })
            : {};
          const emotionsToOrder = pick(
            emotionsObj,
            indicators[indicatorType].selected.map(em => em.split('.')[1]),
          );
          const orderedEmotions = Object.keys(emotionsToOrder).sort(
            (a, b) => arrayEmotions?.indexOf(a) - arrayEmotions?.indexOf(b),
          );
          let emotionsToMap = {};
          emotionsToMap = orderedEmotions
            .map(emotion => ({
              ...emotionsToMap,
              [emotion]: emotionsToOrder[emotion],
            }))
            .reduce((r, c) => Object.assign(r, c), {});
          return Object.values(emotionsToMap).map(em => Math.round(em));
        })
        .map((emotion, index) => {
          emotion.unshift(graphData[index].name);
          return emotion;
        });
    emotionsLabels && emotionsValues.unshift(emotionsLabels);
    indicatorOptions.dataset.source = emotionsValues;
  }
  getIndicatorColor(indicatorType, currentIndicator, indicatorOptions, indicatorsConfig, eindexUnitFahrenheit);
  return indicatorOptions;
};

export const getGraphOption = (graphData, indicators, indicatorsConfig, eindexUnitFahrenheit, t) => {
  const badgeConfig =
    indicators &&
    getIndicatorsOptions(graphData, INDICATORS_TYPE.BADGE, indicators, indicatorsConfig, eindexUnitFahrenheit);
  const barConfig =
    indicators &&
    getIndicatorsOptions(graphData, INDICATORS_TYPE.BAR, indicators, indicatorsConfig, eindexUnitFahrenheit);
  const selectedEmotions = indicators?.[INDICATORS_TYPE.BAR]?.selected
    ?.sort((a, b) => {
      const emotionsArray = ['happiness', 'surprise', 'calm', 'fear', 'sadness', 'anger', 'disgust'];
      return Object.keys(emotionsArray).indexOf(a) - Object.keys(emotionsArray).indexOf(b);
    })
    .map(em => em.split('.')[1]);
  const emotionsBars =
    indicators?.[INDICATORS_TYPE.BAR].id === 'emotions'
      ? selectedEmotions?.map(emotion => ({
          name: t(upperFirst(emotion)),
          type: 'bar',
          itemStyle: {
            barBorderRadius: [0, 0, 0, 0],
            color: emotionColors[emotion],
          },
          z: 1,
          yAxisIndex: 1,
          xAxisIndex: 0,
          barMaxWidth: 12,
        }))
      : null;
  const options = {
    grid: { bottom: graphData && graphData.length > 10 ? '35%' : '15%' },
    tooltip: {
      trigger: 'axis',
      axisPointer: { lineStyle: { color: 'transparent' } },
      formatter: (a: any[]) => {
        let linesArray: string[] = [];
        a.filter(d => d.color !== 'transparent').forEach(item => {
          linesArray = [
            ...linesArray,
            `<span style="width: 10px; height: 10px; background-color: ${
              item.color
            }; border-radius: 50%; display: inline-block;"></span> ${item.seriesName}: ${
              !Number.isNaN(parseInt(item.value, 10))
                ? item.seriesName === 'E-index'
                  ? `${item.value}°`
                  : item.value
                : indicators?.[INDICATORS_TYPE.BAR].selected &&
                  indicators?.[INDICATORS_TYPE.BAR].selected[0] &&
                  indicators?.[INDICATORS_TYPE.BAR].selected[0].split('.')[2] === 'byPercentage'
                ? item.value && item.value?.[item.seriesIndex]
                  ? `${item.value[item.seriesIndex]}%`
                  : '-'
                : (item.value && item.value[item.seriesIndex]) || '-'
            }`,
          ];
        });
        return `<b>${a && a[0].axisValue}:</b> <br/> <br /> ${linesArray.join(' <br/> ')}`;
      },
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      feature: {
        dataZoom: {
          title: {
            zoom: t('graph:Zoom'),
            back: t('graph:Zoom reset'),
          },
          // yAxisIndex: 'none',
          iconStyle: {
            borderColor: purple,
          },
        },
        dataView: {
          title: t('graph:View data'),
          lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
          readOnly: false,
          textColor: purple,
          buttonColor: purple,
          iconStyle: {
            borderColor: purple,
          },
        },
        magicType: {
          title: {
            line: t('graph:Switch to line'),
            bar: t('graph:Switch to bar'),
            stack: t('graph:Stack'),
            tiled: t('graph:Tile'),
          },
          type: indicators?.[INDICATORS_TYPE.BAR].id === 'emotions' ? ['line', 'bar', 'stack'] : ['line', 'bar'],
          iconStyle: {
            borderColor: purple,
          },
        },
        restore: {
          title: t('graph:Reload'),
          iconStyle: {
            borderColor: purple,
          },
        },
        saveAsImage: {
          title: t('graph:Save as image'),
          name: t('graph:Graph Q°Emotion'),
          backgroundColor: '#FFFFFF',
          iconStyle: {
            borderColor: purple,
          },
        },
      },
      emphasis: {
        iconStyle: {
          borderWidth: 2,
          borderColor: purple,
          textBackgroundColor: purple,
          textFill: '#FFFFFF',
          textBorderRadius: [5, 5, 5, 5],
          textPadding: [6, 6, 4, 6],
        },
      },
    },
    dataset: barConfig?.dataset || null,
    xAxis: {
      type: 'category',
      axisLabel: {
        interval: 0,
        fontWeight: 'bolder',
        color: grayShades.g800,
        rotate: graphData && graphData.length > 10 && 35,
      },
      triggerEvent: true,
      axisLine: { show: false },
      data: graphData?.map(item => (item.name.length > 0 ? item.name : 'Unknown')),
    },
    yAxis: [
      {
        type: 'value',
        min: graphData && badgeConfig && badgeConfig.min,
        max: graphData && badgeConfig && badgeConfig.max,
        axisLabel: {
          show: !barConfig || (barConfig.dataset.source && barConfig.dataset.source[1].length < 2),
        },
        axisTick: { show: false },
        axisLine: {
          show: !barConfig || (barConfig.dataset.source && barConfig.dataset.source[1].length < 2),
        },
        splitLine: {
          show: !barConfig || (barConfig.dataset.source && barConfig.dataset.source[1].length < 2),
        },
      },
      barConfig
        ? {
            type: 'value',
            min: graphData && barConfig && barConfig.min,
            max: graphData && barConfig && barConfig.max,
            axisLabel: {
              formatter:
                indicators?.[INDICATORS_TYPE.BAR].selected &&
                indicators?.[INDICATORS_TYPE.BAR].selected[0] &&
                indicators?.[INDICATORS_TYPE.BAR].selected[0].split('.')[2] === 'byPercentage'
                  ? '{value}%'
                  : '{value}',
            },
            axisTick: { show: false },
            axisLine: { show: false },
          }
        : {},
    ],
    dataZoom: [
      {
        show: true,
        start: 0,
        end: 100,
        showDataShadow: false,
        showDetail: false,
        backgroundColor: grayShades.g300,
        fillerColor: '#6f3ac7',
        dataBackgroundColor: 'transparent',
        height: 36,
        handleIcon: `image://${grabberGraph}`,
        handleSize: 24,
        handleStyle: {
          shadowColor: opacity.black,
          shadowBlur: 5,
        },
      },
    ],
    series: [
      {
        type: 'graph',
        name: t(`button:${upperFirst(indicators?.[INDICATORS_TYPE.BADGE].label)}`),
        layout: 'none',
        coordinateSystem: 'cartesian2d',
        symbolSize: 32,
        draggable: false,
        itemStyle: {
          borderWidth: 4,
          borderColor: white,
        },
        label: graphData && badgeConfig && badgeConfig.label,
        data: graphData && badgeConfig && badgeConfig.data,
        z: 2,
      },
      {
        name:
          indicators?.[INDICATORS_TYPE.BAR].id !== 'emotions'
            ? t(`button:${upperFirst(indicators?.[INDICATORS_TYPE.BAR].label)}`)
            : upperFirst(indicators?.[INDICATORS_TYPE.BAR].id),
        type: 'bar',
        data: barConfig && barConfig.data,
        z: 1,
        yAxisIndex: 1,
        xAxisIndex: 0,
        barMaxWidth: 24,
        itemStyle: barConfig && barConfig.itemStyle,
      },
      ...(emotionsBars || []),
    ],
  };
  options.series =
    indicators?.[INDICATORS_TYPE.BAR].id === 'emotions'
      ? options.series.filter(option => option.name !== 'Emotions')
      : options.series;
  return options;
};

const getDataToDisplay = (barIndicator, thematic, emotion) => {
  switch (barIndicator) {
    case 'emotions.volume':
      return thematic?.emotions[emotion].byMentionCount;
    case 'emotions.percentage':
      return thematic?.emotions[emotion].byPercentage.toFixed(1);
    default:
      return thematic?.emotions[emotion].byMentionCount;
  }
};

const getBarColor = (barIndicator, emotion) => {
  if (barIndicator?.split('.')[0] === 'sentiment' && positiveEmotions[emotion]) return sentimentColors.positive;
  if (barIndicator?.split('.')[0] === 'sentiment' && neutralEmotions[emotion]) return sentimentColors.neutral;
  if (barIndicator?.split('.')[0] === 'sentiment' && negativeEmotions[emotion]) return sentimentColors.negative;
  return emotionColors[emotion];
};

const getLabelFormatter = (percentage, value) => {
  return Math.abs(value) + (percentage ? '%' : '');
};

const getTooltipIndication = (percentage, item, t) => {
  if (typeof item.value === 'object') {
    return Math.abs(item.value[item.seriesName]) + (percentage ? ' %' : ` ${t('header:comments')}`);
  }
  return Math.abs(item.value) + (percentage ? ' %' : ` ${t('header:comments')}`);
};

const getToneSeries = (graphData, percentage) => {
  const source = graphData.map(thematic => {
    return {
      category: thematic.name,
      positive: Object.keys(positiveEmotions)
        .reduce((sum, key) => {
          const value = percentage ? thematic.emotions[key].byPercentage : thematic.emotions[key].byMentionCount;
          return thematic.emotions[key] ? sum + value : sum;
        }, 0)
        .toFixed(1),
      negative: -Object.keys(negativeEmotions)
        .reduce((sum, key) => {
          const value = percentage ? thematic.emotions[key].byPercentage : thematic.emotions[key].byMentionCount;
          return thematic.emotions[key] ? sum + value : sum;
        }, 0)
        .toFixed(1),
      neutral: Object.keys(neutralEmotions)
        .reduce((sum, key) => {
          const value = percentage ? thematic.emotions[key].byPercentage : thematic.emotions[key].byMentionCount;
          return thematic.emotions[key] ? sum + value : sum;
        }, 0)
        .toFixed(1),
    };
  });
  return source;
};

export const getHorizontalGraphOption = (graphData, indicators, t) => {
  const sentiment = indicators.bar.id?.split('.')[0] === 'sentiment';
  const percentage = indicators.bar.id?.split('.')[1] === 'percentage';

  const horizontalOptions = {
    grid: {
      bottom: '10%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { lineStyle: { color: 'transparent' } },
      type: 'shadow',
      formatter: (a: any[]) => {
        let linesArray: string[] = [];
        a.filter(d => d.color !== 'transparent').forEach(item => {
          linesArray = [
            ...linesArray,
            `<span style="width: 10px; height: 10px; background-color: ${
              item.color
            }; border-radius: 50%; display: inline-block;"></span> ${
              !sentiment ? item.seriesName : t(upperFirst(item.seriesName))
            }: ${getTooltipIndication(percentage, item, t)}`,
          ];
        });
        return `<b>${a && a[0].axisValue}:</b> <br/> <br /> ${linesArray.join(' <br/> ')}`;
      },
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      right: 50,
      feature: {
        dataZoom: {
          title: {
            zoom: t('graph:Zoom'),
            back: t('graph:Zoom reset'),
          },
          // yAxisIndex: 'none',
          iconStyle: {
            borderColor: purple,
          },
        },
        dataView: {
          title: t('graph:View data'),
          lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
          readOnly: false,
          textColor: purple,
          buttonColor: purple,
          iconStyle: {
            borderColor: purple,
          },
        },
        magicType: {
          title: {
            line: t('graph:Switch to line'),
            bar: t('graph:Switch to bar'),
            stack: t('graph:Stack'),
            tiled: t('graph:Tile'),
          },
          type: ['line', 'bar', 'stack'],
          iconStyle: {
            borderColor: purple,
          },
        },
        restore: {
          title: t('graph:Reload'),
          iconStyle: {
            borderColor: purple,
          },
        },
        saveAsImage: {
          title: t('graph:Save as image'),
          name: t('graph:Graph Q°Emotion'),
          backgroundColor: '#FFFFFF',
          iconStyle: {
            borderColor: purple,
          },
        },
      },
      emphasis: {
        iconStyle: {
          borderWidth: 2,
          borderColor: purple,
          textBackgroundColor: purple,
          textFill: '#FFFFFF',
          textBorderRadius: [5, 5, 5, 5],
          textPadding: [6, 6, 4, 6],
        },
      },
    },
    legend: sentiment
      ? {
          formatter(name) {
            return t(upperFirst(name));
          },
        }
      : {
          data: [
            {
              name: t('Disgust'),
            },
            {
              name: t('Anger'),
            },
            {
              name: t('Sadness'),
            },
            {
              name: t('Fear'),
            },
            {
              name: t('Calm'),
            },
            {
              name: t('Surprise'),
            },
            {
              name: t('Happiness'),
            },
          ],
        },
    dataset: sentiment
      ? {
          dimensions: ['category', 'negative', 'neutral', 'positive'],
          source: getToneSeries(graphData, percentage),
        }
      : null,
    xAxis: {
      type: 'value',
    },
    yAxis: [
      {
        type: 'category',
        data: !sentiment ? graphData.map((thematic: any) => thematic.name) : null,
        axisLabel: {
          formatter: (value, index) => {
            if (indicators.badge.id === 'eindex') {
              const { eindex } = graphData.find(data => data.name === value) || 0;
              return `${value} : ${eindex}°`;
            }
            if (indicators.badge.id === 'mentions') {
              const { mentionCount } = graphData.find(data => data.name === value) || 0;
              return `${value} : ${`${mentionCount} ${t('header:comments')}`}`;
            }
            return value;
          },
          fontWeight: 'bolder',
          color: grayShades.g800,
        },
      },
    ],
    dataZoom: [
      {
        id: 'dataZoomX',
        show: true,
        xAxisIndex: [0],
        filterMode: 'none',
        start: 0,
        end: 100,
        showDataShadow: false,
        showDetail: false,
        backgroundColor: grayShades.g300,
        fillerColor: '#6f3ac7',
        dataBackgroundColor: 'transparent',
        height: 36,
        handleIcon: `image://${grabberGraph}`,
        handleSize: 24,
        handleStyle: {
          shadowColor: opacity.black,
          shadowBlur: 5,
        },
      },
      {
        id: 'dataZoomY',
        show: true,
        yAxisIndex: [0],
        filterMode: 'filter',
        startValue: graphData.length - 9,
        endValue: graphData.length,
        showDataShadow: false,
        showDetail: false,
        backgroundColor: grayShades.g300,
        fillerColor: '#6f3ac7',
        dataBackgroundColor: 'transparent',
        width: 36,
        height: '80%',
        handleIcon: `image://${grabberGraph}`,
        handleSize: 24,
        handleStyle: {
          shadowColor: opacity.black,
          shadowBlur: 5,
        },
      },
    ],
    series: sentiment
      ? [
          {
            type: 'bar',
            stack: 'total',
            name: 'negative',
            color: sentimentColors.negative,
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value.negative),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
          },
          {
            type: 'bar',
            stack: 'total',
            name: 'neutral',
            color: sentimentColors.neutral,
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value.neutral),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
          },
          {
            type: 'bar',
            stack: 'total',
            name: 'positive',
            color: sentimentColors.positive,
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value.positive),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
          },
        ]
      : [
          {
            name: t('Fear'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'fear'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => -getDataToDisplay(indicators.bar.id, thematic, 'fear')),
          },
          {
            name: t('Sadness'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'sadness'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => -getDataToDisplay(indicators.bar.id, thematic, 'sadness')),
          },
          {
            name: t('Anger'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'anger'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => -getDataToDisplay(indicators.bar.id, thematic, 'anger')),
          },
          {
            name: t('Disgust'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'disgust'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => -getDataToDisplay(indicators.bar.id, thematic, 'disgust')),
          },
          {
            name: t('Calm'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'calm'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => getDataToDisplay(indicators.bar.id, thematic, 'calm')),
          },
          {
            name: t('Surprise'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'surprise'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => getDataToDisplay(indicators.bar.id, thematic, 'surprise')),
          },
          {
            name: t('Happiness'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'happiness'),
            label: {
              show: true,
              formatter: (params: any) => {
                const value = getLabelFormatter(percentage, params.value);
                return value;
              },
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => getDataToDisplay(indicators.bar.id, thematic, 'happiness')),
          },
        ],
  };
  return horizontalOptions;
};

export const getHorizontalBenchmarkGraphOption = (graphData, indicators, t, index, benchmarkHorizontalGraphLegend) => {
  const sentiment = indicators.bar.id?.split('.')[0] === 'sentiment';
  const percentage = indicators.bar.id?.split('.')[1] === 'percentage';

  const horizontalOptions = {
    grid: {
      top: 16,
      bottom: '5%',
      left: '5%',
      right: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { lineStyle: { color: 'transparent' } },
      type: 'shadow',
      formatter: (a: any[]) => {
        let linesArray: string[] = [];
        let thematicName = a && a[0].axisValue;
        a.filter(d => d.color !== 'transparent').forEach(item => {
          linesArray = [
            ...linesArray,
            `<span style="width: 10px; height: 10px; background-color: ${
              item.color
            }; border-radius: 50%; display: inline-block;"></span> ${
              !sentiment ? item.seriesName : t(upperFirst(item.seriesName))
            }: ${getTooltipIndication(percentage, item, t)}`,
          ];
        });

        if (index === 0) {
          const caption = a[0].axisValue;
          thematicName = benchmarkHorizontalGraphLegend[caption];
        }

        return `<b>${thematicName}:</b> <br/> <br /> ${linesArray.join(' <br/> ')}`;
      },
    },
    legend: sentiment
      ? {
          formatter(name) {
            return t(upperFirst(name));
          },
          type: 'scroll',
          top: -10,
          show: false,
        }
      : {
          type: 'scroll',
          top: -10,
          show: false,
        },
    dataset: sentiment
      ? {
          dimensions: ['category', 'negative', 'neutral', 'positive'],
          source: getToneSeries(graphData, percentage),
        }
      : null,
    xAxis: {
      type: 'value',
    },
    yAxis: [
      {
        type: 'category',
        data: !sentiment ? graphData.map((thematic: any) => thematic.name) : null,
        axisLabel: {
          formatter: value => {
            const legendCaption = Object.keys(benchmarkHorizontalGraphLegend).find(
              legendKey => benchmarkHorizontalGraphLegend[legendKey] === value,
            );
            if (index === 0) {
              return value;
            }
            return legendCaption;
          },
          fontWeight: 'bolder',
          color: grayShades.g800,
          show: true,
        },
      },
    ],
    dataZoom: [
      {
        id: 'dataZoomX',
        show: true,
        bottom: 8,
        xAxisIndex: [0],
        filterMode: 'none',
        start: 0,
        end: 100,
        showDataShadow: false,
        showDetail: false,
        backgroundColor: grayShades.g300,
        fillerColor: grayShades.g600,
        dataBackgroundColor: 'transparent',
        height: 8,
        handleIcon: `image://${grabberGraph}`,
        handleSize: 6,
        handleStyle: {
          shadowColor: opacity.black,
          shadowBlur: 5,
        },
      },
      {
        id: 'dataZoomY',
        show: true,
        top: 26,
        yAxisIndex: [0],
        filterMode: 'filter',
        startValue: graphData.length - 9,
        endValue: graphData.length,
        showDataShadow: false,
        showDetail: false,
        backgroundColor: grayShades.g300,
        fillerColor: grayShades.g600,
        dataBackgroundColor: 'transparent',
        width: 8,
        height: '80%',
        handleIcon: `image://${grabberGraph}`,
        handleSize: 6,
        handleStyle: {
          shadowColor: opacity.black,
          shadowBlur: 5,
        },
      },
    ],
    series: sentiment
      ? [
          {
            type: 'bar',
            stack: 'total',
            name: 'negative',
            color: sentimentColors.negative,
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value.negative),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
          },
          {
            type: 'bar',
            stack: 'total',
            name: 'neutral',
            color: sentimentColors.neutral,
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value.neutral),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
          },
          {
            type: 'bar',
            stack: 'total',
            name: 'positive',
            color: sentimentColors.positive,
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value.positive),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
          },
        ]
      : [
          {
            name: t('Fear'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'fear'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => -getDataToDisplay(indicators.bar.id, thematic, 'fear')),
          },
          {
            name: t('Sadness'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'sadness'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => -getDataToDisplay(indicators.bar.id, thematic, 'sadness')),
          },
          {
            name: t('Anger'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'anger'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => -getDataToDisplay(indicators.bar.id, thematic, 'anger')),
          },
          {
            name: t('Disgust'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'disgust'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => -getDataToDisplay(indicators.bar.id, thematic, 'disgust')),
          },
          {
            name: t('Calm'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'calm'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => getDataToDisplay(indicators.bar.id, thematic, 'calm')),
          },
          {
            name: t('Surprise'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'surprise'),
            label: {
              show: true,
              formatter: (params: any) => getLabelFormatter(percentage, params.value),
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => getDataToDisplay(indicators.bar.id, thematic, 'surprise')),
          },
          {
            name: t('Happiness'),
            type: 'bar',
            stack: 'total',
            color: getBarColor(indicators.bar.id, 'happiness'),
            label: {
              show: true,
              formatter: (params: any) => {
                const value = getLabelFormatter(percentage, params.value);
                return value;
              },
              color: 'white',
              fontWeight: 'bold',
              fontSize: 16,
              overflow: 'truncate',
              ellipsis: '...',
            },
            labelLayout(params) {
              return {
                width: params.rect.width - params.labelRect.width < 5 ? 0 : params.labelRect.width + 5,
              };
            },
            emphasis: {
              focus: 'series',
            },
            data: graphData.map((thematic: any) => getDataToDisplay(indicators.bar.id, thematic, 'happiness')),
          },
        ],
  };
  return horizontalOptions;
};
