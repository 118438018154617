import React from 'react';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import { Link } from 'react-router-dom';
import { ReactComponent as ProcessingIcon } from './processingIcon.svg';

const Container = styled.div`
  width: 100%;
  text-align: center;
  margin: 1em 0;
`;

const Title = styled(QTitle)`
  text-align: center;
  margin-top: 1em;
`;

const InfoContainer = styled.div`
  margin-bottom: 1em;
`;

const SubTitle = styled.p`
  margin-bottom: 1em;
  font-weight: bold;
`;

const ProcessingStep = ({ t }) => (
  <Container>
    <ProcessingIcon />
    <Title>{t('Processing!')}</Title>
    <br />
    <InfoContainer>
      <SubTitle>{t('We are searching for emotions!')}</SubTitle>
      <p>{t('Our emotional engine is working hard on your data...')}</p>
      <p>{t('You will be notified by email when the processing is completed.')}</p>
    </InfoContainer>
    <Link to="/settings">
      <QButton onClick={() => window.localStorage.setItem('openedSettings', 'projects')}>{t('button:Go to projects page')}</QButton>
    </Link>
  </Container>
);

export default ProcessingStep;
