import React from 'react';
import { Col, Row } from 'reactstrap';
import CSpeechEngagementChart from '_Charts/KpisCharts/CSpeechEngagment/CSpeechEngagementChart';
import BadgeBenchmarkTooltip from '_Components/Tooltips/BadgeBenchmarkTooltip/BadgeBenchmarkTooltip';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import CQuery from '_Container/QQuery/CQuery';
import querySchema from '_Cards/EmotionalKPIsCards/SpeechEngagementCard/graphql/getSpeechEngagementCardData';

const SpeechEngagement = props => (
  <QcardPanel {...props} hints={[HintsContent.HINT_SPEECH_ENGAGEMENT]}>
    <CQuery query={querySchema}>
      <SpeechEngagementView title={props.title} />
    </CQuery>
  </QcardPanel>
);

const SpeechEngagementView = ({ className, children, ...props }) => (
  <div style={{ height: '100%' }}>
    <Row style={{ minHeight: '200px', height: 'calc(100% - 64px)' }}>
      <Col className="text-center mt-5 mb-4">
        <CSpeechEngagementChart
          value={props.data && props.data.speechEngagement.value}
        />
      </Col>
    </Row>
    <Row>
      <Col className="text-center mb-3">
        <BadgeBenchmarkTooltip
          title={props.title}
          idTooltip="SpeechEngagementDeltaTooltip"
          data={props.data.speechEngagement}
        />
      </Col>
    </Row>
  </div>
);

export default SpeechEngagement;
