import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import { get } from 'lodash';
import CURRENT_USER_QUERY from '_Resources/Header/Services/api/graphql/currentUser';
import illustration from './illustration.svg';
import { grayShades, white } from '../../../../styles/abstracts/colors';
import { spacing } from '../../../../styles/abstracts/variables';
import { fontSizes } from '../../../../styles/abstracts/typography';

const DragDrop = styled.div`
  width: 18em;
  background: ${white};
  border: 2px dashed ${grayShades.g400};
  border-radius: 1.5em;
  padding: ${spacing.small};
  margin: ${spacing.large} 0;
  text-align: center;
  font-weight: bold;
`;

const DragDropText = styled.p`
  font-size: ${fontSizes.bigger};
`;

const BrowseFile = styled.span`
  font-size: ${fontSizes.smaller};
  color: ${grayShades.g800};
  text-decoration: underline;
  cursor: pointer;
`;

const FormatInformations = styled.span`
  font-size: ${fontSizes.smaller};
`;

const NewDataContainer = ({
  onDrop,
  t,
  setFileState,
  acceptedFormat,
  fileUploadMutation,
  uuid,
  setFileToUpload,
  defaultIllustration,
  currentUser,
}) => {
  const [logo, setLogo] = useState(defaultIllustration ?? illustration);
  const mutation = fileUploadMutation && useMutation(fileUploadMutation);
  const addProjectFile = mutation && mutation[0];
  const options = mutation && mutation[1];

  useEffect(() => {
    setLogo(defaultIllustration ?? illustration);
  }, [defaultIllustration]);

  const onFileDrop = useCallback((acceptedFiles, rejectedFiles, dropObject) => {
    const fileToUpload = acceptedFiles[0];
    fileToUpload
      ? fileUploadMutation &&
        addProjectFile({
          variables: { uuid, file: fileToUpload },
          update: (cache, { data }) => {
            if (Object.keys(data)[0] === 'uploadCompanyLogo') {
              const {
                uploadCompanyLogo: { file },
              } = data;
              try {
                const { currentUser: user } = cache.readQuery({
                  query: CURRENT_USER_QUERY,
                });
                cache.writeQuery({
                  query: CURRENT_USER_QUERY,
                  data: {
                    currentUser: {
                      user,
                      account: { ...user.account, logo: file },
                    },
                  },
                });
              } catch (e) {
                cache.writeQuery({
                  query: CURRENT_USER_QUERY,
                  data: {
                    currentUser: {
                      currentUser,
                      account: { ...currentUser.account, logo: file },
                    },
                  },
                });
              }
            }
          },
        })
          .then(res => {
            onDrop(acceptedFiles);
          })
          .catch(err => {
            console.error(err);
            setFileState('error');
          })
      : setFileState('error');
    fileToUpload && setFileToUpload && setFileToUpload(fileToUpload);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFileDrop,
    multiple: false,
    accept: acceptedFormat,
  });
  return (
    <>
      <DragDrop {...getRootProps()}>
        <input {...getInputProps()} />
        <img src={logo} alt="Upload your file" style={{ width: '200px' }} />
        {options?.loading ? (
          <DragDropText>Uploading...</DragDropText>
        ) : (
          <>
            <DragDropText>{t('Drag & drop')}</DragDropText>
            <BrowseFile>{t('or browse your file')}</BrowseFile>
            <br />
            <FormatInformations>{t(`Only ${acceptedFormat.sort().join(' and ')} are accepted`)}</FormatInformations>
          </>
        )}
      </DragDrop>
    </>
  );
};

const mapStateToProps = state => ({
  currentUser: get(state, ['auth', 'user']),
});

export default connect(mapStateToProps)(NewDataContainer);
