import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { grayShades } from '../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../styles/abstracts/typography';
import { spacing } from '../../../../styles/abstracts/variables';
import QTitle from '../../../../_Components/Texts/QTitle/QTitle';
import QInput from '../../../../_Components/Fields/QInput/QInput';
import SaveReportingModel from '../apollo/Mutations/SaveReportingModel';

const Header = styled(ModalHeader)`
  display: block !important;
  border: none !important;
`;

const Title = styled(QTitle)`
  padding: 0;
  margin: ${spacing.mediumSmall} auto 0 auto;
  text-align: center;
`;

const Subtitle = styled.p`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  font-weight: bold;
  margin: 0 auto;
  text-align: center;
`;

const StyledQInput = styled(QInput)`
  width: 100%;
`;

const CharactersMax = styled.p`
  font-size: ${fontSizes.smaller};
  color: ${grayShades.g800};
  margin: ${spacing.small};
`;

const CancelButton = styled.p`
  cursor: pointer;
  color: ${grayShades.g800};
  font-weight: bold;
  font-size: ${fontSizes.smaller};
`;

class InputNameModal extends React.Component {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
    template: PropTypes.bool,
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any),
  };

  constructor(props) {
    super(props);
    this.state = {
      templateName: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitTemplateName = this.submitTemplateName.bind(this);
  }

  handleChange(event) {
    this.setState({ templateName: event.target.value });
  }

  submitTemplateName() {
    this.props.toggle();
    this.setState({ templateName: '' });
  }

  render() {
    const { t } = this.props;
    return (
      <Modal centered {...this.props}>
        <Header>
          <Title>{t(this.props.title)}</Title>
          <Subtitle>{t(this.props.subtitle)}</Subtitle>
        </Header>
        <br />
        <ModalBody>
          <StyledQInput
            primary
            maxLength={50}
            value={this.state.templateName}
            onChange={this.handleChange}
          />
          <CharactersMax>{t('card:50 characters max.')}</CharactersMax>
        </ModalBody>
        <ModalFooter>
          <CancelButton onClick={this.props.toggle}>
            {t('button:Cancel')}
          </CancelButton>
          <SaveReportingModel
            toggle={this.props.toggle}
            submitTemplateName={this.submitTemplateName}
            templateName={this.state.templateName}
            button={this.props.button}
            data={this.props.data}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

InputNameModal.defaultProps = {
  template: false,
  data: {},
};

export default withTranslation(['card', 'button'])(InputNameModal);
