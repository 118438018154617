import ConflictSubtitle from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/DateRangeConflictSubtitle';
import { Collapse } from 'reactstrap';
import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { CONTEXT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import { MessageTable } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/MessageTable';
import { CollapseContent } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/CollapseContent';
import { capitalize } from '_Utils/strings/stringsUtils';

const DateRangeConflictMessage = ({
  context,
  globalConflict,
  contentItem,
  period1,
  period2,
  isOpen,
  t,
  i18n,
}) => {
  function formatDate(date) {
    if (!date) return t('Invalid date');
    return moment(date)
      .locale(i18n.language)
      .format('LL');
  }
  return (
    <CollapseContent isOpen={isOpen}>
      <ConflictSubtitle
        context={context}
        globalConflict={!!globalConflict}
        itemTitle={contentItem && contentItem.title}
      />
      <Collapse isOpen={isOpen}>
        <MessageTable>
          <tr>
            <th />
            <th>{t('From')}</th>
            <th>{t('To')}</th>
          </tr>
          <tr>
            <td>
              {capitalize(
                t('cardColumn date range', { type: `$t(${context})` }),
              )}
            </td>
            <td>{period1 && formatDate(period1.startDate)}</td>
            <td>{period1 && formatDate(period1.endDate)}</td>
          </tr>
          <tr>
            <td>
              {capitalize(
                globalConflict
                  ? t('global context date range')
                  : t('cardColumn date range', {
                      type:
                        context === CONTEXT.CARD
                          ? t(CONTEXT.COLUMN)
                          : t(CONTEXT.CARD),
                    }),
              )}
            </td>
            <td>{period2 && formatDate(period2.startDate)}</td>
            <td>{period2 && formatDate(period2.endDate)}</td>
          </tr>
        </MessageTable>
      </Collapse>
    </CollapseContent>
  );
};

export default withTranslation('conflicts')(DateRangeConflictMessage);
