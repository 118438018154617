import React from 'react';
import { Modal, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import QcardHeader from '_Components/QcardPanel/QcardHeader/QcardHeader';
import { withTranslation } from 'react-i18next';
import QButton from '../Buttons/QButton/QButton';

const CModal = props => (
  <Modal
    isOpen={props.modal}
    className={props.className}
    size={props.size || 'lg'}
    next={props.next}
    prev={props.prev}
    backdrop
    scrollable
    toggle={props.toggle}
    centered={props.centered}
  >
    <QcardHeader {...props} />

    <ModalBody>
      <Row>
        <Col sm="12" className={props.classBody}>
          {props.children}
        </Col>
      </Row>
    </ModalBody>
    {!props.footer && !props.hideFooter && (
      <ModalFooter>
        {props.btnPrevName && (
          <QButton bgColor="grayShades.g800" onClick={props.prev} disabled={props.btnPrevDisabled}>
            {props.t(props.btnPrevName)}
          </QButton>
        )}
        <QButton bgColor="grayShades.g800" onClick={props.toggle}>
          {props.t(props.btnCloseName || 'Close')}
        </QButton>
        {props.btnNextName && (
          <QButton bgColor="grayShades.g800" onClick={props.next} disabled={props.btnNextDisabled}>
            {props.t(props.btnNextName)}
          </QButton>
        )}
      </ModalFooter>
    )}
    {props.footer && !props.hideFooter && <ModalFooter>{props.footer}</ModalFooter>}
  </Modal>
);

export default withTranslation('button')(CModal);
