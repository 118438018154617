import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import DataByThematicsGraph from '_Charts/PoleCharts/CBarGraphChart/DataByThematicsGraph';
import { INDICATORS_TYPE } from '_Cards/FilterCards/DataByFiltersCard/FiltersSettings';
import DropdownsContainer from './_Containers/DropdownsContainer/DropdownsContainer';

const GraphContainer = styled.div`
height: calc(100% - 1em);
`;

type DataByThematicsCardProps = {
  data: any[];
  indicatorsConfig: any;
  settings: any;x
  setSettings: any;
  filtersToDisplay: Array<{ id: string; label: string }>;
  defaultSettings: any;
  orderByItems: any[];
  setSideCardTitle: any;
  setMentions: any;
  setCardFilters: any;
  toggleDisplaySideCard: any;
  t: any;
};

const DataByThematicsCard = ({
  data,
  indicatorsConfig,
  settings,
  setSettings,
  filtersToDisplay,
  defaultSettings,
  orderByItems,
  setSideCardTitle,
  setMentions,
  setCardFilters,
  toggleDisplaySideCard,
  t,
}: DataByThematicsCardProps) => {
  const [title, setTitle] = useState<string>(
    `${t('Comparison')} ${defaultSettings &&
      t(`button:${defaultSettings.indicators[INDICATORS_TYPE.BADGE].label}`)}`,
  );

  useEffect(() => {
    if (defaultSettings && defaultSettings.indicators) {
      if (defaultSettings.indicators[INDICATORS_TYPE.BADGE].id === 'eindex') {
        setTitle(
          `${t('Emotional temperature')} ${t('of the selected categories')}`,
        );
      } else if (
        defaultSettings.indicators[INDICATORS_TYPE.BADGE].id === 'mentions'
      ) {
        setTitle(`${t('Volumes')} ${t('of the selected categories')}`);
      } else {
        setTitle(`
          ${t(
            defaultSettings.indicators[INDICATORS_TYPE.BADGE].label?.split(
              ' - ',
            )[0],
          )} ${t('of the selected categories')}
        `);
      }
    }
  }, [defaultSettings]);

  const [orderedData, setOrderedData] = useState(data);
  const sortData = dataToOrder => {
    const [splitIndex, splitOrder] =
      defaultSettings && defaultSettings.orderBy && defaultSettings.orderBy.id
        ? defaultSettings.orderBy.id.split('_')
        : orderByItems[1].subcategories[0].id.split('_');
    const filteredData =
      splitIndex === 'hierarchy'
        ? splitOrder === 'asc'
          ? dataToOrder
          : [...dataToOrder].reverse()
        : orderBy(
            dataToOrder,
            item => {
              if (item[splitIndex]) {
                const currentItem =
                  (defaultSettings.indicators[INDICATORS_TYPE.BAR].id &&
                    defaultSettings.indicators[INDICATORS_TYPE.BAR].id.split(
                      '.',
                    )[1]) ||
                  (defaultSettings.indicators[INDICATORS_TYPE.BADGE].id &&
                    defaultSettings.indicators[INDICATORS_TYPE.BADGE].id.split(
                      '.',
                    )[1]);
                if (item[splitIndex][currentItem]) {
                  return item[splitIndex][currentItem].delta;
                }
                if (item[splitIndex][currentItem] === null) {
                  return '';
                }
                return item[splitIndex] || 0;
              }
              const indicatorId =
                defaultSettings &&
                (defaultSettings.indicators[
                  INDICATORS_TYPE.BAR
                ].selected?.find(item => item?.includes(splitIndex)) ||
                  defaultSettings.indicators[
                    INDICATORS_TYPE.BADGE
                  ].selected?.find(item => item?.includes(splitIndex)));
              if (indicatorId) {
                const [id, subId, subSubId] = indicatorId.split('.');
                return item[id]?.[subId]?.[subSubId];
              }
              return item[splitIndex];
            },
            [splitOrder],
          );
    setOrderedData(filteredData);
  };

  const updateData = () => {
    const filteredData = defaultSettings
      ? data.filter(item => item.filter === defaultSettings?.selectedFilter)
      : data;
    setOrderedData(filteredData);
    sortData(filteredData);
  };

  useEffect(() => {
    updateData();
  }, [defaultSettings]);

  return (
    <QcardPanel
      title={title}
      hints={[HintsContent.HINT_DATA_BY_FILTERS]}
      dataByFiltersCard
      dropDownOptions={['EXPORT']}
    >
      <DropdownsContainer
        indicatorsConfig={indicatorsConfig}
        filtersToDisplay={filtersToDisplay}
        settings={settings}
        setSettings={setSettings}
        defaultSettings={defaultSettings}
        orderByItems={orderByItems}
      />
      <GraphContainer>
        <DataByThematicsGraph
          data={orderedData}
          indicators={defaultSettings?.indicators}
          indicatorsConfig={indicatorsConfig}
          setSideCardTitle={setSideCardTitle}
          setCardFilters={setCardFilters}
          setMentions={setMentions}
          toggleDisplaySideCard={toggleDisplaySideCard}
        />
      </GraphContainer>
    </QcardPanel>
  );
};

export default withTranslation('card')(DataByThematicsCard);
