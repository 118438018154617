import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconCircle from '../../../../../../_Components/Icons/IconCircle/IconCircle';
import { purple } from '../../../../../../styles/abstracts/colors';
import QRoundedButton from '../../../../../../_Components/Buttons/QRoundedButton/QRoundedButton';
import { spacing } from '../../../../../../styles/abstracts/variables';

const Text = styled.span`
  padding-left: ${spacing.mediumLarge};
`;

const StyledQRoundedButton = styled(QRoundedButton)`
  overflow: hidden;
`;

const PreviousButton = ({ label, className, onDeactivatePreview }) => (
  <StyledQRoundedButton
    outline
    onClick={onDeactivatePreview}
    className={className}
    disableHover
  >
    <IconCircle icon="INTERFACE_CHEVRON_LEFT" size={18} color={purple} />
    <Text>{label}</Text>
  </StyledQRoundedButton>
);

PreviousButton.propTypes = {
  onDeactivatePreview: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PreviousButton.defaultProps = {
  className: '',
};

export default PreviousButton;
