import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';
import { capitalize } from '_Utils/strings/stringsUtils';
import { purple, grayShades, emotionColors, sentimentColors } from '../../../../../styles/abstracts/colors';

const LegendTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${purple};
  text-align: left;
  line-height: 21px;
  margin-bottom: 0.5rem;
  text-decoration: underline;
`;

const LegendValue = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${grayShades.g800};
  text-align: left;
  line-height: 21px;
  text-decoration: none;
  margin-left: 0.5em;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 1rem 2rem;
  background-color: ${grayShades.g300};
  border-radius: 0.5em;
  margin: 1rem;
`;

const LegendElements = styled.div<{ nbOfColumns: number }>`
  column-width: 5.5rem;
  column-rule: ${({ nbOfColumns }) => (nbOfColumns > 2 ? 'none' : `1px solid ${grayShades.g600}`)};
  text-decoration: none;
  flex-wrap: wrap;
`;

const LegendElementsList = styled.div`
  list-style: none;
`;

const LegendElementsListItem = styled.div`
  break-inside: avoid;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const EmotionColor = styled.div<{ color: string }>`
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${({ color }) => color};
`;

type EmotionBreakdownBenchmarkCardFooterProps = {
  nbOfColumns: number;
  t: any;
  benchmarkDonutAnalysisAxisToDisplay: string;
};

const EmotionBreakdownBenchmarkCardFooter = ({
  nbOfColumns,
  t,
  benchmarkDonutAnalysisAxisToDisplay,
}: EmotionBreakdownBenchmarkCardFooterProps) => {
  const [legendToShow, setLegendToShow] = useState(emotionColors);

  useEffect(() => {
    if (benchmarkDonutAnalysisAxisToDisplay === 'emotions') {
      setLegendToShow(emotionColors);
    } else {
      setLegendToShow(sentimentColors);
    }
  }, [benchmarkDonutAnalysisAxisToDisplay]);

  return (
    <>
      {legendToShow && Object.keys(legendToShow).length > 0 && (
        <LegendContainer>
          <LegendTitle>{t('Legend')} :</LegendTitle>
          <LegendElements nbOfColumns={nbOfColumns}>
            <LegendElementsList>
              {Object.keys(legendToShow).map((legendItem: string) => {
                return (
                  <LegendElementsListItem key={legendItem}>
                    <EmotionColor color={legendToShow[legendItem]} />
                    <span style={{ marginLeft: '0.5em' }} />
                    <LegendValue>{capitalize(t(`emotion:${legendItem}`))}</LegendValue>
                  </LegendElementsListItem>
                );
              })}
            </LegendElementsList>
          </LegendElements>
        </LegendContainer>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  projectId: get(state, ['projects', 'currentProject', 'id']),
});

export default withTranslation('card')(connect(mapStateToProps)(EmotionBreakdownBenchmarkCardFooter));
