import styled from 'styled-components';
import QButton from '../QButton/QButton';
import { fontSizes } from '../../../styles/abstracts/typography';
import { spacing } from '../../../styles/abstracts/variables';

const QRoundedButton = styled(QButton)`
  display: flex !important;
  outline: none;
  place-content: center space-around;
  align-items: center;
  border-radius: 0 40px 40px 0 !important;
  font-size: ${fontSizes.default} !important;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  padding: ${spacing.mediumSmall} ${spacing.medium} !important;
`;

export default QRoundedButton;
