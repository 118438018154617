import gql from 'graphql-tag';
import ThematicValuesListFragment from './_Fragments/ThematicValuesListFragment';

const query = gql`
  query getCategoryGlobalBestAndPainPointsCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $dataCollectorId: Int
    $nbThematicsPerCategory: Int
    $level: String
  ) {
    results: getCategoryGlobalBestAndPainPointsCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dataCollectorId: $dataCollectorId
      nbThematicsPerCategory: $nbThematicsPerCategory
      level: $level
    ) {
      byEindex {
        ...ThematicValuesListFragment
      }
      byProjectBenchmark {
        ...ThematicValuesListFragment
      }
    }
  }
  ${ThematicValuesListFragment}
`;

export default query;
