import gql from 'graphql-tag';

const query = gql`
  query getLandingPageMessagesData {
    getLandingPageMessagesData {
      globalMessage {
        id
        title
        content
        link
        linkText
      }
      accountMessage {
        id
        title
        content
        link
        linkText
      }
      accountContentMessages {
        id
        title
        content
        link
        linkText
        mdContent
      }
    }
  }
`;

export default query;
