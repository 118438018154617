import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Tooltip } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import QButton from '_Components/Buttons/QButton/QButton';
import queryResults from 'Routes/Main/MainPage/_Components/ProjectListCard/getProjectListCardData';
import QcardPanel from '../../../../../_Components/QcardPanel/QcardPanel';
import ProjectList from './ProjectList';
import { grayShades } from '../../../../../styles/abstracts/colors';
import CQuery from '../../../../../_Container/QQuery/CQuery';

const Cards = styled.div`
  background-color: ${grayShades.g200};
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-top: 1px solid ${grayShades.g400};
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding: 2rem;
`;

const ProjectListQCardPanel = styled(QcardPanel)`
  max-height: calc(100vh - 7em - ${({ headerHeight }) => headerHeight}px);
`;

const ProjectListCard = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <ProjectListQCardPanel
      headerHeight={props.headerHeight}
      {...props}
      scroll
      buttonRight={
        props.currentUser.account?.planName !== 'emotion_corporate' && (
          <span>
            <QButton
              id="mainPageCreateProjectButton"
              onClick={() => props.history.push('/project-configuration')}
              bgColor="purple"
              hasOpacity
              icon="INTERFACE_PLUS_CIRCLE"
              iconPosition="left"
              size={16}
              disabled={
                !props.currentUser.hasEmailValidated ||
                !props.currentUser.account?.planName
              }
              popoverText={
                !props.currentUser.hasEmailValidated
                  ? props.t(
                      'You must validate your email address in order to create a new project.',
                    )
                  : !props.currentUser.account?.planName &&
                    props.t(
                      'You must have a plan in order to create a new project.',
                    )
              }
            >
              {' '}
              {props.t('Create a new project')}
            </QButton>
          </span>
        )
      }
    >
      <Row className="containContent">
        <Cards>
          <CQuery query={queryResults}>
            <ProjectList />
          </CQuery>
        </Cards>
      </Row>
    </ProjectListQCardPanel>
  );
};

export default withRouter(withTranslation('button')(ProjectListCard));
