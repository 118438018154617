import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Tooltip } from 'reactstrap';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import {
  grayShades,
  purple,
  red,
  green,
  lightBlue,
  opacity,
} from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import Icon from '_Components/Icons/Icon';
import DeleteSourceModal from './_Components/DeleteSourceModal/DeleteSourceModal';
import SourceRow from './_Components/SourceRow/SourceRow';

const Container = styled(QPanel)`
  padding: 0 !important;
  margin-bottom: 1em;
`;

const TitleContainer = styled.div`
  padding: 1em;
  display: inline-flex;
  align-items: center;
`;

const HeaderContainer = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  width: 1.5em;
  height: 1.5em;
  margin-right: 1em;
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Content = styled.div`
  padding: 1em;
  border-top: 1px solid ${grayShades.g300};
`;

const DeleteAllSources = styled.span`
  color: ${red};
  font-weight: bold;
  margin-right: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const NumberSources = styled.div`
  background-color: ${purple};
  color: white;
  font-weight: bold;
  font-size: ${fontSizes.smallest};
  padding: 0.125em 1em;
  border-radius: 10px;
  margin-left: 1em;
  margin-top: 0.75em;
`;

const DescriptionRow = styled(Row)`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  font-weight: bolder;
  margin-bottom: 1em;
`;

const AddSourceContainer = styled(Row)`
  padding: 1em;
`;

const Message = styled.p`
  /* color: ${green}; */
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const SourceAddedMessage = styled(Message)`
  color: ${green};
`;

const ErrorMessage = styled(Message)`
  color: ${red};
`;

const TooltipContainer = styled.span`
  color: ${lightBlue};
  font-size: ${fontSizes.smaller};
  font-weight: normal;
`;

const StyledTooltip = styled(Tooltip)`
  & > .tooltip-inner {
    max-width: ${({ target }) =>
      target === 'tripadvisor-link-examples' ? '70em' : '30em'} !important;
    background-color: ${opacity.lightBlue} !important;
    color: ${lightBlue} !important;
    font-weight: bolder;
    font-size: ${fontSizes.smaller};
  }

  & a {
    color: ${lightBlue};
    text-decoration: underline;
  }
`;

const SourceCard = ({ data, setSources, sources, projectId, slug, t, projectDetails}) => {
  const [isModalOpen, openModal] = useState(false);
  const [isSourceAdded, setSourceAdded] = useState(false);
  const [error, setError] = useState(null);
  const [isTooltipOpen, openTooltip] = useState(false);

  const source = data[0].projectSourceItem;
  const sourceIds = data.map(d => d.id);

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer type="panel">
          <LogoContainer>
            <Logo src={source.imageLink} alt={`Logo ${source.name}`} />
          </LogoContainer>
          <QTitle type="panel">{source.name}</QTitle>
          <NumberSources>{data.length}</NumberSources>
        </TitleContainer>
        <DeleteAllSources
          onClick={() => {
            sourceIds[0] === undefined
              ? setSources(sources.filter(s => s.projectSourceItem.id))
              : openModal(true);
          }}
        >
          <Icon icon="TRASH" size={20} color={red} />
          {t('button:Delete this source for future processings')}
        </DeleteAllSources>
        <DeleteSourceModal
          source={data[0].source}
          projectId={projectId}
          slug={slug}
          ids={sourceIds}
          setSources={setSources}
          isModalOpen={isModalOpen}
          openModal={openModal}
          t={t}
        />
      </HeaderContainer>
      <Content>
        <DescriptionRow>
          <Col xs={3}>{t('Name of the source')}</Col>
          {data[0].source !== 'GooglereviewsPage' ? (
            <>
              <Col xs={3}>
                {t('Link to the pages to scan')}{' '}
                <TooltipContainer id="tripadvisor-link-examples">
                  {t('Help')}
                  <StyledTooltip
                    isOpen={isTooltipOpen}
                    toggle={() => openTooltip(!isTooltipOpen)}
                    target="tripadvisor-link-examples"
                    placement="top"
                    autohide={false}
                  >
                    <p>{t('Copy your link in the input below.')}</p>
                    <p>
                      {t('Warning')} :{' '}
                      {t(
                        'the TripAdvisor links containing "ShowTopic", "Tourism" or "Profile" are not accepted.',
                      )}
                    </p>
                  </StyledTooltip>
                </TooltipContainer>
              </Col>
            </>
          ) : (
            <>
              <Col xs={9}>
                Google Place ID{' '}
                <TooltipContainer id="find-place-id">
                  {t('How to find it?')}
                  <StyledTooltip
                    isOpen={isTooltipOpen}
                    toggle={() => openTooltip(!isTooltipOpen)}
                    target="find-place-id"
                    placement="top"
                    autohide={false}
                  >
                    {t(
                      'Just click on the input below, then search your business on the map. Once you\'re done, click "Confirm".',
                    )}
                  </StyledTooltip>
                </TooltipContainer>
              </Col>

              {/* <Col xs={4}>{t('Search term')}</Col> */}
            </>
          )}
        </DescriptionRow>
        {data?.map(d => (
          <SourceRow
            data={d}
            source={data[0].source}
            setSources={setSources}
            sources={sources}
            projectId={projectId}
            slug={slug}
            projectSourceItem={data[0].projectSourceItem}
            t={t}
            setSourceAdded={setSourceAdded}
            setError={setError}
            error={error}
            projectDetails={projectDetails}
          />
        ))}
        <AddSourceContainer>
          <QButton
            hasOpacity
            onClick={() => {
              setSources([
                ...sources,
                {
                  profile: '',
                  profileName: '',
                  source: data[0]?.source,
                  projectSourceItem: data[0]?.projectSourceItem,
                },
              ]);
            }}
          >
            {t('button:Add a source')}
          </QButton>
          {isSourceAdded && (
            <SourceAddedMessage>{t('Source added')}</SourceAddedMessage>
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </AddSourceContainer>
      </Content>
    </Container>
  );
};

export default SourceCard;
