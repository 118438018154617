import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addPageByShortcut } from '../../../../../../_Resources/Reporting/Actions/addPageByShortcut';
import QLayout from '../../../../../../_Components/Layouts/QLayout/QLayout';
import PagesShortcuts from '../../../_Components/PagesShortcuts';
import emptyLayouts from '../../layouts/emptyLayouts';
import { PAGES_SHORTCUT_MODES } from '../../../_Components/Shortcut';

class DefaultLayouts extends Component {
  static propTypes = {
    onPageAdd: PropTypes.func.isRequired,
  };

  getShortcuts = () =>
    emptyLayouts.map(shortcut => ({
      ...shortcut,
      content: () => (
        <QLayout
          active={false}
          layout={shortcut.children}
          cssGridLayout={shortcut.cssGridLayout}
        />
      ),
    }));

  render() {
    const { onPageAdd } = this.props;
    return (
      <PagesShortcuts
        mode={PAGES_SHORTCUT_MODES.EMPTY_LAYOUTS}
        shortcuts={this.getShortcuts()}
        onShortcutClicked={onPageAdd}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onPageAdd: (...args) => dispatch(addPageByShortcut(...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(DefaultLayouts);
