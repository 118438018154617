import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { lightBlue, grayShades } from 'styles/abstracts/colors';
import { connect } from 'react-redux';
import { get } from 'lodash';
import QButton from '_Components/Buttons/QButton/QButton';
import Select from 'react-select';
import Icon from '_Components/Icons/Icon';
import CreateUserModal from './_Containers/CreateUserModal/CreateUserModal';

const Container = styled.div`
  min-height: 40em;
  min-width: 20em;
  overflow-y: scroll;
  border-right: 1px solid ${grayShades.g300};
`;

const AddUserContainer = styled.div`
  background-color: white;
  top: 0;
  position: sticky !important;
  display: flex;
  justify-content: center;
  padding: 1em;
  border-bottom: 1px solid ${grayShades.g300};
  min-height: 4.5em;

  & span {
    padding: 0 2.5em;
  }
`;

const ByGroupDropdown = styled.div`
  background-color: white;
  top: 4.5em;
  min-height: 5em;
  position: sticky !important;
  display: flex;
  justify-content: center;
  padding: 1em;
  border-bottom: 1px solid ${grayShades.g300};
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const LabelContainer = styled.div`
  padding: 1.5em;
  border-bottom: 1px solid ${grayShades.g300}

  &:before {
    content: '';
    position: absolute;
    left: 10%;
    bottom: 0;
    height: 1px;
    width: 80%;
    border-bottom: 2px solid ${grayShades.g300};
  }
`;

const Label = styled.span`
  margin-left: 1em;
  font-weight: bold;
  color: ${({ isSelected }) => (isSelected ? lightBlue : grayShades.g800)};
  cursor: pointer;
`;

const StyledQButton = styled(QButton)`
  // width: 75%;
  display: flex !important;
  align-items: center;
  justify-content: space-around;
`;

const SideMenu = ({
  usersTabId,
  selectedItem,
  selectItem,
  availableGroups,
  data,
  t,
  canCreateUser,
}) => {
  const dropdownOptions = availableGroups.map(g => ({
    value: g.id,
    label: t(g.name),
  }));
  const location = useLocation();
  const [selectedOption, selectOption] = useState(dropdownOptions[0]);
  const [dataToShow, setDataToShow] = useState(data);
  const [isModalOpen, openModal] = useState(location?.state?.fromLandingPage);
  useEffect(() => {
    setDataToShow(data);
  }, [usersTabId, data]);

  useEffect(() => {
    selectedOption.value !== 'all' && selectItem(dataToShow?.[0]);
  }, [selectedOption]);
  return (
    <Container>
      <>
        <AddUserContainer isGroupTab={usersTabId === 'groups'}>
          {usersTabId === 'users' && (
            <StyledQButton
              id="createUserButton"
              hasOpacity
              icon="INTERFACE_PLUS_CIRCLE"
              iconPosition="left"
              size="small"
              onClick={() => openModal(true)}
              disabled={!canCreateUser}
              popoverText={
                !canCreateUser &&
                t(
                  "header:You can't create more users. Please upgrade your plan in order to create more users.",
                )
              }
            >
              <CreateUserModal
                isOpen={isModalOpen}
                toggle={openModal}
                users={dataToShow}
                t={t}
              />
              <span>{t('Add a user')}</span>
            </StyledQButton>
          )}
        </AddUserContainer>
        {usersTabId === 'users' && (
          <ByGroupDropdown>
            <StyledSelect
              name="group-selection"
              value={selectedOption}
              className="selectInput"
              searchable={false}
              clearable={false}
              onChange={value => {
                let filteredUsers;
                if (value.value === 'administrators') {
                  filteredUsers = data.filter(d => d.isAdmin);
                } else if (value.value === 'users') {
                  filteredUsers = data.filter(d => !d.isAdmin);
                } else {
                  filteredUsers = data;
                }
                selectOption(value);
                setDataToShow(filteredUsers);
              }}
              options={dropdownOptions}
              arrowRenderer={() => (
                <Icon icon="INTERFACE_CHEVRON_DOWN" color="secondary" />
              )}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </ByGroupDropdown>
        )}
      </>
      {dataToShow?.map(n => (
        <LabelContainer isUserSelected={false}>
          <Label
            onClick={() => selectItem(n)}
            isSelected={
              n?.value
                ? n?.value === selectedItem?.value
                : n?.id === selectedItem?.id
            }
          >
            {t(n.label) || `${n.firstName} ${n.lastName}`}
          </Label>
        </LabelContainer>
      ))}
    </Container>
  );
};

const mapStateToProps = state => ({
  canCreateUser: get(state, ['auth', 'user', 'account', 'canCreateUser']),
});

export default connect(mapStateToProps)(
  withTranslation('button', 'header')(SideMenu),
);
