import gql from 'graphql-tag';

const query = gql`
  query exportProjectData(
    $thematicsFormat: String!
    $fileExtension: String!
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $dataCollectorId: Int
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $selectedEmotions: [Emotions!]
    $selectedClusters: [Cluster!]
  ) {
    exportProjectData(
      thematicsFormat: $thematicsFormat
      fileExtension: $fileExtension
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      dataCollectorId: $dataCollectorId
      thematics: $thematics
      keywords: $keywords
      selectedEmotions: $selectedEmotions
      selectedClusters: $selectedClusters
    ) {
      code
      message
    }
  }
`;

export default query;
