import { merge } from 'lodash';

const diffEntryPoint = (current, prev) =>
  Object.keys(prev).reduce(
    (entryPoint, entryName) => ({
      ...entryPoint,
      [entryName]: {
        ...current[entryName],
        value: current[entryName].value - prev[entryName].value,
      },
    }),
    {},
  );

export default insight => {
  const {
    entryPoints: { current, previousPeriod, previousYear },
  } = insight;
  return merge(insight, {
    entryPoints: {
      current,
      previousPeriod: diffEntryPoint(current, previousPeriod),
      previousYear: diffEntryPoint(current, previousYear),
    },
  });
};
