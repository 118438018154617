import gql from 'graphql-tag';
import FRAGMENTS from '_Resources/Cards/Services/GraphQl/FRAGMENTS';

const query = gql`
  query getVerbatim($id: Int, $type: VerbatimOrigin!, $uuid: String) {
    getVerbatim(id: $id, type: $type, uuid: $uuid) {
      ...VerbatimFragment
    }
  }
  ${FRAGMENTS.verbatim}
`;

export default query;
