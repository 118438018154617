import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QPanelHeader from '../QPanelHeader/QPanelHeader';
import QAccordionPanelHeader from './QAccordionPanelHeader';

class QAccordionPanel extends PureComponent {
  state = {
    open: false,
  };

  onHeaderClick = () => {
    this.togglePanel();
  };

  togglePanel() {
    const { onToggle } = this.props;
    this.setState(
      prevState => ({
        open: !prevState.open,
      }),
      () => onToggle && onToggle({ open: this.state.open }),
    );
  }

  render() {
    const { title, children, t } = this.props;
    return (
      <div>
        {title && (
          <QPanelHeader type="menu" onClick={this.onHeaderClick}>
            <QAccordionPanelHeader
              isCollapsed={!this.state.open}
              title={t(title)}
            />
          </QPanelHeader>
        )}
        {this.state.open && <div>{children}</div>}
      </div>
    );
  }
}

QAccordionPanel.propTypes = {
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

QAccordionPanel.defaultProps = {
  onToggle: null,
};

export default withTranslation('card')(QAccordionPanel);
