import React from 'react';
import styled from 'styled-components';
import ReportInfos from './_Components/ReportInfos';
import ReportOptions from './_Components/ReportOptions';
import { grayShades } from '../../../../../../../../../../../styles/abstracts/colors';

const Container = styled.tr`
  &:hover {
    background-color: ${grayShades.g300} !important;
  }
`;

class ReportsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      isLinkCopied: false,
      fadeIn: false,
    };
  }

  handleMouseEnter() {
    this.setState({ isHovered: true });
  }

  handleMouseLeave() {
    this.setState({ isHovered: false });
  }

  selectReportLink(el) {
    const range = document.createRange();
    range.selectNodeContents(el);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  copyToClipboard() {
    const link = document.getElementById(`link${this.props.data.id}`);
    this.setState({ isLinkCopied: true, fadeIn: true }, () => {
      this.selectReportLink(link);
      document.execCommand('copy');
    });
    setTimeout(() => {
      this.setState({ isLinkCopied: false, fadeIn: false });
    }, 2000);
  }

  render() {
    return (
      <Container
        onMouseEnter={() => this.handleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        <ReportInfos
          data={this.props.data}
          isLinkCopied={this.state.isLinkCopied}
        />
        <ReportOptions
          copyToClipboard={() => this.copyToClipboard()}
          isHovered={this.state.isHovered}
          isLinkCopied={this.state.isLinkCopied}
          data={this.props.data}
          fadeIn={this.props.fadeIn}
        />
      </Container>
    );
  }
}

export default ReportsRow;
