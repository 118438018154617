import React, { createRef } from 'react';
import { find, get } from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import EindexEvolutionWithMentionsSection from '_Cards/EIndexCards/EindexEvolutionWithMentionsCard/EindexEvolutionWithMentionsSection';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import { EindexTemperaturecardSection } from '_Cards/EIndexCards/EindexTemperatureCard/EindexTemperatureCard';
import SatisfactionNpsSection from '_Cards/SatisfactionCards/SatisfactionNpsCard/SatisfactionNpsSection';
import MentionPreviewPerEmotionsCard from '_Cards/MentionsCards/MentionPreviewPerEmotions/MentionPreviewPerEmotionsCard';
import CategoryGlobalBestAndPainPointsCardSection from '_Cards/ThematicsCard/CategoryGlobalBestAndPainPointsCard/CategoryGlobalBestAndPaintPointCardSection';
import './style.scss';

const StyledQCardPanel = styled(QcardPanel)`
  border-radius: ${({ noBorderRadius }) => noBorderRadius && ` 0 !important`};
`;

const Pane = styled(TabPane)`
  background: white !important;
`;

class SidePanelCard extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 1,
      headerWidth: null,
    };
    this.setHeaderWidth = this.setHeaderWidth.bind(this);
  }

  headerRef = createRef(null);

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  setHeaderWidth(headerWidth) {
    this.setState({ headerWidth });
  }

  componentDidMount() {
    this.setHeaderWidth(this.headerRef?.current?.offsetWidth);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.headerRef?.current?.offsetWidth !== prevState.headerWidth) {
      this.setHeaderWidth(this.headerRef?.current?.offsetWidth);
    }
  }

  render() {
    const { t, markerInfos, filters, filterKeys } = this.props;
    const { headerWidth } = this.state;
    let mentionCount = null;
    let currentFilters;
    let locationTitle;
    if (markerInfos) {
      currentFilters = {
        range: markerInfos.range,
        criteriaKeys: markerInfos.criteriaKeys || [],
        thematics: markerInfos.thematics || [],
        projectId: markerInfos.projectId,
        texts: markerInfos.texts || filterKeys.texts,
        dataCollectorId: filterKeys.dataCollectorId,
      };
      if (currentFilters && currentFilters.texts) {
        currentFilters.keywords = get(
          find(currentFilters.texts, item => item.name === 'keywords'),
          'values',
        );
        delete currentFilters.texts;
      }
      mentionCount = markerInfos.mentionCount;
      locationTitle = `${this.props.title}: ${markerInfos.marker.name}`;
    } else {
      currentFilters = filters || null;
      mentionCount = filters ? filters.mentions : null;
    }
    const subHeader = (
      <Nav tabs className="justify-content-center">
        {[
          'Main indicator_plural',
          'E-index evolution',
          'Verbatim preview',
          this.props.disableBPPTab ? null : 'Global best & pain points',
        ].map((navLink, i) => (
          <NavItem>
            <NavLink
              className={`${this.state.activeTab === i + 1 ? 'tabActive' : ''} Qtabs`}
              onClick={() => {
                this.toggle(i + 1);
                this.setHeaderWidth(this.headerRef?.current?.offsetWidth);
              }}
            >
              {t(navLink)}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    );
    const noDataMessage = <NoDataMessage mainTitle="Please select a location" />;
    return (
      <StyledQCardPanel
        className={this.props.className}
        {...this.props}
        hints={[
          HintsContent.HINT_EINDEX_TEMPERATURE,
          HintsContent.HINT_SAT_NPS,
          HintsContent.HINT_EINDEX_EVOLUTION,
          HintsContent.HINT_VERBATIM_PREVIEW,
        ]}
        subHeader={currentFilters && subHeader}
        numberOfMentions={mentionCount}
        displayCardSubTitle={!!currentFilters}
        title={locationTitle || this.props.title || this.props.cardTitle}
        noBorderRadius={this.props.noBorderRadius}
      >
        <TabContent className="tabContent h-100" activeTab={this.state.activeTab}>
          <Pane key={1} tabId={1} className="overflowNone">
            {!currentFilters ? (
              noDataMessage
            ) : (
              <Col>
                <Row className="mt-6 d-flex align-items-center align-content-center h-100">
                  <Col>
                    <EindexTemperaturecardSection filters={currentFilters} />
                  </Col>
                </Row>
                <Row className="mt-6">
                  <SatisfactionNpsSection filters={currentFilters} classNameCol={6} className="w-100" />
                </Row>
              </Col>
            )}
          </Pane>
          <Pane key={2} tabId={2} className="overflowNone">
            {!currentFilters ? (
              noDataMessage
            ) : (
              <Row className="pt-6 d-flex align-items-center align-content-center h-100">
                <Col className="h-100">
                  <EindexEvolutionWithMentionsSection mixLineBarChart filters={currentFilters} />
                </Col>
              </Row>
            )}
          </Pane>
          <Pane key={3} tabId={3} className="overflowNone">
            {!currentFilters ? (
              noDataMessage
            ) : (
              <Row className="d-flex align-items-center align-content-center h-100">
                <Col className="h-100">
                  <MentionPreviewPerEmotionsCard
                    title="Mention preview per emotions"
                    displayCardTitle={false}
                    displayCardSubTitle={false}
                    displayCardHeader={false}
                    filters={currentFilters}
                    categories={[]}
                    displayBackgroundCard={false}
                    lazyLoad
                    limitVerbatim={10}
                    offsetVerbatim={0}
                    headerRef={this.headerRef}
                    headerWidth={headerWidth}
                    setHeaderWidth={this.setHeaderWidth}
                  />
                </Col>
              </Row>
            )}
          </Pane>
          <Pane key={4} tabId={4} className="overflowNone">
            {!currentFilters ? (
              noDataMessage
            ) : (
              <Row className="d-flex align-items-center align-content-center h-100">
                <Col className="h-100">
                  <CategoryGlobalBestAndPainPointsCardSection filters={currentFilters} />
                </Col>
              </Row>
            )}
          </Pane>
        </TabContent>
      </StyledQCardPanel>
    );
  }
}
function mapStateToProps(state) {
  return {
    markerInfos: state.specificFilters['world-map'],
    filterKeys: get(state, ['filters', 'filterKeys']),
  };
}

export default connect(mapStateToProps)(withTranslation('card')(SidePanelCard));
