import React from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { resetPageSpecificFilters } from '_Resources/SpecificFiltering/Actions/resetPageSpecificFilters';
import NoConfigPage from '_Components/NoConfigPage/NoConfigPage';
import imgMap from '_Components/NoConfigPage/images/map_graph_gif_grey.gif';
import imgGraphs from '_Components/NoConfigPage/images/filter-details-tabs.gif';
import { isEmpty, get } from 'lodash';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import '../../../../node_modules/react-resizable/css/styles.css';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import layoutConfig from './config';
import { getComponentsLayout } from '../../_Utils/routeComponent';
import QResponsiveGridLayout from '../../../_Layouts/QResponsiveGridLayout/QResponsiveGridLayout';
import { getRoute } from '_Utils/routes/routeUtils';

class WorldMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idpage: 'world-map',
      layout: layoutConfig,
      layout_edit: null,
      numberOfMentions: null,
    };
  }

  componentDidMount() {
    getRoute(
      this.props.updateRoute,
      this.props.idpage,
      'place',
      this.props.projectConfiguration,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.idpage && this.props.idpage !== prevProps.idpage) ||
      prevProps.projectConfiguration !== this.props.projectConfiguration
    ) {
      getRoute(
        prevProps.updateRoute,
        this.props.idpage,
        'place',
        this.props.projectConfiguration,
      );
      this.setState({
        idpage: this.props.idpage,
        associatedThematic: null,
      });
    }
  }

  isConfigurationSet = config => {
    if (!config) return false;
    let isConfigSet = false;
    Object.keys(config).forEach(key => {
      if (!isConfigSet) {
        if (!isEmpty(config[key])) {
          isConfigSet = true;
        }
      }
    });
    return isConfigSet;
  };
  // componentWillUnmount() {
  //   this.props.resetPageSpecificAllFilters(this.props.idpage);
  // }
  render() {
    const { layout } = this.state;
    const layoutComponents = getComponentsLayout(
      layout,
      {},
      {
        numberOfMentions: this.state.numberOfMentions,
      },
    );
    const noConfigContent = {
      noConfigMainMessage: {
        message: 'MAP_MAIN_MESSAGE',
        image: null,
      },
      noConfigContent: [
        {
          image: imgMap,
          title: 'MAP_01_TITLE',
          description: 'MAP_01_CONTENT',
        },
        {
          image: imgGraphs,
          title: 'MAP_02_TITLE',
          description: 'MAP_02_CONTENT',
        },
      ],
      noConfigEndMessage: {
        message: 'DEFAULT_LAST_MESSAGE',
      },
    };
    return layout && this.isConfigurationSet(this.props.hiddenFilters) ? (
      <div>
        <Container fluid className="py-3 px-4">
          <QResponsiveGridLayout
            layout={layout}
            isResizable={this.props.editableLayout}
            margin={[0, 0]}
          >
            {layoutComponents}
          </QResponsiveGridLayout>
        </Container>
      </div>
    ) : (
      <div>
        <Container fluid className="py-5 px-4">
          <NoConfigPage config={noConfigContent} />
        </Container>
      </div>
    );
  }
}

WorldMap.defaultProps = {
  layoutDefault: layoutConfig,
  editableLayout: false,
};

function mapStateToProps(state) {
  return {
    // numberOfMentions: state.filter.mentions,
    editableLayout: state.routes.activeLayoutEdition,
    projectConfiguration: state.projectConfiguration,
    module: state.routes.route && state.routes.route.name,
    hiddenFilters: get(state, ['routes', 'pageConfiguration', 'hiddenFilters']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
    resetPageSpecificAllFilters: newState => {
      dispatch(resetPageSpecificFilters(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorldMap);
