import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { fontSizes } from 'styles/abstracts/typography';
import { grayShades } from 'styles/abstracts/colors';
import { availableLanguages } from 'Routes/Settings/ProjectConfiguration/utils';
import DataConfigurationSummary from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ConfigurationValidation/_Components/DataConfigurationSummary/DataConfigurationSummary';

const Section = styled.div`
  margin-bottom: 2em;

  span::before {
    content: '•';
    margin-right: 0.5em;
  }
`;

const SectionTitle = styled.p`
  font-weight: bold;
`;

const SourceLink = styled.p`
  font-size: ${fontSizes.smaller};
  color: ${grayShades.g800};
  font-weight: normal;
  margin-left: 1.5em;
`;

const ConfigurationValidation = ({
  sources,
  selectedSources,
  startDate,
  settings,
  lang,
  t,
}) => {
  const {
    preferDate,
    recoDenominator: recoMark,
    satDenominator: satMark,
  } = settings;
  const numberLinks = sources.map(source => source).length;
  return (
    <div>
      <Section>
        <SectionTitle>
          {selectedSources.length} {t('sources')} ({numberLinks} {t('links')})
        </SectionTitle>
        {selectedSources?.map(selectedSource => (
          <div>
            <span>{selectedSource.name}</span>
            {sources
              ?.filter(source => source.source === selectedSource.id)
              ?.map(source => (
                <SourceLink>{source.profile}</SourceLink>
              ))}
          </div>
        ))}
      </Section>
      {/* <Section>
        <SectionTitle>{t('Data extraction period')}</SectionTitle>
        <p>
          {t('From')} {startDate?.format('L')} {t('to')}{' '}
          {moment()
            .subtract(1, 'day')
            .format('L')}
        </p>
      </Section> */}
      <Section>
        <SectionTitle>{t('Data configuration')}</SectionTitle>
        <span>
          {t('Display language')} :{' '}
          {availableLanguages.find(language => language.value === lang)?.label}
        </span>{' '}
        <br />
        <DataConfigurationSummary
          recoMark={recoMark}
          satMark={satMark}
          preferDate={preferDate}
          t={t}
        />
      </Section>
    </div>
  );
};

export default ConfigurationValidation;
