import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useQuery, withApollo } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import { grayShades, lightBlue } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import UpgradePlanModal from './_Components/UpgradePlanModal/UpgradePlanModal';
import CUSTOMER_PORTAL_QUERY from './graphql/customerPortal';
import ACCOUNT_DETAILS_QUERY from './graphql/accountDetails';

const Container = styled(QPanel)`
  text-align: center;
  font-weight: bold;
  width: 49%;
`;

const SubTitle = styled.span`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
`;

const SubLink = styled.p`
  font-size: ${fontSizes.smaller};
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0.5em;
`;

const ChildContainer = styled.div`
  margin-top: 5em;
  font-size: ${fontSizes.default};
  margin-bottom: ${({ last }) => last && '5em'};
`;

const Number = styled.span`
  color: ${lightBlue};
  font-size: calc(${fontSizes.large} * 1.5);
  font-weight: bold;
`;

const Button = styled(QButton)`
  margin-top: 1em;
`;

const PlanPricingCard = ({ accountId, client, t }) => {
  const [isUpgradeModalOpen, openUpgradeModal] = useState(false);
  const [addMoreCredits, toggleAddMoreCredits] = useState(false);
  const { data: accountDetailsData } = useQuery(ACCOUNT_DETAILS_QUERY, {
    variables: { accountId },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container>
      {accountDetailsData?.accountDetails?.planName ? (
        <>
          <div>
            <QTitle position="center">
              {accountDetailsData?.accountDetails?.planName
                .split('_')
                .map(str => str.charAt(0).toUpperCase() + str.slice(1))
                .join(' ')}
            </QTitle>
            {/* eslint-disable-next-line no-nested-ternary */}
            {accountDetailsData?.accountDetails?.planName ===
            'emotion_start' ? (
              accountDetailsData?.accountDetails?.validUntil && (
                <SubTitle>
                  {t('Credits valid until')} :
                  {moment(
                    accountDetailsData?.accountDetails?.validUntil,
                  ).format('LL')}
                </SubTitle>
              )
            ) : accountDetailsData?.accountDetails?.subscriptionCancelAtDate ? (
              <SubTitle>
                {t('Will be cancelled on')}:{' '}
                {moment(
                  accountDetailsData?.accountDetails?.subscriptionCancelAtDate,
                ).format('LL')}
              </SubTitle>
            ) : (
              <SubTitle>
                {t('Next deadline')}:{' '}
                {moment(
                  accountDetailsData?.accountDetails?.subscriptionResetDate,
                ).format('LL')}
              </SubTitle>
            )}
          </div>
          <ChildContainer>
            <p>{t('Remaining credits')} :</p>
            <Number>
              {accountDetailsData?.accountDetails?.remainingCredits || 0}
            </Number>
            <p>
              / {accountDetailsData?.accountDetails?.totalCredits || 0}{' '}
              {accountDetailsData?.accountDetails?.planName.includes('yearly')
                ? t('yearly credits')
                : accountDetailsData?.accountDetails?.planName.includes(
                    'monthly',
                  )
                ? t('monthly credits')
                : t('total credits')}
            </p>
            {accountDetailsData?.accountDetails?.planName ===
              'emotion_start' && (
              <>
                <Button hasOpacity onClick={() => openUpgradeModal(true)}>
                  {t('button:Upgrade your plan')} !
                </Button>
                <UpgradePlanModal
                  isOpen={isUpgradeModalOpen}
                  open={openUpgradeModal}
                  currentPlan={accountDetailsData?.accountDetails?.planName}
                  addMoreCredits={addMoreCredits}
                  toggleAddMoreCredits={toggleAddMoreCredits}
                  t={t}
                  address={accountDetailsData?.accountDetails?.address}
                  companyName={accountDetailsData?.accountDetails?.companyName}
                />
                <SubLink
                  onClick={() => {
                    openUpgradeModal(true);
                    toggleAddMoreCredits(true);
                  }}
                >
                  {t('button:Add more credits')}
                </SubLink>
              </>
            )}
            <>
              <div>
                {(accountDetailsData?.accountDetails?.planName?.includes(
                  'emotion_plus',
                ) ||
                  accountDetailsData?.accountDetails?.planName?.includes(
                    'emotion_pro',
                  )) && (
                  <Button
                    hasOpacity
                    onClick={async () => {
                      await client
                        .query({
                          query: CUSTOMER_PORTAL_QUERY,
                        })
                        .then(({ data: { customerPortal } }) => {
                          window.open(customerPortal.url);
                        });
                    }}
                  >
                    {t('button:Manage your payment settings')}
                  </Button>
                )}{' '}
                {accountDetailsData?.accountDetails?.canAccessBilling && (
                  <Button
                    hasOpacity
                    onClick={async () => {
                      await client
                        .query({
                          query: CUSTOMER_PORTAL_QUERY,
                        })
                        .then(({ data: { customerPortal } }) => {
                          window.open(customerPortal.url);
                        });
                    }}
                  >
                    {t('button:Billing')}
                  </Button>
                )}
              </div>
              {(accountDetailsData?.accountDetails?.planName?.includes(
                'emotion_plus',
              ) ||
                accountDetailsData?.accountDetails?.planName?.includes(
                  'emotion_pro',
                )) && (
                <>
                  <SubLink onClick={() => openUpgradeModal(true)}>
                    {t('button:See plans in detail')}
                  </SubLink>
                  <UpgradePlanModal
                    isOpen={isUpgradeModalOpen}
                    open={openUpgradeModal}
                    t={t}
                  />
                </>
              )}
            </>
          </ChildContainer>
          {/* TODO: Uncomment when working with credits  */}
          {/* <ChildContainer last>
              <p>Extra credits :</p>
              <Number>{randomNumber2}</Number>
              <p>extra credits</p>
              <Button hasOpacity>Refill extra credits</Button>
            </ChildContainer> */}
        </>
      ) : (
        <>
          <QTitle>{t('Plan & pricing')}</QTitle>
          <ChildContainer>
            <p>{t('You are not currently subscribed to a plan.')}</p>
            <Button
              id="noPlanButton"
              hasOpacity
              onClick={() => openUpgradeModal(true)}
            >
              {t('button:Choose a plan')}
            </Button>
            <UpgradePlanModal
              isOpen={isUpgradeModalOpen}
              open={openUpgradeModal}
              t={t}
              address={accountDetailsData?.accountDetails?.address}
              companyName={accountDetailsData?.accountDetails?.companyName}
            />
          </ChildContainer>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  accountId: get(state, ['auth', 'user', 'account', 'id']),
});

export default withApollo(
  connect(mapStateToProps)(withTranslation('card', 'button')(PlanPricingCard)),
);
