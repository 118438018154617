import React from 'react';
import { capitalize, find } from 'lodash';

const prepareMapConfigToMetadata = (
  { key, allowAny, tagAnyLabel, category },
  type,
) => item => {
  const [id, parent] = item.split('|');
  const label = capitalize(id);
  return {
    type,
    key,
    parent,
    id,
    label,
    tagAnyLabel,
    allowAny,
    category,
  };
};

function prepareToNestedOptions(byKey) {
  return function toNestedOptions(metadata) {
    const { id, children } = metadata;
    return {
      ...metadata,
      subOptions:
        children &&
        (byKey[children] || [])
          .filter(({ parent }) => parent === id)
          .map(toNestedOptions),
    };
  };
}

function createCategoryPreview(relatedLevels, categoryToSearch, array = []) {
  const relatedLevel = find(relatedLevels, { id: categoryToSearch });
  array.push(relatedLevel.label);
  relatedLevel.childrenCriteriaKey !== null &&
    createCategoryPreview(
      relatedLevels,
      relatedLevel.childrenCriteriaKey,
      array,
    );

  return array;
}

function createHierarchyRelatedOptions(
  relatedLevels,
  categoryToSearch,
  array = [],
) {
  const relatedLevel = find(relatedLevels, { id: categoryToSearch });
  const newItemConfig = {
    key: relatedLevel.id,
    category: relatedLevel.label,
  };
  if (relatedLevel.childrenCriteriaKey) {
    newItemConfig.allowAny = true;
    newItemConfig.children = relatedLevel.childrenCriteriaKey;
    createHierarchyRelatedOptions(
      relatedLevels,
      relatedLevel.childrenCriteriaKey,
      array,
    );
  }
  array.push(newItemConfig);
  return array;
}

const createCategoryRoot = (related, type, projectId) => {
  const rootCategory = related.rootLevel;
  const modelRelatedOptions = [
    {
      categoryPreview: createCategoryPreview(related.levels, rootCategory),
      id: related.name,
      label: related.name,
    },
  ];
  const hierarchyRelatedOptions = createHierarchyRelatedOptions(
    related.levels,
    rootCategory,
  );
  const byKey = hierarchyRelatedOptions.reduce((acc, config) => {
    const { key, children } = config;
    const mapToMetadata = prepareMapConfigToMetadata(config, type);
    const category = find(related.levels, { id: key }).values;

    if (!category) return acc;

    const items = category.map(item => ({
      ...mapToMetadata(item),
      children,
    }));

    return {
      ...acc,
      [key]: items,
    };
  }, {});

  const toNestedOptions = prepareToNestedOptions(byKey);
  const roots = byKey[rootCategory].map(toNestedOptions);

  const categoryRoot = modelRelatedOptions;
  categoryRoot.forEach(root => {
    root.subOptions = roots;
  });
  return categoryRoot;
};

export const prepareRelatedToOptions = translate => (
  related,
  type,
  projectId,
) => createCategoryRoot(related, type, projectId);
