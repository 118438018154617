import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { MenuItemLabel } from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import {
  ANALYSIS_AXIS,
  DATE_RANGE_VALUES,
  EMOTIONS_EVOLUTION_DATA_DISPLAY,
  LABEL,
  LEVEL,
} from '../../../Routes/GlobalExperience/Reporting/_Containers/configs/settingOptions';
import { fontSizes } from '../../../styles/abstracts/typography';
import DataDisplaySettings from './_Containers/DataDisplaySettings';
import LevelDisplaySettings from './_Containers/LevelDisplaySettings';
import RangeDisplaySettings from './_Containers/RangeDisplaySettings';
import AnalysisAxisSettings from './_Containers/AnalysisAxisSettings';
import LegendSettings from './_Containers/LegendSettings';
import EvolutionBadgeSettings from './_Containers/EvolutionBadgeSettings';
import LabelSettings from './_Containers/LabelSettings';

export const RadioButton = styled.input`
  display: none;
`;

export const CheckboxContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5em;
  & ${MenuItemLabel} {
    margin-bottom: 0 !important;
    margin-right: 1em !important;
  }
`;

export const TitleSection = styled.p`
  font-size: ${fontSizes.bigger};
  margin-bottom: 0.5em;
`;

class CLineChartSettings extends Component {
  static propTypes = {
    settings: PropTypes.any.isRequired,
    onSettingsChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { settings, onSettingsChange } = this.props;
    if (!settings || isEmpty(settings)) {
      onSettingsChange({
        range: DATE_RANGE_VALUES.DAY.id,
        dataDisplay: EMOTIONS_EVOLUTION_DATA_DISPLAY.PERCENTAGE.value,
        analysisAxis: ANALYSIS_AXIS.EMOTION.id,
        legendShow: true,
        badgeShow: false,
        label: LABEL.EXTERNAL.id,
        level: LEVEL.SUB_SUB_THEMATIC.id,
      });
    }
  }

  TYPES_SETTINGS = {
    eindex: (analysisAxis, onSettingsChange, settings) => (
      <AnalysisAxisSettings analysisAxis={analysisAxis} onSettingsChange={onSettingsChange} settings={settings} />
    ),
    eindexBadge: (analysisAxis, onSettingsChange, settings) => (
      <EvolutionBadgeSettings onSettingsChange={onSettingsChange} settings={settings} />
    ),
    emotionsBreakdown: (analysisAxis, onSettingsChange, settings) => (
      <>
        <AnalysisAxisSettings analysisAxis={analysisAxis} onSettingsChange={onSettingsChange} settings={settings} />
        <LegendSettings onSettingsChange={onSettingsChange} settings={settings} />
        <LabelSettings onSettingsChange={onSettingsChange} settings={settings} />
      </>
    ),
    eindexEvolution: (analysisAxis, onSettingsChange, settings, range) => (
      <RangeDisplaySettings range={range} onSettingsChange={onSettingsChange} settings={settings} />
    ),
    emotionsEvolution: (analysisAxis, onSettingsChange, settings, range, dataDisplay) => (
      <>
        <RangeDisplaySettings range={range} onSettingsChange={onSettingsChange} settings={settings} />
        <DataDisplaySettings
          dataDisplay={dataDisplay}
          analysisAxis={analysisAxis}
          onSettingsChange={onSettingsChange}
          settings={settings}
        />
        <AnalysisAxisSettings analysisAxis={analysisAxis} onSettingsChange={onSettingsChange} settings={settings} />
      </>
    ),
    bestAndWorstCategories: (analysisAxis, onSettingsChange, settings, range, dataDisplay) => (
      <LevelDisplaySettings onSettingsChange={onSettingsChange} settings={settings} />
    ),
  };

  renderCheckboxes() {
    const { settings, settings: { range, dataDisplay, analysisAxis } = {}, onSettingsChange, type } = this.props;
    return <>{this.TYPES_SETTINGS[type](analysisAxis, onSettingsChange, settings, range, dataDisplay)}</>;
  }

  render() {
    return <div>{this.renderCheckboxes()}</div>;
  }
}

export default withTranslation('button')(CLineChartSettings);
