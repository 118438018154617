export const getHeaderTopHeight = () =>
  typeof window !== 'undefined' &&
  document.getElementById('headerTop') &&
  document.getElementById('headerTop').offsetHeight;

export const getMainFiltersContainerHeight = () =>
  typeof window !== 'undefined' &&
  document.getElementById('mainFiltersContainer') &&
  document.getElementById('mainFiltersContainer').offsetHeight;

export const getCollapsibleFiltersContainerHeight = () =>
  typeof window !== 'undefined' &&
  document.getElementById('collapsibleFiltersContainer') &&
  document.getElementById('collapsibleFiltersContainer').offsetHeight;

export const getOptionalHeaderHeight = () =>
  typeof window !== 'undefined' &&
  document.getElementById('optionalHeader') &&
  document.getElementById('optionalHeader').offsetHeight;

export const getAlertStripHeight = () =>
  typeof window !== 'undefined' &&
  document.getElementById('alertStripContainer') &&
  document.getElementById('alertStripContainer').offsetHeight;

export const getFreeTrialStripHeight = () =>
  typeof window !== 'undefined' &&
  document.getElementById('freeTrialStrip') &&
  document.getElementById('freeTrialStrip').offsetHeight;

export const getMenuContainerWidth = () =>
  typeof window !== 'undefined' &&
  document.getElementById('menuContainer') &&
  document.getElementById('menuContainer').offsetWidth;
