import bestAndWorstCategories from './cards/bestAndWorstCategories.png';
import bestVerbatim from './cards/bestVerbatim.png';
import worstVerbatim from './cards/worstVerbatim.png';
import eindex from './cards/eindex.png';
import eindexBarGraph from './cards/eindexBarGraph.png';
import eindexBadge from './cards/eindexBadge.png';
import eindexEvolution from './cards/eindexEvolution.png';
import emotionalIntensity from './cards/emotionalIntensity.png';
import emotionsBreakdown from './cards/emotionsBreakdown.png';
import emotionsEvolution from './cards/emotionsEvolution.png';
import mention from './cards/mention.png';
import satisfaction from './cards/satisfaction.png';
import speechEngagement from './cards/speechEngagement.png';
import wordCloud from './cards/wordCloud.png';

export default {
  bestAndWorstCategories,
  bestVerbatim,
  worstVerbatim,
  eindex,
  eindexBadge,
  eindexEvolution,
  eindexBarGraph,
  emotionalIntensity,
  emotionsBreakdown,
  emotionsEvolution,
  mention,
  satisfaction,
  speechEngagement,
  wordCloud,
};
