import React from 'react';
import { Col } from 'reactstrap';
import '_Components/TabEmotions/style.scss';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import { connect } from 'react-redux';
import { sendEmotionSelected } from '_Resources/Cards/Actions/sendEmotionSelected';
import { withTranslation } from 'react-i18next';
import { capitalize } from '_Utils/strings/stringsUtils';
import { clone } from '_Utils/other/clone';
import { emotions } from '../../constants';

class TabEmotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 'tabEmotions',
      filterEmotions: emotions.map(emotion => emotion.name),
      typeVertical: false,
    };
  }

  componentDidMount() {
    const { filterEmotions, dispatchSendEmotionSelected } = this.props;
    if (filterEmotions === undefined) {
      this.setState({
        filterEmotions: emotions.map(emotion => emotion.name),
      });
      dispatchSendEmotionSelected(emotions.map(emotion => emotion.name));
    }
    if (filterEmotions !== undefined) {
      this.setState({
        filterEmotions,
      });
      dispatchSendEmotionSelected(filterEmotions);
    }
  }

  handleEmotionButtonClick(value) {
    const { dispatchSendEmotionSelected } = this.props;
    let filterEmotions = clone(this.state.filterEmotions);
    if (value === 'all') {
      if (this.state.filterEmotions.length === emotions.length) {
        filterEmotions = [];
      } else {
        filterEmotions = emotions.map(emotion => emotion.value);
      }
    } else if (this.state.filterEmotions.includes(value)) {
      filterEmotions = filterEmotions.filter(e => e !== value);
    } else {
      filterEmotions = filterEmotions.concat(value);
    }
    this.setState({
      filterEmotions,
    });
    dispatchSendEmotionSelected(filterEmotions);
  }

  render() {
    const { t } = this.props;
    const all = {
      name: 'All emotions',
      value: 'all',
      color: '#592ea0',
      icon: 'PICTO_STAR',
    };
    const item = (emotion, i, checkOnActive) => (
      <div
        key={this.state.key + i}
        className={`buttonEmotionFilter ${
          this.props.typeVertical ? 'buttonEmotionFilterVertical' : 'buttonEmotionFilterHorizontal'
        } ${!checkOnActive && 'unchecked'}`}
        onClick={() => this.handleEmotionButtonClick(emotion.value)}
      >
        <IconCircle
          size={32}
          icon={emotion.icon}
          active={checkOnActive}
          color={emotion.color}
          customCSS={!this.props.typeVertical && 'iconCenter'}
        />
        <span
          className={`buttonEmotionText ${!this.props.typeVertical && 'buttonEmotionTextHorizontal'}`}
          style={!checkOnActive ? { color: '#A7ADC7' } : { color: emotion.color }}
        >
          {capitalize(t(emotion.name))}
        </span>
      </div>
    );

    return (
      <Col xs="auto" className={`${'border-right px-0'} ${!this.props.typeVertical && 'displayHorizontal'}`}>
        {item(all, 'all', this.state.filterEmotions.length === emotions.length)}
        {emotions.map((emotion, i) => item(emotion, i, this.state.filterEmotions.includes(emotion.value)))}
      </Col>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSendEmotionSelected: newState => {
      dispatch(sendEmotionSelected(newState));
    },
  };
}
export default connect(null, mapDispatchToProps)(withTranslation('emotion')(TabEmotions));
