import React, { useState } from 'react';
import styled from 'styled-components';
import QDropdown from '_Components/Menus/QDropdownMenu/QDropdown';
import { capitalize } from '_Utils/strings/stringsUtils';
import { lightBlue } from 'styles/abstracts/colors';
import QDropdownMenu, {
  QDropdownMenuItem,
} from '_Components/Menus/QDropdownMenu/QDropdownMenu';
import { AlertType } from '../../../../../../../_Cards/AlertsCards/AlertingCard/_Components/AlertingRow/AlertingRow';

const Requirement = styled.span<{ isEditMode: boolean }>`
  color: ${lightBlue};
  cursor: ${({ isEditMode }) => !isEditMode && 'pointer'};
  border-bottom: 1px solid ${lightBlue};
`;

const Menu = styled(QDropdownMenu)`
  min-width: 7em;
  max-height: 20em;
  overflow-y: scroll;
`;

const Item = styled(QDropdownMenuItem)`
  padding: 1em;
  width: 100%;
`;

const SELECTION_OPTIONS = {
  OR: 'one of these conditions',
  AND: 'all of these conditions',
};
type ConditionDropdownProps = {
  selector: 'and' | 'or';
  setSelector: any;
  alert: AlertType;
  secondary?: boolean;
  setSecondarySelector: any;
  t: any;
};

const SelectorDropdown = ({
  selector,
  setSelector,
  alert,
  secondary,
  setSecondarySelector,
  t,
}: ConditionDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  return (
    <QDropdown as="span">
      <Requirement
        onClick={() =>
          !alert && !secondary && setIsDropdownOpen(!isDropdownOpen)
        }
        isEditMode={!!alert || secondary}
      >
        {t(`button:${SELECTION_OPTIONS[selector.toUpperCase()]}`)}
      </Requirement>
      <Menu isOpen={isDropdownOpen} placement="down">
        {Object.values(SELECTION_OPTIONS).map(
          (value: string, index: number) => (
            <Item
              onClick={() => {
                setSelector(
                  Object.keys(SELECTION_OPTIONS)[index].toLowerCase(),
                );
                setSecondarySelector(
                  Object.keys(SELECTION_OPTIONS)
                    ?.find((o, ind) => ind !== index)
                    ?.toLowerCase(),
                );
                setIsDropdownOpen(!isDropdownOpen);
              }}
            >
              {capitalize(t(`button:${value}`))}
            </Item>
          ),
        )}
      </Menu>
    </QDropdown>
  );
};

export default SelectorDropdown;
