import React from 'react';
import { Mutation } from 'react-apollo';
import QButton from '_Components/Buttons/QButton/QButton';
import unarchiveProjectMutation from '_Cards/SettingsCards/ProjectsCard/graphql/archiveOrUnarchiveProject';
import getProjectListCardData from 'Routes/Main/MainPage/_Components/ProjectListCard/getProjectListCardData';
import queryProjectHeader from '_Resources/Header/Services/api/graphql/getHeaderProjectFiltersData';

const UnarchiveProjectButton = ({ projectId, t }) => (
  <Mutation
    mutation={unarchiveProjectMutation}
    variables={{ id: projectId, archive: false }}
    update={(cache, { data: { archiveOrUnarchiveProject } }) => {
      const activeProjectsList = cache.readQuery({
        query: getProjectListCardData,
      }).results.projects;
      const archivedProjectList = cache.readQuery({
        query: getProjectListCardData,
        variables: { archived: true },
      }).results.projects;
      const headerProjectsList = cache.readQuery({
        query: queryProjectHeader,
      }).data.projects;
      const projectToUnarchive = archivedProjectList.find(
        archivedProject => archivedProject.id === archiveOrUnarchiveProject.id,
      );
      projectToUnarchive.updatedAt = archiveOrUnarchiveProject.updatedAt;
      cache.writeQuery({
        query: queryProjectHeader,
        data: {
          data: {
            projects: [projectToUnarchive, ...headerProjectsList],
          },
        },
      });
      cache.writeQuery({
        query: getProjectListCardData,
        data: {
          results: {
            projects: [projectToUnarchive, ...activeProjectsList],
          },
        },
      });
      cache.writeQuery({
        query: getProjectListCardData,
        variables: { archived: true },
        data: {
          results: {
            projects: archivedProjectList.filter(
              archivedProject =>
                archivedProject.id !== archiveOrUnarchiveProject.id,
            ),
          },
        },
      });
    }}
  >
    {unarchiveProject => (
      <QButton
        bgColor="lightBlue"
        hasOpacity
        icon="INTERFACE_CHEVRON_UP"
        iconPosition="left"
        onClick={() => {
          unarchiveProject({
            variables: { id: projectId, archive: false },
          });
        }}
      >
        {' '}
        &nbsp;{t('Unarchive')}
      </QButton>
    )}
  </Mutation>
);

export default UnarchiveProjectButton;
