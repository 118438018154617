import React from 'react';
import 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/style.scss';
import img from 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/img/arow.png';

class Arrow extends React.Component {
  render() {
    return (
      <div className="arrow">
        <div className="inner_center">
          <img className="arrow_icon" src={img} />
        </div>
      </div>
    );
  }
}

export default Arrow;
