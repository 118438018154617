import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { find } from 'lodash';
import QDropdown from '_Components/Menus/QDropdownMenu/QDropdown';
import QDropdownMenu, {
  QDropdownMenuItem,
} from '_Components/Menus/QDropdownMenu/QDropdownMenu';
import { lightBlue, grayShades } from 'styles/abstracts/colors';

const Container = styled.span`
  display: inline-flex;
`;

const ToggleButton = styled.span`
  color: ${lightBlue};
  border-bottom: 1px solid ${lightBlue};
`;

const Item = styled(QDropdownMenuItem)`
  padding: 1em;
  width: 100%;
`;

const Menu = styled(QDropdownMenu)`
  min-width: 7em;
  max-height: 20em;
  overflow-y: scroll;
`;

const AutomationSettings = ({
  options,
  level,
  automationSettings,
  setAutomationSettings,
  t,
}) => {
  const getDefaultOption = () => {
    return find(options, item => item.id === automationSettings?.[level]);
  };

  const [isDropdownOpen, openDropdown] = useState(false);
  const [choseOption, setOption] = useState(getDefaultOption());

  useEffect(() => {
    const settingsToSend = {
      ...automationSettings,
      [level]: choseOption?.id,
    };
    if (
      automationSettings?.every === 'month' &&
      level === 'on' &&
      automationSettings?.[level] === 'monday'
    ) {
      setOption(find(options, item => item.id === 1));
    } else if (
      automationSettings?.every === 'week' &&
      level === 'on' &&
      automationSettings?.[level] === 1
    ) {
      setOption(find(options, item => item.id === 'monday'));
    }
    setAutomationSettings(settingsToSend);
  }, [choseOption, automationSettings?.every]);

  return (
    <Container>
      <QDropdown>
        <ToggleButton onClick={() => level !== 'every' && openDropdown(!isDropdownOpen)}>
          {t(`monthDays:${t(`button:${choseOption?.label}`)}`)}
        </ToggleButton>
        <Menu
          isOpen={isDropdownOpen}
          placement="down"
          onMouseLeave={() => openDropdown(false)}
        >
          {options.map(option => {
            return (
              <Item
                onClick={() => {
                  setOption(option);
                  openDropdown(!isDropdownOpen);
                }}
              >
                {t(`monthDays:${t(`button:${option.label}`)}`)}
              </Item>
            );
          })}
        </Menu>
      </QDropdown>
    </Container>
  );
};

export default AutomationSettings;
