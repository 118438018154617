import styled from "styled-components";
import { radius } from "../styles/commonProperties";

export default styled.div`
  margin: 2em;
  background-color: white;
  border-radius: ${radius.default};
  padding: 1em;
  font-family: Nunito;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
`;
