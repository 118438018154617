import gql from 'graphql-tag';

const mutation = gql`
  mutation stripeCreateCheckoutSession(
    $itemPrice: String!
    $itemQuantity: Int!
    $paymentType: StripePayment!
  ) {
    stripeCreateCheckoutSession(
      itemPrice: $itemPrice
      itemQuantity: $itemQuantity
      paymentType: $paymentType
    ) {
      sessionId
      errors
    }
  }
`;

export default mutation;
