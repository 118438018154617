import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Mutation } from 'react-apollo';
import mutationArchiveBenchmark from './graphql/archiveBenchmark';
import Icon from '_Components/Icons/Icon';
import { red } from '../../../styles/abstracts/colors';
import { ImageSize, spacing } from '../../../styles/abstracts/variables';
import { fontSizes } from '../../../styles/abstracts/typography';
import getBenchmarkList from './graphql/getBenchmarkList';

const OptionsItem = styled(DropdownItem)`
  font-weight: bold !important;
  color: ${props => (props.delete ? red : `#2e096b`)} !important;
  padding: ${spacing.medium} !important;
  font-size: ${fontSizes.smaller};
`;

class MutationArchiveBenchmark extends React.Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
    benchmarkId: PropTypes.number.isRequired,
  };

  render() {
    const { t } = this.props;
    return (
      <Mutation
        mutation={mutationArchiveBenchmark}
        update={(cache, { data: { archiveBenchmark } }) => {
          const { projectId } = this.props;
          const reportingModelsList = cache.readQuery({
            query: getBenchmarkList,
            variables: { projectId },
          }).getBenchmarkList.projectBenchmarks;
          cache.writeQuery({
            query: getBenchmarkList,
            variables: { projectId },
            data: {
              getBenchmarkList: {
                projectBenchmarks: reportingModelsList.filter(
                  benchmarkModel => benchmarkModel.id !== archiveBenchmark.id,
                ),
              },
            },
          });
        }}
      >
        {archiveBenchmark => (
          <OptionsItem
            delete
            onClick={() => {
              archiveBenchmark({
                variables: {
                  id: this.props.benchmarkId,
                },
              });
            }}
          >
            <Icon icon="TRASH" color={red} size={ImageSize.small} />
            &nbsp; {t('Delete')}
          </OptionsItem>
        )}
      </Mutation>
    );
  }
}

const mapStateToProps = state => ({
  projectId: get(state, ['projects', 'currentProject', 'id']),
});

export default connect(mapStateToProps)(
  withTranslation('benchmark')(MutationArchiveBenchmark),
);
