import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class QLoginRedirect extends Component {
  componentDidMount() {
    const { history } = this.props;
    const isLoggedIn = window.localStorage.getItem('LOGGED-IN') === 'true';
    const isToken = window.localStorage.getItem('token');
    if (!isToken || !isLoggedIn) {
      window.localStorage.setItem('LOGGED-IN', 'false');
      window.localStorage.removeItem('token');
      history.push('/login');
    }
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

QLoginRedirect.propTypes = {
  children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
};

export default withRouter(QLoginRedirect);
