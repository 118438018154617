/* eslint-disable import/prefer-default-export */

const PERIODS = {
  CURRENT: 'current',
  PREVIOUS_PERIOD: 'previousPeriod',
  PREVIOUS_YEAR: 'previousYear',
};

export const PERIOD_LABELS = {
  [PERIODS.CURRENT]: 'Current period',
  [PERIODS.PREVIOUS_PERIOD]: 'Previous period',
  [PERIODS.PREVIOUS_YEAR]: 'Previous year',
};
