import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FilterInput from 'Routes/GlobalExperience/Reporting/_Components/FilterInput';
import memoizeOne from 'memoize-one';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import QTitle from '_Components/Texts/QTitle/QTitle';
import { get } from 'lodash';
import { FILTER_DIMENSIONS } from '../../../../configs/globalConfig';
import { isValidCriteriaKeys } from '../FiltersSwitcher/FiltersUtils/CriteriaKeysUtils';
import { checkConflicts } from '../FiltersSwitcher/FiltersUtils/ConflictsUtils';
import { addConflict } from '../../../../../../../../_Resources/Benchmark/Actions/Items/addConflict';
import { removeConflict } from '../../../../../../../../_Resources/Benchmark/Actions/Items/removeConflict';
import ConflictMessages from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/ConflictMessages';
import MergeMessages from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/MergeMessages';

const addFilterCategory = (category, filters) =>
  filters.map(filter => ({
    ...filter,
    filterCategory: category,
  }));

class CriteriaKeysFilter extends Component {
  static propTypes = {
    onRemoved: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    normalFilters: PropTypes.array.isRequired,
    initialValues: PropTypes.array.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    const args = [
      this.props.benchmark,
      this.props.dispatchAddConflict,
      this.props.dispatchRemoveConflict,
    ];
    if (prevProps.initialValues !== this.props.initialValues) {
      checkConflicts(
        this.props.context,
        FILTER_DIMENSIONS.NORMAL,
        isValidCriteriaKeys,
        ...args,
      );
    }
  }

  createNormalFilters = memoizeOne(normalFilters => {
    const { onRemoved, onSelected, initialValues } = this.props;
    return normalFilters.map(({ id, label, values }) => {
      const currentInitialValues = (initialValues || []).filter(
        ({ filterCategory }) => filterCategory === id,
      );
      return (
        <Col xs={6}>
          <FilterInput
            initialValues={currentInitialValues}
            onRemoved={filters => onRemoved(addFilterCategory(id, filters))}
            onSelected={filters => onSelected(addFilterCategory(id, filters))}
            options={values.map(value => ({ id: value, label: value }))}
            name={label}
          />
        </Col>
      );
    });
  });

  render() {
    return (
      <div>
        <ConflictMessages
          context={this.props.context}
          type={FILTER_DIMENSIONS.NORMAL}
        />
        <QTitle>
          <QTitle.Title>{this.props.t('Filters')}</QTitle.Title>
        </QTitle>
        <Row>{this.createNormalFilters(this.props.normalFilters || [])}</Row>

        <MergeMessages
          context={this.props.context}
          type={FILTER_DIMENSIONS.NORMAL}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    benchmark: get(state, ['benchmark']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchAddConflict: (firstContext, secondContext, typeConflict) => {
      dispatch(addConflict(firstContext, secondContext, typeConflict));
    },
    dispatchRemoveConflict: (firstContext, secondContext, typeConflict) => {
      dispatch(removeConflict(firstContext, secondContext, typeConflict));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(CriteriaKeysFilter));
