import styleVars from 'styles/abstracts/_variables.module.scss';
import { purple } from 'styles/abstracts/colors';

export const QemotionTheme = {
  color: [
    styleVars.red,
    styleVars.orange,
    styleVars.yellow,
    styleVars.green,
    styleVars.light_blue,
    styleVars.blue,
    styleVars.dark_blue,
  ],
  backgroundColor: 'rgba(0,0,0,0)',
  textStyle: {},
  title: {
    textStyle: {
      color: styleVars.black,
    },
    subtextStyle: {
      color: styleVars.gray_600,
    },
  },
  line: {
    itemStyle: {
      borderWidth: '0',
    },
    lineStyle: {
      width: '3',
    },
    symbolSize: '2',
    symbol: 'circle',
    smooth: true,
  },
  radar: {
    itemStyle: {
      borderWidth: '0',
    },
    lineStyle: {
      width: '3',
    },
    symbolSize: '8',
    symbol: 'circle',
    smooth: true,
  },
  bar: {
    itemStyle: {
      borderWidth: '0',
      borderColor: styleVars.white,
    },
    emphasis: {
      itemStyle: {
        borderWidth: '0',
        borderColor: styleVars.white,
      },
    },
  },
  pie: {
    startAngle: 180,
    minAngle: 5,
    itemStyle: {
      borderWidth: '0',
      borderColor: styleVars.white,
    },
    emphasis: {
      itemStyle: {
        borderWidth: '0',
        borderColor: styleVars.white,
      },
    },
  },
  scatter: {
    itemStyle: {
      borderWidth: '0',
      borderColor: styleVars.white,
    },
    emphasis: {
      itemStyle: {
        borderWidth: '0',
        borderColor: styleVars.white,
      },
    },
  },
  boxplot: {
    itemStyle: {
      borderWidth: '0',
      borderColor: styleVars.white,
    },
    emphasis: {
      itemStyle: {
        borderWidth: '0',
        borderColor: styleVars.white,
      },
    },
  },
  parallel: {
    itemStyle: {
      borderWidth: '0',
      borderColor: styleVars.white,
    },
    emphasis: {
      itemStyle: {
        borderWidth: '0',
        borderColor: styleVars.white,
      },
    },
  },
  sankey: {
    itemStyle: {
      borderWidth: '0',
      borderColor: styleVars.white,
    },
    emphasis: {
      itemStyle: {
        borderWidth: '0',
        borderColor: styleVars.white,
      },
    },
  },
  funnel: {
    itemStyle: {
      borderWidth: '0',
      borderColor: styleVars.white,
    },
    emphasis: {
      itemStyle: {
        borderWidth: '0',
        borderColor: styleVars.white,
      },
    },
  },
  gauge: {
    itemStyle: {
      borderWidth: '0',
      borderColor: styleVars.white,
    },
    emphasis: {
      itemStyle: {
        borderWidth: '0',
        borderColor: styleVars.white,
      },
    },
  },
  candlestick: {
    itemStyle: {
      color: styleVars.red,
      color0: styleVars.green,
      borderColor: styleVars.red,
      borderColor0: styleVars.green,
      borderWidth: '-1',
    },
  },
  graph: {
    itemStyle: {
      borderWidth: '0',
      borderColor: styleVars.white,
    },
    lineStyle: {
      width: '1',
      color: styleVars.gray_600,
    },
    symbolSize: '8',
    symbol: 'circle',
    smooth: true,
    color: [
      styleVars.red,
      styleVars.orange,
      styleVars.yellow,
      styleVars.green,
      styleVars.light_blue,
      styleVars.blue,
      styleVars.dark_blue,
    ],
    label: {
      textStyle: {
        color: styleVars.white,
      },
    },
  },
  map: {
    itemStyle: {
      areaColor: styleVars.gray_400,
      borderColor: styleVars.white,
      borderWidth: '2',
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(89,46,160,0.25)',
        borderColor: styleVars.primary,
        borderWidth: '1',
      },
    },
    label: {
      textStyle: {
        color: styleVars.black,
      },
      emphasis: {
        textStyle: {
          color: 'rgb(46,9,107)',
        },
      },
    },
  },
  geo: {
    itemStyle: {
      areaColor: styleVars.gray_400,
      borderColor: styleVars.white,
      borderWidth: '2',
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(89,46,160,0.25)',
        borderColor: styleVars.primary,
        borderWidth: '1',
      },
    },
    label: {
      textStyle: {
        color: styleVars.black,
      },
      emphasis: {
        textStyle: {
          color: 'rgb(46,9,107)',
        },
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: styleVars.gray_400,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: styleVars.gray_400,
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: styleVars.gray_600,
      },
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ['#ccc'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: styleVars.gray_400,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: styleVars.gray_600,
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [styleVars.gray_400],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: styleVars.gray_400,
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: styleVars.gray_600,
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#ccc'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: styleVars.gray_400,
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#333',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['red'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: styleVars.gray_400,
    },
    emphasis: {
      itemStyle: {
        borderColor: '#928fb0',
      },
    },
  },
  legend: {
    padding: 16,
    textStyle: {
      color: styleVars.black,
    },
    icon: 'circle',
    itemHeight: 8,
    itemGap: 16,
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: styleVars.gray_400,
        width: '1',
      },
      crossStyle: {
        color: styleVars.gray_400,
        width: '1',
      },
    },
    textStyle: {
      color: styleVars.black,
    },
    backgroundColor: styleVars.white,
    extraCssText: 'box-shadow: 0 8px 16px 0 rgba(0,0,0,0.10); text-align: left; padding: 1rem',
  },
  grid: {
    left: 80,
    right: 80,
    bottom: 64,
  },
  timeline: {
    lineStyle: {
      color: styleVars.gray_400,
      width: '2',
    },
    itemStyle: {
      color: styleVars.primary,
      borderWidth: '2',
    },
    emphasis: {
      itemStyle: {
        color: styleVars.primary,
        borderWidth: '2',
      },
    },
    controlStyle: {
      color: styleVars.primary,
      borderWidth: '2',
    },
    checkpointStyle: {
      color: styleVars.primary,
      borderColor: 'rgba(255,255,255,0)',
    },
    label: {
      textStyle: {
        color: styleVars.gray_600,
      },
      emphasis: {
        textStyle: {
          color: styleVars.gray_600,
        },
      },
    },
  },
  visualMap: {
    color: [styleVars.primary, '#d5cbe7'],
  },
  dataZoom: {
    backgroundColor: 'rgba(255,255,255,1)',
    dataBackgroundColor: 'rgba(89,46,160,0.75)',
    fillerColor: 'rgba(89,46,160,0.25)',
    handleColor: styleVars.primary,
    handleSize: '100%',
    textStyle: {
      color: styleVars.black,
    },
  },
  markPoint: {
    label: {
      textStyle: {
        color: styleVars.white,
      },
      emphasis: {
        textStyle: {
          color: styleVars.white,
        },
      },
    },
  },
};

export const toolbox = {
  show: true,
  orient: 'vertical',
  feature: {
    dataZoom: {
      title: {
        zoom: 'Zoomer',
        back: 'Remettre le zoom à zéro',
      },
      // yAxisIndex: 'none',
      iconStyle: {
        borderColor: purple,
      },
    },
    dataView: {
      title: 'Voir les données',
      lang: ['Données', 'Annuler', 'Mettre à jour'],
      readOnly: false,
      textColor: purple,
      buttonColor: purple,
      iconStyle: {
        borderColor: purple,
      },
    },
    magicType: {
      title: {
        line: 'Afficher en lignes',
        bar: 'Afficher en barres',
        stack: 'Empiler',
        tiled: 'Désempiler',
      },
      type: ['line', 'bar', 'stack'],
      iconStyle: {
        borderColor: purple,
      },
    },
    restore: {
      title: 'Recharger',
      iconStyle: {
        borderColor: purple,
      },
    },
    saveAsImage: {
      title: "Sauvegarder l'image",
      name: 'Q°Emotion graphique',
      backgroundColor: '#FFFFFF',
      iconStyle: {
        borderColor: purple,
      },
    },
  },
  emphasis: {
    iconStyle: {
      borderWidth: 2,
      borderColor: purple,
      textBackgroundColor: purple,
      textFill: '#FFFFFF',
      textBorderRadius: [5, 5, 5, 5],
      textPadding: [6, 6, 4, 6],
    },
  },
};
