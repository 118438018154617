import { find } from "ramda";

export function getMarkerColorFactory(colors) {
  return function getTemperatureMarkerColor(temperature) {
    const isInRange = prepareRangeComparer(temperature);
    return find(isInRange, colors);
  };
}

function prepareRangeComparer(temperature) {
  return function isInRange({ min, max }) {
    return temperature >= min && temperature < max;
  };
}
