import React from 'react';
import 'react-dates/initialize';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QDatepicker from '_Components/Fields/QDatepicker/QDatepicker';
import { setCurrentPeriod } from '_Resources/Global/Actions/setCurrentPeriod';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';

class CDatepicker extends React.Component {
  static propTypes = {
    projectPeriods: PropTypes.any,
    currentPeriod: PropTypes.any,
    dispatchSetCurrentPeriod: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectPeriods: null,
    currentPeriod: null,
  };

  componentDidMount() {
    const currentFilters = JSON.parse(window.localStorage.getItem('filters'));
    this.props.dispatchSetCurrentPeriod(
      (currentFilters && currentFilters.range) || {},
    );
  }

  componentDidUpdate(prevProps) {
    const {
      dispatchSetCurrentPeriod,
      projectPeriods,
      filterKeys,
      setGlobalQueryFilterKeys,
    } = this.props;
    const currentFilters =
      window.localStorage.getItem('filters') &&
      JSON.parse(window.localStorage.getItem('filters'));
    if (
      (currentFilters &&
        projectPeriods &&
        projectPeriods.byAll &&
        Object.keys(currentFilters.range).length === 0) ||
      (!currentFilters && projectPeriods && projectPeriods.byAll) ||
      (prevProps.projectPeriods &&
        JSON.stringify(prevProps.projectPeriods.byAll.period) !==
          JSON.stringify(projectPeriods.byAll.period))
    ) {
      dispatchSetCurrentPeriod(projectPeriods.byAll.period);
    }
    if (prevProps.currentPeriod !== this.props.currentPeriod) {
      setGlobalQueryFilterKeys({
        ...filterKeys,
        range: this.props.currentPeriod,
      });
    }
  }

  onPeriodUpdate(e) {
    this.props.dispatchSetCurrentPeriod(e);
    this.props.setGlobalQueryFilterKeys({
      ...this.props.filterKeys,
      range: e,
      dataCollectorId: null,
    });
  }

  render() {
    const { projectPeriods, currentPeriod } = this.props;

    return (
      <QDatepicker
        startDate={currentPeriod && currentPeriod.startDate}
        endDate={currentPeriod && currentPeriod.endDate}
        periods={projectPeriods}
        onPeriodUpdate={this.onPeriodUpdate.bind(this)}
        loading={currentPeriod === null || projectPeriods === null}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchSetCurrentPeriod: newState => {
    dispatch(setCurrentPeriod(newState));
  },
  setGlobalQueryFilterKeys: newState => {
    dispatch(setGlobalQueryFilterKeys(newState));
  },
});

const mapStateToProps = ({
  periods: { projectPeriods, currentPeriod },
  filters: { filterKeys },
}) => ({
  projectPeriods,
  currentPeriod,
  filterKeys,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['text', 'button'])(CDatepicker));
