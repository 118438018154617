import React, { useEffect } from 'react';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import { grayShades } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import SAVE_THEMATICS_OVERVIEW_SETTINGS_MUTATION from '../../graphql/saveThematicsOverviewSettings';
import GET_THEMATICS_OVERVIEW_DATA_QUERY from '../../graphql/getThematicsOverviewData';

const Head = styled.thead`
  background-color: white !important;

  & th {
    position: sticky;
    top: 0;
    background-color: white !important;
    border-bottom: 1px solid ${grayShades.g300} !important;
    border-top: 1px solid ${grayShades.g300} !important;
    z-index: 2;
  }

  & th:first-child,
  & td:first-child { 
    position: sticky;
    left: 0;
    width: 33em !important;
    z-index: 3;
  }
`;

const StyledCell = styled.th`
  color: ${grayShades.g600};
  font-size: ${fontSizes.default};
  padding: 1em !important;
`;

const TitleCell = styled(StyledCell)`
  background-color: white;
`;

const IndicatorCell = styled(StyledCell)`
  min-width: 15em !important;
  width: 15em !important;
  text-align: center;
`;

const SortIcon = styled(Icon)`
  visibility: ${({ isVisible }) => !isVisible && 'hidden'};
`;

const TableHeader = ({
  order,
  orderDirection,
  selectedColumns,
  t,
  setOrder,
  changeDirection,
  setOrderDirection,
}) => {
  return (
    <Head id="categoryRankingHead">
      <tr>
        <TitleCell>
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => {
              setOrder(prevState => {
                changeDirection(prevState, 'name');
                return 'name';
              });
            }}
          >
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <SortIcon
                icon={'INTERFACE_CHEVRON_UP'}
                isVisible={
                  !orderDirection ||
                  (order === 'name' && orderDirection === 'asc')
                }
                color={grayShades.g600}
              />
              <SortIcon
                icon={'INTERFACE_CHEVRON_DOWN'}
                isVisible={
                  orderDirection === null ||
                  (order === 'name' && orderDirection === 'desc')
                }
                color={grayShades.g600}
              />
            </span>
            <span>{t('Categories')}</span>
          </div>
        </TitleCell>
        {selectedColumns?.map(col => (
          <IndicatorCell>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOrder(prevState => {
                  changeDirection(prevState, col.id);
                  return col.id
                });
              }}
            >
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <SortIcon
                  icon={'INTERFACE_CHEVRON_UP'}
                  isVisible={
                    !orderDirection ||
                    (order === col.id && orderDirection === 'asc')
                  }
                  color={grayShades.g600}
                />
                <SortIcon
                  icon={'INTERFACE_CHEVRON_DOWN'}
                  isVisible={
                    orderDirection === null ||
                    (order === col.id && orderDirection === 'desc')
                  }
                  color={grayShades.g600}
                />
              </span>
              <span>{t(col.label)}</span>
            </div>
          </IndicatorCell>
        ))}
        <td>&nbsp;</td>
      </tr>
    </Head>
  );
};

export default TableHeader;
