import gql from 'graphql-tag';

const mutation = gql`
  mutation uploadCompanyLogo($file: Upload) {
    uploadCompanyLogo(file: $file) {
      errors
      file
    }
  }
`;

export default mutation;
