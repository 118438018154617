import gql from 'graphql-tag';

const query = gql`
  query exportAlertConfiguration(
    $alertConfigurationId: Int!
    $fileExtension: String!
  ) {
    exportAlertConfiguration(
      alertConfigurationId: $alertConfigurationId
      fileExtension: $fileExtension
    ) {
      code
      message
    }
  }
`;

export default query;
