import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QBooleanSwitch from '../../../../_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import QButton from '../../../../_Components/Buttons/QButton/QButton';
import { settingOptions } from '../_Containers/configs/settingOptions';
import FieldCheckbox from '../_Components/FieldCheckbox';
import QTitle from '../../../../_Components/Texts/QTitle/QTitle';
import QFieldGroup from '../../../../_Components/Panels/QFieldGroup/QFieldGroup';
import {
  FIELD_MODE,
  FIELD_TYPE,
} from '../../../../_Resources/Reporting/Configs/settingFields';
import QInput from '../../../../_Components/Fields/QInput/QInput';
import { TEXT_TYPES } from '../../../../_Components/Texts/textTypes';
import { TEXT_POSITIONS } from '../../../../_Components/Texts/textPositions';

const StyledQInput = styled(QInput)`
  margin-left: 80px;
  ${({ disabled }) => (disabled ? `opacity: 0.5` : null)};
`;

export const PRESENTATION_MODES = {
  CARD: 'card',
  PAGE: 'page',
  DOCUMENT: 'document',
};

export const PRESENTATION_TRANSLATIONS = {
  [PRESENTATION_MODES.CARD]: {
    header: 'Header of card',
  },
  [PRESENTATION_MODES.PAGE]: {
    header: 'Header of page',
    footer: 'Footer of page',
  },
  [PRESENTATION_MODES.DOCUMENT]: {
    header: 'Document header',
    footer: 'Document footer',
  },
};

class PresentationSettings extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    header: PropTypes.any.isRequired,
    footer: PropTypes.any.isRequired,
    isModified: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(Object.values(PRESENTATION_MODES)),
  };

  static defaultProps = {
    title: null,
    mode: PRESENTATION_MODES.PAGE,
    isModified: true,
  };

  onTitleChange = ({ target: { value } }) =>
    this.props.onUpdate({ title: value });

  onContextualSubtitleChange = () =>
    this.props.onUpdate({
      header: {
        isContextualSubtitle: !this.props.header.isContextualSubtitle,
      },
    });

  onFooterChange = () =>
    this.props.onUpdate({
      footer: { isFooter: !this.props.footer.isFooter },
    });

  toggleCheckbox = (id, type) =>
    this.props.onUpdate({ [type]: { [id]: !this.props[type][id] } });

  updateField = (id, type, value) =>
    this.props.onUpdate({ [type]: { [id]: value } });

  createCheckboxes(type, enabled) {
    const { t } = this.props;
    return settingOptions[type].map(({ id, label, mode }) => {
      switch (mode) {
        case FIELD_MODE.CHECKBOX:
          return (
            <FieldCheckbox
              id={id}
              label={t(label)}
              disabled={!enabled}
              indentation
              toggle={() => this.toggleCheckbox(id, type)}
              selected={this.props[type][id]}
            />
          );
        case FIELD_MODE.TEXT_INPUT:
          return (
            <StyledQInput
              value={this.props[type][id]}
              onChange={({ target: { value } }) =>
                enabled && this.updateField(id, type, value)
              }
              disabled={!enabled}
            />
          );
        default:
          return null;
      }
    });
  }

  render() {
    const {
      t,
      title,
      header: { isContextualSubtitle },
      footer,
      onReset,
      mode,
      isModified,
    } = this.props;
    return (
      <Fragment>
        {[PRESENTATION_MODES.PAGE, PRESENTATION_MODES.CARD].indexOf(mode) >
          -1 && [
          <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.LEFT}>
            {t('Title')}
          </QTitle>,
          <QFieldGroup>
            <QFieldGroup.Control>
              <QInput value={title} onChange={this.onTitleChange} />
            </QFieldGroup.Control>
          </QFieldGroup>,
        ]}

        <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.LEFT}>
          {t(PRESENTATION_TRANSLATIONS[mode].routes)}
        </QTitle>
        <QBooleanSwitch
          label={t('Display contextual sub title')}
          value={isContextualSubtitle}
          onChanged={this.onContextualSubtitleChange}
        />
        <QFieldGroup>
          {this.createCheckboxes(FIELD_TYPE.HEADER, isContextualSubtitle)}
        </QFieldGroup>

        {[PRESENTATION_MODES.PAGE, PRESENTATION_MODES.DOCUMENT].indexOf(mode) >
          -1 && [
          <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.LEFT}>
            {t(PRESENTATION_TRANSLATIONS[mode].footer)}
          </QTitle>,
          <QBooleanSwitch
            label={t('Display footer')}
            value={footer.isFooter}
            onChanged={this.onFooterChange}
          />,
          <QFieldGroup>
            {this.createCheckboxes(FIELD_TYPE.FOOTER, footer.isFooter)}
          </QFieldGroup>,
        ]}
      </Fragment>
    );
  }
}

export default withTranslation('reporting')(PresentationSettings);
