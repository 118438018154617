import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import { getFontSizeStyles } from 'styles/abstracts/typography';
import { grayShades, green, red, themeColors } from 'styles/abstracts/colors';
import { hex2rgbaConvert } from '_Utils/styles/colors';
import { createStyle } from 'styles/utils';

const DELTA = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
};

const badgeConfig = {
  [DELTA.POSITIVE]: {
    icon: 'GRAPH_ARROW_UP',
    color: green,
    style: {
      'background-color': hex2rgbaConvert(green, 20),
      color: green,
    },
    text: (delta, isPercentageEvolution = false) => `+${delta}${isPercentageEvolution ? ' %' : ' pts'}`,
  },
  [DELTA.NEGATIVE]: {
    icon: 'GRAPH_ARROW_DOWN',
    color: red,
    style: {
      'background-color': hex2rgbaConvert(red, 20),
      color: red,
    },
    text: (delta, isPercentageEvolution = false) => `${delta}${isPercentageEvolution ? ' %' : ' pts'}`,
  },
  [DELTA.NEUTRAL]: {
    style: {
      'background-color': hex2rgbaConvert(grayShades.g300, 100),
      color: themeColors.secondary,
    },
    text: (delta, isPercentageEvolution = false) =>
      delta === undefined ? `'N/A'` : `0${isPercentageEvolution ? ' %' : ' pt'}`,
  },
};

const Badge = styled.span`
  border-radius: 1rem;
  font-weight: bold;
  ${({ typeStyles }) => createStyle(typeStyles)} display: inline-block;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 2rem;
  line-height: 2rem;
  ${getFontSizeStyles(14)};
`;

const BadgeIcon = styled.span`
  margin-right: 0.5rem;
`;

const getDeltaType = delta => {
  if (!delta) {
    return DELTA.NEUTRAL;
  }
  if (delta > 0) {
    return DELTA.POSITIVE;
  }
  return DELTA.NEGATIVE;
};

const QBadge = ({ id, delta }) => {
  const type = getDeltaType(delta);
  const config = badgeConfig[type];

  return (
    <Badge id={id} typeStyles={config.style}>
      {type !== DELTA.NEUTRAL && (
        <BadgeIcon>
          <Icon icon={config.icon} color={config.color} size={16} />
        </BadgeIcon>
      )}
      <span>{config.text(parseFloat(delta), id && (id.includes('mentionEvolutionBadge') || id.includes('emotionsEvolutionBadge') || id.includes('sentimentsEvolutionBadge')))}</span>
    </Badge>
  );
};

QBadge.propTypes = {
  delta: PropTypes.number,
  id: PropTypes.string,
};

QBadge.defaultProps = {
  delta: undefined,
  id: null,
};

export default QBadge;
