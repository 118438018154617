import React from 'react';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import querySchema from './graphql/getVerbatimGlobalBestAndPainPointsCardData';
import CQuery from '../../../_Container/QQuery/CQuery';
import VerbatimGlobalBestAndPainPointsCard from './VerbatimGlobalBestAndPainPointsCard';
import './style.scss';

const VerbatimGlobalBestAndPaintPointCardContainer = props => (
  <QcardPanel
    data={[true]}
    {...props}
    hints={[HintsContent.HINT_TOPS_AND_FLOPS]}
    CustomCSSBodyCol={'tabContent'}
    scroll
  >
    <CQuery query={querySchema}>
      <VerbatimGlobalBestAndPainPointsCard {...props} />
    </CQuery>
  </QcardPanel>
);
export default VerbatimGlobalBestAndPaintPointCardContainer;
