import gql from 'graphql-tag';

const mutation = gql`
  mutation updateProject($id: Int) {
    updateProject(id: $id) {
      id
      errors
    }
  }
`;

export default mutation;
