export type IconType = {
  iconType: string;
  iconColor: string;
};

export type EmotionalClaimConditionsType = {
  claimType: string;
  status: string;
} | null;

export type OptionType =
  // TYPE DECLARATION D'ERREUR
  | {
      text: string;
      icon: IconType;
      topSeparator: string;
      onClick: Function;
      emotionalClaimConditions: Array<EmotionalClaimConditionsType> | null;
      associatedEmotions: string[] | undefined;
    }
  // TYPE IRRITANTS / POINT D'ENCHANTEMENT
  | {
      text: string;
      tone: string | null | undefined;
      icon: IconType;
      topSeparator: string;
      modal: JSX.Element | undefined;
      action: string;
      modalToSwitchTo: JSX.Element | undefined;
      validateButton: Object;
      onClick: Function;
      emotionalClaimConditions:
        | Array<EmotionalClaimConditionsType>
        | null
        | undefined;
      associatedEmotions: string[];
    };

export type EmotionalClaimType = {
  claimId: string;
  claimType: string;
  sentenceId: string;
  suggestedEmotion: string;
  comment: string | null;
  username: string;
  status: string;
};

export type VerbatimSentenceType = {
  clusters: Array<string> | [];
  emotion: string;
  relevantThematic: boolean;
  emotionalClaim: EmotionalClaimType | null;
  sentenceId: number;
  sentence: string;
};

export const isOptionVisible = (
  option: OptionType,
  verbatimSentence: VerbatimSentenceType,
  emotion: string,
) => {
  const { emotionalClaim } = verbatimSentence;
  const { emotionalClaimConditions, associatedEmotions } = option;

  if (associatedEmotions) return associatedEmotions.includes(emotion);

  if (emotionalClaim === null) return emotionalClaimConditions.includes(null);

  return emotionalClaimConditions.some(
    condition =>
      emotionalClaim.claimType.toUpperCase() === condition?.claimType.toUpperCase() &&
      emotionalClaim.status.toUpperCase() === condition?.status.toUpperCase(),
  );
};
