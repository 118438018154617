import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Fade } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import DropdownOptions from '../../../../../../../../DropdownOptions';
import {
  purple,
  white,
} from '../../../../../../../../../../../../styles/abstracts/colors';
import { spacing } from '../../../../../../../../../../../../styles/abstracts/variables';
import { fontSizes } from '../../../../../../../../../../../../styles/abstracts/typography';
import QButton from '_Components/Buttons/QButton/QButton';

const ReportButton = styled(QButton)`
  border: 2px solid ${purple} !important;
  margin-left: ${spacing.mediumSmall} !important;
  font-size: ${fontSizes.extraSmall} !important;
  padding: ${spacing.small} ${spacing.medium} !important;

  &:hover {
    background-color: ${purple} !important;
    color: ${white} !important;
  }
`;

const Cell = styled.td`
  vertical-align: middle !important;
  text-align: right;
  width: 35%;
`;

const ReportOptions = props => {
  const { t } = props;
  return (
    <Cell>
      {props.isHovered && (
        <Fragment>
          <ReportButton
            onClick={props.copyToClipboard}
            outline
          >
            {props.isLinkCopied ? (
              <Fade in={props.fadeIn}>{t('Copied')}</Fade>
            ) : (
              t('Copy link')
            )}
          </ReportButton>
          <ReportButton outline>{t('Download')}</ReportButton>
          <DropdownOptions />
        </Fragment>
      )}
    </Cell>
  );
};

export default withTranslation('button')(ReportOptions);
