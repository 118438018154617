import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '_Components/Icons/Icon';
import { tagStyles } from './styles';

const Body = styled.div`
  display: inline-block;
  align-content: center;

  font-weight: bold;
  color: ${tagStyles.color};

  background-color: white;

  border: ${tagStyles.border};
  border-radius: ${tagStyles.radius};

  margin: ${tagStyles.margin};
  padding: ${tagStyles.padding};
`;

const Button = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-left: ${tagStyles.margin};
`;

const Tag = ({ onRemove, name }) => (
  <Body>
    <span>{name}</span>
    {onRemove && (
      <Button onClick={() => onRemove(name)}>
        <Icon icon="CROSS" size="20" color={tagStyles.color} />
      </Button>
    )}
  </Body>
);

Tag.propTypes = {
  onRemove: PropTypes.func,
  name: PropTypes.string.isRequired,
};

Tag.defaultProps = {
  onRemove: null,
};

export default Tag;
