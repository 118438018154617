import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { defaultTo, isEmpty, toLower, startCase, isNil, find, capitalize } from 'lodash';
import { withApollo } from 'react-apollo';
import { MenuItemLabel } from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import { RadioButton, CheckboxContainer } from '_Charts/LineCharts/CLineChart/CLineChartSettings';
import QModal from '_Components/Modals/QModal/QModal';
import QButton from '_Components/Buttons/QButton/QButton';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { sentimentsList } from 'constants/index';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: bold;
`;

const THEMATICS_EXPORT_FORMATS = [
  {
    label: 'card:Concatenated',
    id: 'concatenated',
  },
  {
    label: 'card:Columns',
    id: 'columns',
  },
];

const ExportDataModal = ({
  isOpen,
  open,
  client,
  projectId,
  projectName,
  setMessage,
  query,
  additionalVariables,
  t,
  i18n,
  analysisAxis,
}) => {
  const [chosenFormat, setChosenFormat] = useState('csv');
  const [thematicsExportFormat, setThematicsExportFormat] = useState('concatenated');

  const location = useLocation();

  const CLUSTERS_LABELS = {
    ENCHANTEMENT: t('button:Enchantment'),
    EFFET_WOW: t('button:WOW effect'),
    STORIES: t('button:Stories'),
    IRRITATION: t('button:Irritation'),
    ATTRITION: t('button:Churn'),
  };

  const SENTIMENTS_LABELS = {
    POSITIVE: t('card:Positive'),
    NEUTRAL: t('card:Neutral'),
    NEGATIVE: t('card:Negative'),
  };

  const getFullDate = (date, period) => {
    const dateFormat = moment(period[date]);
    dateFormat.locale(i18n.language);
    return dateFormat.format('LL');
  };

  const getThematicLabel = thematicGroup => {
    const { thematic, subThematic, subSubThematic } = thematicGroup ?? {};
    return find([subSubThematic, subThematic, thematic], item => !isNil(item) && item !== '');
  };

  const criteriaKeyLabel = ckGroup => {
    const { name, values } = ckGroup;
    return `${name}: ${values.join(', ')}`;
  };

  const showExportParams = () =>
    ['/verbatim-list', '/main', '/settings'].includes(location?.pathname) ||
    location?.pathname.includes('/project-details');

  const filters = () => {
    const {
      range,
      thematics,
      criteriaKeys,
      selectedEmotions,
      keywords,
      selectedClusters,
      selectedSentiments,
    } = defaultTo(additionalVariables, {});

    let startFilterDate;
    let endFilterDate;
    if (range) {
      startFilterDate = getFullDate('startDate', range);
      endFilterDate = getFullDate('endDate', range);
    }

    return (
      <ul>
        {projectName && (
          <li key={`modal-${projectName}`}>
            {t('card:Project')}: {projectName}
          </li>
        )}
        {startFilterDate && endFilterDate && (
          <li key="modal-period">
            {t('card:Period')}: {t('card:From')} {startFilterDate} {t('card:to')} {endFilterDate}
          </li>
        )}
        {!isEmpty(thematics) && (
          <li key="modal-thematics">
            {t('card:Thematics')}: {thematics.map(group => getThematicLabel(group)).join(', ')}
          </li>
        )}
        {!isEmpty(criteriaKeys) && (
          <li key="modal-criteria-keys">
            {t('filters:Filters')}:
            <ul>
              {criteriaKeys.map(group => (
                <li key={`modal-criteria-key-${group.name}`}>{criteriaKeyLabel(group)}</li>
              ))}
            </ul>
          </li>
        )}
        {analysisAxis === 'emotions' && !isEmpty(selectedEmotions) && (
          <li key="modal-selected-emotions">
            {t('card:Emotions')}:{' '}
            {selectedEmotions.map(emotion => t(`emotion:${startCase(toLower(emotion))}`)).join(', ')}
          </li>
        )}
        {analysisAxis === 'selections' && !isEmpty(selectedClusters) && (
          <li key="modal-selected-clusters">
            {t('card:Verbatim selection')}: {selectedClusters.map(cluster => CLUSTERS_LABELS[cluster]).join(', ')}
          </li>
        )}
        {analysisAxis === 'sentiments' && !isEmpty(selectedSentiments) && (
          <li key="modal-selected-clusters">
            {t('card:Sentiments')}:{' '}
            {selectedSentiments.map(sentiment => t('card:', SENTIMENTS_LABELS[sentiment])).join(', ')}
          </li>
        )}
        {!isEmpty(keywords) && (
          <li>
            {t('filters:Keywords')}: {keywords.join(', ')}
          </li>
        )}
      </ul>
    );
  };

  const getExportQuerySelectedEmotions = () => {
    const { selectedEmotions, selectedSentiments } = defaultTo(additionalVariables, {});
    if (selectedSentiments && selectedSentiments.length > 0) {
      let exportQuerySelectedEmotions = [];
      selectedSentiments.forEach(sentiment => {
        exportQuerySelectedEmotions = exportQuerySelectedEmotions.concat(sentimentsList[toLower(sentiment)]);
      });
      return { selectedEmotions: exportQuerySelectedEmotions, selectedSentiments: undefined };
    }
    return { selectedEmotions, selectedSentiments: undefined };
  };

  const exportQuerySelectedEmotions = getExportQuerySelectedEmotions();

  const footer = (
    <div>
      <QButton
        onClick={async () => {
          const { data } = await client.query({
            query,
            fetchPolicy: 'no-cache',
            variables: {
              fileExtension: chosenFormat,
              thematicsFormat: thematicsExportFormat,
              projectId,
              ...(additionalVariables || {}),
              ...(exportQuerySelectedEmotions || {}),
            },
          });
          setMessage && setMessage(data[Object.keys(data)[0]]);
          open(!isOpen);
        }}
      >
        {t('button:Export')}
      </QButton>
    </div>
  );

  const updatedFiltersToDisplay = filters();

  const [filtersToDisplay, setFiltersToDisplay] = useState(updatedFiltersToDisplay);

  useEffect(() => {
    const newFiltersToDisplay = filters();
    setFiltersToDisplay(newFiltersToDisplay);
  }, [additionalVariables]);

  return (
    <QModal
      isOpen={isOpen}
      title={location?.pathname === '/thematics-overview' ? 'button:Export thematics' : 'button:Export data'}
      onClose={() => open(!isOpen)}
      size="md"
      footer={footer}
      config={{ mentions: false, date: false }}
    >
      <b>{t('card:Choose your file format')}</b>
      <br />
      <Container>
        <CheckboxContainer onClick={() => setChosenFormat('csv')}>
          <MenuItemLabel checked={chosenFormat === 'csv'}>
            <RadioButton
              type="radio"
              checked={chosenFormat === 'csv'}
              value="csv"
              onChange={() => setChosenFormat('csv')}
            />
          </MenuItemLabel>
          CSV
        </CheckboxContainer>
        <CheckboxContainer onClick={() => setChosenFormat('xlsx')}>
          <MenuItemLabel checked={chosenFormat === 'xlsx'}>
            <RadioButton
              type="radio"
              checked={chosenFormat === 'xlsx'}
              value="xlsx"
              onChange={() => setChosenFormat('xlsx')}
            />
          </MenuItemLabel>
          XLSX
        </CheckboxContainer>
      </Container>
      <br />

      {showExportParams() && (
        <>
          <b />
          <b>{t('card:Thematics format')}</b>
          <br />
          <Container>
            <QSwitcher
              selected={thematicsExportFormat}
              options={THEMATICS_EXPORT_FORMATS}
              onOptionSelected={setThematicsExportFormat}
              padding="0.25rem 1rem"
              display="inline-flex"
              width="auto"
            />
          </Container>
          <br />
          <br />
          <span>{t('button:Export filters mention')}</span>
          <br />
          <br />
          <span>{t('filters:Current selection')}: </span>
          {filtersToDisplay}
          <br />
        </>
      )}
    </QModal>
  );
};
export default withTranslation()(withApollo(ExportDataModal));
