import React, { useState } from 'react';
import { GLOBAL_STEPS } from 'Routes/Settings/ProjectConfiguration/utils';
import Layout from '_Layouts/Layout';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import ProjectConfigurationSubHeader from 'Routes/Settings/ProjectConfiguration/_Containers/ProjectConfigurationSubHeader';
import UploadDataSaas from './UploadDataSaas';

const title = 'Upload your data';

const action = props => {
  const [activeStep, setActiveStep] = useState(3);
  const {
    history,
    match: {
      params: { uuid },
    },
    location
  } = props;

  const subHeader = (
    <ProjectConfigurationSubHeader
      steps={GLOBAL_STEPS}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      history={history}
    />
  );

  const body = <UploadDataSaas history={history} uuid={uuid} setActiveStep={setActiveStep} location={location} />;

  return (
    <QLoginRedirect>
      <Layout
        body={body}
        allowCollapse={false}
        subHeader={subHeader}
        title={title}
        {...props}
        hideMentionsCount
      />
    </QLoginRedirect>
  );
};

export default action;
