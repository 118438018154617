import BenchmarkPeriodFragment from './_Fragments/BenchmarkPeriodFragment';
import KpiResultsFragment from './_Fragments/KpiResultsFragment';
import EmotionsBreakdownFragment from './_Fragments/EmotionsBreakdownFragment';
import SentimentsBreakdownFragment from './_Fragments/SentimentsBreakdownFragment';
import VerbatimFragment from './_Fragments/VerbatimFragment';
import ThematicsOverviewFragment from './_Fragments/ThematicsOverviewFragment'

const FRAGMENTS = {
  benchmarkPeriod: BenchmarkPeriodFragment,
  emotionsBreakdown: EmotionsBreakdownFragment,
  sentimentsBreakdown: SentimentsBreakdownFragment,
  kpiResults: KpiResultsFragment,
  verbatim: VerbatimFragment,
  thematicsOverview: ThematicsOverviewFragment,
};
export default FRAGMENTS;
