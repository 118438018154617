/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cloneDeep, flatten, get, uniqBy, map } from 'lodash';
import moment from 'moment';
import BenchmarkEditCard from 'Routes/Benchmark/BenchmarkBoard/_Components/BenchmarkGroupCard/BenchmarkEditCard';
import { deleteItem } from '_Resources/Benchmark/Actions/Items/deleteItem';
import { duplicateItem } from '_Resources/Benchmark/Actions/Items/duplicateItem';
import {
  CONTEXT,
  FILTER_DIMENSIONS,
  STORE_TO_WATCH,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import { connect } from 'react-redux';
import { moveItem } from '_Resources/Benchmark/Actions/saveBenchmarkSettings';
import { withTranslation } from 'react-i18next';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import { setRangeFilter } from '_Resources/Benchmark/Actions/Filters/setRangeFilter';
import QcardPanel from '../../../../../_Components/QcardPanel/QcardPanel';
import { BENCHMARK_COLS_COLORS, COLUMN_WIDTH } from '../configs/columnsConfig';
import { purple, red } from '../../../../../styles/abstracts/colors';
import benchmarkCards from './sections/cards';
import { spacing } from '../../../../../styles/abstracts/variables';
import QFilterKeysProvider from '../../../../../_Providers/QFilterKeysProvider/QFilterKeysProvider';
import { isValidFullDateRange } from './_Utils/checkDateRange';
import { mergeThematics } from '../Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/ThematicsUtils';
import { isValidCriteriaKeys } from '../Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/CriteriaKeysUtils';
import MoveButton from '../StyledMoveButton';
import MentionBreakdownByCategoryBenchmarkCardFooter from './MentionBreakdownByCategoryBenchmarkCardFooter';
import EmotionBreakdownBenchmarkCardFooter from './EmotionBreakdownBenchmarkCardFooter';
import HorizontalGraphBenchmarkCardFooter from './HorizontalGraphBenchmarkCardFooter';

const CustomQCardPanel = styled(QcardPanel)`
  width: calc(${COLUMN_WIDTH}px * ${props => (props.columns ? props.columns.length : 0)});
  margin-bottom: ${spacing.xxlarge};
  & > .row:nth-child(3) .col {
    padding: 0;
    font-weight: bold;
  }
  ${({ conflict }) => conflict && `border : 3px solid ${red};`}
`;

const GraphContainer = styled.div`
  height: inherit;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: stretch;
  ${'' /* overflow: visible; */}
`;

const ConflictContainer = styled.div`
  width: ${COLUMN_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  & > p {
    color: ${red};
    text-align: center;
  }
`;

const ContainCircle = styled.div`
  margin: 1rem auto;
`;

const MoveButtonsContainer = styled.div`
  position: absolute;
  left: ${props => !props.rightSide && '0.75em'};
  right: ${props => props.rightSide && '0.75em'};
  top: 5%;
  display: flex;
  flex-direction: column;
  height: 75%;
  justify-content: space-between;
`;

class BenchmarkGroupCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverCard: false,
      dataToFeed: [],
      conflict: false,
    };
    this.mergeDataBeforeGetComponent = this.mergeDataBeforeGetComponent.bind(this);
  }

  setHoverCard(bool) {
    this.setState({ hoverCard: bool });
  }

  setThematicsJoinSpecify(thematics) {
    return map(thematics, obj => {
      let result = cloneDeep(obj);
      if (obj.excluded === true) result = { ...obj, joinSpecify: 'not' };
      delete result.excluded;
      return result;
    })
  }


  getComponents = (title, filterKeys, color = null, mergeDataBeforeGetComponent, data, columns, column, index) => {
    const { type, displayBenchmarkDetails } = this.props;
    filterKeys.thematics = this.setThematicsJoinSpecify(filterKeys.thematics);

    return benchmarkCards[type](
      title,
      filterKeys,
      color,
      mergeDataBeforeGetComponent,
      displayBenchmarkDetails,
      columns,
      column,
      index,
    );
  };

  // TODO : FINIR FONCTION
  mergeDataBeforeGetComponent = data => {
    const { dataToFeed } = this.state;
    dataToFeed.push(data);
    this.setState({ dataToFeed });
  };

  componentDidUpdate(prevProps) {
    const {
      globalBenchmarkSettings,
      columns,
      cards,
      globalDate,
      dispatchSetRangeFilter,
      temporaryCard,
      cardIndex,
    } = this.props;
    if (prevProps.globalBenchmarkSettings.filters.normal !== globalBenchmarkSettings.filters.normal) {
      this.setState({ conflict: false });
    }
    if (prevProps.columns !== columns || prevProps.cards !== cards) {
      this.setState({ conflict: false });
    }

    const globalRange = globalDate && moment.range(moment(globalDate.startDate), moment(globalDate.endDate)).toDate();
    const { startDate, endDate } = globalBenchmarkSettings.dateRange;
    const currentRange = moment.range(moment(startDate), moment(endDate)).toDate();

    let formattedGlobalRange = [];
    formattedGlobalRange =
      globalRange && globalRange.map(date => [...formattedGlobalRange, moment(date).format('DD/MM/YYYY')]);
    let formattedCurrentRange = [];
    formattedCurrentRange = currentRange.map(date => [...formattedCurrentRange, moment(date).format('DD/MM/YYYY')]);

    const condition = JSON.stringify(flatten(formattedCurrentRange)) === JSON.stringify(flatten(formattedGlobalRange));

    if (cards.length > 0 && cards !== prevProps.cards) {
      if (!condition) {
        columns.map(column => {
          dispatchSetRangeFilter(
            CONTEXT.CARD,
            column.settings.dateRange,
            isValidFullDateRange(
              temporaryCard
                ? temporaryCard.settings.dateRange
                : cards.length > 1 || (cardIndex > -1 && cards[cards.length > 1 ? cardIndex : 0].settings.dateRange),
              globalBenchmarkSettings.dateRange,
            ),
          );
        });
      } else if (condition && !temporaryCard) {
        if (globalBenchmarkSettings.dateRange !== prevProps.globalBenchmarkSettings.dateRange) {
          dispatchSetRangeFilter(
            CONTEXT.CARD,
            globalBenchmarkSettings.dateRange,
            globalBenchmarkSettings.dateRange,
            cardIndex,
          );
        } else {
          columns.map(column => {
            dispatchSetRangeFilter(
              CONTEXT.CARD,
              column.settings.dateRange,
              isValidFullDateRange(
                temporaryCard
                  ? temporaryCard.settings.dateRange
                  : cards[cards.length > 1 ? (cardIndex < 0 ? cards.length - 1 : cardIndex) : 0].settings.dateRange,
                globalBenchmarkSettings.dateRange,
              ),
            );
          });
        }
      }
    } else {
      return null;
    }
  }

  getMergedFilters(typeFilter, mergedSettingToCompare) {
    const { globalBenchmarkSettings, cards, cardIndex, editableLayout } = this.props;
    let validFullFilter;
    switch (typeFilter) {
      case FILTER_DIMENSIONS.DATE_RANGE:
        {
          const cardDateRange = editableLayout
            ? cards[cardIndex].mergedSettings.dateRange
            : globalBenchmarkSettings.dateRange;
          const validFullDateRange = isValidFullDateRange(
            cardDateRange,
            mergedSettingToCompare.mergedSettings.dateRange,
          );
          validFullFilter =
            validFullDateRange &&
            ((validFullDateRange.startDate && validFullDateRange.startDate[0] !== '-') ||
              (validFullDateRange.endDate && validFullDateRange.endDate[0] !== '-'))
              ? validFullDateRange
              : false;
        }
        break;
      case FILTER_DIMENSIONS.THEMATIC:
        const cardThematics = editableLayout
          ? cards[cardIndex].mergedSettings.filters.thematic
          : globalBenchmarkSettings.filters.thematic;
        validFullFilter = mergeThematics(cardThematics, mergedSettingToCompare.mergedSettings.filters.thematic);
        validFullFilter = validFullFilter && uniqBy(validFullFilter, item => item.filterCategory);
        break;
      case FILTER_DIMENSIONS.NORMAL:
        {
          const cardCriteriaKeys = editableLayout
            ? isValidCriteriaKeys(
                cards[cardIndex].mergedSettings.filters.normal,
                globalBenchmarkSettings.filters.normal,
              )
            : globalBenchmarkSettings.filters.normal;
          if (cardCriteriaKeys === false || mergedSettingToCompare.mergedSettings.filters.normal === false) {
            validFullFilter = false;
          } else {
            validFullFilter = isValidCriteriaKeys(
              cardCriteriaKeys,
              mergedSettingToCompare.mergedSettings.filters.normal,
            );
          }
          validFullFilter = validFullFilter && uniqBy(validFullFilter, item => item.filterCategory);
        }
        break;
      default:
        return null;
    }
    return validFullFilter;
  }

  getDropdownOptions() {
    const { title } = this.props;
    const dropDownOptions = {
      'Emotion breakdown': ['EXPORT', 'BENCHMARK_DONUT_GRAPH_SETTINGS'],
      'Mention breakdown by category': ['EXPORT', 'BENCHMARK_GRAPH_SETTINGS'],
      'Waterfall view by category': ['EXPORT', 'BENCHMARK_HORIZONTAL_GRAPH_SETTINGS'],
    };
    return Object.keys(dropDownOptions).includes(title) ? dropDownOptions[title] : ['EXPORT'];
  }

  getFooter() {
    const { title, columns, benchmarkDonutAnalysisAxisToDisplay } = this.props;
    const optionalFooters = {
      'Mention breakdown by category': <MentionBreakdownByCategoryBenchmarkCardFooter nbOfColumns={columns.length}/>,
      'Emotion breakdown': (
        <EmotionBreakdownBenchmarkCardFooter
          nbOfColumns={columns.length}
          benchmarkDonutAnalysisAxisToDisplay={benchmarkDonutAnalysisAxisToDisplay}
        />
      ),
      'Waterfall view by category': <HorizontalGraphBenchmarkCardFooter nbOfColumns={columns.length}/>,
    };
    return Object.keys(optionalFooters).includes(title) ? optionalFooters[title] : undefined;
  }

  render() {
    const {
      cards,
      columns,
      hint,
      title,
      editableLayout,
      cardIndex,
      t,
      dispatchDuplicateCard,
      dispatchDeleteCard,
      dispatchMoveItem,
      keywords,
    } = this.props;
    const { conflict, hoverCard, dataToFeed } = this.state;
    const specificDropDownOptions = editableLayout
      ? [
          {
            text: t('Duplicate'),
            onClick: () =>
              dispatchDuplicateCard({
                id: cardIndex,
                context: CONTEXT.CARD,
              }),
            icon: {
              iconType: 'DUPLICATE',
              iconColor: null,
            },
            topSeparator: null,
          },
          {
            text: t('Delete'),
            onClick: () =>
              dispatchDeleteCard({
                id: cardIndex,
                context: CONTEXT.CARD,
              }),
            icon: {
              iconType: 'TRASH',
              iconColor: red,
            },
            topSeparator: true,
          },
        ]
      : null;
    return (
      <div onMouseEnter={() => this.setHoverCard(true)} onMouseLeave={() => this.setHoverCard(false)}>
        <CustomQCardPanel
          columns={columns}
          hints={[hint]}
          specificDropDownOptions={specificDropDownOptions}
          dropDownOptions={this.getDropdownOptions()}
          displayCardSubTitle={false}
          title={title}
          editableLayout={editableLayout}
          conflict={conflict}
          footer={this.getFooter()}
        >
          {hoverCard && cardIndex > -1 && (
            <MoveButtonsContainer>
              <MoveButton
                direction="up"
                primaryColor={purple}
                moveItem={dispatchMoveItem}
                context={STORE_TO_WATCH[CONTEXT.CARD]}
                itemId={cardIndex}
                disabled={cardIndex === 0}
              />
              <MoveButton
                direction="down"
                primaryColor={purple}
                moveItem={dispatchMoveItem}
                context={STORE_TO_WATCH[CONTEXT.CARD]}
                itemId={cardIndex}
                disabled={cardIndex === cards.length - 1}
              />
            </MoveButtonsContainer>
          )}
          <div>
            <GraphContainer>
              {columns.map((column, index) => {
                if (!this.getMergedFilters('dateRange', column) || this.getMergedFilters('normal', column) === false) {
                  if (!conflict) {
                    this.setState({ conflict: true });
                  }
                  return (
                    <ConflictContainer key={`ConflictContainer${index * 2}`}>
                      <p>{column.title}</p>
                      <ContainCircle>
                        <IconCircle icon="WARNING" size={64} color={red}/>
                      </ContainCircle>
                      <p>{t('conflicts:Conflict detected')}</p>
                    </ConflictContainer>
                  );
                }
                return (
                  <>
                    <QFilterKeysProvider
                      filters={{
                        normal:
                          this.getMergedFilters('normal', column) !== null
                            ? this.getMergedFilters('normal', column)
                            : column.settings.filters.normal,
                        thematic: this.getMergedFilters('thematic', column),
                      }}
                      dateRange={this.getMergedFilters('dateRange', column)}
                      projectId={get(this, ['props', 'projectId'])}
                      keywords={keywords ? keywords.map(keyword => keyword.option.id) : []}
                      key={`BenchmarkQFilterKeysProvider${index * 2}`}
                      render={filterKeys =>
                        this.getComponents(
                          column.title,
                          filterKeys,
                          BENCHMARK_COLS_COLORS[index % BENCHMARK_COLS_COLORS.length],
                          this.mergeDataBeforeGetComponent,
                          dataToFeed,
                          columns,
                          column,
                          index,
                        )
                      }
                    />
                  </>
                );
              })}
            </GraphContainer>
            {editableLayout && <BenchmarkEditCard cardId={cardIndex} isHovered={hoverCard} conflict={conflict}/>}
          </div>
          {hoverCard && cardIndex > -1 && (
            <MoveButtonsContainer rightSide>
              <MoveButton
                direction="up"
                primaryColor={purple}
                moveItem={dispatchMoveItem}
                context={STORE_TO_WATCH[CONTEXT.CARD]}
                itemId={cardIndex}
                disabled={cardIndex === 0}
              />
              <MoveButton
                direction="down"
                primaryColor={purple}
                moveItem={dispatchMoveItem}
                context={STORE_TO_WATCH[CONTEXT.CARD]}
                itemId={cardIndex}
                disabled={cardIndex === cards.length - 1}
              />
            </MoveButtonsContainer>
          )}
        </CustomQCardPanel>
      </div>
    );
  }
}

BenchmarkGroupCard.propTypes = {
  columns: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hint: PropTypes.objectOf(PropTypes.any).isRequired,
  editableLayout: PropTypes.bool.isRequired,
  cardIndex: PropTypes.number.isRequired,
  dispatchDuplicateCard: PropTypes.func.isRequired,
  dispatchDeleteCard: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  displayBenchmarkDetails: PropTypes.bool.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalBenchmarkSettings: PropTypes.objectOf(PropTypes.any).isRequired,
  benchmarkDonutAnalysisAxisToDisplay: PropTypes.string.isRequired,
  globalDate: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchSetRangeFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  temporaryCard: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchMoveItem: PropTypes.func.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function mapStateToProps(state) {
  return {
    globalBenchmarkSettings: get(state, ['benchmark', 'activeBenchmark', 'settings', 'globalSettings']),
    cards: get(state, ['benchmark', 'activeBenchmark', 'settings', 'cards']),
    conflicts: get(state, ['benchmark', 'activeBenchmark', 'settings', 'conflicts']),
    projectId: get(state, ['projects', 'currentProject', 'id']),
    keywords: get(state, ['benchmark', 'activeBenchmark', 'settings', 'globalSettings', 'filters', 'keywords']),
    displayBenchmarkDetails: state.benchmark.displayBenchmarkDetails,
    globalDate: get(state, ['periods', 'projectPeriods', 'byAll', 'period']),
    temporaryCard: get(state, ['benchmark', 'temporaryCard']),
    benchmarkDonutAnalysisAxisToDisplay: get(state, ['benchmark', 'donutAnalysisAxisToDisplay']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchDeleteCard: newState => {
      dispatch(deleteItem(newState));
    },
    dispatchDuplicateCard: newState => {
      dispatch(duplicateItem(newState));
    },
    dispatchMoveItem: newState => {
      dispatch(moveItem(newState));
    },
    dispatchSetRangeFilter: (type, values, mergedValues, itemIndex) => {
      dispatch(setRangeFilter(type, values, mergedValues, itemIndex));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['benchmark', 'conflicts', 'header'])(BenchmarkGroupCard));
