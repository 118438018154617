import mapStyles from "./greyMapStyle";

export const mapOptions = {
  zoom: 13,
  styles: mapStyles,
  mapTypeControl: false,
  panControl: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
  zoomControl: false,
  fullscreenControl: false
};
