import styled from 'styled-components';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddCards from './tabs/AddCards/AddCards';
import AddPages from './tabs/AddPages/AddPages';
import GlobalLayoutSettings from './tabs/GlobalLayoutSettings/GlobalLayoutSettings';
import CoverSettings from './tabs/CoverSettings/CoverSettings';
import Export from './tabs/Export/Export';
import ChangePage from './tabs/ChangePage/ChangePage';
import PageSettings from './tabs/PageSettings/PageSettings';
import CardSettings from './tabs/CardSettings/CardSettings';

const DivContain = styled.div`
  height: calc(100% - 95px);
  ${({ overflow }) => overflow && 'overflow-y: scroll;'}
`;
class ReportingTabContent extends Component {
  static propTypes = {
    selectedTab: PropTypes.string.isRequired,
    selectedMenuOption: PropTypes.string.isRequired,
  };

  tabsToComponents = {
    'add-pages': () => <AddPages />,
    'global-layout-settings': () => <GlobalLayoutSettings />,
    'cover-settings': () => <CoverSettings />,
    export: () => <Export />,
    'change-page': () => <ChangePage />,
    'page-settings': () => <PageSettings />,
    'card-settings': () => <CardSettings />,
    'add-cards': () => <AddCards />,
    'change-card': () => <AddCards />,
  };

  render() {
    const { selectedTab, selectedMenuOption } = this.props;
    const componentFactory = this.tabsToComponents[selectedTab];
    return (
      <DivContain overflow={selectedMenuOption !== 'date-range'}>
        {componentFactory && componentFactory()}
      </DivContain>
    );
  }
}

const mapStateToProps = ({
  reporting: { selectedTab, selectedMenuOption },
}) => ({
  selectedTab,
  selectedMenuOption,
});

export default connect(mapStateToProps)(ReportingTabContent);
