import React from 'react';
import QProjectFilters from '_Container/QProjectFilters/QProjectFilters';
import { QThematicsFilter } from '_Container/QProjectFilters/Filters/QThematicFilter/QThematicsFilter';
import { QNormalFilter } from '_Container/QProjectFilters/Filters/QNormalFilter/QNormalFilter';
import { QRelatedFilter } from '_Container/QProjectFilters/Filters/QRelatedFilter/QRelatedFilter';
import { QTextFilter } from '_Container/QProjectFilters/Filters/QTextFilter/QTextFilter';
import { isEmpty } from 'lodash';

const ProjectFilters = ({
  projectId,
  thematicsInputTags,
  normalCriteriaKeys,
  relatedCriteriaKeys,
  onSearch,
  initialValues,
  adaptOptions,
  keywordSearch,
  dispatchSetSelectedFilters,
}) => {
  return (
    <>
      <QProjectFilters
        key={projectId}
        onSearch={filters => onSearch(filters)}
        initialValues={initialValues}
        adaptOptions={adaptOptions}
        dispatchSetSelectedFilters={dispatchSetSelectedFilters}
      >
        {thematicsInputTags && !isEmpty(thematicsInputTags) && (
          <QThematicsFilter filters={thematicsInputTags} />
        )}
        {normalCriteriaKeys && !isEmpty(normalCriteriaKeys) && (
          <QNormalFilter filters={normalCriteriaKeys} />
        )}
        {relatedCriteriaKeys && !isEmpty(relatedCriteriaKeys) && (
          <QRelatedFilter filters={relatedCriteriaKeys} projectId={projectId} />
        )}
        {keywordSearch && <QTextFilter />}
      </QProjectFilters>
    </>
  );
};

export default ProjectFilters;
