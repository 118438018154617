import gql from 'graphql-tag';

const mutation = gql`
  mutation archiveBenchmark($id: Int!) {
    archiveBenchmark(id: $id) {
      id
    }
  }
`;

export default mutation;
