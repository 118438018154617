import { Tooltip } from 'reactstrap';
import styled from 'styled-components';

export const QTooltip = styled(Tooltip)`
  opacity: 1 !important;

  & .tooltip-inner {
    background: #ffffff;
    box-shadow: 0 0px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    color: #2e096b;
    width: inherit;
    max-width: 300px;
    padding: 1rem;
  }
`;

export const QTooltipTitle = styled.span`
  font-size: 18px;
  color: #2e096b;
  text-align: center;
  line-height: 22.5px;
  font-weight: bold;
`;

export const QTooltipSubTitle = styled.span`
  font-size: 14px;
  color: #2e096b;
  text-align: center;
  line-height: 21px;
  font-weight: bold;
`;
