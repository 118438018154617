import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import Select, { components } from 'react-select';
import QSelect from '_Components/Fields/QSelect/QSelect';
import { withTranslation } from 'react-i18next';
import QTags from '_Components/Fields/QTags/QTags';
import '../style.scss';
import _, { find } from 'lodash';
import { connect } from 'react-redux';
import { storeUserInfos } from '_Resources/Auth/Actions/auth';
import { fontSizes } from '../../../../styles/abstracts/typography';

const CustomCol = styled(Col)`
  display: inline-flex;
  min-height: 5.7rem;
  align-items: center;
  justify-content: space-between;
`;

const CategoriesContainer = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
`;

const SelectLabel = styled.span`
  font-size: ${fontSizes.smaller};
  font-weight: bold;
  float: ${props => props.position};
  margin-right: ${props => (props.position === 'right' ? '1em' : '0')};
`;

const KeywordsInputContainer = styled.div`
  margin: 0.5em 1em;
  min-width: 25em;
`;

const OrderByCol = styled(Col)`
  margin-left: auto;
  flex-wrap: nowrap;
`;

const SelectsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrderBySelect = styled(QSelect)`
  width: 100% !important;
`;

const FiltersSwitchContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 1em;
`;

const FiltersSwitchCol = styled(Col)`
  width: 14.5rem;
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  selectVerbatimsLanguage = value => {
    const {
      id,
      firstName,
      lastName,
      lang,
      isEindexUnitFahrenheit,
      isAdmin,
      account,
      hasEmailValidated,
      phoneNumber,
      expireAt,
      analysisAxis,
    } = this.props;

    this.setState({
      verbatimsLocale: value,
    });
    value && window.localStorage.setItem('verbatimsLocale', value.value);
    this.props.storeUserInfos({
      id,
      firstName,
      lastName,
      lang,
      verbatimsLocale: value.value,
      isAdmin,
      account,
      hasEmailValidated,
      isEindexUnitFahrenheit,
      phoneNumber,
      expireAt,
      analysisAxis,
    });
  };

  render() {
    const { t } = this.props;
    let categoryOptions;
    let subCategoryOptions;
    if (this.props.categories) {
      categoryOptions = this.props.categories;
      categoryOptions = [{ value: 'all', label: this.props.t('button:All categories') }, ...categoryOptions];
    }
    if (this.props.subCategories) {
      subCategoryOptions = this.props.subCategories;
      subCategoryOptions = [{ value: 'all', label: this.props.t('button:All categories') }, ...subCategoryOptions];
    }

    const sortByOptions = [
      {
        value: 'ASC',
        label: t('button:Eindex - Ascending'),
      },
      {
        value: 'DESC',
        label: t('button:Eindex - Descending'),
      },
    ];

    const analysisAxisOptions = [
      {
        value: 'emotions',
        label: t('Emotion'),
      },
      {
        value: 'sentiments',
        label: t('button:Sentiment'),
      },
      {
        value: 'selections',
        label: t('Verbatim selection'),
      },
    ];

    const categoriesStyles = {
      control: initialStyles => ({
        ...initialStyles,
        width: '110%',
      }),
    };

    const currentAnalysisAxisOption = analysisAxisOptions.find(
      analysisAxisOption => analysisAxisOption.value === this.props.selectedAnalysisAxisOption,
    );

    return (
      <Row className="d-flex pl-3 pr-5 justify-content-center">
        <CustomCol>
          <FiltersSwitchContainer>
            <FiltersSwitchCol>
              <SelectLabel position="left">{t('button:Analysis axis')}</SelectLabel>
              <br />
              <OrderBySelect
                name="analysisAxisSelect"
                value={currentAnalysisAxisOption}
                className="selectInput"
                searchable={false}
                clearable={false}
                placeholder={t('button:Analysis axis')}
                onChange={this.props.onAnalysisAxisChange}
                options={analysisAxisOptions}
                styles={categoriesStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </FiltersSwitchCol>
          </FiltersSwitchContainer>
          {!this.props.disableKeywordsSearch && (
            <KeywordsInputContainer>
              <SelectLabel position="left">{t('Keywords')}</SelectLabel>
              <QTags onTagsChanged={this.props.onTagsChanged} initialTags={this.props.initialTags} />
            </KeywordsInputContainer>
          )}
          <SelectsContainer>
            <CategoriesContainer>
              {this.props.categories && (
                <Col xs="2" className="selectMinWidth">
                  <SelectLabel position="left">{t('card:Categories')}</SelectLabel>
                  <Select
                    name="categorySelect"
                    value={this.props.categorySelectOption}
                    className="selectInput"
                    searchable={false}
                    clearable={false}
                    placeholder={t('All categories')}
                    onChange={this.props.onCategoryChange}
                    options={categoryOptions}
                    styles={categoriesStyles}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </Col>
              )}
              {this.props.categorySelectOption.value !== 'all' && this.props.subCategories && (
                <Col xs={2} className="selectMinWidth">
                  <SelectLabel position="left">{t('card:Subcategories')}</SelectLabel>
                  <Select
                    name="subCategorySelect"
                    className="selectInput"
                    searchable={false}
                    clearable={false}
                    placeholder={t('button:All categories')}
                    value={this.props.subCategorySelectOption}
                    onChange={this.props.onSubCategoryChange}
                    options={subCategoryOptions}
                    styles={categoriesStyles}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </Col>
              )}
            </CategoriesContainer>
            <OrderByCol xs={3} className="selectMinWidth">
              <SelectLabel position="right">{t('button:Sort by')}</SelectLabel>
              <br />
              <OrderBySelect
                name="orderBySelect"
                value={this.props.selectedSortByOption}
                searchable={false}
                clearable={false}
                placeholder={t('button:Sort by')}
                onChange={this.props.onSortByChange}
                options={sortByOptions}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </OrderByCol>
          </SelectsContainer>
        </CustomCol>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    id: _.get(state, ['auth', 'user', 'id']),
    lastName: _.get(state, ['auth', 'user', 'lastName']),
    firstName: _.get(state, ['auth', 'user', 'firstName']),
    lang: _.get(state, ['auth', 'user', 'lang']),
    eindexUnit: _.get(state, ['auth', 'user', 'isEindexUnitFahrenheit']),
    isAdmin: _.get(state, ['auth', 'user', 'isAdmin']),
    account: _.get(state, ['auth', 'user', 'account']),
    phoneNumber: _.get(state, ['auth', 'user', 'phoneNumber']),
    hasEmailValidated: _.get(state, ['auth', 'user', 'hasEmailValidated']),
    expireAt: _.get(state, ['auth', 'user', 'expireAt']),
    verbatimsLocale: _.get(state, ['auth', 'user', 'verbatimsLocale']),
    analysisAxis: _.get(state, ['auth', 'user', 'analysisAxis']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserInfos: newState => {
      dispatch(storeUserInfos(newState));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['card', 'button'])(Header));
