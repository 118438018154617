import gql from 'graphql-tag';

const query = gql`
  query accountConsumptionHistory($accountId: Int!) {
    accountConsumptionHistory(accountId: $accountId) {
      creditsUsed
      date
      projectName
      sources
      total
      type
    }
  }
`;

export default query;
