import React from 'react';
import styled, { css } from 'styled-components';
import { red } from 'styles/abstracts/colors';
import PropTypes from 'prop-types';
import EditModal from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/EditModal';
import { createTemporaryItem } from '_Resources/Benchmark/Actions/Items/createTemporaryItem';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { deleteTemporaryItem } from '_Resources/Benchmark/Actions/Items/deleteTemporaryItem';
import { saveTemporaryItem } from '_Resources/Benchmark/Actions/Items/saveTemporaryItem';
import { moveItem } from '_Resources/Benchmark/Actions/saveBenchmarkSettings';
import ModalNamePage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/ModalNamePage';
import ModalFiltersPage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/ModalFiltersPage';
import {
  CONTEXT,
  STORE_TO_WATCH,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import { isEmpty, filter, get } from 'lodash';
import { columnCollapseContent } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/configs/CollapseContents';
import { BenchmarkCol } from './_Components/BenchmarkCol';
import BenchmarkColumnDropdown from './_Components/BenchmarkColumnDropdown';
import EditButton from '../../EditButton';
import ConflictButton from '../../ConflictButton';
import MoveColumnButton from '../../StyledMoveButton';

const TextContainer = styled.div`
  position: relative;
  margin-top: 2rem;
`;
const Title = styled.div`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: ${({ color }) => color && color};
  word-break: break-word;
`;
const Menu = styled.div`
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  font-size: 1rem;
  & > * {
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }
`;

const Buttons = css`
  transition: background-color 0.25s;
  & > span {
    margin-left: 0.5rem;
  }
`;

const StyledEditButton = styled(EditButton)`
  ${Buttons}
  width: 10rem;
`;

const ButtonsDiv = styled.div`
  position: absolute;
  white-space: nowrap;
  bottom: -1.5rem;
  margin-left: -0.5rem;
`;

class HeaderColumn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverHeaderColumn: false,
      isOpen: false,
      isConflict: false,
      currentColumn: null,
    };
  }

  componentDidMount() {
    if (this.props.conflicts) {
      this.checkIfConflict();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.conflicts !== this.props.conflicts) {
      this.checkIfConflict();
    }
  }

  checkIfConflict() {
    if (
      !isEmpty(
        filter(
          this.props.conflicts,
          conflict =>
            conflict.dimensions[CONTEXT.COLUMN] === this.props.columnId,
        ),
      )
    ) {
      this.setIsConflict(true);
    } else {
      this.setIsConflict(false);
    }
  }

  setHoverHeaderColumn(bool) {
    this.setState({ hoverHeaderColumn: bool });
  }

  setIsOpen(bool) {
    this.setState({ isOpen: bool });
  }

  setIsConflict(bool) {
    this.setState({ isConflict: bool });
  }

  openEditColumnModal() {
    this.setIsOpen(!this.state.isOpen);
    this.props.dispatchCreateColumn({
      settings: this.props.columns[this.props.columnId],
      id: this.props.columnId,
      context: CONTEXT.COLUMN,
    });
    this.props.dispatchCreateConflicts({
      settings: filter(
        this.props.conflicts,
        conflict => conflict.dimensions.column === this.props.columnId,
      ),
      id: this.props.conflicts && this.props.conflicts.length,
      context: CONTEXT.CONFLICTS,
    });
  }

  closeEditColumnModal = () => {
    this.setIsOpen(!this.state.isOpen);
    this.setHoverHeaderColumn(false);
  };

  render() {
    const columnTitle = get(this.props.temporaryColumn, ['title']);
    const columnSettings = get(this.props.temporaryColumn, ['settings']);
    const { t } = this.props;

    const MODAL_CONTENT_CONFIG = {
      cancelButton: {
        text: t('button:Cancel'),
      },
      validateButton: {
        text: t('button:Save'),
      },
      steps: {
        name: {
          title: t('Name'),
          stepBody: (
            <ModalNamePage
              stepTitle={t('Name your column')}
              context={CONTEXT.COLUMN}
              name={columnTitle}
            />
          ),
        },
        filters: {
          title: t('Filters'),
          stepBody: (
            <ModalFiltersPage
              stepTitle={t('Choose filters for this column')}
              columnCollapseContent={columnCollapseContent}
              context={CONTEXT.COLUMN}
              currentSettingsFilters={columnSettings}
            />
          ),
        },
      },
    };

    const ButtonToRender = () =>
      // eslint-disable-next-line no-nested-ternary
      this.state.isConflict ? (
        <ButtonsDiv>
          <ConflictButton
            text={t('conflicts:Conflict detected')}
            onClick={() => this.openEditColumnModal()}
            css={Buttons}
          />
        </ButtonsDiv>
      ) : this.state.hoverHeaderColumn ? (
        <ButtonsDiv>
          <MoveColumnButton
            primaryColor={this.props.columnColor}
            direction="left"
            {...this.props}
            itemId={this.props.columnId}
            disabled={this.props.columnId === 0}
            moveItem={this.props.dispatchMoveItem}
            context={STORE_TO_WATCH[CONTEXT.COLUMN]}
          />
          <StyledEditButton
            primaryColor={this.props.columnColor}
            text={t('Edit this column')}
            onClick={() => this.openEditColumnModal()}
          />
          <MoveColumnButton
            primaryColor={this.props.columnColor}
            direction="right"
            {...this.props}
            itemId={this.props.columnId}
            disabled={this.props.columnId === this.props.columns.length - 1}
            moveItem={this.props.dispatchMoveItem}
            context={STORE_TO_WATCH[CONTEXT.COLUMN]}
          />
        </ButtonsDiv>
      ) : null;

    return (
      <BenchmarkCol
        columnWidth={this.props.columnWidth}
        columnColor={this.state.isConflict ? red : this.props.columnColor}
        onMouseEnter={() => this.setHoverHeaderColumn(true)}
        onMouseLeave={() => this.setHoverHeaderColumn(false)}
      >
        <TextContainer>
          <Title color={this.state.isConflict && red}>
            {(this.state.currentColumn && this.state.currentColumn.title) ||
              this.props.title}
          </Title>
          <Menu>
            <BenchmarkColumnDropdown
              onClickEditAction={() => this.openEditColumnModal()}
              columnId={this.props.columnId}
            />
          </Menu>
        </TextContainer>
        <ButtonToRender />
        <EditModal
          disableOnClickOutside
          isOpen={this.state.isOpen}
          closeModal={() => this.closeEditColumnModal()}
          onCancel={this.props.dispatchDeleteTemporaryColumn}
          onSave={this.props.dispatchSaveTemporaryColumn}
          title={t('Edit your column')}
          config={{ mentions: false, date: false }}
          modalConfig={MODAL_CONTENT_CONFIG}
        />
      </BenchmarkCol>
    );
  }
}

HeaderColumn.propTypes = {
  title: PropTypes.string.isRequired,
  columnWidth: PropTypes.number.isRequired,
  columnColor: PropTypes.string,
  columnId: PropTypes.number.isRequired,
  dispatchCreateColumn: PropTypes.func.isRequired,
  dispatchDeleteTemporaryColumn: PropTypes.func.isRequired,
  dispatchSaveTemporaryColumn: PropTypes.func.isRequired,
  columns: PropTypes.node.isRequired,
  temporaryColumn: PropTypes.objectOf(PropTypes.any).isRequired,
  conflicts: PropTypes.arrayOf(PropTypes.any).isRequired,
  dispatchCreateConflicts: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

HeaderColumn.defaultProps = {
  columnColor: '#ffffff',
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreateColumn: newState => {
      dispatch(createTemporaryItem(newState));
    },
    dispatchDeleteTemporaryColumn: () => {
      dispatch(deleteTemporaryItem(CONTEXT.COLUMN));
      dispatch(deleteTemporaryItem(CONTEXT.CONFLICTS));
    },
    dispatchSaveTemporaryColumn: () => {
      dispatch(saveTemporaryItem(CONTEXT.COLUMN));
    },
    dispatchCreateConflicts: newState => {
      dispatch(createTemporaryItem(newState));
    },
    dispatchMoveItem: newState => {
      dispatch(moveItem(newState));
    },
  };
}

function mapStateToProps(state) {
  return {
    columns: state.benchmark.activeBenchmark.settings.columns,
    temporaryColumn: get(state, ['benchmark', 'temporaryColumn']),
    settings: get(state, ['benchmark', 'activeBenchmark', 'settings']),
    benchmarkId: get(state, ['benchmark', 'activeBenchmark', 'id']),
    projectId: get(state, ['projects', 'currentProject', 'projectId']),
    conflicts: state.benchmark.activeBenchmark.settings.conflicts,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark')(HeaderColumn));
