import React from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { storeUserInfos } from '_Resources/Auth/Actions/auth';
import { DispatchDataToStore } from '_Resources/Header/Services/api/DispatchDataToStore';
import { get } from 'lodash';
import currentUser from './graphql/currentUser';

class QueryCurrentUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate() {
    const isAdminSetInRedux = typeof get(this.props, ['auth', 'user', 'isAdmin']) === 'undefined';
    return isAdminSetInRedux;
  }

  render() {
    const { storeUserInfos } = this.props;
    return this.props.auth.user ? (
      <>
        <Query query={currentUser} {...this.props}>
          {({ loading, error, data, networkStatus }) => {
            if (loading || networkStatus === 4) {
              return false;
            }
            if (error) {
              return false;
            }
            return <DispatchDataToStore data={data} action={() => data && storeUserInfos(data.currentUser)} />;
          }}
        </Query>
      </>
    ) : null;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserInfos: newState => {
      dispatch(storeUserInfos(newState));
    },
  };
}

function mapStateToProps(state) {
  const { auth } = state;

  return { auth };
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryCurrentUser);
