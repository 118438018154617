import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { getRoute } from '_Utils/routes/routeUtils';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import ProjectDetailsCard from '_Cards/SettingsCards/ProjectDetailsCard/ProjectDetailsCard';

const ProjectDetails = ({ idpage, updateRoute, data, slug }) => {
  useEffect(() => {
    getRoute(updateRoute, idpage);
  }, []);
  return (
    <Container fluid className="py-3 px-4">
      <ProjectDetailsCard data={data} slug={slug} />
    </Container>
  );
};

const mapDispatchToProps = dispatch => ({
  updateRoute: newState => {
    dispatch(updateRoute(newState));
  },
});

export default connect(null, mapDispatchToProps)(ProjectDetails);
