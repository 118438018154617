import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const Title = styled.span``;

const ToggleIcon = styled.span`
	content: '';
	display: block;
  border-style: solid;
	border-width: .1em .1em 0 0;
	width: .45em;
	height: .45em;
	transform: ${props => (props.isCollapsed ? 'rotate(45deg)' : 'rotate(135deg)')};
  transform-origin: 75% 25%;
  transition: transform .3s cubic-bezier(.5, -.5, .5, .5);
}
`;

const QAccordionPanelHeader = ({ title, isCollapsed, toggleHeaderHandler }) => (
  <Wrapper onClick={toggleHeaderHandler}>
    <Title>{title}</Title>
    {/* isCollapsed ? <ChevronDown /> : <ChevronRight /> */}
    <ToggleIcon isCollapsed={isCollapsed} />
  </Wrapper>
);

export default QAccordionPanelHeader;
