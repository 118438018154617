import gql from 'graphql-tag';

const query = gql`
  query getVarianceAnalysis(
    $projectId: Int!
    $currentPeriod: DateRangeInput!
    $referencePeriod: DateRangeInput!
    $indicator: String!
    $level: Int
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
  ) {
    getVarianceAnalysis(
      projectId: $projectId
      currentPeriod: $currentPeriod
      referencePeriod: $referencePeriod
      indicator: $indicator
      level: $level
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
    ) {
      globalCurrentVarianceAnalysis {
        totalEffect
        indicatorTotal
        verbatimWithThematicCount
        verbatimAmount
      }
      globalReferenceVarianceAnalysis {
        totalEffect
        indicatorTotal
        verbatimWithThematicCount
        verbatimAmount
      }
      thematics {
        name
        mixEffect
        tonalityEffect
        totalEffect
        volumeEffect
        subThematics {
          name
          mixEffect
          tonalityEffect
          totalEffect
          volumeEffect
          subSubThematics {
            name
            mixEffect
            tonalityEffect
            totalEffect
            volumeEffect
          }
        }
      }
    }
  }
`;

export default query;
