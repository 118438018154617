import React from 'react';
import styled from 'styled-components';
import { red } from 'styles/abstracts/colors';
import { withTranslation } from 'react-i18next';
import { CONTEXT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';

const DateRangeConflictSubtitle = styled.p`
  color: ${red};
  font-weight: bold;
`;

const ConflictSubtitle = ({ context, t, globalConflict, itemTitle }) => (
  <DateRangeConflictSubtitle>
    {globalConflict
      ? t('No common date between this cardColumn and the global context', {
          type: `$t(${context})`,
        })
      : t('No common date between this cardColumn and the cardColumn', {
          type: `$t(${context})`,
          otherType: `$t(${
            context === CONTEXT.CARD ? t(CONTEXT.COLUMN) : t(CONTEXT.CARD)
          })`,
          title: itemTitle,
        })}
  </DateRangeConflictSubtitle>
);

export default withTranslation('conflicts')(ConflictSubtitle);
