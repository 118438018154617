import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SavePageButton from '_Container/SavePageButton/SavePageButton';
import mutationSaveBenchmark from '_Resources/Benchmark/Services/graphql/saveBenchmark';
import { updateQueryBenchmarkList } from '_Resources/Benchmark/Services/MutationSaveBenchmark';
import _ from 'lodash';
import { SETTINGS_TYPES } from '_Resources/Benchmark/Services/QueryBenchmarkList';
import { getFiltersFromOptions } from '_Providers/QFilterKeysProvider/QFilterKeysProvider';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

class OptionsButtons extends React.Component {
  static propTypes = {
    activeBenchmark: PropTypes.objectOf(PropTypes.any).isRequired,
    idpage: PropTypes.string.isRequired,
    project_current: PropTypes.objectOf(PropTypes.any).isRequired,
    activeLayoutEdition: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeBenchmark: null,
      variables: null,
      queryForUpdate: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeBenchmarkState !== this.props.activeBenchmarkState) {
      this.setState({ activeBenchmark: this.props.activeBenchmarkState });
    }
    if (prevState.activeBenchmark !== this.state.activeBenchmark) {
      this.prepareVariables();
    }
  }

  prepareVariables() {
    const test = _.cloneDeep(this.state.activeBenchmark);
    if (this.state.activeBenchmark) {
      const optionsToCriteriaKeys =
        this.state.activeBenchmark && this.prepareBenchmarks(test);
      const variables = this.state.activeBenchmark && {
        id: this.state.activeBenchmark.id,
        settings: JSON.stringify(optionsToCriteriaKeys.settings),
      };
      const projectId = this.props.project_current.id;
      const queryForUpdate = updateQueryBenchmarkList(
        projectId,
        variables && variables.settings,
      );
      this.setState({
        variables,
        queryForUpdate,
      });
    }
  }

  prepareBenchmarks(benchmark) {
    // TODO replace var settings with real data when BE ready
    const settings = _.cloneDeep(benchmark.settings);
    // TODO undo json stringify and use directly benchmark.settings
    const settingsTypes = Object.keys(SETTINGS_TYPES);
    const benchmarkSettings = { ..._.cloneDeep(benchmark) };
    if (!_.isEmpty(settings)) {
      settingsTypes.forEach(settingsType => {
        const CONFIG = SETTINGS_TYPES[settingsType];
        const arrayToTransform = settings[CONFIG];
        const resultArray = arrayToTransform.map(setting => ({
          title: setting.title,
          settings: getFiltersFromOptions(setting.settings),
          mergedSettings: getFiltersFromOptions(setting.mergedSettings),
        }));
        benchmarkSettings.settings[CONFIG] = resultArray;
      });
      benchmarkSettings.settings.globalSettings = getFiltersFromOptions(
        settings.globalSettings,
      );
      benchmarkSettings.settings.globalSettings.projectId = this.props.project_current.id;
    }
    return benchmarkSettings;
  }

  render() {
    const { variables, queryForUpdate } = this.state;

    return (
      <Container>
        {this.props.idpage === 'benchmark' && this.state.activeBenchmark && (
          <SavePageButton
            variables={variables}
            query={mutationSaveBenchmark}
            delay={30000}
            idpage={this.props.idpage}
            updateFunction={(cache, saveSettings) =>
              queryForUpdate(cache, saveSettings)
            }
            activeBenchmark={this.props.activeBenchmarkState}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeLayoutEdition: state.routes.activeLayoutEdition,
    idpage: state.routes.subRoute && state.routes.subRoute.id,
    activeBenchmarkState: state.benchmark.activeBenchmark,
    project_current: _.get(state, ['projects', 'currentProject']),
  };
}

export default connect(mapStateToProps)(OptionsButtons);
