import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changePageByShortcut } from '../../../../../../_Resources/Reporting/Actions/changePageByShortcut';
import QLayout from '../../../../../../_Components/Layouts/QLayout/QLayout';
import PagesShortcuts from '../../../_Components/PagesShortcuts';
import emptyLayouts from '../../layouts/emptyLayouts';
import { PAGES_SHORTCUT_MODES } from '../../../_Components/Shortcut';

class DefaultLayouts extends Component {
  static propTypes = {
    dispatchChangePageByShortcut: PropTypes.func.isRequired,
    currentPageIndex: PropTypes.number,
  };

  static defaultProps = {
    currentPageIndex: null,
  };

  getShortcuts = () =>
    emptyLayouts.map(shortcut => ({
      ...shortcut,
      content: () => (
        <QLayout
          active={false}
          layout={shortcut.children}
          cssGridLayout={shortcut.cssGridLayout}
        />
      ),
    }));

  onPageChange = page => {
    const { dispatchChangePageByShortcut, currentPageIndex } = this.props;
    dispatchChangePageByShortcut({
      children: page.children,
      size: page.size,
      cssGridLayout: page.cssGridLayout,
      pageIndex: currentPageIndex,
    });
  };

  render() {
    return (
      <PagesShortcuts
        mode={PAGES_SHORTCUT_MODES.EMPTY_LAYOUTS}
        shortcuts={this.getShortcuts()}
        onShortcutClicked={this.onPageChange}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchChangePageByShortcut: (...args) =>
    dispatch(changePageByShortcut(...args)),
});

const mapStateToProps = ({ reporting: { currentPageIndex } }) => ({
  currentPageIndex,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultLayouts);
