import React from 'react';

const DataSummary = ({ preferDate, satMark, recoMark, t }) => (
  <>
    <span>
      {t(`${!preferDate ? "Don't prefer" : 'Prefer'} the "Date of visit" field if provided (use the "comment date" if not)`)}{' '}
    </span>{' '}
    <br />
    <span>{t('Satisfaction mark')}: {satMark ? `${t('Mark')}/${satMark}` : t('No mark')}</span>
    <br />
    {/* <span>Reco mark: {recoMark ? `Mark/${recoMark}` : 'No mark'}</span> */}
  </>
);

export default DataSummary;
