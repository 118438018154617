import React, { useState } from 'react';
import styled from 'styled-components';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { get } from 'lodash';

import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import EindexEvolutionWithMentionsSection from './EindexEvolutionWithMentionsSection';

type EindexEvolutionWithMentionsCardProps = {
  title: string;
  titleSecondPart: string;
  dropDownOptions: Array<string>;
  mixBarLine?: boolean;
  multipleEindex?: boolean;
  hideRangeButtons: boolean;
  filterKeys: any;
  filters: any;
};

const SwitcherContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const RANGE_BUTTONS: Array<any> = [
  {
    label: 'Day',
    id: 'day',
  },
  {
    label: 'Week',
    id: 'week',
  },
  {
    label: 'Month',
    id: 'month',
  },
];

const EindexEvolutionWithMentionsCard = (props: EindexEvolutionWithMentionsCardProps) => {
  const [range, setRange] = useState<'day' | 'week' | 'month'>('day');

  return (
    <QcardPanel {...props} hints={[HintsContent.HINT_EINDEX_EVOLUTION]}>
      {!props.hideRangeButtons && (
        <SwitcherContainer>
          <QSwitcher
            selected={range}
            options={RANGE_BUTTONS}
            onOptionSelected={setRange}
            padding="0.25rem 1rem"
            display="inline-flex"
            width="auto"
          />
        </SwitcherContainer>
      )}
      <Row className="" style={{ height: '100%' }}>
        <EindexEvolutionWithMentionsSection mixBarLine={props.mixBarLine} filters={props.filterKeys} range={range} />
      </Row>
    </QcardPanel>
  );
};

EindexEvolutionWithMentionsCard.defaultProps = {
  mixBarLine: false,
  multipleEindex: false,
};

const mapStateToProps = (state: any) => ({
  filterKeys: get(state, ['filters', 'filterKeys']),
});

export default connect(mapStateToProps)(EindexEvolutionWithMentionsCard);
