import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PagePropTypes } from '../../../../_Resources/Reporting/Services/types/Page.propTypes';
import { TEXT_TYPES } from '../../../../_Components/Texts/textTypes';
import QPage from '../../../../_Components/Panels/QPage/QPage';
import QFooter from '../../../../_Components/Panels/QFooter/QFooter';
import { withTranslation } from 'react-i18next';

class PageFooter extends PureComponent {
  static propTypes = {
    footer: PagePropTypes.isRequired,
    index: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    reportTitle: PropTypes.string.isRequired,
  };

  getLeftSideParams = ({
    isReportTitle,
    isEditionDate,
    isCustomizedText,
    customizedText,
  }) => {
    const { reportTitle, t, lang } = this.props;
    return [
      {
        enabled: isReportTitle,
        name: reportTitle,
      },
      {
        enabled: isCustomizedText,
        name: customizedText,
      },
      {
        enabled: isEditionDate,
        name: `${t('Edited on')}: ${moment()
          .locale(lang)
          .format('LL')}`,
      },
    ];
  };

  renderLeftSide = () => {
    const { footer } = this.props;
    return this.getLeftSideParams(footer)
      .filter(({ enabled }) => !!enabled)
      .map(({ name }) => name)
      .join(' • ');
  };

  renderRightSide = () => {
    const {
      index,
      size,
      footer: { isPagination },
    } = this.props;
    return isPagination ? (
      <Fragment>
        {index + 1} / {size}
      </Fragment>
    ) : null;
  };

  render() {
    return (
      <QPage.Footer type={TEXT_TYPES.PANEL}>
        <QFooter.LeftBar>{this.renderLeftSide()}</QFooter.LeftBar>
        <QFooter.RightBar>{this.renderRightSide()}</QFooter.RightBar>
      </QPage.Footer>
    );
  }
}

export default withTranslation('card')(PageFooter);
