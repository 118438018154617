import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PagesShortcuts from '../../../_Components/PagesShortcuts';
import { addPageByShortcut } from '../../../../../../_Resources/Reporting/Actions/addPageByShortcut';
import predefinedLayouts from '../../layouts/predefinedLayouts';
import { PAGES_SHORTCUT_MODES } from '../../../_Components/Shortcut';

class PredefinedPages extends Component {
  static propTypes = {
    onPageAdd: PropTypes.func.isRequired,
  };

  render() {
    const { onPageAdd } = this.props;
    return (
      <PagesShortcuts
        mode={PAGES_SHORTCUT_MODES.PREDEFINED_LAYOUTS}
        shortcuts={predefinedLayouts}
        onShortcutClicked={onPageAdd}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onPageAdd: (...args) => dispatch(addPageByShortcut(...args)),
});

export default connect(
  null,
  mapDispatchToProps,
)(PredefinedPages);
