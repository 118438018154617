import { first, isEmpty } from 'lodash';
import { INDICATORS } from './indicators';

export const EINDEX_MODES = {
  CELSIUS: 'celsius',
  FAHRENHEIT: 'fahrenheit',
};

export const getEindexMode = isFahrenheit =>
  isFahrenheit ? EINDEX_MODES.FAHRENHEIT : EINDEX_MODES.CELSIUS;
const nonNumericRange = { min: null, max: null };
const deltaRange = { min: 'down', max: 'up' };
const kpiRange = { min: '0', max: '10' };

const getDefaultRange = (insights, period) =>
  period !== 'current' ? deltaRange : nonNumericRange;

const prepareGetEindexRange = range => (insights, period) =>
  period === 'current' ? range : getDefaultRange(insights, period);

const getMentionsRange = (insights, period) => ({
  min: '0',
  max: !isEmpty(first(insights))
    ? first(insights).entryPoints[period][INDICATORS.MENTIONS].value
    : '0',
});

const RANGES_CONFIG = {
  [EINDEX_MODES.FAHRENHEIT]: {
    [INDICATORS.EINDEX]: prepareGetEindexRange({
      min: '0°',
      max: '100°',
    }),
    [INDICATORS.MENTIONS]: getMentionsRange,
    [INDICATORS.SATISFACTION]: () => kpiRange,
    [INDICATORS.RECOMMENDATION]: () => kpiRange,
    [INDICATORS.CES]: () => kpiRange,
  },
  [EINDEX_MODES.CELSIUS]: {
    [INDICATORS.EINDEX]: prepareGetEindexRange({
      min: '-20°',
      max: '40°',
    }),
    [INDICATORS.MENTIONS]: getMentionsRange,
    [INDICATORS.SATISFACTION]: () => kpiRange,
    [INDICATORS.RECOMMENDATION]: () => kpiRange,
    [INDICATORS.CES]: () => kpiRange,
  },
};

export const RANGES = Object.keys(RANGES_CONFIG).reduce(
  (ranges, eindexMode) => ({
    ...ranges,
    [eindexMode]: {
      ...RANGES_CONFIG[eindexMode],
      default: getDefaultRange,
    },
  }),
  {},
);
