import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import refineByPropTypes from './RefineBy.propTypes';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const RangeLabel = styled.span`
  margin: -8px;
  font-size: 0.9em;
  font-weight: bold;
  color: #928fb0;
`;
class RefineBySensitivity extends Component {
  static propTypes = {
    ...refineByPropTypes,
  };

  marks = {
    '0': { label: '0', style: { color: '#592ea0' } },
    '10': { label: '10', style: { color: '#592ea0' } },
    '20': { label: '20', style: { color: '#592ea0' } },
    '30': { label: '30', style: { color: '#592ea0' } },
    '40': { label: '40', style: { color: '#592ea0' } },
    '50': { label: '50', style: { color: '#592ea0' } },
    '60': { label: '60', style: { color: '#592ea0' } },
    '70': { label: '70', style: { color: '#592ea0' } },
    '80': { label: '80', style: { color: '#592ea0' } },
    '90': { label: '90', style: { color: '#592ea0' } },
    '100': { label: '100', style: { color: '#592ea0' } },
  };

  render() {
    const { value, onChange } = this.props;
    return (
      <>
        <RangeLabel>
          {this.props.t(
            'Similarity (how close are results from initial terms)',
          )}
        </RangeLabel>
        <Range
          min={0}
          max={100}
          value={value || [0, 100]}
          tipFormatter={value => `${value}%`}
          marks={this.marks}
          trackStyle={[
            { backgroundColor: '#592EA0' },
            { backgroundColor: 'yellow' },
          ]}
          handleStyle={[
            {
              transform: 'translateY(-3px) translateX(-3px)',
              backgroundColor: '#592EA0',
              borderColor: 'white',
              width: '20px',
              height: '20px',
            },
          ]}
          activeDotStyle={{
            backgroundColor: '#592EA0',
            borderColor: '#592EA0',
          }}
          onChange={onChange}
        />
      </>
    );
  }
}

export default withTranslation('insights')(RefineBySensitivity);
