/* eslint-disable import/prefer-default-export */
import { merge } from 'lodash';

export const transformSensitivityToPercents = sentence => {
  const { results } = sentence;
  return results
    ? merge({}, sentence, {
        results: { sensitivity: results.sensitivity * 100 },
      })
    : sentence;
};

export const prepareTransformRelevantWithFilters = filtersFunctions => sentence => ({
  ...sentence,
  relevant:
    sentence.relevant && filtersFunctions.map(fn => fn(sentence)).every(r => r),
});
