import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import CModal from '_Components/CModal/CModal';
import { QSideBarLayout } from '_Components/Layouts/QSideBarLayout';
import QButton from '_Components/Buttons/QButton/QButton';
import { useQuery } from 'react-apollo';
import GET_CLUSTER_VERBATIM from '_Resources/Cluster/Services/api/graphql/getClusterVerbatims';
import renameCluster from '_Resources/Cluster/Services/api/graphql/renameCluster';
import ClusterVerbatims from './ClusterVerbatims';

const StyledCModal = styled(CModal)`
  max-width: 1200px !important;
`;

const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  margin: -16px;
`;

const QSideBarLayoutDisplayArea = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  right: 1em;
  top: 1em;
  padding: 0 !important;
`;

const ClusterDetailModal = ({
  projectId,
  cluster,
  onClose,
  isOpen,
  range,
  t,
  totalProjectMentions,
  verbatimsCountSort,
  refetch,
}) => {
  const [clusterVerbatims, setClusterVerbatims] = useState([]);
  const { id } = cluster.clickedCluster || {};
  const { title } = cluster?.clickedCluster || { title: '' };
  const getTitle = () => {
    const newTitle = title.replace(/:$/, '');
    return newTitle;
  };

  const onModalClose = (refetchDataOnClose: boolean) => {
    onClose(refetchDataOnClose);
  };

  const { data } = useQuery(GET_CLUSTER_VERBATIM, {
    variables: {
      projectId,
      clusterId: id,
      range,
    },
  });

  const getFooter = () => {
    return (
      <>
        <QButton
          onClick={() => onModalClose(true)}
          size="normal"
          bgColor="purple"
        >
          {t('Close')}
        </QButton>
      </>
    );
  };

  const editTitleMutationVariables = {
    projectId,
    clusterId: id,
  };

  useEffect(() => {
    setClusterVerbatims(data?.getClusterVerbatims.verbatimList);
  }, [data]);

  const buttonRight = (
    <StyledQButton
      bgColor="greyShades.g300"
      type="button"
      onClick={() => onModalClose(true)}
      size="small"
      icon="CROSS"
      iconSize={24}
    />
  );

  const renderTabContent = () => (
    <ClusterVerbatims
      clusterVerbatims={clusterVerbatims}
      mentionsNumber={totalProjectMentions}
      clusterId={id}
      clusterTitle={getTitle()}
      projectId={projectId}
      range={range}
      closeModal={onModalClose}
      verbatimsCountSort={verbatimsCountSort}
      refetch={refetch}
      cluster={cluster}
    />
  );

  return (
    <>
      <StyledCModal
        data={{}}
        title={getTitle()}
        displayMentionsSubtitle={false}
        displayCardSubtitle
        displayDateSubtitle={false}
        modal={isOpen}
        footer={getFooter()}
        toggle={onModalClose}
        clusterEditableTitle
        editTitleMutation={renameCluster}
        editTitleMutationVariables={editTitleMutationVariables}
        buttonRight={buttonRight}
      >
        <Body>
          <HeaderArea />
          <QSideBarLayout>
            <QSideBarLayoutDisplayArea>
              {renderTabContent()}
            </QSideBarLayoutDisplayArea>
          </QSideBarLayout>
        </Body>
      </StyledCModal>
    </>
  );
};

const mapStateToProps = ({
  filters,
  insight: { insightDetails, insightDetailsVerbatim },
  mentions: { totalSentenceCountWithNull },
  auth,
  cluster,
}) => ({
  verbatim: insightDetailsVerbatim,
  insight: insightDetails,
  cluster,
  projectId: get(filters, ['filterKeys', 'projectId']),
  range: get(filters, ['filterKeys', 'range']),
  totalProjectMentions: totalSentenceCountWithNull,
  userInfos: auth.user,
});

export default connect(mapStateToProps)(
  withTranslation('button')(ClusterDetailModal),
);
