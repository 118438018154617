import gql from 'graphql-tag';

const mutation = gql`
  mutation saveReportingModel(
    $title: String!
    $projectId: Int!
    $settings: Json
    $reportingModelId: Int
  ) {
    saveReportingModel(
      title: $title
      projectId: $projectId
      settings: $settings
      reportingModelId: $reportingModelId
    ) {
      id
      title
      updatedAt
      updatedBy
      errors
      canCreateReporting
    }
  }
`;

export default mutation;
