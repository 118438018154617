import React from 'react';
import styled from 'styled-components';
import { find, get, upperFirst, orderBy } from 'lodash';
import OrderByDropdown from './Dropdowns/OrderByDropdown';
import DataByFiltersDropdown from './Dropdowns/DataByFiltersDropdown';
import { THEMATICS_TYPE, INDICATORS_CONFIG } from '../FiltersSettings';

const SelectsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 3.5em;
  margin-right: 1em;
  flex-wrap: wrap;
`;

const IndicatorsDropdownsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 2em;
  margin-left: 3em;
  & div {
    margin-right: 0.5em;
  }
`;

const ThematicsDropdownsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & div {
    margin-right: 0.5em;
  }
  max-width: 25em;
`;

const DropdownsContainer = props => {
  const settingsVariables = {
    projectId: props.filterKeys && props.filterKeys.projectId,
    pageId: props.idpage,
    settings: JSON.stringify({
      selectedCriteriaKey: props.selectedCriteriaKey,
      indicators: props.currentIndicators,
      thematic: props.thematic,
      orderBy: props.orderBy,
    }),
  };

  const subThematics =
    props.projectThematics &&
    props.thematic &&
    props.thematic.thematic &&
    get(
      find(props.projectThematics, thematic => thematic.id === props.thematic.thematic),
      ['values'],
    );
  const subSubThematics =
    subThematics &&
    get(
      find(subThematics, subThematic => subThematic.id === props.thematic.subThematic),
      ['values'],
    );
  return (
    <div id="dataByFilterDropdownsContainer">
      <SelectsContainer>
        <DataByFiltersDropdown
          id="filterToDisplayContainer"
          dropdownType="selectedCriteriaKey"
          label="Filter to display"
          labelToShow={props.selectedCriteriaKey}
          itemToIterate={props.criteriaKeys}
          updateValue={props.setCurrentCriteriaKey}
          settingsVariables={settingsVariables}
          defaultThematic={props.defaultThematic}
          projectConfiguration={props.projectConfiguration}
          size="xl"
        />
        <IndicatorsDropdownsContainer>
          {Object.keys(INDICATORS_CONFIG).map(indicator => (
            <DataByFiltersDropdown
              id={`${indicator}IndicatorContainer`}
              dropdownType="indicators"
              subCategory={indicator}
              label={`${upperFirst(indicator)} value`}
              labelToShow={props.currentIndicators[indicator].label}
              itemToIterate={INDICATORS_CONFIG[indicator]}
              updateValue={props.updateCurrentIndicators}
              settingsVariables={settingsVariables}
              icon={`${indicator.toUpperCase()}_INDICATOR`}
              defaultThematic={props.defaultThematic}
              projectConfiguration={props.projectConfiguration}
            />
          ))}
        </IndicatorsDropdownsContainer>
        <ThematicsDropdownsContainer>
          {props.thematic && props.thematic.thematic && (
            <>
              <DataByFiltersDropdown
                id="thematicsDropdownContainer"
                dropdownType="thematic"
                subCategory={THEMATICS_TYPE.SUBTHEMATIC}
                label="Sub thematic"
                labelToShow={props.thematic[THEMATICS_TYPE.SUBTHEMATIC]}
                itemToIterate={orderBy(subThematics, ['label'], ['asc'])}
                settingsVariables={settingsVariables}
                updateValue={props.updateSubThematic}
                defaultThematic={props.defaultThematic}
                projectConfiguration={props.projectConfiguration}
              />
              {props.thematic.subThematic && (
                <DataByFiltersDropdown
                  dropdownType="thematic"
                  subCategory={THEMATICS_TYPE.SUBSUBTHEMATIC}
                  label="Sub sub thematic"
                  labelToShow={props.thematic[THEMATICS_TYPE.SUBSUBTHEMATIC]}
                  itemToIterate={subSubThematics && orderBy(subSubThematics, ['label'], ['asc'])}
                  settingsVariables={settingsVariables}
                  updateValue={props.updateSubThematic}
                  defaultThematic={props.defaultThematic}
                  projectConfiguration={props.projectConfiguration}
                />
              )}
            </>
          )}
        </ThematicsDropdownsContainer>
        <OrderByDropdown
          id="orderByDropdownContainer"
          orderBy={props.orderBy}
          setOrderBy={props.setOrderBy}
          settingsVariables={settingsVariables}
          currentIndicators={props.currentIndicators}
          defaultThematic={props.defaultThematic}
          projectConfiguration={props.projectConfiguration}
        />
      </SelectsContainer>
    </div>
  );
};

export default DropdownsContainer;
