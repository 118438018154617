import React, { PureComponent } from "react";
import QPanelHeader from "../components/QPanelHeader";
import QMapPanel from "../components/QMapPanel";

export default class CurrentPlace extends PureComponent {
  onMouseEnter = () => {
    this.props.onMouseEnter && this.props.onMouseEnter();
  };

  onMouseLeave = () => {
    this.props.onMouseLeave && this.props.onMouseLeave();
  };

  render() {
    return (
      <QMapPanel
        className={this.props.className}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <QPanelHeader>{this.props.name}</QPanelHeader>
      </QMapPanel>
    );
  }
}
