import React from 'react';
import QModal from '_Components/Modals/QModal/QModal';
import { Player, BigPlayButton, ControlBar } from 'video-react';


const VideoModal = ({toggle, isOpen, data}) => (
  <QModal onClose={() => toggle(false)} isOpen={isOpen} config={{mentions: false, date: false}} title={data.name}>
    <Player playsInline src={data.video}>
      <BigPlayButton position="center" />
      <ControlBar className="my-class" />
    </Player>
  </QModal>
)

export default VideoModal;
