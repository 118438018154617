import {
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  GET_USER_INFOS,
  RENEW_USER_API_TOKEN,
} from '_Resources/Auth/auth';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ forceRefresh: true });

export function loginFailure(errors) {
  return {
    type: LOGIN_FAILURE,
    payload: {
      errors,
    },
  };
}

function logoutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}

function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function logout() {
  const currentFilters = localStorage.getItem('filters');
  window.localStorage.clear();
  window.localStorage.setItem('filters', currentFilters);
  history.push('/login', { tokenExpired: true });
  return dispatch => {
    dispatch(logoutRequest());
    dispatch(logoutSuccess());
  };
}

export function storeUserInfos(data) {
  return {
    type: GET_USER_INFOS,
    payload: {
      data,
    },
  };
}

export function renewUserApiToken(token) {
  return {
    type: RENEW_USER_API_TOKEN,
    payload: {
      token,
    },
  };
}
