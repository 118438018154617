import React from 'react';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import EindexEvolutionWithMentionsSectionBis from '_Cards/EIndexCards/EindexEvolutionWithMentionsCard/EindexEvolutionWithMentionsSection';
import CardSection from '../../../../Routes/GlobalExperience/Reporting/_Components/CardSection';
import LayoutSection from '../../../../Routes/GlobalExperience/Reporting/_Components/LayoutSection';
import EmotionEvolutionLineSection from '../../../../_Cards/EmotionsCards/EmotionEvolutionLineCard/EmotionEvolutionLineSection';
import EmotionBreakdownDonutSection from '../../../../_Cards/EmotionsCards/EmotionBreakdownDonutCard/EmotionBreakdownDonutSection';
import EindexTemperatureSection from '../../../../_Cards/EIndexCards/EindexTemperatureCard/EindexTemperatureSection';
import EmotionIntensityGaugeSection from '../../../../_Cards/EmotionsCards/EmotionIntensityGaugeCard/EmotionIntensityGaugeSection';
import SpeechEngagementSection from '../../../../_Cards/EmotionalKPIsCards/SpeechEngagementCard/SpeechEngagementSection';
import CategoryGlobalBestAndPaintPointCardSection from '../../../../_Cards/ThematicsCard/CategoryGlobalBestAndPainPointsCard/CategoryGlobalBestAndPaintPointCardSection';
import SatisfactionNpsSection from '../../../../_Cards/SatisfactionCards/SatisfactionNpsCard/SatisfactionNpsSection';
import MentionBadgeSection from '../../../../_Cards/MentionsCards/MentionBadgeCard/MentionBadgeSection';
import ReportingBestWorstVerbatimCardContainer from '../../../../_Cards/VerbatimCards/ReportingBestWorstVerbatimCard/ReportingBestWorstVerbatimContainer';
import EindexBadgeSection from '../../../../_Cards/EIndexCards/EindexBadgeCard/EindexBadgeSection';
import { WordCloudSection } from '../../../../_Cards/WordCards/WordCloudCard/WordCloudCard';
import EindexBarGraphSection from '../../../../_Cards/EIndexCards/EindexBarGraphCard/EindexBarGraphSection';

const placeholderComponents = {
  noDataComponent: <NoDataMessage imagePlaceholder={null} secondText="No data" mainTitle={null} />,
  errorComponent: <NoDataMessage imagePlaceholder={null} secondText="Error" mainTitle={null} />,
};

// TODO: it should be an object

export default {
  eindex: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <EindexTemperatureSection
            filters={filters}
            {...placeholderComponents}
            reportingAnalysisAxis={section.settings?.analysisAxis}
          />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  mention: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <MentionBadgeSection filters={filters} type="medium" {...placeholderComponents} />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  emotionalIntensity: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ width: '60%', height: '60%' }}>
            <EmotionIntensityGaugeSection filters={filters} {...placeholderComponents} />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  speechEngagement: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ width: '60%', height: '60%' }}>
            <SpeechEngagementSection filters={filters} {...placeholderComponents} />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  emotionsBreakdown: {
    factory: (id, section, filters) => {
      return (
        <LayoutSection section={section}>
          <CardSection id={id}>
            <div style={{ width: '100%', height: '100%' }}>
              <EmotionBreakdownDonutSection
                filters={filters}
                reportingMode
                {...placeholderComponents}
                settings={section?.settings}
                reportingAnalysisAxis={section.settings?.analysisAxis}
              />
            </div>
          </CardSection>
        </LayoutSection>
      );
    },
    settings: {},
  },
  eindexEvolution: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ width: '100%', height: '100%' }}>
            <EindexEvolutionWithMentionsSectionBis
              settings={section.settings}
              filters={filters}
              hideRangeButtons
              {...placeholderComponents}
            />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  emotionsEvolution: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <EmotionEvolutionLineSection
            filters={filters}
            hideRangeButtons
            hideDataDisplayButtons
            settings={section.settings}
            reportingAnalysisAxis={section.settings?.analysisAxis}
            {...placeholderComponents}
          />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  bestAndWorstCategories: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <CategoryGlobalBestAndPaintPointCardSection
            filters={filters}
            reportingMode
            {...placeholderComponents}
            level={section.settings?.level}
          />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  satisfaction: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <SatisfactionNpsSection filters={filters} {...placeholderComponents} />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  eindexBadge: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <EindexBadgeSection
            filters={filters}
            id={id}
            settings={section?.settings}
            badgeShow={section.settings?.badgeShow}
            {...placeholderComponents}
          />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  bestVerbatim: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ height: '100%' }} className="reportingVerbatimContainer">
            <ReportingBestWorstVerbatimCardContainer
              typeVerbatim="bestVerbatim"
              filterKeys={filters}
              {...placeholderComponents}
            />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  worstVerbatim: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <div style={{ height: '100%' }} className="reportingVerbatimContainer">
            <ReportingBestWorstVerbatimCardContainer
              typeVerbatim="worstVerbatim"
              filterKeys={filters}
              {...placeholderComponents}
            />
          </div>
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  wordCloud: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <WordCloudSection filters={filters} height="100%" />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
  eindexBarGraph: {
    factory: (id, section, filters) => (
      <LayoutSection section={section}>
        <CardSection id={id}>
          <EindexBarGraphSection filters={filters} height="100%" style={{ height: '100%' }} />
        </CardSection>
      </LayoutSection>
    ),
    settings: {},
  },
};
