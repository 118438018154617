import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NestedInputTags } from '@qemotion_team/react-nested-input-tags';
import { OptionsProps } from '../QNestedInputTags/QNestedInputTag';
import SingleTagInput from './_Components/SingleTagInput';

class QSingleNestedInputTags extends Component {
  static propTypes = {
    // eslint-disable-next-line react/require-default-props
    options: PropTypes.arrayOf(PropTypes.shape(OptionsProps)).isRequired,
    onSelected: PropTypes.func.isRequired,
    onRemoved: PropTypes.func.isRequired,
    initialValues: PropTypes.arrayOf(
      PropTypes.shape({
        option: PropTypes.any,
        parents: PropTypes.string,
      }),
    ),
  };

  static defaultProps = {
    initialValues: null,
  };

  render() {
    const { options, onSelected, onRemoved, initialValues } = this.props;
    return (
      <NestedInputTags
        options={options}
        onSelected={onSelected}
        onRemoved={onRemoved}
        inputFactory={props => <SingleTagInput {...props} />}
        initialValues={initialValues}
        isFlat
      />
    );
  }
}

export default QSingleNestedInputTags;
