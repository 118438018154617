import { isNil, complement, values, filter, map, pipe, find } from 'ramda';

const isNotNil = complement(isNil);

export const getNotNilValues = pipe(values, filter(isNotNil));

export const convertModeToOption = ({ label, mode }) => {
  return {
    label,
    value: mode,
  };
};

export const convertModesToOptions = pipe(
  getNotNilValues,
  map(convertModeToOption),
);

const isMode = mode => ({ mode: current }) => current === mode;

export const findMode = (mode, modes) => {
  const searchModesValuesFor = pipe(getNotNilValues, find(isMode(mode)));
  return searchModesValuesFor(modes);
};
