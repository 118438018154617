import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Fade } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QButton from '_Components/Buttons/QButton/QButton';
import { purple, lightBlue, themeColors } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import LoginForm from './_Components/LoginForm/LoginForm';
import PasswordReset from '../PasswordReset/PasswordReset';
import SignUp from '../SignUp/SignUp';
import ForgotPassword from './_Components/ForgotPassword/ForgotPassword';
import screenshotSignUpfr from './img/sign-up-screenshotfr.png';
import screenshotSignUpen from './img/sign-up-screenshoten.png';
import screenshortSignIn from './img/screen_signin.png';

const StyledContainer = styled(Container)`
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const Body = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  min-width: 100vw;
  background-color: ${purple};
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
`;

const TextContainer = styled(StyledContainer)`
  position: relative;
  top: 0;
  left: 0;
  width: 60%;
  min-width: 60%;
  color: white;
  display: ${({ windowDimensions: { width } }) => width < 1081 && 'none'};
  overflow-y: scroll;
  margin: 0px;
  & img {
    max-height: 20em;
    max-width: 20em;
  }

  & a,
  & a:hover {
    color: white;
    text-decoration: underline;
  }
`;

const ExplanationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${fontSizes.bigger};

  & h2 {
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 1em;
  }

  & div div:not(.signUpOrderedList) {
    font-weight: bold;
    margin-bottom: 0.5em;
    text-align: center;
  }

  & div .signUpOrderedList {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .w-65 {
    width: 65%;

    & ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding: 0;
      margin-left: 5vw;
      & li {
        text-align: left;
        margin-bottom: 0.5em;

        &::before {
          content: '•';
          margin-right: 1em;
        }
      }
    }
  }

  & div .supportMessage {
    margin-top: 4em;
    font-size: ${fontSizes.extraSmall};
  }
`;

const FormCardContainer = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  right: 0;
  width: ${({ windowDimensions: { width } }) => {
    if (width < 576) {
      return '90%';
    }
    if (width < 768) {
      return '80%';
    }
    if (width < 992) {
      return '60%';
    }
    return '40%';
  }};
  min-width: 40%;
  padding: 0;
`;

const FormCard = styled(QPanel)`
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  width: -webkit-fill-available;
  padding: 0rem 0.55em 0rem 1rem;
  margin: 0 1rem 0 0;
`;

const BrowserInformation = styled.div`
  width: 100%;
  text-align: center;
  margin-left: -1.5em;
  color: ${lightBlue};
  font-weight: bold;
  position: relative;
`;

const ContactTeamMessage = styled.div`
  font-weight: bold;
  text-align: right;
  width: 100%;
  position: relative;
  bottom: -0.5em;
  right: 1em;

  & a,
  & a:hover {
    color: white;
    text-decoration: underline;
  }
`;

const WebsiteButton = styled.div`
  z-index: 1;
  color: ${themeColors.secondary} !important;
  padding: 1em;
`;

const StyledFade = styled(Fade)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Login = ({ history, location, rollbar, setToken, t, i18n }) => {
  const [isPasswordForgotten, setForgottenPassword] = useState(
    location && location.pathname === '/password-reset',
  );

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    i18n.changeLanguage(window.navigator.language.substring(0, 2));
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getFormCardContent = () => {
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(
        !window.safari ||
          (typeof safari !== 'undefined' && safari.pushNotification),
      );
    switch (location.pathname) {
      case '/password-reset':
        return (
          <PasswordReset queryParams={location.search} history={history} />
        );
      case '/sign-up':
        return (
          <SignUp
            history={history}
            setToken={setToken}
            t={t}
            windowDimensions={windowDimensions}
          />
        );
      default:
        return isPasswordForgotten ? (
          <ForgotPassword t={t} />
        ) : (
          <>
            <LoginForm
              rollbar={rollbar}
              setToken={setToken}
              history={history}
              toggleForgotPassword={setForgottenPassword}
              t={t}
              windowDimensions={windowDimensions}
            />
            {location.state?.tokenExpired && (
              <BrowserInformation>
                {t('Your session has expired, please reconnect.')}
              </BrowserInformation>
            )}
            {isSafari && (
              <BrowserInformation>
                {t(`You may experience some display problems. For an optimal
                experience, we recommend using another browser (Like Chrome or
                Firefox).`)}
              </BrowserInformation>
            )}
          </>
        );
    }
  };
  return (
    <Body windowDimensions={windowDimensions}>
      <TextContainer windowDimensions={windowDimensions}>
        {isPasswordForgotten ? (
          <WebsiteButton>
            <QButton
              bgColor="grayShades.g300"
              size="small"
              icon="INTERFACE_CHEVRON_LEFT"
              iconSize={16}
              iconPosition="left"
              onClick={() => setForgottenPassword(false)}
            >
              <span className="ml-2">
                {t('button:Go back to the sign in page')}
              </span>
            </QButton>
          </WebsiteButton>
        ) : (
          <WebsiteButton>
            <a href="http://www.qemotion.com">
              <QButton
                bgColor="grayShades.g300"
                size="small"
                icon="INTERFACE_CHEVRON_LEFT"
                iconSize={16}
                iconPosition="left"
                className="d-flex"
              >
                <span className="ml-2">{t('button:Go to website')}</span>
              </QButton>
            </a>
          </WebsiteButton>
        )}
        {location.pathname === '/sign-up' ? (
          <Fade
            in
            tag={ExplanationContainer}
            dangerouslySetInnerHTML={{
              __html: t('SIGNUP_EXPLANATION_BLOCK', {
                link:
                  window.navigator.language.substring(0, 2) === 'fr'
                    ? screenshotSignUpfr
                    : screenshotSignUpen,
              }),
            }}
          />
        ) : (
          <Fade
            in
            tag={ExplanationContainer}
            dangerouslySetInnerHTML={{
              __html: t('SIGNIN_EXPLANATION_BLOCK', {
                link: screenshortSignIn,
              }),
            }}
          />
        )}
      </TextContainer>
      <FormCardContainer windowDimensions={windowDimensions}>
        <StyledFade in tag={FormCard} windowDimensions={windowDimensions}>
          {getFormCardContent()}
        </StyledFade>
        {windowDimensions.width > 520 && (
          <ContactTeamMessage>
            <a href="mailto:support-team@qemotion.com">
              {t('button:Contact the technical team')}
            </a>
          </ContactTeamMessage>
        )}
      </FormCardContainer>
    </Body>
  );
};

export default withTranslation('login', ['button'])(Login);
