import React from 'react';
import { withTranslation } from 'react-i18next';
import img from '_Components/NoConfigPage/images/img-no-config@1x.png';
import { grayShades } from 'styles/abstracts/colors';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import NoConfigCTAMessage from '_Components/NoConfigPage/_Components/NoConfigCTAMessage';
import PropTypes from 'prop-types';

const SeparatorHr = styled.hr`
  border-color: ${grayShades.g400};
  margin: 2rem;
`;

const DescriptionTitle = styled.h6`
  font-weight: bold;
`;

const DescriptionContainer = styled(Col)`
  flex-direction: column;
  align-content: center !important;
  display: flex;
  flex-flow: wrap;
  p {
    padding-top: 2rem;
  }
`;

const NoConfigPage = ({
  config: { noConfigMainMessage, noConfigContent, noConfigEndMessage },
  t,
}) => (
  <>
    <Row>
      <Col lg={8} className="offset-lg-2">
        <NoConfigCTAMessage config={noConfigMainMessage} displayImage />
        <SeparatorHr />
        {noConfigContent.map((content, index) => {
          const isEven = index % 2 === 0;
          return (
            <Row className="my-5">
              {content.image && (
                <Col
                  className={isEven ? 'order-first' : 'order-last'}
                  xs={12}
                  sm={content.image && 6}
                >
                  <img className="img-fluid" alt="" src={content.image} />
                </Col>
              )}

              <DescriptionContainer xs={12} sm={content.image ? 6 : 12}>
                {content.title && (
                  <DescriptionTitle>
                    {t(content.title, { ...content.titleVars })}
                  </DescriptionTitle>
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(content.description, { ...content.titleVars }),
                  }}
                />
              </DescriptionContainer>
            </Row>
          );
        })}
        <SeparatorHr />
        <NoConfigCTAMessage config={noConfigEndMessage} displayImage={false} />
      </Col>
    </Row>
  </>
);

NoConfigPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('placeholderPages')(NoConfigPage);
