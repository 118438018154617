import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { purple, emotionColors } from 'styles/abstracts/colors';
import { getBackgroundCSSFormEindex } from '_Utils/eindex/eindexColorFunctions';
import VerbatimListModal from '_Components/Modals/VerbatimListModal/VerbatimListModal';
import GET_MERGED_VERBATIM_LIST_DATA_QUERY from '_Container/VerbatimList/graphql/getMergedVerbatimListData';
import ReactEcharts from 'echarts-for-react';
import styled from 'styled-components';
import { clone } from '../../../_Utils/other/clone';

const ReactEchartsContainer = styled.div<{ isBenchmark: boolean }>`
  height: ${({ isBenchmark }) => (isBenchmark ? '80%' : '90%')};
  width: 100%;
`;

type PieChartProps = {
  data: Array<{ originalValue: any; value: number; name: any }> | undefined;
  filterToDisplay: string;
  subFilterToDisplay: string | null;
  isDonut: boolean;
  t: any;
  queryFilters: any;
  queryLoading: boolean;
  isBenchmark: boolean;
  thematicsUnifiedLegend: any;
  level: string;
  isEindexUnitFahrenheit: boolean;
  graphThematics: any;
  columns: any;
  column: any;
};

const PieChart = ({
  data,
  filterToDisplay,
  subFilterToDisplay,
  isDonut,
  t,
  queryFilters,
  queryLoading,
  isBenchmark,
  thematicsUnifiedLegend,
  level,
  isEindexUnitFahrenheit,
  graphThematics,
  columns,
  column,
}: PieChartProps) => {
  const [isModalOpen, openModal] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState<any>({});
  const getData = () => {
    let otherCategoriesValue = 0;
    let otherCategoriesVolume = 0;
    let otherCategoriesEindexSum = 0;
    let otherCategoriesCount = 0;
    let otherCategoriesHierarchy: any[] = [];
    let hasOtherCategories = true;
    data.forEach(d => {
      if (
        d.value <= 0.5 &&
        d.originalName !== t('Comments without categories')
      ) {
        otherCategoriesValue += d.value;
        otherCategoriesVolume += d.originalValue;
        otherCategoriesEindexSum += d.eindex;
        otherCategoriesCount += 1;
        otherCategoriesHierarchy = [...otherCategoriesHierarchy, d.hierarchy];
      }
    });
    if (isBenchmark) {
      hasOtherCategories = graphThematics.some(
        t =>
          t.values.every(v => v <= 0.5) &&
          (t.values.length > 1 ? t.values.some(v => v > 0) : true),
      );
    }
    const newCat = hasOtherCategories &&
      otherCategoriesVolume > 0 && {
        name:
          thematicsUnifiedLegend && typeof thematicsUnifiedLegend === 'object'
            ? (
                Object.keys(thematicsUnifiedLegend).findIndex(
                  key => thematicsUnifiedLegend[key] === 'Other categories',
                ) + 1
              ).toString()
            : t('Other categories'),
        originalName: t('Other categories'),
        value: Math.round(otherCategoriesValue * 100) / 100,
        eindex: Math.round(otherCategoriesEindexSum / otherCategoriesCount),
        hierarchy: otherCategoriesHierarchy,
      };
    return [
      ...data.filter(d => {
        if (!hasOtherCategories) {
          return d;
        }
        return d.value > 0.5 || d.name === t('Comments without categories');
      }),
      newCat,
    ].filter(d => d);
  };
  const { data: otherThematicsData } = useQuery(
    GET_MERGED_VERBATIM_LIST_DATA_QUERY,
    {
      variables: {
        ...clone(queryFilters),
        mergedThematics: getData().find(
          d => d.originalName === t('Other categories'),
        )?.hierarchy,
        selectedEmotions: null,
      },
    },
  );

  const getOption = () => {
    return {
      tooltip: {
        trigger: 'item',
        formatter: (a: any) => {
          return `<div><p><b>${
            a?.data?.originalName ? a?.data?.originalName : a?.name
          }</b></p><p>${t('Mention count')}: ${
            a?.data?.originalName === t('Other categories')
              ? otherThematicsData?.getMergedVerbatimListData?.verbatimList
                  .length
              : a?.data?.originalValue
          }</p><p>${t('button:Percentage')}: ${a?.percent}%</p>${
            a?.data?.eindex && filterToDisplay === 'mentionCount'
              ? `<p>${t('button:E-index')}: ${a?.data?.eindex}°</p>`
              : ''
          }</div>`;
        },
        position(pos, params, dom, rect, size) {
          // tooltip will be pushed to the right if mouse hovering on the left,
          // and to the left if hovering on the right. Same for top & bottom
          // https://echarts.apache.org/en/option.html#series-pie.tooltip.position
          const obj = {};
          obj['left'] = (pos[0] < size.viewSize[0] / 2) ? (pos[0] - (dom.offsetWidth / 2) + 20) : (pos[0] - (dom.offsetWidth / 2) - 20);
          obj['top'] = (pos[1] < size.viewSize[1] / 2) ? (pos[1] + 60) : (pos[1] - dom.offsetHeight - 60);
          return isBenchmark ? obj : undefined;
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        feature: {
          dataView: {
            title: t('graph:View data'),
            lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
            readOnly: false,
            textColor: purple,
            buttonColor: purple,
            iconStyle: {
              borderColor: purple,
            },
          },
          saveAsImage: {
            title: t('graph:Save as image'),
            name: t('graph:Graph Q°Emotion'),
            backgroundColor: '#FFFFFF',
            iconStyle: {
              borderColor: purple,
            },
          },
        },
        emphasis: {
          iconStyle: {
            borderWidth: 2,
            borderColor: purple,
            textBackgroundColor: purple,
            textFill: '#FFFFFF',
            textBorderRadius: [5, 5, 5, 5],
            textPadding: [6, 6, 4, 6],
          },
        },
      },
      series: [
        {
          name: 'Pie chart',
          type: 'pie',
          avoidLabelOverlap: true,
          radius: isDonut ? ['60%', '75%'] : '75%',
          cursor: isBenchmark ? 'normal' : 'pointer',
          label: {
            show: true,
            rotate: 0,
            overflow: 'truncate',
            position: 'outside',
            alignTo: 'none',
            edgeDistance: '25%',
            bleedMargin: 10,
            distanceToLabelLine: 2,
          },
          data: [
            ...getData(),
            getData().find(d => d.name === t('Other categories')) && {
              [t('Other categories')]: otherThematicsData
                ?.getMergedVerbatimListData.verbatimList.length,
            },
          ].filter(d => d),
          emphasis: {
            focus: 'none',
            label: {
              show: true,
              fontWeight: 'bold',
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          itemStyle: {
            borderWidth: 1,
            borderColor: '#FFF',
            color: ({ data }) => {
              // eslint-disable-next-line no-nested-ternary
              return subFilterToDisplay && filterToDisplay === 'emotions'
                ? // @ts-ignore
                  emotionColors[subFilterToDisplay]
                : data.eindex
                ? getBackgroundCSSFormEindex(
                    data.eindex,
                    isEindexUnitFahrenheit,
                  )
                : purple;
            },
          },
        },
      ],
    };
  };

  const onDataClick = (d: any) => {
    openModal(!isModalOpen);
    if (
      d.name === t('Other categories') ||
      d.data.originalName === t('Other categories')
    ) {
      const filters = clone(queryFilters);
      delete filters.thematics;
      setModalProps({
        numberOfMentions:
          otherThematicsData?.getMergedVerbatimListData.verbatimList.length,
        title: d.data.originalName ? d.data.originalName : d.name,
        titleSecondPart: '',
        className: '',
        modal: isModalOpen,
        filters: {
          ...filters,
          mergedThematics: d.data.hierarchy,
          selectedEmotions: null,
        },
        // mentionCount: d.data.originalValue,
        classBody: 'containBubble',
        mergedThematics: true,
        mentionCount:
          otherThematicsData?.getMergedVerbatimListData.verbatimList.length,
      });
    } else {
      setModalProps({
        numberOfMentions: d.data.originalValue,
        title: d.data.originalName ? d.data.originalName : d.name,
        titleSecondPart: '',
        className: '',
        modal: isModalOpen,
        filters: {
          ...queryFilters,
          thematics:
            d.data.originalName !== t('Comments without categories') &&
            d.data.originalName !== t('Other categories')
              ? [...queryFilters?.thematics, d.data.hierarchy]
              : null,
          selectedEmotions: subFilterToDisplay
            ? [subFilterToDisplay?.toUpperCase()]
            : null,
          noThematic: d.data.originalName === t('Comments without categories'),
        },
        mentionCount: d.data.originalValue,
        classBody: 'containBubble',
      });
    }
  };

  const onEvents = {
    click: onDataClick,
  };
  return (
    <ReactEchartsContainer isBenchmark={isBenchmark}>
      {ReactEcharts ? (
        <ReactEcharts
          option={getOption()}
          style={{
            height: '100%',
            width: '100%',
          }}
          className="react_for_echarts"
          onEvents={isBenchmark ? null : onEvents}
          theme="QemotionTheme"
        />
      ) : (
        ''
      )}
      <VerbatimListModal
        {...modalProps}
        toggle={() => openModal(!isModalOpen)}
        modal={isModalOpen}
      />
    </ReactEchartsContainer>
  );
};

const mapStateToProps = state => ({
  isEindexUnitFahrenheit: get(state, [
    'projectConfiguration',
    'unitsAndLocalesSettings',
    'eindexUnitFahrenheit',
  ]),
  graphThematics: get(state, ['benchmark', 'graphThematics']),
});

export default withTranslation('card', ['button', 'graph'])(
  connect(mapStateToProps)(PieChart),
);
