export const COVER_SETTINGS_UPDATE_TITLE =
  'REPORTING_COVER_SETTINGS_UPDATE_TITLE';

export const COVER_SETTINGS_UPDATE_SUBTITLE =
  'REPORTING_COVER_SETTINGS_UPDATE_SUBTITLE';

export const updateTitle = textValue => ({
  type: COVER_SETTINGS_UPDATE_TITLE,
  textValue,
});

export const updateSubtitle = textValue => ({
  type: COVER_SETTINGS_UPDATE_SUBTITLE,
  textValue,
});
