import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import QTab from '_Components/Panels/QTabs/tab/QTab';
import QTabs from '_Components/Panels/QTabs/QTabs';
import { grayShades, lightBlue } from 'styles/abstracts/colors';
import query from 'Routes/UtilityRoutes/Login/graphql/resetPasswordSendEmail';
import UserStatistics from './_Containers/UserStatistics/UserStatistics';

const TabsContainer = styled(QTabs)`
  border-bottom: 1px solid ${grayShades.g400};
  border-top: 1px solid ${grayShades.g400};
  background: ${grayShades.g300};
`;

const Tab = styled(QTab)`
  padding: 1em !important;
`;

const BodyContainer = styled.div`
  overflow-y: scroll;
  padding: 2em;
  padding-top: 1em;
`;

const InfoLabel = styled.div`
  padding-bottom: 1em;
  font-weight: bold;
  color: ${lightBlue};
  width: 100%;
  text-align: center;
`;

const SendLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const USERS_SUB_TABS = [{ id: 'statistics', label: 'Statistics' }];

const UsersTab = ({ selectedUser, client, t }) => {
  const [selectedTab, selectTab] = useState(USERS_SUB_TABS[0].id);
  const [queryMessage, setQueryMessage] = useState(null);
  useEffect(() => {
    if (queryMessage) {
      setTimeout(() => {
        setQueryMessage(null);
      }, 5000);
    }
  }, [queryMessage]);
  return (
    <>
      <>
        <TabsContainer
          onTabChanged={tabId => selectTab(tabId)}
          initialPage={selectedTab}
        >
          {USERS_SUB_TABS.map(tab => (
            <Tab id={tab.id}>{t(tab.label)}</Tab>
          ))}
        </TabsContainer>
        {selectedUser ? (
          <BodyContainer>
            {queryMessage && <InfoLabel>{queryMessage}</InfoLabel>}
            {!selectedUser?.statistics?.lastSignIn && !queryMessage && (
              <InfoLabel>
                {t("An activation link has been sent to XXX's email address", {
                  name: selectedUser?.firstName,
                })}
                <SendLink
                  onClick={async () => {
                    const {
                      data: { resetPasswordSendEmail },
                    } = await client.query({
                      query,
                      variables: { email: selectedUser?.email },
                    });
                    setQueryMessage(t('A new email has been sent'));
                  }}
                >
                  {t('Send again')}
                </SendLink>
              </InfoLabel>
            )}
            <UserStatistics selectedUser={selectedUser} t={t} />
          </BodyContainer>
        ) : (
          <InfoLabel>No user is selected.</InfoLabel>
        )}
      </>
    </>
  );
};

export default withApollo(UsersTab);
