import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import Icon from '_Components/Icons/Icon';
import { grayShades, red } from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import { MenuItemLabel } from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import {
  RadioButton,
  CheckboxContainer,
} from '_Charts/LineCharts/CLineChart/CLineChartSettings';

const Container = styled(QPanel)`
  min-height: 100%;
`;

const TitleContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Warning = styled.div`
  font-weight: bold;
  color: ${red};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WarningIcon = styled(Icon)`
  margin: 0 1em;
`;

const OptionsContainer = styled.div`
  margin: 1em;
  font-weight: bold;
  color: ${({ disabled }) => disabled && grayShades.g800};

  & > div:first-child {
    margin-bottom: 1em;
  }
`;

const InfoContainer = styled.div`
  font-weight: bold;
  color: ${grayShades.g800};
  margin-top: 1em;
`;

const SatAndRecoMarkSettings = ({
  isRecoCard,
  setRecoMark,
  setSatMark,
  recoMark,
  satMark,
  selectedSources,
  t,
}) => {
  const [isSwitcherOn, switchOn] = useState(true);
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (isRecoCard) {
      setDisabled(true);
    }
  }, [recoMark, isRecoCard, satMark]);
  return (
    <Container>
      <TitleContainer>
        <QTitle>
          {t(`${isRecoCard ? 'Recommendation' : 'Satisfaction'} mark`)}
        </QTitle>
        {!isDisabled && (
          <QBooleanSwitch
            onChanged={() => {
              switchOn(!isSwitcherOn);
              isSwitcherOn &&
                (isRecoCard ? setRecoMark(null) : setSatMark(null));
            }}
            value={isSwitcherOn}
          />
        )}
      </TitleContainer>
      {!isDisabled ? (
        <>
          {selectedSources.length > 1 &&
            selectedSources.includes(
              source => source.name === 'Tripadvisor',
            ) && (
              <Warning>
                <WarningIcon
                  icon="WARNING"
                  size={ImageSize.medium}
                  color={red}
                />
                {t(
                  'This information is not available for all the selected sources. Thus, the satisfaction score will not reflect all the data.',
                )}
              </Warning>
            )}
          <OptionsContainer disabled={!isSwitcherOn}>
            <CheckboxContainer>
              <MenuItemLabel
                checked={
                  isSwitcherOn &&
                  (isRecoCard ? recoMark === 10 : satMark === 10)
                }
              >
                <RadioButton
                  type="radio"
                  checked={
                    isSwitcherOn &&
                    (isRecoCard ? recoMark === 10 : satMark === 10)
                  }
                  value={5}
                  onChange={() =>
                    isSwitcherOn &&
                    (isRecoCard ? setRecoMark(10) : setSatMark(10))
                  }
                  disabled={!isSwitcherOn}
                />
              </MenuItemLabel>
              {t('Mark')}/10
            </CheckboxContainer>
            <br />
            <CheckboxContainer>
              <MenuItemLabel
                checked={
                  isSwitcherOn && (isRecoCard ? recoMark === 5 : satMark === 5)
                }
              >
                <RadioButton
                  type="radio"
                  checked={
                    isSwitcherOn &&
                    (isRecoCard ? recoMark === 5 : satMark === 5)
                  }
                  value={5}
                  onChange={() => (isRecoCard ? setRecoMark(5) : setSatMark(5))}
                  disabled={!isSwitcherOn}
                />
              </MenuItemLabel>
              {t('Mark')}/5
            </CheckboxContainer>
          </OptionsContainer>
        </>
      ) : (
        <InfoContainer>
          <p>
            There is no {isRecoCard ? 'recommendation' : 'satisfaction'} mark
            available for these sources.
          </p>
        </InfoContainer>
      )}
    </Container>
  );
};

export default SatAndRecoMarkSettings;
