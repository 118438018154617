/* eslint-disable no-console  */
/* eslint-disable react/sort-comp */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, isEqual, uniqWith } from 'lodash';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { getRoute } from '_Utils/routes/routeUtils';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import styled from 'styled-components';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import { withTranslation } from 'react-i18next';
import { setTemporaryTriggersTable } from '_Resources/Triggers/Actions/setTemporaryTriggersTable';
import { setTriggersTableAnalysisAxis } from '_Resources/Triggers/Actions/Filters/setTriggersTableAnalysisAxis';
import {
  getAnalysisAxisOptions,
  // analysisAxisOptions,
  getCalculationCriteriaOptions,
  // itemsToDisplayOptions,
  getItemsToDisplayOptions,
  getStateItemsToDisplay,
} from '_Resources/Triggers/Services/helpers';
import { setTriggersTableItems } from '_Resources/Triggers/Actions/Filters/setTriggersTableItems';
import { setTriggersTableCalculationCriteria } from '_Resources/Triggers/Actions/Filters/setTriggersTableCalculationCriteria';
import { setTriggersTableThematics } from '_Resources/Triggers/Actions/Filters/setTriggersTableThematics';
import { prepareGroupedToOptions } from '_Container/QProjectFilters/Filters/QThematicFilter/groupedToOptions';
import { transformThematicsToOptions } from '_Providers/QFilterOptionsProvider/optionProviderFunctions.js';
import memoizeOne from 'memoize-one';
import TriggersSettingsModal from './_Components/TriggersSubHeader/_Containers/ListTriggers/_Containers/TriggersSettingsModal/TriggersSettingsModal';
// import TriggersSettingsModal from '../Triggers/_Components/TriggersSubHeader/_Containers/ListTriggers/_Containers/TriggersSettingsModal/TriggersSettingsModal';
// import TriggersSettingsModal from '_Components/';
import TriggersColumn from './TriggersColumn';

const StyledContainer = styled(Container)`
  width: calc(100% - 2rem);
  margin: 0 1rem;
  position: relative;
  top: 1rem;
`;

const TriggersColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  gap: 1rem;
`;
class Triggers extends PureComponent {
  constructor(props) {
    super(props);
    const groupedToOptions = prepareGroupedToOptions(props.t);
    this.groupedToOptions = memoizeOne(thematics => {
      return groupedToOptions(thematics);
    });
    this.state = {
      idpage: 'triggers',
      triggersSettingsModal: false,
      edit: false,
    };
    this.toggleTriggersTableSettingsModal = this.toggleTriggersTableSettingsModal.bind(this);
    this.onTriggersSettingsModalClose = this.onTriggersSettingsModalClose.bind(this);
  }

  componentDidMount() {
    const { dispatchUpdateRoute } = this.props;
    const { idpage } = this.state;
    getRoute(dispatchUpdateRoute, idpage);
  }

  toggleTriggersTableSettingsModal() {
    const {
      dispatchSetTemporaryTriggersTable,
      activeTriggersTable,
      dispatchSetTriggersTableAnalysisAxis,
      dispatchSetTriggersTableItems,
      dispatchSetTriggersTableCalculationCriteria,
      dispatchSetTriggersTableThematics,
      thematicsFilters,
      criteriaKeysOptions,
      t,
    } = this.props;
    const { triggersSettingsModal, edit } = this.state;
    if (!edit) {
      dispatchSetTemporaryTriggersTable(activeTriggersTable);
      // TODO : setter chaque variable (criteria keys) sous forme affichable en front. Par ex : { label : '', value: ''}
      const analysisAxisToDispatch = getAnalysisAxisOptions(t).find(
        // const analysisAxisToDispatch = analysisAxisOptions.find(
        option => option.value === activeTriggersTable.settings.analysisAxis,
      );
      dispatchSetTriggersTableAnalysisAxis(analysisAxisToDispatch);
      const itemsToDisplayVariable = activeTriggersTable.settings.itemsToDisplay;
      // const itemsToDisplayArray = itemsToDisplayOptions(criteriaKeysOptions);
      const itemsToDisplayArray = getItemsToDisplayOptions(criteriaKeysOptions, t);
      const stateItemsToDisplay = getStateItemsToDisplay(itemsToDisplayVariable, itemsToDisplayArray);
      dispatchSetTriggersTableItems(stateItemsToDisplay);
      const calculationCriteriaToDispatch = getCalculationCriteriaOptions(t).find(
        option => option.value === activeTriggersTable.settings.calculationCriteria,
      );
      dispatchSetTriggersTableCalculationCriteria(calculationCriteriaToDispatch);

      const initialThematics = activeTriggersTable.settings.thematics;
      const cleanInitialThematics = uniqWith(
        initialThematics.map(thematic =>
          Object.fromEntries(Object.entries(thematic).filter(([key, value]) => value != null)),
        ),
        isEqual(),
      );
      const initialNITThematics = _.uniqWith(
        transformThematicsToOptions(cleanInitialThematics, thematicsFilters),
        _.isEqual,
      );

      dispatchSetTriggersTableThematics(initialNITThematics);
      // TODO: l'affichage semble fonctionner dans le composant des NIT, le store paraît OK quand on ajoute
      // TODO: des thém, mais la requête ne part pas avec les bonnes thématiques
    }
    this.setState({
      triggersSettingsModal: !triggersSettingsModal,
      edit: !edit,
    });
  }

  onTriggersSettingsModalClose() {
    this.setState({
      triggersSettingsModal: false,
      edit: false,
    });
  }

  render() {
    const { activeTriggersTable, t } = this.props;
    const { data, title, description } = activeTriggersTable || {};
    const { triggersSettingsModal, edit } = this.state;
    const triggersSettingsOption = {
      text: 'Settings',
      onClick: () => {
        this.toggleTriggersTableSettingsModal();
      },
      icon: {
        iconType: 'INTERFACE_GEAR',
        iconColor: null,
      },
      topSeparator: false,
    };

    return (
      <div>
        <StyledContainer fluid className="p-0">
          {activeTriggersTable && activeTriggersTable?.id !== '' && (
            <QcardPanel
              title={title}
              displayHints
              hints={[HintsContent.HINT_TRIGGERS_ANALYSIS_AXIS, HintsContent.HINT_TRIGGERS_ITEMS_TO_DISPLAY]}
              subtitleAdditionalContent={description}
              // numberOfMentions={150}
              dropDownOptions={['EXPORT']}
              specificDropDownOptions={[triggersSettingsOption]}
              triggersSection
            >
              <TriggersColumnsContainer>
                {data?.length > 0 && data.map(triggersColumn => <TriggersColumn columnData={triggersColumn} />)}
              </TriggersColumnsContainer>
            </QcardPanel>
          )}
        </StyledContainer>
        <TriggersSettingsModal
          title={t('Edit this triggers table')}
          subtitle={t('You can change name')}
          isOpen={triggersSettingsModal}
          toggle={this.toggleTriggersTableSettingsModal}
          edit={edit}
          triggersTable={activeTriggersTable}
        />
      </div>
    );
  }
}

Triggers.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchUpdateRoute: PropTypes.func.isRequired,
  dispatchSetTemporaryTriggersTable: PropTypes.func.isRequired,
  dispatchSetTriggersTableAnalysisAxis: PropTypes.func.isRequired,
  dispatchSetTriggersTableItems: PropTypes.func.isRequired,
  dispatchSetTriggersTableCalculationCriteria: PropTypes.func.isRequired,
  dispatchSetTriggersTableThematics: PropTypes.func.isRequired,
  activeTriggersTable: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    isDefault: PropTypes.bool.isRequired,
    createdBy: PropTypes.shape({
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    settings: PropTypes.shape({
      analysisAxis: PropTypes.string.isRequired,
      calculationCriteria: PropTypes.string.isRequired,
      itemsToDisplay: PropTypes.shape({
        name: PropTypes.string.isRequired,
        key: PropTypes.string,
      }).isRequired,
      significanceThreshold: PropTypes.number.isRequired,
      range: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
      }).isRequired,
      thematics: PropTypes.arrayOf(
        PropTypes.shape({
          thematic: PropTypes.string.isRequired,
          subThematic: PropTypes.string,
          subSubThematic: PropTypes.string,
        }),
      ).isRequired,
      criteriaKeys: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          values: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        originalColor: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            originalName: PropTypes.string.isRequired,
            percentage: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ).isRequired,
        value: PropTypes.number.isRequired,
        deleted: PropTypes.bool.isRequired,
        masked: PropTypes.bool.isRequired,
      }),
    ),
    customizations: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        originalColor: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            originalName: PropTypes.string.isRequired,
          }),
        ).isRequired,
        deleted: PropTypes.bool.isRequired,
        masked: PropTypes.bool.isRequired,
      }),
    ),
  }).isRequired,
  criteriaKeysOptions: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  thematicsFilters: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

Triggers.defaultProps = {
  criteriaKeysOptions: [],
};

function mapStateToProps(state) {
  return {
    eindexUnitFahrenheit: false,
    activeTriggersTable: get(state, ['triggers', 'activeTriggersTable']),
    criteriaKeysOptions: get(state, ['filters', 'projectFilters', 'criteriaKeys', 'normal']),
    thematicsFilters: get(state, ['filters', 'projectFilters', 'thematics']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateRoute: newState => {
      dispatch(updateRoute(newState));
    },
    dispatchSetTemporaryTriggersTable: newState => {
      dispatch(setTemporaryTriggersTable(newState));
    },
    dispatchSetTriggersTableAnalysisAxis: newState => {
      dispatch(setTriggersTableAnalysisAxis(newState));
    },
    dispatchSetTriggersTableItems: newState => {
      dispatch(setTriggersTableItems(newState));
    },
    dispatchSetTriggersTableCalculationCriteria: newState => {
      dispatch(setTriggersTableCalculationCriteria(newState));
    },
    dispatchSetTriggersTableThematics: newState => {
      dispatch(setTriggersTableThematics(newState));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('triggers')(Triggers));
