import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import { fontSizes } from 'styles/abstracts/typography';
import { spacing, ImageSize } from 'styles/abstracts/variables';
import QButton from '_Components/Buttons/QButton/QButton';
import { connect } from 'react-redux';
import { get, find, isEqual } from 'lodash';
import { purple, white } from 'styles/abstracts/colors';

const Container = styled(QButton)`
  margin: 0 auto;
  height: 2rem;
  font-size: ${fontSizes.smaller} !important;
  padding: 0 ${spacing.small} !important;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.08) !important;

  &:active,
  &:focus {
    border: none !important;
    box-shadow: 0 10px 14px rgba(0, 0, 0, 0.08) !important;
  }
`;

const NumberOfAppliedFilters = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: ${purple};
  color: ${white};
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-right: 5px;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FilterButton = props => {
  const {
    t,
    thematics,
    criteriaKeys,
    keywords,
    displayAppliedFilters,
    defaultThematic,
    idPage,
    benchmarkThematics,
    benchmarkNormalCriteriaKeys,
    benchmarkRelatedCriteriaKeys,
  } = props;

  const [numberOfAppliedFilters, setNumberOfAppliedFilters] = useState(0);

  const checkIfDefaultThematicFilterApplied = () => {
    const defaultThematicApplied = find(thematics, {
      thematic: defaultThematic,
      subThematic: '',
      subSubThematic: '',
    });
    return defaultThematicApplied;
  };

  if (displayAppliedFilters) {
    useEffect(() => {
      let nbOfAppliedThematicsFilters = 0;
      let nbOfAppliedKeywordsFilters = 0;
      let nbOfAppliedCriteriaKeysFilters = 0;

      let nbOfBenchmarkAppliedThematicsFilters = 0;
      let nbOfBenchmarkAppliedNormalCriteriaKeysFilters = 0;
      let nbOfBenchmarkAppliedRelatedCriteriaKeysFilters = 0;

      if (idPage !== 'benchmark') {
        if (thematics) {
          let thematicsThatCount = thematics;
          if (checkIfDefaultThematicFilterApplied()) {
            thematicsThatCount = thematics.filter(
              thematic =>
                !isEqual(thematic, checkIfDefaultThematicFilterApplied()),
            );
          }

          nbOfAppliedThematicsFilters = thematicsThatCount.length;
        }
        if (criteriaKeys) {
          nbOfAppliedCriteriaKeysFilters = criteriaKeys
            .map(criteriaKey => criteriaKey.values.length)
            .reduce((acc, count) => acc + count, 0);
        }
        if (keywords && keywords[0]) {
          nbOfAppliedKeywordsFilters = keywords[0].values.length;
        }
        setNumberOfAppliedFilters(
          nbOfAppliedThematicsFilters +
            nbOfAppliedCriteriaKeysFilters +
            nbOfAppliedKeywordsFilters,
        );
      }
      if (idPage === 'benchmark') {
        if (benchmarkThematics) {
          nbOfBenchmarkAppliedThematicsFilters = benchmarkThematics.length;
        }
        if (benchmarkNormalCriteriaKeys) {
          nbOfBenchmarkAppliedNormalCriteriaKeysFilters =
            benchmarkNormalCriteriaKeys.length;
        }
        if (benchmarkRelatedCriteriaKeys) {
          nbOfBenchmarkAppliedRelatedCriteriaKeysFilters =
            benchmarkRelatedCriteriaKeys.length;
        }
        setNumberOfAppliedFilters(
          nbOfBenchmarkAppliedThematicsFilters +
            nbOfBenchmarkAppliedNormalCriteriaKeysFilters +
            nbOfBenchmarkAppliedRelatedCriteriaKeysFilters,
        );
      }
    }, [
      thematics,
      criteriaKeys,
      keywords,
      benchmarkThematics,
      benchmarkNormalCriteriaKeys,
      benchmarkRelatedCriteriaKeys,
    ]);
  }

  return (
    <Container
      bgColor="white"
      secondaryColor="purple"
      onClick={props.toggle}
      size="large"
      disableHover
    >
      {props.collapsed ? (
        <StyledContainer>
          <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />{' '}
          {numberOfAppliedFilters > 0 && (
            <>
              <NumberOfAppliedFilters>
                {numberOfAppliedFilters}
              </NumberOfAppliedFilters>
              <span>{t(props.filtersAppliedLabelCollapsed)}</span>
            </>
          )}
          {numberOfAppliedFilters === 0 && t(props.labelCollapsed)}
          <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
        </StyledContainer>
      ) : (
        <StyledContainer>
          <Icon icon="INTERFACE_CHEVRON_UP" size={ImageSize.medium} />{' '}
          {numberOfAppliedFilters > 0 && (
            <>
              <NumberOfAppliedFilters>
                {numberOfAppliedFilters}
              </NumberOfAppliedFilters>
              <span>{t(props.filtersAppliedLabelExpanded)}</span>
            </>
          )}
          {numberOfAppliedFilters === 0 && t(props.labelExpanded)}
          <Icon icon="INTERFACE_CHEVRON_UP" size={ImageSize.medium} />
        </StyledContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  thematics: get(state, ['filters', 'filterKeys', 'thematics']),
  criteriaKeys: get(state, ['filters', 'filterKeys', 'criteriaKeys']),
  keywords: get(state, ['filters', 'filterKeys', 'texts']),
  defaultThematic: get(state, [
    'routes',
    'pageConfiguration',
    'defaultThematic',
  ]),
  benchmarkThematics: get(state, [
    'benchmark',
    'activeBenchmark',
    'settings',
    'globalSettings',
    'filters',
    'thematic',
  ]),
  benchmarkNormalCriteriaKeys: get(state, [
    'benchmark',
    'activeBenchmark',
    'settings',
    'globalSettings',
    'filters',
    'normal',
  ]),
  benchmarkRelatedCriteriaKeys: get(state, [
    'benchmark',
    'activeBenchmark',
    'settings',
    'globalSettings',
    'filters',
    'related',
  ]),
});

export default withTranslation('header')(
  connect(mapStateToProps)(FilterButton),
);
