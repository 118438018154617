import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BasePropTypes } from '../../../_Utils/common.propTypes';

const Toggler = styled.div`
  cursor: pointer;
`;

const QDropdownMenuToggle = ({ className, children, toggle, index }) => (
  <div className={className} id={`QDropdownMenuToggle-${index}`}>
    <Toggler onClick={toggle}>{children}</Toggler>
  </div>
);

QDropdownMenuToggle.propTypes = {
  ...BasePropTypes,

  toggle: PropTypes.func.isRequired,
};

export default QDropdownMenuToggle;
