import ConflictSubtitle from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/NormalConflictSubtitle';
import { Collapse } from 'reactstrap';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { CONTEXT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import { CollapseContent } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/CollapseContent';
import { capitalize } from '_Utils/strings/stringsUtils';

const NormalConflictMessage = ({
  context,
  globalConflict,
  arrayConflict,
  contentItem,
  isOpen,
  t,
  i18n,
}) => (
  <CollapseContent isOpen={isOpen}>
    <ConflictSubtitle
      context={context}
      globalConflict={!!globalConflict}
      criteriaKeyTitle={arrayConflict.filter}
      itemTitle={contentItem && contentItem.title}
    />
    <Collapse isOpen={isOpen}>
      <p>
        {globalConflict
          ? capitalize(
              t(
                'Global context filters data on FilterName with the values filterValues',
                {
                  filterName: arrayConflict.filter,
                  filterValues: arrayConflict.period2IDs,
                },
              ),
            )
          : capitalize(
              t(
                'CardColumn cardColumntitle filters data on FilterName with the values filterValues',
                {
                  cardColumn:
                    context === CONTEXT.CARD
                      ? t(CONTEXT.COLUMN)
                      : t(CONTEXT.CARD),
                  cardColumntitle: contentItem && contentItem.title,
                  filterName: arrayConflict.filter,
                  filterValues: arrayConflict.period2IDs,
                },
              ),
            )}
      </p>
      <p>
        {capitalize(
          t(
            'The cardColumn cannot be filtered with the values filterValues because it will end in an invalid result',
            {
              type: t(context),
              filterValues: arrayConflict.period1IDs,
            },
          ),
        )}
      </p>
    </Collapse>
  </CollapseContent>
);

export default withTranslation('conflicts')(NormalConflictMessage);
