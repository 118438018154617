import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { updateDisplaySettings } from '../../../../../../_Resources/Reporting/Actions/GlobalLayoutSettings/updateDisplaySettings';
import PresentationSettings, {
  PRESENTATION_MODES,
} from '../../../_Components/PresentationSettings';
import { globalLayoutPresentationInitialState } from '../../../../../../_Resources/Reporting/Reducers/reporting';

class Display extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    currentPeriod: PropTypes.any.isRequired,
    config: PropTypes.any.isRequired,
    dispatchUpdateDisplaySettings: PropTypes.func.isRequired,
  };

  updateSettings = value => {
    const { dispatchUpdateDisplaySettings } = this.props;
    dispatchUpdateDisplaySettings(value);
  };

  resetSettings = () =>
    this.updateSettings(globalLayoutPresentationInitialState);

  render() {
    const { config } = this.props;
    return (
      <PresentationSettings
        mode={PRESENTATION_MODES.DOCUMENT}
        isModified={!isEqual(config, globalLayoutPresentationInitialState)}
        {...config}
        onUpdate={this.updateSettings}
        onReset={this.resetSettings}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDisplaySettings: (...args) =>
    dispatch(updateDisplaySettings(...args)),
});

const mapStateToProps = ({
  reporting: {
    globalSettings: { header, footer },
  },
}) => ({
  config: { header, footer },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(Display));
