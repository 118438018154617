import { capitalize, sortBy, map } from 'lodash';

const createCategory = (
  { id: categoryId, label: categoryLabel, values: subOptions },
  type = 'normal',
  optionConfig = {},
) => ({
  ...optionConfig,
  id: categoryId,
  label: categoryLabel,
  subOptions:
    subOptions &&
    subOptions.map(subOptionName => ({
      type,
      id: subOptionName,
      label: capitalize(subOptionName),
    })),
});

export const createNormalOptionsCollection = (
  normalKeys,
  type,
  optionConfig,
) => {
  const categories = sortBy(normalKeys, ['label']);
  return map(categories, category =>
    createCategory(category, type, optionConfig),
  );
};

const OTHER_FILTERS_LABEL = 'Other filters';

export const prepareNormalToOptions = translate => (normalKeys, type) => {
  const subOptions = createNormalOptionsCollection(normalKeys, type);
  return [
    {
      id: translate(OTHER_FILTERS_LABEL),
      label: translate(OTHER_FILTERS_LABEL),
      subOptions,
    },
  ];
};
