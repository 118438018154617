import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QPanel from '../../Panels/QPanel/QPanel';
import { BasePropTypes } from '../../../_Utils/common.propTypes';
import { grayShades } from '../../../styles/abstracts/colors';

export const Container = styled(QPanel)`
  z-index: 3;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  left: ${({ placement, isOverstep, toggle, dropdownMenu }) => {
   if (isOverstep) {
      return `calc(${toggle?.left}px - ${dropdownMenu?.width}px - ${toggle?.width}px)`;
    }
    if (placement !== 'right') {
    return `${toggle?.right}px`;
  }}};
  top: ${({ toggle, dropdownMenu, isOverstep }) => {
    if (isOverstep === 'bottom') {
      return `calc(${toggle?.bottom}px - calc(${dropdownMenu?.height}px - ${toggle?.height}px))`;
    }
    return `${toggle?.top}px`;
  }};

  padding-left: 0;
  padding-right: 0;
`;

const QDropdownMenu = ({ children, isOpen, index, ...props }) => {
  const [dropdownProps, setDropdownProps] = useState();
  const [toggleProps, setToggleProps] = useState();

  useEffect(() => {
    setToggleProps(
      document
        .getElementById(`QDropdownMenuToggle-${index}`)
        ?.getBoundingClientRect(),
    );

    setDropdownProps(
      document
        .getElementById(`QDropdownMenu-${index}`)
        ?.getBoundingClientRect(),
    );
  }, [isOpen]);

  const checkIfOverstep = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    let overstep;
    if (dropdownProps?.bottom > windowHeight) {
      overstep = 'bottom';
    } else if (dropdownProps?.right > windowWidth) {
      overstep = 'right';
    }

    return overstep;
  };

  return (
    <Container
      {...props}
      isOpen={isOpen}
      id={`QDropdownMenu-${index}`}
      toggle={toggleProps}
      dropdownMenu={dropdownProps}
      isOverstep={checkIfOverstep()}
      placement="left"
    >
      {children}
    </Container>
  );
};

QDropdownMenu.propTypes = {
  ...BasePropTypes,

  isOpen: PropTypes.bool.isRequired,
};

export const QDropdownMenuItem = styled.div`
  cursor: pointer;
  padding: 5px 25px;
  text-align: left;

  &:hover {
    background-color: ${grayShades.g200};
  }
`;

export default QDropdownMenu;
