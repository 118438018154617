import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { grayShades, lightBlue } from 'styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';
import query from '_Cards/SettingsCards/ProjectDetailsCard/graphql/exportProjectData';
import QToast from '_Components/QToast/QToast';
import { get } from 'lodash/index';
import { useSelector } from 'react-redux';
import ExportDataModal from './_Components/ExportDataModal/ExportDataModal';

const Container = styled(Row)`
  border-bottom: 1px solid ${grayShades.g300};
  width: 100%;
  margin: 0 !important;
`;

const StyledCol = styled(Col)`
  padding: 1em !important;
`;

const HeaderCol = styled(StyledCol)`
  font-weight: bolder;
  color: ${grayShades.g800};
`;

const RightTextCol = styled(HeaderCol)`
  display: flex;
  justify-content: flex-end;
`;

const DownloadLink = styled.span`
  color: ${lightBlue};
  cursor: pointer;
  text-align: right;
  margin-right: 1em;
  display: flex;
  align-items: center;
  ${({ noHistory }) =>
    noHistory &&
    `
    font-weight: bold;
    width: 100%;
    justify-content: center;
  `}
`;

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
`;

export const ExportData = ({ projectId, projectName, t, noHistory }) => {
  const [isModalOpen, openModal] = useState(false);
  const [toastMessage, setToastMessage] = useState({});
  const range = useSelector(state => get(state, ['periods', 'projectPeriods', 'byAll', 'period']));

  return (
    <DownloadLink onClick={() => openModal(true)} noHistory={noHistory}>
      <StyledIcon icon="DOWNLOAD" color={lightBlue} size={28} /> <p>{t('button:Download all data and results')}</p>
      <ExportDataModal
        isOpen={isModalOpen}
        open={openModal}
        projectId={projectId}
        projectName={projectName}
        setMessage={setToastMessage}
        query={query}
        additionalVariables={{ range }}
        t={t}
      />
      {toastMessage.message && <QToast color={lightBlue}>{toastMessage.message}</QToast>}
    </DownloadLink>
  );
};

const ProcessingHistoryHeader = ({ t, projectId, projectName, setMessage }) => {
  return (
    <Container>
      <HeaderCol xs={3}>{t('Processing date')}</HeaderCol>
      <HeaderCol xs={2}>{t('Mode')}</HeaderCol>
      <HeaderCol xs={3}>{t('Nb. mentions')}</HeaderCol>
      <HeaderCol xs={2}>{t('Credits used')}</HeaderCol>
      <RightTextCol xs={6}>
        <ExportData setMessage={setMessage} projectId={projectId} projectName={projectName} t={t} />
      </RightTextCol>
    </Container>
  );
};

export default ProcessingHistoryHeader;
