import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { grayShades } from '../../../../../../styles/abstracts/colors';
import ListTriggersTable from '../_Containers/ListTriggers/ListTriggersTable';

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${grayShades.g800};
  font-weight: bold;
  margin: 0.75em 0 0.75em 2em;
  flex-wrap: wrap;
`;

const Title = styled.p`
  margin-right: 0.5em;
`;

class ListTriggersContainer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Container>
        <Title>{t('Triggers table')}</Title>
        <ListTriggersTable />
      </Container>
    );
  }
}

export default withTranslation('header')(ListTriggersContainer);
