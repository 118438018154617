import gql from 'graphql-tag';

const mutation = gql`
  mutation updateReviewshakeSources(
    $id: Int
    $projectId: Int!
    $profile: String
    $profileName: String
    $profileTypeId: Int
  ) {
    updateReviewshakeSources(
      id: $id
      projectId: $projectId
      profile: $profile
      profileName: $profileName
      profileTypeId: $profileTypeId
    ) {
      id
      errors
      projectId
      profile
      profileName
      profileTypeId
    }
  }
`;

export default mutation;
