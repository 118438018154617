import gql from 'graphql-tag';

const deleteCluster = gql`
  mutation deleteCluster($projectId: Int!, $clusterId: String!) {
    deleteCluster(projectId: $projectId, clusterId: $clusterId) {
      errors
    }
  }
`;

export default deleteCluster;
