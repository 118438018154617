/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ZoomControl from '../ZoomController/ZoomController';
import { BasePropTypes } from '../../../../../../../_Utils/common.propTypes';

function isBottomEdgeIndex(index) {
  return index === 0;
}

export default class ScaledZoomControler extends Component {
  static propTypes = {
    onScaleChange: PropTypes.func.isRequired,

    ...BasePropTypes,
  };

  scaleSteps = [2.0, 1.75, 1.5, 1.25, 1.1, 1.0, 0.9, 0.8, 0.75, 0.67, 0.5];

  state = { currentStep: 1 };

  getCurrentStepIndex(step) {
    return this.scaleSteps.indexOf(step);
  }

  isTopEdgeIndex(index) {
    return index === this.scaleSteps.length - 1;
  }

  getZoomValue() {
    const { currentStep } = this.state;
    return `${Math.round(currentStep * 100)}%`;
  }

  notifyScaleChange = () => {
    const { currentStep } = this.state;
    const { onScaleChange } = this.props;
    onScaleChange(currentStep);
  };

  getPreviousZoomState = ({ currentStep }) => {
    const index = this.getCurrentStepIndex(currentStep);
    if (isBottomEdgeIndex(index)) return null;
    const prevStep = this.scaleSteps[index - 1];
    return {
      currentStep: prevStep,
    };
  };

  getNextZoomState = ({ currentStep }) => {
    const index = this.getCurrentStepIndex(currentStep);
    if (this.isTopEdgeIndex(index)) return null;
    const nextStep = this.scaleSteps[index + 1];
    return {
      currentStep: nextStep,
    };
  };

  onZoomIn = () =>
    this.setState(this.getPreviousZoomState, this.notifyScaleChange);
  onZoomOut = () =>
    this.setState(this.getNextZoomState, this.notifyScaleChange);

  render() {
    const { className } = this.props;
    return (
      <ZoomControl
        className={className}
        value={this.getZoomValue()}
        onZoomIn={this.onZoomIn}
        onZoomOut={this.onZoomOut}
      />
    );
  }
}
