import React, { useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { grayShades, black } from 'styles/abstracts/colors';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import { radius } from 'styles/abstracts/variables';
import Icon from '_Components/Icons/Icon';
import UsersTab from './_Components/UsersTab/UsersTab';
import GroupsTab from './_Components/GroupsTab/GroupsTab';
import DeleteUserModal from './_Components/DeleteUserModal/DeleteUserModal';

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background: ${grayShades.g100};
  border-radius: 0 ${radius.default} ${radius.default} 0;
`;

const TitleContainer = styled.div`
  min-height: 4.5em;
  border-bottom: 1px solid ${grayShades.g300};
  display: flex;
  align-items: center;
  justify-content: ${({ isUsersTab }) =>
    isUsersTab ? 'space-between' : 'flex-start'};
  padding-right: 1em;
`;

const Title = styled(QTitle)`
  margin: 0 !important;
  padding-left: 1em;

  & svg {
    margin-left: 2.5rem;
    vertical-align: top !important;

    & path {
      fill: ${black} !important;
    }
  }
`;

const UsersAndPermissionsBody = ({
  usersTabId,
  selectedItem,
  t,
  users,
  currentUserEmail,
}) => {
  const [isModalOpen, toggleModal] = useState(false);
  return (
    <Container>
      <TitleContainer isUsersTab={usersTabId === 'users'}>
        {selectedItem && (
          <>
            <Title>
              {selectedItem?.label
                ? t(`button:${selectedItem?.label}`)
                : `${selectedItem?.firstName} ${selectedItem?.lastName}`}
              {selectedItem?.otpEnabled && (
                <Icon icon="TWO_FACTOR_AUTHENTICATED" size={24} />
              )}
            </Title>
            {usersTabId === 'users' && currentUserEmail !== selectedItem.email && (
              <>
                <QButton
                  bgColor="red"
                  hasOpacity
                  icon="TRASH"
                  iconPosition="left"
                  iconSize={20}
                  onClick={() => toggleModal(true)}
                >
                  {t('button:Delete this user')}
                </QButton>
                <DeleteUserModal
                  toggle={toggleModal}
                  isOpen={isModalOpen}
                  selectedUser={selectedItem}
                  t={t}
                  users={users}
                />
              </>
            )}
          </>
        )}
      </TitleContainer>
      {usersTabId === 'groups' ? (
        <GroupsTab selectedGroup={selectedItem} t={t} />
      ) : (
        <UsersTab selectedUser={selectedItem} t={t} />
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  currentUserEmail: get(state, ['auth', 'user', 'email']),
});

export default connect(mapStateToProps)(
  withTranslation('card', 'button')(UsersAndPermissionsBody),
);
