import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import QPanel from '../../../_Components/Panels/QPanel/QPanel';
import QPanelHeader from '../../../_Components/Panels/QPanelHeader/QPanelHeader';
import { grayShades } from '../../../styles/abstracts/colors';
import APIIntegrationSection from './_Component/APIIntegrationSection';

const StyledQPanel = styled(QPanel)`
  padding: 0;
  margin-top: 2rem;
`;

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 0;
  border-bottom: 1px solid ${grayShades.g300};
`;

const APIIntegrationCard = ({ isAdmin, account, t }) => {
  const { planName, apiToken } = account;
  const apiIntegrationContainerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (apiIntegrationContainerRef.current) {
      location?.state?.toApiIntegration &&
        apiIntegrationContainerRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
    }
  }, []);

  return (
    <>
      {isAdmin && planName === 'emotion_corporate' && (
        <StyledQPanel ref={apiIntegrationContainerRef}>
          <StyledQPanelHeader>
            <APIIntegrationSection
              isUserAdmin={isAdmin}
              t={t}
              apiToken={apiToken}
            />
          </StyledQPanelHeader>
        </StyledQPanel>
      )}
    </>
  );
};

APIIntegrationCard.propTypes = {
  isAdmin: PropTypes.bool,
  account: PropTypes.shape({
    planName: PropTypes.string,
    apiToken: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

APIIntegrationCard.defaultProps = {
  isAdmin: false,
  account: {
    planName: '',
    apiToken: '',
  },
};

function mapStateToProps(state) {
  return {
    isAdmin: _.get(state, ['auth', 'user', 'isAdmin']),
    account: _.get(state, ['auth', 'user', 'account']),
  };
}

export default connect(mapStateToProps)(
  withTranslation('card')(APIIntegrationCard),
);
