import React, { useState } from 'react';
import styled from 'styled-components';
import { white } from 'styles/abstracts/colors';
import QButton from '_Components/Buttons/QButton/QButton';
import { ImageSize } from 'styles/abstracts/variables';

const StyledMoveButton = styled(QButton)`
  border: 2px solid ${({ borderColor }) => borderColor} !important;
  border-radius: 100% !important;
  padding: 0 !important;
  margin-right: ${props => props.direction === 'left' && '0.5em !important'};
  margin-left: ${props => props.direction === 'right' && '0.5em !important'};
  height: 2.5em;
  width: 2.5em;
`;
const MoveColumnButton = ({
  direction,
  className,
  primaryColor,
  moveItem,
  itemId,
  disabled,
  context,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledMoveButton
      bgColor={hover ? primaryColor : white}
      iconColor={hover ? (disabled ? primaryColor : white) : primaryColor}
      iconSize={ImageSize.xlarge}
      borderColor={primaryColor}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        moveItem({
          direction,
          itemId,
          context,
        });
      }}
      className={className}
      icon={`INTERFACE_CHEVRON_${direction.toUpperCase()}`}
      disabled={disabled}
      direction={direction}
    />
  );
};

export default MoveColumnButton;
