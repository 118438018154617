import gql from 'graphql-tag';

const mutation = gql`
  mutation duplicateBenchmark($id: Int!) {
    duplicateBenchmark(id: $id) {
      id
      title
      description
    }
  }
`;

export default mutation;
