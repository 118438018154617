import { loadComponent } from '../../_Utils/routeComponent';

export default [
  {
    i: 'CWorldMapViz',
    component: 'CWorldMapViz',
    data: {
      title: 'Mention count',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('CWorldMapViz', 'breakpoints').xl.w,
        h: loadComponent('CWorldMapViz', 'breakpoints').xl.h,
        minW: loadComponent('CWorldMapViz', 'breakpoints').xl.minW,
        minH: loadComponent('CWorldMapViz', 'breakpoints').xl.minH,
        x: 0,
        y: 0,
      },
      lg: {
        w: loadComponent('CWorldMapViz', 'breakpoints').lg.w,
        h: loadComponent('CWorldMapViz', 'breakpoints').lg.h,
        minW: loadComponent('CWorldMapViz', 'breakpoints').lg.minW,
        minH: loadComponent('CWorldMapViz', 'breakpoints').lg.minH,
        x: 0,
        y: 0,
      },
      md: {
        w: loadComponent('CWorldMapViz', 'breakpoints').md.w,
        h: loadComponent('CWorldMapViz', 'breakpoints').md.h,
        minW: loadComponent('CWorldMapViz', 'breakpoints').md.minW,
        minH: loadComponent('CWorldMapViz', 'breakpoints').md.minH,
        x: 0,
        y: 0,
      },
      sm: {
        w: loadComponent('CWorldMapViz', 'breakpoints').sm.w,
        h: loadComponent('CWorldMapViz', 'breakpoints').sm.h,
        minW: loadComponent('CWorldMapViz', 'breakpoints').sm.minW,
        minH: loadComponent('CWorldMapViz', 'breakpoints').sm.minH,
        x: 0,
        y: 0,
      },
      xs: {
        w: loadComponent('CWorldMapViz', 'breakpoints').xs.w,
        h: loadComponent('CWorldMapViz', 'breakpoints').xs.h,
        minW: loadComponent('CWorldMapViz', 'breakpoints').xs.minW,
        minH: loadComponent('CWorldMapViz', 'breakpoints').xs.minH,
        x: 0,
        y: 0,
      },
    },
  },
  {
    i: 'WorldMapSidePanelCard',
    component: 'WorldMapSidePanelCard',
    data: {
      title: 'Store',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('WorldMapSidePanelCard', 'breakpoints').xl.w,
        h: loadComponent('WorldMapSidePanelCard', 'breakpoints').xl.h,
        minW: loadComponent('WorldMapSidePanelCard', 'breakpoints').xl.minW,
        minH: loadComponent('WorldMapSidePanelCard', 'breakpoints').xl.minH,
        x: 8,
        y: 0,
      },
      lg: {
        w: loadComponent('WorldMapSidePanelCard', 'breakpoints').lg.w,
        h: loadComponent('WorldMapSidePanelCard', 'breakpoints').lg.h,
        minW: loadComponent('WorldMapSidePanelCard', 'breakpoints').lg.minW,
        minH: loadComponent('WorldMapSidePanelCard', 'breakpoints').lg.minH,
        x: 8,
        y: 0,
      },
      md: {
        w: loadComponent('WorldMapSidePanelCard', 'breakpoints').md.w,
        h: loadComponent('WorldMapSidePanelCard', 'breakpoints').md.h,
        minW: loadComponent('WorldMapSidePanelCard', 'breakpoints').md.minW,
        minH: loadComponent('WorldMapSidePanelCard', 'breakpoints').md.minH,
        x: 0,
        y: 10,
      },
      sm: {
        w: loadComponent('WorldMapSidePanelCard', 'breakpoints').sm.w,
        h: loadComponent('WorldMapSidePanelCard', 'breakpoints').sm.h,
        minW: loadComponent('WorldMapSidePanelCard', 'breakpoints').sm.minW,
        minH: loadComponent('WorldMapSidePanelCard', 'breakpoints').sm.minH,
        x: 0,
        y: 10,
      },
      xs: {
        w: loadComponent('WorldMapSidePanelCard', 'breakpoints').xs.w,
        h: loadComponent('WorldMapSidePanelCard', 'breakpoints').xs.h,
        minW: loadComponent('WorldMapSidePanelCard', 'breakpoints').xs.minW,
        minH: loadComponent('WorldMapSidePanelCard', 'breakpoints').xs.minH,
        x: 0,
        y: 10,
      },
    },
  },
];
