import React from 'react';
import { Col } from 'reactstrap';
import '_Components/TabSentiments/style.scss';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import { connect } from 'react-redux';
import { sendSentimentSelected } from '_Resources/Cards/Actions/sendSentimentSelected';
import { withTranslation } from 'react-i18next';
import { capitalize } from '_Utils/strings/stringsUtils';
import { clone } from '_Utils/other/clone';
import { sentiments } from '../../constants';

class TabSentiments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 'tabSentiments',
      filterSentiments: sentiments.map(sentiment => sentiment.name),
      typeVertical: false,
    };
  }

  componentDidMount() {
    const { filterSentiments, sendSentimentSelected } = this.props;

    this.setState({
      filterSentiments: filterSentiments || sentiments.map(sentiment => sentiment.name),
    });
    sendSentimentSelected(filterSentiments || sentiments.map(sentiment => sentiment.name));
  }

  handleSentimentButtonClick(value) {
    const { sendSentimentSelected } = this.props;
    const { filterSentiments } = this.state;
    let filterSentimentsClone = clone(filterSentiments);
    if (value === 'all') {
      if (filterSentiments.length === sentiments.length) {
        filterSentimentsClone = [];
      } else {
        filterSentimentsClone = sentiments.map(sentiment => sentiment.value);
      }
    } else if (filterSentiments.includes(value)) {
      filterSentimentsClone = filterSentimentsClone.filter(e => e !== value);
    } else {
      filterSentimentsClone = filterSentimentsClone.concat(value);
    }
    this.setState({
      filterSentiments: filterSentimentsClone,
    });
    sendSentimentSelected(filterSentimentsClone);
  }

  render() {
    const { t, typeVertical } = this.props;
    const { filterSentiments, key } = this.state;
    const all = {
      name: 'All sentiments',
      value: 'all',
      color: '#592ea0',
      icon: 'PICTO_STAR',
    };
    const item = (sentiment, i, checkOnActive) => (
      <div
        key={key + i}
        className={`buttonSentimentFilter ${
          this.props.typeVertical ? 'buttonSentimentFilterVertical' : 'buttonSentimentFilterHorizontal'
        } ${!checkOnActive && 'unchecked'}`}
        onClick={() => this.handleSentimentButtonClick(sentiment.value)}
      >
        <IconCircle
          size={32}
          icon={sentiment.icon}
          active={checkOnActive}
          color={sentiment.color}
          customCSS={!typeVertical && 'iconCenter'}
        />
        <span
          className={`buttonSentimentText ${!typeVertical && 'buttonSentimentTextHorizontal'}`}
          style={!checkOnActive ? { color: '#A7ADC7' } : { color: sentiment.color }}
        >
          {capitalize(t(sentiment.name))}
        </span>
      </div>
    );

    return (
      <Col xs="auto" className={`${'border-right px-0'} ${!typeVertical && 'displayHorizontal'}`}>
        {item(all, 'all', filterSentiments.length === sentiments.length)}
        {sentiments.map((sentiment, i) => item(sentiment, i, filterSentiments.includes(sentiment.value)))}
      </Col>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendSentimentSelected: newState => {
      dispatch(sendSentimentSelected(newState));
    },
  };
}
export default connect(null, mapDispatchToProps)(withTranslation('emotion')(TabSentiments));
