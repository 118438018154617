import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { uniq, flattenDeep, map, get, max, chunk } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { convertCelsiusToFahrenheit, getMinMaxFromUnit } from '_Utils/eindex/eindexUnits';
import { hex2rgbaConvert } from '_Utils/styles/colors';
import { purple } from 'styles/abstracts/colors';
import styleVars from 'styles/abstracts/_variables.module.scss';
import { QemotionTheme } from '../../Utils/qemotionTheme';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

type CLineChartProps = {
  data: any;
  mixLineBarChart: boolean;
  eindexUnitFahrenheit: boolean;
  t: any;
};

const CLineChart = ({ data, mixLineBarChart, eindexUnitFahrenheit, t }: CLineChartProps) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const echarts = require('echarts');
      echarts.registerTheme('QemotionTheme', QemotionTheme);
    }
  }, []);
  const getData = data => {
    const formattedData = data.eindexEvolutionWithMention || data;
    if (!formattedData) return;
    if (formattedData[0]) {
      return formattedData?.map(({ value, mentionCount }) => {
        return typeof value === 'number' ? Math.round(value) - (eindexUnitFahrenheit ? 0 : 10) : value;
      });
    }
    return formattedData?.map(value => Math.round(value.eindex / value.count) - (eindexUnitFahrenheit ? 0 : 10));
  };

  const getDataX = data => {
    if (!data) return;
    return uniq(data?.map(item => (item ? moment(item.date).format('L') : undefined)));
  };

  const getOption = () => {
    const formattedData = data.eindexEvolutionWithMention || data;
    const eindexData = getData(formattedData);
    const dataMentions = formattedData?.map(d => d.mentionCount);
    return {
      tooltip: {
        trigger: 'axis',
        formatter(a) {
          let tooltip = '';
          tooltip = a[0].name;
          const arr = chunk(a, 2);
          formattedData[0] instanceof Array
            ? (tooltip += arr.map(
                (dataSet, i) =>
                  `<br/>${arr[i][0]?.seriesName.split(' : ')[0]}: ${arr[i][0]?.marker} eindex: ${
                    !isNaN(arr[i][0]?.value + (eindexUnitFahrenheit ? 0 : 10))
                      ? `${arr[i][0]?.value + (eindexUnitFahrenheit ? 0 : 10)}°`
                      : '-'
                  } ${arr[i][1]?.marker} mentions: ${arr[i][1]?.value}`,
              ))
            : (tooltip +=
                (typeof a[0]?.value === 'number' && !isNaN(a[0]?.value)) || a[0]?.value
                  ? `<br/> ${a[0]?.marker} ${a[0]?.seriesName}: ${a[0]?.value + (eindexUnitFahrenheit ? 0 : 10)}°<br/> ${
                      a[1]?.marker
                    } ${a[1]?.seriesName}: ${a[1]?.value}`
                  : `<br/>No data available`);
          return tooltip;
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        feature: {
          dataZoom: {
            title: {
              zoom: t('graph:Zoom'),
              back: t('graph:Zoom reset'),
            },
            // yAxisIndex: 'none',
            iconStyle: {
              borderColor: purple,
            },
          },
          dataView: {
            title: t('graph:View data'),
            lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
            readOnly: false,
            textColor: purple,
            buttonColor: purple,
            iconStyle: {
              borderColor: purple,
            },
          },
          magicType: {
            title: {
              line: t('graph:Switch to line'),
              bar: t('graph:Switch to bar'),
            },
            type: ['line', 'bar'],
            iconStyle: {
              borderColor: purple,
            },
          },
          restore: {
            title: t('graph:Reload'),
            iconStyle: {
              borderColor: purple,
            },
          },
          saveAsImage: {
            title: t('graph:Save as image'),
            name: t('graph:Graph Q°Emotion'),
            backgroundColor: '#FFFFFF',
            iconStyle: {
              borderColor: purple,
            },
          },
        },
        emphasis: {
          iconStyle: {
            borderWidth: 2,
            borderColor: purple,
            textBackgroundColor: purple,
            textFill: '#FFFFFF',
            textBorderRadius: [5, 5, 5, 5],
            textPadding: [6, 6, 4, 6],
          },
        },
      },
      legend: {
        show: false,
      },
      xAxis: {
        type: 'category',
        data: getDataX(formattedData),
      },
      yAxis: [
        {
          type: 'value',
          min: eindexUnitFahrenheit
            ? getMinMaxFromUnit(eindexUnitFahrenheit).min
            : getMinMaxFromUnit(eindexUnitFahrenheit).min - 10,
          max: eindexUnitFahrenheit
            ? getMinMaxFromUnit(eindexUnitFahrenheit).max
            : getMinMaxFromUnit(eindexUnitFahrenheit).max - 10,
          axisLabel: {
            formatter(value) {
              if (!eindexUnitFahrenheit) {
                value += 10;
              }
              return `${value}°`;
            },
          },
        },
        {
          type: 'value',
          min: 0,
          max: dataMentions && max(formattedData[0] instanceof Array ? flattenDeep(dataMentions) : dataMentions) * 4,
          axisLabel: {
            formatter: '{value}',
          },
          splitLine: {
            show: false,
          },
        },
      ],
      visualMap: {
        type: 'piecewise',
        seriesIndex: 0,
        min: eindexUnitFahrenheit
          ? getMinMaxFromUnit(eindexUnitFahrenheit).min
          : getMinMaxFromUnit(eindexUnitFahrenheit).min - 10,
        max: eindexUnitFahrenheit
          ? getMinMaxFromUnit(eindexUnitFahrenheit).max
          : getMinMaxFromUnit(eindexUnitFahrenheit).max - 10,
        showLabel: false,
        show: false,
        splitNumber: eindexUnitFahrenheit ? 1 : 2,
        color: eindexUnitFahrenheit
          ? [hex2rgbaConvert(styleVars.primary, 25)]
          : [hex2rgbaConvert(styleVars.primary, 25), hex2rgbaConvert(styleVars.primary, 75)],
      },
      series:
        formattedData[0] instanceof Array
          ? dataMentions
              .map((dataSet, i) => ({
                name: `${formattedData[i].name} : mentions`,
                type: 'bar',
                data: dataSet,
                z: 2,
                zlevel: 0,
                yAxisIndex: 1,
                xAxisIndex: 0,
                barMaxWidth: 16,
                itemStyle: {
                  color: hex2rgbaConvert(formattedData[i].color, 35),
                  barBorderRadius: [4, 4, 0, 0],
                },
              }))
              .concat(
                formattedData?.map((dataSet, i) => ({
                  name: `${formattedData[i].name} : e-index`,
                  type: 'line',
                  zlevel: 4,
                  z: 4,
                  connectNulls: true,
                  lineStyle: {
                    normal: {
                      width: 3,
                      color: formattedData[i].color,
                    },
                  },
                  itemStyle: {
                    color: formattedData[i].color,
                  },
                  data: dataSet,
                })),
              )
          : [
              {
                name: 'E-index',
                type: 'line',
                connectNulls: true,
                zlevel: 4,
                z: 4,
                lineStyle: {
                  normal: {
                    width: 3,
                    color: styleVars.primary,
                  },
                },
                itemStyle: {
                  color: styleVars.primary,
                },
                symbol: 'circle',
                areaStyle: {
                  opacity: '1',
                },
                data: eindexUnitFahrenheit
                  ? map(eindexData, dataNumber => convertCelsiusToFahrenheit(dataNumber))
                  : eindexData,
                animation: true,
                animationDuration: 1000,
                animationEasing: 'quadraticInOut',
              },
              {
                name: 'Mentions',
                type: 'bar',
                data: dataMentions,
                z: 2,
                zlevel: 0,
                yAxisIndex: 1,
                xAxisIndex: 0,
                barMaxWidth: 24,
                itemStyle: {
                  color: styleVars.light_blue,
                  barBorderRadius: [4, 4, 0, 0],
                },
              },
            ],
    };
  };

  return (
    <Col className="text-center" style={{ height: '100%' }}>
      <div style={{ height: 'calc(100% - 64px)' }}>
        {ReactEcharts && (
          <ReactEcharts
            option={getOption()}
            style={{ height: '100%', width: '100%' }}
            className="react_for_echarts"
            theme="QemotionTheme"
          />
        )}
      </div>
    </Col>
  );
};

const mapStateToProps = state => {
  return {
    filterKeys: state.filters.filterKeys,
    eindexUnitFahrenheit: get(state, ['projectConfiguration', 'unitsAndLocalesSettings', 'eindexUnitFahrenheit']),
  };
};

export default connect(mapStateToProps)(withTranslation('button', ['graph'])(CLineChart));
