import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PredefinedPages from './PredefinedPages';
import QContentSwitcher from '../../../../../../_Components/Panels/QContentSwitcher/QContentSwitcher';
import DefaultLayouts from './DefaultLayouts';

class AddPages extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  getConfig() {
    const { t } = this.props;
    return [
      {
        id: 'empty-layouts',
        label: t('Empty layouts'),
        content: () => <DefaultLayouts />,
      },
      {
        id: 'predefined-pages',
        label: t('Predefined pages'),
        content: () => <PredefinedPages />,
      },
    ];
  }

  render() {
    return (
      <QContentSwitcher
        config={this.getConfig()}
        initialSelected="predefined-pages"
      />
    );
  }
}

export default connect(null)(withTranslation('reporting')(AddPages));
