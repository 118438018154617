import React, { useEffect, useState } from 'react';
import { Col, Collapse } from 'reactstrap';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useMutation, useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import SubHeader from '_Layouts/Header/_Containers/SubHeader/SubHeader';
import Project from '_Layouts/Header/_Containers/SubHeader/_Containers/ListProjectContainer';
import Period from '_Layouts/Header/_Containers/SubHeader/_Components/Period';
import LatestBatchIndicator from '_Layouts/Header/_Containers/SubHeader/_Components/LatestBatchIndicator';
import FilterIndicator from '_Layouts/Header/_Containers/SubHeader/_Components/LandingPageFilters/FilterIndicator';
import QButton from '_Components/Buttons/QButton/QButton';
import QDropdown from '_Components/Menus/QDropdownMenu/QDropdown';
import QDropdownMenu, { QDropdownMenuItem, } from '_Components/Menus/QDropdownMenu/QDropdownMenu';
import * as colorsVariables from 'styles/abstracts/colors';
import ExportDataModal
  from '_Cards/SettingsCards/ProjectDetailsCard/_Components/ProcessingHistory/_Components/ProcessingHistoryHeader/_Components/ExportDataModal/ExportDataModal';
import EXPORT_PROJECT_DATA_QUERY from '_Cards/SettingsCards/ProjectDetailsCard/graphql/exportProjectData';
import QToast from '_Components/QToast/QToast';
import { updateCollapsedInfo } from '_Resources/Header/Actions/updateCollapsedInfo';
import EXPORT_CLUSTERS from '_Resources/Cluster/Services/api/graphql/exportClusters';
import qemotionLogo from './img/qemotion-icon.png';
import qemotionWaves from './img/LOGOBUBLE_ONDES.png';
import qemotionShout from './img/LOGOBUBLEWHITECRI.png';
import qemotionOk from './img/LOGOBUBLE_OK.png';
import GET_LANDING_PAGE_MESSAGES_DATA from './graphql/getLandingPageMessagesData';
import DropdownSettings from '../ProjectListCard/_Cards/ProjectCard/_Components/DropdownSettings/DropdownSettings';
import FilterButton from '../../../../../_Layouts/Header/_Containers/SubHeader/_Components/FilterButton';

const {black, grayShades, lightBlue} = colorsVariables;

const Container = styled.div`
  width: 100%;
  color: ${black};
`;

const UpperPart = styled.div`
  display: flex;
  align-items: center;
  transition: 0.2s linear;
`;

const LogoGreetingsContainer = styled.div`
  display: flex;
  width: calc(100% / 3);
`;

const LogoContainer = styled.div`
  width: 50%;
  height: auto;
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 9em;
`;

const Greetings = styled.div`
  margin-top: 2em;
  margin-left: 5em;
  width: 50%;
  color: ${grayShades.g800};
`;

const SubSubHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FiltersContainer = styled.div`
  display: flex;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 55%;
  justify-content: flex-end;
`;

const StyledButton = styled(QButton)`
  margin-right: 1rem;
  padding: 0.375rem 1rem !important;
`;

const PersonalizedMessages = styled.div`
  height: 100%;
  width: calc(100% / 3.5);
  margin-left: 5rem;
`;

const PersonalizedMessage = styled.div`
  color: ${grayShades.g800};
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 0.75rem;

  & img {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }

  & div {
    margin-right: 1rem;
  }
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  color: ${grayShades.g800} !important;

  &,
  &:hover {
    text-decoration: none;
  }
`;

const StyledUrl = styled.a`
  font-weight: bold;
  color: ${grayShades.g800} !important;

  &,
  &:hover {
    text-decoration: none;
  }
`;

const Step = styled.span`
  color: ${lightBlue};
  font-size: 0.75rem;
`;

const StyledHr = styled.hr`
  margin-bottom: 0;
`;

const StyledDropdownMenu = styled(QDropdownMenu)`
  border-left: ${({color}) =>
    `10px solid ${colorsVariables[color]} !important`};
`;

const CollapseButtonContainer = styled(Col)`
  position: absolute !important;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
`;

type SubHeaderProps = {
  accountLogo: string;
  fullName: string;
  isAdmin: boolean;
  project: any;
  t: any;
  lang: string;
  accountPlan: string;
  collapsedInfo: boolean;
  toggleInfo: any;
  subHeaderRef: any;
  updateCollapsedInfo: any;
  filters: any
  selectedClusters: string[];
};

const LinkButton = ({link, isUserAdmin, lang, t}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const {title: linkTitle, color, children} = link;
  return (
    <>
      <QDropdown>
        <StyledButton
          icon="INTERFACE_CHEVRON_DOWN"
          bgColor={color}
          onClick={() => setOpen(!isOpen)}
        >
          {t(linkTitle)}
        </StyledButton>
        <StyledDropdownMenu isOpen={isOpen} placement="down" color={color}>
          {children?.map(
            ({
               title,
               href,
               isAdminRestricted,
               step,
               exportLink,
               disabled,
               toApiIntegration,
               callback,
               ...child
             }) => {
              let dropdownLinkItem;
              if (callback) {
                dropdownLinkItem = (
                  <StyledLink onClick={() => callback()} type="button">
                    {t(title)}
                  </StyledLink>
                );
              } else {
                const itemLink = child[`href_${lang}`] || href;

                if (itemLink?.startsWith('https://')) {
                  dropdownLinkItem = (
                    <StyledUrl
                      href={itemLink}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {t(title)}
                    </StyledUrl>
                  );
                } else {
                  dropdownLinkItem = (
                    <StyledLink
                      to={{
                        pathname: itemLink?.split('?')[0],
                        search: itemLink?.split('?')[1]
                          ? `?${itemLink?.split('?')[1]}`
                          : '',
                        state: {
                          fromLandingPage: !exportLink,
                          exportLink,
                          toApiIntegration,
                        },
                      }}
                    >
                      {step && <Step>{step}.</Step>} {t(title)}
                    </StyledLink>
                  );
                }
              }
              return (
                ((isAdminRestricted && isUserAdmin) || !isAdminRestricted) &&
                !disabled && (
                  <QDropdownMenuItem>{dropdownLinkItem}</QDropdownMenuItem>
                )
              );
            },
          )}
        </StyledDropdownMenu>
      </QDropdown>
    </>
  );
};

const Subheader = ({
                     accountLogo,
                     fullName,
                     isAdmin,
                     project,
                     t,
                     lang,
                     accountPlan,
                     collapsedInfo,
                     toggleInfo,
                     subHeaderRef,
                     updateCollapsedInfo,
                     filters,
                     selectedClusters,
                     ...props
                   }: SubHeaderProps) => {
  const range = useSelector(state =>
    get(state, ['filters', 'filterKeys', 'range']),
  );

  const [toastMessage, setToastMessage] = useState(null);
  const [additionalVariables, setAdditionalVariables] = useState({});
  const [isExportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const {data: messagesData, error} = useQuery(
    GET_LANDING_PAGE_MESSAGES_DATA,
  );
  const messageTypes = messagesData?.getLandingPageMessagesData
    ? Object.keys(messagesData?.getLandingPageMessagesData)
    : [];


  useEffect(() => {
    if (!isExportModalOpen) setAdditionalVariables({});
  }, [isExportModalOpen]);

  const [exportClustersData] = useMutation(EXPORT_CLUSTERS, {
    variables: {projectId: project?.id, range},
    onCompleted: data => {
      setToastMessage(data.exportClusters);
      setTimeout(() => {
        setToastMessage(null);
      }, 3000);
    },
  });

  const openExportModal = () => {
    setAdditionalVariables({...filters, selectedClusters});
    setExportModalOpen(true)
  }

  const LINKS_CONFIG = [
    {
      title: 'Start',
      color: 'red',
      children: [
        {
          title: 'Start-up guide',
          href_fr:
            'https://help.cxinsights.io/portal/fr/kb/help-center-1/helpcenter-french/guide-de-d%C3%A9marrage',
          href_en:
            'https://help.cxinsights.io/portal/fr/kb/knowledge-base/english-helpcenter-en/getting-started',
        },
        {
          title: 'Help center',
          href: 'https://help.cxinsights.io/portal/fr/kb/help-center-1',
        },
        {
          title: 'Q&A',
          href:
            'https://help.cxinsights.io/portal/fr/kb/resources-and-tutorials',
        },
        {
          title: 'Our definition of emotions',
          href_fr:
            'https://help.cxinsights.io/portal/fr/kb/articles/emotions-primaires-detectees#Introduction',
          href_en:
            'https://help.cxinsights.io/portal/fr/kb/articles/the-primary-emotions-detected-introduction',
        },
      ],
    },
    {
      title: 'Upload',
      color: 'green',
      children: [
        {title: 'Add files manually', href: '/add-data'},
        {
          title: 'Integration API',
          href: '/settings',
          toApiIntegration: true,
          disabled: accountPlan !== 'emotion_corporate' || !isAdmin,
        },
      ],
    },
    {
      title: 'Create',
      color: 'orange',
      children: [
        {
          title: 'A project',
          href: '/project-configuration',
          disabled: accountPlan === 'emotion_corporate',
        },
        {
          title: 'A benchmark',
          href: '/benchmark',
        },
        {
          title: 'An email alert',
          href: '/alerting',
          disabled: accountPlan !== 'emotion_corporate',
        },
        {
          title: 'An insight',
          href: '/insights',
          disabled: accountPlan !== 'emotion_corporate',
        },
        {
          title: 'A reporting',
          href: '/reporting-main',
          disabled: accountPlan !== 'emotion_corporate',
        },
        {
          title: 'A new user',
          href: '/settings?tab=users',
          isAdminRestricted: true,
        },
      ],
    },
    {
      title: 'Analyze',
      color: 'purple',
      children: [
        {step: 1, title: 'Global results', href: '/global-results'},
        {
          step: 2,
          title: 'Results by theme',
          href: '/thematics-overview',
        },
        {
          step: 3,
          title: 'Results by filter',
          href: '/global-data-by-filters',
        },
        {step: 4, title: 'Customer journey', href: '/customer-journey'},
        {step: 5, title: 'Evolutions', href: '/variance-analysis'},
        {step: 6, title: 'Verbatim', href: '/verbatim-list'},
      ],
    },
    {
      title: 'Export',
      color: 'lightBlue',
      children: [
        {title: 'Verbatim', href: '/verbatim-list', exportLink: true},
        {title: 'Verbatim selection', callback: openExportModal},
        {title: 'Results by theme', href: '/thematics-overview', exportLink: true},
        {title: 'A reporting', href: '/reporting-main', exportLink: true},
        {title: 'An email alert', href: '/alerting', exportLink: true},
        {title: 'Irritants and enchantment points', callback: exportClustersData},
      ],
    },
  ];

  const messagesLogos = [qemotionWaves, qemotionShout, qemotionOk];

  useEffect(() => {
    updateCollapsedInfo(collapsedInfo);
  }, [collapsedInfo]);

  const presentAccountMessage = (message) => {
    return (
      <div>
        <b>
          {!error
            ? message?.title
            : 'Lorem ipsum'}
        </b>
        <p>
          {!error
            ? message?.content
            : `Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Accusamus cum delectus dolore dolorum ea enim in laborum nostrum
                  provident quaerat ratione reiciendis`}{' '}
          {message?.link && (
            <span>
              <a href={message?.link}>{message?.linkText ?? 'En savoir plus.'}</a>
            </span>
          )}
        </p>
      </div>
    )
  }

  const shouldRenderLogo = (type, messages) => type !== 'accountContentMessages' || !isEmpty(messages)

  const presentAccountContentMessages = (messages) => {
    return messages?.map((message) => {
      const title = !error ? message?.title : 'Lorem ipsum';
      const content = !error ? message?.content : `Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Accusamus cum delectus dolore dolorum ea enim in laborum nostrum
                  provident quaerat ratione reiciendis`
      return <div>
        <b>{title}</b>
        <p>
          {content}{' '}
          <span>
              <Link to={{
                pathname: '/message',
                state: {messageId: "6", mdContent: message?.mdContent}
              }} reloadDocument>{isEmpty(message?.linkText) ? 'En savoir plus.' : message.linkText}</Link>
            </span>
        </p>
      </div>
    })
  }

  const SHContent = (
    <Container>
      <Collapse
        isOpen={!collapsedInfo}
        onEntered={() => props.setCollapseHeight(true)}
        onExited={() => props.setCollapseHeight(false)}
      >
        <UpperPart>
          <LogoGreetingsContainer>
            {isAdmin ? (
              <LogoContainer>
                <Link to={{pathname: '/settings', state: {toLogo: true}}}>
                  <Logo
                    src={
                      accountLogo
                        ? `${process.env.REACT_APP_BACKEND_URL}${accountLogo}`
                        : qemotionLogo
                    }
                  />
                </Link>
              </LogoContainer>
            ) : (
              <Logo
                src={
                  accountLogo
                    ? `${process.env.REACT_APP_BACKEND_URL}${accountLogo}`
                    : qemotionLogo
                }
              />
            )}
            <Greetings>
              <p>
                {t('header:Hello')} <b>{fullName}</b>.
              </p>
              <span>
                {t('header:We wish you an excellent visit full of emotions')}
              </span>
            </Greetings>
          </LogoGreetingsContainer>
          <PersonalizedMessages>
            {(messageTypes.length ? messageTypes : [null, null, []])?.map(
              (type, index) => {
                return <PersonalizedMessage>
                  {shouldRenderLogo(type, messagesData?.getLandingPageMessagesData?.[type]) ?
                    <img src={messagesLogos[index]} alt="Logo Q°emotion"/> : ''}
                  {type !== 'accountContentMessages' ? presentAccountMessage(messagesData?.getLandingPageMessagesData?.[type]) : presentAccountContentMessages(messagesData?.getLandingPageMessagesData?.[type])}
                </PersonalizedMessage>
              }
            )}
          </PersonalizedMessages>
        </UpperPart>
        <StyledHr/>
      </Collapse>

      <SubSubHeaderContainer>
        <FiltersContainer>
          <Project/>
          <Period/>
          <LatestBatchIndicator/>
          <FilterIndicator/>
        </FiltersContainer>
        <ButtonsContainer>
          {LINKS_CONFIG.map(link => (
            <LinkButton link={link} isUserAdmin={isAdmin} lang={lang} t={t}/>
          ))}
          {project?.state === 'accessible' && (
            <>
              <DropdownSettings
                projectId={project?.id}
                slug={project?.slug}
                t={t}
                isModalOpen={isExportModalOpen}
                openModal={() => setExportModalOpen(!isExportModalOpen)}
              />
              {createPortal(
                <ExportDataModal
                  t={t}
                  setMessage={setToastMessage}
                  isOpen={isExportModalOpen}
                  open={() => setExportModalOpen(!isExportModalOpen)}
                  projectId={project?.id}
                  projectName={project?.name}
                  query={EXPORT_PROJECT_DATA_QUERY}
                  additionalVariables={{range, ...additionalVariables}}
                />,
                document.getElementById('root'),
              )}
              {toastMessage?.message && (
                <QToast color={lightBlue}>{toastMessage?.message}</QToast>
              )}
            </>
          )}
        </ButtonsContainer>
      </SubSubHeaderContainer>
      <CollapseButtonContainer
        xs="auto"
        className="d-flex align-items-center align-content-center"
        style={{bottom: '-1rem'}}
      >
        <FilterButton
          toggle={toggleInfo}
          collapsed={collapsedInfo}
          labelCollapsed="Show more"
          labelExpanded="Collapse"
          displayAppliedFilters={false}
        />
      </CollapseButtonContainer>
    </Container>
  );
  return (
    <SubHeader
      SHContent={SHContent}
      allowCollapse={false}
      subHeaderRef={subHeaderRef}
    />
  );
};

const mapStateToProps = (state: any) => ({
  accountLogo: get(state, ['auth', 'user', 'account', 'logo']),
  isAdmin: get(state, ['auth', 'user', 'isAdmin']),
  fullName: `${get(state, ['auth', 'user', 'firstName'])} ${get(state, [
    'auth',
    'user',
    'lastName',
  ])}`,
  accountPlan: get(state, ['auth', 'user', 'account', 'planName']),
  lang: get(state, ['auth', 'user', 'lang']),
  project: get(state, ['projects', 'currentProject']),
  filters: get(state, ['filters', 'filterKeys']),
  selectedClusters: get(state, ['filters', 'selectedClusters']),
});

const mapDispatchToProps = dispatch => ({
  updateCollapsedInfo: collapsedInfo => {
    dispatch(updateCollapsedInfo(collapsedInfo));
  },
});

export default withTranslation('button', ['card', 'header'])(
  connect(mapStateToProps, mapDispatchToProps)(Subheader),
);
