import React from 'react';
import 'react-dates/initialize';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { black, grayShades } from 'styles/abstracts/colors';
import 'react-dates/lib/css/_datepicker.css';
import memoize from 'memoize-one';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import { capitalize } from '_Utils/strings/stringsUtils';
import LatestBatch from '_Layouts/Header/_Containers/SubHeader/_Components/LatestBatch';
import PeriodRangeHeader from './_Components/PeriodRangeHeader';
import DefaultRangeHeader from './_Components/DefaultRangeHeader';
import QButton from '../../Buttons/QButton/QButton';
import Loader from '../../Loader/OnLoad';

const DropDownContainer = styled.div`
  flex: none;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  color: ${black} !important;
  width: 667px !important;
  position: absolute !important;
  will-change: transform !important;
  top: 0px !important;
  left: 0px !important;
  transform: translate3d(0px, 43px, 0px) !important;
`;

const DatepickerButton = styled(QButton)`
  color: ${black} !important;
`;

const StyledQButton = styled(QButton)`
  width: 100% !important;
`;

const DATE_RANGE_TYPES = {
  ALL: 'all',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
  CUSTOM: 'custom',
};

const StyledColButton = styled(Col)`
  padding: 0.25rem !important;
`;

const renderDayCell = (day, globalPeriod) => {
  const {
    period: { startDate, endDate },
  } = globalPeriod;
  const cellStyle =
    moment(endDate).add(1, 'days') <= day || day <= moment(startDate)
      ? { color: grayShades.g800, fontWeight: 'bold' }
      : {};

  return <div style={cellStyle}>{day.format('D')}</div>;
};

class QDatepicker extends React.Component {
  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    withPortal: PropTypes.bool,
    onPeriodUpdate: PropTypes.func.isRequired,
    periods: PropTypes.any.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    startDate: null,
    endDate: null,
    withPortal: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      dropDownOpen: false,
      dateRangeChosen: null,
    };
  }

  componentDidUpdate(prevProps) {
    this.getButtonDatepickerText(
      prevProps.endDate,
      prevProps.startDate,
      this.props,
    );
  }

  getButtonDatepickerText = memoize((dateFrom, dateTo, props) => {
    const {
      i18n: { language },
    } = props;
    const startDate = moment(dateFrom);
    const endDate = moment(dateTo);
    startDate.locale(language);
    endDate.locale(language);
    return `${startDate.format('LL')} • ${endDate.format('LL')}`;
  });

  onDatesClose(startDate, endDate) {
    this.setState({
      dropDownOpen: false,
    });
  }

  toggle = () => {
    const { dateRangeChosen, dropDownOpen } = this.state;
    this.setState({
      dropDownOpen: !dropDownOpen,
    });
    if (!dateRangeChosen) {
      this.updateViewDate('custom');
    }
  };

  onMenuItemClick = ({ period: { startDate, endDate } }) => {
    this.periodUpdate({ startDate, endDate });
    this.setState({
      dropDownOpen: false,
      dateRangeChosen: null,
    });
  };

  getPeriodRangeHeader = () => {
    const { dateRangeChosen } = this.state;
    const { periods, t } = this.props;
    return (
      <PeriodRangeHeader
        onMenuItemClick={this.onMenuItemClick}
        dateRangeChosen={dateRangeChosen}
        chooseLabel={t('Choose')}
        periods={periods}
      />
    );
  };

  getDateRangeHeaderViews = () => {
    const { t } = this.props;
    const { ALL, MONTH, QUARTER, YEAR, CUSTOM } = DATE_RANGE_TYPES;
    return {
      [ALL]: () => {
        const {
          periods: {
            byAll: {
              period: { startDate, endDate },
            },
          },
        } = this.props;
        this.setState({
          dropDownOpen: false,
          dateRangeChosen: null,
        });
        this.periodUpdate({ startDate, endDate });
        return null;
      },
      [MONTH]: this.getPeriodRangeHeader,
      [QUARTER]: this.getPeriodRangeHeader,
      [YEAR]: this.getPeriodRangeHeader,
      [CUSTOM]: () => <DefaultRangeHeader title={t('Choose custom range')} />,
    };
  };

  periodUpdate = ({ startDate, endDate }) => {
    const { onPeriodUpdate } = this.props;
    const newStartDate =
      startDate &&
      moment
        .parseZone(startDate)
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYY-MM-DD[T]HH:mm:ss');
    const newEndDate =
      endDate &&
      moment
        .parseZone(endDate)
        .set({ h: 23, m: 59, s: 59 })
        .format('YYYY-MM-DD[T]HH:mm:ss');
    newStartDate &&
      newEndDate &&
      onPeriodUpdate({ startDate: newStartDate, endDate: newEndDate });
  };

  updateViewDate = dateRangeChosen => this.setState({ dateRangeChosen });

  onClose = ({ startDate, endDate }) => this.onDatesClose(startDate, endDate);

  onFocus = focus => this.setState({ focusedInput: focus });

  isOutsideRange = day => {
    const {
      periods: {
        byAll: {
          period: { startDate, endDate },
        },
      },
    } = this.props;
    return null;
  };

  render() {
    const { t } = this.props;
    const { dropDownOpen, dateRangeChosen, focusedInput } = this.state;
    const { startDate, endDate, withPortal, loading } = this.props;
    const availableDateRanges = ['all', 'year', 'quarter', 'month', 'custom'];
    const buttonDatePickerText = this.getButtonDatepickerText(
      startDate,
      endDate,
      this.props,
    );
    const dateRangeHeaderViews = this.getDateRangeHeaderViews();
    return (
      <DropDownContainer>
        <Dropdown isOpen={dropDownOpen} toggle={this.toggle}>
          <DropdownToggle
            tag="span"
            disabled={!(startDate && endDate) || loading}
            onClick={this.toggle}
            data-toggle="dropdown"
            aria-expanded={dropDownOpen}
          >
            <DatepickerButton
              bgColor="grayShades.g300"
              disabled={!(startDate && endDate) || loading}
              disableHover
            >
              {startDate && endDate && !loading
                ? buttonDatePickerText
                : 'Loading available dates...'}
              <span className="ml-1">
                {startDate && endDate && !loading ? (
                  <Icon icon="INTERFACE_CHEVRON_DOWN" color="secondary" />
                ) : (
                  <Loader small />
                )}
              </span>
            </DatepickerButton>
          </DropdownToggle>
          <StyledDropdownMenu
            className="p-1 qdatepicker"
            style={{ width: '400px' }}
          >
            <Row className="m-0">
              <Col xs={12}>
                <Row>
                  <Col className="p-0dot5">
                    <h6 className="font-weight-bold">{t('Choose range')}</h6>
                  </Col>
                </Row>
              </Col>
              {availableDateRanges.map((availableDateRange, index) => (
                <StyledColButton key={`${index * 2}colAvailableDateRange`}>
                  <StyledQButton
                    bgColor="grayShades.g800"
                    size="small"
                    block
                    onClick={() => this.updateViewDate(availableDateRange)}
                    active={dateRangeChosen === availableDateRange}
                  >
                    {t(`button:${capitalize(availableDateRange)}`)}
                  </StyledQButton>
                </StyledColButton>
              ))}
              <StyledColButton
                onClick={() => {
                  this.setState({
                    dropDownOpen: false,
                  });
                }}
              >
                <LatestBatch />
              </StyledColButton>
            </Row>
            {dateRangeChosen && (
              <div>
                <DropdownItem divider className="mx-0dot5" />
                <Row className="m-0">
                  <Col xs={12}>
                    {dateRangeHeaderViews[dateRangeChosen]()}
                    {dateRangeChosen === 'custom' && (
                      <DateRangePicker
                        startDateId="datebegin"
                        endDateId="dateEnd"
                        startDate={moment.utc(startDate)}
                        endDate={moment.utc(endDate)}
                        onClose={this.onClose}
                        onDatesChange={this.periodUpdate}
                        focusedInput={focusedInput}
                        onFocusChange={this.onFocus}
                        isOutsideRange={this.isOutsideRange}
                        withPortal={withPortal}
                        renderDayContents={day =>
                          renderDayCell(day, this.props.periods.byAll)
                        }
                      />
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </StyledDropdownMenu>
        </Dropdown>
      </DropDownContainer>
    );
  }
}

export default withTranslation(['text', 'button'])(QDatepicker);
