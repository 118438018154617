import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import QModal from '_Components/Modals/QModal/QModal';
import QModalBody from '_Components/Modals/QModalBody/QModalBody';
import QButton from '_Components/Buttons/QButton/QButton';
import DELETE_ALERT_CONFIGURATION_MUTATION from '_Cards/AlertsCards/AlertingCard/graphql/deleteAlertConfiguration';
import GET_ALERT_CONFIGURATION_LIST_QUERY from '_Cards/AlertsCards/AlertingCard/graphql/getAlertConfigurationList';
import { AlertType } from '_Cards/AlertsCards/AlertingCard/_Components/AlertingRow/AlertingRow';
import { red } from 'styles/abstracts/colors';

const Body = styled(QModalBody)`
  color: ${red};
  //text-align: center;
  font-weight: bold;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type DeleteAlertModalProps = {
  open: any;
  isOpen: boolean;
  alertId: number;
  projectId: number;
  alerts: Array<AlertType>;
  t: any;
};

const DeleteAlertModal = ({
  open,
  isOpen,
  alertId,
  projectId,
  alerts,
  t,
}: DeleteAlertModalProps) => {
  const [deleteAlertConfiguration] = useMutation(
    DELETE_ALERT_CONFIGURATION_MUTATION,
    {
      update: (cache, { data: { deleteAlertConfiguration: deleteAlert } }) => {
        let alertConfigurationList;
        try {
          alertConfigurationList = cache.readQuery({
            query: GET_ALERT_CONFIGURATION_LIST_QUERY,
            variables: { projectId },
          })?.getAlertConfigurationList;
        } catch (e) {
          alertConfigurationList = alerts;
        }
        const filteredAlertConfigurationList = alertConfigurationList.filter(
          al => al.alertConfigurationId !== deleteAlert.id,
        );
        cache.writeQuery({
          query: GET_ALERT_CONFIGURATION_LIST_QUERY,
          variables: { projectId },
          data: { getAlertConfigurationList: filteredAlertConfigurationList },
        });
      },
    },
  );

  const footer = (
    <FooterContainer>
      <QButton onClick={() => open(!isOpen)}>{t('button:Return')}</QButton>
      <QButton
        bgColor="red"
        onClick={() => {
          deleteAlertConfiguration({ variables: { id: alertId } }).then(() => {
            open(!isOpen);
          });
        }}
      >
        {t('button:Delete')}
      </QButton>
    </FooterContainer>
  );

  return (
    <QModal
      title="Are you sure you want to delete this alert?"
      isOpen={isOpen}
      onClose={() => open(!isOpen)}
      size="lg"
      config={{ mentions: false, date: false }}
      hideFooter={false}
      footer={footer}
    >
      <Body>
        <p>- {t('You will lose access to past alerts that were detected')}</p>
        <p>- {t('You will delete the alert files')}</p>
        <p>- {t('This action will have consequences for all users')}</p>
        <p>
          -{' '}
          {t(
            'This action is irreversible (In case of error, you will have to recreate an alert)',
          )}
        </p>
      </Body>
    </QModal>
  );
};

export default withTranslation('card', ['button'])(DeleteAlertModal);
