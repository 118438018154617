import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QPage from '../../../../_Components/Panels/QPage/QPage';
import QTitle from '../../../../_Components/Texts/QTitle/QTitle';
import { TEXT_TYPES } from '../../../../_Components/Texts/textTypes';
import { TEXT_POSITIONS } from '../../../../_Components/Texts/textPositions';
import { spacing } from '../../../../styles/abstracts/variables';
import { prepareGenerateSubtitles } from './helpers/subtitleHelper';
import qemotionLogo from '../img/qemotion-logo.png';
import QFooter from '../../../../_Components/Panels/QFooter/QFooter';

const HeaderLogo = styled.img`
  text-align: center;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
`;

const StyledQPageBody = styled(QPage.Body)`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${spacing.large};
`;

const FooterLogo = styled.img`
  padding: ${spacing.xsmall};
  max-width: 120px;
`;

const StyledQTitleTitle = styled(QTitle.Title)`
  margin-bottom: ${spacing.large};
`;

const StyledQFooterRightBar = styled(QFooter.RightBar)`
  flex-grow: 1;
`;

class ReportingCover extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    coverSettings: PropTypes.any.isRequired,
    globalSettings: PropTypes.any.isRequired,
  };

  generateSubtitles = null;

  constructor({ t, lang }) {
    super();
    this.generateSubtitles = prepareGenerateSubtitles(t, lang);
  }

  render() {
    const { t, coverSettings, globalSettings } = this.props;
    const reportConfig = merge({}, globalSettings, coverSettings);
    return (
      <QPage>
        <QPage.Header>
          {coverSettings.logo && (
            <HeaderLogo id="reportCoverHeaderLogo" src={coverSettings.logo} />
          )}
        </QPage.Header>
        <StyledQPageBody>
          <QTitle type={TEXT_TYPES.HEADER} position={TEXT_POSITIONS.CENTER}>
            <QTitle.Title>{coverSettings.title}</QTitle.Title>
          </QTitle>
          <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.CENTER}>
            <StyledQTitleTitle>{coverSettings.subtitle}</StyledQTitleTitle>
            {this.generateSubtitles(reportConfig).map(subtitle => (
              <QTitle.Subtitle>{subtitle}</QTitle.Subtitle>
            ))}
          </QTitle>
        </StyledQPageBody>
        <QPage.Footer>
          <StyledQFooterRightBar>
            {t('Generated thanks to')}{' '}
            <FooterLogo id="reportCoverFooterLogo" src={qemotionLogo} />
          </StyledQFooterRightBar>
        </QPage.Footer>
      </QPage>
    );
  }
}

const mapStateToProps = ({
  reporting: { globalSettings, header, logo, footer, title, subtitle },
  auth: { user },
}) => ({
  globalSettings,
  coverSettings: {
    title,
    subtitle,
    header,
    logo,
    footer,
  },
  lang: user.lang,
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('reporting')(ReportingCover));
