import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { chain } from 'lodash';
import QPage from '../../../../_Components/Panels/QPage/QPage';
import {
  REPORTING_MODES,
  updateReportingMode,
} from '../../../../_Resources/Reporting/Actions/updateReportingMode';
import { removePage } from '../../../../_Resources/Reporting/Actions/removePage';
import { duplicatePage } from '../../../../_Resources/Reporting/Actions/duplicatePage';
import { selectPageOver } from '../../../../_Resources/Reporting/Actions/selectPageOver';
import { selectCardOver } from '../../../../_Resources/Reporting/Actions/selectCardOver';
import PageActions from '../_Components/PageActions';
import PageFooter from '../_Components/PageFooter';
import { PagePropTypes } from '../../../../_Resources/Reporting/Services/types/Page.propTypes';
import ReportPageHeader from './ReportPageHeader';
import { TEXT_TYPES } from '../../../../_Components/Texts/textTypes';
import mergeDeep from '_Utils/other/mergeDeep';

class ReportPage extends PureComponent {
  static propTypes = {
    index: PropTypes.number,
    page: PagePropTypes.isRequired,

    // state props
    globalSettings: PagePropTypes.isRequired,
    reportTitle: PropTypes.string.isRequired,
    pagesLength: PropTypes.number.isRequired,
    currentPageIndex: PropTypes.number,
    currentCardIndex: PropTypes.number,
    cardIndexOver: PropTypes.number.isRequired,
    isPreviewMode: PropTypes.bool.isRequired,
    projectId: PropTypes.number,

    // dispatch props
    onPageClicked: PropTypes.func,
    onPageOver: PropTypes.func,
    onSectionOver: PropTypes.func,
    onRemove: PropTypes.func.isRequired,
    onDuplicate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    index: null,
    onPageClicked: null,
    onPageOver: null,
    onSectionOver: null,
    currentPageIndex: null,
    currentCardIndex: null,
    projectId: null,
  };

  isSelected = memoize((currentPageIndex, index) => currentPageIndex === index);
  isActive = memoize(
    (selected, currentCardIndex) => selected && currentCardIndex === null,
  );

  onPanelMouseEnter = event => {
    const { index, onPageOver, isPreviewMode } = this.props;
    if (isPreviewMode) {
      event.stopPropagation();
    } else if (onPageOver) {
      onPageOver(index);
    }
  };
  onPanelMouseLeave = () =>
    !this.props.isPreviewMode &&
    this.props.onPageOver &&
    this.props.onPageOver(null);

  handleSectionOver = index => {
    const { onSectionOver, isPreviewMode } = this.props;
    if (!isPreviewMode) {
      onSectionOver(index);
    }
  };

  onDuplicateClick = event => {
    event.stopPropagation();
    const { onDuplicate, index } = this.props;
    onDuplicate(index);
  };

  onRemoveClick = event => {
    event.stopPropagation();
    const { onRemove, index } = this.props;
    onRemove(index);
  };

  onPageClick = event => {
    event.stopPropagation();
    const { onPageClicked, cardIndexOver, index, isPreviewMode } = this.props;
    if (!isPreviewMode && onPageClicked) {
      onPageClicked(index, cardIndexOver);
    }
  };

  render() {
    const {
      currentCardIndex,
      currentPageIndex,
      pagesLength,
      index,
      globalSettings,
      page,
      projectId,
      reportTitle,
      isPreviewMode,
      t,
    } = this.props;
    const pageConfig = mergeDeep(page, globalSettings);
    const {
      cssGridLayout,
      children,
      footer,
      footer: { isFooter },
    } = pageConfig;
    const config = { pageConfig, projectId };
    const selected = this.isSelected(currentPageIndex, index);
    const active = this.isActive(selected, currentCardIndex);
    return (
      <QPage
        onClick={this.onPageClick}
        onMouseEnter={this.onPanelMouseEnter}
        onMouseLeave={this.onPanelMouseLeave}
        selected={active}
      >
        <ReportPageHeader page={pageConfig} mode={TEXT_TYPES.PANEL} />
        <QPage.Body>
          <QPage.Body.Layout
            preview={isPreviewMode}
            id={index}
            cssGridLayout={cssGridLayout}
            config={config}
            layout={children}
            active
            onSectionOver={this.handleSectionOver}
            selectedIndex={selected && currentCardIndex}
          />
        </QPage.Body>
        {isFooter && (
          <PageFooter
            index={index}
            footer={footer}
            size={pagesLength}
            reportTitle={reportTitle}
            lang={this.props.i18n.language}
            t={t}
          />
        )}
        {active && (
          <PageActions
            onRemoveClick={this.onRemoveClick}
            onDuplicateClick={this.onDuplicateClick}
          />
        )}
      </QPage>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onPageClicked: (pageIndex, cardIndex) =>
    dispatch(
      updateReportingMode(
        cardIndex !== null ? REPORTING_MODES.CARD : REPORTING_MODES.PAGE,
        pageIndex,
        cardIndex,
      ),
    ),
  onRemove: (...args) => dispatch(removePage(...args)),
  onDuplicate: (...args) => dispatch(duplicatePage(...args)),
  onPageOver: (...args) => dispatch(selectPageOver(...args)),
  onSectionOver: (...args) => dispatch(selectCardOver(...args)),
});

const mapStateToProps = ({
  reporting: {
    currentPageIndex,
    currentCardIndex,
    mode,
    cardIndexOver,
    globalSettings,
    title,
    isPreviewMode,
  },
  filters,
}) => ({
  currentPageIndex,
  currentCardIndex,
  mode,
  cardIndexOver,
  globalSettings,
  reportTitle: title,
  isPreviewMode,
  projectId: filters.filterKeys && filters.filterKeys.projectId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(ReportPage));
