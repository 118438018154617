import React, { useState, useEffect } from 'react';
import { clone } from 'lodash';
import QTitle from '_Components/Texts/QTitle/QTitle';
import ProcessingAutomation from '_Cards/SettingsCards/ProjectsConfigurationCard/_Components/ProjectConfigurationCardBody/_Components/ProcessingAutomation/ProcessingAutomation';
import { weekDays } from 'Routes/Settings/ProjectConfiguration/utils';

const DataAutomationSettings = ({ automationSettings, setSettings, t }) => {
  const convertCronToObject = crontask => {
    let frequency;
    const crontaskValues = crontask.split(' ');
    if (crontaskValues[4] !== '*') {
      frequency = {
        every: 'week',
        on: weekDays?.[crontaskValues?.[4]]?.id,
        at: parseInt(crontaskValues[1], 10),
      };
    } else if (crontaskValues[2] !== '*') {
      frequency = {
        every: 'month',
        on: parseInt(crontaskValues[2], 10),
        at: parseInt(crontaskValues[1], 10),
      };
    }
    return frequency;
  };

  const [automation, setAutomation] = useState(automationSettings);
  const [automationFrequency, setAutomationFrequency] = useState(
    automationSettings?.crontask?.length > 0
      ? convertCronToObject(automationSettings?.crontask)
      : {
          every: 'week',
          on: 'monday',
          at: 8,
        },
  );

  useEffect(() => {
    const newSettings = clone({ automation: automationSettings });
    newSettings.automation = automation;
    setSettings(newSettings);
  }, [automation]);

  return (
    <>
      <QTitle>{t('Data automation settings')}</QTitle>
      <ProcessingAutomation
        automationFrequency={automationFrequency}
        automation={automationSettings}
        setAutomation={setAutomation}
        setAutomationFrequency={setAutomationFrequency}
        t={t}
      />
    </>
  );
};
export default DataAutomationSettings;
