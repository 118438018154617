import React from 'react';
import Layout from '_Layouts/Layout';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import Reporting from './ReportingMainPage';

const title = 'Reporting';
const body = <Reporting />;

const action = props => {
  return (
    <QLoginRedirect>
      <Layout body={body} title={title} {...props} />
    </QLoginRedirect>
  );
}

export default action;
