import React from 'react';
import PropTypes from 'prop-types';
import QTitle from '../Texts/QTitle/QTitle';
import QScale from '../QScale/QScale';
import { TEXT_POSITIONS } from '../Texts/textPositions';

const QLegend = ({ colors, className, range, title }) => (
  <div className={className}>
    {title && (
      <QTitle position={TEXT_POSITIONS.CENTER}>
        <QTitle.Title>{title}</QTitle.Title>
      </QTitle>
    )}
    <QScale colors={colors} range={range} />
  </div>
);

const QLegendRangeProp = PropTypes.shape({
  min: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  max: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
});

QLegend.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,

  className: PropTypes.string,
  title: PropTypes.string,
  range: QLegendRangeProp,
};

QLegend.defaultProps = {
  className: null,
  title: null,
  range: { min: 'min', max: 'max' },
};

export default QLegend;
