import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { purple, white, grayShades, opacity } from 'styles/abstracts/colors';
import { radius } from 'styles/abstracts/variables';
import { fontSizes } from 'styles/abstracts/typography';
import Icon from '_Components/Icons/Icon';

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(89, 46, 160, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(89, 46, 160, 0); }
  100% { box-shadow: 0 0 0 0 rgba(89, 46, 160, 0); }
`;

const Container = styled.div`
  height: 4em;
`;

const LabelsContainer = styled.div`
  width: 110%;
  float: left;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  font-size: ${fontSizes.smaller};
  margin: 1.5em -0.7em;
`;

const Step = styled.span`
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  background: ${grayShades.g300};
  color: ${grayShades.g800};
  border-radius: 50%;
  border: 2px solid transparent;
  font-weight: bold;
  transition: background 0.2s ease-in-out;
  margin-bottom: 0.5em;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${purple};
      color: ${white};
      border: 2px solid ${purple};
      animation: ${pulse} 2s infinite;
    `}
  ${({ isComplete }) => {
    return (
      isComplete &&
      css`
        border: 2px solid transparent;
        background-color: rgba(89, 46, 160, 0.5);
        color: ${white};
      `
    );
  }}
`;

const StepContainer = styled.div`
  max-width: 10em;
  text-align: center;
`;

const Label = styled.span`
  color: ${grayShades.g800};
  text-align: center;
  margin-left: -0.5em;
  transition: color 0.2s ease-in-out;

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: bold;
      color: ${purple};
    `}
  ${({ isComplete }) => {
    return (
      isComplete &&
      css`
        color: rgba(89, 46, 160, 0.5);
      `
    );
  }}
`;

const Chevron = styled.span`
  margin-top: 0.25em;
`;

const QProgress = ({ steps, activeStep, t }) => {
  return (
    <Container>
      <LabelsContainer>
        {steps.map((step, index) => {
          return (
            <>
              <StepContainer>
                <Step
                  isActive={activeStep === index + 1}
                  isComplete={
                    activeStep > index + 1 || activeStep > steps.length
                  }
                >
                  {index + 1}
                </Step>
                <Label
                  isActive={activeStep === index + 1}
                  isComplete={
                    activeStep > index + 1 || activeStep > steps.length
                  }
                >
                  {t(`steps:${step.label}`)}
                </Label>
              </StepContainer>
              {index + 1 !== steps.length && (
                <Chevron>
                  {' '}
                  <Icon
                    icon="INTERFACE_CHEVRON_RIGHT"
                    size={24}
                    color={grayShades.g800}
                  />{' '}
                </Chevron>
              )}
            </>
          );
        })}
      </LabelsContainer>
    </Container>
  );
};

export default QProgress;
