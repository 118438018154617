import gql from 'graphql-tag';

const WORST_VERBATIM = gql`
  query getWorstReportingVerbatimCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
  ) {
    getVerbatimGlobalBestAndPainPointsCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
    ) {
      worstVerbatim {
        id
        kpis {
          eindex {
            value
          }
        }
        verbatim {
          verbatimFullText
          verbatimSentences {
            sentence
            relevantThematic
          }
        }
      }
    }
  }
`;

export default WORST_VERBATIM;
