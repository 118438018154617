import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { updateDisplaySettings } from '../../../../../../_Resources/Reporting/Actions/PageSettings/updateDisplaySettings';
import PresentationSettings from '../../../_Components/PresentationSettings';
import { PagePropTypes } from '../../../../../../_Resources/Reporting/Services/types/Page.propTypes';
import mergeDeep from '_Utils/other/mergeDeep';
import { pagePresentationInitialState } from '../../../../../../_Resources/Reporting/Reducers/reporting';

class Display extends Component {
  static propTypes = {
    globalSettings: PropTypes.any.isRequired,
    currentPageDisplay: PagePropTypes.isRequired,
    dispatchUpdateDisplaySettings: PropTypes.func.isRequired,
    currentPageIndex: PropTypes.number.isRequired,
  };

  updateSettings = value => {
    const { currentPageIndex, dispatchUpdateDisplaySettings } = this.props;
    dispatchUpdateDisplaySettings(value, currentPageIndex, null);
  };

  resetSettings = () => this.updateSettings(pagePresentationInitialState);

  render() {
    const { currentPageDisplay, globalSettings } = this.props;
    return (
      <PresentationSettings
        {...mergeDeep(currentPageDisplay, globalSettings)}
        onUpdate={this.updateSettings}
        onReset={this.resetSettings}
        isModified={!isEqual(currentPageDisplay, pagePresentationInitialState)}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDisplaySettings: (...args) =>
    dispatch(updateDisplaySettings(...args)),
});

const mapStateToProps = ({
  reporting: { children, globalSettings, currentPageIndex },
}) => {
  const { title, header, footer } = children[currentPageIndex];
  return {
    currentPageDisplay: { title, header, footer },
    globalSettings,
    currentPageIndex,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(Display));
