import React, { useState } from 'react';
import styled from 'styled-components';
import QButton from '_Components/Buttons/QButton/QButton';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { loadClusters } from '_Resources/Cluster/Actions/loadClusters';
import { loadClustersToMerge } from '_Resources/Cluster/Actions/loadClustersToMerge';
import ClustersToMergeSelectModal from './ClustersToMergeSelectModal';
import NameNewClusterModal from './NameNewClusterModal';

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  margin-right: 1.5em;
  & > * {
    margin: 0 0.25em;
  }
`;

const NameNewClusterModalButton = styled(QButton)`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0 0.25em;
  }
`;

type MergeClusterButtonProps = {
  t: any;
  clusterId: string;
  projectId: number;
  range: {
    startDate: Date;
    endDate: Date;
  };
  closeModal: any;
  verbatimsCountSort: string;
  dispatchLoadClusters: any;
  refetch: any;
  cluster: any;
  dispatchLoadClustersToMerge: any;
};

const MergeClusterButton = ({
  t,
  clusterId,
  projectId,
  closeModal,
  cluster,
  dispatchLoadClustersToMerge,
}: MergeClusterButtonProps) => {
  const [
    mergeClusterConfirmationModalOpen,
    setMergeClusterConfirmationModalOpen,
  ] = useState(false);
  const [nameNewClusterModalOpen, setNameNewClusterModalOpen] = useState(false);
  const { clustersToMerge } = cluster;

  const processToNameNewClusterButton = (
    <NameNewClusterModalButton
      bgColor="purple"
      onClick={() => {
        setNameNewClusterModalOpen(true);
      }}
      icon="DUPLICATE"
      iconSize={24}
      iconPosition="left"
      disabled={clustersToMerge.length < 2}
    >
      <span>{t(`button:Process to naming`)}</span>
    </NameNewClusterModalButton>
  );

  return (
    <>
      <StyledQButton
        bgColor="purple"
        onClick={() => setMergeClusterConfirmationModalOpen(true)}
        icon="DUPLICATE"
        iconPosition="left"
        iconSize={16}
      >
        <span>{t('button:Merge cluster')}</span>
      </StyledQButton>
      <ClustersToMergeSelectModal
        isOpen={mergeClusterConfirmationModalOpen}
        processToNameNewClusterButton={processToNameNewClusterButton}
        onMergeCanceled={() => {
          dispatchLoadClustersToMerge([]);
          setMergeClusterConfirmationModalOpen(false);
        }}
      />
      <NameNewClusterModal
        isOpen={nameNewClusterModalOpen}
        onNameNewClusterModalCanceled={() => {
          setNameNewClusterModalOpen(false);
        }}
        clusterId={clusterId}
        projectId={projectId}
        closeModal={closeModal}
      />
    </>
  );
};

const mapStateToProps = state => ({
  cluster: get(state, ['cluster']),
});

const mapDispatchToProps = dispatch => ({
  dispatchLoadClusters: newState => {
    dispatch(loadClusters(newState));
  },
  dispatchLoadClustersToMerge: newState => {
    dispatch(loadClustersToMerge(newState));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MergeClusterButton);
