import React from 'react';
import { Col, Row } from 'reactstrap';
import CGaugeChart from '_Charts/KpisCharts/CGaugeChart/CGaugeChart';
import BadgeBenchmarkTooltip from '_Components/Tooltips/BadgeBenchmarkTooltip/BadgeBenchmarkTooltip';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import CQuery from '_Container/QQuery/CQuery';

const GaugeCard = props => (
  <QcardPanel {...props}>
    <CQuery query={props.query}>
      <GaugeCardView title={props.title} />
    </CQuery>
  </QcardPanel>
);

export const GaugeCardView = ({ className, children, ...props }) => (
  <div style={{ height: '100%' }}>
    <Row style={{ minHeight: '200px', height: 'calc(100% - 64px)' }}>
      <Col className="text-center">
        <CGaugeChart
          value={props.data && props.data.emotionalIntensity.value}
        />
      </Col>
    </Row>
    <Row>
      <Col className="text-center">
        <BadgeBenchmarkTooltip
          title={props.title}
          idTooltip="gaugeDeltaTooltip"
          data={props.data.emotionalIntensity}
        />
      </Col>
    </Row>
  </div>
);

export default GaugeCard;
