import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { grayShades } from 'styles/abstracts/colors';

const BodyContainer = styled.div`
  background-color: ${({ transparent }) => !transparent && grayShades.g100};
  width: 100%;
  border-top: 1px solid ${grayShades.g300};
  max-height: 48vh;
  overflow-y: scroll;
`;

const ProjectConfigurationCardBody = ({ children, transparent }) => {
  return (
    <BodyContainer transparent={transparent}>
      <Container fluid className="py-2 px-3">
        {children}
      </Container>
    </BodyContainer>
  );
};

export default ProjectConfigurationCardBody;
