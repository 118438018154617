import gql from 'graphql-tag';

const query = gql`
  query getProjectListCardData($archived: Boolean) {
    results: getHeaderProjectFiltersData(archived: $archived) {
      projects {
        id
        slug
        name
        demo
        period {
          startDate
          endDate
        }
        mentionCount
        eindex {
          value
        }
        eindexUnitFahrenheit
        updatedAt
        state
      }
    }
  }
`;

export default query;
