import { GET_BENCHMARK_LIST } from '../Actions/getBenchmarkList';
import { SET_ACTIVE_BENCHMARK } from '../Actions/setActiveBenchmark';
import { SET_DISPLAY_BENCHMARK_DETAILS } from '../Actions/setDisplayBenchmarkDetails';
import { SET_ACTIVE_BENCHMARK_LEGEND } from '../Actions/setActiveBenchmarkLegend';
import { SET_OTHER_CATEGORIES } from '../Actions/setOtherCategories';
import { ADD_FILTERS } from '../Actions/Filters/addFilters';
import { REMOVE_FILTERS } from '../Actions/Filters/removeFilters';
import { UPDATE_FILTERS } from '../Actions/Filters/updateFilters';
import { SET_RANGE_FILTER } from '../Actions/Filters/setRangeFilter';
import { SET_NAME } from '../Actions/Filters/setName';
import { CREATE_TEMPORARY_ITEM } from '../Actions/Items/createTemporaryItem';
import { SAVE_TEMPORARY_ITEM } from '../Actions/Items/saveTemporaryItem';
import { MOVE_ITEM } from '../Actions/saveBenchmarkSettings';
import { DELETE_TEMPORARY_ITEM } from '../Actions/Items/deleteTemporaryItem';
import { DELETE_ITEM } from '../Actions/Items/deleteItem';
import { DUPLICATE_ITEM } from '../Actions/Items/duplicateItem';
import { ADD_CONFLICT } from '../Actions/Items/addConflict';
import { REMOVE_CONFLICT } from '../Actions/Items/removeConflict';
import { replaceRange } from './helpers/rangeFilters';
import {
  saveTemporaryItem,
  createTemporaryItem,
  deleteTemporaryItem,
  deleteItem,
  duplicateItem,
  addConflict,
  removeConflict,
  moveItem,
} from './helpers/items';
import { replaceName } from './helpers/names';
import { addFilters, removeFilters, updateFilters } from './helpers/criteriaKeysFilters';
import { setActiveBenchmark } from './helpers/activeBenchmark';
import { SET_GRAPH_THEMATICS } from '../Actions/setGraphThematics';
import { SET_LEVEL_TO_DISPLAY } from '../Actions/setLevelToDisplay';
import { SET_HORIZONTAL_LEVEL_TO_DISPLAY } from '../Actions/setHorizontalLevelToDisplay';
import { SET_ASPECT_TO_DISPLAY } from '../Actions/setAspectToDisplay';
import { SET_BENCHMARK_DONUT_ANALYSIS_AXIS_TO_DISPLAY } from '../Actions/setBenchmarkDonutAnalysisAxisToDisplay';
import { SET_HORIZONTAL_CATEGORIES } from '../Actions/setHorizontalCategories';
import { SET_HORIZONTAL_CATEGORIES_ORDER } from '../Actions/setHorizontalCategoriesOrder';
import { SET_HORIZONTAL_GRAPH_LEGEND } from '../Actions/setHorizontalGraphLegend';
import { SET_UNIT_TO_DISPLAY } from '../Actions/setUnitToDisplay';
import { SET_ONLY_SELECTED_CATEGORIES } from '../Actions/setOnlySelectedCategories';

const initialState = {
  graphThematics: [],
  levelToDisplay: 'subThematics',
  horizontalLevelToDisplay: 'thematics',
  aspectToDisplay: 'emotions',
  donutAnalysisAxisToDisplay: 'sentiment',
  horizontalCategories: [],
  horizontalCategoriesOrder: {
    id: 'name_asc',
    label: 'Alphabetical - Ascending',
  },
  horizontalGraphLegend: {},
  unitToDisplay: 'volume',
  onlySelectedCategories: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BENCHMARK_LIST: {
      return {
        ...state,
        ...action.getBenchmarkList,
      };
    }
    case SET_ACTIVE_BENCHMARK: {
      return setActiveBenchmark(state, action);
    }
    case SET_DISPLAY_BENCHMARK_DETAILS: {
      return {
        ...state,
        displayBenchmarkDetails: action.displayBenchmarkDetails,
      };
    }
    case SET_ACTIVE_BENCHMARK_LEGEND: {
      const result = state.graphThematics
        .filter(t => t.values.some(v => v > 0.5))
        .reduce((acc, t, index) => Object.assign(acc, { [index + 1]: t.name }), {});
      const hasOtherCategories = state.graphThematics.some(
        t => t.values.every(v => v <= 0.5) && t.values.some(v => v > 0),
      );
      if (hasOtherCategories)
        Object.assign(result, {
          [Object.keys(result).length + 1]: 'Other categories',
        });

      return {
        ...state,
        thematicsUnifiedLegend: result,
      };
    }
    case SET_OTHER_CATEGORIES: {
      return {
        ...state,
        otherCategories: action.data,
      };
    }
    case SET_GRAPH_THEMATICS: {
      const result = action.data.map(thematic => {
        const current = state.graphThematics?.filter(graphThematic => graphThematic.name === thematic.name)[0];
        if (current === undefined) {
          return { name: thematic.name, values: [thematic.value] };
        }
        current.values.push(thematic.value);
        return current;
      });
      return {
        ...state,
        graphThematics: result,
      };
    }
    case SET_LEVEL_TO_DISPLAY: {
      return {
        ...state,
        levelToDisplay: action.data,
      };
    }
    case SET_HORIZONTAL_LEVEL_TO_DISPLAY: {
      return {
        ...state,
        horizontalLevelToDisplay: action.data,
      };
    }
    case SET_ASPECT_TO_DISPLAY: {
      return {
        ...state,
        aspectToDisplay: action.data,
      };
    }
    case SET_BENCHMARK_DONUT_ANALYSIS_AXIS_TO_DISPLAY: {
      return {
        ...state,
        donutAnalysisAxisToDisplay: action.data,
      };
    }
    case SET_HORIZONTAL_CATEGORIES: {
      return {
        ...state,
        horizontalCategories: action.data,
      };
    }
    case SET_HORIZONTAL_CATEGORIES_ORDER: {
      return {
        ...state,
        horizontalCategoriesOrder: action.data,
      };
    }
    case SET_HORIZONTAL_GRAPH_LEGEND: {
      return {
        ...state,
        horizontalGraphLegend: action.data,
      };
    }
    case SET_UNIT_TO_DISPLAY: {
      return {
        ...state,
        unitToDisplay: action.data,
      };
    }
    case SET_ONLY_SELECTED_CATEGORIES: {
      return {
        ...state,
        onlySelectedCategories: action.data,
      };
    }
    case ADD_FILTERS: {
      return addFilters(state, action);
    }
    case REMOVE_FILTERS: {
      return removeFilters(state, action);
    }
    case UPDATE_FILTERS: {
      return updateFilters(state, action);
    }
    case SET_RANGE_FILTER: {
      return replaceRange(state, action);
    }
    case SET_NAME: {
      return replaceName(state, action);
    }
    case CREATE_TEMPORARY_ITEM: {
      return createTemporaryItem(state, action);
    }
    case SAVE_TEMPORARY_ITEM: {
      return saveTemporaryItem(state, action);
    }
    case DELETE_TEMPORARY_ITEM: {
      return deleteTemporaryItem(state, action);
    }
    case DELETE_ITEM: {
      return deleteItem(state, action);
    }
    case DUPLICATE_ITEM: {
      return duplicateItem(state, action);
    }
    case ADD_CONFLICT: {
      return addConflict(state, action);
    }
    case REMOVE_CONFLICT: {
      return removeConflict(state, action);
    }
    case MOVE_ITEM: {
      return moveItem(state, action);
    }

    default: {
      return state;
    }
  }
};
