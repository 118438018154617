/* eslint-disable react/sort-comp,import/prefer-default-export */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isNil, isEmpty, cloneDeep, isEqual, every } from 'lodash';
import QRefineBy, {
  REFINE_BY_CATEGORIES,
} from '../../_Components/QRefineBy/QRefineBy';
import QVerbatim, {
  SENTENCE_BUBBLE_MODE,
} from '../../_Components/QVerbatim/QVerbatim';

import { QVerbatimSelectedOptions } from '../../_Components/QVerbatim/QVerbatim.propTypes';
import {
  prepareHasSentenceMainEmotion,
  prepareIsSentenceInRanges,
} from './utils/filters';
import {
  prepareTransformRelevantWithFilters,
  transformSensitivityToPercents,
} from './utils/verbatim';
import Loader from '../../_Components/Loader/OnLoad';

const VerbatimSection = styled.div`
  background: #f7f9ff;
  max-height: calc(100vh - 630px);
  min-height: 200px;
  width: 100%;
  overflow: scroll;
`;

const {
  EINDEX,
  EMOTIONS,
  RECOMMENDATION,
  SATISFACTION,
  SENSITIVITY,
} = REFINE_BY_CATEGORIES;

const RANGE_FILTERS = [EINDEX, RECOMMENDATION, SATISFACTION, SENSITIVITY];

export const REMOVE = 'remove';

const EMPTY_FILTERS = {
  [EINDEX]: null,
  [EMOTIONS]: null,
  [RECOMMENDATION]: null,
  [SATISFACTION]: null,
  [SENSITIVITY]: null,
  [REMOVE]: null,
};

export const getEmptyFilters = () => cloneDeep(EMPTY_FILTERS);

const hasResults = ({ results }) => results;

class QFilteredVerbatimList extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    selectedSentences: QVerbatimSelectedOptions.isRequired,
    filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
    verbatim: PropTypes.object.isRequired,
    filteredVerbatim: PropTypes.object.isRequired,
    sentenceBubbleMode: PropTypes.oneOf(Object.values(SENTENCE_BUBBLE_MODE)),

    onFilterChanged: PropTypes.func.isRequired,
    onVerbatimChanged: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sentenceBubbleMode: SENTENCE_BUBBLE_MODE.SELECT,
  };

  componentDidMount() {
    this.filterOnInit();
    if (this.isAnyFilterActive()) {
      this.filterOnInit();
    }
  }

  componentDidUpdate({ verbatim: prevVerbatim }) {
    const { verbatim } = this.props;
    if (prevVerbatim !== verbatim) {
      this.filterOnInit();
    }
  }

  isAnyFilterActive() {
    const { filters } = this.props;
    return !!Object.values(filters).find(filterValue => !isEmpty(filterValue));
  }

  filterOnInit() {
    const { filters, onFilterChanged } = this.props;
    const filteredVerbatim = this.filterVerbatim();
    onFilterChanged(filters, filteredVerbatim);
  }

  filterVerbatim(filters = this.props.filters) {
    const { verbatim } = this.props;
    if (isNil(verbatim)) return null;

    const ranges = RANGE_FILTERS.filter(filterId => filters[filterId]).map(
      filterId => ({ id: filterId, range: filters[filterId] }),
    );
    const isSentenceInRanges = prepareIsSentenceInRanges(ranges);
    // const isNotRemoved = prepareIsNotRemoved(filters[REMOVE]);
    const hasSentenceMainEmotion = prepareHasSentenceMainEmotion(
      filters[EMOTIONS],
    );

    const filtersFunctions = [
      hasResults,
      // isNotRemoved,
      isSentenceInRanges,
      hasSentenceMainEmotion,
    ];
    return verbatim.map(text => ({
      ...text,
      sentences: text.sentences
        .map(sentence => ({
          ...sentence,
          sentenceId: sentence.id,
          id: text.id,
        }))
        .map(transformSensitivityToPercents)
        .map(prepareTransformRelevantWithFilters(filtersFunctions)),
    }));
  }

  addNewFilter = (category, value) => {
    const { onFilterChanged, filters } = this.props;
    const newFilters = { ...filters, [category]: value };
    const filteredVerbatim = this.filterVerbatim(newFilters);
    onFilterChanged(newFilters, filteredVerbatim);
  };

  onFiltersChanged = (category, value) => this.addNewFilter(category, value);

  onVerbatimChange = value => {
    const { onVerbatimChanged } = this.props;
    onVerbatimChanged(value);
  };

  onVerbatimRemoved = value => {
    const {
      filters: { [REMOVE]: removeFilter = [] },
    } = this.props;

    const alreadyExists = !!removeFilter.find(item => isEqual(item, value));
    this.addNewFilter(
      REMOVE,
      alreadyExists ? removeFilter : [...removeFilter, value],
    );
  };

  checkNullFilter = (verbatimList, filterType) =>
    every(verbatimList, verbatim => {
      const isRecoDisabledSentence = every(
        verbatim.sentences,
        sentence =>
          sentence.results === null || sentence.results[filterType] === null,
      );
      return isRecoDisabledSentence;
    });

  checkRelevantSensitivity = verbatimList => {
    const result = every(verbatimList, verbatim => {
      const isSensitivity100 = every(
        verbatim.sentences,
        sentence =>
          sentence.results === null ||
          // === 100 -> if keyword mode all sensitivity values are 100
          // === 0 -> if details mode all sensitivity values are 0 because BE sends NULL
          (sentence.results.sensitivity === 100 ||
            sentence.results.sensitivity === 0),
      );
      return isSensitivity100;
    });
    return result;
  };

  getDisabledFilters = () => {
    const verbatimList = this.props.filteredVerbatim;
    const filtersToCheck = ['sat', 'reco', 'sensitivity'];
    const disabledFilters = filtersToCheck.reduce((accumulator, filter) => {
      const isTrue = this.checkNullFilter(verbatimList, filter);
      if (isTrue) accumulator.push(filter);
      return accumulator;
    }, []);
    const relevantSensitivity = this.checkRelevantSensitivity(verbatimList);
    if (relevantSensitivity) {
      disabledFilters.push('sensitivity');
    }
    // FORCING HIDDEN SAT AND RECO TABS BECAUSE NOT RELEVANT FOR NOW
    disabledFilters.push(...['sat', 'reco']);
    return disabledFilters;
  };

  render() {
    const {
      className,
      sentenceBubbleMode,
      filteredVerbatim,
      selectedSentences,
      filters,
      verbatim,
    } = this.props;

    this.getDisabledFilters();

    return (
      <div className={className}>
        <QRefineBy
          onFilterChange={this.onFiltersChanged}
          filters={filters}
          hiddenFilters={this.getDisabledFilters()}
        />
        <VerbatimSection>
          {isNil(filteredVerbatim) ? (
            <Loader />
          ) : (
            <QVerbatim
              verbatim={filteredVerbatim}
              selectedSentences={selectedSentences}
              onVerbatimChange={this.onVerbatimChange}
              onVerbatimRemoved={this.onVerbatimRemoved}
              bubbleMode={sentenceBubbleMode}
              toggleAllSelectedVerbatim={this.props.toggleAllSelectedVerbatim}
              allSelectedVerbatim={this.props.allSelectedVerbatim}
              updateVerbatimList={this.props.updateVerbatimList}
            />
          )}
        </VerbatimSection>
      </div>
    );
  }
}

export default styled(QFilteredVerbatimList)`
  width: 100%;
`;
