import React from 'react';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import query from '_Resources/Header/Services/api/graphql/getTour';
import { getTourSteps } from '_Resources/Header/Actions/getTourSteps';
import { DispatchDataToStore } from '_Resources/Header/Services/api/DispatchDataToStore';

const GuidedTourQuery = props =>
  props.tourID ? (
    <Query variables={{ id: props.tourID, replay: props.replay }} query={query}>
      {({ loading, error, data, refetch, networkStatus }) => {
        console.log('query data is', data);
        if (loading || networkStatus === 4) return false;
        let steps = [];
        data?.results !== null &&
          data?.results.forEach(step => {
            steps = [...steps, JSON.parse(step.settings)];
          });

        const finalStep = {
          disableBeacon: true,
          title: 'Review the tour',
          target: '#questionMark',
          contentBis: [
            {
              type: 'text',
              content:
                '<p>You can review this tour by clicking on this question mark.</p>',
            },
          ],
        };
        steps = [...steps, finalStep];
        return (
          steps && (
            <DispatchDataToStore
              data={steps}
              action={() =>
                props.dispatchGetTourSteps(
                  data?.results !== null ? steps : null,
                )
              }
            />
          )
        );
      }}
    </Query>
  ) : (
    <DispatchDataToStore
      data={{}}
      action={() => props.dispatchGetTourSteps({})}
    />
  );

const mapDispatchToProps = dispatch => ({
  dispatchGetTourSteps: steps => {
    dispatch(getTourSteps(steps));
  },
});

export default connect(null, mapDispatchToProps)(GuidedTourQuery);
