import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useQuery } from 'react-apollo';
import GET_EINDEX_PATH_CARD_DATA_QUERY from 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/graphql/getEindexPathCardData';
import EindexRond from '_Charts/KpisCharts/KpiBadges/EindexRond';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import { grayShades, white, themeColors } from '../../styles/abstracts/colors';
import { TooltipText } from './utils/TooltipText';

const CustomerJourneyContainer = styled.div`
  display: flex;
  height: 62%;
  min-height: 17em;
  align-items: center;
  position: relative;
  left: 1em;
  overflow-x: scroll;
  margin-right: 1.5em;
`;

const ChevronDivBackground = styled.div`
  clip-path: polygon(0% 0%, 70% 0%, 100% 50%, 70% 100%, 0% 100%, 30% 50%);
  height: fit-content;
  padding: 0.5em 0.5em 0.5em 0.55em;
  min-height: 16em;
  height: 70%;
  width: 21.5em;
  background-color: ${grayShades.g500};
  &:first-child {
    clip-path: polygon(0% 0%, 62% 0%, 100% 50%, 62% 100%, 0% 100%);
    width: 17em;

    & .chevronDiv {
      clip-path: polygon(0% 0%, 62% 0%, 100% 50%, 62% 100%, 0% 100%);
      padding: 1em 4.5em 3em 0em;
      width: 15.5em;

      & .customerJourneyTitle {
        width: 10em;
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
`;

const ChevronDiv = styled.div`
  clip-path: polygon(0% 0%, 70% 0%, 100% 50%, 70% 100%, 0% 100%, 30% 50%);
  background-color: white;
  height: 100%;
  min-height: 15em;
  width: 20em;
  padding: 1em 5em 3em 7em;
`;

const CustomerJourneyTitle = styled.div`
  font-weight: bold;
  height: 5em;
  overflow: hidden;
  width: 12em;
  position: relative;
  top: 0;
  left: -2em;
`;

const CustomerJourneyMentions = styled.div`
  font-weight: bold;
  color: ${grayShades.g800};
`;

const CustomerJourney = ({ filters, t }) => {
  const defaultThematic = useSelector(state =>
    get(state, ['projectConfiguration', 'moduleSettings']),
  )
    ?.find(mod => mod.moduleId === 'moments')
    .pageSettings.find(page => page.pageId === 'customer-journey');

  const { data } = useQuery(GET_EINDEX_PATH_CARD_DATA_QUERY, {
    variables: {
      ...filters,
      thematics: [
        {
          thematic: defaultThematic?.defaultThematic,
          subThematic: null,
          subSubThematic: null,
        },
      ],
    },
  });

  const [tooltipShow, setTooltipShow] = useState(false);
  const [tooltipIndex, setTooltipIndex] = useState(0);
  const [coord, setCoord] = useState({ x: 0, y: 0 });
  const onMouseMove = (e: any) => {
    setCoord({
      x: e.clientX - 50,
      y: e.clientY - 70,
    });
  };
  const { eindexItems } = data?.getEindexPathCardData || {};
  return eindexItems ? (
    <CustomerJourneyContainer
      className="customerJourneyContainer"
      onMouseMove={onMouseMove}
    >
      {eindexItems?.map((item, index) => {
        return (
          <>
            <ChevronDivBackground
              className="chevronDivBackground"
              style={{ marginLeft: `${index !== 0 ? '-6.5em' : 0}` }}
            >
              <ChevronDiv
                className="chevronDiv"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <CustomerJourneyTitle className="customerJourneyTitle">
                  <div
                    className="tooltippedText"
                    onMouseEnter={() => {
                      setTooltipShow(true);
                      setTooltipIndex(index);
                    }}
                    onMouseOut={() => {
                      setTooltipShow(false);
                    }}
                  >
                    {/* {truncate(item.label, {
                      length: 25,
                      separator: ' ',
                      omission: ' ...',
                    })} */}
                    {item.label}
                  </div>
                </CustomerJourneyTitle>
                <CustomerJourneyMentions>
                  {item.mentionCount} {t('mentions')}
                </CustomerJourneyMentions>
                <EindexRond eindex={item.eindex} />
              </ChevronDiv>
            </ChevronDivBackground>
            <TooltipText
              className="tooltipText"
              show={tooltipShow && index === tooltipIndex}
              style={{
                position: 'fixed',
                top: coord.y,
                left: coord.x,
              }}
            >
              {item.label}
            </TooltipText>
          </>
        );
      })}
    </CustomerJourneyContainer>
  ) : (
    <NoDataMessage secondText="No key moment configured on this project" />
  );
};

export default CustomerJourney;
