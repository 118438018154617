export const ADD_GLOBAL_FILTERS = 'REPORTING_ADD_GLOBAL_FILTERS';

export const FILTER_TYPES = {
  THEMATIC: 'thematic',
  NORMAL: 'normal',
};

export const addGlobalFilters = (filterType, filterValues) => ({
  type: ADD_GLOBAL_FILTERS,
  filterType,
  filterValues,
});
