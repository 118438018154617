import React from 'react';
import Thermometer from '../_Components/Thermometer';
import '../../style.scss';
import HalfPieChart from '../HalfPieChart/HalfPieChart';

const ChartThermometer = props => {
  const { emotions, analysisAxis, eindex, reportingAnalysisAxis } = props;
  const eindexTemp = eindex && (Number.isInteger(eindex.value) ? eindex.value : eindex);
  const axisToAnalyze = analysisAxis || reportingAnalysisAxis;
  return (
    <div className="chartThermometerRoot text-center">
      <Thermometer eindex={eindexTemp} />
      <div className="pieChartThermometer">
        <HalfPieChart emotionResults={emotions} analysisAxis={axisToAnalyze} />
      </div>
    </div>
  );
};

export default ChartThermometer;
