import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import FiltersSection from '../../../_Components/FiltersSection';
import {
  addGlobalFilters,
  FILTER_TYPES,
} from '../../../../../../_Resources/Reporting/Actions/GlobalLayoutSettings/addGlobalFilters';
import { updateDisplaySettings } from '../../../../../../_Resources/Reporting/Actions/GlobalLayoutSettings/updateDisplaySettings';
import {
  filterSettingsInitialState,
  globalLayoutSettingsInitialState,
} from '../../../../../../_Resources/Reporting/Reducers/reporting';
import { removeGlobalFilters } from '../../../../../../_Resources/Reporting/Actions/GlobalLayoutSettings/removeGlobalFilters';

class Filters extends Component {
  static propTypes = {
    filters: PropTypes.any.isRequired,
    onThematicsSelected: PropTypes.func.isRequired,
    onThematicsRemoved: PropTypes.func.isRequired,
    onOthersSelected: PropTypes.func.isRequired,
    onOthersRemoved: PropTypes.func.isRequired,
    dispatchUpdateDisplaySettings: PropTypes.func.isRequired,
  };

  resetFilters = () => {
    const { dispatchUpdateDisplaySettings } = this.props;
    dispatchUpdateDisplaySettings({ filters: filterSettingsInitialState });
  };

  render() {
    const {
      filters,
      onThematicsSelected,
      onThematicsRemoved,
      onOthersSelected,
      onOthersRemoved,
    } = this.props;

    return (
      <FiltersSection
        onReset={this.resetFilters}
        isModified={!isEqual(filters, globalLayoutSettingsInitialState.filters)}
        onThematicsSelected={onThematicsSelected}
        onThematicsRemoved={onThematicsRemoved}
        onOthersSelected={onOthersSelected}
        onOthersRemoved={onOthersRemoved}
        thematics={filters.thematic}
        other={filters.normal}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onThematicsSelected: filterValues =>
    dispatch(addGlobalFilters(FILTER_TYPES.THEMATIC, filterValues)),
  onThematicsRemoved: (...args) =>
    dispatch(removeGlobalFilters(FILTER_TYPES.THEMATIC, args)),
  onOthersSelected: filterValues =>
    dispatch(addGlobalFilters(FILTER_TYPES.NORMAL, filterValues)),
  onOthersRemoved: filterValues =>
    dispatch(removeGlobalFilters(FILTER_TYPES.NORMAL, [filterValues, ''])),
  dispatchUpdateDisplaySettings: (...args) =>
    dispatch(updateDisplaySettings(...args)),
});

const mapStateToProps = ({
  reporting: {
    globalSettings: { filters },
  },
}) => ({
  filters,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
