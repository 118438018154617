import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { connect } from 'react-redux';
import {
  reverse,
  toPairsIn,
  mapValues,
  forEach,
  cloneDeep,
  forIn,
} from 'lodash';

const ResponsiveGridLayout = WidthProvider(Responsive);
class QResponsiveGridLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layout_edit: null,
      currentBreakpoint: 'xl',
    };
  }

  componentDidUpdate(prevProps) {
    // TODO : OLD LAYOUT SAVING METHOD DEPRECATED, NEEDS TO BE REIMPLEMENTED PROPERLY IN BE + FE
    // if (prevProps.editableLayout !== this.props.editableLayout) {
    //   this.saveLayout();
    // }
  }
  // TODO : OLD LAYOUT SAVING METHOD DEPRECATED, NEEDS TO BE REIMPLEMENTED PROPERLY IN BE + FE
  // saveLayout() {
  //   if (!this.props.editableLayout) {
  //     saveLayoutData(
  //       this,
  //       this.props.layout,
  //       this.state.layout_edit,
  //       this.props.module,
  //       this.props.idpage,
  //       this.state.currentBreakpoint,
  //     );
  //   }
  // }

  setBreakpointFromWindowWidth() {
    const breakpoints = reverse(toPairsIn(this.props.breakpoints));
    const marginsLayout = 64;
    let matching = breakpoints[0];
    for (let i = 1, len = breakpoints.length; i < len; i++) {
      const breakpointName = breakpoints[i][0];
      if (window.innerWidth - marginsLayout > breakpoints[i][1]) {
        matching = breakpointName;
      }
    }
    this.setState({ currentBreakpoint: matching });
  }

  onLayoutChange(layout) {
    this.setState({
      layout_edit: layout,
    });
  }

  onBreakpointChange = breakpoint => {
    this.setState({
      currentBreakpoint: breakpoint,
    });
  };

  getStructuredLayout(layout) {
    let restructuredLayout = {};
    restructuredLayout = mapValues(this.props.breakpoints, () => {
      const arrayBreakpoint = [];
      return arrayBreakpoint;
    });
    forEach(
      this.props.breakpoints,
      (eachBreakPointKey, eachBreakPointValue) => {
        forEach(layout, component => {
          const componentForLayout = cloneDeep(component);
          const keyComponent =
            componentForLayout.breakpoints[eachBreakPointValue];
          forIn(keyComponent, (valueBreakPoint, keyBreakPoint) => {
            componentForLayout[keyBreakPoint] = valueBreakPoint;
          });
          delete componentForLayout.breakpoints;
          restructuredLayout[eachBreakPointValue].push(componentForLayout);
        });
      },
    );
    return cloneDeep(restructuredLayout);
  }

  render() {
    const {
      layout,
      rowHeight,
      margin,
      containerPadding,
      breakpoints,
      cols,
      width,
      isResizable,
    } = this.props;

    return (
      <ResponsiveGridLayout
        measureBeforeMount
        onLayoutChange={layout => this.onLayoutChange(layout)}
        className="layout"
        layouts={this.getStructuredLayout(layout)}
        onBreakpointChange={this.onBreakpointChange}
        rowHeight={rowHeight || 43}
        margin={margin || [32, 32]}
        containerPadding={containerPadding || [0, 0]}
        breakpoints={breakpoints}
        cols={cols}
        isDraggable={this.props.editableLayout}
        width={width}
        isResizable={isResizable}
        useCSSTransforms={false}
      >
        {this.props.children}
      </ResponsiveGridLayout>
    );
  }
}
QResponsiveGridLayout.defaultProps = {
  editableLayout: false,
  breakpoints: { xl: 1600, lg: 1200, md: 900, sm: 600, xs: 0 },
  cols: { xl: 16, lg: 16, md: 8, sm: 4, xs: 4 },
};

function mapStateToProps(state) {
  return {
    editableLayout: state.routes.activeLayoutEdition,
    module: state.routes.route && state.routes.route.name,
    idpage: state.routes.subRoute && state.routes.subRoute.id,
  };
}

export default connect(mapStateToProps, null)(QResponsiveGridLayout);
