import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import InsightPanel from '../InsightPanel/InsightPanel';
import colorInsightTiles from '../../_Containers/Grid/_Utils/presentation/utils/colors/colors';
import QLegend from '../../../../../../../_Components/QLegend/QLegend';

class Legend extends PureComponent {
  prepareColors() {
    const { category } = this.props;
    const colorsConfigs = colorInsightTiles(category);
    return colorsConfigs
      .map(({ backgroundColor }) => backgroundColor)
      .reverse();
  }

  render() {
    const { className, min, max, t } = this.props;
    const colors = this.prepareColors();
    const range = { min: min || 'min', max: max || 'max' };
    return (
      <InsightPanel className={className}>
        <QLegend colors={colors} range={range} title={t('Legend')} />
      </InsightPanel>
    );
  }
}

Legend.propTypes = {
  category: PropTypes.string.isRequired,
  indicator: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Legend.defaultProps = {
  className: '',
};

export default withTranslation('card')(Legend);
