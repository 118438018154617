import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import CModal from '_Components/CModal/CModal';
import QModalSection from '_Components/Modals/QModalSection/QModalSection';
import QButton from '_Components/Buttons/QButton/QButton';

const StyledQModalSection = styled(QModalSection)`
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  flex-direction: column;
  & > * {
    margin: 1em;
  }
`;

const StyledCModal = styled(CModal)`
  width: 500px !important;

  & .modal-content > *:first-child {
    padding-bottom: 0;
  }
`;

const DeleteClusterConfirmationModal = ({
  deleteConfirmButton,
  onDeleteCanceled,
  t,
  isOpen,
}) => {
  const title = 'Delete this cluster';

  const subtitle = 'Are you sure you want to delete this cluster';

  const getFooter = () => {
    return (
      <>
        <StyledQModalSection>
          {deleteConfirmButton}
          <QButton outline onClick={onDeleteCanceled}>
            {t('button:Back to cluster details')}
          </QButton>
        </StyledQModalSection>
      </>
    );
  };

  return (
    <StyledCModal
      size="sm"
      data={{}}
      title={t(title)}
      displayMentionsSubtitle={false}
      displayDateSubtitle={false}
      subtitleAdditionalContent={t(subtitle)}
      footer={getFooter()}
      modal={isOpen}
    />
  );
};

export default withTranslation(
  'card',
  'button',
)(DeleteClusterConfirmationModal);
