import { Component } from 'react';
import PropTypes from 'prop-types';
import { loader } from '../../utils/DependencyLoader';
import { prepareGoogleApiUrl } from '../../utils/prepareGoogleApiUrl';

// TODO: consider creating it a PureComponent
export default class GoogleApi extends Component {
  state = { google: null };

  loadingGoogleApi = null;

  componentDidMount() {
    this.initialize();
  }

  initialize() {
    this.initializeGoogleApi();
  }

  initializeGoogleApi() {
    if (this.props.config) {
      this.loadingGoogleApi = this.loadGoogleApi();
    }
  }

  loadGoogleApi() {
    const url = prepareGoogleApiUrl(this.props.config);
    return loader.load(url).then(() => {
      this.setState({ google: window.google });
    });
  }

  render() {
    const { render: renderContent } = this.props;
    return renderContent(this.state.google);
  }
}

GoogleApi.propTypes = {
  config: PropTypes.shape({
    key: PropTypes.string,
    libraries: PropTypes.arrayOf(PropTypes.string),
    v: PropTypes.string,
    language: PropTypes.string,
  }),
};
