import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { storeUserInfos, logout } from '_Resources/Auth/Actions/auth';
import { white, black, green } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QueryCurrentUser from '_Resources/Header/Services/api/QueryCurrentUser';
import Link from '_Components/Link';
import Icon from '_Components/Icons/Icon';
import CUSTOMER_PORTAL_QUERY from '_Cards/SettingsCards/PlanPricingCard/graphql/customerPortal';
import { getExpireDateString } from '../../../AlertStrip/AlertStrip';

const ProfileText = styled.div`
  font-size: 0.75rem;
  white-space: nowrap;
  cursor: pointer;
  b {
    font-size: 1rem;
    font-weight: bold;
  }
`;

const DropdownAccount = styled(DropdownMenu)`
  background: ${white};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  top: inherit!;
`;

const ItemSubtitle = styled.p`
  color: ${black};
  font-size: ${fontSizes.extraSmall};
`;

const ItemTitle = styled.p`
  color: ${black};
  font-weight: bold;
  font-size: ${fontSizes.smaller};
`;

const UserName = styled.p`
  color: ${black};
  font-weight: bold;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
`;

const ArrowedItem = styled(StyledLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIcon = styled(Icon)`
  margin-left: 1em;
`;

class ImageProfil extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      isOpen: false,
      name_user: '',
      id_user: null,
    };
  }

  componentDidMount() {
    const { auth, i18n, storeUserInfos } = this.props;
    const name = window.localStorage.getItem('userName');
    const firstName = window.localStorage.getItem('firstName');
    const lastName = window.localStorage.getItem('lastName');
    const email = window.localStorage.getItem('userEmail');
    const id = window.localStorage.getItem('userID');
    const lang = window.localStorage.getItem('lang');
    const verbatimsLocale = window.localStorage.getItem('verbatimsLocale');
    storeUserInfos({
      name,
      firstName,
      lastName,
      id,
      email,
      account: auth.user.account,
      isAdmin: auth.user.isAdmin,
      hasEmailValidated: auth.user.hasEmailValidated,
      lang,
      verbatimsLocale,
      expireAt: auth.user.expireAt,
      analysisAxis: auth.user.analysisAxis,
    });
    i18n.changeLanguage(lang);
    this.getDatePresentation(auth.user.expireAt);
  }

  componentDidUpdate() {
    this.getDatePresentation(this.props.auth.user.expireAt);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  getDatePresentation = expirationDate => {
    const today = new Date();
    const daysLeft = Math.floor((new Date(expirationDate) - today) / (1000 * 60 * 60 * 24));
    if (daysLeft >= 90) {
      return { color: 'green', text: 'license valid until' };
    }
    if (daysLeft < 90 && daysLeft >= 30) {
      return { color: 'orange', text: 'license valid until' };
    }
    if (daysLeft < 30 && daysLeft >= 0) {
      return { color: 'red', text: 'license valid until' };
    }
    if (daysLeft < 0) {
      return { color: 'red', text: 'license expired since' };
    }
    return { color: 'green', text: 'license valid until' };
  };

  render() {
    const { t, auth, client, logout, history } = this.props;
    const { firstName, lastName, account, lang, isAdmin, expireAt } = (auth && auth.user) || {};
    const expirationDate = getExpireDateString(lang, new Date(expireAt || new Date()));

    return (
      <div>
        {this.props.auth && <QueryCurrentUser />}
        <div>
          <Dropdown
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
            onMouseEnter={this.handleOpen}
            onMouseLeave={this.handleClose}
            direction="down"
          >
            <DropdownToggle
              tag="span"
              onClick={this.toggle}
              data-toggle="dropdown"
              aria-expanded={this.state.dropdownOpen}
            >
              <ProfileText>
                <p>
                  <b>{`${firstName} ${lastName}` || 'undefined'}</b>
                  <Icon icon="INTERFACE_CHEVRON_DOWN" size={16} color={white} />
                </p>
              </ProfileText>
            </DropdownToggle>
            <DropdownAccount className="imageProfilDropdown">
              <DropdownItem arrow>
                <ArrowedItem
                  to={{
                    pathname: '/settings',
                    state: { fromMenu: true },
                  }}
                  onClick={() => window.localStorage.setItem('openedSettings', 'account-preferences')}
                >
                  <span>
                    <UserName>{`${firstName} ${lastName}` || 'undefined'}</UserName>
                    <ItemSubtitle>{account && account.name}</ItemSubtitle>
                    {expireAt && (
                      <ItemSubtitle
                        style={{
                          color: `${this.getDatePresentation(expireAt).color}`,
                        }}
                      >
                        ⬤{' '}
                        {t(this.getDatePresentation(expireAt).text, {
                          ns: 'text',
                          expireAt: `${expirationDate}`,
                        })}
                      </ItemSubtitle>
                    )}
                    {!expireAt && (
                      <ItemSubtitle style={{ color: `${green}` }}>
                        {t('no expiration date', {
                          ns: 'text',
                        })}
                      </ItemSubtitle>
                    )}
                  </span>
                  <StyledIcon icon="INTERFACE_CHEVRON_RIGHT" size={16} />
                </ArrowedItem>
              </DropdownItem>
              {account && account.planName && account.planName !== 'emotion_corporate' && isAdmin && (
                <>
                  <DropdownItem divider />
                  <DropdownItem>
                    <ArrowedItem
                      to={{
                        pathname: '/settings',
                        state: { fromMenu: true },
                      }}
                      onClick={() => window.localStorage.setItem('openedSettings', 'plan-pricing')}
                    >
                      <span>
                        <ItemTitle>
                          {account &&
                            account.planName
                              .split('_')
                              .map(str => str.charAt(0).toUpperCase() + str.slice(1))
                              .join(' ')}
                        </ItemTitle>
                        {account && account.remainingCredits !== null && (
                          <ItemSubtitle>
                            {account && account.remainingCredits} {t('credits available')}
                          </ItemSubtitle>
                        )}
                      </span>
                      <StyledIcon icon="INTERFACE_CHEVRON_RIGHT" size={16} />
                    </ArrowedItem>
                  </DropdownItem>
                </>
              )}
              <DropdownItem divider />
              <DropdownItem>
                <StyledLink
                  to={{
                    pathname: '/settings',
                    state: { fromMenu: true },
                  }}
                  onClick={() => window.localStorage.setItem('openedSettings', 'projects')}
                >
                  <ItemTitle>{t('Project list')}</ItemTitle>
                </StyledLink>
              </DropdownItem>
              {account &&
                account.planName !== null &&
                account.planName !== 'emotion_corporate' &&
                account.canAccessBilling &&
                isAdmin && (
                  <>
                    <DropdownItem divider />
                    <DropdownItem>
                      <StyledLink
                        to={{
                          pathname: '/settings',
                          state: { fromMenu: true },
                        }}
                        onClick={async () => {
                          await client
                            .query({
                              query: CUSTOMER_PORTAL_QUERY,
                            })
                            .then(({ data: { customerPortal } }) => {
                              window.open(customerPortal.url);
                            });
                        }}
                      >
                        <ItemTitle>{t('Billing')}</ItemTitle>
                      </StyledLink>
                    </DropdownItem>
                  </>
                )}
              <DropdownItem divider />
              <DropdownItem>
                <a
                  href={
                    lang === 'fr'
                      ? 'https://help.cxinsights.io/portal/fr/kb/help-center-1/helpcenter-french/guide-de-d%C3%A9marrage'
                      : 'https://help.cxinsights.io/portal/fr/kb/knowledge-base/english-helpcenter-en/getting-started'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <ItemTitle>{t('Getting started')}</ItemTitle>
                </a>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => logout(history)}>
                <ItemTitle>{t('Sign out')}</ItemTitle>
              </DropdownItem>
            </DropdownAccount>
          </Dropdown>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return { auth };
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserInfos: newState => {
      dispatch(storeUserInfos(newState));
    },
    logout: newState => {
      dispatch(logout(newState));
    },
  };
}

export default withApollo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('profil', 'header', 'text')(ImageProfil))),
);
