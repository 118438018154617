import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { red } from 'styles/abstracts/colors';
import QButton from '_Components/Buttons/QButton/QButton';
import { ImageSize } from 'styles/abstracts/variables';

const StyledButton = styled(QButton)`
  border: 2px solid ${({ borderColor }) => borderColor} !important;
  color: ${({ textColor }) => textColor} !important;
  transition: background-color 0.25s;
  margin-left: 40%;
  & > span {
    margin-left: 0.5rem;
  }
`;

const EditButton = ({ primaryColor, text, size, className, onClick }) => (
  <StyledButton
    bgColor="red"
    // textColor="#FFFFFF"
    borderColor={red}
    className={className}
    size={size || 'medium'}
    onClick={onClick}
    icon="WARNING"
    iconPosition="left"
    iconSize={ImageSize.small}
    disableHover
  >
    <span>{text}</span>
  </StyledButton>
);

EditButton.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EditButton;
