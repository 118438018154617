import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import Icon from '_Components/Icons/Icon';
import { getRoute } from '_Utils/routes/routeUtils';
import FileUploader from '_Components/FileManagement/FileUploader/FileUploader';
import Qcard from '../../../_Components/Qcard/Qcard';
import QTitle from '../../../_Components/Texts/QTitle/QTitle';
import { spacing } from '../../../styles/abstracts/variables';
import { fontSizes } from '../../../styles/abstracts/typography';
import { grayShades } from '../../../styles/abstracts/colors';
import QButton from '../../../_Components/Buttons/QButton/QButton';

const AddProjectCard = styled(Qcard)`
  display: flex;
  justify-content: center;
`;

const Title = styled(QTitle)`
  margin: ${spacing.mediumSmall} auto;
  font-size: ${fontSizes.bigger};
`;

const CardContent = styled.div`
  &,
  & span {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
  width: 100%;
  padding: ${spacing.large} 0;
  background: ${grayShades.g100};
  border-top: 1px solid ${grayShades.g300};
  border-bottom: 1px solid ${grayShades.g300};
`;

const ReturnButton = styled.div`
  margin: 0.75em !important;
  display: flex;
  justify-content: center;
`;

const ReturnBtn = styled(QButton)`
  font-size: ${fontSizes.smaller} !important;
`;

const Return = styled(Link)`
  text-align: center;
  font-weight: bold;
  color: ${grayShades.g800};
  font-size: 0.875em;
  cursor: pointer;
`;

const FooterErrorContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddData = ({ updateRoute, t, history }) => {
  const [fileState, setFileState] = useState('no_file');
  const [title, setTitle] = useState('Send us your data');
  useEffect(() => {
    getRoute(updateRoute, 'add-data');
    if (fileState === 'uploaded') {
      setTitle('Your file uploaded successfully');
    } else if (fileState === 'error') {
      setTitle('Something went wrong...');
    }
  }, [fileState]);

  const getFooter = () => {
    let footer;
    if (fileState === 'uploaded') {
      footer = (
        <ReturnButton>
          <ReturnBtn
            hasOpacity
            icon="INTERFACE_CHEVRON_LEFT"
            iconSize={24}
            iconPosition="left"
            onClick={() => history.push('/main')}
          >
            {t('button:Go back to project list')}
          </ReturnBtn>
        </ReturnButton>
      );
    } else if (fileState === 'error') {
      footer = (
        <FooterErrorContainer className="d-flex">
          <ReturnButton onClick={() => setFileState('no_file')}>
            <ReturnBtn hasOpacity>
              <Icon icon="INTERFACE_CHEVRON_LEFT" size={24} /> &nbsp;{' '}
              <span>{t('button:Retry')}</span>
            </ReturnBtn>
          </ReturnButton>
          <Return to="main">{t('button:Go back to project list')}</Return>
          <br />
        </FooterErrorContainer>
      );
    } else {
      footer = null;
    }

    return footer;
  };

  const ADD_FILE = gql`
    mutation uploadFile($file: Upload!) {
      uploadFile(file: $file) {
        errors
      }
    }
  `;

  return (
    <Container fluid className="py-3 px-4">
      <AddProjectCard>
        <Title>{t(title)}</Title>
        <CardContent>
          <FileUploader
            setFileState={setFileState}
            t={t}
            acceptedFormat={['.csv', '.xlsx']}
            uploadedText="Everything seems to be ok !"
            uploadedSubText="Our technical team will process your data..."
            fileState={fileState}
            fileUploadMutation={ADD_FILE}
          />
        </CardContent>
        {getFooter()}
      </AddProjectCard>
    </Container>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation(['card', 'button'])(withRouter(AddData)));
