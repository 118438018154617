import React from 'react';
import QTagSwitchFilter from '_Components/QTag/QTagSwitchFilter';
import styled from 'styled-components';
import {
  happiness,
  surprise,
  calm,
  anger,
  disgust,
} from '../../../styles/abstracts/colors';

const filters = [
  {
    name: 'ENCHANTEMENT',
    color: happiness,
    text: 'Enchantment',
    icon: 'FILTER_ENCHANTEMENT',
  },
  {
    name: 'EFFET_WOW',
    color: surprise,
    text: 'WOW effect',
    icon: 'FILTER_WOW',
  },
  {
    name: 'STORIES',
    color: calm,
    text: 'Stories',
    icon: 'FILTER_STORIES',
  },
  {
    name: 'IRRITATION',
    color: anger,
    text: 'Irritation',
    icon: 'INTERFACE_IRRITANTS',
  },
  {
    name: 'ATTRITION',
    color: disgust,
    text: 'Churn',
    icon: 'FILTER_CHURN',
  },
];

const QTagSwitchFilterContainer = styled.div`
  min-width: 100%;
`;

type VerbatimFiltersContainerProps = {
  getVerbatims: any;
  verbatimFilters: any;
  selectedSwitchFilters: Array<string>;
  projectId: number;
};

const VerbatimFiltersContainer = ({
  getVerbatims,
  verbatimFilters,
  selectedSwitchFilters,
  projectId,
}: VerbatimFiltersContainerProps) => {
  return (
    <QTagSwitchFilterContainer>
      <QTagSwitchFilter
        availableSwitchFilters={filters}
        selectedSwitchFilters={selectedSwitchFilters}
        query={getVerbatims}
        variables={verbatimFilters}
        variableFieldToModify="selectedClusters"
        projectId={projectId}
      />
    </QTagSwitchFilterContainer>
  );
};

export default VerbatimFiltersContainer;
