import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Shortcut, {
  PAGES_SHORTCUT_MODES,
  PAGES_SHORTCUT_TYPES,
  PageShortCutPropType,
} from './Shortcut';
import QDraggableBox from '../../../../_Components/Panels/QDnD/elements/QDraggableBox';
import QDroppableBox from '../../../../_Components/Panels/QDnD/elements/QDroppableBox';

const ITEMS_PER_ROW = 3;

const Shortcuts = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: ${({ rows }) => Array(rows).fill('1fr')};
  flex-direction: column;
  padding: 1em;
`;

const getRows = (shortcuts, onClick, type, mode) =>
  shortcuts.map((shortcut, index) => (
    <QDraggableBox
      id={index}
      index={index}
      render={() => (
        <Shortcut
          shortcut={shortcut}
          onClicked={() => onClick(shortcut)}
          type={type}
          mode={mode}
        />
      )}
    />
  ));

const PagesShortcuts = ({
  droppable,
  shortcuts,
  onShortcutClicked,
  mode,
  type,
}) => {
  const getShortcutsComponents = () => (
    <Shortcuts rows={Math.ceil(shortcuts.length / ITEMS_PER_ROW)}>
      {getRows(shortcuts, onShortcutClicked, type, mode)}
    </Shortcuts>
  );

  return droppable ? (
    <QDroppableBox id={mode} dropDisabled render={getShortcutsComponents} />
  ) : (
    getShortcutsComponents()
  );
};

PagesShortcuts.propTypes = {
  mode: PropTypes.string,
  onShortcutClicked: PropTypes.func.isRequired,
  shortcuts: PropTypes.arrayOf(PageShortCutPropType).isRequired,
  type: PropTypes.oneOf(PropTypes.arrayOf(Object.values(PAGES_SHORTCUT_TYPES))),
  droppable: PropTypes.bool,
};

PagesShortcuts.defaultProps = {
  type: PAGES_SHORTCUT_TYPES.PAGE,
  mode: PAGES_SHORTCUT_MODES.PREDEFINED_LAYOUTS,
  droppable: true,
};

export default PagesShortcuts;
