import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import ReportingVerbatimList from './_Components/ReportingVerbatimList';
import CQuery from '../../../_Container/QQuery/CQuery';
import BEST_VERBATIM from './graphql/getBestReportingVerbatimCardData';
import WORST_VERBATIM from './graphql/getWorstReportingVerbatimCardData';
import PropTypes from 'prop-types';

const CustomContainer = styled(Container)`
  height: inherit;
`;

const ReportingBestWorstVerbatimCardContainer = ({
  filterKeys,
  noDataComponent,
  errorComponent,
  typeVerbatim,
  ...props
}) => (
  <CustomContainer className="reportingVerbatimContainer">
    {filterKeys && (
      <CQuery
        filters={filterKeys}
        query={
          typeVerbatim === 'bestVerbatim'
            ? BEST_VERBATIM
            : typeVerbatim === 'worstVerbatim' && WORST_VERBATIM
        }
        noDataComponent={noDataComponent}
        errorComponent={errorComponent}
      >
        <ReportingVerbatimList {...props} />
      </CQuery>
    )}
  </CustomContainer>
);

ReportingBestWorstVerbatimCardContainer.propTypes = {
  typeVerbatim: PropTypes.string.isRequired,
};

export default ReportingBestWorstVerbatimCardContainer;
