import { ActionTypes } from '../../../constants';

export default function routes(state = {}, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_ROUTE:
      return {
        ...state,
        route: action.payload.route,
        subRoute: action.payload.subRoute,
        defaultThematic: action.payload.defaultThematic,
        pageConfiguration: action.payload.pageConfiguration,
      };
    default:
      return state;
  }
}
