import createTileFactory from './presentation/tile';
import createInsightTileFactory from './presentation/insightTile';
import createModuleTileFactory from './presentation/moduleTile';

export const FACTORIES_TYPES = {
  INSIGHT_TILE: 'insightTile',
  GRID_TILE: 'gridTile',
  MODULE_TILE: 'moduleTile',
};

export const prepareRunFactories = config => {
  const factories = {
    [FACTORIES_TYPES.GRID_TILE]: {
      factory: createTileFactory(config),
      prepareFactoryArguments: ({ gridPoints }) => ({
        points: gridPoints,
        type: FACTORIES_TYPES.GRID_TILE,
      }),
    },
    [FACTORIES_TYPES.INSIGHT_TILE]: {
      factory: createInsightTileFactory(config),
      prepareFactoryArguments: ({ insightsPoints, period, indicator }) => ({
        points: insightsPoints,
        type: FACTORIES_TYPES.INSIGHT_TILE,
        indicator: period !== 'current' ? 'delta' : indicator, // TODO: coloring here
      }),
    },
    [FACTORIES_TYPES.MODULE_TILE]: {
      factory: createModuleTileFactory(config),
      prepareFactoryArguments: ({
        modulePoint,
        period,
        indicator,
        module,
      }) => ({
        point: modulePoint,
        type: FACTORIES_TYPES.MODULE_TILE,
        module,
        indicator: period !== 'current' ? 'delta' : indicator, // TODO: coloring here
      }),
    },
  };

  return (factoriesToRun, context) => {
    factoriesToRun
      .map(type => factories[type])
      .forEach(({ factory, prepareFactoryArguments }) => {
        const args = prepareFactoryArguments(context);
        factory(args);
      });
  };
};
