import { getColorHexaFromEindex } from '_Utils/eindex/eindexColorFunctions';
import { upperFirst, max, min } from 'lodash';
import { convertCelsiusToFahrenheit, getMinMaxFromUnit } from '_Utils/eindex/eindexUnits';
import { purple } from 'styles/abstracts/colors';
import { INDICATORS_TYPE } from '_Cards/FilterCards/DataByFiltersCard/FiltersSettings';
import { FILTERS_DIMENSIONS } from '../DataByThematics/utils';

export const HORIZONTAL_INDICATORS_CONFIG = {
  [INDICATORS_TYPE.BADGE]: [
    {
      label: 'None',
      id: null,
    },
    {
      label: 'E-index',
      id: FILTERS_DIMENSIONS.EINDEX,
      color: (eindex: any, isFahrenheit: boolean | undefined) => getColorHexaFromEindex(eindex, isFahrenheit),
      min: (data: any[], isFahrenheit: any) =>
        data && min(data.map((item: { eindex: { value: any } }) => !isFahrenheit && item.eindex.value)) < 0
          ? min(data.map((item: { eindex: { value: any } }) => item.eindex.value))
          : 0,
      max: (filterKeys: any, eindexUnitFahrenheit: any) => filterKeys && getMinMaxFromUnit(eindexUnitFahrenheit).max,
      data: (item: { eindex: { value: any } }, isFahrenheit: any) =>
        isFahrenheit ? convertCelsiusToFahrenheit(item.eindex.value) : item.eindex.value,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.MENTIONS),
      id: FILTERS_DIMENSIONS.MENTIONS,
      color: (data: any) => purple,
      min: () => 0,
      max: (data: any[]) => data && max(data.map((item: { mentions: any }) => item.mentions)) * 2,
      data: (item: { mentions: any }) => item.mentions,
    },
  ],

  [INDICATORS_TYPE.BAR]: [
    {
      label: upperFirst(FILTERS_DIMENSIONS.EMOTIONS),
      id: FILTERS_DIMENSIONS.EMOTIONS,
      color: (data: any) => purple,
      subcategories: [
        {
          label: 'Emotions volume',
          id: FILTERS_DIMENSIONS.EMOTIONSVOLUME,
          color: (data: any) => purple,
          min: () => 0,
          max: () => 10000,
          data: (item: { mentionCount: any }) => item.mentionCount,
        },
        {
          label: 'Emotions percentage',
          id: FILTERS_DIMENSIONS.EMOTIONSPERCENTAGE,
          color: (data: any) => purple,
          min: () => 0,
          max: () => 100,
          data: (item: { mentionCount: any }) => item.mentionCount,
        },
      ],
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.SENTIMENT),
      id: FILTERS_DIMENSIONS.SENTIMENT,
      color: (data: any) => purple,
      subcategories: [
        {
          label: 'Sentiment volume',
          id: FILTERS_DIMENSIONS.SENTIMENTVOLUME,
          color: (data: any) => purple,
          min: () => 0,
          max: () => 10000,
          data: (item: { mentionCount: any }) => item.mentionCount,
        },
        {
          label: 'Sentiment percentage',
          id: FILTERS_DIMENSIONS.SENTIMENTPERCENTAGE,
          color: (data: any) => purple,
          min: () => 0,
          max: () => 100,
          data: (item: { mentionCount: any }) => item.mentionCount,
        },
      ],
    },
  ],
};

export const ORDER_BY_ITEMS = [
  {
    id: 'label',
    label: 'Alphabetical',
    subcategories: [
      {
        id: 'name_asc',
        label: 'Alphabetical - Ascending',
      },
      {
        id: 'name_desc',
        label: 'Alphabetical - Descending',
      },
    ],
  },
  {
    id: 'hierarchy',
    label: 'Hierarchical',
    subcategories: [
      {
        id: 'hierarchy_asc',
        label: 'Hierarchical - Ascending',
      },
      {
        id: 'hierarchy_desc',
        label: 'Hierarchical - Descending',
      },
    ],
  },
  {
    id: FILTERS_DIMENSIONS.MENTIONS,
    label: 'Mentions',
    subcategories: [
      {
        id: 'mentionCount_asc',
        label: 'Mentions - Ascending',
      },
      {
        id: 'mentionCount_desc',
        label: 'Mentions - Descending',
      },
    ],
  },
  {
    id: FILTERS_DIMENSIONS.EINDEX,
    label: 'E-Index',
    subcategories: [
      {
        id: 'eindex_asc',
        label: 'Eindex - Ascending',
      },
      {
        id: 'eindex_desc',
        label: 'Eindex - Descending',
      },
    ],
  },
];
