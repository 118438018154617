import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';
import { Table } from 'reactstrap';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { grayShades, black } from 'styles/abstracts/colors';
import AlertingRow, { AlertType } from './_Components/AlertingRow/AlertingRow';
import GET_ALERT_CONFIGURATION_LIST_QUERY from './graphql/getAlertConfigurationList';

const Item = styled.th`
  //padding: 1em !important;
  padding-bottom: 0 !important;
  color: ${black};
  border-bottom: 1px solid ${grayShades.g300} !important;
  position: sticky !important;
  top: 0 !important;
  background-color: white !important;
  z-index: 2;
`;

type AlertingCardProps = {
  projectId: number;
  t: any;
};

const AlertingCard = ({ projectId, t }: AlertingCardProps) => {
  const { data: alertConfigurationList, loading } = useQuery(
    GET_ALERT_CONFIGURATION_LIST_QUERY,
    {
      variables: { projectId },
    },
  );

  const [data, setData] = useState(
    alertConfigurationList?.getAlertConfigurationList,
  );

  useEffect(() => {
    setData(alertConfigurationList?.getAlertConfigurationList);
  }, [alertConfigurationList?.getAlertConfigurationList]);

  return (
    <QcardPanel title="Your alerts" displayCardSubTitle={false}>
      <Table borderless>
        <thead>
          <tr>
            <Item>{t('Name')}</Item>
            <Item>{t('Conditions')}</Item>
            <Item>{t('Frequency')}</Item>
            <Item>{t('Active')}</Item>
            <Item />
          </tr>
        </thead>
        {loading ? (
          <b>{t('Loading')}</b>
        ) : (
          <tbody>
            {data?.map((alert: AlertType, index: number) => (
              <AlertingRow alert={alert} index={index} alerts={data} t={t} />
            ))}
          </tbody>
        )}
      </Table>
    </QcardPanel>
  );
};

const mapStateToProps = (state: any) => ({
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
});

export default withTranslation('card', ['button'])(connect(mapStateToProps)(AlertingCard));
