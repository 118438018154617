import gql from 'graphql-tag';

const mutation = gql`
  mutation createInsight(
    $projectId: Int!
    $name: String!
    $description: String
    $sentenceIds: [Int!]!
  ) {
    createInsight(
      projectId: $projectId
      name: $name
      description: $description
      sentenceIds: $sentenceIds
    ) {
      insight {
        insightId
        insightName
        insightStatus
        description
        entryPoints {
          current {
            mentions
            eindex
            sat
            reco
            ces
            emotions {
              happiness
              surprise
              calm
              fear
              sadness
              anger
              disgust
            }
          }
          previousPeriod {
            mentions
            eindex
            sat
            reco
            emotions {
              happiness
              surprise
              calm
              fear
              sadness
              anger
              disgust
            }
          }
          previousYear {
            mentions
            eindex
            sat
            reco
            emotions {
              happiness
              surprise
              calm
              fear
              sadness
              anger
              disgust
            }
          }
        }
        modules
        editedOn
        editedBy
        createdOn
        createdBy
      }
    }
  }
`;

export default mutation;
