import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { radius } from '../../styles/abstracts/variables';

const QScaleContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const QScaleRangeLabel = styled.span`
  margin: 0 0.5em;
`;

const QScaleStep = styled.div`
  width: 1.5em;
  height: 1em;
  background-color: ${({ bgColor }) => bgColor};

  &:first-child {
    border-top-left-radius: ${radius.default};
    border-bottom-left-radius: ${radius.default};
  }

  &:last-child {
    border-top-right-radius: ${radius.default};
    border-bottom-right-radius: ${radius.default};
  }
`;

const generateStep = color => <QScaleStep key={color} bgColor={color} />;

export default class QScale extends PureComponent {
  createSteps() {
    return this.props.colors.map(generateStep);
  }

  render() {
    const {
      range: { max, min },
      className,
      height,
    } = this.props;
    return (
      <QScaleContainer height={height} className={className}>
        <QScaleRangeLabel>{min}</QScaleRangeLabel>
        <QScaleContainer>{this.createSteps()}</QScaleContainer>
        <QScaleRangeLabel>{max}</QScaleRangeLabel>
      </QScaleContainer>
    );
  }
}
