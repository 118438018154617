import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { lightBlue } from 'styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from 'styles/abstracts/variables';

const Container = styled(Row)`
  width: 100%;
  margin: 0 !important;
`;

const StyledCol = styled(Col)`
  padding: 1em !important;
`;

const BoldCol = styled(StyledCol)`
  font-weight: bold;
`;

const RightTextCol = styled(BoldCol)`
  display: flex;
  justify-content: flex-end;
`;

const DownloadLink = styled.span`
  color: ${lightBlue};
  cursor: pointer;
  text-align: right;
  margin-right: 1em;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;
`;

const ProcessingHistoryRow = ({ data, t }) => {
  const [isHovering, toggleHovering] = useState(false);
  const { processingDate, mentionCount, mode, usedCredits } = data;
  return (
    <Container
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
    >
      <BoldCol xs={3}>
        {moment(processingDate).format(`L [${t('at')}] HH:mm`)}
      </BoldCol>
      <StyledCol xs={2}>{mode}</StyledCol>
      <StyledCol xs={3}>{mentionCount}</StyledCol>
      <StyledCol xs={2}>{usedCredits}</StyledCol>
      <RightTextCol xs={4}>
        {/* {isHovering && (
          <DownloadLink>
            <StyledIcon
              icon="DOWNLOAD"
              color={lightBlue}
              size={ImageSize.medium}
            />{' '}
            <p>{t('button:Download data')}</p>
          </DownloadLink>
        )} */}
      </RightTextCol>
    </Container>
  );
};

export default ProcessingHistoryRow;
