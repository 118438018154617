import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

import CLineChart from '../../../_Charts/LineCharts/CLineChart/CLineChart';

import GET_EINDEX_EVOLUTION_WITH_MENTIONS_CARD_DATA_QUERY from './graphql/getEindexEvolutionWithMentionsCardData';

type EindexEvolutionWithMentionsSectionProps = {
  mixBarLine?: boolean;
  noDataComponent: React.ReactNode;
  errorComponent: React.ReactNode;
  hideRangeButtons: boolean;
  settings: any;
  range?: 'day' | 'week' | 'month';
  filters: any;
  filterKeys: any;
  mockData: any;
};

export const getKeywords = (filters: any) => {
  const keywords: string[] = [];

  if (filters?.texts?.length > 0) {
    if (filters?.texts[0].values.length > 0) {
      filters?.texts[0].values.map((keyword: string) => {
        keywords.push(keyword);
        return keywords;
      });
    }
  }

  return keywords;
};

const EindexEvolutionWithMentionsSection = ({
  mixBarLine,
  settings,
  range = 'day',
  filters,
  filterKeys,
  mockData,
}: EindexEvolutionWithMentionsSectionProps) => {
  const [chartData, setChartData] = useState<any[] | null>(null);
  const { data } = useQuery(GET_EINDEX_EVOLUTION_WITH_MENTIONS_CARD_DATA_QUERY, {
    variables: {
      ...(filters || filterKeys),
      dateType: (settings?.range || range).toUpperCase(),
      keywords: getKeywords(filters || filterKeys),
    },
  });

  useEffect(() => {
    if (data) {
      setChartData(data?.results?.eindexEvolutionWithMention);
    }
  }, [data]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {(mockData || chartData) && (
        <CLineChart
          mixBarLine={mixBarLine}
          currentPeriod={settings && settings.range}
          data={mockData?.eindexEvolutionWithMention ?? chartData}
        />
      )}
    </div>
  );
};

EindexEvolutionWithMentionsSection.defaultProps = {
  mixBarLine: null,
};

export default EindexEvolutionWithMentionsSection;
