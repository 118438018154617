import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { green, white } from 'styles/abstracts/colors';

const ToastContainer = styled.div`
  border-radius: 25em;
  background-color: ${({color}) => color};
  color: ${white};
  font-weight: bold;
  padding: 1em;
`;

const QToast = ({ children, color, t }) => {
  let body = children;
  useEffect(() => {
    setTimeout(() => {
      body = null;
    }, 5000);
  }, []);
  return (
    body &&
    ReactDOM.createPortal(
      <ToastContainer color={color || green}>{t(body)}</ToastContainer>,
      document.getElementById('toasts'),
    )
  );
};

export default withTranslation('card')(QToast);
