import React, { Fragment } from 'react';
import 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/style.scss';
import EindexItem from 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/EindexItem';
import Arrow from 'Routes/_Containers/CustomerJourneyViewGeneric/_Containers/EindexPath/Arrow';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Loader from '_Components/Loader/OnLoad';
import { withTranslation } from 'react-i18next';

class EindexPath extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      associatedThematic: null,
      activeTabId: null,
    };
  }

  componentDidMount() {
    this.checkifHasAssociatedThematic(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.specificFilters &&
      nextProps.specificFilters !== this.props.specificFilters &&
      nextProps.specificFilters.thematics
    ) {
      this.setState({
        activeTabId: nextProps.specificFilters.thematics[0].subThematic,
      });
    }
    this.checkifHasAssociatedThematic(nextProps);
  }

  checkifHasAssociatedThematic(props) {
    const defaultThematic = get(this.props, [
      'pageConfiguration',
      'defaultThematic',
    ]);
    this.setState({
      associatedThematic: defaultThematic,
    });
  }

  render() {
    return this.state.associatedThematic ? (
      <div className="contain">
        {this.props.data ? (
          this.props.data.eindexItems.map((item, i) => (
            <>
              {i > 0 && this.props.withArrows && (
                <Arrow key={`ArrowEindexItem${i}`} />
              )}
              <EindexItem
                key={`eindexItem${i}`}
                {...item}
                active={this.state.activeTabId === item.id}
                i={i}
                associatedThematic={this.state.associatedThematic}
                style={{
                  cursor: item.mentionCount ? 'pointer' : 'not-allowed',
                }}
              />
            </>
          ))
        ) : (
          <Loader />
        )}
      </div>
    ) : null;
  }
}
function mapStateToProps(state, ownProps) {
  return {
    filterKeys: state.filters.filterKeys,
    specificFilters: state.specificFilters[ownProps.idpage] || null,
    pageConfiguration: state.routes.pageConfiguration,
  };
}
export default connect(mapStateToProps)(withTranslation('text')(EindexPath));
