export const purple = '#592ea0';
export const darkPurple = '#592ea0';
export const darkerPurple = '#592ea0';
export const lightPurple = '#dbd6e9';
export const gray = '#a7adc7';
export const lightGray = '#f2f4fa';
export const white = '#FFF';
export const red = '#db2156';
export const orange = '#ff8104';
export const yellow = '#f5d800';
export const green = '#8bdb00';
export const lightBlue = '#0EC3E3';
export const blue = '#007aff';
export const darkBlue = '#222c66';
export const black = '#2e096b';

export const colors = {
  purple,
  darkPurple,
  darkerPurple,
  lightPurple,
  gray,
  lightGray,
  white,
  red,
  orange,
  yellow,
  green,
  lightBlue,
  blue,
  darkBlue,
  black,
};

export const redBlueShades = {
  rbs1: red,
  rbs2: '#bd1370',
  rbs3: '#992085',
  rbs4: '#6e208c',
  rbs5: '#492796',
  rbs6: darkBlue,
  rbs7: '#f7f9ff',
};

export const opacity = {
  green: 'rgba(139, 219, 0, 0.2)',
  red: 'rgba(219, 33, 86, 0.2)',
  orange: 'rgba(255, 129, 4, 0.2)',
  purple: 'rgba(89, 46, 160, 0.2)',
  lightBlue: 'rgba(14, 195, 227, 0.2)',
  black: 'rgba(0, 0, 0, 0.5)',
};

export const grayShades = {
  g100: '#f7f9ff',
  g200: '#f2f4fa',
  g300: '#edf0f7',
  g400: '#e1e4ed',
  g450: '#ded6ec',
  g500: '#c3c8d9',
  g600: '#a7adc7',
  g700: '#9d9ebd',
  g800: '#928fb0',
  g900: '#9c82c6',
};

export const themeColors = {
  secondary: grayShades.g800,
  primary: purple,
};

// Fields
export const inputColor = '#e1e4ed';

// Buttons
export const danger = red;

// Backgrounds
export const backgrounds = {
  secondary: lightGray,
};

// Texts
export const textColor = gray;
export const activeTextColor = lightBlue;
export const touchedTextColor = purple;

// Borders
export const borderColor = '#e1e4ed';

// INDICATORS
// Emotions

export const happiness = '#DB2156';
export const surprise = '#FF8104';
export const calm = '#F5D800';
export const fear = '#8BDB00';
export const sadness = '#0EC3E3';
export const anger = '#007AFF';
export const disgust = '#222C66';

export const emotionColors = {
  happiness,
  surprise,
  calm,
  fear,
  sadness,
  anger,
  disgust,
};

export const sentimentColors = {
  positive: green,
  neutral: grayShades.g500,
  negative: '#000000',
};

export const QemotionSentimentThemeColors = [
  sentimentColors.positive,
  sentimentColors.neutral,
  sentimentColors.negative,
];

// E-Index
export const eindexColors = {
  ec40: redBlueShades.rbs1,
  ec30: redBlueShades.rbs2,
  ec20: redBlueShades.rbs3,
  ec10: redBlueShades.rbs4,
  ec0: redBlueShades.rbs5,
  'ec-10': redBlueShades.rbs6,
};

export const eindexColorsOrdered = [
  eindexColors.ec40,
  eindexColors.ec30,
  eindexColors.ec20,
  eindexColors.ec10,
  eindexColors.ec0,
  eindexColors['ec-10'],
];

// Main emotion
export const mainEmotionColorsOrdered = [orange, yellow, green, lightBlue, blue, darkBlue];

// Delta
export const deltaColorsOrdered = ['#8BDB00', '#9FA703', '#B27306', '#C54009', '#D90C0C'];

export const lightenOrDarkenColor = (hex, lum) => {
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  let rgb = '#';
  let c;
  for (let i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

export const changeOpacity = (color, opacity) => {
  const opacityValue = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + opacityValue.toString(16).toUpperCase();
};

// Default
export const defaultIndicatorColor = '#c1afe0';
