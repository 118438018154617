import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { purple, white } from 'styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';
import { get, find, uniqWith, isEqual } from 'lodash';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { setSelectedFilters } from '_Resources/Header/Actions/setSelectedFilters';
import ModalFiltersPage from './ModalFiltersPage';
import FilterModal from './FilterModal';

const FilterIndicatorContainer = styled.div`
  cursor: 'pointer';
  position: relative;
  display: flex;
  padding: 0.5em;
  margin-left: 1rem;
  align-items: center;
`;

const FilterIndicatorIcon = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  padding: 0.25em;
`;

const NumberOfAppliedFilters = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: ${purple};
  color: ${white};
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  top: -5px;
  right: -5px;
  text-align: center;
  display: flex;
  justify-content: center;
`;

type FilterIndicatorProps = {
  t: any;
  filters: any;
  thematics: any;
  criteriaKeys: any;
  keywords: any;
  projectId: number;
  defaultThematic: any;
};

const FilterIndicator = ({
  t,
  filters,
  thematics,
  criteriaKeys,
  keywords,
  projectId,
  defaultThematic,
}: FilterIndicatorProps) => {
  const dispatch = useDispatch();

  const [isOpen, openModal] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const MODAL_CONTENT_CONFIG = {
    cancelButton: {
      text: t('button:Cancel'),
    },
    closeButton: {
      text: t('button:Close'),
    },
    validateButton: {
      text: t('button:Save'),
    },
    deleteAllButton: {
      text: t('button:DeleteAll'),
    },
    steps: {
      filters: {
        title: t('Filters'),
        stepBody: <ModalFiltersPage />,
      },
    },
  };

  const [numberOfAppliedFilters, setNumberOfAppliedFilters] = useState(0);

  const checkIfDefaultThematicFilterApplied = () => {
    const defaultThematicApplied = find(thematics, {
      thematic: defaultThematic,
      subThematic: '',
      subSubThematic: '',
    });
    return defaultThematicApplied;
  };

  useEffect(() => {
    let nbOfAppliedThematicsFilters = 0;
    let nbOfAppliedKeywordsFilters = 0;
    let nbOfAppliedCriteriaKeysFilters = 0;
    if (thematics) {
      let thematicsThatCount = thematics;
      if (checkIfDefaultThematicFilterApplied()) {
        thematicsThatCount = thematics.filter(
          thematic => !isEqual(thematic, checkIfDefaultThematicFilterApplied()),
        );
      }
      nbOfAppliedThematicsFilters = thematicsThatCount.length;
    }
    if (criteriaKeys) {
      nbOfAppliedCriteriaKeysFilters = criteriaKeys
        .map(criteriaKey => criteriaKey.values.length)
        .reduce((acc, count) => acc + count, 0);
    }
    if (keywords && keywords[0]) {
      nbOfAppliedKeywordsFilters = keywords[0].values.length;
    }
    setNumberOfAppliedFilters(
      nbOfAppliedThematicsFilters +
        nbOfAppliedCriteriaKeysFilters +
        nbOfAppliedKeywordsFilters,
    );
  }, [thematics, criteriaKeys, keywords, defaultThematic]);

  useEffect(() => {
    if (initialized === true) {
      dispatch(
        setGlobalQueryFilterKeys({
          ...filters,
          thematics: [],
          criteriaKeys: [],
          texts: undefined,
          dataCollectorId: null,
        }),
      );
      dispatch(setSelectedFilters([]));
    } else {
      setInitialized(true);
    }
  }, [projectId]);

  return (
    <>
      <FilterIndicatorContainer className="FilterIndicatorContainer">
        <FilterIndicatorIcon
          onClick={() => openModal(true)}
          className="FilterIndicatorIcon"
        >
          <Icon icon="FILTER" size={24} color={purple} />
          {numberOfAppliedFilters > 0 && (
            <NumberOfAppliedFilters>
              {numberOfAppliedFilters}
            </NumberOfAppliedFilters>
          )}
        </FilterIndicatorIcon>
        <FilterModal
          disableOnClickOutside
          isOpen={isOpen}
          closeModal={() => openModal(false)}
          onCancel={() => openModal(false)}
          onSave={() => openModal(false)}
          title={t('Filters to apply')}
          subtitle={t('Here you can filter the results')}
          config={{ mentions: false, date: false }}
          modalConfig={MODAL_CONTENT_CONFIG}
        />
      </FilterIndicatorContainer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  filters: get(state, ['filters', 'filterKeys']),
  thematics: get(state, ['filters', 'filterKeys', 'thematics']),
  criteriaKeys: get(state, ['filters', 'filterKeys', 'criteriaKeys']),
  keywords: get(state, ['filters', 'filterKeys', 'texts']),
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
  defaultThematic: get(state, [
    'routes',
    'pageConfiguration',
    'defaultThematic',
  ]),
});

export default withTranslation('card')(
  connect(mapStateToProps)(FilterIndicator),
);
