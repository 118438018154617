import React from 'react';
import Layout from '_Layouts/Layout';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import VarianceAnalysis from './VarianceAnalysis';

const action = (props: any) => {
  const title = 'Variance analysis';
  const idpage = 'variance-analysis';
  const body = <VarianceAnalysis idpage={idpage} />;
  const tourID = 4;
  return (
    <QLoginRedirect>
      <Layout body={body} title={title} {...props} tourID={tourID} />
    </QLoginRedirect>
  );
};

export default action;
