import React from 'react';
import { Col, Row } from 'reactstrap';
import NoteWithLeftIcon from '../../../_Charts/KpisCharts/NoteWithLeftIcon/NoteWithLeftIcon';
import CQuery from '../../../_Container/QQuery/CQuery';
import querySchema from './graphql/getSatisfactionNpsCardData';

export const SatisfactionNpsSectionView = ({
  title,
  className,
  children,
  classNameCol,
  classNameCssTop,
  data,
  id,
}) => {
  const { satisfaction, netPromoterScore, effort } = data || {};
  const color = '#592EA0';
  const size = 48;
  return (
    <Row className={`containContent ${className}`}>
      {satisfaction && (
        <Col xs={classNameCol} className={classNameCssTop}>
          <NoteWithLeftIcon
            icon="PICTO_STAR"
            color={color}
            size={size}
            backgroundColor={color}
            indicator="Satisfaction"
            data={satisfaction}
          />
        </Col>
      )}
      {netPromoterScore && (
        <Col xs={classNameCol} className={classNameCssTop}>
          <NoteWithLeftIcon
            icon="PICTO_HEART"
            color={color}
            size={size}
            backgroundColor={color}
            indicator="Net promoter score"
            data={netPromoterScore}
          />
        </Col>
      )}
      {effort && (
        <Col xs={classNameCol} className={classNameCssTop}>
          <NoteWithLeftIcon
            icon="CHECK_ALL"
            color={color}
            size={size}
            backgroundColor={color}
            indicator="Effort score"
            data={effort}
          />
        </Col>
      )}
    </Row>
  );
};

const SatisfactionNpsSection = ({
  filters,
  noDataComponent,
  errorComponent,
  classNameCol = 12,
  ...props
}) => (
  <CQuery
    query={querySchema}
    filterKeys={filters}
    noDataComponent={noDataComponent}
    errorComponent={errorComponent}
  >
    <SatisfactionNpsSectionView
      {...props}
      classNameCssBottom="mt-2"
      classNameCol={classNameCol}
    />
  </CQuery>
);

export default SatisfactionNpsSection;
