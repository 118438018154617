import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { findMode } from "./utils/modesHelpers";

import QMapPanel from "../components/QMapPanel";
import QScale from "../components/QScale";
import QPanelHeader from "../components/QPanelHeader";

export default class WorldMapLegend extends PureComponent {
  render() {
    const { currentMode, modes, className } = this.props;
    const { label, colors, range } = findMode(currentMode, modes);
    const title = `${label} legend`;
    return (
      <QMapPanel className={className}>
        <QPanelHeader contentHeader>{title}</QPanelHeader>
        <QScale colors={colors} range={range} />
      </QMapPanel>
    );
  }
}

const modeDataPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  range: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number })
    .isRequired,
});

WorldMapLegend.propTypes = {
  modes: PropTypes.objectOf(modeDataPropType).isRequired,
  currentMode: PropTypes.string.isRequired,
};
