import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getColorHexaFromEindex } from '_Utils/eindex/eindexColorFunctions';
import { QemotionTheme } from '../../Utils/qemotionTheme';

const test =
  typeof document !== 'undefined' && require('echarts-wordcloud').default;
const ReactEcharts =
  typeof document !== 'undefined' && require('echarts-for-react').default;

class CWordCloudGraph extends React.Component {
  static propTypes = {
    height: PropTypes.string,
  };

  static defaultProps = {
    height: '400px',
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (typeof document !== 'undefined') {
      const echarts = require('echarts');
      echarts.registerTheme('QemotionTheme', QemotionTheme);
    }
  }

  getOption(data) {
    const self = this;
    return {
      tooltip: {
        show: true,
        position(point, params, dom, rect, size) {
          // fixed at top
          return { left: point[0] + 50, bottom: 0 };
        },
        triggerOn: 'click',
      },
      series: [
        {
          type: 'wordCloud',
          width: '90%',
          shape: 'square',
          sizeRange: [8, 60],
          textStyle: {
            fontFamily: 'Nunito, sans-serif',
            fontWeight: 'bold',
          },
          data: data.map(word => ({
            name: word.name,
            value: word.mentionCount,
            textStyle: {
              color: getColorHexaFromEindex(word.value, false),
            },
            tooltip: {
              show: true,
              formatter: () =>
                `<div><h6 class="text-center font-weight-bold">${self.props.t(
                  'Word',
                )} <span class="text-red text-capitalize"> "${
                  word.name
                }"</span> : ${Math.round(word.mentionCount)} ${self.props.t(
                  'occurrences',
                )}</h6></div> ${word.contextSentences
                  .map(wordPreview => {
                    const capitalizedWord =
                      word.name.charAt(0).toUpperCase() + word.name.slice(1);
                    const wordToFind = wordPreview.includes(capitalizedWord)
                      ? capitalizedWord
                      : wordPreview.includes(word.name.toUpperCase())
                      ? word.name.toUpperCase()
                      : word.name;
                    return wordPreview
                      .toLowerCase()
                      .includes(word.name.toLowerCase())
                      ? `<div class="row no-gutters d-flex justify-content-center" style="width: 512px"><div class="col text-right" > (...) ${wordPreview
                          .split(`${wordToFind}`)
                          .join(
                            `</div><div class="col-auto">&nbsp;<span style="font-weight: bold">${wordToFind}
                            </span>&nbsp;</div><div class="col"> `,
                          )} (...)</div></div>`
                      : '';
                  })
                  .join('')}`,
            },
          })),
        },
      ],
    };
  }

  render() {
    return (
      <div
        className="examples"
        style={{ width: '100%', height: this.props.height }}
      >
        <div className="parent" style={{ height: '100%' }}>
          <div className="text-center mt-0 mb-0" style={{ height: '100%' }}>
            {ReactEcharts ? (
              <ReactEcharts
                option={this.getOption(this.props.data.wordcloud)}
                style={{ height: '100%', width: '100%' }}
                className="react_for_echarts"
                theme="QemotionTheme"
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
  };
}

export default connect(mapStateToProps)(
  withTranslation('card')(CWordCloudGraph),
);
