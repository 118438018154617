import gql from 'graphql-tag';

const mutation = gql`
  mutation archiveReportingModelOrReporting($id: Int!, $type: Report!) {
    archiveReportingModelOrReporting(id: $id, type: $type) {
      id
      errors
    }
  }
`;

export default mutation;
