import React from "react";
import { map } from "ramda";
import styled from "styled-components";
import { radius, fontSizes } from "../styles/commonProperties";

const QSelectInputBackground = styled.div`
  background-color: #eaeef6;
  border-radius: ${radius.round};
  box-sizing: border-box;
  width: ${({ width }) => width || "auto"};
  padding: 0 1em;
`;

const QSelectInput = styled.select`
  background: transparent;
  font-size: ${fontSizes.default};
  line-height: 2em;
  border: none;
  height: 2em;
  width: 100%;
  appearance: none;

  ::-ms-expand {
    display: none;
  }
`;

export default props => {
  const onChange = event => {
    props.onChange(event.target.value);
  };

  return (
    <QSelectInputBackground className={props.className}>
      <QSelectInput onChange={onChange}>
        {map(
          ({ value, label, id }) => (
            <option key={id || value} value={value}>
              {label}
            </option>
          ),
          props.options
        )}
      </QSelectInput>
    </QSelectInputBackground>
  );
};
