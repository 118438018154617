import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery, useMutation } from 'react-apollo';
import { Row } from 'reactstrap';
import queryString from 'query-string';
import QButton from '_Components/Buttons/QButton/QButton';
import { red } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import {
  passwordUpperCaseVerification,
  passwordLengthVerification,
  passwordDigitVerification,
} from 'Routes/UtilityRoutes/Login/utils';
import NewPasswordForm from './_Components/NewPasswordForm';
import query from './graphql/resetPasswordTokenValidation';
import mutation from './graphql/updateUserPassword';
import logo2x from '../Login/img/qemotion_login-logo@2x.png';
import logo from '../Login/img/qemotion_login-logo.png';

const InfoMessage = styled.p`
  font-weight: bold;
  color: ${red};
  font-size: ${fontSizes.bigger};
`;

const StyledRow = styled(Row)`
  height: fit-content;
`;

const PasswordReset = ({ history, queryParams }) => {
  const [password, setPassword] = useState('');
  const [verificationPassword, setVerificationPassword] = useState('');
  const [status, setStatus] = useState('');
  const [updatePassword] = useMutation(mutation);
  const params = queryString.parse(queryParams);
  const { token } = params;
  const { data } = useQuery(query, { variables: { token } });

  const getBodyContent = () => {
    let bodyContent;
    switch (
      data &&
      data.resetPasswordTokenValidation &&
      data.resetPasswordTokenValidation.code
    ) {
      case 'TOKEN_EXPIRED':
        bodyContent = (
          <InfoMessage>
            Sorry, your token has expired. Please try again.
          </InfoMessage>
        );
        break;
      case 'TOKEN_INVALID':
        bodyContent = (
          <InfoMessage>
            Sorry, your token is invalid. Please try again.
          </InfoMessage>
        );
        break;
      case 'TOKEN_VALID':
        bodyContent =
          status === 'new password updated' ? (
            <p>
              You can know sign in with your email address and your new
              password!
            </p>
          ) : (
            <NewPasswordForm
              password={password}
              verificationPassword={verificationPassword}
              setPassword={setPassword}
              setVerificationPassword={setVerificationPassword}
              digitVerification={passwordDigitVerification(password)}
              uppercaseVerification={passwordUpperCaseVerification(password)}
              lengthVerification={passwordLengthVerification(password)}
            />
          );
        break;
      default:
        bodyContent = null;
        break;
    }
    return bodyContent;
  };

  const onButtonClick = () => {
    if (
      data &&
      data.resetPasswordTokenValidation &&
      data.resetPasswordTokenValidation.code === 'TOKEN_VALID' &&
      status !== 'new password updated'
    ) {
      updatePassword({
        variables: { currentPassword: password, newPassword: password, token },
      }).then(res => setStatus(res.data.updateUserPassword.status));
    } else {
      history.push('/login');
    }
  };

  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  }, [status]);

  return (
    <StyledRow className="justify-content-center flex-column">
      <div className="mb-lg-5 mt-lg-5 mx-auto d-flex justify-content-center">
        <img
          src={logo}
          srcSet={`${logo} 1x, ${logo2x} 2x`}
          alt="Q°emotion"
          className="m-auto img-fluid"
        />
      </div>
      <div className="mb-lg-5 mx-auto w-100">
        <h3 className="text-center">
          {status === 'new password updated'
            ? 'Password successfully changed'
            : 'Password change'}
        </h3>
      </div>
      <div className="mx-auto">{getBodyContent()}</div>
      <div xl={6} lg={8} md={10} sm={12} className="text-center mx-auto">
        <QButton
          bgColor="red"
          size="large"
          className="px-5 mb-3 mt-4"
          type="submit"
          disabled={
            data &&
            data.resetPasswordTokenValidation &&
            data.resetPasswordTokenValidation.code === 'TOKEN_VALID' &&
            (password.length === 0 ||
              password !== verificationPassword ||
              passwordLengthVerification(password) ||
              passwordUpperCaseVerification(password) ||
              passwordDigitVerification(password))
          }
          onClick={() => onButtonClick()}
        >
          {data &&
          data.resetPasswordTokenValidation &&
          data.resetPasswordTokenValidation.code === 'TOKEN_VALID' &&
          status !== 'new password updated'
            ? 'Change password'
            : 'Return to sign in page'}
        </QButton>
      </div>
    </StyledRow>
  );
};

PasswordReset.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  queryParams: PropTypes.string.isRequired,
};

export default PasswordReset;
