import styleVars from 'styles/abstracts/_variables.module.scss';
import { hex2rgbaConvert } from '../../../../../../../../../../../_Utils/styles/colors';

export default function colorInsightTiles(viewMode) {
  switch (viewMode) {
    case 'eindex':
      return [
        {
          backgroundColor: styleVars.eindex40,
          range: {
            celsius: { max: Infinity, min: 30 },
            fahrenheit: { max: Infinity, min: 83 },
            default: { max: Infinity, min: 30 },
          },
        },
        {
          backgroundColor: styleVars.eindex30,
          range: {
            celsius: { max: 30, min: 20 },
            fahrenheit: { max: 83, min: 66 },
            default: { max: 30, min: 20 },
          },
        },
        {
          backgroundColor: styleVars.eindex20,
          range: {
            celsius: { max: 20, min: 10 },
            fahrenheit: { max: 66, min: 50 },
            default: { max: 20, min: 10 },
          },
        },
        {
          backgroundColor: styleVars.eindex10,
          range: {
            celsius: { max: 10, min: 0 },
            fahrenheit: { max: 50, min: 33 },
            default: { max: 10, min: 0 },
          },
        },
        {
          backgroundColor: styleVars.eindex0,
          range: {
            celsius: { max: 0, min: -10 },
            fahrenheit: { max: 33, min: 17 },
            default: { max: 0, min: -10 },
          },
        },
        {
          backgroundColor: styleVars.eindexminus10,
          range: {
            celsius: { max: -10, min: -Infinity },
            fahrenheit: { max: 17, min: -Infinity },
            default: { max: -10, min: -Infinity },
          },
        },
      ];
    case 'mentions':
    case 'sat':
    case 'reco':
    case 'ces':
      return [
        {
          backgroundColor: hex2rgbaConvert(styleVars.purple, 100),
          range: {
            default: { max: Infinity, min: 9 },
          },
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.purple, 80),
          range: {
            default: { max: 9, min: 7 },
          },
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.purple, 60),
          range: {
            default: { max: 7, min: 5 },
          },
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.purple, 40),
          range: {
            default: { max: 5, min: 3 },
          },
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.purple, 20),
          range: {
            default: { max: 3, min: -Infinity },
          },
        },
      ];
    case 'mainEmotion':
      return [
        { backgroundColor: styleVars.red },
        { backgroundColor: styleVars.orange },
        { backgroundColor: styleVars.yellow },
        { backgroundColor: styleVars.green },
        { backgroundColor: styleVars.light_blue },
        { backgroundColor: styleVars.blue },
        { backgroundColor: styleVars.dark_blue },
      ];
    case 'happiness':
      return [
        { backgroundColor: '#C11D4B' },
        { backgroundColor: hex2rgbaConvert(styleVars.red, 100) },
        { backgroundColor: hex2rgbaConvert(styleVars.red, 80) },
        { backgroundColor: hex2rgbaConvert(styleVars.red, 60) },
        {
          backgroundColor: hex2rgbaConvert(styleVars.red, 40),
          textColor: '#C11D4B',
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.red, 20),
          textColor: '#C11D4B',
        },
        { backgroundColor: '#FFFFFF' },
      ];
    case 'surprise':
      return [
        { backgroundColor: '#E07103' },
        { backgroundColor: hex2rgbaConvert(styleVars.orange, 100) },
        { backgroundColor: hex2rgbaConvert(styleVars.orange, 80) },
        { backgroundColor: hex2rgbaConvert(styleVars.orange, 60) },
        {
          backgroundColor: hex2rgbaConvert(styleVars.orange, 40),
          textColor: '#E07103',
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.orange, 20),
          textColor: '#E07103',
        },
        { backgroundColor: '#FFFFFF' },
      ];
    case 'calm':
      return [
        { backgroundColor: '#D8BE00' },
        { backgroundColor: hex2rgbaConvert(styleVars.yellow, 100) },
        { backgroundColor: hex2rgbaConvert(styleVars.yellow, 80) },
        { backgroundColor: hex2rgbaConvert(styleVars.yellow, 60) },
        {
          backgroundColor: hex2rgbaConvert(styleVars.yellow, 40),
          textColor: '#D8BE00',
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.yellow, 20),
          textColor: '#D8BE00',
        },
        { backgroundColor: '#FFFFFF' },
      ];
    case 'fear':
      return [
        { backgroundColor: '#7AC000' },
        { backgroundColor: hex2rgbaConvert(styleVars.green, 100) },
        { backgroundColor: hex2rgbaConvert(styleVars.green, 80) },
        { backgroundColor: hex2rgbaConvert(styleVars.green, 60) },
        {
          backgroundColor: hex2rgbaConvert(styleVars.green, 40),
          textColor: '#7AC000',
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.green, 20),
          textColor: '#7AC000',
        },
        { backgroundColor: '#FFFFFF' },
      ];
    case 'sadness':
      return [
        { backgroundColor: '#0CACC8' },
        { backgroundColor: hex2rgbaConvert(styleVars.light_blue, 100) },
        { backgroundColor: hex2rgbaConvert(styleVars.light_blue, 80) },
        { backgroundColor: hex2rgbaConvert(styleVars.light_blue, 60) },
        {
          backgroundColor: hex2rgbaConvert(styleVars.light_blue, 40),
          textColor: '#0CACC8',
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.light_blue, 20),
          textColor: '#0CACC8',
        },
        { backgroundColor: '#FFFFFF' },
      ];
    case 'anger':
      return [
        { backgroundColor: '#006BE1' },
        { backgroundColor: hex2rgbaConvert(styleVars.blue, 100) },
        { backgroundColor: hex2rgbaConvert(styleVars.blue, 80) },
        { backgroundColor: hex2rgbaConvert(styleVars.blue, 60) },
        {
          backgroundColor: hex2rgbaConvert(styleVars.blue, 40),
          textColor: '#006BE1',
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.blue, 20),
          textColor: '#006BE1',
        },
        { backgroundColor: '#FFFFFF' },
      ];
    case 'disgust':
      return [
        { backgroundColor: '#1C2454' },
        { backgroundColor: hex2rgbaConvert(styleVars.dark_blue, 100) },
        { backgroundColor: hex2rgbaConvert(styleVars.dark_blue, 80) },
        { backgroundColor: hex2rgbaConvert(styleVars.dark_blue, 60) },
        {
          backgroundColor: hex2rgbaConvert(styleVars.dark_blue, 40),
          textColor: '#1C2454',
        },
        {
          backgroundColor: hex2rgbaConvert(styleVars.dark_blue, 20),
          textColor: '#1C2454',
        },
        { backgroundColor: '#FFFFFF' },
      ];
    case 'delta':
      return [
        { backgroundColor: '#8BDB00' },
        { backgroundColor: '#9FA703' },
        { backgroundColor: '#B27306' },
        { backgroundColor: '#C54009' },
        { backgroundColor: '#D90C0C' },
      ];
    default:
      return ['#c1afe0', '#c1afe0', '#c1afe0', '#c1afe0', '#c1afe0', '#c1afe0'];
  }
}
