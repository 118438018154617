import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import { hex2rgbaConvert } from '../../../_Utils/styles/colors';
import { grayShades } from '../../../styles/abstracts/colors';

const getFullSize = (size, padding) => Number(size) + Number(padding) * 2;

const Circle = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  cursor: ${({ pointer }) => pointer && 'pointer'};
`;

class IconCircle extends React.Component {
  render() {
    const {
      icon,
      bgColor,
      color,
      size,
      active,
      iconPadding,
      customCSS,
      pointer,
      onClick,
      ...props
    } = this.props;
    const fullSize = getFullSize(size, iconPadding);
    const currentColor = active ? color : grayShades.g600;
    return (
      <Circle
        size={fullSize}
        bgColor={bgColor || hex2rgbaConvert(currentColor, 20)}
        className={`iconCircle ${customCSS}`}
        pointer={pointer}
        onClick={onClick}
        {...props}
      >
        <Icon icon={icon} color={currentColor} size={size} />
      </Circle>
    );
  }
}

IconCircle.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  active: PropTypes.bool,
  iconPadding: PropTypes.number,
  customCSS: PropTypes.string,
  pointer: PropTypes.bool,
  onClick: PropTypes.func,
};

IconCircle.defaultProps = {
  active: true,
  bgColor: null,
  customCSS: null,
  iconPadding: 8,
  pointer: false,
  onClick: null,
};

export default IconCircle;
