import gql from 'graphql-tag';

const mutation = gql`
  mutation signUp(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $companyName: String!
    $lang: String!
    $alpha3Cc: String!
    $acceptNotifications: Boolean
    $phoneNumber: String
  ) {
    signUp(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      lang: $lang
      alpha3Cc: $alpha3Cc
      acceptNotifications: $acceptNotifications
      phoneNumber: $phoneNumber
    ) {
      errors
    }
  }
`;

export default mutation;
