import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fontSizes } from 'styles/abstracts/typography';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85%;
  font-size: ${fontSizes.bigger};
  margin-bottom: 0.5em;
`;

class LegendSettings extends React.Component {
  legendShowToggled = () => {
    const { onSettingsChange, settings } = this.props;
    onSettingsChange({ ...settings, legendShow: !settings.legendShow });
  };

  render() {
    const { t, settings } = this.props;
    const { legendShow } = settings;

    return (
      <div>
        <br />
        <TitleSection>
          {t('Legend')}
          <QBooleanSwitch id="legend-switcher" onChanged={() => this.legendShowToggled()} value={legendShow} />
        </TitleSection>
      </div>
    );
  }
}

export default withTranslation('card')(LegendSettings);
