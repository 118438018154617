import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  MessageValidation,
  StyledValidationIcon,
} from '_Cards/SettingsCards/AccountAndPreferencesCard/AccountAndPreferencesCard';
import VerificationMessages from 'Routes/UtilityRoutes/PasswordReset/_Components/VerificationMessages';
import {
  grayShades,
  happiness,
  fear,
} from '../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../styles/abstracts/typography';
import QPanelHeader from '../../../../_Components/Panels/QPanelHeader/QPanelHeader';
import QInput from '../../../../_Components/Fields/QInput/QInput';
import { LabelSettings, FormGroupSettings } from './SectionCard';

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 1rem;
  margin: 0 1rem;
  border-bottom: 1px solid ${grayShades.g300};
  &:last-child {
    border-bottom: 0;
  }
`;
const TitleCol = styled(Col)`
  line-height: 3rem;
  padding-left: 0 !important;
  padding-bottom: 1rem;
`;

const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledQInput = styled(QInput)`
  border: ${props => props.errorStyle};
`;
const ErrorText = styled.p`
  color: ${happiness};
  font-weight: normal;
  padding-top: 1rem;
  display: ${props => props.display};
`;
const ErrorVerification = styled(ErrorText)`
  font-size: ${fontSizes.smallest};
  padding-top: 0 !important;
`;
const ValidationGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const ValidationMessagesContainer = styled.div`
  font-size: ${fontSizes.extraSmall};
  margin-left: -0.5em;
  margin-top: -1em;
`;

const ErrorValidation = styled.p`
  color: ${happiness};
  font-size: ${fontSizes.smaller};
`;

const PasswordSection = props => (
  <StyledQPanelHeader type="menu">
    <Row>
      <TitleCol>{props.t('Change Password')}</TitleCol>
    </Row>
    <Row>
      <StyledGroup>
        <FormGroupSettings>
          <LabelSettings>{props.t('Current password')}</LabelSettings>
          <StyledQInput
            type="password"
            value={props.passwords.inputCurrentPass}
            onChange={event => props.callback(event.target)}
            name="inputCurrentPass"
            errorStyle={
              props.errorStyle || props.currentNoInput === true
                ? `2px solid ${happiness}`
                : 'none'
            }
          />
          <ErrorText display={props.errorStyle === true ? 'block' : 'none'}>
            {props.t('Your current password is invalid')}
          </ErrorText>
          <ErrorText
            display={
              props.currentNoInput || props.newNoInput === true
                ? 'block'
                : 'none'
            }
          >
            {props.t('Please fill in fields')}
          </ErrorText>
        </FormGroupSettings>
      </StyledGroup>
      <StyledGroup>
        <ValidationGroup>
          <FormGroupSettings>
            <LabelSettings>{props.t('New password')}</LabelSettings>
            <StyledQInput
              type="password"
              value={props.passwords.inputNewPass}
              onChange={event => props.callback(event.target)}
              name="inputNewPass"
              errorStyle={
                props.noMatching || props.noLength || props.newNoInput === true
                  ? `2px solid ${happiness}`
                  : 'none'
              }
            />
          </FormGroupSettings>
          <FormGroupSettings>
            <LabelSettings>{props.t('Verification')}</LabelSettings>
            <StyledQInput
              type="password"
              value={props.passwords.inputVerificationPass}
              onChange={event => props.callback(event.target)}
              name="inputVerificationPass"
              errorStyle={
                props.noMatching || props.noLength || props.newNoInput === true
                  ? `2px solid ${happiness}`
                  : 'none'
              }
            />
          </FormGroupSettings>
        </ValidationGroup>
        <ValidationMessagesContainer>
          <VerificationMessages password={props.passwords.inputNewPass} />
          {props.passwords.inputVerificationPass &&
            props.passwords.inputVerificationPass.length >= 1 &&
            props.passwords.inputNewPass !==
              props.passwords.inputVerificationPass && (
              <ErrorValidation>The two passwords do not match</ErrorValidation>
            )}
        </ValidationMessagesContainer>
        <ErrorVerification
          display={
            props.noMatching === true || props.noLength === true
              ? 'block'
              : 'none'
          }
        >
          {props.noMatching === true &&
            props.t("New password and verification fields doesn't match")}
        </ErrorVerification>
      </StyledGroup>
    </Row>
    <Row>
      {props.passwordUpdated && (
        <MessageValidation>
          <StyledValidationIcon icon="CHECK_ALL" color={fear} size={24} />{' '}
          {props.t('Password updated')}
        </MessageValidation>
      )}
    </Row>
  </StyledQPanelHeader>
);

PasswordSection.propTypes = {
  t: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  passwords: PropTypes.shape({
    inputCurrentPass: PropTypes.string.isRequired,
    inputNewPass: PropTypes.string.isRequired,
    inputVerificationPass: PropTypes.string.isRequired,
  }).isRequired,
  errorStyle: PropTypes.bool,
  noMatching: PropTypes.bool,
  noLength: PropTypes.bool,
  currentNoInput: PropTypes.bool,
  newNoInput: PropTypes.bool,
  passwordUpdated: PropTypes.bool,
};

PasswordSection.defaultProps = {
  errorStyle: false,
  noMatching: false,
  noLength: false,
  currentNoInput: false,
  newNoInput: false,
  passwordUpdated: false,
};
export default withTranslation('card')(PasswordSection);
