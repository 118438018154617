import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useMutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { orderBy, cloneDeep } from 'lodash';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import Icon from '_Components/Icons/Icon';
import { white, grayShades, purple, lightBlue } from 'styles/abstracts/colors';
import PropTypes from 'prop-types';
import TableRow from './_Components/TableRow';
import CategoryDropdownItem from './_Components/CategoryDropdownItem';
import TableHeader from './_Components/TableHeader';
import SAVE_THEMATICS_OVERVIEW_SETTINGS_MUTATION from '../graphql/saveThematicsOverviewSettings';
import { getAvailableColumns, getSelectedColumns } from './utils';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-right: 1em;
  justify-content: flex-end;
`;

const TableContainer = styled.div`
  overflow-y: scroll;
  max-height: 57.5vh;
  width: 100%;
`;

const Body = styled.tbody`
  & th:first-child,
  & td:first-child {
    position: sticky;
    left: 0;
    width: 33em !important;
  }
`;

const StyledTable = styled(Table)`
  max-width: 100%;
  max-height: 1em !important;
  border-collapse: separate !important;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  background: ${white};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 8px;
  top: inherit;
  width: auto;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 0.5em;
`;

export const Badge = styled.span`
  border-radius: 50%;
  background-color: ${purple};
  color: white;
  font-weight: bold;
  position: ${({ absolute }) => absolute && 'absolute'};
  padding: ${({ absolute }) => (absolute ? '2.5px 5px' : '3.5px 7.5px')};
  font-size: ${({ absolute }) => (absolute ? '0.5em' : '0.75em')};
  left: ${({ absolute }) => absolute && '1.25em'};
  cursor: pointer;
`;

const EmailMessageContainer = styled.div`
  color: ${lightBlue};
  font-weight: bold;
  text-align: center;
`;

const CategoryRankingCard = ({
  filteredData: data,
  t,
  isDetailsToggled,
  settings,
  projectId,
  filterKeys,
  setSettings,
  hints,
  message,
}) => {
  const [order, setOrder] = useState(settings?.categoryRanking?.sortedBy?.split('_')[0]);
  const [orderDirection, setOrderDirection] = useState(settings?.categoryRanking?.sortedBy?.split('_')[1]);
  const [orderedData, setOrderedData] = useState(data);
  const [isDropdownOpen, toggleDropdownOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const [saveThematicsOverviewSettings] = useMutation(SAVE_THEMATICS_OVERVIEW_SETTINGS_MUTATION);

  useEffect(() => {
    setFilteredData(data);
    if (orderDirection === null) {
      setOrderedData(data);
    } else {
      setOrderedData(
        orderBy(
          data,
          item => {
            const evolutionGaugeIndex = order?.split('.').length - 1;
            const evolutionGauge = order?.split('.')[evolutionGaugeIndex];
            if (evolutionGauge === 'yearBefore' || evolutionGauge === 'periodBefore') {
              const [id, subId, subSubId] = order.split('.');
              return item.thematicsOverviewDetails?.[id]?.[subSubId]?.[subId]?.delta || item.thematicsOverviewDetails?.[id]?.[subId]?.delta || '';
            }
            if (
              (item.thematicsOverviewDetails[order] === null ||
                (order?.split('.').length > 1 &&
                  (item.thematicsOverviewDetails[order.split('.')[0]] === null ||
                    item.thematicsOverviewDetails[order.split('.')[0]]?.[order.split('.')[1]] === null))) &&
              orderDirection === 'desc'
            ) {
              return -21;
            }
            if (order !== null && order?.split('.').length === 3) {
              const [id, subId, subSubId] = order.split('.');
              return item.thematicsOverviewDetails?.[id]?.[subId]?.[subSubId];
            }
            return item.thematicsOverviewDetails[order];
          },
          orderDirection,
        ),
      );
    }
  }, [data]);

  useEffect(() => {
    const [newOrder, newOrderDirection] = settings?.categoryRanking?.sortedBy?.split('_') || [null, null];
    setOrder(newOrder);
    setOrderDirection(newOrderDirection);
  }, [settings?.categoryRanking?.sortedBy]);

  const changeDirection = (prevOrder, currentOrder) => {
    const cloneSettings = cloneDeep(settings);
    if (orderDirection === null || prevOrder !== currentOrder) {
      setOrderDirection('asc');
      cloneSettings.categoryRanking.sortedBy = `${currentOrder}_asc`;
    } else if (orderDirection === 'asc') {
      setOrderDirection('desc');
      cloneSettings.categoryRanking.sortedBy = `${currentOrder}_desc`;
    } else if (orderDirection === 'desc') {
      cloneSettings.categoryRanking.sortedBy = null;
      setOrderDirection(null);
    }
    saveThematicsOverviewSettings({
      variables: { projectId, settings: JSON.stringify(cloneSettings) },
    }).then(() => {
      setSettings(cloneSettings);
    });
  };

  const [availableColumns, setAvailableColumns] = useState(getAvailableColumns(filteredData, t));

  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    setAvailableColumns(getAvailableColumns(filteredData, t));
  }, [settings, filteredData]);

  useEffect(() => {
    setSelectedColumns(getSelectedColumns(settings, availableColumns));
  }, [availableColumns]);
  return (
    <QcardPanel title="Category ranking" hints={hints}>
      {message && <EmailMessageContainer>{message.message}</EmailMessageContainer>}
      <ButtonContainer>
        <Dropdown isOpen={isDropdownOpen} toggle={() => toggleDropdownOpen(!isDropdownOpen)} direction="left">
          <DropdownToggle
            tag="span"
            onClick={() => toggleDropdownOpen(!isDropdownOpen)}
            data-toggle="dropdown"
            aria-expanded={isDropdownOpen}
          >
            <StyledIcon icon="INTERFACE_SHOW" color={grayShades.g600} size={24}/>
            <Badge absolute>{selectedColumns?.length}</Badge>
          </DropdownToggle>
          <StyledDropdownMenu>
            {availableColumns?.map(col => {
              return (
                <CategoryDropdownItem
                  col={col}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                  projectId={projectId}
                  settings={settings}
                  t={t}
                  filterKeys={filterKeys}
                />
              );
            })}
          </StyledDropdownMenu>
        </Dropdown>
      </ButtonContainer>
      <TableContainer>
        <StyledTable borderless>
          <TableHeader
            order={order}
            orderDirection={orderDirection}
            selectedColumns={selectedColumns}
            setOrder={setOrder}
            changeDirection={changeDirection}
            t={t}
            setOrderDirection={setOrderDirection}
          />
          <Body>
            {orderedData?.map(orderedDataItem => (
              <TableRow
                data={orderedDataItem}
                isDetailsToggled={isDetailsToggled}
                setOrder={setOrder}
                setOrderDirection={setOrderDirection}
                selectedColumns={selectedColumns}
                filterKeys={filterKeys}
                t={t}
              />
            ))}
          </Body>
        </StyledTable>
      </TableContainer>
    </QcardPanel>
  );
};

CategoryRankingCard.propTypes = {
  filteredData: PropTypes.any,
  t: PropTypes.func.isRequired,
  isDetailsToggled: PropTypes.bool,
  settings: PropTypes.any,
  projectId: PropTypes.number.isRequired,
  filterKeys: PropTypes.any,
  setSettings: PropTypes.func.isRequired,
  hints: PropTypes.arrayOf(PropTypes.shape),
  message: PropTypes.string,
};

CategoryRankingCard.defaultProps = {
  filteredData: {},
  isDetailsToggled: false,
  settings: {},
  filterKeys: {},
  hints: [{}],
  message: '',
};

export default withTranslation('card', ['button', 'emotion'])(CategoryRankingCard);
