import React, { useState } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import CModal from '_Components/CModal/CModal';
import { QSideBarLayout } from '_Components/Layouts/QSideBarLayout';
import QButton from '_Components/Buttons/QButton/QButton';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import { ImageSize } from 'styles/abstracts/variables';
import Icon from '_Components/Icons/Icon';
import { setLevelToDisplay } from '_Resources/Benchmark/Actions/setLevelToDisplay';
import { setOnlySelectedCategories } from '_Resources/Benchmark/Actions/setOnlySelectedCategories';
import { grayShades } from '../../../../../styles/abstracts/colors';

const StyledCModal = styled(CModal)`
  max-width: 500px !important;
`;

const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  margin: -16px;
`;

const QSideBarLayoutDisplayArea = styled.div`
  flex-grow: 1;
  /* overflow: hidden; */
`;

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  position: absolute;
  right: 1em;
  top: 1em;
  padding: 0 !important;
`;

const SettingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em;
  font-weight: bold;
`;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 13rem;
`;

const LabelContainer = styled.div`
  display: flex;
`;

const StyledSpan = styled.span`
  margin-left: 1em;
  position: relative;
  &:hover > div {
  visibility: visible;
`;
const TooltipThematic = styled.div`
  visibility: hidden;
  max-width: 20em;
  position: absolute;
  bottom: 26px;
  left: -2px;
  z-index: 30;
  background-color: ${grayShades.g200};
  text-align: center;
  padding: 5px 4px;
  border-radius: 8px;
  color: ${grayShades.g800};
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
  width: max-content;
  font-size: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 12px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${grayShades.g200} transparent transparent transparent;
  }
`;

type BenchmarkSettingsModalProps = {
  onClose: any;
  isOpen: boolean;
  t: any;
  dispatchSetLevelToDisplay: any;
  dispatchSetOnlySelectedCategories: any;
  benchmarkLevelToDisplay: string;
  benchmarkOnlySelectedCategories: boolean;
};

const BenchmarkSettingsModal = ({
  onClose,
  isOpen,
  t,
  dispatchSetLevelToDisplay,
  dispatchSetOnlySelectedCategories,
  benchmarkLevelToDisplay,
  benchmarkOnlySelectedCategories,
}: BenchmarkSettingsModalProps) => {
  const [onlySelectedCategories, setLocalOnlySelectedCategories] = useState(
    benchmarkOnlySelectedCategories || false,
  );
  const [isDropdownOpen, openDropdown] = useState<boolean>(false);

  const buttonRight = (
    <StyledQButton
      bgColor="greyShades.g300"
      type="button"
      onClick={() => onClose()}
      size="small"
      icon="CROSS"
      iconSize={24}
    />
  );

  const thematicsToDisplay = [
    {
      id: 'thematics',
      label: 'Categories',
    },
    {
      id: 'subthematics',
      label: 'Subcategories',
    },
    {
      id: 'subsubthematics',
      label: 'Sub sub categories',
    },
  ];

  const [currentItem, setCurrentItem] = useState(
    thematicsToDisplay.find(opt => opt.id === benchmarkLevelToDisplay) ||
      thematicsToDisplay[1],
  );

  const onValidate = () => {
    dispatchSetLevelToDisplay(currentItem.id);
    dispatchSetOnlySelectedCategories(onlySelectedCategories);
  };

  const getFooter = () => {
    return (
      <ModalFooter>
        <QButton onClick={() => onClose()} size="big" bgColor="gray">
          {t('Cancel')}
        </QButton>
        <QButton
          onClick={() => {
            onValidate();
            onClose();
          }}
          size="big"
          bgColor="purple"
        >
          {t('Validate')}
        </QButton>
      </ModalFooter>
    );
  };

  return (
    <>
      <StyledCModal
        data={{}}
        title={t('Settings')}
        displayMentionsSubtitle={false}
        displayCardSubtitle={false}
        displayDateSubtitle={false}
        modal={isOpen}
        footer={getFooter()}
        toggle={onClose}
        buttonRight={buttonRight}
        centered
      >
        <Body>
          <HeaderArea />
          <QSideBarLayout>
            <QSideBarLayoutDisplayArea>
              <SettingDiv>
                <Label>{t('Level to display')}</Label>
                <StyledDropdown
                  direction="down"
                  isOpen={isDropdownOpen}
                  toggle={() => openDropdown(!isDropdownOpen)}
                >
                  <StyledDropdownToggle>
                    <StyledToggleButton disableHover bgColor="grayShades.g300">
                      {t(currentItem.label)}
                      <Icon
                        icon="INTERFACE_CHEVRON_DOWN"
                        size={ImageSize.medium}
                      />
                    </StyledToggleButton>
                  </StyledDropdownToggle>
                  <Menu className="dataByFiltersDropdownMenu">
                    {thematicsToDisplay.map(option => {
                      return (
                        <Item
                          disabled={currentItem.id === option.id}
                          onClick={() => {
                            setCurrentItem(option);
                          }}
                        >
                          <span>{t(option.label)}</span>
                        </Item>
                      );
                    })}
                  </Menu>
                </StyledDropdown>
              </SettingDiv>
              <SettingDiv>
                <LabelContainer>
                  <Label>{t('Selected categories')}</Label>
                  <StyledSpan role="presentation">
                    <Icon
                      icon="INTERFACE_INFO"
                      size={20}
                      color={grayShades.g400}
                    />
                    <TooltipThematic
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'benchmark:Only selected categories switch hint',
                        ),
                      }}
                    />
                  </StyledSpan>
                </LabelContainer>
                <QBooleanSwitch
                  onChanged={() => {
                    setLocalOnlySelectedCategories(!onlySelectedCategories);
                  }}
                  value={onlySelectedCategories}
                />
              </SettingDiv>
            </QSideBarLayoutDisplayArea>
          </QSideBarLayout>
        </Body>
      </StyledCModal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  benchmarkLevelToDisplay: get(state, ['benchmark', 'levelToDisplay']),
  benchmarkOnlySelectedCategories: get(state, [
    'benchmark',
    'onlySelectedCategories',
  ]),
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchSetLevelToDisplay: (newState: any) => {
    dispatch(setLevelToDisplay(newState));
  },
  dispatchSetOnlySelectedCategories: (newState: any) => {
    dispatch(setOnlySelectedCategories(newState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(['button', 'benchmark'])(BenchmarkSettingsModal));
