import { SELECT_VERBATIM } from '../Actions/selectVerbatim';

const initialState = {
  selectedVerbatim: {
    id: 0,
    uuid: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_VERBATIM: {
      return {
        ...state,
        selectedVerbatim: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
