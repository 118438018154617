import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { connect } from 'react-redux';

import QTitle from '../../../../../../_Components/Texts/QTitle/QTitle';
import QInput from '../../../../../../_Components/Fields/QInput/QInput';
import QFieldGroup from '../../../../../../_Components/Panels/QFieldGroup/QFieldGroup';
import QCheckbox from '../../../../../../_Components/Fields/QCheckbox/QCheckbox';
import QDropzone from '../../../../../../_Components/Panels/QDropzone/QDropzone';
import QDropzoneBox from '../../../../../../_Components/Panels/QDropzone/QDropzoneBox';
import { toggleOtherInfos } from '../../../../../../_Resources/Reporting/Actions/CoverSettings/toggleOtherInfos';
import {
  updateSubtitle,
  updateTitle,
} from '../../../../../../_Resources/Reporting/Actions/CoverSettings/updateCopy';
import { TEXT_TYPES } from '../../../../../../_Components/Texts/textTypes';
import { TEXT_POSITIONS } from '../../../../../../_Components/Texts/textPositions';
import { updateLogo } from '../../../../../../_Resources/Reporting/Actions/CoverSettings/updateLogo';

const Cart = styled.div`
  padding: 1.5em;
  background-color: white;
`;

const StyledQCheckbox = styled(QCheckbox)`
  margin: 0 0.5em 0 0;
`;

class CoverSettings extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    otherInfoCheckboxes: PropTypes.shape({
      isDateRange: PropTypes.bool.isRequired,
      isFilters: PropTypes.bool.isRequired,
      isThematics: PropTypes.bool.isRequired,
    }).isRequired,

    dispatchToggleOtherInfos: PropTypes.func.isRequired,
    dispatchUpdateTitle: PropTypes.func.isRequired,
    dispatchUpdateSubtitle: PropTypes.func.isRequired,
    dispatchUpdateLogo: PropTypes.func.isRequired,
  };

  checkboxes = [
    {
      id: 'isDateRange',
      label: 'Date range',
    },
    {
      id: 'isFilters',
      label: 'Filters',
    },
    {
      id: 'isThematics',
      label: 'Thematics',
    },
  ];

  isCheckboxSelected(id) {
    const { otherInfoCheckboxes } = this.props;
    return otherInfoCheckboxes && otherInfoCheckboxes[id];
  }

  toggleCheckbox = id => {
    const { dispatchToggleOtherInfos } = this.props;
    dispatchToggleOtherInfos(id);
  };

  onTitleChange = ({ target: { value } }) => {
    const { dispatchUpdateTitle } = this.props;
    dispatchUpdateTitle(value);
  };

  onSubtitleChange = ({ target: { value } }) => {
    const { dispatchUpdateSubtitle } = this.props;
    dispatchUpdateSubtitle(value);
  };

  onDrop = acceptedFiles => {
    const reader = new FileReader();
    acceptedFiles.forEach(file => {
      reader.readAsDataURL(file);
      reader.onload = ({ target: { result } }) => {
        this.props.dispatchUpdateLogo(result);
      };
    });
  };

  createCheckboxes() {
    const { t } = this.props;
    return this.checkboxes.map(({ id, label }) => (
      <QFieldGroup.CheckboxControl key={id}>
        <StyledQCheckbox
          selected={this.isCheckboxSelected(id)}
          onClick={() => this.toggleCheckbox(id)}
        />
        <QCheckbox.Label>{t(label)}</QCheckbox.Label>
      </QFieldGroup.CheckboxControl>
    ));
  }

  render() {
    const { t, title, subtitle, logo } = this.props;
    return (
      <Cart>
        <QFieldGroup>
          <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.LEFT}>
            <QTitle.Title>{t('Title & subtitle')}</QTitle.Title>
          </QTitle>
          <QFieldGroup>
            <QFieldGroup.Label>{t('Title')}</QFieldGroup.Label>
            <QFieldGroup.Control>
              <QInput value={t(title)} onChange={this.onTitleChange} />
            </QFieldGroup.Control>
          </QFieldGroup>

          <QFieldGroup>
            <QFieldGroup.Label>{t('Subtitle')}</QFieldGroup.Label>
            <QFieldGroup.Control>
              <QInput value={t(subtitle)} onChange={this.onSubtitleChange} />
            </QFieldGroup.Control>
          </QFieldGroup>
        </QFieldGroup>

        <QFieldGroup>
          <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.LEFT}>
            <QTitle.Title>{t('Logo')}</QTitle.Title>
          </QTitle>
          <QFieldGroup.Control>
            <QDropzone
              onDrop={this.onDrop}
              acceptTypes="image/bmp, image/jpeg, image/png"
              render={args => <QDropzoneBox previewImg={logo} {...args} />}
            />
          </QFieldGroup.Control>
        </QFieldGroup>

        <QFieldGroup>
          <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.LEFT}>
            <QTitle.Title>{t('Other informations')}</QTitle.Title>
          </QTitle>

          <QFieldGroup>{this.createCheckboxes()}</QFieldGroup>
        </QFieldGroup>
      </Cart>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchToggleOtherInfos: (...args) => dispatch(toggleOtherInfos(...args)),
  dispatchUpdateTitle: (...args) => dispatch(updateTitle(...args)),
  dispatchUpdateLogo: (...args) => dispatch(updateLogo(...args)),
  dispatchUpdateSubtitle: (...args) => dispatch(updateSubtitle(...args)),
});

const mapStateToProps = ({ reporting: { title, subtitle, logo, header } }) => ({
  otherInfoCheckboxes: header,
  title,
  subtitle,
  logo,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(CoverSettings));
