import gql from 'graphql-tag';

const query = gql`
  query getHeaderDatesFiltersData($projectId: Int!) {
    data: getHeaderDatesFiltersData(projectId: $projectId) {
      byAll {
        name
        period {
          startDate
          endDate
        }
      }
      byMonth {
        name
        period {
          startDate
          endDate
        }
      }
      byQuarter {
        name
        period {
          startDate
          endDate
        }
      }
      byYear {
        name
        period {
          startDate
          endDate
        }
      }
      byLastBatch {
        name
        period {
          startDate
          endDate
        }
      }
    }
  }
`;

export default query;
