import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { find } from 'lodash';
import { DropdownItem } from 'reactstrap';
import { Mutation } from 'react-apollo';
import getBenchmarkList from '_Resources/Benchmark/Services/graphql/getBenchmarkList';
import Icon from '_Components/Icons/Icon';
import mutationDuplicateBenchmark from './graphql/duplicateBenchmark';
import { grayShades, opacity, black } from '../../../styles/abstracts/colors';
import { ImageSize, spacing } from '../../../styles/abstracts/variables';
import { fontSizes } from '../../../styles/abstracts/typography';

const OptionsItem = styled(DropdownItem)`
  font-weight: bold !important;
  color: ${props => (props.disabled ? opacity.purple : black)} !important;
  padding: ${spacing.medium} !important;
  cursor: ${props => props.disabled && 'default'} !important;
  font-size: ${fontSizes.smaller};
`;

class MutationDuplicateBenchmark extends React.Component {
  render() {
    const { t, projectBenchmarks } = this.props;
    return (
      <Mutation
        mutation={mutationDuplicateBenchmark}
        update={(cache, { data: { duplicateBenchmark } }) => {
          const projectId = this.props.currentProject.id;
          const benchmarkList = cache.readQuery({
            query: getBenchmarkList,
            variables: { projectId },
          }).getBenchmarkList.projectBenchmarks;
          cache.writeQuery({
            query: getBenchmarkList,
            variables: { projectId },
            data: {
              getBenchmarkList: {
                projectBenchmarks: benchmarkList.concat({
                  ...duplicateBenchmark,
                  isDefault: false,
                  settings: find(
                    projectBenchmarks,
                    project => project.id === this.props.benchmarkId,
                  ).settings,
                }),
              },
            },
          });
        }}
      >
        {duplicateBenchmark => (
          <OptionsItem
            id="duplicateBenchmarkItem"
            disabled={!this.props.canCreateBenchmark}
            onClick={() =>
              duplicateBenchmark({
                variables: { id: this.props.benchmarkId },
              })
            }
          >
            <Icon
              icon="DUPLICATE"
              color={
                !this.props.canCreateBenchmark
                  ? grayShades.g300
                  : grayShades.g800
              }
              size={ImageSize.medium}
            />
            &nbsp; {t('Duplicate')}
          </OptionsItem>
        )}
      </Mutation>
    );
  }
}

const mapStateToProps = state => ({
  currentProject: state.projects.currentProject,
  projectBenchmarks: state.benchmark.projectBenchmarks,
  canCreateBenchmark: state.auth.user.account.canCreateBenchmark,
});

export default connect(mapStateToProps)(
  withTranslation('benchmark')(MutationDuplicateBenchmark),
);
