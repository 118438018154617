import gql from 'graphql-tag';

const mutation = gql`
  mutation createReviewshakeProject($projectSettings: Json!) {
    createReviewshakeProject(projectSettings: $projectSettings) {
      status
      projectId
      projectName
      state
      errors
    }
  }
`;

export default mutation;
