import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { get, cloneDeep } from 'lodash';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import { DISPLAY_TYPE } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/cardsConfig';
import BenchmarkAddCard from 'Routes/Benchmark/BenchmarkBoard/_Components/BenchmarkAddCard/BenchmarkAddCard';
import QueryBenchmarkList from '_Resources/Benchmark/Services/QueryBenchmarkList';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { getRoute } from '_Utils/routes/routeUtils';
import AdditionalHeader from './_Components/AdditionalHeader/AdditionalHeader';
import BenchmarkGroupCard from './_Components/BenchmarkGroupCard/BenchmarkGroupCard';
import mock from './_Components/configs/mockData';

const BenchmarkBody = styled.div`
  position: relative;
  padding: 1rem 2rem;
`;

const GlobalBenchmarkStyle = createGlobalStyle`
  body {
    overflow-x: auto !important;
  }

  html {
    overflow-x: hidden !important;
  }
`;

class BenchmarkV2 extends React.Component {
  static propTypes = {
    updateRoute: PropTypes.func.isRequired,
    activeBenchmark: PropTypes.objectOf(PropTypes.any).isRequired,
    t: PropTypes.func.isRequired,
    currentProject: PropTypes.objectOf(PropTypes.string, PropTypes.number),
  };

  constructor(props) {
    super(props);
    this.state = {
      idpage: 'benchmark',
    };
  }

  componentDidMount() {
    getRoute(this.props.updateRoute, this.state.idpage);
  }

  render() {
    const { activeBenchmark } = this.props;
    const columns = get(activeBenchmark, ['settings', 'columns']);
    const cards = get(activeBenchmark, ['settings', 'cards']) || [];
    const cardsToShow = cloneDeep(mock);
    cards.forEach(card => {
      const cardFedWithFilters = {
        title: card.title,
        type: 'eindex',
        hint: HintsContent.HINT_EINDEX_BADGE,
        displayType: DISPLAY_TYPE.SPLIT,
        editableLayout: true,
        settings: null,
      };
      cardFedWithFilters.settings = card.settings;
      cardsToShow.push(cardFedWithFilters);
    });
    return (
      <div>
        <GlobalBenchmarkStyle />
        {this.props.currentProject && <QueryBenchmarkList />}
        <Container fluid className="p-0" style={{ overflowX: 'scroll' }}>
          {activeBenchmark && <AdditionalHeader colsToShow={columns} />}
          <BenchmarkBody>
            <Row>
              <Col>
                {columns &&
                  columns.length > 0 &&
                  cardsToShow.map((card, index) => {
                    return (
                      <BenchmarkGroupCard
                        columns={columns}
                        {...card}
                        cardIndex={index - mock.length}
                      />
                    );
                  })}
              </Col>
            </Row>
            {columns && <BenchmarkAddCard columns={columns} />}
          </BenchmarkBody>
        </Container>
      </div>
    );
  }
}

BenchmarkV2.defaultProps = {
  currentProject: false,
};

function mapStateToProps(state) {
  return {
    module: state.routes.route && state.routes.route.name,
    activeBenchmark: get(state, ['benchmark', 'activeBenchmark']),
    currentProject: state.projects.currentProject,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark')(BenchmarkV2));
