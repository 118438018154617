import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { getProjectConfiguration } from '_Resources/Header/Actions/getProjectConfiguration';
import { DispatchDataToStore } from '_Resources/Header/Services/api/DispatchDataToStore';
import querySchema from './graphql/getProjectConfigurationData';

class QueryConfigHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Query
          fetchPolicy="cache-and-network"
          query={querySchema}
          variables={{
            projectId:
              (this.props.currentProject && this.props.currentProject.id) || 0,
          }}
          // fetchPolicy="network-only"
        >
          {({ loading, error, data, networkStatus }) => {
            if (loading || networkStatus === 4) return false;
            if (error) {
              return false;
            }
            return (
              <DispatchDataToStore
                data={data}
                action={() => this.props.getProjectConfiguration(data)}
              />
            );
          }}
        </Query>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectConfiguration: configuration => {
      dispatch(getProjectConfiguration(configuration));
    },
  };
}

function mapStateToProps(state) {
  return {
    currentProject: state.projects.currentProject,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryConfigHeader);
