const wrapWithIndicatorObject = indicators =>
  Object.keys(indicators).reduce((wrapped, indicatorName) => {
    const [firstLetter, ...rest] = indicatorName;
    const name = [firstLetter.toUpperCase(), ...rest].join('');
    return {
      ...wrapped,
      [indicatorName]: {
        value: indicators[indicatorName],
        name,
      },
    };
  }, {});

const flatEntryPoint = entryPoint => {
  const { emotions, ...rest } = entryPoint;
  return {
    ...wrapWithIndicatorObject(rest),
    ...wrapWithIndicatorObject(emotions),
  };
};

export default insight => {
  const { entryPoints } = insight;
  const {
    entryPoints: { current, previousPeriod, previousYear },
  } = insight;
  return {
    ...insight,
    entryPoints: {
      ...entryPoints,
      current: flatEntryPoint(current),
      previousPeriod: flatEntryPoint(previousPeriod),
      previousYear: flatEntryPoint(previousYear),
    },
  };
};
