import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { themeColors, grayShades } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import Button from './SaveButton';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid ${grayShades.g300};
`;

const LastSaved = styled.span`
  color: ${themeColors.secondary};
  font-weight: bold;
  font-size: ${fontSizes.smaller};
  white-space: nowrap;
`;

const SAVE_INTERVAL = 30000;

export const SAVE_BTN_MODES = {
  WAITING: 'waiting',
  AFTER_SAVE: 'after-save',
  DEFAULT: 'default',
};

class SavePageButtonBench extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    delay: PropTypes.number,
  };

  state = {
    btnMode: SAVE_BTN_MODES.DEFAULT,
    lastUpdate: null,
  };

  saveInterval = null;
  afterSaveTimeout = null;
  savingTimeout = null;

  // componentWillUnmount() {
  //   clearInterval(this.saveInterval);
  //   clearTimeout(this.afterSaveTimeout);
  //   clearTimeout(this.savingTimeout);
  // }

  delaySavingDisplay = data => {
    setTimeout(() => {
      this.setState({ btnMode: SAVE_BTN_MODES.AFTER_SAVE });
      this.afterSaveTimeout = setTimeout(
        () => this.setState({ btnMode: SAVE_BTN_MODES.DEFAULT }),
        2000,
      );
    }, 500);
    this.updateEditionDate(data);
  };

  updateEditionDate(data) {
    if (data) {
      this.setState({
        lastUpdate: moment(Date.now())
          .locale(this.props.i18n.language)
          .format('LL LTS'),
      });
    }
  }

  runSave(runMutation) {
    clearTimeout(this.afterSaveTimeout);
    clearTimeout(this.savingTimeout);
    this.setState({ btnMode: SAVE_BTN_MODES.WAITING }, () => runMutation());
  }

  runMutation = (addReport, variables) => () => addReport({ variables });

  registerIntervalSave(addReport) {
    if (this.saveInterval) return;

    this.saveInterval = setInterval(() => {
      const { variables } = this.props;
      this.runSave(() => addReport({ variables }));
    }, this.props.delay);
  }

  render() {
    const { t, query, variables, updateFunction } = this.props;
    const { btnMode, lastUpdate } = this.state;
    return (
      <Wrapper>
        {this.props.idpage !== 'benchmark' && lastUpdate && (
          <LastSaved>
            {t('Last update')} : <br /> {lastUpdate}
          </LastSaved>
        )}
        <Mutation
          mutation={query}
          onCompleted={this.delaySavingDisplay}
          update={
            updateFunction
              ? (cache, data) => updateFunction(cache, data)
              : console.log('no updateFunction')
          }
        >
          {(saveSettings, { loading }) => {
            this.registerIntervalSave(saveSettings);

            return (
              <Button
                onSave={() =>
                  this.runSave(this.runMutation(saveSettings, variables))
                }
                onUnmount={this.runMutation(saveSettings, variables)}
                mode={loading ? SAVE_BTN_MODES.WAITING : btnMode}
                idpage={this.props.idpage}
                lastUpdate={lastUpdate}
              />
            );
          }}
        </Mutation>
      </Wrapper>
    );
  }
}

SavePageButtonBench.defaultProps = {
  delay: SAVE_INTERVAL,
};

const mapStateToProps = state => ({
  currentProject: state.projects.currentProject,
  idpage: _.get(state, ['header', 'subRoute', 'id']),
  activeBenchmark: _.get(state, ['benchmark', 'activeBenchmark']),
});

export default connect(mapStateToProps)(
  withTranslation('reporting')(SavePageButtonBench),
);
