/* eslint-disable import/prefer-default-export */
import { capitalize } from 'lodash';

export const INDICATORS = {
  EINDEX: 'eindex',
  MENTIONS: 'mentions',
  SATISFACTION: 'sat',
  RECOMMENDATION: 'reco',
  HAPPINESS: 'happiness',
  SADNESS: 'sadness',
  CALM: 'calm',
  SURPRISE: 'surprise',
  FEAR: 'fear',
  ANGER: 'anger',
  DISGUST: 'disgust',
  CES: 'ces',
};

const defaultLabels = Object.keys(INDICATORS).reduce(
  (labels, currentIndicator) => {
    const value = INDICATORS[currentIndicator];
    return {
      ...labels,
      [value]: capitalize(value),
    };
  },
  {},
);

export const INDICATOR_LABELS = {
  ...defaultLabels,
  [INDICATORS.EINDEX]: 'E-Index',
  [INDICATORS.SATISFACTION]: 'Satisfaction',
  [INDICATORS.RECOMMENDATION]: 'Recommendation',
  [INDICATORS.CES]: 'CES',
};
