import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import {
  black,
  darkerPurple,
  grayShades,
  purple,
} from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import { availableLanguages } from 'Routes/Settings/ProjectConfiguration/utils';
import Icon from '_Components/Icons/Icon';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  margin-top: 0.5em;
  margin-left: 1.5em;
  width: 100%;
`;

const SelectInput = styled(Select)`
  color: ${darkerPurple} !important;
  margin-left: 0.5em;
  width: 15%;
  & span {
    color: ${black} !important;
    font-weight: bold;
  }
  & div,
  & div:active {
    border-radius: 20px !important;
    background: ${grayShades.g300};
    border: none;
    font-size: ${fontSizes.default};
    color: ${purple} !important;
  }
`;

const LanguageSettings = ({ lang, setLang, t }) => {
  const currentLanguage =
    availableLanguages.find(language => language.value === lang) ||
    availableLanguages.find(language => language.value === 'en');
  return (
    <>
      <QTitle>{t('Display language')}</QTitle>
      <Container>
        {t('Choose the display language of your verbatim')}:
        <SelectInput
          name="languageSelect"
          value={currentLanguage}
          searchable={false}
          clearable={false}
          onChange={({ value }) => setLang(value)}
          options={availableLanguages}
        />
      </Container>
    </>
  );
};

export default LanguageSettings;
