import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { preparePropertyComparer } from "../core/utils/commonHelpers";
import { convertModesToOptions } from "./utils/modesHelpers";

import QMapPanel from "../components/QMapPanel";
import QPanelHeader from "../components/QPanelHeader";
import QSelect from "../components/QSelect";

export default class MonitorBy extends PureComponent {
  state = {
    options: []
  };

  isPropChanged = preparePropertyComparer(this);

  componentDidMount() {
    this.prepareOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.isPropChanged("modes", prevProps)) {
      this.prepareOptions();
    }
  }

  prepareOptions() {
    const { modes } = this.props;
    const options = convertModesToOptions(modes);
    this.setState({ options });
  }

  render() {
    return (
      <QMapPanel className={this.props.className}>
        <QPanelHeader contentHeader>Monitor By</QPanelHeader>
        <QSelect options={this.state.options} onChange={this.props.onChange} />
      </QMapPanel>
    );
  }
}

MonitorBy.propTypes = {
  modes: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      mode: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string // TODO: check whether it has to be a string
};
