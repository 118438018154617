import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '_Components/Icons/Icon';
import styled from 'styled-components';
import { tagAreaStyles, tagStyles } from './styles';
import { input } from '../styles';

const Body = styled.div`
  display: flex;
  align-items: center;

  border: ${tagStyles.border};
  border-radius: ${tagAreaStyles.radius};

  background-color: ${tagAreaStyles['background-color']};

  padding: ${tagStyles.padding};
  margin: ${tagStyles.margin};
`;

const Input = styled.input`
  flex-grow: 1;
  outline-width: 0;
  border: none;

  color: ${input.color};
  background-color: ${tagAreaStyles['background-color']};
  width: 100%;
`;
const PlusContainer = styled.div`
  cursor: pointer;
`;

class TagInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    onTagAdd: PropTypes.func.isRequired,
    onTagInput: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  addTag() {
    const { onTagAdd } = this.props;
    onTagAdd();
  }

  onInput = event => {
    const {
      target: { value },
    } = event;
    const { onTagInput } = this.props;

    onTagInput(value);
  };

  onInputKeyPressed = event => {
    const { key } = event;
    if (key === 'Enter') {
      this.addTag();
    }
  };

  onPlusIconClicked = () => {
    this.addTag();
  };

  render() {
    const { className, value } = this.props;

    return (
      <Body className={className}>
        <Input
          onKeyPress={this.onInputKeyPressed}
          onInput={this.onInput}
          value={value}
        />
        <PlusContainer onClick={this.onPlusIconClicked}>
          <Icon icon="PLUS" size={24} color={input.color} />
        </PlusContainer>
      </Body>
    );
  }
}

export default TagInput;
