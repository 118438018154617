import React from "react";
import { Container } from 'reactstrap';
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "styled-components";

const StyledMarkdownContainer = styled.div`
  background-color: #FFFFFF;
  border-radius: 0.5em;
  padding: 1rem 1.5rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0,0,0,0.1);

  /* Light theme. */
  :root {
    --color-canvas-default: #ffffff;
    --color-canvas-subtle: #f6f8fa;
    --color-border-default: #d0d7de;
    --color-border-muted: hsla(210, 18%, 87%, 1);
  }

  /* Dark theme. */
  @media (prefers-color-scheme: dark) {
    :root {
      --color-canvas-default: #0d1117;
      --color-canvas-subtle: #161b22;
      --color-border-default: #30363d;
      --color-border-muted: #21262d;
    }
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    display: block;
    margin-top: 0;
    margin-bottom: 16px;
    width: max-content;
    max-width: 100%;
    overflow: auto;
  }

  tr {
    background-color: var(--color-canvas-default);
    border-top: 1px solid var(--color-border-muted);
  }

  tr:nth-child(2n) {
    background-color: var(--color-canvas-subtle);
  }

  td,
  th {
    padding: 6px 13px;
    border: 1px solid var(--color-border-default);
  }

  th {
    font-weight: 600;
  }

  table img {
    background-color: transparent;
  }
`;

const ContentMessage = ({mdContent}) => {
  return (
    <Container fluid className="py-3 px-4">
      <StyledMarkdownContainer>
        <Markdown remarkPlugins={[[remarkGfm, {singleTilde: false, stringLength: 100000}]]}>{mdContent}</Markdown>
      </StyledMarkdownContainer>
    </Container>
  )
};

export default ContentMessage;
