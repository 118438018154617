import React from 'react';
import Layout from '_Layouts/Layout';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import ReportingPage from './Reporting';

const title = 'Reporting';
const body = <ReportingPage />;

const action = props => {
  return (
    <QLoginRedirect>
      <Layout
        body={body}
        subHeader={null}
        hideSubHeader
        title={title}
        {...props}
      />
    </QLoginRedirect>
  );
};

export default action;
