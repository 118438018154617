import html2canvas from 'html2canvas';
import moment from 'moment';
import { get } from 'lodash';
import { prepareGeneratePdfLayout } from './generatePdfLayout';
import { themeColors } from '../../../../../../../../styles/abstracts/colors';
import { getSectionId } from '../../../../../../../../_Components/Layouts/QLayout/sectionId';
import mergeDeep from '_Utils/other/mergeDeep';
import { multiplyReport } from '../../../../helpers/iterateReport';
import { REPORTING_MODES } from '../../../../../../../../_Resources/Reporting/Actions/updateReportingMode';

const ROW_FONT_RATIO = 1.15;
const DEFAULT_LINE_HEIGHT = 1.15;
const HEADER_LINE_HEIGHT = 1.3;
const splitRegex = /\r\n|\r|\n/g;

const jsPdf = typeof document !== 'undefined' && require('jspdf');

if (jsPdf) {
  // https://stackoverflow.com/questions/28327510/align-text-right-using-jspdf/28433113
  jsPdf.API.textEx = function(text, x, y, hAlign, vAlign) {
    const fontSize = this.internal.getFontSize() / this.internal.scaleFactor;

    // As defined in jsPDF source code
    const lineHeightProportion = ROW_FONT_RATIO;

    let splittedText = null;
    let lineCount = 1;
    if (
      vAlign === 'middle' ||
      vAlign === 'bottom' ||
      hAlign === 'center' ||
      hAlign === 'right'
    ) {
      splittedText = typeof text === 'string' ? text.split(splitRegex) : text;

      lineCount = splittedText.length || 1;
    }

    // Align the top
    y += fontSize * (2 - lineHeightProportion);

    if (vAlign === 'middle') y -= (lineCount / 2) * fontSize;
    else if (vAlign === 'bottom') y -= lineCount * fontSize;

    if (hAlign === 'center' || hAlign === 'right') {
      let alignSize = fontSize;
      if (hAlign === 'center') alignSize *= 0.5;

      if (lineCount > 1) {
        for (let iLine = 0; iLine < splittedText.length; iLine++) {
          this.text(
            splittedText[iLine],
            x - this.getStringUnitWidth(splittedText[iLine]) * alignSize,
            y,
          );
          y += fontSize;
        }
        return this;
      }
      x -= this.getStringUnitWidth(text) * alignSize;
    }

    this.text(text, x, y);
    return this;
  };
}

const SCALE_FACTOR = 2.834645669291339;
const WIDTH = 297;
const HEIGHT = 210;
const Y_MARGIN = 7.095;
const X_MARGIN = 7.055;
const COL_MARGIN = 2.15;
const PAGE_HEAD = {
  M_TOP: Y_MARGIN,
  OFFSET_H: Y_MARGIN + 20,
};
const PAGE_BODY = {
  M_TOP: Y_MARGIN,
  M_BOTTOM: Y_MARGIN + 50,
  H: 103.5,
};
const SECTION = {
  H: 150,
  M_BOTTOM: Y_MARGIN + 20,
};
const PAGE_FOOTER = {
  OFFSET_H: Y_MARGIN + 8.16,
};
const LAYOUT_CONFIG = {
  startX: X_MARGIN - COL_MARGIN,
  endX: WIDTH - X_MARGIN + COL_MARGIN,
  startY: PAGE_BODY.M_TOP + PAGE_HEAD.OFFSET_H - COL_MARGIN,
  endY: HEIGHT - PAGE_FOOTER.OFFSET_H - SECTION.M_BOTTOM + COL_MARGIN,
  colMargin: COL_MARGIN,
};

const getResizedFontSize = fontSize => fontSize;
const getRowFontSize = fontSize =>
  (fontSize / SCALE_FACTOR) * DEFAULT_LINE_HEIGHT;
const getBaseLineSize = fontSize =>
  HEADER_LINE_HEIGHT * getRowFontSize(fontSize);

const generateLayout = prepareGeneratePdfLayout(LAYOUT_CONFIG);

const TEXT_CONFIGS = {
  [REPORTING_MODES.GLOBAL]: {
    TITLE: {
      PRIMARY: {
        color: themeColors.primary,
        fontSize: getResizedFontSize(32),
        options: { align: 'center' },
      },
      SECONDARY: {
        color: themeColors.primary,
        fontSize: getResizedFontSize(20),
        options: { align: 'center' },
      },
    },
    SUBTITLE: {
      color: themeColors.secondary,
      fontSize: getResizedFontSize(16),
      options: { align: 'center' },
    },
  },
  [REPORTING_MODES.PAGE]: {
    TITLE: {
      color: themeColors.primary,
      fontSize: getResizedFontSize(18),
      options: { align: 'center' },
    },
    SUBTITLE: {
      color: themeColors.secondary,
      fontSize: getResizedFontSize(12),
      options: { align: 'center' },
    },
  },
  [REPORTING_MODES.CARD]: {
    TITLE: {
      color: themeColors.primary,
      fontSize: getResizedFontSize(12),
      options: { align: 'center' },
    },
    SUBTITLE: {
      color: themeColors.secondary,
      fontSize: getResizedFontSize(10),
      options: { align: 'center' },
    },
  },
  FOOTER_LEFT: {
    color: themeColors.secondary,
    fontSize: getResizedFontSize(16),
    options: { align: 'left' },
  },
  FOOTER_RIGHT: {
    color: themeColors.secondary,
    fontSize: getResizedFontSize(16),
    options: { align: 'right' },
  },
};

const reset = pdf => {
  pdf.setTextColor(themeColors.primary);
  pdf.setFontSize(16);
};

const splitStr = (str, maxLines = 1, limit = 100) => {
  if (maxLines === 0) {
    return [];
  }
  if (str.length < limit) {
    return [str.trim()];
  }
  const lineChars = str.substring(0, limit);
  const lastSpace = lineChars.lastIndexOf(' ');
  const lineWords = lineChars.substring(0, lastSpace);
  return [
    lineWords.trim(),
    ...splitStr(str.substring(lastSpace + 1), maxLines - 1, limit),
  ];
};

const prepareApplyText = pdf => (config, x, y, text, vAlign = 'top') => {
  pdf.setTextColor(config.color);
  pdf.setFontSize(config.fontSize);
  pdf.textEx(text, x, y, config.options.align, vAlign);
  return reset(pdf);
};

const getTextConfig = (...args) => get(TEXT_CONFIGS, args);

const prepareAddHeader = (generateSubtitles, applyText) => (
  x,
  y,
  sectionOrPage,
  mode = REPORTING_MODES.PAGE,
  limitChars = 90,
  limitLines = 4,
) => {
  const { title } = sectionOrPage;
  if (title) {
    applyText(getTextConfig(mode, 'TITLE'), x, y, title);
  }
  const subtitles = generateSubtitles(sectionOrPage);
  const titleBaseLine = title
    ? getBaseLineSize(TEXT_CONFIGS[mode].TITLE.fontSize)
    : 0;

  const subtitleConfig = getTextConfig(mode, 'SUBTITLE');
  let maxLines = limitLines;
  const results = subtitles.map(text => {
    const splittedText = splitStr(text, maxLines, limitChars);
    maxLines -= splittedText.length;
    return {
      lines: splittedText.length,
      text: splittedText.join('\n'),
    };
  });
  results.forEach(({ text }, index, array) => {
    const additionalPrevLines = array
      .slice(0, index)
      .reduce((prevVal, { lines }) => prevVal + lines - 1, 0);
    applyText(
      subtitleConfig,
      x,
      y +
        titleBaseLine +
        index * getBaseLineSize(subtitleConfig.fontSize) +
        additionalPrevLines * getRowFontSize(subtitleConfig.fontSize),
      text,
    );
  });
};
const prepareAddFooter = applyText => (
  pageSettings,
  reportSettings,
  pagination,
) => {
  const {
    footer: {
      isFooter,
      isReportTitle,
      customizedText,
      isEditionDate,
      isPagination,
    },
  } = pageSettings;

  const { title: reportTitle } = reportSettings;

  if (!isFooter) return;
  let leftFooter = '';
  let rightFooter = '';
  if (isReportTitle) {
    leftFooter += reportTitle;
  }
  if (customizedText) {
    leftFooter += ` • ${splitStr(customizedText, 1, 60)}`;
  }
  if (isEditionDate) {
    leftFooter += ` • Edited on: ${moment().format('YYYY-MM-DD')}`;
  }
  if (isPagination) {
    rightFooter += pagination;
  }
  applyText(
    getTextConfig('FOOTER_LEFT'),
    X_MARGIN,
    HEIGHT - Y_MARGIN,
    leftFooter,
    'bottom',
  );
  applyText(
    getTextConfig('FOOTER_RIGHT'),
    WIDTH - X_MARGIN,
    HEIGHT - Y_MARGIN,
    rightFooter,
    'bottom',
  );
};

const prepareGenerateHeader = (section, addHeader) => {
  const {
    content,
    borders: { bordersX, bordersY },
  } = section;
  if (content) {
    const textX = bordersX.start + (bordersX.end - bordersX.start) / 2;
    const textY = bordersY.start;
    addHeader(
      textX,
      textY,
      section,
      REPORTING_MODES.CARD,
      Math.floor((bordersX.end - bordersX.start) / 2.5),
      Math.floor((bordersY.end - bordersY.start) / 7),
    );
  }
};

const prepareGenerateCharts = (
  updatedPdf,
  pageIndex,
  addHeader,
) => mappedLayout => {
  const sectionsPromises = mappedLayout.map(section => {
    const {
      borders: { bordersX, bordersY },
      name,
    } = section;
    prepareGenerateHeader(section, addHeader);

    const elementId = getSectionId(pageIndex, name);
    const element = document.getElementById(elementId);
    if (element) {
      return html2canvas(element, {
        scale: 4,
        backgroundColor: 'transparent',
      }).then(canvas => {
        const img = canvas.toDataURL('image/png', 1.0);
        const startX = bordersX.start;
        const width = bordersX.end - startX;
        const height = (canvas.height / canvas.width) * width;
        const startY = bordersY.end - height;
        try {
          updatedPdf.addImage(img, 'png', startX, startY, width, height);
        } catch (e) {
          // error
        }
      });
    }
    return Promise.resolve();
  });
  return Promise.all(sectionsPromises);
};

const generateCoverHeader = pdf => {
  const logoEl = document.getElementById('reportCoverHeaderLogo');
  if (logoEl) {
    const logoWidth = logoEl.offsetWidth / 4;
    pdf.addImage(
      logoEl,
      'png',
      WIDTH / 2 - logoWidth / 2,
      15,
      logoWidth,
      logoEl.offsetHeight / 4,
    );
  }
};

const generateCoverBody = (
  generateSubtitles,
  globalSettings,
  reportSettings,
  pdf,
) => {
  const applyText = prepareApplyText(pdf);
  const x = WIDTH / 2;
  const y = 85;
  const primaryTitleConfig = getTextConfig(
    REPORTING_MODES.GLOBAL,
    'TITLE',
    'PRIMARY',
  );
  applyText(primaryTitleConfig, x, y, reportSettings.title);
  const secTitleConfig = getTextConfig(
    REPORTING_MODES.GLOBAL,
    'TITLE',
    'SECONDARY',
  );
  const secTitleY = y + Y_MARGIN + getBaseLineSize(primaryTitleConfig.fontSize);
  applyText(secTitleConfig, x, secTitleY, reportSettings.subtitle);
};

const generateCoverFooter = (coverFooterLabel, pdf) => {
  const footerLogoEl = document.getElementById('reportCoverFooterLogo');
  if (footerLogoEl) {
    pdf.setFontSize(12.8);
    pdf.setTextColor(themeColors.secondary);
    pdf.text(coverFooterLabel, 246, 191, { align: 'right' });
    pdf.addImage(footerLogoEl, 'png', 249, 185, 30, 6.8625);
  }
};

export const pdfTransformation = async (
  generateSubtitles,
  coverFooterLabel,
  reportSettings,
  pages,
  globalSettings,
  onPageGenerated,
) => {
  const reportConfig = mergeDeep(reportSettings, globalSettings);
  const pdf = new jsPdf({
    orientation: 'landscape',
    format: 'a4',
    unit: 'mm',
    compress: true,
  });

  generateCoverHeader(pdf);
  generateCoverBody(generateSubtitles, globalSettings, reportSettings, pdf);
  generateCoverFooter(coverFooterLabel, pdf);

  const multipliedPages = multiplyReport(
    reportSettings.iterateByOptions,
    pages,
  );

  for (const [index, page] of multipliedPages.entries()) {
    const { size, children } = page;
    pdf.addPage();

    const applyText = prepareApplyText(pdf);
    const addHeader = prepareAddHeader(generateSubtitles, applyText);
    const addFooter = prepareAddFooter(applyText);
    const generateCharts = prepareGenerateCharts(pdf, index, addHeader);

    const pageConfig = mergeDeep(page, globalSettings);
    addHeader(WIDTH / 2, PAGE_HEAD.M_TOP, pageConfig);

    if (size) {
      const mappedLayout = generateLayout(size, children, pageConfig);
      await generateCharts(mappedLayout);
    }
    addFooter(pageConfig, reportConfig, `${index + 1}/${pages.length}`);
    onPageGenerated(index);
  }
  pdf.save('report.pdf');
};
