import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import 'rc-slider/assets/index.css';
import Icon from '_Components/Icons/Icon';
import { hex2rgbaConvert } from '_Utils/styles/colors';
import { connect } from "react-redux";
import { switchFilterSelected } from "../../_Resources/Header/Actions/setSelectedSwitchFilter";
import { union, isEmpty, includes, without } from "lodash";

const FilterTag = styled.div<{ active: boolean }>`
  cursor: pointer;
  margin: 5px;
  padding: 0.25em 0.5em;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  background-color: ${({active, color}) =>
    active ? hex2rgbaConvert(color, 25) : '#F5F5F5'};
  color: ${({active, color}) =>
    active ? hex2rgbaConvert(color, 100) : '#A7ADC7'};

  & > span {
    margin-left: 5px;
  }
`;

type QTagSwitchFilterProps = {
  availableSwitchFilters: Array<{
    name: string;
    color: string;
    text: string;
    icon: string;
  }>;
  query: any;
  variables: any;
  selectedSwitchFilters: Array<string> | null;
  variableFieldToModify: string;
  projectId: number;
  switchFilterSelected: any;
  t: any;
};

const QTagSwitchFilter = ({
                            availableSwitchFilters,
                            query,
                            variables,
                            selectedSwitchFilters,
                            variableFieldToModify,
                            projectId,
                            switchFilterSelected,
                            t,
                          }: QTagSwitchFilterProps) => {
  const [selectedFilters, setSelectedFilters] = useState(selectedSwitchFilters);

  useEffect(() => {
    setSelectedFilters(selectedSwitchFilters);
    switchFilterSelected(selectedSwitchFilters);
  }, [projectId]);

  useEffect(() => {
    query({
      variables: {
        ...variables,
        [variableFieldToModify]: selectedSwitchFilters,
      },
    });
  }, []);

  const onSwitchFilterClicked = (newSwitchFilter: string) => {
    let currentSwitchFilters = includes(selectedFilters, newSwitchFilter)
      ? without(selectedFilters, newSwitchFilter)
      : union(selectedFilters, [newSwitchFilter]);

    if (isEmpty(currentSwitchFilters)) currentSwitchFilters = null

    setSelectedFilters(currentSwitchFilters);
    switchFilterSelected(currentSwitchFilters);

    query({
      variables: {...variables, [variableFieldToModify]: currentSwitchFilters},
    });
  };

  const generateSwitchFiltersTags = () => {
    return availableSwitchFilters.map(({name, color, text, icon}) => {
      const isActive =
        selectedFilters === null ? false : selectedFilters.includes(name);

      return (
        <FilterTag
          onClick={() => onSwitchFilterClicked(name)}
          color={color}
          active={isActive}
          key={`switch-${name.toLowerCase()}`}
        >
          <Icon icon={icon} size={20} color={isActive ? color : '#A7ADC7'}/>
          <span>{t(text)}</span>
        </FilterTag>
      );
    });
  };

  return (
    <div>
      <div>{generateSwitchFiltersTags()}</div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => (
  {
    switchFilterSelected: (newSwitchFilter: string[]) => dispatch(switchFilterSelected(newSwitchFilter)),
  }
);

export default connect(null, mapDispatchToProps)(withTranslation('button')(QTagSwitchFilter));
