import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import countryList from 'react-select-country-list';
import getCountryISO3 from 'country-iso-2-to-3';
import getCountryISO2 from 'country-iso-3-to-2';
import QSelect from '_Components/Fields/QSelect/QSelect';
import QPanelHeader from '_Components/Panels/QPanelHeader/QPanelHeader';
import QInput from '_Components/Fields/QInput/QInput';
import { grayShades, red } from 'styles/abstracts/colors';
import {
  LabelSettings,
  FormGroupSettings,
} from '../../AccountAndPreferencesCard/_Component/SectionCard';

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 1rem;
  margin: 0 1rem;
  border-bottom: ${({ displayBorderBottom }) =>
    displayBorderBottom && `1px solid ${grayShades.g300}`};
  &:last-child {
    border-bottom: 0;
  }

  & sup {
    color:  ${red};
    margin-left: 0.5em;
    margin-top: 0.5em;
  }
`;

const TitleCol = styled(Col)`
  line-height: 3rem;
  padding-left: 0 !important;
  padding-bottom: 1rem;
`;
const StyledQInput = styled(QInput)`
  font-weight: bold;
  background-color: ${({disabled}) => disabled && 'white'};
  padding-left: ${({disabled}) => disabled && 0};
`;

const AddressInfosSection = ({
  t,
  address1,
  setAddress1,
  address2,
  setAddress2,
  address3,
  setAddress3,
  city,
  setCity,
  postalCode,
  setPostalCode,
  stateProvince,
  setStateProvince,
  alpha3Cc,
  setAlpha3Cc,
  isUserAdmin,
  companyName,
  setCompanyName,
  error,
  setError,
}) => {
  const [country, setCountry] = useState(null);

  useEffect(() => {
    setCountry(
      countryList()
        .getData()
        .find(co => co.value === getCountryISO2(alpha3Cc)),
    );
  }, [alpha3Cc]);

  const RequiredStar = () => isUserAdmin ? <sup>*</sup> : <></>;

  return (
    <StyledQPanelHeader type="menu">
      <Row>
        <TitleCol>{t('Billing address')}</TitleCol>
      </Row>
      <Row>
        <FormGroupSettings>
          <LabelSettings>{t('Company name')}<RequiredStar /></LabelSettings>
          <StyledQInput
            type="text"
            name="companyName"
            value={companyName}
            onChange={e => setCompanyName(e.target.value)}
            disabled={!isUserAdmin}
          />
        </FormGroupSettings>
      </Row>
      <Row>
        <FormGroupSettings>
          <LabelSettings>{t('Address')} 1<RequiredStar /></LabelSettings>
          <StyledQInput
            type="text"
            name="address1"
            value={address1}
            onChange={e => setAddress1(e.target.value)}
            disabled={!isUserAdmin}
          />
        </FormGroupSettings>
        <FormGroupSettings>
          <LabelSettings>{t('Address')} 2</LabelSettings>
          <StyledQInput
            type="text"
            name="address2"
            value={address2}
            onChange={e => setAddress2(e.target.value)}
            disabled={!isUserAdmin}
          />
        </FormGroupSettings>
        <FormGroupSettings>
          <LabelSettings>{t('Address')} 3</LabelSettings>
          <StyledQInput
            type="text"
            name="address3"
            value={address3}
            onChange={e => setAddress3(e.target.value)}
            disabled={!isUserAdmin}
          />
        </FormGroupSettings>
      </Row>
      <Row>
        <FormGroupSettings>
          <LabelSettings>{t('City')}<RequiredStar /></LabelSettings>
          <StyledQInput
            type="text"
            name="city"
            value={city}
            onChange={e => setCity(e.target.value)}
            disabled={!isUserAdmin}
          />
        </FormGroupSettings>
        <FormGroupSettings>
          <LabelSettings>{t('Postal code')}<RequiredStar /></LabelSettings>
          <StyledQInput
            type="text"
            name="postal_code"
            value={postalCode}
            onChange={e => setPostalCode(e.target.value)}
            disabled={!isUserAdmin}
          />
        </FormGroupSettings>
      </Row>
      <Row>
        <FormGroupSettings>
          <LabelSettings>{t('State/Province')}</LabelSettings>
          <StyledQInput
            type="text"
            name="state_province"
            value={stateProvince}
            onChange={e => setStateProvince(e.target.value)}
            disabled={!isUserAdmin}
          />
        </FormGroupSettings>
        <FormGroupSettings>
          <LabelSettings>{t('Country')}<RequiredStar /></LabelSettings>
          <QSelect
            disabled={!isUserAdmin}
            options={countryList().getData()}
            value={country}
            onChange={value => {
              setCountry(value);
              setAlpha3Cc(getCountryISO3(value.value));
            }}
          />
        </FormGroupSettings>
      </Row>
    </StyledQPanelHeader>
  );
};

export default withTranslation('card')(AddressInfosSection);
