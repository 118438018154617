import gql from 'graphql-tag';

const mutation = gql`
  mutation createSaasUploadConfig($useCaseId: Int!, $industryId: Int!) {
    createSaasUploadConfig(useCaseId: $useCaseId, industryId: $industryId) {
      uuid
      errors
    }
  }
`;

export default mutation;
