import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { grayShades } from 'styles/abstracts/colors';
import { withTranslation } from 'react-i18next';
import QTitle from '_Components/Texts/QTitle/QTitle';
import FileUploader from '_Components/FileManagement/FileUploader/FileUploader';
import QButton from '_Components/Buttons/QButton/QButton';
import mutation from '../graphql/updateSaasUploadConfig';

const Container = styled.div`
  background: ${grayShades.g300};
  width: 100%;
  height: 100%;
  padding: 0.5em 1em;
`;

const FileUploadContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & span {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    text-align-last: center;
    padding: 0 2em;
  }
`;

const FileUploadSection = ({
  t,
  status,
  uuid,
  setFileToUpload,
  fromDetails,
}) => {
  const [fileState, setFileState] = useState('no_file');
  useEffect(() => {
    if (status === 'NEW_FILE') {
      setFileState('no_file');
    } else if (status === 'UPLOADED') {
      setFileState('uploaded');
    }
  }, [status]);
  return (
    <Container>
      <QTitle>{t('Upload your file')}</QTitle>
      <FileUploadContainer>
        <div>
          <FileUploader
            setFileToUpload={setFileToUpload}
            t={t}
            setFileState={setFileState}
            acceptedFormat={['.xlsx', '.csv']}
            fileState={fileState}
            uploadedText="Your data has been uploaded"
            uploadedSubText={`The current workflow takes around 48 hours to be processed. \n
           We will email you when your data will be available.`}
            fileUploadMutation={fromDetails && mutation}
            uuid={uuid}
          />
          {fileState === 'error' && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <QButton
                onClick={() => {
                  setFileToUpload(null);
                  setFileState('no_file');
                }}
                style={{ margin: '0 auto' }}
              >
                {t('button:Retry')}
              </QButton>
            </div>
          )}
        </div>
      </FileUploadContainer>
    </Container>
  );
};

export default withTranslation('card', 'button')(FileUploadSection);
