import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { getProjectPeriod } from '_Resources/Header/Actions/getProjectPeriods';
import { DispatchDataToStore } from '_Resources/Header/Services/api/DispatchDataToStore';
import querySchema from './graphql/getHeaderDatesFiltersData';

class QueryDatesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <Query
          query={querySchema}
          variables={{
            projectId:
              (this.props.currentProject && this.props.currentProject.id) || 0,
          }}
          // fetchPolicy="network-only"
        >
          {({ loading, error, data, networkStatus }) => {
            if (loading || networkStatus === 4) return false;
            if (error) {
              return false;
            }
            return (
              <DispatchDataToStore
                data={data}
                action={() => this.props.getProjectPeriod(data)}
              />
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectPeriod: periods => {
      dispatch(getProjectPeriod(periods));
    },
  };
}

function mapStateToProps(state) {
  return {
    currentProject: state.projects.currentProject,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QueryDatesHeader);
