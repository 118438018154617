import { select } from 'd3-selection';
import { get } from 'lodash';
import { AXIS } from '../calculation/grid';
import { getTransitionDuration, transitionDurationTime } from './tile';

import prepareWrapText from './utils/text';

function getX(pointData) {
  const { point } = pointData;
  return point[AXIS.X];
}

function getY(pointData) {
  const { point } = pointData;
  return point[AXIS.Y];
}

const FONT_SIZE = 22;
const LINES_TO_OFFSET = {
  3: -FONT_SIZE / 2,
  2: 0,
  1: FONT_SIZE / 2,
};

function onEnter(textRowSelections, wrapText, onClick, onMouseover, id) {
  textRowSelections.forEach((textRowSelection, index) =>
    textRowSelection
      .enter()
      .append('text')
      .attr('id', `${id}Label`)
      .on('click', onClick)
      .on('mouseover', onMouseover)
      .on('mouseout', onMouseover)
      .attr('cursor', 'pointer')
      .attr('class', 'insightTile')
      .attr('text-row', index)
      .attr('y', getY)
      .attr('x', getX)
      .attr('dy', ({ insightData }) => {
        const offset =
          LINES_TO_OFFSET[wrapText(insightData.insightName).length];
        return `${offset + index * FONT_SIZE}px`;
      })
      .style('text-anchor', 'middle')
      .style('font', () => `bold ${FONT_SIZE}px Nunito sans`)
      .style('fill', 'white')
      .text(({ insightData = {} }) => wrapText(insightData.insightName)[index])
      .style('opacity', 0)
      .transition(getTransitionDuration())
      .delay(transitionDurationTime)
      .style('opacity', 1),
  );
}

function onUpdate(textRowSelections, wrapText) {
  textRowSelections.forEach((textRowSelection, index) =>
    textRowSelection
      .transition(getTransitionDuration())
      .delay(transitionDurationTime)
      .attr('y', getY)
      .text(({ insightData = {} }) => wrapText(insightData.insightName)[index])
      .attr('x', getX),
  );
}

function onExit(textRowSelections) {
  textRowSelections.forEach(textRowSelection =>
    textRowSelection
      .exit()
      .transition(getTransitionDuration())
      .remove(),
  );
}

const getId = (point, index) => {
  const insightId = get(point, 'insightData.insightId');
  return `id="${insightId}" text-row="${index}"`;
};

export default function createTileLabelFactory({
  container,
  onClick,
  onMouseover,
  onMouseout,
  id,
}) {
  const wrapText = prepareWrapText({ linesNumber: 3, lineLength: 12 });
  return function tileLabelFactory({ points }) {
    const updateSelection1 = select(container)
      .selectAll('text.insightTile[text-row="0"]')
      .data(points, point => getId(point, 0));

    const updateSelection2 = select(container)
      .selectAll('text.insightTile[text-row="1"]')
      .data(points, point => getId(point, 1));

    const updateSelection3 = select(container)
      .selectAll('text.insightTile[text-row="2"]')
      .data(points, point => getId(point, 2));
    const selections = [updateSelection1, updateSelection2, updateSelection3];
    onEnter(selections, wrapText, onClick, onMouseover, onMouseout, id);
    onUpdate(selections, wrapText);
    onExit(selections);
  };
}
