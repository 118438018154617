import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import fr from './fr.json';
import es from './es.json';
import de from './de.json';
import moment from 'moment';
// const LocizeEditor =
// typeof window !== 'undefined' && require('locize-editor');
// const LocizeBackend =
// typeof window !== 'undefined' && require('i18next-locize-backend');
// i18n
//   .use(LocizeBackend)
//   .use(LocizeEditor)
//   .use(LanguageDetector)
//   .use(reactI18nextModule)
//   .init({
//     fallbackLng: 'en',
//     appendNamespaceToCIMode: true,
//     saveMissing: true,
//
//     // have a common namespace used around the full app
//     ns: ['translations'],
//     defaultNS: 'translations',
//
//     debug: true,
//     keySeparator: '### not used ###', // we use content as keys
//
//     backend: {
//       projectId: 'e365e54a-c52c-479b-8538-682635db252f', // <-- replace with your projectId
//       apiKey: 'your apiKey',
//       referenceLng: 'en'
//     },
//
//     interpolation: {
//       escapeValue: false, // not needed for react!!
//       formatSeparator: ',',
//       format: function(value, format, lng) {
//         if (format === 'uppercase') return value.toUpperCase();
//         return value;
//       }
//     },
//
//     react: {
//       wait: true
//     }
//   });
//
//
// export default i18n;
// import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
//
i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en,
    fr,
    es,
    de,
  },
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format(value, format, lng) {
      if (format === 'uppercase') return value.toUpperCase();
      if (value instanceof Date) return moment(value).format(format);
      return value;
    },
  },

  react: {
    wait: true,
  },
});

export default i18n;
