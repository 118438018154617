import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { getFontSizeStyles } from 'styles/abstracts/typography';
import { useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import QInput from '_Components/Fields/QInput/QInput';
import { spacing } from 'styles/abstracts/variables';
import { grayShades, purple } from 'styles/abstracts/colors';
import QButton from '_Components/Buttons/QButton/QButton';
import { renameCluster } from '../../../_Resources/Cluster/Actions/renameCluster';

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  ${getFontSizeStyles(20)};
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  padding: 0.5em 1em;
  font-size: 1.25em;
  cursor: pointer;
`;

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  & > * {
    margin: 0 0.25em;
  }
  margin-left: 2em;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0 !important;
  justify-content: center;
`;

const StyledQInput = styled(QInput)`
  margin-top: ${spacing.small};
  margin-bottom: ${spacing.xsmall};
  font-weight: bold;
  border: none;
  font-size: 1.25em;
  color: ${purple};
  padding: none;
  width: auto;
  transition: all 0.5s ease-in-out;
`;

const ClusterEditableTitle = ({
  cluster,
  renameCluster,
  editTitleMutation,
  editTitleMutationVariables,
}) => {
  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState(cluster?.clickedCluster?.title || '');

  const inputEl = useRef(null);

  const editTitle =
    editTitleMutation !== undefined ? useMutation(editTitleMutation) : null;

  const handleSubmit = () => {
    if (title.length > 0 && title !== cluster?.clickedCluster?.title) {
      editTitle[0]({
        variables: {
          ...editTitleMutationVariables,
          newTitle: title,
        },
      });
      renameCluster(title);
    }
    setEditingTitle(false);
  };

  const handleEnterKey = e => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (editingTitle) {
      if (inputEl.current !== null) {
        inputEl.current.select();
      }
    }
  }, [editingTitle]);

  return (
    <>
      {editingTitle && (
        <TitleContainer>
          <StyledQInput
            name="title"
            ref={inputEl}
            size={title.length}
            type="text"
            value={title}
            onChange={e => {
              setTitle(e.target.value);
            }}
            onFocus={e => e.target.select()}
            onKeyDown={e => handleEnterKey(e)}
          />
          <StyledQButton
            type="button"
            onClick={() => handleSubmit()}
            size="small"
            hasOpacity
            bgColor={grayShades.g800}
          >
            OK
          </StyledQButton>
        </TitleContainer>
      )}
      {!editingTitle && (
        <TitleContainer>
          <Title
            id={`${title}-title`}
            onClick={() => setEditingTitle(!editingTitle)}
          >
            {title}
          </Title>
          <StyledQButton
            type="button"
            size="small"
            onClick={() => setEditingTitle(!editingTitle)}
            icon="INTERFACE_EDIT"
            iconSize={20}
            hasOpacity
            bgColor={grayShades.g800}
          />
        </TitleContainer>
      )}
    </>
  );
};

ClusterEditableTitle.defaultProps = {
  title: '',
  titleSecondPart: '',
};
ClusterEditableTitle.propTypes = {
  title: PropTypes.string,
  titleSecondPart: PropTypes.string,
};

const mapStateToProps = ({ cluster }) => ({
  cluster,
});

const mapDispatchToProps = (dispatch: any) => ({
  renameCluster: (newState: any) => {
    dispatch(renameCluster(newState));
  },
});

export default withTranslation('card')(
  connect(mapStateToProps, mapDispatchToProps)(ClusterEditableTitle),
);
