import { red } from 'styles/abstracts/colors';
import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  StyledPreviewButton,
  Text,
  FloatingActionButtonPosition,
} from './StyledPreviewButton';

export const StyledGroupButton = styled(ButtonGroup)`
  ${FloatingActionButtonPosition};
  & .btn {
    position: initial !important;
    bottom: 0rem;
    right: 0rem;
  }
  & [class~='btn']:first-of-type {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  & [class~='btn']:last-of-type {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

const ExportButton = ({ callbackPreviewMode, onExport, t }) => (
  <StyledGroupButton>
    <StyledPreviewButton
      icon="INTERFACE_CHEVRON_LEFT"
      iconPosition="left"
      iconColor="red"
      bgColor="white"
      secondaryColor="red"
      onClick={() => callbackPreviewMode(false)}
    >
      <Text paddingSide="LEFT">{t('Go back')}</Text>
    </StyledPreviewButton>
    <StyledPreviewButton
      bgColor="red"
      icon="DOWNLOAD"
      onClick={onExport}
    >
      <Text paddingSide="RIGHT">{t('Export to PDF')}</Text>
    </StyledPreviewButton>
  </StyledGroupButton>
);

ExportButton.propTypes = {
  callbackPreviewMode: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('reporting')(ExportButton);
