import React from 'react';
import PropTypes from 'prop-types';
import QDraggable from './QDraggable';

const QDraggableBox = ({ id, render, index, className }) => (
  <QDraggable
    id={id ?? `draggableBox-${index}`}
    index={index}
    render={(provided, snapshot) => (
      <div
        className={className}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {render(provided, snapshot)}
      </div>
    )}
  />
);

QDraggableBox.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,

  render: PropTypes.func.isRequired,
};

QDraggableBox.defaultProps = {
  className: null,
};

export default QDraggableBox;
