import React from 'react';
import Layout from '_Layouts/Layout';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import AddProject from './AddData';

const title = 'Send us your data';
const body = <AddProject />;

function action(props) {
  return (
    <QLoginRedirect>
      <Layout body={body} {...props} title={title} />
    </QLoginRedirect>
  );
}

export default action;
