import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import QModal from '_Components/Modals/QModal/QModal';
import QButton from '_Components/Buttons/QButton/QButton';
import PersonalInfoSection from '_Cards/SettingsCards/AccountAndPreferencesCard/_Component/PersonalInfoSection';
import PreferencesSection from '_Cards/SettingsCards/AccountAndPreferencesCard/_Component/PreferencesSection';
import {
  LabelSettings,
  FormGroupSettings,
} from '_Cards/SettingsCards/AccountAndPreferencesCard/_Component/SectionCard';
import QInput from '_Components/Fields/QInput/QInput';
import { fontSizes } from 'styles/abstracts/typography';
import mutation from '_Cards/SettingsCards/UserAndPermissionsCard/graphql/createUser';
import accountUserQuery from '_Cards/SettingsCards/UserAndPermissionsCard/graphql/getAccountUserList';
import { red } from 'styles/abstracts/colors';
import CURRENT_USER_QUERY from '_Resources/Header/Services/api/graphql/currentUser';

const EmailContainer = styled.div`
  padding: 0 1em;
  font-weight: bold;
`;

const StyledQInput = styled(QInput)`
  font-weight: bold;
`;

const Modal = styled(QModal)`
  font-size: ${fontSizes.bigger};
  & input {
    font-size: 13px;
  }
`;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ErrorContainer = styled.div`
  font-weight: bold;
  color: ${red};
  width: 100%;
  text-align: center;
  font-size: ${fontSizes.default};
`;

const CreateUserModal = ({ isOpen, toggle, users, t }) => {
  const [userName, setUserName] = useState({});
  const [userPreferences, setUserPreferences] = useState({});
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(null);
  const [userInfos, setUserInfos] = useState({
    firstName: '',
    lastName: '',
    email: '',
    lang: '',
    isFahrenheit: false,
    verbatimsLang: '',
  });
  const [createUser] = useMutation(mutation, {
    update: (cache, { data: { createUser } }) => {
      const accountId = window.localStorage.getItem('userAccountId');
      let accountUsers;
      try {
        accountUsers = cache.readQuery({
          query: accountUserQuery,
          variables: { accountId: parseInt(accountId, 10) },
        }).getAccountUserList;
      } catch (e) {
        accountUsers = users;
      }
      const userToAdd = { ...createUser.accountUserItem };
      const newAccountUsers = [...accountUsers, userToAdd];
      cache.writeQuery({
        query: accountUserQuery,
        variables: { accountId: parseInt(accountId, 10) },
        data: {
          getAccountUserList: newAccountUsers,
        },
      });
      const { currentUser } = cache.readQuery({
        query: CURRENT_USER_QUERY,
      });
      currentUser.account.canCreateUser = createUser.canCreateUser;
      cache.writeQuery({
        query: CURRENT_USER_QUERY,
        data: { currentUser },
      });
    },
  });

  useEffect(() => {
    setUserInfos({
      firstName: userName?.firstname || '',
      lastName: userName?.lastname || '',
      email,
      lang: userPreferences?.language?.value || window.localStorage.getItem('lang'),
      isFahrenheit: userPreferences?.menuOption || false,
      verbatimsLang: userPreferences?.verbatimsLanguage?.value || window.localStorage.getItem('verbatimsLang'),
    });
  }, [userName, email, userPreferences]);

  const modalFooter = (
    <ModalFooter>
      <QButton
        bgColor="grayShades.g300"
        onClick={() => {
          toggle(false);
          setUserPreferences({});
          setEmail('');
          setUserInfos({});
        }}
      >
        {t('button:Cancel')}
      </QButton>
      <QButton
        onClick={() => {
          createUser({
            variables: { ...userInfos },
          }).then(
            ({
              data: {
                createUser: { errors },
              },
            }) => {
              if (errors) {
                setErrors(errors);
              } else {
                toggle(false);
                setUserPreferences({});
                setEmail('');
                setUserInfos({});
                setErrors(null);
              }
            },
          );
        }}
      >
        {t('button:Save')}
      </QButton>
    </ModalFooter>
  );

  return (
    <Modal
      onClose={() => toggle(!isOpen)}
      isOpen={isOpen}
      title={t('Add a new user')}
      config={{ mentions: false, date: false }}
      footer={modalFooter}
    >
      <PersonalInfoSection displayBorderBottom={false} callback={name => setUserName(name)} />
      <EmailContainer>
        <FormGroupSettings>
          <LabelSettings>{t('Company email address')}</LabelSettings>
          <StyledQInput type="text" value={email} onChange={e => setEmail(e.target.value)} />
        </FormGroupSettings>
      </EmailContainer>
      <PreferencesSection callback={preferences => setUserPreferences(preferences)} eindexUnit={false} />
      {errors && <ErrorContainer>{errors[0]}</ErrorContainer>}
    </Modal>
  );
};

export default withTranslation('card')(CreateUserModal);
