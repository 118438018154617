import gql from 'graphql-tag';

const query = gql`
  query getProjectSources {
    getProjectSources {
      id
      name
      link
      imageLink
    }
  }
`;

export default query;
