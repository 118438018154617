import gql from 'graphql-tag';

const query = gql`
  query getReportingModel(
    $projectId: Int! #    $range: DateRangeInput! #    $criteriaKeys: [CriteriaKey!] #    $thematics: [ThematicInput!]
  ) {
    getReportingModel(
      projectId: $projectId #      range: $range #      criteriaKeys: $criteriaKeys #      thematics: $thematics
    ) {
      id
      title
      settings
      updatedBy
      updatedAt
    }
  }
`;

export default query;
