import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PagesShortcuts from '../../../_Components/PagesShortcuts';
import predefinedLayouts from '../../layouts/predefinedLayouts';
import { changePageByShortcut } from '../../../../../../_Resources/Reporting/Actions/changePageByShortcut';
import { PAGES_SHORTCUT_MODES } from '../../../_Components/Shortcut';

class PredefinedPages extends Component {
  static propTypes = {
    dispatchChangePageByShortcut: PropTypes.func.isRequired,
    currentPageIndex: PropTypes.number,
  };

  static defaultProps = {
    currentPageIndex: null,
  };

  onPageChange = page => {
    const { dispatchChangePageByShortcut, currentPageIndex } = this.props;
    dispatchChangePageByShortcut({
      size: page.size,
      children: page.children,
      cssGridLayout: page.cssGridLayout,
      pageIndex: currentPageIndex,
    });
  };

  render() {
    return (
      <PagesShortcuts
        mode={PAGES_SHORTCUT_MODES.PREDEFINED_LAYOUTS}
        shortcuts={predefinedLayouts}
        onShortcutClicked={this.onPageChange}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchChangePageByShortcut: (...args) =>
    dispatch(changePageByShortcut(...args)),
});

const mapStateToProps = ({ reporting: { currentPageIndex } }) => ({
  currentPageIndex,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PredefinedPages);
