import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import TabMenu from '../_Components/TabMenu';
import QButton from '../../../../_Components/Buttons/QButton/QButton';
import QTitle from '../../../../_Components/Texts/QTitle/QTitle';
import { TEXT_TYPES } from '../../../../_Components/Texts/textTypes';
import { TEXT_POSITIONS } from '../../../../_Components/Texts/textPositions';
import QDatepicker from '../../../../_Components/Fields/QDatepicker/QDatepicker';

const FiltersArea = styled.div`
  padding: 0.5em;
  font-size: 0.9em;
`;

const StyledTabMenu = styled(TabMenu)`
  padding: 0;
`;

const Footer = styled.div`
  text-align: center;
  margin: 1.5em;
`;

class PresentationDateRange extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    periods: PropTypes.any.isRequired,
    isModified: PropTypes.bool.isRequired,
    settings: PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      isStartDate: PropTypes.bool.isRequired,
      isEndDate: PropTypes.bool.isRequired,
    }).isRequired,
    onUpdate: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
  };

  toggleIsStartDate = () => {
    const {
      settings: { isStartDate },
      onUpdate,
    } = this.props;
    onUpdate({ isStartDate: !isStartDate });
  };
  toggleIsEndDate = () => {
    const {
      settings: { isEndDate },
      onUpdate,
    } = this.props;
    onUpdate({ isEndDate: !isEndDate });
  };

  onReset = () => this.props.onUpdate(null);
  onPeriodUpdate = ({ startDate, endDate }) => {
    const { onUpdate } = this.props;
    onUpdate({ startDate, endDate });
  };

  render() {
    const {
      periods,
      settings: { startDate, endDate },
      onReset,
      isModified,
      t,
    } = this.props;
    return (
      <FiltersArea>
        <StyledTabMenu>
          <QTitle type={TEXT_TYPES.PANEL} position={TEXT_POSITIONS.LEFT}>
            <QTitle.Title>{t('Date range')}</QTitle.Title>
          </QTitle>
          <QDatepicker
            startDate={startDate}
            endDate={endDate}
            periods={periods}
            withPortal
            onPeriodUpdate={this.onPeriodUpdate}
          />

          <Footer>
            <QButton
              bgColor="red"
              disabled={!isModified}
              onClick={isModified && onReset}
            >
              {t('Reset "date range" to default report settings')}
            </QButton>
          </Footer>
        </StyledTabMenu>
      </FiltersArea>
    );
  }
}

export default withTranslation('reporting')(PresentationDateRange);
