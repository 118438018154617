import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QModal from '_Components/Modals/QModal/QModal';
import QButton from '_Components/Buttons/QButton/QButton';
import Icon from '_Components/Icons/Icon';
import { lightBlue } from 'styles/abstracts/colors';
import mapStyle from '_Components/WorldMap/containers/config/greyMapStyle'

const Map = styled.div`
  min-height: 25em;
  margin-bottom: 0.5em;
`;

const Controls = styled.input`
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 300;
  height: 29px;
  margin-left: 17px;
  margin-top: 10px;
  outline: none;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;

  &:focus {
    border-color: #4d90fe;
  }
`;

const Footer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
`;

const Warning = styled.div`
  font-weight: bold;
  color: ${lightBlue};
`;

const GoogleModal = ({ open, isOpen, setGooglePlaceIDValue, t }) => {
  const [temporaryPlaceId, setTemporaryPlaceId] = useState(null);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_WORLD_MAP_KEY}&callback=initMap&libraries=places`;
    script.defer = true;


    const scriptToRemove = Array.from(
      document.getElementsByTagName('script'),
    ).filter(s => s.src === script.src);
    if (isOpen && !scriptToRemove[0]) {
      document.head.appendChild(script);
    }
  }, [isOpen]);

  const closeGoogleModal = () => {
    open(!isOpen);

    const scriptToRemove = Array.from(
      document.getElementsByTagName('script'),
    ).filter(
      s =>
        s.src ===
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_WORLD_MAP_KEY}&callback=initMap&libraries=places`,
    );
    document.head.removeChild(scriptToRemove[0]);
  };

  window.initMap = () => {
    const styledMapType = new google.maps.StyledMapType(mapStyle, { name: 'Styled Map'});
    const map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: -33.8688, lng: 151.2195 },
      zoom: 13,
      fullscreenControl: false,
    });
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
    const input = document.getElementById('pacinput');
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.bindTo('bounds', map);
    // Specify just the place data fields that you need.
    autocomplete.setFields([
      'place_id',
      'geometry',
      'name',
      'formatted_address',
    ]);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    const infowindow = new google.maps.InfoWindow();
    const infowindowContent = document.getElementById('infowindow-content');
    infowindow.setContent(infowindowContent);
    const geocoder = new google.maps.Geocoder();
    const marker = new google.maps.Marker({ map });
    marker.addListener('click', () => {
      infowindow.open(map, marker);
    });
    autocomplete.addListener('place_changed', () => {
      infowindow.close();
      const place = autocomplete.getPlace();

      if (!place.place_id) {
        return;
      }
      geocoder.geocode({ placeId: place.place_id }, (results, status) => {
        setTemporaryPlaceId(place.place_id);

        if (status !== 'OK') {
          window.alert(`Geocoder failed due to: ${status}`);
          return;
        }
        map.setZoom(11);
        map.setCenter(results[0].geometry.location);
        // Set the position of the marker using the place ID and location.

        marker.setPlace({
          placeId: place.place_id,
          location: results[0].geometry.location,
        });
        marker.setVisible(true);
        infowindowContent.children['place-name'].textContent = place.name;
        infowindowContent.children['place-address'].textContent =
          results[0].formatted_address;
        infowindow.open(map, marker);
      });
    });
  };

  const footer = (
    <Footer>
      <QButton onClick={() => closeGoogleModal()}>Retour</QButton>
      <QButton onClick={() => {setGooglePlaceIDValue(temporaryPlaceId); closeGoogleModal()}} disabled={!temporaryPlaceId}>Valider</QButton>
    </Footer>
  )

  return (
    <QModal
      isOpen={isOpen}
      onClose={() => closeGoogleModal()}
      title="Choose your place"
      footer={footer}
      subtitle={'(Exemple ; "FNAC Belfort", "Faubourg de France, Belfort")'}
      config={{date: false, mentions: false}}
    >
      <div style={{ display: 'none' }}>
        <Controls
          id="pacinput"
          className="controls"
          type="text"
          placeholder="Enter a location"
        />
      </div>

      <Map id="map" />
      <div id="infowindow-content" style={{overflow: 'hidden'}}>
        <span id="place-name" className="title" />
        {temporaryPlaceId && <br/>}
        <span id="place-address" />
      </div>
      <Warning><Icon icon="INTERFACE_INFO" color={lightBlue} size={20} />{' '}
      {t('Places and their addresses have 2 different Place IDs. We recommend that you enter a place rather than an address.')}
      </Warning>
    </QModal>
  );
};

export default withTranslation('card')(GoogleModal);
