import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QTitle from '../../Texts/QTitle/QTitle';

const FiltersSection = ({ label, children, className }) => (
  <div className={className}>
    <QTitle>
      <QTitle.Title>{label}</QTitle.Title>
    </QTitle>
    <div>{children}</div>
  </div>
);

FiltersSection.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

FiltersSection.defaultProps = {
  children: [],
};

const StyledFilterSection = styled(FiltersSection)`
  padding: 1em;
`;

export default StyledFilterSection;
