import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';
import BadgeBenchmarkTooltip from '_Components/Tooltips/BadgeBenchmarkTooltip/BadgeBenchmarkTooltip';
import { withTranslation } from 'react-i18next';

const IndicatorName = styled.p`
  font-size: 14px;
  color: #928fb0;
  text-align: left;
  line-height: 21px;
  margin-bottom: 0;
`;

const NotationText = styled.p`
  font-size: 24px;
  color: #2e096b;
  text-align: left;
  margin-bottom: 0;
`;

const IconNotation = styled(Col)`
  /* display: inline-block; */
  /* margin-right: 1rem; */
  &>* {
    float: right;
  }
`;

const NoteWithLeftIcon = props => {
  const { t, icon, color, size, indicator, data, idTooltip } = props;
  return (
    <Row className="font-weight-bold">
      <IconNotation xs="5" className="IconNotation">
        <IconCircle icon={icon} color={color} size={size} />
      </IconNotation>
      <Col xs="7" className="textnotation">
        <IndicatorName className="IndicatorName">{t(indicator)}</IndicatorName>
        <NotationText className="NotationText">{`${data.value} ${
          data.unit.max ? `/ ${data.unit.max}` : ''
        }`}</NotationText>
        {idTooltip && (
          <BadgeBenchmarkTooltip
            title={t(indicator)}
            data={data}
            idTooltip={idTooltip}
          />
        )}
      </Col>
    </Row>
  );
};

export default withTranslation('card')(NoteWithLeftIcon);
