import React from 'react';
import styled from 'styled-components';
import { MenuItemLabel } from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import { Col, Row } from 'reactstrap';
import QTitle from '_Components/Texts/QTitle/QTitle';
import { withTranslation } from 'react-i18next';

const HiddenInput = styled.input`
  visibility: hidden;
`;
const StyledQTitle = styled(QTitle)`
  margin-left: 1rem;
  padding-bottom: 1rem;
`;
const StyledMenuItemLabel = styled(MenuItemLabel)`
  margin: 0 1rem;
  width: 1.25rem;
  height: 1.25rem;
  &:before {
    top: 0.4rem;
    left: 0.4rem;
    width: 0.5rem;
    height: 0.5rem;
  }
`;
const InputContainer = styled.div`
  display: flex;
  place-content: center flex-start;
  align-items: center;
  padding-bottom: 1rem;
`;
const StyledCheckbox = styled(QCheckbox)`
  margin: 0 1rem;
`;
const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`;

class ModaleExportBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      format: 'jpg',
      backgroundCard: true,
      titleDisplay: true,
      subtitleDisplay: true,
      cardID: this.props.cardID,
      showDataByFiltersDropdowns: true,
    };
  }

  componentDidMount() {
    this.setState(
      {
        settingsExport: { ...this.state },
      },
      () => this.props.getSettingsExport(this.state.settingsExport, this),
    );
  }

  handleOptions(value, type) {
    this.setState(
      prevState => ({
        [type]: value,
        settingsExport: { ...prevState.settingsExport, [type]: value },
      }),
      () => this.props.getSettingsExport(this.state.settingsExport, this),
    );
  }

  itemsFormat = [
    {
      label: 'JPG',
      value: 'jpg',
    },
    { label: 'PNG', value: 'png' },
    { label: 'WebP', value: 'webP' },
  ];

  render() {
    const { t, dataByFiltersCard } = this.props;
    return (
      <Row>
        <StyledCol>
          <StyledQTitle>{t('Image format')}</StyledQTitle>
          {this.itemsFormat.map((item, index) => (
            <InputContainer key={`InputFormat${index}`}>
              <StyledMenuItemLabel checked={this.state.format === item.value}>
                <HiddenInput
                  type="radio"
                  value={item.value}
                  onClick={() => this.handleOptions(item.value, 'format')}
                />
              </StyledMenuItemLabel>
              <QCheckbox.Label>{item.label}</QCheckbox.Label>
            </InputContainer>
          ))}
        </StyledCol>
        <StyledCol>
          <StyledQTitle>{t('Showing items')}</StyledQTitle>
          <InputContainer>
            <StyledCheckbox
              selected={this.state.titleDisplay}
              onClick={() =>
                this.handleOptions(!this.state.titleDisplay, 'titleDisplay')
              }
            />
            <QCheckbox.Label>{t('Card title')}</QCheckbox.Label>
          </InputContainer>
          <InputContainer>
            <StyledCheckbox
              selected={this.state.subtitleDisplay}
              onClick={() =>
                this.handleOptions(
                  !this.state.subtitleDisplay,
                  'subtitleDisplay',
                )
              }
            />
            <QCheckbox.Label>{t('Card subtitle')}</QCheckbox.Label>
          </InputContainer>
          <InputContainer>
            <StyledCheckbox
              selected={this.state.backgroundCard}
              onClick={() =>
                this.handleOptions(!this.state.backgroundCard, 'backgroundCard')
              }
            />
            <QCheckbox.Label>{t('Card background')}</QCheckbox.Label>
          </InputContainer>
          {dataByFiltersCard && (
            <InputContainer>
              <StyledCheckbox
                selected={this.state.showDataByFiltersDropdowns}
                onClick={() =>
                  this.handleOptions(
                    !this.state.showDataByFiltersDropdowns,
                    'showDataByFiltersDropdowns',
                  )
                }
              />
              <QCheckbox.Label>{t('Card filters')}</QCheckbox.Label>
            </InputContainer>
          )}
        </StyledCol>
      </Row>
    );
  }
}
export default withTranslation('card')(ModaleExportBody);
