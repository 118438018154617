import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import QSingleDatepicker from '_Components/Fields/QSingleDatepicker/QSingleDatepicker';
import { fontSizes } from 'styles/abstracts/typography';
import { grayShades, purple } from 'styles/abstracts/colors';
import moment from 'moment';

const Container = styled.div`
  margin-left: 2em;
`;

const StyledCol = styled(Col)`
  display: inline-flex;
  align-items: center;
  margin-left: 1em;
`;

const Legend = styled.p`
  font-weight: bolder;
  font-size: ${fontSizes.smaller};
  color: ${grayShades.g800};
`;

const DateConfiguration = ({ startDate, setStartDate, t }) => {
  return (
    <Container>
      <Row>
        <Col xs={1}>
          <Legend>{t('Start date')}</Legend>
        </Col>
        <StyledCol xs={2}>
          <Legend>{t('End date')}</Legend>
        </StyledCol>
      </Row>
      <Row>
        <Col xs={1}>
          <QSingleDatepicker
            chosenDate={startDate}
            setChosenDate={setStartDate}
          />
        </Col>
        <StyledCol xs={2}>
          {moment()
            .subtract(1, 'day')
            .format('L')}{' '}
          ({t('yesterday')})
        </StyledCol>
      </Row>
    </Container>
  );
};

export default DateConfiguration;
