import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QSelectBox from '../../../../../../_Components/Fields/QSelect/QSelectBox';

const Area = styled.div`
  display: flex;
  flex-grow: 1;
`;

class Filters extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    iterateByOptions: PropTypes.any.isRequired,
    selectedIterateByOption: PropTypes.any,
    selectedPreviewFor: PropTypes.string,
    onSelectIterateBy: PropTypes.func.isRequired,
    onSelectPreviewFor: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedIterateByOption: null,
    selectedPreviewFor: null,
  };

  getIterateByOptions = () => {
    const { iterateByOptions } = this.props;
    return iterateByOptions.map(({ option }) => ({
      label: option.label,
      value: option.id,
    }));
  };

  getPreviewForOptions = selectedPreviewForOption => [
    {
      label: 'All',
      value: 'all',
    },
    ...selectedPreviewForOption.map(value => ({
      label: value,
      value,
    })),
  ];

  render() {
    const {
      t,
      iterateByOptions,
      selectedIterateByOption,
      selectedPreviewFor,
      onSelectIterateBy,
      onSelectPreviewFor,
    } = this.props;
    const isIterateBySelected =
      selectedIterateByOption && selectedIterateByOption.values;
    return (
      <Area>
        {iterateByOptions && (
          <QSelectBox
            label={t('Iterate by')}
            value={selectedIterateByOption && selectedIterateByOption.id}
            onChange={onSelectIterateBy}
            options={this.getIterateByOptions()}
          />
        )}

        {isIterateBySelected && (
          <QSelectBox
            label={t('Preview results for')}
            value={selectedPreviewFor}
            onChange={onSelectPreviewFor}
            options={this.getPreviewForOptions(selectedIterateByOption.values)}
          />
        )}
      </Area>
    );
  }
}

export default withTranslation('reporting')(Filters);
