import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const Container = styled.div`
  z-index: 0;
  position: relative;
  width: calc(100% - 5em);
  height: 100%;
  left: 5em;
  top: 9.5rem;
`;

class BodyLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container id="bodyLayout" idpage={this.props.idpage}>
        {this.props.body}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    idpage: state.routes.subRoute && state.routes.subRoute.id,
  };
}

export default connect(mapStateToProps)(BodyLayout);
