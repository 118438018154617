import { chain } from 'lodash';

export const extractModules = ({ modules }) => modules;

const getOccurrences = ([, occurrences]) => occurrences;
const getModule = ([module]) => module;

export const extractModulesFromInsights = insights =>
  chain(insights)
    .map(extractModules)
    .flatten()
    .countBy(String)
    .toPairs()
    .sortBy(getOccurrences)
    .map(getModule)
    .value()
    .reverse();

export const VIEW_MODES = {
  ALL: 'all',
  MODULE: 'module',
};

const allFilter = insights => insights;

const moduleFilter = (insights, module) =>
  insights.filter(insight => {
    const currentModules = extractModules(insight);
    return currentModules.includes(module);
  });

export const VIEW_MODES_FILTERS = {
  [VIEW_MODES.ALL]: allFilter,
  [VIEW_MODES.MODULE]: moduleFilter,
};
