import React from 'react';
import { Dropdown, DropdownToggle, Popover, PopoverBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import DropdownPortal from '_Layouts/_Components/DropdownPortal/DropdownPortal';
import Icon from '_Components/Icons/Icon';
import { white, grayShades } from 'styles/abstracts/colors';
import { spacing, ImageSize } from 'styles/abstracts/variables';
import { fontSizes } from 'styles/abstracts/typography';

const ToggleCustom = styled(DropdownToggle)`
  ${props =>
    props.active
      ? `
      background: none !important;
      border: none;
      color: ${props.disabled ? grayShades.g800 : white} !important;
      fill: ${props.disabled ? grayShades.g800 : white} !important;
      min-width: 5rem !important;
      margin-top: ${!props.tools && spacing.medium};
      box-sizing: border-box;
      cursor: ${!props.disabled ? 'pointer' : 'default'} !important;
 `
      : `
  background: none !important;
  border: none;
  min-width: 5rem !important;
  color: ${props.disabled ? grayShades.g800 : white} !important;
  fill: ${props.disabled ? grayShades.g800 : white} !important;
  opacity: 0.5;
  box-sizing: border-box;
  margin-top: ${!props.tools && spacing.medium};
  cursor: ${!props.disabled ? 'pointer' : 'default'} !important;

  &:hover {
    background: none;
    border: none;
    color: ${white} !important;
    fill: ${props.disabled ? grayShades.g800 : white} !important;
    opacity: 1;
    cursor: ${!props.disabled ? 'pointer' : 'default'} !important;
  }

  &:focus {
    cursor: ${!props.disabled ? 'pointer' : 'default'} !important;
    background: none;
    border: none;
    color: ${props.disabled ? grayShades.g800 : white} !important;
  }

  & .btn, & .btn:hover, & .btn:focus {
    cursor: ${!props.disabled ? 'pointer' : 'default'} !important;
  }
`};
`;

const IconTC = styled(Icon)`
  ${props =>
    props.active
      ? `
   background: none !important;
  border: none;
  min-width: 5rem !important;
  color: ${props.disabled ? grayShades.g800 : white} !important;
  fill: ${props.disabled ? grayShades.g800 : white} !important;
  opacity: 1;
  box-sizing: border-box;
  position: static !important;

  &:hover {
    background: none !important;
    border: none;
    color: ${props.disabled ? grayShades.g800 : white} !important;
    fill: ${props.disabled ? grayShades.g800 : white} !important;
  }

  &:focus {
    background: none !important;
    border: none;
    color: ${props.disabled ? grayShades.g800 : white} !important;
  }
     `
      : `
      background: none !important;
      border: none;
      color: ${props.disabled ? grayShades.g800 : white} !important;
      fill: ${props.disabled ? grayShades.g800 : white} !important;
      opacity: 0.5;
      `};
`;

const Item = styled.div`
  text-align: center;
  cursor: ${props => !props.disabled && 'pointer'};
  font-size: ${fontSizes.smaller};
  line-height: 18px;
`;

const CustomPopover = styled(Popover)`
  background-color: black !important;
  left: 4em !important;
`;

const CustomPopoverBody = styled(PopoverBody)`
  color: ${white} !important;
`;

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      name: this.props.name,
      active: this.props.active,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps() {
    const self = this;
    setTimeout(() => {
      self.setState({
        active: self.props.active,
      });
    }, 100);
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  toggle = () => {
    return null;
  };

  togglePopover = () => {
    if (!this.state.isOpen) {
      this.handleOpen();
    } else {
      this.handleClose();
    }
  };

  render() {
    const {
      t,
      icon,
      name,
      title,
      pages,
      history,
      disabled,
      isProjectListEmpty,
    } = this.props;
    const { isOpen, active } = this.state;
    return (
      <Dropdown
        direction="right"
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
        isOpen={isOpen}
        toggle={!disabled && this.toggle}
        className="d-flex align-items-center align-content-center wrapper"
      >
        <ToggleCustom
          active={active || isOpen}
          tools={this.props.tools}
          onClick={() => history.push(pages[0].href)}
          disabled={!!disabled}
          id={`Menu-${name}`}
        >
          <Item disabled={!!disabled}>
            <div>
              <IconTC
                active={active}
                icon={icon}
                color={disabled ? grayShades.g800 : white}
                size={ImageSize.medium}
              />
            </div>
            <div>°{t(title)}</div>
          </Item>
        </ToggleCustom>
        {!disabled ? (
          <DropdownPortal pages={pages} />
        ) : (
          <CustomPopover
            placement="right-end"
            isOpen={isOpen}
            toggle={this.toggle}
            target={`Menu-${name}`}
            hideArrow
          >
            <CustomPopoverBody>
              {isProjectListEmpty
                ? t(
                    'You need to create a project first before having access to this module.',
                  )
                : t(
                    "You don't have access to this module. Please upgrade your plan in order to access this module.",
                  )}
            </CustomPopoverBody>
          </CustomPopover>
        )}
      </Dropdown>
    );
  }
}

export default withRouter(withTranslation('header')(MenuItem));
