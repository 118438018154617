import gql from 'graphql-tag';

const query = gql`
  query getSaasUploadConfig($uuid: String!) {
    getSaasUploadConfig(uuid: $uuid) {
      uuid
      useCase
      industry
      status
    }
  }
`;

export default query;
