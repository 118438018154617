/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CModal from '../../CModal/CModal';
import QButton from '../../Buttons/QButton/QButton';
import Icon from '_Components/Icons/Icon';
import QModalSection from '../../Modals/QModalSection/QModalSection';

const StyledQModalSection = styled(QModalSection)`
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  flex-direction: column;
  & > * {
    margin: 1em;
  }
`;

const StyledCModal = styled(CModal)`
  width: 500px !important;

  & .modal-content > *:first-child {
    padding-bottom: 0;
  }
`;

const StyledQButton = styled(QButton)`
  & > * {
    margin: 0 0.5em;
  }
`;

export default class QWarningModal extends Component {
  static propTypes = {
    message: PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
    }).isRequired,
    warning: PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }).isRequired,
    cancelText: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onAbortConfirmed: PropTypes.func.isRequired,
    onAbortCanceled: PropTypes.func.isRequired,
  };

  static defaultProps = {
    cancelText: 'Cancel',
    warning: {
      icon: 'WARNING',
    },
  };

  getFooter() {
    const {
      onAbortConfirmed,
      onAbortCanceled,
      warning,
      cancelText,
    } = this.props;
    return (
      <Fragment>
        <StyledQModalSection>
          <StyledQButton bgColor="red" onClick={onAbortConfirmed} icon={warning.icon} iconPosition="left">
            <span>{warning.text}</span>
          </StyledQButton>
          <QButton outline bgColor="grayShades.g800" onClick={onAbortCanceled}>
            {cancelText}
          </QButton>
        </StyledQModalSection>
      </Fragment>
    );
  }

  render() {
    const {
      isOpen,
      message: { title, text },
    } = this.props;
    return (
      <StyledCModal
        size="sm"
        data={{}}
        title={title}
        subtitleAdditionalContent={text}
        displayMentionsSubtitle={false}
        displayDateSubtitle={false}
        footer={this.getFooter()}
        modal={isOpen}
      />
    );
  }
}
