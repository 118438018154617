import React from 'react';
import { connect } from 'react-redux';
import { upperFirst, max, sortBy, find } from 'lodash';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import PropTypes from 'prop-types';
import { getColorHexaFromEindex } from '_Utils/eindex/eindexColorFunctions';
import { getMinMaxFromUnit } from '_Utils/eindex/eindexUnits';
import { QemotionTheme } from '../../Utils/qemotionTheme';

const ReactEcharts =
  typeof document !== 'undefined' && require('echarts-for-react').default;

class CBarGraphChart extends React.Component {
  static propTypes = {
    height: PropTypes.string,
  };

  static defaultProps = {
    height: 'calc(100% - 64px)',
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (typeof document !== 'undefined') {
      const echarts = require('echarts');
      echarts.registerTheme('QemotionTheme', QemotionTheme);
    }
  }

  getOption = data => ({
    grid: {
      bottom:
        this.props.orientation === 'vertical'
          ? '35%'
          : this.props.rotateLabel
          ? '25%'
          : 60,
      left: this.props.orientation === 'horizontal' ? '20%' : '10%',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: 'transparent',
        },
      },
    },
    xAxis:
      this.props.orientation === 'vertical'
        ? {
            type: 'category',
            axisLabel: {
              interval: 0,
              rotate: this.props.rotateLabel && 45,
            },
            axisLine: {
              show: false,
            },
            data: data && data.map(item => upperFirst(item.label)),
          }
        : [
            {
              type: 'value',
              min:
                this.props.filterKeys &&
                getMinMaxFromUnit(this.props.eindexUnitFahrenheit).min -
                  (this.props.eindexUnitFahrenheit ? 40 : 20),
              max:
                this.props.filterKeys &&
                getMinMaxFromUnit(this.props.eindexUnitFahrenheit).max,
              axisLabel: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              splitLine: {
                show: false,
              },
            },
            {
              type: 'value',
              min: 0,
              max:
                data &&
                max(
                  data.map(item => (item.mentionCount ? item.mentionCount : 0)),
                ) * 4,
              position: 'bottom',
              axisLabel: {
                formatter: '{value}',
              },

              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
            },
          ],
    yAxis:
      this.props.orientation === 'vertical'
        ? [
            {
              type: 'value',
              min:
                this.props.filterKeys &&
                getMinMaxFromUnit(this.props.eindexUnitFahrenheit).min -
                  (this.props.eindexUnitFahrenheit ? 40 : 20),
              max:
                this.props.filterKeys &&
                getMinMaxFromUnit(this.props.eindexUnitFahrenheit).max,
              axisLabel: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              splitLine: {
                show: false,
              },
            },
            {
              type: 'value',
              min: 0,
              max:
                data &&
                max(
                  data.map(item => (item.mentionCount ? item.mentionCount : 0)),
                ) * 4,
              position: 'left',
              axisLabel: {
                formatter: '{value}',
              },

              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
            },
          ]
        : {
            type: 'category',
            axisLabel: {
              interval: 0,
              rotate: this.props.rotateLabel && 45,
            },
            axisLine: {
              show: false,
            },
            data: data && data.map(item => upperFirst(item.label)),
          },
    series: [
      {
        type: 'graph',
        name: 'E-index',
        layout: 'none',
        coordinateSystem: 'cartesian2d',
        symbolSize: 32,
        itemStyle: {
          borderWidth: 4,
          borderColor: '#fff',
        },
        label: {
          show: true,
          formatter: '{c}°',
          fontSize: 12,
        },
        data:
          data &&
          data
            .filter(item => item.eindex !== null && item.mentionCount !== null)
            .map(item => ({
              value: item.eindex ? item.eindex : 0,
              itemStyle: {
                color: getColorHexaFromEindex(
                  item.eindex,
                  this.props.eindexUnitFahrenheit,
                ),
              },
            })),
        z: 2,
      },
      {
        name: 'Mentions',
        type: 'bar',
        data:
          data &&
          data
            .filter(item => item.eindex !== null && item.mentionCount !== null)
            .map(item => (item.mentionCount ? item.mentionCount : 0)),
        z: 1,
        yAxisIndex: this.props.orientation === 'vertical' ? 1 : 0,
        xAxisIndex: this.props.orientation === 'vertical' ? 0 : 1,
        barMaxWidth: 24,
        barMinHeight: 12,
        itemStyle: {
          color: '#592EA0',
          barBorderRadius:
            this.props.orientation === 'vertical'
              ? [12, 12, 0, 0]
              : [0, 12, 12, 0],
        },
      },
    ],
  });

  render() {
    const dataChart = this.props.datatoShow
      ? sortBy(
          find(this.props.data, { label: this.props.datatoShow }).values,
          'mentionCount',
        )
      : null;
    const { data } = this.props.data;
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          margin: '0',
        }}
      >
        {this.props.datatoShow ? (
          dataChart.length > 0 ? (
            <ReactEcharts
              option={this.getOption(dataChart)}
              style={{ height: 'calc(100% - 64px)', width: '100%' }}
              className="react_for_echarts"
              theme="QemotionTheme"
            />
          ) : (
            <NoDataMessage secondText="This category doesn't have any children category" />
          )
        ) : ReactEcharts ? (
          <ReactEcharts
            option={this.getOption(data)}
            style={{ height: this.props.height, width: '100%' }}
            className="react_for_echarts"
            theme="QemotionTheme"
          />
        ) : (
          ' '
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
    eindexUnitFahrenheit:
      state.projectConfiguration.unitsAndLocalesSettings.eindexUnitFahrenheit,
  };
}

export default connect(mapStateToProps)(CBarGraphChart);
