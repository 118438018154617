import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

const QDraggable = ({ id, index, render }) => (
  <Draggable draggableId={id ?? `draggableBox-${index}`} index={index}>
    {(provided, snapshot) => render(provided, snapshot)}
  </Draggable>
);

QDraggable.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired,
};

export default QDraggable;
