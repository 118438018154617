import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import Icon from '_Components/Icons/Icon';
import { red, white, grayShades } from 'styles/abstracts/colors';
import QButton from '_Components/Buttons/QButton/QButton';
import { withApollo, useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import { storeUserInfos } from '_Resources/Auth/Actions/auth';
import { connectToZoho, onLoginCompleted } from 'Routes/UtilityRoutes/Login/utils';
import GET_RECAPTCHA_VALIDATION_QUERY from '../../graphql/getRecaptchaValidation';
import logo2x from '../../img/qemotion_login-logo@2x.png';
import logo from '../../img/qemotion_login-logo.png';
import TwoFactorAuthenticationForm from '../TwoFactorAuthenticationForm/TwoFactorAuthenticationForm';
import SEND_OTP from '../../graphql/sendOTP.ts';
import RESEND_OTP from '../../graphql/resendOTP.ts';

const Error = styled.p`
  color: ${red};
  font-weight: bold;
`;

const ForgotPasswordText = styled.div`
  font-weight: bold;
  color: ${grayShades.g800};
  cursor: pointer;
  text-decoration: underline;
  font-size: ${({ windowDimensions: { width } }) => width < 521 && '0.75em'};
`;

const SignUpText = styled.div`
  font-weight: bold;
  color: ${grayShades.g800};
  & span {
    text-decoration: underline;
    cursor: pointer;
  }
  font-size: ${({ windowDimensions: { width } }) => width < 521 && '0.75em'};
  margin-bottom: ${({ windowDimensions: { height } }) => {
    if (height <= 520) {
      return '1rem';
    }
    if (height < 840) {
      return '1.5rem';
    }
    if (height < 900) {
      return '2rem';
    }
    return '2.5rem';
  }};
`;

const CaptchaContainer = styled.div`
  margin-top: 1em;
`;

const LoginContainer = styled.div`
  position: relative;
  top: 0px;
  width: ${({ windowDimensions: { width } }) => {
    if (width < 576) {
      return '87vw';
    }
    if (width < 768) {
      return '77vw';
    }
    if (width < 992) {
      return '57vw';
    }
    return '37vw';
  }};
  display: block;
`;

const StyledLogoCol = styled(Col)`
  margin-top: ${({ windowDimensions: { height } }) => {
    if (height < 840) {
      return '1rem !important';
    }
    return '2.5rem';
  }};
  margin-bottom: ${({ windowDimensions: { height } }) => {
    if (height < 840) {
      return '1rem !important';
    }
    return '2.5rem';
  }};
`;

const StyledTitleCol = styled(Col)`
  margin-bottom: ${({ windowDimensions: { height } }) => {
    if (height < 840) {
      return '1rem !important';
    }
    return '2.5rem';
  }};
`;

const LoginForm = ({
  history,
  dispatchStoreUserInfos,
  setToken,
  rollbar,
  authInfos,
  client,
  toggleForgotPassword,
  t,
  windowDimensions,
}) => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaSuccess, setCaptchaSuccess] = useState(null);
  const [twoFactorAuthentication, setTwoFactorAuthentication] = useState(false);
  const { errors } = authInfos;
  const [twoFAHeight, setTwoFAHeight] = useState(0);
  const formRef = useRef(null);
  const [sendOtp, { data, loading, error }] = useMutation(SEND_OTP, {
    variables: { email: usernameOrEmail, password },
    onCompleted: ({ signIn }) => {
      !error && signIn?.user?.otpEnabled === true && setTwoFactorAuthentication(true);

      !error &&
        signIn?.user?.otpEnabled === false &&
        onLoginCompleted(signIn, dispatchStoreUserInfos, setToken, client, () =>
          history.push('/main', { fromLogin: true }),
        );
    },
    onError: e =>
      process.env.NODE_ENV === 'production' &&
      rollbar.info(e, {
        email: usernameOrEmail,
      }),
  });

  const [resendOtp, { data: resendOTPData, loading: resendOTPLoading, error: resendOTPError }] = useMutation(
    RESEND_OTP,
    {
      variables: { email: usernameOrEmail, password, resendOtp: true },
    },
  );

  useEffect(() => {
    const isLoggedIn = window.localStorage.getItem('LOGGED-IN') === 'true';
    const isToken = window.localStorage.getItem('token');
    if (isToken && isLoggedIn) {
      if (window.location.search && window.location.search.includes('help.cxinsights.io')) {
        const userId = window.localStorage.getItem('userID');
        userId && connectToZoho(client);
      } else {
        history.push('/main', { fromLogin: true });
      }
    }
  }, []);

  useEffect(() => {
    setTwoFAHeight(formRef?.current?.offsetTop);
  });

  return (
    <LoginContainer className="justify-content-center flex-column" windowDimensions={windowDimensions}>
      <form
        name="loginForm"
        method="post"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div>
          <StyledLogoCol
            className="mb-lg-5 mt-lg-5 mx-auto d-flex justify-content-center"
            windowDimensions={windowDimensions}
          >
            <img src={logo} srcSet={`${logo} 1x, ${logo2x} 2x`} alt="Q°emotion" className="m-auto img-fluid" />
          </StyledLogoCol>
        </div>
        <StyledTitleCol
          className="mb-lg-5 mb-3 mx-auto d-flex justify-content-center p-0"
          windowDimensions={windowDimensions}
        >
          <h3 className="text-center text-md-left">{t('Sign in to your account')}</h3>
        </StyledTitleCol>

        <div ref={formRef}>
          <Col xs={12} sm={10} className={windowDimensions.width > 520 && 'mx-auto'}>
            <FormGroup>
              <Label for="login_email" className="ml-1dot5 text-secondary">
                {t('Company email address')}
              </Label>
              <Input
                type="email"
                name="usernameOrEmail"
                id="usernameOrEmail"
                size={windowDimensions.width > 520 ? 'lg' : 'md'}
                placeholder=""
                value={usernameOrEmail}
                onChange={e => setUsernameOrEmail(e.target.value)}
                autoFocus
              />
            </FormGroup>
            <FormGroup className="mb-0">
              <Label for="login_password" className="ml-1dot5 text-secondary">
                {t('Password')}
              </Label>
              <Input
                type="password"
                name="password"
                id="login_password"
                size={windowDimensions.width > 520 ? 'lg' : 'md'}
                placeholder=""
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </FormGroup>
          </Col>
        </div>
        <Col className={`mt-4 ${windowDimensions.width > 520 && 'mb-2'} p-0 d-flex justify-content-center`}>
          <CaptchaContainer>
            <ReCAPTCHA
              sitekey="6Lf7qtsZAAAAAF1sO_Zdj3DJYYfhptRFL5WAWPH9"
              onChange={async value => {
                await client
                  .query({
                    query: GET_RECAPTCHA_VALIDATION_QUERY,
                    variables: { token: value },
                  })
                  .then(res => setCaptchaSuccess(res.data.getRecaptchaValidation.success));
              }}
            />
          </CaptchaContainer>
        </Col>
        <Col xs={12} sm={12} className={`text-center ${windowDimensions.width > 520 && 'mx-auto'}`}>
          {errors.map((item, index) => (
            <>
              <div className={windowDimensions.width > 520 && 'mt-4 mb-2'}>
                <Icon icon="EMOTION_SADNESS" color={red} size={48} />
              </div>
              <Error key={`errors_key_${index * 2}`}>{item.message}</Error>
            </>
          ))}
          <QButton
            bgColor="red"
            size="large"
            className={`px-5 mb-3 ${windowDimensions.width > 520 ? 'mt-1' : 'mt-4'}`}
            type="submit"
            disabled={!captchaSuccess}
            onClick={() => {
              const formToReset = document.querySelector('[name=loginForm]');
              formToReset.reset();
              sendOtp({
                variables: {
                  email: usernameOrEmail,
                  password,
                },
              });
            }}
          >
            {loading ? <Icon icon="INTERFACE_LOADING" color={white} /> : t('button:Sign in')}
          </QButton>
          <br />
          <SignUpText windowDimensions={windowDimensions}>
            {t("Don't have an account yet?")} <br />
            <span onClick={() => history.push('/sign-up')}>{t('button:Sign up!')}</span>
          </SignUpText>
          <ForgotPasswordText windowDimensions={windowDimensions} onClick={() => toggleForgotPassword(true)}>
            {t('button:Forgot password?')}
          </ForgotPasswordText>
        </Col>
        <TwoFactorAuthenticationForm
          twoFactorAuthentication={!!twoFactorAuthentication}
          setTwoFactorAuthentication={setTwoFactorAuthentication}
          resendOtp={resendOtp}
          email={usernameOrEmail}
          password={password}
          dispatchStoreUserInfos={dispatchStoreUserInfos}
          setToken={setToken}
          client={client}
          history={history}
          rollbar={rollbar}
          twoFAHeight={twoFAHeight}
          windowDimensions={windowDimensions}
        />
      </form>
    </LoginContainer>
  );
};

LoginForm.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchStoreUserInfos: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  rollbar: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  authInfos: PropTypes.objectOf(PropTypes.any).isRequired,
  client: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleForgotPassword: PropTypes.func.isRequired,
  windowDimensions: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  authInfos: state.auth,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatchStoreUserInfos: (user, token) => {
      dispatch(storeUserInfos(user, token));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(LoginForm));
