import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import QTabs from '_Components/Panels/QTabs/QTabs';
import QTab from '_Components/Panels/QTabs/tab/QTab';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import QButton from '_Components/Buttons/QButton/QButton';
import Icon from '_Components/Icons/Icon';
import { white, black, grayShades } from 'styles/abstracts/colors';
import ExportDataModal from '_Cards/SettingsCards/ProjectDetailsCard/_Components/ProcessingHistory/_Components/ProcessingHistoryHeader/_Components/ExportDataModal/ExportDataModal';
import EXPORT_CATEGORY_RANKING_DATA_QUERY from '_Cards/ThematicsCard/ThematicsOverviewCards/graphql/exportCategoryRankingData';
import { RadioButton, CheckboxContainer } from '_Charts/LineCharts/CLineChart/CLineChartSettings';
import { MenuItemLabel } from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Components/InsightModeMenu/_Components/InsightModeMenuOption';

const StyledQTabs = styled(QTabs)`
  padding: 0;
  background-color: ${white};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${black};
`;

const SwitcherContainer = styled.div`
  /* margin: auto; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2em;
  & ${MenuItemLabel} {
    margin-right: 0.5em !important;
  }
  & ${CheckboxContainer} {
    margin-right: 1em;
  }
`;

const ButtonsContainer = styled.div`
  /* height: 100%; */
  display: flex;
  align-items: center;
  margin-right: 1em;
`;

const StyledQButton = styled(QButton)`
  display: flex !important;
  align-items: center;
  justify-content: space-around;

  & span {
    margin-left: 0.5em;
    margin-top: 0.1em;
  }
`;

const SubHeader = ({
  currentTab,
  setCurrentTab,
  isDetailsToggled,
  toggleDetails,
  t,
  setMessage,
  filterKeys,
  currentGraphView,
  setCurrentGraphView,
}) => {
  const location = useLocation();
  const [isModalOpen, toggleModalOpen] = useState(location?.state?.exportLink);
  const TABS = [
    { id: 'category-hierarchy', label: t('Category hierarchy') },
    { id: 'category-ranking', label: t('Category ranking') },
    { id: 'graph-view', label: t('Graph view') },
  ];

  useEffect(() => {
    toggleDetails(false);
  }, [currentTab]);

  return (
    <Container>
      <StyledQTabs
        initialPage={currentTab}
        onTabChanged={value => {
          setCurrentTab(value);
        }}
      >
        {TABS.map(tab => (
          <QTab id={tab.id}>{tab.label}</QTab>
        ))}
      </StyledQTabs>
      <ButtonsContainer>
        {(currentTab === 'category-ranking' ||
          (currentTab === 'graph-view' && currentGraphView === 'emotions-trigger')) && (
          <SwitcherContainer>
            <QBooleanSwitch
              value={isDetailsToggled}
              onChanged={() => toggleDetails(!isDetailsToggled)}
              label={t('Details')}
              labelColor={grayShades.g800}
            />
          </SwitcherContainer>
        )}
        {currentTab === 'graph-view' && (
          <SwitcherContainer>
            <CheckboxContainer>
              <MenuItemLabel checked={currentGraphView === 'category-breakdown'}>
                <RadioButton
                  type="radio"
                  checked={currentGraphView === 'category-breakdown'}
                  value="data-by-thematics"
                  onChange={() => setCurrentGraphView('category-breakdown')}
                />
              </MenuItemLabel>
              <Icon icon="PIE_CHART" />
            </CheckboxContainer>
            <CheckboxContainer>
              <MenuItemLabel checked={currentGraphView === 'emotions-trigger'}>
                <RadioButton
                  type="radio"
                  checked={currentGraphView === 'emotions-trigger'}
                  value="emotions-trigger"
                  onChange={() => setCurrentGraphView('emotions-trigger')}
                />
              </MenuItemLabel>
              <Icon icon="BADGE_INDICATOR" />
            </CheckboxContainer>
            <CheckboxContainer>
              <MenuItemLabel checked={currentGraphView === 'data-by-thematics'}>
                <RadioButton
                  type="radio"
                  checked={currentGraphView === 'data-by-thematics'}
                  value="data-by-thematics"
                  onChange={() => setCurrentGraphView('data-by-thematics')}
                />
              </MenuItemLabel>
              <Icon icon="BAR_INDICATOR" />
            </CheckboxContainer>
            <CheckboxContainer>
              <MenuItemLabel checked={currentGraphView === 'horizontal-thematics'}>
                <RadioButton
                  type="radio"
                  checked={currentGraphView === 'horizontal-thematics'}
                  value="horizontal-thematics"
                  onChange={() => setCurrentGraphView('horizontal-thematics')}
                />
              </MenuItemLabel>
              <Icon icon="HORIZONTAL_BAR_CHART" />
            </CheckboxContainer>
          </SwitcherContainer>
        )}
        {currentTab === 'category-ranking' && (
          <StyledQButton
            outline
            bgColor="lightBlue"
            onClick={() => toggleModalOpen(true)}
            icon="DOWNLOAD"
            iconPosition="left"
            iconSize={16}
          >
            <span>{t('button:Export category ranking')}</span>
          </StyledQButton>
        )}
        <ExportDataModal
          t={t}
          setMessage={setMessage}
          isOpen={isModalOpen}
          open={toggleModalOpen}
          projectId={filterKeys.projectId}
          query={EXPORT_CATEGORY_RANKING_DATA_QUERY}
          additionalVariables={{ ...filterKeys }}
        />
      </ButtonsContainer>
    </Container>
  );
};

SubHeader.propTypes = {
  setCurrentTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  filterKeys: get(state, ['filters', 'filterKeys']),
});

export default withTranslation('card', ['button'])(connect(mapStateToProps)(SubHeader));
