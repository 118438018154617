import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { Route } from 'react-router';

const GoogleAnalytics = ({ location: { pathname, search }, options }) => {
  const logPageChange = (pathname, search = '') => {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...options,
    });
    ReactGA.send({ hitType: 'pageview', page: pathname });
  };

  useEffect(() => {
    logPageChange(pathname, search);
  }, [pathname, search]);

  return null;
};

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  options: PropTypes.object,
};

GoogleAnalytics.defaultProps = {
  options: {},
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (options = {}) => {
  const isGAEnabled = process.env.REACT_APP_ENV === 'PROD';

  if (isGAEnabled) {
    ReactGA.initialize('G-ZENHBVG5G5');
  }

  return isGAEnabled;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
};
