import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import { uniq, get, omit, capitalize } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { QemotionSentimentThemeColors, purple } from 'styles/abstracts/colors';
import { QemotionTheme } from '../../Utils/qemotionTheme';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

type CLinesChartProps = {
  data: any;
  mixLineBarChart: boolean;
  eindexUnitFahrenheit: boolean;
  constant: any;
  valueAbsolute: 'percentage' | 'absolute';
  analysisAxis: 'sentiment' | 'emotion';
  t: any;
};

const CLinesChart = ({
  data,
  mixLineBarChart,
  eindexUnitFahrenheit,
  constant,
  valueAbsolute,
  analysisAxis,
  t,
}: CLinesChartProps) => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const echarts = require('echarts');
      if (analysisAxis === 'sentiment') {
        const QemotionSentimentTheme = { ...QemotionTheme, color: QemotionSentimentThemeColors };
        echarts.registerTheme('QemotionSentimentTheme', QemotionSentimentTheme);
      } else {
        echarts.registerTheme('QemotionTheme', QemotionTheme);
      }
    }
  }, [analysisAxis]);

  const getDataInPercentage = data => {
    const arr = JSON.parse(JSON.stringify(data)).emotionEvolution || JSON.parse(JSON.stringify(data));
    const analysisAxisToRead = analysisAxis ? `${analysisAxis}s` : 'emotions';

    arr.forEach(item => {
      let sum = 0;
      Object.keys(omit(item[analysisAxisToRead], '__typename')).map(analysisItem => {
        sum += item[analysisAxisToRead][analysisItem];
        return null;
      });
      Object.keys(omit(item[analysisAxisToRead], '__typename')).map(analysisItem => {
        item[analysisAxisToRead][analysisItem] = Math.round((item[analysisAxisToRead][analysisItem] * 1000) / sum) / 10;
        return null;
      });
    });
    return arr;
  };

  const getLineCategories = () => {
    const arr = [];

    Object.keys(constant).map(lineCategory => arr.push(capitalize(t(lineCategory))));
    return arr;
  };

  const createItemLine = (lineCategory, data) => {
    const analysisAxisToRead = analysisAxis ? `${analysisAxis}s` : 'emotions';

    const json = {
      name: capitalize(t(lineCategory)),
      color: constant[lineCategory].color,
      data: data.map(item => item[analysisAxisToRead][lineCategory]),
    };
    return json;
  };

  const transformDatatoLine = data => {
    const arr = [];
    for (const lineCategory in constant) {
      arr.push(createItemLine(lineCategory, data));
    }
    return arr;
  };

  const getSeries = data => {
    const generatedSeries = transformDatatoLine(data).map(line => ({
      name: line.name,
      type: 'line',
      connectNulls: true,
      smooth: true,
      lineStyle: {
        width: 3,
        color: line.color,
      },
      itemStyle: {
        color: line.color,
      },
      symbol: 'circle',
      data: line.data,
      animation: true,
      animationDuration: 1000,
      animationEasing: 'quadraticInOut',
    }));
    return generatedSeries;
  };

  const getOption = d => {
    const formattedData = data.emotionEvolution || data;
    return {
      tooltip: {
        trigger: 'axis',
        formatter: a => {
          let { name } = a[0];
          let isDataAvailable = false;
          a.forEach(item => {
            if (item.value !== undefined && !isNaN(item.value)) {
              isDataAvailable = true;
              name += `</br>${item.marker} ${item.seriesName}: ${item.value}${
                valueAbsolute === 'absolute' ? '' : ' %'
              }`;
            }
          });
          return isDataAvailable ? name : `${a[0].name} <br/> No data available`;
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        feature: {
          dataZoom: {
            title: {
              zoom: t('graph:Zoom'),
              back: t('graph:Zoom reset'),
            },
            // yAxisIndex: 'none',
            iconStyle: {
              borderColor: purple,
            },
          },
          dataView: {
            title: t('graph:View data'),
            lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
            readOnly: false,
            textColor: purple,
            buttonColor: purple,
            iconStyle: {
              borderColor: purple,
            },
          },
          magicType: {
            title: {
              line: t('graph:Switch to line'),
              bar: t('graph:Switch to bar'),
              stack: t('graph:Stack'),
              tiled: t('graph:Tile'),
            },
            type: ['line', 'bar', 'stack'],
            iconStyle: {
              borderColor: purple,
            },
          },
          restore: {
            title: t('graph:Reload'),
            iconStyle: {
              borderColor: purple,
            },
          },
          saveAsImage: {
            title: t('graph:Save as image'),
            name: t('graph:Graph Q°Emotion'),
            backgroundColor: '#FFFFFF',
            iconStyle: {
              borderColor: purple,
            },
          },
        },
        emphasis: {
          iconStyle: {
            borderWidth: 2,
            borderColor: purple,
            textBackgroundColor: purple,
            textFill: '#FFFFFF',
            textBorderRadius: [5, 5, 5, 5],
            textPadding: [6, 6, 4, 6],
          },
        },
      },
      legend: {
        orient: 'horizontal',
        data: getLineCategories(),
      },
      xAxis: {
        type: 'category',
        data: uniq(formattedData?.map(item => (item ? moment(item.date).format('L') : undefined))),
      },
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter(value) {
              return valueAbsolute === 'absolute' ? value : `${value} %`;
            },
          },
        },
      ],
      series: getSeries(d),
    };
  };

  const optionData = valueAbsolute === 'absolute' ? data : getDataInPercentage(data);
  return (
    <Col className="text-center" style={{ height: '100%' }}>
      <div style={{ height: 'calc(100% - 64px)' }}>
        {ReactEcharts && (
          <ReactEcharts
            option={getOption(optionData)}
            style={{ height: '100%', width: '100%' }}
            className="react_for_echarts"
            theme="QemotionTheme"
          />
        )}
      </div>
    </Col>
  );
};

const mapStateToProps = state => {
  return {
    filterKeys: state.filters.filterKeys,
    eindexUnitFahrenheit: get(state, ['projectConfiguration', 'unitsAndLocalesSettings', 'eindexUnitFahrenheit']),
  };
};

export default connect(mapStateToProps)(withTranslation('emotion', ['graph'])(CLinesChart));
