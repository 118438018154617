import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { withRouter } from 'react-router';
import ReportingFloatingActionButton from 'Routes/GlobalExperience/Reporting/_Containers/ReportingFloatingActionButton/ReportingFloatingActionButton';
import { getRoute } from '_Utils/routes/routeUtils';
import ReportingSidebar from './_Containers/ReportingSidebar';
import ReportPageList from './_Containers/ReportPageList';
import QDnD from '../../../_Components/Panels/QDnD/QDnD';
import { addPageByShortcut } from '../../../_Resources/Reporting/Actions/addPageByShortcut';
import emptyLayouts from './_Containers/layouts/emptyLayouts';
import predefinedLayouts from './_Containers/layouts/predefinedLayouts';
import ReportingHeader from './_Containers/ReportingHeader/ReportingHeader';
import { changePageByShortcut } from '../../../_Resources/Reporting/Actions/changePageByShortcut';
import ReportingLoaderCurtain from './_Containers/ReportingLoaderCurtain/ReportingLoaderCurtain';

const StyledReportingSidebar = styled(ReportingSidebar)`
  flex-shrink: 0;
  flex-grow: 1;
  ${'' /* overflow: hidden; */}
`;

const StyledReportPageList = styled(ReportPageList)`
  flex-grow: 1;
`;

const StyledQDnD = styled(QDnD)`
  display: flex;
  flex-direction: column;
  top: 64px;
  height: calc(100vh - 122px);
  width: 100%;
  position: relative;
  top: 58px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const Page = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  //top: -16px;
`;

const HeaderWrapper = styled.div`
  position: fixed;
  width: calc(100vw - 5em);
  z-index: 2;
`;

export class Reporting extends Component {
  static propTypes = {
    // state props
    exportActive: PropTypes.bool.isRequired,
    isPreviewMode: PropTypes.bool.isRequired,
    pageIndexOver: PropTypes.number,

    // dispatch props
    dispatchUpdateRoute: PropTypes.func.isRequired,
    dispatchAddPage: PropTypes.func.isRequired,
    dispatchChangePageByShortcut: PropTypes.func.isRequired,
  };

  static defaultProps = {
    pageIndexOver: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      draggableId: null,
      droppableId: null,
    };
  }

  idPage = 'reporting';

  pageUpdate = layout => {
    const { pageIndexOver } = this.props;
    if (pageIndexOver === null) {
      this.props.dispatchAddPage(layout);
    } else {
      this.props.dispatchChangePageByShortcut({
        size: layout.size,
        children: layout.children,
        cssGridLayout: layout.cssGridLayout,
        pageIndex: pageIndexOver,
      });
    }
  };

  dragCallbacks = {
    'empty-layouts': id => this.pageUpdate(emptyLayouts[id]),
    'predefined-layouts': id => this.pageUpdate(predefinedLayouts[id]),
    'card-layouts': () => {},
  };

  componentDidMount() {
    const { dispatchUpdateRoute, history } = this.props;
    if (!this.props.reportingId) {
      history.push('reporting-main', { fromReporting: true });
    }
    getRoute(dispatchUpdateRoute, this.idPage);
  }

  onDragStart = (...args) => {
    const [
      {
        draggableId,
        source: { droppableId },
      },
    ] = args;
    this.setState({ draggableId, droppableId });
  };

  onDragRelease = () => {
    const { draggableId, droppableId } = this.state;
    this.dragCallbacks[droppableId](draggableId);
    this.setState({ draggableId: null, droppableId: null });
  };

  render() {
    const { isPreviewMode, exportActive } = this.props;
    return (
      <Page>
        <HeaderWrapper>
          <div>
            <ReportingHeader />
          </div>
        </HeaderWrapper>
        <StyledQDnD onDragStart={this.onDragStart} onDragRelease={this.onDragRelease} previewMode={isPreviewMode}>
          {exportActive && <ReportingLoaderCurtain />}

          <Wrapper>
            {!isPreviewMode && <StyledReportingSidebar />}
            <StyledReportPageList />
            <ReportingFloatingActionButton />
          </Wrapper>
        </StyledQDnD>
      </Page>
    );
  }
}

const mapStateToProps = ({
  reporting: {
    isPreviewMode,
    globalSettings: { dateRange },
    pageIndexOver,
    exportActive,
    id,
  },
  periods: { currentPeriod },
}) => ({
  exportActive,
  isPreviewMode,
  currentPeriod,
  dateRange,
  pageIndexOver,
  reportingId: id,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdateRoute: newState => {
    dispatch(updateRoute(newState));
  },
  dispatchAddPage: shortcut => {
    dispatch(addPageByShortcut(shortcut));
  },
  dispatchChangePageByShortcut: (...args) => dispatch(changePageByShortcut(...args)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reporting));
