import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { getFiltersNumberOfMentions } from '_Resources/Header/Actions/getFiltersNumberOfMentions';
import { DispatchDataToStore } from '_Resources/Header/Services/api/DispatchDataToStore';
import { get } from 'lodash';
import querySchema from './graphql/getHeaderMentionsCountData';

class QueryMentionsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getFilters();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.filters && this.props.filters !== prevProps.filters) ||
      this.props.filterKeys !== prevProps.filterKeys
    ) {
      this.getFilters();
    }
  }

  getFilters() {
    let updatedFilterKeys = this.props.filterKeys;
    if (updatedFilterKeys && updatedFilterKeys.texts) {
      updatedFilterKeys.texts.map(text => {
        updatedFilterKeys = {
          ...updatedFilterKeys,
          [text.name]: text.values,
        };
      });
      delete updatedFilterKeys.texts;
    }
    this.setQueryVariables(this.props.filters ? this.props.filters : updatedFilterKeys);
  }

  setQueryVariables(variablesForQuery) {
    if (!variablesForQuery) {
      return;
    }
    if (variablesForQuery.projectId && variablesForQuery.range) {
      this.setState({ variablesForQuery });
    }
  }

  render() {
    const { getFiltersNumberOfMentions } = this.props;
    const { variablesForQuery } = this.state;
    return (
      <>
        {get(this.props, 'filterKeys.projectId') && (
          <Query query={querySchema} variables={{ ...variablesForQuery }} fetchPolicy="network-only">
            {({ loading, error, data, networkStatus }) => {
              if (loading || networkStatus === 4) return false;
              if (error) {
                return false;
              }
              return <DispatchDataToStore data={data} action={() => getFiltersNumberOfMentions(data)} />;
            }}
          </Query>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getFiltersNumberOfMentions: mentions => {
      dispatch(getFiltersNumberOfMentions(mentions));
    },
  };
}

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryMentionsHeader);
