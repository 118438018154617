import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Icon from '_Components/Icons/Icon';
import { white, lightPurple, purple } from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import VideoBlock from './_Components/VideoBlock/VideoBlock';
import QcardPanel from '../../../../../_Components/QcardPanel/QcardPanel';

const SliderArrow = styled.button`
  height: 27px !important;
  width: 27px !important;
  &:focus svg {
    border: 1px solid ${purple};
  }
`;

const SliderArrowSVG = styled(Icon)`
  display: block !important;
  color: ${white} !important;
  background-color: ${lightPurple} !important;
  border-radius: 50% !important;
  height: 25px !important;
  width: 25px !important;
  padding: 3px !important;
  transition-duration: 0.5s !important;
  transition-timing-function: ease-in-out !important;
  position: relative;
  top: -22px;
  &:hover {
    background-color: ${purple} !important;
  }
`;

const Arrow = props => {
  const { className, onClick, left, right } = props;
  return (
    <>
      {left && (
        <SliderArrow
          className={className}
          style={{
            left: '0',
            zIndex: 10,
          }}
          onClick={onClick}
          type="button"
        >
          <SliderArrowSVG
            class={className}
            style={{
              left: '0',
              zIndex: '10',
            }}
            icon="INTERFACE_CHEVRON_LEFT"
            color={white}
            onClick={onClick}
            size={ImageSize.medium}
          />
        </SliderArrow>
      )}
      {right && (
        <SliderArrow
          className={className}
          style={{
            right: '0',
          }}
          onClick={onClick}
          type="button"
        >
          <SliderArrowSVG
            class={className}
            style={{
              right: '0',
            }}
            icon="INTERFACE_CHEVRON_RIGHT"
            color={white}
            onClick={onClick}
            size={ImageSize.medium}
          />
        </SliderArrow>
      )}
    </>
  );
};

const TricksAndTipsCard = props => {
  const userLang = window.localStorage.getItem('lang');

  const corporateData = [
    {
      name: props.t('Welcome on CXInsights.io!'),
      video: 'https://dv0n152gwy1ag.cloudfront.net/welcomeVideo.mp4',
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_welcome_to_cxinsights_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('Average E-index'),
      video: 'https://dv0n152gwy1ag.cloudfront.net/eindexTemperature.mp4',
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_average_e_index_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('Global best & pain points'),
      video: 'https://dv0n152gwy1ag.cloudfront.net/globalBestAndPainPoints.mp4',
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_success_points_and_points_to_improve_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('General presentation'),
      video: `https://dv0n152gwy1ag.cloudfront.net/general_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.mp4`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_global_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('Thematic overview presentation'),
      video: `https://dv0n152gwy1ag.cloudfront.net/thematic_overview_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.${userLang === 'fr' ? 'mp4' : 'mov'}`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_thematics_overview_module_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('People and Digital modules presentation'),
      video: `https://dv0n152gwy1ag.cloudfront.net/people_and_digital_modules_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.mp4`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_people_&_digital_modules_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('Moments module presentation'),
      video: `https://dv0n152gwy1ag.cloudfront.net/moments_module_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.mp4`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_moments_module_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
  ];

  const saasData = [
    {
      name: props.t('Configure a new project'),
      video: `https://dv0n152gwy1ag.cloudfront.net/saas-configure-a-project-${
        userLang !== 'es' && userLang !== 'de' ? userLang : 'en'
      }.mp4`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_configure_a_project_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('Add a source'),
      video: `https://dv0n152gwy1ag.cloudfront.net/saas-add-a-source-${
        userLang !== 'es' && userLang !== 'de' ? userLang : 'en'
      }.mp4`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_add_a_source_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('Upload your data file'),
      video: `https://dv0n152gwy1ag.cloudfront.net/saas-file-upload-${
        userLang !== 'es' && userLang !== 'de' ? userLang : 'en'
      }.mp4`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_upload_a_file_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('General presentation'),
      video: `https://dv0n152gwy1ag.cloudfront.net/general_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.mp4`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_global_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('Thematic overview presentation'),
      video: `https://dv0n152gwy1ag.cloudfront.net/thematic_overview_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.${userLang === 'fr' ? 'mp4' : 'mov'}`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_thematics_overview_module_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('People and Digital modules presentation'),
      video: `https://dv0n152gwy1ag.cloudfront.net/people_and_digital_modules_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.mp4`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_people_&_digital_modules_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
    {
      name: props.t('Moments module presentation'),
      video: `https://dv0n152gwy1ag.cloudfront.net/moments_module_presentation_${
        userLang === 'fr' ? 'fr' : 'en'
      }.mp4`,
      thumbnail: `https://dv0n152gwy1ag.cloudfront.net/thumbnail_moments_module_${
        userLang === 'fr' ? 'fr' : 'en'
      }.png`,
    },
  ];

  const data =
    props.currentPlan === 'emotion_corporate' ? corporateData : saasData;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    arrows: true,
    slidesToScroll: 1,
    appendDots: dots => (
      <div
        style={{
          bottom: '0px',
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    prevArrow: <Arrow left />,
    nextArrow: <Arrow right />,
  };

  return (
    <QcardPanel {...props}>
      <Slider {...settings}>
        {data.map(d => d.video && <VideoBlock data={d} />)}
      </Slider>
    </QcardPanel>
  );
};

const mapStateToProps = state => ({
  currentPlan: get(state, ['auth', 'user', 'account', 'planName']),
});

export default connect(mapStateToProps)(
  withTranslation('card')(TricksAndTipsCard),
);
