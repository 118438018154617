import gql from 'graphql-tag';

const cancelEmotionalError = gql`
  mutation cancelEmotionalError($projectId: Int!, $claimId: String!) {
    cancelEmotionalError(projectId: $projectId, claimId: $claimId) {
      errors
      claim
    }
  }
`;

export default cancelEmotionalError;
