import { FILTERS_REMOVE_STRATEGIES } from '../../../../_Utils/filters/filterUtils';
import { rawFilterValues } from '../../../../_Utils/filters/rawFilterValues';

export const addPageFilter = (state, { filterType, filterValues }) => {
  const { currentPageIndex, children } = state;
  const currentPage = children[currentPageIndex];
  const filters = currentPage.filters || {};
  const existingFilters = filters[filterType] || [];

  return {
    ...state,
    children: children.map((page, index) =>
      index !== currentPageIndex
        ? page
        : {
            ...page,
            filters: {
              ...filters,
              [filterType]: [
                ...existingFilters,
                ...rawFilterValues(filterValues),
              ],
            },
          },
    ),
  };
};

export const removePageFilter = (
  state,
  { filterType, filterValues: [[filterValue], parentKey] },
) => {
  const { currentPageIndex, children } = state;
  const currentPage = children[currentPageIndex];
  const filters = currentPage.filters || {};
  const existingFilters = filters[filterType] || [];

  const removingStrategy =
    FILTERS_REMOVE_STRATEGIES[filterType] || FILTERS_REMOVE_STRATEGIES.default;

  return {
    ...state,
    children: children.map((page, index) =>
      index !== currentPageIndex
        ? page
        : {
            ...page,
            filters: {
              ...filters,
              [filterType]: removingStrategy(
                existingFilters,
                filterValue,
                parentKey,
              ),
            },
          },
    ),
  };
};
