import React from 'react';
import { withTranslation } from 'react-i18next';
import { map, omit } from 'lodash';
import { EMOTIONS, positiveEmotions, neutralEmotions, negativeEmotions } from 'constants/index';
import { sentimentColors } from 'styles/abstracts/colors';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

class HalfPieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getData(data, sentiment) {
    if (!data) return;
    const collectionOfEmotion = map(omit(data, '__typename'), (value, prop) => ({ name: prop, value }));
    const collectionOfSentiment = [
      {
        name: 'positive',
        value: Object.keys(positiveEmotions).reduce((sum, key) => {
          const value = data[key];
          return data[key] ? sum + value : sum;
        }, 0),
      },
      {
        name: 'neutral',
        value: Object.keys(neutralEmotions).reduce((sum, key) => {
          const value = data[key];
          return data[key] ? sum + value : sum;
        }, 0),
      },
      {
        name: 'negative',
        value: Object.keys(negativeEmotions).reduce((sum, key) => {
          const value = data[key];
          return data[key] ? sum + value : sum;
        }, 0),
      },
    ];
    const d = sentiment ? collectionOfSentiment : collectionOfEmotion;
    let a = 0;
    d.forEach(item => {
      a += item.value;
    });
    d.push({
      value: a,
      name: '',
      tooltip: {
        show: false,
      },
    });
    return d;
  }

  getOption() {
    const self = this;
    const { emotionResults, analysisAxis } = this.props;
    const sentiment = analysisAxis === 'sentiment' || window.localStorage.getItem('analysisAxis') === 'sentiment';
    return {
      tooltip: {
        trigger: 'item',
        formatter(a) {
          return `${self.props.t(a.data.name)}: ${Math.round(a.percent * 20) / 10}%`;
        },
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['50%', '70%'],
          startAngle: 180,
          clockwise: false,
          avoidLabelOverlap: false,
          color: sentiment
            ? [sentimentColors.positive, sentimentColors.neutral, sentimentColors.negative, 'transparent']
            : [
                EMOTIONS.happiness.color,
                EMOTIONS.surprise.color,
                EMOTIONS.calm.color,
                EMOTIONS.fear.color,
                EMOTIONS.sadness.color,
                EMOTIONS.anger.color,
                EMOTIONS.disgust.color,
                'transparent',
              ],
          label: {
            show: false,
            position: 'center',
            emphasis: {
              show: false,
              textStyle: {
                fontSize: '30',
                fontWeight: 'bold',
              },
            },
          },
          labelLine: {
            show: false,
          },
          itemStyle: {
            borderWidth: 3,
            borderColor: '#ffffff',
          },
          data: this.getData(emotionResults, sentiment),
        },
      ],
    };
  }

  render() {
    return (
      <div className="examples">
        <div className="parent">
          <div>
            {ReactEcharts ? (
              <ReactEcharts
                option={this.getOption()}
                style={{ height: '200px', width: '100%' }}
                className="react_for_echarts"
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('emotion')(HalfPieChart);
