export function convertCelsiusToFahrenheit(eindex) {
  return Math.round((parseInt(eindex) + 20) * 1.66);
}

export function convertFahrenheitToCelsius(eindex) {
  return Math.round(parseInt(eindex) / 1.66 - 20);
}

export function getMinMaxFromUnit(unitsInFahrenheit) {
  if (unitsInFahrenheit) {
    return { min: 0, max: 100 };
  }
  return { min: -20, max: 40 };
}
