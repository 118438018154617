import { equals } from "ramda";

export class Point {
  x = 0;
  y = 0;

  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  equals(point) {
    return equals(this, point);
  }

  toString() {
    return `(${this.x}, ${this.y})`;
  }
}
