import React from 'react';
import styled from 'styled-components';
import QPanel from '../QPanel/QPanel';
import { lightBlue } from '../../../styles/abstracts/colors';
import QFooter from '../QFooter/QFooter';
import { spacing } from '../../../styles/abstracts/variables';
import { TEXT_TYPES } from '../../Texts/textTypes';
import QLayout from '../../Layouts/QLayout/QLayout';
import { QHeader } from '../QHeader/QHeader';
import { hex2rgbaConvert } from '../../../_Utils/styles/colors';

const QPage = styled(QPanel)`
  position: relative;
  width: 842px;
  height: 592px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;

  & > * {
    flex-shrink: 0;
  }
  &:hover {
    background-color: ${hex2rgbaConvert(lightBlue, 10)};
    border: 2px solid ${lightBlue};
    transition: all 1s;
  }

  ${({ selected }) =>
    !selected
      ? null
      : `
        border: ${lightBlue} solid 2px;
      `};
`;

const StyledQFooter = styled(QFooter)`
  height: 4%;
  margin: 0 ${spacing.mediumLarge} ${spacing.mediumLarge};
`;

QPage.Header = styled.div`
  height: 16%;
  padding-bottom: 0;
  margin: ${({ type }) =>
    type === TEXT_TYPES.HEADER
      ? `${spacing.mediumLarge}`
      : `${spacing.mediumLarge} ${spacing.mediumLarge} 0`};
`;

QPage.Header.Title = styled(QHeader)`
  margin: 0;
  flex-shrink: 0;
  max-height: 110px;
  overflow: hidden;
`;

QPage.Body = styled.div`
  height: calc(80% - 2 * ${spacing.mediumLarge});
  position: relative;
`;

QPage.Body.Layout = styled(QLayout)`
  position: absolute;
`;

QPage.Footer = ({ ...args }) => <StyledQFooter {...args} />;

export default QPage;
