import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import InsightModeMenuOption from './InsightModeMenuOption';
import QAccordionPanelHeader from '../../../../../../../../_Components/Panels/QAccordion/QAccordionPanelHeader';
import QPanelHeader from '../../../../../../../../_Components/Panels/QPanelHeader/QPanelHeader';

const Body = styled(DropdownMenu)`
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 14px 0px !important;
  margin-left: 10px !important;
  padding: 1em 1em 0.5em 0 !important;
`;

export default class InsightModeMenuSection extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  createOptions() {
    const { item, onClick, selected } = this.props;
    return item.options.map(
      option =>
        ((option.id !== 'sat' && option.id !== 'reco' && option.id !== 'ces') ||
          (option.id === 'reco' && this.props.reco !== null) ||
          (option.id === 'sat' && this.props.sat !== 'null') ||
          (option.id === 'ces' && this.props.ces !== null)) && (
          <InsightModeMenuOption
            onClick={onClick}
            option={option}
            selected={selected}
          />
        ),
    );
  }

  render() {
    const {
      item: { title },
    } = this.props;
    const options = this.createOptions();
    return (
      <div>
        {title ? (
          <Dropdown
            direction="right"
            isOpen={this.state.isOpen}
            toggle={this.toggle}
          >
            <DropdownToggle tag="div">
              <QPanelHeader type="menu">
                <QAccordionPanelHeader title={title} isCollapsed />
              </QPanelHeader>
            </DropdownToggle>
            <Body>{options}</Body>
          </Dropdown>
        ) : (
          options
        )}
      </div>
    );
  }
}

InsightModeMenuSection.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};
