import { flatten, mergeWith, isArray, isEmpty } from 'lodash';

const prepareFilterWithParent = (filterStringValue, parent) => ({
  filterStringValue,
  parent,
});

const convertValuesToOptions = ({ option: { label: parentLabel, values } }) =>
  values.map(filterValue => prepareFilterWithParent(filterValue, parentLabel));

const mergeCustomizer = (srcVal, objVal, key) =>
  key === 'normal' && isArray(srcVal)
    ? srcVal.concat(objVal) // must be added as last el, look: ReportPageList:isPageIterated
    : undefined;

const prepareExtendPageWithFilter = (fsv, parent) => page =>
  mergeWith(
    {},
    page,
    {
      filters: {
        normal: [
          {
            option: { id: fsv, label: fsv },
            filterCategory: parent,
          },
        ],
      },
    },
    mergeCustomizer,
  );

export const getIterateNormalOptions = iterateByOptions =>
  iterateByOptions.filter(({ type }) => type !== 'thematics');

export const multiplyReportByNormalOptions = (iterateByNormalOptions, pages) =>
  flatten(
    flatten(iterateByNormalOptions.map(convertValuesToOptions)).map(
      ({ filterStringValue, parent }) =>
        pages.map(prepareExtendPageWithFilter(filterStringValue, parent)),
    ),
  );

export const multiplyReport = (iterateByOptions, pages) => {
  if (isEmpty(iterateByOptions)) {
    return pages;
  }
  const normalOptions = getIterateNormalOptions(iterateByOptions);

  return multiplyReportByNormalOptions(normalOptions, pages);
};
