import { getColorHexaFromEindex } from '_Utils/eindex/eindexColorFunctions';
import {
  getMinMaxFromUnit,
  convertCelsiusToFahrenheit,
} from '_Utils/eindex/eindexUnits';
import { upperFirst, max, min } from 'lodash';
import { purple, lightBlue } from '../../../styles/abstracts/colors';

export const FILTERS_DIMENSIONS = {
  MENTIONS: 'mentions',
  EINDEX: 'eindex',
  EMOTIONS: 'emotions',
  HAPPINESS: 'happiness',
  SURPRISE: 'surprise',
  SADNESS: 'sadness',
  DISGUST: 'disgust',
  ANGER: 'anger',
  FEAR: 'fear',
  CALM: 'calm',
  SATISFACTION: 'satisfaction',
  RECOMMENDATION: 'recommendation',
  EFFORT: 'effort',
};

export const INDICATORS_TYPE = {
  BADGE: 'badge',
  BAR: 'bar',
};

export const ORDER_BY_ITEMS = [
  {
    label: 'Alphabetical',
    value: 'label',
    subcategories: [
      {
        label: 'Alphabetical - Ascending',
        value: 'criteriaKeyLabel_asc',
      },
      {
        label: 'Alphabetical - Descending',
        value: 'criteriaKeyLabel_desc',
      },
    ],
  },
  {
    label: 'E-Index',
    value: FILTERS_DIMENSIONS.EINDEX,
    subcategories: [
      {
        label: 'Eindex - Ascending',
        value: 'eindex_asc',
      },
      {
        label: 'Eindex - Descending',
        value: 'eindex_desc',
      },
    ],
  },
  {
    label: 'Mentions',
    value: FILTERS_DIMENSIONS.MENTIONS,
    subcategories: [
      {
        label: 'Mentions - Ascending',
        value: 'mentions_asc',
      },
      {
        label: 'Mentions - Descending',
        value: 'mentions_desc',
      },
    ],
  },
  {
    label: 'Recommendation',
    value: FILTERS_DIMENSIONS.RECOMMENDATION,
    subcategories: [
      {
        label: 'Recommendation - Ascending',
        value: 'recommendation_asc',
      },
      {
        label: 'Recommendation - Descending',
        value: 'recommendation_desc',
      },
    ],
  },
  {
    label: 'Effort',
    value: FILTERS_DIMENSIONS.EFFORT,
    subcategories: [
      {
        label: 'Effort - Ascending',
        value: 'effort_asc',
      },
      {
        label: 'Effort - Descending',
        value: 'effort_desc',
      },
    ],
  },
  {
    label: 'Satisfaction',
    value: FILTERS_DIMENSIONS.SATISFACTION,
    subcategories: [
      {
        label: 'Satisfaction - Ascending',
        value: 'satisfaction_asc',
      },
      {
        label: 'Satisfaction - Descending',
        value: 'satisfaction_desc',
      },
    ],
  },
  {
    label: 'Emotions',
    value: FILTERS_DIMENSIONS.EMOTIONS,
    subcategories: [
      {
        label: 'Happiness',
        value: FILTERS_DIMENSIONS.HAPPINESS,
        subcategories: [
          {
            label: 'Happiness - Ascending',
            value: 'happiness_asc',
          },
          {
            label: 'Happiness - Descending',
            value: 'happiness_desc',
          },
        ],
      },
      {
        label: 'Surprise',
        value: FILTERS_DIMENSIONS.SURPRISE,
        subcategories: [
          {
            label: 'Surprise - Ascending',
            value: 'surprise_asc',
          },
          {
            label: 'Surprise - Descending',
            value: 'surprise_desc',
          },
        ],
      },
      {
        label: 'Calm',
        value: FILTERS_DIMENSIONS.CALM,
        subcategories: [
          {
            label: 'Calm - Ascending',
            value: 'calm_asc',
          },
          {
            label: 'Calm - Descending',
            value: 'calm_desc',
          },
        ],
      },
      {
        label: 'Fear',
        value: FILTERS_DIMENSIONS.FEAR,
        subcategories: [
          {
            label: 'Fear - Ascending',
            value: 'fear_asc',
          },
          {
            label: 'Fear - Descending',
            value: 'fear_desc',
          },
        ],
      },
      {
        label: 'Sadness',
        value: FILTERS_DIMENSIONS.SADNESS,
        subcategories: [
          {
            label: 'Sadness - Ascending',
            value: 'sadness_asc',
          },
          {
            label: 'Sadness - Descending',
            value: 'sadness_desc',
          },
        ],
      },
      {
        label: 'Anger',
        value: FILTERS_DIMENSIONS.ANGER,
        subcategories: [
          {
            label: 'Anger - Ascending',
            value: 'anger_asc',
          },
          {
            label: 'Anger - Descending',
            value: 'anger_desc',
          },
        ],
      },
      {
        label: 'Disgust',
        value: FILTERS_DIMENSIONS.DISGUST,
        subcategories: [
          {
            label: 'Disgust - Ascending',
            value: 'disgust_asc',
          },
          {
            label: 'Disgust - Descending',
            value: 'disgust_desc',
          },
        ],
      },
    ],
  },
];

export const INDICATORS_CONFIG = {
  [INDICATORS_TYPE.BADGE]: [
    {
      label: 'None',
      value: null,
    },
    {
      label: 'E-index',
      value: FILTERS_DIMENSIONS.EINDEX,
      color: (eindex, isFahrenheit) =>
        getColorHexaFromEindex(eindex, isFahrenheit),
      min: (filterKeys, eindexUnitFahrenheit) =>
        filterKeys &&
        getMinMaxFromUnit(eindexUnitFahrenheit).min -
          (eindexUnitFahrenheit ? 40 : 20),
      max: (filterKeys, eindexUnitFahrenheit) =>
        filterKeys && getMinMaxFromUnit(eindexUnitFahrenheit).max,
      data: (item, isFahrenheit) =>
        isFahrenheit
          ? convertCelsiusToFahrenheit(item.eindex.value)
          : item.eindex.value,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.MENTIONS),
      value: FILTERS_DIMENSIONS.MENTIONS,
      color: purple,
      min: data => data && 0 - max(data.map(item => item.mentions)) * 4,
      max: data => data && max(data.map(item => item.mentions)),
      data: item => item.mentions,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.SATISFACTION),
      value: FILTERS_DIMENSIONS.SATISFACTION,
      color: lightBlue,
      min: data =>
        data && 0 - max(data.map(item => item.satisfaction.unit.max)),
      max: data => data && max(data.map(item => item.satisfaction.unit.max)),
      data: item => item.satisfaction.value,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.EFFORT),
      value: FILTERS_DIMENSIONS.EFFORT,
      color: lightBlue,
      min: data => data && 0 - max(data.map(item => item.effort.unit.max)),
      max: data => data && max(data.map(item => item.effort.unit.max)),
      data: item => item.effort.value,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.RECOMMENDATION),
      value: FILTERS_DIMENSIONS.RECOMMENDATION,
      color: lightBlue,
      min: data =>
        data && 0 - max(data.map(item => item.recommendation.unit.max)),
      max: data => data && max(data.map(item => item.recommendation.unit.max)),
      data: item => item.recommendation.value,
    },
  ],
  [INDICATORS_TYPE.BAR]: [
    {
      label: 'None',
      value: null,
    },
    {
      label: 'E-index',
      value: FILTERS_DIMENSIONS.EINDEX,
      color: (eindex, isFahrenheit) =>
        getColorHexaFromEindex(eindex, isFahrenheit),
      min: (data, isFahrenheit) =>
        data && min(data.map(item => !isFahrenheit && item.eindex.value)) < 0
          ? min(data.map(item => item.eindex.value))
          : 0,
      max: (filterKeys, eindexUnitFahrenheit) =>
        filterKeys && getMinMaxFromUnit(eindexUnitFahrenheit).max,
      data: (item, isFahrenheit) =>
        isFahrenheit
          ? convertCelsiusToFahrenheit(item.eindex.value)
          : item.eindex.value,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.MENTIONS),
      value: FILTERS_DIMENSIONS.MENTIONS,
      color: purple,
      min: () => 0,
      max: data => data && max(data.map(item => item.mentions)) * 2,
      data: item => item.mentions,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.SATISFACTION),
      value: FILTERS_DIMENSIONS.SATISFACTION,
      color: lightBlue,
      min: () => 0,
      max: data => data && max(data.map(item => item.satisfaction.unit.max)),
      data: item => item.satisfaction.value,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.EFFORT),
      value: FILTERS_DIMENSIONS.EFFORT,
      color: lightBlue,
      min: () => 0,
      max: data => data && max(data.map(item => item.effort.unit.max)),
      data: item => item.effort.value,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.RECOMMENDATION),
      value: FILTERS_DIMENSIONS.RECOMMENDATION,
      color: lightBlue,
      min: () => 0,
      max: data => data && max(data.map(item => item.recommendation.unit.max)),
      data: item => item.recommendation.value,
    },
    {
      label: upperFirst(FILTERS_DIMENSIONS.EMOTIONS),
      value: FILTERS_DIMENSIONS.EMOTIONS,
      min: () => 0,
      max: data =>
        data && max(data.map(item => max(Object.values(item.emotions)))) * 2,
      subcategories: [
        {
          label: 'Happiness',
          value: FILTERS_DIMENSIONS.HAPPINESS,
        },
        {
          label: 'Surprise',
          value: FILTERS_DIMENSIONS.SURPRISE,
        },
        {
          label: 'Calm',
          value: FILTERS_DIMENSIONS.CALM,
        },
        {
          label: 'Fear',
          value: FILTERS_DIMENSIONS.FEAR,
        },
        {
          label: 'Sadness',
          value: FILTERS_DIMENSIONS.SADNESS,
        },
        {
          label: 'Anger',
          value: FILTERS_DIMENSIONS.ANGER,
        },
        {
          label: 'Disgust',
          value: FILTERS_DIMENSIONS.DISGUST,
        },
      ],
    },
  ],
};

export const THEMATICS_TYPE = {
  SUBTHEMATIC: 'subThematic',
  SUBSUBTHEMATIC: 'subSubThematic',
};
