import React, { Component } from 'react';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import _ from 'lodash';
import { grayShades } from 'styles/abstracts/colors';
import { connect } from 'react-redux';
import { transformItemFiltersToSettings } from '_Providers/QFilterOptionsProvider/optionProviderFunctions';
import mock from './mocks/mockDataTemplates';
import TemplatesRow from './_Components/TemplatesRow/TemplatesRow';

const StyledTable = styled(Table)`
  margin-bottom: 0 !important;
  border-top: 1px solid ${grayShades.g400};
`;

// TODO: fix this - it's quick walkaround for demo

function transformReportingItemFiltersToSettings(settings, projectThematics) {
  const { thematics, criteriaKeys, range, children, ...otherInfos } = settings;

  return {
    ...otherInfos,
    ...transformItemFiltersToSettings(settings, projectThematics),
    ...(children && {
      children: children.map(subChild =>
        transformReportingItemFiltersToSettings(subChild, projectThematics),
      ),
    }),
  };
}

class TemplatesList extends Component {
  lock = false;

  prepareReportings(reportings) {
    const { projectThematics } = this.props;
    if (!projectThematics) return;

    return (
      reportings &&
      _.cloneDeep(reportings).map(reporting => {
        const settings = JSON.parse(reporting.settings);

        const isNewSchema =
          !_.isEmpty(reporting.settings) &&
          JSON.stringify(reporting.settings).search('criteriaKeys') > -1;
        const reportingSettings = { ...reporting, settings };
        if (isNewSchema && !_.isEmpty(settings)) {
          reportingSettings.settings.globalSettings = transformReportingItemFiltersToSettings(
            settings.globalSettings,
            projectThematics,
          );
          reportingSettings.settings.children = settings.children.map(child =>
            transformReportingItemFiltersToSettings(child, projectThematics),
          );
        }
        reportingSettings.settings = reportingSettings.settings
          ? JSON.stringify(reportingSettings.settings)
          : null;
        return reportingSettings;
      })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.props.refetch;
    }
  }

  getData() {
    const { data, refetch } = this.props;
    if (!data && !this.lock) {
      this.lock = true;
      refetch();
      return [];
    }
    const simpleDataTransformedToOptions = this.prepareReportings(data);
    this.lock = false;
    return simpleDataTransformedToOptions;
  }

  render() {
    const apiData = this.getData();
    return (
      <StyledTable>
        <tbody>
          {apiData &&
            apiData.map(data => <TemplatesRow key={data.id} data={data} />)}
        </tbody>
      </StyledTable>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentProjectId: _.get(state, ['projects', 'currentProject', 'id']),
    projectThematics: _.get(state, ['filters', 'projectFilters', 'thematics']),
  };
}

export default connect(
  mapStateToProps,
  null,
)(TemplatesList);
