/* eslint-disable react/sort-comp */
import React, { Component, Fragment } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { QFunnelCategories, QFunnelOptions } from './QFunnelSelect.propTypes';
import QSelect from '../QSelect/QSelect';
import { getCategoriesHierarchy } from './utils/categories';

const prepareSelectConfig = label => ({
  multiple: false,
  placeholder: label,
});

export default class QFunnelSelect extends Component {
  static propTypes = {
    categories: QFunnelCategories.isRequired,
    initialValue: PropTypes.arrayOf(QFunnelOptions),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialValue: [],
  };

  state = {
    normalizedCategories: [],
    selectedValues: [],
  };

  constructor(props) {
    super(props);
    const { initialValue } = props;
    this.initializeSelectValues(initialValue);
  }

  initializeSelectValues(initialValue) {
    this.state.selectedValues = cloneDeep(initialValue);
  }

  componentDidMount() {
    this.updateNormalizedCategories();
  }

  componentDidUpdate(prevCategories) {
    if (this.areCategoriesChanged(prevCategories)) {
      this.updateNormalizedCategories();
    }
  }

  areCategoriesChanged({ categories: prevCategories }) {
    const { categories: currentCategories } = this.props;
    return prevCategories !== currentCategories;
  }

  updateNormalizedCategories() {
    const { categories } = this.props;
    this.setState({
      normalizedCategories: getCategoriesHierarchy(categories),
    });
  }

  onCategorySelected = (value, index) => {
    const { onChange } = this.props;
    this.setState(
      ({ selectedValues }) => {
        const updatedValues = selectedValues.slice(0, index + 1);
        updatedValues[index] = value;
        return { selectedValues: updatedValues };
      },
      () => onChange(this.state.selectedValues),
    );
  };

  isSelectDisabled = index => {
    const { selectedValues } = this.state;
    return index > selectedValues.length;
  };

  filterOptions = (options, index) => {
    const { selectedValues } = this.state;
    const parent = selectedValues[index - 1];
    return parent
      ? options.filter(({ parent: p }) => p === parent.value)
      : options;
  };

  prepareCategoriesSelects() {
    const { normalizedCategories, selectedValues } = this.state;
    return normalizedCategories.map(({ label, items }, index) => {
      const config = prepareSelectConfig(label);
      return (
        <QSelect
          options={this.filterOptions(items, index)}
          config={config}
          disabled={this.isSelectDisabled(index)}
          onChange={value => this.onCategorySelected(value, index)}
          value={selectedValues[index]}
        />
      );
    });
  }

  render() {
    return <Fragment>{this.prepareCategoriesSelects()}</Fragment>;
  }
}
