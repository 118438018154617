import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { grayShades } from '../../../../../../../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../../../../../../../styles/abstracts/typography';
import { spacing } from '../../../../../../../../../../styles/abstracts/variables';

const StyledThead = styled.thead`
  color: ${grayShades.g800};
`;

const StyledTh = styled.th`
  font-size: ${fontSizes.smaller};
  padding: ${spacing.medium} ${spacing.mediumLarge} !important;
`;

const ReportsListHeader = props => {
  const { t } = props;
  return (
    <StyledThead>
      <tr>
        <StyledTh>{t('Report link')}</StyledTh>
        <StyledTh>{t('Generation date')}</StyledTh>
        <StyledTh>Nb. pages</StyledTh>
      </tr>
    </StyledThead>
  );
};

export default withTranslation('card')(ReportsListHeader);
