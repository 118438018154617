import gql from 'graphql-tag';

const query = gql`
  query getLandingPageDecryptingMessagesData($projectId: Int!) {
    getLandingPageDecryptingMessagesData(projectId: $projectId) {
      messages {
        id
        content
        tab
        icon
      }
    }
  }
`;

export default query;
