import React from 'react';

export class DispatchDataToStore extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.props.action();
    }
  }

  componentDidMount() {
    this.props.action();
  }

  render() {
    return this.props.children || null;
  }
}
