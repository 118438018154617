import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import ListProject from '_Layouts/Header/_Containers/SubHeader/_Containers/ListProject';
import {
  setCurrentProject,
  resetDataCollectorInProjectConfiguration,
} from '_Resources/Header/Actions/setProject';
import { grayShades } from 'styles/abstracts/colors';
import { spacing } from 'styles/abstracts/variables';
import { setGlobalQueryFilterKeys } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${grayShades.g800};
  font-weight: bold;
  flex-wrap: wrap;
  margin: 0.75em 0;

  & > div {
    width: 15em;
    margin: 0;
  }

  & p {
    margin-right: ${spacing.medium};
  }
`;

class ListProjectContainer extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      projectId,
      filterKeys,
      setGlobalQueryFilterKeys,
    } = this.props;
    if (prevProps.projectId !== projectId) {
      setGlobalQueryFilterKeys({
        ...filterKeys,
        projectId,
        dataCollectorId: null,
      });
    }
  }

  render() {
    const { t, id, setCurrentProject } = this.props;
    return (
      <Container id={id}>
        <p>{t('Project')}</p>
        <ListProject
          callbackParent={newState => setCurrentProject(newState)}
        />
      </Container>
    );
  }
}
function mapStateToProps(state) {
  return {
    projectId:
      state.projects.currentProject && state.projects.currentProject.id,
    filterKeys: get(state, ['filters', 'filterKeys']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentProject: newState => {
      dispatch(setCurrentProject(newState));
    },
    setGlobalQueryFilterKeys: newState => {
      dispatch(setGlobalQueryFilterKeys(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('header')(ListProjectContainer));
