import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import { emotionColors, lightBlue, grayShades } from 'styles/abstracts/colors';
import { capitalize } from '_Utils/strings/stringsUtils';
import EmotionsTriggersChart from '_Charts/ScatterCharts/EmotionsTriggersChart/EmotionsTriggersChart';
import EmotionsTriggersDropdown from './_Containers/EmotionsTriggersDropdown/EmotionsTriggersDropdown';
import { AVAILABLE_DISPLAY_OPTIONS, AVAILABLE_INDICATORS } from './utils';

const Container = styled.div`
  height: 41em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const EmailMessageContainer = styled.div`
  color: ${lightBlue};
  font-weight: bold;
  text-align: center;
  position: absolute;
  display: block;
  width: calc(100% - 32px);
  background-color: white;
  padding: 2px 10px;
  border-radius: 6px;
`;

const EmotionsTriggersCard = ({
  data: globalData,
  settings,
  setSettings,
  t,
  isDetailsToggled,
  message,
}) => {
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [selectedDisplay, setSelectedDisplay] = useState(null);
  const [data, setData] = useState([]);
  const [datazoom, setDatazoom] = useState({ start: 0, end: 100 });

  useEffect(() => {
    setData(globalData.map((d, ind) => ({ id: ind, ...d })));
  }, [globalData]);

  useEffect(() => {
    const { emotionsTriggers } = settings;
    if (emotionsTriggers) {
      setSelectedIndicator(emotionsTriggers?.selectedIndicator);
      setSelectedDisplay(emotionsTriggers?.display);
    } else {
      setSelectedIndicator(['happiness']);
      setSelectedDisplay(['thematics', 'subThematics', 'subSubThematics']);
    }
  }, [settings]);

  const isSelectedIndicatorsArray =
    Array.isArray(selectedIndicator) && selectedIndicator.length > 0;

  const getGraphRawData = () => {
    let result = [];
    if (selectedDisplay?.length >= 1) {
      if (selectedDisplay.includes('thematics')) {
        result = orderBy(
          [...result, ...data.filter(d => d.subThematics)],
          item => item.id,
          ['asc'],
        );
      }
      if (selectedDisplay.includes('subThematics')) {
        result = orderBy(
          [...result, ...data.filter(d => d.subSubThematics)],
          item => item.id,
          ['asc'],
        );
      }
      if (selectedDisplay.includes('subSubThematics')) {
        result = orderBy(
          [...result, ...data.filter(d => d.thematic && d.subThematic)],
          item => item.id,
          ['asc'],
        );
      }
    } else {
      result = data;
    }
    return result;
  };

  const formattedData = getGraphRawData()?.map(d => {
    let emotionalPercentage = 0;
    isSelectedIndicatorsArray &&
      selectedIndicator.map(indicator => {
        emotionalPercentage += d.thematicsOverviewDetails.emotions
          ? d.thematicsOverviewDetails.emotions[indicator]?.byPercentage
          : 0;
      });
    return [
      d.thematicsOverviewDetails.mentionCount,
      isSelectedIndicatorsArray
        ? emotionalPercentage
        : d.thematicsOverviewDetails[selectedIndicator],
    ];
  });

  const graphData = getGraphRawData()?.map((d, ind) => {
    const categoryHierarchy = `${
      d?.subThematics ? d?.thematicsOverviewDetails.name : d?.thematic
    } ${
      d?.subSubThematics
        ? `> ${d?.thematicsOverviewDetails.name}`
        : d.subThematic
        ? `> ${d.subThematic}`
        : ''
    } ${
      d.thematic && d.subThematic ? `> ${d?.thematicsOverviewDetails.name}` : ''
    }`.trim();
    return {
      name: d.thematicsOverviewDetails.name,
      testValue: 'toto',
      hierarchy: categoryHierarchy,
      value: formattedData[ind],
      itemStyle: {
        color:
          selectedIndicator?.length > 1
            ? grayShades.g800
            : emotionColors[selectedIndicator[0]],
      },
      tooltip: {
        formatter: isDetailsToggled
          ? `<b>${categoryHierarchy}</b><br /> ${
              selectedIndicator?.length > 1
                ? t('button:Emotions')
                : t(`button:${capitalize(selectedIndicator[0])}`)
            } : ${Math.round(formattedData[ind][1])}% <br> ${t(
              'Mentions',
            )} : ${Math.round(formattedData[ind][0])}`
          : '{b}',
        confine: true,
        backgroundColor: 'black',
        position: 'top',
        padding: 0,
        textStyle: {
          color: 'white',
        },
      },
    };
  });
  return (
    <QcardPanel
      title={`${t('Categories that triggered')} ${selectedIndicator
        ?.map(ind => t(`button:${capitalize(ind)}`))
        ?.join(' + ') || 'nothing'}`}
      dropDownOptions={['EXPORT']}
      dataByFiltersCard
    >
      {message && (
        <EmailMessageContainer>{message.message}</EmailMessageContainer>
      )}
      <Container>
        <div id="dataByFilterDropdownsContainer">
          <DropdownContainer>
            <EmotionsTriggersDropdown
              label="Selected values"
              options={AVAILABLE_INDICATORS}
              setSelectedItem={setSelectedIndicator}
              selectedItem={selectedIndicator}
              settings={settings}
              setSettings={setSettings}
              t={t}
            />
            <EmotionsTriggersDropdown
              label="Display by"
              options={AVAILABLE_DISPLAY_OPTIONS}
              setSelectedItem={setSelectedDisplay}
              selectedItem={selectedDisplay}
              settings={settings}
              setSettings={setSettings}
              t={t}
            />
          </DropdownContainer>
        </div>
        <EmotionsTriggersChart
          graphData={graphData}
          selectedIndicator={selectedIndicator}
          setDatazoom={d => setDatazoom(d)}
          datazoom={datazoom}
          t={t}
        />
      </Container>
    </QcardPanel>
  );
};

export default withTranslation('card', ['button', 'graph'])(EmotionsTriggersCard);
