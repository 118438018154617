export const getSubscriptions = isMonthlySelected => [
  // TODO : Uncomment if needed
  // {
  //   nameId: `emotion_plus_${isMonthlySelected ? 'monthly' : 'yearly'}`,
  //   name: 'Emotion Plus',
  //   monthlyPrice: 150,
  //   annualPrice: 120,
  //   commentsPerPeriod: isMonthlySelected ? 400 : 4800,
  //   dataCollection: 'Monthly',
  //   maxCategories: 50,
  //   customerJourney: 'Industry',
  //   numberUsers: 5,
  //   numberBenchmarks: 1,
  //   numberReportings: 1,
  //   ticketSupportPriority: 'low',
  //   liveSupport: false,
  // },
  {
    nameId: `emotion_pro_${isMonthlySelected ? 'monthly' : 'yearly'}`,
    name: 'Emotion Pro',
    monthlyPrice: 500,
    annualPrice: 400,
    commentsPerPeriod: isMonthlySelected ? 1000 : 12000,
    dataCollection: 'Weekly',
    maxCategories: 50,
    customerJourney: 'Industry',
    numberUsers: 5,
    numberBenchmarks: 3,
    numberReportings: 3,
    ticketSupportPriority: 'standard',
    liveSupport: false,
  },
  {
    nameId: `emotion_corporate`,
    name: 'Emotion Corporate',
    price: null,
    commentsPerPeriod: 'Unlimited',
    dataCollection: 'Daily',
    maxCategories: 300,
    customerJourney: 'Company',
    numberUsers: 'Unlimited',
    numberBenchmarks: 'Unlimited',
    numberReportings: 'Unlimited',
    ticketSupportPriority: 'high',
    liveSupport: true,
  },
];

export const onTheGoPlan = {
  name: 'Emotion Start',
  nameId: 'emotion_start',
  price: 0,
  pricePerComment: 0.90,
  dataCollection: 'Monthly',
  maxCategories: 50,
  numberUsers: 1,
  numberBenchmarks: 1,
  numberReportings: 1,
  ticketSupportPriority: 'low',
};
