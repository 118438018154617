import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';
import { capitalize } from '_Utils/strings/stringsUtils';
import {
  purple,
  grayShades,
  emotionColors,
  sentimentColors,
} from '../../../../../styles/abstracts/colors';

const LegendTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${purple};
  text-align: left;
  line-height: 21px;
  margin-bottom: 0.5rem;
  text-decoration: underline;
`;

const LegendValue = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${grayShades.g800};
  text-align: left;
  line-height: 21px;
  text-decoration: none;
  margin-left: 0.5em;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 1rem 2rem;
  background-color: ${grayShades.g300};
  border-radius: 0.5em;
  margin: 1rem;
`;

const LegendElements = styled.div<{ nbOfColumns: number }>`
  column-width: ${({ nbOfColumns }) =>
    nbOfColumns > 2 ? '20rem' : '15.75rem'};
  column-rule: 1px solid ${grayShades.g600};
  margin-left: -1.5rem;
  margin-bottom: 1em;
  text-decoration: none;
`;

const EmotionsLegendElementsList = styled.div`
  list-style: none;
`;

const EmotionsLegendElementsListItem = styled.div`
  break-inside: avoid;
  margin-bottom: 0.5em;
  margin-left: 2em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LegendElementsListItem = styled.li`
  &::marker {
    font-size: 14px;
    font-weight: bold;
    color: ${purple};
    text-align: left;
    line-height: 21px;
    margin-left: 0.5rem;
  }
`;

const LegendElementsList = styled.ol`
  padding-left: 3rem;
`;

const EmotionColor = styled.div<{ color: string }>`
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${({ color }) => color};
`;

type HorizontalGraphBenchmarkCardFooterProps = {
  nbOfColumns: number;
  t: any;
  benchmarkHorizontalGraphLegend: {
    [key: string]: string;
  };
  benchmarkAspectToDisplay: string;
};

const HorizontalGraphBenchmarkCardFooter = ({
  nbOfColumns,
  t,
  benchmarkHorizontalGraphLegend,
  benchmarkAspectToDisplay,
}: HorizontalGraphBenchmarkCardFooterProps) => {
  const [legendToDisplay, setLegendToDisplay] = useState(
    benchmarkHorizontalGraphLegend,
  );

  useEffect(() => {
    setLegendToDisplay(benchmarkHorizontalGraphLegend);
  }, [benchmarkHorizontalGraphLegend]);

  return (
    <>
      {emotionColors && Object.keys(emotionColors).length > 0 && (
        <LegendContainer>
          <LegendTitle>{t('Legend')} :</LegendTitle>
          <LegendElements nbOfColumns={nbOfColumns}>
            {benchmarkAspectToDisplay === 'emotions' &&
              Object.entries(emotionColors).map((entry: string) => {
                return (
                  <EmotionsLegendElementsList>
                    <EmotionsLegendElementsListItem key={entry}>
                      <EmotionColor color={entry[1]} />
                      <span style={{ marginLeft: '0.5em' }} />
                      <LegendValue>
                        {capitalize(t(`emotion:${entry[0]}`))}
                      </LegendValue>
                    </EmotionsLegendElementsListItem>
                  </EmotionsLegendElementsList>
                );
              })}
            {benchmarkAspectToDisplay === 'sentiment' &&
              Object.entries(sentimentColors).map((entry: string) => {
                return (
                  <EmotionsLegendElementsList>
                    <EmotionsLegendElementsListItem key={entry}>
                      <EmotionColor color={entry[1]} />
                      <span style={{ marginLeft: '0.5em' }} />
                      <LegendValue>
                        {t(capitalize(`${entry[0]}`))}
                      </LegendValue>
                    </EmotionsLegendElementsListItem>
                  </EmotionsLegendElementsList>
                );
              })}
            {legendToDisplay && (
              <LegendElementsList>
                {Object.entries(legendToDisplay).map((item: string) => (
                  <LegendElementsListItem key={item}>
                    <p>
                      <LegendValue>{t(item)}</LegendValue>
                    </p>
                  </LegendElementsListItem>
                ))}
              </LegendElementsList>
            )}
          </LegendElements>
        </LegendContainer>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  projectId: get(state, ['projects', 'currentProject', 'id']),
  benchmarkHorizontalGraphLegend: get(state, [
    'benchmark',
    'horizontalGraphLegend',
  ]),
  benchmarkAspectToDisplay: get(state, ['benchmark', 'aspectToDisplay']),
});

export default withTranslation('card')(
  connect(mapStateToProps)(HorizontalGraphBenchmarkCardFooter),
);
