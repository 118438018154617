export const TABS: Array<{
  id: string;
  name: string;
  disabled?: boolean;
  icon?: string;
}> = [
  {
    id: 'key_indicators',
    name: 'Key indicators',
    icon: 'INTERFACE_KEY_INDICATORS',
  },
  {
    id: 'customer_journey',
    name: 'Customer journey',
    icon: 'INTERFACE_CUSTOMER_JOURNEY',
  },
  {
    id: 'insights',
    name: 'Key insights',
    icon: 'INTERFACE_KEY_INSIGHTS',
  },
  {
    id: 'irritants',
    name: 'Irritants',
    icon: 'INTERFACE_IRRITANTS',
  },
  {
    id: 'actions',
    name: 'Actions',
    icon: 'INTERFACE_ACTIONS',
  },
];
