import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { prepareRelatedToOptions, relatedToOptions } from './relatedToOptions';
import { createAdapter } from './adapter';
import { forEach } from 'lodash';

export class RelatedFilter extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    filters: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
    type: PropTypes.string,
    projectId: PropTypes.number.isRequired,

    // NOTE: properties passed by QProjectFilters
    onFilterOptionsCreated: PropTypes.func,
    onFilterRemoved: PropTypes.func,
    registerAdapter: PropTypes.func,
  };

  static defaultProps = {
    type: 'related',
    onFilterOptionsCreated: undefined,
    onFilterRemoved: undefined,
    registerAdapter: undefined,
  };

  relatedToOptions = null;

  constructor(props) {
    super(props);
    this.initialize();
  }

  initialize() {
    const { t } = this.props;
    this.relatedToOptions = prepareRelatedToOptions(t);
  }

  componentDidMount() {
    const { registerAdapter, type } = this.props;
    registerAdapter(createAdapter(type));
  }

  componentWillUnmount() {
    const { onFilterRemoved } = this.props;
    onFilterRemoved();
  }

  render() {
    const { filters, onFilterOptionsCreated, type, projectId } = this.props;
    const options = [];
    forEach(filters, filterGroup => {
      options.push(this.relatedToOptions(filterGroup, type, projectId)[0]);
    });
    onFilterOptionsCreated(options);
    return null;
  }
}

export const QRelatedFilter = withTranslation('filters')(RelatedFilter);
