import { GET_PROJECTS_LIST } from '_Resources/Header/Actions/getProjectList';
import {
  SET_CURRENT_PROJECT,
  SET_FUTURE_PROJECT_TO_SELECT,
} from '_Resources/Header/Actions/setProject';

const initialState = {
  projectsList: [],
  currentProject: null,
  futureProjectToSelect: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS_LIST: {
      return {
        ...state,
        projectsList: action.data.projects,
      };
    }
    case SET_CURRENT_PROJECT: {
      return {
        ...state,
        currentProject: action.data,
      };
    }
    case SET_FUTURE_PROJECT_TO_SELECT: {
      return {
        ...state,
        futureProjectToSelect: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
