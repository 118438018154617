import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

const QDroppable = ({ id, dropDisabled, render }) => (
  <Droppable droppableId={id} isDropDisabled={dropDisabled}>
    {(provided, snapshot) => render(provided, snapshot)}
  </Droppable>
);

QDroppable.propTypes = {
  id: PropTypes.string.isRequired,
  dropDisabled: PropTypes.bool,
  render: PropTypes.func.isRequired,
};

QDroppable.defaultProps = {
  dropDisabled: false,
};

export default QDroppable;
