import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import QButton from '_Components/Buttons/QButton/QButton';
import { white, grayShades, purple } from 'styles/abstracts/colors';
import Loader from '_Components/Loader/OnLoad';
import { setSentenceDropdownMenuOpen } from '_Resources/Sentence/Actions/setSentenceDropdownMenuOpen';
import { setSentenceMenuLevel } from '_Resources/Sentence/Actions/setSentenceMenuLevel';
import { connect } from 'react-redux';
import { sendVerbatimEmotionsOnHover } from '_Resources/Cards/Actions/sendVerbatimEmotionsOnHover';
import { selectSentenceDetails } from '_Resources/Sentence/Actions/selectSentenceDetails';

const StyledTextarea = styled.textarea`
  border-radius: 10px;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  padding: 0.5rem;
  font-size: 0.9rem;
`;

const StyledButton = styled(QButton)`
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.5rem;
  line-height: 1.2rem;

  & > * {
    margin: 0 0.25em;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  margin-bottom: 0.5rem;
  margin-top: -0.2rem;
`;

type VerbatimSentenceCommentBoxProps = {
  t: any;
  commentBox: boolean;
  comment: string;
  handleComment: Function;
  toggleCommentBox: Function;
  loading: boolean;
  setDropdownOptions: Function;
  declareError: Function;
  project: any;
  sentence: any;
  option: any;
  verbatimSentenceDropdownOptions: Array<any>;
  sendEmotionOnHover: Function;
  toggleDropdown: Function;
};

const VerbatimSentenceCommentBox = ({
  t,
  commentBox,
  comment,
  handleComment,
  toggleCommentBox,
  loading,
  setDropdownOptions,
  declareError,
  project,
  sentence,
  option,
  verbatimSentenceDropdownOptions,
  sendEmotionOnHover,
  toggleDropdown,
}: VerbatimSentenceCommentBoxProps) => {
  const handleSend = () => {
    toggleCommentBox(!commentBox);
    setDropdownOptions(verbatimSentenceDropdownOptions);
    sendEmotionOnHover(null);
    declareError({
      variables: {
        projectId: project.id,
        sentenceId: sentence.clickedSentence.id,
        suggestedEmotion: option.emotionToSuggest.toUpperCase(),
        comment,
      },
    });
    toggleDropdown();
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <>
      <form id="comment">
        <label className="ml-2 mt-2" htmlFor="comment">
          {t('Add a comment')}
        </label>
        <StyledTextarea
          form="comment"
          name="suggestedEmotionComment"
          placeholder={t('Tell us why')}
          rows={3}
          cols={27}
          onChange={handleComment}
          value={comment}
          onKeyDown={e => handleKeyDown(e)}
        />
        <ButtonsContainer>
          <StyledButton
            type="button"
            onClick={() => toggleCommentBox(!commentBox)}
            bgColor={grayShades.g600}
            disabled={loading}
          >
            {t('Cancel')}
          </StyledButton>
          <StyledButton onClick={() => handleSend()} bgColor={purple}>
            {loading && <Loader color={white} />}
            {t('Send')}
          </StyledButton>
        </ButtonsContainer>
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  sendEmotionOnHover: (newState: any) => {
    dispatch(sendVerbatimEmotionsOnHover(newState));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation('button')(VerbatimSentenceCommentBox));
