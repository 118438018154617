import { find, isArray, get, intersection, flattenDeep } from 'lodash';
import {
  CONTEXT,
  FILTER_DIMENSIONS,
  TEMPORARY_STORE_TO_WATCH,
  STORE_TO_WATCH,
} from '../../../../../configs/globalConfig';

export const constructConflict = (
  firstContext,
  secondContext,
  typeConflict,
) => {
  const finalContext = {
    dimensions: {
      column: null,
      card: null,
      global: null,
    },
    type: typeConflict,
  };
  if (
    firstContext.context === CONTEXT.COLUMN &&
    secondContext.context === CONTEXT.CARD
  ) {
    finalContext.dimensions.column = firstContext.id;
    finalContext.dimensions.card = secondContext.id;
  } else if (
    firstContext.context === CONTEXT.CARD &&
    secondContext.context === CONTEXT.COLUMN
  ) {
    finalContext.dimensions.column = secondContext.id;
    finalContext.dimensions.card = firstContext.id;
  } else if (
    firstContext.context === CONTEXT.COLUMN &&
    secondContext.context === CONTEXT.GLOBAL
  ) {
    finalContext.dimensions.column = firstContext.id;
    finalContext.dimensions.global = true;
  } else if (
    firstContext.context === CONTEXT.CARD &&
    secondContext.context === CONTEXT.GLOBAL
  ) {
    finalContext.dimensions.card = firstContext.id;
    finalContext.dimensions.global = true;
  } else if (
    firstContext.context === CONTEXT.GLOBAL &&
    secondContext.context === CONTEXT.COLUMN
  ) {
    finalContext.dimensions.column = secondContext.id;
    finalContext.dimensions.global = true;
  } else if (
    firstContext.context === CONTEXT.GLOBAL &&
    secondContext.context === CONTEXT.CARD
  ) {
    finalContext.dimensions.card = secondContext.id;
    finalContext.dimensions.global = true;
  }

  return finalContext;
};

export const findExistingConflicts = (
  firstContext,
  secondContext,
  typeConflict,
  conflictsArray,
) => {
  const conflict = constructConflict(firstContext, secondContext, typeConflict);
  const existingConflict = find(conflictsArray, conflict);
  return existingConflict !== undefined;
};

export const getItemSettings = (
  array,
  arrayItem,
  typeConflict,
  settingsCategory,
) => {
  if (!arrayItem) return;
  let itemSettings;
  const settingsToGet =
    settingsCategory === 'merged' ? 'mergedSettings' : 'settings';
  if (array.context === CONTEXT.GLOBAL) {
    itemSettings =
      typeConflict === FILTER_DIMENSIONS.DATE_RANGE
        ? arrayItem.globalSettings[typeConflict]
        : arrayItem.globalSettings.filters[typeConflict];
  } else {
    itemSettings =
      typeConflict === FILTER_DIMENSIONS.DATE_RANGE
        ? arrayItem[settingsToGet][typeConflict]
        : arrayItem[settingsToGet].filters[typeConflict];
  }
  return itemSettings;
};

export const constructArrayItemSettings = (arraySettings, index, benchmark) => {
  if (arraySettings.context === CONTEXT.GLOBAL) {
    arraySettings.id = null;
  } else if (
    arraySettings.context === CONTEXT.COLUMN ||
    arraySettings.context === CONTEXT.CARD
  ) {
    switch (true) {
      case !arraySettings.temporaryID:
      default:
        arraySettings.id = index;
        break;
      case arraySettings.temporaryID === -1:
        arraySettings.id =
          benchmark.activeBenchmark.settings[
            STORE_TO_WATCH[arraySettings.context]
          ].length;
        break;
      case arraySettings.temporaryID > -1:
        arraySettings.id = arraySettings.temporaryID;
        break;
    }
  }
};

export const iterateThroughStore = (
  firstItem,
  secondItem,
  typeConflict,
  addConflictFunc,
  removeConflictFunc,
  validationFunc,
  temporaryConflicts,
  benchmark,
  settingsCategory,
  hasDateBeenModified,
) => {
  const firstArray = !isArray(firstItem.settings)
    ? { ...firstItem, settings: [firstItem.settings] }
    : { ...firstItem };
  const secondArray = !isArray(secondItem.settings)
    ? { ...secondItem, settings: [secondItem.settings] }
    : { ...secondItem };
  firstArray.settings.forEach((firstArrayItem, firstIndex) => {
    const firstArrayItemSettings = getItemSettings(
      firstArray,
      firstArrayItem,
      typeConflict,
      settingsCategory,
    );
    constructArrayItemSettings(firstArray, firstIndex, benchmark);
    secondArray.settings.forEach((secondArrayItem, secondIndex) => {
      const secondArrayItemSettings = getItemSettings(
        secondArray,
        secondArrayItem,
        typeConflict,
        settingsCategory,
      );
      constructArrayItemSettings(secondArray, secondIndex, benchmark);
      const validFullFilter = validationFunc(
        firstArrayItemSettings,
        secondArrayItemSettings,
      );
      const isExistingConflict = findExistingConflicts(
        firstArray,
        secondArray,
        typeConflict,
        temporaryConflicts,
      );
      if (validFullFilter === false && !isExistingConflict) {
        addConflictFunc(firstArray, secondArray, typeConflict);
      } else if (validFullFilter !== false && isExistingConflict) {
        removeConflictFunc(firstArray, secondArray, typeConflict);
      }
    });
  });
};

export const checkConflicts = (
  context,
  typeConflict,
  validateFunc,
  benchmark,
  addConflictFunc,
  removeConflictFunc,
  hasDateBeenModified,
) => {
  const global = {
    context: CONTEXT.GLOBAL,
    temporaryID: false,
    settings: get(benchmark, ['activeBenchmark', 'settings']),
  };
  const cards = {
    context: CONTEXT.CARD,
    temporaryID: false,
    settings: get(benchmark, ['activeBenchmark', 'settings', 'cards']),
  };
  const columns = {
    context: CONTEXT.COLUMN,
    temporaryID: false,
    settings: get(benchmark, ['activeBenchmark', 'settings', 'columns']),
  };
  const temporaryCard = {
    context: CONTEXT.CARD,
    temporaryID: get(benchmark, [
      TEMPORARY_STORE_TO_WATCH[CONTEXT.CARD].temporaryIDField,
    ]),

    settings: get(benchmark, [
      TEMPORARY_STORE_TO_WATCH[CONTEXT.CARD].temporarySettingsField,
    ]),
  };
  const temporaryColumn = {
    context: CONTEXT.COLUMN,
    temporaryID: get(benchmark, [
      TEMPORARY_STORE_TO_WATCH[CONTEXT.COLUMN].temporaryIDField,
    ]),
    settings: get(benchmark, [
      TEMPORARY_STORE_TO_WATCH[CONTEXT.COLUMN].temporarySettingsField,
    ]),
  };
  const temporaryConflicts =
    benchmark[
      TEMPORARY_STORE_TO_WATCH[CONTEXT.CONFLICTS].temporarySettingsField
    ];
  const conflicts = get(benchmark, [
    'activeBenchmark',
    'settings',
    CONTEXT.CONFLICTS,
  ]);
  switch (context) {
    case CONTEXT.CARD: {
      iterateThroughStore(
        temporaryCard,
        global,
        typeConflict,
        addConflictFunc,
        removeConflictFunc,
        validateFunc,
        temporaryConflicts,
        benchmark,
        'original',
      );
      iterateThroughStore(
        temporaryCard,
        columns,
        typeConflict,
        addConflictFunc,
        removeConflictFunc,
        validateFunc,
        temporaryConflicts,
        benchmark,
        'merged',
      );
      break;
    }
    case CONTEXT.COLUMN: {
      iterateThroughStore(
        temporaryColumn,
        global,
        typeConflict,
        addConflictFunc,
        removeConflictFunc,
        validateFunc,
        temporaryConflicts,
        benchmark,
        'original',
      );
      iterateThroughStore(
        temporaryColumn,
        cards,
        typeConflict,
        addConflictFunc,
        removeConflictFunc,
        validateFunc,
        temporaryConflicts,
        benchmark,
        'merged',
      );
      break;
    }
    case CONTEXT.GLOBAL: {
      iterateThroughStore(
        global,
        columns,
        typeConflict,
        addConflictFunc,
        removeConflictFunc,
        validateFunc,
        conflicts,
        benchmark,
        'original',
      );
      iterateThroughStore(
        global,
        cards,
        typeConflict,
        addConflictFunc,
        removeConflictFunc,
        validateFunc,
        conflicts,
        benchmark,
        'original',
      );
      iterateThroughStore(
        cards,
        columns,
        typeConflict,
        addConflictFunc,
        removeConflictFunc,
        validateFunc,
        conflicts,
        benchmark,
        'merged',
      );
      break;
    }
    default:
      break;
  }
};

export const transformCriteriaKeysArray = criteriaKeysToTransform => {
  let mergedCriteriaKeysArray = [];
  criteriaKeysToTransform.forEach(rawCriteriaKey => {
    const mergedCriteriaKeys = {
      filterCategory:
        rawCriteriaKey.filterCategory ||
        rawCriteriaKey.parents[rawCriteriaKey.parents.length - 1],
      parents: [],
      option: {
        id: rawCriteriaKey.option.id,
        label: rawCriteriaKey.option.label,
      },
    };
    mergedCriteriaKeysArray = [...mergedCriteriaKeysArray, mergedCriteriaKeys];
  });
  return mergedCriteriaKeysArray;
};

export const getCriteriaKeysParents = criteriaKeys => {
  let criteriaKeysParents = [];
  criteriaKeys.forEach(criteriaKey => {
    const parent =
      criteriaKey.filterCategory ||
      criteriaKey.parents[criteriaKey.parents.length - 1];
    criteriaKeysParents = [...criteriaKeysParents, parent];
  });
  return criteriaKeysParents;
};

export const getCriteriaKeysIDs = criteriaKeys => {
  let criteriaKeysIds = [];
  criteriaKeys.forEach(criteriaKey => {
    criteriaKeysIds = [...criteriaKeysIds, criteriaKey.option.id];
  });
  return criteriaKeysIds;
};

export const isValidCriteriaKeys = (criteriaKeys, criteriaKeysToCompare) => {
  if (criteriaKeys === false || criteriaKeysToCompare === false) return false;
  const criteriaKeysParents =
    criteriaKeys && getCriteriaKeysParents(criteriaKeys);
  const criteriaKeysToCompareParents =
    criteriaKeysToCompare && getCriteriaKeysParents(criteriaKeysToCompare);
  const criteriaKeysId = criteriaKeys && getCriteriaKeysIDs(criteriaKeys);
  const criteriaKeysToCompareId =
    criteriaKeysToCompare && getCriteriaKeysIDs(criteriaKeysToCompare);
  if (
    criteriaKeys &&
    criteriaKeysToCompare &&
    (intersection(criteriaKeysParents, criteriaKeysToCompareParents).length ===
      0 ||
      intersection(criteriaKeysId, criteriaKeysToCompareId).length > 0)
  ) {
    const rawMergedCriteriaKeys = flattenDeep([
      criteriaKeys,
      criteriaKeysToCompare,
    ]);
    return transformCriteriaKeysArray(rawMergedCriteriaKeys);
  }
  if (criteriaKeys === null || criteriaKeysToCompare === null) {
    return (
      (criteriaKeysToCompare &&
        transformCriteriaKeysArray(criteriaKeysToCompare)) ||
      (criteriaKeys && transformCriteriaKeysArray(criteriaKeys))
    );
  }
  if (
    intersection(criteriaKeysParents, criteriaKeysToCompareParents).length > 0
  ) {
    return false;
  }
  return false;
};
