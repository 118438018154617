import React from 'react';
import { findKey } from 'lodash';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import { QemotionTheme } from '../../Utils/qemotionTheme';

typeof document !== 'undefined' && require('echarts-wordcloud').default;
const ReactEcharts =
  typeof document !== 'undefined' && require('echarts-for-react').default;

class CRadarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSet: this.props.data.byEIndex,
      correspondingCategories: [],
    };
  }

  componentWillMount() {
    if (typeof document !== 'undefined') {
      const echarts = require('echarts');
      echarts.registerTheme('QemotionTheme', QemotionTheme);
    }
    if (this.state.dataSet && this.state.dataSet.thematicName) {
      this.resize;
    }
  }

  componentDidMount() {
    this.createStateForCategories(this.state.dataSet.thematicName);
    window.addEventListener('resize', this.resize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ dataSet: this.props.data.byEIndex });
    }
    if (this.state.dataSet !== prevState.dataSet) {
      this.createStateForCategories(this.state.dataSet.thematicName);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  getOption = (dataSet, correspondingCategories) => ({
    tooltip: {},
    radar: {
      triggerEvent: true,
      shape: 'circle',
      name: {
        textStyle: {
          color: '#592ea0',
          fontSize: 14,
          fontWeight: 'bold',
          backgroundColor: '#DED6EC',
          borderRadius: 100,
          padding: [8, 16],
        },
      },
      indicator: dataSet.thematicName.map(categ => ({
        name: correspondingCategories[categ.name],
        min: categ.min,
        max: categ.max,
      })),
    },
    series: {
      name: 'Human qualities',
      type: 'radar',
      borderRadius: 30,
      label: true,
      data: [
        {
          value: dataSet.thematicValue,
          name: 'Claims',
          label: {
            normal: {
              show: true,
              formatter(params) {
                return `{a|${params.value}}`;
              },
              rich: {
                a: {
                  fontSize: 18,
                  fontWeight: 'bold',
                  color: '#fff',
                  backgroundColor: '#592ea0',
                  borderRadius: 16,
                  width: 32,
                  height: 32,
                  align: 'center',
                  verticalAlign: 'middle',
                  textBorderColor: 'transparent',
                },
              },
            },
          },
          areaStyle: {
            normal: {
              color: 'rgba(89, 46, 160, .25)',
            },
          },
          lineStyle: {
            normal: {
              color: '#592ea0',
            },
          },
        },
      ],
    },
  });

  getRefactoredCategoryName(name) {
    const isSpaceInString = name.indexOf(' ');
    let newName = '';
    if (isSpaceInString === -1) {
      newName = this.tooManycharacters(name);
    } else {
      const arrayWords = name.split(' ');
      let rowOne = '';
      let rowTwo = '';
      const oneThirdString = name.length / 3;
      for (let i = 0; i < arrayWords.length; i++) {
        if (rowOne.length < oneThirdString) {
          rowOne = `${`${rowOne} ${arrayWords[i]}`}`;
        } else {
          rowTwo = `${`${rowTwo} ${arrayWords[i]}`}`;
        }
      }
      if (rowTwo.length === 0) {
        rowOne = name.substring(0, isSpaceInString);
        rowTwo = name.substring(isSpaceInString + 1, name.length);
      }
      rowOne = this.tooManycharacters(rowOne);
      rowTwo = this.tooManycharacters(rowTwo);
      newName = `${rowOne}\n${rowTwo}`;
    }
    return newName;
  }

  modifyStringCharacters(string, number, punctuation = '...') {
    let newValue = string;
    const numberOfCharacters = string.length;
    if (numberOfCharacters > number) {
      newValue = `${newValue.substr(0, number)}${punctuation}`;
    }
    return newValue;
  }

  tooManycharacters(string) {
    const windowWidth = window.innerWidth;
    let newValue;
    switch (true) {
      case windowWidth < 620:
        newValue = this.modifyStringCharacters(string, 3);
        break;
      case windowWidth < 700:
        newValue = this.modifyStringCharacters(string, 5);
        break;
      case windowWidth < 1282:
        newValue = this.modifyStringCharacters(string, 15);
        break;
      case windowWidth < 1310:
        newValue = this.modifyStringCharacters(string, 5);
        break;
      case windowWidth < 1350:
        newValue = this.modifyStringCharacters(string, 7);
        break;
      default:
        newValue = this.modifyStringCharacters(string, 15);
    }
    return newValue;
  }

  createStateForCategories(categories) {
    const objectCategoriesCorrespondance = {};
    categories.forEach(category => {
      const { name } = category;
      const newName = this.getRefactoredCategoryName(category.name);
      objectCategoriesCorrespondance[name] = newName;
    });
    this.setState({ correspondingCategories: objectCategoriesCorrespondance });
  }

  resize = () => this.createStateForCategories(this.state.dataSet.thematicName);

  changeData = value => {
    this.setState({
      dataSet: value,
    });
  };

  render() {
    const btns = [
      {
        label: 'By e-index',
        id: this.props.data.byEIndex,
      },
      {
        label: 'By mentions',
        id: this.props.data.byMentionCount,
      },
    ];
    const onEvents = {
      click: param => {
        if (param.componentType === 'radar') {
          const categoryNameToSend = findKey(
            this.state.correspondingCategories,
            item => item.indexOf(param.name) !== -1,
          );
          this.props.callbackParent(categoryNameToSend);
        }
      },
    };
    const { dataSet } = this.state;
    return (
      <div className="text-center my-0 h-100">
        <QSwitcher
          selected={dataSet}
          options={btns}
          onOptionSelected={this.changeData}
          padding="0.25rem 1rem"
          display="inline-flex"
          width="auto"
        />
        {ReactEcharts ? (
          <ReactEcharts
            option={this.getOption(
              this.state.dataSet,
              this.state.correspondingCategories,
            )}
            style={{
              minHeight: '200px',
              height: 'calc(100% - 128px)',
              width: '100%',
            }}
            className="react_for_echarts"
            theme="QemotionTheme"
            onEvents={onEvents}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default CRadarChart;
