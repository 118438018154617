import React from 'react';
import { DropdownItem } from 'reactstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import Link from '_Components/Link';
import Icon from '_Components/Icons/Icon';
import { fontSizes } from 'styles/abstracts/typography';
import { ImageSize } from 'styles/abstracts/variables';
import { grayShades } from 'styles/abstracts/colors';

const DDItem = styled(DropdownItem)`
  font-size: ${fontSizes.smaller};
  cursor: ${({disabled}) => !disabled && 'pointer'} !important;
  color: ${({disabled}) => disabled && grayShades.g800} !important;
`;

const DDPortalContent = props => {
  const dropdownItemOnclick = (props = null, module, page) => props.active;

  const { t } = props;
  return (
    props.pages &&
    props.pages.map((item, i) =>
      item.id !== 'divider' ? (
        !item.hide && (
          <Link
            key={item.id}
            to={(item.id !== 'world-map' || (item.id === 'world-map' && props.planName === 'emotion_corporate')) && { pathname: item.href }}
            onClick={dropdownItemOnclick(props, item.id)}
          >
            <DDItem
              disabled={item.id === 'world-map' && props.planName !== 'emotion_corporate'}
              key={`keyDropdownItem-${item.id}-${i * 2}`}
              style={
                !item.href.length > 0
                  ? { color: grayShades.g500 }
                  : { color: 'inherit' }
              }
            >
              {item.href.length > 0 ? (
                <>
                  {item.icon && (
                    <span className="mr-2">
                      <Icon icon={item.icon} size={ImageSize.small} />
                    </span>
                  )}
                  {t(item.name)}
                </>
              ) : (
                t(item.name)
              )}
            </DDItem>
          </Link>
        )
      ) : (
        <DropdownItem divider key={`keyDropdownItem-${item.id}-${i * 2}`} />
      ),
    )
  );
};

const mapStateToProps = state => ({
  planName: get(state, ['auth', 'user', 'account', 'planName']),
})

export default withTranslation('header')(connect(mapStateToProps)(DDPortalContent));
