import React from 'react';
import styled, { keyframes } from 'styled-components';
import { grayShades } from 'styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';
import {
  getClassCssRondType,
  getCssRondText,
} from '_Charts/KpisCharts/KpiBadges/utils';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { EnhancerOptions as undefined } from 'redux-devtools-extension';
import { getBackgroundCSSFormEindex } from '_Utils/eindex/eindexColorFunctions';
import { convertCelsiusToFahrenheit } from '_Utils/eindex/eindexUnits';

const Container = styled.div`
  position: relative;
  height: 100%;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    color: #fff;
  }
`;

const NoData = styled.div`
  font-size: ${({type}) => ((type === 'small') ? '20px' : '38px')}!important;
  line-height: ${({type}) => ((type === 'small') ? '20px' : '38px')};
  color: ${grayShades.g500} !important;
`;

const rotation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
`;

const Rotate = styled.div`
  -webkit-animation: ${rotation} 2s infinite linear;
  margin-top: 4px;
`;

const MainContainer = styled.div`
  background-color: ${({ eindex, isFahrenheit }) =>
    (eindex || eindex === 0) && eindex !== 'no data'
      ? getBackgroundCSSFormEindex(eindex, isFahrenheit)
      : grayShades.g300};
  ${({ type }) => getClassCssRondType(type)}

  & > div {
    ${({ type }) => getCssRondText(type)}
  }
`;

const EindexRond = props => {
  const isFahrenheit =
    props.fahrenheitOverride !== undefined
      ? props.fahrenheitOverride
      : props.eindexUnitFahrenheit;
  const eindex =
    props.eindex !== 'no data'
      ? isFahrenheit
        ? convertCelsiusToFahrenheit(parseInt(props.eindex))
        : parseInt(props.eindex)
      : props.eindex;
  return (
    <MainContainer
      eindex={eindex}
      isFahrenheit={isFahrenheit}
      className={`${getClassCssRondType(props.type)} ${props.customCSS}`}
      type={props.type}
    >
      <Container>
        {eindex || eindex === 0 ? (
          eindex !== 'no data' ? (
            <div>{`${eindex}°`}</div>
          ) : (
            <NoData type={props.type}>{`${eindex}`}</NoData>
          )
        ) : (
          <Rotate>
            <Icon icon="INTERFACE_LOADING" color={grayShades.g800} />
          </Rotate>
        )}
      </Container>
    </MainContainer>
  );
};
function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
    eindexUnitFahrenheit: get(state, [
      'projectConfiguration',
      'unitsAndLocalesSettings',
      'eindexUnitFahrenheit',
    ]),
  };
}

export default connect(mapStateToProps)(EindexRond);
