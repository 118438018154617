import styled from 'styled-components';
import PropTypes from 'prop-types';
import { createStyle } from '../../../styles/utils';
import { TEXT_TYPES } from '../textTypes';
import { TEXT_POSITIONS } from '../textPositions';
import { spacing } from '../../../styles/abstracts/variables';
import { grayShades } from '../../../styles/abstracts/colors';

const typeOptions = {
  [TEXT_TYPES.HEADER]: {
    'font-size': '2rem',
  },
  [TEXT_TYPES.PANEL]: {
    'font-size': '1.25rem',
  },
  [TEXT_TYPES.SECTION]: {
    'font-size': '1rem',
  },
};

const QTitle = styled.div`
  margin-top: ${spacing.small};
  margin-bottom: ${spacing.xsmall};
  font-weight: bold;
  text-align: ${({ position }) => position};
  ${({ type }) => createStyle(typeOptions[type])};
`;

QTitle.propTypes = {
  position: PropTypes.oneOf(Object.values(TEXT_POSITIONS)),
  type: PropTypes.oneOf(Object.values(TEXT_TYPES)),
};

QTitle.defaultProps = {
  position: TEXT_POSITIONS.LEFT,
  type: TEXT_TYPES.PANEL,
};

QTitle.Title = styled.div`
  padding-bottom: ${spacing.xsmall};
`;

QTitle.Subtitle = styled.div`
  color: ${grayShades.g800};
  font-size: 80%;
  line-height: 1.2;
  padding-bottom: ${spacing.xsmall};
`;

export default QTitle;
