import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import {
  passwordUpperCaseVerification,
  passwordDigitVerification,
  passwordLengthVerification,
} from 'Routes/UtilityRoutes/PasswordReset/utils';
import QPanel from '../../../_Components/Panels/QPanel/QPanel';
import { grayShades, black, purple } from '../../../styles/abstracts/colors';

import QPanelHeader from '../../../_Components/Panels/QPanelHeader/QPanelHeader';
import QButton from '../../../_Components/Buttons/QButton/QButton';

import PasswordSection from './_Component/PasswordSection';
import UPDATE_PASSWORD from './graphql/updatePassword';

const StyledQPanel = styled(QPanel)`
  padding: 0;
  margin-top: 2rem;
`;
const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 0;
  border-bottom: 1px solid ${grayShades.g300};
`;
const ButtonSave = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  & button {
    background-color: ${purple};
    &:hover {
      background-color: ${black};
    }
  }
`;
class AccountAndPreferencesCard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      passwords: {
        inputCurrentPass: null,
        inputNewPass: null,
        inputVerificationPass: null,
      },
      invalidCurrent: false,
      noMatching: false,
      noLength: false,
      currentNoInput: false,
      newNoInput: false,
      passwordUpdated: false,
    };
  }

  getStatePassword({ name, value }) {
    this.setState((prevState, props) => ({
      passwords: { ...prevState.passwords, [name]: value },
    }));
    this.resetInputCurrent();
    this.resetInputMatching();
  }

  graphQLErrorMessages(errorsFromCatch) {
    const errors = errorsFromCatch.graphQLErrors[0];
    const errorMessage = errors.message;
    if (errorsFromCatch.graphQLErrors.length === 2) {
      this.setState({
        currentNoInput: true,
        newNoInput: true,
      });
    } else {
      switch (errorMessage) {
        case 'invalid current_password':
          this.setState({
            invalidCurrent: true,
          });
          break;
        case 'Variable currentPassword of type String! was provided invalid value':
          this.setState({
            currentNoInput: true,
          });
          break;
        case 'Variable newPassword of type String! was provided invalid value':
          this.setState({
            newNoInput: true,
          });
          break;
        default:
          return null;
      }
    }
    return errorMessage;
  }

  passwordUpdated(data) {
    data !== null
      ? (this.setState({ passwordUpdated: true }),
        setTimeout(() => {
          this.setState({ passwordUpdated: false });
        }, 5000),
        this.resetValueInputs())
      : null;
  }

  resetValueInputs() {
    this.setState({
      passwords: {
        inputCurrentPass: '',
        inputNewPass: '',
        inputVerificationPass: '',
      },
    });
  }

  resetInputCurrent() {
    this.setState({
      invalidCurrent: false,
      currentNoInput: false,
      passwordUpdated: false,
    });
  }

  resetInputMatching() {
    this.setState({
      noMatching: false,
      noLength: false,
      newNoInput: false,
      passwordUpdated: false,
    });
  }

  render() {
    const currentPassword = this.state.passwords.inputCurrentPass;
    const newPassword = this.state.passwords.inputNewPass;
    const verificationPassword = this.state.passwords.inputVerificationPass;
    const { t } = this.props;

    return (
      <StyledQPanel>
        <StyledQPanelHeader>
          <PasswordSection
            {...this.props}
            callback={state => this.getStatePassword(state)}
            passwords={this.state.passwords}
            errorStyle={this.state.invalidCurrent}
            noMatching={this.state.noMatching}
            noLength={this.state.noLength}
            currentNoInput={this.state.currentNoInput}
            newNoInput={this.state.newNoInput}
            passwordUpdated={this.state.passwordUpdated}
          />
        </StyledQPanelHeader>
        <ButtonSave>
          <Mutation mutation={UPDATE_PASSWORD}>
            {(updateUserPassword, { data }) => (
              <QButton
                disabled={
                  !currentPassword ||
                  !newPassword ||
                  !verificationPassword ||
                  newPassword !== verificationPassword ||
                  passwordDigitVerification(newPassword) ||
                  passwordLengthVerification(newPassword) ||
                  passwordUpperCaseVerification(newPassword)
                }
                onClick={() => {
                  newPassword.length >= 6 &&
                  verificationPassword.length >= 6 &&
                  newPassword === verificationPassword
                    ? updateUserPassword({
                        variables: {
                          currentPassword,
                          newPassword,
                        },
                      })
                        .catch(error => {
                          this.graphQLErrorMessages(error);
                        })
                        .then(dataPassword => {
                          this.passwordUpdated(dataPassword || null);
                        })
                    : newPassword.length < 6 || verificationPassword.length < 6
                    ? this.setState({ noLength: true })
                    : this.setState({ noMatching: true });
                }}
              >
                {t('Change Password')}
              </QButton>
            )}
          </Mutation>
        </ButtonSave>
      </StyledQPanel>
    );
  }
}
export default withTranslation('button', 'card')(AccountAndPreferencesCard);
