import styled from 'styled-components';
import { FormGroup, Label } from 'reactstrap';
import { grayShades } from '../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../styles/abstracts/typography';

export const LabelSettings = styled(Label)`
  color: ${grayShades.g800};
`;
export const FormGroupSettings = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  width: 250px;
  font-size: ${fontSizes.smallest};
`;
