const idleState = {
  id: '',
  title: '',
  description: '',
  isDefault: '',
  settings: {
    analysisAxis: {
      value: 'emotion',
      label: 'Emotion',
    },
    itemsToDisplay: {
      value: 'thematic',
      label: 'Thematics',
    },
    calculationCriteria: {
      value: 'percentage',
      label: 'Percentage',
    },
    significanceThreshold: 5,
    range: {
      startDate: '',
      endDate: '',
    },
    thematics: [],
    criteriaKeys: [],
  },
  data: [],
  customizations: [],
};

export const setTemporaryTriggersTable = (state, { temporaryTriggersTable }) => {
  if (temporaryTriggersTable) {
    const { settings, id, title, description, isDefault, data, customizations } = temporaryTriggersTable;

    return {
      ...state,
      temporaryTriggers: {
        ...idleState,
        id,
        title,
        description,
        isDefault,
        settings,
        data,
        customizations,
      },
    };
  }
  return {
    ...state,
    temporaryTriggers: null,
  };
};
