import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const DefaultRangeHeader = ({ title }) => (
  <div>
    <Col xs={12}>
      <Row>
        <Col className="p-0dot5">
          <h6 className="font-weight-bold">{title}</h6>
        </Col>
      </Row>
    </Col>
  </div>
);

DefaultRangeHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DefaultRangeHeader;
