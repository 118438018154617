import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { DispatchDataToStore } from '_Resources/Header/Services/api/DispatchDataToStore';
import { loadInsightVerbatimSuccess } from '_Resources/Insight/Actions/loadInsightDetailsVerbatim';
import querySchema from './graphql/getInsight';

class QueryGetInsightVerbatim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <Query
          query={querySchema}
          variables={{
            insightId: this.props.insightId,
            range: this.props.range,
          }}
          // fetchPolicy="network-only"
        >
          {({ loading, error, data, networkStatus }) => {
            if (loading || networkStatus === 4) return false;
            if (error) {
              return false;
            }
            return (
              <DispatchDataToStore
                data={data}
                action={() =>
                  this.props.loadInsightVerbatimSuccess(
                    data.insight.selectedVerbatim,
                  )
                }
              />
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadInsightVerbatimSuccess: configuration => {
      dispatch(loadInsightVerbatimSuccess(configuration));
    },
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(QueryGetInsightVerbatim);
