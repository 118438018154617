import styled from "styled-components";
import QPanel from "./QPanel";

export default styled(QPanel)`
  position: absolute;
  box-sizing: border-box;
  margin: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
