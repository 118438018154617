import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import noDataImg from '_Components/NoDataMessage/img/no-data-img.svg';
import { withTranslation } from 'react-i18next';

const Message = styled.div`
  font-size: 28px;
  color: #928fb0;
  text-align: center;
  line-height: 40px;
  width: 100%;
  height: 100%;
`;

const Details = styled.p`
  font-size: 16px !important;
  color: #928fb0;
  text-align: center;
  line-height: 24px;
`;

const NoDataMessage = props => (
  <Message className="d-flex align-content-center align-items-center">
    <div className="m-auto">
      {props.imagePlaceholder && (
        <img
          className="d-block mx-auto mb-4"
          src={noDataImg}
          alt={props.t(props.mainTitle)}
        />
      )}
      <p className="d-block">{props.t(props.mainTitle)}</p>
      {props.secondText && <Details>{props.t(props.secondText)}</Details>}
    </div>
  </Message>
);

NoDataMessage.propTypes = {
  mainTitle: PropTypes.string,
  secondText: PropTypes.string,
  imagePlaceholder: PropTypes.bool,
};
NoDataMessage.defaultProps = {
  mainTitle: 'No data to show !',
  imagePlaceholder: true,
};

export default withTranslation('text')(NoDataMessage);
