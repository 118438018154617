export const updateSectionSettingsHandler = (state, { settings }) => {
  const { currentPageIndex, currentCardIndex } = state;

  const pages = state.children;
  const currentPage = pages[currentPageIndex];
  const currentCard = currentPage.children[currentCardIndex];

  const newCard = {
    ...currentCard,
    settings,
  };

  const newSections = [...currentPage.children];
  newSections[currentCardIndex] = newCard;

  const newPage = {
    ...currentPage,
    children: newSections,
  };

  const newPages = [...pages];
  newPages[currentPageIndex] = newPage;

  return {
    ...state,
    children: newPages,
  };
};
