import { ActionTypes } from '../../../constants';

export function updateRoute({ route, subRoute, defaultThematic, pageConfiguration }) {
  return {
    type: ActionTypes.UPDATE_ROUTE,
    payload: {
      route,
      subRoute,
      defaultThematic,
      pageConfiguration,
    },
  };
}
