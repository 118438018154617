import { GET_PROJECT_PERIODS } from '_Resources/Header/Actions/getProjectPeriods';
import { GLOBAL_SET_CURRENT_PERIOD } from '../../Global/Actions/setCurrentPeriod';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_PERIODS: {
      return {
        ...state,
        projectPeriods: action.data.data,
      };
    }
    case GLOBAL_SET_CURRENT_PERIOD: {
      return {
        ...state,
        currentPeriod: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
