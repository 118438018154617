import React from 'react';
import PropTypes from 'prop-types';
import CQuery from '../../../_Container/QQuery/CQuery';
import querySchema from '../../../_Charts/KpisCharts/CGaugeChart/graphql/getEmotionalIntensityCardData';
import CGaugeChart from '../../../_Charts/KpisCharts/CGaugeChart/CGaugeChart';

const EmotionIntensityGaugeSectionView = ({ data }) => (
  <CGaugeChart value={data && data.emotionalIntensity.value} />
);

EmotionIntensityGaugeSectionView.propTypes = {
  data: PropTypes.any,
};

EmotionIntensityGaugeSectionView.defaultProps = {
  data: null,
};

const EmotionIntensityGaugeSection = ({
  filters,
  noDataComponent,
  errorComponent,
}) => (
  <CQuery
    query={querySchema}
    filterKeys={filters}
    noDataComponent={noDataComponent}
    errorComponent={errorComponent}
  >
    <EmotionIntensityGaugeSectionView />
  </CQuery>
);

export default EmotionIntensityGaugeSection;
