import mergeDeep from '_Utils/other/mergeDeep';

export const prepareGeneratePdfLayout = config => {
  const { startX, endX, startY, endY, colMargin } = config;

  return (size, sections, { dateRange, filters }) => {
    const { columns, rows } = size;
    const columnSpace = (endX - startX) / columns;
    const rowSpace = (endY - startY) / rows;

    return sections.map(section => {
      const {
        pdfStartingPoint: { x, y, w = 1, h = 1 },
      } = section;
      const sectionConfig = mergeDeep(section, { dateRange, filters });
      const bordersX = {
        start: startX + colMargin + x * columnSpace,
        end: startX - colMargin + (x + w) * columnSpace,
      };
      const bordersY = {
        start: startY + colMargin + y * rowSpace,
        end: startY - colMargin + (y + h) * rowSpace,
      };
      return {
        ...sectionConfig,
        borders: { bordersX, bordersY },
      };
    });
  };
};
