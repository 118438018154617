import React from 'react';
import styleVars from 'styles/abstracts/_variables.module.scss';
import { QemotionTheme } from '../../Utils/qemotionTheme';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

class CHorizontalStackedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (typeof document !== 'undefined') {
      const echarts = require('echarts');
      echarts.registerTheme('QemotionTheme', QemotionTheme);
    }
  }

  getOption = () => ({
    color: styleVars.primary,
    tooltip: {
      show: true,
    },
    grid: {
      bottom: 32,
      top: 0,
      containLabel: false,
    },
    xAxis: [
      {
        show: true,
        min: 0,
        max: 100,
        type: 'value',
        axisLabel: {
          formatter: '{value} %',
          margin: 0,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        show: false,
        type: 'category',
        data: ['Percent'],
      },
    ],

    series: this.getSeries(),
  });

  getSeries = () =>
    this.transformDatatoPercent(this.props.data).map(bar => ({
      name: bar.name,
      type: 'bar',
      stack: '1',
      data: [bar.percentage],
      itemStyle: {
        barBorderColor: '#fff',
        barBorderWidth: 2,
      },
      label: {
        show: true,
        formatter: `{a}`,
        rotate: bar.percentage < 15 ? 90 : 0,
      },
      tooltip: {
        formatter: ` {a}<br/>${bar.value} mentions <br/> ${Math.round(bar.percentage * 10) / 10} %`,
      },
    }));

  transformDatatoPercent = data => {
    if (!data) return [];
    const totalMentions = data.map(elem => elem.value).reduce((value, sum) => value + sum);

    const newData = [];
    data.forEach(elemen => {
      const percentage = ((elemen.value * 100) / totalMentions).toFixed(2);
      newData.push({
        name: elemen.name,
        value: elemen.value,
        percentage,
      });
    });
    return newData;
  };

  render() {
    return (
      <div className="examples">
        <div className="parent">
          <div>
            {ReactEcharts ? (
              <ReactEcharts
                option={this.getOption()}
                style={{ height: '200px', width: '100%' }}
                className="react_for_echarts"
                theme="QemotionTheme"
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CHorizontalStackedBarChart;
