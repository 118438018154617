import styled from 'styled-components';

export const QSideBarLayout = styled.div`
  display: flex;
  //min-height: 600px;
`;

QSideBarLayout.SideBar = styled.div`
  min-width: 248px; // TODO: to variable
  width: 248px;
  border-left: 1px solid #dee; // TODO: to variable
`;

QSideBarLayout.DisplayArea = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;
