import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { DispatchDataToStore } from '_Resources/Header/Services/api/DispatchDataToStore';
import { loadInsightsSuccess } from '_Resources/Insight/Actions/loadInsights';
import { get } from 'lodash';
import querySchema from './graphql/getInsights';

class QueryGetInsights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        {this.props.currentProject && this.props.period && (
          <Query
            query={querySchema}
            variables={{
              projectId:
                this.props.currentProject && this.props.currentProject.id
            }}
            // fetchPolicy="network-only"
          >
            {({ loading, error, data, networkStatus }) => {
              if (loading || networkStatus === 4) return false;
              if (error) {
                return false;
              }
              return (
                <DispatchDataToStore
                  data={data}
                  action={() => this.props.loadInsightsSuccess(data.insights)}
                />
              );
            }}
          </Query>
        )}
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadInsightsSuccess: configuration => {
      dispatch(loadInsightsSuccess(configuration));
    },
  };
}

function mapStateToProps(state) {
  return {
    currentProject: state.projects.currentProject,
    period: get(state, ['filters', 'filterKeys', 'range']),
    dataCollectorId: get(state, ['filters', 'filterKeys', 'dataCollectorId']),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QueryGetInsights);
