import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Mutation } from 'react-apollo';
import QModal from '_Components/Modals/QModal/QModal';
import QModalBody from '_Components/Modals/QModalBody/QModalBody';
import QButton from '_Components/Buttons/QButton/QButton';
import updateProject from '_Cards/SettingsCards/ProjectsCard/graphql/updateProject';

const StyledBody = styled(QModalBody)`
  text-align: center;
`;

const StyledButton = styled(QButton)`
  margin-bottom: 1em;
`;

const UpdateAllProjectsModal = ({ isOpen, open, project, t }) => (
  <QModal
    title={
      project
        ? t('Update XXX', { projectName: project.name })
        : 'Update all your projects'
    }
    subtitle={
      project
        ? 'This project will not be accessible until the processing of the new data is complete.'
        : 'None of your projects will be accessible until the processing of the new data is complete.'
    }
    isOpen={isOpen}
    onClose={() => open(!isOpen)}
    hideFooter
    size="md"
    config={{mentions: false, date: false}}
  >
    <StyledBody>
      <Mutation
        mutation={updateProject}
        variables={{ id: project ? project.id : null }}
      >
        {updateProject => (
          <StyledButton
            onClick={() => {
              updateProject({ variables: { id: project ? project.id : null } });
              open(!isOpen);
            }}
          >
            {t('button:Yes, update')}
          </StyledButton>
        )}
      </Mutation>

      <br />
      <QButton bgColor="grayShades.g800" outline onClick={() => open(!isOpen)}>
        &nbsp; {t('button:No, it is a mistake')}
      </QButton>
    </StyledBody>
  </QModal>
);

export default withTranslation('card', 'button')(UpdateAllProjectsModal);
