import React from 'react';
import CHorizontalStackedBarChart from '../../../_Charts/PoleCharts/CHorizontalStackedBarChart/CHorizontalStackedBarChart';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';
import { HintsContent } from '../../../_Components/QcardPanel/QcardHeader/QcardHints/Hint';

const MentionBreakdownCard = props => (
  <QcardPanel {...props} hints={[HintsContent.HINT_MENTION_BREAKDOWN]}>
    <CHorizontalStackedBarChart data={props.data} />
  </QcardPanel>
);

export default MentionBreakdownCard;
