import styled from 'styled-components';
import { grayShades } from 'styles/abstracts/colors';

export const MessageTable = styled.table`
  width: 100%;
  margin-top: 0.5rem;
  font-weight: bold;
  th {
    color: ${grayShades.g800};
  }
  font-size: smaller;
`;
