import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { setRangeFilter } from '_Resources/Benchmark/Actions/Filters/setRangeFilter';
import { connect } from 'react-redux';
import {
  FILTER_DIMENSIONS,
  TEMPORARY_STORE_TO_WATCH,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import { lightBlue, opacity } from 'styles/abstracts/colors';
import MessageHeader from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/_.Components/MessagesHeader';
import DateRangeMergeMessage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/DateRangeMergeMessage';
import ThematicMergeMessage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/ThematicMergeMessage';
import NormalMergeMessage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/NormalMergeMessage';
import { transformCriteriaKeysArray } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/ConflictsUtils';
import { chain, concat, uniq, isEmpty } from 'lodash';
import { createThematics } from '_Providers/QFilterKeysProvider/QFilterKeysProvider';

const MergeContainer = styled.div`
  background-color: ${opacity.lightBlue};
  border-radius: 1.5rem;
  padding: 1rem;
  margin-top: 2rem;
`;

class MergeMessages extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: true,
    };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  createDateRangeMergeMessage = item => {
    const { context, benchmark } = this.props;
    const itemMergedPeriod = item.mergedSettings[FILTER_DIMENSIONS.DATE_RANGE];
    const itemOriginalPeriod = item.settings[FILTER_DIMENSIONS.DATE_RANGE];
    const globalContextPeriod =
      benchmark.activeBenchmark.settings.globalSettings[
        FILTER_DIMENSIONS.DATE_RANGE
      ];
    return (
      <DateRangeMergeMessage
        context={context}
        isOpen={this.state.collapse}
        itemMergedPeriod={itemMergedPeriod}
        itemOriginalPeriod={itemOriginalPeriod}
        globalContextPeriod={globalContextPeriod}
      />
    );
  };
  createNormalMergeMessage = item => {
    const { context, benchmark } = this.props;
    const itemOriginalFilters =
      item.settings.filters[FILTER_DIMENSIONS.NORMAL] || [];
    const globalContext =
      benchmark.activeBenchmark.settings.globalSettings.filters[
        FILTER_DIMENSIONS.NORMAL
      ];
    const globalContextFilters = globalContext
      ? transformCriteriaKeysArray(globalContext)
      : [];
    const concatArrays = concat(itemOriginalFilters, globalContextFilters);
    const criteriaKeysResults = chain(concatArrays)
      .groupBy('filterCategory')
      .map((values, name) => ({
        name,
        values: uniq(values.map(value => value && value.option.id)),
      }))
      .value();
    return (
      <NormalMergeMessage
        context={context}
        isOpen={this.state.collapse}
        criteriaKeysResults={criteriaKeysResults}
      />
    );
  };
  createThematicMergeMessage = item => {
    const { context, benchmark } = this.props;
    const itemOriginalThematics =
      item.settings.filters[FILTER_DIMENSIONS.THEMATIC] || [];
    const globalContext =
      benchmark.activeBenchmark.settings.globalSettings.filters[
        FILTER_DIMENSIONS.THEMATIC
      ] || [];
    const concatArrays = concat(itemOriginalThematics, globalContext);
    const thematicsResults = createThematics(concatArrays);
    return (
      <ThematicMergeMessage
        context={context}
        isOpen={this.state.collapse}
        thematicsResults={thematicsResults}
      />
    );
  };

  createMergeBox = item => {
    const { type } = this.props;
    const itemMergedSettings =
      type === FILTER_DIMENSIONS.DATE_RANGE
        ? item.mergedSettings[FILTER_DIMENSIONS.DATE_RANGE]
        : type === FILTER_DIMENSIONS.NORMAL
        ? item.mergedSettings.filters[FILTER_DIMENSIONS.NORMAL]
        : item.mergedSettings.filters[FILTER_DIMENSIONS.THEMATIC];
    const isValidItem = itemMergedSettings !== false;
    return isValidItem ? (
      <MergeContainer>
        <MessageHeader
          onClick={this.toggle}
          collapse={this.state.collapse}
          title={
            type === FILTER_DIMENSIONS.DATE_RANGE
              ? 'Calculated date range'
              : type === FILTER_DIMENSIONS.NORMAL
              ? 'Calculated filters'
              : 'Calculated thematics'
          }
          color={lightBlue}
          icon="INTERFACE_INFO"
        />
        {type === FILTER_DIMENSIONS.DATE_RANGE
          ? this.createDateRangeMergeMessage(item)
          : type === FILTER_DIMENSIONS.NORMAL
          ? this.createNormalMergeMessage(item)
          : this.createThematicMergeMessage(item)}
      </MergeContainer>
    ) : null;
  };

  render() {
    const item = this.props.benchmark[
      TEMPORARY_STORE_TO_WATCH[this.props.context].temporarySettingsField
    ];
    return item && !isEmpty(item) ? (
      <div>{this.createMergeBox(item)}</div>
    ) : null;
  }
}

const mapStateToProps = ({ benchmark }) => ({
  benchmark,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetRangeFilter: (type, values, mergedValues) => {
    dispatch(setRangeFilter(type, values, mergedValues));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('conflicts')(MergeMessages));
