/* eslint-disable import/prefer-default-export */

const CATEGORY_SPLITTER = '|';

const prepareCreateSelectOption = categoryName => value => ({
  label: value,
  value,
  categoryName,
});

const prepareCreateRelatedSelectOptions = categoryName => value => {
  const [current, parent] = value.split(CATEGORY_SPLITTER);
  const createSelectOption = prepareCreateSelectOption(categoryName);
  return {
    ...createSelectOption(current),
    parent,
  };
};

export const getOptionsForNormalFilters = filters => {
  const categories = Object.keys(filters);
  return categories.map(category => {
    const createSelectOption = prepareCreateSelectOption(category);
    return {
      name: category,
      label: category,
      items: filters[category].map(createSelectOption),
    };
  });
};

export const getOptionsFromRelatedFilters = filters => {
  const categories = Object.keys(filters);
  return categories.map(category => {
    const [filterFirstValue] = filters[category];
    const isMainCategory =
      filterFirstValue && filterFirstValue.split(CATEGORY_SPLITTER).length <= 1;
    const createRelatedSelectOptions = prepareCreateRelatedSelectOptions(
      category,
    );
    return {
      label: category,
      name: category,
      disabled: !isMainCategory,
      items: filters[category].map(createRelatedSelectOptions),
    };
  });
};
