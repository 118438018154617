import gql from 'graphql-tag';

const mutation = gql`
  mutation updateProjectConfigurationSettings(
    $projectId: Int!
    $settings: Json!
  ) {
    updateProjectConfigurationSettings(
      projectId: $projectId
      settings: $settings
    ) {
      projectId
      automation {
        crontask
        email
      }
      errors
    }
  }
`;

export default mutation;
