import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import QModal from '_Components/Modals/QModal/QModal';
import QInput from '_Components/Fields/QInput/QInput';
import QButton from '_Components/Buttons/QButton/QButton';
import { LabelSettings } from '_Cards/SettingsCards/AccountAndPreferencesCard/_Component/SectionCard';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import mutation from '_Cards/SettingsCards/UserAndPermissionsCard/graphql/deleteUser';
import accountUserQuery from '_Cards/SettingsCards/UserAndPermissionsCard/graphql/getAccountUserList';

const Input = styled(QInput)`
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteUserModal = ({ isOpen, toggle, selectedUser, t, users }) => {
  const [nameVerification, setNameVerification] = useState('');
  const [transfer, setTransfer] = useState(false);

  const [deleteUser] = useMutation(mutation, {
    update: (cache, { data: { deleteUser } }) => {
      const accountId = window.localStorage.getItem('userAccountId');
      let accountUsers;
      try {
        accountUsers = cache.readQuery({
          query: accountUserQuery,
          variables: { accountId: parseInt(accountId, 10) },
        }).getAccountUserList;
      } catch (e) {
        accountUsers = users;
      }
      cache.writeQuery({
        query: accountUserQuery,
        variables: { accountId: parseInt(accountId, 10) },
        data: {
          getAccountUserList: accountUsers.filter(
            account => account.id !== deleteUser.id,
          ),
        },
      });
    },
  });

  const modalFooter = (
    <Footer>
      <QButton
        bgColor="grayShades.g300"
        onClick={() => {
          toggle(!isOpen);
          setNameVerification('');
          setTransfer(false);
        }}
      >
        {t('button:Cancel')}
      </QButton>
      <QButton
        bgColor="red"
        hasOpacity
        disabled={
          nameVerification !==
          `${selectedUser?.firstName} ${selectedUser?.lastName}`
        }
        onClick={() => {
          deleteUser({
            variables: { id: selectedUser?.id, transferModules: transfer },
          })
            .then(() => {
              toggle(!isOpen);
              setNameVerification('');
              setTransfer(false);
            })
            .catch(err => console.error(err));
        }}
      >
        {t('button:Delete this user')}
      </QButton>
    </Footer>
  );

  return (
    <QModal
      onClose={() => toggle(!isOpen)}
      isOpen={isOpen}
      title="Are you sure you want to delete this user ?"
      footer={modalFooter}
      config={{mentions: false, date: false}}
    >
      <LabelSettings>
        {t('To confirm, please enter the name of the user')}
      </LabelSettings>
      <Input
        value={nameVerification}
        onChange={e => setNameVerification(e.target.value)}
        onPaste={e => e.preventDefault()}
      />
      <CheckboxContainer>
        <QCheckbox selected={transfer} onClick={() => setTransfer(!transfer)} />
        <QCheckbox.Label>
          {t(
            "Transfer all the user's benchmarks and reportings on your account",
          )}
        </QCheckbox.Label>
      </CheckboxContainer>
    </QModal>
  );
};

export default DeleteUserModal;
