import gql from 'graphql-tag';

const getClusterVerbatims = gql`
  query getClusterVerbatims(
    $projectId: Int!
    $clusterId: String!
    $range: DateRangeInput!
  ) {
    getClusterVerbatims(
      projectId: $projectId
      clusterId: $clusterId
      range: $range
    ) {
      verbatimList {
        id
        text
        mainSentences
      }
      verbatimsCount
    }
  }
`;

export default getClusterVerbatims;
