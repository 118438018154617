import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { clone } from 'lodash';
import { useMutation } from 'react-apollo';
import QPanel from '_Components/Panels/QPanel/QPanel';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import Icon from '_Components/Icons/Icon';
import { grayShades, lightBlue } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import TableRow from './_Components/TableRow/TableRow';
import SAVE_THEMATICS_OVERVIEW_SETTINGS_MUTATION from '../graphql/saveThematicsOverviewSettings';
import GET_THEMATICS_OVERVIEW_DATA_QUERY from '../graphql/getThematicsOverviewData';

const StyledQPanel = styled(QcardPanel)`
  padding: 1em 0;
`;

const Title = styled(QTitle)`
  padding: 0 1em;
  margin-bottom: 1em;
`;

const TableContainer = styled.div`
  overflow-y: scroll;
  max-height: 62.5vh;
`;

const Head = styled.thead`
  border-bottom: 1px solid ${grayShades.g300};
  border-top: 1px solid ${grayShades.g300};
`;

const StyledCell = styled.th`
  color: ${grayShades.g600};
  font-size: ${fontSizes.default};
  padding: 1em !important;
`;

const TitleCell = styled(StyledCell)`
  width: 33em !important;
`;

const IndicatorCell = styled(StyledCell)`
  width: 9em !important;
  text-align: center;
`;

const CheckboxCell = styled(StyledCell)`
  /* padding: 0; */
  text-align: center;
  width: 2.5rem;
`;

const Checkbox = styled(QCheckbox)`
  margin: 0;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ButtonsContainer = styled.div`
  margin-right: 1em;
`;

const DisplayAllButton = styled.span`
  margin-bottom: 1em;
  margin-left: 1em;
  font-weight: bold;
  cursor: pointer;
`;

const EmailMessageContainer = styled.div`
  color: ${lightBlue};
  font-weight: bold;
  text-align: center;
`;

export const sortByName = cat => {
  cat.sort((a, b) => (a.thematicsOverviewDetails.name > b.thematicsOverviewDetails.name ? 1 : -1));
  return cat;
};

const CategoryHierarchyCard = ({
  thematics,
  t,
  settings,
  projectId,
  filterKeys,
  allSelectedCategories,
  hints,
  message,
}) => {
  const [categories, setCategories] = useState(thematics);
  const [showSelection, setShowSelection] = useState(false);
  const [selectedThematics, setSelectedThematics] = useState([]);
  const [isGlobalSelected, setGlobalSelected] = useState(true);
  const [displayAll, setDisplayAll] = useState(false);

  const [saveThematicsOverviewSettings] = useMutation(SAVE_THEMATICS_OVERVIEW_SETTINGS_MUTATION);

  useEffect(() => {
    setCategories(sortByName(thematics));
  }, [thematics]);

  useEffect(() => {
    setSelectedThematics(settings?.selectedThematics);
  }, [settings]);

  const findAllByKey = (obj, keyToFind) => {
    return (
      obj &&
      Object.entries(obj).reduce(
        (acc, [key, value]) =>
          key === keyToFind
            ? acc.concat(value)
            : typeof value === 'object'
            ? acc.concat(findAllByKey(value, keyToFind))
            : acc,
        [],
      )
    );
  };

  useEffect(() => {
    if (selectedThematics && allSelectedCategories && selectedThematics.length === allSelectedCategories.length) {
      setGlobalSelected(true);
    } else {
      setGlobalSelected(false);
    }
  }, [selectedThematics]);

  const selectAllItems = (obj, keyToFind) => {
    return obj.map(o => {
      if (o.thematicsOverviewDetails) {
        o.thematicsOverviewDetails[keyToFind] = !isGlobalSelected;
        return o;
      }
      Object.entries(o).reduce(a => {
        if (Array.isArray(a[1]) && a[1].length > 0) {
          selectAllItems(a[1], keyToFind);
        }
      });
    });
  };

  const selectAll = () => {
    if (isGlobalSelected) {
      setSelectedThematics([]);
    } else {
      setSelectedThematics(allSelectedCategories);
    }
  };

  return (
    <StyledQPanel hints={hints} displayCardTitle={false} displayCardSubTitle={false} displayHints>
      <TitleContainer>
        <Title>{t('My categories list')}</Title>
        {message && <EmailMessageContainer>{message.message}</EmailMessageContainer>}
        {showSelection ? (
          <ButtonsContainer>
            <QButton
              onClick={() => {
                setShowSelection(false);
              }}
            >
              {t('button:Cancel')}
            </QButton>{' '}
            <QButton
              bgColor="green"
              hasOpacity
              onClick={() => {
                setShowSelection(false);
                const cloneSettings = clone(settings);
                cloneSettings.selectedThematics = selectedThematics;
                saveThematicsOverviewSettings({
                  variables: {
                    projectId,
                    settings: JSON.stringify(cloneSettings),
                  },
                  refetchQueries: [
                    {
                      query: GET_THEMATICS_OVERVIEW_DATA_QUERY,
                      variables: filterKeys,
                    },
                  ],
                });
              }}
            >
              {t('button:Save structure display')}
            </QButton>
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <QButton
              icon="INTERFACE_EDIT"
              outline
              bgColor="grayShades.g600"
              iconPosition="left"
              iconSize={18}
              onClick={() => setShowSelection(!showSelection)}
            >
              {' '}
              {t('button:Edit my selection')}
            </QButton>
          </ButtonsContainer>
        )}
      </TitleContainer>
      <DisplayAllButton onClick={() => setDisplayAll(!displayAll)}>
        <Icon icon="INTERFACE_FILTER_COLLAPSED" size={24} />{' '}
        {displayAll ? t('button:Collapse view') : t('button:Expand view')}
      </DisplayAllButton>
      <TableContainer>
        <Table borderless>
          <Head>
            <tr>
              {showSelection && (
                <CheckboxCell>
                  <Checkbox selected={isGlobalSelected} onClick={() => selectAll()} />
                </CheckboxCell>
              )}
              <TitleCell>{t('Categories')}</TitleCell>
              <IndicatorCell>{t('Mentions')}</IndicatorCell>
              <IndicatorCell>{t('E-index')}</IndicatorCell>
              <td>&nbsp;</td>
            </tr>
          </Head>
          <tbody>
            {categories.map((thematic, index) => (
              <TableRow
                key={thematic}
                data={thematic}
                level="thematic"
                showSelection={showSelection}
                isGlobalSelected={isGlobalSelected}
                setGlobalSelected={setGlobalSelected}
                categories={categories}
                setCategories={setCategories}
                thematicIndex={index}
                setSelectedThematics={setSelectedThematics}
                selectedThematics={selectedThematics}
                settings={settings}
                filterKeys={filterKeys}
                displayAll={displayAll}
              />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </StyledQPanel>
  );
};

CategoryHierarchyCard.propTypes = {
  thematics: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('card', ['button'])(CategoryHierarchyCard);
