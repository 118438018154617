import gql from 'graphql-tag';

const mutationDuplicateEmotionalTriggersTable = gql`
  mutation duplicateEmotionalTriggersTable($id: Int!) {
    duplicateEmotionalTriggersTable(id: $id) {
      errors
      emotionalTriggersTable {
        id
        createdBy {
          email
          firstName
          lastName
        }
        isDefault
        title
        description
        settings {
          analysisAxis
          calculationCriteria
          itemsToDisplay {
            name
            key
          }
          significanceThreshold
          range {
            startDate
            endDate
          }
          thematics {
            thematic
            subThematic
            subSubThematic
          }
          criteriaKeys {
            name
            values
          }
        }
        data {
          color
          originalColor
          name
          originalName
          items {
            name
            originalName
            percentage
            value
          }
          value
          deleted
          masked
        }
        customizations {
          name
          originalName
          color
          originalColor
          items {
            name
            originalName
          }
          deleted
          masked
        }
      }
    }
  }
`;

export default mutationDuplicateEmotionalTriggersTable;
