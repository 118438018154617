import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import QAccordionPanel from '_Components/Panels/QAccordion/QAccordionPanel';
import QButton from '_Components/Buttons/QButton/QButton';
import { updateDisplaySettings } from '../../../../../../_Resources/Reporting/Actions/PageSettings/updateDisplaySettings';
import PresentationSettings, { PRESENTATION_MODES } from '../../../_Components/PresentationSettings';
import { grayShades } from '../../../../../../styles/abstracts/colors';
import { PagePropTypes } from '../../../../../../_Resources/Reporting/Services/types/Page.propTypes';
import {
  sectionInitialConfigState,
  sectionPresentationInitialConfigState,
} from '../../../../../../_Resources/Reporting/Reducers/reporting';
import editConfig from '../../../../../../_Components/Layouts/QLayout/sections/edit';

const AccordionContainer = styled.div`
  margin: 0 -1.5em;
  padding: 1em;
  border-bottom: 2px solid ${grayShades.g300};
  min-width: 29em;
  &:first-child {
    margin-top: -0.5em;
    padding-top: 0;
  }
`;

const SettingsContainer = styled.div`
  margin-top: 2em;
  margin-left: 0.5em;
  font-weight: bold;
`;

const Footer = styled.div`
  text-align: center;
  margin: 1.5em;
`;

class Display extends Component {
  static propTypes = {
    globalSettings: PropTypes.any.isRequired,
    currentPage: PagePropTypes.isRequired,
    currentCardDisplay: PagePropTypes.isRequired,
    dispatchUpdateDisplaySettings: PropTypes.func.isRequired,
    currentPageIndex: PropTypes.number.isRequired,
    currentCardIndex: PropTypes.number.isRequired,
    currentCard: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.onToggle = this.onToggle.bind(this);
  }

  updateSettings = value => {
    const { currentPageIndex, currentCardIndex, dispatchUpdateDisplaySettings } = this.props;
    dispatchUpdateDisplaySettings(value, currentPageIndex, currentCardIndex);
  };

  onToggle = ({ open }) => {
    this.setState({
      open,
    });
  };

  resetSettings = () => this.updateSettings(sectionInitialConfigState);

  render() {
    const { currentCardDisplay, currentCard, t } = this.props;
    const isModified = !isEqual(currentCardDisplay, sectionPresentationInitialConfigState);
    return (
      <>
        <AccordionContainer>
          <QAccordionPanel title={t('Header and footer')} onToggle={this.onToggle.bind(this)}>
            <SettingsContainer>
              <PresentationSettings
                {...currentCardDisplay}
                mode={PRESENTATION_MODES.CARD}
                isModified={isModified}
                onUpdate={this.updateSettings}
                onReset={this.resetSettings}
              />
            </SettingsContainer>
          </QAccordionPanel>
        </AccordionContainer>
        <AccordionContainer>
          {editConfig[currentCard] && editConfig[currentCard].settingsFactory() && (
            <QAccordionPanel title={t('Specific settings')} onToggle={this.onToggle}>
              <SettingsContainer>{editConfig[currentCard].settingsFactory()}</SettingsContainer>
            </QAccordionPanel>
          )}
        </AccordionContainer>
        <Footer>
          <QButton color={isModified ? 'danger' : 'secondary'} onClick={() => isModified && this.resetSettings()}>
            {t('Reset "presentation" to default report settings')}
          </QButton>
        </Footer>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchUpdateDisplaySettings: (...args) => dispatch(updateDisplaySettings(...args)),
});

const mapStateToProps = ({
  reporting: { children, globalSettings, currentPageIndex, currentCardIndex, currentCard },
}) => {
  const currentPage = children[currentPageIndex];
  const { title, header } = currentPage.children[currentCardIndex];
  return {
    currentCardDisplay: { title, header },
    globalSettings,
    currentPageIndex,
    currentCardIndex,
    currentCard,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('reporting')(Display));
