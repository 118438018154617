import React, { Component } from 'react';
import { isEqual } from 'lodash';
import EmotionBreakdownDonutCardContain from './EmotionBreakdownDonutCardContain';

class EmotionBreakdownDonutSection extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    return <EmotionBreakdownDonutCardContain {...this.props} />;
  }
}

export default EmotionBreakdownDonutSection;
