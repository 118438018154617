import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useMutation } from 'react-apollo';
import { loadStripe } from '@stripe/stripe-js';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import { grayShades, lightBlue, purple } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import CREATE_CHECKOUT_MUTATION from '../../graphql/stripeCreateCheckoutSession';

const Container = styled.div`
  border: 2px solid ${grayShades.g300};
  border-radius: 15px;
  //width: 17.25em;
  // IF less than 3 plans
  padding: 0 2em;
  // ENDIF
  text-align: center;
  font-weight: bold;
`;

const Title = styled(QTitle)`
  text-align: center;
`;

const Price = styled.p`
  color: ${lightBlue};
  font-size: calc(${fontSizes.large} * 1.2);
`;

const Subtitle = styled.p`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
`;

const SubContainer = styled.div`
  margin-top: 2em;
`;

const Item = styled.p`
  margin-top: 0.25em;
  color: ${purple};
`;

const ItemsContainer = styled.div`
  margin-top: ${({ isCorporate }) => isCorporate && '3.4em'};
  margin-bottom: 1em;
`;
const ChooseButton = styled(QButton)`
  margin-bottom: 1em;
  margin-top: ${({ isMonthlySelected, isCorporate }) =>
    isMonthlySelected && !isCorporate && '2.15em'};
`;

const BillingMention = styled(Subtitle)`
  font-size: ${fontSizes.extraSmall};
  margin-top: 1em;
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Plan = ({
  sub,
  isMonthlySelected,
  currentPlan,
  t,
  itemPrice,
  setEnterAddress,
  hasAddress,
  setNameId,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [stripeCreateCheckoutSession] = useMutation(CREATE_CHECKOUT_MUTATION);
  const {
    name,
    annualPrice,
    monthlyPrice,
    commentsPerPeriod: comments,
    dataCollection,
    maxCategories,
    customerJourney,
    numberUsers,
    numberBenchmarks,
    numberReportings,
    ticketSupportPriority,
    liveSupport,
    nameId,
  } = sub;
  const isCorporate = !monthlyPrice;

  const handleClick = async () => {
    setLoading(true);
    const stripe = await stripePromise;
    stripeCreateCheckoutSession({
      variables: {
        itemQuantity: 1,
        itemPrice,
        paymentType: 'SUBSCRIPTION',
      },
    }).then(({ data }) => {
      return stripe
        .redirectToCheckout({
          sessionId: data?.stripeCreateCheckoutSession?.sessionId,
        })
        .then(result => {
          if (result.error) {
            console.error(
              'Plan l.93 result.error.message',
              result.error.message,
            );
          }
        });
    });
  };

  return (
    <Container>
      <Title>{name}</Title>
      <div>
        <Price>
          {isMonthlySelected ? monthlyPrice : annualPrice}
          {monthlyPrice ? '€' : t('button:Contact us')}
        </Price>
        {monthlyPrice && (
          <Subtitle>
            {t(
              isMonthlySelected
                ? 'per month (VAT included)'
                : 'per month billed annually *',
            )}{' '}
          </Subtitle>
        )}
      </div>
      <ItemsContainer isCorporate={isCorporate}>
        <SubContainer>
          <Subtitle>Projects</Subtitle>
          <Item>
            {comments !== 'Unlimited'
              ? `${comments} ${t(
                  `comments per ${isMonthlySelected ? 'month' : 'year'}`,
                )}`
              : t(
                  `Unlimited comments per ${
                    isMonthlySelected ? 'month' : 'year'
                  }`,
                )}
          </Item>
          <Item>{t(`${dataCollection} data collection`)}</Item>
          <Item>
            {maxCategories} {t('max categories')}
          </Item>
          <Item>{t(`${customerJourney}-based categories`)}</Item>
        </SubContainer>
        <SubContainer>
          <Subtitle>{t('Features')}</Subtitle>
          <Item>
            {isCorporate
              ? t('Unlimited users')
              : `${numberUsers} ${t(
                  `user${numberUsers > 1 || isCorporate ? 's' : ''}`,
                )}`}
          </Item>
          <Item>
            {isCorporate
              ? t('Unlimited benchmarks')
              : `${numberBenchmarks} ${t(
                  `benchmark${numberBenchmarks > 1 || isCorporate ? 's' : ''}`,
                )}`}
          </Item>
          <Item>
            {isCorporate
              ? t('Unlimited customizable reportings')
              : `${numberReportings} ${t(
                  `customizable reporting${
                    numberReportings > 1 || isCorporate ? 's' : ''
                  }`,
                )}`}
          </Item>
        </SubContainer>
        <SubContainer>
          <Subtitle>{t('Support')}</Subtitle>
          <Item>{t('Online knowledge base')}</Item>
          <Item>
            {t('Ticket support')}: {t(`${ticketSupportPriority} priority`)}
          </Item>
          {liveSupport && <Item>{t('Live support')}</Item>}
        </SubContainer>
        {!isCorporate && !isMonthlySelected && (
          <BillingMention>
            *{' '}
            {t('Billed annually at XXX EUR (excl. taxes)', {
              price: annualPrice * 12,
            })}
          </BillingMention>
        )}
      </ItemsContainer>

      {!isCorporate ? (
        (currentPlan === 'emotion_start' || !currentPlan) && (
          <ChooseButton
            isMonthlySelected={isMonthlySelected}
            isCorporate={isCorporate}
            bgColor="lightBlue"
            onClick={() => {
              if (!hasAddress) {
                setEnterAddress(true);
                setNameId(nameId);
              } else {
                handleClick();
              }
            }}
            disabled={currentPlan === nameId}
            loading={isLoading}
            transition="none"
          >
            {currentPlan === nameId
              ? 'You already have this plan'
              : t('button:Choose this plan')}
          </ChooseButton>
        )
      ) : (
        <ChooseButton
          isCorporate={isCorporate}
          bgColor="lightBlue"
          onClick={() => window.open('mailto:support-team@qemotion.com')}
        >
          {t('button:Contact us')}
        </ChooseButton>
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  currentPlan: get(state, ['auth', 'user', 'account', 'planName']),
});

export default connect(mapStateToProps)(Plan);
