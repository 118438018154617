import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Sidebar } from '../_Components/Sidebar';
import ReportingTabPanel from './ReportingTabPanel';
import ReportingTabContent from './ReportingTabContent';

class ReportingSidebar extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className } = this.props;
    return (
      <Sidebar className={className}>
        <ReportingTabPanel />
        <ReportingTabContent />
      </Sidebar>
    );
  }
}

export default withTranslation('reporting')(ReportingSidebar);
