import React from 'react';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { setSpecificMapFilters } from '_Resources/SpecificFiltering/Actions/setSpecificCardThematicTab';
import { resetPageSpecificFilters } from '_Resources/SpecificFiltering/Actions/resetPageSpecificFilters';
import { connect } from 'react-redux';
import CQuery from '_Container/QQuery/CQuery';
import CWorldMapVizTransformData from 'Routes/Place/WorldMap/_Charts/CWorldMapViz';
import querySchema from './graphql/getMap';

class CWorldMapVizSection extends React.Component {
  state = {
    mapOptions: { center: { lat: 46.582344, lng: 2.333401 }, zoom: 2 },
    activeMarker: null,
  };

  config = {
    key: process.env.REACT_APP_WORLD_MAP_KEY,
    language: this.props.i18n.language || 'fr',
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.filterKeys &&
      prevProps.filterKeys !== this.props.filterKeys
    ) {
      if (this.props.filterKeys.projectId !== prevProps.filterKeys.projectId) {
        this.props.resetPageSpecificFilters(this.props.idPage);
        this.setState({ activeMarker: null });
      } else if (this.state.activeMarker) {
        this.setKeys(this.state.activeMarker);
      }
    }
  }

  // shouldComponentUpdate(prevProps, prevState) {
  //   return this.props.filterKeys !== prevProps.filterKeys;
  // }

  setKeys(storeData) {
    if (storeData === null) {
      this.props.resetPageSpecificFilters(this.props.idPage);
      this.setState({ activeMarker: null });
      return;
    }
    const {
      filterKeys: {
        range,
        projectId,
        thematics: globalThematics = [],
        criteriaKeys: globalNormalCriteriaKey = [],
      },
    } = this.props;
    const {
      thematic: markerThematic = [],
      normal: markerNormalCriteriaKey = [],
    } = storeData.filters;

    const { mentionCount } = storeData.results;

    const activeMarker = storeData.id !== null ? { ...storeData } : null;

    const activeFilters = {
      range,
      projectId,
      thematics: [...globalThematics, ...(markerThematic || [])],
      criteriaKeys: [
        ...globalNormalCriteriaKey,
        ...(markerNormalCriteriaKey || []),
      ],
    };

    const dataToDispatch = {
      ...activeFilters,
      marker: activeMarker,
      idPage: this.props.idPage,
      mentionCount,
    };

    this.props.setSpecificMapFilters(dataToDispatch);
    this.setState({
      activeMarker,
    });
  }

  render() {
    let updatedFilterKeys = this.props.filterKeys;
    if (updatedFilterKeys && updatedFilterKeys.texts) {
      updatedFilterKeys.texts.map(text => {
        updatedFilterKeys = { ...updatedFilterKeys, [text.name]: text.values };
      });
      delete updatedFilterKeys.texts;
    }
    return (
      <div style={{ height: '100%' }}>
        <CQuery query={querySchema} filters={updatedFilterKeys}>
          <CWorldMapVizTransformData
            config={this.config}
            mapOptions={this.state.mapOptions}
            setKeys={location => this.setKeys(location)}
            fahrenheit={
              this.props.filterKeys
                ? get(this.props, ['eindexUnitFahrenheit'])
                : false
            }
            activeMarker={this.state.activeMarker}
            fetchPolicy="network-only"
          />
        </CQuery>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
    idPage: state.routes.subRoute.id,
    eindexUnitFahrenheit:
      get(state, [
        'projectConfiguration',
        'unitsAndLocalesSettings',
        'eindexUnitFahrenheit',
      ]) || false,
    state,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setSpecificMapFilters: newState => {
      dispatch(setSpecificMapFilters(newState));
    },
    resetPageSpecificFilters: newState => {
      dispatch(resetPageSpecificFilters(newState));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CWorldMapVizSection));
