import React from 'react';
import styled from 'styled-components';
import IconCircle from '../../../Icons/IconCircle/IconCircle';
import { green } from '../../../../styles/abstracts/colors';

const UploadedText = styled.p`
  font-weight: bold;
`;

const DataUploadedContainer = ({ t, uploadedText, uploadedSubText }) => (
  <span>
    <IconCircle size={56} icon="CHECK_ALL" color={green} />
    <br />
    <UploadedText>{t(uploadedText)}</UploadedText>
    <br />
    <p>{t(uploadedSubText)}</p>
    <br />
  </span>
);

export default DataUploadedContainer;
