import React, { Component } from "react";
import { find, compose, propEq, curry, map } from "ramda";
import PropTypes from "prop-types";
import MapMarker from "../../core/containers/Map/MapMarker";
import { preparePropertyComparer } from "../../core/utils/commonHelpers";
import { register } from "./TransformationsRegister";
import { Point } from "../../core/utils/Point";

const path =
  "M25.6660335,25.6710641 L18.2910744,33.0460232 C18.2853662,33.0517313 18.2796408,33.0574222 18.2738982,33.0630957 C16.7023721,34.6157067 14.1697586,34.600373 12.6171476,33.0288469 C9.78470163,30.161894 6.95225564,27.2949411 4.11980965,24.4279881 C-0.388736091,18.7542982 -0.0224992588,10.4773458 5.224908,5.22993856 C10.8696396,-0.414793074 20.0213019,-0.414793074 25.6660335,5.22993856 C31.3107651,10.8746702 31.3107651,20.0263324 25.6660335,25.6710641 Z";

export default class EmotionMarker extends Component {
  state = { options: {}, listeners: {} };
  isPropChanged = preparePropertyComparer(this);
  // NOTE: below assignments are created to avoid using closure in the implementation and allow for static decoration
  path = path;
  textColor = "#FFFFFF";

  componentDidMount() {
    this.updateOptionState();
    this.updateListenersState();
  }

  componentDidUpdate(prevProps) {
    if (
      this.isPropChanged("options", prevProps) ||
      this.isPropChanged("type", prevProps) ||
      this.isPropChanged("fahrenheit", prevProps)
    ) {
      this.updateOptionState();
    }

    if (this.isPropChanged("options", prevProps)) {
      this.updateListenersState();
    }
  }

  updateOptionState() {
    this.setState({
      options: this.prepareMarkerOptions()
    });
  }

  prepareMarkerOptions() {
    const icon = this.prepareMarkerIcon();
    const {
      type,
      options: { position, indicators }
    } = this.props;
    const options = { icon, position };
    const transformationConfig = { isFahrenheit: this.props.fahrenheit };
    const transformations = register.getTransformations(type);
    const indicator = find(propEq("type", type))(indicators);
    return transformations
      ? compose(
          ...map(transform => curry(transform)(indicator), transformations)
        )(options, transformationConfig)
      : options;
  }

  prepareMarkerIcon() {
    return {
      anchor: new Point(16, 33),
      path: this.path,
      fillColor: "#ffffff",
      fillOpacity: 1,
      labelOrigin: new Point(16, 15),
      strokeWeight: 2,
      strokeColor: this.textColor,
      scale: 1.7
    };
  }

  updateListenersState() {
    this.setState({
      listeners: this.prepareMarkersListeners()
    });
  }

  prepareMarkersListeners() {
    const {
      options: { id, name },
      onSelected,
      onMouseOver,
      onMouseOut
    } = this.props;
    return {
      click: () => onSelected && onSelected(id, name),
      mouseover: () => onMouseOver && onMouseOver(id, name),
      mouseout: () => onMouseOut && onMouseOut(id, name)
    };
  }

  render() {
    return (
      <MapMarker
        listeners={this.state.listeners}
        options={this.state.options}
      />
    );
  }
}

EmotionMarker.propTypes = {
  type: PropTypes.string.isRequired,
  fahrenheit: PropTypes.bool,
  options: PropTypes.shape({
    id: PropTypes.number.isRequired,
    position: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};
