/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { first, last } from 'lodash';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QCarouselSwitcher from '../../../../../../../_Components/Panels/QCarouselSwitcher/QCarouselSwitcher';
import QPanelHeader, {
  PANEL_HEADER_TYPES,
} from '../../../../../../../_Components/Panels/QPanelHeader/QPanelHeader';
import { VIEW_MODES } from '../../_Utils/modules';
import { PERIOD_LABELS } from '../../_Utils/periods';
import { INDICATOR_LABELS } from '../../_Utils/indicators';

class ModuleIndicator extends Component {
  // TODO: provide context for grid
  static propTypes = {
    modules: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentModule: PropTypes.string.isRequired,
    onModuleSwitched: PropTypes.func.isRequired,
    period: PropTypes.string.isRequired,
    viewMode: PropTypes.string.isRequired,
    indicator: PropTypes.string.isRequired,
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  onCarouselPreviousClicked = () => {
    const { modules, currentModule, onModuleSwitched } = this.props;

    const index = modules.indexOf(currentModule);
    const prevIndex = index - 1;
    const previousModule = prevIndex < 0 ? last(modules) : modules[prevIndex];

    onModuleSwitched(previousModule);
  };

  onCarouselNextClicked = () => {
    const { modules, currentModule, onModuleSwitched } = this.props;

    const index = modules.indexOf(currentModule);
    const nextIndex = index + 1;
    const nextModule =
      nextIndex > modules.length - 1 ? first(modules) : modules[nextIndex];

    onModuleSwitched(nextModule);
  };

  prepareLabel() {
    const { viewMode, indicator, period, currentModule, t } = this.props;
    return viewMode === VIEW_MODES.ALL
      ? `${t(PERIOD_LABELS[period])}: ${INDICATOR_LABELS[indicator]}`
      : currentModule;
  }

  areControlsHidden() {
    const { viewMode } = this.props;
    return viewMode === VIEW_MODES.ALL;
  }

  render() {
    const { className } = this.props;
    return (
      <QCarouselSwitcher
        className={className}
        onPrevClicked={this.onCarouselPreviousClicked}
        onNextClicked={this.onCarouselNextClicked}
        hideControls={this.areControlsHidden()}
      >
        <QPanelHeader type={PANEL_HEADER_TYPES.PANEL}>
          {this.prepareLabel()}
        </QPanelHeader>
      </QCarouselSwitcher>
    );
  }
}

export default withTranslation('card')(ModuleIndicator);
