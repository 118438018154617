import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-top: 3em;
  padding-bottom: 3em;

  & > * {
    margin: 0 3em;
    flex-shrink: 0;
  }
`;
