import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import XLSX from 'xlsx';

import { red } from 'styles/abstracts/colors';
import { getRoute } from '_Utils/routes/routeUtils';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import QPanel from '_Components/Panels/QPanel/QPanel';

const ADD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      errors
    }
  }
`;

const InsertData = ({ idpage, updateRoute }) => {

  const [file, setFile] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [apiParams, setApiParams] = useState(null);
  const [csvData, setCsvData ] = useState(null);
  const [uploadFile] = useMutation(ADD_FILE);

  const handleFile = data => {
    const file = data;
    const reader = new FileReader();
    let d;
    reader.onload = e => {
      d = e.target.result;
      convertWorkbookToData(XLSX.read(d, {type: 'binary'}));
    }
    file && reader.readAsBinaryString(file);
  }

  const convertWorkbookToData = (wb) => {
      const sheetName = wb.SheetNames[0];
      const sheet = wb.Sheets[sheetName];
      let rowIndex = 2;
      let data = [];
      let headerNumbers = Object.keys(sheet)
        .filter(
          key =>
            key[key.length - 1] === '1' && key[key.length - 2].match(/[A-Z]/),
        )
        .map(key => key.slice(0, -1));
      let headerCols = [];
      headerNumbers.forEach(num => {
        headerCols = [
          ...headerCols,
          {
            headerName: sheet[`${num}1`].v,
            field: num,
            sortable: true,
            filter: true,
            editable: true,
            resizable: true,
            valueGetter: params =>
              params.data[num] && !isNaN(params.data[num])
                ? parseInt(params.data[num], 10)
                : params.data[num],
            valueSetter: params => {
              params.data[num] = !isNaN(params.newValue)
                ? parseInt(params.newValue, 10)
                : params.newValue;
              return true;
            },
            cellStyle: params => {
              return {
                border:
                  params.colDef.headerName === 'id'
                    ? `1px solid ${red}`
                    : 'none',
              };
            },
          },
        ];
      })
      setColumns(headerCols);

    while(sheet[`A${rowIndex}`]) {
      let row = {};
      headerNumbers.forEach((num) => {
        row[num] =
          sheet[`${num}${rowIndex}`] && sheet[`${num}${rowIndex}`].v;
      });
      data = [...data, row];
      rowIndex++;
    }

    setRowData(data);

  }

  useEffect(() => {
    getRoute(updateRoute, idpage);
      handleFile(file);
  }, [file]);

  const onGridReady = params => {
      setApiParams(params.api);
  };

  const download = (data) => {
    const file = new File([data], 'test.csv', {
      type: 'text/csv',
    });
    uploadFile({ variables: { file } });
  }

  return (
    <div>
      <Container fluid className="py-3 px-4">
        <QPanel style={{ overflowX: 'scroll' }}>
          <input
            type="file"
            onChange={e => setFile(e.target.files[0])}
          />
          {file && (
            <>
              <div
                className="ag-theme-material"
                style={{
                  height: '75vh',
                  width: '100%',
                }}
              >
                <AgGridReact
                  onGridReady={onGridReady}
                  rowData={rowData}
                  columnDefs={columns}
                  enableRangeSelection
                  enableFillHandle
                  undoRedoCellEditing
                  pagination
                  paginationAutoPageSize
                />
              </div>
              <button onClick={() => download(apiParams?.getDataAsCsv())}>
                Export ?
              </button>
            </>
          )}
          {csvData}
        </QPanel>
      </Container>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateRoute: newState => {
    dispatch(updateRoute(newState));
  },
});

export default connect(null, mapDispatchToProps)(InsertData);
