import { FILTERS_REMOVE_STRATEGIES } from '../../../../_Utils/filters/filterUtils';
import { rawFilterValues } from '../../../../_Utils/filters/rawFilterValues';

export const addSectionFilter = (state, { filterType, filterValues }) => {
  const { currentPageIndex, currentCardIndex, children } = state;
  const currentPage = children[currentPageIndex];
  const currentSection = currentPage.children[currentCardIndex];
  const filters = currentSection.filters || {};
  const existingFilters = filters[filterType] || [];

  return {
    ...state,
    children: children.map((page, index) =>
      index !== currentPageIndex
        ? page
        : {
            ...page,
            children: page.children.map((section, sectionIndex) =>
              sectionIndex !== currentCardIndex
                ? section
                : {
                    ...section,
                    filters: {
                      ...filters,
                      [filterType]: [
                        ...existingFilters,
                        ...rawFilterValues(filterValues),
                      ],
                    },
                  },
            ),
          },
    ),
  };
};

export const removeSectionFilter = (
  state,
  { filterType, filterValues: [[filterValue], parentKey] },
) => {
  const { currentPageIndex, currentCardIndex, children } = state;
  const currentPage = children[currentPageIndex];
  const currentSection = currentPage.children[currentCardIndex];
  const filters = currentSection.filters || {};
  const existingFilters = filters[filterType] || [];

  const removingStrategy =
    FILTERS_REMOVE_STRATEGIES[filterType] || FILTERS_REMOVE_STRATEGIES.default;

  return {
    ...state,
    children: children.map((page, index) =>
      index !== currentPageIndex
        ? page
        : {
            ...page,
            children: page.children.map((section, sectionIndex) =>
              sectionIndex !== currentCardIndex
                ? section
                : {
                    ...section,
                    filters: {
                      ...filters,
                      [filterType]: removingStrategy(
                        existingFilters,
                        filterValue,
                        parentKey,
                      ),
                    },
                  },
            ),
          },
    ),
  };
};
