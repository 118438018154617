import gql from 'graphql-tag';

const mutation = gql`
  mutation signUpToZoho {
    signUpToZoho {
      hasSignedUpToZoho
      errors
    }
  }
`;

export default mutation;
