import React, { useState } from 'react';
import styled from 'styled-components';
import { red, opacity } from 'styles/abstracts/colors';
import mutationArchiveInsight from '_Resources/Insight/Services/api/graphql/archiveInsight';
import getInsights from '_Resources/Insight/Services/api/graphql/getInsights';
import { filter } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Mutation } from 'react-apollo';
import DeleteConfirmationModal from 'Routes/Insights/InsightsWall/_Containers/InsightWallGrid/_Containers/InsightDetailsModal/_Containers/DeleteConfirmationModal';
import QButton from '_Components/Buttons/QButton/QButton';

const StyledQButton = styled(QButton)`
  display: flex;
  align-items: center;
  & > * {
    margin: 0 0.25em;
  }
`;

const DeleteInsightButton = ({
  insightId,
  projectId,
  range,
  closeModal,
  t,
}) => {
  const [deleteModalOpened, toggleModal] = useState(false);

  const deleteConfirmButton = (
    <Mutation
      mutation={mutationArchiveInsight}
      onCompleted={() => {
        closeModal();
      }}
      update={(cache, { data: { archiveInsight } }) => {
        const insightList = cache.readQuery({
          query: getInsights,
          variables: { projectId },
        }).insights;
        const newList = filter(
          insightList,
          insight => insight.insightId !== archiveInsight.insight.insightId,
        );
        cache.writeQuery({
          query: getInsights,
          variables: { projectId },
          data: {
            insights: newList,
          },
        });
      }}
    >
      {archiveInsight => (
        <StyledQButton
          bgColor="red"
          onClick={() => {
            archiveInsight({
              variables: {
                projectId,
                id: insightId,
              },
            });
          }}
          icon="TRASH"
          iconSize={24}
          iconPosition="left"
        >
          <span>{t('Yes, delete this insight')}</span>
        </StyledQButton>
      )}
    </Mutation>
  );

  return (
    <>
      <StyledQButton
        bgColor="red"
        hasOpacity
        size="large"
        onClick={() => toggleModal(true)}
        icon="TRASH"
        iconPosition="left"
        iconSize={24}
      >
        {t('Delete this insight')}
      </StyledQButton>
      <DeleteConfirmationModal
        isOpen={deleteModalOpened}
        deleteConfirmButton={deleteConfirmButton}
        onDeleteCanceled={() => toggleModal(false)}
      />
    </>
  );
};

export default withTranslation('button')(DeleteInsightButton);
