import React from 'react';
import { forEach, omit, find, get } from 'lodash';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { capitalize } from '_Utils/strings/stringsUtils';
import './style.scss';
import NoDataMessage from '_Components/NoDataMessage/NoDataMessage';
import CategoryWithLeftEindex from './CategoryWithLeftEindex/CategoryWithLeftEindex';
import Loader from '../../../_Components/Loader/OnLoad';
import VerbatimListModal from '../../../_Components/Modals/VerbatimListModal/VerbatimListModal';

class CategoryGlobalBestAndPainPointsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: { value: 'byEindex', label: 'Display by : E-index' },
      modal: false,
      verbatim: null,
      numberOfMentions: '',
      titleSecondPart: '',
      title: '',
      mentionCount: '',
    };
    this.toggle = this.toggle.bind(this);
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  toggle(value) {
    if (value.categoryHierarchy) {
      this.setState({
        verbatim: null,
        numberOfMentions: 'See all corresponding',
        titleSecondPart: capitalize(value.categoryName),
        title: capitalize(value.categoryHierarchy),
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  setStateVerbatim(value, self) {
    self.setState({
      verbatim: value,
    });
  }

  addInfosToCategoriesData(dataCategoriesToDisplay) {
    const dataCategories = omit(dataCategoriesToDisplay, '__typename');
    forEach(dataCategories, (groupValue, groupKey) => {
      forEach(groupValue, (categoryValue, categoryKey) => {
        const resultThematic = this.getInlineThematic(categoryValue.thematic);
        dataCategories[groupKey][categoryKey] = {
          ...dataCategories[groupKey][categoryKey],
          ...resultThematic,
        };
      });
    });
    return dataCategories;
  }

  getInlineThematic(thematicOrigin) {
    const thematic = omit(thematicOrigin, '__typename');
    if (thematic.subSubThematic) {
      return {
        categoryHierarchy: `${thematic.thematic} > ${thematic.subThematic}`,
        categoryName: `${thematic.subSubThematic}`,
      };
    }
    if (thematic.subThematic) {
      return {
        categoryHierarchy: `${thematic.thematic}`,
        categoryName: `${thematic.subThematic}`,
      };
    }
    return {
      categoryHierarchy: '',
      categoryName: `${thematic.thematic}`,
    };
  }

  createCategoriesList = selectedOption => {
    const switchList = {
      byEindex: 'byEindex',
      byBenchmark: 'byProjectBenchmark',
    };
    const relatedResults = switchList[selectedOption.value];
    const dataCategoriesToDisplay = this.props.data[relatedResults];
    const finalDataCategories = this.addInfosToCategoriesData(dataCategoriesToDisplay);
    const rows = [];
    let maxLength = 0;
    forEach(finalDataCategories, element => {
      maxLength = maxLength < element.length ? element.length : maxLength;
      return maxLength;
    });
    let j = 0;
    for (let i = 0; i < maxLength; i++) {
      forEach(finalDataCategories, val => {
        j += 1;
        rows.push(
          <Col
            key={`CategoryWithLeftEindex${j}`}
            xs="6"
            className={`Notation flexItem ${this.props.reportingMode ? 'mt-1' : 'mt-4'}`}
            onClick={() => {
              this.toggle(val[i]);
              this.setState({ thematicSelected: val[i].thematic });
              this.setState({ mentionCount: val[i].mentionCount });
            }}
          >
            {val[i] && <CategoryWithLeftEindex {...val[i]} index={j} reportingMode={this.props.reportingMode} />}
          </Col>,
        );
      });
    }
    return rows.length > 0 ? <div className="flexContainer"> {rows} </div> : false;
  };

  getOrderByBestOrWorstPoint(thematic) {
    if (this.props.data.byEindex) {
      if (find(this.props.data.byEindex.bestThematics, { thematic })) {
        return 'DESC';
      }
    } else if (this.props.data.byProjectBenchmark) {
      if (
        find(this.props.data.byProjectBenchmark.bestThematics, {
          thematic,
        })
      ) {
        return 'DESC';
      }
    }
    return 'ASC';
  }

  setVariables() {
    return {
      projectId: this.props.filterKeys.projectId,
      range: {
        startDate: moment(get(this.props, 'filterKeys.range.startDate')).format('YYYY-MM-DD'),
        endDate: moment(get(this.props, 'filterKeys.range.endDate')).format('YYYY-MM-DD'),
      },
      criteriaKeys: this.props.filterKeys.criteriaKeys,
      thematics: this.props.globalThematics
        ? [this.state.thematicSelected, ...this.props.globalThematics]
        : [this.state.thematicSelected],
      selectedEmotions: null,
      sort: this.getOrderByBestOrWorstPoint(this.state.thematicSelected),
      keywords: get(this.props, ['filterKeys', 'texts']) ? get(this.props, ['filterKeys', 'texts'])[0]?.values : [],
    };
  }

  render() {
    const { t, reportingMode } = this.props;
    const { selectedOption, numberOfMentions, titleSecondPart, title } = this.state;
    const value = selectedOption && selectedOption.value;
    const rows = this.createCategoriesList(this.state.selectedOption);
    const propsModal = {
      numberOfMentions,
      titleSecondPart,
      title,
      toggle: this.toggle,
      className: this.props.className,
      classBody: 'containVerbatimbubble',
      data: this.state.verbatim,
      modal: this.state.modal,
    };

    const subHeader = (
      <div>
        {/* TODO : rework this select with BE guys and implement it */}
        {/* <Row className="justify-content-center">
          <Col xs="8" sm="4" md="4" lg="4" xl="5">
            <Select
              name="form-field-name"
              value={value}
              className={`${s.selectInput}`}
              searchable={false}
              clearable={false}
              onChange={value => this.handleChange(value)}
              options={[
                { value: 'byEindex', label: `${t('Display by')} : E-index` },
                {
                  value: 'byBenchmark',
                  label: `${t('Display by')} : ${t('Project benchmark')}`,
                },
              ]}
            />
          </Col>
        </Row> */}
        <Row className="">
          <Col className={`${!reportingMode && 'py-3'} mx-3 border-bottom`}>
            <Row>
              <Col className="titleCols success text-center">{t('Best points')}</Col>
              <Col className="titleCols s.error text-center">{t('Worst points')}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
    return rows ? (
      <div style={{ width: '100%' }}>
        {subHeader}
        <Row className="mb-3">
          {this.props.data ? this.createCategoriesList(this.state.selectedOption) : <Loader />}
        </Row>
        <VerbatimListModal mentionCount={this.state.mentionCount} {...propsModal} filters={this.setVariables()} />
      </div>
    ) : (
      <NoDataMessage secondText="No best or pain points found on this project" />
    );
  }
}

function mapStateToProps(state) {
  return {
    globalThematics: get(state, ['filters', 'filterKeys', 'thematics']),
  };
}

export default connect(mapStateToProps)(withTranslation('card')(CategoryGlobalBestAndPainPointsCard));
