import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import { CARD_STEPS } from 'Routes/Settings/ProjectConfiguration/utils';

const StyledRow = styled(Row)`
  margin-top: 1.5em;
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled.p`
  font-weight: bold;
  margin-bottom: 0.5em;
`;

// TODO : Implement translations to reduce file's size
const getHeaderContent = (activeStep, t) => {
  let content = { title: '', explanation: <></> };
  switch (activeStep) {
    case CARD_STEPS.INDUSTRIES:
    case CARD_STEPS.USE_CASES:
      content.title =
        activeStep === CARD_STEPS.INDUSTRIES
          ? t('Select the industry')
          : t('Select the use case you want to analyze');
      content.explanation = (
        <>
          <SubTitle>{t('Why is it useful ?')}</SubTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: t('USE_CASES_INDUSTRIES_EXPLANATION'),
            }}
          />
        </>
      );
      content.contact = (
        <>
          <SubTitle>
            {t(
              `You don't find a suitable ${
                activeStep === CARD_STEPS.INDUSTRIES ? 'industry' : 'use case'
              } ?`,
            )}{' '}
          </SubTitle>
          <p>
            {t(
              `Don't worry, we can configure a new ${
                activeStep === CARD_STEPS.INDUSTRIES ? 'industry' : 'use case'
              } for your needs...`,
            )}
          </p>
        </>
      );
      break;
    case CARD_STEPS.SOURCES_LIST:
      content.title = t('Select the sources you want to monitor');
      content.explanation = (
        <>
          <SubTitle>{t('What are sources ?')}</SubTitle>
          <div
            dangerouslySetInnerHTML={{ __html: t('SOURCES_LIST_EXPLANATION') }}
          />
        </>
      );
      content.contact = (
        <>
          <SubTitle>
            {t("You don't find a source you aim to monitor ?")}
          </SubTitle>
          <p>{t('Please contact us so we can try to add this new source !')}</p>
        </>
      );
      break;
    case CARD_STEPS.SOURCES_CONFIGURATION:
      content.title = t('Configure your sources');
      content.explanation = (
        <>
          <SubTitle>{t('Source configuration')}</SubTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: t('SOURCES_CONFIGURATION_EXPLANATION'),
            }}
          />
        </>
      );
      break;
    case CARD_STEPS.DATE_CONFIGURATION:
      content.title = t('Choose the starting date of the extraction period');
      content.explanation = (
        <>
          <SubTitle>
            {t(
              'Choose the date from which we will collect the available comments',
            )}
          </SubTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: t('DATE_CONFIGURATION_EXPLANATION'),
            }}
          />
        </>
      );
      break;
    case CARD_STEPS.DATA_CONFIGURATION:
      content.title = t('Configure your data');
      content.explanation = (
        <>
          <SubTitle>
            {t("Choose which data to display and how it'll be...")}
          </SubTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: t('DATA_CONFIGURATION_EXPLANATION'),
            }}
          />
        </>
      );
      break;
    case CARD_STEPS.AUTOMATION:
      content.title = t('Automatic feeding of the project');
      content.explanation = (
        <>
          <SubTitle>
            {t('We can automatically search for new data...')}
          </SubTitle>
          <div
            dangerouslySetInnerHTML={{ __html: t('AUTOMATION_EXPLANATION') }}
          />
        </>
      );
      break;
    case CARD_STEPS.VALIDATION:
      content.title = t('Configuration validation');
      content.explanation = (
        <SubTitle>
          {t(
            `Please check this configuration carefully as it cannot be undone and your credit will be deducted from the collected verbatim volume.`,
          )}
        </SubTitle>
      );
      break;
    case CARD_STEPS.PROJECT_NAMING:
      content.title = t('Final step !');
      content.explanation = (
        <SubTitle>
          {t(
            'Just name your project and let us know if you want an email when data is processed !',
          )}
        </SubTitle>
      );
      break;
    default:
      content = {};
  }
  return content;
};

const ProjectConfigurationHeader = ({ activeStep, t }) => {
  const { title, explanation, contact } = getHeaderContent(
    CARD_STEPS[Object.keys(CARD_STEPS)[activeStep]],
    t,
  );
  return (
    <Container fluid className="py-2 px-3">
      <QTitle>{title}</QTitle>
      <StyledRow>
        <Col>{explanation}</Col>
        {!!contact && (
          <Col xs={4}>
            {contact}
            <br />
            <QButton
              hasOpacity
              size="small"
              onClick={() =>
                globalThis.open('mailto:support-team@qemotion.com')
              }
            >
              {t('button:Contact the technical team')}
            </QButton>
          </Col>
        )}
      </StyledRow>
    </Container>
  );
};

export default ProjectConfigurationHeader;
