import React, {
  PureComponent,
  Children,
  cloneElement,
  isValidElement,
} from 'react';
import { connect } from 'react-redux';
import { get, flatten, identity, chain, omit } from 'lodash';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import QNestedInputTag from '../../_Components/Fields/NestedInputs/QNestedInputTags/QNestedInputTag';
import { containsOption, getNaiveKeyImplementation } from './utils';

const categorizeOptions = options =>
  chain(options)
    .groupBy('type')
    .mapValues(typeOptions => typeOptions.map(option => omit(option, 'type')))
    .value();

class QProjectFilters extends PureComponent {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    adaptOptions: PropTypes.bool,
    projectId: PropTypes.number.isRequired,
    children: PropTypes.oneOf([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    className: PropTypes.string,
    initialValues: PropTypes.arrayOf(
      PropTypes.shape({
        option: PropTypes.shape({
          type: PropTypes.string,
          id: PropTypes.string,
          label: PropTypes.string,
        }),
        parents: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
    dispatchSetSelectedFilters: PropTypes.func,
  };

  static defaultProps = {
    className: undefined,
    adaptOptions: true,
    initialValues: [],
    dispatchSetSelectedFilters: () => {},
  };

  state = {
    optionsByFilter: {},
    adapters: {},
  };

  filters = null;

  prepareOnFilterOptionsCreated(index) {
    return options => {
      this.setState(({ optionsByFilter }) => ({
        optionsByFilter: {
          ...optionsByFilter,
          [index]: options,
        },
      }));
    };
  }

  prepareOnFiltersRemoved(index) {
    return () => {
      this.setState(({ optionsByFilter, adapters }) => ({
        optionsByFilter: {
          ...optionsByFilter,
          [index]: undefined,
        },
        adapters: {
          ...adapters,
          [index]: {},
        },
      }));
    };
  }

  prepareRegisterAdapter(index) {
    return adapter => {
      this.setState(({ adapters }) => ({
        adapters: {
          ...adapters,
          [index]: adapter,
        },
      }));
    };
  }

  renderChildren = memoizeOne(children =>
    Children.map(children, (child, index) =>
      isValidElement(child)
        ? cloneElement(child, {
            onFilterOptionsCreated: this.prepareOnFilterOptionsCreated(index),
            onFilterRemoved: this.prepareOnFiltersRemoved(index),
            registerAdapter: this.prepareRegisterAdapter(index),
          })
        : child,
    ),
  );

  onSearch = optionsWithParents => {
    const { onSearch, adaptOptions } = this.props;

    if (adaptOptions) {
      const { adapters } = this.state;
      const allAdapters = Object.values(adapters);

      const adaptedOptions = optionsWithParents.map(optionWithParent => {
        const adapter =
          allAdapters &&
          allAdapters.find(
            ({ isValid }) => isValid && isValid(optionWithParent),
          );
        return adapter ? adapter.transform(optionWithParent) : optionWithParent;
      });
      onSearch(categorizeOptions(adaptedOptions));
    } else {
      onSearch(optionsWithParents);
    }
  };

  previousOptions = null;

  previousProject = null;

  syncIndicator = 0;

  updateSyncIndicator(options) {
    const { projectId } = this.props;
    const isProjectChanged = this.previousProject === projectId;
    const isReferenceToOptionsChanged = this.previousOptions !== options;

    if (!isProjectChanged && isReferenceToOptionsChanged) {
      this.previousOptions = options;
      this.syncIndicator += 1;
    } else if (isProjectChanged) {
      this.previousProject = projectId;
      this.syncIndicator = 0;
    }
  }

  getOptions = memoizeOne(optionsByFilter =>
    flatten(Object.values(optionsByFilter)).filter(identity),
  );

  render() {
    const {
      className,
      children,
      projectId,
      initialValues,
      dispatchSetSelectedFilters,
    } = this.props;
    const { optionsByFilter } = this.state;

    const options = this.getOptions(optionsByFilter);
    const keywords = initialValues.filter(
      value => value.parents[1] === 'keywords',
    );

    const filteredKeywords = keywords.map(keyword => {
      keyword.option = {
        ...keyword.option,
        allowAny: true,
        category: 'Keywords',
        subOptions: false,
        type: 'textSearch',
      };
      return keyword;
    });

    const keywordsOptions =
      options[2] &&
      options[2].subOptions.filter(option => option.id === 'keywords');
    if (keywordsOptions && filteredKeywords.length > 0) {
      keywordsOptions[0].subOptions = filteredKeywords;
    }
    if (options[2]) {
      options[2].subOptions = keywordsOptions;
    }

    const containsOptionForCurrentSet = containsOption(options);
    const currentInitialValues = initialValues.filter(value =>
      containsOptionForCurrentSet(value),
    );

    this.updateSyncIndicator(options);

    const key = `${getNaiveKeyImplementation(currentInitialValues)}--${
      this.syncIndicator
    }--${projectId}`;

    return (
      <>
        <QNestedInputTag
          key={key}
          className={className}
          options={options}
          onSearch={this.onSearch}
          idpage={this.props.idpage}
          initialValues={currentInitialValues}
          dispatchSetSelectedFilters={dispatchSetSelectedFilters}
        />
        {this.renderChildren(children)}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    idpage: state.routes.subRoute && state.routes.subRoute.id,
    projectId: get(state, 'projects.currentProject.id'),
  };
}

export default connect(mapStateToProps)(QProjectFilters);
