import gql from 'graphql-tag';

const unmergeClusters = gql`
  mutation unmergeClusters($projectId: Int!, $mergedClusterId: String!) {
    unmergeClusters(projectId: $projectId, mergedClusterId: $mergedClusterId) {
      errors
      parents
    }
  }
`;

export default unmergeClusters;
