import React from 'react';
import { Col, Row } from 'reactstrap';
import NoteWithLeftIcon from '../../../_Charts/KpisCharts/NoteWithLeftIcon/NoteWithLeftIcon';
import QcardPanel from '../../../_Components/QcardPanel/QcardPanel';

// TODO: Duplicate with SatisfactionNpsCard -> See how to merge and change only the query used and the display depending on the answers ?
const SatisfactionCard = props => (
  <QcardPanel {...props}>
    <Row className="containContent">
      <Col xs="12">
        <NoteWithLeftIcon
          icon="PICTO_STAR"
          color="#592EA0"
          size={48}
          backgroundColor="#592EA0"
          indicator="Satisfaction"
          idTooltip={`satisfactionDeltaTooltip${props.id}` || ''}
          data={props.data}
        />
      </Col>
    </Row>
  </QcardPanel>
);

export default SatisfactionCard;
