import React, { useState } from 'react';
import styled from 'styled-components';
import PermissionsCard from '../PermissionsCard/PermissionsCard';
import { groupPermissions } from './utils/permissions';
import { lightBlue } from 'styles/abstracts/colors';

const BodyContainer = styled.div`
  overflow-y: scroll;
  padding: 2em;
`;

const CardContainer = styled.div`
  margin-bottom: 2em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoLabel = styled.div`
  padding-bottom: 1em;
  font-weight: bold;
  color: ${lightBlue};
  width: 100%;
  text-align: center;
`;

const GroupsTab = ({ selectedGroup, t }) => {
  return (
    <BodyContainer>
      <InfoLabel>
        {t('If you want to change administrators, please contact the support')}
      </InfoLabel>
      {selectedGroup.value &&
        groupPermissions[selectedGroup.value].map(permissions => (
          <CardContainer>
            <PermissionsCard permissions={permissions} t={t} />
          </CardContainer>
        ))}
    </BodyContainer>
  );
};

export default GroupsTab;
