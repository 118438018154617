import { intersectionBy, flattenDeep } from 'lodash';

export const transformCriteriaKeysArray = criteriaKeysToTransform => {
  let mergedCriteriaKeysArray = [];
  criteriaKeysToTransform.forEach(rawCriteriaKey => {
    const mergedCriteriaKeys = {
      filterCategory: rawCriteriaKey.filterCategory || rawCriteriaKey.parents[rawCriteriaKey.parents.length - 1],
      parents: [],
      option: {
        id: rawCriteriaKey.option.id,
        label: rawCriteriaKey.option.label,
      },
    };
    mergedCriteriaKeysArray = [...mergedCriteriaKeysArray, mergedCriteriaKeys];
  });
  return mergedCriteriaKeysArray;
};

export const isValidCriteriaKeys = (criteriaKeys, criteriaKeysToCompare) => {
  if (criteriaKeys === false || criteriaKeysToCompare === false) return false;
  const intersection = value =>
    criteriaKeys &&
    criteriaKeysToCompare &&
    intersectionBy(transformCriteriaKeysArray(criteriaKeys), transformCriteriaKeysArray(criteriaKeysToCompare), value);

  const rawMergedCriteriaKeys =
    criteriaKeys && criteriaKeysToCompare && flattenDeep([criteriaKeys, criteriaKeysToCompare]);

  if (
    criteriaKeys === false ||
    criteriaKeysToCompare === false ||
    (intersection('filterCategory') &&
      intersection('filterCategory').length > 0 &&
      intersection('option.id').length === 0)
  ) {
    return false;
  }
  if (criteriaKeys === null || !criteriaKeys || criteriaKeysToCompare === null || !criteriaKeysToCompare) {
    return (
      (criteriaKeysToCompare && transformCriteriaKeysArray(criteriaKeysToCompare)) ||
      (criteriaKeys && transformCriteriaKeysArray(criteriaKeys))
    );
  }
  return criteriaKeys && criteriaKeysToCompare && transformCriteriaKeysArray(rawMergedCriteriaKeys);
};
