import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import QTabs from '../../../../_Components/Panels/QTabs/QTabs';
import { REPORTING_MODES } from '../../../../_Resources/Reporting/Actions/updateReportingMode';
import QTab from '../../../../_Components/Panels/QTabs/tab/QTab';
import Icon from '_Components/Icons/Icon';
import { gray, purple } from '../../../../styles/abstracts/colors';

const StyledQTabs = styled(QTabs)`
  padding: 0;
`;
const StyledIcon = styled(Icon)``;
const TabBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Label = styled.span`
  font-size: 0.75rem;
`;

const GLOBAL_TABS = [
  { id: 'add-pages', label: 'Add pages', icon: 'ADD_PAGES' },
  {
    id: 'global-layout-settings',
    label: 'Document layout settings',
    icon: 'REPORT_LAYOUT',
  },
  { id: 'cover-settings', label: 'Cover settings', icon: 'REPORT_SETTINGS' },
  { id: 'export', label: 'Export', icon: 'REPORT_EXPORT' },
];

const PAGE_TABS = [
  { id: 'change-page', label: 'Change page', icon: 'ADD_PAGES' },
  { id: 'add-cards', label: 'Add cards', icon: 'ADD_CARD' },
  { id: 'page-settings', label: 'Page settings', icon: 'REPORT_LAYOUT' },
];

const CARD_TABS = [
  { id: 'change-card', label: 'Change card', icon: 'ADD_PAGES' },
  { id: 'card-settings', label: 'Card settings', icon: 'REPORT_LAYOUT' },
];

// TODO: consider moving it to
class TabPanel extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(Object.values(REPORTING_MODES)).isRequired,
    selectedTab: PropTypes.string.isRequired,
    onTabSelected: PropTypes.func.isRequired,
  };

  tabsConfig = {
    [REPORTING_MODES.GLOBAL]: () => GLOBAL_TABS.map(this.createTab),
    [REPORTING_MODES.PAGE]: () => PAGE_TABS.map(this.createTab),
    [REPORTING_MODES.CARD]: () => CARD_TABS.map(this.createTab),
  };

  createTab = desc => {
    const { t, selectedTab } = this.props;
    const { id, label, icon } = desc;
    const color = selectedTab === id ? purple : gray;
    return (
      <QTab id={id}>
        <TabBody>
          <StyledIcon icon={icon} size={24} color={color} />
          <Label>{t(label)}</Label>
        </TabBody>
      </QTab>
    );
  };

  getTabs() {
    const { mode } = this.props;
    return this.tabsConfig[mode]();
  }

  render() {
    const { onTabSelected, selectedTab } = this.props;
    return (
      <StyledQTabs initialPage={selectedTab} onTabChanged={onTabSelected}>
        {this.getTabs()}
      </StyledQTabs>
    );
  }
}

export default withTranslation('reporting')(TabPanel);
