import React from 'react';
import PropTypes from 'prop-types';
import {
  CollapsibleStepTitle,
  StepTitle,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/StepTitle';
import { withTranslation } from 'react-i18next';

const ModalPageTitle = ({ stepTitle, collapseContent, t }) => {
  if (collapseContent) {
    return (
      <CollapsibleStepTitle
        collapseText={t(collapseContent.collapseText)}
        collapseImage={collapseContent.collapseImage}
        stepTitle={stepTitle}
        t={t}
      />
    );
  }

  return <StepTitle>{stepTitle}</StepTitle>;
};

ModalPageTitle.propTypes = {
  stepTitle: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  collapseContent: {
    collapseText: PropTypes.node,
    collapseImage: PropTypes.element,
  }.isRequired,
};

export default withTranslation('benchmark')(ModalPageTitle);
