export const ADD_STAGE_SUCCESS = 'ADD_ALERTING_STAGE_SUCCESS';
export const ADD_STAGE_FAILURE = 'ADD_ALERTING_STAGE_FAILURE';

export const ADD_STAGE = 'ADD_ALERTING_STAGE';

const addStageSuccess = ({ name }) => ({
  type: ADD_STAGE_SUCCESS,
  name,
});

const addStageFailure = error => ({
  type: ADD_STAGE_FAILURE,
  error,
});

export const addStage = addCriteria => addStageSuccess(addCriteria);
