import gql from 'graphql-tag';

const ThematicValuesListFragment = gql`
  fragment ThematicValuesListFragment on ThematicValuesList {
    bestThematics {
      #TODO ...ThematicValuesItemFragment
      value
      thematic {
        thematic
        subThematic
        subSubThematic
      }
      mentionCount
    }
    worstThematics {
      #TODO ...ThematicValuesItemFragment
      value
      thematic {
        thematic
        subThematic
        subSubThematic
      }
      mentionCount
    }
    __typename
  }
`;

export default ThematicValuesListFragment;
