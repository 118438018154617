import React from 'react';
import styled from 'styled-components';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Icon from '_Components/Icons/Icon';
import QButton from '_Components/Buttons/QButton/QButton';
import { setGraphThematics } from '_Resources/Benchmark/Actions/setGraphThematics';
import {
  grayShades,
  lightBlue,
  purple,
  opacity,
  white,
} from '../../../../../../../../../styles/abstracts/colors';
import { spacing } from '../../../../../../../../../styles/abstracts/variables';
import { fontSizes } from '../../../../../../../../../styles/abstracts/typography';

const ToggleButton = styled(QButton)`
  display: flex !important;
  justify-content: space-between;
  color: ${purple} !important;
  min-width: 200px;
  text-align: left !important;
  position: relative;
`;

const DropdownSection = styled.div`
  max-height: 15em;
  overflow-y: scroll;
`;

const BenchmarkItem = styled(DropdownItem)`
  font-size: ${fontSizes.smaller};
  font-weight: bold !important;
  color: #2e096b !important;
  padding: ${spacing.mediumSmall} !important;
`;

const BenchmarkOptions = styled(BenchmarkItem)`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

const AddBenchmarkItem = styled(BenchmarkOptions)`
  color: ${props =>
    props.disabled ? opacity.lightBlue : lightBlue} !important;
  cursor: pointer;
`;

const CustomDivider = styled(DropdownItem)`
  width: 75%;
  margin: ${spacing.small} auto !important;
`;

const StyledMenu = styled(DropdownMenu)`
  top: 0 !important;
`;

const CustomPopover = styled(Popover)`
  background-color: black !important;
  top: calc(${props => props.menuDimensions?.top}px - 1em) !important;
  left: calc(${props => props.menuDimensions?.right}px + 3em) !important;

  & .arrow:after {
    border-right-color: black !important;
  }
`;

const CustomPopoverBody = styled(PopoverBody)`
  color: ${white} !important;
`;

class BenchmarkDropdown extends React.Component {
  static propTypes = {
    benchmarkList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
        settings: PropTypes.array,
      }),
    ).isRequired,
    isOpen: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    toggleAddBenchmarkModal: PropTypes.func.isRequired,
    toggleManageBenchmarksModal: PropTypes.func.isRequired,
    dispatchSetActiveBenchmark: PropTypes.func.isRequired,
    dispatchSetGraphThematics: PropTypes.func,
    activeBenchmarkId: PropTypes.number.isRequired,
    activeBenchmarkTitle: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeBenchmarkTitle: 'No benchmark',
    dispatchSetGraphThematics: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopoverOpen: false,
    };
  }

  getDropdownMenuDimensions() {
    return {
      top: menu.offsetTop,
      right: menu.offsetLeft,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({ menuDimensions: this.getDropdownMenuDimensions() });
    }
  }

  render() {
    const { benchmarkList, t } = this.props;
    return (
      <Dropdown
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        id="addBenchmark"
      >
        <DropdownToggle onClick={this.props.toggle} tag="span">
          <ToggleButton
            bgColor="grayShades.g300"
            icon="INTERFACE_CHEVRON_DOWN"
            iconColor="purple"
            disableHover
          >
            {t(this.props.activeBenchmarkTitle)}
          </ToggleButton>
        </DropdownToggle>
        <StyledMenu positionFixed>
          {!isEmpty(benchmarkList) && (
            <>
              <DropdownSection>
                {benchmarkList.map(data => (
                  <BenchmarkItem
                    onClick={() => {
                      this.props.dispatchSetActiveBenchmark(data);
                      this.props.dispatchSetGraphThematics([]);
                    }}
                    active={data.id === this.props.activeBenchmarkId}
                  >
                    {data.title}
                  </BenchmarkItem>
                ))}
              </DropdownSection>
              <DropdownItem divider disabled />
            </>
          )}
          <AddBenchmarkItem
            onClick={this.props.toggleAddBenchmarkModal}
            disabled={!this.props.canCreateBenchmark}
          >
            <div>
              <Icon
                icon="PLUS"
                size={24}
                color={
                  this.props.canCreateBenchmark ? lightBlue : opacity.lightBlue
                }
              />
              {t('Add a new benchmark')}
            </div>
          </AddBenchmarkItem>
          {!this.props.canCreateBenchmark &&
            this.props.isOpen &&
            document.getElementById('addBenchmark') && (
              <CustomPopover
                target="addBenchmark"
                placement="right"
                isOpen
                menuDimensions={this.state.menuDimensions}
              >
                <CustomPopoverBody>
                  {t(
                    "header:You can't create more benchmarks. Please upgrade your plan in order to create more benchmarks.",
                  )}
                </CustomPopoverBody>
              </CustomPopover>
            )}
          <CustomDivider divider disabled />
          <BenchmarkOptions onClick={this.props.toggleManageBenchmarksModal}>
            <Icon icon="INTERFACE_GEAR" color={grayShades.g800} size={24} />{' '}
            {t('Manage benchmarks')}
          </BenchmarkOptions>
        </StyledMenu>
      </Dropdown>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeBenchmarkId: get(state, ['benchmark', 'activeBenchmark', 'id']),
    activeBenchmarkTitle: get(state, ['benchmark', 'activeBenchmark', 'title']),
    canCreateBenchmark: get(state, [
      'auth',
      'user',
      'account',
      'canCreateBenchmark',
    ]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetGraphThematics: newState => {
      dispatch(setGraphThematics(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark', 'header')(BenchmarkDropdown));
