import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { chain, camelCase, flatten, last } from 'lodash';

const prepareThematicParts = orderedThematicParts =>
  orderedThematicParts.map((thematicValue, index) => {
    const propertyNamePrefix = camelCase(
      Array(index)
        .fill('sub')
        .join('_'),
    );
    const propertyNamePostfix = propertyNamePrefix.length
      ? 'Thematic'
      : 'thematic';
    return {
      property: propertyNamePrefix + propertyNamePostfix,
      value: thematicValue,
    };
  });

const createThematicObject = (thematicObject, { property, value }) => ({
  ...thematicObject,
  [property]: value,
});

export const createThematics = thematic => {
  const result = thematic
    ? thematic.map(({ option: { id, excluded }, parents }) => {
      const [, ...parentThematics] = parents;
      const temp =  prepareThematicParts([...parentThematics, id])
        .reduce(createThematicObject, {});
      temp.excluded = excluded;
      return temp;
    })
    : []
  return result
};

export const getFiltersFromOptions = ({ projectId, dateRange, filters, keywords }) => {
  const { startDate, endDate } = dateRange;
  const { thematic, normal } = filters;
  return {
    range:
      startDate !== null || endDate !== null
        ? {
            startDate,
            endDate,
          }
        : null,
    projectId,
    keywords,
    criteriaKeys:
      normal !== false
        ? flatten(
            chain(normal)
              .groupBy('filterCategory')
              .map((values, name) => {
                if (name === 'undefined') {
                  // IF NAME IS UNDEFINED, IT'S RELATED TO A MULTI TYPE NIT WHICH USES PARENTS AND NOT FILTER CATEGORY (USED FOR BENCHMARK AND REPORTING FILTER SIMPLIFICATION
                  return chain(normal)
                    .groupBy('parents')
                    .map((values, name) => {
                      if (name === 'undefined') {
                      }
                      return {
                        name: last(values[0].parents),
                        values: values.map(value => value.option.id),
                      };
                    })
                    .value();
                }
                return {
                  name: name !== 'undefined' ? name : last(values[0].parents),
                  values: values.map(value => value.option.id),
                };
              })
              .value(),
          )
        : false,
    thematics: createThematics(thematic),
  };
};

export default class QFilterKeysProvider extends PureComponent {
  static propTypes = {
    filters: PropTypes.any.isRequired,
    dateRange: PropTypes.any.isRequired,
    projectId: PropTypes.number.isRequired,
    render: PropTypes.func.isRequired,
  };

  render() {
    const { render } = this.props;
    return render(getFiltersFromOptions(this.props));
  }
}
