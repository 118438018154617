import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Icon from '_Components/Icons/Icon';
import QDropdownMenuToggle from '_Components/Menus/QDropdownMenu/QDropdownMenuToggle';
import styled from 'styled-components';
import { grayShades, black, red, lightBlue } from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import { fontSizes } from 'styles/abstracts/typography';
import ExportDataModal from '_Cards/SettingsCards/ProjectDetailsCard/_Components/ProcessingHistory/_Components/ProcessingHistoryHeader/_Components/ExportDataModal/ExportDataModal';
import EXPORT_PROJECT_DATA_QUERY from '_Cards/SettingsCards/ProjectDetailsCard/graphql/exportProjectData';
import QToast from '_Components/QToast/QToast';
import ArchiveProjectModal from './Modals/ArchiveProjectModal';
import RenameProjectModal from './Modals/RenameProjectModal';
import UpdateProjectModal from './Modals/UpdateAllProjectsModal';
import AlreadyProcessedModal from './Modals/AlreadyProcessedModal';

const Menu = styled(DropdownMenu)`
  z-index: 999 !important;
`;

const Item = styled(DropdownItem)`
  padding: 1em !important;
  color: ${black} !important;
  font-weight: bold !important;
  font-size: ${fontSizes.default} !important;

  & span,
  & svg {
    line-height: 20px !important;
    margin-left: 0.25em;
  }
`;

const Toggle = styled(DropdownToggle)`
  cursor: pointer !important;
`;

const ArchiveItem = styled(Item)`
  color: ${red} !important;
`;

const ActiveProjectRowSettings = ({ project, t, history, goToAddData, index, currentPlan }) => {
  const [isDropdownOpen, setDropdownToggle] = useState(false);
  const [isArchiveModalOpen, openArchiveModal] = useState(false);
  const [isRenameModalOpen, openRenameModal] = useState(false);
  const [isUpdateModalOpen, openUpdateModal] = useState(false);
  const [isAlreadyProcessedModalOpen, openAlreadyProcessedModal] = useState(false);
  const [isExportDataModalOpen, openExportDataModal] = useState(false);
  const [toastMessage, setToastMessage] = useState({});
  return (
    <Dropdown isOpen={isDropdownOpen} toggle={() => setDropdownToggle(!isDropdownOpen)} direction="left">
      <Toggle isOpen={isDropdownOpen} index={index} tag="span">
        <Icon icon="INTERFACE_DOTS_VERTICAL" color={grayShades.g800} size={ImageSize.medium} />
      </Toggle>
      <Menu index={index}>
        {!project.demo && (
          <Item onClick={() => goToAddData()}>
            <Icon icon="INTERFACE_PLUS_CIRCLE" size={ImageSize.medium} color={grayShades.g800} />
            <span>{t('Add data')}</span>
          </Item>
        )}
        {currentPlan !== 'emotion_corporate' && !project.demo && (
          <Item
            onClick={() =>
              moment().diff(moment(project.updatedAt), 'days', true) > 1
                ? openUpdateModal(!isUpdateModalOpen)
                : openAlreadyProcessedModal(!isAlreadyProcessedModalOpen)
            }
          >
            <Icon icon="INTERFACE_REFRESH" size={ImageSize.medium} color={grayShades.g800} />
            <span>{t('Update')}</span>
            <UpdateProjectModal isOpen={isUpdateModalOpen} open={openUpdateModal} project={project} />
            <AlreadyProcessedModal
              isOpen={isAlreadyProcessedModalOpen}
              open={() => openAlreadyProcessedModal(!isAlreadyProcessedModalOpen)}
            />
          </Item>
        )}
        {!project.demo && (
          <Item onClick={() => openRenameModal(!isRenameModalOpen)}>
            <Icon icon="INTERFACE_EDIT" size={ImageSize.medium} color={grayShades.g800} />
            <span>{t('Rename')}</span>
            <RenameProjectModal isOpen={isRenameModalOpen} open={openRenameModal} project={project} />
          </Item>
        )}
        <Item onClick={() => openExportDataModal(!isExportDataModalOpen)}>
          <Icon icon="DOWNLOAD" size={ImageSize.medium} />
          <span>{t('Export data')}</span>
          {ReactDOM.createPortal(
            <ExportDataModal
              t={t}
              setMessage={setToastMessage}
              isOpen={isExportDataModalOpen}
              open={openExportDataModal}
              projectId={project.id}
              projectName={project.name}
              query={EXPORT_PROJECT_DATA_QUERY}
              additionalVariables={{ range: project.period }}
            />,
            document.getElementById('root'),
          )}
          {toastMessage.message && <QToast color={lightBlue}>{toastMessage.message}</QToast>}
        </Item>
        <Item
          onClick={() =>
            history.push(`/project-details/${project.slug}`, {
              id: project.id,
            })
          }
        >
          <Icon icon="INTERFACE_GEAR" size={ImageSize.medium} color={grayShades.g800} />
          <span>{t('Configuration')}</span>
        </Item>
        {!project.demo && <DropdownItem divider />}
        {!project.demo && (
          <ArchiveItem onClick={() => openArchiveModal(!isArchiveModalOpen)}>
            <Icon icon="TRASH" size={ImageSize.medium} color={red} />
            <span>{t('Archive this project')}</span>
            <ArchiveProjectModal isOpen={isArchiveModalOpen} open={openArchiveModal} projectId={project.id} />
          </ArchiveItem>
        )}
      </Menu>
    </Dropdown>
  );
};

export default withTranslation('button', ['card'])(ActiveProjectRowSettings);
