import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SavePageButton from '_Container/SavePageButton/SavePageButton';
import {
  SAVE_REPORT,
  updateQueryReporting,
} from '_Resources/Reporting/Services/MutationSaveReporting';
import _, { get } from 'lodash';
import { getFiltersFromOptions } from '_Providers/QFilterKeysProvider/QFilterKeysProvider';
import { white } from '../../../../../styles/abstracts/colors';
import ReportingBreadCrumbs from './_Containers/ReportingBreadCrumb';
import PreviewHeader from './_Containers/PreviewHeader';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${white};
  min-height: 58px;
  box-shadow: 0 0rem 0.5rem rgba(0, 0, 0, 0.25);
`;

const HEADER_MODES = {
  EDIT: 'edit',
  PREVIEW: 'preview',
};

class ReportingHeader extends Component {
  static propTypes = {
    // state props
    isPreviewMode: PropTypes.bool.isRequired,
  };

  headerOptions = {
    [HEADER_MODES.PREVIEW]: () => <PreviewHeader />,
    [HEADER_MODES.EDIT]: () => [
      <ReportingBreadCrumbs />,
      <SavePageButton
        query={SAVE_REPORT}
        delay={30000}
        variables={this.prepareVariables()}
        updateFunction={(cache, saveReportingModel) =>
          updateQueryReporting(
            this.prepareVariables().projectId,
            this.prepareVariables().title,
            this.prepareVariables().settings,
          )(cache, saveReportingModel)
        }
      />,
    ],
  };

  prepareSettings(initialSettings) {
    const benchmarkSettings = _.cloneDeep(initialSettings);
    return {
      ...benchmarkSettings,
      globalSettings: this.transformChildrenFilters(
        benchmarkSettings.globalSettings,
      ),
      children: benchmarkSettings.children.map(child =>
        this.transformChildrenFilters(child),
      ),
    };
  }

  transformChildrenFilters(child) {
    const { filters, dateRange, children, ...childOtherFilters } = child;
    const transformedChild = {
      ...childOtherFilters,
      ...getFiltersFromOptions({
        filters: filters || { normal: null, thematic: null },
        dateRange: dateRange || { startDate: null, endDate: null },
      }),
      ...(children && {
        children: children.map(subChild =>
          this.transformChildrenFilters(subChild),
        ),
      }),
    };
    return transformedChild;
  }

  prepareVariables() {
    const { report, id, title, projectId } = this.props;
    const reportSettingsTransformed = this.prepareSettings(report);
    return {
      projectId,
      reportingModelId: id,
      title,
      settings: JSON.stringify(reportSettingsTransformed),
    };
  }

  render() {
    const { isPreviewMode } = this.props;
    const reportMode = isPreviewMode ? HEADER_MODES.PREVIEW : HEADER_MODES.EDIT;
    return <Header>{this.headerOptions[reportMode]()}</Header>;
  }
}

const mapStateToProps = ({
  projects,
  reporting: {
    isPreviewMode,
    id,
    children,
    title,
    subtitle,
    logo,
    header,
    globalSettings,
    iterateByOptions,
  },
}) => ({
  projectId: get(projects, 'currentProject.id'),
  id,
  title,
  report: {
    children,
    title,
    subtitle,
    logo,
    header,
    globalSettings,
    iterateByOptions,
  },
  isPreviewMode,
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('reporting')(ReportingHeader));
