import React from 'react';
import { Col } from 'reactstrap';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import moment from 'moment';
import { grayShades, opacity, purple } from '../../../../styles/abstracts/colors';
import grabberGraph from '../../../PoleCharts/CBarGraphChart/grabber_graph.svg';
import NoDataMessage from '../../../../_Components/NoDataMessage/NoDataMessage';

const ReactEcharts =
  typeof document !== 'undefined' && require('echarts-for-react').default;

type RiverChartProps = {
  subFilterToDisplay: string;
  filterToDisplay: string;
  level: string;
  data: Array<any>;
  riverChartDateType: string;
  setRiverChartDateType: any;
  t: any;
  isEindexUnitFahrenheit: boolean;
};

const RiverChart = ({
  subFilterToDisplay,
  filterToDisplay,
  level,
  data,
  riverChartDateType,
  setRiverChartDateType,
  t,
  isEindexUnitFahrenheit,
}: RiverChartProps) => {
  const switcherOptions = [
    {
      label: 'Day',
      id: 'day',
    },
    {
      label: 'Week',
      id: 'week',
    },
    {
      label: 'Month',
      id: 'month',
    },
  ];

  const getOption = () => {
    return {
      tooltip: {
        formatter: a => {
          return `<div><p><b>${
            riverChartDateType === 'day'
              ? moment(a[0].data[0].split('T')[0]).format('L') ||
                a[0].data[0].split('T')[0]
              : riverChartDateType === 'week'
              ? `S${moment(a[0].data[0].split('T')[0]).format('W YYYY')}`
              : moment(a[0].data[0].split('T')[0]).format('MMM YYYY')
          }</b></p>${a
            .map(
              d =>
                `<p>
              ${d?.name}: ${d?.data?.[1]} (${d?.data?.[3]}%)
            </p>`,
            )
            .join('')}</div>`;
        },
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          lineStyle: {
            color: 'rgba(0,0,0,0.2)',
            width: 1,
            type: 'solid',
          },
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        feature: {
          dataView: {
            title: t('graph:View data'),
            lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
            readOnly: false,
            textColor: purple,
            buttonColor: purple,
            iconStyle: {
              borderColor: purple,
            },
          },
          restore: {
            title: t('graph:Reload'),
            iconStyle: {
              borderColor: purple,
            },
          },
          saveAsImage: {
            title: t('graph:Save as image'),
            name: t('graph:Graph Q°Emotion'),
            backgroundColor: '#FFFFFF',
            iconStyle: {
              borderColor: purple,
            },
          },
        },
        emphasis: {
          iconStyle: {
            borderWidth: 2,
            borderColor: purple,
            textBackgroundColor: purple,
            textFill: '#FFFFFF',
            textBorderRadius: [5, 5, 5, 5],
            textPadding: [6, 6, 4, 6],
          },
        },
      },
      singleAxis: {
        top: 50,
        bottom: 50,
        axisTick: {},
        axisLabel: {
          formatter: (value: number) =>
            riverChartDateType === 'day'
              ? moment(value).format('L') || value
              : riverChartDateType === 'week'
              ? `S${moment(value).format('W YYYY')}`
              : moment(value).format('MMM YYYY'),
        },
        type: 'time',
        axisPointer: {
          animation: true,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            opacity: 0.2,
          },
        },
      },
      series: [
        {
          type: 'themeRiver',
          label: {
            show: false,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 20,
              shadowColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
          data,
          color: [
            'rgb(124, 212, 148)',
            'rgb(140, 228, 148)',
            'rgb(187, 235, 118)',
            'rgb(156, 228, 188)',
            'rgb(114, 181, 212)',
          ],
        },
      ],
      dataZoom: [
        {
          show: true,
          start: 0,
          end: 100,
          showDataShadow: false,
          showDetail: false,
          backgroundColor: grayShades.g300,
          fillerColor: '#6f3ac7',
          dataBackgroundColor: 'transparent',
          height: 24,
          bottom: -1,
          handleIcon: `image://${grabberGraph}`,
          handleSize: 16,
          handleStyle: {
            shadowColor: opacity.black,
            shadowBlur: 5,
          },
        },
      ],
    };
  };

  return (
    <Col className="text-center" style={{ height: '100%', marginTop: '1em' }}>
      <>
        <QSwitcher
          selected={riverChartDateType}
          options={switcherOptions}
          onOptionSelected={setRiverChartDateType}
          padding="0.25rem 1rem"
          display="inline-flex"
          width="auto"
        />
      </>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {data[0] !== 'Too many categories' ? (
          ReactEcharts ? (
            <ReactEcharts
              option={getOption()}
              style={{
                height: '90%',
                width: '100%',
              }}
              className="react_for_echarts"
              theme="QemotionTheme"
            />
          ) : (
            ''
          )
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NoDataMessage
              mainTitle="Too many categories!"
              secondText="Please refine your search by selecting 20 categories or less"
            />
          </div>
        )}
      </div>
    </Col>
  );
};

const mapStateToProps = state => ({
  isEindexUnitFahrenheit: get(state, [
    'projectConfiguration',
    'unitsAndLocalesSettings',
    'eindexUnitFahrenheit',
  ]),
});

export default withTranslation('button', ['graph'])(connect(mapStateToProps)(RiverChart));
