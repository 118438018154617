const idleState = {
  settings: {
    globalSettings: {
      filters: {
        normal: null,
        related: null,
        thematic: null,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
    },
    cards: [],
    columns: [],
    conflicts: [],
  },
};

export const setActiveBenchmark = (state, { activeBenchmark }) => {
  if (activeBenchmark) {
    const { settings, id, title, description, isDefault } = activeBenchmark;
    const benchmarkSettings =
      typeof settings === 'string'
        ? JSON.parse(settings)
        : settings || idleState.settings;
    return {
      ...state,
      activeBenchmark: {
        ...idleState,
        id,
        title,
        description,
        isDefault,
        settings: {
          ...benchmarkSettings,
        },
      },
      temporaryColumn: null,
      currentColumnIndex: null,
      temporaryCard: null,
      currentCardIndex: null,
      temporaryConflicts: null,
    };
  }
  return {
    ...state,
    activeBenchmark: null,
    temporaryColumn: null,
    currentColumnIndex: null,
    temporaryCard: null,
    currentCardIndex: null,
    temporaryConflicts: null,
  };
};
