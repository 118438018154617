import gql from 'graphql-tag';

const query = gql`
  query getEindexEvolutionWithMentionsCardData(
    $projectId: Int!
    $range: DateRangeInput!
    $criteriaKeys: [CriteriaKey!]
    $thematics: [ThematicInput!]
    $keywords: [String!]
    $dateType: DateInterval!
    $dataCollectorId: Int
  ) {
    results: getEindexEvolutionWithMentionsCardData(
      projectId: $projectId
      range: $range
      criteriaKeys: $criteriaKeys
      thematics: $thematics
      keywords: $keywords
      dateType: $dateType
      dataCollectorId: $dataCollectorId
    ) {
      eindexEvolutionWithMention {
        date
        value
        mentionCount
      }
    }
  }
`;

export default query;
