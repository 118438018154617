import React from 'react';
import styled from 'styled-components';
import { Table } from 'reactstrap';
import ReportsRow from './_Components/ReportsRow/ReportsRow';
import { grayShades } from '../../../../../../../../../styles/abstracts/colors';
import ReportsListHeader from './_Components/ReportsListHeader';

const Container = styled.td`
  margin: 0 !important;
  padding: 0 !important;
  border: 0 solid ${grayShades.g400};
  border-top-width: 1px;
  border-bottom-width: ${props => (props.showReports ? '1px' : '0px')};
`;

const ListReports = styled(Table)`
  background-color: ${grayShades.g100} !important;
  width: 100%;
  margin: 0 !important;
`;

const ReportsList = props => (
  <Container showReports={props.showReports} colSpan={2}>
    {props.showReports && (
      <ListReports hover borderless>
        <ReportsListHeader />
        <tbody>
          {props.data.generatedResults &&
            props.data.generatedResults.map(result => (
              <ReportsRow data={result} />
            ))}
        </tbody>
      </ListReports>
    )}
  </Container>
);

export default ReportsList;
