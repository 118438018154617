import React from 'react';
import { withTranslation } from 'react-i18next';
import { green, red, lightBlue, grayShades, opacity, disgust, anger, purple } from 'styles/abstracts/colors';
import { getMinMaxFromUnit } from '_Utils/eindex/eindexUnits';
// @ts-ignore
import grabberGraph from '../../PoleCharts/CBarGraphChart/grabber_graph.svg';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

type VarianceAnalysisChartProps = {
  data: any;
  details: boolean;
  mainIndicator: string;
  isEindexUnitFahrenheit: boolean;
  t: any;
};

const VarianceAnalysisChart = ({
  data,
  details,
  mainIndicator,
  isEindexUnitFahrenheit,
  t,
}: VarianceAnalysisChartProps) => {
  const getOptions = () => {
    return {
      grid: {
        bottom: '35%',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow', lineStyle: { color: 'transparent' } },
        formatter: (a: any[]) => {
          let linesArray: string[] = [];
          a.shift();
          a.forEach((item: any) => {
            linesArray = [
              ...linesArray,
              `<span style="width: 10px; height: 10px; background-color: ${
                item.color
              }; border-radius: 50%; display: inline-block"></span> ${item.seriesName}: ${
                Math.round(item.value * 10) / 10
                  ? item.seriesName === t('Decrease')
                    ? '-'
                    : item.seriesName === t('Increase')
                    ? '+'
                    : ''
                  : ''
              }${Math.round(item.value * 100) / 100 || '-'}${Math.round(item.value * 100) / 100 ? '°' : ''}`,
            ];
          });
          const lastTwoCharacters = a?.[0]?.axisValue.substring(a[0].axisValue.length - 2);
          return lastTwoCharacters
            ? `<b>${
                a && lastTwoCharacters === '-0'
                  ? `${a[0].axisValue.slice(0, -2)} (${t('Volume effect')})`
                  : lastTwoCharacters === '-1'
                  ? `${a[0].axisValue.slice(0, -2)} (${t('Tonality effect')})`
                  : lastTwoCharacters === '-2'
                  ? `${a[0].axisValue.slice(0, -2)} (${t('Mix effect')})`
                  : a[0].axisValue
              }</b> <br/> ${linesArray.join(' <br/> ')}`
            : null;
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        right: 50,
        feature: {
          dataZoom: {
            title: {
              zoom: t('graph:Zoom'),
              back: t('graph:Zoom reset'),
            },
            // yAxisIndex: 'none',
            iconStyle: {
              borderColor: purple,
            },
          },
          dataView: {
            title: t('graph:View data'),
            lang: [t('graph:Data'), t('graph:Cancel'), t('graph:Update')],
            readOnly: false,
            textColor: purple,
            buttonColor: purple,
            iconStyle: {
              borderColor: purple,
            },
          },
          saveAsImage: {
            title: t('graph:Save as image'),
            name: t('graph:Graph Q°Emotion'),
            backgroundColor: '#FFFFFF',
            iconStyle: {
              borderColor: purple,
            },
          },
        },
        emphasis: {
          iconStyle: {
            borderWidth: 2,
            borderColor: purple,
            textBackgroundColor: purple,
            textFill: '#FFFFFF',
            textBorderRadius: [5, 5, 5, 5],
            textPadding: [6, 6, 4, 6],
          },
        },
      },
      legend: {
        data: [t('Total'), t('Increase'), t('Decrease')],
      },
      xAxis: {
        type: 'category',
        splitLine: {
          show: false,
        },
        data: data.labels,
        axisLabel: {
          interval: 0,
          rotate: 35,
          fontWeight: 'bold',
          formatter: value =>
            !Number.isNaN(parseInt(value.substring(value.length - 2), 10))
              ? parseInt(value.substring(value.length - 2), 10) === -0
                ? `${value.substring(0, value.length - 2)} Ⓥ`
                : parseInt(value.substring(value.length - 2), 10) === -1
                ? `${value.substring(0, value.length - 2)} Ⓣ`
                : parseInt(value.substring(value.length - 2), 10) === -2
                ? `${value.substring(0, value.length - 2)} Ⓜ`
                : value.substring(0, value.length - 2)
              : value,
          color: value => {
            if (value.substring(value.length - 2) === '-0') {
              return disgust;
            }
            if (value.substring(value.length - 2) === '-1') {
              return purple;
            }
            if (value.substring(value.length - 2) === '-2') {
              return anger;
            }
            return grayShades.g600;
          },
        },
      },
      yAxis: {
        type: 'value',
        max: value =>
          mainIndicator === 'eindex'
            ? value.max < 40
              ? getMinMaxFromUnit(isEindexUnitFahrenheit).max
              : value.max + 5
            : Math.ceil(value.max),
        axisLabel: {
          formatter: mainIndicator === 'eindex' ? '{value}°' : '{value}',
        },
      },
      dataZoom: [
        {
          type: 'slider',
          xAxisIndex: 0,
          show: true,
          start: 0,
          end: 100,
          filterMode: 'empty',
          showDataShadow: false,
          showDetail: false,
          backgroundColor: grayShades.g300,
          fillerColor: '#6f3ac7',
          dataBackgroundColor: 'transparent',
          height: 36,
          handleIcon: `image://${grabberGraph}`,
          handleSize: 24,
          handleStyle: {
            shadowColor: opacity.black,
            shadowBlur: 5,
          },
        },
        {
          filterMode: 'none',
          yAxisIndex: 0,
          show: true,
          start: 0,
          end: 100,
          showDataShadow: false,
          showDetail: false,
          backgroundColor: grayShades.g300,
          fillerColor: '#6f3ac7',
          dataBackgroundColor: 'transparent',
          width: 36,
          height: '60%',
          handleIcon: `image://${grabberGraph}`,
          handleSize: 24,
          handleStyle: {
            shadowColor: opacity.black,
            shadowBlur: 5,
          },
        },
      ],
      series: [
        {
          name: 'Test',
          type: 'bar',
          stack: 'Test',
          itemStyle: {
            barBorderColor: 'rgba(0,0,0,0)',
            color: 'rgba(0,0,0,0)',
            padding: 0,
            barCategoryGap: '0%',
          },
          emphasis: {
            itemStyle: {
              barBorderColor: 'rgba(0,0,0,0)',
              color: 'rgba(0,0,0,0)',
            },
          },
          markLine: data.labels &&
            data?.labels?.includes('') && {
              silent: true,
              symbol: 'none',
              label: {
                show: false,
              },
              tooltip: {
                formatter: () => null,
              },
              data: details && [
                {
                  name: 'Split',
                  xAxis: data.labels.map((label, i) => (label === '' ? i : -1)).filter(ind => ind !== -1)[0],
                  lineStyle: {
                    color: grayShades.g600,
                    width: 2,
                    type: 'dashed',
                  },
                },
                {
                  name: 'Split',
                  xAxis: data.labels.map((label, i) => (label === '' ? i : -1)).filter(ind => ind !== -1)[1],
                  lineStyle: {
                    color: grayShades.g600,
                    width: 2,
                    type: 'dashed',
                  },
                },
              ],
            },
          data: data?.values?.graphData,
        },
        {
          name: t('Total'),
          type: 'bar',
          stack: 'Test',
          barWidth: '100%',
          data: data?.values?.totalEffect,
          itemStyle: {
            color: lightBlue,
          },
          label: {
            show: true,
            position: 'top',
            formatter: (a, b) => {
              return `${Math.round(a?.data * 10) / 10}${mainIndicator === 'eindex' ? '°' : ''}`;
            },
          },
        },
        {
          name: t('Increase'),
          type: 'bar',
          stack: 'Test',
          data: data?.values?.positiveTotalEffect,
          itemStyle: {
            color: green,
          },
          label: {
            show: true,
            position: 'top',
            formatter: a =>
              a.name !== '' ? `+${Math.round(a?.data * 10) / 10}${mainIndicator === 'eindex' ? '°' : ''}` : '',
          },
        },
        {
          name: t('Decrease'),
          type: 'bar',
          stack: 'Test',
          data: data?.values?.negativeTotalEffect,
          itemStyle: {
            color: red,
          },
          label: {
            show: true,
            position: 'bottom',
            formatter: a =>
              a.name !== '' ? `-${Math.round(a?.data * 10) / 10}${mainIndicator === 'eindex' ? '°' : ''}` : '',
          },
        },
      ],
    };
  };

  const onEvents = () => {};

  const options = data && getOptions();
  return (
    <div style={{ height: '100%', width: '100%', margin: 0 }}>
      {ReactEcharts ? (
        <ReactEcharts
          option={options}
          style={{ height: 'calc(100% - 4em)', width: 'calc(100% - 2em)' }}
          className="react_for_echarts"
          theme="QemotionTheme"
          onEvents={onEvents()}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default withTranslation('card', ['graph'])(VarianceAnalysisChart);
