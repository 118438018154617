import gql from 'graphql-tag';

const mergeClusters = gql`
  mutation mergeClusters($projectId: Int!, $clusterIds: [String!]!, $newClusterName: String!) {
    mergeClusters(projectId: $projectId, clusterIds: $clusterIds, newClusterName: $newClusterName) {
      clusterId
      errors
    }
  }
`;

export default mergeClusters;
