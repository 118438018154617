import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import { withTranslation } from 'react-i18next';
import { grayShades } from 'styles/abstracts/colors';
import { setDisplayBenchmarkDetails } from '_Resources/Benchmark/Actions/setDisplayBenchmarkDetails';

class DisplaySwitcher extends React.Component {
  static propTypes = {
    displayBenchmarkDetails: PropTypes.bool.isRequired,
    dispatchDisplayBenchmark: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  onDisplayChange = () => {
    this.props.dispatchDisplayBenchmark(!this.props.displayBenchmarkDetails);
  };

  render() {
    const { t } = this.props;
    return (
      <QBooleanSwitch
        label={t('Show card details')}
        value={this.props.displayBenchmarkDetails}
        onChanged={this.onDisplayChange}
        labelColor={grayShades.g800}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    displayBenchmarkDetails: state.benchmark.displayBenchmarkDetails,
  };
}

const mapDispatchToProps = dispatch => ({
  dispatchDisplayBenchmark: newState => {
    dispatch(setDisplayBenchmarkDetails(newState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('header')(DisplaySwitcher));
