import React from 'react';
import styled from 'styled-components';
import { keys, pickBy } from 'lodash';
import { grayShades, white } from '../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../styles/abstracts/typography';
import EindexRond from '../../../../_Charts/KpisCharts/KpiBadges/EindexRond';
import { spacing } from '../../../../styles/abstracts/variables';

const Bubble = styled.div`
  display: flex;
  position: relative;
  font-size: ${fontSizes.extraSmall};
  border: 2px solid ${grayShades.g300};
  border-radius: 25px;
  padding: ${spacing.mediumLarge};
  margin: ${spacing.small};
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  align-items: center;
  background-color: ${white};
`;

const Eindex = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -1.35em;
  border: 2px solid ${white};
  border-radius: 20px;
`;

class ReportingVerbatimBubble extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verbatimToShow: this.getRelevantThematic(),
    };
  }

  getRelevantThematic() {
    const mockData = this.props.data.verbatim.verbatimSentences;
    const relevantThematicsIndexes = keys(
      pickBy(mockData, { relevantThematic: true }),
    );
    const checkIfHasToCreate = (
      index,
      collectionSentences,
      beginSlice,
      endSlice,
    ) => {
      if (
        collectionSentences[index] &&
        collectionSentences[index].relevantThematic === false &&
        !relevantThematicsIndexes.includes(index)
      ) {
        return collectionSentences[index].sentence
          .split(/\s+/)
          .slice(beginSlice, endSlice)
          .join(' ');
      }
      return '';
    };

    const results = relevantThematicsIndexes.map(relevantIndex => {
      const checkPreVerbatim = checkIfHasToCreate(
        relevantIndex - 1,
        mockData,
        -3,
      );
      const checkPostVerbatim = checkIfHasToCreate(
        Number(relevantIndex) + 1,
        mockData,
        0,
        3,
      );
      const preVerbatim =
        checkPreVerbatim &&
        `${
          mockData[relevantIndex - 1].sentence.split(' ').length > 3
            ? '(...)'
            : ''
        } ${checkPreVerbatim}`;
      const postVerbatim =
        checkPostVerbatim &&
        `${checkPostVerbatim} ${
          mockData[Number(relevantIndex) + 1].sentence.split(' ').length > 3
            ? '(...)'
            : ''
        }`;
      return `${preVerbatim} <b>${
        mockData[relevantIndex].sentence
      }</b> ${postVerbatim}`;
    });

    let sentence = '';

    results.forEach(result => (sentence += result));

    if (sentence.includes('(...)(...)')) {
      sentence = sentence.replace('(...)(...)', '(...)');
    }

    return <p dangerouslySetInnerHTML={{ __html: sentence }} />;
  }

  render() {
    return (
      <Bubble className="bubble">
        <Eindex>
          <EindexRond eindex={this.props.data.kpis.eindex.value} type="xs" />
        </Eindex>
        {this.state.verbatimToShow}
      </Bubble>
    );
  }
}

export default ReportingVerbatimBubble;
