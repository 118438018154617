import React, { useState } from 'react';
import styled from 'styled-components';
import { black, grayShades } from 'styles/abstracts/colors';
import VerbatimListModal from '_Components/Modals/VerbatimListModal/VerbatimListModal';
import { fontSizes } from 'styles/abstracts/typography';
import '../../style.scss';

const StyledCell = styled.td`
  font-weight: bold;
  color: ${black};
  padding: 0.75em !important;
  & > span {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
`;

const ParentThematic = styled.span`
  visibility: ${({ isDetailsToggled }) => !isDetailsToggled && 'hidden'};
  color: ${grayShades.g800};
  text-align: right;
  font-size: ${fontSizes.smaller};
`;

const NameCell = styled(StyledCell)`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background: white;
  margin: auto 0;
  z-index: 2;
`;

const EindexCell = styled(StyledCell)`
  & > span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const NumbersCell = styled(StyledCell)`
  color: ${grayShades.g800};
  text-align: center;
  margin: auto;
`;

const ModalLink = styled.span`
  cursor: pointer;
`;

const TableRow = ({ data, isDetailsToggled, selectedColumns, filterKeys, t }) => {
  const [modal, setModal] = useState(false);

  const getCellType = cellType => {
    switch (cellType) {
      case 'number':
        return NumbersCell;
      case 'eindex':
      case 'evolution':
        return EindexCell;
    }
  };

  const propsModal = {
    numberOfMentions: data?.thematicsOverviewDetails.mentionCount,
    title: `${data?.subThematics ? data?.thematicsOverviewDetails.name : data?.thematic} ${
      data?.subSubThematics
        ? `> ${data?.thematicsOverviewDetails.name}`
        : data.subThematic
        ? `> ${data.subThematic}`
        : ''
    } ${data.thematic && data.subThematic ? `> ${data?.thematicsOverviewDetails.name}` : ''}`,
    titleSecondPart: '',
    toggle: () => setModal(!modal),
    className: '',
    modal,
  };

  const getHierarchy = () => {
    let categoryHierarchy = { joinSpecify: 'and' };
    if (data.thematic && data.subThematic) {
      categoryHierarchy = {
        ...categoryHierarchy,
        thematic: data.thematic,
        subThematic: data?.subThematic,
        subSubThematic: data?.thematicsOverviewDetails?.name,
      };
    } else if (data.thematic && data?.subSubThematics) {
      categoryHierarchy = {
        ...categoryHierarchy,
        thematic: data.thematic,
        subThematic: data?.thematicsOverviewDetails.name,
        subSubThematic: null,
      };
    } else {
      categoryHierarchy = {
        ...categoryHierarchy,
        thematic: data?.thematicsOverviewDetails.name,
        subThematic: null,
        subSubThematic: null,
      };
    }
    return categoryHierarchy;
  };

  const getReadDataOrder = col => {
    let [id, subId, subSubId] = col.id.split('.');

    if (data?.thematicsOverviewDetails?.[id] !== null) {
      if (id === 'emotionsEvolution' || id === 'sentimentsEvolution') {
        const bufferSubId = subId;
        subId = subSubId;
        subSubId = bufferSubId;
      }
      if (subSubId) {
        if (id === 'emotionsEvolution' || id === 'sentimentsEvolution') {
          return col.getFormattedData(
            data?.thematicsOverviewDetails?.[id]?.[subId]?.[subSubId],
            data?.thematicsOverviewDetails?.name,
          );
        }
        return col.getFormattedData(
          Math.round(data?.thematicsOverviewDetails?.[id]?.[subId]?.[subSubId]),
          data?.thematicsOverviewDetails?.name,
        );
      }
      if (subId) {
        return col.getFormattedData(
          data?.thematicsOverviewDetails?.[id]?.[subId],
          data?.thematicsOverviewDetails?.name,
        );
      }
      return col.getFormattedData(data?.thematicsOverviewDetails?.[id], data?.thematicsOverviewDetails?.name);
    }
    return 'N/A';
  };

  return (
    <tr>
      <NameCell>
        <ModalLink onClick={() => setModal(!modal)}>{data?.thematicsOverviewDetails?.name}</ModalLink>
        <VerbatimListModal
          {...propsModal}
          filters={{
            ...filterKeys,
            thematics: [...filterKeys.thematics, getHierarchy()],
            selectedEmotions: null,
          }}
          mentionCount={data?.thematicsOverviewDetails?.mentionCount}
          classBody="containBubble"
        />
        <ParentThematic isDetailsToggled={isDetailsToggled}>
          {data?.thematic && `${t('from')} ${data?.thematic} `}
          {data?.thematic && data?.subThematic && `> ${data?.subThematic}`}
        </ParentThematic>
      </NameCell>
      {selectedColumns.map(col => {
        const Cell = getCellType(col.cellType);
        return (
          <Cell>
            <span>{getReadDataOrder(col)}</span>
          </Cell>
        );
      })}
    </tr>
  );
};

export default TableRow;
