import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import QPage from '../../../../_Components/Panels/QPage/QPage';
import { TEXT_TYPES } from '../../../../_Components/Texts/textTypes';
import { PagePropTypes } from '../../../../_Resources/Reporting/Services/types/Page.propTypes';
import { prepareGenerateSubtitles } from './helpers/subtitleHelper';
import { connect } from 'react-redux';

class ReportPageHeader extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(Object.values(TEXT_TYPES)).isRequired,
    page: PagePropTypes.isRequired,
  };

  generateSubtitles = null;

  constructor({ t, lang }) {
    super();
    this.generateSubtitles = prepareGenerateSubtitles(t, lang);
  }

  render() {
    const { page, mode } = this.props;
    return (
      <QPage.Header mode={mode}>
        <QPage.Header.Title
          title={page.title}
          subtitles={this.generateSubtitles(page)}
          mode={mode}
        />
      </QPage.Header>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  lang: user.lang,
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('reporting')(ReportPageHeader));
