export const AVAILABLE_INDICATORS = [
      {
        id: 'happiness',
        label: 'Happiness',
      },
      {
        id: 'surprise',
        label: 'Surprise',
      },
      {
        id: 'calm',
        label: 'Calm',
      },
      {
        id: 'fear',
        label: 'Fear',
      },
      {
        id: 'sadness',
        label: 'Sadness',
      },
      {
        id: 'anger',
        label: 'Anger',
      },
      {
        id: 'disgust',
        label: 'Disgust',
      },
];


export const AVAILABLE_DISPLAY_OPTIONS = [
    {
        id: 'thematics',
        label: 'Categories',
    },
    {
        id: 'subThematics',
        label: 'Subcategories'
    },
    {
        id: 'subSubThematics',
        label: 'Sub sub categories',
    }
]
