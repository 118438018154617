import React from 'react';
import styled from 'styled-components';
import { upperFirst } from 'lodash';
import DataByThematicsDropdown from './_Containers/DataByThematicsDropdown/DataByThematicsDropdown';

const SelectsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 3.5em;
  margin-right: 1em;
  flex-wrap: wrap;
`;

const IndicatorsDropdownsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 2em;
  margin-left: 3em;
  & div {
    margin-right: 0.5em;
  }
`;

type DropdownsContainerProps = {
  indicatorsConfig: any;
  filtersToDisplay: Array<{ id: string; label: string }>;
  settings: any;
  setSettings: any;
  defaultSettings: any;
  orderByItems: any[];
};

const DropdownsContainer = ({
  indicatorsConfig,
  filtersToDisplay,
  settings,
  setSettings,
  defaultSettings,
  orderByItems,
}: DropdownsContainerProps) => {
  return (
    <div>
      <SelectsContainer>
        <DataByThematicsDropdown
          id="filterToDisplayContainer"
          dropdownType="selectedFilter"
          label="Filter to display"
          itemToIterate={filtersToDisplay}
          settings={settings}
          setSettings={setSettings}
          defaultSettings={defaultSettings}
        />
        <IndicatorsDropdownsContainer>
          {Object.keys(indicatorsConfig).map(indicator => {
            return (
              <DataByThematicsDropdown
                id={`${indicator}IndicatorContainer`}
                dropdownType="indicators"
                subCategory={indicator}
                itemToIterate={indicatorsConfig[indicator]}
                label={`${upperFirst(indicator)} value`}
                icon={`${indicator.toUpperCase()}_INDICATOR`}
                settings={settings}
                setSettings={setSettings}
                defaultSettings={defaultSettings}
                orderByItems={orderByItems}
              />
            );
          })}
        </IndicatorsDropdownsContainer>
        <DataByThematicsDropdown
          id="orderByDropdownContainer"
          dropdownType="orderBy"
          label="Sort by"
          itemToIterate={orderByItems}
          settings={settings}
          setSettings={setSettings}
          defaultSettings={defaultSettings}
        />
      </SelectsContainer>
    </div>
  );
};

export default DropdownsContainer;
