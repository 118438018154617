import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InsightModeMenuSection from './InsightModeMenuSection';

class InsightModeMenuBody extends Component {
  render() {
    const { onClick, selected, items, className } = this.props;
    return (
      <div className={className}>
        {items.map(
          item =>
            ((item.category === 'kpis' &&
              (this.props.sat !== null || this.props.reco !== null || this.props.ces !== null)) ||
              item.category !== 'kpis') && (
              <InsightModeMenuSection
                onClick={onClick}
                selected={selected}
                item={item}
                sat={this.props.sat}
                reco={this.props.reco}
                ces={this.props.ces}
              />
            ),
        )}
      </div>
    );
  }
}

export default styled(InsightModeMenuBody)`
  font-size: 0.9em;
`;
// TODO: move prop-types out of the file
InsightModeMenuBody.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
};
