import React, { Fragment } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import Icon from '_Components/Icons/Icon';
import QButton from '_Components/Buttons/QButton/QButton';
import { ImageSize, spacing } from '../../../../styles/abstracts/variables';
import QTitle from '../../../../_Components/Texts/QTitle/QTitle';
import { fontSizes } from '../../../../styles/abstracts/typography';
import { grayShades } from '../../../../styles/abstracts/colors';
import InputNameModal from './InputNameModal';

const Title = styled(QTitle)`
  margin: ${spacing.mediumSmall} auto 0 auto;
  font-size: ${fontSizes.bigger};
`;

const SubTitle = styled.p`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  margin: auto;
  font-weight: bold;
`;

const CreateButton = styled(QButton)`
  font-size: ${fontSizes.smaller} !important;
  margin: ${spacing.large} auto !important;
`;

const CreateText = styled.span`
  vertical-align: middle;
`;

class ReportingCardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    if (this.props.location?.state?.fromLandingPage) {
      this.toggleModal();
    }
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  render() {
    const { t } = this.props;
    const propsModal = {
      toggle: this.toggleModal,
      isOpen: this.state.modal,
      title: 'Create a new template',
      subtitle: 'Please name your report template',
      button: 'Create my report template',
    };
    return (
      <>
        <Title>{t('card:Your project report templates')}</Title>
        <SubTitle>
          {t(
            'text:Here, you can create, edit, duplicate report templates, and access to the previously generated report document!',
          )}
        </SubTitle>
        <CreateButton
          id="reportingCardHeaderButton"
          hasOpacity
          onClick={this.toggleModal}
          disabled={!this.props.canCreateReporting}
          popoverText={
            !this.props.canCreateReporting &&
            t(
              "header:You can't create more reportings. Please upgrade your plan in order to create more benchmarks.",
            )
          }
        >
          <Icon icon="PLUS" size={ImageSize.medium} /> &nbsp;
          <CreateText>{t('button:Create a new template')}</CreateText>
        </CreateButton>
        <InputNameModal template {...propsModal} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  canCreateReporting: get(state, [
    'auth',
    'user',
    'account',
    'canCreateReporting',
  ]),
});

export default connect(mapStateToProps)(
  withTranslation(['card', 'text', 'button', 'header'])(
    withRouter(ReportingCardHeader),
  ),
);
