/** @module insightGridTile */
import { select } from 'd3-selection';
import * as d3Transition from 'd3-transition';
import { line as d3lineGen, curveCardinalClosed } from 'd3-shape';
import { get } from 'lodash';

/**
 * @typedef SelectionObject
 * @type {Object}
 * @property {D3.selection} update - updating data selection
 * @property {D3.selection} enter - entering data selection
 * @property {D3.selection} exit - exiting data selection
 */

/**
 * @typedef TileFactoryConfig
 * @type {Object}
 * @property {Array} points - Tiles position array
 * @property {String} type - Type of the tile
 */

const d3Line = d3lineGen();
export const line = d3Line
  .x(({ x }) => x)
  .y(({ y }) => y)
  .curve(curveCardinalClosed.tension(0.725));

let transitionDuration = null;
export const transitionDurationTime = 300;
export function getTransitionDuration() {
  if (!transitionDuration) {
    transitionDuration = d3Transition
      .transition()
      .duration(transitionDurationTime);
  }
  return transitionDuration;
}

function selectAllTiles(container, type) {
  const elementSelector = `path.${type}`;
  return select(container).selectAll(elementSelector);
}

function createPathElement(selection, type) {
  return selection.append('path').attr('class', type);
}

function updateTileData(selection, pathResolver) {
  return selection
    .attr('d', ({ point }) => {
      const coords = pathResolver(point);
      return line(coords);
    })
    .attr('fill', 'white');
}

function onTileDataUpdate(updateTilesSelection, pathResolver) {
  return updateTileData(
    updateTilesSelection.transition(getTransitionDuration()),
    pathResolver,
  );
}

function onTileDataEnter(updateTilesSelection, pathResolver, type) {
  const enterTilesSelection = updateTilesSelection.enter();
  const newTilesSelection = createPathElement(enterTilesSelection, type);
  return updateTileData(newTilesSelection, pathResolver);
}

function onTileDataExit(updateTilesSelection) {
  return updateTilesSelection.exit();
}

/**
 * Creates [tileFactory]{@link module:insightGridTile~tileFactory}.
 * @function createTileFactory
 * @param  {Object} container - Reference to parent element of all new svg elements
 * @returns {module:insightGridTile~tileFactory} tileFactory - Factory for creating tiles
 */
export default function createTileFactory({ container, pathResolver }) {
  /**
   * Creates the tiles placed on the positions indicated in <code>config.points</code> collection. </br>
   * Tile itself is an SVG <path> element formed into Hexagon. </br>
   * By default it's filled with white color and does not contain any text
   * @function tileFactory
   * @param  {module:insightGridTile~TileFactoryConfig} config - Configuration object indicating tiles positions and type
   * @returns {module:insightGridTile~SelectionObject} tilesSelection - D3.selections related to tiles
   */
  return function tileFactory({ points, type }) {
    const updateTilesSelection = selectAllTiles(container, type).data(
      points,
      (point, index) => {
        const id = get(point, 'insightData.insightId');
        return id || index;
      },
    );
    // TODO: read what this selection really is
    const update = onTileDataUpdate(updateTilesSelection, pathResolver);
    const enter = onTileDataEnter(updateTilesSelection, pathResolver, type);
    const exit = onTileDataExit(updateTilesSelection);
    return { tile: { update, enter, exit } };
  };
}
