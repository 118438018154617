import React from 'react';
import gql from 'graphql-tag';
import { get } from 'lodash';
import CQuery from '../../../../_Container/QQuery/CQuery';
import query from '../../../../_Container/VerbatimList/graphql/getVerbatimListData';
import VerbatimList from '../../../../_Container/VerbatimList/VerbatimList';
import getMergedVerbatimListDataQuery from '../../../../_Container/VerbatimList/graphql/getMergedVerbatimListData';

class QueryVerbatimList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      querySchema: gql`
        ${query}
      `,
    };
  }

  componentDidMount() {
    const { loadNumberOfMentions, emotions, filters } = this.props;
    loadNumberOfMentions && emotions && filters.selectedEmotions && loadNumberOfMentions(filters.selectedEmotions.reduce((acc, cur) => acc + get(emotions, cur.toLowerCase()), 0));
  }

  onLoadMore2 = (fetchMore, data) => {
    const { querySchema } = this.state;
    const { filters } = this.props;
    const filtersToPass = {...filters, sort: 'DESC'};
    fetchMore({
      query: querySchema,
      variables: {
        ...filtersToPass,
        lastVerbatimId: data.getVerbatimListData.lastVerbatimId,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousVerbatim = previousResult.getVerbatimListData.verbatimList;
        const newVerbatim = fetchMoreResult.getVerbatimListData.verbatimList;
        const newLastVerbatimId = fetchMoreResult.getVerbatimListData.lastVerbatimId;
        return {
          getVerbatimListData: {
            verbatimList: [...previousVerbatim, ...newVerbatim],
            lastVerbatimId: newLastVerbatimId,
            remainingVerbatim: fetchMoreResult.getVerbatimListData.remainingVerbatim,
          },
        };
      },
    });
  };

  render() {
    const { headerRef, headerWidth, setHeaderWidth, mergedThematics, filters, keywords } = this.props;
    const filtersToPass = {...filters, sort: 'DESC'};
    const { verbatimList } = this.state;
    const queryToPass = mergedThematics ? getMergedVerbatimListDataQuery : query;
    return (
      <CQuery
        query={queryToPass}
        filters={filtersToPass}
        fetchMore={(fetchMore, data) => this.onLoadMore2(fetchMore, data)}
        lazyLoad
      >
        <VerbatimList
          keywords={keywords}
          verbatimList={verbatimList}
          headerRef={headerRef}
          headerWidth={headerWidth}
          setHeaderWidth={setHeaderWidth}
        />
      </CQuery>
    );
  }
}

export default QueryVerbatimList;
