import React from 'react';
import { _, orderBy, get, forEach, trim } from 'lodash';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { setFutureProjectToSelect } from '_Resources/Header/Actions/setProject';
import styled from 'styled-components';
import { fontSizes } from 'styles/abstracts/typography';
import ProjectCard from './_Cards/ProjectCard/ProjectCard';
import { radiusRoot } from '../../../../../styles/abstracts/variables';
import QTitle from '../../../../../_Components/Texts/QTitle/QTitle';
import { hex2rgbaConvert } from '../../../../../_Utils/styles/colors';
import {
  themeColors,
  white,
  grayShades,
  purple,
} from '../../../../../styles/abstracts/colors';

const ProjectCardContainer = styled(Col)`
  //min-width: 22rem !important;
`;

const RowLine = styled(Row)`
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
`;

const AddDataCard = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border-radius: ${radiusRoot.round};
  transition: 0.5s;
  color: ${grayShades.g800};
  border: 2px dashed ${grayShades.g800};
  text-align: center;
  height: 13rem;
  cursor: pointer;
  flex-basis: 10rem;
  flex-shrink: 1;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  font-size: ${fontSizes.bigger};

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
    color: ${purple};
    border-color: ${purple};
    transition: all 0.2s ease-in-out;
  }`}
`;

const AddDataTitle = styled(QTitle)`
  text-align: center;
  color: #928fb0 !important;
`;

const Button = styled.div`
  background-color: ${hex2rgbaConvert(themeColors.primary, 20)};
  color: ${themeColors.primary};
  &:hover {
    background-color: ${themeColors.primary};
    color: ${white};
  }
`;

const BigPlus = styled.p`
  font-size: ${`calc(${fontSizes.large} * 3)`};
`;

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    forEach(this.props.data.projects, project => {
      project.name = trim(project.name);
      project.name =
        project.name.charAt(0).toUpperCase() + project.name.slice(1);
    });
    this.state = {
      queryResults: this.props.data.projects,
    };
  }

  changeProjectThenRedirect(newState) {
    this.props.setFutureProjectToSelect(newState);
    setTimeout(() => {
      this.props.history.push('global-results');
    }, 1000);
  }

  goToProjectConfiguration() {
    this.props.history.push('/project-configuration');
  }

  render() {
    return (
      <>
        <RowLine>
          {this.state.queryResults &&
            orderBy(this.state.queryResults, 'name').map(project => (
              <ProjectCardContainer className="mb-4">
                <ProjectCard
                  key={`project-${project.id}`}
                  data={project}
                  callbackParent={newState =>
                    this.changeProjectThenRedirect(newState)
                  }
                />
              </ProjectCardContainer>
            ))}
          {this.props.currentUser.account?.planName && this.props.currentUser.account?.planName !== 'emotion_corporate' &&
            this.props.currentUser.hasEmailValidated && (
              <ProjectCardContainer className="mb-4">
                <AddDataCard
                  onClick={() =>
                    this.props.currentUser.hasEmailValidated &&
                    this.props.currentUser.account?.planName !==
                      'emotion_corporate' &&
                    this.goToProjectConfiguration()
                  }
                  disabled={
                    !this.props.currentUser.hasEmailValidated ||
                    !this.props.currentUser.account?.planName
                  }
                >
                  <span>
                    <BigPlus>+</BigPlus>
                    <p>{this.props.t('Create a new project')}</p>
                  </span>
                </AddDataCard>
              </ProjectCardContainer>
            )}
        </RowLine>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: get(state, ['auth', 'user']),
});

function mapDispatchToProps(dispatch) {
  return {
    setFutureProjectToSelect: newState => {
      dispatch(setFutureProjectToSelect(newState));
    },
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation('button')(ProjectList)),
);
