import styled from "styled-components";
import { white, themeColors } from '../../../styles/abstracts/colors';

export const TooltipText = styled.div<{ show: boolean }>`
  max-width: 10em;
  background-color: ${themeColors.primary};
  color: ${white};
  text-align: center;
  border-radius: 6px;
  padding: 5px 12px;
  z-index: 1;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 0.5s ease-in-out;
`;
