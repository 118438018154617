import React, { PureComponent } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { BasePropTypes } from '../../../_Utils/common.propTypes';

class QDnD extends PureComponent {
  static propTypes = {
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
    onDragRelease: PropTypes.func,

    ...BasePropTypes,
  };

  static defaultProps = {
    onDragRelease: null,
  };

  state = {
    released: true,
  };

  onDragStart = (...args) => {
    const { onDragStart } = this.props;
    this.setState({ released: false }, () => onDragStart(...args));
  };

  onMouseUp = () => {
    const { released } = this.state;
    const { onDragRelease } = this.props;
    if (!released) {
      this.setState({ released: true }, onDragRelease && onDragRelease());
    }
  };

  render() {
    const { onDragEnd, children, className } = this.props;
    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={onDragEnd}>
        <div onMouseUp={this.onMouseUp} className={className}>
          {children}
        </div>
      </DragDropContext>
    );
  }
}

export default QDnD;
