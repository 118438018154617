import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { white, grayShades, purple } from 'styles/abstracts/colors';
import SubHeader from '_Layouts/Header/_Containers/SubHeader/SubHeader';
import QProgress from '_Components/QProgress/QProgress';
import IconCircle from '_Components/Icons/IconCircle/IconCircle';

const Container = styled.div`
  width: 100%;
  background: ${white};
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const CancelButton = styled.div`
  color: ${grayShades.g800};
  cursor: pointer;
  position: absolute;
  left: 1em;
  display: flex;
  align-items: center;

  & b {
    margin-left: 0.5em;
  }
`;

const ProgressContain = styled.div`
  width: 40%;
`;

const ProjectConfigurationSubHeader = ({
  steps,
  activeStep,
  setActiveStep,
  history,
  t
}) => {
  const SHContent = (
    <Container>
      <CancelButton onClick={() => history.push('/settings')}>
        <IconCircle
          bgColor={grayShades.g300}
          color={purple}
          icon="INTERFACE_CHEVRON_LEFT"
          size={16}
        />
        <b>{t('Cancel')}</b>
      </CancelButton>
      <ProgressContain>
        <QProgress
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          t={t}
        />
      </ProgressContain>
    </Container>
  );

  return <SubHeader allowCollapse={false} SHContent={SHContent} />;
};

export default withTranslation('button', 'steps')(ProjectConfigurationSubHeader);
