import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { connect } from 'react-redux';
import '../../../../node_modules/react-resizable/css/styles.css';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import { getRoute } from '_Utils/routes/routeUtils';
import LandingPageCard from '_Cards/LandingPageCard/LandingPageCard';
import ProjectListCard from './_Components/ProjectListCard/ProjectListCard';
import TipsAndTricksCard from './_Components/TipsAndTricksCard/TipsAndTricksCard';
import { getHeaderTopHeight } from '../../../_Layouts/Header/_Utils/HeaderUtils';

const MainPageBis = ({
  updateRoute,
  currentUser,
  subHeaderRef,
  editableLayout,
  collapsedInfo,
}) => {
  const [headerHeight, setHeaderHeight] = useState(subHeaderRef.current?.getBoundingClientRect().height + getHeaderTopHeight());

  useEffect(() => {
    getRoute(updateRoute, 'main');
    setTimeout(() => {
      setHeaderHeight(subHeaderRef.current?.getBoundingClientRect().height + getHeaderTopHeight());
    }, 200);
  }, [collapsedInfo]);

  const [numberOfMentions, setNumberOfMentions] = useState(null);
  return (
    <div>
      <Container fluid className="py-3 px-4">
        <LandingPageCard headerHeight={headerHeight} />
        <ProjectListCard
          currentUser={currentUser}
          title="Your projects"
          titleSecondPart=""
          numberOfMentions={numberOfMentions}
          displayCardSubTitle={false}
          dropDownOptions={['EXPORT', 'PROJECT_SETTINGS']}
          className="mb-4"
          headerHeight={headerHeight}
        />
        <TipsAndTricksCard
          title="Video tutorials"
          titleSecondPart=""
          displayCardSubTitle={false}
          dropDownOptions={['EXPORT']}
          style={{
            height: 'auto',
            paddingBottom: '2em',
          }}
        />
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    list_projects: state.projects.projectsList,
    editableLayout: state.routes.activeLayoutEdition,
    module: state.routes.route && state.routes.route.name,
    currentUser: state.auth && state.auth.user,
    collapsedInfo: state.projectConfiguration.collapsedInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPageBis);
