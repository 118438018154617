import { convertCelsiusToFahrenheit } from '_Utils/eindex/eindexUnits';

export const MAIN_INDICATOR_CONFIG: Array<{ value: string; label: string }> = [
  {
    value: 'eindex',
    label: 'E-index ©',
  },
  {
    value: 'satisfaction',
    label: 'Satisfaction',
  },
  {
    value: 'recommendation',
    label: 'Recommendation',
  },
  {
    value: 'emotional_intensity',
    label: 'Emotional intensity',
  },
  {
    value: 'confidence_rate',
    label: 'Confidence rate',
  },
];

export const EXPLAIN_INDICATOR_CONFIG: Array<{
  value: number;
  label: string;
}> = [
  {
    value: 1,
    label: 'Categories',
  },
  {
    value: 2,
    label: 'Subcategories',
  },
  {
    value: 3,
    label: 'Sub sub categories',
  },
];

export const ORDER_BY_CONFIG: Array<{
  value: string;
  label: string;
  subcategories: Array<{ value: string; label: string }>;
}> = [
  {
    value: 'impactWeight',
    label: 'Impact weight',
    subcategories: [
      {
        value: 'impactWeight_asc',
        label: 'Impact weight - Ascending',
      },
      {
        value: 'impactWeight_desc',
        label: 'Impact weight - Descending',
      },
    ],
  },
  {
    value: 'thematics',
    label: 'Categories',
    subcategories: [
      {
        value: 'thematics_asc',
        label: 'Categories - Ascending',
      },
      {
        value: 'thematics_desc',
        label: 'Categories - Descending',
      },
    ],
  },
];

export const sortData = (
  orderBy,
  details,
  thematicsToIterate,
  mainIndicator,
  isEindexUnitFahrenheit,
  t,
) => {
  const [filter, order] = orderBy.split('_');
  let effect = [];
  let labels = [];
  switch (details) {
    case true:
      switch (filter) {
        case 'impactWeight':
        default:
          if (order === 'asc') {
            effect = [
              ...thematicsToIterate
                .filter(
                  d =>
                    d.volumeEffect >= 0.1 ||
                    d.volumeEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(d =>
                  mainIndicator === 'eindex' && isEindexUnitFahrenheit
                    ? convertCelsiusToFahrenheit(d.volumeEffect)
                    : d.volumeEffect,
                )
                ?.sort((a, b) => a - b),
              0,
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.tonalityEffect >= 0.1 ||
                    d.tonalityEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(d =>
                  mainIndicator === 'eindex' && isEindexUnitFahrenheit
                    ? convertCelsiusToFahrenheit(d.volumeEffect)
                    : d.tonalityEffect,
                )
                ?.sort((a, b) => a - b),
              0,
              ...thematicsToIterate
                .filter(
                  d =>
                    d.mixEffect >= 0.1 ||
                    d.mixEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(d =>
                  mainIndicator === 'eindex' && isEindexUnitFahrenheit
                    ? convertCelsiusToFahrenheit(d.volumeEffect)
                    : d.mixEffect,
                )
                ?.sort((a, b) => a - b),
            ];
            labels = [
              ...thematicsToIterate
                .filter(
                  d =>
                    d.volumeEffect >= 0.1 ||
                    d.volumeEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.sort((a, b) => a.volumeEffect - b.volumeEffect)
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-0`,
                ),
              '',
              ...thematicsToIterate
                .filter(
                  d =>
                    d.tonalityEffect >= 0.1 ||
                    d.tonalityEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.sort((a, b) => a.tonalityEffect - b.tonalityEffect)
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-1`,
                ),
              '',
              ...thematicsToIterate
                .filter(
                  d =>
                    d.mixEffect >= 0.1 ||
                    d.mixEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.sort((a, b) => a.mixEffect - b.mixEffect)
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-2`,
                ),
            ];
          } else {
            labels = [
              ...thematicsToIterate
                .filter(
                  d =>
                    d.volumeEffect >= 0.1 ||
                    d.volumeEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.sort((a, b) => b.volumeEffect - a.volumeEffect)
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-0`,
                ),
              '',
              ...thematicsToIterate
                .filter(
                  d =>
                    d.tonalityEffect >= 0.1 ||
                    d.tonalityEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.sort((a, b) => b.tonalityEffect - a.tonalityEffect)
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-1`,
                ),
              '',
              ...thematicsToIterate
                .filter(
                  d =>
                    d.mixEffect >= 0.1 ||
                    d.mixEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.sort((a, b) => b.mixEffect - a.mixEffect)
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-2`,
                ),
            ];
            effect = [
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.volumeEffect >= 0.1 ||
                    d.volumeEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(d => d.volumeEffect)
                ?.sort((a, b) => b - a),
              0,
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.tonalityEffect >= 0.1 ||
                    d.tonalityEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(d => d.tonalityEffect)
                ?.sort((a, b) => b - a),
              0,
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.mixEffect >= 0.1 ||
                    d.mixEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(d => d.mixEffect)
                ?.sort((a, b) => b - a),
            ];
          }
          break;
        case 'thematics':
          if (order === 'asc') {
            labels = [
              ...thematicsToIterate
                .filter(
                  d =>
                    d.volumeEffect >= 0.1 ||
                    d.volumeEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-0`,
                ),
              '',
              ...thematicsToIterate
                .filter(
                  d =>
                    d.tonalityEffect >= 0.1 ||
                    d.tonalityEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-1`,
                ),
              '',
              ...thematicsToIterate
                .filter(
                  d =>
                    d.mixEffect >= 0.1 ||
                    d.mixEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-2`,
                ),
            ];
            effect = [
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.volumeEffect >= 0.1 ||
                    d.volumeEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                .map(d => d.volumeEffect),
              0,
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.tonalityEffect >= 0.1 ||
                    d.tonalityEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                .map(d => d.tonalityEffect),
              0,
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.mixEffect >= 0.1 ||
                    d.mixEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                .map(d => d.mixEffect),
            ];
          } else {
            labels = [
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.volumeEffect >= 0.1 ||
                    d.volumeEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-0`,
                )
                ?.reverse(),
              '',
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.tonalityEffect >= 0.1 ||
                    d.tonalityEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-1`,
                )
                ?.reverse(),
              '',
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.mixEffect >= 0.1 ||
                    d.mixEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(
                  d => `${d.name === 'Miscellaneous' ? t(d.name) : d.name}-2`,
                )
                ?.reverse(),
            ];
            effect = [
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.volumeEffect >= 0.1 ||
                    d.volumeEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(d => d.volumeEffect)
                ?.reverse(),
              0,
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.tonalityEffect >= 0.1 ||
                    d.tonalityEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(d => d.tonalityEffect)
                ?.reverse(),
              0,
              ...thematicsToIterate
                ?.filter(
                  d =>
                    d.mixEffect >= 0.1 ||
                    d.mixEffect <= -0.1 ||
                    d.name === 'Miscellaneous',
                )
                ?.map(d => d.mixEffect)
                ?.reverse(),
            ];
          }
          break;
      }
      break;
    default:
    case false:
      switch (filter) {
        case 'impactWeight':
        default:
          if (order === 'asc') {
            effect = thematicsToIterate
              ?.filter(
                d =>
                  d.totalEffect >= 0.1 ||
                  d.totalEffect <= -0.1 ||
                  d.name === 'Miscellaneous',
              )
              ?.map(d => d.totalEffect)
              ?.sort((a, b) => a - b);
            labels = thematicsToIterate
              ?.filter(
                d =>
                  d.totalEffect >= 0.1 ||
                  d.totalEffect <= -0.1 ||
                  d.name === 'Miscellaneous',
              )
              ?.sort((a, b) => a.totalEffect - b.totalEffect)
              ?.map(d => d.name === 'Miscellaneous' ? t(d.name) : d.name);
          } else {
            labels = thematicsToIterate
              ?.filter(
                d =>
                  d.totalEffect >= 0.1 ||
                  d.totalEffect <= -0.1 ||
                  d.name === 'Miscellaneous',
              )
              ?.sort((a, b) => b.totalEffect - a.totalEffect)
              ?.map(d => d.name === 'Miscellaneous' ? t(d.name) : d.name);
            effect = thematicsToIterate
              ?.filter(
                d =>
                  d.totalEffect >= 0.1 ||
                  d.totalEffect <= -0.1 ||
                  d.name === 'Miscellaneous',
              )
              ?.map(d => d.totalEffect)
              ?.sort((a, b) => b - a);
          }
          break;
        case 'thematics':
          if (order === 'asc') {
            effect = thematicsToIterate
              ?.filter(
                d =>
                  d.totalEffect >= 0.1 ||
                  d.totalEffect <= -0.1 ||
                  d.name === 'Miscellaneous',
              )
              ?.map(d => d.totalEffect);
            labels = thematicsToIterate
              ?.filter(
                d =>
                  d.totalEffect >= 0.1 ||
                  d.totalEffect <= -0.1 ||
                  d.name === 'Miscellaneous',
              )
              ?.map(d => d.name === 'Miscellaneous' ? t(d.name) : d.name);
          } else {
            effect = thematicsToIterate
              ?.filter(
                d =>
                  d.totalEffect >= 0.1 ||
                  d.totalEffect <= -0.1 ||
                  d.name === 'Miscellaneous',
              )
              ?.map(d => d.totalEffect)
              ?.reverse();
            labels = thematicsToIterate
              ?.filter(
                d =>
                  d.totalEffect >= 0.1 ||
                  d.totalEffect <= -0.1 ||
                  d.name === 'Miscellaneous',
              )
              ?.map(d => d.name === 'Miscellaneous' ? t(d.name) : d.name)
              ?.reverse();
          }
          break;
      }
      break;
  }

  return { totalEffect: effect, labels };
};
