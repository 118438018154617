import gql from 'graphql-tag';

const mutation = gql`
  mutation duplicateReportingModel($reportingModelId: Int!) {
    duplicateReportingModel(reportingModelId: $reportingModelId) {
      id
      title
      updatedAt
      updatedBy
      canCreateReporting
    }
  }
`;

export default mutation;
