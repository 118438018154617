import React from 'react';
import styled from 'styled-components';
import { grayShades, lightBlue } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QTitle from '_Components/Texts/QTitle/QTitle';

export const SectionLink = styled.p`
  color: ${lightBlue} !important;
  text-decoration: underline;
  margin-left: 1em;
  cursor: pointer;
`;

export const Section = styled.section`
  margin-top: 1.5em;
  font-weight: bold;

  & p {
    color: ${grayShades.g800};
    margin-right: 0.5em;
  }

  & p,
  & span {
    font-size: ${fontSizes.smaller};
    margin-top: 0.5em;
  }
`;

export const SectionItem = styled.div`
  display: flex;
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionTitle = styled(QTitle)`
  font-size: 1.1em;
`;
