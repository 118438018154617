import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import { grayShades } from 'styles/abstracts/colors';
import DatePickerContainer from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/Inputs/DatePickerContainer';
import CriteriaKeysFilter from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/Inputs/CriteriaKeysFilter';
import ThematicsFilter from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/Inputs/ThematicsFilter';
import { setTriggersRangeFilter } from '_Resources/Triggers/Actions/Filters/setTriggersRangeFilter';
import _, { isEqual, get, uniqWith } from 'lodash';
import { connect } from 'react-redux';
import { FILTER_TYPES } from 'Routes/Insights/Triggers/_Components/configs/filtersConfig';
import { addTriggersFilters } from '_Resources/Triggers/Actions/Filters/addTriggersFilters';
import { removeTriggersFilters } from '_Resources/Triggers/Actions/Filters/removeTriggersFilters';
import { isValidFullDateRange } from 'Routes/Insights/Triggers/_Utils/checkDateRange';
import { prepareGroupedToOptions } from '_Container/QProjectFilters/Filters/QThematicFilter/groupedToOptions';
import memoizeOne from 'memoize-one';
import { transformThematicsToOptions } from '_Providers/QFilterOptionsProvider/optionProviderFunctions.js';

const SwitcherContainer = styled(Col)`
  padding: 1rem;
  border-bottom: 1px solid ${grayShades.g400};
`;

const CustomCol = styled(Col)`
  margin: 1em;
`;

class FiltersSwitcher extends Component {
  constructor(props) {
    super(props);
    const groupedToOptions = prepareGroupedToOptions(props.t);
    this.groupedToOptions = memoizeOne(thematics => {
      return groupedToOptions(thematics);
    });
    this.state = {
      menuOption: 'date-range',
    };
  }

  onPeriodUpdate = ({ startDate, endDate }) => {
    const { dispatcher, context, currentPeriod } = this.props;
    if (!isEqual({ startDate, endDate }, currentPeriod)) {
      dispatcher(context, {
        startDate,
        endDate,
      });
    }
  };

  getRangeDate() {
    const { currentPeriod, triggersDateRangeSettings, dispatchSetTriggersRangeFilter } = this.props;

    const specificDateRangeSettings = triggersDateRangeSettings || {};
    if (!specificDateRangeSettings.startDate || !specificDateRangeSettings.endDate) {
      dispatchSetTriggersRangeFilter(currentPeriod);
      return currentPeriod;
    }
    return { ...specificDateRangeSettings };
  }

  getThematicsInitialValues() {
    const { thematicsFilters } = this.props;
    const initialThematics = get(this.props, ['currentSettingsFilters', 'thematics']);
    if (initialThematics && initialThematics.length > 0) {
      const cleanInitialThematics = initialThematics.map(thematic => {
        return Object.fromEntries(Object.entries(thematic).filter(([key, value]) => value != null));
      });
      const initialNITThematics = transformThematicsToOptions(cleanInitialThematics, thematicsFilters);

      const uniquesInitialNITThematics = uniqWith(initialNITThematics, _.isEqual);

      return uniquesInitialNITThematics;
    }
    return [];
  }

  dispatchTriggersRangeFilters(range) {
    const { projectPeriods, dispatchSetTriggersRangeFilter } = this.props;
    const globalProjectRange = projectPeriods.byAll.period;
    const finalValidFullDateRange = isValidFullDateRange(range, globalProjectRange);
    dispatchSetTriggersRangeFilter(finalValidFullDateRange);
  }

  content = {
    'date-range': () => {
      const { projectPeriods } = this.props;
      return (
        <>
          <DatePickerContainer
            dispatcher={(type, range) => this.dispatchTriggersRangeFilters(range)}
            projectPeriods={projectPeriods}
            dateRange={this.getRangeDate()}
            title="Period"
          />
        </>
      );
    },
    thematics: () => {
      const {
        context,
        onRemovedThematicFilter,
        onSelectedThematicFilter,
        thematicsFilters,
        edit,
        temporaryTriggersTable,
      } = this.props;
      return (
        <ThematicsFilter
          context={context}
          onRemoved={onRemovedThematicFilter}
          onSelected={onSelectedThematicFilter}
          thematics={thematicsFilters}
          initialValues={
            edit ? this.getThematicsInitialValues() || [] : temporaryTriggersTable?.settings?.thematics || []
          }
        />
      );
    },
    filters: () => {
      const { context, onRemovedNormalFilter, onSelectedNormalFilter, normalFilters } = this.props;
      return (
        <CriteriaKeysFilter
          context={context}
          onRemoved={onRemovedNormalFilter}
          onSelected={onSelectedNormalFilter}
          normalFilters={normalFilters}
          initialValues={get(this.props, ['currentSettingsFilters', 'criteriaKeys']) || null}
        />
      );
    },
  };

  getSwitcherOptions() {
    const { t } = this.props;
    return [
      {
        id: 'date-range',
        label: t('Date range'),
      },
      {
        id: 'thematics',
        label: t('Thematics'),
      },
      {
        id: 'filters',
        label: t('Filters'),
      },
    ];
  }

  onMenuOptionSelected = menuOption => {
    this.setState({ menuOption });
  };

  render() {
    const { menuOption } = this.state;
    const content = this.content[menuOption];
    return (
      <>
        <Row>
          <SwitcherContainer>
            <QSwitcher
              selected={menuOption}
              options={this.getSwitcherOptions()}
              onOptionSelected={this.onMenuOptionSelected}
            />
          </SwitcherContainer>
        </Row>
        <Row>
          <CustomCol>{content()}</CustomCol>
        </Row>
      </>
    );
  }
}

FiltersSwitcher.propTypes = {
  t: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  thematicsFilters: PropTypes.any.isRequired,
  normalFilters: PropTypes.any.isRequired,
  projectPeriods: PropTypes.any.isRequired,
  temporaryTriggersTable: PropTypes.any.isRequired,
  /* eslint-enable react/forbid-prop-types */
  context: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  onRemovedNormalFilter: PropTypes.func.isRequired,
  onSelectedNormalFilter: PropTypes.func.isRequired,
  dispatchSetTriggersRangeFilter: PropTypes.func.isRequired,
  onRemovedThematicFilter: PropTypes.func.isRequired,
  onSelectedThematicFilter: PropTypes.func.isRequired,
  triggersDateRangeSettings: PropTypes.objectOf({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  currentPeriod: PropTypes.objectOf({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  dispatcher: PropTypes.func.isRequired,
};

FiltersSwitcher.defaultProps = {
  triggersDateRangeSettings: {
    startDate: '',
    endDate: '',
  },
  currentPeriod: {
    startDate: '',
    endDate: '',
  },
  edit: false,
};

const mapStateToProps = ({ periods: { projectPeriods, currentPeriod }, triggers, filters: { projectFilters } }) => ({
  projectPeriods,
  currentPeriod,
  activeTriggersTable: get(triggers, ['activeTriggersTable']),
  temporaryTriggersTable: get(triggers, ['temporaryTriggers']),
  triggersDateRangeSettings: get(triggers, ['temporaryTriggers', 'settings', 'range']),
  normalFilters: get(projectFilters, ['criteriaKeys', 'normal']),
  thematicsFilters: get(projectFilters, ['thematics']),
});

const mapDispatchToProps = (dispatch, { context }) => ({
  dispatchSetTriggersRangeFilter: range => {
    dispatch(setTriggersRangeFilter(range));
  },
  onSelectedNormalFilter: filterValues =>
    dispatch(addTriggersFilters(context, FILTER_TYPES.CRITERIA_KEYS, filterValues)),
  onRemovedNormalFilter: filterValues =>
    dispatch(removeTriggersFilters(context, FILTER_TYPES.CRITERIA_KEYS, [filterValues, ''])),
  onSelectedThematicFilter: filterValues => dispatch(addTriggersFilters(context, FILTER_TYPES.THEMATICS, filterValues)),
  onRemovedThematicFilter: (...args) => dispatch(removeTriggersFilters(context, FILTER_TYPES.THEMATICS, args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('reporting')(FiltersSwitcher));
