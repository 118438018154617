import { merge, isNil } from 'lodash';

export const updatePageDisplaySettings = (
  state,
  { pageId, sectionId, newData },
) => {
  // null or undefined
  if (!isNil(sectionId)) {
    return {
      ...state,
      children: state.children.map((page, pageIndex) =>
        pageIndex === pageId
          ? {
              ...page,
              children: page.children.map((section, sectionIndex) =>
                sectionIndex === sectionId
                  ? merge({}, section, newData)
                  : section,
              ),
            }
          : page,
      ),
    };
  }
  return {
    ...state,
    children: state.children.map((page, index) =>
      index === pageId ? merge({}, page, newData) : page,
    ),
  };
};
