import gql from 'graphql-tag';

const mutation = gql`
  mutation archiveOrUnarchiveProject($id: Int!, $archive: Boolean!) {
    archiveOrUnarchiveProject(id: $id, archive: $archive) {
      id
      archived
      updatedAt
      errors
    }
  }
`;

export default mutation;
