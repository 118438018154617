import React from 'react';
import {
  FIELD_TYPE,
  COPY_FIELD_IDS,
  FOOTER_FIELD_IDS,
  HEADER_FIELD_IDS,
  FIELD_MODE,
} from '../../../../../_Resources/Reporting/Configs/settingFields';

export const TITLE_FIELD = {
  id: COPY_FIELD_IDS.TITLE,
  label: 'Title',
  mode: FIELD_MODE.CHECKBOX,
};
export const SUBTITLE_FIELD = {
  id: COPY_FIELD_IDS.SUBTITLE,
  label: 'Subtitle',
  mode: FIELD_MODE.CHECKBOX,
};
export const DATE_RANGE_FIELD = {
  id: HEADER_FIELD_IDS.DATE_RANGE,
  label: 'Date range',
  mode: FIELD_MODE.CHECKBOX,
};
export const FILTER_FIELD = {
  id: HEADER_FIELD_IDS.FILTERS,
  label: 'Filters',
  mode: FIELD_MODE.CHECKBOX,
};
export const THEMATIC_FIELD = {
  id: HEADER_FIELD_IDS.THEMATICS,
  label: 'Thematics',
  mode: FIELD_MODE.CHECKBOX,
};
export const REPORT_TITLE_FIELD = {
  id: FOOTER_FIELD_IDS.REPORT_TITLE,
  label: 'Report title',
  mode: FIELD_MODE.CHECKBOX,
};
export const PAGINATION_FIELD = {
  id: FOOTER_FIELD_IDS.PAGINATION,
  label: 'Pagination',
  mode: FIELD_MODE.CHECKBOX,
};
export const EDITION_DATE_FIELD = {
  id: FOOTER_FIELD_IDS.EDITION_DATE,
  label: 'Edition date',
  mode: FIELD_MODE.CHECKBOX,
};
export const IS_CUSTOMIZED_TEXT_FIELD = {
  id: FOOTER_FIELD_IDS.IS_CUSTOMIZED_TEXT,
  label: 'Add customized text',
  mode: FIELD_MODE.CHECKBOX,
};
export const CUSTOMIZED_TEXT_FIELD = {
  id: FOOTER_FIELD_IDS.CUSTOMIZED_TEXT,
  label: 'Type your text here',
  mode: FIELD_MODE.TEXT_INPUT,
};

export const settingOptions = {
  [FIELD_TYPE.COPY]: [TITLE_FIELD, SUBTITLE_FIELD],
  [FIELD_TYPE.HEADER]: [DATE_RANGE_FIELD, FILTER_FIELD, THEMATIC_FIELD],
  [FIELD_TYPE.FOOTER]: [
    REPORT_TITLE_FIELD,
    PAGINATION_FIELD,
    EDITION_DATE_FIELD,
    IS_CUSTOMIZED_TEXT_FIELD,
    CUSTOMIZED_TEXT_FIELD,
  ],
};

export const DATE_RANGE_VALUES = {
  DAY: { id: 'day', label: 'Day' },
  WEEK: { id: 'week', label: 'Week' },
  MONTH: { id: 'month', label: 'Month' },
};

export const EMOTIONS_EVOLUTION_DATA_DISPLAY = {
  PERCENTAGE: { value: 'percentage', label: 'Percentage' },
  ABSOLUTE: { value: 'absoluteValues', label: 'Absolute values' },
};

export const ANALYSIS_AXIS = {
  EMOTION: { id: 'emotion', label: 'Emotion' },
  SENTIMENT: { id: 'sentiment', label: 'Sentiment' },
};

export const LABEL = {
  CLASSIC: { id: 'classic', label: 'Classic' },
  EXTERNAL: { id: 'external', label: 'External' },
};

export const LEVEL = {
  THEMATIC: { id: 'thematic', label: 'Categories' },
  SUB_THEMATIC: { id: 'subThematic', label: 'Subcategories' },
  SUB_SUB_THEMATIC: { id: 'subSubThematic', label: 'Sub sub categories' },
};
