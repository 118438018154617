import gql from 'graphql-tag';

const query = gql`
  query searchInsight(
    $projectId: Int!
    $range: DateRangeInput
    $terms: [String!]!
    $limit: Int
    $engine: InsightEngine!
    $insightId: Int
  ) {
    searchInsight(
      projectId: $projectId
      range: $range
      limit: $limit
      engine: $engine
      terms: $terms
      insightId: $insightId
    ) {
      id
      createdOn
      fullText
      sentences {
        id
        order
        relevant
        sentence
        results {
          eindex
          sat
          reco
          sensitivity
          strongestEmotion
          emotions {
            happiness
            surprise
            calm
            fear
            sadness
            anger
            disgust
          }
        }
      }
    }
  }
`;

export default query;
