import gql from 'graphql-tag';

const mutation = gql`
  mutation saveBenchmark(
    $id: Int
    $title: String
    $description: String
    $isDefault: Boolean
    $benchmarkType: BenchmarkCategory
    $projectIds: Int
    $settings: Json
  ) {
    saveSettings: saveBenchmark(
      id: $id
      title: $title
      description: $description
      isDefault: $isDefault
      benchmarkType: $benchmarkType
      projectIds: $projectIds
      settings: $settings
    ) {
      id
      title
      description
      isDefault
      errors
      canCreateBenchmark
    }
  }
`;

export default mutation;
