import React from 'react';
import CModal from '../../CModal/CModal';
import QueryVerbatimList from './_Components/QueryVerbatimList';

class VerbatimListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfMentions: 0,
    };
    this.loadNumberOfMentions = this.loadNumberOfMentions.bind(this);
  }

  loadNumberOfMentions(mentions) {
    this.setState({ numberOfMentions: mentions });
  }

  render() {
    const { mentionCount, filters, emotions, mergedThematics } = this.props;
    const { numberOfMentions } = this.state;
    return (
      <CModal {...this.props} numberOfMentions={numberOfMentions} mentionCount={mentionCount} displayMentionsSubtitle>
        <QueryVerbatimList
          filters={filters}
          emotions={emotions}
          loadNumberOfMentions={this.loadNumberOfMentions}
          mergedThematics={mergedThematics}
        />
      </CModal>
    );
  }
}

export default VerbatimListModal;
