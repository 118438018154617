import React from 'react';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import QStepWizard from '../../../../../../../../_Components/QStepWizard/QStepWizard';

export const ADDING_INSIGHT_PROGRESS_STEPS = {
  SEARCH: 'search',
  FILTERS: 'filters',
  RESULTS: 'results',
};

const { SEARCH, FILTERS, RESULTS } = ADDING_INSIGHT_PROGRESS_STEPS;

const STEP_LABELS = {
  [SEARCH]: 'Search',
  [FILTERS]: 'Select',
  [RESULTS]: 'Name',
};

const STATUSES = {
  COMPLETE: 'complete',
  ACTIVE: 'active',
  DISABLED: 'disabled',
};

const STATUSES_CONFIG = {
  [STATUSES.COMPLETE]: { status: STATUSES.COMPLETE, value: STATUSES.COMPLETE },
  [STATUSES.ACTIVE]: { status: STATUSES.ACTIVE, value: STATUSES.ACTIVE },
  [STATUSES.DISABLED]: { status: STATUSES.DISABLED, value: STATUSES.DISABLED },
};

const createStep = (step, status) => {
  const config = STATUSES_CONFIG[status];
  return merge(
    {
      id: step,
      name: STEP_LABELS[step],
    },
    config,
  );
};

const prepareGetStatus = (activeIndex, steps) => index => {
  if (index < activeIndex || activeIndex === steps.length - 1) {
    return STATUSES.COMPLETE;
  } else if (index === activeIndex) {
    return STATUSES.ACTIVE;
  }

  return STATUSES.DISABLED;
};

const prepareStepsConfigs = (steps, activeState) => {
  const activeStateIndex = steps.indexOf(activeState);

  const getStatus = prepareGetStatus(activeStateIndex, steps);

  return steps.map((step, index) => createStep(step, getStatus(index)));
};

const AddInsightProgress = ({
  currentStep,
  className,
  steps,
  onStepSelected,
}) => {
  const stepsConfigs = prepareStepsConfigs(steps, currentStep);
  const onWizardStepSelected = name => {
    const step = stepsConfigs.find(({ name: stepName }) => name === stepName);
    if (step.status === STATUSES.COMPLETE) {
      onStepSelected(step.id);
    }
  };
  return (
    <QStepWizard
      steps={stepsConfigs}
      className={className}
      onStepSelected={onWizardStepSelected}
    />
  );
};

AddInsightProgress.propTypes = {
  steps: PropTypes.arrayOf(Object.values(ADDING_INSIGHT_PROGRESS_STEPS))
    .isRequired,
  onStepSelected: PropTypes.func,
  currentStep: PropTypes.string,
  className: PropTypes.string,
};

AddInsightProgress.defaultProps = {
  currentStep: '',
  className: '',
  onStepSelected: null,
};

export default AddInsightProgress;
