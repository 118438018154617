import gql from 'graphql-tag';

const mutation = gql`
  mutation updateUserPassword(
    $currentPassword: String!
    $newPassword: String!
    $token: String
  ) {
    updateUserPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      token: $token
    ) {
      status
      errors
    }
  }
`;

export default mutation;
