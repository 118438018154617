/* eslint-disable  import/prefer-default-export */

export function getPeriodConfig(config) {
  const { onSelect } = config;
  return {
    title: 'Data type',
    onSelect,
    items: [
      {
        category: 'dataType',
        options: [
          { id: 'current', title: 'Current period' },
          { id: 'previousPeriod', title: 'Prev. period evolution' },
          { id: 'previousYear', title: 'Prev year evolution' },
        ],
      },
    ],
  };
}

export const DISPLAY_BY_CATEGORIES = {
  MAIN_ENTRIES: 'mainEntries',
  KPIS: 'kpis',
  EMOTIONS: 'emotions',
};

export function getDisplayByConfig(config) {
  const { onSelect } = config;
  return {
    title: 'Display by',
    onSelect,
    items: [
      {
        category: DISPLAY_BY_CATEGORIES.MAIN_ENTRIES,
        options: [
          { id: 'eindex', title: 'Eindex' },
          { id: 'mentions', title: 'Mentions' },
        ],
      },
      {
        title: 'KPIs',
        category: DISPLAY_BY_CATEGORIES.KPIS,
        options: [
          { id: 'sat', title: 'Satisfaction' },
          { id: 'reco', title: 'Recommendation' },
          { id: 'ces', title: 'CES' },
        ],
      },
      {
        title: 'Emotions',
        category: DISPLAY_BY_CATEGORIES.EMOTIONS,
        options: [
          { id: 'happiness', title: 'Happiness' },
          { id: 'surprise', title: 'Surprise' },
          { id: 'calm', title: 'Calm' },
          { id: 'fear', title: 'Fear' },
          { id: 'sadness', title: 'Sadness' },
          { id: 'anger', title: 'Anger' },
          { id: 'disgust', title: 'Disgust' },
        ],
      },
    ],
  };
}
