import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import styled from 'styled-components';
import './style.scss';
import VerbatimBubble from '../../../_Components/QVerbatimBubble/VerbatimBubble';
import NoDataMessage from '../../../_Components/NoDataMessage/NoDataMessage';

const CustomTabPane = styled(TabPane)`
  overflow-y: scroll;
  flex-grow: 1;
  overflow-x: hidden;
  height: 100%;
`;

class VerbatimGlobalBestAndPainPointsCard extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      data: null,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { t } = this.props;
    const list = data => {
      if (data === 'error') return <NoDataMessage />;
      const rows = [];
      let id = 0;
      for (const i in data) {
        id += 1;
        rows.push(
          <CustomTabPane key={i} tabId={id.toString()}>
            <Row className="p-3">
              {data[i].map((item, j) => (
                <Col key={`VerbatimBubble${i}${j}`} sm="12">
                  <VerbatimBubble {...item} />
                </Col>
              ))}
            </Row>
          </CustomTabPane>,
        );
      }
      return rows;
    };
    const subHeader = (
      <Nav tabs className="justify-content-center minHeightTabs">
        <NavItem>
          <NavLink
            className={`${
              this.state.activeTab === '1' ? 'tabSuccessActive' : ''
            } Qtabs`}
            onClick={() => {
              this.toggle('1');
            }}
          >
            {t('Best Verbatim')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${
              this.state.activeTab === '2' ? 'tabErrorActive' : ''
            } Qtabs`}
            onClick={() => {
              this.toggle('2');
            }}
          >
            {t('Worst Verbatim')}
          </NavLink>
        </NavItem>
      </Nav>
    );
    return (
      <>
        {subHeader}
        <TabContent activeTab={this.state.activeTab} className="height100">
          {list(this.props.data)}
        </TabContent>
      </>
    );
  }
}

export default withTranslation('button')(VerbatimGlobalBestAndPainPointsCard);
