import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get, clone, orderBy } from 'lodash';
import { useMutation } from 'react-apollo';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from 'styles/abstracts/variables';
import { grayShades } from 'styles/abstracts/colors';
import SAVE_THEMATICS_OVERVIEW_SETTINGS_MUTATION from '../../../graphql/saveThematicsOverviewSettings';

const StyledQCheckbox = styled(QCheckbox)`
  margin: 0 1em 0 0 !important;
`;

const StyledItem = styled(Item)`
  justify-content: flex-start !important;
`;

const CustomToggle = styled(ToggleButton)`
  max-width: 20em !important;
`;

const EmotionsTriggersDropdown = ({
  label,
  options,
  setSelectedItem,
  selectedItem,
  projectId,
  settings,
  setSettings,
  t,
  currentLang,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const [saveThematicsOverviewSettings] = useMutation(
    SAVE_THEMATICS_OVERVIEW_SETTINGS_MUTATION,
  );
  const buttonLabel = `${t(
    `button:${
      options.find(
        opt =>
          opt.id ===
          (label === 'Display by'
            ? orderBy(selectedItem, item => item, ['desc'])
            : selectedItem)?.[0],
      )?.label
    }`,
  ) || t('Select')} ${
    selectedItem?.length > 1
      ? `${t('button:and')} ${selectedItem?.length - 1} ${t('button:other')}${
          selectedItem?.length - 1 > 1 && currentLang !== 'de' ? 's' : ''
        }`
      : ''
  }`;
  return (
    <div>
      <Label>{t(label)}</Label>
      <StyledDropdown
        direction="down"
        isOpen={isDropdownOpen}
        toggle={() => setDropdownOpen(!isDropdownOpen)}
      >
        <StyledDropdownToggle>
          <CustomToggle disableHover bgColor="grayShades.g300">
            {buttonLabel}
            <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
          </CustomToggle>
        </StyledDropdownToggle>
        <Menu className="emotionsTriggersDropdownMenu">
          {options.map(option => (
            <StyledItem
              toggle={false}
              onClick={() => {
                let newItem;
                if (Array.isArray(selectedItem)) {
                  if (selectedItem.includes(option.id)) {
                    newItem = selectedItem.filter(ind => ind !== option.id);
                  } else {
                    newItem = [...selectedItem, option.id];
                  }
                } else {
                  newItem = [option.id];
                }
                setSelectedItem(newItem);
                const cloneSettings = clone(settings);
                if (!cloneSettings.emotionsTriggers) {
                  cloneSettings.emotionsTriggers = {
                    selectedIndicator:
                      label !== 'Display by' ? newItem : ['happiness'],
                    display:
                      label === 'Display by'
                        ? newItem
                        : ['thematics', 'subThematics', 'subSubThematics'],
                    datazoom: { start: 0, end: 100 },
                  };
                } else if (label === 'Display by') {
                  cloneSettings.emotionsTriggers.display = newItem;
                } else {
                  cloneSettings.emotionsTriggers.selectedIndicator = newItem;
                }
                saveThematicsOverviewSettings({
                  variables: {
                    projectId,
                    settings: JSON.stringify(cloneSettings),
                  },
                }).then(() => setSettings(cloneSettings));
              }}
            >
              <StyledQCheckbox selected={selectedItem?.includes(option.id)} />
              {t(`button:${option.label || option.id}`)}
            </StyledItem>
          ))}
        </Menu>
      </StyledDropdown>
    </div>
  );
};

const mapStateToProps = state => ({
  projectId: get(state, ['filters', 'filterKeys', 'projectId']),
  currentLang: get(state, ['auth', 'user', 'lang']),
});

export default connect(mapStateToProps)(EmotionsTriggersDropdown);
