import React, { useState } from 'react';
import Layout from '_Layouts/Layout';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
import ProjectConfiguration from 'Routes/Settings/ProjectConfiguration/ProjectConfiguration';
import ProjectConfigurationSubHeader from './_Containers/ProjectConfigurationSubHeader';
import { GLOBAL_STEPS } from './utils';

const title = 'Project configuration';

const action = props => {
  const [activeStep, setActiveStep] = useState(1);
  const { history, location } = props;
  const subHeader = (
    <ProjectConfigurationSubHeader
      steps={GLOBAL_STEPS}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      history={history}
    />
  );
  const body = (
    <ProjectConfiguration
      idpage="project-configuration"
      setActiveStep={setActiveStep}
      activeStep={activeStep}
      steps={GLOBAL_STEPS}
      history={history}
      location={location}
    />
  );
  return (
    <QLoginRedirect>
      <Layout
        body={body}
        allowCollapse={false}
        subHeader={subHeader}
        title={title}
        {...props}
        hideMentionsCount
      />
    </QLoginRedirect>
  );
};

export default action;
