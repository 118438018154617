import { isNil } from 'lodash';
import {
  LOAD_INSIGHTS_FAILURE,
  LOAD_INSIGHTS_SUCCESS,
} from '../Actions/loadInsights';
import { SELECT_INSIGHT } from '../Actions/selectInsightDetails';
import { LOAD_INSIGHT_VERBATIM_SUCCESS } from '../Actions/loadInsightDetailsVerbatim';

const insightDetailsInitialState = {
  insightDetails: null,
  insightDetailsVerbatim: null,
};

const initialState = {
  ...insightDetailsInitialState,

  insights: [],
  insightsLoadError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INSIGHTS_SUCCESS: {
      return {
        ...state,
        insights: action.insights,
        insightsLoadError: null,
      };
    }
    case LOAD_INSIGHTS_FAILURE: {
      return {
        ...state,
        insights: [],
        insightsLoadError: action.error,
      };
    }
    case SELECT_INSIGHT: {
      const { id } = action;
      return {
        ...state,
        insightDetails: isNil(id)
          ? null
          : state.insights.find(({ insightId }) => insightId === id),
      };
    }
    case LOAD_INSIGHT_VERBATIM_SUCCESS: {
      return {
        ...state,
        insightDetailsVerbatim: action.verbatim,
      };
    }
    default: {
      return state;
    }
  }
};
