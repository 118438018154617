import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../styles/abstracts/variables';
import QText, { QTEXT_MODES } from '../../Texts/QText/QText';
import { BasePropTypes } from '../../../_Utils/common.propTypes';

const StyledQText = styled(QText)`
  position: relative;
  margin: ${spacing.mediumLarge};
  display: flex;
  font-weight: bold;
`;

const QFooter = ({ children, className }) => (
  <StyledQText mode={QTEXT_MODES.PRIMARY} className={className}>
    {children}
  </StyledQText>
);

QFooter.propTypes = {
  ...BasePropTypes,
};

QFooter.LeftBar = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

QFooter.RightBar = styled.div`
  flex-grow: 0;
  text-align: right;
  white-space: nowrap;
`;

export default QFooter;
