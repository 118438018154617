import { cloneDeep, merge } from 'lodash';

import {
  REPORTING_MODES,
  UPDATE_REPORTING_MODE,
} from '../Actions/updateReportingMode';
import { SELECT_REPORTING_TAB } from '../Actions/selectReportingTab';
import { SELECT_REPORTING_MENU_OPTION } from '../Actions/selectReportingMenuOption';
import { ADD_PAGE_BY_SHORTCUT } from '../Actions/addPageByShortcut';
import { COVER_SETTINGS_TOGGLE_OTHER_INFOS } from '../Actions/CoverSettings/toggleOtherInfos';
import {
  COVER_SETTINGS_UPDATE_SUBTITLE,
  COVER_SETTINGS_UPDATE_TITLE,
} from '../Actions/CoverSettings/updateCopy';
import { EXPORT_SELECT_OPTION } from '../Actions/Export/selectOption';
import { CHANGE_PAGE_BY_SHORTCUT } from '../Actions/changePageByShortcut';
import { REMOVE_PAGE } from '../Actions/removePage';
import { DUPLICATE_PAGE } from '../Actions/duplicatePage';
import { SELECT_SLOT_OVER } from '../Actions/selectSlotOver';
import { SELECT_CARD_OVER } from '../Actions/selectCardOver';
import { SELECT_PAGE_OVER } from '../Actions/selectPageOver';
import { UPDATE_PAGE_CARD } from '../Actions/updatePageCard';
import { updatePageCardHelper } from './helpers/updatePageCard';
import defaultLayouts from '../../../Routes/GlobalExperience/Reporting/_Containers/layouts/defaultLayouts';
import { UPDATE_SECTION_DATA } from '../Actions/Section/updateSectionData';
import { PAGE_UPDATE_DISPLAY_SETTINGS } from '../Actions/PageSettings/updateDisplaySettings';
import { GLOBAL_LAYOUT_UPDATE_DISPLAY_SETTINGS } from '../Actions/GlobalLayoutSettings/updateDisplaySettings';
import { updatePageDisplaySettings } from './helpers/updatePageDisplaySettings';
import { SET_PREVIEW_MODE } from '../Actions/setPreviewMode';
import { ADD_GLOBAL_FILTERS } from '../Actions/GlobalLayoutSettings/addGlobalFilters';
import { addReportFilter, removeReportFilter } from './helpers/reportFilter';
import { ADD_ITERATE_BY } from '../Actions/Export/addIterateBy';
import { addIterateBy, removeIterateBy } from './helpers/addIterateBy';
import { SET_PREVIEW_FOR } from '../Actions/setPreviewFor';
import { SET_ITERATE_BY } from '../Actions/setIterateBy';
import { GLOBAL_SET_CURRENT_PERIOD } from '../../Global/Actions/setCurrentPeriod';
import { ADD_PAGE_FILTERS } from '../Actions/PageSettings/addPageFilters';
import { addPageFilter, removePageFilter } from './helpers/pageFilter';
import { ADD_SECTION_FILTERS } from '../Actions/Section/addSectionFilters';
import { addSectionFilter, removeSectionFilter } from './helpers/sectionFilter';
import { REMOVE_PAGE_FILTERS } from '../Actions/PageSettings/removePageFilters';
import { REMOVE_GLOBAL_FILTERS } from '../Actions/GlobalLayoutSettings/removeGlobalFilters';
import { REMOVE_SECTION_FILTERS } from '../Actions/Section/removeSectionFilters';
import { REMOVE_ITERATE_BY } from '../Actions/Export/removeIterateBy';
import { UPDATE_EXPORT_COUNTER } from '../Actions/Export/updateCounter';
import { UPDATE_EXPORT_ACTIVE } from '../Actions/Export/updateExportActive';
import { RESET_REPORTING_SETTINGS } from '../Actions/resetReportingSettings';
import { COVER_SETTINGS_UPDATE_LOGO } from '../Actions/CoverSettings/updateLogo';
import { updateReportingModeHandler } from './helpers/updateReportingMode';
import { UPDATE_SECTION_SETTINGS } from '../Actions/Section/updateSectionSettings';
import { updateSectionSettingsHandler } from './helpers/updateSectionSettings';

const iterateInitialState = {
  iterateBy: null,
  previewFor: 'all',
};

export const filterSettingsInitialState = {
  thematic: null,
  normal: null,
};

export const dateRangeInitialState = {
  isStartDate: false,
  isEndDate: false,
  startDate: null,
  endDate: null,
};

const headerSettingsInitialState = {
  isDateRange: true,
  isFilters: true,
  isThematics: true,
};

const headerInitialState = {
  isContextualSubtitle: true,
  ...headerSettingsInitialState,
};

const footerInitialState = {
  isFooter: false,
  isReportTitle: false,
  isPagination: true,
  isEditionDate: false,
  isCustomizedText: false,
  customizedText: '',
};

const coverSettingsInitialState = {
  title: 'Report title',
  subtitle: 'Report subtitle',
  logo: '',
  header: headerSettingsInitialState,
};

export const globalLayoutPresentationInitialState = {
  header: headerInitialState,
  footer: footerInitialState,
};

export const globalLayoutSettingsInitialState = {
  ...globalLayoutPresentationInitialState,
  filters: filterSettingsInitialState,
  dateRange: dateRangeInitialState,
};

export const pagePresentationInitialState = {
  title: 'Page title',
  header: null,
  footer: null,
};

export const pageInitialConfigState = {
  filters: null,
  dateRange: null,
  ...pagePresentationInitialState,
};

export const sectionPresentationInitialConfigState = {
  title: 'Section title',
  header: headerInitialState,
};

export const sectionInitialConfigState = {
  ...sectionPresentationInitialConfigState,
  filters: null,
  dateRange: null,
  settings: {},
};

const pageInitialState = {
  ...defaultLayouts.init,
  children: defaultLayouts.init.children.map(section => ({
    ...sectionInitialConfigState,
    ...section,
  })),
  ...pageInitialConfigState,
};

const exportInitialState = {
  iterateByDate: 'monthly',
  iterateType: 'combined',
  filterType: 'pdf',
  freqPeriodType: 'monthly',
  freqPeriodDelay: 'first',
};

const initialCoverTabSelectionState = {
  mode: REPORTING_MODES.GLOBAL,
  selectedTab: 'add-pages',
  selectedMenuOption: 'display',
};

const idleState = {
  ...initialCoverTabSelectionState,
  iterateBy: null,
  previewFor: 'all',
  currentPageIndex: null,
  currentCardIndex: null,
  pageIndexOver: null,
  cardIndexOver: null,
  slotIndexOver: null,
  children: [pageInitialState],
  ...coverSettingsInitialState,
  globalSettings: globalLayoutSettingsInitialState,
  isPreviewMode: false,
  export: exportInitialState,
  exportCounter: null,
  exportActive: false,
  iterateByOptions: [],
};

export default (state = idleState, action) => {
  switch (action.type) {
    case RESET_REPORTING_SETTINGS: {
      const { id, title, report } = action;
      return { ...idleState, id, ...report, title };
    }
    case UPDATE_EXPORT_COUNTER: {
      return {
        ...state,
        exportCounter: action.exportCounter,
      };
    }
    case UPDATE_EXPORT_ACTIVE: {
      const { exportActive } = action;
      const newIterateState = exportActive ? iterateInitialState : {};
      return {
        ...state,
        ...newIterateState,
        exportActive,
        exportCounter: 0,
      };
    }
    case GLOBAL_SET_CURRENT_PERIOD: {
      const {
        data: { startDate, endDate },
      } = action;

      const dateGlobalSettings = {
        dateRange: { startDate, endDate },
      };

      const globalSettings = merge(
        {},
        state.globalSettings,
        dateGlobalSettings,
      );

      merge(idleState.globalSettings, dateGlobalSettings);

      return {
        ...state,
        globalSettings,
      };
    }
    case EXPORT_SELECT_OPTION: {
      const { selectName, selectedValue } = action;
      return {
        ...state,
        export: {
          ...state.export,
          [selectName]: selectedValue,
        },
      };
    }
    case COVER_SETTINGS_UPDATE_TITLE: {
      const { textValue } = action;
      return {
        ...state,
        title: textValue,
      };
    }
    case COVER_SETTINGS_UPDATE_SUBTITLE: {
      const { textValue } = action;
      return {
        ...state,
        subtitle: textValue,
      };
    }
    case COVER_SETTINGS_UPDATE_LOGO: {
      const { base64String } = action;
      return {
        ...state,
        logo: base64String,
      };
    }
    case COVER_SETTINGS_TOGGLE_OTHER_INFOS: {
      const { id } = action;
      return {
        ...state,
        header: {
          ...state.header,
          [id]: !state.header[id],
        },
      };
    }
    case UPDATE_REPORTING_MODE: {
      return updateReportingModeHandler(state, action);
    }
    case SELECT_REPORTING_TAB: {
      return {
        ...state,
        selectedTab: action.selectedTab,
      };
    }
    case SELECT_REPORTING_MENU_OPTION: {
      return {
        ...state,
        selectedMenuOption: action.selectedMenuOption,
      };
    }
    case CHANGE_PAGE_BY_SHORTCUT: {
      const { pageIndex, children, cssGridLayout, size } = action;
      return {
        ...state,
        children: [
          ...state.children.slice(0, pageIndex),
          {
            ...state.children[pageIndex],
            children: children.map(section => ({
              ...sectionInitialConfigState,
              ...section,
            })),
            cssGridLayout,
            size,
          },
          ...state.children.slice(pageIndex + 1),
        ],
      };
    }
    case ADD_PAGE_BY_SHORTCUT: {
      const { slotIndexOver, children } = state;
      const putBeforeIndex =
        slotIndexOver !== null ? slotIndexOver : children.length;

      return {
        ...state,
        children: [
          ...children.slice(0, putBeforeIndex),
          {
            ...action.page,
            children: action.page.children.map(section => ({
              ...sectionInitialConfigState,
              ...section,
            })),
            ...pageInitialConfigState,
          },
          ...children.slice(putBeforeIndex),
        ],
      };
    }
    case REMOVE_PAGE: {
      const { pageIndex } = action;
      const { children } = state;
      return {
        ...state,
        ...initialCoverTabSelectionState,
        currentPageIndex: null,
        currentCardIndex: null,
        children: [
          ...children.slice(0, pageIndex),
          ...children.slice(pageIndex + 1),
        ],
      };
    }
    case DUPLICATE_PAGE: {
      const { pageIndex } = action;
      const { children } = state;
      return {
        ...state,
        children: [
          ...children.slice(0, pageIndex + 1),
          cloneDeep(children[pageIndex]),
          ...children.slice(pageIndex + 1),
        ],
      };
    }
    case SELECT_PAGE_OVER: {
      return {
        ...state,
        pageIndexOver: action.pageIndexOver,
      };
    }
    case PAGE_UPDATE_DISPLAY_SETTINGS: {
      return updatePageDisplaySettings(state, action);
    }
    case GLOBAL_LAYOUT_UPDATE_DISPLAY_SETTINGS: {
      const { newData } = action;
      return {
        ...state,
        globalSettings: merge({}, state.globalSettings, newData),
      };
    }
    case SELECT_CARD_OVER: {
      return {
        ...state,
        cardIndexOver: action.cardIndexOver,
      };
    }
    case SELECT_SLOT_OVER: {
      return {
        ...state,
        slotIndexOver: action.slotIndexOver,
      };
    }
    case UPDATE_PAGE_CARD: {
      return state.currentPageIndex !== null
        ? updatePageCardHelper(state, action)
        : state;
    }
    case UPDATE_SECTION_DATA: {
      const { pageIndex, cardIndex, data } = action;
      return {
        ...state,
        children: state.children.map((page, index) =>
          index !== pageIndex
            ? page
            : {
                ...page,
                children: page.children.map((section, sectionIndex) =>
                  sectionIndex !== cardIndex
                    ? section
                    : { ...section, ...data },
                ),
              },
        ),
      };
    }
    case SET_PREVIEW_MODE: {
      return {
        ...state,
        isPreviewMode: action.isActive,
        ...iterateInitialState,
      };
    }
    case SET_ITERATE_BY: {
      return {
        ...state,
        iterateBy: action.iterateBy,
        previewFor: iterateInitialState.previewFor,
      };
    }
    case SET_PREVIEW_FOR: {
      return {
        ...state,
        previewFor: action.previewFor,
      };
    }
    case ADD_SECTION_FILTERS: {
      return addSectionFilter(state, action);
    }
    case REMOVE_SECTION_FILTERS: {
      return removeSectionFilter(state, action);
    }
    case ADD_PAGE_FILTERS: {
      return addPageFilter(state, action);
    }
    case REMOVE_PAGE_FILTERS: {
      return removePageFilter(state, action);
    }
    case ADD_GLOBAL_FILTERS: {
      return addReportFilter(state, action);
    }
    case REMOVE_GLOBAL_FILTERS: {
      return removeReportFilter(state, action);
    }

    case ADD_ITERATE_BY: {
      return addIterateBy(state, action);
    }
    case REMOVE_ITERATE_BY: {
      return removeIterateBy(state, action);
    }
    case UPDATE_SECTION_SETTINGS: {
      return updateSectionSettingsHandler(state, action);
    }
    default: {
      return state;
    }
  }
};
