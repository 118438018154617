import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';
import { purple, grayShades } from '../../../../../styles/abstracts/colors';
import BenchmarkGroupCardEindexLegend from './sections/BenchmarkGroupCardEindexLegend';

const LegendTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${purple};
  text-align: left;
  line-height: 21px;
  margin-bottom: 0.5rem;
  text-decoration: underline;
`;

const LegendValue = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${grayShades.g800};
  text-align: left;
  line-height: 21px;
  text-decoration: none;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 2rem 2rem;
  background-color: ${grayShades.g300};
  border-radius: 0.5em;
  margin: 1rem;
`;

const LegendElements = styled.div<{ nbOfColumns: number }>`
  column-width: ${({ nbOfColumns }) =>
    nbOfColumns > 2 ? '20rem' : '15.75rem'};
  column-rule: 1px solid ${grayShades.g600};
  margin-left: -1.5rem;
  margin-bottom: 1em;
  text-decoration: none;
`;

const LegendElementsList = styled.ol`
  padding-left: 3rem;
`;

const LegendElementsListItem = styled.li`
  &::marker {
    font-size: 14px;
    font-weight: bold;
    color: ${purple};
    text-align: left;
    line-height: 21px;
    margin-left: 0.5rem;
  }
`;

const EIndexListItem = styled.li<{ nbOfColumns: number }>`
  margin-left: ${({ nbOfColumns }) => (nbOfColumns > 1 ? '2.1rem' : '0.6rem')};
  margin-top: 1rem;

  &::marker {
    content: 'E-index. ';
    font-size: 14px;
    font-weight: bold;
    color: ${purple};
    text-align: left;
    line-height: 21px;
    margin-left: 0.5rem;
  }
`;

const EIndexListValueContainer = styled.p<{ nbOfColumns: number }>`
  margin-left: ${({ nbOfColumns }) => (nbOfColumns > 2 ? '-3.5rem' : '1.7rem')};
`;

const EIndexListValue = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${grayShades.g800};
  text-align: left;
  line-height: 21px;
  text-decoration: none;
`;

type MentionBreakdownByCategoryBenchmarkCardFooterProps = {
  nbOfColumns: number;
  t: any;
  thematicsUnifiedLegend: {
    [key: string]: string;
  };
};

const MentionBreakdownByCategoryBenchmarkCardFooter = ({
  nbOfColumns,
  t,
  thematicsUnifiedLegend,
}: MentionBreakdownByCategoryBenchmarkCardFooterProps) => {
  const [legendToDisplay, setLegendToDisplay] = useState(
    thematicsUnifiedLegend,
  );

  useEffect(() => {
    setLegendToDisplay(thematicsUnifiedLegend);
  }, [thematicsUnifiedLegend]);

  return (
    <>
      {legendToDisplay && Object.keys(legendToDisplay).length > 0 && (
        <LegendContainer>
          <LegendTitle>{t('Legend')} :</LegendTitle>
          <LegendElements nbOfColumns={nbOfColumns}>
            <LegendElementsList>
              {Object.values(legendToDisplay).map((item: string) => (
                <LegendElementsListItem key={item}>
                  <p>
                    <LegendValue>{t(item)}</LegendValue>
                  </p>
                </LegendElementsListItem>
              ))}
              {nbOfColumns > 1 && (
                <EIndexListItem key="eindex-legend" nbOfColumns={nbOfColumns}>
                  <EIndexListValueContainer nbOfColumns={nbOfColumns}>
                    <EIndexListValue>
                      <BenchmarkGroupCardEindexLegend
                        category="eindex"
                        max="40°"
                        min="-20°"
                        nbOfColumns={nbOfColumns}
                      />
                    </EIndexListValue>
                  </EIndexListValueContainer>
                </EIndexListItem>
              )}
            </LegendElementsList>
            {nbOfColumns < 2 && (
              <BenchmarkGroupCardEindexLegend
                category="eindex"
                max="40°"
                min="-20°"
                nbOfColumns={nbOfColumns}
              />
            )}
          </LegendElements>
        </LegendContainer>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  thematicsUnifiedLegend: get(state, ['benchmark', 'thematicsUnifiedLegend']),
  projectId: get(state, ['projects', 'currentProject', 'id']),
});

export default withTranslation('card')(
  connect(mapStateToProps)(MentionBreakdownByCategoryBenchmarkCardFooter),
);
