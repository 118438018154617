import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { setActiveBenchmark } from '_Resources/Benchmark/Actions/setActiveBenchmark';
import { spacing } from '../../../../../../../../../../../styles/abstracts/variables';
import {
  grayShades,
  lightBlue,
  white,
} from '../../../../../../../../../../../styles/abstracts/colors';
import QTitle from '../../../../../../../../../../../_Components/Texts/QTitle/QTitle';
import { fontSizes } from '../../../../../../../../../../../styles/abstracts/typography';
import BenchmarkOptions from './_Components/BenchmarkOptions';
import MutationSaveBenchmark from '../../../../../../../../../../../_Resources/Benchmark/Services/MutationSaveBenchmark';

const StyledRow = styled(Row)`
  padding: ${spacing.small} ${spacing.large} !important;
  border-top: 1px solid ${grayShades.g300};
  &:hover {
    background-color: ${grayShades.g200} !important;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledQTitle = styled(QTitle)`
  font-size: ${fontSizes.bigger};
  color: ${props => (props.active ? lightBlue : 'inherit')} !important;
  &:hover {
    cursor: pointer;
    color: ${lightBlue} !important;
  }
`;

const DefaultButton = styled.p`
  font-size: ${fontSizes.extraSmall};
  font-weight: bold;
  padding: ${spacing.xsmall} ${spacing.small};
  border: 1px solid ${props => (props.default ? lightBlue : grayShades.g800)};
  border-radius: 2em;
  background-color: ${props => props.default && lightBlue};
  color: ${props => (props.default ? white : grayShades.g800)};
  cursor: ${props => !props.default && `pointer`};
`;

const Description = styled.p`
  font-size: ${fontSizes.extraSmall};
  text-align: justify;
  color: ${grayShades.g800};
`;

class BenchmarkRow extends React.Component {
  static propTypes = {
    benchmark: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
        settings: PropTypes.array,
      }),
    ).isRequired,
    toggleModal: PropTypes.func.isRequired,
    activeBenchmarkId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  handleMouseEnter() {
    this.setState({ isHovered: true });
  }

  handleMouseLeave() {
    this.setState({ isHovered: false });
  }

  setActiveBenchmark(benchmark) {
    this.props.dispatchSetActiveBenchmark(benchmark);
    this.props.toggleModal();
  }

  render() {
    const { benchmark, t } = this.props;
    return (
      <StyledRow
        onMouseEnter={() => this.handleMouseEnter()}
        onMouseLeave={() => this.handleMouseLeave()}
      >
        <Col xs={6}>
          <TitleDiv>
            <StyledQTitle
              active={this.props.activeBenchmarkId === benchmark.id}
              onClick={() => this.setActiveBenchmark(benchmark)}
            >
              {benchmark.title}
            </StyledQTitle>{' '}
            &nbsp;&nbsp;
            {benchmark.isDefault ? (
              <DefaultButton default>{t('Default')}</DefaultButton>
            ) : (
              this.state.isHovered && (
                <MutationSaveBenchmark
                  variables={{ id: benchmark.id, isDefault: true }}
                >
                  <DefaultButton>{t('Set as default')}</DefaultButton>
                </MutationSaveBenchmark>
              )
            )}
          </TitleDiv>
          <Description>{benchmark.description}</Description>
        </Col>
        {this.state.isHovered && (
          <BenchmarkOptions
            benchmark={benchmark}
            toggleModal={this.props.toggleModal}
          />
        )}
      </StyledRow>
    );
  }
}

function mapStateToProps(state) {
  return {
    activeBenchmarkId: get(state, ['benchmark', 'activeBenchmark', 'id']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetActiveBenchmark: newState => {
      dispatch(setActiveBenchmark(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('button')(BenchmarkRow));
