import styled from 'styled-components';
import { fontSizes } from '../../../styles/abstracts/typography';

export const PANEL_HEADER_TYPES = {
  PANEL: 'panel',
  CARD: 'card',
  MENU: 'menu',
  DEFAULT: 'default',
};

const typeOptions = {
  [PANEL_HEADER_TYPES.PANEL]: { 'text-align': 'center', padding: '0 2rem' },
  [PANEL_HEADER_TYPES.CARD]: { 'text-align': 'center', padding: '0 2rem' },
  [PANEL_HEADER_TYPES.MENU]: { 'text-align': 'left', padding: '0 0.5rem' },
  [PANEL_HEADER_TYPES.DEFAULT]: { 'text-align': 'left', padding: '0 2rem' },
};

function getTypeOption(type) {
  return typeOptions[type] || typeOptions.default;
}

export default styled.div`
  text-align: ${({ type }) => getTypeOption(type)['text-align']};
  font-weight: bold;
  font-size: ${({ secondary }) => (secondary ? fontSizes.default : fontSizes.bigger)};
  padding: ${({ type }) => getTypeOption(type).padding};
  margin-bottom: ${({ contentHeader }) => contentHeader && '0.5rem'};
`;
