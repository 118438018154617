import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { setRangeFilter } from '_Resources/Benchmark/Actions/Filters/setRangeFilter';
import { connect } from 'react-redux';
import {
  FILTER_DIMENSIONS,
  STORE_TO_WATCH,
  TEMPORARY_STORE_TO_WATCH,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import NormalConflictMessage from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/_Components/NormalConflictMessage';
import {
  getCriteriaKeysIDs,
  getCriteriaKeysParents,
  transformCriteriaKeysArray,
} from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/ConflictsUtils';
import { filter, omit, findKey, get, uniq, intersection, find } from 'lodash';
import { opacity, red } from 'styles/abstracts/colors';
import DateRangeConflictMessage from './_Components/DateRangeConflictMessage';
import MessageHeader from './_Components/_.Components/MessagesHeader';

const WarningContainer = styled.div`
  background-color: ${opacity.red};
  border-radius: 1.5rem;
  padding: 1rem;
  margin-bottom: 2rem;
`;

class ConflictMessages extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
    };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  createDateRangeConflictMessage = (conflicts, globalConflict) => {
    const { context, benchmark } = this.props;
    const conflictsArray = globalConflict
      ? filter(
          conflicts,
          conflictinArray => conflictinArray.dimensions.global === true,
        )
      : conflicts;
    return conflictsArray.map(conflict => {
      let period1;
      let period2;
      let contentItem = null;
      if (!globalConflict) {
        const otherItems = omit(conflict.dimensions, [context]);
        const relevantItem = findKey(
          otherItems,
          otherItem => Number.isInteger(otherItem) || !!otherItem,
        );
        contentItem =
          benchmark.activeBenchmark.settings[STORE_TO_WATCH[relevantItem]][
            conflict.dimensions[relevantItem]
          ];
        period1 =
          benchmark[TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField]
            .mergedSettings.dateRange;
        period2 = contentItem.mergedSettings.dateRange;
      } else {
        period1 = get(benchmark, [
          TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField,
          'settings',
          'dateRange',
        ]);
        period2 = benchmark.activeBenchmark.settings.globalSettings.dateRange;
      }

      return (
        <DateRangeConflictMessage
          context={context}
          globalConflict={!!globalConflict}
          contentItem={contentItem}
          isOpen={this.state.collapse}
          period1={period1}
          period2={period2}
        />
      );
    });
  };

  createNormalConflictMessage = (conflicts, globalConflict) => {
    const conflictsArray = globalConflict
      ? filter(
          conflicts,
          conflictinArray => conflictinArray.dimensions.global === true,
        )
      : conflicts;
    const { context, benchmark, type } = this.props;

    return conflictsArray.map(conflict => {
      let filters1;
      let filters2;
      let contentItem = null;
      if (!globalConflict) {
        const otherItems = omit(conflict.dimensions, [context]);
        const relevantItem = findKey(
          otherItems,
          otherItem => Number.isInteger(otherItem) || !!otherItem,
        );
        contentItem =
          benchmark.activeBenchmark.settings[STORE_TO_WATCH[relevantItem]][
            conflict.dimensions[relevantItem]
          ];
        filters1 =
          benchmark[TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField]
            .mergedSettings.filters[type];
        filters2 = contentItem.mergedSettings.filters[type];
      } else {
        filters1 =
          benchmark[TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField]
            .settings.filters[type];
        filters2 = transformCriteriaKeysArray(
          benchmark.activeBenchmark.settings.globalSettings.filters[type],
        );
      }

      const filters1criteriaKeysParents =
        filters1 && uniq(getCriteriaKeysParents(filters1));
      const filters2criteriaKeysParents =
        filters2 && uniq(getCriteriaKeysParents(filters2));
      const arrayOfConflicts = [];
      if (
        intersection(filters1criteriaKeysParents, filters2criteriaKeysParents)
          .length > 0
      ) {
        filters1criteriaKeysParents.forEach(filters1criteriaKeysParent => {
          const relevantCriteriaKeyFilters1 = filter(
            filters1,
            criteriaOption =>
              criteriaOption.filterCategory === filters1criteriaKeysParent,
          );
          const relevantCriteriaKeyFilters2 = filter(
            filters2,
            criteriaOption =>
              criteriaOption.filterCategory === filters1criteriaKeysParent,
          );
          const globalArrayLength = relevantCriteriaKeyFilters2.length;
          const filters1IDs = getCriteriaKeysIDs(relevantCriteriaKeyFilters1);
          const period2IDs = getCriteriaKeysIDs(relevantCriteriaKeyFilters2);
          const mergedIds = intersection(filters1IDs, period2IDs);
          // TODO: refac après debug de nicolas : ne doit pas prendre mergedIds.length !== globalArrayLength mais globalWithoutMerged === 0
          const globalWithoutMerged = filter(
            relevantCriteriaKeyFilters2,
            globalOption => !mergedIds.includes(globalOption.option.id),
          );
          if (mergedIds.length !== globalArrayLength) {
            const conflictToShow = {
              filter: filters1criteriaKeysParent,
              period1IDs: filters1IDs,
              period2IDs,
            };
            arrayOfConflicts.push(conflictToShow);
          }
        });
      }
      return arrayOfConflicts.map(conflictToShow => (
        <NormalConflictMessage
          context={context}
          globalConflict={!!globalConflict}
          isOpen={this.state.collapse}
          contentItem={contentItem}
          arrayConflict={conflictToShow}
        />
      ));
    });
  };

  createConflictBox = conflicts => {
    const { type } = this.props;
    const globalConflict = find(
      conflicts,
      conflict => conflict.dimensions.global === true,
    );

    return conflicts && conflicts.length > 0 ? (
      <WarningContainer>
        <MessageHeader
          onClick={this.toggle}
          collapse={this.state.collapse}
          title={
            type === FILTER_DIMENSIONS.DATE_RANGE
              ? 'Date conflicts detected'
              : 'Filter conflicts detected'
          }
          color={red}
          icon="WARNING"
        />
        {type === FILTER_DIMENSIONS.DATE_RANGE
          ? this.createDateRangeConflictMessage(conflicts, globalConflict)
          : this.createNormalConflictMessage(conflicts, globalConflict)}
      </WarningContainer>
    ) : null;
  };

  render() {
    const { temporaryConflicts } = this.props.benchmark;
    return (
      <div>
        {this.createConflictBox(
          filter(
            temporaryConflicts,
            temporaryConflict => temporaryConflict.type === this.props.type,
          ),
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ benchmark }) => ({
  benchmark,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetRangeFilter: (type, values, mergedValues) => {
    dispatch(setRangeFilter(type, values, mergedValues));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('conflicts')(ConflictMessages));
