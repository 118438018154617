import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  textColor,
  touchedTextColor,
} from '../../../../../../../../styles/abstracts/colors';

const DescriptionDetailsPanel = styled.div`
  font-weight: bold;
  display: flex;
  color: ${textColor};
  padding: 0.75em;
  display: flex;
  & > * {
    flex-grow: 1;
  }
`;

const DescriptionValue = styled.span`
  color: ${touchedTextColor};
`;

const getDateValue = (date, lang) =>
  moment(date)
    .locale(lang)
    .format('LL');

const DescriptionDetails = ({
  lastEditionDate,
  editedBy,
  creationDate,
  createdBy,
  t,
  lang,
}) => (
  <DescriptionDetailsPanel>
    <span>
      {t('Edited on')} :{' '}
      <DescriptionValue>{getDateValue(lastEditionDate, lang)}</DescriptionValue>
    </span>
    <span>
      {t('Edited by')} : <DescriptionValue>{editedBy}</DescriptionValue>
    </span>
    <span>
      {t('Created on')} :{' '}
      <DescriptionValue>{getDateValue(creationDate, lang)}</DescriptionValue>
    </span>
    <span>
      {t('Created by')} : <DescriptionValue>{createdBy}</DescriptionValue>
    </span>
  </DescriptionDetailsPanel>
);

DescriptionDetails.propTypes = {
  lastEditionDate: PropTypes.string.isRequired,
  editedBy: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
};

export default withTranslation('card')(DescriptionDetails);
