import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import QPanel from '../QPanel/QPanel';
import QChevron, { QCHEVRON_ORIENTATION } from '../_Components/QChevron';

const StyledQPanel = styled(QPanel)`
  display: flex;
`;

const Body = styled.div`
  flex-grow: 5;
  text-align: center;
`;
const Navigation = styled.div`
  flex-grow: 1;
`;

const QCarouselSwitcher = ({
  onPrevClicked,
  onNextClicked,
  hideControls,
  children,
  className,
}) => (
  <StyledQPanel className={className}>
    {!hideControls && (
      <Navigation>
        <QChevron
          orientation={QCHEVRON_ORIENTATION.LEFT}
          onClick={onPrevClicked}
        />
      </Navigation>
    )}
    <Body>{children}</Body>
    {!hideControls && (
      <Navigation>
        <QChevron
          orientation={QCHEVRON_ORIENTATION.RIGHT}
          onClick={onNextClicked}
        />
      </Navigation>
    )}
  </StyledQPanel>
);

QCarouselSwitcher.propTypes = {
  onPrevClicked: PropTypes.func,
  onNextClicked: PropTypes.func,
  hideControls: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
};

QCarouselSwitcher.defaultProps = {
  onPrevClicked: _ => {},
  onNextClicked: _ => {},
  hideControls: false,
  children: [],
  className: '',
};

export default QCarouselSwitcher;
