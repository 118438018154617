import React, { Component } from 'react';
import styled from 'styled-components';

import EmotionBreakdownDonutCard from '../../../../../../../../../_Cards/EmotionsCards/EmotionBreakdownDonutCard/EmotionBreakdownDonutCard';
import SatisfactionNpsCard from '../../../../../../../../../_Cards/SatisfactionCards/SatisfactionNpsCard/SatisfactionNpsCard';
import EindexEvolutionWithMentionsCard from '../../../../../../../../../_Cards/EIndexCards/EindexEvolutionWithMentionsCard/EindexEvolutionWithMentionsCard';

const Body = styled.div`
  overflow: scroll;
`;

export default class Statistics extends Component {
  render() {
    return (
      <Body>
        <EmotionBreakdownDonutCard insights />
        <SatisfactionNpsCard />
        <EindexEvolutionWithMentionsCard />
      </Body>
    );
  }
}
