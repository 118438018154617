import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivatedStateFromError(error) {
    console.error('ErrorBoundary error', error);
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ error });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
