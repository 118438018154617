import React, { Component } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import '_Components/Fields/QSelect/style.scss';
import { QSelectProps } from './QSelect.propTypes';

const StyledSelect = styled(Select)``;

const DEFAULT_CONFIG = {
  name: null,
  multi: false,
  placeholder: '',
  rtl: false,
  closeOnSelect: true,
  removeSelected: false,
  searchable: false,
  clearable: true,
  simpleValue: false,
};

class QSelect extends Component {
  static propTypes = QSelectProps;

  static defaultProps = {
    disabled: false,
    config: {},
  };

  prepareSelectConfig() {
    const { config = {} } = this.props;
    return { ...DEFAULT_CONFIG, ...config };
  }

  render() {
    const { value, options, onChange, disabled } = this.props;
    const config = this.prepareSelectConfig();
    return (
      <StyledSelect
        className="selectInput"
        value={value}
        onChange={onChange}
        options={options}
        isDisabled={disabled}
        {...config}
      />
    );
  }
}

export default QSelect;
