import { Col, Row } from 'reactstrap';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withApollo } from 'react-apollo';
import QButton from '_Components/Buttons/QButton/QButton';
import { renewUserApiToken } from '_Resources/Auth/Actions/auth';
import QPanelHeader from '../../../../_Components/Panels/QPanelHeader/QPanelHeader';
import { black } from '../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../styles/abstracts/typography';
import { LabelSettings } from './SectionCard';
import RENEW_API_TOKEN_QUERY from '../graphql/renewApiToken';

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 1rem;
  margin: 0 1rem;
`;
const TitleCol = styled(Col)`
  line-height: 3rem;
  padding-left: 0 !important;
  padding-bottom: 1rem;
`;

const StyledText = styled.div`
  text-align: left;
  margin-bottom: 2rem;
  font-weight: normal;
  display: inline;
  font-size: ${fontSizes.extraSmall};
`;

const APIIntegrationSection = ({
  isUserAdmin,
  t,
  client,
  apiToken,
  dispatchRenewUserApiToken,
}) => {
  const renewApiToken = async () => {
    await client
      .mutate({ mutation: RENEW_API_TOKEN_QUERY })
      .then(({ data }) => {
        if (data) {
          dispatchRenewUserApiToken(data?.renewApiToken.account.apiToken);
        }
      });
  };

  return (
    <StyledQPanelHeader>
      <Row>
        <TitleCol>{t('API implementation')}</TitleCol>
      </Row>
      <LabelSettings
        style={{ marginBottom: '2rem', display: 'block', textAlign: 'center' }}
      >
        {apiToken && (
          <p>
            {t('API token')} : {apiToken}
          </p>
        )}
        {!apiToken && (
          <p>
            {t('API token activation guide')}
            <a
              href="mailto: support-team@qemotion.com"
              style={{ color: 'inherit', textDecoration: 'underline' }}
            >
              support-team@qemotion.com
            </a>
          </p>
        )}
      </LabelSettings>
      {isUserAdmin && apiToken && (
        <div style={{ marginBottom: '2rem', textAlign: 'center' }}>
          <QButton onClick={() => renewApiToken()}>
            {t('API token regenerate')}
          </QButton>
        </div>
      )}
      <StyledText
        dangerouslySetInnerHTML={{
          __html: t('API implemented', {
            support: `<a href="mailto:support-team@qemotion.com"><span style="text-decoration: underline; color: ${black};">support-team@qemotion.com</span></a>`,
            apiDocumentationLinkStyle: `"text-decoration: underline; color: ${black};"`,
          }),
        }}
      />
    </StyledQPanelHeader>
  );
};

APIIntegrationSection.propTypes = {
  isUserAdmin: PropTypes.bool,
  t: PropTypes.func.isRequired,
  client: PropTypes.isRequired,
  apiToken: PropTypes.string,
  dispatchRenewUserApiToken: PropTypes.func,
};

APIIntegrationSection.defaultProps = {
  isUserAdmin: false,
  apiToken: '',
  dispatchRenewUserApiToken: () => {},
};

const mapStateToProps = state => ({
  apiToken: state.auth.user.account.apiToken,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatchRenewUserApiToken: token => {
      dispatch(renewUserApiToken(token));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApollo(withTranslation('card')(APIIntegrationSection)));
