import React from 'react';
import { connect } from 'react-redux';
import { map, omit, get } from 'lodash';
import { withTranslation } from 'react-i18next';
import '../../style.scss';
import styleVars from 'styles/abstracts/_variables.module.scss';
import { capitalize } from '_Utils/strings/stringsUtils';
import { positiveEmotions, negativeEmotions, neutralEmotions, sentimentsList } from 'constants/index';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import styled from 'styled-components';
import { QemotionSentimentThemeColors } from 'styles/abstracts/colors';
import { QemotionTheme } from '../../Utils/qemotionTheme';
import VerbatimListModal from '../../../_Components/Modals/VerbatimListModal/VerbatimListModal';

const ReactEcharts = typeof document !== 'undefined' && require('echarts-for-react').default;

const AnalysisAxisSwitcherContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

class CDonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      emotionTitleModal: '',
      emotionMentionsModal: 0,
      verbatims: null,
      analysisAxis: 'sentiment',
    };

    this.toggleModaleVerbatim = this.toggleModaleVerbatim.bind(this);
  }

  componentWillMount() {
    const { sentimentAxis } = this.props;
    const { analysisAxis } = this.state;
    if (typeof document !== 'undefined') {
      const echarts = require('echarts');
      if (sentimentAxis || analysisAxis === 'sentiment') {
        const QemotionSentimentTheme = { ...QemotionTheme, color: QemotionSentimentThemeColors };
        echarts.registerTheme('QemotionSentimentTheme', QemotionSentimentTheme);
      } else {
        echarts.registerTheme('QemotionTheme', QemotionTheme);
      }
    }
  }

  toggleModaleVerbatim(param) {
    this.setState({
      modal: !this.state.modal,
      emotionTitleModal: param.name,
      emotionMentionsModal: param.value,
    });
  }

  getEmphasisLabelColor() {
    const { reportingMode, benchmarkMode, labelColor, benchmarkDonutAnalysisAxisToDisplay } = this.props;
    if ((benchmarkMode && benchmarkDonutAnalysisAxisToDisplay === 'sentiment') || reportingMode) {
      return labelColor || styleVars.white;
    }
    return labelColor || styleVars.black;
  }

  getLabelSettings() {
    const { reportingMode, landingPageMode, benchmarkMode, labelColor, t, richText, settings } = this.props;
    const { label } = settings || {};

    const classicLabel = label === 'classic';
    const externalLabel = label === 'external';

    const getPosition = () => {
      if (benchmarkMode || (reportingMode && classicLabel)) return 'inside';
      if (reportingMode && externalLabel) return 'outside';
      return 'center';
    };

    if (landingPageMode) {
      return {
        label: {
          show: true,
          textStyle: {
            fontSize: '12',
            fontWeight: 'bold',
            lineHeight: 16,
          },
          color: labelColor || styleVars.black,
          formatter(a) {
            return `${capitalize(t(a.name))}: ${Math.round(a.percent * 10) / 10} %`;
          },
        },
        labelLine: {
          show: true,
        },
      };
    }
    return {
      label: {
        show: !!(reportingMode || benchmarkMode),
        position: getPosition(),
        formatter(a) {
          if (reportingMode && externalLabel) return `${Math.round(a.percent * 10) / 10} %`;
          return `${Math.round(a.percent * 10) / 10} %`;
        },
        color: labelColor || styleVars.black,
        textStyle:
          reportingMode && externalLabel
            ? {
                fontSize: 10,
                fontWeight: 'bold',
                lineHeight: 10,
              }
            : {
                fontSize: 16,
                fontWeight: 'bold',
                color: 'white',
              },
      },
      emphasis: reportingMode
        ? undefined
        : {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 'bold',
              color: this.getEmphasisLabelColor(),
              rich: richText || {
                legend: {
                  fontSize: 16,
                  fontWeight: 'bold',
                },
                percent: {
                  fontSize: 28,
                  fontWeight: 'lighter',
                  lineHeight: 20,
                },
              },
              formatter(a) {
                return `{legend|${capitalize(t(a.name))}\n} {percent|\n${Math.round(a.percent * 10) / 10} %}`;
              },
            },
          },
    };
  }

  getRadius() {
    const { reportingMode, landingPageMode, benchmarkMode } = this.props;
    if (reportingMode || landingPageMode) return ['30%', '60%'];
    if (benchmarkMode) return ['30%', '85%'];
    return ['30%', '80%'];
  }

  getOption(data, sentimentAxis) {
    const { reportingMode, landingPageMode, t, settings } = this.props;
    const { legendShow, label } = settings || {};
    const classicLabel = label === 'classic';
    const externalLabel = label === 'external';
    const collectionOfEmotion = map(omit(data, '__typename'), (value, prop) => ({ name: prop, value }));
    const collectionOfSentiment = [
      {
        name: 'positive',
        value: Object.keys(positiveEmotions).reduce((sum, key) => {
          const value = data[key];
          return data[key] ? sum + value : sum;
        }, 0),
      },
      {
        name: 'neutral',
        value: Object.keys(neutralEmotions).reduce((sum, key) => {
          const value = data[key];
          return data[key] ? sum + value : sum;
        }, 0),
      },
      {
        name: 'negative',
        value: Object.keys(negativeEmotions).reduce((sum, key) => {
          const value = data[key];
          return data[key] ? sum + value : sum;
        }, 0),
      },
    ];
    const totalValues = collectionOfEmotion.reduce((a, b) => a + b.value, 0);
    const collectionOfEmotionWithPercentage = map(omit(data, '__typename'), (value, prop) => {
      const percent = ((value / totalValues) * 100).toFixed(2);
      return { name: prop, value, percent };
    });
    const collectionOfSentimentWithPercentage = collectionOfSentiment.map(sentiment => {
      const percent = ((sentiment.value / totalValues) * 100).toFixed(2);
      return { name: sentiment.name, value: sentiment.value, percent };
    });
    return {
      tooltip: {
        trigger: 'item',
        formatter: '<h6>{a}</h6> <br/>{b}: {c} ({d}%)',
      },
      legend:
        reportingMode && legendShow
          ? {
              bottom: '0%',
              left: 'left',
              formatter(name) {
                const dataToReturn = sentimentAxis
                  ? collectionOfSentimentWithPercentage.find(element => element.name === name)
                  : collectionOfEmotionWithPercentage.find(element => element.name === name);
                return `${t(capitalize(dataToReturn.name))}`;
              },
            }
          : undefined,
      series: [
        {
          name: 'Emotion breakdown',
          type: 'pie',
          radius: this.getRadius(),
          avoidLabelOverlap: true,
          center: reportingMode && legendShow ? ['50%', '35%'] : ['50%', '50%'],
          tooltip: {
            show: false,
          },
          minAngle: 0,
          ...this.getLabelSettings(),
          data: sentimentAxis ? collectionOfSentiment : collectionOfEmotion,
          labelLine: {
            show: (reportingMode && externalLabel) || landingPageMode,
            length: (reportingMode && externalLabel) ? 4 : 15,
            length2: (reportingMode && externalLabel) ? 2 : 15,
            lineStyle: {
              width: 1,
              type: 'solid',
              opacity: 1,
            },
          },
        },
      ],
    };
  }

  getSelectedEmotions(emotionTitleModal) {
    if (Object.keys(sentimentsList).includes(emotionTitleModal)) {
      return sentimentsList[emotionTitleModal];
    }
    return [emotionTitleModal.toUpperCase()];
  }

  setVariables() {
    const { emotionTitleModal } = this.state;
    const variables = {
      projectId: get(this.props, ['filters', 'projectId']) || get(this.props, ['filterKeys', 'projectId']),
      range: {
        startDate: get(this.props, ['filters', 'range', 'startDate']) || get(this.props, 'filterKeys.range.startDate'),
        endDate: get(this.props, ['filters', 'range', 'endDate']) || get(this.props, 'filterKeys.range.endDate'),
      },
      criteriaKeys: get(this.props, ['filters', 'criteriaKeys']) || get(this.props, ['filterKeys', 'criteriaKeys']),
      thematics: get(this.props, ['filters', 'thematics']) || get(this.props, ['filterKeys', 'thematics']),
      selectedEmotions: emotionTitleModal && this.getSelectedEmotions(emotionTitleModal),
      sort: 'DESC',
      keywords: get(this.props, ['filterKeys', 'texts']) ? get(this.props, ['filterKeys', 'texts'])[0]?.values : [],
    };
    return variables.selectedEmotions && variables.selectedEmotions.length > 0 ? variables : [];
  }

  getAnalysisAxisSwitcherOptions() {
    const { t } = this.props;
    return [
      {
        id: 'sentiment',
        label: t('Sentiment'),
      },
      {
        id: 'emotion',
        label: t('Emotion'),
      },
    ];
  }

  onAnalysisAxisOptionSelected = analysisAxis => {
    this.setState({ analysisAxis });
  };

  getContainerDivStyle() {
    const { reportingMode, benchmarkMode, landingPageMode } = this.props;
    if (reportingMode || benchmarkMode) {
      return { height: '100%', width: '100%' };
    }
    if (landingPageMode) {
      return { minHeight: '300px', width: '100%' };
    }
    return { height: '85%', width: '100%' };
  }

  getDonutChartStyle() {
    const { landingPageMode, windowHeight } = this.props;
    if (landingPageMode) {
      if (windowHeight && windowHeight > 1200) {
        return { minHeight: '500px', height: '100%', width: '100%' };
      }
      return { minHeight: '300px', height: '100%', width: '100%' };
    }
    return { height: '100%', width: '100%' };
  }

  getSentiment() {
    const { analysisAxis } = this.state;
    const {
      sentimentAxis,
      landingPageMode,
      benchmarkMode,
      benchmarkDonutAnalysisAxisToDisplay,
      reportingMode,
      settings,
    } = this.props;
    if (landingPageMode && sentimentAxis) {
      return true;
    }
    if (benchmarkMode) {
      if (benchmarkDonutAnalysisAxisToDisplay === 'emotions') return false;
      return true;
    }
    if (reportingMode) {
      if (settings && settings.analysisAxis && settings.analysisAxis === 'sentiment') return true;
      return false;
    }
    if (!landingPageMode && analysisAxis === 'sentiment') {
      return true;
    }
    return false;
  }

  render() {
    const onEvents = {
      click: param => {
        this.toggleModaleVerbatim(param);
      },
    };
    const echarts = require('echarts');
    const {
      numberOfMentions,
      title,
      className,
      data: { emotions },
      t,
      globalResultsMode,
    } = this.props;
    const { emotionTitleModal, verbatims, modal, analysisAxis } = this.state;
    const sentiment = this.getSentiment();
    const propsModal = {
      numberOfMentions,
      emotions,
      selectedEmotion: emotionTitleModal,
      titleSecondPart: t(emotionTitleModal),
      title: sentiment ? 'Sentiment breakdown' : title,
      toggle: this.toggleModaleVerbatim,
      className,
      classBody: 'containVerbatimbubble',
      data: verbatims,
      modal,
    };
    if (sentiment) {
      const QemotionSentimentTheme = { ...QemotionTheme, color: QemotionSentimentThemeColors };
      echarts.registerTheme('QemotionSentimentTheme', QemotionSentimentTheme);
    } else {
      echarts.registerTheme('QemotionTheme', QemotionTheme);
    }
    return (
      <div style={this.getContainerDivStyle()}>
        {globalResultsMode && (
          <AnalysisAxisSwitcherContainer>
            <span className="mx-2">
              <QSwitcher
                selected={analysisAxis}
                options={this.getAnalysisAxisSwitcherOptions()}
                onOptionSelected={this.onAnalysisAxisOptionSelected}
                borderradius="2em"
                padding="0.25rem 1rem"
                display="inline-flex"
                width="auto"
              />
            </span>
          </AnalysisAxisSwitcherContainer>
        )}
        {ReactEcharts ? (
          <ReactEcharts
            option={this.getOption(emotions, sentiment)}
            style={this.getDonutChartStyle()}
            className="react_for_echarts"
            onEvents={onEvents}
            theme={sentiment ? 'QemotionSentimentTheme' : 'QemotionTheme'}
          />
        ) : (
          ''
        )}
        <VerbatimListModal {...propsModal} filters={this.setVariables()} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterKeys: state.filters.filterKeys,
    benchmarkDonutAnalysisAxisToDisplay: get(state, ['benchmark', 'donutAnalysisAxisToDisplay']),
    userAnalysisAxis: get(state, ['auth', 'user', 'analysisAxis']),
  };
}

export default connect(mapStateToProps)(withTranslation('emotion')(CDonutChart));
