import gql from 'graphql-tag';

const mutation = gql`
  mutation updateAlertConfiguration(
    $alertConfigurationId: Int!
    $conditions: Json!
    $emails: [String!]!
    $alertConfigurationName: String!
    $active: Boolean!
    $frequency: String!
  ) {
    updateAlertConfiguration(
      alertConfigurationId: $alertConfigurationId
      conditions: $conditions
      emails: $emails
      alertConfigurationName: $alertConfigurationName
      active: $active
      frequency: $frequency
    ) {
      active
      alertConfigurationId
      alertConfigurationName
      conditions
      emails
      errors
      projectId
    }
  }
`;

export default mutation;
