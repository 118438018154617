export function hex2rgbaConvert(hex, opacity) {
  const normalizedHex = hex.replace('#', '');
  const r = parseInt(normalizedHex.substring(0, normalizedHex.length / 3), 16);
  const g = parseInt(
    normalizedHex.substring(normalizedHex.length / 3, (2 * hex.length) / 3),
    16,
  );
  const b = parseInt(
    normalizedHex.substring(
      (2 * normalizedHex.length) / 3,
      (3 * hex.length) / 3,
    ),
    16,
  );
  return `rgba(${r},${g},${b}, ${opacity / 100})`;
}
