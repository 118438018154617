import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation, withApollo } from 'react-apollo';
import deleteMutation from '_Cards/SettingsCards/ProjectDetailsCard/graphql/deleteReviewshakeSources';
import QInput from '_Components/Fields/QInput/QInput';
import QModal from '_Components/Modals/QModal/QModal';
import QButton from '_Components/Buttons/QButton/QButton';
import { grayShades } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import queryProjectDetails from 'Routes/Settings/ProjectDetails/graphql/getProjectDetails';

const FooterContainer = styled.div`
  & button:last-child {
    margin-left: 1em;
  }
`;

const InputSubTitle = styled.span`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  font-weight: bolder;
`;

const StyledInput = styled(QInput)`
  width: 100% !important;
`;

const DeleteSourceModal = ({
  source,
  projectId,
  slug,
  setSources,
  openModal,
  isModalOpen,
  ids,
  t,
  client,
}) => {
  const [verification, setVerification] = useState('');

  const [deleteReviewshakeSources] = useMutation(deleteMutation, {
    update: (cache, { data: { deleteReviewshakeSources } }) => {
      try {
      const projectDetailsConfig = cache.readQuery({
        query: queryProjectDetails,
        variables: {
          id: projectId,
          slug,
        },
      }).getProjectDetails;
      const cloneSources = projectDetailsConfig.sources;
      const newSources = cloneSources.filter(src =>
        deleteReviewshakeSources.id.includes(src.id),
      );
      setSources(newSources);
      cache.writeQuery({
        query: queryProjectDetails,
        variables: { id: projectId, slug },
        data: {
          getProjectDetails: {
            sources: newSources,
            ...projectDetailsConfig,
          },
        },
      });
    } catch(e) {
      client.query({
        query: queryProjectDetails,
        variables: {
          id: projectId,
          slug,
        },
      }).then(({data: results}) => {
      const cloneSources = results?.getProjectDetails?.sources;
      const newSources = cloneSources.filter(src =>
        deleteReviewshakeSources.id.includes(src.id),
      );
      setSources(newSources);
      cache.writeQuery({
        query: queryProjectDetails,
        variables: { id: projectId, slug },
        data: {
          getProjectDetails: {
            sources: newSources,
            ...results?.getProjectDetails,
          },
        },
      });
      })
    }
  },
  refetchQueries: [{query: queryProjectDetails, variables: {id: projectId, slug}}],
  awaitRefetchQueries: true,
  });

  const modalFooter = (
    <FooterContainer>
      <QButton
        outline
        bgColor="grayShades.g800"
        onClick={() => openModal(!isModalOpen)}
      >
        {t('button:No, it is a mistake')}
      </QButton>
      <QButton
        bgColor="red"
        iconPosition="left"
        icon="TRASH"
        onClick={() => {
          deleteReviewshakeSources({
            variables: {
              id: ids,
              projectId,
            },
          });
          openModal(!isModalOpen);
        }}
        disabled={verification !== (source.profileName || source)}
      >
        {t('button:Delete')}
      </QButton>
    </FooterContainer>
  );

  return (
    <QModal
      title={t(`Definitely delete XXX ?`, {
        projectName: source.profileName || source,
      })}
      subtitle="We will not longer process this source's data if you delete this source"
      isOpen={isModalOpen}
      onClose={() => openModal(!isModalOpen)}
      footer={modalFooter}
      size="md"
      config={{mentions: false, date: false}}
    >
      <InputSubTitle>
        {t('To confirm, please enter the name of the source')}
      </InputSubTitle>
      <StyledInput
        onChange={e => setVerification(e.target.value)}
        onPaste={e => e.preventDefault()}
      />
    </QModal>
  );
};

export default withApollo(DeleteSourceModal);
