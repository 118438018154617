/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const TemplatePropTypes = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node.isRequired,
]);

export const BasePropTypes = {
  children: TemplatePropTypes.isRequired,
  className: PropTypes.string,
};
