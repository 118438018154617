import { loadComponent } from '../../_Utils/routeComponent';

export default [
  {
    i: 'MentionBadgeCard',
    component: 'MentionBadgeCard',
    data: {
      title: 'Mention count',
      titleSecondPart: '',
      color: '#592ea0',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: 8,
        h: 8,
        minW: loadComponent('MentionBadgeCard', 'breakpoints').xl.minW,
        minH: loadComponent('MentionBadgeCard', 'breakpoints').xl.minH,
        x: 0,
        y: 0,
      },
      lg: {
        w: 8,
        h: 8,
        minW: loadComponent('MentionBadgeCard', 'breakpoints').lg.minW,
        minH: loadComponent('MentionBadgeCard', 'breakpoints').lg.minH,
        x: 0,
        y: 0,
      },
      md: {
        w: loadComponent('MentionBadgeCard', 'breakpoints').md.w,
        h: 8,
        minW: loadComponent('MentionBadgeCard', 'breakpoints').md.minW,
        minH: loadComponent('MentionBadgeCard', 'breakpoints').md.minH,
        x: 0,
        y: 0,
      },
      sm: {
        w: loadComponent('MentionBadgeCard', 'breakpoints').sm.w,
        h: 8,
        minW: loadComponent('MentionBadgeCard', 'breakpoints').sm.minW,
        minH: loadComponent('MentionBadgeCard', 'breakpoints').sm.minH,
        x: 0,
        y: 0,
      },
      xs: {
        w: loadComponent('MentionBadgeCard', 'breakpoints').xs.w,
        h: loadComponent('MentionBadgeCard', 'breakpoints').xs.h,
        minW: loadComponent('MentionBadgeCard', 'breakpoints').xs.minW,
        minH: loadComponent('MentionBadgeCard', 'breakpoints').xs.minH,
        x: 0,
        y: 0,
      },
    },
  },
  {
    i: 'EindexTemperatureCard',
    component: 'EindexTemperatureCard',
    data: {
      title: 'Average E-index',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: 8,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').xl.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').xl.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').xl.minH,
        x: 8,
        y: 0,
      },
      lg: {
        w: 8,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').lg.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').lg.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').lg.minH,
        x: 8,
        y: 0,
      },
      md: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').md.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').md.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').md.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').md.minH,
        x: 4,
        y: 0,
      },
      sm: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').sm.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').sm.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').sm.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').sm.minH,
        x: 2,
        y: 0,
      },
      xs: {
        w: loadComponent('EindexTemperatureCard', 'breakpoints').xs.w,
        h: loadComponent('EindexTemperatureCard', 'breakpoints').xs.h,
        minW: loadComponent('EindexTemperatureCard', 'breakpoints').xs.minW,
        minH: loadComponent('EindexTemperatureCard', 'breakpoints').xs.minH,
        x: 4,
        y: 0,
      },
    },
  },
  {
    i: 'RadarAndHorizBarCard',
    component: 'RadarAndHorizBarCard',
    data: {
      title: 'Staff skills assessment',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('RadarAndHorizBarCard', 'breakpoints').xl.w,
        h: loadComponent('RadarAndHorizBarCard', 'breakpoints').xl.h,
        minW: loadComponent('RadarAndHorizBarCard', 'breakpoints').xl.minW,
        minH: loadComponent('RadarAndHorizBarCard', 'breakpoints').xl.minH,
        x: 0,
        y: 8,
      },
      lg: {
        w: loadComponent('RadarAndHorizBarCard', 'breakpoints').lg.w,
        h: loadComponent('RadarAndHorizBarCard', 'breakpoints').lg.h,
        minW: loadComponent('RadarAndHorizBarCard', 'breakpoints').lg.minW,
        minH: loadComponent('RadarAndHorizBarCard', 'breakpoints').lg.minH,
        x: 0,
        y: 8,
      },
      md: {
        w: loadComponent('RadarAndHorizBarCard', 'breakpoints').md.w,
        h: loadComponent('RadarAndHorizBarCard', 'breakpoints').md.h,
        minW: loadComponent('RadarAndHorizBarCard', 'breakpoints').md.minW,
        minH: loadComponent('RadarAndHorizBarCard', 'breakpoints').md.minH,
        x: 0,
        y: 8,
      },
      sm: {
        w: loadComponent('RadarAndHorizBarCard', 'breakpoints').sm.w,
        h: loadComponent('RadarAndHorizBarCard', 'breakpoints').sm.h,
        minW: loadComponent('RadarAndHorizBarCard', 'breakpoints').sm.minW,
        minH: loadComponent('RadarAndHorizBarCard', 'breakpoints').sm.minH,
        x: 0,
        y: 8,
      },
      xs: {
        w: loadComponent('RadarAndHorizBarCard', 'breakpoints').xs.w,
        h: loadComponent('RadarAndHorizBarCard', 'breakpoints').xs.h,
        minW: loadComponent('RadarAndHorizBarCard', 'breakpoints').xs.minW,
        minH: loadComponent('RadarAndHorizBarCard', 'breakpoints').xs.minH,
        x: 0,
        y: 8,
      },
    },
  },
  {
    i: 'MentionPreviewPerEmotions',
    component: 'MentionPreviewPerEmotions',
    data: {
      title: 'Mention preview per emotions',
      titleSecondPart: '',
      dropDownOptions: ['EXPORT'],
    },
    breakpoints: {
      xl: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xl.minH,
        x: 0,
        y: 32,
      },
      lg: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').lg.minH,
        x: 0,
        y: 32,
      },
      md: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').md.minH,
        x: 0,
        y: 32,
      },
      sm: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').sm.minH,
        x: 0,
        y: 32,
      },
      xs: {
        w: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.w,
        h: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.h,
        minW: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.minW,
        minH: loadComponent('MentionPreviewPerEmotions', 'breakpoints').xs.minH,
        x: 0,
        y: 32,
      },
    },
  },
];
