import React from 'react';

const ReactEcharts =
  typeof document !== 'undefined' && require('echarts-for-react').default;

class CGaugeChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getOption = () => ({
    series: [
      {
        name: 'Gauge',
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        radius: '100%',
        splitNumber: 10,
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: [[this.props.value / 100, '#592EA0'], [1, '#EDF0F7']],
            width: 32,
          },
        },

        itemStyle: {
          color: '#BFC0D3',
        },
        pointer: {
          width: 16,
          length: '50%',
        },
        detail: {
          formatter: '{value} %',
          offsetCenter: [0, '60%'],
          textStyle: {
            fontSize: 24,
            color: '#2E096B',
            fontWeight: 'bold',
          },
        },
        title: {
          show: false,
        },
        data: [
          {
            value: this.props.value,
          },
        ],
      },
    ],
  });

  render() {
    return (
      <div style={{ height: '100%' }}>
        {ReactEcharts ? (
          <ReactEcharts
            option={this.getOption()}
            style={{ height: '100%', width: '100%' }}
            className="react_for_echarts"
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default CGaugeChart;
