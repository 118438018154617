import React from 'react';
import styled from 'styled-components';
import { grayShades, black, green, red, orange, opacity } from 'styles/abstracts/colors';
import Icon from '_Components/Icons/Icon';
import { withTranslation } from 'react-i18next';

const GraphContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const GraphItem = styled.div<{ topMargin: number }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 10em;
  width: 5em;
  // margin-top: calc(0em + ${({ topMargin }) => topMargin}em);
  color: ${grayShades.g800};
  transition: all 0.5s;
  &:not(:last-child) {
    margin-right: 5em;
  }
`;

const GraphBar = styled.div<{
  height: number;
  opacity: number;
  item: string;
  max: number;
  greenLimit: number;
  backgroundColor: string;
}>`
  width: 70%;
  height : 6em;
  background-color: ${({backgroundColor}) => backgroundColor};
  border-radius : 2.5em;
  transition: all 0.5s;
  // position: relative;
`;

const GraphItemLabel = styled.div`
  font-size: 0.8em;
  font-weight: 700;
  margin-top: 1em;
  // position: absolute;
  // top: 37%;
  // opacity: 1;
`;

const GraphItemNoteLabel = styled.div<{
  color: string;
}>`
  width: 100%;
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-top: 0.5em;
`;

const GraphItemNoteValue = styled.span<{
  color: string;
}>`
  color: ${({ color }) => color};
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0.5em;
`;

const StyledIcon = styled(Icon)<{color: string}>`
  // opacity : 1;
  & path {
    stroke: ${({ color }) => color};
  }
`;

const getTopMargin = query => {
  const tab = [];
  Object.keys(query).map(item => {
    tab.push(query[item]?.percentage / 100);
  });
  return -(1 - Math.max(...tab)) * 20;
};

const getGraphItemLabel = (kpiValue) => {
  switch (kpiValue) {
    case "dectractors" :
      return "EMOTION_ANGER"
    case "promoters" :
      return "EMOTION_HAPPINESS"
    case "neutrals" :
      return "EMOTION_CALM"
    default : "EMOTION_CALM"
  }
};

const getGraphItemColor = (kpiValue) => {
  switch (kpiValue) {
    case "dectractors" :
      return {
        "barColor": opacity.red,
        "smileyColor": red,
      }
    case "promoters" :
      return  {
        "barColor" : opacity.green,
        "smileyColor" : green,
      }
    case "neutrals" :
      return  {
        "barColor" : opacity.orange,
        "smileyColor" : orange,
      }
    default :
      return {
        "barColor" : opacity.green,
        "smileyColor" : green,
      }
  }
};

const getNPSOrderedArray = (dataObject) => {
  let NPSOrderedArray = dataObject.sort((a, b) => (a.kpiValue < b.kpiValue) ? -1 : ((b.kpiValue > a.kpiValue) ? 1 : 0));

  return NPSOrderedArray;
};

const KPIGraph = ({ data, t }) => {
  const NPSOrderedArray = getNPSOrderedArray(data?.verbatimsPerKpi);

  return (
    <GraphContainer className="graphContainer">
      {NPSOrderedArray.map(item => {
        return (
          <GraphItem
            className="graphItem"
            topMargin={getTopMargin(data?.verbatimsPerKpi)}
            key={item}
          >
            <GraphBar
              height={item.percentage / 100}
              opacity={0.2}
              backgroundColor={getGraphItemColor(item.kpiValue).barColor}
            >
              <GraphItemLabel><Icon icon={getGraphItemLabel(item.kpiValue)} size={30} color={getGraphItemColor(item.kpiValue).smileyColor }/></GraphItemLabel>
              <GraphItemNoteLabel color={getGraphItemColor(item.kpiValue).smileyColor}>{`${item.percentage} %`}</GraphItemNoteLabel>
            </GraphBar>
            <GraphItemNoteValue color={getGraphItemColor(item.kpiValue).smileyColor}>{t(item.kpiValue)}</GraphItemNoteValue>
          </GraphItem>
        );
      })}
    </GraphContainer>
  );
};

export default withTranslation('card')(KPIGraph);
