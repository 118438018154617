import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { pdfTransformation } from './transformations/pdf';
import { prepareGenerateSubtitles } from '../../../helpers/subtitleHelper';
import { exportTaskRunner } from './transformations/exportTaskRunner';
import { updateExportCounter } from '../../../../../../../_Resources/Reporting/Actions/Export/updateCounter';

// TODO: move this propType along with
export const REPORT_TYPES = {
  PDF: 'pdf',
  CSV: 'csv',
};

const TRANSFORMATIONS = {
  [REPORT_TYPES.PDF]: pdfTransformation,
};

class ReportExportProvider extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    reportSettings: PropTypes.any.isRequired,
    pages: PropTypes.any.isRequired,
    globalSettings: PropTypes.any.isRequired,
    type: PropTypes.oneOf(Object.values(REPORT_TYPES)).isRequired,
    dispatchUpdateExportCounter: PropTypes.func.isRequired,
  };

  generateSubtitles = null;

  constructor({ t, lang }) {
    super();
    this.generateSubtitles = prepareGenerateSubtitles(t, lang);
  }

  notifyPageProgress = pageIndex => {
    const { dispatchUpdateExportCounter } = this.props;
    dispatchUpdateExportCounter(pageIndex);
  };

  scheduleTask = memoizeOne((type, reportSettings, pages, globalSettings) => {
    const coverFooterLabel = this.props.t('Generated thanks to');
    const task = () =>
      TRANSFORMATIONS[type](
        this.generateSubtitles,
        coverFooterLabel,
        reportSettings,
        pages,
        globalSettings,
        this.notifyPageProgress,
      );
    exportTaskRunner.scheduleExportTask(task);
  });

  prepareTransform() {
    const { type, reportSettings, pages, globalSettings } = this.props;
    this.scheduleTask(type, reportSettings, pages, globalSettings);
  }

  render() {
    const { render } = this.props;
    return render(() => this.prepareTransform());
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchUpdateExportCounter: pageIndex =>
    dispatch(updateExportCounter(pageIndex)),
});

const mapStateToProps = ({ auth: { user } }) => ({
  lang: user.lang,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('reporting')(ReportExportProvider));
