export const CONTEXT = {
  COLUMN: 'column',
  CARD: 'card',
  GLOBAL: 'global',
  CONFLICTS: 'conflicts',
};

export const TEMPORARY_STORE_TO_WATCH = {
  [CONTEXT.COLUMN]: {
    temporarySettingsField: 'temporaryColumn',
    temporaryIDField: 'currentColumnIndex',
  },
  [CONTEXT.CARD]: {
    temporarySettingsField: 'temporaryCard',
    temporaryIDField: 'currentCardIndex',
  },
  [CONTEXT.CONFLICTS]: {
    temporarySettingsField: 'temporaryConflicts',
    temporaryIDField: 'currentConflicts',
  },
};

export const STORE_TO_WATCH = {
  [CONTEXT.COLUMN]: 'columns',
  [CONTEXT.CARD]: 'cards',
  [CONTEXT.CONFLICTS]: 'conflicts',
};

export const CONTEXT_TO_CHECK_FOR_CONFLICT = {
  [CONTEXT.COLUMN]: {
    storeToWatch: [STORE_TO_WATCH[CONTEXT.CARD]],
    contextToWatch: [CONTEXT.CARD],
  },
  [CONTEXT.CARD]: {
    storeToWatch: [STORE_TO_WATCH[CONTEXT.COLUMN]],
    contextToWatch: [CONTEXT.COLUMN],
  },
  [CONTEXT.GLOBAL]: {
    storeToWatch: [
      STORE_TO_WATCH[CONTEXT.COLUMN],
      STORE_TO_WATCH[CONTEXT.CARD],
    ],
    contextToWatch: [CONTEXT.COLUMN, CONTEXT.CARD],
  },
};

export const FILTER_DIMENSIONS = {
  DATE_RANGE: 'dateRange',
  THEMATIC: 'thematic',
  NORMAL: 'normal',
};
