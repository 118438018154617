import React from 'react';
import { groupBy, uniq, flatten } from 'lodash';
import PieChart from '../../../../_Charts/BreakdownCharts/PieChart/PieChart';
import NoDataMessage from '../../../../_Components/NoDataMessage/NoDataMessage';
import TreemapChart from '../../../../_Charts/BreakdownCharts/TreemapChart/TreemapChart';
import RiverChart from '../../../../_Charts/BreakdownCharts/PieChart/RiverChart/RiverChart';

export const flat = (array: any) => {
  let result: Array<any> = [];
  array.forEach((a: any) => {
    result.push(a);
    if (Array.isArray(a.subThematics || a.subSubThematics || a.children)) {
      result = result.concat(flat(a.subThematics || a.subSubThematics || a.children));
    }
  });
  return result;
};

export const getPieChartData = (
  onlySelectedCategories: boolean,
  rawData: any,
  data: any,
  level: string,
  subFilterToDisplay: string | null,
  filterToDisplay: string,
  verbatimsWithoutThematicCount: number,
  t: (arg0: string) => any,
  commentsWithoutCategoriesLegendIndex: string | undefined,
  isCaptionedData: boolean,
  categoryJoinSpecify: string | null = null,
) => {
  const filteredData = (!onlySelectedCategories ? flat(rawData) : data).filter((d: any) => {
    if (level === 'thematics') {
      return d.subThematics;
    }
    if (level === 'subthematics') {
      return d.subSubThematics;
    }
    return !d.subThematics && !d.subSubThematics;
  });
  const values = filteredData.map((d: any) => {
    return subFilterToDisplay
      ? d?.thematicsOverviewDetails?.[filterToDisplay]?.[subFilterToDisplay]?.byMentionCount || 0
      : d?.thematicsOverviewDetails[filterToDisplay];
  });
  const totalValue = values.length ? values?.reduce((a: any, b: any) => a + b) : 1;

  return [
    ...filteredData.map((d: any, ind: number) => {
      const hierarchy = {
        thematic: null,
        subThematic: null,
        subSubThematic: null,
        joinSpecify: 'and',
      };
      if (level === 'thematics') {
        hierarchy.thematic =
          isCaptionedData && d?.thematicsOverviewDetails.index
            ? d?.thematicsOverviewDetails.index
            : t(d?.thematicsOverviewDetails.name);
      } else if (level === 'subthematics') {
        hierarchy.thematic = flat(rawData)?.find(thematic =>
          thematic.subThematics?.find(
            (subThematic: any) => subThematic?.thematicsOverviewDetails.name === d?.thematicsOverviewDetails.name,
          ),
        )?.thematicsOverviewDetails.name;
        hierarchy.subThematic =
          isCaptionedData && d?.thematicsOverviewDetails.index
            ? d?.thematicsOverviewDetails.index
            : t(d?.thematicsOverviewDetails.name);
      } else {
        hierarchy.thematic = flat(rawData)?.find(thematic =>
          thematic?.subThematics?.find((subThematic: any) =>
            subThematic?.subSubThematics?.find(
              (subSubThematic: any) =>
                subSubThematic?.thematicsOverviewDetails.name === d?.thematicsOverviewDetails.name,
            ),
          ),
        )?.thematicsOverviewDetails.name;
        hierarchy.subThematic = flat(rawData)
          .filter(them => them.subSubThematics)
          ?.find(subThematic =>
            subThematic?.subSubThematics?.find(
              (subSubThematic: any) =>
                subSubThematic?.thematicsOverviewDetails?.name === d?.thematicsOverviewDetails.name,
            ),
          )?.thematicsOverviewDetails?.name;
        hierarchy.subSubThematic =
          isCaptionedData && d?.thematicsOverviewDetails.index
            ? d?.thematicsOverviewDetails.index
            : t(d?.thematicsOverviewDetails.name);
      }

      if (!categoryJoinSpecify) delete hierarchy.joinSpecify;

      return {
        originalValue: values[ind],
        value: Math.round(((100 * values[ind]) / totalValue) * 100) / 100,
        name:
          isCaptionedData && d?.thematicsOverviewDetails.index
            ? d?.thematicsOverviewDetails.index
            : t(d?.thematicsOverviewDetails.name),
        originalName: t(d?.thematicsOverviewDetails.name),
        eindex: d?.thematicsOverviewDetails.eindex,
        hierarchy,
      };
    }),
    subFilterToDisplay || verbatimsWithoutThematicCount === 0
      ? null
      : {
          originalValue: verbatimsWithoutThematicCount,
          value: Math.round(((100 * verbatimsWithoutThematicCount) / totalValue) * 100) / 100,
          name: commentsWithoutCategoriesLegendIndex
            ? t(commentsWithoutCategoriesLegendIndex)
            : t('Comments without categories'),
          originalName: t('Comments without categories'),
        },
  ].filter(d => d);
};

export const getGraphToDisplay = (
  graphToDisplay: string,
  onlySelectedCategories: boolean,
  rawData: any,
  data: any,
  level: string,
  subFilterToDisplay: string | null,
  filterToDisplay: string,
  verbatimsWithoutThematicCount: number,
  queryFilters: any,
  riverChartData: any[],
  riverChartDateType: string,
  setRiverChartDateType: {
    (value: React.SetStateAction<'day' | 'week' | 'month'>): void;
  },
  t: (arg0: string) => any,
  queryLoading: boolean,
  commentsWithoutCategoriesLegendIndex: string | undefined,
  isBenchmark: boolean,
  isCaptionedData: boolean,
  thematicsUnifiedLegend: any,
  columns: any,
  column: any,
  categoryJoinSpecify: string | null = null,
) => {
  let graph;
  switch (graphToDisplay) {
    case 'pie':
    case 'donut': {
      const chartData = getPieChartData(
        onlySelectedCategories,
        rawData,
        data,
        level,
        subFilterToDisplay,
        filterToDisplay,
        verbatimsWithoutThematicCount,
        t,
        commentsWithoutCategoriesLegendIndex,
        isCaptionedData,
        categoryJoinSpecify,
      );

      const originalValues = chartData
        ?.filter(d => t(d.name) !== t('Comments without categories'))
        ?.map(d => d.originalValue);
      graph = originalValues?.length ? (
        <PieChart
          data={chartData}
          filterToDisplay={filterToDisplay}
          subFilterToDisplay={subFilterToDisplay}
          isDonut={graphToDisplay === 'donut'}
          t={t}
          queryFilters={queryFilters}
          queryLoading={queryLoading}
          isBenchmark={isBenchmark}
          thematicsUnifiedLegend={thematicsUnifiedLegend}
          level={level}
          columns={columns}
          column={column}
        />
      ) : (
        <NoDataMessage />
      );
      break;
    }
    case 'sunburst':
    case 'treemap': {
      const getChartData = () => {
        let dataToDisplay = [
          ...(!onlySelectedCategories ? flat(rawData) : data)
            .filter((d: any) => d.subThematics)
            .map((d: any) => ({
              name: d?.thematicsOverviewDetails.name,
              value: subFilterToDisplay
                ? d?.thematicsOverviewDetails?.[filterToDisplay]?.[subFilterToDisplay]?.byMentionCount
                : d?.thematicsOverviewDetails?.[filterToDisplay],
              eindex: d?.thematicsOverviewDetails?.eindex,
              children: d.subThematics.map((st: any) => ({
                name: st?.thematicsOverviewDetails.name,
                value: subFilterToDisplay
                  ? st?.thematicsOverviewDetails?.[filterToDisplay]?.[subFilterToDisplay]?.byMentionCount
                  : st?.thematicsOverviewDetails?.[filterToDisplay],
                eindex: st?.thematicsOverviewDetails?.eindex,
                children: st?.subSubThematics.map((sst: any) => ({
                  name: sst?.thematicsOverviewDetails.name,
                  value: subFilterToDisplay
                    ? sst?.thematicsOverviewDetails?.[filterToDisplay]?.[subFilterToDisplay]?.byMentionCount
                    : sst?.thematicsOverviewDetails?.[filterToDisplay],
                  eindex: sst?.thematicsOverviewDetails.eindex,
                })),
              })),
            })),
          {
            name: t('Comments without categories'),
            value: verbatimsWithoutThematicCount,
            children: [],
          },
        ];

        const totalValue = dataToDisplay.map(d => d.value).reduce((a, b) => a + b);
        const childrenTotalValue = dataToDisplay
          .map(d => d.children.map((child: any) => child.value))
          .reduce((a, b) => a + b);
        dataToDisplay = dataToDisplay?.map(d => ({
          ...d,
          percentage: Math.round(((100 * d.value) / totalValue) * 100) / 100,
          children: d.children.map((child: any) => ({
            ...child,
            percentage: Math.round(((100 * child.value) / totalValue) * 100) / 100,
            children: child.children.map((grandchild: any) => ({
              ...grandchild,
              percentage: Math.round(((100 * grandchild.value) / totalValue) * 100) / 100,
            })),
          })),
        }));
        return dataToDisplay;
      };
      graph = getChartData()
        ?.filter(d => d.name !== t('Comments without categories'))
        .map(d => d.value)
        .reduce((a, b) => a + b) ? (
        <TreemapChart
          data={getChartData()}
          subFilterToDisplay={subFilterToDisplay}
          filterToDisplay={filterToDisplay}
          sunburstMode={graphToDisplay === 'sunburst'}
        />
      ) : (
        <NoDataMessage />
      );
      break;
    }
    case 'river': {
      graph = (
        <RiverChart
          subFilterToDisplay={subFilterToDisplay}
          filterToDisplay={filterToDisplay}
          level={level}
          data={riverChartData}
          riverChartDateType={riverChartDateType}
          setRiverChartDateType={setRiverChartDateType}
        />
      );
    }
  }
  return graph;
};

export const getThematicsOverviewByDatesData = ({
  thematicsOverviewByDateData,
  data,
  level,
  t,
  onlySelectedCategories,
  subFilterToDisplay,
  filterToDisplay,
  setRiverChartData,
}) => {
  let newData: any[] = [];
  const {
    getThematicsOverviewByDateData: { thematics },
  } = thematicsOverviewByDateData;
  const commentsWithoutCategoriesData = Object.entries(groupBy(thematics, t => t.date)).map(item => [
    item[0].split('-').join('/'),
    item[1].map(i => i.verbatimsWithoutThematicCount)[0],
    t('Comments without categories'),
  ]);
  let selectedThematics = [];
  if (level === 'thematics') {
    selectedThematics = thematics.filter(them => {
      return data.find(d => d?.thematicsOverviewDetails?.name === them?.thematicsOverviewDetails?.name);
    });
  } else if (level === 'subthematics') {
    selectedThematics = thematics.filter(them =>
      them?.subThematics?.find(subThem =>
        data.find(d => d?.thematicsOverviewDetails?.name === subThem?.thematicsOverviewDetails?.name),
      ),
    );
  } else if (level === 'subsubthematics') {
    selectedThematics = thematics.filter(them =>
      them?.subThematics?.find(subThem =>
        subThem.subSubThematics?.find(subSubThem =>
          data.find(d => d?.thematicsOverviewDetails?.name === subSubThem?.thematicsOverviewDetails?.name),
        ),
      ),
    );
  }

  newData = [...newData, ...commentsWithoutCategoriesData];
  if (level === 'thematics') {
    if (
      uniq(
        (!onlySelectedCategories ? thematics : selectedThematics).map(d => d?.thematicsOverviewDetails?.name),
      ).filter(them => data.find(d => d?.thematicsOverviewDetails.name === them)).length <= 20
    ) {
      (!onlySelectedCategories ? thematics : selectedThematics).forEach((thematic: any) => {
        newData = [
          ...newData,
          [
            thematic.date.split('-').join('/'),
            subFilterToDisplay
              ? thematic?.thematicsOverviewDetails?.[filterToDisplay]?.[subFilterToDisplay]?.byMentionCount || 0
              : thematic?.thematicsOverviewDetails[filterToDisplay],
            thematic?.thematicsOverviewDetails.name,
          ],
        ];
      });
      const totalValue = newData?.map(d => d[1]).length && newData?.map(d => d[1])?.reduce((a, b) => a + b);
      newData = newData.map(d => [...d, Math.round(((100 * d[1]) / totalValue) * 100) / 100]);
    } else {
      newData = ['Too many categories'];
    }
  } else if (level === 'subthematics') {
    if (
      uniq(
        flatten((!onlySelectedCategories ? thematics : selectedThematics).map(thematic => thematic.subThematics)).map(
          d => d?.thematicsOverviewDetails?.name,
        ),
      ).filter(them => data.find(d => d?.thematicsOverviewDetails.name === them))?.length <= 20
    ) {
      (!onlySelectedCategories ? thematics : selectedThematics)?.forEach((thematic: any) => {
        thematic.subThematics
          .filter(subThem =>
            data.find(d => d?.thematicsOverviewDetails?.name === subThem?.thematicsOverviewDetails?.name),
          )
          ?.forEach((subThematic: any) => {
            newData = [
              ...newData,
              [
                thematic.date.split('-').join('/'),
                subFilterToDisplay
                  ? subThematic?.thematicsOverviewDetails?.[filterToDisplay]?.[subFilterToDisplay]?.byMentionCount || 0
                  : subThematic?.thematicsOverviewDetails[filterToDisplay],
                subThematic?.thematicsOverviewDetails.name,
              ],
            ];
          });
      });
      const totalValue = newData.map(d => d[1]).reduce((a, b) => a + b, 0);
      newData = newData.map(d => [...d, Math.round(((100 * d[1]) / totalValue) * 100) / 100]);
    } else {
      newData = ['Too many categories'];
    }
  } else if (level === 'subsubthematics') {
    if (
      uniq(
        flatten(
          flatten((!onlySelectedCategories ? thematics : selectedThematics).map(thematic => thematic.subThematics)).map(
            d => d.subSubThematics,
          ),
        ).map(d => d?.thematicsOverviewDetails?.name),
      ).filter(them => data.find(d => d?.thematicsOverviewDetails.name === them))?.length <= 20
    ) {
      (!onlySelectedCategories ? thematics : selectedThematics)?.forEach((thematic: any) => {
        thematic.subThematics?.forEach((subThematic: any) => {
          subThematic.subSubThematics
            ?.filter(subSubThem =>
              data.find(d => d?.thematicsOverviewDetails?.name === subSubThem?.thematicsOverviewDetails?.name),
            )
            ?.forEach((subSubThematic: any) => {
              newData = [
                ...newData,
                [
                  thematic.date.split('-').join('/'),
                  subFilterToDisplay
                    ? subSubThematic?.thematicsOverviewDetails?.[filterToDisplay]?.[subFilterToDisplay]
                        ?.byMentionCount || 0
                    : subSubThematic?.thematicsOverviewDetails[filterToDisplay],
                  subSubThematic?.thematicsOverviewDetails.name,
                ],
              ];
              const totalValue = newData.map(d => d[1]).reduce((a, b) => a + b);
              newData = newData.map(d => [...d, Math.round(((100 * d[1]) / totalValue) * 100) / 100]);
            });
        });
      });
    } else {
      newData = ['Too many categories'];
    }
  }
  const commentsWithoutCategories = newData.shift();
  newData = [...newData, commentsWithoutCategories];
  setRiverChartData(newData);
};
