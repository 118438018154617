import React from 'react';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import {
  Section,
  SectionItem,
  SectionTitle,
  SectionTitleContainer,
} from '_Cards/SettingsCards/ProjectDetailsCard/_Components/GlobalInformations/styles';

const Container = styled(Section)`
  /* height: 6em; */
  /* max-width: 20em; */
  /* display: flex; */
  /* flex-direction: column; */
  flex-wrap: wrap;
  margin-top: 0;
`;

const AdditionalInformationsSection = ({ informations, xs, t }) => (
  <Col xs={xs}>
    <Section>
      <SectionTitleContainer>
        <SectionTitle>{t('Additional information')}</SectionTitle>
      </SectionTitleContainer>
      <Container>
        {informations?.map(data => (
          <SectionItem>
            <span>• {data}</span>
          </SectionItem>
        ))}
      </Container>
    </Section>
  </Col>
);

export default AdditionalInformationsSection;
