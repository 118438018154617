import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import FiltersSection from '../../../_Components/FiltersSection';
import {
  addSectionFilters,
  FILTER_TYPES,
} from '../../../../../../_Resources/Reporting/Actions/Section/addSectionFilters';
import { updateDisplaySettings } from '../../../../../../_Resources/Reporting/Actions/PageSettings/updateDisplaySettings';
import { sectionInitialConfigState } from '../../../../../../_Resources/Reporting/Reducers/reporting';
import { removeSectionFilters } from '../../../../../../_Resources/Reporting/Actions/Section/removeSectionFilters';

class Filters extends Component {
  static propTypes = {
    // state props
    filters: PropTypes.any.isRequired,
    currentPageIndex: PropTypes.number.isRequired,
    currentCardIndex: PropTypes.number.isRequired,

    // dispatch props
    onThematicsSelected: PropTypes.func.isRequired,
    onThematicsRemoved: PropTypes.func.isRequired,
    onOthersSelected: PropTypes.func.isRequired,
    onOthersRemoved: PropTypes.func.isRequired,
    dispatchUpdateDisplaySettings: PropTypes.func.isRequired,
  };

  resetFilters = () => {
    const {
      dispatchUpdateDisplaySettings,
      currentPageIndex,
      currentCardIndex,
    } = this.props;
    dispatchUpdateDisplaySettings(
      { filters: sectionInitialConfigState.filters },
      currentPageIndex,
      currentCardIndex,
    );
  };

  render() {
    const {
      filters,
      onThematicsSelected,
      onThematicsRemoved,
      onOthersSelected,
      onOthersRemoved,
    } = this.props;

    return (
      <FiltersSection
        isModified={!isEqual(filters, sectionInitialConfigState.filters)}
        onThematicsSelected={onThematicsSelected}
        onThematicsRemoved={onThematicsRemoved}
        onOthersSelected={onOthersSelected}
        onOthersRemoved={onOthersRemoved}
        onReset={this.resetFilters}
        thematics={(filters || {}).thematic}
        other={(filters || {}).normal}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onThematicsSelected: filterValues =>
    dispatch(addSectionFilters(FILTER_TYPES.THEMATIC, filterValues)),
  onThematicsRemoved: (...args) =>
    dispatch(removeSectionFilters(FILTER_TYPES.THEMATIC, args)),
  onOthersSelected: filterValues =>
    dispatch(addSectionFilters(FILTER_TYPES.NORMAL, filterValues)),
  onOthersRemoved: filterValues =>
    dispatch(removeSectionFilters(FILTER_TYPES.NORMAL, [filterValues, ''])),
  dispatchUpdateDisplaySettings: (...args) =>
    dispatch(updateDisplaySettings(...args)),
});

const mapStateToProps = ({
  reporting: { currentPageIndex, currentCardIndex, children },
}) => {
  const currentPage = children[currentPageIndex];
  const { filters } = currentPage.children[currentCardIndex];
  return {
    currentPageIndex,
    currentCardIndex,
    filters,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
