import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Icon from '_Components/Icons/Icon';
import Slider from 'react-slick';
import { white, lightPurple, purple } from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import CDonutChart from '../../../_Charts/KpisCharts/CDonutChart/CDonutChart';
import querySchema from './graphql/getEmotionBreakdownDonutCardData';
import CQuery from '../../../_Container/QQuery/CQuery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '_Cards/SatisfactionCards/SatisfactionNpsCard/style.css';

const SliderArrow = styled.button`
  height: 27px !important;
  width: 27px !important;
  &:focus svg {
    border: 1px solid ${purple};
  }
`;

const SliderArrowSVG = styled(Icon)`
  display: block !important;
  color: ${white} !important;
  background-color: ${lightPurple} !important;
  border-radius: 50% !important;
  height: 25px !important;
  width: 25px !important;
  padding: 3px !important;
  transition-duration: 0.5s !important;
  transition-timing-function: ease-in-out !important;
  position: relative;
  top: -22px;
  &:hover {
    background-color: ${purple} !important;
  }
`;

const LandingPageDonutCardSliderContainer = styled.div`
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;

  & .landingPageDonutCardSlider {
    min-height: 100%;
    height: 100%;
  }
`;

const LandingPageDonutCardSlide = styled.div`
  min-height: 100%;
  height: 100%;
`;

const Arrow = props => {
  const { className, onClick, left, right } = props;
  return (
    <>
      {left && (
        <SliderArrow
          className={className}
          style={{
            left: '-16px',
            zIndex: 10,
          }}
          onClick={onClick}
          type="button"
        >
          <SliderArrowSVG
            class={className}
            style={{
              left: '0',
              zIndex: 10,
            }}
            icon="INTERFACE_CHEVRON_LEFT"
            color={white}
            onClick={onClick}
            size={ImageSize.medium}
          />
        </SliderArrow>
      )}
      {right && (
        <SliderArrow
          className={className}
          style={{
            right: '-17px',
          }}
          onClick={onClick}
          type="button"
        >
          <SliderArrowSVG
            class={className}
            style={{
              right: '0',
            }}
            icon="INTERFACE_CHEVRON_RIGHT"
            color={white}
            onClick={onClick}
            size={ImageSize.medium}
          />
        </SliderArrow>
      )}
    </>
  );
};

const LandingPageDonutCardSlider = ({ filters, noDataComponent, errorComponent, collapsedInfo, userAnalysisAxis, ...props }) => {
  const [windowHeight, setWindowHeight] = useState(500);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  }, [collapsedInfo, filters, window]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    dotsClass: 'slider-dots',
    appendDots: dots => (
      <div
        style={{
          bottom: '0px',
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    prevArrow: <Arrow left />,
    nextArrow: <Arrow right />,
  };

  const sentimentAxis = userAnalysisAxis === 'sentiment';

  return (
    <LandingPageDonutCardSliderContainer>
      <Slider {...settings}>
        <LandingPageDonutCardSlide>
          <CQuery
            query={querySchema}
            filterKeys={filters}
            noDataComponent={noDataComponent}
            errorComponent={errorComponent}
          >
            <CDonutChart {...props} filters={filters} sentimentAxis={sentimentAxis} landingPageMode windowHeight={windowHeight} />
          </CQuery>
        </LandingPageDonutCardSlide>
        <LandingPageDonutCardSlide>
          <CQuery
            query={querySchema}
            filterKeys={filters}
            noDataComponent={noDataComponent}
            errorComponent={errorComponent}
          >
            <CDonutChart {...props} filters={filters} sentimentAxis={!sentimentAxis} landingPageMode windowHeight={windowHeight} />
          </CQuery>
        </LandingPageDonutCardSlide>
      </Slider>
    </LandingPageDonutCardSliderContainer>
  );
};

function mapStateToProps(state) {
  return {
    collapsedInfo: state.projectConfiguration.collapsedInfo,
    userAnalysisAxis: state.auth.user.analysisAxis,
  };
}

export default connect(mapStateToProps)(LandingPageDonutCardSlider);
