import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { flatten } from 'lodash';
import {
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import QBooleanSwitch from '_Components/Fields/QBooleanSwitch/QBooleanSwitch';
import { capitalize } from '_Utils/strings/stringsUtils';
import Icon from '_Components/Icons/Icon';
import CreateAlertModal from 'Routes/Alerts/Alerting/_Components/CreateAlertModal/CreateAlertModal';
import DeleteAlertModal from 'Routes/Alerts/Alerting/_Components/DeleteAlertModal/DeleteAlertModal';
import ExportAlertModal from 'Routes/Alerts/Alerting/_Components/ExportAlertModal/ExportAlertModal';
import { IDENTIFIER_OPTIONS } from 'Routes/Alerts/Alerting/_Components/CreateAlertModal/_Components/Condition/Condition';
import {
  black,
  grayShades,
  lightBlue,
  purple,
  red,
} from 'styles/abstracts/colors';
import { ImageSize } from 'styles/abstracts/variables';
import { fontSizes } from 'styles/abstracts/typography';
import UPDATE_ALERT_CONFIGURATION_MUTATION from '../../graphql/updateAlertConfiguration';
import GET_ALERT_CONFIGURATION_LIST_QUERY from '../../graphql/getAlertConfigurationList';
import QToast from '../../../../../_Components/QToast/QToast';

const StyledCell = styled.td`
  font-weight: bold;
`;

const NameCell = styled(StyledCell)`
  color: ${purple};
`;

const ConditionCell = styled(StyledCell)`
  color: ${grayShades.g800};
`;

const Toggle = styled(DropdownToggle)`
  cursor: pointer !important;
`;

const Menu = styled(DropdownMenu)`
  z-index: 999 !important;
`;

const Item = styled(DropdownItem)`
  padding: 1em !important;
  color: ${black} !important;
  font-weight: bold !important;
  font-size: ${fontSizes.default} !important;

  & span {
    line-height: 20px !important;
    vertical-align: bottom;
    margin-left: 0.25em;
  }
`;

const DeleteItem = styled(Item)`
  color: ${red} !important;
`;

const ExportItem = styled(Item)`
  color: ${lightBlue} !important;
`;

const StyledTooltip = styled(Tooltip)`
  & > .tooltip-inner {
    max-width: 30em !important;
    background-color: ${grayShades.g300} !important;
    color: ${red} !important;
    font-weight: bolder;
    font-size: ${fontSizes.smaller};
  }

  & a {
    color: ${lightBlue};
    text-decoration: underline;
  }
`;

export type AlertType = {
  alertConfigurationId: number;
  active: boolean;
  alertConfigurationName: string;
  conditions: string;
  emails: Array<string>;
  projectId: number;
};

type AlertingRowProps = {
  alert: AlertType;
  index: number;
  alerts: Array<AlertType>;
  t: any;
};

const AlertingRow = ({ alert, index, alerts, t }: AlertingRowProps) => {
  const [isActive, setIsActive] = useState<boolean>(alert.active);
  const [isDropdownOpen, openDropdown] = useState<boolean>(false);
  const [isUpdateModalOpen, openUpdateModal] = useState<boolean>(false);
  const [isDeleteModalOpen, openDeleteModal] = useState<boolean>(false);
  const [isExportModalOpen, openExportModal] = useState<boolean>(false);
  const [isTooltipOpen, openTooltip] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const { alertConfigurationName: alertName, conditions } = alert;
  const [updateAlertConfiguration] = useMutation(
    UPDATE_ALERT_CONFIGURATION_MUTATION,
    {
      update: (cache, { data: { updateAlertConfiguration: updateAlert } }) => {
        let alertConfigurationList;
        try {
          alertConfigurationList = cache.readQuery({
            query: GET_ALERT_CONFIGURATION_LIST_QUERY,
            variables: { projectId },
          }).getAlertConfigurationList;
        } catch (e) {
          alertConfigurationList = alerts;
        }
        const newAlertConfigurationList = alertConfigurationList?.map(config =>
          config.alertConfigurationId === updateAlert.alertConfigurationId
            ? updateAlert
            : config,
        );
        cache.writeQuery({
          query: GET_ALERT_CONFIGURATION_LIST_QUERY,
          variables: { projectId: alert.projectId },
          data: { getAlertConfigurationList: newAlertConfigurationList },
        });
      },
    },
  );

  const getConditionsLabels = () => {
    const cond = JSON.parse(conditions);
    if (Array.isArray(cond)) {
      const phrases = flatten(cond?.map(c => c.conditions));
      return phrases.map((cdt, ind: number) => {
        return (
          <>
            {ind !== 0 && (
              <span style={{ color: purple }}>
                {t(cdt.selector).toUpperCase()} &nbsp;
              </span>
            )}
            <span>
              {t(
                IDENTIFIER_OPTIONS?.find(
                  ident => ident.value === cdt.identifier,
                )?.label,
              )}{' '}
              {t(cdt.include ? 'is equal to' : 'is not equal to')}{' '}
              {t(capitalize(cdt.value))}
            </span>
            <br />
          </>
        );
      });
    }
  };
  return (
    <>
      <tr>
        <NameCell>{alertName}</NameCell>
        <ConditionCell>{getConditionsLabels()}</ConditionCell>
        <NameCell>{t(`button:${capitalize(alert?.frequency)}`)}</NameCell>
        <StyledCell>
          <QBooleanSwitch
            id="active-alert-switcher"
            onChanged={() => {
              setIsActive(!isActive);
              updateAlertConfiguration({
                variables: {
                  ...alert,
                  active: !isActive,
                },
              }).then(() => {
                openTooltip(true);
                setTimeout(() => {
                  openTooltip(false);
                }, 3000);
              });
            }}
            value={isActive}
          />
          <StyledTooltip
            isOpen={isTooltipOpen}
            target="active-alert-switcher"
            placement="top"
            autohide={false}
          >
            {t('This action will have consequences for all users')}
          </StyledTooltip>
        </StyledCell>
        <td id={`alerting-row-options-${index}`}>
          <Dropdown
            isOpen={isDropdownOpen}
            toggle={() => openDropdown(!isDropdownOpen)}
            direction="left"
          >
            <Toggle isOpen={isDropdownOpen} index={index} tag="span">
              <Icon
                icon="INTERFACE_DOTS_VERTICAL"
                color={grayShades.g800}
                size={ImageSize.medium}
              />
            </Toggle>
            <Menu index={index}>
              <Item
                onClick={() => {
                  openUpdateModal(!isUpdateModalOpen);
                }}
              >
                <Icon
                  icon="INTERFACE_EDIT"
                  size={ImageSize.small}
                  color={black}
                />
                <span>{t('button:Edit alert')}</span>
                <CreateAlertModal
                  isOpen={isUpdateModalOpen}
                  open={openUpdateModal}
                  projectId={alert.projectId}
                  alert={alert}
                />
              </Item>
              <ExportItem
                onClick={() => {
                  openExportModal(!isExportModalOpen);
                }}
              >
                <Icon
                  icon="DOWNLOAD"
                  size={ImageSize.small}
                  color={lightBlue}
                />
                <span>{t('button:Export data')}</span>
                <ExportAlertModal
                  alertConfigurationId={alert.alertConfigurationId}
                  isOpen={isExportModalOpen}
                  open={openExportModal}
                  setMessage={setMessage}
                />
                {message?.message?.length > 0 && (
                  <QToast color={lightBlue}>{message?.message}</QToast>
                )}
              </ExportItem>
              <DropdownItem divider />
              <DeleteItem
                onClick={() => {
                  openDeleteModal(!isDeleteModalOpen);
                }}
              >
                <Icon icon="TRASH" size={ImageSize.small} color={red} />
                <span>{t('button:Delete alert')}</span>
              </DeleteItem>
              <DeleteAlertModal
                open={openDeleteModal}
                isOpen={isDeleteModalOpen}
                alertId={alert?.alertConfigurationId}
                projectId={alert?.projectId}
                alerts={alerts}
              />
            </Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};

export default AlertingRow;
